import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/mysql/drop-views";

export default function MysqlDropViews() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Mysql - Drop Views | Aspirant's Home");
        const urls = {
            'previous': '/mysql/update-views',
            'next': '/mysql/rename-views'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>Mysql - Drop Views</h3>
            <div className='mt-4 mb-5'>
                <p>
                    To drop (delete) a MySQL view, you can use the <strong>`DROP VIEW`</strong> statement followed by the name of the view you want to drop. Here's the basic syntax:
                </p>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-blue">DROP VIEW</span> view_name;</p>
                        `
                    }}></div>
                </div>
                <p>
                    Replace <strong>`view_name`</strong> with the name of the view you want to drop. For example, if you want to drop a view called <strong>`youngster`</strong>, you would use the following SQL statement:
                </p>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-blue">DROP VIEW</span> youngster;</p>
                        `
                    }}></div>
                </div>
                <p>
                    After executing this statement, the <strong>`youngster`</strong> view will be deleted, and you will no longer be able to query it.
                </p>
            </div>
        </section>
    )
}