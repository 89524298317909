import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../../Context";
import { Link } from 'react-router-dom';
export const pageUrl = () => "/mysql/string-functions/upper";

export default function UPPER() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Mysql - String Functions - UPPER() | Aspirant's Home");
        path.setPreviousNext({});
    }, [])

    return (
        <>
            <section class='clearfix mb-3'>
                <span className='float-start'>
                    <Link to='/mysql/string-functions' onClick={() => path.setPathName('/mysql/string-functions')} className='btn btn-primary'> <i class="fa-solid fa-less-than"></i>&nbsp; Back to String Functions </Link>
                </span>
            </section>
            <section className='mt-5 mb-5'>
                <h3>Mysql - UPPER() Function</h3>
                <div className='mt-4 mb-5'>
                    <p>
                        The <strong>`UPPER()`</strong> function in MySQL is used to convert all characters in a string to uppercase. This is useful for standardizing text data or for performing case-insensitive comparisons.
                    </p>
                    <p>
                        Here is a simple example using the <strong>`UPPER()`</strong> function:
                    </p>
                    <div className='codePalateBox mt-2 mb-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                                <p><span class="color-blue">SELECT</span> UPPER(<span class="color-green">'Hello, World!'</span>) <span class="color-blue">AS</span> uppercased;</p>
                            `
                        }}></div>
                    </div>
                    <p>
                        This query will return <strong>`HELLO, WORLD!`</strong>.
                    </p>
                </div>
                <div className='mt-5 mb-5'>
                    <h5>UPPER() with MySQL Table</h5>
                    <div className='mt-3 mb-2'>
                        <p>
                            Let's consider an example using the <strong>`UPPER()`</strong> function with a <strong>`customers`</strong> table. The <strong>`UPPER()`</strong> function converts the text in a specified column to uppercase.
                        </p>
                        <table className='table' style={{border: '1px dashed #ccc'}}> 
                            <thead>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>id</th>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>first_name</th>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>last_name</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>1</td>
                                    <td style={{border: '1px dashed #ccc'}}>John</td>
                                    <td style={{border: '1px dashed #ccc'}}>Doe</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>2</td>
                                    <td style={{border: '1px dashed #ccc'}}>Jane</td>
                                    <td style={{border: '1px dashed #ccc'}}>Smith</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>3</td>
                                    <td style={{border: '1px dashed #ccc'}}>Emily</td>
                                    <td style={{border: '1px dashed #ccc'}}>Johnson</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>4</td>
                                    <td style={{border: '1px dashed #ccc'}}>Michael</td>
                                    <td style={{border: '1px dashed #ccc'}}>Brown</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>5</td>
                                    <td style={{border: '1px dashed #ccc'}}>Jessica</td>
                                    <td style={{border: '1px dashed #ccc'}}>Williams</td>
                                </tr>
                            </tbody>
                        </table>
                        <p>
                            If you want to convert the first_name and last_name of each customer to uppercase.
                        </p>
                        <div className='codePalateBox mt-2 mb-2'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-blue">SELECT</span> id, first_name, last_name, UPPER(first_name) <span class="color-blue">AS</span> first_name_upper, UPPER(last_name) <span class="color-blue">AS</span> last_name_upper <span class="color-blue">FROM</span> customers;</p>
                                `
                            }}></div>
                        </div>
                        <p>
                            This query will return:
                        </p>
                        <table className='table' style={{border: '1px dashed #ccc'}}> 
                            <thead>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>id</th>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>first_name</th>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>last_name</th>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>first_name_upper</th>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>last_name_upper</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>1</td>
                                    <td style={{border: '1px dashed #ccc'}}>John</td>
                                    <td style={{border: '1px dashed #ccc'}}>Doe</td>
                                    <td style={{border: '1px dashed #ccc'}}>JOHN</td>
                                    <td style={{border: '1px dashed #ccc'}}>DOE</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>2</td>
                                    <td style={{border: '1px dashed #ccc'}}>Jane</td>
                                    <td style={{border: '1px dashed #ccc'}}>Smith</td>
                                    <td style={{border: '1px dashed #ccc'}}>JANE</td>
                                    <td style={{border: '1px dashed #ccc'}}>SMITH</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>3</td>
                                    <td style={{border: '1px dashed #ccc'}}>Emily</td>
                                    <td style={{border: '1px dashed #ccc'}}>Johnson</td>
                                    <td style={{border: '1px dashed #ccc'}}>EMILY</td>
                                    <td style={{border: '1px dashed #ccc'}}>JOHNSON</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>4</td>
                                    <td style={{border: '1px dashed #ccc'}}>Michael</td>
                                    <td style={{border: '1px dashed #ccc'}}>Brown</td>
                                    <td style={{border: '1px dashed #ccc'}}>MICHAEL</td>
                                    <td style={{border: '1px dashed #ccc'}}>BROWN</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>5</td>
                                    <td style={{border: '1px dashed #ccc'}}>Jessica</td>
                                    <td style={{border: '1px dashed #ccc'}}>Williams</td>
                                    <td style={{border: '1px dashed #ccc'}}>JESSICA</td>
                                    <td style={{border: '1px dashed #ccc'}}>WILLIAMS</td>
                                </tr>
                            </tbody>
                        </table>
                        <p>
                            In this example, The <strong>`UPPER()`</strong> function converts the <strong>`first_name`</strong> and <strong>`last_name`</strong> columns to uppercase.
                        </p>
                    </div>
                </div>
            </section>
        </>
    )
}