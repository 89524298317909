import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/mongo-db/indexing";

export default function MongoIndexing() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Indexing in MongoDB Database | A Complete Guide");
        title.setKeyWords("indexing in mongodb, types of indexing in mongodb, how to create indexing in mongodb, how to apply indexing in mongodb, disadvantages of indexing in mongodb, how to check indexing in mongodb, text indexing in mongodb, how to do indexing in mongodb, what is the purpose of indexing in mongodb, how to add indexing in mongodb, indexing in mongodb example, mongodb when to create index, advantages of indexing in mongodb, how to make index in mongodb, indexing mongodb, indexing mongodb collection, create indexing in mongodb, how to create indexing in mongodb mongoose, mongodb indexing");
        title.setPageDescription("Creating indexes in MongoDB can significantly improve the performance of your queries. Mongoose provides a straightforward way to define indexes on your schemas. Here's how you can create indexes and perform efficient searches using Mongoose.");
        const urls = {
            'previous': '/mongo-db/sorting-records',
            'next': '/mongo-db/aggregation'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>MongoDB - Indexing</h3>
            <div className='mt-4 mb-5'>
                <p>
                    Creating indexes in MongoDB can significantly improve the performance of your queries. Mongoose provides a straightforward way to define indexes on your schemas. Here's how you can create indexes and perform efficient searches using Mongoose.
                </p>
                <h5 className='mt-5'>Creating Indexes</h5>
                <p className='mb-4'>
                    Indexes are defined at the schema level. You can create single-field or compound indexes, and you can specify the type of index, such as unique, sparse, or text.
                </p>
                <h6>Single-Field Index</h6>
                <p>
                    Here's how to create a single-field index:
                </p>
                <div className='codePalateBox mt-2 mb-5'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-blue">const</span> userSchema = <span class="color-blue">new</sppan> mongoose.<span class="color-red">Schema</span>({</p>
                            <p style="margin-left:30px">    <span class="color-pink">name</span>: { <span class="color-pink">type</span>: <span class="color-red">String</span>, <span class="color-pink">required</span>: <span class="color-blue">true</span> },</p>
                            <p style="margin-left:30px">    <span class="color-pink">email</span>: { <span class="color-pink">type</span>: <span class="color-red">String</span>, <span class="color-pink">required</span>: <span class="color-blue">true</span> },</p>
                            <p style="margin-left:30px">    <span class="color-pink">age</span>: { <span class="color-pink">type</span>: <span class="color-red">Number</span>, <span class="color-pink">min</span>: <span class="color-pink">0</span> },</p>
                            <p style="margin-left:30px">    <span class="color-pink">createdAt</span>: { <span class="color-pink">type</span>: <span class="color-red">Date</span>, <span class="color-pink">default</span>: <span class="color-red">Date</span>.now },</p>
                            <p>});</p>
                            <br />
                            <p><span class="color-grey">// Create an index on the 'name' field</span></p>
                            <p>userSchema.<span class="color-red">index</span>({ <span class="color-pink">name</span>: <span class="color-pink">1</span> });  <span class="color-grey">// 1 for ascending, -1 for descending</span></p>
                            <br />
                            <p><span class="color-blue">const</span> User = mongoose.<span class="color-red">model</span>(<span class="color-green">'User'</span>, userSchema);</p>
                        `
                    }}></div>
                </div>
                <h6>Compound Index</h6>
                <p>
                    A compound index is an index on multiple fields. Here's an example:
                </p>
                <div className='codePalateBox mt-2 mb-5'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-grey">// Create a compound index on 'name' and 'email'</span></p>
                            <p>userSchema.<span class="color-red">index</span>({ <span class="color-pink">name</span>: <span class="color-pink">1</span>, <span class="color-pink">email</span>: <span class="color-pink">1</span> });</p>
                            <br />
                            <p><span class="color-blue">const</span> User = mongoose.<span class="color-red">model</span>(<span class="color-green">'User'</span>, userSchema);</p>
                        `
                    }}></div>
                </div>
                <h6>Text Index</h6>
                <p>
                    A text index allows you to perform text search queries. Here's how to create a text index:
                </p>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-blue">const</span> articleSchema = <span class="color-blue">new</span> mongoose.<span class="color-red">Schema</span>({</p>
                            <p style="margin-left:30px">    <span class="color-pink">title</span>: <span class="color-red">String</span>,</p>
                            <p style="margin-left:30px">    <span class="color-pink">content</span>: <span class="color-red">String</span>,</p>
                            <p style="margin-left:30px">    <span class="color-pink">author</span>: <span class="color-red">String</span>,</p>
                            <p>});</p>
                            <br />
                            <p><span class="color-grey">// Create a text index on 'title' and 'content'</span></p>
                            <p>articleSchema.<span class="color-red">index</span>({ <span class="color-pink">title</span>: <span class="color-green">'text'</span>, <span class="color-pink">content</span>: <span class="color-green">'text'</span> });</p>
                            <br />
                            <p><span class="color-blue">const</span> Article = mongoose.<span class="color-red">model</span>(<span class="color-green">'Article'</span>, articleSchema);</p>
                        `
                    }}></div>
                </div>
                <h5 className='mt-5'>Performing Searches</h5>
                <p>
                    Once an index is created, you can perform efficient search queries using Mongoose.
                </p>
                <h6>Basic Search</h6>
                <p>
                    You can perform basic searches using the <strong>`find`</strong> method. When an index exists on the fields being queried, MongoDB can efficiently locate the documents.
                </p>
                <div className='codePalateBox mt-2 mb-5'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>User.<span class="color-red">find</span>({ <span class="color-pink">name</span>: <span class="color-green">'Alice'</span> }, (err, users) => {</p>
                            <p style="margin-left:30px">    <span class="color-blue">if</span> (err) <span class="color-blue">return</span> <span class="color-pink">console</span>.<span class="color-red">error</span>(err);</p>
                            <p style="margin-left:30px">    <span class="color-pink">console</span>.<span class="color-red">log</span>(users);</p>
                            <p>});</p>
                        `
                    }}></div>
                </div>
                <h6>Text Search</h6>
                <p>
                    To perform a text search, use the <strong>`$text`</strong> operator. The <strong>`text`</strong> index must be defined beforehand.
                </p>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>Article.<span class="color-red">find</span>({ <span class="color-pink">$text</span>: { <span class="color-pink">$search</span>: <span class="color-green">'Mongoose'</span> } }, (err, articles) => {</p>
                            <p style="margin-left:30px">    <span class="color-blue">if</span> (err) <span class="color-blue">return</span> <span class="color-pink">console</span>.<span class="color-red">error</span>(err);</p>
                            <p style="margin-left:30px">    <span class="color-pink">console</span>.<span class="color-red">log</span>(articles);</p>
                            <p>});</p>
                        `
                    }}></div>
                </div>
                <p>
                    You can also use text search with additional criteria:
                </p>
                <div className='codePalateBox mt-2 mb-5'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>Article.<span class="color-red">find</span>({ </p>
                            <p style="margin-left:30px">    <span class="color-pink">$text</span>: { <span class="color-pink">$search</span>: <span class="color-green">'Mongoose'</span> }, </p>
                            <p style="margin-left:30px">    <span class="color-pink">author</span>: <span class="color-green">'John Doe'</span> </p>
                            <p>}, (err, articles) => {</p>
                            <p style="margin-left:30px">    <span class="color-blue">if</span> (err) <span class="color-blue">return</span> <span class="color-pink">console</span>.<span class="color-red">error</span>(err);</p>
                            <p style="margin-left:30px">    <span class="color-pink">console</span>.<span class="color-red">log</span>(articles);</p>
                            <p>});</p>
                        `
                    }}></div>
                </div>
                <p>
                    By creating indexes and using efficient search queries, you can significantly enhance the performance of your MongoDB operations with Mongoose.
                </p>
            </div>
        </section>
    )
}