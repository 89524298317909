import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/mysql/change-password";

export default function MysqlChangePassword() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Mysql - Change User Password | Aspirant's Home");
        const urls = {
            'previous': '/mysql/show-users',
            'next': '/mysql/grant-privileges'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>Mysql - Change User Password</h3>
            <div className='mt-4 mb-5'>
                <p>
                    MySQL has a provision to change the user password. There are several ways to change the user password, depending on the MySQL version.
                </p>
                <p>Using,</p>
                <ul>
                    <li>ALTER Statement</li>
                    <li>SET PASSWORD Statement (deprecated)</li>
                </ul>
            </div>
            <div className='mt-5 mb-5'>
                <h5>ALTER Statement</h5>
                <p>
                    In MySQL 5.7.6 and later, you can use the <strong>`ALTER USER`</strong> statement to set or change the password of a user account. Here's the syntax:
                </p>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-blue">ALTER USER</span> <span class="color-green">'username'</span>@<span class="color-green">'host'</span> IDENTIFIED <span class="color-blue">BY</span> <span class="color-green">'new_password'</span>;</p>
                        `
                    }}></div>
                </div>
                <p>
                    For example, to change the password for a user named <strong>`myuser`</strong> that can connect from any host to <strong>`new_password`</strong>, you would use:
                </p>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-blue">ALTER USER</span> <span class="color-green">'myuser'</span>@<span class="color-green">'%'</span> IDENTIFIED <span class="color-blue">BY</span> <span class="color-green">'new_password'</span>;</p>
                        `
                    }}></div>
                </div>
            </div>
            <div className='mt-5 mb-5'>
                <h5>SET PASSWORD Statement</h5>
                <p>
                    In older versions of MySQL (prior to 5.7.6), you can use the <strong>`SET PASSWORD`</strong> statement to change the password.
                </p>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-blue">SET</span> PASSWORD <span class="color-blue">FOR</span> <span class="color-green">'username'</span>@<span class="color-green">'host'</span> = PASSWORD(<span class="color-green">'new_password'</span>);</p>
                        `
                    }}></div>
                </div>
                <p>
                    For example, to change the password for a user named <strong>`myuser`</strong> that can connect from any host to <strong>`new_password`</strong>, you would use:
                </p>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-blue">SET</span> PASSWORD <span class="color-blue">FOR</span> <span class="color-green">'myuser'</span>@<span class="color-green">'%'</span> = PASSWORD(<span class="color-green">'new_password'</span>);</p>
                        `
                    }}></div>
                </div>
            </div>
        </section>
    )
}