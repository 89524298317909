import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../../Context";
import { Link } from 'react-router-dom';
export const pageUrl = () => "/mysql/string-functions/concat";

export default function CONCAT() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Mysql - String Functions - CONCAT() | Aspirant's Home");
        path.setPreviousNext({});
    }, [])

    return (
        <>
            <section class='clearfix mb-3'>
                <span className='float-start'>
                    <Link to='/mysql/string-functions' onClick={() => path.setPathName('/mysql/string-functions')} className='btn btn-primary'> <i class="fa-solid fa-less-than"></i>&nbsp; Back to String Functions </Link>
                </span>
            </section>
            <section className='mt-5 mb-5'>
                <h3>Mysql - CONCAT() Function</h3>
                <div className='mt-4 mb-5'>
                    <p>
                        The <strong>`CONCAT()`</strong> function in MySQL is used to concatenate two or more strings together. It takes multiple string arguments and combines them into a single string. Here’s a detailed explanation with examples, including usage in a table context.
                    </p>
                    <p>
                        Here is a simple example using the <strong>`CONCAT()`</strong> function:
                    </p>
                    <div className='codePalateBox mt-2 mb-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                                <p><span class="color-blue">SELECT</span> CONCAT(<span class="color-green">'Hello, '</span>, <span class="color-green">'World!'</span>);</p>
                            `
                        }}></div>
                    </div>
                    <p>
                        This query will return <strong>`Hello, World!`</strong>.
                    </p>
                </div>
                <div className='mt-5 mb-5'>
                    <h5>CONCAT() with MySQL Table</h5>
                    <div className='mt-3 mb-2'>
                        <p>
                            Consider a table <strong>`employees`</strong> with columns <strong>`id`</strong>, <strong>`first_name`</strong> and <strong>`last_name`</strong>.
                        </p>
                        <table className='table' style={{border: '1px dashed #ccc'}}> 
                            <thead>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>id</th>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>first_name</th>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>last_name</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>1</td>
                                    <td style={{border: '1px dashed #ccc'}}>John</td>
                                    <td style={{border: '1px dashed #ccc'}}>Doe</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>2</td>
                                    <td style={{border: '1px dashed #ccc'}}>Jane</td>
                                    <td style={{border: '1px dashed #ccc'}}>Smith</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>3</td>
                                    <td style={{border: '1px dashed #ccc'}}>Emily</td>
                                    <td style={{border: '1px dashed #ccc'}}>Johnson</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>4</td>
                                    <td style={{border: '1px dashed #ccc'}}>Michael</td>
                                    <td style={{border: '1px dashed #ccc'}}>Brown</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>5</td>
                                    <td style={{border: '1px dashed #ccc'}}>Jessica</td>
                                    <td style={{border: '1px dashed #ccc'}}>Williams</td>
                                </tr>
                            </tbody>
                        </table>
                        <p>
                            If you want to create a new column that combines the first name and last name into a full name.
                        </p>
                        <div className='codePalateBox mt-2 mb-2'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-blue">SELECT</span> id, first_name, last_name, CONCAT(first_name, ' ', last_name) <span class="color-blue">AS</span> full_name <span class="color-blue">FROM</span> employees;</p>
                                `
                            }}></div>
                        </div>
                        <p>
                            This query will return:
                        </p>
                        <table className='table' style={{border: '1px dashed #ccc'}}> 
                            <thead>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>id</th>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>first_name</th>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>last_name</th>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>full_name</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>1</td>
                                    <td style={{border: '1px dashed #ccc'}}>John</td>
                                    <td style={{border: '1px dashed #ccc'}}>Doe</td>
                                    <td style={{border: '1px dashed #ccc'}}>John Doe</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>2</td>
                                    <td style={{border: '1px dashed #ccc'}}>Jane</td>
                                    <td style={{border: '1px dashed #ccc'}}>Smith</td>
                                    <td style={{border: '1px dashed #ccc'}}>Jane Smith</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>3</td>
                                    <td style={{border: '1px dashed #ccc'}}>Emily</td>
                                    <td style={{border: '1px dashed #ccc'}}>Johnson</td>
                                    <td style={{border: '1px dashed #ccc'}}>Emily Johnson</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>4</td>
                                    <td style={{border: '1px dashed #ccc'}}>Michael</td>
                                    <td style={{border: '1px dashed #ccc'}}>Brown</td>
                                    <td style={{border: '1px dashed #ccc'}}>Michael Brown</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>5</td>
                                    <td style={{border: '1px dashed #ccc'}}>Jessica</td>
                                    <td style={{border: '1px dashed #ccc'}}>Williams</td>
                                    <td style={{border: '1px dashed #ccc'}}>Jessica Williams</td>
                                </tr>
                            </tbody>
                        </table>
                        <p>
                            In this example, <strong>`CONCAT(first_name, ' ', last_name)`</strong> concatenates the <strong>`first_name`</strong> and <strong>`last_name`</strong> columns with a space ' ' in between to form a full name.
                        </p>
                    </div>
                </div>
            </section>
        </>
    )
}