import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/html/tables";

export default function HTMLTables() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("HTML - Tables | Aspirant's Home");
        const urls = {
            'previous': '/html/semantics',
            'next': '/html/table-styling'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>HTML - Tables</h3>
            <div className='mt-4 mb-5'>
                <p>
                    HTML tables are used to display tabular data in a structured format. Tables are composed of rows and columns, and they use a combination of several HTML tags to define the table's structure.
                </p>
                <p>
                    The <strong>`&lt;table&gt;`</strong> tag in HTML is used to create tables that organize data into rows and columns. Tables are useful for displaying tabular data such as schedules, financial reports, and any information that needs to be presented in a grid format.
                </p>
                <div className='mt-5 mb-5'>
                    <table className="table table-striped borderTable">
                        <tr style={{border: '1px solid #ccc'}}>
                            <th style={{border: '1px solid #ccc'}}>Header 1</th>
                            <th style={{border: '1px solid #ccc'}}>Header 2</th>
                            <th style={{border: '1px solid #ccc'}}>Header 3</th>
                        </tr>
                        <tr style={{border: '1px solid #ccc'}}>
                            <td style={{border: '1px solid #ccc'}}>Row 1, Cell 1</td>
                            <td style={{border: '1px solid #ccc'}}>Row 1, Cell 2</td>
                            <td style={{border: '1px solid #ccc'}}>Row 1, Cell 3</td>
                        </tr>
                        <tr style={{border: '1px solid #ccc'}}>
                            <td style={{border: '1px solid #ccc'}}>Row 2, Cell 1</td>
                            <td style={{border: '1px solid #ccc'}}>Row 2, Cell 2</td>
                            <td style={{border: '1px solid #ccc'}}>Row 2, Cell 3</td>
                        </tr>
                    </table>
                </div>
                <h5 className='mt-5 mb-3'>HTML Table Tags</h5>
                <ul style={{ listStyle: 'decimal' }}>
                    <li>
                        <strong>`&lt;table&gt;`</strong> :
                        <p className='mt-2 mb-3'>
                            Defines the beginning and end of a table. This tag is a container for all other table-related tags.
                        </p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p>&lt;table&gt;</p>
                                    <p class="ml-30 color-grey">    &lt;!-- Table content goes here --&gt; </p>
                                    <p>&lt;/table&gt;</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <strong>`&lt;tr&gt;`</strong> :
                        <p className='mt-2 mb-3'>
                            Defines a table row. It is used within a <strong>`&lt;table&gt;`</strong>, <strong>`&lt;thead&gt;`</strong>, <strong>`&lt;tbody&gt;`</strong>, or <strong>`&lt;tfoot&gt;`</strong> tag to define a row of cells.
                        </p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p>&lt;table&gt;</p>
                                    <p class="ml-30">    &lt;tr&gt;</p>
                                    <p class="ml-60">        &lt;td&gt;Cell 1&lt;/td&gt;</p>
                                    <p class="ml-60">        &lt;td&gt;Cell 2&lt;/td&gt;</p>
                                    <p class="ml-30">    &lt;/tr&gt;</p>
                                    <p>&lt;/table&gt;</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <strong>`&lt;th&gt;`</strong> :
                        <p className='mt-2 mb-3'>
                            Defines a header cell in a table. This tag is used to create a cell in a table header, which is typically displayed as bold and centered by default.
                        </p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p>&lt;table&gt;</p>
                                    <p class="ml-30">    &lt;tr&gt;</p>
                                    <p class="ml-60">        &lt;th&gt;Header 1&lt;/th&gt;</p>
                                    <p class="ml-60">        &lt;th&gt;Header 2&lt;/th&gt;</p>
                                    <p class="ml-30">    &lt;/tr&gt;</p>
                                    <p>&lt;/table&gt;</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <strong>`&lt;td&gt;`</strong> :
                        <p className='mt-2 mb-3'>
                            Defines a standard data cell in a table. It is used within a <strong>`&lt;tr&gt;`</strong> tag to define individual cells containing data.
                        </p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p>&lt;table&gt;</p>
                                    <p class="ml-30">    &lt;tr&gt;</p>
                                    <p class="ml-60">        &lt;td&gt;Data 1&lt;/td&gt;</p>
                                    <p class="ml-60">        &lt;td&gt;Data 2&lt;/td&gt;</p>
                                    <p class="ml-30">    &lt;/tr&gt;</p>
                                    <p>&lt;/table&gt;</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <strong>`&lt;caption&gt;`</strong> :
                        <p className='mt-2 mb-3'>
                            The <strong>`&lt;caption&gt;`</strong> tag provides a title or description for the table. It is placed immediately after the <strong>`&lt;table&gt;`</strong> tag.
                        </p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p>&lt;table&gt;</p>
                                <p class="ml-30">    &lt;caption&gt;This is a table caption&lt;/caption&gt;</p>
                                <p class="ml-30">    &lt;tr&gt;</p>
                                <p class="ml-60"        &lt;th&gt;Header 1&lt;/th&gt;</p>
                                <p class="ml-60">        &lt;th&gt;Header 2&lt;/th&gt;</p>
                                <p class="ml-60">        &lt;th&gt;Header 3&lt;/th&gt;</p>
                                <p class="ml-30">    &lt;/tr&gt;</p>
                                <p class="ml-30">    &lt;tr&gt;</p>
                                <p class="ml-60">        &lt;td&gt;Data 1&lt;/td&gt;</p>
                                <p class="ml-60">        &lt;td&gt;Data 2&lt;/td&gt;</p>
                                <p class="ml-60">        &lt;td&gt;Data 3&lt;/td&gt;</p>
                                <p class="ml-30">    &lt;/tr&gt;</p>
                                <p>&lt;/table&gt;</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <strong>`&lt;thead&gt;`</strong> :
                        <p className='mt-2 mb-3'>
                            The <strong>`&lt;thead&gt;`</strong> tag is used to group the header content in a table. It contains one or more <strong>`&lt;tr&gt;`</strong> elements.
                        </p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p>&lt;table&gt;</p>
                                    <p class="ml-30">    &lt;thead&gt;</p>
                                    <p class="ml-60">        &lt;tr&gt;</p>
                                    <p class="ml-90">            &lt;th&gt;Header 1&lt;/th&gt;</p>
                                    <p class="ml-90">            &lt;th&gt;Header 2&lt;/th&gt;</p>
                                    <p class="ml-90">            &lt;th&gt;Header 3&lt;/th&gt;</p>
                                    <p class="ml-60">        &lt;/tr&gt;</p>
                                    <p class="ml-30">    &lt;/thead&gt;</p>
                                    <p>&lt;/table&gt;</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <strong>`&lt;tbody&gt;`</strong> :
                        <p className='mt-2 mb-3'>
                            The <strong>`&lt;tbody&gt;`</strong> tag is used to group the body content in a table. It contains one or more <strong>`&lt;tr&gt;`</strong> elements.
                        </p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p>&lt;table&gt;</p>
                                    <p class="ml-30">    &lt;tbody&gt;</p>
                                    <p class="ml-60">        &lt;tr&gt;</p>
                                    <p class="ml-90">            &lt;td&gt;Data 1&lt;/td&gt;</p>
                                    <p class="ml-90">            &lt;td&gt;Data 2&lt;/td&gt;</p>
                                    <p class="ml-90">            &lt;td&gt;Data 3&lt;/td&gt;</p>
                                    <p class="ml-60">        &lt;/tr&gt;</p>
                                    <p class="ml-60">        &lt;tr&gt;</p>
                                    <p class="ml-90">            &lt;td&gt;Data 4&lt;/td&gt;</p>
                                    <p class="ml-90">            &lt;td&gt;Data 5&lt;/td&gt;</p>
                                    <p class="ml-90">            &lt;td&gt;Data 6&lt;/td&gt;</p>
                                    <p class="ml-60">        &lt;/tr&gt;</p>
                                    <p class="ml-30">    &lt;/tbody&gt;</p>
                                    <p>&lt;/table&gt;</p>
                                `
                            }}></div>
                        </div>
                    </li>
                </ul>
            </div>
        </section>
    )
}