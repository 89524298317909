import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/mysql/sub-query";

export default function MysqlSubQuery() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Mysql - SubQuery | Aspirant's Home");
        const urls = {
            'previous': '/mysql/sequences',
            'next': '/mysql/cursor'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>Mysql - SubQuery</h3>
            <div className='mt-4 mb-5'>
                <p>
                    A subquery in MySQL is a query nested within another query, often used to perform operations that cannot be easily achieved with a single query. Subqueries can be used in various parts of a SQL statement, including the <strong>`SELECT`</strong> list, <strong>`FROM`</strong> clause, <strong>`WHERE`</strong> clause, and <strong>`HAVING`</strong> clause.
                </p>
            </div>
            <div className='mt-5 mb-5'>
                <h5>Types of Subqueries</h5>
                <div className='mt-3 mb-5'>
                    <ul>
                        <li><strong>Scalar Subquery:</strong> Returns a single value.</li>
                        <li><strong>Row Subquery:</strong> Returns a single row.</li>
                        <li><strong>Column Subquery:</strong> Returns a single column of values.</li>
                        <li><strong>Table Subquery:</strong> Returns a table of results.</li>
                    </ul>
                </div>
            </div>
            <div className='mt-5 mb-5'>
                <h5>Examples of Subqueries</h5>
                <div className='mt-3 mb-5'>
                    <p>
                        Let's assume we have the following tables:
                    </p>
                    <p><strong>employees</strong> table:</p>
                    <table className='table' style={{border: '1px dashed #ccc'}}> 
                        <thead>
                            <tr style={{border: '1px dashed #ccc'}}>
                                <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>id</th>
                                <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>name</th>
                                <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>department_id</th>
                                <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>salary</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr style={{border: '1px dashed #ccc'}}>
                                <td style={{border: '1px dashed #ccc'}}>1</td>
                                <td style={{border: '1px dashed #ccc'}}>Jane Smith</td>
                                <td style={{border: '1px dashed #ccc'}}>1</td>
                                <td style={{border: '1px dashed #ccc'}}>50000.00</td>
                            </tr>
                            <tr style={{border: '1px dashed #ccc'}}>
                                <td style={{border: '1px dashed #ccc'}}>2</td>
                                <td style={{border: '1px dashed #ccc'}}>John Doe</td>
                                <td style={{border: '1px dashed #ccc'}}>2</td>
                                <td style={{border: '1px dashed #ccc'}}>60000.00</td>
                            </tr>
                            <tr style={{border: '1px dashed #ccc'}}>
                                <td style={{border: '1px dashed #ccc'}}>3</td>
                                <td style={{border: '1px dashed #ccc'}}>Alice Johnson</td>
                                <td style={{border: '1px dashed #ccc'}}>1</td>
                                <td style={{border: '1px dashed #ccc'}}>70000.00</td>
                            </tr>
                            <tr style={{border: '1px dashed #ccc'}}>
                                <td style={{border: '1px dashed #ccc'}}>4</td>
                                <td style={{border: '1px dashed #ccc'}}>Chris Lee</td>
                                <td style={{border: '1px dashed #ccc'}}>3</td>
                                <td style={{border: '1px dashed #ccc'}}>80000.00</td>
                            </tr>
                        </tbody>
                    </table>
                    <p><strong>departments</strong> table:</p>
                    <table className='table' style={{border: '1px dashed #ccc'}}> 
                        <thead>
                            <tr style={{border: '1px dashed #ccc'}}>
                                <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>id</th>
                                <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>department_name</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr style={{border: '1px dashed #ccc'}}>
                                <td style={{border: '1px dashed #ccc'}}>1</td>
                                <td style={{border: '1px dashed #ccc'}}>IT</td>
                            </tr>
                            <tr style={{border: '1px dashed #ccc'}}>
                                <td style={{border: '1px dashed #ccc'}}>2</td>
                                <td style={{border: '1px dashed #ccc'}}>HR</td>
                            </tr>
                            <tr style={{border: '1px dashed #ccc'}}>
                                <td style={{border: '1px dashed #ccc'}}>3</td>
                                <td style={{border: '1px dashed #ccc'}}>Finance</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div className='mt-5 mb-5'>
                <h3>Scalar Subquery Example</h3>
                <div className='mt-3 mb-5'>
                    <p>
                        Suppose you want to find out the name of the department for each employee. You can use a scalar subquery to achieve this:
                    </p>
                    <div className='codePalateBox mt-2 mb-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                                <p><span class="color-blue">SELECT</span> id, name, (<span class="color-blue">SELECT</span> department_name <span class="color-blue">FROM</span> departments <span class="color-blue">WHERE</span> departments.id = employees.department_id) <span class="color-blue">AS</span> department_name, salary <span class="color-blue">FROM</span> employees;</p>
                            `
                        }}></div>
                    </div>
                    <p>
                        In this example, The main <strong>`SELECT`</strong> statement retrieves <strong>`name`</strong> and <strong>`salary`</strong> from the <strong>`employees`</strong> table.
                    </p>
                    <p>
                        The subquery <strong>`(SELECT department_name FROM departments WHERE departments.id = employees.department_id)`</strong> is used to fetch the <strong>`department_name`</strong> from the <strong>`departments`</strong> table where the <strong>`id`</strong> matches the <strong>`department_id`</strong> in the <strong>`employees`</strong> table. This subquery returns a single value for each row in the <strong>`employees`</strong> table.
                    </p>
                    <table className='table' style={{border: '1px dashed #ccc'}}> 
                        <thead>
                            <tr style={{border: '1px dashed #ccc'}}>
                                <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>id</th>
                                <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>name</th>
                                <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>department_name</th>
                                <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>salary</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr style={{border: '1px dashed #ccc'}}>
                                <td style={{border: '1px dashed #ccc'}}>1</td>
                                <td style={{border: '1px dashed #ccc'}}>Jane Smith</td>
                                <td style={{border: '1px dashed #ccc'}}>IT</td>
                                <td style={{border: '1px dashed #ccc'}}>50000.00</td>
                            </tr>
                            <tr style={{border: '1px dashed #ccc'}}>
                                <td style={{border: '1px dashed #ccc'}}>2</td>
                                <td style={{border: '1px dashed #ccc'}}>John Doe</td>
                                <td style={{border: '1px dashed #ccc'}}>HR</td>
                                <td style={{border: '1px dashed #ccc'}}>60000.00</td>
                            </tr>
                            <tr style={{border: '1px dashed #ccc'}}>
                                <td style={{border: '1px dashed #ccc'}}>3</td>
                                <td style={{border: '1px dashed #ccc'}}>Alice Johnson</td>
                                <td style={{border: '1px dashed #ccc'}}>IT</td>
                                <td style={{border: '1px dashed #ccc'}}>70000.00</td>
                            </tr>
                            <tr style={{border: '1px dashed #ccc'}}>
                                <td style={{border: '1px dashed #ccc'}}>4</td>
                                <td style={{border: '1px dashed #ccc'}}>Chris Lee</td>
                                <td style={{border: '1px dashed #ccc'}}>Finance</td>
                                <td style={{border: '1px dashed #ccc'}}>80000.00</td>
                            </tr>
                        </tbody>
                    </table>
                    <p>
                        This output shows the <strong>`name`</strong>, <strong>`department_name`</strong>, and <strong>`salary`</strong> for each employee, with the <strong>`department_name`</strong> obtained using a scalar subquery.
                    </p>
                </div>
            </div>
            <div className='mt-5 mb-5'>
                <h3>Row Subquery Example</h3>
                <div className='mt-3 mb-5'>
                    <p>
                        Suppose you want to retrieve the name and department name of the employee with the highest salary. You can use a row subquery to achieve this:
                    </p>
                    <div className='codePalateBox mt-2 mb-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                                <p><span class="color-blue">SELECT</span> id, name, department_name <span class="color-blue">FROM</span> employees, departments <span class="color-blue">WHERE</span></p>
                                <p>(department_id, salary) = (</p>
                                <p style="margin-left:30px">    <span class="color-blue">SELECT</span> department_id, <span class="color-yellow">MAX</span>(salary)</p>
                                <p style="margin-left:30px">    <span class="color-blue">FROM</span> employees <span class="color-blue">GROUP BY</span> department_id <span class="color-blue">ORDER BY</span> <span class="color-yellow">MAX</span>(salary) <span class="color-blue">DESC LIMIT</span> <span class="color-pink">1</span></p>
                                <p>);</p>
                            `
                        }}></div>
                    </div>
                    <p>
                        In this example, The main <strong>`SELECT`</strong> statement retrieves <strong>`name`</strong>, and <strong>`department_name`</strong> from the <strong>`employees`</strong> and <strong>`departments`</strong> tables.
                    </p>
                    <p>
                        The subquery <strong>`(SELECT department_id, MAX(salary) FROM employees GROUP BY department_id ORDER BY MAX(salary) DESC LIMIT 1)`</strong> returns a single row with two columns: <strong>`department_id`</strong> and the highest salary in the <strong>`employees`</strong> table.
                    </p>
                    <p>
                        The <strong>`WHERE`</strong> clause uses the row subquery to find the employee whose <strong>`department_id`</strong> and <strong>`salary`</strong> match the results of the subquery.
                    </p>
                    <p>
                        Output of this query is -
                    </p>
                    <table className='table' style={{border: '1px dashed #ccc'}}> 
                        <thead>
                            <tr style={{border: '1px dashed #ccc'}}>
                                <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>id</th>
                                <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>name</th>
                                <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>department_name</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr style={{border: '1px dashed #ccc'}}>
                                <td style={{border: '1px dashed #ccc'}}>4</td>
                                <td style={{border: '1px dashed #ccc'}}>Chris Lee</td>
                                <td style={{border: '1px dashed #ccc'}}>Finance</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div className='mt-5 mb-5'>
                <h3>Column Subquery Example</h3>
                <div className='mt-3 mb-5'>
                    <p>
                        Suppose we want to find all employees who work in departments where the highest salary is greater than 60000.
                    </p>
                    <div className='codePalateBox mt-2 mb-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                                <p><span class="color-blue">SELECT</span> e.name, d.department_name <span class="color-blue">FROM</span> employees e <span class="color-blue">JOIN</span> departments d <span class="color-blue">ON</span> e.department_id = d.id <span class="color-blue">WHERE</span></p>
                                <p>e.department_id IN (</p>
                                <p style="margin-left:30px">    <span class="color-blue">SELECT</span> department_id <span class="color-blue">FROM</span> employees <span class="color-blue">GROUP BY</span> department_id  <span class="color-blue">HAVING</span> <span class="color-yellow">MAX</span>(salary) > <span class="color-pink">60000</span></p>
                                <p>);</p>
                            `
                        }}></div>
                    </div>
                    <p>
                        In this example, The main query selects <strong>`name`</strong> and <strong>`department_name`</strong> of employees whose <strong>`department_id`</strong> matches any in the subquery result.
                    </p>
                    <p>
                        And the subquery <strong>`(SELECT department_id FROM employees GROUP BY department_id HAVING MAX(salary) &gt; 60000)`</strong> identifies the departments where the highest salary exceeds 60000.
                    </p>
                    <p>The output will be the following - </p>
                    <table className='table' style={{border: '1px dashed #ccc'}}> 
                        <thead>
                            <tr style={{border: '1px dashed #ccc'}}>
                                <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>id</th>
                                <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>name</th>
                                <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>department_name</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr style={{border: '1px dashed #ccc'}}>
                                <td style={{border: '1px dashed #ccc'}}>1</td>
                                <td style={{border: '1px dashed #ccc'}}>Jane Smith</td>
                                <td style={{border: '1px dashed #ccc'}}>IT</td>
                            </tr>
                            <tr style={{border: '1px dashed #ccc'}}>
                                <td style={{border: '1px dashed #ccc'}}>3</td>
                                <td style={{border: '1px dashed #ccc'}}>Alice Johnson</td>
                                <td style={{border: '1px dashed #ccc'}}>IT</td>
                            </tr>
                            <tr style={{border: '1px dashed #ccc'}}>
                                <td style={{border: '1px dashed #ccc'}}>4</td>
                                <td style={{border: '1px dashed #ccc'}}>Chris Lee</td>
                                <td style={{border: '1px dashed #ccc'}}>Finance</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div className='mt-5 mb-5'>
                <h3>Table Subquery Example</h3>
                <div className='mt-3 mb-5'>
                    <p>
                        Suppose we want to find the average salary by department and then select departments where the average salary is greater than 60,000.
                    </p>
                    <div className='codePalateBox mt-2 mb-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                            <p><span class="color-blue">SELECT</span> d.department_name, avg_salaries.avg_salary <span class="color-blue">FROM</span> departments d <span class="color-blue">JOIN</span> </p>
                            <p style="margin-left:30px">    (<span class="color-blue">SELECT</span> department_id, AVG(salary) <span class="color-blue">AS</span> avg_salary <span class="color-blue">FROM</span> employees <span class="color-blue">GROUP BY</span> department_id) <span class="color-blue">AS</span> avg_salaries</p>
                            <p><span class="color-blue">ON</span> d.id = avg_salaries.department_id </p>
                            <p><span class="color-blue">WHERE</span> avg_salaries.avg_salary > <span class="color-pink">60000</span>;</p>
                            `
                        }}></div>
                    </div>
                    <p>
                        In this example,
                    </p>
                    <ul style={{listStyle:'decimal'}}>
                        <li>
                            The subquery <strong>`(SELECT department_id, AVG(salary) AS avg_salary FROM employees GROUP BY department_id)`</strong> calculates the average salary for each department.
                        </li>
                        <li>
                            This result set is treated as a temporary table called <strong>`avg_salaries`</strong>.
                        </li>
                        <li>
                            The outer query joins the <strong>`departments`</strong> table with this temporary table using the department IDs.
                        </li>
                        <li>
                            The <strong>`WHERE`</strong> clause filters the results to include only those departments where the average salary is greater than 60,000
                        </li>
                    </ul>
                    <p>The output will be the following - </p>
                    <table className='table' style={{border: '1px dashed #ccc'}}> 
                        <thead>
                            <tr style={{border: '1px dashed #ccc'}}>
                                <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>department_name</th>
                                <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>avg_salary</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr style={{border: '1px dashed #ccc'}}>
                                <td style={{border: '1px dashed #ccc'}}>Finance</td>
                                <td style={{border: '1px dashed #ccc'}}>80000</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </section>
    )
}