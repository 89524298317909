import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';
export const pageUrl = () => "/mysql/date-and-time-functions";

export default function MysqlDateTimeFunctions() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Mysql - Date and Time functions | Aspirant's Home");
        const urls = {
            'previous': '/mysql/drop-trigger',
            'next': '/mysql/arithmetic-operators'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>Mysql - Date and Time functions</h3>
            <div className='mt-4 mb-5'>
                <p>
                    MySQL provides several date and time functions that you can use to work with date and time values in your queries. Here are some commonly used date and time functions in MySQL:
                </p>
                <table className='table' style={{border: '1px dashed #ccc'}}> 
                    <thead>
                        <tr style={{border: '1px dashed #ccc'}}>
                            <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff', width: '20%'}}>Function Name</th>
                            <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>Description & Syntax</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr style={{ border: '1px dashed #ccc' }}>
                            <td style={{ border: '1px dashed #ccc', textAlign: 'center' }}>
                                <Link to="/mysql/date-and-time-functions/adddate">ADDDATE()</Link>
                            </td>
                            <td style={{ border: '1px dashed #ccc' }}>
                                <p>
                                    The MySQL <strong>`ADDDATE()`</strong> function is used to add the specified interval to a date value.
                                </p>
                            </td>
                        </tr>
                        <tr style={{ border: '1px dashed #ccc' }}>
                            <td style={{ border: '1px dashed #ccc', textAlign: 'center' }}>
                                <Link to="/mysql/date-and-time-functions/addtime">ADDTIME()</Link>
                            </td>
                            <td style={{ border: '1px dashed #ccc' }}>
                                <p>
                                    The MySQL <strong>`ADDTIME()`</strong> function is used to add a time interval to a time or datetime expression.
                                </p>
                            </td>
                        </tr>
                        <tr style={{ border: '1px dashed #ccc' }}>
                            <td style={{ border: '1px dashed #ccc', textAlign: 'center' }}>
                                <Link to="/mysql/date-and-time-functions/convert-tz">CONVERT_TZ()</Link>
                            </td>
                            <td style={{ border: '1px dashed #ccc' }}>
                                <p>
                                    The MySQL <strong>`CONVERT_TZ()`</strong> function is used to convert a datetime value from one timezone to another. 
                                </p>
                            </td>
                        </tr>
                        <tr style={{ border: '1px dashed #ccc' }}>
                            <td style={{ border: '1px dashed #ccc', textAlign: 'center' }}>
                                <Link to="/mysql/date-and-time-functions/curdate">CURDATE()</Link>
                            </td>
                            <td style={{ border: '1px dashed #ccc' }}>
                                <p>
                                    The MySQL <strong>`CURDATE()`</strong> function is used to get the current date. It returns the current date in 'YYYY-MM-DD' format. 
                                </p>
                            </td>
                        </tr>
                        <tr style={{ border: '1px dashed #ccc' }}>
                            <td style={{ border: '1px dashed #ccc', textAlign: 'center' }}>
                                <Link to="/mysql/date-and-time-functions/curtime">CURTIME()</Link>
                            </td>
                            <td style={{ border: '1px dashed #ccc' }}>
                                <p>
                                    The MySQL <strong>`CURTIME()`</strong> function is used to get the current time. It returns the current time in 'HH:MM:SS' format.
                                </p>
                            </td>
                        </tr>
                        <tr style={{ border: '1px dashed #ccc' }}>
                            <td style={{ border: '1px dashed #ccc', textAlign: 'center' }}>
                                <Link to="/mysql/date-and-time-functions/date-format">DATE_FORMAT()</Link>
                            </td>
                            <td style={{ border: '1px dashed #ccc' }}>
                                <p>
                                    The MySQL <strong>`DATE_FORMAT()`</strong> function is used to format date and time values. It takes a date or datetime value and a format string as arguments, and returns the date or datetime value formatted according to the format string.
                                </p>
                            </td>
                        </tr>
                        <tr style={{ border: '1px dashed #ccc' }}>
                            <td style={{ border: '1px dashed #ccc', textAlign: 'center' }}>
                                <Link to="/mysql/date-and-time-functions/date-add">DATE_ADD()</Link>
                            </td>
                            <td style={{ border: '1px dashed #ccc' }}>
                                <p>
                                    The MySQL <strong>`DATE_ADD()`</strong> function is used to add a specified time interval to a date or datetime expression. 
                                </p>
                            </td>
                        </tr>
                        <tr style={{ border: '1px dashed #ccc' }}>
                            <td style={{ border: '1px dashed #ccc', textAlign: 'center' }}>
                                <Link to="/mysql/date-and-time-functions/date-sub">DATE_SUB()</Link>
                            </td>
                            <td style={{ border: '1px dashed #ccc' }}>
                                <p>
                                    The MySQL <strong>`DATE_SUB()`</strong> function is used to subtract a specified time interval from a date or datetime expression.
                                </p>
                            </td>
                        </tr>
                        <tr style={{ border: '1px dashed #ccc' }}>
                            <td style={{ border: '1px dashed #ccc', textAlign: 'center' }}>
                                <Link to="/mysql/date-and-time-functions/date">DATE()</Link>
                            </td>
                            <td style={{ border: '1px dashed #ccc' }}>
                                <p>
                                    The MySQL <strong>`DATE()`</strong> function is used to extract the date part from a date or datetime expression. It returns the date part as a string in 'YYYY-MM-DD' format.
                                </p>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div className='mt-5 mb-5 addBetweenSpace'>
                    <ins class="adsbygoogle"
                        style={{ 'display': 'block' }}
                        data-ad-client="ca-pub-2061924575986153"
                        data-ad-slot="6159913076"
                        data-ad-format="auto"
                        data-full-width-responsive="true"></ins>
                    <script>
                        (adsbygoogle = window.adsbygoogle || []).push({});
                    </script>
                </div>
                <table className='table' style={{border: '1px dashed #ccc'}}>
                    <tbody>
                        <tr style={{ border: '1px dashed #ccc' }}>
                            <td style={{ border: '1px dashed #ccc', width: '20%', textAlign: 'center' }}>
                                <Link to="/mysql/date-and-time-functions/datediff">DATEDIFF()</Link>
                            </td>
                            <td style={{ border: '1px dashed #ccc' }}>
                                <p>
                                    The MySQL <strong>`DATEDIFF()`</strong> function is used to calculate the difference between two dates. It returns the number of days between two dates.
                                </p>
                            </td>
                        </tr>
                        <tr style={{ border: '1px dashed #ccc' }}>
                            <td style={{ border: '1px dashed #ccc', width: '20%', textAlign: 'center' }}>
                                <Link to="/mysql/date-and-time-functions/day">DAY()</Link>
                            </td>
                            <td style={{ border: '1px dashed #ccc' }}>
                                <p>
                                    The MySQL <strong>`DAY()`</strong> function is used to extract the day part from a date or datetime expression. It returns the day of the month as an integer between 1 and 31.
                                </p>
                            </td>
                        </tr>
                        <tr style={{ border: '1px dashed #ccc' }}>
                            <td style={{ border: '1px dashed #ccc', width: '20%', textAlign: 'center' }}>
                                <Link to="/mysql/date-and-time-functions/now">NOW()</Link>
                            </td>
                            <td style={{ border: '1px dashed #ccc' }}>
                                <p>
                                    The MySQL <strong>`NOW()`</strong> function is used to return the current date and time. It returns a datetime value in 'YYYY-MM-DD HH:MM:SS' format representing the current date and time according to the server's system date and time settings.
                                </p>
                            </td>
                        </tr>
                        <tr style={{ border: '1px dashed #ccc' }}>
                            <td style={{ border: '1px dashed #ccc', width: '20%', textAlign: 'center' }}>
                                <Link to="/mysql/date-and-time-functions/str-to-date">STR_TO_DATE()</Link>
                            </td>
                            <td style={{ border: '1px dashed #ccc' }}>
                                <p>
                                    The MySQL <strong>`STR_TO_DATE()`</strong> function is used to convert a string into a date value based on a specified format.
                                </p>
                            </td>
                        </tr>
                        <tr style={{ border: '1px dashed #ccc' }}>
                            <td style={{ border: '1px dashed #ccc', width: '20%', textAlign: 'center' }}>
                                <Link to="/mysql/date-and-time-functions/sec-to-time">SEC_TO_TIME()</Link>
                            </td>
                            <td style={{ border: '1px dashed #ccc' }}>
                                <p>
                                    The MySQL <strong>`SEC_TO_TIME()`</strong> function is used to convert a number of seconds into a time format. It takes a number of seconds as input and returns a time value in 'HH:MM:SS' format.
                                </p>
                            </td>
                        </tr>
                        <tr style={{ border: '1px dashed #ccc' }}>
                            <td style={{ border: '1px dashed #ccc', width: '20%', textAlign: 'center' }}>
                                <Link to="/mysql/date-and-time-functions/time-to-sec">TIME_TO_SEC()</Link>
                            </td>
                            <td style={{ border: '1px dashed #ccc' }}>
                                <p>
                                    The MySQL <strong>`TIME_TO_SEC()`</strong> function is used to convert a time value into the number of seconds since midnight. It takes a time value as input and returns the total number of seconds represented by that time.
                                </p>
                            </td>
                        </tr>
                        <tr style={{ border: '1px dashed #ccc' }}>
                            <td style={{ border: '1px dashed #ccc', width: '20%', textAlign: 'center' }}>
                                <Link to="/mysql/date-and-time-functions/timediff">TIMEDIFF()</Link>
                            </td>
                            <td style={{ border: '1px dashed #ccc' }}>
                                <p>
                                    The MySQL <strong>`TIMEDIFF()`</strong> function is used to calculate the difference between two time values. It takes two arguments, the end time and the start time, and returns the difference as a time value.
                                </p>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </section>
    )
}