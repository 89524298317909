import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';
export const pageUrl = () => "/html/layouts";

export default function HTMLLayouts() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("HTML - Layout | Aspirant's Home");
        const urls = {
            'previous': '/html/emojis',
            'next': '/html/semantics'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>HTML - Layout</h3>
            <div className='mt-4 mb-5'>
                <p>
                    HTML layout refers to the structure and organization of content on a web page. It involves arranging elements such as headers, footers, navigation menus, sidebars, and main content areas to create a visually appealing and user-friendly interface. There are various methods and techniques to create layouts in HTML, including the use of HTML5 semantic elements, CSS, and layout frameworks.
                </p>
                <h5 className='mt-5 mb-3'>Semantic Elements</h5>
                <p>
                    HTML5 introduced several new elements that help define the structure of a web page in a more meaningful way. These elements include:
                </p>
                <div className='row'>
                    <div className='col-4'>
                        <div className='mt-3 myImage'>
                            <img className='img-fluid' src='../assets/html/html-layouts.png' />
                        </div>
                    </div>
                    <div className='col-8'>
                        <ul style={{ 'listStyle': 'disc' }}>
                            <li><strong>`&lt;header&gt;`</strong>: Represents the header section of a document or a section, typically containing headings, navigation, or other introductory content.</li>
                            <li><strong>`&lt;nav&gt;`</strong>: Represents a section of the page intended for navigation links.</li>
                            <li><strong>`&lt;section&gt;`</strong>: Represents a generic section of a document, typically with a heading.</li>
                            <li><strong>`&lt;article&gt;`</strong>: Represents a self-contained piece of content that could be distributed independently (e.g., a blog post).</li>
                            <li><strong>`&lt;aside&gt;`</strong>: Represents content that is tangentially related to the main content (e.g., a sidebar).</li>
                            <li><strong>`&lt;footer&gt;`</strong>: Represents the footer section of a document or a section, typically containing information about the author, copyright information, or navigation links.</li>
                        </ul>
                    </div>
                </div>
                <h5 className='mt-5 mb-3'>HTML Layout Techniques</h5>
                <p>There are four different techniques to create multicolumn layouts. Each technique has its pros and cons:</p>
                <ul style={{ 'listStyle': 'disc' }}>
                    <li>CSS framework</li>
                    <li>CSS float property</li>
                    <li>CSS flexbox</li>
                    <li>CSS grid</li>
                </ul>
                <h5 className='mt-5 mb-3'>CSS framework</h5>
                <p>
                    A CSS framework is a pre-prepared library that is meant to be used by web developers to simplify the development of web pages. It includes a collection of CSS files that provide a foundation for your web development project. CSS frameworks are designed to help you achieve a consistent and structured layout, as well as handle common tasks such as creating grids, buttons, forms, and other UI components.
                </p>
                <h5 className='mt-5 mb-3'>CSS float property</h5>
                <p>
                    The CSS float property is a powerful tool used to create layouts in HTML. While modern techniques like Flexbox and Grid have largely replaced float for layout purposes, understanding float is still valuable for working with older code and for certain specific use cases.
                </p>
                <p>
                    The <strong>`float`</strong> property in CSS is used to position an element to the left or right within its container, allowing text and inline elements to wrap around it.
                </p>
                <h5 className='mt-5 mb-3'>CSS Flexbox</h5>
                <p>
                    CSS Flexbox (Flexible Box Layout) is a layout model designed to make it easier to design flexible and responsive layout structures. It provides an efficient way to distribute space among items in a container, even when their size is unknown or dynamic. Flexbox is particularly useful for creating layouts that adapt to different screen sizes and orientations.
                </p>
                <p>
                    The parent element that holds the flex items. It is defined by setting the <strong>`display`</strong> property to <strong>`flex`</strong> or <strong>`inline-flex`</strong>.
                </p>
                <h5 className='mt-5 mb-3'>CSS Grid</h5>
                <p>
                    CSS Grid Layout is a powerful layout system that allows you to create complex and responsive web designs with a grid-based approach. It provides a way to create two-dimensional layouts using rows and columns, offering greater control over the placement of items within a container.
                </p>
                <p>
                    The parent element that holds grid items. Defined by setting the <strong>`display`</strong> property to <strong>`grid`</strong> or <strong>`inline-grid`</strong>.
                </p>

                <p className='mt-5 featureClass'>
                    You can check the all CSS based layout in full details <Link to="/css/introduction">here</Link>.
                </p>
            </div>
        </section>
    )
}