import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/mongo-db/get-started";

export default function MongoGetStarted() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Install MongoDB on Windows, MacOS & Linux Step By Step");
        title.setKeyWords("How to Install MongoDB on Windows?, mongodb installation, mongodb installation mac, mongodb installation windows, mongodb installation linux, mongodb installation steps, mongodb installation guide, mongodb installation and configuration in windows, mongodb installation and configuration, mongodb installation and configuration on linux, introduction and installation of mongodb, how to use mongodb after installation, how to run mongodb after installation, mongodb install brew, mongodb installation on linux step by step, mongodb installation on windows step by step, ");
        title.setPageDescription("Setting up MongoDB involves a few steps, including installation, starting the MongoDB server, and connecting to the server.");
        const urls = {
            'previous': '/mongo-db/introduction',
            'next': '/mongo-db/create-database'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>MongoDB - Get Started</h3>
            <div className='mt-4 mb-5'>
                <p>
                    Setting up MongoDB involves a few steps, including installation, starting the MongoDB server, and connecting to the server. Here’s a brief overview of each step:
                </p>
                <h5>Installation</h5>
                <ul>
                    <li>
                        <strong>For Windows:</strong>
                        <ul style={{listStyle:'decimal'}}>
                            <li>Download the MongoDB installer from the official MongoDB download page.</li>
                            <li>Run the installer and follow the installation prompts.</li>
                            <li>Choose the complete installation option and make sure to install MongoDB as a Windows service.</li>
                            <li>Add the MongoDB bin directory to the system PATH environment variable.</li>
                        </ul>
                    </li>
                    <li>
                        <strong>For macOS:</strong>
                        <ul style={{ listStyle: 'decimal' }}>
                            <li>
                                Use Homebrew to install MongoDB
                                <div className='codePalateBox mt-2 mb-2'>
                                    <div className='codePalate' dangerouslySetInnerHTML={{
                                        __html: `
                                            <p>brew tap mongodb/brew</p>
                                            <p>brew install mongodb-community@6.0</p>
                                        `
                                    }}></div>
                                </div>
                            </li>
                            <li>
                                Start MongoDB using Homebrew services
                                <div className='codePalateBox mt-2 mb-2'>
                                    <div className='codePalate' dangerouslySetInnerHTML={{
                                        __html: `
                                            <p>brew services start mongodb/brew/mongodb-community</p>
                                        `
                                    }}></div>
                                </div>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <strong>For Linux:</strong>
                        <ul style={{ listStyle: 'decimal' }}>
                            <li>
                                Run the following command to import the MongoDB public GPG key −
                                <div className='codePalateBox mt-2 mb-2'>
                                    <div className='codePalate' dangerouslySetInnerHTML={{
                                        __html: `
                                            <p>wget -qO - https://www.mongodb.org/static/pgp/server-6.0.asc | sudo apt-key add -</p>
                                        `
                                    }}></div>
                                </div>
                            </li>
                            <li>
                                Create a /etc/apt/sources.list.d/mongodb.list file using the following command.
                                <div className='codePalateBox mt-2 mb-2'>
                                    <div className='codePalate' dangerouslySetInnerHTML={{
                                        __html: `
                                            <p>echo "deb [ arch=amd64,arm64 ] https://repo.mongodb.org/apt/ubuntu focal/mongodb-org/6.0 multiverse" | sudo tee /etc/apt/sources.list.d/mongodb-org-6.0.list</p>
                                        `
                                    }}></div>
                                </div>
                            </li>
                            <li>
                                Now issue the following command to update the repository −
                                <div className='codePalateBox mt-2 mb-2'>
                                    <div className='codePalate' dangerouslySetInnerHTML={{
                                        __html: `
                                            <p>sudo apt-get update</p>
                                        `
                                    }}></div>
                                </div>
                            </li>
                            <li>
                                Next install the MongoDB by using the following command −
                                <div className='codePalateBox mt-2 mb-2'>
                                    <div className='codePalate' dangerouslySetInnerHTML={{
                                        __html: `
                                            <p>sudo apt-get install -y mongodb-org</p>
                                        `
                                    }}></div>
                                </div>
                            </li>
                        </ul>
                    </li>
                </ul>
                <h5>Starting MongoDB</h5>
                <ul>
                    <li>
                        <strong>For Windows:</strong>
                        <p>
                            MongoDB should start automatically if installed as a service. If not, start it manually using
                        </p>
                        <div className='codePalateBox mt-2 mb-2'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p>net start MongoDB</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <strong>For macOS and Linux:</strong>
                        <p>
                            Start the MongoDB server by running
                        </p>
                        <div className='codePalateBox mt-2 mb-2'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p>mongod</p>
                                `
                            }}></div>
                        </div>
                    </li>
                </ul>
            </div>
        </section>
    )
}