import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/node-js/mongodb-create-database";

export default function MongoDbCreateDatabase() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Node Js - MongoDB - Create Database | Aspirant's Home");
        const urls = {
            'previous': '/node-js/mongodb',
            'next': '/node-js/mongodb-create-collection'
        }
        path.setPreviousNext(urls);
    }, [])
    
    return (
        <section className='mt-5 mb-5'>
            <h3 className='mb-4'>Node Js MongoDB Create Database</h3>
            <p>To create a database in MongoDB, you start by creating a MongoClient object. Then, you specify a connection URL with the correct IP address and the name of the database you want to create. If the database does not exist, MongoDB will create it and establish a connection to it.</p>
            <div className='mb-3'>
                <p>Here is an example how to create database using MongoClient</p>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html:
                            `<p><span class='color-blue'>var</span> MongoClient = <span class='color-yellow'>require</span>(<span class='color-green'>'mongodb'</span>).MongoClient;</p>
                <p><span class='color-blue'>var</span> url = <span class='color-green'>"mongodb://localhost:27017/mymongodbdatabase"</span>;</p>
                <br />
                <p>MongoClient.<span class='color-red'>connect</span>(url, function(err, db) {</p>
                <p style='margin-left:30px'>  <span class='color-blue'>if</span> (err) throw err;</p>
                <p style='margin-left:30px'> <span class='color-pink'>console</span>.<span class='color-red'>log</span>(<span class='color-green'>"Database created!"</span>);</p>
                <p style='margin-left:30px'>  db.<span class='color-red'>close</span>();</p>
                <p>});</p>` }}></div>
                </div>
            </div>
            <p>Which will give you this result:</p>
            <div className='codePalateBox mt-2 mb-2'>
                <div className='codePalate' dangerouslySetInnerHTML={{
                    __html: `Database created!`
                }}></div>
            </div>
        </section>
    )
}
