import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/mysql/case-function";

export default function MysqlCaseFunction() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Mysql - CASE Function | Aspirant's Home");
        const urls = {
            'previous': '/mysql/stored-functions'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>Mysql - CASE Function</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <strong>`CASE`</strong> function in MySQL is used to implement conditional logic in SQL queries. It allows you to execute a series of conditions and return a specific result when a condition is met. This can be particularly useful for transforming data in your SELECT queries based on certain criteria.
                </p>
                <p>
                    The <strong>`CASE`</strong> function has two formats:
                </p>
                <ul style={{listStyle:'decimal'}}>
                    <li>
                        <strong>Simple CASE Function</strong>
                        <div className='codePalateBox mt-2 mb-2'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p><span class="color-blue">CASE</span> expression</p>
                                <p style="margin-left:30px">    <span class="color-blue">WHEN</span> value1 <span class="color-blue">THEN</span> result1</p>
                                <p style="margin-left:30px">    <span class="color-blue">WHEN</span> value2 <span class="color-blue">THEN</span> result2</p>
                                <p style="margin-left:30px">    ...</p>
                                <p style="margin-left:30px">    <span class="color-blue">ELSE</span> default_result</p>
                                <p><span class="color-blue">END</span></p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <strong>Searched CASE Function</strong>
                        <div className='codePalateBox mt-2 mb-2'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p><span class="color-blue">CASE</span></p>
                                <p style="margin-left:30px">    <span class="color-blue">WHEN</span> condition1 <span class="color-blue">THEN</span> result1</p>
                                <p style="margin-left:30px">    <span class="color-blue">WHEN</span> condition2 <span class="color-blue">THEN</span> result2</p>
                                <p style="margin-left:30px">    ...</p>
                                <p style="margin-left:30px">    <span class="color-blue">ELSE</span> default_result</p>
                                <p><span class="color-blue">END</span></p>
                                `
                            }}></div>
                        </div>
                    </li>
                </ul>
                <p>
                    Let's consider an <strong>`employees`</strong> table:
                </p>
                <table className='table' style={{border: '1px dashed #ccc'}}> 
                    <thead>
                        <tr style={{border: '1px dashed #ccc'}}>
                            <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>id</th>
                            <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>name</th>
                            <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>department</th>
                            <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>salary</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr style={{border: '1px dashed #ccc'}}>
                            <td style={{border: '1px dashed #ccc'}}>1</td>
                            <td style={{border: '1px dashed #ccc'}}>Jane Smith</td>
                            <td style={{border: '1px dashed #ccc'}}>IT</td>
                            <td style={{border: '1px dashed #ccc'}}>500000</td>
                        </tr>
                        <tr style={{border: '1px dashed #ccc'}}>
                            <td style={{border: '1px dashed #ccc'}}>2</td>
                            <td style={{border: '1px dashed #ccc'}}>John Doe</td>
                            <td style={{border: '1px dashed #ccc'}}>HR</td>
                            <td style={{border: '1px dashed #ccc'}}>350000</td>
                        </tr>
                        <tr style={{border: '1px dashed #ccc'}}>
                            <td style={{border: '1px dashed #ccc'}}>3</td>
                            <td style={{border: '1px dashed #ccc'}}>Johny Smith</td>
                            <td style={{border: '1px dashed #ccc'}}>IT</td>
                            <td style={{border: '1px dashed #ccc'}}>50000</td>
                        </tr>
                        <tr style={{border: '1px dashed #ccc'}}>
                            <td style={{border: '1px dashed #ccc'}}>4</td>
                            <td style={{border: '1px dashed #ccc'}}>Jeffer Joe</td>
                            <td style={{border: '1px dashed #ccc'}}>Finance</td>
                            <td style={{border: '1px dashed #ccc'}}>380000</td>
                        </tr>
                    </tbody>
                </table>
                <div className='mt-5 mb-5'>
                    <h5>Example of the Simple CASE Function</h5>
                    <div className='mt-3 mb-5'>
                        <p>
                            To categorize employees by department,
                        </p>
                        <div className='codePalateBox mt-2 mb-2'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-blue">SELECT</span> name, department,</p>
                                    <p style="margin-left:30px">    <span class="color-blue">CASE</span> department</p>
                                    <p style="margin-left:60px">        <span class="color-blue">WHEN</span> <span class="color-green">'IT'</span> <span class="color-blue">THEN</span> <span class="color-green">'Technical Department'</span></p>
                                    <p style="margin-left:60px">        <span class="color-blue">WHEN</span> <span class="color-green">'HR'</span> <span class="color-blue">THEN</span> <span class="color-green">'Human Resources'</span></p>
                                    <p style="margin-left:60px">        <span class="color-blue">WHEN</span> <span class="color-green">'Finance'</span> <span class="color-blue">THEN</span> <span class="color-green">'Financial Department'</span></p>
                                    <p style="margin-left:60px">        <span class="color-blue">ELSE</span> <span class="color-green">'Other Department'</span></p>
                                    <p style="margin-left:30px">    <span class="color-blue">END AS</span> department_category</p>
                                    <p><span class="color-blue">FROM</span> employees;</p>
                                `
                            }}></div>
                        </div>
                        <p>
                            In this query, 
                        </p>
                        <ul>
                            <li>Checks the value of the <strong>`department`</strong> column.</li>
                            <li>Returns a specific string based on the department's value.</li>
                            <li>Provides a default value with <strong>`ELSE`</strong> if none of the <strong>`WHEN`</strong> conditions match.</li>
                        </ul>
                        <p>
                            Output of the following -
                        </p>
                        <table className='table' style={{border: '1px dashed #ccc'}}> 
                            <thead>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>name</th>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>department</th>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>department_category</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>Jane Smith</td>
                                    <td style={{border: '1px dashed #ccc'}}>IT</td>
                                    <td style={{border: '1px dashed #ccc'}}>Technical Department</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>John Doe</td>
                                    <td style={{border: '1px dashed #ccc'}}>HR</td>
                                    <td style={{border: '1px dashed #ccc'}}>Human Resources</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>Johny Smith</td>
                                    <td style={{border: '1px dashed #ccc'}}>IT</td>
                                    <td style={{border: '1px dashed #ccc'}}>Technical Department</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>Jeffer Joe</td>
                                    <td style={{border: '1px dashed #ccc'}}>Finance</td>
                                    <td style={{border: '1px dashed #ccc'}}>Financial Department</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className='mt-5 mb-5'>
                    <h5>Example of the Searched CASE Function</h5>
                    <div className='mt-3 mb-5'>
                        <p>
                            To categorize employees based on salary ranges, you could use a searched CASE function:
                        </p>
                        <div className='codePalateBox mt-2 mb-2'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p><span class="color-blue">SELECT</span> name, department, salary,</p>
                                <p style="margin-left:30px">    <span class="color-blue">CASE</span></p>
                                <p style="margin-left:60px">        <span class="color-blue">WHEN</span> salary >= <span class="color-pink">75000</span> <span class="color-blue">THEN</span> <span class="color-green">'High'</span></p>
                                <p style="margin-left:60px">        <span class="color-blue">WHEN</span> salary <span class="color-blue">BETWEEN</span> <span class="color-pink">50000</span> <span class="color-blue">AND</span> <span class="color-pink">74999</span> <span class="color-blue">THEN</span> <span class="color-green">'Medium'</span></p>
                                <p style="margin-left:60px">        <span class="color-blue">ELSE</span> <span class="color-green">'Low'</span></p>
                                <p style="margin-left:30px">    <span class="color-blue">END AS</span> salary_category</p>
                                <p><span class="color-blue">FROM</span> employees;</p>
                                `
                            }}></div>
                        </div>
                        <p>
                            In this query, 
                        </p>
                        <ul>
                            <li>Evaluates multiple conditions.</li>
                            <li>Returns a result based on which condition is true.</li>
                            <li>The <strong>`ELSE`</strong> clause provides a default result if none of the conditions are met.</li>
                        </ul>
                        <p>
                            Output of the following -
                        </p>
                        <table className='table' style={{border: '1px dashed #ccc'}}> 
                            <thead>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>name</th>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>department</th>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>salary</th>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>salary_category</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>Jane Smith</td>
                                    <td style={{border: '1px dashed #ccc'}}>IT</td>
                                    <td style={{border: '1px dashed #ccc'}}>500000</td>
                                    <td style={{border: '1px dashed #ccc'}}>High</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>John Doe</td>
                                    <td style={{border: '1px dashed #ccc'}}>HR</td>
                                    <td style={{border: '1px dashed #ccc'}}>350000</td>
                                    <td style={{border: '1px dashed #ccc'}}>High</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>Johny Smith</td>
                                    <td style={{border: '1px dashed #ccc'}}>IT</td>
                                    <td style={{border: '1px dashed #ccc'}}>50000</td>
                                    <td style={{border: '1px dashed #ccc'}}>Medium</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>Jeffer Joe</td>
                                    <td style={{border: '1px dashed #ccc'}}>Finance</td>
                                    <td style={{border: '1px dashed #ccc'}}>380000</td>
                                    <td style={{border: '1px dashed #ccc'}}>High</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className='mt-5 mb-5'>
                    <h5>Benefits of Using the `CASE` Function</h5>
                    <div className='mt-3 mb-5'>
                        <ul>
                            <li>
                                <strong>Flexibility:</strong> Allows you to handle complex conditional logic directly within your SQL queries.
                            </li>
                            <li>
                                <strong>Readability:</strong> Makes your SQL code easier to understand by clearly defining different cases and their corresponding results.
                            </li>
                            <li>
                                <strong>Efficiency:</strong> Reduces the need for multiple separate queries or complex application logic to handle conditional data transformations.
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    )
}