import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/mysql/select-database";

export default function MysqlSelectDatabase() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Mysql - Select Database | Aspirant's Home");
        const urls = {
            'previous': '/mysql/drop-database',
            'next':'/mysql/show-database'
        }
        path.setPreviousNext(urls);
    },[])

    return (
        <section className='mt-5 mb-5'>
            <h3>Mysql - Select Database</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <strong>`USE`</strong> statement in MySQL allows you to select or switch to a specific database. Once you've set the current database using this statement, it remains the same for the duration of the session unless you change it. Here's the basic syntax:
                </p>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-blue">USE</span> database_name;</p>
                        `
                    }}></div>
                </div>
                <p>
                    For example, to select a database named <strong>`mydatabase`</strong>, you would use the following SQL statement:
                </p>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-blue">USE</span> mydatabase;</p>
                        `
                    }}></div>
                </div>
                <p>
                    That means, whenever selecting a database using the <strong>`USE`</strong> statement, all subsequent SQL queries will be executed in the context of <strong>`mydatabase`</strong> database until you select a different database or end your database session.
                </p>
            </div>
        </section>
    )
}