import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../../Context";
import { Link } from 'react-router-dom';
export const pageUrl = () => "/mysql/date-and-time-functions/time-to-sec";

export default function Now() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Mysql - Date and Time functions - TIME_TO_SEC() | Aspirant's Home");
        path.setPreviousNext({});
    }, [])

    return (
        <>
            <section class='clearfix mb-3'>
                <span className='float-start'>
                    <Link to='/mysql/date-and-time-functions' onClick={() => path.setPathName('/mysql/date-and-time-functions')} className='btn btn-primary'> <i class="fa-solid fa-less-than"></i>&nbsp; Back to Date and Time functions </Link>
                </span>
            </section>
            <section className='mt-5 mb-5'>
                <h3>Mysql - TIME_TO_SEC() Functions</h3>
                <div className='mt-4 mb-5'>
                    <p>
                        In MySQL, the <strong>`TIME_TO_SEC()`</strong> function is used to convert a time value into the number of seconds since midnight. It takes a time value as input and returns the total number of seconds represented by that time. Here's the basic syntax:
                    </p>
                    <div className='codePalateBox mt-2 mb-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                                <p>TIME_TO_SEC(time_value)</p>
                            `
                        }}></div>
                    </div>
                    <p>
                        Where, <strong>`time_value`</strong> is the time value you want to convert into seconds.
                    </p>
                    <p>
                        For example, if you have a time value '01:30:00' (1 hour, 30 minutes, 0 seconds) and you want to convert it into the total number of seconds, you would use:
                    </p>
                    <div className='codePalateBox mt-2 mb-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                                <p><span class="color-blue">SELECT</span> TIME_TO_SEC(<span class="color-green">'01:30:00'</span>);</p>
                            `
                        }}></div>
                    </div>
                    <p>
                        This would return 5400, indicating that '01:30:00' is equivalent to 5400 seconds.
                    </p>
                </div>
                <div className='mt-5 mb-5'>
                    <h5>TIME_TO_SEC() with MySQL Table</h5>
                    <div className='mt-4 mb-2'>
                        <p>
                            To explain the <strong>`TIME_TO_SEC()`</strong> function in MySQL with a table, let's consider a table <strong>`tasks`</strong> with a column <strong>`duration`</strong> representing the duration of each task in the format of <strong>`HH:MM:SS`</strong>. We want to convert these durations into total seconds using the <strong>`TIME_TO_SEC()`</strong> function. Here's an example:
                        </p>
                        <p>
                            Consider the <strong>`tasks`</strong> table:
                        </p>
                        <table className='table' style={{border: '1px dashed #ccc'}}> 
                            <thead>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>id</th>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>task_name</th>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>duration</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>1</td>
                                    <td style={{border: '1px dashed #ccc'}}>Task A</td>
                                    <td style={{border: '1px dashed #ccc'}}>01:30:00</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>2</td>
                                    <td style={{border: '1px dashed #ccc'}}>Task B</td>
                                    <td style={{border: '1px dashed #ccc'}}>00:45:30</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>3</td>
                                    <td style={{border: '1px dashed #ccc'}}>Task C</td>
                                    <td style={{border: '1px dashed #ccc'}}>02:15:00</td>
                                </tr>
                            </tbody>
                        </table>
                        <p>
                            To convert the duration column into total seconds, you would use:
                        </p>
                        <div className='codePalateBox mt-2 mb-2'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-blue">SELECT</span> id, task_name, duration, TIME_TO_SEC(duration) <span class="color-blue">AS</span> total_seconds <span class="color-blue">FROM</span> tasks;</p>
                                `
                            }}></div>
                        </div>
                        <p>
                            This query will return:
                        </p>
                        <table className='table' style={{border: '1px dashed #ccc'}}> 
                            <thead>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>id</th>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>task_name</th>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>duration</th>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>total_seconds</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>1</td>
                                    <td style={{border: '1px dashed #ccc'}}>Task A</td>
                                    <td style={{border: '1px dashed #ccc'}}>01:30:00</td>
                                    <td style={{border: '1px dashed #ccc'}}>5400</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>2</td>
                                    <td style={{border: '1px dashed #ccc'}}>Task B</td>
                                    <td style={{border: '1px dashed #ccc'}}>00:45:30</td>
                                    <td style={{border: '1px dashed #ccc'}}>2730</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>3</td>
                                    <td style={{border: '1px dashed #ccc'}}>Task C</td>
                                    <td style={{border: '1px dashed #ccc'}}>02:15:00</td>
                                    <td style={{border: '1px dashed #ccc'}}>8100</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </section>
        </>
    )
}