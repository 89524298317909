import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../../Context";
import { Link } from 'react-router-dom';
export const pageUrl = () => "/node-js/built-in-modules/os";

export default function OsModule() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("NodeJs - OS Module | Aspirant's Home");
        path.setPreviousNext({});
    }, [])

    return (
        <div className='contentPage'>
            <section class='clearfix mb-3'>
                <span className='float-start'>
                    <Link to='/node-js/built-in-modules' onClick={() => path.setPathName('/node-js/built-in-modules')} className='btn btn-primary'> <i class="fa-solid fa-less-than"></i>&nbsp; Back to Built-In Modules </Link>
                </span>
            </section>
            <section className='mt-5 mb-5'>
                <h3>Node Js Os Module</h3>
                <div className='mt-4'>
                    <p>The os module in Node.js provides a way to access information about the operating system on which the Node.js process is running. It allows you to retrieve information such as the operating system's hostname, architecture, and platform, as well as information about the system's memory, CPU, and network interfaces.</p>
                    <p>The syntax for including the os module in your application: </p>
                    <div className='codePalateBox mt-2 mb-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `<p><span class='color-blue'>var</span> os = <span class='color-yellow'>require</span>(<span class='color-green'>'os'</span>);</p>
                                    `
                        }}></div>
                    </div>
                </div>
                <div className='mt-4'>
                    <p>Here are the methods of os module - </p>
                    <p><strong>`os.platform()`</strong> -	To get the operating system platform (e.g., 'win32', 'linux', 'darwin' for macOS).</p>
                    <p><strong>`os.type()`</strong> -	To get the operating system name (e.g., 'Linux', 'Windows_NT', 'Darwin' for macOS).</p>
                    <p><strong>`os.cpus()`</strong> -	To get an array of objects containing information about each CPU core.</p>
                    <p><strong>`os.totalmem()`</strong> -	To get the total amount of system memory in bytes.</p>
                    <p><strong>`os.freemem()`</strong> -	To get the amount of free system memory in bytes.</p>
                    <p><strong>`os.networkInterfaces()`</strong> -	To get a list of network interfaces on the system, along with their IP addresses and other information.</p>
                    <p><strong>`os.userInfo()`</strong> -	To get information about the current user, such as username, UID, GID, home directory, and shell.</p>
                    <p><strong>`os.hostname()`</strong> -	To get the hostname of the operating system.</p>
                </div>
                <div className='mt-4'>
                    <h4>Examples</h4>
                    <p>Here's a basic example of how you might use the os module:</p>
                    <div className='codePalateBox mt-2 mb-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `<p><span class='color-blue'>const</span> os = <span class='color-yellow'>require</span>(<span class='color-green'>'os'</span>);</p>
                            <br />
                            <p><span class='color-pink'>console</span>.<span class='color-red'>log</span>(<span class='color-green'>'Platform:'</span>, os.<span class='color-red'>platform</span>());</p>
                            <p><span class='color-pink'>console</span>.<span class='color-red'>log</span>(<span class='color-green'>'Type:'</span>, os.<span class='color-red'>type</span>());</p>
                            <p><span class='color-pink'>console</span>.<span class='color-red'>log</span>(<span class='color-green'>'CPUs:'</span>, os.<span class='color-red'>cpus</span>().length);</p>
                            <p><span class='color-pink'>console</span>.<span class='color-red'>log</span>(<span class='color-green'>'Total Memory:'</span>, os.<span class='color-red'>totalmem</span>() / (1024 * 1024 * 1024), <span class='color-green'>'GB'</span>);</p>
                            <p><span class='color-pink'>console</span>.<span class='color-red'>log</span>(<span class='color-green'>'Free Memory:'</span>, os.<span class='color-red'>freemem</span>() / (1024 * 1024 * 1024), <span class='color-green'>'GB'</span>);</p>
                            <p><span class='color-pink'>console</span>.<span class='color-red'>log</span>(<span class='color-green'>'Network Interfaces:'</span>, os.<span class='color-red'>networkInterfaces</span>());</p>
                            <p><span class='color-pink'>console</span>.<span class='color-red'>log</span>(<span class='color-green'>'Hostname:'</span>, os.<span class='color-red'>hostname</span>());</p>
                            `
                        }}></div>
                    </div>
                </div>
            </section>
        </div>
    )
}
