import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../../Context";
import { Link } from 'react-router-dom';
export const pageUrl = () => "/mysql/string-functions/rpad";

export default function RPAD() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Mysql - String Functions - RPAD() | Aspirant's Home");
        path.setPreviousNext({});
    }, [])

    return (
        <>
            <section class='clearfix mb-3'>
                <span className='float-start'>
                    <Link to='/mysql/string-functions' onClick={() => path.setPathName('/mysql/string-functions')} className='btn btn-primary'> <i class="fa-solid fa-less-than"></i>&nbsp; Back to String Functions </Link>
                </span>
            </section>
            <section className='mt-5 mb-5'>
                <h3>Mysql - RPAD() Function</h3>
                <div className='mt-4 mb-5'>
                    <p>
                        The <strong>`RPAD()`</strong> function in MySQL is used to pad the right side of a string with a specified set of characters until the string reaches a desired length. This function is useful for formatting strings to a uniform length by adding characters to the left.
                    </p>
                    <h5>Syntax</h5>
                    <div className='codePalateBox mt-2 mb-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                                <p>RPAD(string, length, pad_string)</p>
                            `
                        }}></div>
                    </div>
                    <p>
                        Where, <strong>`string`</strong> is the original string to be padded. <strong>`length`</strong> is the total length of the resulting string after padding and <strong>`pad_string`</strong> is the string to pad the original string with.
                    </p>
                    <h5>Examples</h5>
                    <div className='codePalateBox mt-2 mb-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                                <p><span class="color-blue">SELECT</span> RPAD(<span class="color-green">'123'</span>, <span class="color-pink">6</span>, <span class="color-green">'0'</span>) <span class="color-blue">AS</span> padded_string;</p>
                            `
                        }}></div>
                    </div>
                    <p>
                        Output will be 123000. In this example, the string '123' is padded with '0' on the right to make the total length 6.
                    </p>
                </div>
                <div className='mt-5 mb-5'>
                    <h5>RPAD() with MySQL Table</h5>
                    <div className='mt-3 mb-2'>
                        <p>
                            Consider a table <strong>`products`</strong> with the following structure and data:
                        </p>
                        <table className='table' style={{border: '1px dashed #ccc'}}> 
                            <thead>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>id</th>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>product_code</th>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>price</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>1</td>
                                    <td style={{border: '1px dashed #ccc'}}>A1</td>
                                    <td style={{border: '1px dashed #ccc'}}>1000</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>2</td>
                                    <td style={{border: '1px dashed #ccc'}}>A2</td>
                                    <td style={{border: '1px dashed #ccc'}}>1800</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>3</td>
                                    <td style={{border: '1px dashed #ccc'}}>A3</td>
                                    <td style={{border: '1px dashed #ccc'}}>2300</td>
                                </tr>
                            </tbody>
                        </table>
                        <p>
                            If you want to add pad to the product codes to length 5, we can use <strong>`LPAD()`</strong> function.
                        </p>
                        <div className='codePalateBox mt-2 mb-2'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-blue">SELECT</span> id, product_code, LPAD(product_code, <span class="color-pink">5</span>, <span class="color-green">'0'</span>) <span class="color-blue">AS</span> padded_code <span class="color-blue">FROM</span> products;</p>
                                `
                            }}></div>
                        </div>
                        <p>
                            This query will return:
                        </p>
                        <table className='table' style={{border: '1px dashed #ccc'}}> 
                            <thead>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>id</th>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>product_code</th>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>padded_code</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>1</td>
                                    <td style={{border: '1px dashed #ccc'}}>A1</td>
                                    <td style={{border: '1px dashed #ccc'}}>A1000</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>2</td>
                                    <td style={{border: '1px dashed #ccc'}}>A2</td>
                                    <td style={{border: '1px dashed #ccc'}}>A2000</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>3</td>
                                    <td style={{border: '1px dashed #ccc'}}>A3</td>
                                    <td style={{border: '1px dashed #ccc'}}>A3000</td>
                                </tr>
                            </tbody>
                        </table>
                        <p>
                            In this example, each <strong>`product_code`</strong> is padded with '0' on the right to make the total length 5.
                        </p>
                    </div>
                </div>
            </section>
        </>
    )
}