import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";

export const pageUrl = () => "/html/ordered-lists";

export default function HTMLOrderedLists() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("HTML - Ordered Lists | Aspirant's Home");
        const urls = {
            'previous': '/html/unordered-lists',
            'next': '/html/definition-lists'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>HTML - Ordered Lists</h3>
            <div className='mt-4 mb-5'>
                <p>
                    An ordered list (<strong>`&lt;ol&gt;`</strong>) in HTML is used to display a list of items that are sequentially ordered. Each item in the list is numbered, and the numbering can be customized. The <strong>`&lt;ol&gt;`</strong> tag is used to define the ordered list, and each item within the list is defined using the <strong>`&lt;li&gt;`</strong> (list item) tag.
                </p>
                <h5 className='mt-5 mb-3'>Basic Structure</h5>
                <p>
                    Here's the basic structure of an ordered list:
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;ol&gt;</p>
                        <p class="ml-30">    &lt;li&gt;Item 1&lt;/li&gt;</p>
                        <p class="ml-30">    &lt;li&gt;Item 2&lt;/li&gt;</p>
                        <p class="ml-30">    &lt;li&gt;Item 3&lt;/li&gt;</p>
                        <p>&lt;/ol&gt;</p>
                        `
                    }}></div>
                </div>
                <h5 className='mt-5 mb-3'>Example</h5>
                <p>
                    Here's a simple example of an ordered list:
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;!DOCTYPE html&gt;</p>
                        <p>&lt;html&gt;</p>
                        <p class="ml-30">    &lt;head&gt;</p>
                        <p class="ml-60">        &lt;title&gt;Ordered List Example&lt;/title&gt;</p>
                        <p class="ml-30">    &lt;/head&gt;</p>
                        <p class="ml-30">    &lt;body&gt;</p>
                        <p class="ml-60">        &lt;ol&gt;</p>
                        <p class="ml-90">            &lt;li&gt;Step 1&lt;/li&gt;</p>
                        <p class="ml-90">            &lt;li&gt;Step 2&lt;/li&gt;</p>
                        <p class="ml-90">            &lt;li&gt;Step 3&lt;/li&gt;</p>
                        <p class="ml-60">        &lt;/ol&gt;</p>
                        <p class="ml-30">    &lt;/body&gt;</p>
                        <p>&lt;/html&gt;</p>
                        `
                    }}></div>
                </div>
                <h5 className='mt-5 mb-3'>Attributes for <strong>`&lt;ol&gt;`</strong></h5>
                <h6 className='mt-3 mb-3'>`type` Attribute</h6>
                <p>
                    The <strong>`type`</strong> attribute specifies the type of numbering to use for the list items. Possible values include:
                </p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><strong>`1`</strong>: Default. Decimal numbers (1, 2, 3, ...)</li>
                    <li><strong>`A`</strong>: Uppercase letters (A, B, C, ...)</li>
                    <li><strong>`a`</strong>: Lowercase letters (a, b, c, ...)</li>
                    <li><strong>`I`</strong>: Uppercase Roman numerals (I, II, III, ...)</li>
                    <li><strong>`i`</strong>: Lowercase Roman numerals (i, ii, iii, ...)</li>
                </ul>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-grey">&lt;!DOCTYPE</span> <span class="color-blue">html</span><span class="color-grey">&gt;</span></p>
                        <p>&lt;html&gt;</p>
                        <p class="ml-30">    &lt;head&gt;</p>
                        <p class="ml-60">        &lt;title&gt;Ordered List with Type&lt;/title&gt;</p>
                        <p class="ml-30">    &lt;/head&gt;</p>
                        <p class="ml-30">    &lt;body&gt;</p>
                        <p class="ml-60">        &lt;ol <span class="color-pink">type</span>=<span class="color-green">"1"</span>&gt;</p>
                        <p class="ml-90">            &lt;li&gt;Item 1&lt;/li&gt;</p>
                        <p class="ml-90">            &lt;li&gt;Item 2&lt;/li&gt;</p>
                        <p class="ml-90">            &lt;li&gt;Item 3&lt;/li&gt;</p>
                        <p class="ml-60">        &lt;/ol&gt;</p>
                        <p class="ml-60">        &lt;ol <span class="color-pink">type</span>=<span class="color-green">"A"</span>&gt;</p>
                        <p class="ml-90">            &lt;li&gt;Item 1&lt;/li&gt;</p>
                        <p class="ml-90">            &lt;li&gt;Item 2&lt;/li&gt;</p>
                        <p class="ml-90">            &lt;li&gt;Item 3&lt;/li&gt;</p>
                        <p class="ml-60">        &lt;/ol&gt;</p>
                        <p class="ml-60">        &lt;ol <span class="color-pink">type</span>=<span class="color-green">"a"</span>&gt;</p>
                        <p class="ml-90">            &lt;li&gt;Item 1&lt;/li&gt;</p>
                        <p class="ml-90">            &lt;li&gt;Item 2&lt;/li&gt;</p>
                        <p class="ml-90">            &lt;li&gt;Item 3&lt;/li&gt;</p>
                        <p class="ml-60">        &lt;/ol&gt;</p>
                        <p class="ml-60">        &lt;ol <span class="color-pink">type</span>=<span class="color-green">"I"</span>&gt;</p>
                        <p class="ml-90">            &lt;li&gt;Item 1&lt;/li&gt;</p>
                        <p class="ml-90">            &lt;li&gt;Item 2&lt;/li&gt;</p>
                        <p class="ml-90">            &lt;li&gt;Item 3&lt;/li&gt;</p>
                        <p class="ml-60">        &lt;/ol&gt;</p>
                        <p class="ml-60">        &lt;ol <span class="color-pink">type</span>=<span class="color-green">"i"</span>&gt;</p>
                        <p class="ml-90">            &lt;li&gt;Item 1&lt;/li&gt;</p>
                        <p class="ml-90">            &lt;li&gt;Item 2&lt;/li&gt;</p>
                        <p class="ml-90">            &lt;li&gt;Item 3&lt;/li&gt;</p>
                        <p class="ml-60">        &lt;/ol&gt;</p>
                        <p class="ml-30">    &lt;/body&gt;</p>
                        <p>&lt;/html&gt;</p>
                        `
                    }}></div>
                </div>
                <p>
                    Output of the following -
                </p>
                <div className='outputPalateBox mb-4'>
                    <div className='outputPalate' dangerouslySetInnerHTML={{
                        __html: `
                        <!DOCTYPE html>
                        <html>
                        <head>
                          <title>Ordered List with Type</title>
                        </head>
                        <body>
                          <ol type="1">
                            <li>Item 1</li>
                            <li>Item 2</li>
                            <li>Item 3</li>
                          </ol>
                          <ol type="A">
                            <li>Item 1</li>
                            <li>Item 2</li>
                            <li>Item 3</li>
                          </ol>
                          <ol type="a">
                            <li>Item 1</li>
                            <li>Item 2</li>
                            <li>Item 3</li>
                          </ol>
                          <ol type="I">
                            <li>Item 1</li>
                            <li>Item 2</li>
                            <li>Item 3</li>
                          </ol>
                          <ol type="i">
                            <li>Item 1</li>
                            <li>Item 2</li>
                            <li>Item 3</li>
                          </ol>
                        </body>
                        </html>
                        
                        `
                    }}></div>
                </div>
                <h6 className='mt-3 mb-3'>`start` Attribute</h6>
                <p>
                    The <strong>`start`</strong> attribute specifies the starting number of the list.
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-grey">&lt;!DOCTYPE</span> <span class="color-blue">html</span><span class="color-grey">&gt;</span></p>
                        <p>&lt;html&gt;</p>
                        <p class="ml-30">    &lt;head&gt;</p>
                        <p class="ml-60">        &lt;title&gt;Ordered List with Start&lt;/title&gt;</p>
                        <p class="ml-30">    &lt;/head&gt;</p>
                        <p class="ml-30">    &lt;body&gt;</p>
                        <p class="ml-60">        &lt;ol <span class="color-pink">start</span>=<span class="color-green">"5"</span>&gt;</p>
                        <p class="ml-90">            &lt;li&gt;Item 5&lt;/li&gt;</p>
                        <p class="ml-90">            &lt;li&gt;Item 6&lt;/li&gt;</p>
                        <p class="ml-90">            &lt;li&gt;Item 7&lt;/li&gt;</p>
                        <p class="ml-60">        &lt;/ol&gt;</p>
                        <p class="ml-30">    &lt;/body&gt;</p>
                        <p>&lt;/html&gt;</p>
                        
                        `
                    }}></div>
                </div>
                <p>
                    Output of the following -
                </p>
                <div className='outputPalateBox mb-4'>
                    <div className='outputPalate' dangerouslySetInnerHTML={{
                        __html: `
                        <!DOCTYPE html>
                        <html>
                            <head>
                                <title>Ordered List with Start</title>
                            </head>
                            <body>
                                <ol start="5">
                                    <li>Item 5</li>
                                    <li>Item 6</li>
                                    <li>Item 7</li>
                                </ol>
                            </body>
                        </html>
                        `
                    }}></div>
                </div>
                <h6 className='mt-3 mb-3'>`reversed` Attribute</h6>
                <p>
                    The <strong>`reversed`</strong> attribute displays the list in descending order.
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-grey">&lt;!DOCTYPE</span> <span class="color-blue">html</span><span class="color-grey">&gt;</span></p>
                        <p>&lt;html&gt;</p>
                        <p class="ml-30">    &lt;head&gt;</p>
                        <p class="ml-60">        &lt;title&gt;Reversed Ordered List&lt;/title&gt;</p>
                        <p class="ml-30">    &lt;/head&gt;</p>
                        <p class="ml-30">    &lt;body&gt;</p>
                        <p class="ml-60">        &lt;ol <span class="color-pink">reversed</span>&gt;</p>
                        <p class="ml-90">            &lt;li&gt;Item 3&lt;/li&gt;</p>
                        <p class="ml-90">            &lt;li&gt;Item 2&lt;/li&gt;</p>
                        <p class="ml-90">            &lt;li&gt;Item 1&lt;/li&gt;</p>
                        <p class="ml-60">        &lt;/ol&gt;</p>
                        <p class="ml-30">    &lt;/body&gt;</p>
                        <p>&lt;/html&gt;</p>
                        `
                    }}></div>
                </div>
                <p>
                    Output of the following -
                </p>
                <div className='outputPalateBox mb-4'>
                    <div className='outputPalate' dangerouslySetInnerHTML={{
                        __html: `
                            <!DOCTYPE html>
                            <html>
                            <head>
                            <title>Reversed Ordered List</title>
                            </head>
                            <body>
                            <ol reversed>
                                <li>Item 3</li>
                                <li>Item 2</li>
                                <li>Item 1</li>
                            </ol>
                            </body>
                            </html>
                        `
                    }}></div>
                </div>
                <h5 className='mt-5 mb-3'>Nesting Ordered Lists</h5>
                <p>You can nest ordered lists inside other lists to create sub-lists.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-grey">&lt;!DOCTYPE</span> <span class="color-blue">html</span><span class="color-grey">&gt;</span></p>
                        <p>&lt;html&gt;</p>
                        <p class="ml-30">    &lt;head&gt;</p>
                        <p class="ml-60">        &lt;title&gt;Nesting Ordered Lists&lt;/title&gt;</p>
                        <p class="ml-30">    &lt;/head&gt;</p>
                        <p class="ml-30">    &lt;body&gt;</p>
                        <p class="ml-60">        &lt;ol&gt;</p>
                        <p class="ml-90">            &lt;li&gt;Step 1&lt;/li&gt;</p>
                        <p class="ml-90">            &lt;li&gt;Step 2</p>
                        <p class="ml-120">                &lt;ol&gt;</p>
                        <p class="ml-150">                    &lt;li&gt;Sub-step 2.1&lt;/li&gt;</p>
                        <p class="ml-150">                    &lt;li&gt;Sub-step 2.2&lt;/li&gt;</p>
                        <p class="ml-120">                &lt;/ol&gt;</p>
                        <p class="ml-90">            &lt;/li&gt;</p>
                        <p class="ml-90">            &lt;li&gt;Step 3&lt;/li&gt;</p>
                        <p class="ml-60">        &lt;/ol&gt;</p>
                        <p class="ml-30">    &lt;/body&gt;</p>
                        <p>&lt;/html&gt;</p>
                        `
                    }}></div>
                </div>
                <p>
                    Output of the following -
                </p>
                <div className='outputPalateBox mb-4'>
                    <div className='outputPalate' dangerouslySetInnerHTML={{
                        __html: `
                        <!DOCTYPE html>
                        <html>
                            <head>
                                <title>Nesting Ordered Lists</title>
                            </head>
                            <body>
                                <ol>
                                    <li>Step 1</li>
                                    <li>Step 2
                                        <ol>
                                            <li>Sub-step 2.1</li>
                                            <li>Sub-step 2.2</li>
                                        </ol>
                                    </li>
                                    <li>Step 3</li>
                                </ol>
                            </body>
                        </html>
                        `
                    }}></div>
                </div>
                <h5 className='mt-5 mb-3'>Styling Ordered Lists with CSS</h5>
                <p>
                    You can style ordered lists using CSS to change the numbering style, padding, margins, and more.
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-grey">&lt;!DOCTYPE</span> <span class="color-blue">html</span><span class="color-grey">&gt;</span></p>
                        <p>&lt;html&gt;</p>
                        <p class="ml-30">    &lt;head&gt;</p>
                        <p class="ml-60">        &lt;style&gt;</p>
                        <p class="ml-90">            ol.<span class="color-pink">custom-list</span> {</p>
                        <p class="ml-120">                <span class="color-green">list-style-type</span>: upper-roman; <span class="color-grey">/* Custom numbering style */</span></p>
                        <p class="ml-120">                <span class="color-green">padding-left</span>: 20px;           <span class="color-grey">/* Indentation */</span></p>
                        <p class="ml-90">            }</p>
                        <p class="ml-90">            ol.<span class="color-pink">custom-list</span> li {</p>
                        <p class="ml-120">                <span class="color-green">margin</span>: 10px 0;               <span class="color-grey">/* Spacing between items */</span></p>
                        <p class="ml-90">            }</p>
                        <p class="ml-60">        &lt;/style&gt;</p>
                        <p class="ml-30">    &lt;/head&gt;</p>
                        <p class="ml-30">    &lt;body&gt;</p>
                        <p class="ml-60">        &lt;ol <span class="color-pink">class</span>=<span class="color-green">"custom-list"</span>&gt;</p>
                        <p class="ml-90">            &lt;li&gt;First item&lt;/li&gt;</p>
                        <p class="ml-90">            &lt;li&gt;Second item&lt;/li&gt;</p>
                        <p class="ml-90">            &lt;li&gt;Third item&lt;/li&gt;</p>
                        <p class="ml-60">       &lt;/ol&gt;</p>
                        <p class="ml-30">    &lt;/body&gt;</p>
                        <p>&lt;/html&gt;</p>
                        
                        `
                    }}></div>
                </div>
                <p>
                    Output of the following -
                </p>
                <div className='outputPalateBox mb-4'>
                    <div className='outputPalate' dangerouslySetInnerHTML={{
                        __html: `
                        <!DOCTYPE html>
                        <html>
                            <head>
                                <style>
                                    ol.custom-list1 {
                                        list-style-type: upper-roman; /* Custom numbering style */
                                        padding-left: 20px;           /* Indentation */
                                    }
                                    ol.custom-list1 li {
                                        margin: 10px 0;               /* Spacing between items */
                                    }
                                </style>
                            </head>
                            <body>
                                <ol class="custom-list1">
                                    <li>First item</li>
                                    <li>Second item</li>
                                    <li>Third item</li>
                                </ol>
                            </body>
                        </html>
                        `
                    }}></div>
                </div>
                <p className='mt-4 featureClass'>
                    By understanding and using ordered lists properly, you can create well-structured and visually appealing lists in your HTML documents.
                </p>
            </div>
        </section>
    )
}