import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/react-js/redux/create-a-store";

export default function ReduxCreateStore() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("React - Redux - Creating a Store | Aspirant's Home");
        const urls = {
            'previous': '/react-js/redux/data-flow',
            'next': '/react-js/redux/dispatching-an-action'
        }
        path.setPreviousNext(urls);
    },[])

    return (
        <section className='mt-5 mb-5'>
            <h3>Creating a Store In Redux</h3>
            <div className='mt-4 mb-4'>
                <p>
                    A store in Redux is a state container that holds the application's state as an immutable object tree. Redux allows for only a single store in your application. When creating a store in Redux, you must specify the reducer function, which describes how state should be updated in response to actions.
                </p>
                <p>
                    To create a store you have to use <strong>`createStore`</strong> function from <strong>`redux`</strong>. You also need to define a reducer function to manage the state. Create a <strong>`store.js`</strong>
                </p>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-blue">import</span> { <span class="color-red">createStore</span> } <span class="color-blue">from</span> <span class="color-green">'redux'</span>;</p>
                            <p><span class="color-blue">import</span> rootReducer <span class="color-blue">from</span> <span class="color-green">'./reducers'</span>; <span class="color-grey">// Assuming you have a root reducer</span></p>
                            <br />
                            <p><span class="color-blue">const</span> store = <span class="color-red">createStore</span>(rootReducer);</p>
                            <br />
                            <p><span class="color-blue">export default</span> <span class="color-red">store</span>;</p>
                        `
                    }}></div>
                </div>
                <p>
                    We creates a Redux store using the <strong>`createStore`</strong> function and the root reducer. The store variable now holds the Redux store, which contains the entire state tree of your application.
                </p>
                <p>
                    Let's see how we create an reducer for our <strong>`createStore`</strong> function.
                </p>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-grey">// Define an initial state</span></p>
                            <p><span class="color-blue">const</span> initialState = {</p>
                            <p style="margin-left:30px">    <span class="color-pink">count</span>: 0,</p>
                            <p>};</p>
                            <br />
                            <p><span class="color-grey">// Define a reducer function</span></p>
                            <p><span class="color-blue">const</span> <span class="color-red">counterReducer</span> = (state = initialState, action) => {</p>
                            <p style="margin-left:30px">    <span class="color-blue">switch</span> (action.type) {</p>
                            <p style="margin-left:60px">        <span class="color-blue">case</span> <span class="color-green">'INCREMENT'</span>:</p>
                            <p style="margin-left:90px">            <span class="color-blue">return</span> {</p>
                            <p style="margin-left:120px">                ...state,</p>
                            <p style="margin-left:120px">                <span class="color-pink">count</span>: state.count + <span class="color-pink">1</span>,</p>
                            <p style="margin-left:90px">            };</p>
                            <p style="margin-left:60px">        <span class="color-blue">case</span> <span class="color-green">'DECREMENT'</span>:</p>
                            <p style="margin-left:90px">            <span class="color-blue">return</span> {</p>
                            <p style="margin-left:120px">                ...state,</p>
                            <p style="margin-left:120px">                <span class="color-pink">count</span>: state.count - <span class="color-pink">1</span>,</p>
                            <p style="margin-left:90px">            };</p>
                            <p style="margin-left:60px">       <span class="color-blue">default</span>:</p>
                            <p style="margin-left:90px">            <span class="color-blue">return</span> state;</p>
                            <p style="margin-left:30px">    }</p>
                            <p>};</p>
                            <br />
                            <p><span class="color-blue">export default</span> <span class="color-red">counterReducer</span>;</p>
                        `
                    }}></div>
                </div>
                <p>
                    Now use the <strong>`Provider`</strong> component from <strong>`react-redux`</strong> in your root index.js to provide the Redux store to your React application.
                </p>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-grey">// src/index.js</span></p>
                            <p><span class="color-blue">import</span> <span class="color-red">React</span> <span class="color-blue">from</span> <span class="color-green">'react'</span>;</p>
                            <p><span class="color-blue">import</span> <span class="color-red">ReactDOM</span> <span class="color-blue">from</span> <span class="color-green">'react-dom'</span>;</p>
                            <p><span class="color-blue">import</span> { <span class="color-red">Provider</span> } <span class="color-blue">from</span> <span class="color-green">'react-redux'</span>;</p>
                            <p><span class="color-blue">import</span> <span class="color-red">store</span> <span class="color-blue">from</span> <span class="color-green">'./store'</span>; <span class="color-grey">// Import your Redux store</span></p>
                            <p><span class="color-blue">import</span> <span class="color-red">App</span> <span class="color-blue">from</span> <span class="color-green">'./App'</span>;</p>
                            <br />
                            <p><span class="color-red">ReactDOM</span>.<span class="color-red">render</span>(</p>
                            <p style="margin-left:30px">    &lt;Provider <span class="color-pink">store</span>=<span class="color-green">{store}</span>&gt;</p>
                            <p style="margin-left:60px">        &lt;App /&gt;</p>
                            <p style="margin-left:30px">    &lt;/Provider&gt;,</p>
                            <p style="margin-left:30px">    <span class="color-pink">document</span>.<span class="color-red">getElementById</span>(<span class="color-green">'root'</span>)</p>
                            <p>);</p>
                        `
                    }}></div>
                </div>
            </div>
        </section>
    )
}