import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/mongo-db/data-types";

export default function MongoDataTypes() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Data Types in MongoDB | A complete List");
        title.setKeyWords("data types in mongodb, different data types in mongodb, bson data types in mongodb, collection data types in mongodb, data types in mongodb schema, all data types in mongodb, data types array in mongodb, what are the data types in mongodb, what are different types of data models in mongodb explain, what are the data types in mongodb, data types in mongodb with examples, does mongodb have data types, data types supported by mongodb, mongodb types of data");
        title.setPageDescription("MongoDB is a flexible, document-oriented database that stores data in BSON (Binary JSON) format. BSON supports a variety of data types, allowing for complex data structures and easy integration with many programming languages. Below are some of the common data types in MongoDB");
        const urls = {
            'previous': '/mongo-db/drop-collection',
            'next': '/mongo-db/insert-document'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>MongoDB - Data Types</h3>
            <div className='mt-4 mb-5'>
                <p>
                    MongoDB is a flexible, document-oriented database that stores data in BSON (Binary JSON) format. BSON supports a variety of data types, allowing for complex data structures and easy integration with many programming languages. Below are some of the common data types in MongoDB:
                </p>
                <ul style={{listStyle:'disc'}}>
                    <li>
                        <strong>String</strong> : Used to store text data.
                        <p>
                            Example : <strong>`"name": "John Doe"`</strong>
                        </p>
                    </li>
                    <li>
                        <strong>Integer</strong> : Used to store numeric values.
                        <p>
                            Example : <strong>`"age": 30`</strong>
                        </p>
                    </li>
                    <li>
                        <strong>Double</strong> : Used to store floating-point numbers.
                        <p>
                            Example : <strong>`"height": 5.9`</strong>
                        </p>
                    </li>
                    <li>
                        <strong>Boolean</strong> : Used to store <strong>`true`</strong> or <strong>`false`</strong> values.
                        <p>
                            Example : <strong>`"isActive": true`</strong>
                        </p>
                    </li>
                    <li>
                        <strong>Date</strong> : Used to store dates in a special format.
                        <p>
                            Example : <strong>`"birthdate": ISODate("1990-01-01T00:00:00Z")`</strong>
                        </p>
                    </li>
                    <li>
                        <strong>Array</strong> : Used to store lists of values.
                        <p>
                            Example : <strong>`"hobbies": ["reading", "traveling", "swimming"]`</strong>
                        </p>
                    </li>
                    <li>
                        <strong>Embedded Document (Object)</strong> : Used to store nested documents.
                        <p>
                            Example :
                        </p>
                        <div className='codePalateBox mt-2 mb-2'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-pink">"address"</span>: {</p>
                                    <p style="margin-left:30px">    <span class="color-pink">"street"</span>: <span class="color-green">"123 Main St"</span>,</p>
                                    <p style="margin-left:30px">    <span class="color-pink">"city"</span>: <span class="color-green">"Anytown"</span>,</p>
                                    <p style="margin-left:30px">    <span class="color-pink">"state"</span>: <span class="color-green">"CA"</span></p>
                                    <p>}</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <strong>ObjectId</strong> : Used to store unique identifiers for documents. It is a 12-byte identifier.
                        <p>
                            Example : <strong>`"id": ObjectId("507f1f77bcf86cd799439011")`</strong>
                        </p>
                    </li>
                    <li>
                        <strong>Binary Data</strong> : Used to store binary data.
                        <p>
                            Example : <strong>`"file": BinData(0, "binary data")`</strong>
                        </p>
                    </li>
                    <li>
                        <strong>Regular Expression</strong> : Used to store regular expressions.
                        <p>
                            Example : <strong>`"pattern": /abc/`</strong>
                        </p>
                    </li>
                    <li>
                        <strong>Null</strong> : Used to store a <strong>`null`</strong> value.
                        <p>
                            Example : <strong>`"middleName": null`</strong>
                        </p>
                    </li>
                    <li>
                        <strong>Symbol</strong> : Similar to a string, but generally reserved for languages that support symbols.
                        <p>
                            Example : <strong>`"symbol": Symbol("foo")`</strong>
                        </p>
                    </li>
                    <li>
                        <strong>Code</strong> : Used to store JavaScript code.
                        <p>
                            Example : <strong>`"code": Code("function() &#123; return true; &#125;")`</strong>
                        </p>
                    </li>
                    <li>
                        <strong>Decimal128</strong> : Used to store high-precision decimal values.
                        <p>
                            Example : <strong>`"preciseNumber": Decimal128("123.456")`</strong>
                        </p>
                    </li>
                    <li>
                        <strong>MinKey</strong> : A special type which compares lower than all other possible BSON element values.
                        <p>
                            Example : <strong>`"minKey": MinKey()`</strong>
                        </p>
                    </li>
                    <li>
                        <strong>MaxKey</strong> : A special type which compares higher than all other possible BSON element values.
                        <p>
                            Example : <strong>`"maxKey": MaxKey()`</strong>
                        </p>
                    </li>
                </ul>
            </div>
        </section>
    )
}