import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/html/iframes";

export default function HTMLIframes() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("HTML - Iframes | Aspirant's Home");
        const urls = {
            'previous': '/html/page-title',
            'next': '/html/javascripts'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>HTML - Iframes</h3>
            <div className='mt-4 mb-5'>
                <p>
                    An HTML <strong>`&lt;iframe&gt;`</strong> (short for inline frame) is an HTML element that allows you to embed another HTML document within the current document. This can be useful for embedding content such as videos, maps, or other web pages within your page.
                </p>
                <p>
                    An HTML iframe is used to display a web page within a web page. You can see in the below example -
                </p>
                <div className='' style={{'border':'3px solid #CCC'}}>
                    <iframe src="https://aspirantshome.com/html/introduction" width="100%" height="400"></iframe>
                </div>
                <h5 className='mt-5 mb-3'>Basic Syntax</h5>
                <p>
                    The basic syntax of an <strong>`&lt;iframe&gt;`</strong> element is as follows:
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>&lt;iframe <span class="color-pink">src</span>=<span class="color-green">"URL"</span> <span class="color-pink">width</span>=<span class="color-green">"width"</span> <span class="color-pink">height</span>=<span class="color-green">"height"</span>&gt;&lt;/iframe&gt;</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><strong>`src`</strong>: Specifies the URL of the page to embed.</li>
                    <li><strong>`width`</strong>: Specifies the width of the iframe (in pixels or percentage).</li>
                    <li><strong>`height`</strong>: Specifies the height of the iframe (in pixels or percentage).</li>
                </ul>
                <h5 className='mt-5 mb-3'>Attributes</h5>
                <p>
                    The <strong>`&lt;iframe&gt;`</strong> element supports several attributes that control its behavior and appearance:
                </p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><strong>`src`</strong>: URL of the page to embed.</li>
                    <li><strong>`srcdoc`</strong>: Inline HTML content to display if src is not specified.</li>
                    <li><strong>`name`</strong>: Specifies the name of the iframe, which can be used as a target for links and forms.</li>
                    <li><strong>`width`</strong>: Width of the iframe.</li>
                    <li><strong>`height`</strong>: Height of the iframe.</li>
                    <li><strong>`frameborder`</strong>: Specifies whether or not to display a border around the iframe (deprecated in HTML5; use CSS instead).</li>
                    <li><strong>`allow`</strong>: Specifies a feature policy for the iframe.</li>
                    <li><strong>`allowfullscreen`</strong>: Allows the iframe to be displayed in fullscreen mode.</li>
                    <li><strong>`loading`</strong>: Specifies whether the iframe should be loaded immediately or delayed (lazy).</li>
                    <li><strong>`sandbox`</strong>: Applies extra restrictions to the content in the iframe.</li>
                </ul>

                <h5 className='mt-5 mb-3'>Iframe - Target link</h5>
                <p>
                    An iframe can be designated as the target frame for a hyperlink. The link's target attribute must correspond to the iframe's name attribute:
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>&lt;iframe <span class="color-pink">src</span>=<span class="color-green">"demo_iframe.htm"</span> <span class="color-pink">name</span>=<span class="color-green">"iframe_a"</span> <span class="color-pink">title</span>=<span class="color-green">"Iframe Example"</span>&gt;&lt;/iframe&gt;</p>
                            <p>&lt;p&gt;&lt;a <span class="color-pink">href</span>=<span class="color-green">"https://aspirantshome.com/"</span> <span class="color-pink">target</span>=<span class="color-green">"iframe_a"</span>&gt;Aspirant's Home&lt;/a&gt;</p>
                        `
                    }}></div>
                </div>
                <p>
                    Output of the following -
                </p>
                <div className='outputPalateBox mb-4'>
                    <div className='outputPalate' dangerouslySetInnerHTML={{
                        __html: `
                            <!DOCTYPE html>
                            <html>
                            <body>
                            
                            <h2>Iframe - Target for a Link</h2>
                            
                            <iframe src="https://www.lipsum.com" name="iframe_a" height="300px" width="100%" title="Iframe Example"></iframe>
                            
                            <p><a href="https://aspirantshome.com/" target="iframe_a">Aspirant's Home</a></p>
                            
                            <p>When the target attribute of a link matches the name of an iframe, the link will open in the iframe.</p>
                            
                            </body>
                            </html>
                        `
                    }}></div>
                </div>
                <h5 className='mt-5 mb-3'>Security Considerations</h5>
                <p>
                    Using iframes can introduce security risks, such as clickjacking and cross-site scripting (XSS). Here are some ways to mitigate these risks:
                </p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>
                        <strong>Sandboxing</strong>: The sandbox attribute adds an extra layer of security by restricting the iframe's capabilities.
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p>&lt;iframe <span class="color-pink">src</span>=<span class="color-green">"example.html"</span> <span class="color-pink">sandbox</span>&gt;&lt;/iframe&gt;
                                `
                            }}></div>
                        </div>
                        <p>You can specify particular features to allow:</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p>&lt;iframe <span class="color-pink">src</span>=<span class="color-green">"example.html"</span> <span class="color-pink">sandbox</span>=<span class="color-green">"allow-scripts allow-same-origin"</span>&gt;&lt;/iframe&gt;
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <strong>Content Security Policy (CSP)</strong>: Use CSP to restrict the sources of content that can be loaded in an iframe.
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p>&lt;meta <span class="color-pink">http-equiv</span>=<span class="color-green">"Content-Security-Policy"</span> <span class="color-pink">content</span>=<span class="color-green">"default-src 'self'; frame-src 'self' example.com;"</span>&gt;</p>
                                `
                            }}></div>
                        </div>
                    </li>
                </ul>
                <div className='mt-4 featureClass'>
                    <h5>Summary</h5>
                    <ul style={{ listStyle: 'disc' }}>
                        <li>The HTML <strong>`&lt;iframe&gt;`</strong> tag defines an inline frame.</li>
                        <li>The <strong>`src`</strong> attribute specifies the URL of the page to embed.</li>
                        <li>Always include a <strong>`title`</strong> attribute for accessibility and screen readers.</li>
                        <li>The <strong>`height`</strong> and <strong>`width`</strong> attributes set the dimensions of the iframe.</li>
                        <li>Use <strong>`border: none;`</strong> to remove the border around the iframe.</li>
                        <li>Key attributes include <strong>`src`</strong>, <strong>`width`</strong>, <strong>`height`</strong>, <strong>`frameborder`</strong>, <strong>`allow`</strong>, <strong>`allowfullscreen`</strong>, <strong>`loading`</strong>, and <strong>`sandbox`</strong>.</li>
                        <li>Use <strong>`sandbox`</strong> and Content Security Policy (CSP) to enhance security.</li>
                        <li>Common use cases include embedding videos, maps, and other web pages.</li>
                    </ul>
                </div>
            </div>
        </section>
    )
}