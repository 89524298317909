import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/css/outline/shorthand";

export default function OutlineShorthand() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Outline Shorthand in CSS | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/css/outline/color',
            'next': '/css/outline/offset'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>Outline Shorthand</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <strong>`outline`</strong> shorthand property in CSS is a convenient way to set all the individual outline properties (<strong>`outline-width`</strong>, <strong>`outline-style`</strong>, and <strong>`outline-color`</strong>) in a single declaration. This property is used to create an outline around an element, which is a line drawn outside the border edge.
                </p>
                <p>
                    It has a basic syntax, you have to follow the order.
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>element {</p>
                            <p class="ml-30">    <span class="color-green">outline</span>: [outline-width] [outline-style] [outline-color];</p>
                            <p>}</p>
                        `
                    }}></div>
                </div>
                <p>Where, </p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><strong>`outline-width`</strong>: Specifies the thickness of the outline (e.g., <strong>`thin`</strong>, <strong>`medium`</strong>, <strong>`thick`</strong>, or a specific length like <strong>`2px`</strong>).</li>
                    <li><strong>`outline-style`</strong>: Specifies the style of the outline (e.g., <strong>`solid`</strong>, <strong>`dashed`</strong>, <strong>`dotted`</strong>).</li>
                    <li><strong>`outline-color`</strong>: Specifies the color of the outline (e.g., <strong>`red`</strong>, <strong>`#ff0000`</strong>, <strong>`rgb(255, 0, 0))`</strong>.</li>
                </ul>

                <p>
                    If any of the individual properties are omitted, their default values will be used. Default value of <strong>`outline-width`</strong> is <strong>`medium`</strong>. Default value of <strong>`outline-style`</strong> is <strong>`none`</strong>. Default value of <strong>`outline-color`</strong> is the current color of the element (usually the <strong>`color`</strong> property of the text).
                </p>

                <h5 className="mt-5 mb-3">Examples of Outline</h5>
                <p>
                    Here are the examples of some outline.
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;style&gt;</p>
                        <p class="ml-30">    p.ex1 {<span class="color-green">outline</span>: dashed;}</p>
                        <p class="ml-30">    p.ex2 {<span class="color-green">outline</span>: dotted red;}</p>
                        <p class="ml-30">    p.ex3 {<span class="color-green">outline</span>: <span class="color-pink">5px</span> solid yellow;}</p>
                        <p class="ml-30">    p.ex4 {<span class="color-green">outline</span>: thick ridge pink;}</p>
                        <p>&lt;/style&gt;</p>
                        <p>&lt;p <span class="color-pink">class</span>=<span class="color-green">"ex1"</span>&gt;A dashed outline.&lt;/p&gt;</p>
                        <p>&lt;p <span class="color-pink">class</span>=<span class="color-green">"ex2"</span>&gt;A dotted red outline.&lt;/p&gt;</p>
                        <p>&lt;p <span class="color-pink">class</span>=<span class="color-green">"ex3"</span>&gt;A 5px solid yellow outline.&lt;/p&gt;</p>
                        <p>&lt;p <span class="color-pink">class</span>=<span class="color-green">"ex4"</span>&gt;A thick ridge pink outline.&lt;/p&gt;</p>
                        `
                    }}></div>
                </div>
                <p>
                    Output will be the following -
                </p>
                <div className='outputPalateBox mb-4 mt-3' style={{'border':'0'}}>
                    <div className='outputPalate' dangerouslySetInnerHTML={{
                        __html: `
                        <style>
                            p.ex1 {outline: dashed;}
                            p.ex2 {outline: dotted red;}
                            p.ex3 {outline: 5px solid yellow;}
                            p.ex4 {outline: thick ridge pink;}
                        </style>
                        <p class="ex1">A dashed outline.</p>
                        <p class="ex2">A dotted red outline.</p>
                        <p class="ex3">A 5px solid yellow outline.</p>
                        <p class="ex4">A thick ridge pink outline.</p>
                        `
                    }}></div>
                </div>
                        
            </div>
        </section>
    )
}