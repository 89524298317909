import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/css/border-color";

export default function BorderColor() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Border Color in CSS | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/css/border-width',
            'next': '/css/border-sides'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>Border Color</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <strong>`border-color`</strong> property in CSS specifies the color of an element's border. It can be set for all four sides of an element or for individual sides (<strong>`top`</strong>, <strong>`right`</strong>, <strong>`bottom`</strong>, <strong>`left`</strong>). This property accepts color values such as -
                </p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><strong>`named colors`</strong> specify a color name, like <strong>`red`</strong>, <strong>`blue`</strong>, <strong>`green`</strong>, <strong>`yellow`</strong>, <strong>`purple`</strong>, <strong>`black`</strong>, <strong>`white`</strong>, etc</li>
                    <li><strong>`hex values`</strong> specify a hexadecimal notation like <strong>`#ff0000`</strong> (red), <strong>`#00ff00`</strong> (green), <strong>`#0000ff`</strong> (blue).</li>
                    <li><strong>`RGB values`</strong> specifies the intensity of these colors on a scale from 0 to 255 like <strong>`rgb(255, 0, 0)`</strong> (red), <strong>`rgb(0, 255, 0)`</strong> (green), <strong>`rgb(0, 0, 255)`</strong> (blue).</li>
                    <li><strong>`RGBA values`</strong> specifies the intensity of these colors on a scale from 0 to 255 with alpha value like <strong>`rgb(255, 0, 0, 1)`</strong> (red), <strong>`rgb(0, 255, 0, 1)`</strong> (green), <strong>`rgb(0, 0, 255, 1)`</strong> (blue).</li>
                    <li><strong>`HSL values`</strong> specify a HSL Values like <strong>`hsl(0, 100%, 50%)`</strong> (red), <strong>`hsl(120, 100%, 50%)`</strong> (green), <strong>`hsl(240, 100%, 50%)`</strong> (blue).</li>
                    <li><strong>`HSLA values`</strong> specify a HSL Values with alpha like <strong>`hsl(0, 100%, 50%, 1)`</strong> (red), <strong>`hsl(120, 100%, 50%, 1)`</strong> (green), <strong>`hsl(240, 100%, 50%, 1)`</strong> (blue).</li>
                </ul>
                    
                
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>selector {</p>
                            <p class="ml-30">    <span class="color-green">border-color</span>: value;</p>
                            <p>}</p>
                        `
                    }}></div>
                </div>
                <h5 className="mt-5 mb-3">How a Border Color Can Change a Look</h5>
                <p>
                    Here is some examples of <strong>`border-color`</strong> property. Looks will be different because of different color of a border.
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>&lt;style&gt;</p>
                            <p class="ml-30">    .pClassOne{</p>
                            <p class="ml-60">        <span class="color-green">border-style</span>:solid;</p>
                            <p class="ml-60">        <span class="color-green">border-width</span>: <span class="color-pink">5px;</span></p>
                            <p class="ml-60">        <span class="color-green">border-color</span>: navy;</p>
                            <p class="ml-60">        <span class="color-green">padding</span>: <span class="color-pink">10px 20px</span>;</p>
                            <p class="ml-30">    }</p>
                            <p class="ml-30">    .pClassTwo{</p>
                            <p class="ml-60">        <span class="color-green">border-style</span>:solid;</p>
                            <p class="ml-60">        <span class="color-green">border-width</span>: <span class="color-pink">5px;</span></p>
                            <p class="ml-60">        <span class="color-green">border-color</span>: red;</p>
                            <p class="ml-60">        <span class="color-green">padding</span>: <span class="color-pink">10px 20px</span>;</p>
                            <p class="ml-30">    }</p>
                            <p class="ml-30">    .pClassThree{</p>
                            <p class="ml-60">        <span class="color-green">border-style</span>:solid;</p>
                            <p class="ml-60">        <span class="color-green">border-width</span>: <span class="color-pink">5px;</span></p>
                            <p class="ml-60">        <span class="color-green">border-color</span>: green;</p>
                            <p class="ml-60">        <span class="color-green">padding</span>: <span class="color-pink">10px 20px</span>;</p>
                            <p class="ml-30">    }</p>
                            <p>&lt;/style&gt;</p>
                            <p>&lt;p <span class="color-pink">class</span>=<span class="color-green">"pClassOne"</span>&gt;</p>
                            <p class="ml-30">    I am a solid border, with &lt;strong&gt;navy&lt;/strong&gt; border color.</p>
                            <p>&lt;/p&gt;</p>
                            <p>&lt;p <span class="color-pink">class</span>=<span class="color-green">"pClassTwo"</span>&gt;</p>
                            <p class="ml-30">    I am a solid border, with &lt;strong&gt;red&lt;/strong&gt; border color.</p>
                            <p>&lt;/p&gt;</p>
                            <p>&lt;p <span class="color-pink">class</span>=<span class="color-green">"pClassThree"</span>&gt;</p>
                            <p class="ml-30">    I am a solid border, with &lt;strong&gt;green&lt;/strong&gt; border color.</p>
                            <p>&lt;/p&gt;</p>
                        `
                    }}></div>
                </div>
                <p>
                    Output will be the following -
                </p>
                <div className='outputPalateBox mb-4 mt-3' style={{'border':'none'}}>
                    <div className='outputPalate' dangerouslySetInnerHTML={{
                        __html: `
                        <style>
                            .pClassOne{
                                border-style:solid;
                                border-width: 5px;
                                border-color: navy;
                                padding: 10px 20px;
                            }
                            .pClassTwo{
                                border-style:solid;
                                border-width: 5px;
                                border-color: red;
                                padding: 10px 20px;
                            }
                            .pClassThree{
                                border-style:solid;
                                border-width: 5px;
                                border-color: green;
                                padding: 10px 20px;
                            }
                        </style>
                        <p class="pClassOne">
                            I am a solid border, with <strong>navy</strong> border color.
                        </p>
                        <p class="pClassTwo">
                            I am a solid border, with <strong>red</strong> border color.
                        </p>
                        <p class="pClassThree">
                            I am a solid border, with <strong>green</strong> border color.
                        </p>
                        `
                    }}></div>
                </div>

                <h5 className="mt-5 mb-3">Individual Border Color</h5>
                <p>
                    You can also specify individual color for each side using:
                </p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><strong>`border-top-color`</strong></li>
                    <li><strong>`border-right-color`</strong></li>
                    <li><strong>`border-bottom-color`</strong></li>
                    <li><strong>`border-left-color`</strong></li>
                </ul>

                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>&lt;style&gt;</p>
                            <p class="ml-30">    .pIndivisual{</p>
                            <p class="ml-60">        <span class="color-green">border-top-color</span>: red;</p>
                            <p class="ml-60">        <span class="color-green">border-right-color</span>: navy;</p>
                            <p class="ml-60">        <span class="color-green">border-bottom-color</span>: magenta;</p>
                            <p class="ml-60">        <span class="color-green">border-left-color</span>: green;</p>
                            <p class="ml-60">        <span class="color-green">border-style</span>: solid;</p>
                            <p class="ml-60">        <span class="color-green">border-width</span>: <span class="color-pink">5px</span>;</p>
                            <p class="ml-60">        <span class="color-green">padding</span>: <span class="color-pink">10px 20px</span>;</p>
                            <p class="ml-30">    }</p>
                            <p>&lt;/style&gt;</p>
                            <p>&lt;p <span class="color-pink">class</span>=<span class="color-green">"pIndivisual"</span>&gt;</p>
                            <p class="ml-30">    I am a &lt;strong&gt;solid&lt;/strong&gt; border. I have &lt;strong&gt;red&lt;/strong&gt; top color, &lt;strong&gt;navy&lt;/strong&gt; right color, &lt;strong&gt;magenta&lt;/strong&gt; bottom color and &lt;strong&gt;green&lt;/strong&gt; left color.</p>
                            <p>&lt;/p&gt;</p>
                        `
                    }}></div>
                </div>
                <p>
                    Output will be the following -
                </p>
                <div className='outputPalateBox mb-4 mt-3' style={{'border':'none'}}>
                    <div className='outputPalate' dangerouslySetInnerHTML={{
                        __html: `
                            <style>
                                .pIndivisual{
                                    border-top-color: red;
                                    border-right-color: navy;
                                    border-bottom-color: magenta;
                                    border-left-color: green;
                                    border-style: solid;
                                    border-width: 5px;
                                    padding: 10px 20px;
                                }
                            </style>
                            <p class="pIndivisual">
                                I am a <strong>solid</strong> border. I have <strong>red</strong> top color, <strong>navy</strong> right color, <strong>magenta</strong> bottom color and <strong>green</strong> left color.
                            </p>
                        `
                    }}></div>
                </div>
            </div>
        </section>
    )
}