import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/mysql/show-indexes";

export default function MysqlShowIndex() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Mysql - Show Indexes | Aspirant's Home");
        const urls = {
            'previous': '/mysql/drop-index',
            'next': '/mysql/unique-index'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>Mysql - Show Indexes</h3>
            <div className='mt-4 mb-5'>
                <p>
                    To show the indexes for a table in MySQL, you can use the <strong>`SHOW INDEX`</strong> statement. Here's the basic syntax:
                </p>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-blue">SHOW INDEX</span> FROM table_name;</p>
                        `
                    }}></div>
                </div>
                <p>
                    Replace <strong>`table_name`</strong> with the name of the table for which you want to display the indexes.
                </p>
                <p>
                    For example, to show the indexes for the <strong>`users`</strong> table:
                </p>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-blue">SHOW INDEX</span> FROM users;</p>
                        `
                    }}></div>
                </div>
                <p>
                    This statement will display information about the indexes on the users table, including the index name, the column(s) included in the index, the index type (e.g., BTREE), and other details such as the cardinality of the index.
                </p>
                <p>
                    Additionally, you can use the <strong>`DESCRIBE`</strong> statement as a shorthand for <strong>`SHOW INDEX`</strong>:
                </p>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-blue">DESCRIBE</span> table_name;</p>
                        `
                    }}></div>
                </div>
            </div>
        </section>
    )
}