import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../../Context";
import { Link } from 'react-router-dom';
export const pageUrl = () => "/mysql/arithmetic-operators/modulus";

export default function Modulus() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Mysql - Arithmetic Operators - Modulus (MOD) | Aspirant's Home");
        path.setPreviousNext({});
    }, [])

    return (
        <>
            <section class='clearfix mb-3'>
                <span className='float-start'>
                    <Link to='/mysql/arithmetic-operators' onClick={() => path.setPathName('/mysql/arithmetic-operators')} className='btn btn-primary'> <i class="fa-solid fa-less-than"></i>&nbsp; Back to Arithmetic Operators </Link>
                </span>
            </section>
            <section className='mt-5 mb-5'>
                <h3>Mysql - Modulus (MOD) Operator</h3>
                <div className='mt-4 mb-5'>
                    <p>
                        In MySQL, the <strong>`MOD`</strong> operator is used to find the remainder of a division operation. It returns the remainder of <strong>`dividend / divisor`</strong>. The syntax is:
                    </p>
                    <p>
                        Here's a basic example of using the MOD operator in a SELECT query:
                    </p>
                    <div className='codePalateBox mt-2 mb-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                                <p><span class="color-blue">SELECT</span> <span class="color-pink">5</span> MOD <span class="color-pink">2</span>;</p>
                            `
                        }}></div>
                    </div>
                    <p>
                        This query will return the result of adding 5 MOD 2, which is 1.
                    </p>
                </div>
                <div className='mt-5 mb-5'>
                    <h5>Modulus (MOD) with MySQL Table</h5>
                    <div className='mt-3 mb-2'>
                        <p>
                            Consider a table <strong>`employees`</strong> with the following structure and data:
                        </p>
                        <table className='table' style={{border: '1px dashed #ccc'}}> 
                            <thead>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>emp_id</th>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>emp_name</th>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>salary</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>1</td>
                                    <td style={{border: '1px dashed #ccc'}}>Alice</td>
                                    <td style={{border: '1px dashed #ccc'}}>7000</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>2</td>
                                    <td style={{border: '1px dashed #ccc'}}>Electronics</td>
                                    <td style={{border: '1px dashed #ccc'}}>6000</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>3</td>
                                    <td style={{border: '1px dashed #ccc'}}>Charlie</td>
                                    <td style={{border: '1px dashed #ccc'}}>5500</td>
                                </tr>
                            </tbody>
                        </table>
                        <p>
                            You want to find employees whose salary is not divisible by 1000. Here's how you can use the <strong>`MOD`</strong> operator in a query:
                        </p>
                        <div className='codePalateBox mt-2 mb-2'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-blue">SELECT</span> emp_id, emp_name, salary <span class="color-blue">FROM</span> employees <span class="color-blue">WHERE</span> salary MOD <span class="color-pink">1000</span> != <span class="color-pink">0</span>;</p>
                                `
                            }}></div>
                        </div>
                        <p>
                            This query will return:
                        </p>
                        <table className='table' style={{border: '1px dashed #ccc'}}> 
                            <thead>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>emp_id</th>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>emp_name</th>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>salary</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>3</td>
                                    <td style={{border: '1px dashed #ccc'}}>Charlie</td>
                                    <td style={{border: '1px dashed #ccc'}}>5500</td>
                                </tr>
                            </tbody>
                        </table>
                        <p>
                            This is the employee whose salary is not divisible by 1000.
                        </p>
                    </div>
                </div>
            </section>
        </>
    )
}