import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/css/background-attachment";

export default function CSSBackgroundAttachment() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Background Attachment in CSS | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/css/background-repeat',
            'next': '/css/background-position'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>Background Attachment</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <strong>`background-attachment`</strong> property in CSS controls how a background image behaves when the user scrolls the page. It determines whether the background image stays fixed relative to the viewport or scrolls along with the content. It has a basic syntax -
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>selector {</p>
                            <p class="ml-30">    <span class="color-green">background-attachment</span>: value;</p>
                            <p>}</p>
                        `
                    }}></div>
                </div>

                <h5 className="mt-5 mb-3">Commonly Used Values and Their Uses</h5>
                <p>
                    There are two <strong>`background-attachment`</strong> property values. They are <strong>`fixed`</strong> and <strong>`scroll`</strong>.
                </p>
                <ul style={{ 'listStyle': 'decimal' }}>
                    <li>
                        <strong>`fixed`</strong>
                        <p>The background image stays fixed relative to the viewport, so it does not move when the page is scrolled.</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p>div {</p>
                                    <p class="ml-30">   <span class="color-green">background-image</span>: <span class="color-yellow">url</span>(<span class="color-green">'pattern.png'</span>);</p>
                                    <p class="ml-30">    <span class="color-green">background-attachment</span>: fixed;</p>
                                    <p>}</p>
                                `
                            }}></div>
                        </div>
                        <div className='outputPalateBox mb-4 mt-3'>
                            <div className='outputPalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <style>
                                        .underDiv2{
                                            background-image : url('../../assets/small-2-background.png');
                                            background-repeat: repeat-x;
                                            background-position: right top;
                                        }
                                        .colorNameDiv2{
                                            height: 200px;
                                            overflow: scroll;
                                        }
                                    </style>
                                    <div class="underDiv2">
                                        <div class="colorNameDiv2 fontStyle">
                                            <p>
                                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                                            </p>
                                            <p>
                                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                                            </p>
                                        </div>
                                    </div>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <strong>`scroll`</strong>
                        <p>The background image scrolls with the rest of the page. This is the default value.</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p>div {</p>
                                    <p class="ml-30">   <span class="color-green">background-image</span>: <span class="color-yellow">url</span>(<span class="color-green">'pattern.png'</span>);</p>
                                    <p class="ml-30">    <span class="color-green">background-attachment</span>: scroll;</p>
                                    <p>}</p>
                                `
                            }}></div>
                        </div>
                        <div className='outputPalateBox mb-4 mt-3' style={{'border':'none'}}>
                            <div className='outputPalate' dangerouslySetInnerHTML={{
                                __html: `
                                <style>
                                    .underDiv{
                                        background-image : url('../../assets/small-2-background.png');
                                        background-repeat: repeat-x;
                                        background-position: right top;
                                        background-attachment: scroll;
                                    }
                                    .colorNameDiv2{
                                        height: 200px;
                                        overflow: scroll;
                                    }
                                </style>
                                <div class="colorNameDiv2 fontStyle">
                                    <p class="underDiv">
                                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                                    </p>
                                    <p>
                                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                                    </p>
                                </div>
                                `
                            }}></div>
                        </div>
                    </li>
                </ul>
            </div>
        </section>
    )
}