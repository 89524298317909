import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../../Context";
import { Link } from 'react-router-dom';
export const pageUrl = () => "/mysql/string-functions/format";

export default function FORMAT() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Mysql - String Functions - FORMAT() | Aspirant's Home");
        path.setPreviousNext({});
    }, [])

    return (
        <>
            <section class='clearfix mb-3'>
                <span className='float-start'>
                    <Link to='/mysql/string-functions' onClick={() => path.setPathName('/mysql/string-functions')} className='btn btn-primary'> <i class="fa-solid fa-less-than"></i>&nbsp; Back to String Functions </Link>
                </span>
            </section>
            <section className='mt-5 mb-5'>
                <h3>Mysql - FORMAT() Function</h3>
                <div className='mt-4 mb-5'>
                    <p>
                        The <strong>`FORMAT()`</strong> function in MySQL is used to format a number to a specified number of decimal places and optionally include a thousands separator. This function is particularly useful for displaying numbers in a more readable format, especially in reports and user interfaces.
                    </p>
                    <h5>Syntax</h5>
                    <div className='codePalateBox mt-2 mb-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                                <p>FORMAT(number, decimal_places, [locale])</p>
                            `
                        }}></div>
                    </div>
                    <p>
                        Where, <strong>`number`</strong> is the number to be formatted. <strong>`decimal_places`</strong> is the number of decimal places to include and  <strong>`locale`</strong> is an optional argument that specifies the locale for the formatting. If omitted, the default locale is used.
                    </p>
                    <h5>Examples</h5>
                    <div className='codePalateBox mt-2 mb-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                                <p><span class="color-blue">SELECT</span> FORMAT(<span class="color-pink">1234567.8901</span>, <span class="color-pink">2</span>) <span class="color-blue">AS</span> formatted_number;</p>
                            `
                        }}></div>
                    </div>
                    <p>
                        Output will be 1,234,567.89<br />
                        In this example, the number 1234567.8901 is formatted to two decimal places with a comma as the thousands separator.
                    </p>
                </div>
                <div className='mt-5 mb-5'>
                    <h5>FORMAT() with MySQL Table</h5>
                    <div className='mt-3 mb-2'>
                        <p>
                            Consider a table <strong>`products`</strong> with the following structure and data:
                        </p>
                        <table className='table' style={{border: '1px dashed #ccc'}}> 
                            <thead>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>id</th>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>product_name</th>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>price</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>1</td>
                                    <td style={{border: '1px dashed #ccc'}}>Product A</td>
                                    <td style={{border: '1px dashed #ccc'}}>12345.678</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>2</td>
                                    <td style={{border: '1px dashed #ccc'}}>Product B</td>
                                    <td style={{border: '1px dashed #ccc'}}>98765.432</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>3</td>
                                    <td style={{border: '1px dashed #ccc'}}>Product C</td>
                                    <td style={{border: '1px dashed #ccc'}}>54321.987</td>
                                </tr>
                            </tbody>
                        </table>
                        <p>
                            To format the price column value, we can use <strong>`FORMAT()`</strong> function.
                        </p>
                        <div className='codePalateBox mt-2 mb-2'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-blue">SELECT</span> id, product_name, FORMAT(price, <span class="color-pink">2</span>) <span class="color-blue">AS</span> formatted_price <span class="color-blue">FROM</span> products;</p>
                                `
                            }}></div>
                        </div>
                        <p>
                            This query will return:
                        </p>
                        <table className='table' style={{border: '1px dashed #ccc'}}> 
                            <thead>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>id</th>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>product_name</th>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>formatted_price</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>1</td>
                                    <td style={{border: '1px dashed #ccc'}}>Product A</td>
                                    <td style={{border: '1px dashed #ccc'}}>12,345.68</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>2</td>
                                    <td style={{border: '1px dashed #ccc'}}>Product B</td>
                                    <td style={{border: '1px dashed #ccc'}}>98,765.43</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>3</td>
                                    <td style={{border: '1px dashed #ccc'}}>Product C</td>
                                    <td style={{border: '1px dashed #ccc'}}>54,321.99</td>
                                </tr>
                            </tbody>
                        </table>
                        <p>
                            In this example, each <strong>`price`</strong> is formatted to two decimal places with a comma as the thousands separator.
                        </p>
                    </div>
                </div>
            </section>
        </>
    )
}