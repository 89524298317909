import React, { useEffect} from 'react'
import { MenuContext, TitleContext } from "../Context";
import { useState, createContext, useContext } from "react";

export default function TopHeader() {
    const menu = useContext(MenuContext);
    const [pathname, setPathName] = useState();

    function openMenuPanel(){
        console.log(menu.isMenu)
        menu.setMenuOpen(!menu.isMenu)
    }
    useEffect(() => {
        setPathName(window.location.pathname.split('/')[1])
    }, [pathname])

    return (
        <>
            <div className="topHeader">
                <div className="div">
                    <div className="div">
                        {/* <i class="fa-solid fa-house-user"></i> Aspirant's Home */}
                        <a href='https://aspirantshome.com'>
                            <img src="https://aspirantshome.com/logo-new.png" />
                        </a>
                        <div className='float-end headerSocialPart'>
                            <span>
                                <a style={{ 'color':'inherit'}} target='_blank' href='https://www.facebook.com/people/Aspirants-Home/61560419296735/'>
                                    <i className="fa-brands fa-facebook"></i>
                                </a>
                            </span>
                            <span>
                                <a style={{ 'color': 'inherit' }} target='_blank' href='https://x.com/AspirantHome'>
                                    <i className="fa-brands fa-x-twitter"></i>
                                </a>
                            </span>
                            {/* <span><i className="fa-brands fa-linkedin"></i></span>
                            <span><i className="fa-brands fa-instagram"></i></span>
                            <span><i className="fa-brands fa-youtube"></i></span> */}
                        </div>
                    </div>
                </div>
            </div>
            <div className="topHeader-2">
                <div className="div">
                    <div className="div language-menu">
                        <div className="dropdown my-menu-dropdown text-center">
                            <span className='menuBar' onClick={()=>openMenuPanel()}>
                                <i class="fa fa-bars" aria-hidden="true"></i>
                            </span>
                            <a className={pathname == 'node-js' ? 'dropdown-item myLink active' : 'dropdown-item myLink'} href="/node-js/introduction">NodeJs</a>
                            <a className={pathname == 'mongo-db' ? 'dropdown-item myLink active' : 'dropdown-item myLink'} href="/mongo-db/introduction">MongoDB</a>
                            {/* <a className={pathname == 'angular' ? 'dropdown-item myLink active' : 'dropdown-item myLink'} href="/angular/introduction">Angular</a> */}
                            <a className={pathname == 'react-js' ? 'dropdown-item myLink active' : 'dropdown-item myLink'} href="/react-js/introduction">ReactJS</a>
                            {/* <a className={pathname == 'javascript' ? 'dropdown-item myLink active' : 'dropdown-item myLink'} href="/javascript/introduction">Javascript</a>*/}
                            <a className={pathname == 'html' ? 'dropdown-item myLink active' : 'dropdown-item myLink'} href="/html/introduction">HTML</a>
                            <a className={pathname == 'css' ? 'dropdown-item myLink active' : 'dropdown-item myLink'} href="/css/introduction">CSS</a>
                            <a className={pathname == 'mysql' ? 'dropdown-item myLink active' : 'dropdown-item myLink'} href="/mysql/introduction">Mysql</a>
                            {/* <a className={pathname == 'php' ? 'dropdown-item myLink active' : 'dropdown-item myLink'} href="/php/introduction">PHP</a>
                            <a className={pathname == 'python' ? 'dropdown-item myLink active' : 'dropdown-item myLink'} href="/python/introduction">Python</a>
                            <a className={pathname == 'jquery' ? 'dropdown-item myLink active' : 'dropdown-item myLink'} href="/jquery/introduction">JQuery</a>
                            <a className={pathname == 'next-js' ? 'dropdown-item myLink active' : 'dropdown-item myLink'} href="/next-js/introduction">Next Js</a>
                            <a className={pathname == 'blockchain' ? 'dropdown-item myLink active' : 'dropdown-item myLink'} href="/blockchain/introduction">Blockchain</a>
                            <a className={pathname == 'machine-learning' ? 'dropdown-item myLink active' : 'dropdown-item myLink'} href="/machine-learning/introduction">Machine Learning</a> */}

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
