import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/mysql/unique-index";

export default function MysqlUniqueIndex() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Mysql - Unique Index | Aspirant's Home");
        const urls = {
            'previous': '/mysql/show-indexes',
            'next': '/mysql/where-clause'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>Mysql - Unique Index</h3>
            <div className='mt-4 mb-5'>
                <p>
                    A unique index can be created on one or more columns of a table using the <strong>`CREATE UNIQUE INDEX`</strong> statement in MySQL.
                </p>
                <div className='featureClass'>
                    <ul style={{listStyle:'disc'}}>
                        <li>
                            When creating a unique index on a single column, each value in that column must be unique.
                        </li>
                        <li>
                            A unique index cannot be created if NULL values exist in multiple rows of that column.
                        </li>
                        <li>
                            When creating a unique index on multiple columns, the combination of values in those columns must be unique.
                        </li>
                        <li>
                            A unique index on multiple columns cannot be created if the combination of columns contains NULL values in more than one row.
                        </li>
                    </ul>
                </div>
                <p>
                    Here's the basic syntax:
                </p>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-blue">CREATE UNIQUE</span> INDEX index_name <span class="color-blue">ON</span> table_name (column1, column2, ...);</p>
                        `
                    }}></div>
                </div>
                <p>
                    <strong>`index_name`</strong> is the name of the unique index. It must be unique within the database. <strong>`table_name`</strong> is the name of the table on which you want to create the index. <strong>`column1, column2, ...`</strong> are the columns to include in the index. You can specify one or more columns.
                </p>
                <p>
                    For example, to create a unique index named <strong>`idx_name`</strong> on the <strong>`name`</strong> column of a table named <strong>`employees`</strong>, you would use the following SQL statement:
                </p>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-blue">CREATE UNIQUE</span> INDEX idx_name <span class="color-blue">ON</span> employees (name);</p>
                        `
                    }}></div>
                </div>
                <p>
                    You can also create composite unique indexes that include multiple columns. For example, to create a unique index on the <strong>`name`</strong> and <strong>`department`</strong> columns:
                </p>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-blue">CREATE UNIQUE</span> INDEX idx_name <span class="color-blue">ON</span> employees (name, department);</p>
                        `
                    }}></div>
                </div>
            </div>
        </section>
    )
}