import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/mysql/primary-key";

export default function MysqlPrimaryKey() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Mysql - Primary Key | Aspirant's Home");
        const urls = {
            'previous': '/mysql/unique-key',
            'next': '/mysql/foreign-key'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>Mysql - Primary Key</h3>
            <div className='mt-4 mb-5'>
                <p>
                    In MySQL, a primary key is a column or a group of columns that uniquely identifies each row in a table. It must contain unique values and cannot contain NULL values.
                </p>
                <p>
                    When you define a primary key on a table, MySQL automatically creates a unique index on the primary key column(s) to enforce the uniqueness requirement. Additionally, the primary key column(s) are typically used as the clustering key, which determines the physical order of rows in the table.
                </p>
                <div className='featureClass mb-4'>
                    <p>
                        A primary key in a relational database is a special kind of unique key that uniquely identifies each record in a table. Here are some key points about primary keys:
                    </p>
                    <ul style={{listStyle:'decimal'}}>
                        <li>
                            <strong>Uniqueness :</strong> A primary key must contain unique values. No two rows in a table can have the same value for the primary key column(s).
                        </li>
                        <li>
                            <strong>Non-nullability :</strong> A primary key column cannot contain NULL values. Each record must have a value for the primary key.
                        </li>
                        <li>
                            <strong>Uniqueness Across Tables :</strong> The values in a primary key must be unique not only within the table but also across tables in the same database.
                        </li>
                        <li>
                            <strong>Single or Composite :</strong> A primary key can consist of a single column or multiple columns (composite key).
                        </li>
                        <li>
                            <strong>Primary Key Constraint :</strong> In MySQL, you can define a primary key constraint when creating a table using the PRIMARY KEY keyword, like this:
                            <div className='codePalateBox mt-2 mb-2'>
                                <div className='codePalate' dangerouslySetInnerHTML={{
                                    __html: `
                                        <p><span class="color-blue">CREATE TABLE</span> table_name (</p>
                                        <p style="margin-left:30px">    column1 datatype <span class="color-blue">PRIMARY KEY</span>,</p>
                                        <p style="margin-left:30px">    column2 datatype,</p>
                                        <p style="margin-left:30px">    ...</p>
                                        <p>);</p>
                                    `
                                }}></div>
                            </div>
                        </li>
                        <li>
                            <strong>Auto-increment :</strong> It's common to use an auto-increment integer column as the primary key for tables. 
                            <div className='codePalateBox mt-2 mb-2'>
                                <div className='codePalate' dangerouslySetInnerHTML={{
                                    __html: `
                                        <p><span class="color-blue">CREATE TABLE</span> users (</p>
                                        <p style="margin-left:30px">    id <span class="color-pink">INT</span> AUTO_INCREMENT <span class="color-blue">PRIMARY KEY</span>,</p>
                                        <p style="margin-left:30px">    username <span class="color-pink">VARCHAR</span>(<span class="color-pink">255</span>) <span class="color-blue">NOT NULL</span>,</p>
                                        <p style="margin-left:30px">    email <span class="color-pink">VARCHAR</span>(<span class="color-pink">255</span>) <span class="color-blue">NOT NULL</span></p>
                                        <p>);</p>
                                    `
                                }}></div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div className='mt-5 mb-5'>
                <h5>Creating Primary Key on Existing Column</h5>
                <div className='mt-2 mb-2'>
                    <p>
                        You can add the primary key on an existing table using the <strong>`ALTER TABLE... ADD CONSTRAINT`</strong> statement. Following is the syntax to create a unique constraint on existing columns of a table −
                    </p>
                    <div className='codePalateBox mt-2 mb-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                                <p><span class="color-blue">ALTER TABLE</span> table_name</p> 
                                <p><span class="color-blue">ADD CONSTRAINT</span> </p>
                                <p><span class="color-blue">PRIMARY KEY</span> (column_name);</p>
                            `
                        }}></div>
                    </div>
                </div>
            </div>
            <div className='mt-5 mb-5'>
                <h5>Dropping MySQL Primary Key</h5>
                <div className='mt-2 mb-2'>
                    <p>
                        MySQL provides the <strong>`ALTER TABLE... DROP`</strong> statement to drop the primary key from a table. Following is the syntax to drop the PRIMARY KEY constraint using the <strong>`ALTER TABLE... DROP`</strong> statement −
                    </p>
                    <div className='codePalateBox mt-2 mb-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                                <p><span class="color-blue">ALTER TABLE</span> table_name <span class="color-blue">DROP PRIMARY KEY</span>;</p>
                            `
                        }}></div>
                    </div>
                </div>
            </div>
        </section>
    )
}