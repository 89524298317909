import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../../Context";
import { Link } from 'react-router-dom';
export const pageUrl = () => "/node-js/built-in-modules/util";

export default function UtilModule() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("NodeJs - Util Module | Aspirant's Home");
        path.setPreviousNext({});
    }, [])

    return (
        <div className='contentPage'>
            <section class='clearfix mb-3'>
                <span className='float-start'>
                    <Link to='/node-js/built-in-modules' onClick={() => path.setPathName('/node-js/built-in-modules')} className='btn btn-primary'> <i class="fa-solid fa-less-than"></i>&nbsp; Back to Built-In Modules </Link>
                </span>
            </section>
            <section className='mt-5 mb-5'>
                <h3>Node Js Util Module</h3>
                <div className='mt-4'>
                    <p>In Node.js, the <strong>`util`</strong> module provides a set of utility functions that are helpful for developers. These functions cover a wide range of use cases, including debugging, error handling, object inspection, and more. </p>
                    <p>The syntax for including the util module in your application: </p>
                    <div className='codePalateBox mt-2 mb-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `<p><span class='color-blue'>var</span> util = <span class='color-yellow'>require</span>(<span class='color-green'>'util'</span>);</p>
                                    `
                        }}></div>
                    </div>
                </div>
                <div className='mt-4'>
                    <h5>Examples</h5>
                    <p>Here's a basic example of how you might use the util module:</p>
                    <div className='codePalateBox mt-2 mb-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `<p><span class='color-blue'>const</span> util = <span class='color-yellow'>require</span>(<span class='color-green'>'util'</span>);</p>
                            <p><span class='color-blue'>const</span> formatted = util.<span class='color-red'>format</span>(<span class='color-green'>'Hello %s, Good %s'</span>, <span class='color-blue'>'Harish'</span>, <span class='color-green'>'Morning'</span>);</p>
                            <p><span class='color-pink'>console</span>.<span class='color-red'>log</span>(formatted); <span class='color-grey'>// Output: 'Hello Harish, Good Morning'</span> </p>                           
                                    `
                        }}></div>
                    </div>
                </div>
                <div className='mt-4'>
                    <p>Here are the methods of util module - </p>
                    <p><strong>`debuglog()`</strong> -	The debuglog method in the util module of Node.js is used to create a customized console.log method with a specific namespace.</p>
                    <p><strong>`deprecate()`</strong> - The util.deprecate method in Node.js is used to mark functions as deprecated, meaning they are no longer recommended for use and will likely be removed in future versions. </p>
                    <p><strong>`format()`</strong> - The util.format method in Node.js is used to format strings. It takes a string as its first argument, which may contain format specifiers, and replaces them with the values of the subsequent arguments.</p>
                    <p><strong>`inherits()`</strong> -	The util.inherits method in Node.js is used to inherit the prototype methods from one constructor (referred to as the "super constructor") into another constructor's prototype. </p>
                    <p><strong>`inspect()`</strong> - The util.inspect method in Node.js is used to convert JavaScript objects into strings for debugging purposes. It is similar to JSON.stringify, but it provides more options and is designed for human-readable output.</p>
                </div>
            </section>
        </div>
    )
}
