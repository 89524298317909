import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../../Context";
import { Link } from 'react-router-dom';
export const pageUrl = () => "/mysql/date-and-time-functions/now";

export default function Now() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Mysql - Date and Time functions - NOW() | Aspirant's Home");
        path.setPreviousNext({});
    }, [])

    return (
        <>
            <section class='clearfix mb-3'>
                <span className='float-start'>
                    <Link to='/mysql/date-and-time-functions' onClick={() => path.setPathName('/mysql/date-and-time-functions')} className='btn btn-primary'> <i class="fa-solid fa-less-than"></i>&nbsp; Back to Date and Time functions </Link>
                </span>
            </section>
            <section className='mt-5 mb-5'>
                <h3>Mysql - NOW() Functions</h3>
                <div className='mt-4 mb-5'>
                    <p>
                        The <strong>`NOW()`</strong> function in MySQL is used to return the current date and time. It does not require any arguments. When you call <strong>`NOW()`</strong>, it returns a <strong>`DATETIME`</strong> value representing the current date and time according to the server's system date and time.
                    </p>
                    <p>
                        Here's a simple example:
                    </p>
                    <div className='codePalateBox mt-2 mb-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                                <p><span class="color-blue">SELECT</span> NOW();</p>
                            `
                        }}></div>
                    </div>
                    <p>
                        This will return something like <strong>`2022-05-16 12:34:56`</strong>, depending on the current date and time when the query is executed.
                    </p>
                    <p>
                        You can use <strong>`NOW()`</strong> in <strong>`INSERT`</strong> or <strong>`UPDATE`</strong> statements to insert or update the current date and time into a column. For example:
                    </p>
                    <div className='codePalateBox mt-2 mb-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                                <p><span class="color-blue">INSERT INTO</span> table_name (date_column) <span class="color-blue">VALUES</span> (NOW());</p>
                            `
                        }}></div>
                    </div>
                    <p>
                        This would insert the current date and time into the <strong>`date_column`</strong> of <strong>`table_name`</strong>.
                    </p>
                </div>
            </section>
        </>
    )
}