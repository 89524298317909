import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/html/ids";

export default function HTMLIds() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("HTML - IDs | Aspirant's Home");
        const urls = {
            'previous': '/html/classes',
            'next': '/html/entities'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>HTML - IDs</h3>
            <div className='mt-4 mb-5'>
                <p>
                    In HTML, the <strong>`id`</strong> attribute is used to uniquely identify a single element within the document. Unlike classes, which can be applied to multiple elements, an <strong>`id`</strong> must be unique within an HTML document. This uniqueness allows the <strong>`id`</strong> attribute to be used for specific purposes such as applying styles, targeting elements with JavaScript, and creating anchors for navigation.
                </p>
                <h5 className='mt-5 mb-3'>Syntax</h5>
                <p>
                    To assign an <strong>`id`</strong> to an HTML element, use the <strong>`id`</strong> attribute followed by the desired unique identifier:
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>&lt;div <span class="color-pink">id</span>=<span class="color-green">"myUniqueId"</span>&gt;This is a div with a unique id.&lt;/div&gt;</p>
                        `
                    }}></div>
                </div>
                <h5 className='mt-5 mb-3'>Use of IDs in CSS</h5>
                <p>
                    IDs can be used in CSS to apply styles to specific elements. In CSS, ID selectors are prefixed with a hash symbol (<strong>`#`</strong>).
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-grey">&lt;!DOCTYPE</span> <span class="color-blue">html</span><span class="color-grey">&gt;</span></p>
                            <p>&lt;html <span class="color-pink">lang</span>=<span class="color-green">"en"</span>&gt;</p>
                            <p class="ml-30">    &lt;head&gt;</p>
                            <p class="ml-60">        &lt;meta <span class="color-pink">charset</span>=<span class="color-green">"UTF-8"</span>&gt;</p>
                            <p class="ml-60">        &lt;title&gt;ID Example&lt;/title&gt;</p>
                            <p class="ml-60">        &lt;style&gt;</p>
                            <p class="ml-90">            <span class="color-pink">#myUniqueId</span> {</p>
                            <p class="ml-120">                <span class="color-green">color</span>: blue;</p>
                            <p class="ml-120">                <span class="color-green">font-size</span>: 24px;</p>
                            <p class="ml-90">            }</p>
                            <p class="ml-60">        &lt;/style&gt;</p>
                            <p class="ml-30">    &lt;/head&gt;</p>
                            <p class="ml-30">    &lt;body&gt;</p>
                            <p class="ml-60">        &lt;div <span class="color-pink">id</span>=<span class="color-green">"myUniqueId"</span>&gt;This text is blue and 24px in size.&lt;/div&gt;</p>
                            <p class="ml-30">    &lt;/body&gt;</p>
                            <p>&lt;/html&gt;</p>
                        `
                    }}></div>
                </div>
                <p>
                    Output of the following -
                </p>
                <div className='outputPalateBox mb-4'>
                    <div className='outputPalate' dangerouslySetInnerHTML={{
                        __html: `
                        <!DOCTYPE html>
                        <html lang="en">
                            <head>
                                <meta charset="UTF-8">
                                <title>ID Example</title>
                                <style>
                                    #myUniqueId {
                                        color: blue;
                                        font-size: 24px;
                                    }
                                </style>
                            </head>
                            <body>
                                <div id="myUniqueId">This text is blue and 24px in size.</div>
                            </body>
                        </html>
                        `
                    }}></div>
                </div>
                <h5 className='mt-5 mb-3'>Use of IDs in JavaScript</h5>
                <p>
                    IDs are often used in JavaScript to select and manipulate specific elements. The <strong>`getElementById`</strong> method is used to access an element by its <strong>`id`</strong>.
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-grey">&lt;!DOCTYPE</span> <span class="color-blue">html</span><span class="color-grey">&gt;</span></p>
                        <p>&lt;html <span class="color-pink">lang</span>=<span class="color-green">"en"</span>&gt;</p>
                        <p class="ml-30">    &lt;head&gt;</p>
                        <p class="ml-60">        &lt;meta <span class="color-pink">charset</span>=<span class="color-green">"UTF-8"</span>&gt;</p>
                        <p class="ml-60">        &lt;title&gt;ID Manipulation Example&lt;/title&gt;</p>
                        <p class="ml-60">        &lt;script&gt;</p>
                        <p class="ml-90">            <span class="color-blue">function</span> <span class="color-red">changeContent</span>() {</p>
                        <p class="ml-120">                <span class="color-pink">document</span>.<span class="color-red">getElementById</span>(<span class="color-green">'myUniqueId'</span>).innerHTML = <span class="color-green">'Content has been changed!'</span>;</p>
                        <p class="ml-90">            }</p>
                        <p class="ml-60">        &lt;/script&gt;</p>
                        <p class="ml-30">    &lt;/head&gt;</p>
                        <p class="ml-30">    &lt;body&gt;</p>
                        <p class="ml-60">        &lt;div <span class="color-pink">id</span>=<span class="color-green">"myUniqueId"</span>&gt;This is the original content.&lt;/div&gt;</p>
                        <p class="ml-60">        &lt;button <span class="color-pink">onclick</span>=<span class="color-green">"changeContent()"</span>&gt;Change Content&lt;/button&gt;</p>
                        <p class="ml-30">    &lt;/body&gt;</p>
                        <p>&lt;/html&gt;</p>
                        `
                    }}></div>
                </div>
                <p>
                    Output of the following -
                </p>
                <div className='outputPalateBox mb-4'>
                    <div className='outputPalate' dangerouslySetInnerHTML={{
                        __html: `
                        <!DOCTYPE html>
                        <html lang="en">
                            <head>
                                <meta charset="UTF-8">
                                <title>ID Manipulation Example</title>
                            </head>
                            <body>
                                <div id="myUniqueId1">This is the original content.</div>
                                <button onclick="document.getElementById('myUniqueId1').innerHTML = 'Content has been changed!';">Change Content</button>
                            </body>
                        </html>
                        
                        `
                    }}></div>
                </div>
                <h5 className='mt-5 mb-3'>Use of IDs for Navigation</h5>
                <p>
                    IDs can also be used to create anchor links within a page. This is useful for creating a table of contents or for navigating to different sections of a long document.
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-grey">&lt;!DOCTYPE</span> <span class="color-blue">html</span><span class="color-grey">&gt;</span></p>
                        <p>&lt;html <span class="color-pink">lang</span>=<span class="color-green">"en"</span></p>
                        <p class="ml-30">    &lt;head&gt;</p>
                        <p class="ml-60">        &lt;meta <span class="color-pink">charset</span>=<span class="color-green">"UTF-8"</span>&gt;</p>
                        <p class="ml-60">        &lt;title&gt;Anchor Links Example&lt;/title&gt;</p>
                        <p class="ml-30">    &lt;/head&gt;</p>
                        <p class="ml-30">    &lt;body&gt;</p>
                        <p class="ml-60">        &lt;nav&gt;</p>
                        <p class="ml-90">            &lt;ul&gt;</p>
                        <p class="ml-120">                &lt;li&gt;&lt;a <span class="color-pink">href</span>=<span class="color-green">"#section1"</span>&gt;Go to Section 1&lt;/a&gt;&lt;/li&gt;</p>
                        <p class="ml-120">                &lt;li&gt;&lt;a <span class="color-pink">href</span>=<span class="color-green">"#section2"</span>&gt;Go to Section 2&lt;/a&gt;&lt;/li&gt;</p>
                        <p class="ml-90">            &lt;/ul&gt;</p>
                        <p class="ml-60">        &lt;/nav&gt;</p>
                        <br />    
                        <p class="ml-60">        &lt;h2 <span class="color-pink">id</span>=<span class="color-green">"section1"</span>&gt;Section 1&lt;/h2&gt;</p>
                        <p class="ml-60">        &lt;p&gt;This is the content of section 1.&lt;/p&gt;</p>
                        <br />    
                        <p class="ml-60">        &lt;h2 <span class="color-pink">id</span>=<span class="color-green">"section2"</span>&gt;Section 2&lt;/h2&gt;</p>
                        <p class="ml-60">        &lt;p&gt;This is the content of section 2.&lt;/p&gt;</p>
                        <p class="ml-30">    &lt;/body&gt;</p>
                        <p>&lt;/html&gt;</p>
                        `
                    }}></div>
                </div>
                <p className='mt-4'>
                    By using the id attribute appropriately, you can target and manipulate specific elements on your web pages with precision.
                </p>
            </div>
        </section>
    )
}