import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/mongo-db/one-to-many-relations";

export default function MongoOneToMany() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("One to Many Relations in MongoDB Document");
        title.setKeyWords("one to many relations in mongodb, one to many mongodb, mongodb one to many relationship, mongodb one to many example, mongodb one to many, mongodb one-to-many relationship example, one to many relations, how to create one to many relationship in mongodb, db one to many relationship, one to many relationship mongodb example, one to many relationship examples, mongodb one to many relationship query");
        title.setPageDescription("In MongoDB, a one-to-many relationship is a common pattern where a single document is related to multiple documents in another collection. There are two primary methods for representing one-to-many relationships: embedding and referencing. Each method has its own advantages and is suitable for different use cases.");
        const urls = {
            'previous': '/mongo-db/one-to-one-relations',
            'next': '/mongo-db/many-to-many-relations'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>MongoDB - One to Many Relations</h3>
            <div className='mt-4 mb-5'>
                <p>
                    In MongoDB, a one-to-many relationship is a common pattern where a single document is related to multiple documents in another collection. There are two primary methods for representing one-to-many relationships: <strong>embedding</strong> and <strong>referencing</strong>. Each method has its own advantages and is suitable for different use cases.
                </p>
                <div className='mt-3 myImage'>
                    <img className='img-fluid' src='../assets/mongodb/one-to-many.png' />
                </div>
                <p>
                    Consider a blog application where a <strong>`Post`</strong> can have multiple <strong>`Comments`</strong>.
                </p>
                <h5 className='mt-5 mb-3'>Embedded Document Structure</h5>
                <p>
                    In this approach, the related documents are embedded directly within the parent document. This is useful when the related data is small and always accessed together.
                </p>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>{</p>
                            <p style="margin-left:30px">    <span class="color-pink">"_id"</span>: ObjectId(<span class="color-green">"507f191e810c19729de860ea"</span>),</p>
                            <p style="margin-left:30px">    <span class="color-pink">"title"</span>: <span class="color-green">"My First Blog Post"</span>,</p>
                            <p style="margin-left:30px">    <span class="color-pink">"content"</span>: <span class="color-green">"This is the content of the blog post."</span>,</p>
                            <p style="margin-left:30px">    <span class="color-pink">"comments"</span>: [</p>
                            <p style="margin-left:60px">         {</p>
                            <p style="margin-left:90px">                <span class="color-pink">"author"</span>: <span class="color-green">"Jane Doe"</span>,</p>
                            <p style="margin-left:90px">                <span class="color-pink">"text"</span>: <span class="color-green">"Great post!"</span>,</p>
                            <p style="margin-left:90px">                <span class="color-pink">"date"</span>: ISODate(<span class="color-green">"2024-06-01T10:00:00Z"</span>)</p>
                            <p style="margin-left:60px">         },</p>
                            <p style="margin-left:60px">         {</p>
                            <p style="margin-left:90px">                <span class="color-pink">"author"</span>: <span class="color-green">"John Smith"</span>,</p>
                            <p style="margin-left:90px">                <span class="color-pink">"text"</span>: <span class="color-green">"Very informative."</span>,</p>
                            <p style="margin-left:90px">                <span class="color-pink">"date"</span>: ISODate(<span class="color-green">"2024-06-01T12:00:00Z"</span>)</p>
                            <p style="margin-left:60px">         }</p>
                            <p style="margin-left:30px">    ]</p>
                            <p> }</p>
                        `
                    }}></div>
                </div>
                <p>
                    All related data is stored together, making it efficient to retrieve the post along with its comments in a single query.
                </p>
                <div className='featureClass'>
                    <p>Embedding is ideal for one-to-many relationships when:</p>
                    <ul style={{ listStyle: 'disc' }}>
                        <li>The related data is small and not expected to grow indefinitely.</li>
                        <li>The related data is always accessed together with the parent document.</li>
                    </ul>
                </div>
                <h5 className='mt-5 mb-3'>Document Structure with Referencing</h5>
                <p>
                    Continuing with the blog application, you can store <strong>`Post`</strong> and <strong>`Comments`</strong> documents in separate collections.
                </p>
                <h6>Post Document</h6>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>{</p>
                            <p style="margin-left:30px">    <span class="color-pink">"_id"</span>: ObjectId(<span class="color-green">"507f191e810c19729de860ea"</span>),</p>
                            <p style="margin-left:30px">    <span class="color-pink">"title"</span>: <span class="color-green">"My First Blog Post"</span>,</p>
                            <p style="margin-left:30px">    <span class="color-pink">"content"</span>: <span class="color-green">"This is the content of the blog post."</span></p>
                            <p>}</p>
                        `
                    }}></div>
                </div>
                <h6>Comment Document</h6>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>{</p>
                            <p style="margin-left:30px">    <span class="color-pink">"_id"</span>: ObjectId(<span class="color-green">"507f191e810c19729de860eb"</span>),</p>
                            <p style="margin-left:30px">    <span class="color-pink">"postId"</span>: ObjectId(<span class="color-green">"507f191e810c19729de860ea"</span>),</p>
                            <p style="margin-left:30px">    <span class="color-pink">"author"</span>: <span class="color-green">"Jane Doe"</span>,</p>
                            <p style="margin-left:30px">    <span class="color-pink">"text"</span>: <span class="color-green">"Great post!"</span>,</p>
                            <p style="margin-left:30px">    <span class="color-pink">"date"</span>: ISODate(<span class="color-green">"2024-06-01T10:00:00Z"</span>)</p>
                            <p>}</p>
                        `
                    }}></div>
                </div>
                <div className='mt-4 featureClass'>
                    <p>Referencing is ideal for one-to-many relationships when:</p>
                    <ul style={{ listStyle: 'disc' }}>
                        <li>The related data is large or frequently accessed independently.</li>
                        <li>The relationship needs to be maintained without duplicating data.</li>
                    </ul>
                </div>
            </div>
        </section>
    )
}