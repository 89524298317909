import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/mysql/composite-key";

export default function MysqlCompositeKey() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Mysql - Composite Key | Aspirant's Home");
        const urls = {
            'previous': '/mysql/foreign-key',
            'next': '/mysql/create-trigger'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>Mysql - Composite Key</h3>
            <div className='mt-4 mb-5'>
                <p>
                    A MySQL composite key is a key that consists of two or more columns in a table, used together to uniquely identify a record (combination of values in the same table row). It can also be described as a primary key created on multiple columns.
                </p>
                <p>
                    When a composite key is created on multiple columns of a table, the combination of these columns guarantees uniqueness, even though individually these columns may or may not guarantee uniqueness. Therefore, when the database table doesn't have any column which is individually capable of identifying a unique row (or a record) from the table, then we might need two or more columns to get a unique record/row from the table.
                </p>
                <div className='featureClass'>
                    <ul>
                        <li>A composite key may or may not be a part of a foreign key constraint.</li>
                        <li>A composite key cannot contain NULL values.</li>
                        <li>A composite key can be created by combining more than one candidate key.</li>
                        <li>It is also known as a compound key.</li>
                    </ul>
                </div>
            </div>
            <div className='mt-5 mb-5'>
                <h5>Creating MySQL Composite Key</h5>
                <div className='mt-2 mb-2'>
                    <p>
                        To create a composite key in a MySQL table, you can specify a primary key on two or more columns of a table using the <strong>`PRIMARY KEY`</strong> keyword in the <strong>`CREATE TABLE`</strong> statement. 
                    </p>
                    <p>
                        Following is the syntax to create a Composite Key while creating a table −
                    </p>
                    <div className='codePalateBox mt-2 mb-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                                <p><span class="color-blue">CREATE TABLE</span> table_name(</p>
                                <p style="margin-left:30px">    column1 datatype, column2 datatype, column3 datatype..., </p>
                                <p style="margin-left:30px">    <span class="color-blue">CONSTRAINT</span> composite_key_name </p>
                                <p style="margin-left:30px">    <span class="color-blue">PRIMARY KEY</span>(column_name1, column_name2,..)</p>
                                <p>);</p>
                            `
                        }}></div>
                    </div>
                </div>
            </div>
            <div className='mt-5 mb-5'>
                <h5>Dropping MySQL Composite Key</h5>
                <div className='mt-2 mb-2'>
                    <p>
                        We can drop the MySQL Composite Key by using the <strong>`ALTER TABLE... DROP`</strong> statement. Following is the syntax to drop the Composite key from the column of a table −
                    </p>
                    <div className='codePalateBox mt-2 mb-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                                <p><span class="color-blue">ALTER TABLE</span> table_name <span class="color-blue">DROP PRIMARY KEY</span>;</p>
                            `
                        }}></div>
                    </div>
                </div>
            </div>
        </section>
    )
}