import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/mysql/drop-database";

export default function MysqlDropDatabase() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Mysql - Drop Database | Aspirant's Home");
        const urls = {
            'previous': '/mysql/create-database',
            'next':'/mysql/select-database'
        }
        path.setPreviousNext(urls);
    },[])

    return (
        <section className='mt-5 mb-5'>
            <h3>Mysql - Drop Database</h3>
            <div className='mt-4 mb-5'>
                <p>
                    In MySQL, the <strong>`DROP DATABASE`</strong> statement is used to delete a database and all of its tables permanently. It removes the database from the server's filesystem and releases the disk space that it occupied. Here's the basic syntax for dropping a database:
                </p>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-blue">DROP</span> DATABASE [IF <span class="color-blue">EXISTS</span>] database_name;</p>
                        `
                    }}></div>
                </div>
                <ul>
                    <li>
                        <strong>`IF EXISTS`</strong>: This optional clause prevents an error from occurring if the database does not exist. If the database exists, it will be dropped. If it does not exist, no action will be taken.
                    </li>
                    <li>
                        <strong>`database_name`</strong>: The name of the database you want to drop.
                    </li>
                </ul>
                <p>
                    For example, to drop a database named <strong>`mydatabase`</strong>, you would use the following SQL statement:
                </p>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-blue">DROP</span> DATABASE mydatabase;</p>
                        `
                    }}></div>
                </div>
            </div>
        </section>
    )
}