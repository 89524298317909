import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/css/text/spacing";

export default function TextSpacing() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Text Spacing in CSS | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/css/text/transformation',
            'next': '/css/text/shadow'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>Text Spacing</h3>
            <div className='mt-4 mb-5'>
                <p>
                    Text spacing in CSS refers to the manipulation of space between characters, words, and lines within text elements. CSS provides several properties to control these aspects, allowing for fine-tuned typography that improves readability and visual appeal.
                </p>
                <h5 className="mt-5 mb-3">Key Properties for Text Spacing</h5>
                <p>
                    Here, we will discuss each of the following properties.
                </p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>letter-spacing</li>
                    <li>word-spacing</li>
                    <li>line-height</li>
                    <li>text-indent</li>
                    <li>white-space</li>
                </ul>

                <h5 className="mt-5 mb-3">Letter Spacing</h5>
                <p>
                    It controls the space between individual characters in the text. If you set it's value to `normal` then it use default spacing between characters. You can also add a specific amount of space, which can be positive or negative, measured in units like `px`, `em`, etc.
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>p {</p>
                            <p class="ml-30">    <span class="color-green">letter-spacing</span>: <span class="color-pink">2px</span>;</p>
                            <p>}</p>
                        `
                    }}></div>
                </div>
                <p>
                    In above example, This will adds 2 pixels of space between each character in the paragraph.
                </p>

                <h5 className="mt-5 mb-3">Word Spacing</h5>
                <p>
                    It controls the space between individual words in the text. If you set it's value to `normal` then it use default spacing between words. You can also add a specific amount of space, which can be positive or negative, measured in units like `px`, `em`, etc.
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>p {</p>
                            <p class="ml-30">    <span class="color-green">word-spacing</span>: <span class="color-pink">5px</span>;</p>
                            <p>}</p>
                        `
                    }}></div>
                </div>
                <p>
                    In above example, This will adds 5 pixels of space between each word in the paragraph.
                </p>

                <h5 className="mt-5 mb-3">Line Height</h5>
                <p>
                    It controls the space between lines of text within a block of text. If you set it's value to `normal` then line height usually around 1.2 times the font size. You can set even a number in value, which is a multiplier of the element's font size. You can set a specific value in units like `px`, `em`, etc or a percentage, which is a percentage of the element's font size..
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>p {</p>
                            <p class="ml-30">    <span class="color-green">line-height</span>: <span class="color-pink">1.5</span>;</p>
                            <p>}</p>
                        `
                    }}></div>
                </div>
                <p>
                    The above example sets the line height to 1.5 times the font size, adding more space between lines.
                </p>

                <h5 className="mt-5 mb-3">Text Indent</h5>
                <p>
                    It controls the indentation of the first line of text in a block-level element. You can set a specific length in values to indent the first line, measured in px, em, etc. You can set a percentage in value which is a percentage of the containing block's width.
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>p {</p>
                            <p class="ml-30">    <span class="color-green">text-indent</span>: <span class="color-pink">30px</span>;</p>
                            <p>}</p>
                        `
                    }}></div>
                </div>
                <p>
                    In the above example, it indents the first line of the paragraph by 30 pixels.
                </p>

                <h5 className="mt-5 mb-3">White Space</h5>
                <p>
                    It controls how white space (spaces, tabs, and newlines) inside an element is handled. It has many possible values -
                </p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><strong>`normal`</strong>: It collapses white space and breaks lines when necessary (default).</li>
                    <li><strong>`nowrap`</strong>: It collapses white space but prevents line breaks.</li>
                    <li><strong>`pre`</strong>: It preserves white space and line breaks.</li>
                    <li><strong>`pre-wrap`</strong>: It preserves white space but allows lines to wrap.</li>
                    <li><strong>`pre-line`</strong>: It collapses white space but preserves line breaks.</li>
                </ul>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>p {</p>
                            <p class="ml-30">    <span class="color-green">white-space</span>: nowrap;</p>
                            <p>}</p>
                        `
                    }}></div>
                </div>
                <p>
                    Above example prevents the text in the paragraph from wrapping to the next line.
                </p>
            </div>
        </section>
    )
}