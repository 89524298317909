import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/react-js/props";

export default function ReactProps() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("React Props | Aspirant's Home");
        const urls = {
            'previous': '/react-js/components',
            'next':'/react-js/events'
        }
        path.setPreviousNext(urls);
    },[])

    return (
        <section className='mt-5 mb-5'>
            <h3>React Js - Props</h3>
            <div className='mt-4 mb-5'>
                <p>The main advantage of React is that we can reuse the same UI structure multiple times without rewriting it using components. Now, if we want dynamic data in a component, we can use props. <strong>`Props`</strong> allow us to pass data from a parent component to a child component.</p>
                <p>Props are used to customize a component's behavior or appearance based on the data passed to it. They are passed to a component as attributes in JSX and are accessible inside the component as an object. For example, if you have a <strong>`User`</strong> component that accepts a name <strong>`prop`</strong>, you can pass the name to the component like this:</p>

                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `<p>&lt;<span class='color-red'>User</span> name=<span class='color-green'>"John"</span> /&gt;</p>`
                    }}></div>
                </div>
                <p>Inside the <strong>`User`</strong> component, you can access the name <strong>`prop`</strong> like this:</p>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `<p><span class="color-blue">function</span> <span class="color-red">User</span>(props) {</p>
                            <p style="margin-left:30px"><span class="color-blue">return</span> &lt;div&gt;Hello, {props.name}!&lt;/div&gt;;</p>
                            <p>}</p>
                            `
                    }}></div>
                </div>
            </div>
            <div className='mt-5 mb-5'>
                <h5>Understanding Props</h5>
                <div className='mt-4 mb-4'>
                    <ul style={{listStyle:'decimal'}}>
                        <li>
                            <p>Props are "custom HTML attributes" set on components. Look at the below examples - </p>
                            <div className='codePalateBox mt-2 mb-2'>
                                <div className='codePalate' dangerouslySetInnerHTML={{
                                    __html: `<p>&lt;<span class='color-red'>User</span> name=<span class='color-green'>"John"</span> age=<span class='color-green'>"30"</span> address=<span class='color-green'>"17 Camac Street"</span> /&gt;</p>`
                                }}></div>
                            </div>
                        </li>
                        <li>
                            <p>React merges all props into a single object.</p>
                            <div className='codePalateBox mt-2 mb-2'>
                                <div className='codePalate' dangerouslySetInnerHTML={{
                                    __html: `<p>{</p>
                                        <p style="margin-left:30px"><span class='color-pink'>name</span> : <span class='color-green'>"John"</span>,</p>
                                        <p style="margin-left:30px"><span class='color-pink'>age</span> : <span class='color-green'>"30"</span>,</p>
                                        <p style="margin-left:30px"><span class='color-pink'>address</span> : <span class='color-green'>"17 Camac Street"</span></p>
                                    }`
                                }}></div>
                            </div>
                        </li>
                        <li>
                            <p>Props are passed to the component function as the first argument by React.</p>
                            <div className='codePalateBox mt-2 mb-2'>
                                <div className='codePalate' dangerouslySetInnerHTML={{
                                    __html: `<p><span class="color-blue">function</span> <span class="color-red">User</span>(props) {</p>
                                    <p style="margin-left:30px"><span class="color-blue">return</span> (</p>
                                    <p style="margin-left:60px">&lt;div&gt;
                                    <p style="margin-left:90px">&lt;p&gt;Hello, I am {props.name}&lt;p/&gt;</p>
                                    <p style="margin-left:90px">&lt;p&gt;My age is {props.age}&lt;p/&gt;</p>
                                    <p style="margin-left:90px">&lt;p&gt;My address is {props.address}&lt;p/&gt;</p>
                                    <p style="margin-left:60px">&lt;/div&gt;</p>
                                    <p style="margin-left:30px">)</p>
                                    <p>}</p>`
                                }}></div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div className='mt-5 mb-4'>
                <h5>How to use Dynamic Props Data ?</h5>
                <div className='mt-4 mb-4'>
                    <p>Suppose we have to call <strong>`User`</strong> component multiple times. Then what we have to do.</p>
                    <div className='codePalateBox mt-2 mb-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `<p>&lt;<span class='color-red'>User</span> name=<span class='color-green'>"John"</span> age=<span class='color-green'>"30"</span> address=<span class='color-green'>"17 Camac Street"</span> /&gt;</p>
                            <p>&lt;<span class='color-red'>User</span> name=<span class='color-green'>"Mickel"</span> age=<span class='color-green'>"45"</span> address=<span class='color-green'>"21 Habra Street"</span> /&gt;</p>
                            <p>&lt;<span class='color-red'>User</span> name=<span class='color-green'>"Sophia"</span> age=<span class='color-green'>"24"</span> address=<span class='color-green'>"06 Salva Street"</span> /&gt;</p>`
                        }}></div>
                    </div>
                    <p>We have to call the all props data multiple time like above.</p>
                    <p>You can also call the props data from an variable like below</p>
                    <div className='codePalateBox mt-2 mb-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `<p><span class='color-blue'>function</span> <span class='color-red'>PropsExample</span>() {</p>
                                <br />
                                <p style="margin-left:30px"><span class='color-blue'>const</span> userData = [</p>
                                <p style="margin-left:60px">    {</p>
                                <p style="margin-left:90px">        <span class='color-pink'>name</span>: <span class='color-green'>"John"</span>,</p>
                                <p style="margin-left:90px">        <span class='color-pink'>age</span>: <span class='color-green'>"30"</span>,</p>
                                <p style="margin-left:90px">        <span class='color-pink'>address</span>: <span class='color-green'>"17 Camac Street"</span></p>
                                <p style="margin-left:60px">    },</p>
                                <p style="margin-left:60px">    {</p>
                                <p style="margin-left:90px">        <span class='color-pink'>name</span>: <span class='color-green'>"Mickel"</span>,</p>
                                <p style="margin-left:90px">        <span class='color-pink'>age</span>: <span class='color-green'>"45"</span>,</p>
                                <p style="margin-left:90px">        <span class='color-pink'>address</span>: <span class='color-green'> "21 Habra Street"</span></p>
                                <p style="margin-left:60px">    },</p>
                                <p style="margin-left:60px">    {</p>
                                <p style="margin-left:90px">        <span class='color-pink'>name</span>: <span class='color-green'> "Sophia"</span>,</p>
                                <p style="margin-left:90px">        <span class='color-pink'>age</span>: <span class='color-green'> "24"</span>,</p>
                                <p style="margin-left:90px">        <span class='color-pink'>address</span>: <span class='color-green'> "06 Salva Street"</span></p>
                                <p style="margin-left:60px">    }</p>
                                <p style="margin-left:30px">]</p>
                                <p style="margin-left:30px"><span class='color-blue'>return</span> (</p>
                                <p style="margin-left:60px">    &lt;div className='contentPage'&gt;</p>
                                <p style="margin-left:90px">        &lt;<span class='color-red'>User</span> name={userData[0].name} age={userData[0].age } address={userData[0].address } /&gt;</p>
                                <p style="margin-left:90px">        &lt;<span class='color-red'>User</span> name={userData[1].name} age={userData[1].age } address={userData[1].address } /&gt;</p>
                                <p style="margin-left:90px">        &lt;<span class='color-red'>User</span> name={userData[2].name} age={userData[2].age } address={userData[2].address } /&gt;</p>
                                <p style="margin-left:60px">    &lt;/div&gt;</p>
                                <p style="margin-left:30px">)</p>
                            <p>}</p>`
                        }}></div>
                    </div>
                </div>
            </div>
            <div className='mt-5 mb-4'>
                <h5>Alternative Way Of Using Props</h5>
                <div className='mt-4 mb-4'>
                    <p>The above code is becoming lengthy. To optimize it, we can use destructuring. To use destructuring, ensure that all the key names in the <strong>`userData`</strong> array match the prop attributes in the component.</p>
                    <p>See the examples below -</p>
                    <div className='codePalateBox mt-2 mb-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `<p><span class='color-blue'>function</span> <span class='color-red'>PropsExample</span>() {</p>
                            <br />
                            <p style="margin-left:30px"><span class='color-blue'>const</span> userData = [</p>
                            <p style="margin-left:60px">    {</p>
                            <p style="margin-left:90px">        <span class='color-pink'>name</span>: <span class='color-green'>"John"</span>,</p>
                            <p style="margin-left:90px">        <span class='color-pink'>age</span>: <span class='color-green'>"30"</span>,</p>
                            <p style="margin-left:90px">        <span class='color-pink'>address</span>: <span class='color-green'>"17 Camac Street"</span></p>
                            <p style="margin-left:60px">    },</p>
                            <p style="margin-left:60px">    {</p>
                            <p style="margin-left:90px">        <span class='color-pink'>name</span>: <span class='color-green'>"Mickel"</span>,</p>
                            <p style="margin-left:90px">        <span class='color-pink'>age</span>: <span class='color-green'>"45"</span>,</p>
                            <p style="margin-left:90px">        <span class='color-pink'>address</span>: <span class='color-green'> "21 Habra Street"</span></p>
                            <p style="margin-left:60px">    },</p>
                            <p style="margin-left:60px">    {</p>
                            <p style="margin-left:90px">        <span class='color-pink'>name</span>: <span class='color-green'> "Sophia"</span>,</p>
                            <p style="margin-left:90px">        <span class='color-pink'>age</span>: <span class='color-green'> "24"</span>,</p>
                            <p style="margin-left:90px">        <span class='color-pink'>address</span>: <span class='color-green'> "06 Salva Street"</span></p>
                            <p style="margin-left:60px">    }</p>
                            <p style="margin-left:30px">]</p>
                            <p style="margin-left:30px"><span class='color-blue'>return</span> (</p>
                            <p style="margin-left:60px">    &lt;div className='contentPage'&gt;</p>
                            <p style="margin-left:90px; border: 2px solid yellow; padding:10px; width:300px">        &lt;<span class='color-red'>User</span> { ...userData[0]} /&gt;</p>
                            <p style="margin-left:90px; padding:10px">        &lt;<span class='color-red'>User</span> { ...userData[1]} /&gt;</p>
                            <p style="margin-left:90px; border: 2px solid yellow; padding:10px">        &lt;<span class='color-red'>User</span> name={userData[2].name} age={userData[2].age } address={userData[2].address } /&gt;</p>
                            <p style="margin-left:60px">    &lt;/div&gt;</p>
                            <p style="margin-left:30px">)</p>
                        <p>}</p>
                        <br />
                        <p><span class='color-blue'>function</span> <span class='color-red'>User</span>({name, age, address}) {</p>
                        <p style="margin-left:30px">    <span class='color-blue'>return</span> (</p>
                        <p style="margin-left:60px">        &lt;div&gt;</p>
                        <p style="margin-left:90px">            &lt;p&gt;Hello, I am {name}&lt;/p&gt;</p>
                        <p style="margin-left:90px">            &lt;p&gt;My age is {age}&lt;/p&gt;</p>
                        <p style="margin-left:90px">            &lt;p&gt;My address is {address}&lt;/p&gt;</p>
                        <p style="margin-left:60px">        &lt;/div&gt;</p>
                        <p style="margin-left:30px">    )</p>
                        <p>}</p>`
                        }}></div>
                    </div>
                    <p>We can pass props both way to User component as above. </p>
                </div>
            </div>
            <div className='mt-5 mb-4'>
                <h5>Default Prop Values</h5>
                <div className='mt-4 mb-4'>
                    <p>Sometimes, we may build components that receive an optional prop. For example, We have an <strong>`User`</strong> component that has a props named <strong>`role`</strong> </p>
                    <div className='codePalateBox mt-2 mb-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                            <p>&lt;<span class="color-red">User</span> name=<span class="color-green">"Harry"</span> role=<span class="color-green">"Customer"</span> /&gt;
                            `
                        }}></div>
                    </div>
                    <p>Or without having role props</p>
                    <div className='codePalateBox mt-2 mb-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                            <p>&lt;<span class="color-red">User</span> name=<span class="color-green">"John"</span> /&gt;
                            `
                        }}></div>
                    </div>
                    <p>Then to make this <strong>`User`</strong> component work, you might want to set a default value for this <strong>`role`</strong> prop - if you don't pass it.</p>
                    <div className='codePalateBox mt-2 mb-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                            <p><span class="color-blue">function</span> <span class="color-red">User</span>({name, role=<span class="color-green">"admin"</span>}){</p>
                            <p style="margin-left:30px"> <span class="color-grey">// name has no default value, but role have a default value "admin", if we don't pass role prop it takes default value "admin" when destructuring.</span>
                            <p>}</p>
                            `
                        }}></div>
                    </div>
                </div>
            </div>
        </section>
    )
}
