import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/css/border";

export default function Border() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("What is Border in CSS ? | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/css/background',
            'next': '/css/border-width'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>Border in CSS</h3>
            <div className='mt-4 mb-5'>
                <p>
                    A border in CSS is a property that allows you to add a line around an element's content, padding, and optionally its margin. It helps to define the boundaries of an element, making it stand out visually. Borders can be styled in various ways, including different widths, styles, and colors.
                </p>

                <h5 className="mt-5 mb-3">How a Border Looks Like</h5>
                <p>Here is the some sample of a border.</p>
                <div className='outputPalateBox mb-4 mt-3' style={{'border':'none'}}>
                    <div className='outputPalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p style="border:1px solid #CCC; padding: 10px 20px;">I am a solid all sides border.</p>
                            <p style="border:2px dashed #007BFF; padding: 10px 20px;">I am a dashed border.</p>
                            <p style="border:2px dotted #28A745; padding: 10px 20px;">I am a dotted border.</p>
                            <p style="border:2px double #DC3545; padding: 10px 20px;">I am a double border.</p>
                            <p style="border:2px groove #FFC107; padding: 10px 20px;">I am a groove border.</p>
                            <p style="border:2px solid #6C757D; padding: 10px 20px;border-radius: 15px;">I am a rounded border.</p>
                            <p style="border:1px solid #343A40; padding: 10px 20px;background-color: #a1a6ac;">I am a border with background color.</p>
                            <p style="border-left: 5px solid #007BFF; padding: 10px 20px;background-color: #abcaeb"">I am a border on left side.</p>
                        `
                    }}></div>
                </div>

                <h5 className="mt-5 mb-3">Border Style</h5>
                <p>
                    The <strong>`border-style`</strong> property in CSS specifies the style of the border of an element. It determines how the border will appear and can be customized for each side of the element (top, right, bottom, and left).
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>selector {</p>
                            <p class="ml-30">    <span class="color-green">border-style</span>: value;</p>
                            <p>}</p>
                        `
                    }}></div>
                </div>
                <p>
                    You can set the border style for all sides at once or specify different styles for each side individually.
                </p>
                <h5 className="mt-5 mb-3">Commonly Used Values and Their Uses</h5>
                <p>
                    There are many <strong>`border-style`</strong> property values. They are -
                </p>
                <table className='table table-striped'>
                    <thead>
                        <th>Values</th>
                        <th>Uses</th>
                    </thead>
                    <tbody>
                        <tr>
                            <td>none</td>
                            <td>No border is displayed.</td>
                        </tr>
                        <tr>
                            <td>solid</td>
                            <td>A solid, single-colored border.</td>
                        </tr>
                        <tr>
                            <td>dotted</td>
                            <td>A border consisting of dots.</td>
                        </tr>
                        <tr>
                            <td>dashed</td>
                            <td>A border consisting of dashes.</td>
                        </tr>
                        <tr>
                            <td>double</td>
                            <td>Two solid lines. The border-width value specifies the width of the combined lines.</td>
                        </tr>
                        <tr>
                            <td>groove</td>
                            <td>A 3D grooved border. The effect depends on the border-color value.</td>
                        </tr>
                        <tr>
                            <td>ridge</td>
                            <td>A 3D ridged border. The effect depends on the border-color value.</td>
                        </tr>
                        <tr>
                            <td>inset</td>
                            <td>A 3D inset border. The effect depends on the border-color value.</td>
                        </tr>
                        <tr>
                            <td>outset</td>
                            <td>A 3D outset border. The effect depends on the border-color value.</td>
                        </tr>
                    </tbody>
                </table>

                <h5 className="mt-5 mb-3">Individual Border Styles</h5>
                <p>
                    You can also set the border style for each side individually using the following properties:
                </p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><strong>`border-top-style`</strong> : It sets style in top border of an element.</li>
                    <li><strong>`border-right-style`</strong> : It sets style in right border of an element.</li>
                    <li><strong>`border-bottom-style`</strong>  : It sets style in bottom border of an element.</li>
                    <li><strong>`border-left-style`</strong> : It sets style in left border of an element.</li>
                </ul>
                <p>
                    You can even set different style for border in one element -
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>&lt;style&gt;</p>
                            <p class="ml-30">    .divElement {</p>
                            <p class="ml-60">        <span class="color-green">border-top-style</span>: solid;</p>
                            <p class="ml-60">        <span class="color-green">border-right-style</span>: dotted;</p>
                            <p class="ml-60">        <span class="color-green">border-bottom-style</span>: dashed;</p>
                            <p class="ml-60">        <span class="color-green">border-left-style</span>: double;</p>
                            <p class="ml-60">        <span class="color-green">padding</span>: <span class="color-pink">25px</span>;</p>
                            <p class="ml-30">    }</p>
                            <p>&lt;/style&gt;</p>
                            <p>&lt;div class="divElement"&gt;This is example text&lt;/div&gt;</p>
                        `
                    }}></div>
                </div>
                <p>
                    Output will be the following -
                </p>
                <div className='outputPalateBox mb-4 mt-3' style={{'border':'none'}}>
                    <div className='outputPalate' dangerouslySetInnerHTML={{
                        __html: `
                        <style>
                            .divElement {
                                border-top-style: solid;
                                border-right-style: dotted;
                                border-bottom-style: dashed;
                                border-left-style: double;
                                padding: 25px;
                            }
                        </style>
                        <div class="divElement">This is example text</div>
                        `
                    }}></div>
                </div>
            </div>
        </section>
    )
}