import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../../Context";
import { Link } from 'react-router-dom';
export const pageUrl = () => "/mysql/arithmetic-operators/subtraction";

export default function Subtraction() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Mysql - Arithmetic Operators - Subtraction(-) | Aspirant's Home");
        path.setPreviousNext({});
    }, [])

    return (
        <>
            <section class='clearfix mb-3'>
                <span className='float-start'>
                    <Link to='/mysql/arithmetic-operators' onClick={() => path.setPathName('/mysql/arithmetic-operators')} className='btn btn-primary'> <i class="fa-solid fa-less-than"></i>&nbsp; Back to Arithmetic Operators </Link>
                </span>
            </section>
            <section className='mt-5 mb-5'>
                <h3>Mysql - Subtraction(-) Operator</h3>
                <div className='mt-4 mb-5'>
                    <p>
                        In MySQL, the subtraction operator (<strong>`-`</strong>) is used to subtract one numeric value from another. It can be used in various contexts, such as in SELECT queries, UPDATE statements, and when defining columns in CREATE TABLE statements.
                    </p>
                    <p>
                        Here's a basic example of using the subtraction operator in a SELECT query:
                    </p>
                    <div className='codePalateBox mt-2 mb-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                                <p><span class="color-blue">SELECT</span> <span class="color-pink">20</span> - <span class="color-pink">8</span>;</p>
                            `
                        }}></div>
                    </div>
                    <p>
                        This query will return the result of subtracting 8 from 20, which is 12.
                    </p>
                </div>
                <div className='mt-5 mb-5'>
                    <h5>Subtraction(-) with MySQL Table</h5>
                    <div className='mt-3 mb-2'>
                        <p>
                            Consider a table <strong>`inventory`</strong> with columns <strong>`product_name`</strong>, <strong>`quantity_in_stock`</strong>, and <strong>`quantity_sold`</strong>.
                        </p>
                        <table className='table' style={{border: '1px dashed #ccc'}}> 
                            <thead>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>id</th>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>product_name</th>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>quantity_in_stock</th>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>quantity_sold</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>1</td>
                                    <td style={{border: '1px dashed #ccc'}}>Laptop</td>
                                    <td style={{border: '1px dashed #ccc'}}>100</td>
                                    <td style={{border: '1px dashed #ccc'}}>10</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>2</td>
                                    <td style={{border: '1px dashed #ccc'}}>Phone</td>
                                    <td style={{border: '1px dashed #ccc'}}>500</td>
                                    <td style={{border: '1px dashed #ccc'}}>50</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>3</td>
                                    <td style={{border: '1px dashed #ccc'}}>Tablet</td>
                                    <td style={{border: '1px dashed #ccc'}}>1000</td>
                                    <td style={{border: '1px dashed #ccc'}}>100</td>
                                </tr>
                            </tbody>
                        </table>
                        <p>
                            If you want to calculate the remaining quantity in stock after subtracting the quantity sold, you can use the subtraction operator as follows:
                        </p>
                        <div className='codePalateBox mt-2 mb-2'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-blue">SELECT</span> product_name, quantity_in_stock, quantity_sold, (quantity_in_stock - quantity_sold) <span class="color-blue">AS</span> remaining_quantity <span class="color-blue">FROM</span> inventory;</p>
                                `
                            }}></div>
                        </div>
                        <p>
                            This query will return:
                        </p>
                        <table className='table' style={{border: '1px dashed #ccc'}}> 
                            <thead>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>id</th>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>product_name</th>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>quantity_in_stock</th>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>quantity_sold</th>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>remaining_quantity</th>
                                </tr>
                            </thead>
                            <tbody>
                            <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>1</td>
                                    <td style={{border: '1px dashed #ccc'}}>Laptop</td>
                                    <td style={{border: '1px dashed #ccc'}}>100</td>
                                    <td style={{border: '1px dashed #ccc'}}>10</td>
                                    <td style={{border: '1px dashed #ccc'}}>90</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>2</td>
                                    <td style={{border: '1px dashed #ccc'}}>Phone</td>
                                    <td style={{border: '1px dashed #ccc'}}>500</td>
                                    <td style={{border: '1px dashed #ccc'}}>50</td>
                                    <td style={{border: '1px dashed #ccc'}}>450</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>3</td>
                                    <td style={{border: '1px dashed #ccc'}}>Tablet</td>
                                    <td style={{border: '1px dashed #ccc'}}>1000</td>
                                    <td style={{border: '1px dashed #ccc'}}>100</td>
                                    <td style={{border: '1px dashed #ccc'}}>900</td>
                                </tr>
                            </tbody>
                        </table>
                        <p>
                            In this example, the expression <strong>`(quantity_in_stock - quantity_sold)`</strong> uses the subtraction operator (<strong>`-`</strong>) to subtract the <strong>`quantity_sold`</strong> from the <strong>`quantity_in_stock`</strong>, calculating the remaining quantity in stock. The result is displayed in a new column named <strong>`remaining_quantity`</strong>.
                        </p>
                    </div>
                </div>
            </section>
        </>
    )
}