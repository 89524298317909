import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../../Context";
import { Link } from 'react-router-dom';
export const pageUrl = () => "/mysql/date-and-time-functions/str-to-date";

export default function StrToDate() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Mysql - Date and Time functions - STR_TO_DATE() | Aspirant's Home");
        path.setPreviousNext({});
    }, [])

    return (
        <>
            <section class='clearfix mb-3'>
                <span className='float-start'>
                    <Link to='/mysql/date-and-time-functions' onClick={() => path.setPathName('/mysql/date-and-time-functions')} className='btn btn-primary'> <i class="fa-solid fa-less-than"></i>&nbsp; Back to Date and Time functions </Link>
                </span>
            </section>
            <section className='mt-5 mb-5'>
                <h3>Mysql - STR_TO_DATE() Functions</h3>
                <div className='mt-4 mb-5'>
                    <p>
                        The <strong>`STR_TO_DATE()`</strong> function in MySQL is used to convert a string into a date value based on a specified format. It takes two arguments: the string value and the format string that specifies the format of the input string. Here's the basic syntax:
                    </p>
                    <div className='codePalateBox mt-2 mb-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                                <p>STR_TO_DATE(str, format)</p>
                            `
                        }}></div>
                    </div>
                    <p>
                        Where, <strong>`str`</strong> is the input string that you want to convert into a date. <strong>`format`</strong> is the format string that specifies the format of the input string. It uses the same format specifiers as the <strong>`DATE_FORMAT()`</strong> function.
                    </p>
                    <p>
                        For example, if you have a string '2022-05-15' representing a date in the format 'YYYY-MM-DD' and you want to convert it into a date value, you would use:
                    </p>
                    <div className='codePalateBox mt-2 mb-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                                <p><span class="color-blue">SELECT</span> STR_TO_DATE(<span class="color-green">'2022-05-15'</span>, <span class="color-green">'%Y-%m-%d'</span>);</p>
                            `
                        }}></div>
                    </div>
                    <p>
                        This would return a date value '2022-05-15'. The <strong>`%Y`</strong>, <strong>`%m`</strong>, and <strong>`%d`</strong> are format specifiers for the year, month, and day parts of the date respectively. They indicate that the input string is in the format 'YYYY-MM-DD'.
                    </p>
                </div>
                <div className='mt-5 mb-5'>
                    <h5>STR_TO_DATE() with MySQL Table</h5>
                    <div className='mt-4 mb-2'>
                        <p>
                            To demonstrate the <strong>`STR_TO_DATE()`</strong> function in MySQL with a table using a different date format, let's consider a table <strong>`tasks`</strong> with columns <strong>`id`</strong>, <strong>`task_name`</strong>, and <strong>`due_date_str`</strong>, where <strong>`due_date_str`</strong> is a string representing the due date of each task in the format <strong>`'DD/MM/YYYY'`</strong>. We want to convert this string into a date format using <strong>`STR_TO_DATE()`</strong>. Here's an example:
                        </p>
                        <p>
                            Consider the <strong>`tasks`</strong> table:
                        </p>
                        <table className='table' style={{border: '1px dashed #ccc'}}> 
                            <thead>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>id</th>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>task_name</th>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>due_date_str</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>1</td>
                                    <td style={{border: '1px dashed #ccc'}}>Task A</td>
                                    <td style={{border: '1px dashed #ccc'}}>'15/05/2022'</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>2</td>
                                    <td style={{border: '1px dashed #ccc'}}>Task B</td>
                                    <td style={{border: '1px dashed #ccc'}}>'20/06/2022'</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>3</td>
                                    <td style={{border: '1px dashed #ccc'}}>Task C</td>
                                    <td style={{border: '1px dashed #ccc'}}>'10/07/2022'</td>
                                </tr>
                            </tbody>
                        </table>
                        <p>
                            To convert the <strong>`due_date_str`</strong> column into a date format, you would use:
                        </p>
                        <div className='codePalateBox mt-2 mb-2'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-blue">SELECT</span> id, task_name, STR_TO_DATE(due_date_str, <span class="color-green">'%d/%m/%Y'</span>) <span class="color-blue">AS</span> due_date <span class="color-blue">FROM</span> tasks;</p>
                                `
                            }}></div>
                        </div>
                        <p>
                            This query will return:
                        </p>
                        <table className='table' style={{border: '1px dashed #ccc'}}> 
                            <thead>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>id</th>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>task_name</th>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>due_date</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>1</td>
                                    <td style={{border: '1px dashed #ccc'}}>Task A</td>
                                    <td style={{border: '1px dashed #ccc'}}>2022-05-15</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>2</td>
                                    <td style={{border: '1px dashed #ccc'}}>Task B</td>
                                    <td style={{border: '1px dashed #ccc'}}>2022-06-20</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>3</td>
                                    <td style={{border: '1px dashed #ccc'}}>Task C</td>
                                    <td style={{border: '1px dashed #ccc'}}>2022-07-10</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </section>
        </>
    )
}