import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Outlet, Link } from "react-router-dom";
export const pageUrl = () => "/mysql/create-users";

export default function MysqlCreateUser() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Mysql - Create Users | Aspirant's Home");
        const urls = {
            'previous': '/mysql/show-database',
            'next':'/mysql/drop-users'
        }
        path.setPreviousNext(urls);
    },[])

    return (
        <section className='mt-5 mb-5'>
            <h3>Mysql - Create Users</h3>
            <div className='mt-4 mb-4'>
                <p>
                    To create a new user in MySQL, you can use the <strong>`CREATE USER`</strong> statement followed by the username and the host from which the user is allowed to connect. Here's the basic syntax:
                </p>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-blue">CREATE USER</span> <span class="color-green">'username'</span>@<span class="color-green">'host'</span> IDENTIFIED <span class="color-blue">BY</span> <span class="color-green">'password'</span>;</p>
                        `
                    }}></div>
                </div>
                <p> 
                    Where, <strong>`username`</strong> The username you want to create.<br />
                    <strong>`host`</strong> The host from which the user is allowed to connect.<br />
                    <strong>`password`</strong> The password for the user.
                </p>
                <p>
                    For example, to create a user named <strong>`newuser`</strong> with a password <strong>`password`</strong> that can connect from any host, you would use the following SQL statement:
                </p>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-blue">CREATE USER</span> <span class="color-green">'newuser'</span>@<span class="color-green">'%'</span> IDENTIFIED <span class="color-blue">BY</span> <span class="color-green">'password'</span>;</p>
                        `
                    }}></div>
                </div>
                <p>
                    After creating the user, you need to <Link to="/mysql/grant-privileges">grant the necessary privileges</Link> to the user.
                </p>
            </div>
        </section>
    )
}