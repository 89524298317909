import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/node-js/mongodb-insert-query";

export default function MongoDbInsertQuery() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Node Js - MongoDB - Insert Query | Aspirant's Home");
        const urls = {
            'previous': '/node-js/mongodb-create-collection',
            'next': '/node-js/mongodb-find-query'
        }
        path.setPreviousNext(urls);
    }, [])
    
    return (
        <section className='mt-5 mb-5'>
            <h3>Node Js MongoDB Insert Document</h3>
            <div className='mt-5 mb-5'>
                <p>To insert a document into a collection in MongoDB using Node.js, you can use the <strong>`insertOne`</strong> or <strong>`insertMany`</strong> methods provided by the mongodb package. Here's an example, how to insert a single document into a collection:</p>
                <div className='featureClass mb-3'>
                    Note : In MongoDB, documents are basically same as tables in Mysql.
                </div>
                <p>Ok, lets see how we insert a document in MongoDB.</p>
            </div>
            <div className='mb-5'>
                <h5>Inserting One Document</h5>
                <p className='mt-3'>We can insert one single document to the collection using <strong>`insertOne`</strong> method.</p>
                <p>The <strong>`insertOne()`</strong> method's parameter is an object that contains the name(s) and value(s) of each field in the document you want to insert in a json format like below </p>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html:
                            `<p>var myobj = { employee_name: "Sudhir Sen", email: "sudhir@gmail.com" };</p>`
                    }}>
                    </div>
                </div>
                <p>It also accepts a callback function where you can handle any errors or the result of the insertion:</p>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html:
                            `<p><span class='color-blue'>var</span> MongoClient = <span class='color-yellow'>require</span>(<span class='color-green'>'mongodb'</span>).MongoClient ;</p>
                            <p><span class='color-blue'>var</span> url = <span class='color-green'>"mongodb://localhost:27017/"</span> ;</p>
                            <br />
                            <p>MongoClient.<span class='color-red'>connect</span>(url, function(err, db) {</p>
                            <p style='margin-left:30px'>  <span class='color-blue'>if</span> (err) throw err;</p>
                            <p style='margin-left:30px'>  <span class='color-blue'>var</span> dbo = db.<span class='color-red'>db</span>(<span class='color-green'>"mymongodbdatabase"</span>);</p>
                            <p style='margin-left:30px'>  <span class='color-blue'>var</span> myobj = { <span class='color-red'>employee_name</span>: <span class='color-green'>"Sudhir Sen"</span>, <span class='color-red'>email</span>: <span class='color-green'>"sudhir@gmail.com"</span> } ;</p>
                                <p style='margin-left:30px'> dbo.<span class='color-red'>collection</span>(<span class='color-green'>"employees"</span>).<span class='color-red'>insertOne</span>(myobj, <span class='color-blue'>function</span>(err, res) {</p>
                                <p style='margin-left:60px'>   <span class='color-blue'>if</span> (err) throw err;</p>
                                <p style='margin-left:60px'>  <span class='color-pink'>console</span>.<span class='color-red'>log</span>(<span class='color-green'>"1 document inserted"</span>);</p>
                                <p style='margin-left:60px'>   db.<span class='color-red'>close</span>();</p>
                                <p style='margin-left:30px'> });</p>
                            <p>});</p>
                            `
                    }}>
                    </div>
                </div>
                <p>Which will give you this result:</p>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `1 document inserted`
                    }}></div>
                </div>
            </div>

            <div className='mb-5'>
                <h5>Inserting Multiple Document</h5>
                <p className='mt-3'>We can insert multiple document to the collection using <strong>`insertMany`</strong> method.</p>
                <p>The <strong>`insertMany()`</strong> method's parameter is an array of objects that contains the name(s) and value(s) of each field in the document you want to insert in a json format like below </p>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html:
                            `<p>var myobj = [</p>
                            <p style='margin-left:30px'> { employee_name: "Sudhir", email: "sudhir@gmail.com" },</p>
                            <p style='margin-left:30px'> { employee_name: "Pradip", email: "pradip@gmail.com" },</p>
                            <p style='margin-left:30px'> { employee_name: "Rachel", email: "rachel@gmail.com" },</p>
                            <p style='margin-left:30px'> { employee_name: "Benjamin", email: "benjamin@gmail.com" },</p>
                            <p style='margin-left:30px'> { employee_name: "Samantha", email: "samantha@gmail.com" },</p>
                            <p style='margin-left:30px'> { employee_name: "Charlotte", email: "charlotte@gmail.com" },</p>
                            <p style='margin-left:30px'> { employee_name: "Gregg", email: "gregg@gmail.com" },</p>
                            <p style='margin-left:30px'> { employee_name: "Stacie", email: "stacie@gmail.com" },</p>
                            <p style='margin-left:30px'> { employee_name: "Eleanor", email: "eleanor@gmail.com" },</p>
                            <p style='margin-left:30px'> { employee_name: "Horton", email: "horton@gmail.com" },</p>
                            <p>];</p>`
                    }}>
                    </div>
                </div>
                <p>It also accepts a callback function where you can handle any errors or the result of the insertion:</p>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html:
                            `<p><span class='color-blue'>var</span> MongoClient = <span class='color-yellow'>require</span>(<span class='color-green'>'mongodb'</span>).MongoClient ;</p>
                            <p><span class='color-blue'>var</span> url = <span class='color-green'>"mongodb://localhost:27017/"</span> ;</p>
                            <br />
                            <p>MongoClient.<span class='color-red'>connect</span>(url, function(err, db) {</p>
                            <p style='margin-left:30px'>  <span class='color-blue'>if</span> (err) throw err;</p>
                            <p style='margin-left:30px'>  <span class='color-blue'>var</span> dbo = db.<span class='color-red'>db</span>(<span class='color-green'>"mymongodbdatabase"</span>);</p>
                            <p style='margin-left:30px'>  <span class='color-blue'>var</span> myobj = </p>
                            <p style='margin-left:30px'>[</p>
                            <p style='margin-left:60px'>{ <span class='color-red'>employee_name</span>: <span class='color-green'>"Sudhir"</span>, <span class='color-red'>email</span>: <span class='color-green'>"sudhir@gmail.com"</span> } </p>
                            <p style='margin-left:60px'>{ <span class='color-red'>employee_name</span>: <span class='color-green'>"Pradip"</span>, <span class='color-red'>email</span>: <span class='color-green'>"pradip@gmail.com"</span> } </p>
                            <p style='margin-left:60px'>{ <span class='color-red'>employee_name</span>: <span class='color-green'>"Rachel"</span>, <span class='color-red'>email</span>: <span class='color-green'>"rachel@gmail.com"</span> } </p>
                            <p style='margin-left:60px'>{ <span class='color-red'>employee_name</span>: <span class='color-green'>"Benjamin"</span>, <span class='color-red'>email</span>: <span class='color-green'>"benjamin@gmail.com"</span> } </p>
                            <p style='margin-left:60px'>{ <span class='color-red'>employee_name</span>: <span class='color-green'>"Samantha"</span>, <span class='color-red'>email</span>: <span class='color-green'>"samantha@gmail.com"</span> } </p>
                            <p style='margin-left:60px'>{ <span class='color-red'>employee_name</span>: <span class='color-green'>"Charlotte"</span>, <span class='color-red'>email</span>: <span class='color-green'>"charlotte@gmail.com"</span> } </p>
                            <p style='margin-left:60px'>{ <span class='color-red'>employee_name</span>: <span class='color-green'>"Gregg"</span>, <span class='color-red'>email</span>: <span class='color-green'>"gregg@gmail.com"</span> } </p>
                            <p style='margin-left:60px'>{ <span class='color-red'>employee_name</span>: <span class='color-green'>"Stacie"</span>, <span class='color-red'>email</span>: <span class='color-green'>"stacie@gmail.com"</span> } </p>
                            <p style='margin-left:60px'>{ <span class='color-red'>employee_name</span>: <span class='color-green'>"Eleanor"</span>, <span class='color-red'>email</span>: <span class='color-green'>"eleanor@gmail.com"</span> } </p>
                            <p style='margin-left:60px'>{ <span class='color-red'>employee_name</span>: <span class='color-green'>"Horton"</span>, <span class='color-red'>email</span>: <span class='color-green'>"horton@gmail.com"</span> } </p>
                            <p style='margin-left:30px'>];</p>
                                <p style='margin-left:30px'> dbo.<span class='color-red'>collection</span>(<span class='color-green'>"employees"</span>).<span class='color-red'>insertMany</span>(myobj, <span class='color-blue'>function</span>(err, res) {</p>
                                <p style='margin-left:60px'>   <span class='color-blue'>if</span> (err) throw err;</p>
                                <p style='margin-left:60px'>  <span class='color-pink'>console</span>.<span class='color-red'>log</span>(<span class='color-green'>"10 document inserted"</span>);</p>
                                <p style='margin-left:60px'>   db.<span class='color-red'>close</span>();</p>
                                <p style='margin-left:30px'> });</p>
                            <p>});</p>
                            `
                    }}>
                    </div>
                </div>
                <p>Which will give you this result:</p>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `10 document inserted`
                    }}></div>
                </div>
            </div>
        </section>
    )
}
