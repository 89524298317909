import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../../Context";
import { Link } from 'react-router-dom';
export const pageUrl = () => "/mysql/date-and-time-functions/sec-to-time";

export default function SecToTime() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Mysql - Date and Time functions - SEC_TO_TIME() | Aspirant's Home");
        path.setPreviousNext({});
    }, [])

    return (
        <>
            <section class='clearfix mb-3'>
                <span className='float-start'>
                    <Link to='/mysql/date-and-time-functions' onClick={() => path.setPathName('/mysql/date-and-time-functions')} className='btn btn-primary'> <i class="fa-solid fa-less-than"></i>&nbsp; Back to Date and Time functions </Link>
                </span>
            </section>
            <section className='mt-5 mb-5'>
                <h3>Mysql - SEC_TO_TIME() Functions</h3>
                <div className='mt-4 mt-5'>
                    <p>
                        In MySQL, the <strong>`SEC_TO_TIME()`</strong> function is used to convert a number of seconds into a time format. It takes a number of seconds as input and returns a time value in 'HH:MM:SS' format.
                    </p>
                    <p>
                        Here's the basic syntax:
                    </p>
                    <div className='codePalateBox mt-2 mb-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                                <p>SEC_TO_TIME(seconds)</p>
                            `
                        }}></div>
                    </div>
                    <p>
                        Where, <strong>`seconds`</strong> is the number of seconds you want to convert into a time value.
                    </p>
                    <p>
                        For example, if you have a total number of seconds representing a duration and you want to convert it into a time format, you would use <strong>`SEC_TO_TIME()`</strong>.
                    </p>
                    <p>
                        For instance, to convert 3661 seconds (which is equivalent to 1 hour, 1 minute, and 1 second) into a time value, you would use:
                    </p>
                    <div className='codePalateBox mt-2 mb-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                                <p><span class="color-blue">SELECT</span> SEC_TO_TIME(<span class="color-pink">3661</span>);</p>
                            `
                        }}></div>
                    </div>
                    <p>
                        This would return '01:01:01', indicating 1 hour, 1 minute, and 1 second.
                    </p>
                </div>
                <div className='mt-5 mb-5'>
                    <h5>SEC_TO_TIME() with MySQL Table</h5>
                    <div className='mt-4 mb-2'>
                        <p>
                            To demonstrate the usage of the <strong>`SEC_TO_TIME()`</strong> function in MySQL with a query, let's consider a scenario where we have a table <strong>`duration`</strong> with a column <strong>`total_seconds`</strong> representing the duration in seconds. We want to convert these total seconds into a time format. Here's an example query:
                        </p>
                        <p>
                            Assuming the <strong>`duration`</strong> table:
                        </p>
                        <table className='table' style={{border: '1px dashed #ccc'}}> 
                            <thead>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>total_seconds</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>3661</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>7200</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>18000</td>
                                </tr>
                            </tbody>
                        </table>
                        <p>
                            To convert these total seconds into a time format, you would use:
                        </p>
                        <div className='codePalateBox mt-2 mb-2'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-blue">SELECT</span> SEC_TO_TIME(total_seconds) <span class="color-blue">AS</span> time_format</p>
                                    <p><span class="color-blue">FROM</span> duration;</p>
                                `
                            }}></div>
                        </div>
                        <p>
                            This query will return:
                        </p>
                        <table className='table' style={{border: '1px dashed #ccc'}}> 
                            <thead>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>time_format</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>01:01:01</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>02:00:00</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>05:00:00</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </section>
        </>
    )
}