import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/html/editors";

export default function HTMLEditors() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("HTML - Editors | Aspirant's Home");
        const urls = {
            'previous': '/html/introduction',
            'next': '/html/basic-tags'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>HTML - Editors</h3>
            <div className='mt-4 mb-5'>
                <p>
                    HTML editors are software tools that help developers create and edit HTML code for building web pages. They provide various features to make the coding process easier, more efficient, and less error-prone. 
                </p>
                <h5 className='mt-4 mb-3'>Examples of Mostly Used Text-Based HTML Editors</h5>
                <ul style={{'listStyle':'disc'}}>
                    <li><strong>Visual Studio Code</strong>: A powerful, open-source editor with extensive extensions for web development.</li>
                    <li><strong>Sublime Text</strong>: Known for its speed and simplicity, offering a wide range of plugins.</li>
                    <li><strong>Atom</strong>: A hackable editor developed by GitHub, customizable with various packages.</li>
                </ul>
                <p>
                    You can also learn HTML from your Notepad or TextEdit.
                </p>
                <h5 className='mt-4 mb-3'>Learn HTML Using Notepad or TextEdit</h5>
                <p>
                    Follow the steps below to create your first web page with Notepad or TextEdit.
                </p>
                <h6 className='mt-4 mb-3'>Step 1: Open Notepad/TextEdit</h6>
                <p>
                    Open a new document to place the code.
                </p>
                <h6 className='mt-4 mb-3'>Step 2: Write Some HTML</h6>
                <p>
                    Write or copy the following HTML code into Notepad/TextEdit:
                </p>
                <div className='codePalateBox mt-2 mb-5'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>&lt;!DOCTYPE <span class="color-blue">html</span>&gt;</p>
                            <p><span class="color-blue">&lt;html&gt;</span></p>
                            <p>    <span class="color-blue">&lt;body&gt;</span></p>
                            <br />    
                            <p>        <span class="color-blue">&lt;h1&gt;</span>My First Heading<span class="color-blue">&lt;/h1&gt;</span></p>
                            <br />        
                            <p>        <span class="color-blue">&lt;p&gt;</span>My first paragraph.<span class="color-blue">&lt;/p&gt;</span></p>
                            <br />    
                            <p>    <span class="color-blue">&lt;/body&gt;</span></p>
                            <p><span class="color-blue">&lt;/html&gt;</span></p>
                        `
                    }}></div>
                </div>
                <div className='mt-3 myImage'>
                    <img className='img-fluid' src='../assets/html/html-editor.png' />
                </div>
                <h6 className='mt-4 mb-3'>Step 3: Save the HTML Page</h6>
                <p>
                    Save the file on your computer. Name the file "index.html"
                </p>
                <h6 className='mt-4 mb-3'>Step 4: View the HTML Page in Your Browser</h6>
                <p>
                    Open the saved HTML file in your favorite browser. Your result will be like this -
                </p>
                <div className='mt-3 myImage'>
                    <img className='img-fluid' src='../assets/html/html-results.png' />
                </div>
            </div>
        </section>
    )
}