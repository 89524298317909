import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/mysql/transaction";

export default function MysqlTransaction() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Mysql - Transaction | Aspirant's Home");
        const urls = {
            'previous': '/mysql/aggregate-functions',
            'next': '/mysql/sequences'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>Mysql - Transaction</h3>
            <div className='mt-4 mb-5'>
                <p>
                    A MySQL transaction is a sequence of one or more SQL statements that are executed as a single unit of work. Transactions ensure that the database remains consistent and that either all operations within the transaction are successfully completed, or none are. 
                </p>
                <p>
                    Transactions are crucial for maintaining the integrity of the database, especially in applications where multiple users access and modify the data concurrently.
                </p>
            </div>
            <div className='mt-5 mb-5'>
                <h5>ACID Properties of Transactions</h5>
                <div className='mt-3 mb-5'>
                    <p>
                        Transactions in MySQL adhere to the ACID properties:
                    </p>
                    <ul style={{listStyle:'disc'}}>
                        <li>
                            <strong>Atomicity :</strong> Ensures that all operations within the transaction are completed successfully; if not, the transaction is rolled back.
                        </li>
                        <li>
                            <strong>Consistency :</strong> Ensures that the database remains in a consistent state before and after the transaction.
                        </li>
                        <li>
                            <strong>Isolation :</strong> Ensures that transactions are isolated from each other, preventing concurrent transactions from interfering with each other.
                        </li>
                        <li>
                            <strong>Durability :</strong> Ensures that the results of the transaction are permanently stored in the database, even in the event of a system failure.
                        </li>
                    </ul>
                </div>
            </div>
            <div className='mt-5 mb-5'>
                <h5>Basic Transaction Commands</h5>
                <div className='mt-3 mb-5'>
                    <ul style={{listStyle:'disc'}}>
                        <li>
                            <strong>`START TRANSACTION`</strong> or <strong>`BEGIN`</strong>: Begins a new transaction.
                        </li>
                        <li>
                            <strong>`COMMIT`</strong>: Saves all changes made during the transaction to the database.
                        </li>
                        <li>
                            <strong>`ROLLBACK`</strong>: Undoes all changes made during the transaction.
                        </li>
                        <li>
                            <strong>`SAVEPOINT`</strong>: Sets a point within a transaction to which you can later roll back.
                        </li>
                        <li>
                            <strong>`RELEASE SAVEPOINT`</strong>: Removes a previously defined savepoint.
                        </li>
                        <li>
                            <strong>`ROLLBACK TO SAVEPOINT`</strong>: Rolls back the transaction to the specified savepoint.
                        </li>
                    </ul>
                </div>
            </div>
            <div className='mt-5 mb-5'>
                <h5>Example of Using Transactions</h5>
                <div className='mt-3 mb-5'>
                    <p>
                        Let's consider a simple example involving two tables: <strong>`accounts`</strong> and <strong>`transactions`</strong>. The goal is to transfer money from one account to another, ensuring that either both the debit and credit operations succeed or neither does.
                    </p>
                    <p><strong>`accounts`</strong> table:</p>
                    <div className='codePalateBox mt-2 mb-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                                <p><span class="color-blue">CREATE TABLE</span> accounts (</p>
                                <p style="margin-left:30px">    id <span class="color-pink">INT</span> <span class="color-blue">PRIMARY</span> KEY,</p>
                                <p style="margin-left:30px">    name <span class="color-pink">VARCHAR</span>(<span class="color-pink">100</span>),</p>
                                <p style="margin-left:30px">    balance <span class="color-pink">DECIMAL</span>(<span class="color-pink">10</span>, <span class="color-pink">2</span>)</p>
                                <p>);</p>
                                <br />
                                <p><span class="color-blue">INSERT INTO</span> accounts (id, name, balance) <span class="color-blue">VALUES</span></p>
                                <p>(<span class="color-pink">1</span>, <span class="color-green">'Alice'</span>, <span class="color-pink">1000.00</span>),</p>
                                <p>(<span class="color-pink">2</span>, <span class="color-green">'Bob'</span>, <span class="color-pink">500.00</span>);</p>
                            `
                        }}></div>
                    </div>
                    <p><strong>`transactions`</strong> table:</p>
                    <div className='codePalateBox mt-2 mb-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                                <p><span class="color-blue">CREATE TABLE</span> transactions (</p>
                                <p style="margin-left:30px">    id <span class="color-pink">INT</span> <span class="color-blue">PRIMARY</span> KEY AUTO_INCREMENT,</p>
                                <p style="margin-left:30px">    from_account <span class="color-pink">INT</span>,</p>
                                <p style="margin-left:30px">    to_account <span class="color-pink">INT</span>,</p>
                                <p style="margin-left:30px">    amount <span class="color-pink">DECIMAL</span>(<span class="color-pink">10</span>, <span class="color-pink">2</span>),</p>
                                <p style="margin-left:30px">    transaction_date <span class="color-pink">TIMESTAMP</span> <span class="color-blue">DEFAULT</span> <span class="color-yellow">CURRENT_TIMESTAMP</span></p>
                                <p>);</p>
                            `
                        }}></div>
                    </div>
                </div>
            </div>
            <div className='mt-5 mb-5'>
                <h3>Transfer Money with a Transaction</h3>
                <div className='mt-3 mb-5'>
                    <p>
                        Here's how to perform a transaction to transfer $200 from Alice's account to Bob's account:
                    </p>
                    <div className='codePalateBox mt-2 mb-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                                <p><span class="color-blue">START</span> TRANSACTION;</p>
                                <br />
                                <p><span class="color-grey">-- Debit Alice's account</span></p>
                                <p><span class="color-blue">UPDATE</span> accounts <span class="color-blue">SET</span> balance = balance - <span class="color-pink">200</span> <span class="color-blue">WHERE</span> id = <span class="color-pink">1</span>;</p>
                                <br />
                                <p><span class="color-grey">-- Credit Bob's account</span></p>
                                <p><span class="color-blue">UPDATE</span> accounts <span class="color-blue">SET</span> balance = balance + <span class="color-pink">200</span> <span class="color-blue">WHERE</span> id = <span class="color-pink">2</span>;</p>
                                <br />
                                <p><span class="color-grey">-- Record the transaction</span></p>
                                <p><span class="color-blue">INSERT INTO</span> transactions (from_account, to_account, amount) <span class="color-blue">VALUES</span> (<span class="color-pink">1</span>, <span class="color-pink">2</span>, <span class="color-pink">200</span>);</p>
                                <br />
                                <p><span class="color-grey">-- Check for errors and commit or rollback</span></p>
                                <p>IF @<span class="color-pink">@ERROR</span> = <span class="color-pink">0</span> <span class="color-blue">THEN</span></p>
                                <p style="margin-left:30px">    <span class="color-blue">COMMIT</span>;</p>
                                <p><span class="color-blue">ELSE</span></p>
                                <p style="margin-left:30px">   <span class="color-blue">ROLLBACK</span>;</p>
                                <p><span class="color-blue">END</span> IF;</p>
                            `
                        }}></div>
                    </div>
                    <p>
                        In this example:
                    </p>
                    <ul style={{listStyle:'decimal'}}>
                        <li>We start the transaction with <strong>`START TRANSACTION`</strong>.</li>
                        <li>We update Alice's account to debit $200.</li>
                        <li>We update Bob's account to credit $200.</li>
                        <li>We record the transaction in the <strong>`transactions`</strong> table.</li>
                        <li>If all operations are successful, we commit the transaction with <strong>`COMMIT`</strong>.</li>
                        <li>If any operation fails, we roll back the transaction with <strong>`ROLLBACK`</strong>.</li>
                    </ul>
                </div>
                <div className='mt-5 mb-5'>
                    <h3>Savepoints in Transactions</h3>
                    <div className='mt-3 mb-5'>
                        <p>
                            Savepoints allow you to partially roll back a transaction to a certain point without rolling back the entire transaction.
                        </p>
                        <p>Using Savepoints:</p>
                        <div className='codePalateBox mt-2 mb-2'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-blue">START</span> TRANSACTION;</p>
                                    <br />
                                    <p><span class="color-grey">-- Debit Alice's account</span></p>
                                    <p><span class="color-blue">UPDATE</span> accounts <span class="color-blue">SET</span> balance = balance - <span class="color-pink">200</span> <span class="color-blue">WHERE</span> id = <span class="color-pink">1</span>;</p>
                                    <br />
                                    <p><span class="color-blue">SAVEPOINT</span> debit_done;</p>
                                    <br />
                                    <p><span class="color-grey">-- Credit Bob's account</span></p>
                                    <p><span class="color-blue">UPDATE</span> accounts <span class="color-blue">SET</span> balance = balance + <span class="color-pink">200</span> <span class="color-blue">WHERE</span> id = <span class="color-pink">2</span>;</p>
                                    <br />
                                    <p><span class="color-grey">-- Record the transaction</span></p>
                                    <p><span class="color-blue">INSERT INTO</span> transactions (from_account, to_account, amount) <span class="color-blue">VALUES</span> (<span class="color-pink">1</span>, <span class="color-pink">2</span>, <span class="color-pink">200</span>);</p>
                                    <br />
                                    <p><span class="color-grey">-- Check for errors</span></p>
                                    <p>IF @<span class="color-pink">@ERROR</span> != <span class="color-pink">0</span> <span class="color-blue">THEN</span></p>
                                    <p style="margin-left:30px">    <span class="color-blue">ROLLBACK TO SAVEPOINT</span> debit_done;</p>
                                    <p><span class="color-blue">END</span> IF;</p>
                                    <br />
                                    <p><span class="color-grey">-- Commit the transaction</span></p>
                                    <p><span class="color-blue">COMMIT</span>;</p>
                                `
                            }}></div>
                        </div>
                        <p>
                            In this example:    
                        </p>
                        <ul>
                            <li>We create a savepoint after debiting Alice's account.</li>
                            <li>If the credit to Bob's account or the transaction record fails, we roll back to the savepoint.</li>
                            <li>Finally, we commit the transaction if no errors occur.</li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    )
}