import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/html/styles";

export default function HTMLStyles() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("HTML - Styles | Aspirant's Home");
        const urls = {
            'previous': '/html/paragraphs',
            'next': '/html/blocks'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>HTML - Styles</h3>
            <div className='mt-4 mb-5'>
                <div>
                    HTML styles refer to the ways you can control the appearance and layout of HTML elements on a webpage. This is typically done using CSS (Cascading Style Sheets). Styles can be applied directly within HTML elements using the <strong>`style`</strong> attribute, within the <strong>`&lt;head&gt;`</strong> section using the <strong>`&lt;style&gt;`</strong> tag, or through external CSS files.
                </div>
                <h5 className='mt-5 mb-3'>Methods to Apply Styles in HTML</h5>
                <ul style={{ listStyle: 'decimal' }}>
                    <li><strong>Inline Styles</strong>: Applied directly within an HTML element using the <strong>`style`</strong> attribute.</li>
                    <li><strong>Internal Styles</strong>: Defined within the <strong>`&lt;style&gt;`</strong> tag inside the <strong>`&lt;head&gt;`</strong> section of the HTML document.</li>
                    <li><strong>External Styles</strong>: Linked to an HTML document through an external CSS file using the <strong>`&lt;link&gt;`</strong> tag.</li>
                </ul>
                <h5 className='mt-5 mb-3'>Inline Styles</h5>
                <div>
                    Inline styles are used to apply unique styles to a single HTML element. They are specified using the <strong>`style`</strong> attribute within the opening tag.
                </div>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <div>&lt;p <span class="color-pink">style</span>=<span class="color-green">"color: blue; font-size: 16px;"</span>&gt;This is a styled paragraph.&lt;/p&gt;</div>
                        `
                    }}></div>
                </div>
                <h5 className='mt-5 mb-3'>Internal Styles</h5>
                <div>
                    Internal styles are defined within the <strong>`&lt;style&gt;`</strong> tag, which is placed in the <strong>`&lt;head&gt;`</strong> section of the HTML document. These styles apply to the entire document.
                </div>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <div><span class="color-grey">&lt;!DOCTYPE</span> <span class="color-blue">html</span><span class="color-grey">&gt;</span></div>
                        <div>&lt;html <span class="color-pink">lang</span>=<span class="color-green">"en"</span>&gt;</div>
                        <div class="ml-30">    &lt;head&gt;</div>
                        <div class="ml-60">        &lt;meta <span class="color-pink">charset</span>=<span class="color-green">"UTF-8"</span>&gt;</div>
                        <div class="ml-60">        &lt;meta <span class="color-pink">name</span>=<span class="color-green">"viewport"</span> <span class="color-pink">content</span>=<span class="color-green">"width=device-width, initial-scale=1.0"</span>&gt;</div>
                        <div class="ml-60">        &lt;title&gt;Internal Styles Example&lt;/title&gt;</div>
                        <div class="ml-60">        &lt;style&gt;</div>
                        <div class="ml-90">            body {</div>
                        <div class="ml-120">                <span class="color-green">font-family</span>: Arial, sans-serif;</div>
                        <div class="ml-90">            }</div>
                        <div class="ml-90">            h1 {</div>
                        <div class="ml-120">                <span class="color-green">color</span>: green;</div>
                        <div class="ml-90">            }</div>
                        <div class="ml-90">            p {</div>
                        <div class="ml-120">                <span class="color-green">color</span>: blue;</div>
                        <div class="ml-120">                <span class="color-green">font-size</span>: <span class="color-pink">16px</span>;</div>
                        <div class="ml-90">            }</div>
                        <div class="ml-60">        &lt;/style&gt;</div>
                        <div class="ml-30">    &lt;/head&gt;</div>
                        <div class="ml-30">    &lt;body&gt;</div>
                        <div class="ml-60">        &lt;h1&gt;Internal Styles Example&lt;/h1&gt;</div>
                        <div class="ml-60">        &lt;p&gt;This paragraph is styled using internal CSS.&lt;/p&gt;</div>
                        <div class="ml-30">    &lt;/body&gt;</div>
                        <div>&lt;/html&gt;</div>
                        `
                    }}></div>
                </div>
                <div>
                    Output will be the following -
                </div>
                <div className='codePalateBox mt-2 mb-4' style={{ 'background': '#FFF', 'border': '1px solid #000' }}>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <!DOCTYPE html>
                        <html lang="en">
                            <head>
                                <meta charset="UTF-8">
                                <meta name="viewport" content="width=device-width, initial-scale=1.0">
                                <title>Internal Styles Example</title>
                                <style>
                                    body {
                                        font-family: Arial, sans-serif;
                                    }
                                    h1 {
                                        color: green;
                                    }
                                    p {
                                        color: blue;
                                        font-size: 16px;
                                    }
                                </style>
                            </head>
                            <body>
                                <h1>Internal Styles Example</h1>
                                <p>This paragraph is styled using internal CSS.</p>
                            </body>
                        </html>
                        `
                    }}></div>
                </div>
                <h5 className='mt-5 mb-3'>External Styles</h5>
                <div>
                    External styles are defined in an external CSS file, which is linked to the HTML document using the <strong>`&lt;link&gt;`</strong> tag. This method is preferred for maintaining and reusing styles across multiple pages
                </div>
                <h6 className='mt-3'>Example CSS File (styles.css)</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <div>body {</div>
                            <div class="ml-30">    <span class="color-green">font-family</span>: Arial, sans-serif;</div>
                            <div>}</div>
                            <div>h1 {</div>
                            <div class="ml-30">    <span class="color-green">color</span>: green;</div>
                            <div>}</div>
                            <div>p {</div>
                            <div class="ml-30">    <span class="color-green">color</span>: blue;</div>
                            <div class="ml-30">    <span class="color-green">font-size</span>: <span class="color-pink">16px</span>;</div>
                            <div>}</div>
                        `
                    }}></div>
                </div>
                <h6 className='mt-3'>HTML Document Linking to External CSS</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <div><span class="color-grey">&lt;!DOCTYPE</span> <span class="color-blue">html</span><span class="color-grey">&gt;</span></div>
                        <div>&lt;html <span class="color-pink">lang</span>=<span class="color-green">"en"</span>&gt;</div>
                        <div class="ml-30">    &lt;head&gt;</div>
                        <div class="ml-60">        &lt;meta <span class="color-pink">charset</span>=<span class="color-green">"UTF-8"</span>&gt;</div>
                        <div class="ml-60">        &lt;meta <span class="color-pink">name</span>=<span class="color-green">"viewport"</span> <span class="color-pink">content</span>=<span class="color-green">"width=device-width, initial-scale=1.0"</span>&gt;</div>
                        <div class="ml-60">        &lt;title&gt;External Styles Example&lt;/title&gt;</div>
                        <div class="ml-60">        &lt;link <span class="color-pink">rel</span>=<span class="color-green">"stylesheet"</span> <span class="color-pink">href</span>=<span class="color-green">"styles.css"</span>&gt;</div>
                        <div class="ml-30">    &lt;/head&gt;</div>
                        <div class="ml-30">    &lt;body&gt;</div>
                        <div class="ml-60">        &lt;h1&gt;Internal Styles Example&lt;/h1&gt;</div>
                        <div class="ml-60">        &lt;p&gt;This paragraph is styled using internal CSS.&lt;/p&gt;</div>
                        <div class="ml-30">    &lt;/body&gt;</div>
                        <div>&lt;/html&gt;</div>
                        `
                    }}></div>
                </div>
            </div>
        </section>
    )
}