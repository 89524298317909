import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/node-js/mysql-insert-query";

export default function MysqlInsertQuery() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Node Js - Mysql - Insert Query | Aspirant's Home");
        const urls = {
            'previous': '/node-js/mysql-create-table',
            'next': '/node-js/mysql-select-query'
        }
        path.setPreviousNext(urls);
    }, [])
    
    return (
        <>
            <section className='mt-5 mb-5'>
                <h3 className='mb-4'>Node js Mysql Insert Query</h3>
                <p>In Node.js, you can use the <strong>`mysql`</strong> package to execute an <strong>`INSERT`</strong> query to insert data into a MySQL database. Here's an example:</p>

                <p>Create a Node.js script to connect to your MySQL server and insert data into a table:</p>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                    <p><span class='color-blue'>const</span> mysql = <span class='color-yellow'>require</span>(<span class='color-green'>'mysql'</span>);</p>
                    <br />
                    <p class='color-grey'>// Create a connection to the MySQL server</p>
                    <p><span class='color-blue'>const</span> connection = mysql.<span class='color-red'>createConnection</span>({</p>
                    <p style='margin-left:30px'><span class='color-pink'>host</span>: <span class='color-green'>'localhost'</span>,</p>
                    <p style='margin-left:30px'><span class='color-pink'>user</span>: <span class='color-green'>'yourusername'</span>,</p>
                    <p style='margin-left:30px'><span class='color-pink'>password</span>: <span class='color-green'>'yourpassword'</span>,</p>
                    <p style='margin-left:30px'><span class='color-pink'>database</span>: <span class='color-green'>'yourdatabase'</span></p>
                    <p>});</p>
                    <br />
                    <p class='color-grey'>// Connect to the MySQL server</p>
                    <p>connection.<span class='color-red'>connect</span>((err) => {</p>
                    <p style='margin-left:30px'><span class='color-blue'>if</span> (err) {</p>
                        <p style='margin-left:60px'><span class='color-pink'>console</span>.<span class='color-red'>error</span>(<span class='color-green'>'Error connecting to the database:'</span>, err.stack);</p>
                        <p style='margin-left:60px' class='color-blue'>return;</p>
                    <p style='margin-left:30px'>}</p>
                    <p style='margin-left:30px'><span class='color-pink'>console</span>.<span class='color-red'>log</span>(<span class='color-green'>'Connected to the database'</span>);</p>
                    <br />
                    <p style='margin-left:30px' class='color-grey'>// Insert data into the table</p>
                    <p style='margin-left:30px'><span class='color-blue'>const</span> insertQuery = 'INSERT INTO users (name, email) VALUES (?, ?)';</p>
                    <p style='margin-left:30px'><span class='color-blue'>const</span> values = ['John Doe', 'john.doe@example.com'];</p>
                    <p style='margin-left:30px'>connection.<span class='color-red'>query</span>(insertQuery, values, (err, results) => {</p>
                        <p style='margin-left:60px'><span class='color-blue'>if</span> (err) {</p>
                        <p style='margin-left:90px'><span class='color-pink'>console</span>.<span class='color-red'>error</span>(<span class='color-green'>'Error inserting data:'</span>, err.stack);</p>
                        <p style='margin-left:90px' class='color-blue'>return;</p>
                        <p style='margin-left:60px'>}</p>
                        <p style='margin-left:60px'><span class='color-pink'>console</span>.<span class='color-red'>log</span>(<span class='color-green'>'Data inserted:'</span>, results);</p>
                    <p style='margin-left:30px'>});</p>
                    <p>});</p>
                    <br />
                    <p class='color-grey'>// Close the connection</p>
                    <p>connection.<span class='color-red'>end</span>((err) => {</p>
                    <p style='margin-left:30px'><span class='color-blue'>if</span> (err) {</p>
                        <p style='margin-left:60px'><span class='color-pink'>console</span>.<span class='color-red'>error</span>(<span class='color-green'>'Error closing connection:'</span>, err.stack);</p>
                        <p style='margin-left:60px' class='color-blue'>return;</p>
                    <p style='margin-left:30px'>}</p>
                    <p style='margin-left:30px'><span class='color-pink'>console</span>.<span class='color-red'>log</span>(<span class='color-green'>'Connection closed'</span>);</p>
                    <p>});</p>

                `}}></div>
                </div>
            </section>
        </>
    )
}
