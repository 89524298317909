import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/mongo-db/delete-document";

export default function MongoDeleteDocument() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Delete Document in MongoDB | A Complete Guide");
        title.setKeyWords("delete document in mongodb, command to delete document in mongodb, mongodb delete field in document, delete one document in mongodb, delete all document in mongodb, delete a document in mongodb, how to delete a document in mongodb, mongodb delete documents, delete in mongodb, delete documents in mongodb collection, delete all documents in mongodb collection, mongodb command to delete document, delete all document in collection mongodb, mongodb delete document with condition, mongodb delete document, delete document from mongodb, how to delete document from collection in mongodb, how to delete document in mongodb, how to delete one document in mongodb, how to delete all document in mongodb, delete document by id mongodb, delete multiple document in mongodb, mongodb delete documents by filter");
        title.setPageDescription("Deleting documents in a database can vary depending on the type of database you are using. Here, I'll explain how to delete documents in MongoDB, which is a NoSQL database commonly used for storing JSON-like documents.");
        const urls = {
            'previous': '/mongo-db/update-document',
            'next': '/mongo-db/document-relations'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>MongoDB - Delete Document</h3>
            <div className='mt-4 mb-5'>
                <p>
                    Deleting documents in a database can vary depending on the type of database you are using. Here, I'll explain how to delete documents in MongoDB, which is a NoSQL database commonly used for storing JSON-like documents.
                </p>
                <p>
                    In MongoDB, you can delete documents using the <strong>`deleteOne()`</strong>, <strong>`deleteMany()`</strong>, and <strong>`findOneAndDelete()`</strong> methods.
                </p>
                <h5 className='mt-5 mb-4'>The `deleteOne()` Method</h5>
                <p>
                    This method deletes a single document that matches a specified filter. If multiple documents match the filter, only the first matching document is deleted.
                </p>
                <p>
                    Here is the syntax -
                </p>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>db.collection.<span class="color-red">deleteOne</span>(filter)</p>
                        `
                    }}></div>
                </div>
                <p>Where, <strong>`filter`</strong> is a document that specifies the criteria for deletion.</p>
                <p>For example, deleting a single document by a specific field:</p>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            db.users.<span class="color-red">deleteOne</span>(
                                { <span class="color-pink">username</span>: <span class="color-green">"johndoe"</span> }
                            )
                        `
                    }}></div>
                </div>
                <p>
                    This example deletes the first document where <strong>`username`</strong> is <strong>`"johndoe"`</strong>.
                </p>
                <h5 className='mt-5 mb-4'>The `deleteMany()` Method</h5>
                <p>
                    The <strong>`deleteMany`</strong> method in MongoDB is used to delete multiple documents that match a specified filter. This method is useful when you need to remove all documents that meet certain criteria.
                </p>
                <p>
                    Here is the syntax -
                </p>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>db.collection.<span class="color-red">deleteMany</span>(filter)</p>
                        `
                    }}></div>
                </div>
                <p>Where, <strong>`filter`</strong> is a document that specifies the criteria for deletion.</p>
                <p>For example, deleting a single document by a specific field:</p>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            db.users.<span class="color-red">deleteMany</span>(
                                { <span class="color-pink">status</span>: <span class="color-green">"inactive"</span> }
                            )
                        `
                    }}></div>
                </div>
                <p>This example deletes all documents where <strong>`status`</strong> is <strong>`"inactive"`</strong>.</p>
                <h5 className='mt-5 mb-4'>The `findOneAndDelete()` Method</h5>
                <p>
                    The <strong>`findOneAndDelete`</strong> method in MongoDB is used to find a single document that matches a specified filter, delete it, and return the deleted document. This method is particularly useful when you need to delete a document and simultaneously retrieve it in a single atomic operation.
                </p>
                <p>
                    Here is the syntax -
                </p>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>db.collection.<span class="color-red">findOneAndDelete</span>(filter)</p>
                        `
                    }}></div>
                </div>
                <p>Where, <strong>`filter`</strong> is a document that specifies the criteria for deletion.</p>
                <p>For example, </p>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            db.users.<span class="color-red">findOneAndDelete</span>(
                                { <span class="color-pink">username</span>: <span class="color-green">"johndoe"</span> }
                            )
                        `
                    }}></div>
                </div>
                <p>This example deletes the first document where <strong>`username`</strong> is <strong>`"johndoe"`</strong> and returns the deleted document.</p>
            </div>
        </section>
    )
}