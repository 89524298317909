import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/css/text/alignment";

export default function TextAlignment() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Text Alignment in CSS | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/css/text/color',
            'next': '/css/text/decoration'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>Text Alignment</h3>
            <div className='mt-4 mb-5'>
                <p>
                    Text alignment in CSS is managed using the <strong>`text-align`</strong> property, which controls the horizontal alignment of text within an element. This property is most commonly applied to block-level elements like paragraphs, headings, divs, etc.
                </p>
                <p>
                    It has a basic syntax -
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>element {</p>
                            <p class="ml-30">    <span class="color-green">text-align</span>: value;</p>
                            <p>}</p>
                        `
                    }}></div>
                </div>
                <p>
                    Where, <strong>`value`</strong> determines how the text is aligned. It can be <strong>`left`</strong>, <strong>`right`</strong>, <strong>`center`</strong>, <strong>`justify`</strong>, <strong>`initial`</strong> and <strong>`inherit`</strong>.
                </p>

                <h5 className="mt-5 mb-3">Examples : How Text Alignment Works</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;style&gt;</p>
                        <p class="ml-30">    .left-align {</p>
                        <p class="ml-60">        <span class="color-green">text-align</span>: left;</p>
                        <p class="ml-30">    }</p>
                        <br />
                        <p class="ml-30">    .right-align {</p>
                        <p class="ml-60">        <span class="color-green">text-align</span>: right;</p>
                        <p class="ml-30">    }</p>
                        <br />
                        <p class="ml-30">    .center-align {</p>
                        <p class="ml-60">        <span class="color-green">text-align</span>: center;</p>
                        <p class="ml-30">    }</p>
                        <br />
                        <p class="ml-30">    .justify-align {</p>
                        <p class="ml-60">        <span class="color-green">text-align</span>: justify;</p>
                        <p class="ml-30">    }</p>
                        <p>&lt;/style&gt;</p>
                        <p>&lt;p <span class="color-pink">class</span>=<span class="color-green">"left-align"</span>&gt;This text is aligned to the left.&lt;/p&gt;</p>
                        <p>&lt;p <span class="color-pink">class</span>=<span class="color-green">"right-align"</span>&gt;This text is aligned to the right.&lt;/p&gt;</p>
                        <p>&lt;p <span class="color-pink">class</span>=<span class="color-green">"center-align"</span>&gt;This text is centered.&lt;/p&gt;</p>
                        <p>&lt;p <span class="color-pink">class</span>=<span class="color-green">"justify-align"</span>&gt;This is an example of justified text. This text will spread out to fill the entire width of the paragraph, creating a clean block of text with both edges aligned.&lt;/p&gt;</p>
                        `
                    }}></div>
                </div>
                <p>
                    Output will be the following -
                </p>
                <div className='outputPalateBox mb-4 mt-3'>
                    <div className='outputPalate' dangerouslySetInnerHTML={{
                        __html: `
                        <style>
                            .left-align {
                                text-align: left;
                            }
                    
                            .right-align {
                                text-align: right;
                            }
                    
                            .center-align {
                                text-align: center;
                            }
                    
                            .justify-align {
                                text-align: justify;
                            }
                        </style>
                        <p class="left-align">This text is aligned to the left.</p>
                        <p class="right-align">This text is aligned to the right.</p>
                        <p class="center-align">This text is centered.</p>
                        <p class="justify-align">This is an example of justified text. This text will spread out to fill the entire width of the paragraph, creating a clean block of text with both edges aligned.</p>
                        `
                    }}></div>
                </div>
            </div>
        </section>
    )
}