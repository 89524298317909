import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/css/height/width";

export default function HeightWidth() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Height and Width in CSS | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/css/padding',
            'next': '/css/box/model'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>Setting Height and Width</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <strong>`height`</strong> and <strong>`width`</strong> properties in CSS are used to set the size of an element. These properties can be applied to block-level elements and replaced elements (like <strong>`&lt;img&gt;`</strong>).
                </p>
                <p>
                    You can specify the height and width using various units:
                </p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>
                        <strong>Absolute Units</strong>: <strong>`px`</strong> (pixels), <strong>`cm`</strong> (centimeters), <strong>`mm`</strong> (millimeters), <strong>`in`</strong> (inches), <strong>`pt`</strong> (points), <strong>`pc`</strong> (picas).
                    </li>
                    <li>
                        <strong>Relative Units</strong>: <strong>`%`</strong> (percentage), <strong>`em`</strong> (relative to the font-size of the element), <strong>`rem`</strong> (relative to the font-size of the root element), <strong>`vw`</strong> (viewport width), <strong>`vh`</strong> (viewport height).
                    </li>
                </ul>

                <h5 className="mt-5 mb-3">Some Examples</h5>
                <p>
                    Here is some examples of how we set height and width to elements -
                </p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>
                        You can set fixed height and width to an element by direct passing the fixed value.
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p>&lt;style&gt;</p>
                                    <p class="ml-30">    .box-fixed {</p>
                                    <p class="ml-60">        <span class="color-green">width</span>: <span class="color-pink">200px</span>;</p>
                                    <p class="ml-60">        <span class="color-green">height</span>: <span class="color-pink">100px</span>;</p>
                                    <p class="ml-60">        <span class="color-green">background-color</span>: lightblue;</p>
                                    <p class="ml-30">    }</p>
                                    <p>&lt;/style&gt;</p>
                                    <p>&lt;div <span class="color-pink">class</span>=<span class="color-green">"box-fixed"</span>&gt;This element has a fixed 200px width and 100px height.&lt;/div&gt;</p>
                                `
                            }}></div>
                        </div>
                        <p>
                            Output will be the following -
                        </p>
                        <div className='outputPalateBox mb-4 mt-3' style={{'border':'0'}}>
                            <div className='outputPalate' dangerouslySetInnerHTML={{
                                __html: `
                                <style>
                                    .box-fixed {
                                        width: 200px;
                                        height: 100px;
                                        background-color: lightblue;
                                    }
                                </style>
                                <div class="box-fixed">This element has a fixed 200px width and 100px height.</div>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        You can set relative value of an parent element in percentage.
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p>&lt;style&gt;</p>
                                <p class="ml-30">    .container-box {</p>
                                <p class="ml-60">        <span class="color-green">width</span>: <span class="color-pink">100%</span>;</p>
                                <p class="ml-60">        <span class="color-green">height</span>: <span class="color-pink">300px</span>;</p>
                                <p class="ml-60">        <span class="color-green">background-color</span>: lightgrey;</p>
                                <p class="ml-30">    }</p>
                                <br />    
                                <p class="ml-30">    .box-percentage {</p>
                                <p class="ml-60">        <span class="color-green">width</span>: <span class="color-pink">50%</span>; <span class="color-grey">/* 50% of the parent width */</span></p>
                                <p class="ml-60">        <span class="color-green">height</span>: <span class="color-pink">50%</span>; <span class="color-grey">/* 50% of the parent height */</span></p>
                                <p class="ml-60">        <span class="color-green">background-color</span>: lightblue;</p>
                                <p class="ml-30">    }</p>
                                <p>&lt;/style&gt;</p>
                                <p>&lt;div <span class="color-pink">class</span>=<span class="color-green">"container-box"</span>&gt;</p>
                                <p class="ml-30">    &lt;div <span class="color-pink">class</span>=<span class="color-green">"box-percentage"</span>&gt;</p>
                                <p class="ml-60">        This element has 50% height and width of parent element.</p>
                                <p class="ml-30">    &lt;/div&gt;</p>
                                <p>&lt;/div&gt;</p>
                                `
                            }}></div>
                        </div>
                        <p>
                            Output will be the following -
                        </p>
                        <div className='outputPalateBox mb-4 mt-3' style={{'border':'0'}}>
                            <div className='outputPalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <style>
                                        .container-box {
                                            width: 100%;
                                            height: 300px;
                                            background-color: lightgrey;
                                        }
                                        
                                        .box-percentage {
                                            width: 50%; /* 50% of the parent width */
                                            height: 50%; /* 50% of the parent height */
                                            background-color: lightblue;
                                        }
                                    </style>
                                    <div class="container-box">
                                        <div class="box-percentage">
                                            This element has 50% height and width of parent element.
                                        </div>
                                    </div>
                                `
                            }}></div>
                        </div>
                    </li>
                </ul>

                <h5 className="mt-5 mb-3">Viewport Area in CSS</h5>
                <p>
                    The viewport refers to the visible area of a web page on a screen. It is the part of the web page that the user can see without scrolling. The size of the viewport varies depending on the device and its screen size.
                </p>
                <h6>Viewport Units:</h6>
                <ul style={{ listStyle: 'disc' }}>
                    <li><strong>`vw`</strong> (viewport width): 1vw is equal to 1% of the viewport's width.</li>       
                    <li><strong>`vh`</strong> (viewport height): 1vh is equal to 1% of the viewport's height.</li>
                </ul>
                <p>
                    Example of using viewport units - 
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>.box-viewport {</p>
                            <p class="ml-30">    <span class="color-green">width</span>: <span class="color-pink">50vw</span>; <span class="color-grey">/* 50% of the viewport width */</span></p>
                            <p class="ml-30">    <span class="color-green">height</span>: <span class="color-pink">50vh</span>; <span class="color-grey">/* 50% of the viewport height */</span></p>
                            <p class="ml-30">    <span class="color-green">background-color</span>: lightblue;</p>
                            <p>}</p>
                        `
                    }}></div>
                </div>

                <h5 className="mt-5 mb-3">Setting Min and Max Values</h5>
                <p>
                    By setting min and max value in height and width of an element you can set minimum and maximum height and width of that element. You can use <strong>`min-width`</strong>, <strong>`max-width`</strong>, <strong>`min-height`</strong>, and <strong>`max-height`</strong> to control the minimum and maximum size of an element.
                </p>
                <p>
                    Look at the below example for understanding better.
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>&lt;style&gt;</p>
                            <p class="ml-30">    .box-parent {</p>
                            <p class="ml-60">        <span class="color-green">width</span>: <span class="color-pink">400px</span>;</p>
                            <p class="ml-60">        <span class="color-green">height</span>: <span class="color-pink">200px</span>;</p>
                            <p class="ml-60">        <span class="color-green">background-color</span>: grey;</p>
                            <p class="ml-30">    }</p>
                            <p class="ml-30">    .box-child {</p>
                            <p class="ml-60">        <span class="color-green">width</span>: <span class="color-pink">50%</span>;</p>
                            <p class="ml-60">        <span class="color-green">height</span>: <span class="color-pink">50%</span>;</p>
                            <p class="ml-60">        <span class="color-green">background-color</span>: lightblue;</p>
                            <p class="ml-30">    }</p>
                            <p>&lt;/style&gt;</p>
                            <p>&lt;div class="box-parent"&gt;</p>
                            <p class="ml-30">    &lt;div class="box-child"&gt;&lt;/div&gt;</p>
                            <p>&lt;/div&gt;</p>
                        `
                    }}></div>
                </div>
                <p>
                    Output will be the following -
                </p>
                <div className='outputPalateBox mb-4 mt-3' style={{'border':'0'}}>
                    <div className='outputPalate' dangerouslySetInnerHTML={{
                        __html: `
                        <style>
                            .box-parent {
                                width: 400px;
                                height: 200px;
                                background-color: grey;
                            }
                            .box-child {
                                height: 80%;
                                width: 80%;
                                background-color: lightblue;
                                display: grid;
                            }
                        </style>
                        <div class="box-parent">
                            <div class="box-child">
                                <p style="align-self: center; justify-self: center;">Width : 320px <br/>Height : 160px</p>
                            </div>
                            <p style="text-align:center">Width : 400px Height : 200px</p>
                        </div>
                        `
                    }}></div>
                </div>

                <p>
                    Now if we set max width(<strong>`max-width: 250px;`</strong>) and max height(<strong>`max-height: 100px;`</strong>) of above <strong>`box-child`</strong> element, then the result will be 
                </p>

                <div className='outputPalateBox mb-4 mt-3' style={{'border':'0'}}>
                    <div className='outputPalate' dangerouslySetInnerHTML={{
                        __html: `
                        <style>
                            .box-parent-modified {
                                width: 400px;
                                height: 200px;
                                background-color: grey;
                                display: flex;
                            }
                            .box-child-modified {
                                height: 80%;
                                width: 80%;
                                background-color: lightblue;
                                display: grid;
                                max-width: 250px;
                                max-height: 100px;
                            }
                        </style>
                        <div class="box-parent-modified">
                            <div class="box-child-modified">
                                <p style="align-self: center; justify-self: center;">Width : 250px <br/>Height : 100px</p>
                            </div>
                            <p style="align-self: flex-end;">Width : 400px <br/>Height : 200px</p>
                        </div>
                        `
                    }}></div>
                </div>

                <p>
                    Using <strong>`max-width`</strong> and <strong>`max-height`</strong> in above <strong>`box-child`</strong> element, width will be <strong>`250px`</strong> instead of <strong>`320px`</strong> and height will be <strong>`100px`</strong> instead of <strong>`160px`</strong>.
                </p>
            </div>
        </section>
    )
}