import React, { useState } from 'react'
import TopHeader from './TopHeader'
import Footer from './Footer'
import Autocomplete from 'react-autocomplete'
import AllItems from './SearchIndex'
import * as moment from 'moment'

export default function Home() {
    const [value, setValue] = useState('');
    function setValueValue(e) {
        setValue(e.target.value)
    }
    return (
        <>
            <TopHeader />
            <div className=''>
                <div className='bannerPart'>
                    <div className="content1 bannercontent" style={{ 'xmax-width': '1400px' }}>
                        <h1 className="codeh1">Want to Learn Code</h1>
                        <h3 className="codeh3">
                            You are in right place, come and join us.
                        </h3>
                    </div>
                </div>
                <div className='svgPart'>
                    <svg style={{ 'background-color': '#FFF' }} width="100%" height="70" viewBox="0 0 100 100" preserveAspectRatio="none">
                        <path id="wavepath" d="M0,0  L110,0C35,150 35,0 0,100z" fill="#080612"></path>
                    </svg>
                </div>
                <div className='latestUpdates mt-5 mb-5'>
                    <section className="container" data-spacing="small">
                        <div className="">
                            <h2 className="fs-700 codeh2">
                                Start your <span className="ml-1 text-accent-700" >Learning</span>
                            </h2>
                            <p className="mt-4 mb-4">Begin your educational journey now. Start gaining knowledge, developing skills, and expanding your understanding with our accessible learning resources. Our platform offers a variety of subjects and materials designed to suit all learning levels. Whether you're a student, professional, or lifelong learner, we have the tools to help you succeed.</p>
                        </div>
                        <div className="section-learn-language">
                            <div className="section-learn-grid">
                                <div className='row mb-5'>
                                    <div className='col-6 col-sm-2 mb-3'>
                                        <button className="aspirant-learn-button">
                                            <span>Let's Learn</span><br />
                                            <a href="/mongo-db/introduction">MongoDB</a>
                                        </button>
                                    </div>
                                    <div className='col-6 col-sm-2 mb-3'>
                                        <button className="aspirant-learn-button">
                                            <span>Let's Learn</span><br />
                                            <a href="/css/introduction">CSS</a>
                                        </button>
                                    </div>
                                    <div className='col-6 col-sm-2 mb-3'>
                                        <button className="aspirant-learn-button">
                                            <span>Let's Learn</span><br />
                                            <a href="/react-js/introduction">ReactJS</a>
                                        </button>
                                    </div>
                                    <div className='col-6 col-sm-2 mb-3'>
                                        <button className="aspirant-learn-button">
                                            <span>Let's Learn</span><br />
                                            <a href="/node-js/introduction">NodeJS</a>
                                        </button>
                                    </div>
                                    <div className='col-6 col-sm-2 mb-3'>
                                        <button className="aspirant-learn-button">
                                            <span>Let's Learn</span><br />
                                            <a href="/html/introduction">HTML</a>
                                        </button>
                                    </div>
                                    <div className='col-6 col-sm-2 mb-3'>
                                        <button className="aspirant-learn-button">
                                            <span>Let's Learn</span><br />
                                            <a href="/mysql/introduction">Mysql</a>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                <div className='svgPart'>
                    <svg style={{ 'background-color': '#080612' }} width="100%" height="70" viewBox="0 0 100 100" preserveAspectRatio="none">
                        <path id="wavepath" d="M0,0  L110,0C35,150 35,0 0,100z" fill="#FFF"></path>
                    </svg>
                </div>
                <div className='svgPart'>
                    <svg style={{ 'background-color': '#080612' }} width="100%" height="70" viewBox="0 0 100 100" preserveAspectRatio="none">
                        <path id="wavepath" d="M0,0  L110,0C35,150 35,0 0,100z" fill="#080612"></path>
                    </svg>
                </div>
                <div className='aboutUs'  style={{ 'background-color': '#080612', 'color' : '#FFF' }}>
                    <h3 className="text-center mb-5 codeh1">About Us</h3>
                    <p className='codeh3'>
                        Aspirant's Home is an educational platform and community sharing knowledge and promoting lifelong learning. We aim to provide educational content to everyone, believing in the power of education. Our high-quality resources help individuals learn, achieve their goals, and contribute to a well-informed world.
                    </p>
                </div>
                <div className='svgPart'>
                    <svg style={{ 'background-color': '#FFF' }} width="100%" height="70" viewBox="0 0 100 100" preserveAspectRatio="none">
                        <path id="wavepath" d="M0,0  L110,0C35,150 35,0 0,100z" fill="#080612"></path>
                    </svg>
                </div>
                <ins className="adsbygoogle"
                    style={{ 'display': 'block' }}
                    data-ad-client="ca-pub-2061924575986153"
                    data-ad-slot="7856138126"
                    data-ad-format="auto"
                    data-full-width-responsive="true"></ins>
                <script>
                    (adsbygoogle = window.adsbygoogle || []).push({ });
                </script>
                <div className='latestUpdates mt-5 mb-5'>
                    <section className="container newUpdate" data-spacing="small">
                        <div className="">
                            <h2 className="fs-700 codeh2">
                                Latest <span className="ml-1 text-accent-700" >Updates - {moment(new Date()).format('MMM YYYY')}</span>
                            </h2>
                            <p className="mt-4 mb-4">Check out our newly added and updated tutorials. We regularly add fresh content and update existing materials to ensure you have the latest information. These tutorials cover various topics, making it easy to find what you need. Stay current and enhance your learning with our up-to-date resources.</p>
                        </div>
                        <div className="section-learn-language">
                            <div className="section-learn-grid">
                                <div className='row mb-5'>
                                    <div className='col-6 col-sm-2 mb-3'>
                                        <div className="aspirant-learn-button">
                                            <span>Look for updates</span><br />
                                            <a href="/mongo-db/introduction">MongoDB</a>
                                        </div>
                                    </div>
                                    {/* <div className='col-6 col-sm-4 mb-3'>
                                        <div className="aspirant-learn-button">
                                            <a href="/angular/introduction"><span>Angular</span></a>
                                        </div>
                                    </div> */}
                                    <div className='col-6 col-sm-2 mb-3'>
                                        <div className="aspirant-learn-button">
                                            <span>Look for updates</span><br />
                                            <a href="/react-js/introduction">ReactJS</a>
                                        </div>
                                    </div>
                                    <div className='col-6 col-sm-2 mb-3'>
                                        <div className="aspirant-learn-button">
                                            <span>Look for updates</span><br />
                                            <a href="/node-js/introduction">NodeJS</a>
                                        </div>
                                    </div>
                                    {/* <div className='col-6 col-sm-4 mb-3'>
                                        <div className="aspirant-learn-button">
                                            <a href="/javascript/introduction"><span>Javascript</span></a>
                                        </div>
                                    </div> */}
                                    <div className='col-6 col-sm-2 mb-3'>
                                        <div className="aspirant-learn-button">
                                            <span>Look for updates</span><br />
                                            <a href="/mysql/introduction">Mysql</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                <ins className="adsbygoogle"
                    style={{ 'display': 'block' }}
                    data-ad-client="ca-pub-2061924575986153"
                    data-ad-slot="7856138126"
                    data-ad-format="auto"
                    data-full-width-responsive="true"></ins>
                <script>
                    (adsbygoogle = window.adsbygoogle || []).push({ });
                </script>
                <div className='svgPart'>
                    <svg style={{ 'background-color': '#080612' }} width="100%" height="70" viewBox="0 0 100 100" preserveAspectRatio="none">
                        <path id="wavepath" d="M0,0  L110,0C35,150 35,0 0,100z" fill="#FFF"></path>
                    </svg>
                </div>
                <div className='svgPart'>
                    <svg style={{ 'background-color': '#080612' }} width="100%" height="70" viewBox="0 0 100 100" preserveAspectRatio="none">
                        <path id="wavepath" d="M0,0  L110,0C35,150 35,0 0,100z" fill="#080612"></path>
                    </svg>
                </div>
                <div className='aboutUs' style={{ 'background-color': '#080612', 'color': '#FFF', 'height':'300px' }}>
                    <h3 className="text-center mb-5 codeh1">Trending Job Career</h3>
                    <p className='codeh3'>
                        Look for trending job careers in technology at Aspirant's Home. We offer resources and tutorials on high-demand tech roles like software development, data science, and cybersecurity. Our platform provides up-to-date information on industry trends, helping you find promising career paths. With detailed guides, interactive modules, and expert advice, we support your skill and knowledge growth. Whether you're a beginner or a pro, Aspirant's Home helps you stay ahead in the evolving tech landscape.
                    </p>
                </div>
                <div className='svgPart'>
                    <svg style={{ 'background-color': '#FFF' }} width="100%" height="70" viewBox="0 0 100 100" preserveAspectRatio="none">
                        <path id="wavepath" d="M0,0  L110,0C35,150 35,0 0,100z" fill="#080612"></path>
                    </svg>
                </div>
                <div className='latestUpdates mt-5 mb-5'>
                    <section className="container newUpdate" data-spacing="small">
                        <div className="">
                            <h2 className="fs-700 codeh2">
                                Trending <span className="ml-1 text-accent-700" > Job Career - {moment(new Date()).format('MMM YYYY')}</span>
                            </h2>
                            <p className="mt-4 mb-4">Look for trending job careers in technology, Stay updated with industry trends to find the best tech career opportunities.</p>
                        </div>
                        <div className="section-learn-language">
                            <div className="section-learn-grid">
                                <div className='row mb-5'>
                                    <div className='col-6 eachCareer'>
                                        <h5 className='mt-4 mb-5'>Full Stack <span className='text-accent-700'>Web Developer</span></h5>
                                        <div className='row mb-5'>
                                            <div className='col-4 mb-3'>
                                                <div className="techSkillContainer">
                                                    <a className='techSkill' href="/mongo-db/introduction">MongoDB</a>
                                                </div>
                                            </div>
                                            <div className='col-4 mb-3'>
                                                <div className="techSkillContainer">
                                                    <a className='techSkill' href="/css/introduction">CSS</a>
                                                </div>
                                            </div>
                                            <div className='col-4 mb-3'>
                                                <div className="techSkillContainer">
                                                    <a className='techSkill' href="/react-js/introduction">ReactJS</a>
                                                </div>
                                            </div>
                                            <div className='col-4 mb-3'>
                                                <div className="techSkillContainer">
                                                    <a className='techSkill' href="/node-js/introduction">NodeJS</a>
                                                </div>
                                            </div>
                                            <div className='col-4 mb-3'>
                                                <div className="techSkillContainer">
                                                    <a className='techSkill' href="/html/introduction">HTML</a>
                                                </div>
                                            </div>
                                            <div className='col-4 mb-3'>
                                                <div className="techSkillContainer">
                                                    <a className='techSkill' href="/mysql/introduction">Mysql</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                <div className='svgPart'>
                    <svg style={{ 'background-color': '#080612' }} width="100%" height="70" viewBox="0 0 100 100" preserveAspectRatio="none">
                        <path id="wavepath" d="M0,0  L110,0C35,150 35,0 0,100z" fill="#080612"></path>
                    </svg>
                </div>
            </div>
            <div className='container-fluid footerPart'>
                <Footer />
            </div>
        </>
    )
}
