import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/html/emojis";

export default function HTMLEmojis() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("HTML - Emojis | Aspirant's Home");
        const urls = {
            'previous': '/html/symbols',
            'next': '/html/layouts'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>HTML - Emojis</h3>
            <div className='mt-4 mb-5'>
                <p>
                    HTML emojis are graphical representations of emotions, objects, and symbols that can be included in web pages to add visual interest and convey meaning. Emojis are a subset of Unicode characters, which means they are supported by most modern browsers and operating systems.
                </p>
                <h5 className='mt-5 mb-3'>Using Emojis in HTML</h5>
                <p>
                    To include an emoji in an HTML document, you can use its Unicode character directly or represent it with a numeric or hexadecimal entity. Emojis can be inserted in the same way as any other text character.
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate'>
                        <p>&lt;p&gt;Happy face: <code>{`&#128512;`}</code>&lt;/p&gt;</p>
                        <p>&lt;p&gt;Thumbs up: <code>{`&#128077;`}</code>&lt;/p&gt;</p>
                        <p>&lt;p&gt;Heart: <code>{`&#10084;&#65039;`}</code>&lt;/p&gt;</p>
                    </div>
                </div>
                <p>
                    Output of the following -
                </p>
                <div className='outputPalateBox mb-4'>
                    <div className='outputPalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>Happy face: &#128512;</p>
                            <p>Thumbs up: &#128077;</p>
                            <p>Heart: &#10084;&#65039;</p>
                        `
                    }}></div>
                </div>
                <h5 className='mt-5 mb-3'>Common Emojis and Their Unicode Values</h5>
                <p>Here are some commonly used emojis with their Unicode values:</p>
                <ul style={{listStyle:'decimal'}}>
                    <li>
                        <strong>Smileys & People:</strong>
                        <ul style={{ listStyle: 'disc' }}>
                            <li>😀 Grinning Face: <code>{`&#128512;`}</code> or <code>{`&#x1F600;`}</code></li>
                            <li>😂 Face with Tears of Joy: <code>{`&#128514;`}</code> or <code>{`&#x1F602;`}</code></li>
                            <li>😍 Smiling Face with Heart-Eyes: <code>{`&#128525;`}</code> or <code>{`&#x1F60D;`}</code></li>
                            <li>🤔 Thinking Face: <code>{`&#129300;`}</code> or <code>{`&#x1F914;`}</code></li> 
                            <li>🙏 Folded Hands: <code>{`&#128591;`}</code> or <code>{`&#x1F64F;`}</code></li>
                        </ul>
                    </li>
                    <li>
                        <strong>Animals & Nature:</strong>
                        <ul style={{ listStyle: 'disc' }}>
                            <li>🐶 Dog Face: <code>{`&#128054;`}</code> or <code>{`&#x1F436;`}</code></li>
                            <li>🐱 Cat Face: <code>{`&#128049;`}</code> or <code>{`&#x1F431;`}</code></li>
                            <li>🌲 Evergreen Tree: <code>{`&#127794;`}</code> or <code>{`&#x1F332;`}</code></li>
                            <li>🌞 Sun with Face: <code>{`&#127773;`}</code> or <code>{`&#x1F31E;`}</code></li>
                        </ul>
                    </li>
                    <li>
                        <strong>Food & Drink:</strong>
                        <ul style={{ listStyle: 'disc' }}>
                            <li>🍎 Red Apple: <code>{`&#127822;`}</code> or <code>{`&#x1F34E;`}</code></li>
                            <li>🍔 Hamburger: <code>{`&#127828;`}</code> or <code>{`&#x1F354;`}</code></li>
                            <li>🍕 Pizza: <code>{`&#127829;`}</code> or <code>{`&#x1F355;`}</code></li>
                            <li>🍺 Beer Mug: <code>{`&#127866;`}</code> or <code>{`&#x1F37A;`}</code></li>
                        </ul>
                    </li>
                    <li>
                        <strong>Activities:</strong>
                        <ul style={{ listStyle: 'disc' }}>
                            <li>⚽ Soccer Ball: <code>{`&#9917;`}</code> or <code>{`&#x26BD;`}</code></li>
                            <li>🎾 Tennis: <code>{`&#127934;`}</code> or <code>{`&#x1F3BE;`}</code></li>
                            <li>🎸 Guitar: <code>{`&#127928;`}</code> or <code>{`&#x1F3B8;`}</code></li>
                        </ul>
                    </li>
                    <li>
                        <strong>Travel & Places:</strong>
                        <ul style={{ listStyle: 'disc' }}>
                            <li>✈️ Airplane: <code>{`&#9992;`}</code> or <code>{`&#x2708;`}</code></li>
                            <li>🚗 Car: <code>{`&#128663;`}</code> or <code>{`&#x1F697;`}</code></li>
                            <li>🗽 Statue of Liberty: <code>{`&#128509;`}</code> or <code>{`&#x1F5FD;`}</code></li>
                        </ul>
                    </li>
                    <li>
                        <strong>Objects:</strong>
                        <ul style={{ listStyle: 'disc' }}>
                            <li>📱 Mobile Phone: <code>{`&#128241;`}</code> or <code>{`&#x1F4F1;`}</code></li>
                            <li>💡 Light Bulb: <code>{`&#128161;`}</code> or <code>{`&#x1F4A1;`}</code></li>
                            <li>⌚ Watch: <code>{`&#8986;`}</code> or <code>{`&#x231A;`}</code></li>
                        </ul>
                    </li>
                    <li>
                        <strong>Symbols:</strong>
                        <ul style={{ listStyle: 'disc' }}>
                            <li>❤️ Red Heart: <code>{`&#10084;&#65039;`}</code> or <code>{`&#x2764;&#xFE0F;`}</code></li>
                            <li>💯 Hundred Points: <code>{`&#128175;`}</code> or <code>{`&#x1F4AF;`}</code></li>
                            <li>🔥 Fire: <code>{`&#128293;`}</code> or <code>{`&#x1F525;`}</code></li>
                        </ul>
                    </li>
                </ul>
                <p>
                    By incorporating emojis, you can enhance the user experience by adding visual elements that convey emotions and meanings effectively.
                </p>
            </div>
        </section>
    )
}