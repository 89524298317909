import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/mysql/repair-tables";

export default function MysqlRepairTable() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Mysql - Repair Table | Aspirant's Home");
        const urls = {
            'previous': '/mysql/truncate-tables',
            'next': '/mysql/drop-tables'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>Mysql - Repair Table</h3>
            <div className='mt-4 mb-5'>
                <p>
                    In MySQL, the <strong>`REPAIR TABLE`</strong> statement is used to repair corrupted or damaged tables. It can fix various types of corruption issues, such as deleted or missing rows, incorrect indexes, and more. Here's the basic syntax:
                </p>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-blue">REPAIR TABLE</span> table_name;</p>
                        `
                    }}></div>
                </div>
                <p>
                    For example, to repair a table named <strong>`my_table`</strong>:
                </p>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-blue">REPAIR TABLE</span> my_table;</p>
                        `
                    }}></div>
                </div>
            </div>
            <div className='mt-5 mb-5'>
                <p>
                    We have various optional clauses to use with <strong>`REPAIR TABLE`</strong> such as <strong>`QUICK`</strong>, <strong>`EXTENDED`</strong>.
                </p>
                <div className='mt-5 mb-5'>
                    <h5>QUICK Clause</h5>
                    <div className='mt-2 mb-2'>
                        <p>
                            The <strong>`QUICK`</strong> clause is the is the default and it is most commonly used with <strong>`REPAIR TABLE`</strong>. If you specify the <strong>`QUICK`</strong> clause, MySQL will repair the table without re-creating it. −
                        </p>
                        <div className='codePalateBox mt-2 mb-2'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-blue">REPAIR TABLE</span> table_name QUICK;</p>
                                `
                            }}></div>
                        </div>
                    </div>
                </div>
                <div className='mt-5 mb-5'>
                    <h5>EXTENDED Clause</h5>
                    <div className='mt-2 mb-2'>
                        <p>
                            If we specify the <strong>`EXTENDED`</strong> clause, MySQL not only repairs the table but also rebuilds the index and optimizes the table structure.
                        </p>
                        <div className='codePalateBox mt-2 mb-2'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-blue">REPAIR TABLE</span> table_name EXTENDED;</p>
                                `
                            }}></div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='mt-5 mb-5'>
                <h5>Repairing multiple tables</h5>
                <div className='mt-2 mb-2'>
                    <p>
                        In MySQL, we can also repair multiple tables and get the results using the REPAIR TABLE Statement. To do this, we just need to list the names of the tables we want to repair, separating them with commas.
                    </p>
                    <div className='codePalateBox mt-2 mb-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                                <p><span class="color-blue">REPAIR TABLE</span> Table1, Table2, Table3;</p>
                            `
                        }}></div>
                    </div>
                </div>
            </div>
        </section>
    )
}