import React, {useEffect, useContext, useState} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/css/colors/rgb";

export default function CSSColorRGB() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    const [redColor, setRedColor] = useState(120);
    const [greenColor, setGreenColor] = useState(40);
    const [blueColor, setBlueColor] = useState(210);


    useEffect(() => {
        title.setPageTitle("RGB Colors in CSS | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/css/colors',
            'next': '/css/colors/hex'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>RGB Colors in CSS</h3>
            <div className='mt-4 mb-5'>
                <p>
                    RGB stands for <strong><i>Red</i></strong>, <strong><i>Green</i></strong>, and <strong><i>Blue</i></strong>. It's a color model that mixes these three main colors in different amounts to create many colors. In CSS, the <strong>`rgb()`</strong> function is used to define colors using the RGB color model.
                </p>
                <h5 className='mt-4 mb-4'>Syntax</h5>
                <p>
                    The <strong>`rgb()`</strong> function takes three parameters:
                </p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><strong>`red`</strong>: An integer between 0 and 255 representing the amount of the red color.</li>
                    <li><strong>`green`</strong>: An integer between 0 and 255 representing the amount of the green color.</li>
                    <li><strong>`blue`</strong>: An integer between 0 and 255 representing the amount of the blue color.</li>
                </ul>
                <div className='codePalateBox mt-2 mb-5'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-green">color</span>: <span class="color-yellow">rgb</span>(red, green, blue);</p>
                        `
                    }}></div>
                </div>
                <h5 className='mt-5 mb-5'>Examples</h5>
                <div className='mt-4 mb-5 row text-center colorNames transparentBackground'>
                    <div className='col-4' style={{backgroundColor:'rgb(0, 0, 0)',color:'#FFF'}}>rgb(0, 0, 0)</div>
                    <div className='col-4' style={{backgroundColor:'rgb(255, 0, 0)',color:'#FFF'}}>rgb(255, 0, 0)</div>
                    <div className='col-4' style={{backgroundColor:'rgb(161, 90, 30)',color:'#FFF'}}>rgb(161, 90, 30)</div>
                    <div className='col-4' style={{backgroundColor:'rgb(155, 120, 80)',color:'#FFF'}}>rgb(155, 120, 80)</div>
                    <div className='col-4' style={{backgroundColor:'rgb(240, 90, 140)',color:'#FFF'}}>rgb(240, 90, 140)</div>
                    <div className='col-4' style={{backgroundColor:'rgb(20, 50, 170)',color:'#FFF'}}>rgb(20, 50, 170)</div>
                    <div className='col-4' style={{backgroundColor:'rgb(220, 190, 240)',color:'#FFF'}}>rgb(220, 190, 240)</div>
                    <div className='col-4' style={{backgroundColor:'rgb(230, 150, 135)',color:'#FFF'}}>rgb(230, 150, 135)</div>
                    <div className='col-4' style={{backgroundColor:'rgb(170, 50, 190)',color:'#FFF'}}>rgb(170, 50, 190)</div>
                </div>

                <h5 className='mt-5 mb-4'>Experiment with RGB Value</h5>
                <p>
                    You can experiment rgb value with the below sliders - 
                </p>
                <div className='row'>
                    <div className='col-3'></div>
                    <div className='col-6 fontStyle' style={{height:'100px','alignContent':'center','textAlign':'center', backgroundColor: 'rgb(' + redColor + ',' + greenColor + ',' + blueColor + ')', color: '#FFF' }}>
                        rgb({redColor + ', ' + greenColor + ', ' + blueColor})
                    </div>
                    <div className='col-3'></div>
                </div>
                <div className='row mt-5 mb-5'>
                    <div className='col-4 text-center'>
                        <label for="points">Red:</label>
                        <br />
                        <input type="range" id="points1" value={redColor} name="points" min="0" max="255" onChange={(e) => setRedColor(e.target.value)} />
                        <br />
                        <span>{redColor}</span>
                    </div>
                    <div className='col-4 text-center'>
                        <label for="points">Green:</label>
                        <br />
                        <input type="range" id="points2" value={greenColor} name="points" min="0" max="255" onChange={(e)=>setGreenColor(e.target.value)} />
                        <br />
                        <span>{greenColor}</span>
                    </div>
                    <div className='col-4 text-center'>
                        <label for="points">Blue:</label>
                        <br />
                        <input type="range" id="points3" value={blueColor} name="points" min="0" max="255" onChange={(e)=>setBlueColor(e.target.value)} />
                        <br />
                        <span>{blueColor}</span>
                    </div>
                </div>

                <h5 className='mt-5 mb-3'>What is RGBA Value ?</h5>
                <p>
                    RGBA color values are an extension of RGB color values with an alpha which represent a number between 0.0 (completely <strong>`transparent`</strong>) and 1.0 (completely <strong>`opaque`</strong>), the opacity level. It has basic syntax -
                </p>
                <div className='codePalateBox mt-2 mb-5'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-green">color</span>: <span class="color-yellow">rgb</span>(red, green, blue, alpha);</p>
                        `
                    }}></div>
                </div>
                <h5 className='mt-5 mb-5'>Some Example of Using RGBA Values</h5>
                <div className='mt-4 mb-5 row text-center colorNames  transparentBackground'>
                    <div className='col-4' style={{backgroundColor:'rgb(0, 0, 0, 0)',color:'#000'}}>rgb(0, 0, 0, 0)</div>
                    <div className='col-4' style={{backgroundColor:'rgb(0, 0, 0, 0.2)',color:'#FFF'}}>rgb(0, 0, 0, 0.2)</div>
                    <div className='col-4' style={{backgroundColor:'rgb(0, 0, 0, 0.4)',color:'#FFF'}}>rgb(0, 0, 0, 0.4)</div>
                    <div className='col-4' style={{backgroundColor:'rgb(0, 0, 0, 0.6)',color:'#FFF'}}>rgb(0, 0, 0, 0.6)</div>
                    <div className='col-4' style={{backgroundColor:'rgb(0, 0, 0, 0.8)',color:'#FFF'}}>rgb(0, 0, 0, 0.8)</div>
                    <div className='col-4' style={{backgroundColor:'rgb(0, 0, 0, 1)',color:'#FFF'}}>rgb(0, 0, 0, 1)</div>
                </div>
                <p className='mt-4'>
                    In this above examples, you can see RGB value are for color <strong>`black`</strong>, but these are the shades of grey. RGBA allows you to control the transparency of colors, which is useful for creating overlay effects, backgrounds, and more.
                </p>
            </div>
        </section>
    )
}