import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/node-js/mongodb-find-query";

export default function MongoDbFindQuery() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Node Js - MongoDB - Find Query | Aspirant's Home");
        const urls = {
            'previous': '/node-js/mongodb-insert-query',
            'next': '/node-js/mongodb-query'
        }
        path.setPreviousNext(urls);
    }, [])
    
    return (
        <section className='mt-5 mb-5'>
            <h3>Node Js MongoDB Find Query</h3>
            <div className='mt-4'>
                <p>In MongoDB, To find a record or document from a collection we can use <strong>`find()`</strong> and  <strong>`findOne()`</strong> methods.</p>
                <div className='featureClass mb-3'>
                    Note : In MongoDB, find document is similar as select record in MySql.
                </div>
                <p>Let's checkout how to find document from a collection.</p>
            </div>
            <div className='mt-4 mb-5'>
                <h5>Using <strong>find()</strong></h5>
                <p className='mt-3'>The syntax is following -</p>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html:
                            ` db.collection.find( &ltquery&gt, &ltprojection&gt, &ltoptions&gt )`
                    }}>
                    </div>
                </div>
                <div className='mb-3'>
                    <p>The find() method with no parameters returns all documents from a collection and returns all fields for the documents. It gives the same result as SELECT * in MySQL.</p>
                </div>
                <p>Ok Let's take an example.</p>
                <p>Find all the employees from "employee" collection.</p>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html:
                            `<p><span class='color-blue'>var</span> MongoClient = <span class='color-yellow'>require</span>(<span class='color-green'>'mongodb'</span>).MongoClient;</p>
                            <p><span class='color-blue'>var</span> url = <span class='color-green'>"mongodb://localhost:27017/"</span>;</p>
                            <br />
                            <p>MongoClient.<span class='color-red'>connect</span>(<span class='color-blue'>url</span>, function(err, db) {</p>
                            <p style='margin-left:30px'>  <span class='color-blue'>if</span> (err) throw err;</p>
                            <p style='margin-left:30px'>  <span class='color-blue'>var</span> dbo = db.<span class='color-red'>db</span>(<span class='color-green'>"mymongodbdatabase"</span>);</p>
                            <p style='margin-left:30px'>  dbo.<span class='color-red'>collection</span>(<span class='color-green'>"employees"</span>).find({ }).toArray(<span class='color-blue'>function</span>(err, result) {</p>
                            <p style='margin-left:60px'>    <span class='color-blue'>if</span> (err) <span class='color-blue'>throw</span> err;</p>
                            <p style='margin-left:60px'>    <span class='color-pink'>console</span>.<span class='color-red'>log</span>(result);</p>
                            <p style='margin-left:60px'>    db.<span class='color-red'>close</span>();</p>
                            <p style='margin-left:30px'>  });</p>
                            <p>});</p>`
                    }}>
                    </div>
                </div>
                <p>Which will give you this result:</p>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html:
                            `<p>[</p>
                            <p style='margin-left:30px'> { _id: 58fdbf5c0ef8a50b4cdd9a80 , employee_name: "Sudhir", email: "sudhir@gmail.com" },</p>
                            <p style='margin-left:30px'> { _id: 58fdbf5c0ef8a50b4cdd9a81, employee_name: "Pradip", email: "pradip@gmail.com" },</p>
                            <p style='margin-left:30px'> { _id: 58fdbf5c0ef8a50b4cdd9a82, employee_name: "Rachel", email: "rachel@gmail.com" },</p>
                            <p style='margin-left:30px'> { _id: 58fdbf5c0ef8a50b4cdd9a83, employee_name: "Benjamin", email: "benjamin@gmail.com" },</p>
                            <p style='margin-left:30px'> { _id: 58fdbf5c0ef8a50b4cdd9a84, employee_name: "Samantha", email: "samantha@gmail.com" },</p>
                            <p style='margin-left:30px'> { _id: 58fdbf5c0ef8a50b4cdd9a85, employee_name: "Charlotte", email: "charlotte@gmail.com" },</p>
                            <p style='margin-left:30px'> { _id: 58fdbf5c0ef8a50b4cdd9a86, employee_name: "Gregg", email: "gregg@gmail.com" },</p>
                            <p style='margin-left:30px'> { _id: 58fdbf5c0ef8a50b4cdd9a87, employee_name: "Stacie", email: "stacie@gmail.com" },</p>
                            <p style='margin-left:30px'> { _id: 58fdbf5c0ef8a50b4cdd9a88, employee_name: "Eleanor", email: "eleanor@gmail.com" },</p>
                            <p style='margin-left:30px'> { _id: 58fdbf5c0ef8a50b4cdd9a89, employee_name: "Horton", email: "horton@gmail.com" },</p>
                            <p>]</p>`
                    }}>
                    </div>
                </div>
            </div>
            <div className='mt-4 mb-5'>
                <h5>Using <strong>findOne()</strong></h5>
                <p className='mt-3'>The syntax is following -</p>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html:
                            ` db.collection.findOne( &ltquery&gt, &ltprojection&gt, &ltoptions&gt )`
                    }}>
                    </div>
                </div>
                <div className='mb-3'>
                    <p>The findOne() method with no parameters returns only the first document from a collection but return all fields for the documents.</p>
                </div>
                <p>Ok Let's take an example.</p>
                <p>Find the employee from "employee" collection.</p>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html:
                            `<p><span class='color-blue'>var</span> MongoClient = <span class='color-yellow'>require</span>(<span class='color-green'>'mongodb'</span>).MongoClient;</p>
                            <p><span class='color-blue'>var</span> url = <span class='color-green'>"mongodb://localhost:27017/"</span>;</p>
                            <br />
                            <p>MongoClient.<span class='color-red'>connect</span>(<span class='color-blue'>url</span>, function(err, db) {</p>
                            <p style='margin-left:30px'>  <span class='color-blue'>if</span> (err) throw err;</p>
                            <p style='margin-left:30px'>  <span class='color-blue'>var</span> dbo = db.<span class='color-red'>db</span>(<span class='color-green'>"mymongodbdatabase"</span>);</p>
                            <p style='margin-left:30px'>  dbo.<span class='color-red'>collection</span>(<span class='color-green'>"employees"</span>).findOne({ }).toArray(<span class='color-blue'>function</span>(err, result) {</p>
                            <p style='margin-left:60px'>    <span class='color-blue'>if</span> (err) <span class='color-blue'>throw</span> err;</p>
                            <p style='margin-left:60px'>    <span class='color-pink'>console</span>.<span class='color-red'>log</span>(result);</p>
                            <p style='margin-left:60px'>    db.<span class='color-red'>close</span>();</p>
                            <p style='margin-left:30px'>  });</p>
                            <p>});</p>`
                    }}>
                    </div>
                </div>
                <p>Which will give you this result:</p>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html:
                            `<p>[</p>
                            <p style='margin-left:30px'> { _id: 58fdbf5c0ef8a50b4cdd9a80 , employee_name: "Sudhir", email: "sudhir@gmail.com" }</p>
                            <p>]</p>`
                    }}>
                    </div>
                </div>
            </div>

            <div className='mt-4'>
                <h5 className='mb-4'>Parameters of `find()` and `findOne()`</h5>
                <table className='responsive'>
                    <thead>
                        <th style={{width: '20%'}}>Parameter</th>
                        <th style={{width: '20%'}}>Type</th>
                        <th style={{width: '60%'}}>Description</th>
                    </thead>
                    <tbody>
                        <tr style={{height:'70px'}}>
                            <td>query</td>
                            <td>document</td>
                            <td>Optional. Specifies query selection criteria using query operators.</td>
                        </tr>
                        <tr style={{height:'70px'}}>
                            <td>projection</td>
                            <td>document</td>
                            <td>Optional. Specifies the fields to return using projection operators. Omit this parameter to return all fields in the matching document.</td>
                        </tr>
                        <tr style={{height:'70px'}}>
                            <td>options</td>
                            <td>document</td>
                            <td>Optional. Specifies additional options for the query. These options modify query behavior and how results are returned.</td>
                        </tr>
                    </tbody>
                </table>
                <p>We have discussed in details about these parameter in our <a href='/mongo-db'>MongoDB Tutorial</a> page.</p>
            </div>
        </section>
    )
}
