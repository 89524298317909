import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';
export const pageUrl = () => "/mongo-db/aggregation/project";

export default function MongoAggregationProject() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("$project Aggregation Pipelines in MongoDB");
        title.setKeyWords("$project aggregation pipeline in mongodb, mongodb aggregation pipeline project, mongodb aggregation pipeline multiple project, aggregation pipeline mongodb examples, aggregation pipeline mongodb, mongodb aggregation pipeline project, mongodb aggregation pipeline query");
        title.setPageDescription("The `$project` stage in MongoDB's aggregation framework is used to shape the documents in a pipeline by including, excluding, or adding new fields. This stage is akin to the `SELECT` clause in SQL, allowing you to specify exactly which fields you want in your output documents and how they should be transformed.");
        path.setPreviousNext({});
    }, [])

    return (
        <>
            <section class='clearfix mb-3'>
                <span className='float-start'>
                    <Link to='/mongo-db/aggregation' onClick={() => path.setPathName('/mongo-db/aggregation')} className='btn btn-primary'> <i class="fa-solid fa-less-than"></i>&nbsp; Back to Aggregation </Link>
                </span>
            </section>
            <section className='mt-5 mb-5'>
                <h3>MongoDB - $project Pipelines</h3>
                <div className='mt-4 mb-5'>
                    <p>
                        The <strong>`$project`</strong> stage in MongoDB's aggregation framework is used to shape the documents in a pipeline by including, excluding, or adding new fields. This stage is akin to the <strong>`SELECT`</strong> clause in SQL, allowing you to specify exactly which fields you want in your output documents and how they should be transformed.
                    </p>
                    <h5>Basic Syntax</h5>
                    <p>
                        The <strong>`$project`</strong> stage uses a document to specify the fields to include or exclude, as well as expressions to compute new fields or transform existing ones.
                    </p>
                    <div className='codePalateBox mt-2 mb-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                                <p>{</p>
                                <p style="margin-left:30px">    <span class="color-pink">$project</span>: {</p>
                                <p style="margin-left:60px">    &lt;field1&gt;: &lt;expression1&gt;,</p>
                                <p style="margin-left:60px">    &lt;field2&gt;: &lt;expression2&gt;,</p>
                                <p style="margin-left:60px">    ...</p>
                                <p style="margin-left:30px">    }</p>
                                <p>}</p>
                            `
                        }}></div>
                    </div>
                    <p>
                        Where,
                    </p>
                    <ul style={{ listStyle: 'disc' }}>
                        <li>&lt;field&gt;: The name of the field in the output document.</li>
                        <li>&lt;expression&gt;: An expression that can be a field path, a value, or a computed expression.</li>
                    </ul>
                    <h5 className='mt-4 mb-3'>Example Scenario</h5>
                    <p>
                        Consider a <strong>`students`</strong> collection where each document represents a student with fields for <strong>`name`</strong>, <strong>`age`</strong>, and <strong>`scores`</strong> (an object with multiple test scores).
                    </p>
                    <div className='codePalateBox mt-2 mb-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                                <p>[</p>
                                <p style="margin-left:30px">    { <span class="color-pink">"_id"</span>: <span class="color-pink">1</span>, <span class="color-pink">"name"</span>: <span class="color-green">"Alice"</span>, <span class="color-pink">"age"</span>: <span class="color-pink">21</span>, <span class="color-pink">"scores"</span>: { <span class="color-pink">"math"</span>: <span class="color-pink">85</span>, <span class="color-pink">"science"</span>: <span class="color-pink">90</span>, <span class="color-pink">"history"</span>: <span class="color-pink">80</span> } },</p>
                                <p style="margin-left:30px">    { <span class="color-pink">"_id"</span>: <span class="color-pink">2</span>, <span class="color-pink">"name"</span>: <span class="color-green">"Bob"</span>, <span class="color-pink">"age"</span>: <span class="color-pink">19</span>, <span class="color-pink">"scores"</span>: { <span class="color-pink">"math"</span>: <span class="color-pink">75</span>, <span class="color-pink">"science"</span>: <span class="color-pink">85</span>, <span class="color-pink">"history"</span>: <span class="color-pink">70</span> } },</p>
                                <p style="margin-left:30px">    { <span class="color-pink">"_id"</span>: <span class="color-pink">3</span>, <span class="color-pink">"name"</span>: <span class="color-green">"Charlie"</span>, <span class="color-pink">"age"</span>: <span class="color-pink">23</span>, <span class="color-pink">"scores"</span>: { <span class="color-pink">"math"</span>: <span class="color-pink">95</span>, <span class="color-pink">"science"</span>:<span class="color-pink"> 80</span>, <span class="color-pink">"history"</span>: <span class="color-pink">85</span> } }</p>
                                <p>]</p>
                            `
                        }}></div>
                    </div>
                    <h5 className='mt-5 mb-3'>Including Specific Fields</h5>
                    <p>
                        To include only the <strong>`name`</strong> and <strong>`age`</strong> fields in the output documents:
                    </p>
                    <div className='codePalateBox mt-2 mb-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                                <p>db.students.<span class="color-red">aggregate</span>([</p>
                                <p style="margin-left:30px">    {</p>
                                <p style="margin-left:60px">        <span class="color-pink">$project</span>: {</p>
                                <p style="margin-left:90px">            <span class="color-pink">name</span>: <span class="color-pink">1</span>,</p>
                                <p style="margin-left:90px">            <span class="color-pink">age</span>: <span class="color-pink">1</span>,</p>
                                <p style="margin-left:90px">            <span class="color-pink">_id</span>: <span class="color-pink">0</span> <span class="color-grey">// Exclude the _id field</span></p>
                                <p style="margin-left:60px">        }</p>
                                <p style="margin-left:30px">    }</p>
                                <p>])</p>
                            `
                        }}></div>
                    </div>
                    <h6 className='mt-4 mb-4'>Result of the above</h6>
                    <div className='codePalateBox mt-2 mb-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                                <p>[</p>
                                <p style="margin-left:30px">    { <span class="color-pink">"name"</span>: <span class="color-green">"Alice"</span>, <span class="color-pink">"age"</span>: <span class="color-pink">21</span> },</p>
                                <p style="margin-left:30px">    { <span class="color-pink">"name"</span>: <span class="color-green">"Bob"</span>, <span class="color-pink">"age"</span>: <span class="color-pink">19</span> },</p>
                                <p style="margin-left:30px">    { <span class="color-pink">"name"</span>: <span class="color-green">"Charlie"</span>, <span class="color-pink">"age"</span>: <span class="color-pink">23</span> }</p>
                                <p>]</p>
                            `
                        }}></div>
                    </div>
                    <h5 className='mt-5 mb-3'>Renaming Fields</h5>
                    <p>
                        To include the <strong>`name`</strong> field and rename the <strong>`age`</strong> field to <strong>`studentAge`</strong>:
                    </p>
                    <div className='codePalateBox mt-2 mb-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                                <p>db.students.<span class="color-red">aggregate</span>([</p>
                                <p style="margin-left:30px">    {</p>
                                <p style="margin-left:60px">        <span class="color-pink">$project</span>: {</p>
                                <p style="margin-left:90px">            <span class="color-pink">name</span>: <span class="color-pink">1</span>,</p>
                                <p style="margin-left:90px">            <span class="color-pink">studentAge</span>: <span class="color-green">"$age"</span>,</p>
                                <p style="margin-left:90px">            <span class="color-pink">_id</span>: <span class="color-pink">0</span></p>
                                <p style="margin-left:60px">        }</p>
                                <p style="margin-left:30px">    }</p>
                                <p>])</p>
                            `
                        }}></div>
                    </div>
                    <h6 className='mt-4 mb-4'>Result of the above</h6>
                    <div className='codePalateBox mt-2 mb-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                                <p>[</p>
                                <p style="margin-left:30px">    { <span class="color-pink">"name"</span>: <span class="color-green">"Alice"</span>, <span class="color-pink">"studentAge"</span>: <span class="color-pink">21</span> },</p>
                                <p style="margin-left:30px">    { <span class="color-pink">"name"</span>: <span class="color-green">"Bob"</span>, <span class="color-pink">"studentAge"</span>: <span class="color-pink">19</span> },</p>
                                <p style="margin-left:30px">    { <span class="color-pink">"name"</span>: <span class="color-green">"Charlie"</span>, <span class="color-pink">"studentAge"</span>: <span class="color-pink">23</span> }</p>
                                <p>]</p>
                            `
                        }}></div>
                    </div>
                    <h5 className='mt-5 mb-3'>Adding Computed Fields</h5>
                    <p>
                        To add a new field <strong>`totalScore`</strong> that sums the math, science, and history scores:
                    </p>
                    <div className='codePalateBox mt-2 mb-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                                <p>db.students.<span class="color-red">aggregate</span>([</p>
                                <p style="margin-left:30px">    {</p>
                                <p style="margin-left:60px">     <span class="color-pink">$project</span>: {</p>
                                <p style="margin-left:90px">            <span class="color-pink">name</span>: <span class="color-pink">1</span>,</p>
                                <p style="margin-left:90px">            <span class="color-pink">totalScore</span>: {</p>
                                <p style="margin-left:120px">                <span class="color-pink">$sum</span>: [<span class="color-green">"$scores.math"</span>, <span class="color-green">"$scores.science"</span>, <span class="color-green">"$scores.history"</span>]</p>
                                <p style="margin-left:90px">            },</p>
                                <p style="margin-left:90px">           <span class="color-pink"> _id</span>: <span class="color-pink">0</span></p>
                                <p style="margin-left:60px">        }</p>
                                <p style="margin-left:30px">    }</p>
                                <p>])</p>
                            `
                        }}></div>
                    </div>
                    <h6 className='mt-4 mb-4'>Result of the above</h6>
                    <div className='codePalateBox mt-2 mb-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                                <p>[</p>
                                <p style="margin-left:30px">    { <span class="color-pink">"name"</span>: <span class="color-green">"Alice"</span>, <span class="color-pink">"totalScore"</span>: <span class="color-pink">255</span> },</p>
                                <p style="margin-left:30px">    { <span class="color-pink">"name"</span>: <span class="color-green">"Bob"</span>, <span class="color-pink">"totalScore"</span>: <span class="color-pink">230</span> },</p>
                                <p style="margin-left:30px">    { <span class="color-pink">"name"</span>: <span class="color-green">"Charlie"</span>, <span class="color-pink">"totalScore"</span>: <span class="color-pink">260</span> }</p>
                                <p>]</p>
                            `
                        }}></div>
                    </div>
                    <div className='mt-5 mb-4 featureClass'>
                        <h5 className='mb-4'>Important Note</h5>
                        <p>The <strong>`$project`</strong> stage in MongoDB's aggregation framework is a versatile tool for transforming documents. It allows you to:</p>
                        <ul style={{listStyle:'disc'}}>
                            <li>Include or exclude specific fields.</li>
                            <li>Rename fields.</li>
                            <li>Add new computed fields.</li>
                            <li>Transform the shape and content of documents in the pipeline.</li>
                        </ul>
                    </div>
                </div>
            </section>
        </>
    )
}