import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/react-js/handling-states";

export default function HandlingState() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("React Http Request - Handling Loading States | Aspirant's Home");
        const urls = {
            'previous': '/react-js/handling-http-request',
            'next':'/react-js/handling-http-errors'
        }
        path.setPreviousNext(urls);
    },[])

    return (
        <section className='mt-5 mb-5'>
            <h3>React Http Request - Handling Loading States</h3>
            <div className='mt-4 mb-4'>
                <p>
                    Handling loading states in React typically involves setting a state variable to indicate when data is being fetched and then updating the state based on the fetch operation's status. Here's a basic example using the <strong>`fetch`</strong> API:
                </p>
                <div className='codePalateBox mt-2 mb-5'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-blue">import</span> <span class="color-red">React</span>, { <span class="color-red">useState</span>, <span class="color-red">useEffect</span> } <span class="color-blue">from</span> <span class="color-green">'react'</span>;</p>
                            <br />
                            <p><span class="color-blue">function</span> <span class="color-red">App</span>() {</p>
                            <p style="margin-left:30px">    <span class="color-blue">const</span> [data, setData] = <span class="color-red">useState</span>(null);</p>
                            <p style="margin-left:30px">    <span class="color-blue">const</span> [loading, setLoading] = <span class="color-red">useState</span>(true);</p>
                            <p style="margin-left:30px">    <span class="color-blue">const</span> [error, setError] = <span class="color-red">useState</span>(null);</p>
                            <br />    
                            <p style="margin-left:30px">    <span class="color-red">useEffect</span>(() => {</p>
                            <p style="margin-left:60px">        <span class="color-red">fetch</span>(<span class="color-green">'https://api.example.com/data'</span>)</p>
                            <p style="margin-left:60px">        .<span class="color-red">then</span>(response => {</p>
                            <p style="margin-left:90px">            <span class="color-blue">if</span> (!response.ok) {</p>
                            <p style="margin-left:120px">                 <span class="color-blue">throw new</span> <span class="color-red">Error</span>(<span class="color-green">'Network response was not ok'</span>);</p>
                            <p style="margin-left:90px">            }</p>
                            <p style="margin-left:90px">            <span class="color-blue">return</span> response.<span class="color-red">json</span>();</p>
                            <p style="margin-left:60px">        })</p>
                            <p style="margin-left:60px">        .<span class="color-red">then</span>(data => {</p>
                            <p style="margin-left:90px">            <span class="color-red">setData</span>(data);</p>
                            <p style="margin-left:90px">            <span class="color-red">setLoading</span>(false); <span class="color-grey">// Set loading to false once data is fetched</span></p>
                            <p style="margin-left:60px">        })</p>
                            <p style="margin-left:60px">        .<span class="color-red">catch</span>(error => {</p>
                            <p style="margin-left:90px">            <span class="color-red">setError</span>(error.message);</p>
                            <p style="margin-left:90px">            <span class="color-red">setLoading</span>(false); <span class="color-grey">// Set loading to false if an error occurs</span></p>
                            <p style="margin-left:60px">        });</p>
                            <p style="margin-left:30px">    }, []);</p>
                            <br />    
                            <p style="margin-left:30px">    <span class="color-blue">return</span> (</p>
                            <p style="margin-left:60px">        &lt;div&gt;</p>
                            <p style="margin-left:90px">            {loading && &lt;p&gt;Loading...&lt;/p&gt;}</p>
                            <p style="margin-left:90px">            {error && &lt;p&gt;Error: {error}&lt;/p&gt;}</p>
                            <p style="margin-left:90px">            {data && (</p>
                            <p style="margin-left:120px">                &lt;div&gt;</p>
                            <p style="margin-left:150px">                    &lt;h1&gt;Data:&lt;/h1&gt;</p>
                            <p style="margin-left:150px">                    &lt;pre&gt;{JSON.stringify(data, null, 2)}&lt;/pre&gt;</p>
                            <p style="margin-left:120px">                &lt;/div&gt;</p>
                            <p style="margin-left:90px">            )}</p>
                            <p style="margin-left:60px">        &lt;/div&gt;</p>
                            <p style="margin-left:30px">    );</p>
                            <p>}</p>
                            <br />
                            <p><span class="color-blue">export default</span> <span class="color-red">App</span>;</p>
                        
                        `
                    }}></div>
                </div>
                <p>
                    In this example:
                </p>
                <ul style={{listStyle:'disc'}}>
                    <li>
                        We use the <strong>`loading`</strong> state variable to indicate when data is being fetched (<strong>`true`</strong>) or when the fetch operation is complete (<strong>`false`</strong>).
                    </li>
                    <li>
                        Initially, <strong>`loading`</strong> is set to <strong>`true`</strong>, and we display a "Loading..." message.
                    </li>
                    <li>
                        Once the data is fetched successfully, we set <strong>`loading`</strong> to <strong>`false`</strong> and display the fetched data.
                    </li>
                    <li>
                        If an error occurs during the fetch operation, we set <strong>`loading`</strong> to <strong>`false`</strong> and display an error message.
                    </li>
                </ul>
            </div>
        </section>
    )
}