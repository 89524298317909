import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../../Context";
export const pageUrl = () => "/react-js/hooks/custom";

export default function ReactHookCustom() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("React Hooks - Custom Hooks | Aspirant's Home");
        const urls = {
            'previous': '/react-js/hooks/usememo',
            'next':'/react-js/forms'
        }
        path.setPreviousNext(urls);
    },[])

    return (
        <section className='mt-5 mb-5'>
            <h3>React Custom Hook</h3>
            <div className='mt-4 mb-4'>
                Hooks are essentially reusable functions. When you have component logic that needs to be used by multiple components, you can extract that logic into a custom Hook. Custom Hooks typically start with "use". For example, <strong>`useToggle`</strong>.
            </div>
            <p>Here is an example, how we create a Custom Hook.</p>
            <div className='codePalateBox mt-2 mb-5'>
                <div className='codePalate' dangerouslySetInnerHTML={{
                    __html: `
                        <p><span class="color-grey">// useToggle.js</span></p>
                        <p><span class="color-blue">import</span> { <span class="color-red">useState</span> } <span class="color-blue">from</span> <span class="color-green">'react'</span>;</p>
                        <br />
                        <p><span class="color-grey">// Custom hook for managing a boolean toggle state</span></p>
                        <p><span class="color-blue">function</span> <span class="color-red">useToggle</span>(initialState = false) {</p>
                        <p style="margin-left:30px">    <span class="color-blue">const</span> [state, setState] = <span class="color-red">useState</span>(initialState);</p>
                        <br />
                        <p style="margin-left:30px">    <span class="color-blue">const</span> <span class="color-red">toggle</span> = () => {</p>
                        <p style="margin-left:60px">        <span class="color-red">setState</span>((prevState) => !prevState);</p>
                        <p style="margin-left:30px">    };</p>
                        <br />
                        <p style="margin-left:30px">    <span class="color-blue">return</span> [state, toggle];</p>
                        <p>}</p>
                        <br />
                        <p><span class="color-blue">export default</span> <span class="color-red">useToggle</span>;</p>
                    `
                }}></div>
            </div>
            <p>
                In this example, <strong>`useToggle`</strong> is a custom hook that manages a boolean toggle state. It uses the useState hook internally to manage the state and provides a toggle function to toggle the state value. This custom hook can be reused in any component that needs a boolean toggle state.
            </p>
            <p>
                To use the <strong>`useToggle`</strong> custom hook, you simply import it into your component and call it to get the state value and the toggle function. Here's an example of how you can use the <strong>`useToggle`</strong> hook in a component:
            </p>
            <div className='codePalateBox mt-2 mb-5'>
                <div className='codePalate' dangerouslySetInnerHTML={{
                    __html: `
                        <p> <span class="color-grey">// App.js</span></p>
                        <p><span class="color-blue">import</span> <span class="color-red">React</span> <span class="color-blue">from</span> <span class="color-green">'react'</span>;</p>
                        <p><span class="color-blue">import</span> <span class="color-red">useToggle</span> <span class="color-blue">from</span> <span class="color-green">'./useToggle'</span>; <span class="color-grey">// Assuming useToggle is defined in a separate file</span></p>
                        <br />
                        <p><span class="color-blue">function</span> <span class="color-red">App</span>() {</p>
                        <p style="margin-left:30px">    <span class="color-grey">// Using the useToggle hook to manage a boolean toggle state</span></p>
                        <p style="margin-left:30px">    <span class="color-blue">const</span> [isToggled, toggle] = <span class="color-red">useToggle</span>(false);</p>
                        <br />    
                        <p style="margin-left:30px">    <span class="color-blue">return</span> (</p>
                        <p style="margin-left:60px">        &lt;div&gt;</p>
                        <p style="margin-left:90px">             &lt;p&gt;<span class="color-green">{isToggled ? 'Toggled On' : 'Toggled Off'}</span>&lt;/p&gt;</p>
                        <p style="margin-left:90px">             &lt;button <span class="color-pink">onClick</span>=<span class="color-green">{toggle}</span>&gt;Toggle&lt;/button&gt;</p>
                        <p style="margin-left:60px">        &lt;/div&gt;</p>
                        <p style="margin-left:30px">    );</p>
                        <p>}</p>
                        <br />
                        <p><span class="color-blue">export default</span> <span class="color-red">App</span>;</p>
                    `
                }}></div>
            </div>
            <p>
                In this example, the <strong>`useToggle`</strong> hook is used to manage a boolean toggle state (<strong>`isToggled`</strong>) and provide a <strong>`toggle`</strong> function to toggle the state value. The <strong>`isToggled`</strong> state is displayed in a paragraph element, and a button is used to toggle the state when clicked.
            </p>
        </section>
    )
}
