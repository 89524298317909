import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/css/text/transformation";

export default function TextTransformation() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Text Transformation in CSS | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/css/text/decoration',
            'next': '/css/text/spacing'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>Text Transformation</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <strong>`text-transform`</strong> property in CSS is used to control the capitalization of text. It allows you to convert text to uppercase, lowercase, or capitalize the first letter of each word. This property is particularly useful for styling text without altering the actual content in the HTML. It has a basic syntax -
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>element {</p>
                            <p class="ml-30">    <span class="color-green">text-transform</span>: value;</p>
                            <p>}</p>
                        `
                    }}></div>
                </div>
                <p>
                    Where, value defines how the text will be transformed.
                </p>

                <h5 className="mt-5 mb-3">Values of `text-transform`</h5>
                <ul style={{ listStyle: 'disc' }}>
                    <li>
                        <strong>`none`</strong> : If the value is none then no transformation is applied. The text appears exactly as it is written in the HTML.
                    </li>
                    <li>
                        <strong>`capitalize`</strong> : If the value is capitalize then it transforms the first letter of each word in the text to uppercase.
                    </li>
                    <li>
                        <strong>`uppercase`</strong> : If the value is uppercase then it converts all characters in the text to uppercase.
                    </li>
                    <li>
                        <strong>`lowercase`</strong> : If the value is lowercase then it converts all characters in the text to lowercase.
                    </li>
                    <li>
                        <strong>`full-width`</strong> : If the value is full-width then it transforms characters into their full-width form.
                    </li>
                </ul>

                <h5 className="mt-5 mb-3">Examples of `text-transform`</h5>
                <p>
                    Here are some examples of `text-transform`.
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;style&gt;</p>
                        <p class="ml-30">    .none {</p>
                        <p class="ml-60">        <span class="color-green">text-transform</span>: none;</p>
                        <p class="ml-30">    }</p>
                        <br />
                        <p class="ml-30">    .capitalize {</p>
                        <p class="ml-60">        <span class="color-green">text-transform</span>: capitalize;</p>
                        <p class="ml-30">    }</p>
                        <br />
                        <p class="ml-30">    .uppercase {</p>
                        <p class="ml-60">        <span class="color-green">text-transform</span>: uppercase;</p>
                        <p class="ml-30">    }</p>
                        <br />
                        <p class="ml-30">    .lowercase {</p>
                        <p class="ml-60">        <span class="color-green">text-transform</span>: lowercase;</p>
                        <p class="ml-30">    }</p>
                        <p>&lt;/style&gt;</p>
                        <p>&lt;p <span class="color-pink">class</span>=<span class="color-green">"none"</span>&lt;This is normal text.&lt;/p&gt;</p>
                        <p>&lt;p <span class="color-pink">class</span>=<span class="color-green">"capitalize"</span>&gt;this is capitalized text.&lt;/p&gt;</p>
                        <p>&lt;p <span class="color-pink">class</span>=<span class="color-green">"uppercase"</span>&gt;this is uppercase text.&lt;/p&gt;</p>
                        <p>&lt;p <span class="color-pink">class</span>=<span class="color-green">"lowercase"</span>&gt;THIS IS LOWERCASE TEXT.&lt;/p&gt;</p>
                        `
                    }}></div>
                </div>
                <p>
                    Output will be the following -
                </p>
                <div className='outputPalateBox mb-4 mt-3'>
                    <div className='outputPalate' dangerouslySetInnerHTML={{
                        __html: `
                        <style>
                            .none {
                                text-transform: none;
                            }
                    
                            .capitalize {
                                text-transform: capitalize;
                            }
                    
                            .uppercase {
                                text-transform: uppercase;
                            }
                    
                            .lowercase {
                                text-transform: lowercase;
                            }
                        </style>
                        <p class="none">This is normal text.</p>
                        <p class="capitalize">this is capitalized text.</p>
                        <p class="uppercase">this is uppercase text.</p>
                        <p class="lowercase">THIS IS LOWERCASE TEXT.</p>
                        `
                    }}></div>
                </div>
            </div>
        </section>
    )
}