import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../../Context";
import { Link } from 'react-router-dom';
export const pageUrl = () => "/mysql/date-and-time-functions/convert-tz";

export default function ConvertTZ() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Mysql - Date and Time functions - CONVERT_TZ() | Aspirant's Home");
        path.setPreviousNext({});
    }, [])

    return (
        <>
            <section class='clearfix mb-3'>
                <span className='float-start'>
                    <Link to='/mysql/date-and-time-functions' onClick={() => path.setPathName('/mysql/date-and-time-functions')} className='btn btn-primary'> <i class="fa-solid fa-less-than"></i>&nbsp; Back to Date and Time functions </Link>
                </span>
            </section>
            <section className='mt-5 mb-5'>
                <h3>Mysql - CONVERT_TZ() Functions</h3>
                <div className='mt-4 mb-5'>
                    <p>
                        The <strong>`CONVERT_TZ()`</strong> function in MySQL is used to convert a datetime value from one time zone to another. It takes three arguments: the datetime value you want to convert, the original time zone, and the target time zone.
                    </p>
                    <p>
                        Here's the basic syntax of the <strong>`CONVERT_TZ()`</strong> function:
                    </p>
                    <div className='codePalateBox mt-2 mb-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                                <p>CONVERT_TZ(datetime_value, from_time_zone, to_time_zone)</p>
                            `
                        }}></div>
                    </div>
                    <p>
                        Where, <strong>`datetime_value`</strong> is the datetime value you want to convert. <strong>`from_time_zone`</strong> is the original time zone of the datetime value. <strong>`to_time_zone`</strong> is the target time zone to which you want to convert the datetime value.
                    </p>
                    <p>
                        For example, if you have a datetime value <strong>`'2022-05-16 12:00:00'`</strong> in the UTC time zone and you want to convert it to the 'America/New_York' time zone, you can use <strong>`CONVERT_TZ()`</strong> like this:
                    </p>
                    <div className='codePalateBox mt-2 mb-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                                <p><span class="color-blue">SELECT</span> CONVERT_TZ(<span class="color-green">'2022-05-16 12:00:00'</span>, <span class="color-green">'UTC'</span>, <span class="color-green">'America/New_York'</span>);</p>
                            `
                        }}></div>
                    </div>
                    <p>
                        This will return <strong>`'2022-05-16 08:00:00'`</strong>, which is 12:00 PM UTC converted to 08:00 AM in the America/New_York time zone.
                    </p>
                </div>
                <div className='mt-5 mb-5'>
                    <h5>CONVERT_TZ() with MySQL Table</h5>
                    <div className='mt-4 mb-2'>
                        <p>
                            To use the <strong>`CONVERT_TZ()`</strong> function in MySQL with a table, let's consider a table <strong>`events`</strong> with columns <strong>`id`</strong>, <strong>`event_name`</strong>, <strong>`start_time`</strong> (datetime), and <strong>`time_zone`</strong> (varchar) where <strong>`start_time`</strong> represents the start time of each event and <strong>`time_zone`</strong> represents the time zone of each event. We want to convert the start time of each event to a different time zone. 
                        </p>
                        <p>
                            Consider the <strong>`events`</strong> table:
                        </p>
                        <table className='table' style={{border: '1px dashed #ccc'}}> 
                            <thead>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>id</th>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>event_name</th>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>start_time</th>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>time_zone</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>1</td>
                                    <td style={{border: '1px dashed #ccc'}}>Event A</td>
                                    <td style={{border: '1px dashed #ccc'}}>2022-05-16 09:00:00</td>
                                    <td style={{border: '1px dashed #ccc'}}>UTC</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>2</td>
                                    <td style={{border: '1px dashed #ccc'}}>Event B</td>
                                    <td style={{border: '1px dashed #ccc'}}>2022-05-16 10:30:00</td>
                                    <td style={{border: '1px dashed #ccc'}}>America/New_York</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>3</td>
                                    <td style={{border: '1px dashed #ccc'}}>Event C</td>
                                    <td style={{border: '1px dashed #ccc'}}>2022-05-16 12:00:00</td>
                                    <td style={{border: '1px dashed #ccc'}}>Asia/Tokyo</td>
                                </tr>
                            </tbody>
                        </table>
                        <p>
                            To convert the <strong>`start_time`</strong> of each event to a different time zone specified in the <strong>`time_zone`</strong> column, you would use:
                        </p>
                        <div className='codePalateBox mt-2 mb-2'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-blue">SELECT</span> id, event_name, start_time, time_zone, CONVERT_TZ(start_time, 'UTC', time_zone) <span class="color-blue">AS</span> converted_start_time <span class="color-blue">FROM</span> events;</p>
                                `
                            }}></div>
                        </div>
                        <p>
                            This query will return:
                        </p>
                        <table className='table' style={{border: '1px dashed #ccc'}}> 
                            <thead>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>id</th>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>event_name</th>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>start_time</th>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>time_zone</th>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>converted_start_time</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>1</td>
                                    <td style={{border: '1px dashed #ccc'}}>Event A</td>
                                    <td style={{border: '1px dashed #ccc'}}>2022-05-16 09:00:00</td>
                                    <td style={{border: '1px dashed #ccc'}}>UTC</td>
                                    <td style={{border: '1px dashed #ccc'}}>2022-05-16 09:00:00</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>2</td>
                                    <td style={{border: '1px dashed #ccc'}}>Event B</td>
                                    <td style={{border: '1px dashed #ccc'}}>2022-05-16 10:30:00</td>
                                    <td style={{border: '1px dashed #ccc'}}>America/New_York</td>
                                    <td style={{border: '1px dashed #ccc'}}>2022-05-16 06:30:00</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>3</td>
                                    <td style={{border: '1px dashed #ccc'}}>Event C</td>
                                    <td style={{border: '1px dashed #ccc'}}>2022-05-16 12:00:00</td>
                                    <td style={{border: '1px dashed #ccc'}}>Asia/Tokyo</td>
                                    <td style={{border: '1px dashed #ccc'}}>2022-05-16 21:00:00</td>
                                </tr>
                            </tbody>
                        </table>
                        <p>
                            In this example, the <strong>`CONVERT_TZ()`</strong> function is used to convert the <strong>`start_time`</strong> of each event from 'UTC' to the time zone specified in the <strong>`time_zone`</strong> column. The result is displayed as <strong>`converted_start_time`</strong> in the result set.
                        </p>
                    </div>
                </div>
            </section>
        </>
    )
}