import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../../Context";
import { Link } from 'react-router-dom';
export const pageUrl = () => "/mysql/string-functions/substring";

export default function SUBSTRING() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Mysql - String Functions - SUBSTRING() | Aspirant's Home");
        path.setPreviousNext({});
    }, [])

    return (
        <>
            <section class='clearfix mb-3'>
                <span className='float-start'>
                    <Link to='/mysql/string-functions' onClick={() => path.setPathName('/mysql/string-functions')} className='btn btn-primary'> <i class="fa-solid fa-less-than"></i>&nbsp; Back to String Functions </Link>
                </span>
            </section>
            <section className='mt-5 mb-5'>
                <h3>Mysql - SUBSTRING() Function</h3>
                <div className='mt-4 mb-5'>
                    <p>
                        The <strong>`SUBSTRING()`</strong> function in MySQL is used to extract a substring from a given string. It allows you to specify the starting position and the length of the substring you want to extract. Here’s a detailed explanation with examples, including usage in a table context.
                    </p>
                    <div className='mb-4'>
                        <h5>Extract a substring starting from a specific position:</h5>
                        <div className='codePalateBox mt-2 mb-2'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p>SUBSTRING(string, start_position)</p>
                                `
                            }}></div>
                        </div>
                    </div>
                    <div className='mb-4'>
                        <h5>Extract a substring with a specified length:</h5>
                        <div className='codePalateBox mt-2 mb-2'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p>SUBSTRING(string, start_position, length)</p>
                                `
                            }}></div>
                        </div>
                    </div>
                    <p>
                        Where, <strong>`string`</strong> is the string from which the substring will be extracted. <strong>`start_position`</strong> is the position to start extracting from. Positions start at 1. <strong>`length`</strong> is the number of characters to extract.
                    </p>
                </div>
                <div className='mt-5 mb-5'>
                    <h5>SUBSTRING() with MySQL Table</h5>
                    <div className='mt-3 mb-2'>
                    <p>
                            Consider a table <strong>`employees`</strong> with columns <strong>`id`</strong>, <strong>`first_name`</strong> and <strong>`last_name`</strong>.
                        </p>
                        <table className='table' style={{border: '1px dashed #ccc'}}> 
                            <thead>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>id</th>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>first_name</th>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>last_name</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>1</td>
                                    <td style={{border: '1px dashed #ccc'}}>John</td>
                                    <td style={{border: '1px dashed #ccc'}}>Doe</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>2</td>
                                    <td style={{border: '1px dashed #ccc'}}>Jane</td>
                                    <td style={{border: '1px dashed #ccc'}}>Smith</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>3</td>
                                    <td style={{border: '1px dashed #ccc'}}>Emily</td>
                                    <td style={{border: '1px dashed #ccc'}}>Johnson</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>4</td>
                                    <td style={{border: '1px dashed #ccc'}}>Michael</td>
                                    <td style={{border: '1px dashed #ccc'}}>Brown</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>5</td>
                                    <td style={{border: '1px dashed #ccc'}}>Jessica</td>
                                    <td style={{border: '1px dashed #ccc'}}>Williams</td>
                                </tr>
                            </tbody>
                        </table>
                        <p>
                            If you want to extract the first three letters of each employee's first name.
                        </p>
                        <div className='codePalateBox mt-2 mb-2'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                   <p><span class="color-blue"> SELECT</span> id, first_name, last_name, SUBSTRING(first_name, 1, 3) <span class="color-blue">AS</span> first_three_letters <span class="color-blue">FROM</span> employees;</p>
                                `
                            }}></div>
                        </div>
                        <p>
                            This query will return:
                        </p>
                        <table className='table' style={{border: '1px dashed #ccc'}}> 
                            <thead>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>id</th>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>first_name</th>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>last_name</th>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>first_three_letters</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>1</td>
                                    <td style={{border: '1px dashed #ccc'}}>John</td>
                                    <td style={{border: '1px dashed #ccc'}}>Doe</td>
                                    <td style={{border: '1px dashed #ccc'}}>Joh</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>2</td>
                                    <td style={{border: '1px dashed #ccc'}}>Jane</td>
                                    <td style={{border: '1px dashed #ccc'}}>Smith</td>
                                    <td style={{border: '1px dashed #ccc'}}>Jan</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>3</td>
                                    <td style={{border: '1px dashed #ccc'}}>Emily</td>
                                    <td style={{border: '1px dashed #ccc'}}>Johnson</td>
                                    <td style={{border: '1px dashed #ccc'}}>Emi</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>4</td>
                                    <td style={{border: '1px dashed #ccc'}}>Michael</td>
                                    <td style={{border: '1px dashed #ccc'}}>Brown</td>
                                    <td style={{border: '1px dashed #ccc'}}>Mic</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>5</td>
                                    <td style={{border: '1px dashed #ccc'}}>Jessica</td>
                                    <td style={{border: '1px dashed #ccc'}}>Williams</td>
                                    <td style={{border: '1px dashed #ccc'}}>Jes</td>
                                </tr>
                            </tbody>
                        </table>
                        <p>
                            In this case, <strong>`SUBSTRING(first_name, 1, 3)`</strong> extracts a substring starting at position 1 and extending for 3 characters.
                        </p>
                    </div>
                </div>
            </section>
        </>
    )
}