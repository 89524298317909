import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";

export default function HTMLFonts() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("HTML - Fonts | Aspirant's Home");
        const urls = {
            'previous': '/html/styles',
            'next': '/html/blocks'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>HTML - Fonts</h3>
            <div className='mt-4 mb-5'>

            </div>
        </section>
    )
}