import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../../Context";
import { Link } from 'react-router-dom';
export const pageUrl = () => "/node-js/built-in-modules/events";

export default function EventsModule() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("NodeJs - Events Module | Aspirant's Home");
        path.setPreviousNext({});
    }, [])

    return (
        <div className='contentPage'>
            <section class='clearfix mb-3'>
                <span className='float-start'>
                    <Link to='/node-js/built-in-modules' onClick={() => path.setPathName('/node-js/built-in-modules')} className='btn btn-primary'> <i class="fa-solid fa-less-than"></i>&nbsp; Back to Built-In Modules </Link>
                </span>
            </section>
            <section className='mt-5 mb-5'>
                <h3>Node Js Events Module</h3>
                <div className='mt-4'>
                    <p>The events module provides a way to handle and emit events. It is a core module that allows objects to emit and listen for events. This module is the foundation of many other Node.js modules and is used extensively in building event-driven applications.</p>
                    <p>The syntax for including the events module in your application: </p>
                    <div className='codePalateBox mt-2 mb-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `<p><span class='color-blue'>var</span> events = <span class='color-yellow'>require</span>(<span class='color-green'>'events'</span>);</p>
                                    `
                        }}></div>
                    </div>
                </div>
                <div className='mt-4'>
                    <h4>Examples</h4>
                    <p>Here's a basic example of how you might use the events module:</p>
                    <div className='mt-4'>
                        <h5>Creating Custom Events:</h5>
                        <p>You can create a custom event emitter by extending the EventEmitter class:</p>
                        <div className='codePalateBox mt-2 mb-2'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `<p><span class='color-blue'>const</span> <span class='color-red'>EventEmitter</span> = <span class='color-yellow'>require</span>(<span class='color-green'>'events'</span>);</p>
                            <p><span class='color-blue'>class</span> <span class='color-red'>MyEmitter</span> <span class='color-blue'>extends</span> <span class='color-red'>EventEmitter</span> {}</p>
                            <p><span class='color-blue'>const</span> myEmitter = <span class='color-blue'>new</span> <span class='color-red'>MyEmitter</span>();</p>
                            `
                            }}></div>
                        </div>
                    </div>
                    <div className='mt-4'>
                        <h5>Listening for Events:</h5>
                        <p>You can listen for events by using the <strong>`on()`</strong> method, which registers a listener for a specific event:</p>
                        <div className='codePalateBox mt-2 mb-2'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `<p>myEmitter.<span class='color-red'>on</span>(<span class='color-green'>'myEvent'</span>, () => {</p>
                                    <p style="margin-left:30px"><span class='color-pink'>console</span>.<span class='color-red'>log</span>(<span class='color-green'>'Event occurred'</span>);</p>
                                  <p>});</p>
                                  `
                            }}></div>
                        </div>
                    </div>
                    <div className='mt-4'>
                        <h5>Emitting Events:</h5>
                        <p>You can emit an event using the <strong>`emit()`</strong> method, which triggers all listeners for that event:</p>
                        <div className='codePalateBox mt-2 mb-2'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `<p>myEmitter.<span class='color-red'>emit</span>(<span class='color-green'>'myEvent'</span>);</p>
                                  `
                            }}></div>
                        </div>
                    </div>
                    <div className='mt-4'>
                        <h5>Passing Data with Events:</h5>
                        <p>You can pass data to listeners by including it as arguments in the <strong>`emit()`</strong> method:</p>
                        <div className='codePalateBox mt-2 mb-2'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `<p>myEmitter.<span class='color-red'>on</span>(<span class='color-green'>'dataEvent'</span>, (data) => {</p>
                                    <p style="margin-left:30px"><span class='color-pink'>console</span>.<span class='color-red'>log</span>(<span class='color-green'>'Data received:'</span>, data);</p>
                                  <p>});</p>
                                  <br />
                                  <p>myEmitter.<span class='color-red'>emit</span>(<span class='color-green'>'dataEvent'</span>, <span class='color-green'>'Hello, World!'</span>);</p>                                  
                                  `
                            }}></div>
                        </div>
                    </div>
                    <div className='mt-4'>
                        <h5>Once Listener:</h5>
                        <p>You can use the <strong>`once()`</strong> method to register a listener that will be called only once:</p>
                        <div className='codePalateBox mt-2 mb-2'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `<p>myEmitter.<span class='color-red'>once</span>(<span class='color-green'>'onceEvent'</span>, () => {</p>
                                    <p style="margin-left:30px"><span class='color-pink'>console</span>.<span class='color-red'>log</span>(<span class='color-green'>'This listener will be called only once'</span>);</p>
                                  <p>});</p>
                                  <br />
                                  <p>myEmitter.<span class='color-red'>emit</span>(<span class='color-green'>'onceEvent'</span>);</p>
                                  <p>myEmitter.<span class='color-red'>emit</span>(<span class='color-green'>'onceEvent'</span>); <span class='color-grey'>// This will not trigger the listener</span></p>`
                            }}></div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}
