import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/css/outline/color";

export default function OutlineColor() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Outline Color in CSS | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/css/outline/width',
            'next': '/css/outline/shorthand'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>Outline Color</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <strong>`outline-color`</strong> property in CSS is used to set the color of an element's outline. The outline color can be defined in various formats, including named colors, HEX values, RGB/RGBA values, HSL/HSLA values, or the keyword <strong>`invert`</strong>.
                </p>
                <p>
                    The <strong>`outline-color`</strong> property sets the color of an element's outline.
                </p>
                <p>
                    You can set the color using:
                </p>
                <ul style={{listStyle:'disc'}}>
                    <li><strong>Name</strong>: A color name, like "red."</li>
                    <li><strong>HEX</strong>: A hex value, like "#ff0000."</li>
                    <li><strong>RGB</strong>: An RGB value, like "rgb(255, 0, 0)."</li>
                    <li><strong>HSL</strong>: An HSL value, like "hsl(0, 100%, 50%)."</li>
                    <li><strong>Invert</strong>: This inverts the color, making sure the outline is visible against any background.</li>
                </ul>
                <p>
                    Here’s an example showing different outlines with various colors. Notice that each element also has a thin black border inside the outline.
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;style&gt;</p>
                        <p class="ml-30">    .box {</p>
                        <p class="ml-60">        <span class="color-green">width</span>: <span class="color-pink">200px</span>;</p>
                        <p class="ml-60">        <span class="color-green">height</span>: <span class="color-pink">100px</span>;</p>
                        <p class="ml-60">        <span class="color-green">padding</span>: <span class="color-pink">20px</span>;</p>
                        <p class="ml-60">        <span class="color-green">margin</span>: <span class="color-pink">10px</span>;</p>
                        <p class="ml-60">        <span class="color-green">outline-style</span>: solid;</p>
                        <p class="ml-60">        <span class="color-green">outline-width</span>: <span class="color-pink">4px</span>;</p>
                        <p class="ml-60">        <span class="color-green">display</span>: inline-flex;</p>
                        <p class="ml-30">    }</p>
                        <br />
                        <p class="ml-30">    .box1 {</p>
                        <p class="ml-60">        <span class="color-green">outline-color</span>: red; <span class="color-grey">/* Named color */</span></p>
                        <p class="ml-30">    }</p>
                        <br />
                        <p class="ml-30">    .box2 {</p>
                        <p class="ml-60">        <span class="color-green">outline-color</span>: <span class="color-pink">#00ff00</span>; <span class="color-grey">/* HEX color */</span></p>
                        <p class="ml-30">    }</p>
                        <br />
                        <p class="ml-30">    .box3 {</p>
                        <p class="ml-60">        <span class="color-green">outline-color</span>: <span class="color-pink">rgb(0, 0, 255)</span>; <span class="color-grey">/* RGB color */</span></p>
                        <p class="ml-30">    }</p>
                        <br />
                        <p class="ml-30">    .box4 {</p>
                        <p class="ml-60">        <span class="color-green">outline-color</span>: <span class="color-pink">rgba(255, 165, 0, 0.7)</span>; <span class="color-grey">/* RGBA color */</span></p>
                        <p class="ml-30">    }</p>
                        <br />
                        <p class="ml-30">    .box5 {</p>
                        <p class="ml-60">        <span class="color-green">outline-color</span>: <span class="color-pink">hsl(120, 100%, 50%)</span>; <span class="color-grey">/* HSL color */</span></p>
                        <p class="ml-30">    }</p>
                        <br />
                        <p class="ml-30">    .box6 {</p>
                        <p class="ml-60">        <span class="color-green">outline-color</span>: <span class="color-pink">hsla(240, 100%, 50%, 0.5)</span>; <span class="color-grey">/* HSLA color */</span></p>
                        <p class="ml-30">    }</p>
                        <p>&lt;/style&gt;</p>
                        <p>&lt;div <span class="color-pink">class</span>=<span class="color-green">"box box1"</span>&gt;Red Outline&lt;/div&gt;</p>
                        <p>&lt;div <span class="color-pink">class</span>=<span class="color-green">"box box2"</span>&gt;Green Outline (HEX)&lt;/div&gt;</p>
                        <p>&lt;div <span class="color-pink">class</span>=<span class="color-green">"box box3"</span>&gt;Blue Outline (RGB)&lt;/div&gt;</p>
                        <p>&lt;div <span class="color-pink">class</span>=<span class="color-green">"box box4"</span>&gt;Orange Outline (RGBA)&lt;/div&gt;</p>
                        <p>&lt;div <span class="color-pink">class</span>=<span class="color-green">"box box5"</span>&gt;Green Outline (HSL)&lt;/div&gt;</p>
                        <p>&lt;div <span class="color-pink">class</span>=<span class="color-green">"box box6"</span>&gt;Semi-transparent Blue (HSLA)&lt;/div&gt;</p>
                        `
                    }}></div>
                </div>
                <p>
                    Output will be the following -
                </p>
                <div className='outputPalateBox mb-4 mt-3' style={{'border':'0'}}>
                    <div className='outputPalate' dangerouslySetInnerHTML={{
                        __html: `
                        <style>
                            .box {
                                width: 200px;
                                height: 100px;
                                padding: 20px;
                                margin: 10px;
                                outline-style: solid;
                                outline-width: 4px;
                                display: inline-flex;
                            }
                    
                            .box1 {
                                outline-color: red; /* Named color */
                            }
                    
                            .box2 {
                                outline-color: #00ff00; /* HEX color */
                            }
                    
                            .box3 {
                                outline-color: rgb(0, 0, 255); /* RGB color */
                            }
                    
                            .box4 {
                                outline-color: rgba(255, 165, 0, 0.7); /* RGBA color */
                            }
                    
                            .box5 {
                                outline-color: hsl(120, 100%, 50%); /* HSL color */
                            }
                    
                            .box6 {
                                outline-color: hsla(240, 100%, 50%, 0.5); /* HSLA color */
                            }
                        </style>
                        <div class="box box1">Red Outline</div>
                        <div class="box box2">Green Outline (HEX)</div>
                        <div class="box box3">Blue Outline (RGB)</div>
                        <div class="box box4">Orange Outline (RGBA)</div>
                        <div class="box box5">Green Outline (HSL)</div>
                        <div class="box box6">Semi-transparent Blue (HSLA)</div>
                        `
                    }}></div>
                </div>
            </div>
        </section>
    )
}