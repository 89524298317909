import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/node-js/mongodb";

export default function MongoDbStarted() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Node Js - MongoDB - Get Started | Aspirant's Home");
        const urls = {
            'previous': '/node-js/mysql-join-table',
            'next': '/node-js/mongodb-create-database'
        }
        path.setPreviousNext(urls);
    }, [])
    
    return (
        <section className='mt-5 mb-5'>
            <h3 className='mb-4'>Node Js & MongoDB</h3>
            <p>MongoDB is a popular open-source, NoSQL, document-oriented database. Unlike traditional relational databases, MongoDB does not store data in tables with rows and columns. Instead, it stores data in flexible, JSON-like documents with dynamic schemas, called BSON (Binary JSON), which allows for easier and more scalable data storage and querying.</p>
            <p>To interact with a MongoDB database in Node.js, you can use the <strong>`mongodb`</strong> package, which is a MySQL client for Node.js. To download and install the <strong>`mongodb`</strong> module, open the Command Terminal and execute the following:</p>
            <div className='mb-5'>
                <p>Install mongodb using npm: </p>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{ __html: '<p>npm install mongodb</p>' }}></div>
                </div>
            </div>
            <p>After installing the <strong>`mongodb`</strong> module, you can use this module like this</p>
            <div className='codePalateBox mt-2 mb-2'>
                <div className='codePalate' dangerouslySetInnerHTML={{ __html: `<p><span class='color-blue'>var</span> mongo = <span class='color-yellow'>require</span>(<span class='color-green'>'mongodb'</span>);</p>` }}></div>
            </div>
        </section>
    )
}
