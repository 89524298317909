import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/mysql/create-index";

export default function MysqlCreateIndex() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Mysql - Create Index | Aspirant's Home");
        const urls = {
            'previous': '/mysql/rename-views',
            'next': '/mysql/drop-index'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>Mysql - Create Index</h3>
            <div className='mt-4 mb-5'>
                <p>
                    In MySQL, an index is a data structure that improves the speed of data retrieval operations on a database table at the cost of additional space and decreased performance for write operations. Indexes are created on columns in database tables to quickly locate rows with specific column values without having to scan the entire table.
                </p>
            </div>
            <div className='mt-5 mb-5'>
                <h5>Creating Index on New Table</h5>
                <div className='mt-2 mb-2'>
                    <p>
                        When creating a new table in MySQL, you can create indexes on one or more columns to improve query performance. Indexes are created using the <strong>`CREATE TABLE`</strong> statement with the INDEX keyword.
                    </p>
                    <p>
                        Here's an example of how you can create a new table with index:
                    </p>
                    <div className='codePalateBox mt-2 mb-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                            <p><span class="color-blue">CREATE TABLE</span> users (</p>
                            <p style="margin-left:30px">    id <span class="color-pink">INT</span> AUTO_INCREMENT <span class="color-blue">PRIMARY KEY</span>,</p>
                            <p style="margin-left:30px">    username <span class="color-pink">VARCHAR(50)</span> <span class="color-blue">NOT NULL</span>,</p>
                            <p style="margin-left:30px">    email <span class="color-pink">VARCHAR(100)</span> <span class="color-blue">NOT NULL</span>,</p>
                            <p style="margin-left:30px">    created_at <span class="color-pink">TIMESTAMP</span> <span class="color-blue">DEFAULT</span> <span class="color-yellow">CURRENT_TIMESTAMP</span>,</p>
                            <p style="margin-left:30px">    <span class="color-blue">INDEX</span> idx_username (username)</p>
                            <p>);</p>
                            `
                        }}></div>
                    </div>
                </div>
            </div>
            <h5>Creating Index on Existing Table</h5>
            <div className='mt-2 mb-2'>
                <p>
                    To create an index on existing table, we use the following SQL statements −
                </p>
                <ul>
                    <li>Using CREATE INDEX Statement</li>
                    <li>Using ADD INDEX Statement</li>
                </ul>
            </div>
            <div className='mt-5 mb-5'>
                <h5>Using CREATE INDEX Statement</h5>
                <div className='mt-2 mb-2'>
                    <p>
                        To create index on an existing table in MySQL, you can use the <strong>`CREATE INDEX`</strong> statement. Here's the basic syntax:
                    </p>
                    <div className='codePalateBox mt-2 mb-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                                <p><span class="color-blue">CREATE INDEX</span> index_name <span class="color-blue">ON</span> table_name (column_name);</p>
                            `
                        }}></div>
                    </div>
                    <p>
                        Replace <strong>`index_name`</strong> with the name you want to give to the index, <strong>`table_name`</strong> with the name of your existing table, and <strong>`column_name`</strong> with the name of the column you want to index.
                    </p>
                    <p>
                        For example, let's say you have an existing table called <strong>`users`</strong> and you want to create an index on the email column:
                    </p>
                    <div className='codePalateBox mt-2 mb-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                                <p><span class="color-blue">CREATE INDEX</span> idx_email <span class="color-blue">ON</span> users (email);</p>
                            `
                        }}></div>
                    </div>
                </div>
            </div>
            <div className='mt-5 mb-5'>
                <h5>Using ADD INDEX Statement</h5>
                <div className='mt-2 mb-2'>
                    <p>
                        To create an index using the <strong>`ALTER TABLE`</strong> statement in MySQL, you can use the <strong>`ADD INDEX`</strong> clause. Here's the basic syntax:
                    </p>
                    <div className='codePalateBox mt-2 mb-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                                <p><span class="color-blue">ALTER TABLE</span> table_name <span class="color-blue">ADD INDEX</span> index_name (column_name);</p>
                            `
                        }}></div>
                    </div>
                    <p>
                        Replace <strong>`table_name`</strong> with the name of your existing table, <strong>`index_name`</strong> with the name you want to give to the index, and <strong>`column_name`</strong> with the name of the column you want to index.
                    </p>
                    <p>
                        For example, to add an index on the email column of the <strong>`users`</strong> table:
                    </p>
                    <div className='codePalateBox mt-2 mb-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                                <p><span class="color-blue">ALTER TABLE</span> users <span class="color-blue">ADD INDEX</span> idx_email (email);</p>
                            `
                        }}></div>
                    </div>
                    <p>
                        When using <strong>`ALTER TABLE`</strong> to add an index, MySQL will lock the table for writes while the index is being created, which can impact the performance of your application. It's generally a good practice to create indexes during off-peak hours to add indexes without locking the table. 
                    </p>
                </div>
            </div>
        </section>
    )
}