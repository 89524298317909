import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../../Context";
export const pageUrl = () => "/react-js/hooks/usestate";

export default function ReactHookUseState() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("React Hooks - useState() Hooks | Aspirant's Home");
        const urls = {
            'previous': '/react-js/hooks',
            'next':'/react-js/hooks/useeffect'
        }
        path.setPreviousNext(urls);
    },[])

    return (
        <section className='mt-5 mb-5'>
            <h3>React useState Hook</h3>
            <div className='mt-4 mb-4'>
                <p>
                    In React, state variables are declared using the <strong>`useState`</strong> hook (in functional components) or as part of the component's class (in class components). When a state variable changes, React re-renders the component to reflect the updated state.
                </p>
                <div className='mt-5 mb-5 featureClass'>
                    <p>
                        The <strong>`useState`</strong> Hook can be used to keep track of strings, numbers, booleans, arrays, objects, and any combination of these!
                    </p>
                    <p>
                        We could create multiple state Hooks to track individual values Or, we can just use one state and include an object instead!
                    </p>
                </div>

                <h5>What is State Variables ?</h5>
                <p>
                    State variables, also known simply as state, are variables used in React components to manage the component's dynamic data. They allow components to keep track of information that can change over time, such as user input, server responses, or UI state. State variables are essential for creating interactive and dynamic user interfaces. 
                </p>

                <div className='mt-5 mb-5'>
                    <h5>Import <strong>`useState`</strong></h5>
                    <p className='mt-4'>To use the <strong>`useState`</strong> Hook, we first need to import it into our component.</p>
                    <div className='codePalateBox mt-2 mb-5'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                                <p><span class="color-blue">import</span> { <span class="color-red">useState</span> } <span class="color-blue">from</span> <span class="color-green">'react'</span>;</p>
                            `
                        }}></div>
                    </div>
                </div>

                <div className='mt-5 mb-5'>
                    <h5>Initialize <strong>`useState`</strong></h5>
                    <p>We initialize our state by calling useState in our function component. useState accepts an initial state and returns two values. One is current state and another one is a function that updates the state.</p>
                    <div className='codePalateBox mt-2 mb-5'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                                <p><span class="color-blue">function</span> <span class="color-red">Counter</span>() {</p>
                                <p style="margin-left:30px">    <span class="color-grey">// Declare a state variable named count, initialized to 0</span></p>
                                <p style="margin-left:30px">    <span class="color-blue">const</span> [count, setCount] = <span class="color-red">useState</span>(0);</p>
                                <p>}</p>
                            `
                        }}></div>
                    </div>
                </div>

                <div className='mt-5 mb-5'>
                    <h5>Read State</h5>
                    <p>We can now read our state anywhere in our component.</p>
                    <div className='codePalateBox mt-2 mb-5'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                                <p><span class="color-blue">import</span> { <span class="color-red">useState</span> } <span class="color-blue">from</span> <span class="color-green">'react'</span>;</p>
                                <br />
                                <p><span class="color-blue">function</span> <span class="color-red">Counter</span>() {</p>
                                <p style="margin-left:30px">    <span class="color-grey">// Declare a state variable named count, initialized to 0</span></p>
                                <p style="margin-left:30px">    <span class="color-blue">const</span> [count, setCount] = <span class="color-red">useState</span>(0);</p>
                                <p style="margin-left:30px">    <span class="color-blue">return</span> (</p>
                                <p style="margin-left:60px">        &lt;div&gt;</p>
                                <p style="margin-left:90px">            &lt;p&gt;Count: {count}&lt;/p&gt;</p>
                                <p style="margin-left:60px">        &lt;/div&gt;</p>
                                <p style="margin-left:30px">    );</p>
                                <p>}</p>
                                <br />
                                <p><span class="color-blue">export default</span> <span class="color-red">Counter</span>;</p>
                            `
                        }}></div>
                    </div>
                </div>

                <div className='mt-5 mb-5 addBetweenSpace'>
                    <ins class="adsbygoogle"
                        style={{ 'display': 'block' }}
                        data-ad-client="ca-pub-2061924575986153"
                        data-ad-slot="6159913076"
                        data-ad-format="auto"
                        data-full-width-responsive="true"></ins>
                    <script>
                        (adsbygoogle = window.adsbygoogle || []).push({});
                    </script>
                </div>

                <div className='mt-5 mb-5'>
                    <h5>Update State</h5>
                    <p>To update state value we use our state updater function.</p>
                    <div className='codePalateBox mt-2 mb-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                                <p><span class="color-blue">import</span> { <span class="color-red">useState</span> } <span class="color-blue">from</span> <span class="color-green">'react'</span>;</p>
                                <br />
                                <p><span class="color-blue">function</span> <span class="color-red">Counter</span>() {</p>
                                <p style="margin-left:30px">    <span class="color-grey">// Declare a state variable named count, initialized to 0</span></p>
                                <p style="margin-left:30px">    <span class="color-blue">const</span> [count, setCount] = <span class="color-red">useState</span>(0);</p>
                                <br />    
                                <p style="margin-left:30px">    <span class="color-blue">return</span> (</p>
                                <p style="margin-left:60px">        &lt;div&gt;</p>
                                <p style="margin-left:90px">            &lt;p&gt;Count: {count}&lt;/p&gt;</p>
                                <p style="margin-left:90px">            &lt;button <span class="color-pink">onClick</span>=<span class="color-green">{() => setCount(count + 1)}</span>&gt;Increment&lt;/button&gt;</p>
                                <p style="margin-left:60px">        &lt;/div&gt;</p>
                                <p style="margin-left:30px">    );</p>
                                <p>}</p>
                                <br />
                                <p><span class="color-blue">export default</span> <span class="color-red">Counter</span>;</p>
                            
                            `
                        }}></div>
                    </div> 
                    <p>
                        The <strong>`setCount`</strong> function is used to update the count variable. When the button is clicked, <strong>`setCount`</strong> is called with the new value of count, causing the component to re-render with the updated count value.
                    </p>
                </div>

                <div className='mt-5 mb-5'>
                    <h5>Updating Objects and Arrays in State</h5>
                    <p>
                        In the <strong>`useState`</strong> hook, the <strong>`...previousState`</strong> spread operator is used to create a copy of the previous state object. This operator is used to ensure that we are not mutating the original state object directly, which is important in React to maintain immutability and trigger re-renders correctly.
                    </p>
                    <div className='codePalateBox mt-2 mb-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                                <p><span class="color-blue">import</span> { <span class="color-red">useState</span> } <span class="color-blue">from</span> <span class="color-green">"react"</span>;</p>
                                <br />
                                <p><span class="color-blue">function</span> <span class="color-red">Person</span>() {</p>
                                <p style="margin-left:30px">    <span class="color-blue">const</span> [person, setPerson] = <span class="color-red">useState</span>({</p>
                                <p style="margin-left:60px">        <span class="color-pink">name</span>: <span class="color-green">"John Doe"</span>,</p>
                                <p style="margin-left:60px">        <span class="color-pink">age</span>: <span class="color-green">30</span>,</p>
                                <p style="margin-left:60px">        <span class="color-pink">gender</span>: <span class="color-green">"male"</span></p>
                                <p style="margin-left:30px">    });</p>
                                <br />    
                                <p style="margin-left:30px">    <span class="color-blue">const</span> <span class="color-red">increaseAge</span> = () => {</p>
                                <p style="margin-left:60px">         <span class="color-red">setPerson</span>(previousState => {</p>
                                <p style="margin-left:90px">             <span class="color-blue">return</span> { ...previousState, <span class="color-pink">age</span>: previousState.age + <span class="color-pink">1</span> }</p>
                                <p style="margin-left:60px">         });</p>
                                <p style="margin-left:30px">    }</p>
                                <br />    
                                <p style="margin-left:30px">    <span class="color-blue">return</span> (</p>
                                <p style="margin-left:60px">        &lt;&gt;</p>
                                <p style="margin-left:90px">             &lt;h1&gt;{person.name}&lt;/h1&gt;</p>
                                <p style="margin-left:90px">             &lt;p&gt;</p>
                                <p style="margin-left:120px">                    Age: {person.age}, Gender: {person.gender}</p>
                                <p style="margin-left:90px">             &lt;/p&gt;</p>
                                <p style="margin-left:90px">             &lt;button <span class="color-pink">type</span>=<span class="color-green">"button"</span> <span class="color-pink">onClick</span>=<span class="color-green">{increaseAge}</span>&gt;Increase Age&lt;/button&gt;</p>
                                <p style="margin-left:60px">            &lt;/&gt;</p>
                                <p style="margin-left:30px">        )</p>
                                <p>    }</p>
                                <p><span class="color-blue">export default</span> <span class="color-red">Person</span>;</p>
                            `
                        }}></div>
                    </div> 
                    <p>
                        Here, <strong>`...previousState`</strong> creates a new object that has all the properties of the <strong>`previousState`</strong> object <strong>`(name, age, gender)`</strong>. We then specify the property we want to update <strong>`(age)`</strong> and its new value <strong>`(previousState.age + 1)`</strong>. This approach ensures that we are updating state correctly without mutating the original state object.
                    </p>
                </div>

            </div>
        </section>
    )
}
