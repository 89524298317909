import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/node-js/mysql-order-by";

export default function MysqlOrderByClause() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Node Js - Mysql - Order By Clause | Aspirant's Home");
        const urls = {
            'previous': '/node-js/mysql-where-clause',
            'next': '/node-js/mysql-delete-query'
        }
        path.setPreviousNext(urls);
    }, [])
    
    return (
        <section className='mt-5 mb-5'>
            <h3 className='mb-4'>Node js - Mysql Order By Clause</h3>
            <p>In MySQL, the ORDER BY clause is used to sort the result set of a SELECT query based on one or more columns. It allows you to specify the column(s) by which the result set should be sorted and the order (ascending or descending) using the syntax ASC or DESC. If you not specify ASC or DESC after the column name by default it takes ascending order.</p>
            <p>Below is the basic syntax of ORDER BY clause in mysql.</p>
            <div className='codePalateBox mt-2 mb-2'>
                <div className='codePalate' dangerouslySetInnerHTML={{
                    __html: `<p><span class='color-blue'>SELECT</span> column1, column2, ...</p>
                    <p><span class='color-blue'>FROM</span> table_name</p>
                    <p><span class='color-blue'>ORDER BY</span> column1 [<span class='color-blue'>ASC</span>|<span class='color-blue'>DESC</span>], column2 [<span class='color-blue'>ASC</span>|<span class='color-blue'>DESC</span>], ... ;</p>
                    `
                }}></div>
            </div>
            <p>To perform a MySQL order by in Node.js, you can use the <strong>`mysql`</strong> module along with the <strong>`mysql`</strong> package. First, make sure to install the <strong>`mysql`</strong> package using npm:</p>
            <div className='codePalateBox mt-2 mb-2'>
                <div className='codePalate' dangerouslySetInnerHTML={{
                    __html: `npm install mysql`
                }}></div>
            </div>
            <p>Then, you have to write the select query with/without Where clause</p>
            <div className='codePalateBox mt-2 mb-2'>
                <div className='codePalate' dangerouslySetInnerHTML={{
                    __html: `<p><span class='color-blue'>var</span> mysql = <span class='color-yellow'>require</span>(<span class='color-green'>'mysql'</span>);</p>
                    <br />
                    <p><span class='color-blue'>var</span> con = mysql.<span class='color-red'>createConnection</span>({</p>
                        <p style='margin-left:30px'><span class='color-pink'>host</span>: <span class='color-green'>"localhost"</span>,</p>
                        <p style='margin-left:30px'><span class='color-pink'>user</span>: <span class='color-green'>"yourusername"</span>,</p>
                        <p style='margin-left:30px'><span class='color-pink'>password</span>: <span class='color-green'>"yourpassword"</span>,</p>
                        <p style='margin-left:30px'><span class='color-pink'>database</span>: <span class='color-green'>"mydb"</span></p>
                    <p>});</p>
                    <br />
                    <p>con.<span class='color-red'>connect</span>(function(err) {</p>
                        <p style='margin-left:30px'><span class='color-blue'>if</span> (err) throw err;</p>
                        <p style='margin-left:30px'>con.<span class='color-red'>query</span>(<span class='color-green'>"SELECT name, email FROM users WHERE email LIKE 'be%' ORDER BY name"</span>, function (err, result, fields) {</p>
                        <p style='margin-left:60px'><span class='color-blue'>if</span> (err) throw err;</p>
                        <p style='margin-left:60px'><span class='color-pink'>console</span>.<span class='color-red'>log</span>(result);</p>
                        <p style='margin-left:30px'>});</p>
                    <p>});</p>
                    `
                }}></div>
            </div>
            <p>Which will give you this result:</p>
            <div className='codePalateBox mt-2 mb-2'>
                <div className='codePalate' dangerouslySetInnerHTML={{
                    __html: `<p>[</p>
                        <p style='margin-left:10px'>{ name: 'Ben', email: 'ben@gmail.com'},</p>
                        <p style='margin-left:10px'>{ name: 'Betty', email: 'betty@gmail.com'},</p>
                        ]`
                }}></div>
            </div>
        </section>
    )
}
