import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../../Context";
import { Link } from 'react-router-dom';
export const pageUrl = () => "/node-js/built-in-modules/http";

export default function HttpModule() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("NodeJs - Http Module | Aspirant's Home");
        path.setPreviousNext({});
    }, [])

    return (
        <div className='contentPage'>
            <section class='clearfix mb-3'>
                <span className='float-start'>
                    <Link to='/node-js/built-in-modules' onClick={() => path.setPathName('/node-js/built-in-modules')} className='btn btn-primary'> <i class="fa-solid fa-less-than"></i>&nbsp; Back to Built-In Modules </Link>
                </span>
            </section>
            <section className='mt-5 mb-5'>
                <h3>Node Js Http & Https Module</h3>
                <div className='mt-4'>
                    <p>The http and https modules are used to create HTTP and HTTPS servers, respectively, and make HTTP and HTTPS requests. Both modules provide classes such as http.Server and http.ClientRequest (or https.Server and https.ClientRequest for HTTPS) to handle server and client operations.</p>
                    <p>The syntax for including the http and https module in your application: </p>
                    <div className='codePalateBox mt-2 mb-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `<p><span class='color-blue'>var</span> http = <span class='color-yellow'>require</span>(<span class='color-green'>'http'</span>);</p>
                                    <p><span class='color-blue'>var</span> https = <span class='color-yellow'>require</span>(<span class='color-green'>'https'</span>);</p>
                                    `
                        }}></div>
                    </div>
                </div>
                <div className='mt-4'>
                    <p>Here's a brief overview of each module:</p>
                    <ul style={{ 'listStyle': 'decimal' }}>
                        <li className='mt-4'>
                            <strong>http Module:</strong>
                            <ul style={{ 'listStyle': 'disc' }}>
                                <li className='mt-4'>
                                    The http module provides functions for creating HTTP servers and making HTTP requests.
                                </li>
                                <li>To create an HTTP server, you use the http.createServer() method, which takes a callback function that is called whenever a request is received.</li>
                                <li>Here's a basic example of creating an HTTP server:
                                    <div className='codePalateBox mt-2 mb-2'>
                                        <div className='codePalate' dangerouslySetInnerHTML={{
                                            __html: `<p><span class='color-blue'>const</span> http = <span class='color-yellow'>require</span>(<span class='color-green'>'http'</span>);</p>
                                            <br />
                                            <p><span class='color-blue'>const</span> server = http.<span class='color-red'>createServer</span>((req, res) => {</p>
                                            <p style="margin-left:30px"> res.<span class='color-red'>writeHead</span>(<span class='color-pink'>200</span>, { <span class='color-green'>'Content-Type'</span>: <span class='color-green'>'text/plain'</span> });</p>
                                            <p style="margin-left:30px">  res.<span class='color-red'>end</span>(<span class='color-green'>'Hello, World!\n'</span>);</p>
                                            <p>});</p>
                                            <br />
                                            <p>server.<span class='color-red'>listen</span>(<span class='color-pink'>3000</span>, () => {</p>
                                            <p style="margin-left:30px">  <span class='color-pink'>console</span>.<span class='color-red'>log</span>(<span class='color-green'>'Server running at http://localhost:3000/'</span>);</p>
                                            <p>});</p>`
                                        }}></div>
                                    </div>
                                </li>
                            </ul>
                        </li>
                        <li className='mt-4'>
                            <strong>https Module:</strong>
                            <ul style={{ 'listStyle': 'disc' }}>
                                <li className='mt-4'>
                                    The https module is similar to the http module but is used for creating HTTPS servers, which provide secure communication over the HTTPS protocol.
                                </li>
                                <li>To create an HTTPS server, you use the https.createServer() method, which takes options for configuring the server's behavior and a callback function for handling requests.</li>
                                <li>Here's a basic example of creating an HTTPS server:
                                    <div className='codePalateBox mt-2 mb-2'>
                                        <div className='codePalate' dangerouslySetInnerHTML={{
                                            __html: `<p><span class='color-blue'>const</span> https = <span class='color-yellow'>require</span>(<span class='color-green>'>'https'</span>);</p>
                                            <p><span class='color-blue'>const</span> fs = <span class='color-yellow'>require</span>(<span class='color-green'>'fs'</span>);</p>
                                            <br />
                                            <p><span class='color-blue'>const</span> options = {</p>
                                            <p style="margin-left:30px"> <span class='color-pink'>key</span>: fs.<span class='color-red'>readFileSync</span>(<span class='color-green'>'server-key.pem'</span>),</p>
                                            <p style="margin-left:30px"> <span class='color-pink'>cert</span>: fs.<span class='color-red'>readFileSync</span>(<span class='color-green'>'server-cert.pem'</span>)</p>
                                            <p>};</p>
                                            <br />
                                            <p><span class='color-blue'>const</span> server = https.<span class='color-red'>createServer</span>(options, (req, res) => {</p>
                                            <p style="margin-left:30px"> res.<span class='color-red'>writeHead</span>(<span class='color-pink'>200</span>, { <span class='color-green'>'Content-Type': 'text/plain'</span> });</p>
                                            <p style="margin-left:30px">  res.<span class='color-red'>end</span>(<span class='color-blue'>'Hello, World!\n'</span>);</p>
                                            <p>});</p>
                                            <br />
                                            <p>server.<span class='color-red'>listen</span>(<span class='color-pink'>3000</span>, () => {</p>
                                            <p style="margin-left:30px">  <span class='color-pink'>console</span>.<span class='color-red'>log</span>(<span class='color-green'>'Server running at https://localhost:3000/'</span>);</p>
                                            <p>});</p>
                                            `
                                        }}></div>
                                    </div>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </section>
        </div>
    )
}
