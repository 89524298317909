import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";

export const pageUrl = () => "/css/introduction";

export default function CSSIntro() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("An Introduction to CSS | A Complete Guide");
        title.setKeyWords("an introduction to CSS, what are the benifits of css, benifits of using css, what is css, what is cascading style sheet");
        title.setPageDescription("CSS is the style set, that we used to style a web page. It stands for Cascading Style Sheets. It is a Stylesheet language, used for describing the presentation of a document written in HTML or XML. It controls the layout and appearance of web pages. It defines how elements should be rendered on screen, on paper, in speech, or on other media.");
        const urls = {
            'next': '/css/syntax'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>CSS - Introduction</h3>
            <div className='mt-4 mb-5'>
                <p>
                    CSS is the style set, that we used to style a web page. It stands for Cascading Style Sheets.
                </p>
                <h5 className='mt-5 mb-4'>What is Cascading Style Sheets (CSS)?</h5>
                <ul style={{ listStyle: 'disc' }}>
                    <li>CSS stands for Cascading Style Sheets.</li>
                    <li>It is a Stylesheet language, used for describing the presentation of a document written in HTML or XML.</li>
                    <li>It controls the layout and appearance of web pages.</li>
                    <li>It defines how elements should be rendered on screen, on paper, in speech, or on other media.</li>
                </ul>

                <h5 className='mt-5 mb-4'>Simple Demo of Using CSS</h5>
                <p>
                    These are the basic 3 looks that show a CSS can change your website to a beautiful look.
                </p>
                <div className='outputPalateBox mb-4 mt-4'>
                    <div className='outputPalate' dangerouslySetInnerHTML={{
                        __html: `
                            <h5 class="mt-4 mb-4">Without Style</h5>
                            <div class="navbar1">
                                <a href="javascript:void(0)">Home</a>
                                <a href="javascript:void(0)">About</a>
                                <a href="javascript:void(0)">Services</a>
                                <a href="javascript:void(0)">Contact</a>
                            </div>
                            <h5 class="mt-4 mb-4">Looks 1</h5>
                            <style>
                            .example1 .navbar {
                                background-color: #bc4141;
                                overflow: hidden;
                                justify-content: end;
                            }
                            .example1 .navbar a {
                                float: left;
                                display: block;
                                color: #f2f2f2;
                                text-align: center;
                                padding: 14px 20px;
                                text-decoration: none;
                            }
                            .example1 .navbar a:hover {
                                background-color: #ddd;
                                color: black;
                            }
                            </style>
                            <div class="example1">
                                <div class="navbar">
                                    <a href="javascript:void(0)">Home</a>
                                    <a href="javascript:void(0)">About</a>
                                    <a href="javascript:void(0)">Services</a>
                                    <a href="javascript:void(0)">Contact</a>
                                </div>
                            </div>
                            <h5 class="mt-4 mb-4">Looks 2</h5>
                            <style>
                            .example2 .navbar {
                                background-color: #444;
                                border-bottom: 2px solid #ddd;
                                overflow: hidden;
                                justify-content: center;
                            }
                            .example2 .navbar a {
                                float: left;
                                display: block;
                                color: #f9f9f9;
                                text-align: center;
                                padding: 16px 24px;
                                text-decoration: none;
                                border-right: 1px solid #bbb;
                            }
                            .example2 .navbar a:last-child {
                                border-right: none;
                            }
                            .example2 .navbar a:hover {
                                background-color: #555;
                            }
                            </style>
                            <div class="example2">
                                <div class="navbar">
                                    <a href="javascript:void(0)">Home</a>
                                    <a href="javascript:void(0)">About</a>
                                    <a href="javascript:void(0)">Services</a>
                                    <a href="javascript:void(0)">Contact</a>
                                </div>
                            </div>

                            <h5 class="mt-4 mb-4">Looks 3</h5>
                            <style>
                            .example3 .navbar {
                                background-color: #1e4c69;
                                overflow: hidden;
                                justify-content: start;
                            }
                            .example3 .navbar a {
                                display: inline-block;
                                color: #f2f2f2;
                                padding: 14px 20px;
                                text-decoration: none;
                            }
                            .example3 .navbar a:hover {
                                background-color: #777;
                            }
                            </style>
                            <div class="example3">
                                <div class="navbar">
                                    <a href="javascript:void(0)">Home</a>
                                    <a href="javascript:void(0)">About</a>
                                    <a href="javascript:void(0)">Services</a>
                                    <a href="javascript:void(0)">Contact</a>
                                </div>
                            </div>
                        `
                    }}></div>
                </div>

                <h5 className='mt-5 mb-4'>CSS Has Solved Many Problem</h5>
                <ul style={{ listStyle: 'disc' }}>
                    <li>
                        It keeps HTML structure clean and separates styling concerns, making it easier to maintain and update.
                    </li>
                    <li>
                        CSS has many more styling options than HTML, so you can make your HTML page look much better compared to using only HTML attributes.
                    </li>
                    <li>
                        It enables the design to adapt to different devices (desktops, tablets, smartphones) and media (print, screen, speech).
                    </li>
                    <li>
                        Reduces the amount of code in HTML files, leading to faster page load times.
                    </li>
                </ul>
            </div>
        </section>
    )
}