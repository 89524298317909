import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/mysql/self-join";

export default function MysqlSelfJoin() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Mysql - Self Join | Aspirant's Home");
        const urls = {
            'previous': '/mysql/full-join',
            'next': '/mysql/unique-key'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>Mysql - Self Join</h3>
            <div className='mt-4 mb-5'>
                <p>
                    A self join is a regular join, but the table is joined with itself. This can be useful when you have a table that contains hierarchical data or when you want to compare rows within the same table.
                </p>
                <p>
                    The basic syntax for a self join is similar to a regular join:
                </p>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class='color-blue'>SELECT</span> t1.column1, t1.column2, ..., t2.column1, t2.column2, ...</p>
                            <p><span class='color-blue'>FROM</span> table_name t1</p>
                            <p><span class='color-blue'>JOIN</span> table_name t2 <span class='color-blue'>ON</span> t1.common_column = t2.common_column;</p>
                        `
                    }}></div>
                </div>
                <p>
                    Where, <strong>`table_name`</strong> is the name of the table you are joining.
                    <strong>`t1`</strong> and <strong>`t2`</strong> are aliases for the same table (they are essentially two instances of the same table).
                    <strong>`common_column`</strong> is the column that is used to join the table to itself.
                </p>
                <p>
                    Suppose, we have a employee table like below -
                </p>
                <table className='table' style={{border: '1px dashed #ccc'}}> 
                    <thead>
                        <tr style={{border: '1px dashed #ccc'}}>
                            <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>employee_id</th>
                            <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>employee_name</th>
                            <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>manager_id</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr style={{border: '1px dashed #ccc'}}>
                            <td style={{border: '1px dashed #ccc'}}>1</td>
                            <td style={{ border: '1px dashed #ccc' }}>John</td>
                            <td style={{border: '1px dashed #ccc'}}>3</td>
                        </tr>
                        <tr style={{border: '1px dashed #ccc'}}>
                            <td style={{border: '1px dashed #ccc'}}>2</td>
                            <td style={{ border: '1px dashed #ccc' }}>Jane</td>
                            <td style={{border: '1px dashed #ccc'}}>3</td>
                        </tr>
                        <tr style={{border: '1px dashed #ccc'}}>
                            <td style={{border: '1px dashed #ccc'}}>3</td>
                            <td style={{ border: '1px dashed #ccc' }}>Alex</td>
                            <td style={{border: '1px dashed #ccc'}}><span className='color-blue'>NULL</span></td>
                        </tr>
                        <tr style={{border: '1px dashed #ccc'}}>
                            <td style={{border: '1px dashed #ccc'}}>4</td>
                            <td style={{ border: '1px dashed #ccc' }}>Sarah</td>
                            <td style={{border: '1px dashed #ccc'}}>2</td>
                        </tr>
                    </tbody>
                </table>

                <p>
                    In this example, <strong>`manager_id`</strong> is a foreign key that points to the <strong>`employee_id`</strong> of the manager.
                </p>
                <p>
                    Now, let's say we want to retrieve a list of employees along with the names of their managers. We can use a self join to achieve this:
                </p>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-blue">SELECT</span> e.employee_id, e.employee_name, m.employee_id <span class="color-blue">AS</span> manager_id, m.employee_name <span class="color-blue">AS</span> manager_name</p>
                            <p><span class="color-blue">FROM</span> employees e</p>
                            <p><span class="color-blue">LEFT JOIN</span> employees m <span class="color-blue">ON</span> e.manager_id = m.employee_id;</p>
                        `
                    }}></div>
                </div>
                <p>
                    This query performs a left join between the <strong>`employees`</strong> table (aliased as <strong>`e`</strong>) and itself (aliased as <strong>`m`</strong>) on the <strong>`manager_id`</strong> column. It retrieves the <strong>`employee_id`</strong> and <strong>`employee_name`</strong> of each employee, as well as the <strong>`manager_id`</strong> and <strong>`employee_name`</strong> of their manager (if they have one).
                </p>
                <p>
                    The Output of the query will be the following -
                </p>
                <table className='table' style={{border: '1px dashed #ccc'}}> 
                    <thead>
                        <tr style={{border: '1px dashed #ccc'}}>
                            <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>employee_id</th>
                            <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>employee_name</th>
                            <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>manager_id</th>
                            <th style={{ border: '1px dashed #ccc', backgroundColor: '#e1f1ff' }}>manager_name</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr style={{ border: '1px dashed #ccc' }}>
                            <td style={{border: '1px dashed #ccc'}}>1</td>
                            <td style={{border: '1px dashed #ccc'}}>John</td>
                            <td style={{border: '1px dashed #ccc'}}>3</td>
                            <td style={{ border: '1px dashed #ccc' }}>Alex</td>
                        </tr>
                        <tr style={{ border: '1px dashed #ccc' }}>
                            <td style={{border: '1px dashed #ccc'}}>2</td>
                            <td style={{border: '1px dashed #ccc'}}>Jane</td>
                            <td style={{border: '1px dashed #ccc'}}>3</td>
                            <td style={{ border: '1px dashed #ccc' }}>Alex</td>
                        </tr>
                        <tr style={{ border: '1px dashed #ccc' }}>
                            <td style={{border: '1px dashed #ccc'}}>3</td>
                            <td style={{border: '1px dashed #ccc'}}>Alex</td>
                            <td style={{border: '1px dashed #ccc'}}><span className='color-blue'>NULL</span></td>
                            <td style={{ border: '1px dashed #ccc' }}><span className='color-blue'>NULL</span></td>
                        </tr>
                        <tr style={{ border: '1px dashed #ccc' }}>
                            <td style={{border: '1px dashed #ccc'}}>4</td>
                            <td style={{border: '1px dashed #ccc'}}>Sarah</td>
                            <td style={{border: '1px dashed #ccc'}}>2</td>
                            <td style={{ border: '1px dashed #ccc' }}>Jane</td>
                        </tr>
                    </tbody>
                </table>
                <p>
                    In the output, each employee is listed with their <strong>`employee_name`</strong>, and if they have a manager, the <strong>`manager_name`</strong> is also displayed. If an employee does not have a manager (<strong>`manager_id`</strong> is <strong>`NULL`</strong>), both <strong>`manager_id`</strong> and <strong>`manager_name`</strong> are NULL.
                </p>
            </div>
        </section>
    )
}