import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/mysql/create-tables";

export default function MysqlCreateTable() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Mysql - Create Table | Aspirant's Home");
        const urls = {
            'previous': '/mysql/revoke-privileges',
            'next': '/mysql/show-tables'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>Mysql - Create Table</h3>
            <div className='mt-4 mb-5'>
                <p>
                    To create a table in MySQL, you can use the CREATE TABLE statement followed by the table name and a list of columns along with their data types. Here's the basic syntax:
                </p>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-blue">CREATE TABLE</span> table_name (</p>
                            <p style="margin-left:30px">    column1_name column1_datatype,</p>
                            <p style="margin-left:30px">    column2_name column2_datatype,</p>
                            <p style="margin-left:30px">    ...</p>
                            <p>);</p>
                        `
                    }}></div>
                </div>
                <p>
                    For example, let's create a simple table called <strong>`users`</strong> with columns for <strong>`id`</strong>, <strong>`name`</strong>, and <strong>`email`</strong>:
                </p>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-blue">CREATE TABLE</span> users (</p>
                            <p style="margin-left:30px">   id <span class="color-pink">INT</span> AUTO_INCREMENT <span class="color-blue">PRIMARY</span> KEY,</p>
                            <p style="margin-left:30px">    name <span class="color-pink">VARCHAR(255)</span> <span class="color-blue">NOT NULL</span>,</p>
                            <p style="margin-left:30px">    email <span class="color-pink">VARCHAR(255)</span> <span class="color-blue">NOT NULL</span></p>
                            <p>);</p>
                        `
                    }}></div>
                </div>
                <p>In this example, <strong>`id`</strong> is an auto-incrementing integer column and the primary key of the table. <strong>`name`</strong> and <strong>`email`</strong> are <strong>`VARCHAR`</strong> columns with a maximum length of 255 characters, and they cannot be NULL.</p>

                <p>You can add more columns as needed, along with constraints such as <strong>`NOT NULL`</strong>, <strong>`UNIQUE`</strong>, <strong>`DEFAULT`</strong>, etc., to define the structure of your table.</p>
            </div>
            <div className='mt-5 mb-5'>
                <h5>The IF NOT EXISTS clause</h5>
                <p>
                    If you try to create a table with an existing name an error will be generated −
                </p>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-blue">CREATE TABLE</span> users (</p>
                            <p style="margin-left:30px">   id <span class="color-pink">INT</span> AUTO_INCREMENT <span class="color-blue">PRIMARY</span> KEY,</p>
                            <p style="margin-left:30px">    name <span class="color-pink">VARCHAR(255)</span> <span class="color-blue">NOT NULL</span>,</p>
                            <p style="margin-left:30px">    email <span class="color-pink">VARCHAR(255)</span> <span class="color-blue">NOT NULL</span></p>
                            <p>);</p>
                            <p>ERROR 1050 (42S01): Table 'users' already exists</p>
                        `
                    }}></div>
                </div>
                <p>
                    If you use the <strong>`IF NOT EXISTS`</strong> clause along with the CREATE statement as shown below a new table will be created and if a table with the given name, already exists the query will be ignored.
                </p>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-blue">CREATE TABLE</span> IF <span class="color-blue">NOT EXISTS</span> users (</p>
                            <p style="margin-left:30px">   id <span class="color-pink">INT</span> AUTO_INCREMENT <span class="color-blue">PRIMARY</span> KEY,</p>
                            <p style="margin-left:30px">    name <span class="color-pink">VARCHAR(255)</span> <span class="color-blue">NOT NULL</span>,</p>
                            <p style="margin-left:30px">    email <span class="color-pink">VARCHAR(255)</span> <span class="color-blue">NOT NULL</span></p>
                            <p>);</p>
                        `
                    }}></div>
                </div>
            </div>
            <div className='mt-5 mb-5'>
                <h5>Creating a table using an existing one</h5>
                <p>
                    You can also create a table using the existing table (with same definition), following is the syntax to do so −
                </p>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-blue">CREATE TABLE</span> employee <span class="color-blue">LIKE</span> users</p>
                        `
                    }}></div>
                </div>
                <p>
                    The statement <strong>`CREATE TABLE employee LIKE users;`</strong>  this statement creates a new table named <strong>`employee`</strong> that has the same structure (columns and data types) as the <strong>`users`</strong> table. However, it does not copy the data from the <strong>`users`</strong> table into the <strong>`employee`</strong> table. If you want to copy both the structure and data, you would typically use <strong>`CREATE TABLE employee AS SELECT * FROM users`</strong>;
                </p>
            </div>
        </section>
    )
}