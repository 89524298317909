import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/react-js/hooks";

export default function ReactHooks() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("React Hooks | Aspirant's Home");
        const urls = {
            'previous': '/react-js/events',
            'next':'/react-js/hooks/usestate'
        }
        path.setPreviousNext(urls);
    },[])

    return (
        <section className="mt-5 mb-5">
            <h3>React Js - What is Hooks ?</h3>
            <div className="mt-4 mb-4">
                <p>
                    React Hook is a special built-in functions that allow us to <strong>`hook`</strong> into React core internal function. It creates and access <strong>state</strong> from <strong>Fiber tree</strong>.
                </p>
                <p>React Hooks are always start with <strong>`use`</strong>.</p>
                <p>It enables easy reusing of non-visual logic.</p>
                <p>We can create our own custom hooks.</p>
            </div>
            <div className="mt-4 mb-4 featureClass">
                <h5>What is Fiber tree ?</h5>
                <div className="mt-4 mb-4">
                    <p>The Fiber Tree is a tree-like data structure where each node represents an element or component in the React application. Each node contains information about the type of element or component, its props, and its children. The Fiber Tree also contains additional information used by React to determine the order in which nodes should be updated and rendered.</p>
                </div>
            </div>
            <div className="mt-4 mb-4">
                <p>In React, there are atleast 20 built-in hooks. Mostly used built-in hooks are <strong>`useState`</strong>, <strong>`useEffect`</strong>, <strong>`useReducer`</strong>, <strong>`useContext`</strong>. </p>
                <p>There are many others built-in hooks we are using like <strong>`useRef`</strong>, <strong>`useCallback`</strong>, <strong>`useMemo`</strong>.</p>
            </div>
            <div className="mt-5 mb-4">
                <h5>Rules of Hooks</h5>
                <p>There are some rules to use React Hooks</p>
                <ul style={{listStyle:'disc'}}>
                    <li>Only call hooks at the top level. It should not call hooks inside conditionals, loops, nested functions or after an early return. Because hooks are always called in the same order.</li>
                    <li>Only call hooks from React functions. That means we can only call hooks inside a function component.</li>
                </ul>
            </div>
        </section>
    );
}
