import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/react-js/layouts-nested-routes";

export default function NestedRoutes() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("React - Layouts & Nested Routes | Aspirant's Home");
        const urls = {
            'previous': '/react-js/navigating-between-pages-with-links',
            'next':'/react-js/showing-error-page'
        }
        path.setPreviousNext(urls);
    },[])

    return (
        <section className='mt-5 mb-5'>
            <h3>Layouts & Nested Routes</h3>
            <div className='mt-4 mb-4'>
                <p>In React Router, layouts and nested routes are commonly used to organize and structure complex applications. Layouts provide a way to define a common structure for multiple pages, such as a header, footer, and navigation menu. Nested routes allow you to nest routes within each other, creating a hierarchy of pages. </p>
                <p>Ok, lets setup a layout wrapper in <strong>`App.js`</strong> component for a common structure.</p>

                <p>If you are using <strong>createBrowserRouter</strong> from `react-router-dom`, look at the below code for nested routing.</p>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-blue">const</span> router = <span class="color-red">createBrowserRouter</span>([</p>
                            <p style="margin-left:30px">    {</p>
                            <p style="margin-left:60px">        <span class="color-pink">path</span>: <span class="color-green">'/'</span>,</p>
                            <p style="margin-left:60px">        <span class="color-pink">element</span>: &lt;<span class="color-green">RootLayout</span> /&gt;,</p>
                            <p style="margin-left:60px">        <span class="color-pink">children</span>: <span class="color-green">[</span></p>
                            <div class="color-green">
                            <p style="margin-left:90px">            { path: '/', element: &lt;HomePage /&gt; },</p>
                            <p style="margin-left:90px">            { path: '/about', element: &lt;AboutPage /&gt;}</p>
                            </div>
                            <p style="margin-left:60px">        <span class="color-green">]</span></p>
                            <p style="margin-left:30px">    }</p>
                            <p>])</p>
                        `
                    }}></div>
                </div> 
                <p>Or, If you are using <strong>BrowserRouter</strong> from `react-router-dom`, look at the below code for nested routing.</p>

                <div className='codePalateBox mt-2 mb-5'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-blue">import</span> <span class="color-red">React</span> <span class="color-blue">from</span> <span class="color-green">'react'</span>;</p>
                        <p><span class="color-blue">import</span> { <span class="color-red">BrowserRouter</span> <span class="color-blue">as</span> <span class="color-red">Router</span>, <span class="color-red">Routes</span>, <span class="color-red">Route</span> } <span class="color-blue">from</span> <span class="color-green">'react-router-dom'</span>;</p>
                        <p><span class="color-blue">import</span> <span class="color-red">HomePage</span> <span class="color-blue">from</span> <span class="color-green">'./components/Home'</span>;</p>
                        <p><span class="color-blue">import</span> <span class="color-red">AboutPage</span> <span class="color-blue">from</span> <span class="color-green">'./components/About'</span>;</p>
                        <p><span class="color-blue">import</span> <span class="color-red">RootLayout</span> <span class="color-blue">from</span> <span class="color-green">'./components/RootLayout'</span>;</p>
                        <br />
                        <p><span class="color-blue">function</span> <span class="color-red">App</span>() {</p>
                        <p style="margin-left:30px;">    <span class="color-blue">return</span> (</p>
                        <p style="margin-left:60px;"> &lt;<span class="color-red">Router</span>&gt;</p>
                        <p style="margin-left:90px">    &lt;<span class="color-red">Routes</span>&gt;</p>
                        <p style="margin-left:120px">        &lt;<span class="color-red">Route</span> <span class="color-pink">path</span>=<span class="color-green">"/"</span> <span class="color-pink">element</span>={&lt;<span class="color-red">RootLayout</span> /&gt;}&gt;</p>
                        <p style="margin-left:150px">            &lt;<span class="color-red">Route</span> <span class="color-pink">path</span>=<span class="color-green">"/"</span> <span class="color-pink">exact element</span>={&lt;<span class="color-red">HomePage</span> /&gt;} /&gt;</p>
                        <p style="margin-left:150px">            &lt;<span class="color-red">Route</span> <span class="color-pink">path</span>=<span class="color-green">"/about"</span> <span class="color-pink">element</span>={&lt;<span class="color-red">AboutPage</span> /&gt;} /&gt;</p>
                        <p style="margin-left:120px">        &lt;/<span class="color-red">Route</span>&gt;</p>
                        <p style="margin-left:90px">    &lt;/<span class="color-red">Routes</span>&gt;</p>
                        <p style="margin-left:60px;">&lt;/<span class="color-red">Router</span>&gt;</p>
                        <p style="margin-left:30px;">    );</p>
                        <p>}</p>
                        <br />
                        <p><span class="color-blue">export default</span> <span class="color-red">App</span>;</p>
                        `
                    }}></div>
                </div> 


                <p>Now in the <strong>RootLayout</strong> component, define the section where children components are shown.</p>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-blue">import</span> { <span class="color-red">Outlet</span> } <span class="color-blue">from</span> <span class="color-green">'react-router-dom'</span>;</p>
                            <br />
                            <p><span class="color-blue">function</span> <span class="color-red">RootLayout</span>() {</p>
                            <p style="margin-left:30px">    <span class="color-blue">return</span> (</p>
                            <p style="margin-left:60px">        &lt;&gt;</p>
                            <p style="margin-left:90px">            &lt;h1&gt;Root Layout&lt;/h1&gt;</p>
                            <p style="margin-left:90px">            &lt;<span class="color-red">Outlet</span> /&gt;</p>
                            <p style="margin-left:60px">        &lt;/&gt;</p>
                            <p style="margin-left:30px">    );</p>
                            <p>}</p>
                            <br />
                            <p><span class="color-blue">export default</span> <span class="color-red">RootLayout</span>;</p>
                        `
                    }}></div>
                </div> 
            </div>
        </section>
    )
}