import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/html/form-attributes";

export default function HTMLForms() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("HTML - Form Attributes  | Aspirant's Home");
        const urls = {
            'previous': '/html/forms',
            'next': '/html/form-elements'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>HTML - Form Attributes</h3>
            <div className='mt-4 mb-5'>
                <p>
                    HTML form attributes define the behavior and features of a form. Here are the common attributes you can use with the <strong>`&lt;form&gt;`</strong> element, along with their explanations:
                </p>
                <h5 className='mt-5'>The `action` Attribute</h5>
                <p>
                    The <strong>`action`</strong> attribute of a form specifies the URL where the form data will be sent when the form is submitted.
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>&lt;form <span class="color-pink">action</span>=<span class="color-green">"/submit-form"</span>&gt;</p>
                        `
                    }}></div>
                </div>
                <h5 className='mt-5'>The `method` Attribute</h5>
                <p>
                    The <strong>`method`</strong> attribute of a form specifies the HTTP method to use when sending form data. The most common values are <strong>`get`</strong> where it is appends the form data to the URL, suitable for non-sensitive data. And <strong>`post`</strong> where it is sends the form data as part of the request body, suitable for sensitive or large amounts of data.
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>&lt;form <span class="color-pink">method</span>=<span class="color-green">"post"</span>&gt;</p>
                        `
                    }}></div>
                </div>
                <h5 className='mt-5'>The `enctype` Attribute</h5>
                <p>
                    The <strong>`enctype`</strong> attribute of a form specifies how the form data should be encoded when submitting to the server. The common values are -
                </p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><strong>`application/x-www-form-urlencoded`</strong>: This is default value of <strong>`enctype`</strong> attribute. Form data is encoded as key-value pairs.</li>
                    <li><strong>`multipart/form-data`</strong>: It is required when uploading files.</li>
                    <li><strong>`text/plain`</strong>: It is required to encodes the form data as plain text.</li>
                </ul>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>&lt;form <span class="color-pink">enctype</span>=<span class="color-green">"multipart/form-data"</span>&gt;</p>
                        `
                    }}></div>
                </div>
                <h5 className='mt-5'>The `target` Attribute</h5>
                <p>
                    The <strong>`target`</strong> attribute of a form specifies where to display the response after submitting the form. Here are the common values are -
                </p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><strong>`_self`</strong>: It is default value. It opens in the same browsing context as the form.</li>
                    <li><strong>`_blank`</strong>: It opens in a new tab or window.</li>
                    <li><strong>`_parent`</strong>: It opens in the parent frame.</li>
                    <li><strong>`_top`</strong>: It opens in the full body of the window.</li>
                </ul>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>&lt;form <span class="color-pink">target</span>=<span class="color-green">"_blank"</span>&gt;</p>
                        `
                    }}></div>
                </div>
                <h5 className='mt-5'>The `name` Attribute</h5>
                <p>
                    The <strong>`name`</strong> attribute of a form specifies the name of the form. This can be used to identify the form in scripts or when submitting the form data.
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>&lt;form <span class="color-pink">name</span>=<span class="color-green">"exampleForm"</span>&gt;</p>
                        `
                    }}></div>
                </div>
                <h5 className='mt-5'>The `autocomplete` Attribute</h5>
                <p>
                    The <strong>`autocomplete`</strong> attribute of a form specifies whether the form should have autocomplete enabled or not. It has two common values - when it is <strong>`on`</strong> it enables autocomplete (It is a default value) and when it is <strong>`off`</strong> it disables autocomplete.
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>&lt;form <span class="color-pink">autocomplete</span>=<span class="color-green">"off"</span>&gt;</p>
                        `
                    }}></div>
                </div>
                <h5 className='mt-5'>The `novalidate` Attribute</h5>
                <p>
                    When the <strong>`autocomplete`</strong> attribute present, it disables the browser’s built-in form validation.
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>&lt;form <span class="color-pink">novalidate</span>&gt;</p>
                        `
                    }}></div>
                </div>
                <h5 className='mt-5'>The `accept-charset` Attribute</h5>
                <p>
                    The <strong>`accept-charset`</strong> attribute of a form specifies the character encodings that are accepted by the server.
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>&lt;form <span class="color-pink">accept-charset</span>=<span class="color-green">"UTF-8"</span>&gt;</p>
                        `
                    }}></div>
                </div>
                <p className='mt-4'>
                    These attributes allow you to control how your form behaves, how the data is encoded and sent, and where the response is displayed.
                </p>
            </div>
        </section>
    )
}