import React, { useEffect } from 'react'
import axios from 'axios';

export default function GenericNotFound() {

    useEffect(() => {

        const currentTime = new Date().toLocaleString();
        const logMessage = `[${currentTime}] URL fired: ${window.location.history}\n`;
        console.log(logMessage)

        // axios.post('http://localhost:3009/save/error/log', {
        //     content: logMessage
        // })
        // .then(function (response) {
        //     console.log(response);
        // })
        // .catch(function (error) {
        //     console.log(error);
        // });
    });
    return (
        <div className="notFoundContainer">
            <div className="notFoundRow">
                <div className="col-md-12">
                    <div className="error-template">
                        <h1>
                            Oops!</h1>
                        <h2>
                            404 Not Found</h2>
                        <div className="error-details">
                            Sorry, an error has occured, Requested page not found!
                        </div>
                        <div className="error-actions">
                            <a href="/" className="btn btn-primary btn-lg">
                                <i className="fa-solid fa-house-user"></i> &nbsp;
                                Take Me Home
                            </a>
                            <a href="/support" className="btn btn-default btn-lg">
                                <i className="fa-solid fa-envelope"></i> &nbsp;
                                Contact Support
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
