import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../../Context";
import { Link } from 'react-router-dom';
export const pageUrl = () => "/node-js/built-in-modules/cluster";

export default function ClusterModule() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("NodeJs - Cluster Module | Aspirant's Home");
        path.setPreviousNext({});
    }, [])

    return (
        <div className='contentPage'>
            <section class='clearfix mb-3'>
                <span className='float-start'>
                    <Link to='/node-js/built-in-modules' onClick={() => path.setPathName('/node-js/built-in-modules')} className='btn btn-primary'> <i class="fa-solid fa-less-than"></i>&nbsp; Back to Built-In Modules </Link>
                </span>
            </section>
            <section className='mt-5 mb-5'>
                <h3>Node Js Cluster Module</h3>
                <div className='mt-4'>
                    <p>The cluster module is used to create child processes (workers) that share server ports to handle incoming requests in a more efficient manner. This module allows you to take advantage of multi-core systems by spawning a process for each core, thus distributing the load evenly. </p>
                    <p>The syntax for including the cluster module in your application: </p>
                    <div className='codePalateBox mt-2 mb-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `<p><span class='color-blue'>var</span> cluster = <span class='color-yellow'>require</span>(<span class='color-green'>'cluster'</span>);</p>
                                    `
                        }}></div>
                    </div>
                </div>
                <div className='mt-4'>
                    <h4>Examples</h4>
                    <p>Here's a basic example of how you might use the cluster module for creating child process. We can create child process using <strong>`fork()`</strong> method:</p>
                    <div className='codePalateBox mt-2 mb-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `<p><span class='color-blue'>const</span> cluster = <span class='color-yellow'>require</span>(<span class='color-green'>'cluster'</span>);</p>
                            <p><span class='color-blue'>const</span> http = <span class='color-yellow'>require</span>(<span class='color-green'>'http'</span>);</p>
                            <p><span class='color-blue'>const</span> numCPUs = <span class='color-yellow'>require</span>(<span class='color-green'>'os'</span>).cpus().length;</p>
                            <br />
                            <p><span class='color-blue'>if</span> (cluster.isMaster) {</p>
                            <p style="margin-left:30px">  <span class='color-pink'>console</span>.<span class='color-red'>log</span>(<span class='color-green'>'Master &dollar;{process.pid} is running'</span>);</p>
                            <br />
                            <p style="margin-left:30px">  <span class='color-grey'>// Fork workers</span></p>
                            <p style="margin-left:30px">  <span class='color-blue'>for</span> (<span class='color-blue'>let</span> i = 0; i < numCPUs; i++) {</p>
                            <p style="margin-left:60px">    cluster.<span class='color-red'>fork</span>();</p>
                            <p style="margin-left:30px">  }</p>
                            <br />
                            <p style="margin-left:30px">  cluster.<span class='color-red'>on</span>(<span class='color-green'>'exit'</span>, (worker, code, signal) => {</p>
                            <p style="margin-left:60px">    <span class='color-pink'>console</span>.<span class='color-red'>log</span>(<span class='color-green'>'Worker &dollar;{worker.process.pid} died'</span>);</p>
                            <p style="margin-left:30px">  });</p>
                            <p>} <span class='color-blue'>else</span> {</p>
                            <p style="margin-left:30px">  <span class='color-grey'>// Workers can share any TCP connection</span></p>
                            <p style="margin-left:30px">  <span class='color-grey'>// In this case, it is an HTTP server</span></p>
                            <p style="margin-left:30px">  http.<span class='color-red'>createServer</span>((req, res) => {</p>
                            <p style="margin-left:60px">    res.<span class='color-red'>writeHead</span>(200);</p>
                            <p style="margin-left:60px">    res.<span class='color-red'>end</span>(<span class='color-green'>'Hello, World!\n'</span>);</p>
                            <p style="margin-left:30px">  }).<span class='color-red'>listen</span>(8000);</p>
                            <br />
                            <p style="margin-left:30px">  <span class='color-pink'>console</span>.<span class='color-red'>log</span>(<span class='color-green'>'Worker &dollar;{process.pid} started'</span>);</p>
                            <p>}</p>`
                        }}></div>
                    </div>
                    <p className='mt-5'>In this example, the <strong>`cluster.isMaster`</strong> property is used to determine if the current process is the master process. If it is the master process, it forks a worker process for each CPU core using the <strong>`cluster.fork()`</strong> method.</p>

                    <p>Each worker process then creates an HTTP server using the <strong>`http.createServer()`</strong> method and listens on port 8000. When a request is received, it responds with 'Hello, World!'.</p>
                </div>
            </section>
        </div>
    )
}
