import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';
export const pageUrl = () => "/mysql/arithmetic-operators";

export default function MysqlArithmeticFunctions() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Mysql - Arithmetic Operators | Aspirant's Home");
        const urls = {
            'previous': '/mysql/date-and-time-functions',
            'next': '/mysql/numeric-functions'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>Mysql - Arithmetic Operators</h3>
            <div className='mt-5 mb-5'>
                <p>
                    Arithmetic operators in MySQL are used to perform mathematical calculations on numeric values in a database. These operators allow you to add, subtract, multiply, and divide values, similar to how you would in basic arithmetic.
                </p>
                <p>
                    Here are the basic arithmetic operators supported by MySQL:
                </p>
                <table className='table' style={{border: '1px dashed #ccc'}}> 
                    <thead>
                        <tr style={{border: '1px dashed #ccc'}}>
                            <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff', width: '20%'}}>Operator Name</th>
                            <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>Description & Syntax</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr style={{ border: '1px dashed #ccc' }}>
                            <td style={{ border: '1px dashed #ccc', textAlign: 'center' }}>
                                <Link to="/mysql/arithmetic-operators/addition">Addition (+)</Link>
                            </td>
                            <td style={{ border: '1px dashed #ccc' }}>
                                <p>
                                    The MySQL <strong>`+`</strong> operator is used to add two numbers.
                                </p>
                            </td>
                        </tr>
                        <tr style={{ border: '1px dashed #ccc' }}>
                            <td style={{ border: '1px dashed #ccc', textAlign: 'center' }}>
                                <Link to="/mysql/arithmetic-operators/subtraction">Subtraction (-)</Link>
                            </td>
                            <td style={{ border: '1px dashed #ccc' }}>
                                <p>
                                    The MySQL <strong>`-`</strong> operator is used to subtracts one value from another.
                                </p>
                            </td>
                        </tr>
                        <tr style={{ border: '1px dashed #ccc' }}>
                            <td style={{ border: '1px dashed #ccc', textAlign: 'center' }}>
                                <Link to="/mysql/arithmetic-operators/multiplication">Multiplication (*)</Link>
                            </td>
                            <td style={{ border: '1px dashed #ccc' }}>
                                <p>
                                    The MySQL <strong>`*`</strong> operator is used to multiplies two values.
                                </p>
                            </td>
                        </tr>
                        <tr style={{ border: '1px dashed #ccc' }}>
                            <td style={{ border: '1px dashed #ccc', textAlign: 'center' }}>
                                <Link to="/mysql/arithmetic-operators/division">Division (/)</Link>
                            </td>
                            <td style={{ border: '1px dashed #ccc' }}>
                                <p>
                                    The MySQL <strong>`/`</strong> operator is used to divides one value by another. Returns a floating-point number.
                                </p>
                            </td>
                        </tr>
                        <tr style={{ border: '1px dashed #ccc' }}>
                            <td style={{ border: '1px dashed #ccc', textAlign: 'center' }}>
                                <Link to="/mysql/arithmetic-operators/integer-division">Integer Division (DIV)</Link>
                            </td>
                            <td style={{ border: '1px dashed #ccc' }}>
                                <p>
                                    The MySQL <strong>`DIV`</strong> operator is used to divides one value by another. Returns only the integer portion of the result.
                                </p>
                            </td>
                        </tr>
                        <tr style={{ border: '1px dashed #ccc' }}>
                            <td style={{ border: '1px dashed #ccc', textAlign: 'center' }}>
                                <Link to="/mysql/arithmetic-operators/modulus">Modulus (MOD)</Link>
                            </td>
                            <td style={{ border: '1px dashed #ccc' }}>
                                <p>
                                    The MySQL <strong>`MOD`</strong> operator is returns the remainder of the division of one value by another.
                                </p>
                            </td>
                        </tr>
                        <tr style={{ border: '1px dashed #ccc' }}>
                            <td style={{ border: '1px dashed #ccc', textAlign: 'center' }}>
                                <Link to="/mysql/arithmetic-operators/unary-plus">Unary Plus (+)</Link>
                            </td>
                            <td style={{ border: '1px dashed #ccc' }}>
                                <p>
                                    The MySQL <strong>`+`</strong> operator indicates a positive value. This operator doesn't have any effect and is mostly used for clarity.
                                </p>
                            </td>
                        </tr>
                        <tr style={{ border: '1px dashed #ccc' }}>
                            <td style={{ border: '1px dashed #ccc', textAlign: 'center' }}>
                                <Link to="/mysql/arithmetic-operators/unary-minus">Unary Minus (-)</Link>
                            </td>
                            <td style={{ border: '1px dashed #ccc' }}>
                                <p>
                                    The MySQL <strong>`-`</strong> operator indicates a negative value or negates a value.
                                </p>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </section>
    )
}