import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/html/javascripts";

export default function HTMLJavascripts() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("HTML - Javascripts | Aspirant's Home");
        const urls = {
            'previous': '/html/iframes',
            'next': '/html/meta-tags'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>HTML - Javascripts</h3>
            <div className='mt-4 mb-5'>
                <p>
                    HTML and JavaScript are often used together to create dynamic, interactive web pages. HTML provides the structure and content of a webpage, while JavaScript allows you to add interactivity, manipulate the DOM (Document Object Model), and handle events.
                </p>
                <p>
                    Look at below example -
                </p>
                <div className='outputPalateBox mb-4' style={{border:'none'}}>
                    <div className='outputPalate' dangerouslySetInnerHTML={{
                        __html: `
                            <!DOCTYPE html>
                            <html lang="en">
                                <head>
                                    <meta charset="UTF-8">
                                    <meta name="viewport" content="width=device-width, initial-scale=1.0">
                                    <title>Inline JavaScript Example</title>
                                </head>
                                <body>
                                    <button onclick="alert('Hello, User!')">Click Me</button>
                                </body>
                            </html>
                        `
                    }}></div>
                </div>
                <h5 className='mt-5 mb-3'>Adding JavaScript to HTML</h5>
                <p>
                    There are several ways to include JavaScript in an HTML document:
                </p>
                <ul style={{ listStyle: 'decimal' }}>
                    <li><strong>Inline JavaScript</strong>: Using the onclick attribute or similar attributes directly within HTML tags.</li>
                    <li><strong>Internal JavaScript</strong>: Placing JavaScript code within the <strong>`&lt;script&gt;`</strong> tag inside the HTML document.</li>
                    <li><strong>External JavaScript</strong>: Linking to an external JavaScript file using the <strong>`&lt;script&gt;`</strong> tag.</li>
                </ul>
                <h5 className='mt-5 mb-3'>Inline JavaScript</h5>
                <p>
                    You can add JavaScript directly to HTML elements using attributes like <strong>`onclick`</strong>, <strong>`onmouseover`</strong>, etc.
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-grey">&lt;!DOCTYPE</span> <span class="color-blue">html</span><span class="color-grey">&gt;</span></p>
                        <p>&lt;html <span class="color-pink">lang</span>=<span class="color-green">"en"</span>&gt;</p>
                        <p class="ml-30">    &lt;head&gt;</p>
                        <p class="ml-60">        &lt;meta <span class="color-pink">charset</span>=<span class="color-green">"UTF-8"</span>&gt;</p>
                        <p class="ml-60">        &lt;meta <span class="color-pink">name</span>=<span class="color-green">"viewport"</span> <span class="color-pink">content</span>=<span class="color-green">"width=device-width, initial-scale=1.0"</span>&gt;</p>
                        <p class="ml-60">        &lt;title&gt;Inline JavaScript Example&lt;/title&gt;</p>
                        <p class="ml-30">    &lt;/head&gt;</p>
                        <p class="ml-30">    &lt;body&gt;</p>
                        <p class="ml-60">        &lt;button <span class="color-pink">onclick</span>=<span class="color-green">"alert('Hello, User!')"</span>&gt;Click Me&lt;/button&gt;</p>
                        <p class="ml-30">    &lt;/body&gt;</p>
                        <p>&lt;/html&gt;</p>
                        `
                    }}></div>
                </div>
                
                <h5 className='mt-5 mb-3'>Internal JavaScript</h5>
                <p>
                    You can place JavaScript code within the <strong>`&lt;script&gt;`</strong> tag inside the HTML document, usually within the <strong>`&lt;head&gt;`</strong> or at the end of the <strong>`&lt;body&gt;`</strong>.
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-grey">&lt;!DOCTYPE</span> <span class="color-blue">html</span><span class="color-grey">&gt;</span></p>
                        <p>&lt;html <span class="color-pink">lang</span>=<span class="color-green">"en"</span>&gt;</p>
                        <p class="ml-30">    &lt;head&gt;</p>
                        <p class="ml-60">        &lt;meta <span class="color-pink">charset</span>=<span class="color-green">"UTF-8"</span>&gt;</p>
                        <p class="ml-60">        &lt;meta <span class="color-pink">name</span>=<span class="color-green">"viewport"</span> <span class="color-pink">content</span>=<span class="color-green">"width=device-width, initial-scale=1.0"</span>&gt;</p>
                        <p class="ml-60">        &lt;title&gt;Internal JavaScript Example&lt;/title&gt;</p>
                        <p class="ml-60">        &lt;script&gt;</p>
                        <p class="ml-90">            <span class="color-blue">function</span> <span class="color-red">showMessage</span>() {</p>
                        <p class="ml-120">                <span class="color-red">alert</span>(<span class="color-green">'Hello, world!'</span>);</p>
                        <p class="ml-90">            }</p>
                        <p class="ml-60">        &lt;/script&gt;</p>
                        <p class="ml-30">    &lt;/head&gt;</p>
                        <p class="ml-30">    &lt;body&gt;</p>
                        <p class="ml-60">        &lt;button <span class="color-pink">onclick</span>=<span class="color-green">"showMessage()"</span>&gt;Click Me&lt;/button&gt;</p>
                        <p class="ml-30">    &lt;/body&gt;</p>
                        <p>&lt;/html&gt;</p>
                        `
                    }}></div>
                </div>
                <h5 className='mt-5 mb-3'>External JavaScript</h5>
                <p>
                    You can write your JavaScript code in a separate file and link to it using the <strong>`&lt;script&gt;`</strong> tag with the <strong>`src`</strong> attribute.
                </p>
                <h6 className='mt-2 mb-2'>example.js:</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>            <span class="color-blue">function</span> <span class="color-red">showMessage</span>() {</p>
                        <p class="ml-30">                <span class="color-red">alert</span>(<span class="color-green">'Hello, world!'</span>);</p>
                        <p>            }</p>
                        `
                    }}></div>
                </div>
                <h6 className='mt-2 mb-2'>index.html:</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-grey">&lt;!DOCTYPE</span> <span class="color-blue">html</span><span class="color-grey">&gt;</span></p>
                        <p>&lt;html <span class="color-pink">lang</span>=<span class="color-green">"en"</span>&gt;</p>
                        <p class="ml-30">    &lt;head&gt;</p>
                        <p class="ml-60">        &lt;meta <span class="color-pink">charset</span>=<span class="color-green">"UTF-8"</span>&gt;</p>
                        <p class="ml-60">        &lt;meta <span class="color-pink">name</span>=<span class="color-green">"viewport"</span> <span class="color-pink">content</span>=<span class="color-green">"width=device-width, initial-scale=1.0"</span>&gt;</p>
                        <p class="ml-60">        &lt;title&gt;External JavaScript Example&lt;/title&gt;</p>
                        <p class="ml-60">        &lt;script <span class="color-pink">src</span>=<span class="color-green">"example.js"</span>&gt;&lt;/script&gt;</p?
                        <p class="ml-30">    &lt;/head&gt;</p>
                        <p class="ml-30">    &lt;body&gt;</p>
                        <p class="ml-60">        &lt;button <span class="color-pink">onclick</span>=<span class="color-green">"showMessage()"</span>&gt;Click Me&lt;/button&gt;</p>
                        <p class="ml-30">    &lt;/body&gt;</p>
                        <p>&lt;/html&gt;</p>
                        `
                    }}></div>
                </div>
                <h5 className='mt-5 mb-3'>DOM Manimulation</h5>
                <p>
                    You can change the content of an HTML element using the innerHTML or textContent properties. Also you can change the style of an HTML element using the style property.
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-grey">&lt;!DOCTYPE</span> <span class="color-blue">html</span><span class="color-grey">&gt;</span></p>
                        <p>&lt;html <span class="color-pink">lang</span>=<span class="color-green">"en"</span>&gt;</p>
                        <p class="ml-30">    &lt;head&gt;</p>
                        <p class="ml-60">        &lt;meta <span class="color-pink">charset</span>=<span class="color-green">"UTF-8"</span>&gt;</p>
                        <p class="ml-60">        &lt;title&gt;Change Content Example&lt;/title&gt;</p>
                        <p class="ml-60">        &lt;script&gt;</p>
                        <p class="ml-90">            <span class="color-blue">function</span> <span class="color-red">changeContent</span>() {</p>
                        <p class="ml-120">                <span class="color-pink">document</span>.<span class="color-red">getElementById</span>(<span class="color-green">'myText'</span>).textContent = <span class="color-green">'My Initial Content is changed.'</span>;</p>
                        <p class="ml-90">            }</p>
                        <p class="ml-90">            <span class="color-blue">function</span> <span class="color-red">changeStyle</span>() {</p>
                        <p class="ml-120">                <span class="color-pink">document</span>.<span class="color-red">getElementById</span>(<span class="color-green">'myText'</span>).style.color = <span class="color-green">'blue'</span>;</p>
                        <p class="ml-120">                <span class="color-pink">document</span>.<span class="color-red">getElementById</span>(<span class="color-green">'myText'</span>).style.fontSize = <span class="color-green">'20px'</span>;</p>
                        <p class="ml-90">            }</p>
                        <p class="ml-60">        &lt;/script&gt;</p>
                        <p class="ml-30">    &lt;/head&gt;</p>
                        <p class="ml-30">    &lt;body&gt;</p>
                        <p class="ml-60">        &lt;p <span class="color-pink">id</span>=<span class="color-green">"myText"</span>&gt;My Initial Content&lt;/p&gt;</p>
                        <p class="ml-60">        &lt;button <span class="color-pink">onclick</span>=<span class="color-green">"changeContent()"</span>&gt;Change Style&lt;/button&gt;</p>
                        <p class="ml-60">        &lt;button <span class="color-pink">onclick</span>=<span class="color-green">"changeContent()"</span>&gt;Change Content&lt;/button&gt;</p>
                        <p class="ml-30">    &lt;/body&gt;</p>
                        <p>&lt;/html&gt;</p>
                        
                        `
                    }}></div>
                </div>
                <p>
                    Output will be the following -
                </p>
                <div className='outputPalateBox mb-4' style={{border:'none'}}>
                    <div className='outputPalate' dangerouslySetInnerHTML={{
                        __html: `
                            <!DOCTYPE html>
                            <html lang="en">
                                <head>
                                    <meta charset="UTF-8">
                                    <title>Change Style Example</title>
                                </head>
                                <body>
                                    <p id="myText1">My Initial Content.</p>
                                    <button onclick="document.getElementById('myText1').style.color = 'blue';
                                    document.getElementById('myText1').style.fontSize = '20px';">Change Style</button>
                                    <button onclick="document.getElementById('myText1').textContent = 'My Initial Content is changed.';">Change Content</button>
                                </body>
                            </html>
                        `
                    }}></div>
                </div>
                <div className='mt-4 featureClass'>
                    <h5>Summary</h5>
                    <ul style={{ listStyle: 'disc' }}>
                        <li><strong>HTML and JavaScript Integration</strong>: JavaScript is used to create interactive and dynamic web pages by manipulating the HTML and CSS.</li>
                        <li><strong>Including JavaScript</strong>: Can be done inline, internally, or externally.</li>
                    </ul>
                </div>
            </div>
        </section>
    )
}