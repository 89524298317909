import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/mysql/rename-tables";

export default function MysqlRenameTable() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Mysql - Rename Table | Aspirant's Home");
        const urls = {
            'previous': '/mysql/alter-tables',
            'next': '/mysql/clone-tables'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>Mysql - Rename Table</h3>
            <div className='mt-4 mb-5'>
                <p>
                    We can rename an table in MySQL using two methods - the <strong>`RENAME TABLE`</strong> or <strong>`ALTER TABLE`</strong> statement.
                    Let's explain it with examples -
                </p>
            </div>
            <div className='mt-5 mb-5'>
                <h5>RENAME TABLE statement</h5>
                <div className='mt-2 mb-2'>
                    <p>
                        The MySQL <strong>`RENAME TABLE`</strong> statement is used to rename an existing table in a database with another name. Here is the basic syntax -
                    </p>
                    <div className='codePalateBox mt-2 mb-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                                <p><span class="color-blue">RENAME TABLE</span> old_table_name <span class="color-blue">TO</span> new_table_name;</p>
                            `
                        }}></div>
                    </div>
                    <p>
                        For example, to rename a table called <strong>`old_table`</strong> to <strong>`new_table`</strong>, you would use the following SQL statement:
                    </p>
                    <div className='codePalateBox mt-2 mb-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                                <p><span class="color-blue">RENAME TABLE</span> old_table <span class="color-blue">TO</span> new_table;</p>
                            `
                        }}></div>
                    </div>
                    <p>
                        You can also rename multiple tables in a single statement by separating each <strong>`old_table_name`</strong> TO <strong>`new_table_name`</strong> pair with a comma. For example:
                    </p>
                    <div className='codePalateBox mt-2 mb-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                                <p><span class="color-blue">RENAME TABLE</span> old_table1 <span class="color-blue">TO</span> new_table1, old_table2 <span class="color-blue">TO</span> new_table2;</p>
                            `
                        }}></div>
                    </div>
                </div>
            </div>
            <div className='mt-5 mb-5'>
                <h5>ALTER TABLE statement</h5>
                <div className='mt-2 mb-2'>
                    <p>
                        You can also rename a table using the <strong>`ALTER TABLE`</strong> statement with the <strong>`RENAME TO`</strong> clause. Here's the syntax:
                    </p>
                    <div className='codePalateBox mt-2 mb-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                                <p><span class="color-blue">ALTER TABLE</span> old_table_name <span class="color-blue">RENAME TO</span> new_table_name;</p>
                            `
                        }}></div>
                    </div>
                    <p>
                        For example, to rename a table called <strong>`old_table`</strong> to <strong>`new_table`</strong>, you would use the following SQL statement:
                    </p>
                    <div className='codePalateBox mt-2 mb-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                                <p><span class="color-blue">ALTER TABLE</span> old_table <span class="color-blue">RENAME TO</span> new_table;</p>
                            `
                        }}></div>
                    </div>
                </div>
            </div>
        </section>
    )
}