import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/mysql/create-trigger";

export default function MysqlCreateTrigger() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Mysql - Create Trigger | Aspirant's Home");
        const urls = {
            'previous': '/mysql/composite-key',
            'next': '/mysql/drop-trigger'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>Mysql - Create Trigger</h3>
            <div className='mt-4 mb-5'>
                <p>
                    In MySQL, a trigger is a named database object that is associated with a table and is activated when a particular event occurs for the table. These events can include <strong>`INSERT`</strong>, <strong>`UPDATE`</strong>, or <strong>`DELETE`</strong> operations. Triggers are used to enforce business rules, perform logging, replicate data, and more.
                </p>
                <div className='featureClass'>
                    <p>
                        Here are some key points about triggers in MySQL:
                    </p>
                    <ul>
                        <li>
                            <strong>Event :</strong> Triggers are associated with a specific event, such as <strong>`BEFORE INSERT`</strong>, <strong>`AFTER UPDATE`</strong>, or <strong>`BEFORE DELETE`</strong>.
                        </li>
                        <li>
                            <strong>Timing :</strong> Triggers can be classified based on when they are executed: <strong>`BEFORE`</strong> the triggering event or <strong>`AFTER`</strong> the triggering event.
                        </li>
                        <li>
                            <strong>Action :</strong> Triggers can perform a variety of actions, including modifying data in the same table or other tables, logging changes, or raising errors.
                        </li>
                        <li>
                            <strong>Scope :</strong> Triggers are scoped to the table on which they are defined. Each table can have multiple triggers associated with it, each triggered by different events.
                        </li>
                    </ul>
                </div>
            </div>
            <div className='mt-5 mb-5'>
                <h5>Creating Trigger in MySQL</h5>
                <div className='mt-2 mb-2'>
                    <p>
                        The basic syntax to create a trigger in MySQL is as follows:
                    </p>
                    <div className='codePalateBox mt-2 mb-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                                <p><span class="color-blue">CREATE TRIGGER</span> trigger_name</p>
                                <p>{BEFORE | AFTER} {<span class="color-blue">INSERT</span> | <span class="color-blue">UPDATE</span> | <span class="color-blue">DELETE</span>} ON table_name</p>
                                <p><span class="color-blue">FOR EACH</span> <span class="color-pink">ROW</span></p>
                                <p><span class="color-blue">BEGIN</span></p>
                                <p><span class="color-grey">    -- trigger logic</span></p>
                                <p><span class="color-blue">END</span>;</p>
                            `
                        }}></div>
                    </div>
                    <p>
                        Where, <strong>`trigger_name`</strong> is the name of the trigger.
                        <strong>`BEFORE`</strong> or <strong>`AFTER`</strong> specifies when the trigger should be executed.
                        <strong>`INSERT`</strong>, <strong>`UPDATE`</strong>, or <strong>`DELETE`</strong> specifies the event that triggers the trigger.
                        <strong>`table_name`</strong> is the name of the table associated with the trigger.
                        <strong>`FOR EACH ROW`</strong> indicates that the trigger should be executed for each row affected by the triggering event.
                    </p>
                    <p>
                        Here's an example of a simple <strong>`BEFORE INSERT`</strong> trigger that sets a default value for a column:
                    </p>
                    <div className='codePalateBox mt-2 mb-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                                <p><span class="color-blue">CREATE TRIGGER</span> set_default_value</p>
                                <p>BEFORE <span class="color-blue">INSERT ON</span> my_table</p>
                                <p><span class="color-blue">FOR EACH</span> <span class="color-pink">ROW</span></p>
                                <p><span class="color-blue">BEGIN</span></p>
                                <p style="margin-left:30px">    IF NEW.column_name <span class="color-blue">IS NULL THEN</span></p>
                                <p style="margin-left:60px">        <span class="color-blue">SET</span> NEW.column_name = <span class="color-green">'default_value'</span>;</p>
                                <p style="margin-left:30px">    <span class="color-blue">END</span> IF;</p>
                                <p><span class="color-blue">END</span>;</p>
                            `
                        }}></div>
                    </div>
                    <p>
                        This trigger ensures that if a <strong>`NULL`</strong> value is inserted into <strong>`column_name`</strong>, it will be replaced with <strong>`'default_value'`</strong>.
                    </p>
                </div>
            </div>
        </section>
    )
}