import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/react-js/navigating-between-pages-with-links";

export default function NavigatePages() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("React - Navigating Between Pages With Links | Aspirant's Home");
        const urls = {
            'previous': '/react-js/defining-router',
            'next':'/react-js/layouts-nested-routes'
        }
        path.setPreviousNext(urls);
    },[])

    return (
        <section className='mt-5 mb-5'>
            <h3>Navigating Between Pages With Links</h3>
            <div className='mt-4 mb-5'>
                <p>In React, you can navigate between pages using the <strong>`Link`</strong> component from <strong>`react-router-dom`</strong>. The <strong>`Link`</strong> component renders an anchor tag (&lt;a&gt;) that changes the URL in the browser without reloading the page. Here's an example to demonstrate how to use Link to navigate between pages:</p>

                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-blue">import</span> { <span class="color-red">Link</span> } <span class="color-blue">from</span> <span class="color-green">'react-router-dom'</span>;</p>
                            <br />
                            <p><span class="color-blue">function</span> <span class="color-red">HomePage</span>() {</p>
                            <p style="margin-left:30px">    <span class="color-blue">return</span> (</p>
                            <p style="margin-left:60px">        &lt;&gt;</p>
                            <p style="margin-left:90px">            &lt;h1&gt;My Home Page&lt;/h1&gt;</p>
                            <p style="margin-left:90px">            &lt;p&gt;</p>
                            <p style="margin-left:120px">                Go to &lt;<span class="color-red">Link</span> <span class="color-pink">to</span>=<span class="color-green">"/about"</span>&gt;About Me&lt;/<span class="color-red">Link</span>&gt;
                            <p style="margin-left:90px">            &lt;/p&gt;</p>
                            <p style="margin-left:60px">        &lt;/&gt;</p>
                            <p style="margin-left:30px">    );</p>
                            <p>}</p>
                            <br />
                            <p><span class="color-blue">export default</span> <span class="color-red">HomePage</span>;</p>
                        `
                    }}></div>
                </div> 
            </div>
            <div className='mt-5 mb-4'>
                <h5>Why we are using &lt;Link&gt; instead of &lt;a&gt; ? </h5>
                <div className='mt-4 mb-4'>
                    <p>There are many reason of using &lt;Link&gt; instead of &lt;a&gt;. They are -</p>
                    <ul style={{listStyle: 'disc'}}>
                        <li>
                            <strong>Prevents Full Page Reloads : </strong> When you use a regular anchor tag (&lt;a&gt;), clicking on it typically causes the browser to reload the entire page, even in a single-page application (<strong>SPA</strong>) setup. This behavior is undesirable in <strong>SPAs</strong> because they aim to provide a seamless user experience without full page reloads. The <strong>Link</strong> component, on the other hand, uses the browser's history API to update the URL and render the new content without a full page reload.
                        </li>
                        <li>
                            <strong>Maintains Application State : </strong> SPAs often maintain application state in memory to preserve data between page navigations. When you use the <strong>Link</strong> component, the React Router library can manage the navigation process while keeping the application state intact. In contrast, a regular anchor tag would trigger a full page load, potentially resetting the application state.
                        </li>
                    </ul>
                </div>
            </div>
        </section>
    )
}