import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/react-js/events";

export default function ReactEvents() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("React Events | Aspirant's Home");
        const urls = {
            'previous': '/react-js/props',
            'next':'/react-js/hooks'
        }
        path.setPreviousNext(urls);
    },[])

    return (
        <section className='mt-5 mb-5'>
            <h3>React Js - Events</h3>
            <div className='mt-4 mb-5'>
                <p>In React, events are similar to events in traditional DOM elements but are wrapped in a consistent, cross-browser interface to ensure they behave the same across different browsers. React provides a set of synthetic events that wrap the native browser events, making them easier to work with and ensuring consistent behavior.</p>

                <p>Some common React synthetic events include <strong>`onClick`</strong>, <strong>`onChange`</strong>, <strong>`onSubmit`</strong>, <strong>`onKeyDown`</strong>, and many more. These events are used in <strong>`JSX`</strong> to listen for specific user actions, such as clicks, changes in form elements, key presses, etc.</p>

                <p>For example, you can use the <strong>`onClick`</strong> event to handle a button click:</p>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `<p><span class="color-blue">function</span> <span class="color-red">handleClick</span>() {</p>
                            <p style="margin-left:30px"><span class="color-blue">alert</span>(<span class="color-green">'Button clicked!'</span>);</p>
                            <p>}</p>
                            <br />
                            <p><span class="color-blue">function</span> <span class="color-red">App</span>() {</p>
                            <p style="margin-left:30px"><span class="color-blue">return</span> (</p>
                            <p style="margin-left:60px">  &lt;button <span class="color-pink">onClick</span>=<span class="color-green">{handleClick}</span>&gt;</p>
                            <p style="margin-left:90px">    Click me</p>
                            <p style="margin-left:60px">  &lt;/button&gt;</p>
                            <p style="margin-left:30px">);</p>
                            <p>}</p>  
                        `
                    }}></div>
                </div>
            </div>
            <div className='mt-5 mb-4'>
                <h5>Passing Custom Arguments to Event Functions</h5>
                <div className='mt-4 mb-4'>
                    <p>To pass custom arguments to event functions in React, you can use arrow functions. Here's how you can do it:</p>
                    <div className='codePalateBox mt-2 mb-4'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p><span class="color-blue">function</span> <span class="color-red">ReactEventsExample</span>() {</p>
                                <br />
                                <p style="margin-left:30px">  <span class="color-blue">function</span> <span class="color-red">handleButtonClick</span>(buttonname)</p>
                                <p style="margin-left:30px">    {</p>
                                <p style="margin-left:60px">        <span class="color-pink">console</span>.<span class="color-red">log</span>(buttonname);</p>
                                <p style="margin-left:30px">    }</p>
                                <br />
                                <p style="margin-left:30px">    <span class="color-blue">return</span> (</p>
                                <p style="margin-left:60px">        &lt;div&gt;</p>
                                <p style="margin-left:90px">            &lt;<span class="color-red">Button</span> onClick={()=><span class="color-red">handleButtonClick</span>(<span class="color-green">'Button 1'</span>)}&gt;Button 1&lt;/<span class="color-red">Button</span>&gt;</p>
                                <p style="margin-left:90px">            &lt;<span class="color-red">Button</span> onClick={()=><span class="color-red">handleButtonClick</span>(<span class="color-green">'Button 2'</span>)}&gt;Button 2&lt;/<span class="color-red">Button</span>&gt;</p>
                                <p style="margin-left:90px">            &lt;<span class="color-red">Button</span> onClick={()=><span class="color-red">handleButtonClick</span>(<span class="color-green">'Button 3'</span>)}&gt;Button 3&lt;/<span class="color-red">Button</span>&gt;</p>
                                <p style="margin-left:60px">        &lt;/div&gt;</p>
                                <p style="margin-left:30px">    )</p>
                                <p>}</p>
                            `
                        }}></div>
                    </div>
                    <p>In this above example, we define an arrow function inside the onClick attribute of the button. This arrow function calls the <strong>`handleButtonClick`</strong> function with the argument 'Button Name'.</p>
                    <p></p>
                </div>
            </div>
        </section>
    )
}
