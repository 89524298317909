import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/html/comments";

export default function HTMLComments() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("HTML - Comments | Aspirant's Home");
        const urls = {
            'previous': '/html/quotations',
            'next': '/html/images'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>HTML - Comments</h3>
            <div className='mt-4 mb-5'>
                <p>
                    HTML comments are used to leave notes or explanations within the HTML code that are not displayed to users in the web browser. These comments can be helpful for developers to describe the code, leave reminders, or temporarily disable parts of the code during development and debugging.
                </p>
                <h5 className='mt-5 mb-3'>Syntax</h5>
                <p>
                    HTML comments start with <strong>`&lt;!--`</strong> and end with <strong>`--&gt;`</strong>. The content within these tags is ignored by the browser and is not rendered on the webpage.
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-grey">&lt;!-- This is a comment --&gt;</span></p>
                        `
                    }}></div>
                </div>
                <h5 className='mt-5 mb-3'>Add Comments</h5>
                <p>With the help of comments you can place notifications and reminders in your HTML code:</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p class="color-grey">&lt;!-- This is a comment --&gt;</p>

                            <p>&lt;p&gt;This is a paragraph.&lt;/p&gt;</p>
                            
                            <p class="color-grey">&lt;!-- Remember to add more information here --&gt;</p>
                        `
                    }}></div>
                </div>
                <h5 className='mt-5 mb-3'>Hide Content</h5>
                <p>
                    Temporarily remove or disable sections of code without deleting them.
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>&lt;p&gt;This is a paragraph.&lt;/p&gt;</p>
                            <p class="color-grey">&lt;!-- &lt;p&gt;This is another paragraph &lt;/p&gt; --&gt;</p>
                            <p>&lt;p&gt;This is a paragraph too.&lt;/p&gt;</p>
                        `
                    }}></div>
                </div>
                <p>
                    You can also hide more than one line. Everything between the <strong>`&lt;!--`</strong> and the <strong>`--&gt;`</strong> will be hidden from the display.
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>&lt;p&gt;This is a paragraph.&lt;/p&gt;</p>
                            <p class="color-grey">&lt;!--</p>
                            <p class="color-grey">&lt;p&gt;Look at this below image:&lt;/p&gt;</p>
                            <p class="color-grey">&lt;img border="0" src="images.jpg" alt="Images"&gt;</p>
                            <p class="color-grey">--&gt;</p>
                            <p>&lt;p&gt;This is a paragraph too.&lt;/p&gt;</p>
                        `
                    }}></div>
                </div>
                <h5 className='mt-5 mb-3'>Hide Inline Content</h5>
                <p>
                    Comments can be used to hide content in the middle of the HTML code.
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>&lt;p&gt;This <span class="color-grey">&lt;!-- great text --&gt;</span> is a paragraph.&lt;/p&gt;</p>
                        `
                    }}></div>
                </div>
            </div>
        </section>
    )
}