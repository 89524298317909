import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';
export const pageUrl = () => "/html/basic-tags/body";

export default function HTMLBasicBODYTags() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("HTML - <body> Basic Tags | Aspirant's Home");
        path.setPreviousNext({});
    }, [])

    return (
        <>
            <section class='clearfix mb-3'>
                <span className='float-start'>
                    <Link to='/html/basic-tags' onClick={() => path.setPathName('/html/basic-tags')} className='btn btn-primary'> <i class="fa-solid fa-less-than"></i>&nbsp; Back to Basic Tags </Link>
                </span>
            </section>
            <section className='mt-5 mb-5'>
                <h3>HTML - &lt;body&gt; Basic tags</h3>
                <div className='mt-4 mb-5'>
                    <p>
                        The <strong>`&lt;body&gt;`</strong> tag in an HTML document is a container for all the content that is displayed on the web page. This includes text, images, videos, links, forms, and any other elements that make up the visual and interactive aspects of the page.
                    </p>
                    <h5 className='mt-5 mb-3'>Purpose of the `&lt;body&gt;` Tag</h5>
                    <ul style={{ listStyle: 'decimal' }}>
                        <li>
                            <strong>Content Container</strong>: The <strong>`&lt;body&gt;`</strong> tag contains all the content that is visible to users on the web page.
                        </li>
                        <li>
                            <strong>Structure and Layout</strong>: It helps organize the structure and layout of the web page by holding various HTML elements and components.
                        </li>
                        <li>
                            <strong>Interactivity</strong>: The <strong>`&lt;body&gt;`</strong> tag can include interactive elements like forms, buttons, and scripts that enhance user interaction.
                        </li>
                    </ul>
                    <h5 className='mt-5 mb-3'>Syntax of the `&lt;body&gt;` Tag</h5>
                    <p>
                        The <strong>`&lt;body&gt;`</strong> tag is placed right after the closing <strong>`&lt;/head&gt;`</strong> tag and it is closed before the closing <strong>`&lt;/html&gt;`</strong> tag.
                    </p>
                    <div className='codePalateBox mt-2 mb-4'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                                <p><span class="color-grey">&lt;!DOCTYPE</span> <span class="color-blue">html</span><span class="color-grey">&gt;</span></p>
                                <p>&lt;html <span class="color-pink">lang</span>=<span class="color-green">"en"</span>&gt;</p>
                                <p class="ml-30">    &lt;head&gt;</p>
                                <p class="ml-60">        &lt;meta <span class="color-pink">charset</span>=<span class="color-green">"UTF-8"</span>&gt;</p>
                                <p class="ml-60">        &lt;meta <span class="color-pink">name</span>=<span class="color-green">"viewport"</span> <span class="color-pink">content</span>=<span class="color-green">"width=device-width, initial-scale=1.0"</span>&gt;</p>
                                <p class="ml-60">        &lt;title&gt;Document Title&lt;/title&gt;</p>
                                <p class="ml-30">    &lt;/head&gt;</p>
                                <p class="ml-30">    &lt;body&gt;</p>
                                <p class="color-grey ml-60">        &lt;!-- Content goes here --&gt;</p>
                                <p class="ml-30">    &lt;/body&gt;</p>
                                <p>&lt;/html&gt;</p>
                            `
                        }}></div>
                    </div>
                </div>
            </section>
        </>
    )
}