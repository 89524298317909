import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/css/background-position";

export default function CSSBackgroundPosition() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Background Position in CSS | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/css/background-attachment',
            'next': '/css/background-origin'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>Background Position</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <strong>`background-position`</strong> property in CSS specifies the initial position of a background image within an element. This property allows you to control where the background image is placed within the element's background area. It has a basic syntax -
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>selector {</p>
                            <p class="ml-30">    <span class="color-green">background-position</span>: value;</p>
                            <p>}</p>
                        `
                    }}></div>
                </div>
                <h5 className="mt-5 mb-3">Commonly Used Values and Their Uses</h5>
                <p>
                    There are many <strong>`background-position`</strong> property values. They are -
                </p>
                <ul style={{ 'listStyle': 'decimal' }}>
                    <li>
                        <strong>Keyword Values</strong>
                        <p>There are many predefined keyword values to position the background image like top, bottom, left, right, center.</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p>div {</p>
                                    <p class="ml-30">   <span class="color-green">background-image</span>: <span class="color-yellow">url</span>(<span class="color-green">'pattern.png'</span>);</p>
                                    <p class="ml-30">    <span class="color-green">background-position</span>: top left;</p>
                                    <p>}</p>
                                `
                            }}></div>
                        </div>
                        <div className='outputPalateBox mb-4 mt-3'>
                            <div className='outputPalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <style>
                                        .underDiv2{
                                            background-image : url('../../assets/aspirantshome_small.jpg');
                                            background-repeat: no-repeat;
                                            background-position: top left;
                                            background-size:30%;
                                        }
                                        .colorNameDiv2{
                                            height: 300px;
                                            overflow: scroll;
                                        }
                                    </style>
                                    <div class="underDiv2">
                                        <div class="colorNameDiv2 fontStyle">
                                            <p>
                                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                                            </p>
                                            <p>
                                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                                            </p>
                                        </div>
                                    </div>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <strong>Percentage Values</strong>
                        <p>It specifies the position using percentages relative to the element's dimensions.</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p>div {</p>
                                    <p class="ml-30">   <span class="color-green">background-image</span>: <span class="color-yellow">url</span>(<span class="color-green">'pattern.png'</span>);</p>
                                    <p class="ml-30">    <span class="color-green">background-position</span>: 50% 50%; <span class="color-grey">/* Centered */</span></p>
                                    <p>}</p>
                                `
                            }}></div>
                        </div>
                        <div className='outputPalateBox mb-4 mt-3'>
                            <div className='outputPalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <style>
                                        .underDiv3{
                                            background-image : url('../../assets/aspirantshome_small.jpg');
                                            background-repeat: no-repeat;
                                            background-position: 50% 50%; /* Centered */
                                            background-size:30%;
                                        }
                                        .colorNameDiv3{
                                            height: 300px;
                                            overflow: scroll;
                                        }
                                    </style>
                                    <div class="underDiv3">
                                        <div class="colorNameDiv3 fontStyle">
                                            <p>
                                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                                            </p>
                                            <p>
                                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                                            </p>
                                        </div>
                                    </div>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <strong>Length Values</strong>
                        <p>It specifies the position using length units (e.g., pixels, ems).</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p>div {</p>
                                    <p class="ml-30">   <span class="color-green">background-image</span>: <span class="color-yellow">url</span>(<span class="color-green">'pattern.png'</span>);</p>
                                    <p class="ml-30">    <span class="color-green">background-position</span>: 100px 120px;</span></p>
                                    <p>}</p>
                                `
                            }}></div>
                        </div>
                        <div className='outputPalateBox mb-4 mt-3'>
                            <div className='outputPalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <style>
                                        .underDiv4{
                                            background-image : url('../../assets/aspirantshome_small.jpg');
                                            background-repeat: no-repeat;
                                            background-position: 100px 120px;
                                            background-size:30%;
                                        }
                                        .colorNameDiv4{
                                            height: 300px;
                                            overflow: scroll;
                                        }
                                    </style>
                                    <div class="underDiv4">
                                        <div class="colorNameDiv4 fontStyle">
                                            <p>
                                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                                            </p>
                                            <p>
                                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                                            </p>
                                        </div>
                                    </div>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <strong>Combination of Keywords and Length/Percentage Values</strong>
                        <p>You can mix keywords with length or percentage values.</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p>div {</p>
                                    <p class="ml-30">   <span class="color-green">background-image</span>: <span class="color-yellow">url</span>(<span class="color-green">'pattern.png'</span>);</p>
                                    <p class="ml-30">    <span class="color-green">background-position</span>: right 10px bottom 20px;</span></p>
                                    <p>}</p>
                                `
                            }}></div>
                        </div>
                        <div className='outputPalateBox mb-4 mt-3'>
                            <div className='outputPalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <style>
                                        .underDiv5{
                                            background-image : url('../../assets/aspirantshome_small.jpg');
                                            background-repeat: no-repeat;
                                            background-position: right 10px bottom 20px;
                                            background-size:30%;
                                        }
                                        .colorNameDiv5{
                                            height: 300px;
                                            overflow: scroll;
                                        }
                                    </style>
                                    <div class="underDiv5">
                                        <div class="colorNameDiv5 fontStyle">
                                            <p>
                                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                                            </p>
                                            <p>
                                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                                            </p>
                                        </div>
                                    </div>
                                `
                            }}></div>
                        </div>
                    </li>
                </ul>
            </div>
        </section>
    )
}