import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../../Context";
import { Link } from 'react-router-dom';
export const pageUrl = () => "/mysql/numeric-functions/mod";

export default function MOD() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Mysql - Numeric Functions - MOD(x, y) | Aspirant's Home");
        path.setPreviousNext({});
    }, [])

    return (
        <>
            <section class='clearfix mb-3'>
                <span className='float-start'>
                    <Link to='/mysql/numeric-functions' onClick={() => path.setPathName('/mysql/numeric-functions')} className='btn btn-primary'> <i class="fa-solid fa-less-than"></i>&nbsp; Back to Numeric Functions </Link>
                </span>
            </section>
            <section className='mt-5 mb-5'>
                <h3>Mysql - MOD(x, y) Function</h3>
                <div className='mt-4 mb-5'>
                    <p>
                        The <strong>`MOD(x, y)`</strong> function in MySQL is used to return the remainder of the division of <strong>`x`</strong> by <strong>`y`</strong>. This function is also known as the modulo operation.
                    </p>
                    <p>
                        Here is a basic example using the MOD function in a SELECT query:
                    </p>
                    <div className='codePalateBox mt-2 mb-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                                <p><span class="color-blue">SELECT</span> MOD(10, 3);</p>
                            `
                        }}></div>
                    </div>
                </div>
                <div className='mt-5 mb-5'>
                    <h5>MOD(x, y) with MySQL Table</h5>
                    <div className='mt-3 mb-2'>
                        <p>
                            Suppose we have a table <strong>`work_schedule`</strong> that tracks the total hours an employee is scheduled to work and the total hours they've worked so far.
                        </p>
                        <table className='table' style={{border: '1px dashed #ccc'}}> 
                            <thead>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>id</th>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>employee_name</th>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>total_hours_scheduled</th>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>total_hours_worked</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>1</td>
                                    <td style={{border: '1px dashed #ccc'}}>Alice</td>
                                    <td style={{border: '1px dashed #ccc'}}>160</td>
                                    <td style={{border: '1px dashed #ccc'}}>37</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>2</td>
                                    <td style={{border: '1px dashed #ccc'}}>Bob</td>
                                    <td style={{border: '1px dashed #ccc'}}>160</td>
                                    <td style={{border: '1px dashed #ccc'}}>41</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>3</td>
                                    <td style={{border: '1px dashed #ccc'}}>Charlie</td>
                                    <td style={{border: '1px dashed #ccc'}}>160</td>
                                    <td style={{border: '1px dashed #ccc'}}>78</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>4</td>
                                    <td style={{border: '1px dashed #ccc'}}>David</td>
                                    <td style={{border: '1px dashed #ccc'}}>160</td>
                                    <td style={{border: '1px dashed #ccc'}}>85</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>5</td>
                                    <td style={{border: '1px dashed #ccc'}}>Eva</td>
                                    <td style={{border: '1px dashed #ccc'}}>160</td>
                                    <td style={{border: '1px dashed #ccc'}}>123</td>
                                </tr>
                            </tbody>
                        </table>
                        <p>
                            We want to find out the remaining hours they need to work to complete their next full workday (assuming an 8-hour workday).
                        </p>
                        <div className='codePalateBox mt-2 mb-2'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-blue">SELECT</span> id, employee_name, total_hours_scheduled, total_hours_worked, (8 - MOD(total_hours_worked, 8)) <span class="color-blue">AS</span> remaining_hours_to_complete_day <span class="color-blue">FROM</span> work_schedule;</p>
                                `
                            }}></div>
                        </div>
                        <p>
                            This query will return:
                        </p>
                        <table className='table' style={{border: '1px dashed #ccc'}}> 
                            <thead>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>id</th>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>employee_name</th>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>total_hours_scheduled</th>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>total_hours_worked</th>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>remaining_hours_to_complete_day</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>1</td>
                                    <td style={{border: '1px dashed #ccc'}}>Alice</td>
                                    <td style={{border: '1px dashed #ccc'}}>160</td>
                                    <td style={{border: '1px dashed #ccc'}}>37</td>
                                    <td style={{border: '1px dashed #ccc'}}>3</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>2</td>
                                    <td style={{border: '1px dashed #ccc'}}>Bob</td>
                                    <td style={{border: '1px dashed #ccc'}}>160</td>
                                    <td style={{border: '1px dashed #ccc'}}>41</td>
                                    <td style={{border: '1px dashed #ccc'}}>7</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>3</td>
                                    <td style={{border: '1px dashed #ccc'}}>Charlie</td>
                                    <td style={{border: '1px dashed #ccc'}}>160</td>
                                    <td style={{border: '1px dashed #ccc'}}>78</td>
                                    <td style={{border: '1px dashed #ccc'}}>2</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>4</td>
                                    <td style={{border: '1px dashed #ccc'}}>David</td>
                                    <td style={{border: '1px dashed #ccc'}}>160</td>
                                    <td style={{border: '1px dashed #ccc'}}>85</td>
                                    <td style={{border: '1px dashed #ccc'}}>3</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>5</td>
                                    <td style={{border: '1px dashed #ccc'}}>Eva</td>
                                    <td style={{border: '1px dashed #ccc'}}>160</td>
                                    <td style={{border: '1px dashed #ccc'}}>123</td>
                                    <td style={{border: '1px dashed #ccc'}}>5</td>
                                </tr>
                            </tbody>
                        </table>
                        <p>
                            In this example, <strong>`total_hours_worked`</strong> column shows the total hours the employee has worked so far.  <strong>`MOD(total_hours_worked, 8)`</strong> function calculates the hours worked beyond the last full 8-hour workday. <strong>`8 - MOD(total_hours_worked, 8)`</strong> expression calculates the remaining hours needed to complete the next full 8-hour workday.
                        </p>
                    </div>
                </div>
            </section>
        </>
    )
}