import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';
export const pageUrl = () => "/mongo-db/aggregation/lookup";

export default function MongoAggregationLookup() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("$lookup Aggregation Pipelines in MongoDB");
        title.setKeyWords("$lookup aggregation pipeline in mongodb, $lookup pipeline mongodb, mongodb aggregate lookup pipeline match, mongodb aggregate lookup pipeline, mongodb pipeline lookup match, mongodb $lookup pipeline, aggregation pipeline mongodb examples, $lookup pipeline, $lookup example mongodb");
        title.setPageDescription("The `$lookup` stage in MongoDB's aggregation framework is used to perform a left outer join to another collection in the same database. It allows you to combine documents from different collections based on a specified field, similar to SQL joins. This stage is particularly useful for aggregating and merging related data from multiple collections.");
        path.setPreviousNext({});
    }, [])

    return (
        <>
            <section class='clearfix mb-3'>
                <span className='float-start'>
                    <Link to='/mongo-db/aggregation' onClick={() => path.setPathName('/mongo-db/aggregation')} className='btn btn-primary'> <i class="fa-solid fa-less-than"></i>&nbsp; Back to Aggregation </Link>
                </span>
            </section>
            <section className='mt-5 mb-5'>
                <h3>MongoDB - $lookup Pipelines</h3>
                <div className='mt-4 mb-5'>
                    <p>
                        The <strong>`$lookup`</strong> stage in MongoDB's aggregation framework is used to perform a left outer join to another collection in the same database. It allows you to combine documents from different collections based on a specified field, similar to SQL joins. This stage is particularly useful for aggregating and merging related data from multiple collections.
                    </p>
                    <h5>Basic Syntax</h5>
                    <p>
                        The <strong>`$lookup`</strong> stage requires a document that specifies the join conditions and the fields to be used.
                    </p>
                    <div className='codePalateBox mt-2 mb-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                                <p>{</p>
                                <p style="margin-left:30px">    <span class="color-pink">$lookup</span>: {</p>
                                <p style="margin-left:60px">    <span class="color-pink">from</span>: &lt;collection&gt;,</p>
                                <p style="margin-left:60px">    <span class="color-pink">localField</span>: &lt;localField&gt;,</p>
                                <p style="margin-left:60px">    <span class="color-pink">foreignField</span>: &lt;foreignField&gt;,</p>
                                <p style="margin-left:60px">    <span class="color-pink">as</span>: &lt;outputField&gt;</p>
                                <p style="margin-left:30px">    }</p>
                                <p>}</p>
                            `
                        }}></div>
                    </div>
                    <ul style={{listStyle:'disc'}}>
                        <li><strong>`from`</strong>: The name of the collection in the same database to join.</li>
                        <li><strong>`localField`</strong>: The field from the input documents (the current collection) to match against the <strong>`foreignField`</strong> from the <strong>`from`</strong> collection.</li>
                        <li><strong>`foreignField`</strong>: The field from the <strong>`from`</strong> collection to match against the <strong>`localField`</strong> from the input documents.</li>
                        <li><strong>`as`</strong>: The name of the new array field to add to the input documents. This field contains the matching documents from the <strong>`from`</strong> collection.</li>
                    </ul>
                    <h5>Example Scenario</h5>
                    <p>
                        Consider two collections: <strong>`orders`</strong> and <strong>`customers`</strong>.
                    </p>
                    <h6>Example `orders` Collection</h6>
                    <div className='codePalateBox mt-2 mb-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                                <p>[</p>
                                <p style="margin-left:30px">    { <span class="color-pink">"_id"</span>: <span class="color-pink">1</span>, <span class="color-pink">"product"</span>: <span class="color-green">"Laptop"</span>, <span class="color-pink">"customerId"</span>: <span class="color-pink">101</span> },</p>
                                <p style="margin-left:30px">    { <span class="color-pink">"_id"</span>: <span class="color-pink">2</span>, <span class="color-pink">"product"</span>: <span class="color-green">"Smartphone"</span>, <span class="color-pink">"customerId"</span>: <span class="color-pink">102</span> },</p>
                                <p style="margin-left:30px">    { <span class="color-pink">"_id"</span>: <span class="color-pink">3</span>, <span class="color-pink">"product"</span>: <span class="color-green">"Tablet"</span>, <span class="color-pink">"customerId"</span>: <span class="color-pink">101</span> }</p>
                                <p>]</p>
                            `
                        }}></div>
                    </div>
                    <h6>Example `customers` Collection</h6>
                    <div className='codePalateBox mt-2 mb-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                                <p>[</p>
                                <p style="margin-left:30px">    { <span class="color-pink">"_id"</span>: <span class="color-pink">101</span>, <span class="color-pink">"name"</span>: <span class="color-green">"Alice"</span>, <span class="color-pink">"email"</span>: <span class="color-green">"alice@example.com"</span> },</p>
                                <p style="margin-left:30px">    { <span class="color-pink">"_id"</span>: <span class="color-pink">102</span>, <span class="color-pink">"name"</span>: <span class="color-green">"Bob"</span>, <span class="color-pink">"email"</span>: <span class="color-green">"bob@example.com"</span> }</p>
                                <p>]</p>
                            `
                        }}></div>
                    </div>
                    <h5 className='mt-4 mb-3'>Basic `$lookup`</h5>
                    <p>
                        To join the <strong>`customers`</strong> collection with the <strong>`orders`</strong> collection based on the <strong>`customerId`</strong> field in <strong>`orders`</strong> and the <strong>`_id`</strong> field in <strong>`customers`</strong>:
                    </p>
                    <div className='codePalateBox mt-2 mb-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                                <p>db.orders.<span class="color-red">aggregate</span>([</p>
                                <p style="margin-left:30px">    {</p>
                                <p style="margin-left:60px">    <span class="color-pink">$lookup</span>: {</p>
                                <p style="margin-left:90px">        <span class="color-pink">from</span>: <span class="color-green">"customers"</span>,</p>
                                <p style="margin-left:90px">        <span class="color-pink">localField</span>: <span class="color-green">"customerId"</span>,</p>
                                <p style="margin-left:90px">        <span class="color-pink">foreignField</span>: <span class="color-green">"_id"</span>,</p>
                                <p style="margin-left:90px">        <span class="color-pink">as</span>: <span class="color-green">"customerInfo"</span></p>
                                <p style="margin-left:60px">    }</p>
                                <p style="margin-left:30px">    }</p>
                                <p>])</p>
                            `
                        }}></div>
                    </div>
                    <h6 className='mt-4 mb-4'>Result of the above</h6>
                    <div className='codePalateBox mt-2 mb-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                                <p>[</p>
                                <p style="margin-left:30px">    {</p>
                                <p style="margin-left:60px">        <span class="color-pink">"_id"</span>: <span class="color-pink">1</span>,</p>
                                <p style="margin-left:60px">        <span class="color-pink">"product"</span>: <span class="color-green">"Laptop"</span>,</p>
                                <p style="margin-left:60px">        <span class="color-pink">"customerId"</span>: <span class="color-pink">101</span>,</p>
                                <p style="margin-left:60px">        <span class="color-pink">"customerInfo"</span>: [</p>
                                <p style="margin-left:90px">            { <span class="color-pink">"_id"</span>: <span class="color-pink">101</span>, <span class="color-pink">"name"</span>: <span class="color-green">"Alice"</span>, <span class="color-pink">"email"</span>: <span class="color-green">"alice@example.com"</span> }</p>
                                <p style="margin-left:60px">        ]</p>
                                <p style="margin-left:30px">    },</p>
                                <p style="margin-left:30px">    {</p>
                                <p style="margin-left:60px">        <span class="color-pink">"_id"</span>: <span class="color-pink">2</span>,</p>
                                <p style="margin-left:60px">        <span class="color-pink">"product"</span>: <span class="color-green">"Smartphone"</span>,</p>
                                <p style="margin-left:60px">        <span class="color-pink">"customerId"</span>: <span class="color-pink">102</span>,</p>
                                <p style="margin-left:60px">        <span class="color-pink">"customerInfo"</span>: [</p>
                                <p style="margin-left:90px">            { <span class="color-pink">"_id"</span>: <span class="color-pink">102</span>, <span class="color-pink">"name"</span>: <span class="color-green">"Bob"</span>, <span class="color-pink">"email"</span>: <span class="color-green">"bob@example.com"</span> }</p>
                                <p style="margin-left:60px">        ]</p>
                                <p style="margin-left:30px">    },</p>
                                <p style="margin-left:30px">    {</p>
                                <p style="margin-left:60px">        <span class="color-pink">"_id"</span>: <span class="color-pink">3</span>,</p>
                                <p style="margin-left:60px">        <span class="color-pink">"product"</span>: <span class="color-green">"Tablet"</span>,</p>
                                <p style="margin-left:60px">        <span class="color-pink">"customerId"</span>: <span class="color-pink">101</span>,</p>
                                <p style="margin-left:60px">        <span class="color-pink">"customerInfo"</span>: [</p>
                                <p style="margin-left:90px">            { <span class="color-pink">"_id"</span>: <span class="color-pink">101</span>, <span class="color-pink">"name"</span>: <span class="color-green">"Alice"</span>, <span class="color-pink">"email"</span>: <span class="color-green">"alice@example.com"</span> }</p>
                                <p style="margin-left:60px">        ]</p>
                                <p style="margin-left:30px">    }</p>
                                <p>]</p>
                            `
                        }}></div>
                    </div>
                    <h5 className='mt-4 mb-3'>Unwinding the Result</h5>
                    <p>
                        The above result contains an array field <strong>`customerInfo`</strong> that holds matching documents from the <strong>`customers`</strong> collection. If you want to flatten the array to have a single document for each join, you can use the <strong>`$unwind`</strong> stage.
                    </p>
                    <div className='codePalateBox mt-2 mb-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                                <p>db.orders.<span class="color-red">aggregate</span>([</p>
                                <p style="margin-left:30px">    {</p>
                                <p style="margin-left:60px">    <span class="color-pink">$lookup</span>: {</p>
                                <p style="margin-left:90px">        <span class="color-pink">from</span>: <span class="color-green">"customers"</span>,</p>
                                <p style="margin-left:90px">        <span class="color-pink">localField</span>: <span class="color-green">"customerId"</span>,</p>
                                <p style="margin-left:90px">        <span class="color-pink">foreignField</span>: <span class="color-green">"_id"</span>,</p>
                                <p style="margin-left:90px">        <span class="color-pink">as</span>: <span class="color-green">"customerInfo"</span></p>
                                <p style="margin-left:60px">    }</p>
                                <p style="margin-left:30px">    },</p>
                                <p style="margin-left:30px">    {</p>
                                <p style="margin-left:60px">    <span class="color-pink">$unwind</span>: <span class="color-green">"$customerInfo"</span></p>
                                <p style="margin-left:30px">    }</p>
                                <p>])</p>
                            `
                        }}></div>
                    </div>
                    <h6 className='mt-4 mb-4'>Result of the above</h6>
                    <div className='codePalateBox mt-2 mb-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                                <p>[</p>
                                <p style="margin-left:30px">    {</p>
                                <p style="margin-left:60px">        <span class="color-pink">"_id"</span>: <span class="color-pink">1</span>,</p>
                                <p style="margin-left:60px">        <span class="color-pink">"product"</span>: <span class="color-green">"Laptop"</span>,</p>
                                <p style="margin-left:60px">        <span class="color-pink">"customerId"</span>: <span class="color-pink">101</span>,</p>
                                <p style="margin-left:60px">        <span class="color-pink">"customerInfo"</span>: { <span class="color-pink">"_id"</span>: <span class="color-pink">101</span>, <span class="color-pink">"name"</span>: <span class="color-green">"Alice"</span>, <span class="color-pink">"email"</span>: <span class="color-green">"alice@example.com"</span> }</p>
                                <p style="margin-left:30px">    },</p>
                                <p style="margin-left:30px">    {</p>
                                <p style="margin-left:60px">        <span class="color-pink">"_id"</span>: <span class="color-pink">2</span>,</p>
                                <p style="margin-left:60px">        <span class="color-pink">"product"</span>: <span class="color-green">"Smartphone"</span>,</p>
                                <p style="margin-left:60px">        <span class="color-pink">"customerId"</span>: <span class="color-pink">102</span>,</p>
                                <p style="margin-left:60px">        <span class="color-pink">"customerInfo"</span>: { <span class="color-pink">"_id"</span>: <span class="color-pink">102</span>, <span class="color-pink">"name"</span>: <span class="color-green">"Bob"</span>, <span class="color-pink">"email"</span>: <span class="color-green">"bob@example.com"</span> }</p>
                                <p style="margin-left:30px">    },</p>
                                <p style="margin-left:30px">    {</p>
                                <p style="margin-left:60px">        <span class="color-pink">"_id"</span>: <span class="color-pink">3</span>,</p>
                                <p style="margin-left:60px">        <span class="color-pink">"product"</span>: <span class="color-green">"Tablet"</span>,</p>
                                <p style="margin-left:60px">        <span class="color-pink">"customerId"</span>: <span class="color-pink">101</span>,</p>
                                <p style="margin-left:60px">        <span class="color-pink">"customerInfo"</span>: { <span class="color-pink">"_id"</span>: <span class="color-pink">101</span>, <span class="color-pink">"name"</span>: <span class="color-green">"Alice"</span>, <span class="color-pink">"email"</span>: <span class="color-green">"alice@example.com"</span> }</p>
                                <p style="margin-left:30px">    }</p>
                                <p>]</p>
                            `
                        }}></div>
                    </div>
                    <div className='mt-5 mb-4 featureClass'>
                        <p>
                            The <strong>`$lookup`</strong> stage in MongoDB's aggregation framework allows you to perform joins between collections, providing powerful capabilities to combine and aggregate data across different collections. By using <strong>`$lookup`</strong>, you can:
                        </p>
                        <ul style={{listStyle:'disc'}}>
                            <li>Merge related documents from different collections.</li>
                            <li>Perform complex queries involving multiple collections.</li>
                            <li>Enhance the flexibility and power of your data aggregation and analysis operations.</li>
                        </ul>
                    </div>
                </div>
            </section>
        </>
    )
}