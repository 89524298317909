import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/mysql/union-operator";

export default function MysqlUnionOperator() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Mysql - UNION Operator | Aspirant's Home");
        const urls = {
            'previous': '/mysql/between-operator',
            'next': '/mysql/inner-join'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>Mysql - UNION Operator</h3>
            <div className='mt-4 mb-5'>
                <p>
                    In MySQL, the <strong>`UNION`</strong> operator is used to combine the result sets of two or more <strong>`SELECT`</strong> statements into a single result set. The <strong>`UNION`</strong> operator removes duplicate rows by default.
                </p>
                <p>
                    Following is the syntax of the <strong>`UNION`</strong> operator in MySQL −
                </p>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-blue">SELECT</span> column1, column2, ...</p>
                            <p><span class="color-blue">FROM</span> table1</p>
                            <p><span class="color-blue">WHERE</span> condition1</p>
                            <p><span class="color-blue">UNION</span></p>
                            <p><span class="color-blue">SELECT</span> column1, column2, ...</p>
                            <p><span class="color-blue">FROM</span> table2</p>
                            <p><span class="color-blue">WHERE</span> condition2;</p>
                        `
                    }}></div>
                </div>
                <p>
                    Where, <strong>`column1, column2, ...`</strong> are the columns you want to retrieve. <strong>`table1, table2, ...`</strong> are the tables you're querying. <strong>`condition1, condition2, ...`</strong> are optional conditions to filter the rows in each <strong>`SELECT`</strong> statement.
                </p>

                <p>
                    Suppose, we have a <strong>`employees`</strong> table like below -
                </p>
                <table className='table' style={{border: '1px dashed #ccc'}}> 
                    <thead>
                        <tr style={{border: '1px dashed #ccc'}}>
                            <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>id</th>
                            <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>name</th>
                            <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>department</th>
                            <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>salary</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr style={{border: '1px dashed #ccc'}}>
                            <td style={{border: '1px dashed #ccc'}}>1</td>
                            <td style={{border: '1px dashed #ccc'}}>Jane Smith</td>
                            <td style={{border: '1px dashed #ccc'}}>IT</td>
                            <td style={{border: '1px dashed #ccc'}}>500000</td>
                        </tr>
                        <tr style={{border: '1px dashed #ccc'}}>
                            <td style={{border: '1px dashed #ccc'}}>2</td>
                            <td style={{border: '1px dashed #ccc'}}>John Doe</td>
                            <td style={{border: '1px dashed #ccc'}}>IT</td>
                            <td style={{border: '1px dashed #ccc'}}>350000</td>
                        </tr>
                        <tr style={{border: '1px dashed #ccc'}}>
                            <td style={{border: '1px dashed #ccc'}}>3</td>
                            <td style={{border: '1px dashed #ccc'}}>Johny Smith</td>
                            <td style={{border: '1px dashed #ccc'}}>Accountant</td>
                            <td style={{border: '1px dashed #ccc'}}>50000</td>
                        </tr>
                        <tr style={{border: '1px dashed #ccc'}}>
                            <td style={{border: '1px dashed #ccc'}}>4</td>
                            <td style={{border: '1px dashed #ccc'}}>Jeffer Joe</td>
                            <td style={{border: '1px dashed #ccc'}}>HR Manager</td>
                            <td style={{border: '1px dashed #ccc'}}>380000</td>
                        </tr>
                        <tr style={{border: '1px dashed #ccc'}}>
                            <td style={{border: '1px dashed #ccc'}}>5</td>
                            <td style={{border: '1px dashed #ccc'}}>Sikhar Smith</td>
                            <td style={{border: '1px dashed #ccc'}}>Accountant</td>
                            <td style={{border: '1px dashed #ccc'}}>70000</td>
                        </tr>
                        <tr style={{border: '1px dashed #ccc'}}>
                            <td style={{border: '1px dashed #ccc'}}>6</td>
                            <td style={{border: '1px dashed #ccc'}}>Xavier Doe</td>
                            <td style={{border: '1px dashed #ccc'}}>IT</td>
                            <td style={{border: '1px dashed #ccc'}}>750000</td>
                        </tr>
                    </tbody>
                </table>

                <p>
                    For example, suppose you have two tables, <strong>`employees1`</strong> and <strong>`employees2`</strong>, with similar columns, and you want to combine the results of both tables:
                </p>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-blue">SELECT</span> * <span class="color-blue">FROM</span> employees <span class="color-blue">WHERE</span> department = <span class="color-green">'IT'</span> <span class="color-blue">UNION</span> <span class="color-blue">SELECT</span> * <span class="color-blue">FROM</span> employees <span class="color-blue">WHERE</span> department = <span class="color-green">'HR Manager'</span>;</p>
                        `
                    }}></div>
                </div>
                <p>
                    This query will return a result set containing the names of employees in both the <strong>'IT'</strong> department and the <strong>'HR Manager'</strong> department, with duplicate names removed. 
                </p>
                <p>
                    The Output of the query will be the following -
                </p>
                <table className='table' style={{border: '1px dashed #ccc'}}> 
                    <thead>
                        <tr style={{border: '1px dashed #ccc'}}>
                            <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>id</th>
                            <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>name</th>
                            <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>department</th>
                            <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>salary</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr style={{border: '1px dashed #ccc'}}>
                            <td style={{border: '1px dashed #ccc'}}>1</td>
                            <td style={{border: '1px dashed #ccc'}}>Jane Smith</td>
                            <td style={{border: '1px dashed #ccc'}}>IT</td>
                            <td style={{border: '1px dashed #ccc'}}>500000</td>
                        </tr>
                        <tr style={{border: '1px dashed #ccc'}}>
                            <td style={{border: '1px dashed #ccc'}}>2</td>
                            <td style={{border: '1px dashed #ccc'}}>John Doe</td>
                            <td style={{border: '1px dashed #ccc'}}>IT</td>
                            <td style={{border: '1px dashed #ccc'}}>350000</td>
                        </tr>
                        <tr style={{border: '1px dashed #ccc'}}>
                            <td style={{border: '1px dashed #ccc'}}>4</td>
                            <td style={{border: '1px dashed #ccc'}}>Jeffer Joe</td>
                            <td style={{border: '1px dashed #ccc'}}>HR Manager</td>
                            <td style={{border: '1px dashed #ccc'}}>380000</td>
                        </tr>
                        <tr style={{border: '1px dashed #ccc'}}>
                            <td style={{border: '1px dashed #ccc'}}>6</td>
                            <td style={{border: '1px dashed #ccc'}}>Xavier Doe</td>
                            <td style={{border: '1px dashed #ccc'}}>IT</td>
                            <td style={{border: '1px dashed #ccc'}}>750000</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </section>
    )
}