import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../../Context";
import { Link } from 'react-router-dom';
export const pageUrl = () => "/mysql/numeric-functions/sqrt";

export default function SQRT() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Mysql - Numeric Functions - SQRT(x) | Aspirant's Home");
        path.setPreviousNext({});
    }, [])

    return (
        <>
            <section class='clearfix mb-3'>
                <span className='float-start'>
                    <Link to='/mysql/numeric-functions' onClick={() => path.setPathName('/mysql/numeric-functions')} className='btn btn-primary'> <i class="fa-solid fa-less-than"></i>&nbsp; Back to Numeric Functions </Link>
                </span>
            </section>
            <section className='mt-5 mb-5'>
                <h3>Mysql - SQRT(x) Function</h3>
                <div className='mt-4 mb-5'>
                    <p>
                        The <strong>`SQRT()`</strong> function in MySQL returns the square root of a given number. It is used to perform the mathematical operation of finding the square root.
                    </p>
                    <p>
                        Here's a simple example of using the <strong>`SQRT()`</strong> function:
                    </p>
                    <div className='codePalateBox mt-2 mb-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                                <p><span class="color-blue">SELECT</span> SQRT(<span class="color-pink">16</span>);</p>
                            `
                        }}></div>
                    </div>
                    <p>
                        This will return <strong>`4`</strong>, because the square root of 16 is 4.
                    </p>
                </div>
                <div className='mt-5 mb-5'>
                    <h5>SQRT(x) with MySQL Table</h5>
                    <div className='mt-3 mb-2'>
                        <p>
                            Suppose you have a table <strong>`plots`</strong> that stores information about the areas of square plots of land, and you want to calculate the side length of each square plot. The side length of a square plot can be found by taking the square root of its area.
                        </p>
                        <table className='table' style={{border: '1px dashed #ccc'}}> 
                            <thead>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>id</th>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>plot_name</th>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>area</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>1</td>
                                    <td style={{border: '1px dashed #ccc'}}>Plot A</td>
                                    <td style={{border: '1px dashed #ccc'}}>2500</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>2</td>
                                    <td style={{border: '1px dashed #ccc'}}>Plot B</td>
                                    <td style={{border: '1px dashed #ccc'}}>1600</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>3</td>
                                    <td style={{border: '1px dashed #ccc'}}>Plot C</td>
                                    <td style={{border: '1px dashed #ccc'}}>900</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>4</td>
                                    <td style={{border: '1px dashed #ccc'}}>Plot D</td>
                                    <td style={{border: '1px dashed #ccc'}}>3600</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>5</td>
                                    <td style={{border: '1px dashed #ccc'}}>Plot E</td>
                                    <td style={{border: '1px dashed #ccc'}}>1225</td>
                                </tr>
                            </tbody>
                        </table>
                        <p>
                            To find the side length of each square plot, you can use the <strong>`SQRT()`</strong> function to calculate the square root of the area.
                        </p>
                        <div className='codePalateBox mt-2 mb-2'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-blue">SELECT</span> id, plot_name, area, SQRT(area) <span class="color-blue">AS</span> side_length <span class="color-blue">FROM</span> plots;</p>
                                `
                            }}></div>
                        </div>
                        <p>
                            This query will return:
                        </p>
                        <table className='table' style={{border: '1px dashed #ccc'}}> 
                            <thead>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>id</th>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>plot_name</th>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>area</th>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>side_length</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>1</td>
                                    <td style={{border: '1px dashed #ccc'}}>Plot A</td>
                                    <td style={{border: '1px dashed #ccc'}}>2500</td>
                                    <td style={{border: '1px dashed #ccc'}}>50</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>2</td>
                                    <td style={{border: '1px dashed #ccc'}}>Plot B</td>
                                    <td style={{border: '1px dashed #ccc'}}>1600</td>
                                    <td style={{border: '1px dashed #ccc'}}>40</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>3</td>
                                    <td style={{border: '1px dashed #ccc'}}>Plot C</td>
                                    <td style={{border: '1px dashed #ccc'}}>900</td>
                                    <td style={{border: '1px dashed #ccc'}}>30</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>4</td>
                                    <td style={{border: '1px dashed #ccc'}}>Plot D</td>
                                    <td style={{border: '1px dashed #ccc'}}>3600</td>
                                    <td style={{border: '1px dashed #ccc'}}>60</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>5</td>
                                    <td style={{border: '1px dashed #ccc'}}>Plot E</td>
                                    <td style={{border: '1px dashed #ccc'}}>1225</td>
                                    <td style={{border: '1px dashed #ccc'}}>35</td>
                                </tr>
                            </tbody>
                        </table>
                        <p>
                            In this query, the <strong>`SQRT()`</strong> function helps in computing the side length of square plots based on their area.
                        </p>
                    </div>
                </div>
            </section>
        </>
    )
}