import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/node-js/mongodb-update-document";

export default function MongoDbUpdateDocument() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Node Js - MongoDB - Update Document | Aspirant's Home");
        const urls = {
            'previous': '/node-js/mongodb-drop-document',
            'next': '/node-js/mongodb-limit-document'
        }
        path.setPreviousNext(urls);
    }, [])
    
    return (
        <section className='mt-5 mb-5'>
            <h3>Node Js MongoDB Update Document</h3>
            <div className='mt-4'>
                <p>In MongoDB, you can update a document using the <strong>`updateOne()`</strong> or <strong>`updateMany()`</strong> method, depending on whether you want to update a single document or multiple documents that match a specified filter .</p>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html:
                            ` db.collection.<span class='color-red'>updateOne</span>(filter, update, options)`
                    }}>
                    </div>
                </div>
                <p>We have discussed in details about these parameter in our <a href='/mongo-db'>MongoDB Tutorial</a> page.</p>
                <p>Now lets see how we update document</p>
            </div>
            <div className='mt-4'>
                <h5>Using `updateMany()`</h5>
                <p>We can update multiple document using `updateMany()` method. Ok lets see an example where we update all documents that meets the criteria of the query.</p>
                <p>Suppose we have a collection of employee like below.</p>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html:
                            `<p>[</p>
                            <p style='margin-left:30px'> { _id: 58fdbf5c0ef8a50b4cdd9a80 , employee_name: "Sudhir", email: "sudhir@gmail.com", address: "8341 Murphy Forges Suite 858" },</p>
                            <p style='margin-left:30px'> { _id: 58fdbf5c0ef8a50b4cdd9a81, employee_name: "Pradip", email: "pradip@gmail.com", address: "783 Wiza Forks" },</p>
                            <p style='margin-left:30px'> { _id: 58fdbf5c0ef8a50b4cdd9a82, employee_name: "Rachel", email: "rachel@gmail.com", address: "59697 Daugherty Mills" },</p>
                            <p style='margin-left:30px'> { _id: 58fdbf5c0ef8a50b4cdd9a83, employee_name: "Samantha", email: "samantha@gmail.com", address: "5871 Toney Groves" },</p>
                            <p>]</p>`
                    }}>
                    </div>
                </div>
                <p>Let's do an example to see how we update all document whoose employee_name is start with "S".</p>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html:
                            `<p><span class='color-blue'>var</span> MongoClient = <span class='color-yellow'>require</span>(<span class='color-green'>'mongodb'</span>).MongoClient;</p>
                            <p><span class='color-blue'>var</span> url = <span class='color-green'>"mongodb://127.0.0.1:27017/"</span>;</p>
                            <br />
                            <p>MongoClient.<span class='color-red'>connect</span>(<span class='color-blue'>url</span>, function(err, db) {</p>
                            <p style="margin-left:30px">  <span class='color-blue'>if</span> (err) <span class='color-blue'>throw</span> err;</p>
                            <p style="margin-left:30px">  <span class='color-blue'>var</span> dbo = db.<span class='color-red'>db</span>(<span class='color-green'>"mymongodbdatabase"</span>);</p>
                            <p style="margin-left:30px">  <span class='color-blue'>var</span> myquery = { <span class='color-pink'>employee_name</span>: /^S/ };</p>
                            <p style="margin-left:30px">  <span class='color-blue'>var</span> newvalues = {$set: {<span class='color-pink'>address</span>: <span class='color-green'>"92399 Larson Trail"</span>} };</p>
                            <p style="margin-left:30px">  dbo.<span class='color-red'>collection</span>(<span class='color-green'>"employees"</span>).<span class='color-red'>updateMany</span>(myquery, newvalues, function(<span class='color-blue'>err</span>, res) {</p>
                            <p style="margin-left:60px">    <span class='color-blue'>if</span> (err) <span class='color-blue'>throw</span> err;</p>
                            <p style="margin-left:60px">    <span class='color-pink'>console</span>.<span class='color-red'>log</span>(res.result.nModified + " document(s) updated");</p>
                            <p style="margin-left:60px">    db.<span class='color-red'>close</span>();</p>
                            <p style="margin-left:30px">  });</p>
                            <p>});</p>`
                    }}>
                    </div>
                </div>
                <p>Which will give you this result:</p>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html:
                            `<p> 2 document(s) updated</p>`
                    }}>
                    </div>
                </div>
            </div>
            <div className='mt-4'>
                <h5>Using `updateOne()`</h5>
                <p>Using `updateOne()`, it will update only the first document that matches the condition.</p>
                <p>Let's do an example to see how we update document whoose employee_name is start with "S".</p>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html:
                            `<p><span class='color-blue'>var</span> MongoClient = <span class='color-yellow'>require</span>(<span class='color-green'>'mongodb'</span>).MongoClient;</p>
                            <p><span class='color-blue'>var</span> url = <span class='color-green'>"mongodb://127.0.0.1:27017/"</span>;</p>
                            <br />
                            <p>MongoClient.<span class='color-red'>connect</span>(<span class='color-blue'>url</span>, function(err, db) {</p>
                            <p style="margin-left:30px">  <span class='color-blue'>if</span> (err) <span class='color-blue'>throw</span> err;</p>
                            <p style="margin-left:30px">  <span class='color-blue'>var</span> dbo = db.<span class='color-red'>db</span>(<span class='color-green'>"mymongodbdatabase"</span>);</p>
                            <p style="margin-left:30px">  <span class='color-blue'>var</span> myquery = { <span class='color-pink'>employee_name</span>: /^S/ };</p>
                            <p style="margin-left:30px">  <span class='color-blue'>var</span> newvalues = {$set: {<span class='color-pink'>address</span>: <span class='color-green'>"92399 Larson Trail"</span>} };</p>
                            <p style="margin-left:30px">  dbo.<span class='color-red'>collection</span>(<span class='color-green'>"employees"</span>).<span class='color-red'>updateOne</span>(myquery, newvalues, function(<span class='color-blue'>err</span>, res) {</p>
                            <p style="margin-left:60px">    <span class='color-blue'>if</span> (err) <span class='color-blue'>throw</span> err;</p>
                            <p style="margin-left:60px">    <span class='color-pink'>console</span>.<span class='color-red'>log</span>(res.result.nModified + " document(s) updated");</p>
                            <p style="margin-left:60px">    db.<span class='color-red'>close</span>();</p>
                            <p style="margin-left:30px">  });</p>
                            <p>});</p>`
                    }}>
                    </div>
                </div>
                <p>Which will give you this result:</p>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html:
                            `<p> 1 document updated</p>`
                    }}>
                    </div>
                </div>
            </div>
        </section>
    )
}
