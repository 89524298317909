import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/mysql/cross-join";

export default function MysqlCrossJoin() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Mysql - Cross Join | Aspirant's Home");
        const urls = {
            'previous': '/mysql/right-join',
            'next': '/mysql/full-join'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>Mysql - Cross Join</h3>
            <div className='mt-4 mb-5'>
                <p>
                    A <strong>`CROSS JOIN`</strong>, also known as a Cartesian join, is used to combine each row from one table with each row from another table. It produces a result set where the number of rows is the product of the number of rows in each table.
                </p>
                <p>
                    The basic syntax of a <strong>`CROSS JOIN`</strong> is as follows:
                </p>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class='color-blue'>SELECT</span> * <span class='color-blue'>FROM</span> table1 <span class='color-blue'>CROSS JOIN</span> table2;</p>
                        `
                    }}></div>
                </div>
                <p>
                    Suppose, we have two table, <strong>`employees`</strong> and <strong>`departments`</strong> table like below -
                </p>
                <div className='row'>
                    <div className='col-6'>
                        <h6>Table `employees`</h6>
                        <table className='table' style={{border: '1px dashed #ccc'}}> 
                            <thead>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>id</th>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>name</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>1</td>
                                    <td style={{border: '1px dashed #ccc'}}>Jane Smith</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>2</td>
                                    <td style={{border: '1px dashed #ccc'}}>John Doe</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className='col-6'>
                        <h6>Table `departments`</h6>
                        <table className='table' style={{border: '1px dashed #ccc'}}> 
                            <thead>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>department_id</th>
                                    <th style={{ border: '1px dashed #ccc', backgroundColor: '#e1f1ff' }}>department_name</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>1</td>
                                    <td style={{ border: '1px dashed #ccc' }}>IT</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>2</td>
                                    <td style={{ border: '1px dashed #ccc' }}>Accountant</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <p>
                    If we perform a <strong>`CROSS JOIN`</strong> between these two tables, the result set will contain all possible combinations of rows:
                </p>
                <p>
                    The Output of the query will be the following -
                </p>
                <table className='table' style={{border: '1px dashed #ccc'}}> 
                    <thead>
                        <tr style={{border: '1px dashed #ccc'}}>
                            <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>id</th>
                            <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>name</th>
                            <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>department_id</th>
                            <th style={{ border: '1px dashed #ccc', backgroundColor: '#e1f1ff' }}>department_name</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr style={{ border: '1px dashed #ccc' }}>
                            <td style={{border: '1px dashed #ccc'}}>1</td>
                            <td style={{border: '1px dashed #ccc'}}>Jane Smith</td>
                            <td style={{border: '1px dashed #ccc'}}>1</td>
                            <td style={{ border: '1px dashed #ccc' }}>IT</td>
                        </tr>
                        <tr style={{ border: '1px dashed #ccc' }}>
                            <td style={{border: '1px dashed #ccc'}}>1</td>
                            <td style={{border: '1px dashed #ccc'}}>Jane Smith</td>
                            <td style={{border: '1px dashed #ccc'}}>2</td>
                            <td style={{ border: '1px dashed #ccc' }}>Accountant</td>
                        </tr>
                        <tr style={{ border: '1px dashed #ccc' }}>
                            <td style={{border: '1px dashed #ccc'}}>2</td>
                            <td style={{border: '1px dashed #ccc'}}>John Doe</td>
                            <td style={{border: '1px dashed #ccc'}}>1</td>
                            <td style={{ border: '1px dashed #ccc' }}>IT</td>
                        </tr>
                        <tr style={{ border: '1px dashed #ccc' }}>
                            <td style={{border: '1px dashed #ccc'}}>2</td>
                            <td style={{border: '1px dashed #ccc'}}>John Doe</td>
                            <td style={{border: '1px dashed #ccc'}}>2</td>
                            <td style={{ border: '1px dashed #ccc' }}>Accountant</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </section>
    )
}