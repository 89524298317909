import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/mysql/left-join";

export default function MysqlLeftJoin() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Mysql - Left Join | Aspirant's Home");
        const urls = {
            'previous': '/mysql/inner-join',
            'next': '/mysql/right-join'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>Mysql - Left Join</h3>
            <div className='mt-4 mb-5'>
                <p>
                    In MySQL, the <strong>`LEFT JOIN`</strong> keyword is used to return all records from the left table (table1), and the matched records from the right table (table2). The result is NULL from the right side if there is no match.
                </p>
                <p>
                    Following is the basic syntax of MySQL <strong>`Left Join`</strong> −
                </p>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-blue">SELECT</span> columns</p>
                            <p><span class="color-blue">FROM</span> table1</p>
                            <p><span class="color-blue">LEFT JOIN</span> table2</p>
                            <p><span class="color-blue">ON</span> table1.column_name = table2.column_name;</p>
                        `
                    }}></div>
                </div>
                <p>
                    Where, <strong>`columns`</strong> is the columns you want to retrieve. <strong>`table1, table2`</strong> are the tables you're joining. <strong>`column_name`</strong> is the column(s) used to join the tables.
                </p>
                <p>
                    Suppose, we have two table, <strong>`employees`</strong> and <strong>`departments`</strong> table like below -
                </p>
                <div className='row'>
                    <div className='col-6'>
                        <h6>Table `employees`</h6>
                        <table className='table' style={{border: '1px dashed #ccc'}}> 
                            <thead>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>id</th>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>name</th>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>department_id</th>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>salary</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>1</td>
                                    <td style={{border: '1px dashed #ccc'}}>Jane Smith</td>
                                    <td style={{border: '1px dashed #ccc'}}>1</td>
                                    <td style={{border: '1px dashed #ccc'}}>500000</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>2</td>
                                    <td style={{border: '1px dashed #ccc'}}>John Doe</td>
                                    <td style={{border: '1px dashed #ccc'}}>5</td>
                                    <td style={{border: '1px dashed #ccc'}}>350000</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>3</td>
                                    <td style={{border: '1px dashed #ccc'}}>Johny Smith</td>
                                    <td style={{border: '1px dashed #ccc'}}>2</td>
                                    <td style={{border: '1px dashed #ccc'}}>50000</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>4</td>
                                    <td style={{border: '1px dashed #ccc'}}>Jeffer Joe</td>
                                    <td style={{border: '1px dashed #ccc'}}>3</td>
                                    <td style={{border: '1px dashed #ccc'}}>380000</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className='col-6'>
                        <h6>Table `departments`</h6>
                        <table className='table' style={{border: '1px dashed #ccc'}}> 
                            <thead>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>id</th>
                                    <th style={{ border: '1px dashed #ccc', backgroundColor: '#e1f1ff' }}>department_name</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>1</td>
                                    <td style={{ border: '1px dashed #ccc' }}>IT</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>2</td>
                                    <td style={{ border: '1px dashed #ccc' }}>Accountant</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>3</td>
                                    <td style={{ border: '1px dashed #ccc' }}>HR Manager</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>4</td>
                                    <td style={{ border: '1px dashed #ccc' }}>Receptionist</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <p>
                    If we perform a <strong>`LEFT JOIN`</strong> between these two tables based on the <strong>`department_id`</strong> in the <strong>`employees`</strong> table and the <strong>`id`</strong> in the <strong>`departments`</strong> table -
                </p>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-blue">SELECT</span> employees.name, departments.department_name</p>
                            <p><span class="color-blue">FROM</span> employees</p>
                            <p><span class="color-blue">LEFT JOIN</span> departments</p>
                            <p><span class="color-blue">ON</span> employees.department_id = departments.id;</p>
                        `
                    }}></div>
                </div>
                <p>
                    The Output of the query will be the following -
                </p>
                <table className='table' style={{border: '1px dashed #ccc'}}> 
                    <thead>
                        <tr style={{border: '1px dashed #ccc'}}>
                            <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>name</th>
                            <th style={{ border: '1px dashed #ccc', backgroundColor: '#e1f1ff' }}>department_name</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr style={{border: '1px dashed #ccc'}}>
                            <td style={{border: '1px dashed #ccc'}}>Jane Smith</td>
                            <td style={{border: '1px dashed #ccc'}}>IT</td>
                        </tr>
                        <tr style={{border: '1px dashed #ccc'}}>
                            <td style={{border: '1px dashed #ccc'}}>John Doe</td>
                            <td style={{border: '1px dashed #ccc'}}><span className='color-blue'>NULL</span></td>
                        </tr>
                        <tr style={{border: '1px dashed #ccc'}}>
                            <td style={{border: '1px dashed #ccc'}}>Johny Smith</td>
                            <td style={{border: '1px dashed #ccc'}}>Accountant</td>
                        </tr>
                        <tr style={{border: '1px dashed #ccc'}}>
                            <td style={{border: '1px dashed #ccc'}}>Jeffer Joe</td>
                            <td style={{border: '1px dashed #ccc'}}>HR Manager</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </section>
    )
}