import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../../Context";
import { Link } from 'react-router-dom';
export const pageUrl = () => "/mysql/numeric-functions/abs";

export default function ABS() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Mysql - Numeric Functions - ABS(x) | Aspirant's Home");
        path.setPreviousNext({});
    }, [])

    return (
        <>
            <section class='clearfix mb-3'>
                <span className='float-start'>
                    <Link to='/mysql/numeric-functions' onClick={() => path.setPathName('/mysql/numeric-functions')} className='btn btn-primary'> <i class="fa-solid fa-less-than"></i>&nbsp; Back to Numeric Functions </Link>
                </span>
            </section>
            <section className='mt-5 mb-5'>
                <h3>Mysql - ABS(x) Function</h3>
                <div className='mt-4 mb-5'>
                    <p>
                        The <strong>`ABS()`</strong> function in MySQL is used to return the absolute (positive) value of a number. It effectively removes the negative sign from a negative number, leaving the positive value unchanged.
                    </p>
                    <p>
                        Here is a simple example using the <strong>`ABS()`</strong> function:
                    </p>
                    <div className='codePalateBox mt-2 mb-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                                <p><span class="color-blue">SELECT</span> ABS(<span class="color-pink">-5</span>);</p>
                            `
                        }}></div>
                    </div>
                    <p>
                        This query will return <strong>`5`</strong>, as the absolute value of <strong>`-5`</strong> is <strong>`5`</strong>.
                    </p>
                </div>
                <div className='mt-5 mb-5'>
                    <h5>ABS(x) with MySQL Table</h5>
                    <div className='mt-3 mb-2'>
                        <p>
                            Consider a table <strong>`transactions`</strong> with columns <strong>`transaction_id`</strong> and <strong>`amount`</strong>.
                        </p>
                        <table className='table' style={{border: '1px dashed #ccc'}}> 
                            <thead>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>transaction_id</th>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>amount</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>1</td>
                                    <td style={{border: '1px dashed #ccc'}}>500.00</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>2</td>
                                    <td style={{border: '1px dashed #ccc'}}>-200.00</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>3</td>
                                    <td style={{border: '1px dashed #ccc'}}>300.00</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>4</td>
                                    <td style={{border: '1px dashed #ccc'}}>-150.00</td>
                                </tr>
                            </tbody>
                        </table>
                        <p>
                            If you want to retrieve the absolute value of the <strong>`amount`</strong> for each transaction, you can use the ABS function as follows:
                        </p>
                        <div className='codePalateBox mt-2 mb-2'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-blue">SELECT</span> transaction_id, amount, ABS(amount) <span class="color-blue">AS</span> absolute_amount <span class="color-blue">FROM</span> transactions;</p>
                                `
                            }}></div>
                        </div>
                        <p>
                            This query will return:
                        </p>
                        <table className='table' style={{border: '1px dashed #ccc'}}> 
                            <thead>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>transaction_id</th>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>amount</th>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>absolute_amount</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>1</td>
                                    <td style={{border: '1px dashed #ccc'}}>500.00</td>
                                    <td style={{border: '1px dashed #ccc'}}>500.00</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>2</td>
                                    <td style={{border: '1px dashed #ccc'}}>-200.00</td>
                                    <td style={{border: '1px dashed #ccc'}}>200.00</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>3</td>
                                    <td style={{border: '1px dashed #ccc'}}>300.00</td>
                                    <td style={{border: '1px dashed #ccc'}}>300.00</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>4</td>
                                    <td style={{border: '1px dashed #ccc'}}>-150.00</td>
                                    <td style={{border: '1px dashed #ccc'}}>150.00</td>
                                </tr>
                            </tbody>
                        </table>
                        <p>
                            In this example, the ABS function is used to calculate the absolute value of the <strong>`amount`</strong> column. The <strong>`ABS(amount)`</strong> expression returns the absolute value of the <strong>`amount`</strong> for each row.
                        </p>
                    </div>
                </div>
            </section>
        </>
    )
}