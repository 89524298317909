import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/mongo-db/one-to-one-relations";

export default function MongoOneToOne() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("One to One Relations in MongoDB Documents");
        title.setKeyWords("one to one relations in mongodb, one to one relation in mongodb, mongodb one-to-one relationship example, mongodb one to one relationship, one-to-one relationship in database example, one to one relations, db one to one relationship, relations mongodb");
        title.setPageDescription("In MongoDB, a One to One relationship means that one document in a collection is related to exactly one document in another collection. There are two main ways to represent a one-to-one relationship: embedding and referencing");
        const urls = {
            'previous': '/mongo-db/document-relations',
            'next': '/mongo-db/one-to-many-relations'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>MongoDB - One to One Relations</h3>
            <div className='mt-4 mb-5'>
                <p>
                    In MongoDB, a One to One relationship means that one document in a collection is related to exactly one document in another collection. There are two main ways to represent a one-to-one relationship: <strong>embedding</strong> and <strong>referencing</strong>.
                </p>
                <div className='mt-3 myImage'>
                    <img className='img-fluid' src='../assets/mongodb/one-to-one-relation.png' />
                </div>
                <p>
                    Consider you have two entities: <strong>`User`</strong> and <strong>`Profile`</strong>. Each user has a unique profile.
                </p>
                <h5 className='mt-5 mb-3'>Embedded Document Structure</h5>
                <p>
                    You can embed the <strong>`Profile`</strong> document directly within the <strong>`User`</strong> document to represent the one-to-one relationship.
                </p>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>{</p>
                            <p style="margin-left:30px">    <span class="color-pink">"_id"</span>: ObjectId(<span class="color-green">"507f191e810c19729de860ea"</span>),</p>
                            <p style="margin-left:30px">    <span class="color-pink">"name"</span>: <span class="color-green">"John Doe"</span>,</p>
                            <p style="margin-left:30px">    <span class="color-pink">"age"</span>: <span class="color-pink">29</span>,</p>
                            <p style="margin-left:30px">    <span class="color-pink">"profile"</span>: {</p>
                            <p style="margin-left:60px">    <span class="color-pink">"bio"</span>: <span class="color-green">"Software developer with 10 years of experience.",</p>
                            <p style="margin-left:60px">    <span class="color-pink">"website"</span>: <span class="color-green">"https://johndoe.com"</span>,</p>
                            <p style="margin-left:60px">    <span class="color-pink">"location"</span>: <span class="color-green">"San Francisco, CA"</span></p>
                            <p style="margin-left:30px">    }</p>
                            <p>}</p>
                        `
                    }}></div>
                </div>
                <p>
                    By embedding the <strong>`Profile`</strong> within the <strong>`User`</strong> document, MongoDB allows you to efficiently manage and retrieve related data in a single, cohesive document structure.
                </p>
                <div className='featureClass'>
                    <p>Embedding is ideal for one-to-one relationships where:</p>
                    <ul style={{listStyle:'disc'}}>
                        <li>The embedded document is small and won't grow indefinitely.</li>
                        <li>The relationship is straightforward, and the data is always accessed together.</li>
                    </ul>
                </div>
                <h5 className='mt-5 mb-3'>Document Structure with Referencing</h5>
                <p>
                    Each user has a unique profile, but instead of embedding the profile directly in the user document, you store them in separate collections and link them using references.
                </p>
                <h6>User Document</h6>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>{</p>
                            <p style="margin-left:30px">    <span class="color-pink">"_id"</span>: ObjectId(<span class="color-green">"507f191e810c19729de860ea"</span>),</p>
                            <p style="margin-left:30px">    <span class="color-pink">"name"</span>: <span class="color-green">"John Doe"</span>,</p>
                            <p style="margin-left:30px">    <span class="color-pink">"age"</span>: <span class="color-pink">29</span>,</p>
                            <p style="margin-left:30px">    <span class="color-pink">"profileId"</span>: ObjectId(<span class="color-green">"507f191e810c19729de860eb"</span>)</p>
                            <p>}</p>
                        `
                    }}></div>
                </div>
                <h6>Profile Document</h6>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>{</p>
                            <p style="margin-left:30px">    <span class="color-pink">"_id"</span>: ObjectId(<span class="color-green">"507f191e810c19729de860eb"</span>),</p>
                            <p style="margin-left:30px">    <span class="color-pink">"bio"</span>: <span class="color-green">"Software developer with 10 years of experience."</span>,</p>
                            <p style="margin-left:30px">    <span class="color-pink">"website"</span>: <span class="color-green">"https://johndoe.com"</span>,</p>
                            <p style="margin-left:30px">    <span class="color-pink">"location"</span>: <span class="color-green">"San Francisco, CA"</span></p>
                            <p>}</p>
                        `
                    }}></div>
                </div>
                <div className='mt-4 featureClass'>
                    <p>Referencing is ideal for one-to-one relationships when:</p>
                    <ul style={{listStyle:'disc'}}>
                        <li>The documents are large or likely to grow significantly.</li>
                        <li>The relationship is complex, and the related data needs to be managed or queried separately.</li>
                        <li>There are different access patterns, and you don't always need to retrieve both sets of data together.</li>
                    </ul>
                </div>
            </div>
        </section>
    )
}