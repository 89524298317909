import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/mysql/create-views";

export default function MysqlCreateViews() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Mysql - Create Views | Aspirant's Home");
        const urls = {
            'previous': '/mysql/replace-query',
            'next': '/mysql/update-views'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>Mysql - Create Views</h3>
            <div className='mt-4 mb-5'>
                <p>
                    In MySQL, a view is a virtual table that is based on the result set of a <strong>`SELECT`</strong> query. Views allow you to simplify complex queries and encapsulate logic, making it easier to work with the database. Here's how you can create a view:
                </p>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-blue">CREATE VIEW</span> view_name <span class="color-blue">AS</span></p>
                            <p><span class="color-blue">SELECT</span> column1, column2, ...</p>
                            <p><span class="color-blue">FROM</span> table_name</p>
                            <p><span class="color-blue">WHERE</span> condition;</p>
                        `
                    }}></div>
                </div>
                <p>
                    Where, <strong>`view_name`</strong> is the name of the view you want to create. <strong>`column1, column2, ...`</strong> are the columns you want to include in the view. <strong>`table_name`</strong> is the name of the table from which you want to create the view and <strong>`condition`</strong> is an optional condition to filter the rows in the view.
                </p>
                <p>
                    Suppose, we have a <strong>`users`</strong> table like below -
                </p>
                <table className='table' style={{border: '1px dashed #ccc'}}> 
                    <thead>
                        <tr style={{border: '1px dashed #ccc'}}>
                            <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>id</th>
                            <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>name</th>
                            <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>age</th>
                            <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>Address</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr style={{border: '1px dashed #ccc'}}>
                            <td style={{border: '1px dashed #ccc'}}>1</td>
                            <td style={{border: '1px dashed #ccc'}}>Jane Smith</td>
                            <td style={{border: '1px dashed #ccc'}}>25</td>
                            <td style={{border: '1px dashed #ccc'}}>Apt. 415 9295 Anh Harbor, South Junior, FL 51309-5804</td>
                        </tr>
                        <tr style={{border: '1px dashed #ccc'}}>
                            <td style={{border: '1px dashed #ccc'}}>2</td>
                            <td style={{border: '1px dashed #ccc'}}>John Doe</td>
                            <td style={{border: '1px dashed #ccc'}}>35</td>
                            <td style={{border: '1px dashed #ccc'}}>Suite 977 83408 Murray Lakes, Lakinchester, WV 60583</td>
                        </tr>
                        <tr style={{border: '1px dashed #ccc'}}>
                            <td style={{border: '1px dashed #ccc'}}>3</td>
                            <td style={{border: '1px dashed #ccc'}}>Johny Smith</td>
                            <td style={{border: '1px dashed #ccc'}}>39</td>
                            <td style={{border: '1px dashed #ccc'}}>3797 Benton Locks, Weissnatside, MS 54762-5660</td>
                        </tr>
                        <tr style={{border: '1px dashed #ccc'}}>
                            <td style={{border: '1px dashed #ccc'}}>4</td>
                            <td style={{border: '1px dashed #ccc'}}>Jeffer Joe</td>
                            <td style={{border: '1px dashed #ccc'}}>28</td>
                            <td style={{border: '1px dashed #ccc'}}>Suite 715 6587 Champlin Freeway, Port Angelhaven, PA 42789</td>
                        </tr>
                    </tbody>
                </table>

                <p>
                    Now, we want to create a <strong>`View`</strong> whose age is less than equal to <strong>`30`</strong>. Then the syntax will be the following -
                </p>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-blue">CREATE VIEW</span> youngster <span class="color-blue">AS</span></p>
                            <p><span class="color-blue">SELECT</span> * FROM users</p>
                            <p><span class="color-blue">WHERE</span> age <= <span class="color-pink">30</span>;</p>
                        `
                    }}></div>
                </div>
                <p>
                    The Output of the query will be the following -
                </p>
                <table className='table' style={{border: '1px dashed #ccc'}}> 
                    <thead>
                        <tr style={{border: '1px dashed #ccc'}}>
                            <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>id</th>
                            <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>name</th>
                            <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>age</th>
                            <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>Address</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr style={{border: '1px dashed #ccc'}}>
                            <td style={{border: '1px dashed #ccc'}}>1</td>
                            <td style={{border: '1px dashed #ccc'}}>Jane Smith</td>
                            <td style={{border: '1px dashed #ccc'}}>25</td>
                            <td style={{border: '1px dashed #ccc'}}>Apt. 415 9295 Anh Harbor, South Junior, FL 51309-5804</td>
                        </tr>
                        <tr style={{border: '1px dashed #ccc'}}>
                            <td style={{border: '1px dashed #ccc'}}>2</td>
                            <td style={{border: '1px dashed #ccc'}}>Jeffer Joe</td>
                            <td style={{border: '1px dashed #ccc'}}>28</td>
                            <td style={{border: '1px dashed #ccc'}}>Suite 715 6587 Champlin Freeway, Port Angelhaven, PA 42789</td>
                        </tr>
                    </tbody>
                </table>

                <p>
                    After creating the view, you can query it like a regular table:
                </p>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-blue">SELECT</span> * <span class="color-blue">FROM</span> youngster;</p>
                        `
                    }}></div>
                </div>
            </div>
        </section>
    )
}