import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/mongo-db/document-relations";

export default function MongoRelations() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("A Complete Understanding of Relations in MongoDB");
        title.setKeyWords("relation between documents, embedding document in mongodb, referencing document in mongodb, what is embedding document and referencing document, what is embedding document in mongodb, what is referencing documents in mongodb");
        title.setPageDescription("In MongoDB, relationships between documents can be represented in two main ways: embedding and referencing. These methods are used to manage relationships similar to how foreign keys are used in relational databases.");
        const urls = {
            'previous': '/mongo-db/delete-document',
            'next': '/mongo-db/one-to-one-relations'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>MongoDB - Understanding of Relations</h3>
            <div className='mt-4 mb-5'>
                <p>
                    In MongoDB, relationships between documents can be represented in two main ways: <strong>embedding</strong> and <strong>referencing</strong>. These methods are used to manage relationships similar to how foreign keys are used in relational databases.
                </p>
                <h5 className='mt-5 mb-4'>What is Embedding Documents ?</h5>
                <p>
                    Embedding means storing related data within the same document. This is useful when you need to keep related data together and want to avoid the need for complex joins.
                </p>
                <p>Below is an example of <strong>Embedding an Address in a User Document</strong> </p>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>{</p>
                            <p style="margin-left:30px">    <span class="color-pink">"_id"</span>: ObjectId(<span class="color-green">"507f1f77bcf86cd799439011"</span>),</p>
                            <p style="margin-left:30px">    <span class="color-pink">"name"</span>: <span class="color-green">"John Doe"</span>,</p>
                            <p style="margin-left:30px">    <span class="color-pink">"age"</span>: <span class="color-pink">29</span>,</p>
                            <p style="margin-left:30px">    <span class="color-pink">"address"</span>: {</p>
                            <p style="margin-left:60px">       <span class="color-pink"> "street"</span>: <span class="color-green">"123 Main St"</span>,</p>
                            <p style="margin-left:60px">       <span class="color-pink"> "city"</span>: <span class="color-green">"Anytown"</span>,</p>
                            <p style="margin-left:60px">        <span class="color-pink">"state"</span>: <span class="color-green">"CA"</span></p>
                            <p style="margin-left:30px">    }</p>
                            <p>}</p>
                        `
                    }}></div>
                </div>
                <p>
                    In this example, <strong>address</strong> type is an Embedded Document. This is a nested document that contains the address details for <strong>`John Doe`</strong>. It includes:
                </p>
                <ul>
                    <li><strong>street</strong>: <strong>`"123 Main St"`</strong></li>
                    <li><strong>city</strong>: <strong>`"Anytown"`</strong></li>
                    <li><strong>state</strong>: <strong>`"CA"`</strong></li>
                </ul>
                <h5 className='mt-5 mb-4'>What is Referencing Documents ?</h5>
                <p>
                    Referencing means storing related data in separate documents and using references to link them. This is useful when the data is large or when it’s updated independently.
                </p>
                <p>Lets see, how we <strong>Referencing an Address in a User Document</strong></p>
                <ul style={{ listStyle: 'decimal' }}>
                    <li>
                        <strong>User Document</strong>
                        <div className='codePalateBox mt-2 mb-2'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p>{</p>
                                    <p style="margin-left:30px">    <span class="color-pink">"_id"</span>: ObjectId(<span class="color-green">"507f1f77bcf86cd799439011"</span>),</p>
                                    <p style="margin-left:30px">    <span class="color-pink">"name"</span>: <span class="color-green">"John Doe"</span>,</p>
                                    <p style="margin-left:30px">    <span class="color-pink">"age"</span>: <span class="color-pink">29</span>,</p>
                                    <p style="margin-left:30px">    <span class="color-pink">"addressId"</span>: ObjectId(<span class="color-green">"507f191e810c19729de860ea"</span>)</p>
                                    <p>}</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <strong>Address Document</strong>
                        <div className='codePalateBox mt-2 mb-2'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p>{</p>
                                    <p style="margin-left:30px">    <span class="color-pink">"_id"</span>: ObjectId(<span class="color-green">"507f191e810c19729de860ea"</span>),</p>
                                    <p style="margin-left:30px">    <span class="color-pink">"street"</span>: <span class="color-green">"123 Main St"</span>,</p>
                                    <p style="margin-left:30px">    <span class="color-pink">"city"</span>: <span class="color-green">"Anytown"</span>,</p>
                                    <p style="margin-left:30px">    <span class="color-pink">"state"</span>: <span class="color-green">"CA"</span></p>
                                    <p>}</p>
                                `
                            }}></div>
                        </div>
                    </li>
                </ul>
                <p>
                    In this example, you can see in <strong>User Document</strong> there is a reference id of <strong>Address Document</strong>.
                </p>
            </div>
        </section>
    )
}