import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/mysql/grant-privileges";

export default function MysqlChangePassword() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Mysql - Grant Privileges To User | Aspirant's Home");
        const urls = {
            'previous': '/mysql/change-password',
            'next': '/mysql/show-privileges'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>Mysql - Grant Privileges To User</h3>
            <div className='mt-4 mb-5'>
                <p>
                    To grant privileges to a MySQL user account, you can use the <strong>`GRANT`</strong> statement. This statement allows you to specify the privileges you want to grant and the database objects (like tables, columns, etc.) on which you want to grant those privileges. Here's the basic syntax:
                </p>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-blue">GRANT</span> privileges <span class="color-blue">ON</span> database_name.table_name <span class="color-blue">TO</span> <span class="color-green">'username'</span>@<span class="color-green">'host'</span>;</p>
                        `
                    }}></div>
                </div>
                <ul>
                    <li>
                        <strong>`privileges:`</strong> The privileges you want to grant. These can include <strong>`SELECT`</strong>, <strong>`INSERT`</strong>, <strong>`UPDATE`</strong>, <strong>`DELETE`</strong>, <strong>`CREATE`</strong>, <strong>`DROP`</strong>, <strong>`ALL PRIVILEGES`</strong>, and more.
                    </li>
                    <li>
                        <strong>`database_name:`</strong> The name of the database to which the privileges apply. You can use <strong>`*`</strong> to grant privileges to all databases.
                    </li>
                    <li>
                        <strong>`table_name:`</strong> The name of the table to which the privileges apply. You can use <strong>`*`</strong> to grant privileges to all tables.
                    </li>
                    <li>
                        <strong>`'username'@'host':`</strong> The username and host of the user account to which you want to grant privileges.
                    </li>
                </ul>
                <p>
                    For example, to grant all privileges on all tables in a database named <strong>`mydatabase`</strong> to a user named <strong>`myuser`</strong> connecting from <strong>`localhost`</strong>, you would use the following SQL statement:
                </p>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-blue">GRANT ALL</span> PRIVILEGES <span class="color-blue">ON</span> mydatabase.* <span class="color-blue">TO</span> <span class="color-green">'myuser'</span>@<span class="color-green">'localhost'</span>;</p>
                        `
                    }}></div>
                </div>
                <p>
                    After granting the privileges, you should flush privileges to apply the changes:
                </p>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>FLUSH PRIVILEGES;</p>
                        `
                    }}></div>
                </div>
                <p>
                    <strong>`FLUSH PRIVILEGES`</strong> is used to refresh the user privileges so that any changes made to the user accounts are applied to the system. The Flush Privileges statement reloads the grant tables privileges, ensuring that any changes made to user permissions are immediately applied without requiring a restart of the MySQL server.
                </p>
            </div>
        </section>
    )
}