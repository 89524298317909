import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../../Context";
import { Link } from 'react-router-dom';
export const pageUrl = () => "/mysql/date-and-time-functions/addtime";

export default function AddTime() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Mysql - Date and Time functions - ADDTIME() | Aspirant's Home");
        path.setPreviousNext({});
    }, [])

    return (
        <>
            <section class='clearfix mb-3'>
                <span className='float-start'>
                    <Link to='/mysql/date-and-time-functions' onClick={() => path.setPathName('/mysql/date-and-time-functions')} className='btn btn-primary'> <i class="fa-solid fa-less-than"></i>&nbsp; Back to Date and Time functions </Link>
                </span>
            </section>
            <section className='mt-5 mb-5'>
                <h3>Mysql - ADDTIME() Functions</h3>
                <div className='mt-4 mb-5'>
                    <p>
                        The <strong>`ADDTIME()`</strong> function in MySQL is used to add a time value to a datetime or time value. It takes two arguments: the datetime or time value to which you want to add time, and the time value you want to add. The time value can be specified in various formats, such as <strong>`'HH:MM:SS'`</strong>, <strong>`'HH:MM'`</strong>, or just <strong>`'SS'`</strong> for seconds.
                    </p>
                    <p>
                        Here's the basic syntax of the <strong>`ADDTIME()`</strong> function:
                    </p>
                    <div className='codePalateBox mt-2 mb-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                                <p>ADDTIME(datetime_or_time_value, time_value_to_add)</p>
                            `
                        }}></div>
                    </div>
                    <p>
                        Where <strong>`datetime_or_time_value`</strong> is the datetime or time value to which you want to add time, and <strong>`time_value_to_add`</strong> is the time value you want to add.
                    </p>
                    <p>
                        For example, if you have a datetime value <strong>`'2022-05-16 12:00:00'`</strong> and you want to add 3 hours and 30 minutes to it, you can use <strong>`ADDTIME()`</strong> like this:
                    </p>
                    <div className='codePalateBox mt-2 mb-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                                <p><span class="color-blue">SELECT</span> ADDTIME(<span class="color-green">'2022-05-16 12:00:00'</span>, <span class="color-green">'3:30:00'</span>);</p>
                            `
                        }}></div>
                    </div>
                    <p>
                        This will return <strong>`'2022-05-16 15:30:00'`</strong>, which is <strong>`3`</strong> hours and <strong>`30`</strong> minutes added to the original datetime value.
                    </p>
                </div>
                <div className='mt-5 mb-5'>
                    <h5>ADDTIME() with MySQL Table</h5>
                    <div className='mt-4 mb-2'>
                        <p>
                            To use the <strong>`ADDTIME()`</strong> function in MySQL with a table, let's consider a table <strong>`appointments`</strong> with columns <strong>`id`</strong>, <strong>`appointment_time`</strong> (datetime), and <strong>`duration`</strong> (time), where <strong>`appointment_time`</strong> represents the start time of each appointment and <strong>`duration`</strong> represents the duration of each appointment. We want to calculate the end time of each appointment by adding the duration to the start time. 
                        </p>
                        <p>
                            Consider the <strong>`appointments`</strong> table:
                        </p>
                        <table className='table' style={{border: '1px dashed #ccc'}}> 
                            <thead>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>id</th>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>appointment_time</th>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>duration</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>1</td>
                                    <td style={{border: '1px dashed #ccc'}}>2022-05-16 09:00:00</td>
                                    <td style={{border: '1px dashed #ccc'}}>02:30:00</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>2</td>
                                    <td style={{border: '1px dashed #ccc'}}>2022-05-16 10:30:00</td>
                                    <td style={{border: '1px dashed #ccc'}}>01:15:00</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>3</td>
                                    <td style={{border: '1px dashed #ccc'}}>2022-05-16 12:00:00</td>
                                    <td style={{border: '1px dashed #ccc'}}>00:45:00</td>
                                </tr>
                            </tbody>
                        </table>
                        <p>
                            To calculate the end time of each appointment by adding the duration to the start time, you would use:
                        </p>
                        <div className='codePalateBox mt-2 mb-2'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-blue">SELECT</span> id, appointment_time, duration, ADDTIME(appointment_time, duration) <span class="color-blue">AS</span> end_time <span class="color-blue">FROM</span> appointments;</p>
                                `
                            }}></div>
                        </div>
                        <p>
                            This query will return:
                        </p>
                        <table className='table' style={{border: '1px dashed #ccc'}}> 
                            <thead>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>id</th>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>appointment_time</th>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>duration</th>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>end_time</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>1</td>
                                    <td style={{border: '1px dashed #ccc'}}>2022-05-16 09:00:00</td>
                                    <td style={{border: '1px dashed #ccc'}}>02:30:00</td>
                                    <td style={{border: '1px dashed #ccc'}}>2022-05-16 11:30:00</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>2</td>
                                    <td style={{border: '1px dashed #ccc'}}>2022-05-16 10:30:00</td>
                                    <td style={{border: '1px dashed #ccc'}}>01:15:00</td>
                                    <td style={{border: '1px dashed #ccc'}}>2022-05-16 11:45:00</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>3</td>
                                    <td style={{border: '1px dashed #ccc'}}>2022-05-16 12:00:00</td>
                                    <td style={{border: '1px dashed #ccc'}}>00:45:00</td>
                                    <td style={{border: '1px dashed #ccc'}}>2022-05-16 12:45:00</td>
                                </tr>
                            </tbody>
                        </table>
                        <p>
                            In this example, the <strong>`ADDTIME()`</strong> function is used to calculate the end time of each appointment by adding the <strong>`duration`</strong> to the <strong>`appointment_time`</strong>. The result is displayed as <strong>`end_time`</strong> in the result set.
                        </p>
                    </div>
                </div>
            </section>
        </>
    )
}