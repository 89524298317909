import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../../Context";
import { Link } from 'react-router-dom';
export const pageUrl = () => "/mysql/arithmetic-operators/unary-plus";

export default function UnaryPlus() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Mysql - Arithmetic Operators - Unary Plus (+) | Aspirant's Home");
        path.setPreviousNext({});
    }, [])

    return (
        <>
            <section class='clearfix mb-3'>
                <span className='float-start'>
                    <Link to='/mysql/arithmetic-operators' onClick={() => path.setPathName('/mysql/arithmetic-operators')} className='btn btn-primary'> <i class="fa-solid fa-less-than"></i>&nbsp; Back to Arithmetic Operators </Link>
                </span>
            </section>
            <section className='mt-5 mb-5'>
                <h3>Mysql - Unary Plus (+) Operator</h3>
                <div className='mt-4 mb-5'>
                    <p>
                        The unary plus operator in MySQL is used to indicate a positive value, but it doesn't change the value itself. It's essentially a no-op (no operation) since positive values are the default state of numeric values.
                    </p>
                    <p>
                        Here is a simple example using the unary plus operator:
                    </p>
                    <div className='codePalateBox mt-2 mb-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                                <p><span class="color-blue">SELECT</span> +<span class="color-pink">5</span>;</p>
                            `
                        }}></div>
                    </div>
                    <p>
                        This query will return <strong>`+5`</strong>.
                    </p>
                </div>
                <div className='mt-5 mb-5'>
                    <h5>Unary Plus (+) with MySQL Table</h5>
                    <div className='mt-3 mb-2'>
                        <p>
                            Consider a table <strong>`products`</strong> with columns <strong>`product_id`</strong>, <strong>`price_string`</strong> (a string representation of the price), and <strong>`quantity`</strong>.
                        </p>
                        <table className='table' style={{border: '1px dashed #ccc'}}> 
                            <thead>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>product_id</th>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>price_string</th>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>quantity</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>1</td>
                                    <td style={{border: '1px dashed #ccc'}}>'100'</td>
                                    <td style={{border: '1px dashed #ccc'}}>3</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>2</td>
                                    <td style={{border: '1px dashed #ccc'}}>'150.5'</td>
                                    <td style={{border: '1px dashed #ccc'}}>4</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>3</td>
                                    <td style={{border: '1px dashed #ccc'}}>'75.25'</td>
                                    <td style={{border: '1px dashed #ccc'}}>2</td>
                                </tr>
                            </tbody>
                        </table>
                        <p>
                            If you want to convert <strong>`price_string`</strong> to a numeric value and calculate the total cost (price multiplied by quantity), you can use the unary plus operator as follows:
                        </p>
                        <div className='codePalateBox mt-2 mb-2'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p><span class="color-blue">SELECT</span> product_id, price_string, quantity, +price_string <span class="color-blue">AS</span> price_numeric, (+price_string * quantity) <span class="color-blue">AS</span> total_cost <span class="color-blue">FROM</span> products;</p>
                                `
                            }}></div>
                        </div>
                        <p>
                            This query will return:
                        </p>
                        <table className='table' style={{border: '1px dashed #ccc'}}> 
                            <thead>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>product_id</th>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>price_string</th>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>quantity</th>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>price_numeric</th>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>total_cost</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>1</td>
                                    <td style={{border: '1px dashed #ccc'}}>'100'</td>
                                    <td style={{border: '1px dashed #ccc'}}>3</td>
                                    <td style={{border: '1px dashed #ccc'}}>100.00</td>
                                    <td style={{border: '1px dashed #ccc'}}>300.00</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>2</td>
                                    <td style={{border: '1px dashed #ccc'}}>'150.5'</td>
                                    <td style={{border: '1px dashed #ccc'}}>4</td>
                                    <td style={{border: '1px dashed #ccc'}}>150.50</td>
                                    <td style={{border: '1px dashed #ccc'}}>602.00</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>3</td>
                                    <td style={{border: '1px dashed #ccc'}}>'75.25'</td>
                                    <td style={{border: '1px dashed #ccc'}}>2</td>
                                    <td style={{border: '1px dashed #ccc'}}>75.25</td>
                                    <td style={{border: '1px dashed #ccc'}}>150.50</td>
                                </tr>
                            </tbody>
                        </table>
                        <p>
                            In this example, the expression <strong>`+price_string`</strong> uses the unary plus operator to convert the string <strong>`price_string`</strong> to a numeric value. The resulting numeric value is then multiplied by <strong>`quantity`</strong> to calculate the <strong>`total_cost`</strong>.
                        </p>
                    </div>
                </div>
            </section>
        </>
    )
}