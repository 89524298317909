import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/css/outline";

export default function Outline() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Outline in CSS | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/css/box/model',
            'next': '/css/outline/width'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>Outline</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The outline property in CSS is used to draw a line outside the element's border, making the element stand out. Unlike the border, the outline does not take up space and is always drawn outside the element's box, potentially overlapping other elements. Basically an outline is a line drawn outside the element's border.
                </p>
                <div class="mt-4 boxmodel">
                    <div class="outline" style={{outlineColor:'blue'}}>
                        <div class="border">
                            <div class="content"></div>
                        </div>
                    </div>
                </div>
                <h5 className="mt-5 mb-3">Outline Properties</h5>
                <p>
                    CSS has the following outline properties:
                </p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>
                        <strong>`outline-style`</strong> : It defines the style of the outline.
                    </li>
                    <li>
                        <strong>`outline-width`</strong> : It specifies the thickness of the outline. 
                    </li>
                    <li>
                        <strong>`outline-color`</strong> : It sets the color of the outline.
                    </li>
                    <li>
                        <strong>`outline-offset`</strong> : It adds space between the element's border and its outline.
                    </li>
                    <li>
                        <strong>`outline`</strong> : A shorthand property for setting the style, width, and color of the outline in a single declaration.
                    </li>
                </ul>

                <h5 className="mt-5 mb-3">CSS Outline Style</h5>
                <p>
                    The <strong>`outline-style`</strong> property specifies the style of the outline. It can take several values, similar to the border-style property. It has many predefined value -
                </p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><strong>`none`</strong>: No outline.</li>
                    <li><strong>`solid`</strong>: A single solid line.</li>
                    <li><strong>`dotted`</strong>: A series of dots.</li>
                    <li><strong>`dashed`</strong>: A series of short dashes.</li>
                    <li><strong>`double`</strong>: Two solid lines.</li>
                    <li><strong>`groove`</strong>: A 3D grooved (indented) border.</li>
                    <li><strong>`ridge`</strong>: A 3D ridged (raised) border.</li>
                    <li><strong>`inset`</strong>: A 3D inset border that makes the element appear embedded.</li>
                    <li><strong>`outset`</strong>: A 3D outset border that makes the element appear raised.</li>
                </ul>

                <h5 className="mt-5 mb-3">Examples of Outline Style</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>&lt;style&gt;</p>
                            <p class="ml-30">    p.solid { <span class="color-green">outline-style</span>: solid;}</p>
                            <p class="ml-30">    p.dotted { <span class="color-green">outline-style</span>: dotted;}</p>
                            <p class="ml-30">    p.dashed { <span class="color-green">outline-style</span>: dashed;}</p>
                            <p class="ml-30">    p.double { <span class="color-green">outline-style</span>: double;}</p>
                            <p class="ml-30">    p.groove { <span class="color-green">outline-style</span>: groove;}</p>
                            <p class="ml-30">    p.ridge { <span class="color-green">outline-style</span>: ridge;}</p>
                            <p class="ml-30">    p.inset { <span class="color-green">outline-style</span>: inset;}</p>
                            <p class="ml-30">    p.outset { <span class="color-green">outline-style</span>: outset;}</p>
                            <p>&lt;/style&gt;</p>
                            <p>&lt;p <span class="color-pink">class</span>=<span class="color-green">"solid"</span>&gt;A solid Outline.&lt;/p&gt;</p>
                            <p>&lt;p <span class="color-pink">class</span>=<span class="color-green">"dotted"</span>&gt;A dotted Outline.&lt;/p&gt;</p>
                            <p>&lt;p <span class="color-pink">class</span>=<span class="color-green">"dashed"</span>&gt;A dashed Outline.&lt;/p&gt;</p>
                            <p>&lt;p <span class="color-pink">class</span>=<span class="color-green">"double"</span>&gt;A double Outline.&lt;/p&gt;</p>
                            <p>&lt;p <span class="color-pink">class</span>=<span class="color-green">"groove"</span>&gt;A groove Outline.&lt;/p&gt;</p>
                            <p>&lt;p <span class="color-pink">class</span>=<span class="color-green">"ridge"</span>&gt;A ridge Outline.&lt;/p&gt;</p>
                            <p>&lt;p <span class="color-pink">class</span>=<span class="color-green">"inset"</span>&gt;A inset Outline.&lt;/p&gt;</p>
                            <p>&lt;p <span class="color-pink">class</span>=<span class="color-green">"outset"</span>&gt;A outset Outline.&lt;/p&gt;</p>
                        `
                    }}></div>
                </div>
                <p>
                    Output will be the following -
                </p>
                <div className='outputPalateBox mb-4 mt-3' style={{'border':'0'}}>
                    <div className='outputPalate' dangerouslySetInnerHTML={{
                        __html: `
                        <style>
                            p { outline-color: red;}
                            p.solid{outline-style: solid;}
                            p.dotted{outline-style: dotted;}
                            p.dashed{outline-style: dashed;}
                            p.double{outline-style: double;}
                            p.groove{outline-style: groove;}
                            p.ridge{outline-style: ridge;}
                            p.inset{outline-style: inset;}
                            p.outset{outline-style: outset;}
                        </style>
                        <p class="solid">A solid Outline.</p>
                        <p class="dotted">A dotted Outline.</p>
                        <p class="dashed">A dashed Outline.</p>
                        <p class="double">A double Outline.</p>
                        <p class="groove">A groove Outline.</p>
                        <p class="ridge">A ridge Outline.</p>
                        <p class="inset">A inset Outline.</p>
                        <p class="outset">A outset Outline.</p>
                        `
                    }}></div>
                </div>
            </div>
        </section>
    )
}