import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/css/border-shorthand";

export default function BorderShorthand() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Border Shorthand in CSS | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/css/border-sides',
            'next': '/css/border-rounded'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>Border Shorthand</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <strong>`border`</strong> shorthand property in CSS allows you to define the width, style, and color of an element's border in a single declaration. This can simplify your CSS code and make it more readable. The only thing you need to do is you have to maintain the order. It has a basic syntax -
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>selector {</p>
                            <p class="ml-30">    <span class="color-green">border</span>: [border-width] [border-style] [border-color];</p>
                            <p>}</p>
                        `
                    }}></div>
                </div>
                <p>
                    Where,
                </p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><strong>`border-width`</strong> specifies the width of the border (like <strong>`1px`</strong>, <strong>`2em`</strong>, <strong>`thin`</strong>, <strong>`medium`</strong>, <strong>`thick`</strong>).</li>
                    <li><strong>`border-style`</strong> Specifies the style of the border (like <strong>`solid`</strong>, <strong>`dashed`</strong>, <strong>`dotted`</strong>, <strong>`double`</strong>, <strong>`groove`</strong>, <strong>`ridge`</strong>, <strong>`inset`</strong>, <strong>`outset`</strong>, <strong>`none`</strong>, <strong>`hidden`</strong>).</li>
                    <li><strong>`border-color`</strong> Specifies the color of the border (like <strong>`color names`</strong>, <strong>`hex values`</strong>, <strong>`RGB`</strong>, <strong>`RGBA`</strong>, <strong>`HSL`</strong>, <strong>`HSLA`</strong>).</li>
                </ul>

                <h5 className="mt-5 mb-3">How to use Border Shorthand</h5>
                <p>
                    You can use the shorthand property to apply the same border to all sides of an element.
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>&lt;style&gt;</p>
                            <p class="ml-30">    .box {</p>
                            <p class="ml-60">        <span class="color-green">border</span>: <span class="color-pink">2px</span> solid magenta;</p>
                            <p class="ml-60">        <span class="color-green">padding</span>: <span class="color-pink">10px 20px</span>;</p>
                            <p class="ml-30">    }</p>
                            <p>&lt;/style&gt;</p>
                            <p>&lt;div <span class="color-pink">class</span>=<span class="color-green">"box"</span>&gt;This is a box&lt;/div&gt;</p>
                        `
                    }}></div>
                </div>
                <p>
                    Output will be the following -
                </p>
                <div className='outputPalateBox mb-4 mt-3' style={{'border':'none'}}>
                    <div className='outputPalate' dangerouslySetInnerHTML={{
                        __html: `
                            <style>
                                .box {
                                    border: 2px solid magenta;
                                    padding: 10px 20px;
                                }
                            </style>
                            <div class="box">This is a box</div>
                        `
                    }}></div>
                </div>
                <p>
                    When you need different borders on each side, use individual properties instead of the shorthand.
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>&lt;style&gt;</p>
                            <p class="ml-30">    .boxBorderIndividual {</p>
                            <p class="ml-60">        <span class="color-green">border-top</span>: <span class="color-pink">2px</span> solid red;</p>
                            <p class="ml-60">        <span class="color-green">border-right</span>: <span class="color-pink">3px</span> dashed green;</p>
                            <p class="ml-60">        <span class="color-green">border-bottom</span>: <span class="color-pink">4px</span> dotted blue;</p>
                            <p class="ml-60">        <span class="color-green">border-left</span>: <span class="color-pink">5px</span> double purple;</p>
                            <p class="ml-60">        <span class="color-green">padding</span>: <span class="color-pink">10px 20px</span>;</p>
                            <p class="ml-30">    }</p>
                            <p>&lt;/style&gt;</p>
                            <p>&lt;div <span class="color-pink">class</span>=<span class="color-green">"boxBorderIndividual"</span>&gt;This is a box with border individual&lt;/div&gt;</p>
                        `
                    }}></div>
                </div>
                <p>
                    Output will be the following -
                </p>
                <div className='outputPalateBox mb-4 mt-3' style={{'border':'none'}}>
                    <div className='outputPalate' dangerouslySetInnerHTML={{
                        __html: `
                        <style>
                            .boxBorderIndividual {
                                border-top: 2px solid red;
                                border-right: 3px dashed green;
                                border-bottom: 4px dotted blue;
                                border-left: 5px double purple;
                                padding: 10px 20px;
                            }
                        </style>
                        <div class="boxBorderIndividual">This is a box with border individual</div>
                        `
                    }}></div>
                </div>
            </div>
        </section>
    )
}