import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/react-js/components";

export default function ReactComponents() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("React Js Components | Aspirant's Home");
        const urls = {
            'previous': '/react-js/jsx',
            'next':'/react-js/props'
        }
        path.setPreviousNext(urls);
    },[])

    return (
        <section className="mt-5 mb-5">
            <h3>React Js - Components</h3>
            <div className="mt-4 mb-5">
                <p>
                    Components - Components are fundamental to React,
                    serving as independent and reusable UI building blocks.
                    This means you can reuse a single component anywhere and
                    as many times as needed within your project, as each
                    component is a self-contained and fully independent unit
                    of code.
                </p>
                <p>
                    In <strong>React</strong>, we divide the project's HTML
                    or code into multiple components to reuse functionality
                    across different pages. This allows us to avoid
                    duplicating code and UI elements, as we can simply use
                    the component where needed instead of rewriting the
                    entire functionality.
                </p>
                <p>
                    Components can be nested inside each other and can pass
                    data between them.
                </p>
            </div>
            <div className="mt-5 mb-4">
                <h5>What is Nested Component ?</h5>
                <p>
                    Nested components in ReactJS are components that are
                    defined within another component. This means that a
                    component can contain other components as its children,
                    and these child components can, in turn, contain
                    additional components. This hierarchical structure
                    allows for the composition of complex UIs from simpler,
                    reusable components.
                </p>
            </div>
            <div className="mt-5 mb-4">
                <h5>Types of Component</h5>
                <div className="mt-4 mb-4">
                    <p>
                        In <strong>React</strong>, There are two types of
                        components - one is Class Component and another is
                        Function Component. Previously we used Class
                        Component, but now after React 16.8 we only use
                        Function Component.
                    </p>
                    <ul style={{listStyle: "decimal"}}>
                        <li>
                            <div className="mb-4">
                                <h5>Class Component</h5>
                                <p>
                                    When you create a Class Component you
                                    need to <span className="color-red"> extends React.Component </span>
                                    statement. This will inherits all the
                                    React.Component functions to your
                                    component.
                                </p>
                                <p>
                                    Here is an example of how we create a
                                    Class Component.
                                </p>
                                <div className="codePalateBox mt-2 mb-3">
                                    <div
                                        className="codePalate"
                                        dangerouslySetInnerHTML={{
                                            __html: `<p><span class="color-blue">class</span> <span class="color-red">Welcome</span> <span class="color-blue">extends</span> <span class="color-red">React.Component</span> {</p>
                                            <p style="margin-left:30px"><span class="color-red">render</span>() {</p>
                                            <p style="margin-left:60px">  <span class="color-blue">return</span> &lt;h1&gt;Welcome to Aspirant's Home.&lt;/h1&gt;;</p>
                                            <p style="margin-left:30px">}</p>
                                        <p>}</p>                             
                            `,
                                        }}
                                    ></div>
                                </div>
                                <p>
                                    The component also requires a <span class="color-red">render()</span> method, this method returns HTML.
                                </p>
                            </div>
                        </li>
                        <li>
                            <div className="mb-4">
                                <h5>Function Component</h5>
                                <p>
                                    A Function Component also returns the
                                    same JSX (not HTML) as a Class
                                    Component. Let's see how we can write it
                                    for the example above:
                                </p>
                                <div className="codePalateBox mt-2 mb-3">
                                    <div
                                        className="codePalate"
                                        dangerouslySetInnerHTML={{
                                            __html: `<p><span class="color-blue">function</span> <span class="color-red">Welcome</span> {</p>                                
                            <p style="margin-left:60px">  <span class="color-blue">return</span> &lt;h1&gt;Welcome to Aspirant's Home.&lt;/h1&gt;;</p>
                            <p>}</p>                             
                            `,
                                        }}
                                    ></div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="mt-5 mb-4">
                <h5>Reusing a Component</h5>
                <p>
                    The main feature of components is reusability. We can
                    reuse a component throughout the entire project wherever
                    we need the same functionality, eliminating the need to
                    rewrite the same code and functionality.
                </p>
                <p>Ok, lets take an example.</p>
                <p>
                    Here, You can see there is are two React components
                    <strong>`Listing`</strong> and
                    <strong>`Example`</strong>.
                </p>
                <div className="row mb-4">
                    <div className="col-sm-6">
                        <div className="codePalateBox mt-2 mb-3">
                            <div
                                className="codePalate"
                                dangerouslySetInnerHTML={{
                                    __html: `<p><span class="color-blue">import</span> React <span class="color-blue">from</span> <span class="color-green">'react'</span></p>
                            <br />
                            <p><span class="color-blue">function</span> <span class="color-yellow">Listing()</span></p>
                            <p>{</p>
                            <p style="margin-left:30px">    <span class="color-blue">return</span> (</p>
                            <p style="margin-left:60px">        &lt;p&gt;This is listing paragraph.&lt;/p&gt;</p>
                            <p style="margin-left:30px">    )</p>
                            <p>}</p>
                            <br />
                            <p><span class="color-blue">export</span> default <span class="color-blue">function</span> <span class="color-yellow">Example()</span> {</p>
                            <p style="margin-left:30px">  <span class="color-blue">return</span> (</p>
                            <p style="margin-left:60px">    &lt;div&gt;</p>
                            <p style="margin-left:90px">     &lt;p&gt;Here in Example Component&lt;/p&gt; </p>
                            <p style="margin-left:90px">         &lt;h1&gt;Heading Of Listing&lt;/h1&gt;</p>
                            <p style="margin-left:90px">            <span class="color-yellow">&lt;Listing /&gt;</span></p>
                            <p style="margin-left:90px">            <span class="color-yellow">&lt;Listing /&gt;</span></p>
                            <p style="margin-left:90px">           <span class="color-yellow">&lt;Listing /&gt;</span></p>
                            <p style="margin-left:90px">            <span class="color-yellow">&lt;Listing /&gt;</span></p>
                            <p style="margin-left:60px">    &lt;/div&gt;</p>
                            <p style="margin-left:30px">  )</p>
                            <p>}</p>
                                                        
                            `,
                                }}
                            ></div>
                        </div>
                    </div>
                    <div className="col-sm-6">
                        <p>
                            The <strong>`Listing`</strong> component returns
                            a JSX element, specifically a &lt;p&gt; element
                            containing the text "This is listing paragraph".
                            The Listing component is not directly exported;
                            it's used internally within the Example
                            component.
                        </p>
                        <p>
                            The <strong>`Example`</strong> component returns
                            JSX, which represents the structure of a
                            container &lt;div&gt; containing multiple
                            Listing components and an &lt;h1&gt; element.
                            Inside the &lt;div&gt;, there is an &lt;h1&gt;
                            element with the text "Heading Of Listing".
                            Following the &lt;h1&gt; element, there are four
                            instances of the Listing component (&lt;Listing
                            /&gt;). Each Listing component renders the
                            paragraph defined in the Listing component.
                        </p>
                        <p>Which will give you this result:</p>
                        <div className="codePalateBox mt-2 mb-2">
                            <div
                                className="codePalate"
                                dangerouslySetInnerHTML={{
                                    __html: `<p style="font-size:25px">Heading Of Listing</p>
                                    <p>This is listing paragraph</p>
                                    <p>This is listing paragraph</p>
                                    <p>This is listing paragraph</p>
                                    <p>This is listing paragraph</p>
                                    `,
                                }}
                            ></div>
                        </div>
                    </div>
                </div>
                <p>
                    As a summary, A React component is a reusable piece of
                    code that defines how a part of a user interface should
                    appear and behave. Components allow you to break down
                    your UI into smaller, manageable pieces, making your
                    code more modular, reusable, and easier to understand.
                </p>
            </div>
            <div className="mt-5 mb-4">
                <h5>Component Lifecycle</h5>
                <div className="mt-4 mb-4">
                    <p><strong>Mount/Render :</strong> When a component is mounted for the first time, an instance is created and rendered to the UI. At that time, state and props are created and initialized. </p>
                    <p><strong>Re-Render :</strong> When a state,props or context changes component will re-render to the UI.</p>
                    <p><strong>Unmount :</strong> An component instance is destroyed and removed if we navigate to another page. State and props are also destroyed at that time.</p>
                </div>
            </div>
        </section>
    );
}
