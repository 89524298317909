import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/html/form-elements";

export default function HTMLInputs() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("HTML - Form Elements | Aspirant's Home");
        const urls = {
            'previous': '/html/form-attributes',
            'next': '/html/inputs'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        
        <section className='mt-5 mb-5'>
            <h3>HTML - Form Elements</h3>
            <div className='mt-4 mb-5'>
                <p>
                    There are different types 
                </p>
            </div>
        </section>
    )
}