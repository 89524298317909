import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/node-js/file-system";

export default function FileSystem() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Node Js - File System | Aspirant's Home");
        const urls = {
            'previous': '/node-js/http-module',
            'next': '/node-js/url-module'
        }
        path.setPreviousNext(urls);
    }, [])
    
    return (
        <section className='mt-5 mb-5'>
            <h3 className='mb-4'>Node js FileSystem</h3>
            <div className='mb-3'>
                <p>The Node.js <strong>`fs`</strong> (File System) module provides an API for interacting with the file system in a Node.js application. It allows you to perform various file-related operations such as reading from and writing to files, creating and deleting files and directories, and more.</p>
                <p>To include the File System module, use the <strong>`require()`</strong> method:</p>
                <div className='codePalateBox mt-2'>
                    <div className='codePalate'>
                        <p><span class='color-blue'>const</span> fs = <span class='color-yellow'>require</span>(<span class='color-green'>'fs'</span>);</p>
                    </div>
                </div>
            </div>
            <div className='mt-5 mb-3'>
                <h5 className='mb-4'>Reading a File</h5>
                <p>Here is an example how to read a file content from a file using <strong>`fs`</strong> module.</p>
                <div className='codePalateBox mt-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{ __html: "<p><span class='color-blue'>const</span> fs = <span class='color-yellow'>require</span>(<span class='color-green'>'fs'</span>);</p><p class='color-grey'>// Asynchronous file read</p> <p>fs.<span class='color-red'>readFile</span>(<span class='color-green'>'example.txt'</span>, <span class='color-green'>'utf8'</span>, (err, data) => {</p><p style='margin-left:30px'>  if (err) {</p><p style='margin-left:60px'>    <span class='color-pink'>console</span>.<span class='color-red'>error</span>(<span class='color-green'>'Error reading file:'</span>, err);</p><p style='margin-left:60px'>    <span class='color-blue'>return</span>;</p><p style='margin-left:30px'>  }</p><p style='margin-left:30px'>  <span class='color-pink'>console</span>.<span class='color-red'>log</span>(<span class='color-green'>'File contents:'</span>, data);</p><p>});</p>" }}>
                    </div>
                </div>
            </div>
            <div className='mt-5 mb-3'>
                <h5 className='mb-4'>Writing to a File</h5>
                <p>Here is an example how to write into a file using <strong>`fs`</strong> module.</p>
                <div className='codePalateBox mt-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{ __html: "<p><span class='color-blue'>const</span> fs = <span class='color-yellow'>require</span>(<span class='color-green'>'fs'</span>);</p><p class='color-grey'>// Asynchronous file write</p><p><span class='color-blue'>const</span> content = <span class='color-green'>'Hello, World!'</span>;</p><p>fs.<span class='color-red'>writeFile</span>(<span class='color-green'>'example.txt'</span>, content, <span class='color-green'>'utf8'</span>, err => {</p><p style='margin-left:30px'>  <span class='color-blue'>if</span> (err) {</p><p style='margin-left:60px'><span class='color-pink'>console</span>.<span class='color-red'>error</span>(<span class='color-green'>'Error writing file:'</span>, err);</p><p style='margin-left:60px'><span class='color-blue'>return</span>;</p><p style='margin-left:30px'>  }</p><p style='margin-left:30px'><span class='color-pink'>console</span>.<span class='color-red'>log</span>(<span class='color-green'>'File written successfully'</span>);</p><p>});</p>" }}>
                    </div>
                </div>
            </div>
            <div className='mt-5 mb-3'>
                <h5 className='mb-4'>Creating a Directory</h5>
                <p>We can create a directory using <strong>`fs`</strong> module.</p>
                <div className='codePalateBox mt-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{ __html: "<p><span class='color-blue'>const</span> fs = <span class='color-yellow'>require</span>(<span class='color-green'>'fs'</span>);</p><p class='color-grey'>// Asynchronous directory creation</p><p>fs.<span class='color-red'>mkdir</span>(<span class='color-green'>'new-directory'</span>, err => {</p><p style='margin-left:30px'> <span class='color-blue'>if</span> (err) {</p><p style='margin-left:60px'> <span class='color-pink'>console</span>.<span class='color-red'>error</span>(<span class='color-green'>'Error creating directory:'</span>, err);</p><p style='margin-left:60px'>  <span class='color-blue'>return</span>;</p><p style='margin-left:30px'>  }</p><p style='margin-left:30px'>  <span class='color-pink'>console</span>.<span class='color-red'>log</span>(<span class='color-green'>'Directory created successfully'</span>);</p><p>});</p>" }}>
                    </div>
                </div>
            </div>
            <div className='mt-5 mb-3'>
                <h5 className='mb-4'>Reading Directory Contents</h5>
                <p>We can read a directory content using <strong>`fs`</strong> module.</p>
                <div className='codePalateBox mt-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{ __html: "<p><span class='color-blue'>const</span> fs = <span class='color-yellow'>require</span>(<span class='color-green'>'fs'</span>);</p><p class='color-grey'>// Asynchronous directory read</p><p>fs.<span class='color-red'>readdir</span>('.', (err, files) => {</p><p style='margin-left:30px'>  <span class='color-blue'>if</span> (err) {</p><p style='margin-left:60px'> <span class='color-pink'>console</span>.<span class='color-red'>error</span>(<span class='color-green'>'Error reading directory:'</span>, err);</p><p style='margin-left:60px'> <span class='color-blue'>return</span>;</p><p style='margin-left:30px'>  }</p><p style='margin-left:30px'> <span class='color-pink'>console</span>.<span class='color-red'>log</span>(<span class='color-green'>'Directory contents:'</span>, files);</p><p>});</p>" }}>
                    </div>
                </div>
            </div>
            <div className='mt-5 mb-3'>
                <h5 className='mb-4'>Get File Information</h5>
                <p>We can get file information using <strong>`fs`</strong> module.</p>
                <div className='codePalateBox mt-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{ __html: "<p><span class='color-blue'>const</span> fs = <span class='color-yellow'>require</span>(<span class='color-green'>'fs'</span>);</p><p class='color-grey'>// Asynchronous file information</p><p>fs.<span class='color-red'>stat</span>(<span class='color-green'>'example.txt'</span>, (err, stats) => {</p><p style='margin-left:30px'><span class='color-blue'>if</span> (err) {</p><p style='margin-left:60px'><span class='color-pink'>console</span>.<span class='color-red'>error</span>(<span class='color-green'>'Error getting file stats:'</span>, err);</p><p style='margin-left:60px'><span class='color-blue'> return</span>;</p><p style='margin-left:30px'> }</p><p style='margin-left:30px'>  <span class='color-pink'>console</span>.<span class='color-red'>log</span>(<span class='color-green'>'File stats:'</span>, stats);</p><p>});</p>" }}>
                    </div>
                </div>
            </div>
            <p className='mt-5'>These examples demonstrate how to use various methods of the <strong>`fs`</strong> module in Node.js to perform file system operations such as reading/writing files, creating directories, reading directory contents, and getting file information.</p>
        </section>
    )
}
