import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../../Context";
import { Link } from 'react-router-dom';
export const pageUrl = () => "/mysql/string-functions/reverse";

export default function REVERSE() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Mysql - String Functions - REVERSE() | Aspirant's Home");
        path.setPreviousNext({});
    }, [])

    return (
        <>
            <section class='clearfix mb-3'>
                <span className='float-start'>
                    <Link to='/mysql/string-functions' onClick={() => path.setPathName('/mysql/string-functions')} className='btn btn-primary'> <i class="fa-solid fa-less-than"></i>&nbsp; Back to String Functions </Link>
                </span>
            </section>
            <section className='mt-5 mb-5'>
                <h3>Mysql - REVERSE() Function</h3>
                <div className='mt-4 mb-5'>
                    <p>
                        The <strong>`REVERSE()`</strong> function in MySQL is used to reverse the characters in a string. This function is particularly useful when you need to analyze or manipulate strings in a way that involves their reversed order.
                    </p>
                    <h5>Syntax</h5>
                    <div className='codePalateBox mt-2 mb-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                                <p>REVERSE(string)</p>
                            `
                        }}></div>
                    </div>
                    <p> Where, <strong>`string`</strong> is the string to be reversed.</p>
                    <h5>Examples</h5>
                    <div className='codePalateBox mt-2 mb-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                                <p><span class="color-blue">SELECT</span> REVERSE(<span class="color-green">'hello'</span>) <span class="color-blue">AS</span> reversed_string;</p>
                            `
                        }}></div>
                    </div>
                    <p>
                        In this example, the string 'hello' is reversed to 'olleh'.
                    </p>
                </div>
                <div className='mt-5 mb-5'>
                    <h5>REVERSE() with MySQL Table</h5>
                    <div className='mt-3 mb-2'>
                        <p>
                            Suppose, we have a table <strong>`words`</strong> with <strong>`word`</strong> column.
                        </p>
                        <table className='table' style={{border: '1px dashed #ccc'}}> 
                            <thead>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>word_id</th>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>word</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>1</td>
                                    <td style={{border: '1px dashed #ccc'}}>racecar</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>2</td>
                                    <td style={{border: '1px dashed #ccc'}}>level</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>3</td>
                                    <td style={{border: '1px dashed #ccc'}}>openai</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>3</td>
                                    <td style={{border: '1px dashed #ccc'}}>madam</td>
                                </tr>
                            </tbody>
                        </table>
                        <p>
                            You can check word are palindrome or not by using <strong>`REVERSE()`</strong> function.
                        </p>
                        <div className='codePalateBox mt-2 mb-2'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-blue">SELECT</span> word_id, word, </p>
                                    <p style="margin-left:30px">    <span class="color-blue">CASE</span> </p>
                                    <p style="margin-left:60px">        <span class="color-blue">WHEN</span> word = REVERSE(word) <span class="color-blue">THEN</span> <span class="color-green">'Yes'</span> </p>
                                    <p style="margin-left:30px">    <span class="color-blue">ELSE</span> <span class="color-green">'No'</span> </p>
                                    <p style="margin-left:30px">    <span class="color-blue">END AS</span> is_palindrome</p>
                                    <p><span class="color-blue">FROM</span> words;</p>
                                `
                            }}></div>
                        </div>
                        <p>
                            This query will return:
                        </p>
                        <table className='table' style={{border: '1px dashed #ccc'}}> 
                            <thead>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>word_id</th>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>word</th>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>is_palindrome</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>1</td>
                                    <td style={{border: '1px dashed #ccc'}}>racecar</td>
                                    <td style={{border: '1px dashed #ccc'}}>Yes</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>2</td>
                                    <td style={{border: '1px dashed #ccc'}}>level</td>
                                    <td style={{border: '1px dashed #ccc'}}>Yes</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>3</td>
                                    <td style={{border: '1px dashed #ccc'}}>openai</td>
                                    <td style={{border: '1px dashed #ccc'}}>No</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>3</td>
                                    <td style={{border: '1px dashed #ccc'}}>madam</td>
                                    <td style={{border: '1px dashed #ccc'}}>Yes</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </section>
        </>
    )
}