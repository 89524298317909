import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/mysql/insert-query";

export default function MysqlInsertQuery() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Mysql - Insert Query | Aspirant's Home");
        const urls = {
            'previous': '/mysql/drop-tables',
            'next': '/mysql/select-query'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>Mysql - Insert Query</h3>
            <div className='mt-4 mb-5'>
                <p>
                    In MySQL, the <strong>`INSERT`</strong> statement is used to insert new rows of data into a table. Here's the basic syntax for the <strong>`INSERT`</strong> statement:
                </p>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-blue">INSERT INTO</span> table_name (column1, column2, ...)</p>
                            <p><span class="color-blue">VALUES</span> (value1, value2, ...);</p>
                        `
                    }}></div>
                </div>
                <p>
                    Where, <strong>`table_name`</strong> is the name of the table into which you want to insert data. <strong>`column1, column2, ...`</strong> are the columns into which you want to insert data. If you are inserting data into all columns, you can omit the column list. <strong>`value1, value2, ...`</strong> are the values to be inserted into the corresponding columns. The number of values must match the number of columns specified.
                </p>
                <p>
                    For example, to insert a new row into a table called <strong>`users`</strong> with columns <strong>`id`</strong>, <strong>`name`</strong>, and <strong>`age`</strong>, you would use the following SQL statement:
                </p>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-blue">INSERT INTO</span> users (name, age) <span class="color-blue">VALUES</span> ('John Doe', 30);</p>
                        `
                    }}></div>
                </div>
                <p>
                    The Output will be the following -
                </p>
                <table className='table' style={{border: '1px dashed #ccc'}}> 
                    <thead>
                        <tr style={{border: '1px dashed #ccc'}}>
                            <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>id</th>
                            <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>name</th>
                            <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>age</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr style={{border: '1px dashed #ccc'}}>
                            <td style={{border: '1px dashed #ccc'}}>1</td>
                            <td style={{border: '1px dashed #ccc'}}>John Doe</td>
                            <td style={{border: '1px dashed #ccc'}}>30</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className='mt-5 mb-5'>
                <h5>INSERT ... SET</h5>
                <div className='mt-2 mb-2'>
                    <p>
                        You can also use the <strong>`INSERT ... SET`</strong> syntax to insert rows into a table. This syntax allows you to specify column-value pairs directly without specifying the column names in the same order as they appear in the table. Here's the basic syntax:
                    </p>
                    <div className='codePalateBox mt-2 mb-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                                <p><span class="color-blue">INSERT INTO</span> table_name</p>
                                <p><span class="color-blue">SET</span> column1 = value1, column2 = value2, ...;</p>
                            `
                        }}></div>
                    </div>
                    <p>
                        For example, to insert a new row into a table called <strong>`users`</strong> with columns <strong>`name`</strong>, <strong>`age`</strong>, and <strong>`city`</strong>, you would use the following SQL statement:
                    </p>
                    <div className='codePalateBox mt-2 mb-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                                <p><span class="color-blue">INSERT INTO</span> users</p>
                                <p><span class="color-blue">SET</span> name = <span class="color-green">'Jane Smith'</span>, age = <span class="color-pink">25</span>, city = <span class="color-green">'New York'</span>;</p>
                            `
                        }}></div>
                    </div>

                    <p>
                        The Output will be the following -
                    </p>
                    <table className='table' style={{border: '1px dashed #ccc'}}> 
                        <thead>
                            <tr style={{border: '1px dashed #ccc'}}>
                                <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>id</th>
                                <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>name</th>
                                <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>age</th>
                                <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>city</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr style={{border: '1px dashed #ccc'}}>
                                <td style={{border: '1px dashed #ccc'}}>1</td>
                                <td style={{border: '1px dashed #ccc'}}>Jane Smith</td>
                                <td style={{border: '1px dashed #ccc'}}>25</td>
                                <td style={{border: '1px dashed #ccc'}}>New York</td>
                            </tr>
                        </tbody>
                    </table>

                    
                </div>
            </div>
            <div className='mt-5 mb-5 addBetweenSpace'>
                <ins class="adsbygoogle"
                    style={{ 'display': 'block' }}
                    data-ad-client="ca-pub-2061924575986153"
                    data-ad-slot="6159913076"
                    data-ad-format="auto"
                    data-full-width-responsive="true"></ins>
                <script>
                    (adsbygoogle = window.adsbygoogle || []).push({});
                </script>
            </div>
            <div className='mt-5 mb-5'>
                <h5>INSERT ... SELECT</h5>
                <div className='mt-2 mb-2'>
                    <p>
                        In MySQL, the <strong>`INSERT ... SELECT`</strong> statement allows you to insert data into a table from the result of a <strong>`SELECT`</strong> query. This is useful when you want to copy data from one table to another or insert data based on a query result. Here's the basic syntax:
                    </p>
                    <div className='codePalateBox mt-2 mb-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                                <p><span class="color-blue">INSERT INTO</span> target_table (column1, column2, ...)</p>
                                <p><span class="color-blue">SELECT</span> column1, column2, ...</p>
                                <p><span class="color-blue">FROM</span> source_table</p>
                                <p><span class="color-blue">WHERE condition</span>;</p>
                            `
                        }}></div>
                    </div>
                    <p>
                        Where, <strong>`target_table`</strong> is the name of the table into which you want to insert data. <strong>`column1, column2, ...`</strong> are the columns in the target table you want to insert data into. <strong>`source_table`</strong> is the name of the table from which you want to select data and <strong>`condition`</strong> is an optional condition to filter the rows to be inserted.
                    </p>
                    <p>
                        Suppose, we have a <strong>`old_table`</strong> like below -
                    </p>
                    <table className='table' style={{border: '1px dashed #ccc'}}> 
                        <thead>
                            <tr style={{border: '1px dashed #ccc'}}>
                                <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>id</th>
                                <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>name</th>
                                <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>age</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr style={{border: '1px dashed #ccc'}}>
                                <td style={{border: '1px dashed #ccc'}}>1</td>
                                <td style={{border: '1px dashed #ccc'}}>Jane Smith</td>
                                <td style={{border: '1px dashed #ccc'}}>25</td>
                            </tr>
                            <tr style={{border: '1px dashed #ccc'}}>
                                <td style={{border: '1px dashed #ccc'}}>2</td>
                                <td style={{border: '1px dashed #ccc'}}>John Doe</td>
                                <td style={{border: '1px dashed #ccc'}}>35</td>
                            </tr>
                            <tr style={{border: '1px dashed #ccc'}}>
                                <td style={{border: '1px dashed #ccc'}}>3</td>
                                <td style={{border: '1px dashed #ccc'}}>Johny Smith</td>
                                <td style={{border: '1px dashed #ccc'}}>39</td>
                            </tr>
                        </tbody>
                    </table>
                    <p>
                        Now, if we want to insert data to <strong>`new_table`</strong> from <strong>`old_table`</strong> whose age is greater than <strong>`30`</strong>, then we have to run the below query - 
                    </p>
                    <div className='codePalateBox mt-2 mb-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                                <p><span class="color-blue">INSERT INTO</span> new_table (name, age)</p>
                                <p><span class="color-blue">SELECT</span> name, age</p>
                                <p><span class="color-blue">FROM</span> old_table</p>
                                <p><span class="color-blue">WHERE</span> age > <span class="color-pink">30</span>;</p>
                            `
                        }}></div>
                    </div>

                    <p>
                        The Output of the <strong>`new_table`</strong> will be the following -
                    </p>

                    <table className='table' style={{border: '1px dashed #ccc'}}> 
                        <thead>
                            <tr style={{border: '1px dashed #ccc'}}>
                                <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>id</th>
                                <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>name</th>
                                <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>age</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr style={{border: '1px dashed #ccc'}}>
                                <td style={{border: '1px dashed #ccc'}}>1</td>
                                <td style={{border: '1px dashed #ccc'}}>John Doe</td>
                                <td style={{border: '1px dashed #ccc'}}>35</td>
                            </tr>
                            <tr style={{border: '1px dashed #ccc'}}>
                                <td style={{border: '1px dashed #ccc'}}>2</td>
                                <td style={{border: '1px dashed #ccc'}}>Johny Smith</td>
                                <td style={{border: '1px dashed #ccc'}}>39</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div className='mt-5 mb-5'>
                <h5>INSERT ... TABLE</h5>
                <div className='mt-2 mb-2'>
                    <p>
                        In MySQL, the <strong>`INSERT INTO ... TABLE`</strong> statement is used to copy rows from one table to another. This statement is similar to <strong>`INSERT INTO ... SELECT`</strong>, but it copies all rows from the source table directly into the target table without the need for a <strong>`SELECT`</strong> query. Here's the basic syntax:
                    </p>
                    <div className='codePalateBox mt-2 mb-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                                <p><span class="color-blue">INSERT INTO</span> target_table</p>
                                <p><span class="color-blue">TABLE</span> source_table;</p>
                            `
                        }}></div>
                    </div>
                    <p>
                        Where, <strong>`target_table`</strong> is the name of the table where you want to insert data and <strong>`source_table`</strong> is the name of the table from which you want to copy data.
                    </p>
                    <p className='featureClass'> 
                        <strong>Note :</strong> Both tables must have the same structure (same columns in the same order) for this statement to work. If the tables have different structures, you should use <strong>`INSERT INTO ... SELECT`</strong> instead, specifying the columns explicitly to ensure proper mapping of data.
                    </p>
                </div>
            </div>
        </section>
    )
}