import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/html/images";

export default function HTMLImages() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("HTML - Images | Aspirant's Home");
        const urls = {
            'previous': '/html/comments',
            'next': '/html/favicon'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>HTML - Images</h3>
            <div className='mt-4 mb-5'>
                <p>
                    HTML images are used to add visual content to web pages. Images can make content more engaging and informative. The <strong>`&lt;img&gt;`</strong> tag is used to embed images in an HTML document. The <strong>`&lt;img&gt;`</strong> tag is an empty tag, meaning it doesn't have a closing tag.
                </p>
                <div className='row mt-5 mb-5'>
                    <div className='col-4'>
                        <img className="img-fluid" src='../assets/wmpaintff078.webp' />
                    </div>
                    <div className='col-4'>
                        <img className="img-fluid" src='../assets/html_images2.jpeg' />
                    </div>
                    <div className='col-4'>
                        <img className="img-fluid" src='../assets/html_images3.jpg' />
                    </div>
                </div>
                <h5 className='mt-5 mb-3'>Syntax</h5>
                <p>
                    The basic syntax for the <strong>`&lt;img&gt;`</strong> tag includes the <strong>`src`</strong> attribute to specify the image source (URL or path) and the <strong>`alt`</strong> attribute to provide alternative text for the image.
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>&lt;img <span class="color-pink">src</span>=<span class="color-green">"image.jpg"</span> <span class="color-pink">alt</span>=<span class="color-green">"Description of the image"</span>&gt;</p>
                        `
                    }}></div>
                </div>
                <h5 className='mt-5 mb-3'>Attributes of `&lt;img&gt;` Tag</h5>
                <ul style={{ listStyle: 'decimal' }}>
                    <li>
                        <strong>`src` (source)</strong>:
                        <ul style={{ listStyle: 'disc' }}>
                            <li>Specifies the path to the image file.</li>
                            <li>This can be a relative path, an absolute path, or a URL.</li>
                        </ul>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p>&lt;img <span class="color-pink">src</span>=<span class="color-green">"images/photo.jpg"</span> <span class="color-pink">alt</span>=<span class="color-green">"A beautiful scenery"</span>&gt;</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <strong>`alt` (alternative text):</strong>
                        <ul style={{ listStyle: 'disc' }}>
                            <li>Provides a text description of the image.</li>
                            <li>This text is displayed if the image cannot be loaded and is used by screen readers for accessibility.</li>
                        </ul>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p>&lt;img <span class="color-pink">src</span>=<span class="color-green">"logo.png"</span> <span class="color-pink">alt</span>=<span class="color-green">"Company Logo"</span>&gt;</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <strong>`width` and `height`</strong>:
                        <ul style={{ listStyle: 'disc' }}>
                            <li>Specifies the width and height of the image in pixels.</li>
                            <li>It's generally better to use CSS to control image dimensions for better flexibility.</li>
                        </ul>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p>&lt;img <span class="color-pink">src</span>=<span class="color-green">"thumbnail.jpg"</span> <span class="color-pink">alt</span>=<span class="color-green">"Thumbnail"</span> <span class="color-pink">width</span>=<span class="color-green">"150"</span> <span class="color-pink">height</span>=<span class="color-green">"100"</span>&gt;</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <strong>`title`</strong>:
                        <ul style={{ listStyle: 'disc' }}>
                            <li>Provides additional information about the image.</li>
                            <li>This text often appears as a tooltip when the mouse hovers over the image.</li>
                        </ul>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p>&lt;img <span class="color-pink">src</span>=<span class="color-green">"portrait.jpg"</span> <span class="color-pink">alt</span>=<span class="color-green">"Portrait"</span> <span class="color-pink">title</span>=<span class="color-green">"Portrait of John Doe"</span>&gt;</p>
                                `
                            }}></div>
                        </div>
                    </li>
                </ul>
            </div>
        </section>
    )
}