import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/mysql/update-views";

export default function MysqlUpdateViews() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Mysql - Update Views | Aspirant's Home");
        const urls = {
            'previous': '/mysql/create-views',
            'next': '/mysql/drop-views'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>Mysql - Update Views</h3>
            <div className='mt-4 mb-5'>
                <p>
                    In MySQL, views are virtual tables that represent the result of a <strong>`SELECT`</strong> query. By default, views are read-only, meaning you cannot use them to perform <strong>`UPDATE`</strong>, <strong>`INSERT`</strong>, or <strong>`DELETE`</strong> operations directly. However, in some cases, you can create updatable views that allow you to update the underlying tables through the view.
                </p>
                <p>
                    To create an updatable view, the <strong>`SELECT`</strong> statement used to define the view must meet certain criteria. Some of the key requirements for creating an updatable view in MySQL include:
                </p>
                <ul style={{listStyle:'decimal'}}>
                    <li>
                        The <strong>`SELECT`</strong> statement must not contain any of the following:
                        <ul style={{listStyle:'disc'}}>
                            <li>Aggregate functions (e.g., <strong>`SUM`</strong>, <strong>`COUNT`</strong>)</li>
                            <li><strong>`GROUP BY`</strong> clause</li>
                            <li><strong>`HAVING`</strong> clause</li>
                            <li><strong>`DISTINCT`</strong> keyword</li>
                            <li><strong>`UNION`</strong> or <strong>`UNION ALL`</strong> operators</li>
                        </ul>
                    </li>
                    <li>
                        The <strong>`SELECT`</strong> statement must reference only one base table (not another view).
                    </li>
                    <li>
                        The columns being updated must belong to the base table of the view.
                    </li>
                </ul>
                <p>
                    If these conditions are met, you can update the view using the <strong>`UPDATE`</strong> statement, just like you would update a regular table. The syntax for updating a view is the same as for updating a table:
                </p>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-blue">UPDATE</span> view_name</p>
                            <p><span class="color-blue">SET</span> column1 = value1, column2 = value2, ...</p>
                            <p><span class="color-blue">WHERE condition</span>;</p>
                        `
                    }}></div>
                </div>
                <p>
                    This will ultimately update the base table and the same would reflect in the view itself.
                </p>
            </div>
        </section>
    )
}