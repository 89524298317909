import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/react-js/redux/data-flow";

export default function ReduxDataFlow() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("React - Redux - Data Flow | Aspirant's Home");
        const urls = {
            'previous': '/react-js/redux',
            'next': '/react-js/redux/create-a-store'
        }
        path.setPreviousNext(urls);
    },[])

    return (
        <section className='mt-5 mb-5'>
            <h3>Redux - Data Flow</h3>
            <div className='mt-4 mb-5'>
                <p>
                    Redux follows a unidirectional data flow, which means that data in your application flows in a single direction. This one-way binding data flow ensures that changes to the application state are predictable and easy to manage. As your application grows and becomes more complex, having control over the state of your application is crucial for reproducing issues and adding new features.
                </p>
                <div className='mt-3'>
                    <img className='img-fluid' src='../../assets/react/redux-data-flow-diagram.png' />
                </div>
                <p>
                    From the diagram you can see
                </p>
                <ul>
                    <li>An action is dispatched when an event occurs in the application, such as a user interaction.</li>

                    <li>The root reducer function is invoked with the current state and the dispatched action. The root reducer may delegate tasks to smaller reducer functions, which then return a new state.</li>

                    <li>The store informs the view by executing their callback functions.</li>

                    <li>The view can access the updated state and re-render accordingly.</li>
                </ul>
            </div>
            <div className='mt-5 mb-4'>
                <h5>Core concepts of Redux</h5>
                <p>
                    Here are the main core concepts of Redux:
                </p>
                <ul>
                    <li>
                        <strong>Store:</strong> The store is a JavaScript object that holds the application state. It is the single source of truth for the state in your application. You can access the state using the getState method, and update the state using the dispatch method.
                    </li>
                    <li>
                        <strong>Actions:</strong> Actions are plain JavaScript objects that represent an intention to change the state. They must have a type property indicating the type of action being performed. You can also include additional data in the action object, known as the payload.
                    </li>
                    <li>
                        <strong>Reducers:</strong> Reducers are pure functions that specify how the application's state changes in response to actions. A reducer takes the previous state and an action as arguments, and returns the new state. Reducers should not modify the state directly, but instead return a new state object.
                    </li>
                    <li>
                        <strong>Dispatch:</strong> Dispatch is a method used to dispatch actions to the Redux store. When you dispatch an action, Redux calls the corresponding reducer, which then calculates the new state based on the action and the previous state.
                    </li>
                    <li>
                        <strong>Immutable State:</strong> In Redux, state is immutable, meaning it cannot be changed directly. Instead, reducers return a new state object that reflects the changes. This ensures that the state remains predictable and easy to reason about.
                    </li>
                    <li>
                        <strong>Single Source of Truth:</strong> Redux follows the principle of having a single source of truth for the application state. This means that all state is stored in a single object, making it easier to manage and debug.
                    </li>
                    <li>
                        <strong>Unidirectional Data Flow:</strong> Redux enforces a unidirectional data flow, where data flows in a single direction from the store to the components. This makes it easier to understand how data changes in your application.
                    </li>
                </ul>
            </div>
        </section>
    )
}