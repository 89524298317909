import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../../Context";
export const pageUrl = () => "/react-js/hooks/usereducer";

export default function ReactHookUseReducer() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("React Hooks - useReducer() Hooks | Aspirant's Home");
        const urls = {
            'previous': '/react-js/hooks/useref',
            'next':'/react-js/hooks/usecallback'
        }
        path.setPreviousNext(urls);
    },[])

    return (
        <section className='mt-5 mb-5'>
            <h3>React useReducer Hook</h3>
            <div className='mt-4 mb-4'>
                <p>
                    The <strong>`useReducer`</strong> hook in React is a more powerful alternative to the <strong>`useState`</strong> hook when you have complex state logic that involves multiple sub-values or when the next state depends on the previous one. It is based on the reducer pattern, which is commonly used in JavaScript to manage state in a more predictable way.
                </p>
            </div>
            <div className='mt-4 mb-4'>
                <h5>Creating a Reducer Function</h5>
                <div className='mt-2 mb-2'>
                    <p>
                        This function takes the current state and an action as arguments, and returns the new state based on the action.
                    </p>
                    <div className='codePalateBox mt-2 mb-5'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                                <p><span class="color-blue">import</span> <span class="color-red">React</span>, { useReducer } <span class="color-blue">from</span> <span class="color-green">'react'</span>;</p>
                                <br />
                                <p><span class="color-grey">// Reducer function</span></p>
                                <p><span class="color-blue">const</span> <span class="color-red">reducer</span> = (state, action) => {</p>
                                <p style="margin-left:30px">    <span class="color-blue">switch</span> (action.type) {</p>
                                <p style="margin-left:60px">        <span class="color-blue">case</span> <span class="color-green">'increment'</span>:</p>
                                <p style="margin-left:90px">            <span class="color-blue">return</span> { <span class="color-pink">count</span>: state.count + <span class="color-pink">1</span> };</p>
                                <p style="margin-left:60px">        <span class="color-blue">case</span> <span class="color-green">'decrement'</span>:</p>
                                <p style="margin-left:90px">            <span class="color-blue">return</span> { <span class="color-pink">count</span>: state.count - <span class="color-pink">1</span> };</p>
                                <p style="margin-left:60px">        <span class="color-red">default</span>:</p>
                                <p style="margin-left:90px">            <span class="color-blue">return</span> state;</p>
                                <p style="margin-left:30px">    }</p>
                                <p>};</p>
                            `
                        }}></div>
                    </div>
                </div>
            </div>
            <div className='mt-4 mb-4'>
                <h5>Initializing State</h5>
                <div className='mt-2 mb-2'>
                    <p>
                        You can initialize the state using the <strong>`useReducer`</strong> hook by passing the reducer function and the initial state as arguments. It returns an array containing the current state and a dispatch function.
                    </p>
                    <div className='codePalateBox mt-2 mb-5'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                                <p><span class="color-grey">// Component using useReducer</span></p>
                                <p><span class="color-blue">function</span> <span class="color-red">Counter</span>()</p>
                                <p> {</p>
                                <p style="margin-left:30px">    <span class="color-grey">// Initialize state using useReducer</span></p>
                                <p style="margin-left:30px">    <span class="color-blue">const</span> [state, dispatch] = <span class="color-red">useReducer</span>(reducer, { <span class="color-pink">count</span>: <span class="color-pink">0</span> });</p>
                                <p style="margin-left:30px">    <span class="color-blue">return</span> (</p>
                                <p style="margin-left:60px">        &lt;&gt;&lt;/&gt;</p>
                                <p style="margin-left:30px">    )</p>
                                <p>}</p>
                                <br />
                                <p><span class="color-blue">export default</span> <span class="color-red">Counter</span>;</p>
                            `
                        }}></div>
                    </div>
                </div>
            </div>
            <div className='mt-4 mb-4'>
                <h5>Dispatching Actions</h5>
                <div className='mt-2 mb-2'>
                    <p>
                        To update the state, you dispatch actions to the reducer. An action is a plain JavaScript object that typically has a <strong>`type`</strong> field to indicate the type of action and an optional <strong>`payload`</strong> field to carry additional data.
                    </p>
                    <div className='codePalateBox mt-2 mb-5'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                                <p>&lt;div&gt;</p>
                                <p style="margin-left:30px">    &lt;p&gt;Count: {state.count}&lt;/p&gt;</p>
                                <p style="margin-left:30px">    &lt;button <span class="color-pink">onClick</span>=<span class="color-green">{() => dispatch({ type: 'increment' })}</span>&gt;Increment&lt;/button&gt;</p>
                                <p style="margin-left:30px">    &lt;button <span class="color-pink">onClick</span>=<span class="color-green">{() => dispatch({ type: 'decrement' })}</span>&gt;Decrement&lt;/button&gt;</p>
                                <p>&lt;/div&gt;</p>
                            `
                        }}></div>
                    </div>
                </div>
            </div>

            <div className='mt-4 mb-4'>
                <p>
                    Here, is the full examples of using <strong>`useReducer`</strong> hook.
                </p>
                <div className='codePalateBox mt-2 mb-5'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-blue">import</span> <span class="color-red">React</span>, { useReducer } <span class="color-blue">from</span> <span class="color-green">'react'</span>;</p>
                            <br />
                            <p><span class="color-grey">// Reducer function</span></p>
                            <p><span class="color-blue">const</span> <span class="color-red">reducer</span> = (state, action) => {</p>
                            <p style="margin-left:30px">    <span class="color-blue">switch</span> (action.type) {</p>
                            <p style="margin-left:60px">        <span class="color-blue">case</span> <span class="color-green">'increment'</span>:</p>
                            <p style="margin-left:90px">            <span class="color-blue">return</span> { <span class="color-pink">count</span>: state.count + <span class="color-pink">1</span> };</p>
                            <p style="margin-left:60px">        <span class="color-blue">case</span> <span class="color-green">'decrement'</span>:</p>
                            <p style="margin-left:90px">            <span class="color-blue">return</span> { <span class="color-pink">count</span>: state.count - <span class="color-pink">1</span> };</p>
                            <p style="margin-left:60px">        <span class="color-red">default</span>:</p>
                            <p style="margin-left:90px">            <span class="color-blue">return</span> state;</p>
                            <p style="margin-left:30px">    }</p>
                            <p>};</p>

                            <p><span class="color-grey">// Component using useReducer</span></p>
                            <p><span class="color-blue">function</span> <span class="color-red">Counter</span>()</p>
                            <p> {</p>
                            <p style="margin-left:30px">    <span class="color-grey">// Initialize state using useReducer</span></p>
                            <p style="margin-left:30px">    <span class="color-blue">const</span> [state, dispatch] = <span class="color-red">useReducer</span>(reducer, { <span class="color-pink">count</span>: <span class="color-pink">0</span> });</p>
                            <p style="margin-left:30px">    <span class="color-blue">return</span> (</p>
                            <p style="margin-left:60px">&lt;div&gt;</p>
                            <p style="margin-left:90px">    &lt;p&gt;Count: {state.count}&lt;/p&gt;</p>
                            <p style="margin-left:90px">    &lt;button <span class="color-pink">onClick</span>=<span class="color-green">{() => dispatch({ type: 'increment' })}</span>&gt;Increment&lt;/button&gt;</p>
                            <p style="margin-left:90px">    &lt;button <span class="color-pink">onClick</span>=<span class="color-green">{() => dispatch({ type: 'decrement' })}</span>&gt;Decrement&lt;/button&gt;</p>
                            <p style="margin-left:60px">&lt;/div&gt;</p>
                            <p style="margin-left:30px">    )</p>
                            <p>}</p>
                            <br />
                            <p><span class="color-blue">export default</span> <span class="color-red">Counter</span>;</p>
                    `
                    }}></div>
                </div>
                <p>
                    In this example, the <strong>`Counter`</strong> component uses the <strong>`useReducer`</strong> hook to manage a simple counter state. The <strong>`reducer`</strong> function takes the current state and an action, and returns the new state based on the action type. The component renders the current count and provides buttons to increment and decrement the count by dispatching the corresponding actions to the reducer.
                </p>
            </div>
        </section>
    )
}
