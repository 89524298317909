import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/node-js/mongodb-sort-document";

export default function MongoDbSortDocument() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Node Js - MongoDB - Sort Document | Aspirant's Home");
        const urls = {
            'previous': '/node-js/mongodb-query',
            'next': '/node-js/mongodb-delete-document'
        }
        path.setPreviousNext(urls);
    }, [])
    
    return (
        <section className='mt-5 mb-5'>
            <h3>Node Js MongoDB Sort Document</h3>
            <div className='mt-4'>
                <p>Sort Document is basically sorting the result of documents in ascending or decending order. For Sorting we use <strong>`sort()`</strong>. The sort() method takes one parameter, an object defining the sorting order. For Example,</p>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html:
                            `var mysortvariable = { employee_name: 1 };`
                    }}>
                    </div>
                </div>
                <p>Here, 1 define the ascending order and 0 define the descending order.</p>
                <p>Lets see how we call sort() method in our program</p>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html:
                            `<p><span class='color-blue'>var</span> MongoClient = <span class='color-yellow'>require</span>(<span class='color-green'>'mongodb'</span>).MongoClient;</p>
                            <p><span class='color-blue'>var</span> url = <span class='color-green'>"mongodb://localhost:27017/"</span>;</p>
                            <br />
                            <p>MongoClient.<span class='color-red'>connect</span>(<span class='color-blue'>url</span>, function(err, db) {</p>
                            <p style='margin-left:30px'>  <span class='color-blue'>if</span> (err) throw err;</p>
                            <p style='margin-left:30px'>  <span class='color-blue'>var</span> dbo = db.<span class='color-red'>db</span>(<span class='color-green'>"mymongodbdatabase"</span>);</p>
                            <p style='margin-left:30px'><span class='color-blue'>var</span> mysortvariable = { <span class='color-pink'>employee_name</span>: <span class='color-green'>1</span> };</p>
                            <p style='margin-left:30px'>  dbo.<span class='color-red'>collection</span>(<span class='color-green'>"employees"</span>).find({ }).sort(<span class='color-blue'>mysortvariable</span>).toArray(<span class='color-blue'>function</span>(err, result) {</p>
                            <p style='margin-left:60px'>    <span class='color-blue'>if</span> (err) <span class='color-blue'>throw</span> err;</p>
                            <p style='margin-left:60px'>    <span class='color-pink'>console</span>.<span class='color-red'>log</span>(result);</p>
                            <p style='margin-left:60px'>    db.<span class='color-red'>close</span>();</p>
                            <p style='margin-left:30px'>  });</p>
                            <p>});</p>`
                    }}>
                    </div>
                </div>
                <p>Which will give you this result:</p>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html:
                            `<p>[</p>
                            <p style='margin-left:30px'> { _id: 58fdbf5c0ef8a50b4cdd9a83, employee_name: "Benjamin", email: "benjamin@gmail.com" },</p>
                            <p style='margin-left:30px'> { _id: 58fdbf5c0ef8a50b4cdd9a85, employee_name: "Charlotte", email: "charlotte@gmail.com" },</p>
                            <p style='margin-left:30px'> { _id: 58fdbf5c0ef8a50b4cdd9a88, employee_name: "Eleanor", email: "eleanor@gmail.com" },</p>
                            <p style='margin-left:30px'> { _id: 58fdbf5c0ef8a50b4cdd9a86, employee_name: "Gregg", email: "gregg@gmail.com" },</p>
                            <p style='margin-left:30px'> { _id: 58fdbf5c0ef8a50b4cdd9a89, employee_name: "Horton", email: "horton@gmail.com" },</p>
                            <p style='margin-left:30px'> { _id: 58fdbf5c0ef8a50b4cdd9a80 , employee_name: "Sudhir", email: "sudhir@gmail.com" },</p>
                            <p style='margin-left:30px'> { _id: 58fdbf5c0ef8a50b4cdd9a81, employee_name: "Pradip", email: "pradip@gmail.com" },</p>
                            <p style='margin-left:30px'> { _id: 58fdbf5c0ef8a50b4cdd9a82, employee_name: "Rachel", email: "rachel@gmail.com" },</p>
                            <p style='margin-left:30px'> { _id: 58fdbf5c0ef8a50b4cdd9a84, employee_name: "Samantha", email: "samantha@gmail.com" },</p>
                            <p style='margin-left:30px'> { _id: 58fdbf5c0ef8a50b4cdd9a87, employee_name: "Stacie", email: "stacie@gmail.com" },</p>
                            <p>]</p>`
                    }}>
                    </div>
                </div>
            </div>
        </section>
    )
}
