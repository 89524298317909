import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/mongo-db/many-to-many-relations";

export default function MongoOneToMany() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Many to Many Relations in MongoDB Document");
        title.setKeyWords("many to many relations in mongodb, many to many mongodb, mongodb many to many relationship, mongodb many to many example, mongodb many to many, mongodb many-to-many relationship example, many to many relations, how to create many to many relationship in mongodb, db many to many relationship, many to many relationship mongodb example, many to many relationship examples, mongodb many to many relationship query");
        title.setPageDescription("In MongoDB, a many-to-many relationship is represented using referencing. This involves using two collections and a third collection (often referred to as a join table) to establish the relationship between them.");
        const urls = {
            'previous': '/mongo-db/one-to-many-relations',
            'next': '/mongo-db/projection'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>MongoDB - Many to Many Relations</h3>
            <div className='mt-4 mb-5'>
                <p>
                    In MongoDB, a many-to-many relationship is represented using referencing. This involves using two collections and a third collection (often referred to as a join table) to establish the relationship between them.
                </p>
                <p>
                    Consider a scenario where <strong>`Students`</strong> can enroll in multiple <strong>`Courses`</strong>, and each <strong>`Course`</strong> can have multiple <strong>`Students`</strong>.
                </p>
                <h6>Students Document</h6>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>{</p>
                            <p style="margin-left:30px">    <span class="color-pink">"_id"</span>: ObjectId(<span class="color-green">"507f191e810c19729de860ea"</span>),</p>
                            <p style="margin-left:30px">    <span class="color-pink">"name"</span>: <span class="color-green">"Alice"</span>,</p>
                            <p style="margin-left:30px">    <span class="color-pink">"age"</span>: <span class="color-pink">21</span></p>
                            <p>}</p>
                        `
                    }}></div>
                </div>
                <h6>Courses Document</h6>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>{</p>
                            <p style="margin-left:30px">    <span class="color-pink">"_id"</span>: ObjectId(<span class="color-green">"507f191e810c19729de860eb"</span>),</p>
                            <p style="margin-left:30px">    <span class="color-pink">"title"</span>: <span class="color-green">"Introduction to Databases"</span>,</p>
                            <p style="margin-left:30px">   <span class="color-pink"> "description"</span>: <span class="color-green">"A basic course on databases."</span></p>
                            <p>}</p>
                        `
                    }}></div>
                </div>
                <h6>Enrollments Document</h6>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>{</p>
                            <p style="margin-left:30px">    <span class="color-pink">"_id"</span>: ObjectId(<span class="color-green">"507f191e810c19729de860ec"</span>),</p>
                            <p style="margin-left:30px">    <span class="color-pink">"studentId"</span>: ObjectId(<span class="color-green">"507f191e810c19729de860ea"</span>),</p>
                            <p style="margin-left:30px">    <span class="color-pink">"courseId"</span>: ObjectId(<span class="color-green">"507f191e810c19729de860eb"</span>),</p>
                            <p style="margin-left:30px">    <span class="color-pink">"enrollmentDate"</span>: ISODate(<span class="color-green">"2024-06-01T10:00:00Z"</span>)</p>
                            <p>}</p>
                        `
                    }}></div>
                </div>
                <p>
                    In above collection, stores the many-to-many relationships between students and courses by referencing the <strong>`_id`</strong> fields of both collections.
                </p>
                <p>
                    Using this approach, MongoDB allows you to effectively manage many-to-many relationships, ensuring data normalization and providing the flexibility to query and update entities independently.
                </p>
                <div className='mt-4 featureClass'>
                    <h5>Advantages of Referencing with a Join Table</h5>
                    <ul style={{ listStyle: 'decimal' }}>
                        <li><strong>Scalability</strong>: Allows for large and complex relationships to be managed efficiently.</li>
                        <li><strong>Flexibility</strong>: Enables independent querying and updating of related entities.</li>
                        <li><strong>Normalization</strong>: Avoids duplication of data by keeping entities in separate collections and linking them through references.</li>
                    </ul>
                </div>
            </div>
        </section>
    )
}