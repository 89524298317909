import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/mysql/where-clause";

export default function MysqlWhereClause() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Mysql - Where Clause | Aspirant's Home");
        const urls = {
            'previous': '/mysql/unique-index',
            'next': '/mysql/limit-clause'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>Mysql - Where Clause</h3>
            <div className='mt-4 mb-5'>
                <p>
                    <strong>`WHERE Clause`</strong> is use as a conditional clause to filter out the results in <strong>`SELECT`</strong>, <strong>`UPDATE`</strong>, <strong>`DELETE`</strong>, and <strong>`REPLACE`</strong> statement. Using this <strong>`WHERE clause`</strong>, we can specify a selection criteria to select the required records from a table.
                </p>
                <p>
                    The <strong>`WHERE`</strong> clause allows you to specify complex conditions using logical operators (<strong>`AND`</strong>, <strong>`OR`</strong>, <strong>`NOT`</strong>) and comparison operators (<strong>`=`</strong>, <strong>`!=`</strong>, <strong>`&gt;`</strong>, <strong>`&lt;`</strong>, <strong>`&gt;=`</strong>, <strong>`&lt;=`</strong>, <strong>`BETWEEN`</strong>, <strong>`IN`</strong>, <strong>`LIKE`</strong>, etc.).
                </p>
                <p>
                    Suppose, we have a <strong>`employees`</strong> table like below -
                </p>
                <table className='table' style={{border: '1px dashed #ccc'}}> 
                    <thead>
                        <tr style={{border: '1px dashed #ccc'}}>
                            <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>id</th>
                            <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>name</th>
                            <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>department</th>
                            <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>salary</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr style={{border: '1px dashed #ccc'}}>
                            <td style={{border: '1px dashed #ccc'}}>1</td>
                            <td style={{border: '1px dashed #ccc'}}>Jane Smith</td>
                            <td style={{border: '1px dashed #ccc'}}>IT</td>
                            <td style={{border: '1px dashed #ccc'}}>500000</td>
                        </tr>
                        <tr style={{border: '1px dashed #ccc'}}>
                            <td style={{border: '1px dashed #ccc'}}>2</td>
                            <td style={{border: '1px dashed #ccc'}}>John Doe</td>
                            <td style={{border: '1px dashed #ccc'}}>IT</td>
                            <td style={{border: '1px dashed #ccc'}}>350000</td>
                        </tr>
                        <tr style={{border: '1px dashed #ccc'}}>
                            <td style={{border: '1px dashed #ccc'}}>3</td>
                            <td style={{border: '1px dashed #ccc'}}>Johny Smith</td>
                            <td style={{border: '1px dashed #ccc'}}>Accountant</td>
                            <td style={{border: '1px dashed #ccc'}}>50000</td>
                        </tr>
                        <tr style={{border: '1px dashed #ccc'}}>
                            <td style={{border: '1px dashed #ccc'}}>4</td>
                            <td style={{border: '1px dashed #ccc'}}>Jeffer Joe</td>
                            <td style={{border: '1px dashed #ccc'}}>HR Manager</td>
                            <td style={{border: '1px dashed #ccc'}}>150000</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className='mt-5 mb-5'>
                <h5>WHERE clause with SELECT statement</h5>
                <p>
                    For example, to select all employees from a table named <strong>`employees`</strong> who have a salary greater than 1,00,000:
                </p>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-blue">SELECT * FROM</span> employees <span class="color-blue">WHERE</span> salary > 100000;</p>
                        `
                    }}></div>
                </div>

                <p>
                    The Output of the query will be the following -
                </p>

                <table className='table' style={{border: '1px dashed #ccc'}}> 
                    <thead>
                        <tr style={{border: '1px dashed #ccc'}}>
                            <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>id</th>
                            <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>name</th>
                            <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>department</th>
                            <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>salary</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr style={{border: '1px dashed #ccc'}}>
                            <td style={{border: '1px dashed #ccc'}}>1</td>
                            <td style={{border: '1px dashed #ccc'}}>Jane Smith</td>
                            <td style={{border: '1px dashed #ccc'}}>IT</td>
                            <td style={{border: '1px dashed #ccc'}}>500000</td>
                        </tr>
                        <tr style={{border: '1px dashed #ccc'}}>
                            <td style={{border: '1px dashed #ccc'}}>2</td>
                            <td style={{border: '1px dashed #ccc'}}>John Doe</td>
                            <td style={{border: '1px dashed #ccc'}}>IT</td>
                            <td style={{border: '1px dashed #ccc'}}>350000</td>
                        </tr>
                        <tr style={{border: '1px dashed #ccc'}}>
                            <td style={{border: '1px dashed #ccc'}}>4</td>
                            <td style={{border: '1px dashed #ccc'}}>Jeffer Joe</td>
                            <td style={{border: '1px dashed #ccc'}}>HR Manager</td>
                            <td style={{border: '1px dashed #ccc'}}>150000</td>
                        </tr>
                    </tbody>
                </table>

            </div>
            <div className='mt-5 mb-5'>
                <h5>WHERE clause with UPDATE statement</h5>
                <p>
                    To update the salary of employees in the IT department to 6,00,000:
                </p>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-blue">UPDATE</span> employees <span class="color-blue">SET</span> salary = 600000 <span class="color-blue">WHERE</span> department = <span class="color-green">'IT'</;</p>
                        `
                    }}></div>
                </div>
                <p>
                    The Output of the query will be the following -
                </p>
                <table className='table' style={{border: '1px dashed #ccc'}}> 
                    <thead>
                        <tr style={{border: '1px dashed #ccc'}}>
                            <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>id</th>
                            <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>name</th>
                            <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>department</th>
                            <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>salary</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr style={{border: '1px dashed #ccc'}}>
                            <td style={{border: '1px dashed #ccc'}}>1</td>
                            <td style={{border: '1px dashed #ccc'}}>Jane Smith</td>
                            <td style={{border: '1px dashed #ccc'}}>IT</td>
                            <td style={{border: '1px dashed #ccc'}}>600000</td>
                        </tr>
                        <tr style={{border: '1px dashed #ccc'}}>
                            <td style={{border: '1px dashed #ccc'}}>2</td>
                            <td style={{border: '1px dashed #ccc'}}>John Doe</td>
                            <td style={{border: '1px dashed #ccc'}}>IT</td>
                            <td style={{border: '1px dashed #ccc'}}>600000</td>
                        </tr>
                        <tr style={{border: '1px dashed #ccc'}}>
                            <td style={{border: '1px dashed #ccc'}}>3</td>
                            <td style={{border: '1px dashed #ccc'}}>Johny Smith</td>
                            <td style={{border: '1px dashed #ccc'}}>Accountant</td>
                            <td style={{border: '1px dashed #ccc'}}>50000</td>
                        </tr>
                        <tr style={{border: '1px dashed #ccc'}}>
                            <td style={{border: '1px dashed #ccc'}}>4</td>
                            <td style={{border: '1px dashed #ccc'}}>Jeffer Joe</td>
                            <td style={{border: '1px dashed #ccc'}}>HR Manager</td>
                            <td style={{border: '1px dashed #ccc'}}>150000</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </section>
    )
}