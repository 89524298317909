import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/mysql/sequences";

export default function MysqlSequences() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Mysql - Sequences | Aspirant's Home");
        const urls = {
            'previous': '/mysql/transaction',
            'next': '/mysql/sub-query'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>Mysql - Sequences</h3>
            <div className='mt-4 mb-5'>
                <p>
                    In databases, a sequence is a database object that generates a sequence of unique numerical values, typically used for creating unique identifiers for rows of a table. Sequences are often used in conjunction with primary keys to ensure that each row in a table has a unique identifier.
                </p>
            </div>
            <div className='mt-5 mb-5'>
                <h5>Key Features of Sequences</h5>
                <div className='mt-3 mb-5'>
                    <ul style={{ listStyle: 'decimal' }}>
                        <li>
                            <strong>Unique Values:</strong> Sequences generate unique values, ensuring no duplication.
                        </li>
                        <li>
                            <strong>Incremental Values:</strong> Values generated by sequences can be incremented by a specified value.
                        </li>
                        <li>
                            <strong>Customizable:</strong> You can specify the starting value, increment value, minimum and maximum values, and whether the sequence should cycle when it reaches the end.
                        </li>
                        <li>
                            <strong>Automatic Generation:</strong> The next value in the sequence can be generated automatically when inserting a new row into a table.
                        </li>
                    </ul>
                </div>
            </div>
            <div className='mt-5 mb-5'>
                <h5>Using AUTO_INCREMENT for Sequences</h5>
                <div className='mt-3 mb-5'>
                    <p>
                        The <strong>`AUTO_INCREMENT`</strong> attribute allows you to automatically generate a unique value when a new row is inserted into a table. This is the closest feature to sequences in MySQL.
                    </p>
                    <p>
                        Creating a Table with AUTO_INCREMENT
                    </p>
                    <div className='codePalateBox mt-2 mb-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                                <p><span class="color-blue">CREATE TABLE</span> employees (</p>
                                <p style="margin-left:30px">    id <span class="color-pink">INT</span> <span class="color-blue">NOT NULL</span> AUTO_INCREMENT,</p>
                                <p style="margin-left:30px">    name <span class="color-pink">VARCHAR</span>(<span class="color-pink">100</span>) <span class="color-blue">NOT NULL</span>,</p>
                                <p style="margin-left:30px">    department <span class="color-pink">VARCHAR</span>(<span class="color-pink">100</span>),</p>
                                <p style="margin-left:30px">    <span class="color-blue">PRIMARY</span> KEY (id)</p>
                                <p>);</p>
                            `
                        }}></div>
                    </div>
                    <p>
                        In this example:
                    </p>
                    <ul>
                        <li>The <strong>`id`</strong> column is defined with the <strong>`AUTO_INCREMENT`</strong> attribute.</li>
                        <li>Each time a new row is inserted into the <strong>`employees`</strong> table, MySQL will automatically generate a unique value for the <strong>`id`</strong> column.</li>
                    </ul>
                    <p><strong>Inserting Data into the Table</strong></p>
                    <p>
                        When you insert data into the table, you don't need to specify a value for the id column. MySQL will automatically generate the next available value.
                    </p>
                    <div className='codePalateBox mt-2 mb-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                                <p><span class="color-blue">INSERT INTO</span> employees (name, department) <span class="color-blue">VALUES</span> (<span class="color-green">'Jane Smith'</span>, <span class="color-green">'IT'</span>);</p>
                                <p><span class="color-blue">INSERT INTO</span> employees (name, department) <span class="color-blue">VALUES</span> (<span class="color-green">'John Doe'</span>, <span class="color-green">'HR'</span>);</p>
                            `
                        }}></div>
                    </div>
                    <p>
                        Output will be the following -
                    </p>
                    <table className='table' style={{border: '1px dashed #ccc'}}> 
                        <thead>
                            <tr style={{border: '1px dashed #ccc'}}>
                                <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>id</th>
                                <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>name</th>
                                <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>department</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr style={{border: '1px dashed #ccc'}}>
                                <td style={{border: '1px dashed #ccc'}}>1</td>
                                <td style={{border: '1px dashed #ccc'}}>Jane Smith</td>
                                <td style={{border: '1px dashed #ccc'}}>IT</td>
                            </tr>
                            <tr style={{border: '1px dashed #ccc'}}>
                                <td style={{border: '1px dashed #ccc'}}>2</td>
                                <td style={{border: '1px dashed #ccc'}}>John Doe</td>
                                <td style={{border: '1px dashed #ccc'}}>HR</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div className='mt-5 mb-5'>
                <h5>Managing AUTO_INCREMENT Values</h5>
                <div className='mt-3 mb-5'>
                    <p>
                        You can manage the <strong>`AUTO_INCREMENT`</strong> value in several ways:
                    </p>
                    <ul style={{listStyle:'disc'}}> 
                        <li>
                            <strong>Setting the Initial Value:</strong> You can set the initial value of the  <strong>`AUTO_INCREMENT`</strong> column when you create the table or after the table is created.
                            <div className='codePalateBox mt-2 mb-2'>
                                <div className='codePalate' dangerouslySetInnerHTML={{
                                    __html: `
                                        <p><span class="color-blue">CREATE TABLE</span> employees (</p>
                                        <p style="margin-left:30px">    id <span class="color-pink">INT</span> <span class="color-blue">NOT NULL</span> AUTO_INCREMENT,</p>
                                        <p style="margin-left:30px">    name <span class="color-pink">VARCHAR</span>(<span class="color-pink">100</span>) <span class="color-blue">NOT NULL</span>,</p>
                                        <p style="margin-left:30px">    department <span class="color-pink">VARCHAR</span>(<span class="color-pink">100</span>),</p>
                                        <p style="margin-left:30px">    salary <span class="color-pink">DECIMAL</span>(<span class="color-pink">10</span>, <span class="color-pink">2</span>),</p>
                                        <p style="margin-left:30px">    <span class="color-blue">PRIMARY</span> KEY (id)</p>
                                        <p>) AUTO_INCREMENT=<span class="color-pink">1000</span>;</p>
                                    `
                                }}></div>
                            </div>
                            <p>
                                This sets the initial <strong>`AUTO_INCREMENT`</strong> value to 1000.
                            </p>
                        </li>
                        <li>
                            <strong>Altering the AUTO_INCREMENT Value:</strong> You can change the <strong>`AUTO_INCREMENT`</strong> value for an existing table.
                            <div className='codePalateBox mt-2 mb-2'>
                                <div className='codePalate' dangerouslySetInnerHTML={{
                                    __html: `
                                        <p><span class="color-blue">ALTER TABLE</span> employees AUTO_INCREMENT = <span class="color-pink">2000</span>;</p>
                                    `
                                }}></div>
                            </div>
                            <p>
                                This changes the next <strong>`AUTO_INCREMENT`</strong> value to 2000.
                            </p>
                        </li>
                    </ul>
                </div>
            </div>
        </section>
    )
}