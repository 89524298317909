import React from 'react'

export default function PythonIntro() {
    return (
        <div className='contentPage'>
            <section class='clearfix mb-3'>
                <span className='float-end'>
                    <a href='/python/how-it-works' className='btn btn-primary'>Next &nbsp; <i class="fa-solid fa-greater-than"></i></a>
                </span>
            </section>
            <section className='mt-5 mb-5'>
                <h3>What is Python ?</h3>
                <div className='mt-4 mb-4'>
                    <p>Python is a popular object oriented programing language used in various applications. It is basically hybrid based high level language that use both compiler and interpreter.</p>
                    <p>It's hybrid nature makes the language an platform independent language, it can run on any platform like macOs, Windows or Linux.</p>
                </div>
                <div className='mt-4 mb-4'>
                    <h5>What is high level language ?</h5>
                    <div className='mt-4 mb-4'>
                        <p>A high level language is a programming language that allows an user to write their programmes very easily as compared to low level language. Low-level languages are machine friendly, basically it is a machine language.</p>
                        <p>There are many types of high level language are available like C, JAVA, Python and any other Javascript language.</p>
                        <p>They are based on 3 types</p>
                        <ul style={{ listStyle: 'disc' }}>
                            <li>Compiler Based</li>
                            <li>Interpreter Based</li>
                            <li>Hybrid Based</li>
                        </ul>
                    </div>
                </div>
                <div className='mt-4 mb-4'>
                    <h5>What is Compiler and How it works ?</h5>
                    <div className='mt-4 mb-4'>
                        <ul>
                            <li>Converts your source code to mechine code. Machine can not understand your source code, compiler compiles your code and converts your code to machine friendly machine language. </li>
                            <li>Compiler reads the all source code and converts the source code to a separate executable file for machine.</li>
                            <li>Only the first time compiler is required to converts the source code after that it is not required as many time you want to execute that executable file.</li>
                        </ul>
                    </div>
                </div>
                <div className='mt-4 mb-4'>
                    <h5>What is Interpreter and How it works ?</h5>
                    <div className='mt-4 mb-4'>
                        <ul>
                            <li>Basically javascript based language are used interpreter to converts the source code which is written in .js file to machine language. It is not create a separate file for machine's understand.</li>
                            <li>Interpreter read only one line of the source code and generate the machine code, executed that line only.</li>
                            <li>After that it reads the secocond line and so on.</li>
                            <li>It is basically Runtime Environment.</li>
                        </ul>
                    </div>
                </div>
                <div className='mt-4 mb-4'>
                    <h5>Compiler Vs Interpreter</h5>
                    <div className='mt-4 mb-4'>
                        <ul style={{ listStyle: 'decimal' }}>
                            <li>Compiler gives you complete executable code, you can execute the code whenever you want. It doesn't execute directly on machine. But Interpreter directly execute your code on machine.</li>
                            <li>Compiler runs on once, Interpreter runs on every line of code one by one.</li>
                        </ul>
                    </div>
                </div>
                <div className='mt-4 mb-4'>
                    <h5>Why Python is a Hybrid based ?</h5>
                </div>
            </section>
            <section class='clearfix mb-3'>
                <span className='float-end'>
                    <a href='/python/how-it-works' className='btn btn-primary'>Next &nbsp; <i class="fa-solid fa-greater-than"></i></a>
                </span>
            </section>
        </div>
    )
}
