import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/mysql/having-clause";

export default function MysqlHavingClause() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Mysql - Having Clause | Aspirant's Home");
        const urls = {
            'previous': '/mysql/group-by-clause',
            'next': '/mysql/and-operator'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>Mysql - Having Clause</h3>
            <div className='mt-4 mb-5'>
                <p>
                    In MySQL, the <strong>`HAVING clause`</strong> is used in conjunction with the <strong>`GROUP BY`</strong> clause to filter the rows in a grouped result set based on a specified condition. It allows you to filter the results of aggregate functions applied to grouped rows. Here's the basic syntax:
                </p>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-blue">SELECT</span> column1, aggregate_function(column2), ...</p>
                            <p><span class="color-blue">FROM</span> table_name</p>
                            <p><span class="color-blue">WHERE condition</span></p>
                            <p><span class="color-blue">GROUP BY</span> column1, ...</p>
                            <p><span class="color-blue">HAVING condition</span>;</p>
                        `
                    }}></div>
                </div>
                <p>
                    Where, <strong>`column1, ...`</strong> are the columns you want to select or group by. <strong>`aggregate_function(column2)`</strong> is an aggregate function applied to a column. <strong>`table_name`</strong> is the name of the table from which you want to retrieve data. <strong>`WHERE`</strong> is an optional condition to filter the rows before grouping. <strong>`GROUP BY`</strong> The GROUP BY clause to group the rows. <strong>`HAVING`</strong> The HAVING clause to filter the grouped rows based on an aggregate condition.
                </p>

                <p>
                    Suppose, we have a <strong>`employees`</strong> table like below -
                </p>
                <table className='table' style={{border: '1px dashed #ccc'}}> 
                    <thead>
                        <tr style={{border: '1px dashed #ccc'}}>
                            <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>id</th>
                            <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>name</th>
                            <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>department</th>
                            <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>salary</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr style={{border: '1px dashed #ccc'}}>
                            <td style={{border: '1px dashed #ccc'}}>1</td>
                            <td style={{border: '1px dashed #ccc'}}>Jane Smith</td>
                            <td style={{border: '1px dashed #ccc'}}>IT</td>
                            <td style={{border: '1px dashed #ccc'}}>500000</td>
                        </tr>
                        <tr style={{border: '1px dashed #ccc'}}>
                            <td style={{border: '1px dashed #ccc'}}>2</td>
                            <td style={{border: '1px dashed #ccc'}}>John Doe</td>
                            <td style={{border: '1px dashed #ccc'}}>IT</td>
                            <td style={{border: '1px dashed #ccc'}}>350000</td>
                        </tr>
                        <tr style={{border: '1px dashed #ccc'}}>
                            <td style={{border: '1px dashed #ccc'}}>3</td>
                            <td style={{border: '1px dashed #ccc'}}>Johny Smith</td>
                            <td style={{border: '1px dashed #ccc'}}>Accountant</td>
                            <td style={{border: '1px dashed #ccc'}}>50000</td>
                        </tr>
                        <tr style={{border: '1px dashed #ccc'}}>
                            <td style={{border: '1px dashed #ccc'}}>4</td>
                            <td style={{border: '1px dashed #ccc'}}>Jeffer Joe</td>
                            <td style={{border: '1px dashed #ccc'}}>HR Manager</td>
                            <td style={{border: '1px dashed #ccc'}}>150000</td>
                        </tr>
                        <tr style={{border: '1px dashed #ccc'}}>
                            <td style={{border: '1px dashed #ccc'}}>5</td>
                            <td style={{border: '1px dashed #ccc'}}>Sikhar Smith</td>
                            <td style={{border: '1px dashed #ccc'}}>Accountant</td>
                            <td style={{border: '1px dashed #ccc'}}>70000</td>
                        </tr>
                        <tr style={{border: '1px dashed #ccc'}}>
                            <td style={{border: '1px dashed #ccc'}}>6</td>
                            <td style={{border: '1px dashed #ccc'}}>Xavier Doe</td>
                            <td style={{border: '1px dashed #ccc'}}>IT</td>
                            <td style={{border: '1px dashed #ccc'}}>750000</td>
                        </tr>
                    </tbody>
                </table>
                <p>
                    For example, We need to calculate the number of employees per department and select those departments where the number of employees is greater than 1 -
                </p>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-blue">SELECT</span> department, COUNT(name) as total_employee <span class="color-blue">FROM</span> employees <span class="color-blue">GROUP BY</span> department <span class="color-blue">HAVING</span> total_employee > 1;</p>
                        `
                    }}></div>
                </div>
                <p>
                    The Output of the query will be the following -
                </p>
                <table className='table' style={{border: '1px dashed #ccc'}}> 
                    <thead>
                        <tr style={{border: '1px dashed #ccc'}}>
                            <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>department</th>
                            <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>total_employee</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr style={{border: '1px dashed #ccc'}}>
                            <td style={{border: '1px dashed #ccc'}}>IT</td>
                            <td style={{border: '1px dashed #ccc'}}>3</td>
                        </tr>
                        <tr style={{border: '1px dashed #ccc'}}>
                            <td style={{border: '1px dashed #ccc'}}>Accountant</td>
                            <td style={{border: '1px dashed #ccc'}}>2</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </section>
    )
}