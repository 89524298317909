import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/css/comments";

export default function CSSComments() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("How to do Comments in CSS ? | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/css/how-to-use',
            'next': '/css/colors'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <div className='mt-4 mb-5'>
                <h3 className='mb-4'>How to do Comments in CSS?</h3>
                <p>
                    CSS comments are used to add notes or comments within CSS code. Comments are ignored by browsers during rendering, so they do not affect the appearance or functionality of the styles. They are useful for documenting code, providing context, and making the stylesheet easier to understand and maintain.
                </p>
                <h5>Syntax</h5>
                <p>
                    CSS comments start with <strong>`/*`</strong> and end with <strong>`*/`</strong>. Anything between these markers is treated as a comment.
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p class="color-grey">/* This is a single-line comment */</p>
                        <br />
                        <p class="color-grey">/*</p> 
                        <p class="color-grey">  This is a </p>
                        <p class="color-grey">  multi-line comment </p>
                        <p class="color-grey">*/</p>
                        `
                    }}></div>
                </div>
                <h5 class="mt-5 mb-3">Examples in CSS Code</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p class="color-grey">/* Main container styles */</p>
                            <p>.<span class="color-pink">container</span> {</p>
                            <p class="ml-30">    <span class="color-green">width</span>: <span class="color-pink">100%</span>;</p>
                            <p class="ml-30">    <span class="color-green">margin</span>: <span class="color-pink">0</span> auto;</p>
                            <p>}</p>
                        `
                    }}></div>
                </div>
                <p>
                    Here, in the above example you can see comments are used for providing information.
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p class="color-grey">/* Header Styles */</p>
                            <p>header {</p>
                            <p class="ml-30">    <span class="color-green">background-color</span>: <span class="color-pink">#333</span>;</p>
                            <p class="ml-30">    <span class="color-green">color</span>: white;</p>
                            <p class="ml-30">    <span class="color-green">padding</span>: <span class="color-pink">10px</span>;</p>
                            <p>}</p>
                            <br />
                            <p class="color-grey">/* Navigation Styles */</p>
                            <p>nav {</p>
                            <p class="ml-30">    <span class="color-green">background-color</span>: <span class="color-pink">#444</span>;</p>
                            <p class="ml-30">    <span class="color-green">color</span>: <span class="color-pink">#fff</span>;</p>
                            <p>}</p>
                        `
                    }}></div>
                </div>
                <p>
                    Here, in the above example you can see comments are used to separate different sections of the stylesheet for future understanding.
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p class="color-grey">/*</p>
                            <p class="color-grey">.sidebar {</p>
                            <p class="ml-30 color-grey">    display: none;</p>
                            <p class="color-grey">}</p>
                            <p class="color-grey">*/</p>
                            <br />
                            <p>.<span class="color-pink">main-content</span> {</p>
                            <p class="ml-30">    <span class="color-green">width</span>: <span class="color-pink">75%</span>;</p>
                            <p>}</p>
                        `
                    }}></div>
                </div>
                <p>
                    Here, in the above example you can see comments are used to comment out sections of code that you want to disable temporarily.
                </p>
            </div>
        </section>
    )
}