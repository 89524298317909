import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../../Context";
import { Link } from 'react-router-dom';
export const pageUrl = () => "/node-js/built-in-modules/fs";

export default function FsModule() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("NodeJs - Fs Module | Aspirant's Home");
        path.setPreviousNext({});
    }, [])

    return (
        <div className='contentPage'>
            <section class='clearfix mb-3'>
                <span className='float-start'>
                    <Link to='/node-js/built-in-modules' onClick={() => path.setPathName('/node-js/built-in-modules')} className='btn btn-primary'> <i class="fa-solid fa-less-than"></i>&nbsp; Back to Built-In Modules </Link>
                </span>
            </section>
            <section className='mt-5 mb-5'>
                <h3>Node Js FS Module</h3>
                <div className='mt-4'>
                    <p>The fs (file system) module provides an API for interacting with the file system. It allows you to perform various operations such as reading from and writing to files, creating and deleting files and directories, and more.</p>
                    <p>The syntax for including the fs module in your application: </p>
                    <div className='codePalateBox mt-2 mb-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `<p><span class='color-blue'>var</span> fs = <span class='color-yellow'>require</span>(<span class='color-green'>'fs'</span>);</p>
                                    `
                        }}></div>
                    </div>
                </div>
                <div className='mt-4'>
                    <p>Here's a basic overview of where and how to use the fs module:</p>
                    <ul style={{ 'listStyle': 'decimal' }}>
                        <li className='mt-4'>
                            <strong>Reading Files:</strong>
                            <p>To read the contents of a file, you can use the <strong>`fs.readFile()`</strong> method:</p>
                            <div className='codePalateBox mt-2 mb-2'>
                                <div className='codePalate' dangerouslySetInnerHTML={{
                                    __html: `<p><span class="color-blue">const</span> fs = <span class="color-yellow">require</span>(<span class="color-green">'fs'</span>);</p>
                                    <br />
                                    <p>fs.<span class="color-red">readFile</span>(<span class="color-green">'example.txt'</span>, <span class="color-green">'utf8'</span>, (err, data) => {</p>
                                    <p style="margin-left:30px"><span class="color-blue">if</span> (err) <span class="color-blue">throw</span> err;</p>
                                    <p style="margin-left:30px"><span class="color-pink">console</span>.<span class="color-red">log</span>(data);</p>
                                    <p>});</p>`
                                }}></div>
                            </div>
                        </li>
                        <li className='mt-4'>
                            <strong>Writing Files:</strong>
                            <p>To write data to a file, you can use the <strong>`fs.writeFile()`</strong> method:</p>
                            <div className='codePalateBox mt-2 mb-2'>
                                <div className='codePalate' dangerouslySetInnerHTML={{
                                    __html: `<p><span class="color-blue">const</span> fs = <span class="color-yellow">require</span>(<span class="color-green">'fs'</span>);</p>
                                    <br />
                                    <p>fs.<span class="color-red">writeFile</span>(<span class="color-green">'example.txt'</span>, <span class="color-green">'Hello, World!'</span>, (err) => {</p>
                                    <p style="margin-left:30px"><span class="color-blue">if</span> (err) <span class="color-blue">throw</span> err;</p>
                                    <p style="margin-left:30px"><span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">'File written successfully'</span>);</p>
                                    <p>});</p>`
                                }}></div>
                            </div>
                        </li>
                        <li className='mt-4'>
                            <strong>Deleting Files:</strong>
                            <p>To delete a file, you can use the <strong>`fs.unlink()`</strong> method:</p>
                            <div className='codePalateBox mt-2 mb-2'>
                                <div className='codePalate' dangerouslySetInnerHTML={{
                                    __html: `<p><span class="color-blue">const</span> fs = <span class="color-yellow">require</span>(<span class="color-green">'fs'</span>);</p>
                                    <br />
                                    <p>fs.<span class="color-red">unlink</span>(<span class="color-green">'example.txt'</span>, (err) => {</p>
                                    <p style="margin-left:30px"> <span class="color-blue"> if</span> (err) <span class="color-blue">throw</span> err;</p>
                                    <p style="margin-left:30px">  <span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">'File deleted successfully'</span>);</p>
                                    <p>});</p>
                                    `
                                }}></div>
                            </div>
                        </li>
                        <li className='mt-4'>
                            <strong>Checking if a File Exists:</strong>
                            <p>To check if a file exists, you can use the <strong>`fs.existsSync()`</strong> method:</p>
                            <div className='codePalateBox mt-2 mb-2'>
                                <div className='codePalate' dangerouslySetInnerHTML={{
                                    __html: `<p><span class="color-blue">const</span> fs = <span class="color-yellow">require</span>(<span class="color-green">'fs'</span>);</p>
                                    <br />
                                    <p><span class="color-blue">if</span> (fs.<span class="color-red">existsSync</span>(<span class="color-green">'example.txt'</span>)) {</p>
                                    <p style="margin-left:30px">  <span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">'File exists'</span>);</p>
                                    <p>} <span class="color-blue">else</span> {</p>
                                    <p style="margin-left:30px">  <span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">'File does not exist'</span>);</p>
                                    <p>}</p>`
                                }}></div>
                            </div>
                        </li>
                        <li className='mt-4'>
                            <strong>Creating Directories:</strong>
                            <p>To create a directory, you can use the <strong>`fs.mkdir()`</strong> method:</p>
                            <div className='codePalateBox mt-2 mb-2'>
                                <div className='codePalate' dangerouslySetInnerHTML={{
                                    __html: `<p><span class="color-blue">const</span> fs = <span class="color-yellow">require</span>(<span class="color-green">'fs'</span>);</p>
                                    <br />
                                    <p>fs.<span class="color-red">mkdir</span>(<span class="color-green">'example'</span>, (err) => {</p>
                                    <p style="margin-left:30px">  <span class="color-blue">if</span> (err) <span class="color-blue">throw</span> err;</p>
                                    <p style="margin-left:30px">  <span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">'Directory created successfully'</span>);</p>
                                    <p>});</p>
                                    `
                                }}></div>
                            </div>
                        </li>
                    </ul>
                </div>
            </section>
        </div>
    )
}
