import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/mysql/revoke-privileges";

export default function MysqlRevokePriviledge() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Mysql - Revoke Privileges | Aspirant's Home");
        const urls = {
            'previous': '/mysql/show-privileges',
            'next': '/mysql/create-tables'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>Mysql - Revoke Privileges</h3>
            <div className='mt-4 mb-5'>
                <p>
                    To revoke privileges from a MySQL user account, you can use the <strong>`REVOKE`</strong> statement. This statement allows you to revoke specific privileges that were previously granted to a user account. Here's the basic syntax:
                </p>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-blue">REVOKE</span> privileges <span class="color-blue">ON</span> database_name.table_name <span class="color-blue">FROM</span> <span class="color-green">'username'</span>@<span class="color-green">'host'</span>;</p>
                        `
                    }}></div>
                </div>
                <p>
                    Where,
                </p>
                <ul>
                    <li>
                        <strong>`privileges:`</strong> The privileges you want to revoke. These should be the same privileges that were granted previously.
                    </li>
                    <li>
                        <strong>`database_name:`</strong> The name of the database from which you want to revoke privileges.
                    </li>
                    <li>
                        <strong>`table_name:`</strong> The name of the table from which you want to revoke privileges.
                    </li>
                    <li>
                        <strong>`'username'@'host':`</strong> The username and host of the user account from which you want to revoke privileges.
                    </li>
                </ul>
                <p>
                    For example, to revoke the <strong>`SELECT`</strong> privilege on a table named <strong>`mytable`</strong> in a database named <strong>`mydatabase`</strong> from a user named <strong>`myuser`</strong> connecting from <strong>`localhost`</strong>, you would use the following SQL statement:
                </p>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-blue">REVOKE SELECT ON</span> database_name.table_name <span class="color-blue">FROM</span> <span class="color-green">'myuser'</span>@<span class="color-green">'localhost'</span>;</p>
                        `
                    }}></div>
                </div>
                <p>
                    After revoking the privileges, you should flush privileges to apply the changes:
                </p>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            FLUSH PRIVILEGES;
                        `
                    }}></div>
                </div>
            </div>
        </section>
    )
}