import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../../Context";
import { Link } from 'react-router-dom';
export const pageUrl = () => "/mysql/string-functions/trim";

export default function TRIM() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Mysql - String Functions - TRIM() | Aspirant's Home");
        path.setPreviousNext({});
    }, [])

    return (
        <>
            <section class='clearfix mb-3'>
                <span className='float-start'>
                    <Link to='/mysql/string-functions' onClick={() => path.setPathName('/mysql/string-functions')} className='btn btn-primary'> <i class="fa-solid fa-less-than"></i>&nbsp; Back to String Functions </Link>
                </span>
            </section>
            <section className='mt-5 mb-5'>
                <h3>Mysql - TRIM() Function</h3>
                <div className='mt-4 mb-5'>
                    <p>
                        The <strong>`TRIM()`</strong> function in MySQL is used to remove unwanted leading and trailing spaces or specified characters from a string. It is helpful for cleaning up data by eliminating extra spaces or other characters that may have been inadvertently included.
                    </p>
                    <h5>Syntax</h5>
                    <div className='codePalateBox mt-2 mb-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                                <p>TRIM([[<span class="color-blue">LEADING</span> | <span class="color-blue">TRAILING</span> | <span class="color-blue">BOTH</span>] [remstr] <span class="color-blue">FROM</span>] str)</p>
                            `
                        }}></div>
                    </div>
                    <p>
                        Where, <strong>`LEADING`</strong> removes characters from the beginning of the string. <strong>`TRAILING`</strong> removes characters from the end of the string. <strong>`BOTH`</strong> removes characters from both the beginning and the end of the string (default behavior). <strong>`remstr`</strong> is the character to remove (if omitted, spaces are removed) and
                        <strong>`str`</strong> is the string to be trimmed.
                    </p>
                </div>
                <div className='mt-5 mb-5'>
                    <h5>Examples</h5>
                    <div className='mt-4 mt-2'>
                        <ul style={{'listStyle':'decimal'}}>
                            <li>
                                <strong>Basic Usage - Trim Leading and Trailing Spaces:</strong>
                                <p>
                                    <div className='codePalateBox mt-2 mb-2'>
                                        <div className='codePalate' dangerouslySetInnerHTML={{
                                            __html: `
                                                <p><span class="color-blue">SELECT</span> TRIM(<span class="color-green">'   Hello, World!   '</span>) <span class="color-blue">AS</span> trimmed_string;</p>
                                            `
                                        }}></div>
                                    </div>
                                </p>
                                <p>
                                    This query will return <strong>`Hello, World!`</strong>.
                                </p>
                            </li>
                            <li>
                                <strong>Trim Leading Spaces:</strong>
                                <p>
                                    <div className='codePalateBox mt-2 mb-2'>
                                        <div className='codePalate' dangerouslySetInnerHTML={{
                                            __html: `
                                                <p><span class="color-blue">SELECT</span> TRIM(<span class="color-blue">LEADING</span> ' ' <span class="color-blue">FROM</span> <span class="color-green">'   Hello, World!   '</span>) <span class="color-blue">AS</span> trimmed_string;</p>
                                            `
                                        }}></div>
                                    </div>
                                </p>
                                <p>
                                    This query will return <strong>`Hello, World!`</strong>.
                                </p>
                            </li>
                            <li>
                                <strong>Trim Trailing Spaces:</strong>
                                <p>
                                    <div className='codePalateBox mt-2 mb-2'>
                                        <div className='codePalate' dangerouslySetInnerHTML={{
                                            __html: `
                                                <p><span class="color-blue">SELECT</span> TRIM(<span class="color-blue">TRAILING</span> ' ' <span class="color-blue">FROM</span> <span class="color-green">'   Hello, World!   '</span>) <span class="color-blue">AS</span> trimmed_string;</p>
                                            `
                                        }}></div>
                                    </div>
                                </p>
                                <p>
                                    This query will return <strong>`Hello, World!`</strong>.
                                </p>
                            </li>
                            <li>
                                <strong>Trim Specific Characters:</strong>
                                <p>
                                    <div className='codePalateBox mt-2 mb-2'>
                                        <div className='codePalate' dangerouslySetInnerHTML={{
                                            __html: `
                                                <p><span class="color-blue">SELECT</span> TRIM(<span class="color-blue">BOTH</span> <span class="color-green">'x'</span> <span class="color-blue">FROM</span> <span class="color-green">'xxxHello, World!xxx'</span>) <span class="color-blue">AS</span> trimmed_string;</p>
                                            `
                                        }}></div>
                                    </div>
                                </p>
                                <p>
                                    This query will return <strong>`Hello, World!`</strong>.
                                </p>
                            </li>
                        </ul>
                    </div>
                </div>
            </section>
        </>
    )
}