import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/react-js/handling-http-errors";

export default function HandlingErrors() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("React Http Request - Handling HTTP Errors | Aspirant's Home");
        const urls = {
            'previous': '/react-js/handling-states',
            'next':'/react-js/handling-fetched-data'
        }
        path.setPreviousNext(urls);
    },[])

    return (
        <section className='mt-5 mb-5'>
            <h3>React Http Request - Handling HTTP Errors</h3>
            <div className='mt-4 mb-4'>
                <p>
                    In React, you can handle HTTP errors in a similar way to handling any other errors. You can use the <strong>`fetch`</strong> API or <strong>`Axios`</strong> to make HTTP requests and then check the response status to determine if an error occurred. Here's a basic example using fetch:
                </p>
                <div className='codePalateBox mt-2 mb-5'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-blue">import</span> <span class="color-red">React</span>, { <span class="color-red">useState</span>, <span class="color-red">useEffect</span> } <span class="color-blue">from</span> <span class="color-green">'react'</span>;</p>
                            <br />
                            <p><span class="color-blue">function</span> <span class="color-red">App</span>() {</p>
                            <p style="margin-left:30px">    <span class="color-blue">const</span> [data, setData] = <span class="color-red">useState</span>(null);</p>
                            <p style="margin-left:30px">    <span class="color-blue">const</span> [error, setError] = <span class="color-red">useState</span>(null);</p>
                            <br />    
                            <p style="margin-left:30px">    <span class="color-red">useEffect</span>(() => {</p>
                            <p style="margin-left:60px">        <span class="color-red">fetch</span>(<span class="color-green">'https://api.example.com/data'</span>)</p>
                            <p style="margin-left:60px">        .<span class="color-red">then</span>(response => {</p>
                            <p style="margin-left:90px">            <span class="color-blue">if</span> (!response.ok) {</p>
                            <p style="margin-left:120px">                <span class="color-blue">throw new</span> <span class="color-red">Error</span>(<span class="color-green">'Network response was not ok'</span>);</p>
                            <p style="margin-left:90px">            }</p>
                            <p style="margin-left:90px">            <span class="color-blue">return</span> response.<span class="color-red">json</span>();</p>
                            <p style="margin-left:60px">        })</p>
                            <p style="margin-left:60px">        .<span class="color-red">then</span>(data => <span class="color-red">setData</span>(data))</p>
                            <p style="margin-left:60px">        .<span class="color-red">catch</span>(error => <span class="color-red">setError</span>(error.message));</p>
                            <p style="margin-left:30px">    }, []);</p>
                            <br />    
                            <p style="margin-left:30px">    <span class="color-blue">return</span> (</p>
                            <p style="margin-left:60px">        &lt;div&gt;</p>
                            <p style="margin-left:90px">        {data ? (</p>
                            <p style="margin-left:120px">            &lt;p&gt;Data: {JSON.stringify(data)}&lt;/p&gt;</p>
                            <p style="margin-left:90px">        ) : error ? (</p>
                            <p style="margin-left:120px">            &lt;p&gt;Error: {error}&lt;/p&gt;</p>
                            <p style="margin-left:90px">        ) : (</p>
                            <p style="margin-left:120px">            &lt;p&gt;Loading...&lt;/p&gt;</p>
                            <p style="margin-left:90px">        )}</p>
                            <p style="margin-left:60px">        &lt;/div&gt;</p>
                            <p style="margin-left:30px">    );</p>
                            <p>}</p>
                            <br />
                            <p><span class="color-blue">export default</span> <span class="color-red">App</span>;</p>
                        
                        `
                    }}></div>
                </div>
                <p>In this example:</p> 
                <ul style={{listStyle:'disc'}}>
                    <li>
                        We use the <strong>`error`</strong> state to store any errors that occur during the fetch operation.
                    </li>
                    <li>
                        Inside the <strong>`fetch`</strong> promise chain, we check the response status using <strong>`response.ok`</strong>. If the status is not in the range 200-299 (indicating a successful response), we throw an error.
                    </li>
                    <li>
                        The <strong>`.catch()`</strong> block catches any errors that occur during the fetch operation and sets the <strong>`error`</strong> state with the error message.
                    </li>
                    <li>
                        We conditionally render the data, error message, or a loading indicator based on the state of <strong>`data`</strong> and <strong>`error`</strong>.
                    </li>
                </ul>
                
            </div>
        </section>
    )
}