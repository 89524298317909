import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../../Context";
import { Link } from 'react-router-dom';
export const pageUrl = () => "/mysql/numeric-functions/truncate";

export default function TRUNCATE() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Mysql - Numeric Functions - TRUNCATE(x, d) | Aspirant's Home");
        path.setPreviousNext({});
    }, [])

    return (
        <>
            <section class='clearfix mb-3'>
                <span className='float-start'>
                    <Link to='/mysql/numeric-functions' onClick={() => path.setPathName('/mysql/numeric-functions')} className='btn btn-primary'> <i class="fa-solid fa-less-than"></i>&nbsp; Back to Numeric Functions </Link>
                </span>
            </section>
            <section className='mt-5 mb-5'>
                <h3>Mysql - TRUNCATE(x, d) Function</h3>
                <div className='mt-4 mb-5'>
                    <p>
                        The <strong>`TRUNCATE()`</strong> function is used to truncate a number to a specified number of decimal places, removing the decimal part without rounding.
                    </p>
                    <p>
                        Here's a basic example of using the <strong>`TRUNCATE()`</strong> function in a SELECT query:
                    </p>
                    <div className='codePalateBox mt-2 mb-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                                <p><span class="color-blue">SELECT</span> TRUNCATE(<span class="color-pink">5.678</span>, <span class="color-pink">2</span>);</p>
                            `
                        }}></div>
                    </div>
                    <p>
                        This query will return the result 5.67 (truncates to 2 decimal places).
                    </p>
                </div>
                <div className='mt-5 mb-5'>
                    <h5>TRUNCATE(x, d) with MySQL Table</h5>
                    <div className='mt-3 mb-2'>
                        <p>
                            Consider a table <strong>`temperatures`</strong> with columns <strong>`location`</strong> and <strong>`temperature`</strong>.
                        </p>
                        <table className='table' style={{border: '1px dashed #ccc'}}> 
                            <thead>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>id</th>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>location</th>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>temperature</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>1</td>
                                    <td style={{border: '1px dashed #ccc'}}>New York</td>
                                    <td style={{border: '1px dashed #ccc'}}>25.678</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>2</td>
                                    <td style={{border: '1px dashed #ccc'}}>Los Angeles</td>
                                    <td style={{border: '1px dashed #ccc'}}>30.123</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>3</td>
                                    <td style={{border: '1px dashed #ccc'}}>Chicago</td>
                                    <td style={{border: '1px dashed #ccc'}}>20.987</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>4</td>
                                    <td style={{border: '1px dashed #ccc'}}>Miami</td>
                                    <td style={{border: '1px dashed #ccc'}}>35.543</td>
                                </tr>
                            </tbody>
                        </table>
                        <p>
                            If you want to round the <strong>`temperature`</strong> of each location to two decimal places, you can use the ROUND function as follows:
                        </p>
                        <div className='codePalateBox mt-2 mb-2'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-blue">SELECT</span> location, temperature, TRUNCATE(temperature, 2) <span class="color-blue">AS</span> truncated_temperature <span class="color-blue">FROM</span> temperatures;</p>
                                `
                            }}></div>
                        </div>
                        <p>
                            This query will return:
                        </p>
                        <table className='table' style={{border: '1px dashed #ccc'}}> 
                            <thead>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>id</th>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>location</th>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>temperature</th>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>truncated_temperature</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>1</td>
                                    <td style={{border: '1px dashed #ccc'}}>New York</td>
                                    <td style={{border: '1px dashed #ccc'}}>25.678</td>
                                    <td style={{border: '1px dashed #ccc'}}>25.67</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>2</td>
                                    <td style={{border: '1px dashed #ccc'}}>Los Angeles</td>
                                    <td style={{border: '1px dashed #ccc'}}>30.123</td>
                                    <td style={{border: '1px dashed #ccc'}}>30.12</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>3</td>
                                    <td style={{border: '1px dashed #ccc'}}>Chicago</td>
                                    <td style={{border: '1px dashed #ccc'}}>20.987</td>
                                    <td style={{border: '1px dashed #ccc'}}>20.98</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>4</td>
                                    <td style={{border: '1px dashed #ccc'}}>Miami</td>
                                    <td style={{border: '1px dashed #ccc'}}>35.543</td>
                                    <td style={{border: '1px dashed #ccc'}}>35.54</td>
                                </tr>
                            </tbody>
                        </table>
                        <p>
                            In this example, the ROUND function is used to round the <strong>`temperature`</strong> column to two decimal places. The <strong>`TRUNCATE(temperature, 2)`</strong> expression returns the truncated value for each row.
                        </p>
                    </div>
                </div>
            </section>
        </>
    )
}