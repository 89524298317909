import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/html/definition-lists";

export default function HTMLDefinitionLists() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("HTML - Definition Lists | Aspirant's Home");
        const urls = {
            'previous': '/html/ordered-lists',
            'next': '/html/text-links'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>HTML - Definition Lists</h3>
            <div className='mt-4 mb-5'>
                <p>
                    A definition list (<strong>`&lt;dl&gt;`</strong>) in HTML is used to display a list of terms and their corresponding definitions. This type of list is often used for glossaries, dictionaries, or to present metadata (a list of key-value pairs).
                </p>
                <h5 className='mt-5 mb-3'>Basic Structure</h5>
                <p>
                    The definition list consists of three main tags:
                </p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><strong>`&lt;dl&gt;`</strong>: Defines the definition list.</li>
                    <li><strong>`&lt;dt&gt;`</strong>: Defines a term in the definition list.</li>
                    <li><strong>`&lt;dd&gt;`</strong>: Describes the term in the definition list.</li>
                </ul>
                <h5 className='mt-5 mb-3'>Syntax</h5>
                <p>
                    Here's the basic structure of a definition list:
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;dl&gt;</p>
                        <p class="ml-30">    &lt;dt&gt;Term 1&lt;/dt&gt;</p>
                        <p class="ml-30">    &lt;dd&gt;Description for Term 1&lt;/dd&gt;</p>
                        <p class="ml-30">    &lt;dt&gt;Term 2&lt;/dt&gt;</p>
                        <p class="ml-30">    &lt;dd&gt;Description for Term 2&lt;/dd&gt;</p>
                        <p>&lt;/dl&gt;</p>
                        `
                    }}></div>
                </div>
                <h5 className='mt-5 mb-3'>Example</h5>
                <p>
                    Here's an example of a definition list:
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;!DOCTYPE html&gt;</p>
                        <p>&lt;html&gt;</p>
                        <p class="ml-30">    &lt;head&gt;</p>
                        <p class="ml-60">        &lt;title&gt;Definition List Example&lt;/title&gt;</p>
                        <p class="ml-30">    &lt;/head&gt;</p>
                        <p class="ml-30">    &lt;body&gt;</p>
                        <p class="ml-60">        &lt;dl&gt;</p>
                        <p class="ml-90">            &lt;dt&gt;HTML&lt;/dt&gt;</p>
                        <p class="ml-90">            &lt;dd&gt;A standard markup language for creating web pages.&lt;/dd&gt;</p>
                        <p class="ml-90">            &lt;dt&gt;CSS&lt;/dt&gt;</p>
                        <p class="ml-90">            &lt;dd&gt;A style sheet language used for describing the presentation of a document written in HTML.&lt;/dd&gt;</p>
                        <p class="ml-90">           &lt;dt&gt;JavaScript&lt;/dt&gt;</p>
                        <p class="ml-90">            &lt;dd&gt;A programming language commonly used to create interactive effects within web browsers.&lt;/dd&gt;</p>
                        <p class="ml-60">        &lt;/dl&gt;</p>
                        <p class="ml-30">    &lt;/body&gt;</p>
                        <p>&lt;/html&gt;</p>
                        `
                    }}></div>
                </div>
                <p>
                    Output of the following -
                </p>
                <div className='outputPalateBox mb-4'>
                    <div className='outputPalate' dangerouslySetInnerHTML={{
                        __html: `
                        <!DOCTYPE html>
                        <html>
                        <head>
                          <title>Definition List Example</title>
                        </head>
                        <body>
                          <dl>
                            <dt>HTML</dt>
                            <dd>A standard markup language for creating web pages.</dd>
                            <dt>CSS</dt>
                            <dd>A style sheet language used for describing the presentation of a document written in HTML.</dd>
                            <dt>JavaScript</dt>
                            <dd>A programming language commonly used to create interactive effects within web browsers.</dd>
                          </dl>
                        </body>
                        </html>
                        
                        `
                    }}></div>
                </div>
                <h5 className='mt-5 mb-3'>Styling Definition Lists with CSS</h5>
                <p>
                    You can style definition lists using CSS to customize their appearance.
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-grey">&lt;!DOCTYPE</span> <span class="color-blue">html</span><span class="color-grey">&gt;</span></p>
                        <p>&lt;html&gt;</p>
                        <p class="ml-30">    &lt;head&gt;</p>
                        <p class="ml-60">        &lt;style&gt;</p>
                        <p class="ml-90">            dl {</p>
                        <p class="ml-120">                <span class="color-green">font-family</span>: Arial, sans-serif;</p>
                        <p class="ml-120">                <span class="color-green">padding</span>: <span class="color-pink">10px</span>;</p>
                        <p class="ml-90">            }</p>
                        <p class="ml-90">            dt {</p>
                        <p class="ml-120">                <span class="color-green">font-weight</span>: bold;</p>
                        <p class="ml-120">                <span class="color-green">color</span>: <span class="color-pink">#2c3e50</span>;</p>
                        <p class="ml-120">                <span class="color-green">margin-top</span>: <span class="color-pink">10px</span>;</p>
                        <p class="ml-90">            }</p>
                        <p class="ml-90">            dd {</p>
                        <p class="ml-120">                <span class="color-green">margin-left</span>: <span class="color-pink">20px</span>;</p>
                        <p class="ml-120">                <span class="color-green">color</span>: <span class="color-pink">#7f8c8d</span>;</p>
                        <p class="ml-90">            }</p>
                        <p class="ml-60">        &lt;/style&gt;</p>
                        <p class="ml-60">        &lt;title&gt;Styled Definition List&lt;/title&gt;</p>
                        <p class="ml-30">    &lt;/head&gt;</p>
                        <p class="ml-30">&lt;body&gt;</p>
                        <p class="ml-60">  &lt;dl&gt;</p>
                        <p class="ml-90">    &lt;dt&gt;HTML&lt;/dt&gt;</p>
                        <p class="ml-90">    &lt;dd&gt;A standard markup language for creating web pages.&lt;/dd&gt;</p>
                        <p class="ml-90">    &lt;dt&gt;CSS&lt;/dt&gt;</p>
                        <p class="ml-90">    &lt;dd&gt;A style sheet language used for describing the presentation of a document written in HTML.&lt;/dd&gt;</p>
                        <p class="ml-90">    &lt;dt&gt;JavaScript&lt;/dt&gt;</p>
                        <p class="ml-90">    &lt;dd&gt;A programming language commonly used to create interactive effects within web browsers.&lt;/dd&gt;</p>
                        <p class="ml-60">  &lt;/dl&gt;</p>
                        <p class="ml-30">&lt;/body&gt;</p>
                        <p>&lt;/html&gt;</p>
                        `
                    }}></div>
                </div>
                <p>
                    Output of the following -
                </p>
                <div className='outputPalateBox mb-4'>
                    <div className='outputPalate' dangerouslySetInnerHTML={{
                        __html: `
                        <!DOCTYPE html>
                        <html>
                        <head>
                          <style>
                            dl.style1 {
                              font-family: Arial, sans-serif;
                              padding: 10px;
                            }
                            dl.style1 dt {
                              font-weight: bold;
                              color: #2c3e50;
                              margin-top: 10px;
                            }
                            dl.style1 dd {
                              margin-left: 20px;
                              color: #7f8c8d;
                            }
                          </style>
                          <title>Styled Definition List</title>
                        </head>
                        <body>
                          <dl class="style1">
                            <dt>HTML</dt>
                            <dd>A standard markup language for creating web pages.</dd>
                            <dt>CSS</dt>
                            <dd>A style sheet language used for describing the presentation of a document written in HTML.</dd>
                            <dt>JavaScript</dt>
                            <dd>A programming language commonly used to create interactive effects within web browsers.</dd>
                          </dl>
                        </body>
                        </html>
                        
                        `
                    }}></div>
                </div>
                <h5 className='mt-5 mb-3'>Nesting Definition Lists</h5>
                <p>
                    You can nest definition lists within other lists to create hierarchical structures.
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-grey">&lt;!DOCTYPE</span> <span class="color-blue">html</span><span class="color-grey">&gt;</span></p>
                        <p>&lt;html&gt;</p>
                        <p class="ml-30">    &lt;head&gt;</p>
                        <p class="ml-60">        &lt;title&gt;Nesting Definition Lists&lt;/title&gt;</p>
                        <p class="ml-30">    &lt;/head&gt;</p>
                        <p class="ml-30">    &lt;body&gt;</p>
                        <p class="ml-60">        &lt;dl&gt;</p>
                        <p class="ml-90">            &lt;dt&gt;Programming Languages&lt;/dt&gt;</p>
                        <p class="ml-90">            &lt;dd&gt;</p>
                        <p class="ml-120">            &lt;dl&gt;</p>
                        <p class="ml-150">                &lt;dt&gt;HTML&lt;/dt&gt;</p>
                        <p class="ml-150">                &lt;dd&gt;A standard markup language for creating web pages.&lt;/dd&gt;</p>
                        <p class="ml-150">                &lt;dt&gt;CSS&lt;/dt&gt;</p>
                        <p class="ml-150">                &lt;dd&gt;A style sheet language used for describing the presentation of a document written in HTML.&lt;/dd&gt;</p>
                        <p class="ml-150">                &lt;dt&gt;JavaScript&lt;/dt&gt;</p>
                        <p class="ml-150">                &lt;dd&gt;A programming language commonly used to create interactive effects within web browsers.&lt;/dd&gt;</p>
                        <p class="ml-120">            &lt;/dl&gt;</p>
                        <p class="ml-90">           &lt;/dd&gt;</p>
                        <p class="ml-60">        &lt;/dl&gt;</p>
                        <p class="ml-30">    &lt;/body&gt;</p>
                        <p>&lt;/html&gt;</p>
                        `
                    }}></div>
                </div>
                <p>
                    Output of the following -
                </p>
                <div className='outputPalateBox mb-4'>
                    <div className='outputPalate' dangerouslySetInnerHTML={{
                        __html: `
                        <!DOCTYPE html>
                        <html>
                            <head>
                                <title>Nesting Definition Lists</title>
                            </head>
                            <body>
                                <dl>
                                    <dt>Programming Languages</dt>
                                    <dd>
                                    <dl>
                                        <dt>HTML</dt>
                                        <dd>A standard markup language for creating web pages.</dd>
                                        <dt>CSS</dt>
                                        <dd>A style sheet language used for describing the presentation of a document written in HTML.</dd>
                                        <dt>JavaScript</dt>
                                        <dd>A programming language commonly used to create interactive effects within web browsers.</dd>
                                    </dl>
                                    </dd>
                                </dl>
                            </body>
                        </html>
                        
                        `
                    }}></div>
                </div>
                <p className='mt-4 featureClass'>
                    By understanding and using definition lists properly, you can create well-structured and informative lists in your HTML documents.
                </p>
            </div>
        </section>
    )
}