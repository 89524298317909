import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/react-js/navigate-to-page-dynamically";

export default function NavigateDynamically() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("React - Navigate To Page Dynamically | Aspirant's Home");
        const urls = {
            'previous': '/react-js/navigation-link',
            'next':'/react-js/defining-dynamic-routes'
        }
        path.setPreviousNext(urls);
    },[])

    return (
        <section className='mt-5 mb-5'>
            <h3>Navigate To Page Dynamically</h3>
            <div className='mt-4 mb-4'>
                <p>In React Router v6, the <strong>`useNavigate`</strong> hook is used for programmatic navigation. It provides a function that allows you to navigate to a different location in your application. Here's how you can use <strong>`useNavigate`</strong> and an example to demonstrate its usage:</p>
                <p>Let's create an HomePage Component.</p>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p class="color-grey">// components/HomePage.js</p>
                            <p><span class="color-blue">import</span> <span class="color-red">React</span> <span class="color-blue">from</span> <span class="color-green">'react'</span>;</p>
                            <p><span class="color-blue">import</span> { <span class="color-red">useNavigate</span> } <span class="color-blue">from</span> <span class="color-green">'react-router-dom'</span>;
                            <br />
                            <p><span class="color-blue">function</span> <span class="color-red">HomePage</span> = () => {</p>
                            <p style="margin-left:30px"><span class="color-blue">const</span> navigate = <span class="color-red">useNavigate</span>();
                            <br />
                            <p style="margin-left:30px"><span class="color-blue">function</span> <span class="color-red">navigateHandler</span>(){</p>
                            <p style="margin-left:60px"> <span class="color-red">navigate</span>(<span class="color-green">'/about'</span>);</p>
                            <p style="margin-left:30px">}</p>
                            <p style="margin-left:30px">    <span class="color-blue">return</span> (</p>
                            <p style="margin-left:60px">        &lt;div&gt;</p>
                            <p style="margin-left:90px">        &lt;h2&gt;Home Page&lt;/h2&gt;</p>
                            <p style="margin-left:90px">        &lt;p&gt;Welcome to the home page!&lt;/p&gt;</p>
                            <p style="margin-left:90px">&lt;button <span class="color-pink">onclick</span>=<span class="color-green">{navigateHandler}</span>&gt;Navigate&lt;/button&gt;</p>
                            <p style="margin-left:60px">        &lt;/div&gt;</p>
                            <p style="margin-left:30px">    );</p>
                            <p>};</p>
                            <br />
                            <p><span class="color-blue">export default</span> <span class="color-red">HomePage</span>;</p>
                        `
                    }}></div>
                </div> 
                <p>In this example, the <strong>`useNavigate`</strong> hook is used to get the navigate function. The <strong>`navigateHandler`</strong> function uses <strong>`navigate('/about')`</strong> to programmatically navigate to the About page when a button is clicked. This demonstrates how you can use <strong>`useNavigate`</strong> for programmatic navigation in a React Router v6 application.</p>
            </div>
        </section>
    )
}