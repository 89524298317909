import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../../Context";
export const pageUrl = () => "/react-js/hooks/usecontext";


export default function ReactHookUseContext() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("React Hooks - useContext() Hooks | Aspirant's Home");
        const urls = {
            'previous': '/react-js/hooks/useeffect',
            'next':'/react-js/hooks/useref'
        }
        path.setPreviousNext(urls);
    },[])

    return (
        <section className='mt-5 mb-5'>
            <h3>React useContext Hook</h3>
            <div className='mt-4 mb-4'>
                <p>
                    Context is a crucial concept in React. It allows you to pass information from a parent component to all its children, even to deeply nested levels, without passing the information through props at each level. Using context can make your code more readable and easier to understand. Context is ideal for storing information that either doesn't change or changes infrequently like user settings, theme settings, user authenticate information etc.
                </p>
                <p>
                    The <strong>`useContext`</strong> hook in React is used to access the value of a context. It allows you to consume a context that has been created using the <strong>`React.createContext`</strong> method. Here's a brief explanation of how <strong>`useContext`</strong> works:
                </p>

                <ul style={{ listStyle: 'disc' }}>
                    <li>
                        <strong>Creating a Context:</strong> First, you need to create a context using the <strong>`React.createContext`</strong> method. This method returns a context object that consists of two components: <strong>`Provider`</strong> and <strong>`Consumer`</strong>.
                        <div className='codePalateBox mt-2 mb-5'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-blue">import</span> { <span class="color-red">createContext</span> } <span class="color-blue">from</span> <span class="color-green">"react"</span>;</p>
                                    <p><span class="color-blue">const</span> UserContext = <span class="color-red">createContext</span>();</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <strong>Providing a Context Value:</strong> You provide a context value to the <strong>`Provider`</strong> component. This value will be accessible to all components that are descendants of the <strong>`Provider`</strong> component in the component tree.
                        <div className='codePalateBox mt-2 mb-5'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-blue">function</span> <span class="color-red">Component1</span>() {</p>
                                    <p style="margin-left:30px">    <span class="color-blue">const</span> [user, setUser] = <span class="color-red">useState</span>(<span class="color-green">"Jesse Hall"</span>);</p>
                                    <br />
                                    <p style="margin-left:30px">    <span class="color-blue">return</span> (</p>
                                    <p style="margin-left:60px">        &lt;<span class="color-red">UserContext.Provider</span> <span class="color-pink">value</span>=<span class="color-green">{user}</span>&gt;</p>
                                    <p style="margin-left:90px">            &lt;h1&gt;{<span class="color-green">&#96;Hello &#36;{user}!&#96;</span>}&lt;/h1&gt;</p>
                                    <p style="margin-left:90px">            &lt;<span class="color-red">Component2</span> <span class="color-pink">user</span>=<span class="color-green">{user}</span> /&gt;</p>
                                    <p style="margin-left:60px">        &lt;/<span class="color-red">UserContext.Provider</span>&gt;</p>
                                    <p style="margin-left:30px">    );</p>
                                    <p>}</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <strong>Consuming a Context Value:</strong> To consume the context value in a component, you use the <strong>`useContext`</strong> hook. This hook takes the context object as an argument and returns the current context value.
                        <div className='codePalateBox mt-2 mb-5'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-blue">import</span> { <span class="color-red">useState</span>, <span class="color-red">createContext</span>, <span class="color-red">useContext</span> } <span class="color-blue">from</span> <span class="color-green">"react"</span>;</p>
                                    <p>    <span class="color-blue">function</span> <span class="color-red">Component2</span>() {</p>
                                    <p style="margin-left:30px">        <span class="color-blue">const</span> user = <span class="color-red">useContext</span>(UserContext);</p>
                                    <br />
                                    <p style="margin-left:30px">        <span class="color-blue">return</span> (</p>
                                    <p style="margin-left:60px">            &lt;&gt;</p>
                                    <p style="margin-left:90px">                &lt;h1&gt;Component 2&lt;/h1&gt;</p>
                                    <p style="margin-left:90px">                &lt;h2&gt;<span class="color-green">{&#96;Hello &#36;{user} again!&#96;}</span>&lt;/h2&gt;</p>
                                    <p style="margin-left:60px">            &lt;/&gt;</p>
                                    <p style="margin-left:30px">        );</p>
                                    <p>    }</p>
                                `
                            }}></div>
                        </div>
                    </li>
                </ul>
            </div>
            <div className='mt-4 mb-4'>
                <h5>Why are we using the useContext hook?</h5>
                <div className='mt-4 mb-4'>
                    <ul style={{ listStyle: 'disc' }}>
                        <li>
                            <strong>`useContext`</strong> allows you to avoid prop drilling, which is the process of passing props through multiple levels of components. This can make your code cleaner and more maintainable, as you don't have to pass props manually through each intermediate component.
                        </li>
                        <li>
                            <strong>`useContext`</strong> simplifies component composition by allowing you to access context values directly in the components that need them, without the need to pass them explicitly as props.
                        </li>
                        <li>
                            Context can be used to centralize state management in your application. By storing state in a context, you can share it across multiple components without the need for complex state lifting or prop passing.
                        </li>
                        <li>
                            <strong>`useContext`</strong> can improve performance by reducing the number of re-renders caused by prop changes, especially in deeply nested component trees.
                        </li>
                    </ul>
                </div>
            </div>
        </section>
    )
}
