import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/mongo-db/create-collection";

export default function MongoCreateCollection() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Create Collection in MongoDB | Full Guide Step By Step");
        title.setKeyWords("create collection in mongodb, command to create collection in mongodb, create a collection in mongodb, create database and collection in mongodb, create and drop collection in mongodb, mongodb create collection example, mongodb get collection definition, create collection and insert data in mongodb, create collections mongodb, create collection example in mongodb, create collection in mongodb command");
        title.setPageDescription("Creating a collection in MongoDB can be done either explicitly using the `createCollection()` method or implicitly by inserting a document into a collection that does not yet exist.");
        const urls = {
            'previous': '/mongo-db/drop-database',
            'next': '/mongo-db/drop-collection'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>MongoDB - Create Collection</h3>
            <div className='mt-4 mb-5'>
                <p>
                    Creating a collection in MongoDB can be done either explicitly using the <strong>`createCollection()`</strong> method or implicitly by inserting a document into a collection that does not yet exist.
                </p>
                <h5 className='mt-5 mb-4'>Explicitly Creating a Collection</h5>
                <p>
                    Using the <strong>`createCollection()`</strong> method, you can specify various options for the collection.
                </p>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>db.<span class="color-red">createCollection</span>(name, options)</p>
                        `
                    }}></div>
                </div>
                <p>
                    Where, <strong>`name`</strong> is the name of the collection to create and <strong>`options`</strong> are optional which are the configuration options for that collection.
                </p>

                <p>For example, if you want to create a collection named <strong>`mycollection`</strong>:</p>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>use mydatabase</p>
                            <p>db.<span class="color-red">createCollection</span>(<span class="color-green">"mycollection"</span>)</p>
                        `
                    }}></div>
                </div>
                <p>With options:</p>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>db.<span class="color-red">createCollection</span>(<span class="color-green">"mycollection"</span>, { <span class="color-pink">capped</span>: <span class="color-blue">true</span>, <span class="color-pink">size</span>: <span class="color-pink">10000</span>, <span class="color-pink">max</span>: <span class="color-pink">1000</span> })</p>
                        `
                    }}></div>
                </div>
                <h5 className='mt-5 mb-4'>Implicitly Creating a Collection</h5>
                <p>
                    MongoDB will automatically create a collection if you try to insert a document into a collection that doesn't exist.
                </p>
                <p>
                    For example, If you want to insert a document into mycollection (this will create the collection if it doesn't exist):
                </p>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>use mydatabase</p>
                            <p>db.mycollection.<span class="color-red">insertOne</span>({ <span class="color-pink">name</span>: <span class="color-green">"John Doe"</span>, <span class="color-pink">age</span>: <span class="color-pink">30</span> })</p>
                        `
                    }}></div>
                </div>
                <p>
                    To verify the collections in your database, you can use the <strong>`show collections`</strong> command:
                </p>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>&gt; show collections</p>
                            <p>mycollection</p>
                        `
                    }}></div>
                </div>
            </div>
        </section>
    )
}