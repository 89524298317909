import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/mongo-db/projection";

export default function MongoProjection() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("What is Projection in MongoDB | A Complete Guide");
        title.setKeyWords("projection in mongodb, find with projection in mongodb, what is a projection in mongodb queries, findone with projection in mongodb, projection in mongodb example, projection in mongodb aggregation, how to use projection in mongodb, how to add projection in mongodb query, mongodb projection example, what is projection in mongodb, aggregation and projection in mongodb, query and projection operators in mongodb");
        title.setPageDescription("In MongoDB, the `projection` option is used to specify which fields should be included or excluded in the documents returned by a query. This can be useful for optimizing performance and reducing the amount of data transferred over the network by returning only the necessary fields.");
        const urls = {
            'previous': '/mongo-db/many-to-many-relations',
            'next': '/mongo-db/limit-records'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>MongoDB - Projection</h3>
            <div className='mt-4 mb-5'>
                <p>
                    In MongoDB, the <strong>`projection`</strong> option is used to specify which fields should be included or excluded in the documents returned by a query. This can be useful for optimizing performance and reducing the amount of data transferred over the network by returning only the necessary fields.
                </p>
                <h5 className='mt-5 mb-4'>How to Use Projection</h5>
                <p>
                    Projection is specified as a document where the field names are keys and the values are either <strong>`1`</strong> (to include the field) or <strong>`0`</strong> (to exclude the field).
                </p>
                <h5 className='mt-5 mb-4'>Including Fields</h5>
                <p>
                    To include specific fields in the result, set their value to <strong>`1`</strong>.
                </p>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>db.users.<span class="color-red">find</span>(</p>
                            <p style="margin-left:30px">    { <span class="color-pink">age</span>: { <span class="color-pink">$gt</span>: <span class="color-pink">18</span> } },</p>
                            <p style="margin-left:30px">    { <span class="color-pink">name</span>: <span class="color-pink">1</span>, <span class="color-pink">email</span>: <span class="color-pink">1</span> }</p>
                            <p>)</p>
                        `
                    }}></div>
                </div>
                <p>
                    In this example:
                </p>
                <ul style={{listStyle:'disc'}}>
                    <li>The query finds users older than 18.</li>
                    <li>The projection specifies that only the <strong>`name`</strong> and <strong>`email`</strong> fields should be included in the result.</li>
                </ul>
                <h5 className='mt-5 mb-4'>Excluding Fields</h5>
                <p>
                    To exclude specific fields, set their value to <strong>`0`</strong>.
                </p>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>db.users.<span class="color-red">find</span>(</p>
                            <p style="margin-left:30px">    { <span class="color-pink">age</span>: { <span class="color-pink">$gt</span>: <span class="color-pink">18</span> } },</p>
                            <p style="margin-left:30px">    { <span class="color-pink">password</span>: <span class="color-pink">0</span>, <span class="color-pink">_id</span>: <span class="color-pink">0</span> }</p>
                            <p>)</p>
                        `
                    }}></div>
                </div>
                <p>In this example:</p>
                <ul style={{listStyle:'disc'}}>
                    <li>The query finds users older than 18.</li>
                    <li>The projection specifies that the <strong>`password`</strong> field should be excluded from the result.</li>
                    <li>The <strong>`_id`</strong> field is also excluded.</li>
                </ul>
                <h5 className='mt-5 mb-4'>Mixed Inclusion and Exclusion</h5>
                <p>
                    MongoDB does not allow mixing inclusion and exclusion in the same projection, except for the <strong>`_id`</strong> field.
                </p>
                <h5 className='mt-5 mb-4'>Including Embedded Documents</h5>
                <p>
                    To include specific fields from embedded documents, use dot notation.
                </p>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>db.users.<span class="color-red">find</span>(</p>
                            <p style="margin-left:30px">    { <span class="color-pink">age</span>: { <span class="color-pink">$gt</span>: <span class="color-pink">18</span> } },</p>
                            <p style="margin-left:30px">    { <span class="color-green">"address.city"</span>: <span class="color-pink">1</span>, <span class="color-green">"address.zip"</span>: <span class="color-pink">1</span>, <span class="color-pink">_id</span>: <span class="color-pink">0</span> }</p>
                            <p>)</p>
                        `
                    }}></div>
                </div>
                <p>In this example:</p>
                <ul style={{listStyle:'disc'}}>
                    <li>The query finds users older than 18.</li>
                    <li>The projection includes only the <strong>`city`</strong> and <strong>`zip`</strong> fields from the <strong>`address`</strong> embedded document.</li>
                </ul>
                <h5 className='mt-5 mb-4'>Excluding Arrays</h5>
                <p>
                    To exclude specific fields from arrays, use the <strong>`$elemMatch`</strong> operator.
                </p>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>db.users.<span class="color-red">find</span>(</p>
                            <p style="margin-left:30px">    { <span class="color-pink">age</span>: { <span class="color-pink">$gt</span>: <span class="color-pink">18</span> } },</p>
                            <p style="margin-left:30px">    { <span class="color-green">"interests"</span>: { <span class="color-pink">$elemMatch</span>: { <span class="color-pink">category</span>: <span class="color-green">"sports"</span> } }, <span class="color-pink">_id</span>: <span class="color-pink">0</span> }</p>
                            <p>)</p>
                        `
                    }}></div>
                </div>
                <p>In this example:</p>
                <ul style={{listStyle:'disc'}}>
                    <li>The query finds users older than 18.</li>
                    <li>The projection includes only the array elements from the <strong>`interests`</strong> array where the <strong>`category`</strong> is "sports".</li>
                </ul>
                <h5 className='mt-5 mb-4'>Using Projection with Other Methods</h5>
                <p>
                    Projection can also be used with other MongoDB methods, such as <strong>`findOne()`</strong>, <strong>`findOneAndUpdate()`</strong>, <strong>`findOneAndDelete()`</strong>, etc.
                </p>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>db.users.<span class="color-red">findOneAndUpdate</span>(</p>
                            <p style="margin-left:30px">    { <span class="color-pink">name</span>: <span class="color-green">"John Doe"</span> },</p>
                            <p style="margin-left:30px">    { <span class="color-pink">$set</span>: { <span class="color-pink">age</span>: <span class="color-pink">30</span> } },</p>
                            <p style="margin-left:30px">    { <span class="color-pink">projection</span>: { <span class="color-pink">name</span>: <span class="color-pink">1</span>, <span class="color-pink">email</span>: <span class="color-pink">1</span>, <span class="color-pink">_id</span>: <span class="color-pink">0</span> }, <span class="color-pink">returnNewDocument</span>: <span class="color-blue">true</span> }</p>
                            <p>)</p>
                        `
                    }}></div>
                </div>
                <p>In this example:</p>
                <ul style={{listStyle:'disc'}}>
                    <li>The query finds a user with the name "John Doe".</li>
                    <li>The update sets the user's age to 30.</li>
                    <li>The projection includes only the <strong>`name`</strong> and <strong>`email`</strong> fields in the returned document.</li>
                    <li>The <strong>`returnNewDocument: true`</strong> option ensures the updated document is returned.</li>
                </ul>
                <h5 className='mt-5 mb-4'>In Mongoose</h5>
                <p>
                    If you are using Mongoose, projection can be specified in the <strong>`select`</strong> method.
                </p>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>User.<span class="color-red">find</span>({ <span class="color-pink">age</span>: { <span class="color-pink">$gt</span>: <span class="color-pink">18</span> } })</p>
                            <p>.<span class="color-red">select</span>(<span class="color-green">'name email -_id'</span>)</p>
                            <p>.<span class="color-red">exec</span>((err, users) => {</p>
                            <p style="margin-left:30px">    <span class="color-blue">if</span> (err) <span class="color-pink">console</span>.<span class="color-red">log</span>(err);</p>
                            <p style="margin-left:30px">    <span class="color-blue">else</span> <span class="color-pink">console</span>.<span class="color-red">log</span>(users);</p>
                            <p>});</p>
                        `
                    }}></div>
                </div>
                <p>In this example:</p>
                <ul style={{listStyle:'disc'}}>
                    <li>The query finds users older than 18.</li>
                    <li>The <strong>`select`</strong> method specifies that only the <strong>`name`</strong> and <strong>`email`</strong> fields should be included, and the <strong>`_id`</strong> field should be excluded.</li>
                </ul>

                <div className='mt-5 mb-4 featureClass'>
                    <p>
                        <strong>Note :</strong> Using projection effectively can help you manage the data you retrieve from your MongoDB collections, making your queries more efficient and your applications faster.
                    </p>
                </div>
            </div>
        </section>
    )
}