import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';
export const pageUrl = () => "/mongo-db/update-operators";

export default function MongoUpdateOperators() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Update Operators in MongoDB | A Complete List");
        title.setKeyWords("update operators in mongodb, array update operators in mongodb, operators in mongodb aggregation, mongodb update operator, mongodb update operators example");
        title.setPageDescription("MongoDB provides a variety of update operators that you can use to modify documents in a collection. These operators enable you to update specific fields, arrays, and other elements within a document.");
        path.setPreviousNext({});
    }, [])

    return (
        <>
            <section class='clearfix mb-3'>
                <span className='float-start'>
                    <Link to='/mongo-db/update-document' onClick={() => path.setPathName('/mongo-db/update-document')} className='btn btn-primary'> <i class="fa-solid fa-less-than"></i>&nbsp; Back to Update Document </Link>
                </span>
            </section>
            <section className='mt-5 mb-5'>
                <h3>MongoDB - Update Operators</h3>
                <p>
                    MongoDB provides a variety of update operators that you can use to modify documents in a collection. These operators enable you to update specific fields, arrays, and other elements within a document.
                </p>
                <h5 className='mt-5 mb-4'>Field Update Operators</h5>
                <ul style={{listStyle:'disc'}}>
                    <li>
                        <strong>`$set`</strong>: Sets the value of a field in a document.
                        <div className='codePalateBox mt-2 mb-2'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p>db.collection.<span class="color-red">updateOne</span>(</p>
                                    <p style="margin-left:30px">    { <span class="color-pink">_id</span>: <span class="color-pink">1</span> },</p>
                                    <p style="margin-left:30px">    { <span class="color-pink">$set</span>: { <span class="color-pink">name</span>: <span class="color-green">"John"</span>, <span class="color-pink">age</span>: <span class="color-pink">30</span> } }</p>
                                    <p>)</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <strong>`$unset`</strong>: Removes the specified field from a document.
                        <div className='codePalateBox mt-2 mb-2'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p>db.collection.<span class="color-red">updateOne</span>(</p>
                                    <p style="margin-left:30px">    { <span class="color-pink">_id</span>: <span class="color-pink">1</span> },</p>
                                    <p style="margin-left:30px">    { <span class="color-pink">$unset</span>: { <span class="color-pink">age</span>: <span class="color-green">""</span> } }</p>
                                    <p>)</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <strong>`$inc`</strong>: Increments the value of a field by a specified amount.
                        <div className='codePalateBox mt-2 mb-2'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p>db.collection.<span class="color-red">updateOne</span>(</p>
                                    <p style="margin-left:30px">    { <span class="color-pink">_id</span>: <span class="color-pink">1</span> },</p>
                                    <p style="margin-left:30px">    { <span class="color-pink">$inc</span>: { <span class="color-pink">age</span>: <span class="color-pink">1</span> } }</p>
                                    <p>)</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <strong>`$mul`</strong>: Multiplies the value of a field by a specified amount.
                        <div className='codePalateBox mt-2 mb-2'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p>db.collection.<span class="color-red">updateOne</span>(</p>
                                    <p style="margin-left:30px">    { <span class="color-pink">_id</span>: <span class="color-pink">1</span> },</p>
                                    <p style="margin-left:30px">    { <span class="color-pink">$mul</span>: { <span class="color-pink">salary</span>: <span class="color-pink">1.1</span> } }</p>
                                    <p>)</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <strong>`$rename`</strong>: Renames a field.
                        <div className='codePalateBox mt-2 mb-2'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p>db.collection.<span class="color-red">updateOne</span>(</p>
                                    <p style="margin-left:30px">    { <span class="color-pink">_id</span>: <span class="color-pink">1</span> },</p>
                                    <p style="margin-left:30px">    { <span class="color-pink">$rename</span>: { <span class="color-green">"oldName"</span>: <span class="color-green">"newName"</span> } }</p>
                                    <p>)</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <strong>`$min`</strong>: Only updates the field if the specified value is less than the current value.
                        <div className='codePalateBox mt-2 mb-2'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p>db.collection.<span class="color-red">updateOne</span>(</p>
                                    <p style="margin-left:30px">    { <span class="color-pink">_id</span>: <span class="color-pink">1</span> },</p>
                                    <p style="margin-left:30px">    { <span class="color-pink">$min</span>: { <span class="color-pink">age</span>: <span class="color-pink">25</span> } }</p>
                                    <p>)</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <strong>`$max`</strong>: Only updates the field if the specified value is greater than the current value.
                        <div className='codePalateBox mt-2 mb-2'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p>db.collection.<span class="color-red">updateOne</span>(</p>
                                    <p style="margin-left:30px">    { <span class="color-pink">_id</span>: <span class="color-pink">1</span> },</p>
                                    <p style="margin-left:30px">    { <span class="color-pink">$max</span>: { <span class="color-pink">age</span>: <span class="color-pink">25</span> } }</p>
                                    <p>)</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <strong>`$currentDate`</strong>: Sets the value of a field to the current date.
                        <div className='codePalateBox mt-2 mb-2'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p>db.collection.<span class="color-red">updateOne</span>(</p>
                                    <p style="margin-left:30px">    { <span class="color-pink">_id</span>: <span class="color-pink">1</span> },</p>
                                    <p style="margin-left:30px">    { <span class="color-pink">$currentDate</span>: { <span class="color-pink">lastModified</span>: <span class="color-blue">true</span> } }</p>
                                    <p>)</p>
                                `
                            }}></div>
                        </div>
                    </li>
                </ul>
                <h5 className='mt-5 mb-4'>Array Update Operators</h5>
                <ul style={{ listStyle: 'disc' }}>
                    <li>
                        <strong>`$push`</strong>: Adds an element to an array.
                        <div className='codePalateBox mt-2 mb-2'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p>db.collection.<span class="color-red">updateOne</span>(</p>
                                    <p style="margin-left:30px">    { <span class="color-pink">_id</span>: <span class="color-pink">1</span> },</p>
                                    <p style="margin-left:30px">    { <span class="color-pink">$push</span>: { <span class="color-pink">tags</span>: <span class="color-green">"mongodb"</span> } }</p>
                                    <p>)</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <strong>`$pop`</strong>: Removes the first or last element of an array.
                        <div className='codePalateBox mt-2 mb-2'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-grey">// Remove the last element</span></p>
                                    <p>db.collection.<span class="color-red">updateOne</span>(</p>
                                    <p style="margin-left:30px">    { <span class="color-pink">_id</span>: <span class="color-pink">1</span> },</p>
                                    <p style="margin-left:30px">    { <span class="color-pink">$pop</span>: { <span class="color-pink">tags</span>: <span class="color-pink">1</span> } }</p>
                                    <p>)</p>
                                    <p><span class="color-grey">// Remove the first element</span></p>
                                    <p>db.collection.<span class="color-red">updateOne</span>(</p>
                                    <p style="margin-left:30px">    { <span class="color-pink">_id</span>: <span class="color-pink">1</span> },</p>
                                    <p style="margin-left:30px">    { <span class="color-pink">$pop</span>: { <span class="color-pink">tags</span>: <span class="color-pink">-1</span> } }</p>
                                    <p>)</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <strong>`$pull`</strong>: Removes all array elements that match a specified query.
                        <div className='codePalateBox mt-2 mb-2'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p>db.collection.<span class="color-red">updateOne</span>(</p>
                                    <p style="margin-left:30px">    { <span class="color-pink">_id</span>: <span class="color-pink">1</span> },</p>
                                    <p style="margin-left:30px">    { <span class="color-pink">$pull</span>: { <span class="color-pink">tags</span>: <span class="color-green">"mongodb"</span> } }</p>
                                    <p>)</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <strong>`$addToSet`</strong>: Adds an element to an array only if the element does not already exist in the array.
                        <div className='codePalateBox mt-2 mb-2'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p>db.collection.<span class="color-red">updateOne</span>(</p>
                                    <p style="margin-left:30px">    { <span class="color-pink">_id</span>: <span class="color-pink">1</span> },</p>
                                    <p style="margin-left:30px">    { <span class="color-pink">$addToSet</span>: { <span class="color-pink">tags</span>: <span class="color-green">"mongodb"</span> } }</p>
                                    <p>)</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <strong>`$each`</strong>: Used with <strong>`$push`</strong> and <strong>`$addToSet`</strong> to add multiple elements to an array.
                        <div className='codePalateBox mt-2 mb-2'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p>db.collection.<span class="color-red">updateOne</span>(</p>
                                    <p style="margin-left:30px">    { <span class="color-pink">_id</span>: <span class="color-pink">1</span> },</p>
                                    <p style="margin-left:30px">    { <span class="color-pink">$push</span>: { <span class="color-pink">tags</span>: { <span class="color-pink">$each</span>: [<span class="color-green">"database"</span>, <span class="color-green">"NoSQL"</span>] } } }</p>
                                    <p>)</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <strong>`$slice`</strong>: Used with <strong>`$push`</strong> to limit the size of an array.
                        <div className='codePalateBox mt-2 mb-2'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p>db.collection.<span class="color-red">updateOne</span>(</p>
                                <p style="margin-left:30px">    { <span class="color-pink">_id</span>: <span class="color-pink">1</span> },</p>
                                <p style="margin-left:30px">    { <span class="color-pink">$push</span>: { <span class="color-pink">tags</span>: { <span class="color-pink">$each</span>: [<span class="color-green">"database"</span>, <span class="color-green">"NoSQL"</span>], <span class="color-pink">$slice</span>: <span class="color-pink">-3</span> } } }</p>
                                <p>)</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <strong>`$sort`</strong>: Used with <strong>`$push`</strong> to reorder elements in an array.
                        <div className='codePalateBox mt-2 mb-2'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p>db.collection.<span class="color-red">updateOne</span>(</p>
                                    <p style="margin-left:30px">    { <span class="color-pink">_id</span>: <span class="color-pink">1</span> },</p>
                                    <p style="margin-left:30px">    { <span class="color-pink">$push</span>: { <span class="color-pink">scores</span>: { <span class="color-pink">$each</span>: [], <span class="color-pink">$sort</span>: <span class="color-pink">1</span> } } }</p>
                                    <p>)</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <strong>`$position`</strong>: Used with <strong>`$push`</strong> to specify the position in the array at which to insert elements.
                        <div className='codePalateBox mt-2 mb-2'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p>db.collection.<span class="color-red">updateOne</span>(</p>
                                    <p style="margin-left:30px">    { <span class="color-pink">_id</span>: <span class="color-pink">1</span> },</p>
                                    <p style="margin-left:30px">    { <span class="color-pink">$push</span>: { <span class="color-pink">tags</span>: { <span class="color-pink">$each</span>: [<span class="color-green">"newTag"</span>], <span class="color-pink">$position</span>: <span class="color-pink">0</span> } } }</p>
                                    <p>)</p>
                                `
                            }}></div>
                        </div>
                    </li>
                </ul>
            </section>
        </>
    )
}