import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/html/semantics";

export default function HTMLSemantics() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("HTML - Semantics Element | Aspirant's Home");
        const urls = {
            'previous': '/html/layouts',
            'next': '/html/tables'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>HTML - Semantics Element</h3>
            <div className='mt-4 mb-5'>
                <p>
                    HTML semantic elements are those that clearly describe their meaning in a human- and machine-readable way. These elements help define the structure of web pages, making them more accessible, understandable, and maintainable.
                </p>
                <h5 className='mt-5 mb-3'>Key Semantic Elements</h5>
                <ul style={{ listStyle: 'decimal' }}>
                    <li>
                        <strong>`&lt;header&gt;`</strong>
                        <ul style={{ listStyle: 'disc' }}>
                            <li>Represents introductory content or a set of navigational links.</li>
                            <li>Typically contains headings, logos, navigation links, or introductory information.</li>
                            <li>Example<br />
                                <div className='codePalateBox mt-2 mb-4'>
                                    <div className='codePalate' dangerouslySetInnerHTML={{
                                        __html: `
                                        <p>&lt;header&gt;</p>
                                        <p class="ml-30">    &lt;h1&gt;Website Title&lt;/h1&gt;</p>
                                        <p class="ml-30">    &lt;nav&gt;</p>
                                        <p class="ml-60">        &lt;ul&gt;</p>
                                        <p class="ml-90">            &lt;li&gt;&lt;a <span class="color-pink">href</span>=<span class="color-green">"#home"</span>&gt;Home&lt;/a&gt;&lt;/li&gt;</p>
                                        <p class="ml-90">            &lt;li&gt;&lt;a <span class="color-pink">href</span>=<span class="color-green">"#about"</span>&gt;About&lt;/a&gt;&lt;/li&gt;</p>
                                        <p class="ml-90">            &lt;li&gt;&lt;a <span class="color-pink">href</span>=<span class="color-green">"#contact"</span>&gt;Contact&lt;/a&gt;&lt;/li&gt;</p>
                                        <p class="ml-60">        &lt;/ul&gt;</p>
                                        <p class="ml-30">    &lt;/nav&gt;</p>
                                        <p></header></p>
                                        `
                                    }}></div>
                                </div>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <strong>`&lt;nav&gt;`</strong>
                        <ul style={{ listStyle: 'disc' }}>
                            <li>Contains navigation links.</li>
                            <li>Intended for major navigational blocks like menus or tables of contents.</li>
                            <li>Example<br />
                                <div className='codePalateBox mt-2 mb-4'>
                                    <div className='codePalate' dangerouslySetInnerHTML={{
                                        __html: `
                                            <p>&lt;nav&gt;</p>
                                            <p class="ml-30">    &lt;ul&gt;</p>
                                            <p class="ml-60">        &lt;li&gt;&lt;a <span class="color-pink">href</span>=<span class="color-green">"#home"</span>&gt;Home&lt;/a&gt;&lt;/li&gt;</p>
                                            <p class="ml-60">        &lt;li&gt;&lt;a <span class="color-pink">href</span>=<span class="color-green">"#about"</span>&gt;About&lt;/a&gt;&lt;/li&gt;</p>
                                            <p class="ml-60">        &lt;li&gt;&lt;a <span class="color-pink">href</span>=<span class="color-green">"#services"&gt;Services&lt;/a&gt;&lt;/li&gt;</p>
                                            <p class="ml-60">        &lt;li&gt;&lt;a <span class="color-pink">href</span>=<span class="color-green">"#contact"</span>&gt;Contact&lt;/a&gt;&lt;/li&gt;</p>
                                            <p class="ml-30">    &lt;/ul&gt;</p>
                                            <p>&lt;/nav&gt;</p>
                                        `
                                    }}></div>
                                </div>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <strong>`&lt;main&gt;`</strong>
                        <ul style={{ listStyle: 'disc' }}>
                            <li>Specifies the main content of the document.</li>
                            <li>Only one <strong>`&lt;main&gt;`</strong> element should be used per document.</li>
                            <li>Example:<br />
                                <div className='codePalateBox mt-2 mb-4'>
                                    <div className='codePalate' dangerouslySetInnerHTML={{
                                        __html: `
                                            <p>&lt;main&gt;</p>
                                            <p class="ml-30">    &lt;h1&gt;Main Content&lt;/h1&gt;</p>
                                            <p class="ml-30">    &lt;p&gt;This is the primary content of the page.&lt;/p&gt;</p>
                                            <p>&lt;/main&gt;</p>
                                        `
                                    }}></div>
                                </div>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <strong>`&lt;section&gt;`</strong>
                        <ul style={{ listStyle: 'disc' }}>
                            <li>Defines sections in a document, such as chapters, headers, footers, or any thematic grouping of content.</li>
                            <li>Example:<br />
                                <div className='codePalateBox mt-2 mb-4'>
                                    <div className='codePalate' dangerouslySetInnerHTML={{
                                        __html: `
                                            <p>&lt;section&gt;</p>
                                            <p class="ml-30">    &lt;h2&gt;Section Title&lt;/h2&gt;</p>
                                            <p class="ml-30">    &lt;p&gt;Content for this section.&lt;/p&gt;</p>
                                            <p>&lt;/section&gt;</p>
                                        `
                                    }}></div>
                                </div>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <strong>`&lt;article&gt;`</strong>
                        <ul style={{ listStyle: 'disc' }}>
                            <li>Represents a self-contained composition in a document, such as a blog post, news article, or forum post.</li>
                            <li>Example:<br />
                                <div className='codePalateBox mt-2 mb-4'>
                                    <div className='codePalate' dangerouslySetInnerHTML={{
                                        __html: `
                                            <p>&lt;article&gt;</p>
                                            <p class="ml-30">    &lt;h2&gt;Article Title&lt;/h2&gt;</p>
                                            <p class="ml-30">    &lt;p&gt;This is an article.&lt;/p&gt;</p>
                                            <p>&lt;/article&gt;</p>
                                        `
                                    }}></div>
                                </div>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <strong>`&lt;aside&gt;`</strong>
                        <ul style={{ listStyle: 'disc' }}>
                            <li>Defines content aside from the main content, such as sidebars, pull quotes, or advertisements.</li>
                            <li>Example:<br />
                                <div className='codePalateBox mt-2 mb-4'>
                                    <div className='codePalate' dangerouslySetInnerHTML={{
                                        __html: `
                                            <p>&lt;aside&gt;</p>
                                            <p class="ml-30">    &lt;h2&gt;Related Information&lt;/h2&gt;</p>
                                            <p class="ml-30">    &lt;p&gt;This is related content.&lt;/p&gt;</p>
                                            <p>&lt;/aside&gt;</p>
                                        `
                                    }}></div>
                                </div>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <strong>`&lt;footer&gt;`</strong>
                        <ul style={{ listStyle: 'disc' }}>
                            <li>Represents a footer for a document or section.</li>
                            <li>Typically contains author information, copyright information, or navigational elements.</li>
                            <li>Example:<br />
                                <div className='codePalateBox mt-2 mb-4'>
                                    <div className='codePalate' dangerouslySetInnerHTML={{
                                        __html: `
                                            <p>&lt;footer&gt;</p>
                                            <p class="ml-30">    &lt;p&gt;&copy; 2024 My Website&lt;/p&gt;</p>
                                            <p class="ml-30">    &lt;address&gt;</p>
                                            <p class="ml-60">        Contact us at: &lt;a <span class="color-pink">href</span>=<span class="color-green">"mailto:info@example.com"</span>&gt;info@example.com&lt;/a&gt;.</p>
                                            <p class="ml-30">    &lt;/address&gt;</p>
                                            <p>&lt;/footer&gt;</p>
                                        `
                                    }}></div>
                                </div>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <strong>`&lt;figure&gt;` and `&lt;figcaption&gt;`</strong>
                        <ul style={{ listStyle: 'disc' }}>
                            <li>`&lt;figure&gt;`: Contains visual content, such as images, illustrations, diagrams, or code snippets.</li>
                            <li>`&lt;figcaption&gt;`: Provides a caption for the `&lt;figure&gt;` element.</li>
                            <li>Example:<br />
                                <div className='codePalateBox mt-2 mb-4'>
                                    <div className='codePalate' dangerouslySetInnerHTML={{
                                        __html: `
                                            <p>&lt;figure&gt;</p>
                                            <p class="ml-30">    &lt;img <span class="color-pink">src</span>=<span class="color-green">"image.jpg"</span> <span class="color-pink">alt</span>=<span class="color-green">"Description of image"</span>&gt;</p>
                                            <p class="ml-30">    &lt;figcaption&gt;Caption for the image&lt;/figcaption&gt;</p>
                                            <p>&lt;/figure&gt;</p>
                                        `
                                    }}></div>
                                </div>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <strong>`&lt;mark&gt;`</strong>
                        <ul style={{ listStyle: 'disc' }}>
                            <li>Highlights or marks text for reference or emphasis.</li>
                            <li>    Example:<br />
                                <div className='codePalateBox mt-2 mb-4'>
                                    <div className='codePalate' dangerouslySetInnerHTML={{
                                        __html: `
                                            <p>&lt;p&gt;This is &lt;mark&gt;highlighted&lt;/mark&gt; text.&lt;/p&gt;</p>
                                        `
                                    }}></div>
                                </div>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <strong>`&lt;time&gt;`</strong>
                        <ul style={{ listStyle: 'disc' }}>
                            <li>Represents a specific time or date.</li>
                            <li>Example:<br />
                                <div className='codePalateBox mt-2 mb-4'>
                                    <div className='codePalate' dangerouslySetInnerHTML={{
                                        __html: `
                                            <p>&lt;p&gt;The event starts at &lt;time <span class="color-pink">datetime</span>=<span class="color-green">"2024-06-21T20:00"</span>&gt;8 PM&lt;/time&gt;.&lt;/p&gt;</p>
                                        `
                                    }}></div>
                                </div>
                            </li>
                        </ul>
                    </li>
                </ul>
                <p className='mt-5 featureClass'>
                    Using semantic elements appropriately enhances the meaning and structure of web content, making it more accessible and user-friendly.
                </p>
            </div>
        </section>
    )
}