import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../../Context";
export const pageUrl = () => "/react-js/hooks/usememo";

export default function ReactHookUseMemo() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("React Hooks - useMemo() Hooks | Aspirant's Home");
        const urls = {
            'previous': '/react-js/hooks/usecallback',
            'next':'/react-js/hooks/custom'
        }
        path.setPreviousNext(urls);
    },[])

    return (
        <section className='mt-5 mb-5'>
            <h3>React useMemo Hook</h3>
            <div className='mt-4 mb-4'>
                <p>
                    The <strong>`useMemo`</strong> hook in React is used to memoize values, similar to <strong>`useCallback`</strong> but for any type of value, not just functions. It's useful for optimizing performance by memoizing expensive computations so that they are only re-computed when their dependencies change.
                </p>
                <p>
                    Here's a brief overview of how <strong>`useMemo`</strong> works:
                </p>
                <ul style={{listStyle:'disc'}}>
                    <li>
                        <strong>Creating a Memoized Value:</strong> You use the useMemo hook to create a memoized value. The hook takes two arguments: a function that computes the value and an array of dependencies.
                    </li>
                    <li>
                        <strong>Dependencies:</strong> The dependencies array is used to specify the values that the memoized value depends on. If any of these values change, the memoized value will be re-computed.
                    </li>
                    <li>
                        <strong>Usage:</strong> You can then use the memoized value in your components. The value will only be re-computed when one of its dependencies changes.
                    </li>
                </ul>

                <p>
                    Here's a simple example to demonstrate the usage of <strong>`useMemo`</strong>:
                </p>
                <div className='codePalateBox mt-2 mb-5'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-blue">import</span> <span class="color-red">React</span>, { <span class="color-red">useState</span>, <span class="color-red">useMemo</span> } <span class="color-blue">from</span> <span class="color-green">'react'</span>;</p>
                            <br />
                            <p><span class="color-blue">function</span> <span class="color-red">ExpensiveCalculation</span>({ number }) {</p>
                            <p style="margin-left:30px">    <span class="color-blue">const</span> result = <span class="color-red">useMemo</span>(() => {</p>
                            <p style="margin-left:60px">        <span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">'Calculating...'</span>);</p>
                            <p style="margin-left:60px">        <span class="color-blue">let</span> sum = 0;</p>
                            <p style="margin-left:60px">        <span class="color-blue">for</span> (<span class="color-blue">let</span> i = 1; i <= number; i++) {</p>
                            <p style="margin-left:90px">             sum += i;</p>
                            <p style="margin-left:60px">        }</p>
                            <p style="margin-left:60px">        <span class="color-blue">return</span> sum;</p>
                            <p style="margin-left:30px">    }, [number]); <span class="color-grey">// Depend on number</span></p>
                            <p style="margin-left:30px">    <span class="color-blue">return</span> &lt;p&gt;Result: {result}&lt;/p&gt;;</p>
                            <p>}</p>
                            <br />    
                            <p><span class="color-blue">function</span> <span class="color-red">App</span>() {</p>
                            <p style="margin-left:30px">    <span class="color-blue">const</span> [count, setCount] = <span class="color-red">useState</span>(1);</p>
                            <p style="margin-left:30px">    <span class="color-blue">return</span> (</p>
                            <p style="margin-left:60px">        &lt;div&gt;</p>
                            <p style="margin-left:90px">            &lt;button <span class="color-pink">onClick</span>=<span class="color-green">{() => setCount(count + 1)}</span>&gt;Increment&lt;/button&gt;</p>
                            <p style="margin-left:90px">            &lt;<span class="color-red">ExpensiveCalculation</span> <span class="color-pink">number</span>=<span class="color-green">{count}</span> /&gt;</p>
                            <p style="margin-left:60px">        &lt;/div&gt;</p>
                            <p style="margin-left:30px">    );</p>
                            <p>}</p>
                            <br />
                            <p><span class="color-blue">export default</span> <span class="color-red">App</span>;</p>
                        
                        `
                    }}></div>
                </div>

                <p>
                    In <strong>`ExpensiveCalculation`</strong> Component we pass a prop number and calculates the sum of numbers. The <strong>`useMemo`</strong> hook is used to memoize the result of the calculation. It takes a function that performs the calculation and an array of dependencies.
                </p>
                <p>
                    In <strong>`App`</strong> Component when the button is clicked and <strong>`count`</strong> is incremented, the <strong>`ExpensiveCalculation`</strong> component is re-rendered because we pass the <strong>`count`</strong> as a prop named <strong>`number`</strong>. The calculation function is then re-executed when the <strong>`number`</strong> prop changes. This optimizes performance by avoiding unnecessary re-calculations.
                </p>
            </div>
        </section>
    )
}
