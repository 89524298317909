import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../../Context";
import { Link } from 'react-router-dom';
export const pageUrl = () => "/node-js/built-in-modules/querystring";

export default function QueryStringModule() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("NodeJs - Query String Module | Aspirant's Home");
        path.setPreviousNext({});
    }, [])

    return (
        <div className='contentPage'>
            <section class='clearfix mb-3'>
                <span className='float-start'>
                    <Link to='/node-js/built-in-modules' onClick={() => path.setPathName('/node-js/built-in-modules')} className='btn btn-primary'> <i class="fa-solid fa-less-than"></i>&nbsp; Back to Built-In Modules </Link>
                </span>
            </section>
            <section className='mt-5 mb-5'>
                <h3>Node Js Query String Module</h3>
                <div className='mt-4'>
                    <p>The querystring module provides utilities for parsing and formatting URL query strings. It can be used to work with query parameters in URLs.</p>
                    <p>The syntax for including the querystring module in your application: </p>
                    <div className='codePalateBox mt-2 mb-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `<p><span class='color-blue'>var</span> querystring = <span class='color-yellow'>require</span>(<span class='color-green'>'querystring'</span>);</p>
                                    `
                        }}></div>
                    </div>
                </div>
                <div className='mt-4'>
                    <p>Here's a basic overview of how to use the querystring module:</p>
                    <ul style={{ 'listStyle': 'decimal' }}>
                        <li className='mt-4'>
                            <strong>Parsing Query Strings:</strong>
                            <p>You can use the <strong>`querystring.parse()`</strong> method to parse a query string into a JavaScript object:</p>
                            <div className='codePalateBox mt-2 mb-2'>
                                <div className='codePalate' dangerouslySetInnerHTML={{
                                    __html: `<p><span class='color-blue'>const</span> querystring = <span class='color-yellow'>require</span>(<span class='color-green'>'querystring'</span>);</p>
                                    <br />
                                    <p><span class='color-blue'>const</span> queryString = <span class='color-green'>'foo=bar&baz=qux'</span>;</p>
                                    <p><span class='color-blue'>const</span> parsedQuery = querystring.<span class='color-red'>parse</span>(queryString);</p>
                                    <br />
                                    <p><span class='color-pink'>console</span>.<span class='color-red'>log</span>(parsedQuery);</p>`
                                }}></div>
                            </div>
                            <p>Which will give you this result:</p>
                            <div className='codePalateBox mt-2 mb-2'>
                                <div className='codePalate' dangerouslySetInnerHTML={{
                                    __html: `<p>{ foo: <span class='color-green'>'bar'</span>, baz: <span class='color-green'>'qux'</span> }</p>`
                                }}></div>
                            </div>
                        </li>
                        <li className='mt-4'>
                            <strong>Formatting Query Strings:</strong>
                            <p>The <strong>`querystring.stringify()`</strong> method is used to convert a JavaScript object into a query string:</p>
                            <div className='codePalateBox mt-2 mb-2'>
                                <div className='codePalate' dangerouslySetInnerHTML={{
                                    __html: `<p><span class='color-blue'>const</span> obj = { <span class='color-pink'>foo</span>: <span class='color-green'>'bar'</span>, <span class='color-pink'>baz</span>: <span class='color-green'>'qux'</span> };</p>
                                    <p><span class='color-blue'>const</span> queryString = querystring.<span class='color-red'>stringify</span>(obj);</p>
                                    <br />
                                    <p><span class='color-pink'>console</span>.<span class='color-red'>log</span>(queryString);</p>
                                    `
                                }}></div>
                            </div>
                            <p>Which will give you this result:</p>
                            <div className='codePalateBox mt-2 mb-2'>
                                <div className='codePalate' dangerouslySetInnerHTML={{
                                    __html: `<p class="color-green">'foo=bar&baz=qux'</p>`
                                }}></div>
                            </div>
                        </li>
                        <li className='mt-4'>
                            <strong>Encoding and Decoding:</strong>
                            <p>The <strong>`querystring.escape()`</strong> and <strong>`querystring.unescape()`</strong> methods can be used to encode and decode query strings:</p>
                            <div className='codePalateBox mt-2 mb-2'>
                                <div className='codePalate' dangerouslySetInnerHTML={{
                                    __html: `<p><span class='color-blue'>const</span> encoded = querystring.<span class='color-red'>escape</span>(<span class='color-green'>'foo=bar'</span>);</p>
                                    <p><span class='color-pink'>console</span>.<span class='color-red'>log</span>(encoded); <span class='color-grey'>// 'foo%3Dbar'</span></p>
                                    <br />
                                    <p><span class='color-blue'>const</span> decoded = querystring.<span class='color-red'>unescape</span>(encoded);</p>
                                    <p><span class='color-pink'>console</span>.<span class='color-red'>log</span>(decoded); <span class='color-grey'>// 'foo=bar'</span></p>                                    
                                    `
                                }}></div>
                            </div>
                        </li>
                    </ul>
                </div>
            </section>
        </div>
    )
}
