import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/css/text/decoration";

export default function TextDecoration() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Text Decoration in CSS | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/css/text/alignment',
            'next': '/css/text/transformation'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>Text Decoration</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <strong>`text-decoration`</strong> property in CSS is used to add decorative elements to text, such as underlines, overlines, line-throughs, and more. This property allows you to control the appearance of these decorations, including their color, style, and position relative to the text.
                </p>
                <p>
                    It has a basic syntax -
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>element {</p>
                            <p class="ml-30">    <span class="color-green">text-decoration</span>: value;</p>
                            <p>}</p>
                        `
                    }}></div>
                </div>
                <p>
                    Where, value can be a single decoration or a combination of several values to customize the decoration.
                </p>

                <h5 className="mt-5 mb-3">Sub-Properties of `text-decoration`</h5>
                <p>
                    The <strong>`text-decoration`</strong> property in CSS can be broken down into several sub-properties that provide fine-grained control over text decorations like underlines, overlines, and line-throughs. Here's a detailed explanation of each:
                </p>
                <ul style={{ listStyle: 'decimal' }}>
                    <li>
                        <strong>`text-decoration-line`</strong> <br />
                        It specifies the type of line decoration to apply to the text. It can be <strong>`none`</strong>, <strong>`underline`</strong>, <strong>`overline`</strong> and <strong>`line-through`</strong>.
                        <ul style={{ listStyle: 'disc' }}>
                            <li><strong>`none`</strong>: It is used for no text decoration.</li>
                            <li><strong>`underline`</strong>: It is used to add an underline under the text.</li>
                            <li><strong>`overline`</strong>: It is used to add a line above the text.</li>
                            <li><strong>`line-through`</strong>: It is used to add a line through the text (strikethrough).</li>
                        </ul>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p>&lt;style&gt;</p>
                                <p class="ml-30">    p.none{</p>
                                <p class="ml-60">        <span class="color-green">text-decoration-line</span> : none;</p>
                                <p class="ml-30">    }</p>
                                <p class="ml-30">    p.underline{</p>
                                <p class="ml-60">        <span class="color-green">text-decoration-line</span> : underline;</p>
                                <p class="ml-30">    }</p>
                                <p class="ml-30">    p.overline{</p>
                                <p class="ml-60">        <span class="color-green">text-decoration-line</span> : overline;</p>
                                <p class="ml-30">    }</p>
                                <p class="ml-30">    p.line-through{</p>
                                <p class="ml-60">        <span class="color-green">text-decoration-line</span> : line-through;</p>
                                <p class="ml-30">    }</p>
                                <p>&lt;/style&gt;</p>
                                <p>&lt;p <span class="color-pink">class</span>=<span class="color-green">"none"</span>&gt; This paragraph is no decoration. &lt;/p&gt;</p>
                                <p>&lt;p <span class="color-pink">class</span>=<span class="color-green">"underline"</span>&gt; This paragraph is decorate with underline. &lt;/p&gt;</p>
                                <p>&lt;p <span class="color-green">class</span>=<span class="color-green">"overline"</span>&gt; This paragraph decorate with overline. &lt;/p&gt;</p>
                                <p>&lt;p <span class="color-green">class</span>=<span class="color-green">"line-through"</span>&gt; This paragraph is decorate with line-through. &lt;/p&gt;</p>
                                `
                            }}></div>
                        </div>
                        <p>
                            Output will be the following -
                        </p>
                        <div className='outputPalateBox mb-4 mt-3'>
                            <div className='outputPalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <style>
                                        p.none{
                                            text-decoration-line : none;
                                        }
                                        p.underline{
                                            text-decoration-line : underline;
                                        }
                                        p.overline{
                                            text-decoration-line : overline;
                                        }
                                        p.line-through{
                                            text-decoration-line : line-through;
                                        }
                                    </style>
                                    <p class="none"> This paragraph is no decoration. </p>
                                    <p class="underline"> This paragraph is decorate with underline. </p>
                                    <p class="overline"> This paragraph decorate with overline. </p>
                                    <p class="line-through"> This paragraph is decorate with line-through. </p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <strong>`text-decoration-color`</strong> <br />
                        It specifies the color of the text decoration line. It can be a named color (like <strong>`red`</strong>), a HEX value (like <strong>`#ff0000`</strong>), an RGB or RGBA value (like <strong>`rgb(255, 0, 0)`</strong> or <strong>`rgba(255, 0, 0, 0.8)`</strong>), an HSL or HSLA value (like <strong>`hsl(0, 100%, 50%)`</strong> or <strong>`hsla(0, 100%, 50%, 0.8)`</strong>)
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p>&lt;style&gt;</p>
                                <p class="ml-30">    p.colorname{</p>
                                <p class="ml-60">        <span class="color-green">text-decoration-color</span> : blue;</p>
                                <p class="ml-60">        <span class="color-green">text-decoration-line</span> : underline;</p>
                                <p class="ml-30">    }</p>
                                <p class="ml-30">    p.colorcode{</p>
                                <p class="ml-60">        <span class="color-green">text-decoration-color</span> : <span class="color-pink">#e80707</span>;</p>
                                <p class="ml-60">        <span class="color-green">text-decoration-line</span> : underline;</p>
                                <p class="ml-30">    }</p>
                                <p>&lt;/style&gt;</p>
                                <p>&lt;p <span class="color-pink">class</span>=<span class="color-green">"colorname"</span>&gt; This paragraph is underline with blue color. &lt;/p&gt;</p>
                                <p>&lt;p <span class="color-pink">class</span>=<span class="color-green">"colorcode"</span>&gt; This paragraph is underline with red color. &lt;/p&gt;</p>
                                `
                            }}></div>
                        </div>
                        <p>
                            Output will be the following -
                        </p>
                        <div className='outputPalateBox mb-4 mt-3'>
                            <div className='outputPalate' dangerouslySetInnerHTML={{
                                __html: `
                                <style>
                                    p.colorname{
                                        text-decoration-color : blue;
                                        text-decoration-line : underline;
                                    }
                                    p.colorcode{
                                        text-decoration-color : #e80707;
                                        text-decoration-line : underline;
                                    }
                                </style>
                                <p class="colorname"> This paragraph is underline with blue color. </p>
                                <p class="colorcode"> This paragraph is underline with red color. </p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <strong>`text-decoration-style`</strong> <br />
                        It specifies the style of the text decoration line.
                        <ul style={{ listStyle: 'disc' }}>
                            <li><strong>`solid`</strong>: It's make a single solid line.</li>
                            <li><strong>`double`</strong>: It's make two solid line.</li>
                            <li><strong>`dotted`</strong>: It's make a line consisting of dots.</li>
                            <li><strong>`dashed`</strong>: It's make a line consisting of dashed.</li>
                            <li><strong>`wavy`</strong>: It's make a wavy line.</li>
                        </ul>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p>&lt;style&gt;</p>
                                <p class="ml-30">    p.dotted{</p>
                                <p class="ml-60">        <span class="color-green">text-decoration-line</span> : underline;</p>
                                <p class="ml-60">        <span class="color-green">text-decoration-style</span> : dotted;</p>
                                <p class="ml-60">        <span class="color-green">text-decoration-color</span> : <span class="color-pink">#e80707</span>;</p>
                                <p class="ml-30">    }</p>
                                <p class="ml-30">    p.dashed{</p>
                                <p class="ml-60">        <span class="color-green">text-decoration-line</span> : underline;</p>
                                <p class="ml-60">        <span class="color-green">text-decoration-style</span> : dashed;</p>
                                <p class="ml-60">        <span class="color-green">text-decoration-color</span> : blue;</p>
                                <p class="ml-30">    }</p>
                                <p class="ml-30">    p.wavy{</p>
                                <p class="ml-60">        <span class="color-green">text-decoration-line</span> : underline;</p>
                                <p class="ml-60">        <span class="color-green">text-decoration-style</span> : wavy;</p>
                                <p class="ml-60">        <span class="color-green">text-decoration-color</span> : green;</p>
                                <p class="ml-30">    }</p>
                                <p>&lt;/style&gt;</p>
                                <p>&lt;p <span class="color-pink">class</span>=<span class="color-green">"dotted"</span>&gt; This paragraph is decorate with dotted underline. &lt;/p&gt;</p>
                                <p>&lt;p <span class="color-pink">class</span>=<span class="color-green">"dashed"</span>&gt; This paragraph is decorate with dashed underline. &lt;/p&gt;</p>
                                <p>&lt;p <span class="color-pink">class</span>=<span class="color-green">"wavy"</span>&gt; This paragraph is decorate with wavy underline. &lt;/p&gt;</p>
                                `
                            }}></div>
                        </div>
                        <p>
                            Output will be the following -
                        </p>
                        <div className='outputPalateBox mb-4 mt-3'>
                            <div className='outputPalate' dangerouslySetInnerHTML={{
                                __html: `
                                <style>
                                    p.dotted{
                                        text-decoration-line : underline;
                                        text-decoration-style : dotted;
                                        text-decoration-color : #e80707;
                                    }
                                    p.dashed{
                                        text-decoration-line : underline;
                                        text-decoration-style : dashed;
                                        text-decoration-color : blue;
                                    }
                                    p.wavy{
                                        text-decoration-line : underline;
                                        text-decoration-style : wavy;
                                        text-decoration-color : green;
                                    }
                                </style>
                                <p class="dotted"> This paragraph is decorate with dotted underline. </p>
                                <p class="dashed"> This paragraph is decorate with dashed underline. </p>
                                <p class="wavy"> This paragraph is decorate with wavy underline. </p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <strong>`text-decoration-thickness`</strong> <br />
                        It specifies the thickness of the text decoration line. It's value should be - 
                        <ul style={{ listStyle: 'disc' }}>
                            <li>
                                <strong>`auto`</strong>: The browser automatically sets the thickness based on the font size. It is a default value.
                            </li>
                            <li>
                                <strong>`from-font`</strong>: It uses the thickness specified by the font itself.
                            </li>
                            <li>
                                <strong>`&lt;length&gt;`</strong>: It specifies a fixed thickness using units like <strong>`px`</strong>, <strong>`em`</strong>, <strong>`rem`</strong>, etc.
                            </li>
                            <li>
                                <strong>`&lt;percentage&gt;`</strong>: It specifies the thickness as a percentage of the font size.
                            </li>
                        </ul>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p>&lt;style&gt;</p>
                                <p class="ml-30">    p.thickness2X{</p>
                                <p class="ml-60">        <span class="color-green">text-decoration-line</span> : underline;</p>
                                <p class="ml-60">        <span class="color-green">text-decoration-style</span> : dotted;</p>
                                <p class="ml-60">        <span class="color-green">text-decoration-color</span> : <span class="color-pink">#e80707</span>;</p>
                                <p class="ml-60">        <span class="color-green">text-decoration-thickness</span> : <span class="color-pink">5px</span>;</p>
                                <p class="ml-30">    }</p>
                                <p class="ml-30">    p.thickness5X{</p>
                                <p class="ml-60">        <span class="color-green">text-decoration-line</span> : underline;</p>
                                <p class="ml-60">        <span class="color-green">text-decoration-style</span> : dashed;</p>
                                <p class="ml-60">        <span class="color-green">text-decoration-color</span> : blue;</p>
                                <p class="ml-60">        <span class="color-green">text-decoration-thickness</span> : <span class="color-pink">50%</span>;</p>
                                <p class="ml-30">    }</p>
                                <p>&lt;/style&gt;</p>
                                <p>&lt;p <span class="color-pink">class</span>=<span class="color-green">"thickness2X"</span>&gt; This paragraph is decorate with 5px dotted underline. &lt;/p&gt;</p>
                                <p>&lt;p <span class="color-pink">class</span>=<span class="color-green">"thickness5X"</span>&gt; This paragraph is decorate with dashed (50% of font size) underline. &lt;/p&gt;</p>
                                `
                            }}></div>
                        </div>
                        <p>
                            Output will be the following -
                        </p>
                        <div className='outputPalateBox mb-4 mt-3'>
                            <div className='outputPalate' dangerouslySetInnerHTML={{
                                __html: `
                                <style>
                                    p.thickness2X{
                                        text-decoration-line : underline;
                                        text-decoration-style : dotted;
                                        text-decoration-color : #e80707;
                                        text-decoration-thickness : 5px;
                                    }
                                    p.thickness5X{
                                        text-decoration-line : underline;
                                        text-decoration-style : dashed;
                                        text-decoration-color : blue;
                                        text-decoration-thickness : 50%;
                                    }
                                </style>
                                <p class="thickness2X"> This paragraph is decorate with 5px dotted underline. </p>
                                <p class="thickness5X"> This paragraph is decorate with dashed (50% of font size) underline. </p>
                                `
                            }}></div>
                        </div>
                    </li>
                </ul>

                <h5 className="mt-5 mb-3">Shorthand of `text-decoration`</h5>
                <p>
                    You can also use the shorthand version of the <strong>`text-decoration`</strong> property to specify multiple aspects at once, such as style, color, and line type. It has a basic syntax -
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>element {</p>
                            <p class="ml-30">    <span class="color-green">text-decoration</span>: &lt;text-decoration-line&gt; &lt;text-decoration-style&gt; &lt;text-decoration-color&gt;;</p>
                            <p>}</p>
                        `
                    }}></div>
                </div>
                <h5 className="mt-5 mb-3">Examples of `text-decoration`</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;style&gt;</p>
                        <p class="ml-30">    p.shorthand{</p>
                        <p class="ml-60">        <span class="color-green">text-decoration</span> : underline dotted blue;</p>
                        <p class="ml-30">    }</p>
                        <p>&lt;/style&gt;</p>
                        <p>&lt;p <span class="color-pink">class</span>=<span class="color-green">"shorthand"</span>&gt;This is a paragraph with blue dotted underline.&lt;/p&gt;</p>
                        `
                    }}></div>
                </div>
                <p>
                    Output will be the following -
                </p>
                <div className='outputPalateBox mb-4 mt-3'>
                    <div className='outputPalate' dangerouslySetInnerHTML={{
                        __html: `
                            <style>
                                p.shorthand{
                                    text-decoration : underline dotted blue;
                                }
                            </style>
                            <p class="shorthand">This is a paragraph with blue dotted underline.</p>
                        `
                    }}></div>
                </div>
            </div>
        </section>
    )
}