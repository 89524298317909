import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/css/margins";

export default function CSSMargin() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Margin in CSS | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/css/border-rounded',
            'next': '/css/margin/collapse'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>Margin</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The CSS <strong>`margin`</strong> property is used to create space around elements, outside of any defined borders. Margins can be set for each side of an element (top, right, bottom, and left) and can be used to control the spacing between elements on a webpage. It has a basic syntax -
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>selector {</p>
                            <p class="ml-30">    <span class="color-green">margin</span>: top right bottom left;</p>
                            <p>}</p>
                        `
                    }}></div>
                </div>
                <p>
                    Where, <strong>`top`</strong> specifies the top margin, <strong>`right`</strong> specifies the right margin, <strong>`bottom`</strong> specifies the bottom margin and <strong>`left`</strong> specifies the left margin.
                </p>

                <h5 className="mt-5 mb-3">What is Margin ?</h5>
                <p>
                    Margins are used to create space around elements, outside of any defined borders.
                </p>

                <div className='outputPalateBox mb-4 mt-3' style={{'border':'none'}}>
                    <div className='outputPalate' dangerouslySetInnerHTML={{
                        __html: `
                            <style>
                                .fullBox{
                                    background: #CCC;
                                    height: 300px;
                                    width:600px;
                                    position: relative;
                                    border: 1px dashed black;
                                }

                                .textArea{
                                    margin: 50px;
                                    border: 1px dashed red;
                                    background: yellow;
                                    color: red;
                                    position: absolute;
                                    height:200px;
                                    width:500px;
                                    justify-content: center;
                                    display: flex;
                                    align-items: center;
                                }
                            </style>
                            <div class="fullBox">
                                <div class="textArea">This is text part of this box</div>
                            </div>
                        `
                    }}></div>
                </div>

                <p>
                    Here, in the above example you can see the yellow box it is <strong>`50px`</strong> margin from each side from grey box.
                </p>

                <h5 className="mt-5 mb-3">Examples of Margin</h5>
                <p>
                    Let's see how margin is worked in different senario.
                </p>
                <h5 className="mt-5 mb-3">Example 1 : Uniform Margin</h5>
                <p>
                    To set a uniform margin on all four sides of an element.
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>&lt;style&gt;</p>
                            <p class="ml-30">    .box1 {</p>
                            <p class="ml-60">        <span class="color-green">background-color</span>: <span class="color-pink">#3498db</span>;</p>
                            <p class="ml-60">        <span class="color-green">margin</span>: <span class="color-pink">70px</span>; <span class="color-grey">/* 70px margin on all sides */</span></p>
                            <p class="ml-60">        <span class="color-green">text-align</span>: center;</p>
                            <p class="ml-30">    }</p>
                            <p>&lt;/style&gt;</p>
                            <p>&lt;div <span class="color-pink">class</span>=<span class="color-green">"box1"</span>&gt;This has a uniform 70px margin.&lt;/div&gt;</p>
                        `
                    }}></div>
                </div>
                <p>
                    Output will be the following -
                </p>
                <div className='outputPalateBox mb-4 mt-3' style={{'padding':'0'}}>
                    <div className='outputPalate' dangerouslySetInnerHTML={{
                        __html: `
                        <style>
                            .box1 {
                                background-color: #3498db;
                                margin: 70px;
                                text-align: center;
                            }
                        </style>
                        <div class="box1">This has a uniform 70px margin.</div>
                        `
                    }}></div>
                </div>

                <h5 className="mt-5 mb-3">Example 2: Different Margins on Each Side</h5>
                <p>
                    You can set different margins for each side of an element.
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>&lt;style&gt;</p>
                            <p class="ml-30">    .box2 {</p>
                            <p class="ml-60">        <span class="color-green">background-color</span>: <span class="color-pink">#2ecc71</span>;</p>
                            <p class="ml-60">        <span class="color-green">text-align</span>: center;</p>
                            <p class="ml-60">        <span class="color-green">margin</span>: <span class="color-pink">10px 30px 50px 70px</span>; <span class="color-grey">/* top, right, bottom, left */</span></p>
                            <p class="ml-30">    }</p>
                            <p>&lt;/style&gt;</p>
                            <p>&lt;div <span class="color-pink">class</span>=<span class="color-green">"box2"</span>&gt;This has a 10px top margin, 30px right margin, 50px bottom margin, 70px left margin.&lt;/div&gt;</p>
                        `
                    }}></div>
                </div>
                <p>
                    Output will be the following -
                </p>
                <div className='outputPalateBox mb-4 mt-3' style={{'padding':'0'}}>
                    <div className='outputPalate' dangerouslySetInnerHTML={{
                        __html: `
                            <style>
                                .box2 {
                                    background-color: #2ecc71;
                                    text-align: center;
                                    margin: 10px 30px 50px 70px; /* top, right, bottom, left */
                                }
                            </style>
                            <div class="box2">This has a 10px top margin, 30px right margin, 50px bottom margin, 70px left margin.</div>
                        `
                    }}></div>
                </div>

                <h5 className="mt-5 mb-3">Example 3: Auto Margin for Centering</h5>
                <p>
                    Using <strong>`margin: auto;`</strong> to center an element horizontally within its container.
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;style&gt;</p>
                        <p class="ml-30">    .box3 {</p>
                        <p class="ml-60">        <span class="color-green">background-color</span>: <span class="color-pink">#e74c3c</span>;</p>
                        <p class="ml-60">        <span class="color-green">margin</span>: 0 auto; <span class="color-grey">/* Center horizontally */</span></p>
                        <p class="ml-60">        <span class="color-green">height</span>: <span class="color-pink">100px;</span></p>
                        <p class="ml-60">        <span class="color-green">width</span>: <span class="color-pink">70%</span>;</p>
                        <p class="ml-30">    }</p>
                        <p>&lt;/style&gt;</p>
                        <p>&lt;div <span class="color-pink">class</span>=<span class="color-green">"box3"</span>&gt;This has a auto margin.&lt;/div&gt;</p>
                        `
                    }}></div>
                </div>
                <p>
                    Output will be the following -
                </p>
                <div className='outputPalateBox mb-4 mt-3' style={{'padding':'0','height':'200px','width': '100%'}}>
                    <div className='outputPalate' dangerouslySetInnerHTML={{
                        __html: `
                        <style>
                            .box3 {
                                background-color: #e74c3c;
                                margin: 0 auto; /* Center horizontally */
                                height: 100px;
                                width: 70%;
                            }
                        </style>
                        <div class="box3">This has a auto margin.</div>
                        `
                    }}></div>
                </div>
            </div>
        </section>
    )
}