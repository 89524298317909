import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';
export const pageUrl = () => "/mongo-db/mongoose/validation";

export default function MongooseValidation() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Validation in Mongoose Schema");
        title.setKeyWords("validation in mongoose schema, email validation in mongoose schema, password validation in mongoose schema, mongoose schema validation example, mongoose schema validation not working, mongodb schema validation example, schema validation mongodb, mongoose validate schema, validation mongoose, mongoose schema custom validation, mongodb schema validation, schema in mongoose, mongoose validate email, mongoose matching validation email");
        title.setPageDescription("Validation in Mongoose ensures that the data stored in the MongoDB database meets specific requirements defined in the schema. This is crucial for maintaining data integrity and consistency. Mongoose provides built-in validation rules as well as the ability to define custom validation functions.");
        path.setPreviousNext({});
    }, [])

    return (
        <>
            <section class='clearfix mb-3'>
                <span className='float-start'>
                    <Link to='/mongo-db/mongoose/introduction' onClick={() => path.setPathName('/mongo-db/mongoose/introduction')} className='btn btn-primary'> <i class="fa-solid fa-less-than"></i>&nbsp; Back to Mongoose </Link>
                </span>
            </section>
            <section className='mt-5 mb-5'>
                <h3>Mongoose - Validation</h3>
                <div className='mt-3 mb-4'>
                    <p>
                        Validation in Mongoose ensures that the data stored in the MongoDB database meets specific requirements defined in the schema. This is crucial for maintaining data integrity and consistency. Mongoose provides built-in validation rules as well as the ability to define custom validation functions.
                    </p>
                    <h5 className='mt-4'>Built-in Validation</h5>
                    <p>
                        Mongoose schemas support several built-in validation rules, such as:
                    </p>
                    <ul style={{listStyle:'decimal'}}>
                        <li><strong>required</strong>: Ensures that a field is not empty.</li>
                        <li><strong>min and max</strong>: Sets minimum and maximum values for numbers.</li>
                        <li><strong>minLength and maxLength</strong>: Sets minimum and maximum lengths for strings.</li>
                        <li><strong>enum</strong>: Restricts a field's value to a specified set of values.</li>
                        <li><strong>match</strong>: Ensures that a string matches a given regular expression.</li>
                    </ul>
                    <p>
                        You can check the below example of built-in validation -
                    </p>
                    <div className='codePalateBox mt-2 mb-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                                <p><span class="color-blue">const</span> mongoose = <span class="color-yellow">require</span>(<span class="color-green">'mongoose'</span>);</p>
                                <p><span class="color-blue">const</span> Schema = mongoose.<span class="color-red">Schema</span>;</p>
                                <br />
                                <p><span class="color-blue">const</span> userSchema = new <span class="color-red">Schema</span>({</p>
                                <p style="margin-left:30px">    <span class="color-pink">name</span>: { <span class="color-pink">type</span>: <span class="color-red">String</span>, <span class="color-pink">required</span>: <span class="color-blue">true</span> },</p>
                                <p style="margin-left:30px">    <span class="color-pink">email</span>: { <span class="color-pink">type</span>: <span class="color-red">String</span>, <span class="color-pink">required</span>: <span class="color-blue">true</span>, <span class="color-pink">match</span>: <span class="color-green">/\S+@\S+\.\S+/</span> },</p>
                                <p style="margin-left:30px">    <span class="color-pink">age</span>: { <span class="color-pink">type</span>: <span class="color-red">Number</span>, <span class="color-pink">min</span>: <span class="color-pink">0</span>, <span class="color-pink">max</span>: <span class="color-pink">120</span> },</p>
                                <p style="margin-left:30px">    <span class="color-pink">role</span>: { <span class="color-pink">type</span>: <span class="color-red">String</span>, <span class="color-pink">enum</span>: [<span class="color-green">'user'</span>, <span class="color-green">'admin'</span>] }</p>
                                <p>});</p>
                                <br />
                                <p><span class="color-blue">const</span> User = mongoose.<span class="color-red">model</span>(<span class="color-green">'User'</span>, userSchema);</p>
                            `
                        }}></div>
                    </div>
                    <h5 className='mt-4'>Custom Validation</h5>
                    <p>
                        You can define custom validation functions for more complex validation logic. These functions can be synchronous or asynchronous.
                    </p>
                    <h6>Synchronous Custom Validation:</h6>
                    <p>
                        In Mongoose, you can perform synchronous custom validation by defining a validator function directly in your schema. This function will run synchronously and validate the data before it is saved to the database.
                    </p>
                    <p>
                        Here is an example demonstrating how to create a Mongoose schema with a custom synchronous validation function:
                    </p>
                    <div className='codePalateBox mt-2 mb-4'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                                <p><span class="color-blue">const</span> userSchema = <span class="color-blue">new</span> <span class="color-red">Schema</span>({</p>
                                <p style="margin-left:30px">    <span class="color-pink">name</span>: { <span class="color-pink">type</span>: <span class="color-red">String</span>, <span class="color-pink">required</span>: <span class="color-blue">true</span> },</p>
                                <p style="margin-left:30px">    <span class="color-pink">email</span>: { </p>
                                <p style="margin-left:60px">        <span class="color-pink">type</span>: <span class="color-red">String</span>,</p> 
                                <p style="margin-left:60px">        <span class="color-pink">required</span>: <span class="color-blue">true</span>, </p>
                                <p style="margin-left:60px">        <span class="color-pink">unique</span>: <span class="color-blue">true</span>,</p>
                                <p style="margin-left:60px">        <span class="color-pink">validate</span>: {</p>
                                <p style="margin-left:90px">            <span class="color-pink">validator</span>: <span class="color-blue">function</span>(v) {</p>
                                <p style="margin-left:120px">                <span class="color-blue">return</span> <span class="color-green">/\S+@\S+\.\S+/</span>.<span class="color-red">test</span>(v);</p>
                                <p style="margin-left:90px">            },</p>
                                <p style="margin-left:90px">            <span class="color-pink">message</span>: props => <span class="color-green">&#96;&#36;{props.value} is not a valid email!&#96;</span></p>
                                <p style="margin-left:60px">        }</p>
                                <p style="margin-left:30px">    },</p>
                                <p style="margin-left:30px">    <span class="color-pink">age</span>: { </p>
                                <p style="margin-left:60px">        <span class="color-pink">type</span>: <span class="color-red">Number</span>, </p>
                                <p style="margin-left:60px">        <span class="color-pink">min</span>: <span class="color-pink">0</span>, </p>
                                <p style="margin-left:60px">        <span class="color-pink">max</span>: <span class="color-pink">120</span>,</p>
                                <p style="margin-left:60px">        <span class="color-pink">validate</span>: {</p>
                                <p style="margin-left:90px">            <span class="color-pink">validator</span>: <span class="color-blue">function</span>(v) {</p>
                                <p style="margin-left:120px">                <span class="color-blue">return</span> v % <span class="color-pink">1</span> === <span class="color-pink">0</span>; <span class="color-grey">// Ensures age is an integer</span></p>
                                <p style="margin-left:90px">            },</p>
                                <p style="margin-left:90px">            <span class="color-pink">message</span>: props => <span class="color-green">&#96;&#36;{props.value} is not an integer value!&#96;</span></p>
                                <p style="margin-left:60px">        }</p>
                                <p style="margin-left:30px">    }</p>
                                <p>});</p>
                            `
                        }}></div>
                    </div>
                    <h6>Asynchronous Custom Validation:</h6>
                    <p>
                        You can perform asynchronous custom validation by defining a validator function that returns a promise. This is useful when the validation logic involves asynchronous operations such as querying the database.
                    </p>
                    <p>
                        Here's an example demonstrating how to create a Mongoose schema with a custom asynchronous validation function:
                    </p>
                    <div className='codePalateBox mt-2 mb-4'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                                <p><span class="color-blue">const</span> userSchema = <span class="color-blue">new</span> <span class="color-red">Schema</span>({</p>
                                <p style="margin-left:30px">    <span class="color-pink">username</span>: { </p>
                                <p style="margin-left:60px">        <span class="color-pink">type</span>: <span class="color-red">String</span>, </p>
                                <p style="margin-left:60px">        <span class="color-pink">required</span>: <span class="color-blue">true</span>, </p>
                                <p style="margin-left:60px">        <span class="color-pink">validate</span>: {</p>
                                <p style="margin-left:90px">            <span class="color-pink">isAsync</span>: <span class="color-blue">true</span>,</p>
                                <p style="margin-left:90px">            <span class="color-pink">validator</span>: <span class="color-blue">function</span>(value, callback) {</p>
                                <p style="margin-left:120px">                User.<span class="color-red">find</span>({ <span class="color-pink">username</span>: value }, <span class="color-blue">function</span>(err, users) {</p>
                                <p style="margin-left:150px">                    <span class="color-red">callback</span>(users.length === <span class="color-pink">0</span>); <span class="color-grey">// Ensures username is unique</span></p>
                                <p style="margin-left:120px">                });</p>
                                <p style="margin-left:90px">            },</p>
                                <p style="margin-left:90px">            <span class="color-pink">message</span>: <span class="color-green">'Username already exists!'</span></p>
                                <p style="margin-left:60px">        }</p>
                                <p style="margin-left:30px">    }</p>
                                <p>});</p>
                            `
                        }}></div>
                    </div>
                    <h5 className='mt-4'>Running Validation</h5>
                    <p>
                        Validation in Mongoose is automatically run when you call the <strong>`save()`</strong> method on a document. It can also be manually triggered using the <strong>`validate()`</strong> or <strong>`validateSync()`</strong> methods.
                    </p>
                    <h6>Automatic Validation</h6>
                    <div className='codePalateBox mt-2 mb-4'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                                <p><span class="color-blue">const</span> newUser = <span class="color-blue">new</span> <span class="color-red">User</span>({ <span class="color-pink">name</span>: <span class="color-green">'Alice'</span>, <span class="color-pink">email</span>: <span class="color-green">'alice@example'</span>, <span class="color-pink">age</span>: <span class="color-pink">25</span> });</p>
                                <br />
                                <p>newUser.<span class="color-red">save</span>((err) => {</p>
                                <p style="margin-left:30px">    <span class="color-blue">if</span> (err) {</p>
                                <p style="margin-left:60px">        <span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">'Validation error:'</span>, err.message);</p>
                                <p style="margin-left:30px">    } <span class="color-blue">else</span> {</p>
                                <p style="margin-left:60px">        <span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">'User saved successfully!'</span>);</p>
                                <p style="margin-left:30px">    }</p>
                                <p>});</p>
                            `
                        }}></div>
                    </div>
                    <h6>Manual Validation</h6>
                    <div className='codePalateBox mt-2 mb-4'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                                <p>newUser.<span class="color-red">validate</span>((err) => {</p>
                                <p>    <span class="color-blue">if</span> (err) {</p>
                                <p>        <span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">'Validation error:'</span>, err.message);</p>
                                <p>    } <span class="color-blue">else</span> {</p>
                                <p>        <span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">'Validation passed!'</span>);</p>
                                <p>    }</p>
                                <p>});</p>
                            `
                        }}></div>
                    </div>
                </div>
            </section>
        </>
    )
}