import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import PreviousNext from '../PreviousNext';
export const pageUrl = () => "/react-js/start-new-application";

export default function NewApplication() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("React Js - Start A New Application | Aspirant's Home");
        const urls = {
            'previous': '/react-js/features',
            'next':'/react-js/folder-architecture'
        }
        path.setPreviousNext(urls);
    },[])

    return (
        <section className='mt-5 mb-5'>
            <h3>React Js - Start A New Application</h3>
            <div className='mb-4 mt-5'>
                <p>To setting up a new react js application you have to run the below command with your application(project) name.</p>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `<p><span class="color-yellow">npx</span> create-react-app my-first-react-app</p>
                    `
                    }}></div>
                </div>
                <p><strong>`npx`</strong> is mainly used for managing dependencies in a project. In conclusion, while <strong>`npm`</strong> is used for managing dependencies in a project, <strong>`npx`</strong> is used for executing command-line tools that are part of a package without the need for a global or local installation.</p>
                <p>The <strong>`create-react-app`</strong> will set up everything you need to run a React application.</p>
                <p><strong>`my-first-react-app`</strong> will be your project/application name you want to create.</p>
                <div className='mt-4'>
                    <img className='img-fluid' src='../assets/react/create-react-app.png' />
                </div>
            </div>
            <div className='mb-4 mt-5'>
                <p>After running above command you have to go to the project folder <strong>`my-first-react-app`</strong></p>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `<p><span class="color-yellow">cd</span> my-first-react-app</p>
                    `
                    }}></div>
                </div>
            </div>
            <div className='mb-4 mt-5'>
                <p>To run your application you have to do the below command</p>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `<p>npm <span class="color-blue">start</span></p>
                    `
                    }}></div>
                </div>
                <p>By default it will open in your port 3000. See the screenshot below </p>
                <div className='mt-4 mb-4'>
                    <img className='img-fluid' src='../assets/react/npm-start.png' />
                </div>
                <p>If port 3000 is busy, then it is ask for confirmation to start the application on diffrent port. See the screenshot below. </p>
                <div className='mt-4'>
                    <img className='img-fluid' src='../assets/react/busy-port.png' />
                </div>
            </div>
        </section>
    )
}
