import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/mysql/delete-query";

export default function MysqlDeleteQuery() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Mysql - Delete Query | Aspirant's Home");
        const urls = {
            'previous': '/mysql/update-query',
            'next': '/mysql/replace-query'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>Mysql - Delete Query</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <strong>`DELETE`</strong> statement from MySQL is used to delete records from a MySQL table. To remove specific records, you need to use <strong>`WHERE`</strong> clause along with it. Here's the basic syntax:
                </p>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-blue">DELETE FROM</span> table_name <span class="color-blue">WHERE</span> condition;</p>
                        `
                    }}></div>
                </div>
                <p>
                    Where, <strong>`table_name`</strong> is the name of the table from which you want to delete rows. <strong>`condition`</strong> is an optional condition to specify which rows to delete. If omitted, all rows in the table will be deleted.
                </p>
                <p>
                    Suppose, we have a <strong>`users`</strong> table like below -
                </p>
                <table className='table' style={{border: '1px dashed #ccc'}}> 
                    <thead>
                        <tr style={{border: '1px dashed #ccc'}}>
                            <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>id</th>
                            <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>name</th>
                            <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>age</th>
                            <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>Address</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr style={{border: '1px dashed #ccc'}}>
                            <td style={{border: '1px dashed #ccc'}}>1</td>
                            <td style={{border: '1px dashed #ccc'}}>Jane Smith</td>
                            <td style={{border: '1px dashed #ccc'}}>25</td>
                            <td style={{border: '1px dashed #ccc'}}>Apt. 415 9295 Anh Harbor, South Junior, FL 51309-5804</td>
                        </tr>
                        <tr style={{border: '1px dashed #ccc'}}>
                            <td style={{border: '1px dashed #ccc'}}>2</td>
                            <td style={{border: '1px dashed #ccc'}}>John Doe</td>
                            <td style={{border: '1px dashed #ccc'}}>40</td>
                            <td style={{border: '1px dashed #ccc'}}>641 Eloise Fall, South Bradlyhaven, MT 53320</td>
                        </tr>
                        <tr style={{border: '1px dashed #ccc'}}>
                            <td style={{border: '1px dashed #ccc'}}>3</td>
                            <td style={{border: '1px dashed #ccc'}}>Johny Smith</td>
                            <td style={{border: '1px dashed #ccc'}}>39</td>
                            <td style={{border: '1px dashed #ccc'}}>3797 Benton Locks, Weissnatside, MS 54762-5660</td>
                        </tr>
                        <tr style={{border: '1px dashed #ccc'}}>
                            <td style={{border: '1px dashed #ccc'}}>4</td>
                            <td style={{border: '1px dashed #ccc'}}>Jeffer Joe</td>
                            <td style={{border: '1px dashed #ccc'}}>28</td>
                            <td style={{border: '1px dashed #ccc'}}>Suite 715 6587 Champlin Freeway, Port Angelhaven, PA 42789</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className='mt-5 mb-5'>
                <h5>Delete Data With Conditions</h5>
                <div className='mt-2 mb-2'>
                    <p>
                        For example, delete all users whose age is greater than <strong>`30`</strong>:
                    </p>
                    <div className='codePalateBox mt-2 mb-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                                <p><span class="color-blue">DELETE FROM</span> users WHERE age > <span class="color-pink">30</span>;</p>
                            `
                        }}></div>
                    </div>
                    <p>
                        The Output of the query will be the following -
                    </p>
                    
                    <table className='table' style={{border: '1px dashed #ccc'}}> 
                        <thead>
                            <tr style={{border: '1px dashed #ccc'}}>
                                <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>id</th>
                                <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>name</th>
                                <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>age</th>
                                <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>Address</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr style={{border: '1px dashed #ccc'}}>
                                <td style={{border: '1px dashed #ccc'}}>2</td>
                                <td style={{border: '1px dashed #ccc'}}>John Doe</td>
                                <td style={{border: '1px dashed #ccc'}}>35</td>
                                <td style={{border: '1px dashed #ccc'}}>Suite 977 83408 Murray Lakes, Lakinchester, WV 60583</td>
                            </tr>
                            <tr style={{border: '1px dashed #ccc'}}>
                                <td style={{border: '1px dashed #ccc'}}>3</td>
                                <td style={{border: '1px dashed #ccc'}}>Johny Smith</td>
                                <td style={{border: '1px dashed #ccc'}}>39</td>
                                <td style={{border: '1px dashed #ccc'}}>3797 Benton Locks, Weissnatside, MS 54762-5660</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </section>
    )
}