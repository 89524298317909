import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/css/text/shadow";

export default function TextShadow() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Text Shadow in CSS | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/css/text/spacing',
            'next': '/css/font/family'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>Text Shadow</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <strong>`text-shadow`</strong> property in CSS is used to add shadow effects to text. It allows you to create various visual effects by specifying the position, blur, and color of the shadow. This property is often used to give text a more polished or dramatic look on webpages.
                </p>
                <p>
                    It has a basic syntax -
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>element {</p>
                            <p class="ml-30">    <span class="color-green">text-shadow</span>: offset-x offset-y blur-radius color;</p>
                            <p>}</p>
                        `
                    }}></div>
                </div>
                <p>
                    Where,
                </p>
                <ul style={{ 'listStyle': 'disc' }}>
                    <li><strong>`offset-x`</strong>: The horizontal distance of the shadow. Positive values move the shadow to the right, while negative values move it to the left.</li>
                    <li><strong>`offset-y`</strong>: The vertical distance of the shadow. Positive values move the shadow down, while negative values move it up.</li>
                    <li><strong>`blur-radius (optional)`</strong>: Defines the blur radius of the shadow. The larger the value, the more blurred the shadow will appear. If omitted, the default is 0 (a sharp shadow).</li>
                    <li><strong>`color (optional)`</strong>: Specifies the color of the shadow. If omitted, the color of the text will be used.</li>
                </ul>

                <h5 className="mt-5 mb-3">How a Text Shadow Looks Like</h5>
                <p>
                    Here are some example of how text shadow looks like -
                </p>
                <div className='outputPalateBox mb-4 mt-3'>
                    <div className='outputPalate' dangerouslySetInnerHTML={{
                        __html: `
                            <style>
                                h1.example1 {
                                    text-shadow: 2px 2px 5px red;
                                }
                                h2.example2 {
                                    text-shadow: 1px 1px 2px black;
                                }
                                h3.example3 {
                                    text-shadow: 2px 2px yellow;
                                }
                            </style>
                            <p>Example 1</p>
                            <h1 class="example1">Text Shadow One</h1>
                            <p>Example 2</p>
                            <h2 class="example2">Text Shadow Two</h2>
                            <p>Example 3</p>
                            <h3 class="example3">Text Shadow Three</h3>
                        `
                    }}></div>
                </div>

                <h5 className="mt-5 mb-3">Examples of Text Shadow</h5>
                <h6 className='mt-4'>Example 1</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>h1 {</p>
                            <p class="ml-30">    <span class="color-green">text-shadow</span>: <span class="color-pink">2px 2px 5px</span> red;</p>
                            <p>}</p>
                        `
                    }}></div>
                </div>
                <h6 className='mt-4'>Example 2</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>h2 {</p>
                            <p class="ml-30">    <span class="color-green">text-shadow</span>: <span class="color-pink">1px 1px 2px</span> black;</p>
                            <p>}</p>
                        `
                    }}></div>
                </div>
                <h6 className='mt-4'>Example 3</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>h3 {</p>
                            <p class="ml-30">    <span class="color-green">text-shadow</span>: <span class="color-pink">2px 2px</span> yellow;</p>
                            <p>}</p>
                        `
                    }}></div>
                </div>

                <h5 className="mt-5 mb-3">Multiple Shadows</h5>
                <p>
                    You can add multiple shadows to a single text element by separating each shadow with a comma:
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>h2 {</p>
                            <p class="ml-30">    <span class="color-green">text-shadow</span>: <span class="color-pink">2px 2px 5px</span> black, <span class="color-pink">-2px -2px 5px</span> red;</p>
                            <p>}</p>
                        `
                    }}></div>
                </div>
                <p>
                    Output will be the following -
                </p>
                <div className='outputPalateBox mb-4 mt-3'>
                    <div className='outputPalate' dangerouslySetInnerHTML={{
                        __html: `
                            <style>
                                h2.multipleShadow {
                                    text-shadow: 2px 2px 5px black, -2px -2px 5px red;
                                }
                            </style>
                            <h2 class="multipleShadow">
                                Example of multiple text shadows
                            </h2>
                        `
                    }}></div>
                </div>
                <p>
                    In this example -
                </p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>The first shadow is a black shadow that moves 2 pixel to the right and down.</li>
                    <li>The second shadow is a red shadow that moves 2 pixel to the left and up.</li>
                </ul>

                <h5 className="mt-5 mb-3">Making an 3D text</h5>
                <p>
                    You can even make an 3D text using text shadow. Look at the below example -
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>h2 {</p>
                        <p class="ml-30">    <span class="color-green">text-shadow</span>: <span class="color-pink">1px 1px 0</span> red, <span class="color-pink">2px 2px 0</span> red, <span class="color-pink">3px 3px 0</span> red;</p>
                        <p class="ml-30">    <span class="color-green">color</span> : red;</p>
                        <p>}</p>
                        `
                    }}></div>
                </div>
                <p>
                    Output will be the following -
                </p>
                <div className='outputPalateBox mb-4 mt-3'>
                    <div className='outputPalate' dangerouslySetInnerHTML={{
                        __html: `
                            <style>
                                h2.threeDText {
                                    text-shadow: 1px 1px 0 red, 2px 2px 0 red, 3px 3px 0 red;
                                    color : red;
                                }
                            </style>
                            <h2 class="threeDText">
                                3D Text Using Text Shadow
                            </h2>
                        `
                    }}></div>
                </div>
            </div>
        </section>
    )
}