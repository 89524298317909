import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/mongo-db/create-database";

export default function MongoCreateDatabase() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Steps to Create a MongoDB Database");
        title.setKeyWords("create a mongodb database, how to create a mongodb database, create a database in mongodb command, mongodb create a database, create a database and collection in mongodb, mongodb get database names, mongodb create a new database, how to create database in mongodb step by step, create a database mongodb, mongodb create database example, mongodb create database from command line, create a new database mongodb, ");
        title.setPageDescription("Creating a database in MongoDB is straightforward. MongoDB automatically creates a new database if it doesn't already exist when you first store data in it.")
        const urls = {
            'previous': '/mongo-db/get-started',
            'next': '/mongo-db/drop-database'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>MongoDB - Create Database</h3>
            <div className='mt-4 mb-5'>
                <p>
                    Creating a database in MongoDB is straightforward. MongoDB automatically creates a new database if it doesn't already exist when you first store data in it.
                </p>
                <h5>The use Command</h5>
                <p>
                    In MongoDB, the <strong>`use`</strong> command is used to switch to a database. If the database does not exist, MongoDB will create it when you insert the first document. Here's a step-by-step guide to creating a database using the <strong>`use`</strong> command:
                </p>
                <p>
                    For example, to create a database named <strong>`mynewdatabase`</strong>, type:
                </p>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>use mynewdatabase</p>
                        `
                    }}></div>
                </div>
                <p>
                    The output will be:
                </p>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            switched to db mynewdatabase
                        `
                    }}></div>
                </div>
                <p>
                    At this point, MongoDB has switched to the <strong>`mynewdatabase`</strong> database. However, the database will not be created until you insert the first document.
                </p>
                <p>
                    To verify that the database has been created, you can list all databases:
                </p>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>&gt; show dbs</p>
                            <p><span style="margin-right:30px">admin</span>          0.000GB</p>
                            <p><span style="margin-right:30px">config</span>         0.000GB</p>
                            <p><span style="margin-right:40px">local</span>          0.000GB</p>
                        `
                    }}></div>
                </div>
                <p>
                    To create the database, you need to insert at least one document into a collection. For example:
                </p>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>db.mycollection.<span class="color-red">insertOne</span>({ <span class="color-pink">name</span>: <span class="color-green">"John Doe"</span>, <span class="color-pink">age</span>: <span class="color-pink">30</span> })</p>
                        `
                    }}></div>
                </div>
                <p>
                    The output will be:
                </p>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>{</p>
                            <p style="margin-left:30px">    <span class="color-pink">"acknowledged"</span> : <span class="color-blue">true</span>,</p>
                            <p style="margin-left:30px">    <span class="color-pink">"insertedId"</span> : ObjectId(<span class="color-green">"603d2f1d5b4a3d14b1c8c7f1"</span>)</p>
                            <p>}</p>
                        `
                    }}></div>
                </div>
                <p>
                    To verify that the database has been created, you can list all databases:
                </p>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>&gt; show dbs</p>
                            <p><span style="margin-right:30px">admin</span>          0.000GB</p>
                            <p><span style="margin-right:30px">config</span>         0.000GB</p>
                            <p><span style="margin-right:40px">local</span>          0.000GB</p>
                            <p><span style="margin-right:40px">mynewdatabase</span>  0.000GB</p>
                        `
                    }}></div>
                </div>
                <p>
                    Using the <strong>`use`</strong> command in MongoDB allows you to switch to a specific database. If the database does not exist, it will be created when you insert the first document into it. This process makes database creation in MongoDB very simple and straightforward.
                </p>
            </div>
        </section>
    )
}