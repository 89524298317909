import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';
export const pageUrl = () => "/mysql/string-functions";

export default function MysqlStringFunctions() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Mysql - String Functions | Aspirant's Home");
        const urls = {
            'previous': '/mysql/numeric-functions',
            'next': '/mysql/aggregate-functions'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>Mysql - String Functions</h3>
            <div className='mt-4 mb-5'>
                <p>
                    MySQL provides a variety of string functions that allow you to manipulate and query string data effectively. Here are some commonly used string functions in MySQL:
                </p>
                <table className='table' style={{border: '1px dashed #ccc'}}> 
                    <thead>
                        <tr style={{border: '1px dashed #ccc'}}>
                            <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff', width: '20%'}}>Function Name</th>
                            <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>Description & Syntax</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr style={{ border: '1px dashed #ccc' }}>
                            <td style={{ border: '1px dashed #ccc', textAlign: 'center' }}>
                                <Link to="/mysql/string-functions/concat">CONCAT()</Link>
                            </td>
                            <td style={{ border: '1px dashed #ccc' }}>
                                <p>
                                    Concatenates two or more strings together.
                                </p>
                            </td>
                        </tr>
                        <tr style={{ border: '1px dashed #ccc' }}>
                            <td style={{ border: '1px dashed #ccc', textAlign: 'center' }}>
                                <Link to="/mysql/string-functions/substring">SUBSTRING()</Link>
                            </td>
                            <td style={{ border: '1px dashed #ccc' }}>
                                <p>
                                    Extracts a substring from a string.
                                </p>
                            </td>
                        </tr>
                        <tr style={{ border: '1px dashed #ccc' }}>
                            <td style={{ border: '1px dashed #ccc', textAlign: 'center' }}>
                                <Link to="/mysql/string-functions/length">LENGTH()</Link>
                            </td>
                            <td style={{ border: '1px dashed #ccc' }}>
                                <p>
                                    Returns the length of a string.
                                </p>
                            </td>
                        </tr>
                        <tr style={{ border: '1px dashed #ccc' }}>
                            <td style={{ border: '1px dashed #ccc', textAlign: 'center' }}>
                                <Link to="/mysql/string-functions/upper">UPPER()</Link>
                            </td>
                            <td style={{ border: '1px dashed #ccc' }}>
                                <p>
                                    Converts a string to uppercase.
                                </p>
                            </td>
                        </tr>
                        <tr style={{ border: '1px dashed #ccc' }}>
                            <td style={{ border: '1px dashed #ccc', textAlign: 'center' }}>
                                <Link to="/mysql/string-functions/lower">LOWER()</Link>
                            </td>
                            <td style={{ border: '1px dashed #ccc' }}>
                                <p>
                                    Converts a string to lowercase.
                                </p>
                            </td>
                        </tr>
                        <tr style={{ border: '1px dashed #ccc' }}>
                            <td style={{ border: '1px dashed #ccc', textAlign: 'center' }}>
                                <Link to="/mysql/string-functions/trim">TRIM()</Link>
                            </td>
                            <td style={{ border: '1px dashed #ccc' }}>
                                <p>
                                    Removes leading and trailing spaces from a string.
                                </p>
                            </td>
                        </tr>
                        <tr style={{ border: '1px dashed #ccc' }}>
                            <td style={{ border: '1px dashed #ccc', textAlign: 'center' }}>
                                <Link to="/mysql/string-functions/replace">REPLACE()</Link>
                            </td>
                            <td style={{ border: '1px dashed #ccc' }}>
                                <p>
                                    Replaces all occurrences of a substring within a string with another substring.
                                </p>
                            </td>
                        </tr>
                        <tr style={{ border: '1px dashed #ccc' }}>
                            <td style={{ border: '1px dashed #ccc', textAlign: 'center' }}>
                                <Link to="/mysql/string-functions/instr">INSTR()</Link>
                            </td>
                            <td style={{ border: '1px dashed #ccc' }}>
                                <p>
                                    Returns the position of the first occurrence of a substring in a string.
                                </p>
                            </td>
                        </tr>
                        <tr style={{ border: '1px dashed #ccc' }}>
                            <td style={{ border: '1px dashed #ccc', textAlign: 'center' }}>
                                <Link to="/mysql/string-functions/left">LEFT()</Link>
                            </td>
                            <td style={{ border: '1px dashed #ccc' }}>
                                <p>
                                    Extracts a specified number of characters from the left side of a string.
                                </p>
                            </td>
                        </tr>
                        <tr style={{ border: '1px dashed #ccc' }}>
                            <td style={{ border: '1px dashed #ccc', textAlign: 'center' }}>
                                <Link to="/mysql/string-functions/right">RIGHT()</Link>
                            </td>
                            <td style={{ border: '1px dashed #ccc' }}>
                                <p>
                                    Extracts a specified number of characters from the right side of a string.
                                </p>
                            </td>
                        </tr>
                        <tr style={{ border: '1px dashed #ccc' }}>
                            <td style={{ border: '1px dashed #ccc', textAlign: 'center' }}>
                                <Link to="/mysql/string-functions/lpad">LPAD()</Link>
                            </td>
                            <td style={{ border: '1px dashed #ccc' }}>
                                <p>
                                    Pads the left side of a string with another string to a specified length.
                                </p>
                            </td>
                        </tr>
                        <tr style={{ border: '1px dashed #ccc' }}>
                            <td style={{ border: '1px dashed #ccc', textAlign: 'center' }}>
                                <Link to="/mysql/string-functions/rpad">RPAD()</Link>
                            </td>
                            <td style={{ border: '1px dashed #ccc' }}>
                                <p>
                                    Pads the right side of a string with another string to a specified length.
                                </p>
                            </td>
                        </tr>
                        <tr style={{ border: '1px dashed #ccc' }}>
                            <td style={{ border: '1px dashed #ccc', textAlign: 'center' }}>
                                <Link to="/mysql/string-functions/repeat">REPEAT()</Link>
                            </td>
                            <td style={{ border: '1px dashed #ccc' }}>
                                <p>
                                    Repeats a string a specified number of times.
                                </p>
                            </td>
                        </tr>
                        <tr style={{ border: '1px dashed #ccc' }}>
                            <td style={{ border: '1px dashed #ccc', textAlign: 'center' }}>
                                <Link to="/mysql/string-functions/reverse">REVERSE()</Link>
                            </td>
                            <td style={{ border: '1px dashed #ccc' }}>
                                <p>
                                    Reverses a string.
                                </p>
                            </td>
                        </tr>
                        <tr style={{ border: '1px dashed #ccc' }}>
                            <td style={{ border: '1px dashed #ccc', textAlign: 'center' }}>
                                <Link to="/mysql/string-functions/format">FORMAT()</Link>
                            </td>
                            <td style={{ border: '1px dashed #ccc' }}>
                                <p>
                                    Formats a number as a string with a specified number of decimal places and optional thousands separator.
                                </p>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </section>
    )
}