import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/node-js/mongodb-create-collection";

export default function MongoDbCreateCollection() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Node Js - MongoDB - Create Collection | Aspirant's Home");
        const urls = {
            'previous': '/node-js/mongodb-create-database',
            'next': '/node-js/mongodb-insert-query'
        }
        path.setPreviousNext(urls);
    }, [])
    
    return (
        <section className='mt-5 mb-5'>
            <h3 className='mb-4'>Node Js MongoDB Create Collection</h3>
            <p>In MongoDB, a collection is a grouping of MongoDB documents. A collection is similar to a table in a relational database, but it does not require a schema. Collections in MongoDB do not enforce a specific structure for the documents they contain, which means that documents within a single collection can have different fields and structures.</p>
            <div className='mb-3'>
                <p>Here is an example how to create collection using MongoClient</p>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html:
                            `<p><span class='color-blue'>var</span> MongoClient = <span class='color-yellow'>require</span>(<span class='color-green'>'mongodb'</span>).MongoClient;</p>
                <p><span class='color-blue'>var</span> url = <span class='color-green'>"mongodb://localhost:27017/"</span>;</p>
                <br />
                <p>MongoClient.<span class='color-red'>connect</span>(url, function(err, db) {</p>
                <p style='margin-left:30px'>  <span class='color-blue'>if</span> (err) throw err;</p>
                <p style='margin-left:30px'><span class='color-blue'>var</span> dbo = db.<span class='color-red'>db </span>(<span class='color-green'>"mymongodbdatabase"</span>);</p>
                <p style='margin-left:30px'>dbo.<span class='color-red'>createCollection</span>(<span class='color-green'>"employee"</span>, function(err, res) {</p>
                <p style='margin-left:60px'> <span class='color-blue'>if</span> (err) throw err;</p>
                <p style='margin-left:60px'>  <span class='color-pink'>console</span>.<span class='color-red'>log</span>(<span class='color-green'>"Collection created!"</span>);</p>
                <p style='margin-left:60px'>  db.<span class='color-red'>close</span>();</p>
                <p style='margin-left:30px'>});</p>
                <p>});</p>` }}></div>
                </div>
            </div>
            <p>Which will give you this result:</p>
            <div className='codePalateBox mt-2 mb-2'>
                <div className='codePalate' dangerouslySetInnerHTML={{
                    __html: `Collection created!`
                }}></div>
            </div>
        </section>
    )
}
