import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/html/headings";

export default function HTMLHeadings() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("HTML - Headings | Aspirant's Home");
        const urls = {
            'previous': '/html/attributes',
            'next': '/html/paragraphs'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>HTML - Headings</h3>
            <div className='mt-4 mb-5'>
                <p>
                    HTML headings are used to define the titles and subtitles on a webpage. They range from <strong>`&lt;h1&gt;`</strong> to <strong>`&lt;h6&gt;`</strong>, with <strong>`&lt;h1&gt;`</strong> being the highest level (most important) and <strong>`&lt;h6&gt;`</strong> the lowest level (least important). Headings are essential for structuring content, making it easier to read and navigate, and they also play a significant role in search engine optimization (SEO).
                </p>
                <h5 className='mt-5 mb-3'>Purpose of HTML Headings</h5>
                <ul style={{listStyle:'decimal'}}>
                    <li><strong>Structure and Organization</strong>: Headings help to organize content into sections, making it easier for users to understand and navigate the page.</li>
                    <li><strong>Accessibility</strong>: Screen readers and other assistive technologies use headings to help users navigate the content.</li>
                    <li><strong>SEO</strong>: Search engines use headings to understand the content and context of a webpage, which can impact search rankings.</li>
                </ul>
                <h5 className='mt-5 mb-3'>HTML Heading Elements</h5>
                <ul style={{ listStyle: 'decimal' }}>
                    <li>
                        <strong>`&lt;h1&gt;`</strong>: Main heading or title of the document. There should typically be only one <strong>`&lt;h1&gt;`</strong> per page.
                    </li>
                    <li>
                        <strong>`&lt;h2&gt;`</strong>: Secondary headings, used for major sections within the content.
                    </li>
                    <li>
                        <strong>`&lt;h3&gt;`</strong>: Tertiary headings, used for subsections within <strong>`&lt;h2&gt;`</strong> sections.
                    </li>
                    <li>
                        <strong>`&lt;h4&gt;`</strong>: Used for sub-subsections within <strong>`&lt;h3&gt;`</strong> sections.
                    </li>
                    <li>
                        <strong>`&lt;h5&gt;`</strong>: Used for smaller divisions within <strong>`&lt;h4&gt;`</strong> sections.
                    </li>
                    <li>
                        <strong>`&lt;h6&gt;`</strong>: Used for the least important headings.
                    </li>
                </ul>
                <h5 className='mt-5 mb-3'>Example of Using Headings in a Document</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <h1>Heading h1</h1>
                            <h2>Heading h2</h2>
                            <h3>Heading h3</h3>
                            <h4>Heading h4</h4>
                            <h5>Heading h5</h5>
                            <h6>Heading h6</h6>
                        `
                    }}></div>
                </div>
            </div>
        </section>
    )
}