import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../../Context";
import { Link } from 'react-router-dom';
export const pageUrl = () => "/mysql/arithmetic-operators/multiplication";

export default function Multiplication() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Mysql - Arithmetic Operators - Multiplication(*) | Aspirant's Home");
        path.setPreviousNext({});
    }, [])

    return (
        <>
            <section class='clearfix mb-3'>
                <span className='float-start'>
                    <Link to='/mysql/arithmetic-operators' onClick={() => path.setPathName('/mysql/arithmetic-operators')} className='btn btn-primary'> <i class="fa-solid fa-less-than"></i>&nbsp; Back to Arithmetic Operators </Link>
                </span>
            </section>
            <section className='mt-5 mb-5'>
                <h3>Mysql - Multiplication(*) Operator</h3>
                <div className='mt-4 mb-5'>
                    <p>
                        In MySQL, the multiplication operator (*) is used to multiply two or more numeric values together. It can be used in various contexts, such as in SELECT queries, UPDATE statements, and when defining columns in CREATE TABLE statements.  
                    </p>
                    <p>
                        Here's a basic example of using the multiplication operator in a SELECT query:
                    </p>
                    <div className='codePalateBox mt-2 mb-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                                <p><span class="color-blue">SELECT</span> <span class="color-pink">3</span> * <span class="color-pink">6</span>;</p>
                            `
                        }}></div>
                    </div>
                    <p>
                        This query will return the result of multiplying 3 by 6, which is 18.
                    </p>
                </div>
                <div className='mt-5 mb-5'>
                    <h5>Multiplication(*) with MySQL Table</h5>
                    <div className='mt-3 mb-2'>
                        <p>
                            Consider a table <strong>`products`</strong> with columns <strong>`product_name`</strong>, <strong>`unit_price`</strong>, and <strong>`quantity`</strong>. 
                        </p>
                        <table className='table' style={{border: '1px dashed #ccc'}}> 
                            <thead>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>id</th>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>product_name</th>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>unit_price</th>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>quantity</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>1</td>
                                    <td style={{border: '1px dashed #ccc'}}>Chair</td>
                                    <td style={{border: '1px dashed #ccc'}}>50.00</td>
                                    <td style={{border: '1px dashed #ccc'}}>10</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>2</td>
                                    <td style={{border: '1px dashed #ccc'}}>Table</td>
                                    <td style={{border: '1px dashed #ccc'}}>150.00</td>
                                    <td style={{border: '1px dashed #ccc'}}>5</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>3</td>
                                    <td style={{border: '1px dashed #ccc'}}>Desk</td>
                                    <td style={{border: '1px dashed #ccc'}}>200.00</td>
                                    <td style={{border: '1px dashed #ccc'}}>3</td>
                                </tr>
                            </tbody>
                        </table>
                        <p>
                            If you want to calculate the total price for each product (unit_price * quantity), you can use the multiplication operator as follows:
                        </p>
                        <div className='codePalateBox mt-2 mb-2'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-blue">SELECT</span> id, product_name, unit_price, quantity, (unit_price * quantity) <span class="color-blue">AS</span> total_price <span class="color-blue">FROM</span> products;</p>
                                `
                            }}></div>
                        </div>
                        <p>
                            This query will return:
                        </p>
                        <table className='table' style={{border: '1px dashed #ccc'}}> 
                            <thead>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>id</th>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>product_name</th>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>unit_price</th>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>quantity</th>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>total_price</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>1</td>
                                    <td style={{border: '1px dashed #ccc'}}>Chair</td>
                                    <td style={{border: '1px dashed #ccc'}}>50.00</td>
                                    <td style={{border: '1px dashed #ccc'}}>10</td>
                                    <td style={{border: '1px dashed #ccc'}}>500.00</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>2</td>
                                    <td style={{border: '1px dashed #ccc'}}>Table</td>
                                    <td style={{border: '1px dashed #ccc'}}>150.00</td>
                                    <td style={{border: '1px dashed #ccc'}}>5</td>
                                    <td style={{border: '1px dashed #ccc'}}>750.00</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>3</td>
                                    <td style={{border: '1px dashed #ccc'}}>Desk</td>
                                    <td style={{border: '1px dashed #ccc'}}>200.00</td>
                                    <td style={{border: '1px dashed #ccc'}}>3</td>
                                    <td style={{border: '1px dashed #ccc'}}>600.00</td>
                                </tr>
                            </tbody>
                        </table>
                        <p>
                            In this example, the expression <strong>`(unit_price * quantity)`</strong> uses the multiplication operator (*) to multiply the <strong>`unit_price`</strong> by the <strong>`quantity`</strong> for each row, calculating the total price. The result is displayed in a new column named <strong>`total_price`</strong>.
                        </p>
                    </div>
                </div>
            </section>
        </>
    )
}