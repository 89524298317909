import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../../Context";
import { Link } from 'react-router-dom';
export const pageUrl = () => "/node-js/built-in-modules/zlib";

export default function ZlibModule() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("NodeJs - Zlib Module | Aspirant's Home");
        path.setPreviousNext({});
    }, [])

    return (
        <div className='contentPage'>
            <section class='clearfix mb-3'>
                <span className='float-start'>
                    <Link to='/node-js/built-in-modules' onClick={() => path.setPathName('/node-js/built-in-modules')} className='btn btn-primary'> <i class="fa-solid fa-less-than"></i>&nbsp; Back to Built-In Modules </Link>
                </span>
            </section>
            <section className='mt-5 mb-5'>
                <h3>Node Js Zlib Module</h3>
                <div className='mt-4'>
                    <p>In Node.js, the <strong>`zlib`</strong> module provides compression and decompression functionalities through the use of zlib. It supports gzip, deflate, and raw deflate compression formats. The module can be used to compress and decompress buffers, streams, or files.</p>

                    <p>The syntax for including the zlib module in your application: </p>
                    <div className='codePalateBox mt-2 mb-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `<p><span class='color-blue'>var</span> zlib = <span class='color-yellow'>require</span>(<span class='color-green'>'zlib'</span>);</p>
                                    `
                        }}></div>
                    </div>
                </div>
                <div className='mt-4'>
                    <h5>Examples</h5>                    
                    <p>Here's a basic example of how you might use the zlib module to compress and decompress a string:</p>
                    <div className='codePalateBox mt-2 mb-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `<p><span class="color-blue">const</span> zlib = <span class="color-yellow">require</span>(<span class="color-green">'zlib'</span>);</p>
                            <br />
                            <p><span class="color-grey">// Compress a string</span></p>
                            <p><span class="color-blue">const</span> input = <span class="color-green">'Hello, World!'</span>;</p>
                            <p>zlib.<span class="color-red">deflate</span>(input, (err, buffer) => {</p>
                            <p style="margin-left:30px">  <span class="color-blue">if</span> (!err) {</p>
                            <p style="margin-left:60px">    <span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">'Compressed:'</span>, buffer.<span class="color-red">toString</span>(<span class="color-green">'base64'</span>));</p>
                            <p style="margin-left:60px"><span class="color-grey">    // Output: 'eJzT0yMAAGTvBe8='</span></p>
                            <br />    
                            <p style="margin-left:60px"><span class="color-grey">    // Decompress the buffer</span></p>
                            <p style="margin-left:60px">    zlib.<span class="color-red">inflate</span>(buffer, (err, decompressedBuffer) => {</p>
                            <p style="margin-left:90px">      <span class="color-blue">if</span> (!err) {</p>
                            <p style="margin-left:90px">        <span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">'Decompressed:'</span>, decompressedBuffer.<span class="color-red">toString</span>());</p>
                            <p style="margin-left:90px">       <span class="color-grey"> // Output: 'Hello, World!'</span></p>
                            <p style="margin-left:90px">      }</p>
                            <p style="margin-left:60px">    });</p>
                            <p style="margin-left:30px">  }</p>
                            <p>});</p> `
                        }}></div>
                    </div>
                </div>
                <div className='mt-4'>
                    <p>The <strong>`zlib`</strong> module also provides other methods for working with different compression formats and streams, such as <strong>`zlib.gzip`</strong>, <strong>`zlib.gunzip`</strong>, <strong>`zlib.createGzip`</strong>, <strong>`zlib.createGunzip`</strong>, etc. These methods can be used to compress and decompress data in various formats and scenarios.</p>
                </div>
            </section>
        </div>
    )
}
