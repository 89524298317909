import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/mysql/unique-key";

export default function MysqlUniqueKey() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Mysql - Unique Key | Aspirant's Home");
        const urls = {
            'previous': '/mysql/self-join',
            'next': '/mysql/primary-key'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>Mysql - Unique Key</h3>
            <div className='mt-4 mb-5'>
                <p>
                    In MySQL, a unique key is a constraint that ensures the values in a column or a group of columns are unique across the table. This means that no two rows in the table can have the same value for the unique key column(s). <strong>`Unique keys`</strong> are useful for ensuring data integrity and can be used to enforce business rules that require certain columns to have unique values.
                </p>
                <p>
                    To create a unique key in MySQL, you can use the <strong>`UNIQUE`</strong> constraint when defining the table. Here's an example:
                </p>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-blue">CREATE TABLE</span> example_table (</p>
                            <p style="margin-left:30px">    id <span class="color-pink">INT</span> <span class="color-blue">PRIMARY</span> KEY,</p>
                            <p style="margin-left:30px">    email <span class="color-pink">VARCHAR</span>(<span class="color-pink">255</span>) <span class="color-blue">UNIQUE</span>,</p>
                            <p style="margin-left:30px">    username <span class="color-pink">VARCHAR</span>(<span class="color-pink">255</span>) <span class="color-blue">UNIQUE</span></p>
                            <p>);</p>
                        `
                    }}></div>
                </div>
                <p>
                    In this example, both the <strong>`email`</strong> and <strong>`username`</strong> columns have unique constraints, meaning that each email and username must be unique in the <strong>`example_table`</strong>.
                </p>
                <p>
                    You can also add a unique key to an existing table using the <strong>`ALTER TABLE`</strong> statement:
                </p>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-blue">ALTER TABLE</span> example_table <span class="color-blue">ADD UNIQUE</span> (email);</p>
                        `
                    }}></div>
                </div>
                <p>
                This statement adds a unique constraint to the email column in the <strong>`example_table`</strong>.   
                </p>
            </div>
        </section>
    )
}