import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';
export const pageUrl = () => "/mongo-db/query-document";

export default function MongoQueryDocument() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("What is Query Document in MongoDB ?");
        title.setKeyWords("query document in mongodb, mongodb query to update a field in a document, query to delete a document in mongodb, query to find document size in mongodb, query document in collection mongodb, mongodb in query example, mongodb query count documents, mongodb query criteria example, query a document in mongodb, query document db, mongodb or query example, query a mongodb collection, mongodb query documents, mongodb query db, mongodb document query, mongodb query document, delete document query in mongodb, mongodb find query example, how to query document in mongodb, document query for mongodb, query data from mongodb, query document by id mongodb, how to query all document");
        title.setPageDescription("Querying documents in MongoDB allows you to retrieve data based on specific criteria. You can use the `find` method to query documents from a collection. You can also use `findOne()` method to return only one document.");
        const urls = {
            'previous': '/mongo-db/insert-document',
            'next': '/mongo-db/update-document'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>MongoDB - Query Document</h3>
            <div className='mt-4 mb-5'>
                <p>
                    Querying documents in MongoDB allows you to retrieve data based on specific criteria. You can use the <strong>`find`</strong> method to query documents from a collection. You can also use <strong>`findOne()`</strong> method to return only one document.
                </p>
                <h5 className='mt-5 mb-4'>Query Operators</h5>
                <p>
                    MongoDB provides a variety of query operators to help you perform complex queries on your data. These operators allow you to specify criteria for selecting documents from a collection. Here are <Link to="/mongo-db/query-operators">some</Link> most commonly used query operators.
                </p>
                <h5 className='mb-4'>Examples of Query Document</h5>
                <ul style={{listStyle:'disc'}}>
                    <li>
                        <strong>Query All Documents</strong>
                        <p>To retrieve all documents in a collection:</p>
                        <div className='codePalateBox mt-2 mb-2'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p>db.myCollection.find()</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <strong>Query Documents with Specific Criteria</strong>
                        <p>To find documents where the <strong>`age`</strong> is greater than 30:</p>
                        <div className='codePalateBox mt-2 mb-2'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p>db.myCollection.find({ age: { <span class="color-pink">$gt</span>: 30 } })</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <strong>Query Documents with Multiple Criteria</strong>
                        <p>To find documents where the <strong>`age`</strong> is greater than 30 and the <strong>`isActive`</strong> field is <strong>`true`</strong>:</p>
                        <div className='codePalateBox mt-2 mb-2'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p>db.myCollection.find({ age: { <span class="color-pink">$gt</span>: 30 }, isActive: <span class="color-blue">true</span> })</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <strong>Query Documents with Logical Operators</strong>
                        <p>To find documents where <strong>`age`</strong> is less than 30 or <strong>`isActive`</strong> is <strong>`false`</strong>:</p>
                        <div className='codePalateBox mt-2 mb-2'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p>db.myCollection.find({ <span class="color-pink">$or</span>: [{ age: { <span class="color-pink">$lt</span>: 30 } }, { isActive: <span class="color-blue">false</span> }] })</p>
                                `
                            }}></div>
                        </div>
                    </li>
                </ul>
            </div>
        </section>
    )
}