import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/node-js/environment-setup";
export default function Environment() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Node Js - Environment Setup | Aspirant's Home");
        const urls = {
            'previous': '/node-js/introduction',
            'next': '/node-js/get-started'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>Environment Setup</h3>
            <p className='mt-4'> Node.js can be installed on different OS platforms such as Windows, Linux, Mac OS X, etc</p>
            <div>
                <section className='mb-3'>
                    <h6 className='mb-4'>For Windows:</h6>
                    <ul style={{ 'listStyle': 'decimal' }}>
                        <li><strong>Download Node.js Installer:</strong> Visit the official Node.js website (https://nodejs.org/) and download the Windows installer (.msi file) for the LTS (Long-Term Support) version.</li>

                        <li><strong>Run the Installer:</strong> Double-click the downloaded .msi file to run the installer. Follow the on-screen instructions to install Node.js and npm.</li>

                        <li><strong>Verify Installation:</strong> Open a command prompt and run the following commands to verify that Node.js and npm are installed:
                            <div className='codePalateBox mt-2'>
                                <div className='codePalate'>
                                    <p>node -v</p>
                                    <p>npm -v</p>
                                </div>
                            </div>
                        </li>
                    </ul>

                </section>
                <section className='mt-4 mb-3'>
                    <h6 className='mb-4'>For macOS:</h6>
                    <ul style={{ 'listStyle': 'decimal' }}>
                        <li><strong>Install Node.js via Homebrew (Optional):</strong> If you have Homebrew installed, you can install Node.js and npm using the following command:
                            <div className='codePalateBox mt-2'>
                                <div className='codePalate'>
                                    <p>brew install node</p>
                                </div>
                            </div>
                        </li>
                        <li><strong>Download Node.js Installer:</strong> Alternatively, you can download the macOS installer (.pkg file) from the official Node.js website and run it to install Node.js and npm.</li>

                        <li><strong>Verify Installation:</strong> Open a terminal and run the following commands to verify that Node.js and npm are installed:
                            <div className='codePalateBox mt-2'>
                                <div className='codePalate'>
                                    <p>node -v</p>
                                    <p>npm -v</p>
                                </div>
                            </div>
                        </li>
                    </ul>
                </section>
                <section className='mt-4 mb-3'>
                    <h6 className='mb-4'>For Linux (Ubuntu/Debian):</h6>
                    <ul style={{ 'listStyle': 'decimal' }}>
                        <li><strong>Install Node.js using Package Manager:</strong> You can install Node.js and npm using the package manager. First, update the package index:
                            <div className='codePalateBox mt-2'>
                                <div className='codePalate'>
                                    <p>sudo apt update</p>
                                </div>
                            </div>
                            <div>Then, install Node.js and npm:</div>
                            <div className='codePalateBox mt-2'>
                                <div className='codePalate'>
                                    <p>sudo apt install nodejs npm</p>
                                </div>
                            </div>
                        </li>
                        <li><strong>Verify Installation:</strong> Run the following commands to verify that Node.js and npm are installed:
                            <div className='codePalateBox mt-2'>
                                <div className='codePalate'>
                                    <p>node -v</p>
                                    <p>npm -v</p>
                                </div>
                            </div>
                        </li>
                    </ul>
                </section>
            </div>
        </section>
    )
}
