import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/html/input-attributes";

export default function HTMLForms() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("HTML - Input Attributes  | Aspirant's Home");
        const urls = {
            'previous': '/html/inputs',
            'next': '/html/video-element'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>HTML - Input Attributes</h3>
            <div className='mt-4 mb-5'>
                <p>
                    HTML input attributes are used to define the characteristics and behavior of the <strong>`&lt;input&gt;`</strong> element.
                </p>
                <p>
                    These attributes apply to various types of input fields such as text, email, password, date, number, and more. The <strong>`&lt;input&gt;`</strong> element creates interactive controls for web-based forms, allowing them to accept data from the user.
                </p>
                <h5 className='mt-5 mb-3'>Commonly Used Attributes</h5>
                <p>
                    Here are some of the most frequently used attributes for the <strong>`&lt;input type="text"&gt;`</strong> element:
                </p>

                <ul style={{ listStyle: 'disc' }}>
                    <li>
                        <h6>The `name` attribute</h6>
                        <p>
                            This attribute specifies the name of the input field. This is used to reference the form data after it is submitted.
                        </p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p>&lt;input <span class="color-pink">type</span>=<span class="color-green">"text"</span> <span class="color-pink">name</span>=<span class="color-green">"username"</span>&gt;</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <h6>The `id` attribute</h6>
                        <p>
                            It specifies a unique identifier for the input field. This is often used in conjunction with the <strong>`&lt;label&gt;`</strong> element.
                        </p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p>&lt;input <span class="color-pink">type</span>=<span class="color-green">"text"</span> <span class="color-pink">name</span>=<span class="color-green">"username"</span> <span class="color-pink">id</span>=<span class="color-green">"username"</span>&gt;</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <h6>The `value` attribute</h6>
                        <p>
                            This attribute specifies the initial value of the input field.
                        </p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p>&lt;input <span class="color-pink">type</span>=<span class="color-green">"text"</span> <span class="color-pink">name</span>=<span class="color-green">"username"</span> <span class="color-pink">value</span>=<span class="color-green">"JohnDoe"</span>&gt;</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <h6>The `placeholder` attribute</h6>
                        <p>
                            This attribute specifies a short hint that describes the expected value of the input field. This hint is displayed in the field when it is empty.
                        </p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p>&lt;input <span class="color-pink">type</span>=<span class="color-green">"text"</span> <span class="color-pink">name</span>=<span class="color-green">"username"</span> <span class="color-pink">placeholder</span>=<span class="color-green">"Enter your username"</span>&gt;</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <h6>The `required` attribute</h6>
                        <p>
                            This attribute indicates that the input field must be filled out before submitting the form.
                        </p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p>&lt;input <span class="color-pink">type</span>=<span class="color-green">"text"</span> <span class="color-pink">name</span>=<span class="color-green">"username"</span> <span class="color-pink">required</span>&gt;</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <h6>The `maxlength` attribute</h6>
                        <p>
                            This attribute specifies the maximum number of characters allowed in the input field.
                        </p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p>&lt;input <span class="color-pink">type</span>=<span class="color-green">"text"</span> <span class="color-pink">name</span>=<span class="color-green">"username"</span> <span class="color-pink">maxlength</span>=<span class="color-green">"15"</span>&gt;</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <h6>The `minlength` attribute</h6>
                        <p>
                            This attribute specifies the minimum number of characters required in the input field.
                        </p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p>&lt;input <span class="color-pink">type</span>=<span class="color-green">"text"</span> <span class="color-pink">name</span>=<span class="color-green">"username"</span> <span class="color-pink">minlength</span>=<span class="color-green">"5"</span>&gt;</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <h6>The `readonly` attribute</h6>
                        <p>
                            This attribute specifies that the input field is read-only, meaning the user cannot modify its value.
                        </p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p>&lt;input <span class="color-pink">type</span>=<span class="color-green">"text"</span> <span class="color-pink">name</span>=<span class="color-green">"username"</span> <span class="color-pink">value</span>=<span class="color-green">"JohnDoe"</span> <span class="color-pink">readonly</span>&gt;</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <h6>The `disabled` attribute</h6>
                        <p>
                            It specifies that the input field is disabled, meaning the user cannot interact with it and it is not submitted with the form.
                        </p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p>&lt;input <span class="color-pink">type</span>=<span class="color-green">"text"</span> <span class="color-pink">name</span>=<span class="color-green">"username"</span> <span class="color-pink">disabled</span>&gt;</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <h6>The `size` attribute</h6>
                        <p>
                            It specifies the width of the input field in characters. It means user can write only the specified character in this text field.
                        </p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p>&lt;input <span class="color-pink">type</span>=<span class="color-green">"text"</span> <span class="color-pink">name</span>=<span class="color-green">"username"</span> <span class="color-pink">size</span>=<span class="color-green">"15"</span>&gt;</p>
                                `
                            }}></div>
                        </div>
                    </li>
                </ul>
            </div>
        </section>
    )
}