import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/html/attributes";

export default function HTMLAttribute() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("HTML - Attributes | Aspirant's Home");
        const urls = {
            'previous': '/html/elements',
            'next': '/html/headings'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>HTML - Attributes</h3>
            <div className='mt-4 mb-5'>
                <p>
                    HTML attributes provide additional information about HTML elements. They are used to modify the default behavior or appearance of elements and are included within the opening tag. Attributes come in name/value pairs, where the name is the attribute and the value provides specific information.
                </p>
                <h5 className='mt-5 mb-3'>Structure of HTML Attributes</h5>
                <p>An attribute is included within an opening tag and consists of:</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><strong>Name</strong>: The attribute name (e.g., <strong>`href`</strong>, <strong>`src`</strong>, <strong>`class`</strong>).</li>
                    <li><strong>Value</strong>: The attribute value (e.g., <strong>`https://example.com`</strong>, <strong>`image.jpg`</strong>, <strong>`myClass`</strong>), enclosed in quotes.</li>
                </ul>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>&lt;a <span class="color-pink">href</span>=<span class="color-green">"https://example.com"</span> <span class="color-pink">target</span>=<span class="color-green">"_blank"</span>&gt;Visit Example.com&lt;/a&gt;</p>
                        `
                    }}></div>
                </div>
                <p>
                    Where,
                </p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><strong>`href`</strong> and <strong>`target`</strong> are attributes of the <strong>`&lt;a&gt;`</strong> element.</li>
                    <li><strong>`https://example.com`</strong> is the value of the <strong>`href`</strong> attribute.</li>
                    <li><strong>`_blank`</strong> is the value of the target attribute.</li>
                </ul>
                <h5 className='mt-5 mb-3'>Common HTML Attributes</h5>
                <ul style={{ listStyle: 'decimal' }}>
                    <li>
                        <strong>Global Attributes</strong>: These attributes can be used on any HTML element.
                        <ul style={{ listStyle: 'disc' }} className='mt-4'>
                            <li>
                                <strong>`class`</strong>: Specifies one or more class names for an element (used for CSS and JavaScript).
                                <div className='codePalateBox mt-2 mb-4'>
                                    <div className='codePalate' dangerouslySetInnerHTML={{
                                        __html: `
                                            <p>&lt;div <span class="color-pink">class</span>=<span class="color-green">"container"</span>&gt;&lt;/div&gt;</p>
                                        `
                                    }}></div>
                                </div>
                            </li>
                            <li>
                                <strong>`id`</strong>: Specifies a unique id for an element (used for CSS and JavaScript).
                                <div className='codePalateBox mt-2 mb-4'>
                                    <div className='codePalate' dangerouslySetInnerHTML={{
                                        __html: `
                                            <p>&lt;h1 <span class="color-pink">id</span>=<span class="color-green">"main-heading"</span>&gt;Heading&lt;/h1&gt;</p>
                                        `
                                    }}></div>
                                </div>
                            </li>
                            <li>
                                <strong>`style`</strong>: Specifies inline CSS styles for an element.
                                <div className='codePalateBox mt-2 mb-4'>
                                    <div className='codePalate' dangerouslySetInnerHTML={{
                                        __html: `
                                            <p>&lt;p <span class="color-pink">style</span>=<span class="color-green">"color: red;"</span>&gt;This is a red paragraph.&lt;/p&gt;</p>
                                        `
                                    }}></div>
                                </div>
                            </li>
                            <li>
                                <strong>`title`</strong>: Provides additional information about an element (often displayed as a tooltip).
                                <div className='codePalateBox mt-2 mb-4'>
                                    <div className='codePalate' dangerouslySetInnerHTML={{
                                        __html: `
                                            <p>&lt;abbr <span class="color-pink">title</span>=<span class="color-green">"HyperText Markup Language"</span>&gt;HTML&lt;/abbr&gt;</p>
                                        `
                                    }}></div>
                                </div>
                            </li>
                            <li>
                                <strong>`lang`</strong>: Specifies the language of the element's content.
                                <div className='codePalateBox mt-2 mb-4'>
                                    <div className='codePalate' dangerouslySetInnerHTML={{
                                        __html: `
                                            <p>&lt;p <span class="color-pink">lang</span>=<span class="color-green">"en"</span>&gt;This is a paragraph.&lt;/p&gt;</p>
                                        `
                                    }}></div>
                                </div>
                            </li>
                            <li>
                                <strong>`data-*`</strong>: Used to store custom data private to the page or application.
                                <div className='codePalateBox mt-2 mb-4'>
                                    <div className='codePalate' dangerouslySetInnerHTML={{
                                        __html: `
                                            <p>&lt;div <span class="color-pink">data-user-id</span>=<span class="color-green">"12345"</span>>User Info&lt;/div&gt;</p>
                                        `
                                    }}></div>
                                </div>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <strong>Specific Attributes</strong>: These attributes are specific to certain HTML elements.
                        <ul style={{ listStyle: 'disc' }} className='mt-4'>
                            <li>
                                <strong>For `&lt;a&gt;` (anchor) elements:</strong>
                                <ul style={{ listStyle: 'disc' }} className='mt-4'>
                                    <li>
                                        <strong>`href`</strong>: Specifies the URL of the linked document.
                                        <div className='codePalateBox mt-2 mb-4'>
                                            <div className='codePalate' dangerouslySetInnerHTML={{
                                                __html: `
                                                    <p>&lt;a <span class="color-pink">href</span>=<span class="color-green">"https://example.com"</span>&gt;Visit Example.com&lt;/a&gt;</p>
                                                `
                                            }}></div>
                                        </div>
                                    </li>
                                    <li>
                                        <strong>`target`</strong>: Specifies where to open the linked document.
                                        <div className='codePalateBox mt-2 mb-4'>
                                            <div className='codePalate' dangerouslySetInnerHTML={{
                                                __html: `
                                                    <p>&lt;a <span class="color-pink">href</span>=<span class="color-green">"https://example.com"</span> <span class="color-pink">target</span>=<span class="color-green">"_blank"</span>&gt;Open in new tab&lt;/a&gt;</p>
                                                `
                                            }}></div>
                                        </div>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <strong>For `&lt;img&gt;` (image) elements:</strong>
                                <ul style={{ listStyle: 'disc' }} className='mt-4'>
                                    <li>
                                        <strong>`src`</strong>: Specifies the path to the image.
                                        <div className='codePalateBox mt-2 mb-4'>
                                            <div className='codePalate' dangerouslySetInnerHTML={{
                                                __html: `
                                                    <p>&lt;img <span class="color-pink">src</span>=<span class="color-green">"image.jpg"</span> <span class="color-pink">alt</span>=<span class="color-green">"Description of the image"</span>&gt;</p>
                                                `
                                            }}></div>
                                        </div>
                                    </li>
                                    <li>
                                        <strong>`alt`</strong>: Provides alternative text for the image (used for accessibility).
                                        <div className='codePalateBox mt-2 mb-4'>
                                            <div className='codePalate' dangerouslySetInnerHTML={{
                                                __html: `
                                                    <p>&lt;img <span class="color-pink">src</span>=<span class="color-green">"image.jpg"</span> <span class="color-pink">alt</span>=<span class="color-green">"Description of the image"</span>&gt;</p>
                                                `
                                            }}></div>
                                        </div>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <strong>For `&lt;input&gt;` (form input) elements:</strong>
                                <ul style={{ listStyle: 'disc' }} className='mt-4'>
                                    <li>
                                        <strong>`type`</strong>: Specifies the type of input.
                                        <div className='codePalateBox mt-2 mb-4'>
                                            <div className='codePalate' dangerouslySetInnerHTML={{
                                                __html: `
                                                    <p>&lt;input <span class="color-pink">type</span>=<span class="color-green">"text"</span> <span class="color-pink">name</span>=<span class="color-green">"username"</span>&gt;</p>
                                                `
                                            }}></div>
                                        </div>
                                    </li>
                                    <li>
                                        <strong>`name`</strong>: Specifies the name of the input element.
                                        <div className='codePalateBox mt-2 mb-4'>
                                            <div className='codePalate' dangerouslySetInnerHTML={{
                                                __html: `
                                                    <p>&lt;input <span class="color-pink">type</span>=<span class="color-green">"text"</span> <span class="color-pink">name</span>=<span class="color-green">"username"</span>&gt;</p>
                                                `
                                            }}></div>
                                        </div>
                                    </li>
                                    <li>
                                        <strong>`value`</strong>: Specifies the default value of the input element.
                                        <div className='codePalateBox mt-2 mb-4'>
                                            <div className='codePalate' dangerouslySetInnerHTML={{
                                                __html: `
                                                    <p>&lt;input <span class="color-pink">type</span>=<span class="color-green">"text"</span> <span class="color-pink">name</span>=<span class="color-green">"username"</span>  <span class="color-pink">value</span>=<span class="color-green">"John Doe"</span>&gt;</p>
                                                `
                                            }}></div>
                                        </div>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <strong>For `&lt;form&gt;` elements:</strong>
                                <ul style={{ listStyle: 'disc' }} className='mt-4'>
                                    <li>
                                        <strong>`action`</strong>: Specifies the URL where the form data will be sent.
                                        <div className='codePalateBox mt-2 mb-4'>
                                            <div className='codePalate' dangerouslySetInnerHTML={{
                                                __html: `
                                                    <p>&lt;form <span class="color-pink">action</span>=<span class="color-green">"/submit"</span> <span class="color-pink">method</span>=<span class="color-green">"post"</span>&gt;</p>
                                                `
                                            }}></div>
                                        </div>
                                    </li>
                                    <li>
                                        <strong>`method`</strong>: Specifies the HTTP method to be used when submitting the form.
                                        <div className='codePalateBox mt-2 mb-4'>
                                            <div className='codePalate' dangerouslySetInnerHTML={{
                                                __html: `
                                                    <p>&lt;form <span class="color-pink">action</span>=<span class="color-green">"/submit"</span> <span class="color-pink">method</span>=<span class="color-green">"post"</span>&gt;</p>
                                                `
                                            }}></div>
                                        </div>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
        </section>
    )
}