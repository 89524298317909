import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "./Context";
import Footer from "./pages/Footer";
import TopHeader from "./pages/TopHeader";

export default function Privacy() {
    const title = useContext(TitleContext);
    useEffect(() => {
        title.setPageTitle("Privacy Policy | Aspirant's Home");
    }, [])


    return (
        <>
            <TopHeader />
            <div className='container-fluid'>
                <div className='row mt-5 mb-5'>
                    <div className="col-2">
                        <ins className="adsbygoogle"
                            style={{ 'display': 'block' }}
                            data-ad-client="ca-pub-4667727216643481"
                            data-ad-slot="1035415263"
                            data-ad-format="auto"
                            data-full-width-responsive="true"></ins>
                        <script>
                            (adsbygoogle = window.adsbygoogle || []).push({ });
                        </script>   
                    </div>
                    <div className="col-8">
                        <h3 className="text-center mb-5">Privacy Policy</h3>
                        <p>
                            Welcome to Aspirant's Home! We are dedicated to protecting your privacy. This Privacy Policy outlines how we collect, use, and protect your information when you visit our website.
                        </p>
                        <ul style={{ 'listStyle': 'decimal' }}>
                            <li>
                                <strong>Information We Collect</strong>
                                <p>We collect information automatically to improve your experience on our Site:</p>
                                <ul style={{ 'listStyle': 'disc' }}>
                                    <li>
                                        <strong>Usage Data</strong>: We collect information about how you interact with our Site. This includes your IP address, browser type, browser version, the pages you visit, the time and date of your visit, the time spent on those pages, and other diagnostic data.
                                    </li>
                                    <li>
                                        <strong>Cookies and Tracking Technologies</strong>: We use cookies and similar tracking technologies to monitor activity on our Site and store certain information. Cookies are files with small amounts of data, which may include an anonymous unique identifier.
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <strong>How We Use Your Information</strong>
                                <p>The information we collect is used for the following purposes:</p>
                                <ul style={{ 'listStyle': 'disc' }}>
                                    <li>
                                        To provide and maintain our Site
                                    </li>
                                    <li>To analyze and understand how our Site is used so we can improve it</li>
                                    <li>To monitor and ensure the security of our Site</li>
                                    <li>To detect, prevent, and address technical issues</li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                    <div className="col-2">
                        <ins className="adsbygoogle"
                            style={{ 'display': 'block' }}
                            data-ad-client="ca-pub-4667727216643481"
                            data-ad-slot="1035415263"
                            data-ad-format="auto"
                            data-full-width-responsive="true"></ins>
                        <script>
                            (adsbygoogle = window.adsbygoogle || []).push({ });
                        </script>   
                    </div>
                </div>
                <div className="row">
                    <div className='footerAdSpace'>
                        <ins className="adsbygoogle"
                            style={{ 'display': 'block' }}
                            data-ad-client="ca-pub-4667727216643481"
                            data-ad-slot="6714530623"
                            data-ad-format="auto"
                            data-full-width-responsive="true"></ins>
                        <script>
                            (adsbygoogle = window.adsbygoogle || []).push({ });
                        </script>
                    </div>
                    <div className='col-12'>
                        <div className='container-fluid footerPart'>
                            <Footer />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
    
}