import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/react-js/folder-architecture";

export default function FolderArchitecture() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("React Js - Folder Architecture | Aspirant's Home");
        const urls = {
            'previous': '/react-js/start-new-application',
            'next':'/react-js/render-html'
        }
        path.setPreviousNext(urls);
    },[])


    return (
        <section className='mt-5 mb-5'>
            <h3>React Js - Folder Architecture</h3>
            <div className='mb-4 mt-5'>
                <p>Open your project in VS code. You can check the folder architecture below,</p>
                <div className='mt-3'>
                    <img className='img-fluid' src='../assets/react/folder-architecture.png' />
                </div>
            </div>
            <div className='mt-4 mb-5'>
                <ul style={{ listStyle: 'disc' }}>
                    <li><strong>`node_modules/`</strong>: This folder contains all the dependencies installed via npm.</li>

                    <li><strong>`public/`</strong>: Static files like index.html and other assets that don't need processing by Webpack or Babel.</li>

                    <li><strong>`src/`</strong>: Source code of the React application.</li>

                    <li><strong>`assets/`</strong>: Images, fonts, or other static assets used in the app.</li>

                    <li><strong>`App.js`</strong>: The root component of the application that contains the main structure and routing logic.</li>

                    <li><strong>`index.js`</strong>: The entry point of the application where React is rendered into the DOM.</li>

                    <li><strong>`.gitignore`</strong>: Specifies which files and directories to ignore in version control (e.g., node_modules/).</li>

                    <li><strong>`package.json`</strong>: Contains metadata about the project and its dependencies, as well as scripts for building and running the app.</li>

                    <li><strong>`README.md`</strong>: Documentation for the project, typically written in Markdown format.</li>
                </ul>
                <p>This is a basic structure, and depending on the project's complexity and requirements, additional folders and files may be added.</p>
            </div>
        </section>
    )
}
