import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/react-js/styling";

export default function ReactStyling() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("React Styling | Aspirant's Home");
        const urls = {
            'previous': '/react-js/handling-fetched-data',
            'next':'/react-js/css-files'
        }
        path.setPreviousNext(urls);
    },[])

    return (
        <section className='mt-5 mb-5'>
            <h3>React Js - Styling</h3>
            <div className='mt-4 mb-4'>
                <p>In React, there are several ways to style your components. The most common approaches include using</p>
                <ul style={{listStyle:'disc'}}>
                    <li><strong>CSS files</strong>,</li>
                    <li><strong>Inline styles</strong>,</li>
                    <li><strong>CSS modules</strong>, and</li>
                    <li><strong>CSS-in-JS</strong> libraries with <strong>"Styled Components"</strong>.</li>
                </ul>
                <p>Each approach has its own advantages and use cases. </p>
            </div>
            
        </section>
    )
}
