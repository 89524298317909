import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/node-js/get-started";
export default function GetStarted() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Node Js - Start A New Application | Aspirant's Home");
        const urls = {
            'previous': '/node-js/environment-setup',
            'next': '/node-js/modules'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>Start A New Application</h3>
            <p className='mt-4'> To start a new application in Node.js, you'll need to follow these steps:</p>
            <div>
                <section className='mb-3'>
                    <ul style={{ 'listStyle': 'decimal' }}>
                        <li className='mb-3'><strong>Create a New Directory:</strong> Create a new directory for your Node.js application. You can do this using the command line:
                            <div className='codePalateBox mt-2'>
                                <div className='codePalate'>
                                    <p><span class='color-yellow'>mkdir</span> my-node-app</p>
                                </div>
                            </div>
                        </li>
                        <li className='mb-3'><strong>Navigate to the Directory:</strong> Move into the newly created directory:
                            <div className='codePalateBox mt-2'>
                                <div className='codePalate'>
                                    <p><span class='color-yellow'>cd</span> my-node-app</p>
                                </div>
                            </div>
                        </li>
                        <li className='mb-3'><strong>Initialize a New Node.js Project:</strong> Initialize a new Node.js project in the directory. This will create a <strong>`package.json`</strong> file, which stores metadata about your project and its dependencies:
                            <div className='codePalateBox mt-2'>
                                <div className='codePalate'>
                                    <p>npm init -y</p>
                                </div>
                            </div>
                            <div>The -y flag skips the interactive setup and uses the default values for <strong>`package.json`</strong>.</div>
                        </li>
                        <li className='mb-3'><strong>Create Your Application File:</strong> Create a new JavaScript file (e.g., <strong>`app.js`</strong>) in your project directory. This file will contain the code for your Node.js application.</li>
                        <li className='mb-3'><strong>Write Your Node.js Application:</strong>Open the <strong>`app.js`</strong> file in a text editor and write your Node.js application code. For example, you can create a simple HTTP server:
                            <div className='codePalateBox mt-2'>
                                <div className='codePalate' dangerouslySetInnerHTML={{ __html: "<div className='codeSample'><p><span class='color-blue'>const</span> http = <span class='color-yellow'>require</span>(<span class='color-green'>'http'</span>);</p><p><span class='color-blue'>const</span> hostname = <span class='color-green'>'127.0.0.1'</span>;</p><p><span class='color-blue'>const</span> port = <span class='color-pink'>3000</span>;</p>                                 <p><span class='color-blue'>const</span> server = http.<span class='color-red'>createServer</span>((req, res) => {</p><p style='margin-left:30px'> res.statusCode = <span class='color-pink'>200</span>;</p>             <p style='margin-left:30px'>res.<span class='color-red'>setHeader</span>(<span class='color-green'>'Content-Type', 'text/plain'</span>);</p><p style='margin-left:30px'>res.<span class='color-red'>end</span>(<span class='color-green'>'Hello, World!\n'</span>);</p>            <p>});</p><p>server.<span class='color-red'>listen</span>(port, hostname, () => {</p>                                      <p style='margin-left:30px'><span class='color-pink'>console</span>.<span class='color-red'>log</span>(<span class='color-green'>`Server running at http://${hostname}:${port}/`</span>);</p><p>});</p></div>" }}>
                                </div>
                            </div>
                        </li>
                        <li className='mb-3'><strong>Run Your Node.js Application:</strong> To run your Node.js application, use the following command:
                            <div className='codePalateBox mt-2'>
                                <div className='codePalate'>
                                    <p>node app.js</p>
                                </div>
                            </div>
                            <div>This will start your Node.js application, and you should see the message Server running at <strong>`http://127.0.0.1:3000/`</strong> in the console.</div>
                        </li>
                        <li className='mb-3'><strong>Access Your Application:</strong>Open a web browser and navigate to <strong>`http://127.0.0.1:3000/`</strong> to access your Node.js application. You should see the message "Hello, World!" displayed in the browser.</li>
                    </ul>
                </section>
            </div>
        </section>
    )
}
