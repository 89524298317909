import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/react-js/inline-styles";

export default function InlineStyle() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("React Styling - Using Inline Style | Aspirant's Home");
        const urls = {
            'previous': '/react-js/css-files',
            'next':'/react-js/css-modules'
        }
        path.setPreviousNext(urls);
    },[])

    return (
        <section className='mt-5 mb-5'>
            <h3>React Js - Styling Using Inline Style</h3>
            <div className='mt-4 mb-5'>
                <p>
                    In React, you can apply inline styles to elements using the <strong>`style`</strong> attribute. The <strong>`style`</strong> attribute expects an object where the keys are camelCased CSS property names and the values are the corresponding CSS values as strings. Here's an example:
                    <div className='codePalateBox mt-2 mb-2'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                                <p><span class="color-blue">import</span> <span class="color-red">React</span> <span class="color-blue">from </span> <span class="color-green">'react'</span>;</p>
                                <br />
                                <p><span class="color-blue">function</span> <span class="color-red">App</span>() {</p>
                                <p style="margin-left:30px">    <span class="color-blue">const</span> headingStyle = {</p>
                                <p style="margin-left:60px">        <span class="color-pink">color</span>: <span class="color-green">'blue'</span>,</p>
                                <p style="margin-left:60px">        <span class="color-pink">fontSize</span>: <span class="color-green">'24px'</span>,</p>
                                <p style="margin-left:60px">        <span class="color-pink">marginBottom</span>: <span class="color-green">'20px'</span></p>
                                <p style="margin-left:30px">    };</p>
                                <br />
                                <p style="margin-left:30px">    <span class="color-blue">return</span> (</p>
                                <p style="margin-left:60px">        &lt;div&gt;</p>
                                <p style="margin-left:90px">            &lt;h1 <span class="color-pink">style</span>=<span class="color-green">{headingStyle}</span>&gt;Hello, world!&lt;/h1&gt;</p>
                                <p style="margin-left:90px">            &lt;p <span class="color-pink">style</span>=<span class="color-green">{{ fontStyle: 'italic' }}</span>&gt;This is a paragraph with inline styles.&lt;/p&gt;</p>
                                <p style="margin-left:60px">        &lt;/div&gt;</p>
                                <p style="margin-left:30px">    );</p>
                                <p>}</p>
                                <br />
                                <p><span class="color-blue">export default</span> <span class="color-red">App</span>;</p>
                            `
                        }}></div>
                    </div>
                </p>
            </div>
            <div className='mt-5 mb-4'>
                <h5>Dynamic & Conditional Styling</h5>
                <div className='mt-4 mb-4'>
                    <p>In React, you can conditionally apply styles in inline CSS by dynamically constructing the style object based on some condition or state. Here's an example:</p>
                    <div className='codePalateBox mt-2 mb-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                                <p><span class="color-blue">import</span> <span class="color-red">React</span> <span class="color-blue">from</span> <span class="color-green">'react'</span>;</p>
                                <br />
                                <p><span class="color-blue">function</span> <span class="color-red">App</span>() {</p>
                                <p style="margin-left:30px">     <span class="color-blue">const</span> isError = <span class="color-blue">true</span>;</p>
                                <br />
                                <p style="margin-left:30px">     <span class="color-blue">const</span> buttonStyle = {</p>
                                <p style="margin-left:60px">            <span class="color-pink">padding</span>: <span class="color-green">'10px'</span>,</p>
                                <p style="margin-left:60px">            <span class="color-pink">backgroundColor</span>: isError ? <span class="color-green">'red'</span> : <span class="color-green">'green'</span>,</p>
                                <p style="margin-left:60px">            <span class="color-pink">color</span>: <span class="color-green">'white'</span>,</p>
                                <p style="margin-left:60px">            <span class="color-pink">border</span>: <span class="color-green">'none'</span>,</p>
                                <p style="margin-left:60px">            <span class="color-pink">borderRadius</span>: <span class="color-green">'5px'</span>,</p>
                                <p style="margin-left:60px">            <span class="color-pink">cursor</span>: <span class="color-green">'pointer'</span></p>
                                <p style="margin-left:30px">     };</p>
                                <br />
                                <p style="margin-left:30px">     <span class="color-blue">return</span> (</p>
                                <p style="margin-left:60px">         &lt;div&gt;</p>
                                <p style="margin-left:90px">             &lt;button <span class="color-pink">style</span>=<span class="color-green">{buttonStyle}</span>&gt;</p>
                                <p style="margin-left:90px">                  {isError ? 'Error' : 'Success'}</p>
                                <p style="margin-left:90px">             &lt;/button&gt;</p>
                                <p style="margin-left:60px">         &lt;/div&gt;</p>
                                <p style="margin-left:30px">      );</p>
                                <p>}</p>
                                <br />
                                <p><span class="color-blue">export default</span> <span class="color-red">App</span>;</p>
                            `
                        }}></div>
                    </div>
                    <p>In this example, the <strong>`isError`</strong> variable is a boolean that determines whether the button should have a red background (indicating an error) or a green background (indicating success). The <strong>`backgroundColor`</strong> property in the <strong>`buttonStyle`</strong> object is set based on the value of <strong>`isError`</strong>.</p>
                </div>
            </div>
        </section>
    )
}