import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../../Context";
import { Link } from 'react-router-dom';
export const pageUrl = () => "/node-js/built-in-modules/crypto";

export default function CryptoModule() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("NodeJs - Crypto Module | Aspirant's Home");
        path.setPreviousNext({});
    }, [])

    return (
        <div className='contentPage'>
            <section class='clearfix mb-3'>
                <span className='float-start'>
                    <Link to='/node-js/built-in-modules' onClick={() => path.setPathName('/node-js/built-in-modules')} className='btn btn-primary'> <i class="fa-solid fa-less-than"></i>&nbsp; Back to Built-In Modules </Link>
                </span>
            </section>
            <section className='mt-5 mb-5'>
                <h3>Node Js Crypto Module</h3>
                <div className='mt-4'>
                    <p>The crypto module provides cryptographic functionality including encryption, decryption, hashing, and more. It offers a way to perform secure communication, generate secure random numbers, and create cryptographic signatures.</p>
                    <p>The syntax for including the crypto module in your application: </p>
                    <div className='codePalateBox mt-2 mb-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `<p><span class='color-blue'>var</span> crypto = <span class='color-yellow'>require</span>(<span class='color-green'>'crypto'</span>);</p>`
                        }}></div>
                    </div>
                </div>
                <div className='mt-4'>
                    <h4>Examples</h4>
                    <p>Here's a basic example of how you might use the crypto module for encryption and decryption:</p>
                    <div className='codePalateBox mt-2 mb-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `<p><span class='color-blue'>const</span> crypto = <span class='color-yellow'>require</span>(<span class='color-green'>'crypto'</span>);</p>
                            <br />
                            <p><span class='color-grey'>// Encryption</span></p>
                            <p><span class='color-blue'>const</span> algorithm = <span class='color-green'>'aes-256-cbc'</span>; <span class='color-grey'>// AES encryption with a 256-bit key in CBC mode</span></p>
                            <p><span class='color-blue'>const</span> key = crypto.<span class='color-red'>randomBytes</span>(32); <span class='color-grey'>// Generate a random 256-bit key</span></p>
                            <p><span class='color-blue'>const</span> iv = crypto.<span class='color-red'>randomBytes</span>(16); <span class='color-grey'>// Generate a random initialization vector</span></p>
                            <br />
                            <p><span class='color-blue'>const</span> cipher = crypto.<span class='color-red'>createCipheriv</span>(algorithm, key, iv);</p>
                            <p><span class='color-blue'>let</span> encrypted = cipher.<span class='color-red'>update</span>(<span class='color-green'>'Hello, World!'</span>, <span class='color-green'>'utf8'</span>, <span class='color-green'>'hex'</span>);</p>
                            <p>encrypted += cipher.<span class='color-red'>final</span>(<span class='color-green'>'hex'</span>);</p>
                            <br />
                            <p><span class='color-pink'>console</span>.<span class='color-red'>log</span>(<span class='color-green'>'Encrypted:'</span>, encrypted); <span class='color-grey'>// Encrypted: 0fa5a8d7f6fdcc4c26d0b4f6ff3815f6</span></p>
                            <br />
                            <p><span class='color-grey'>// Decryption</span></p>
                            <p><span class='color-blue'>const</span> decipher = crypto.<span class='color-red'>createDecipheriv</span>(algorithm, key, iv);</p>
                            <p><span class='color-blue'>let</span> decrypted = decipher.<span class='color-red'>update</span>(encrypted, <span class='color-green'>'hex'</span>, <span class='color-green'>'utf8'</span>);</p>
                            <p>decrypted += decipher.<span class='color-red'>final</span>(<span class='color-green'>'utf8'</span>);</p>
                            <br />
                            <p><span class='color-pink'>console</span>.<span class='color-red'>log</span>(<span class='color-green'>'Decrypted:'</span>, decrypted); <span class='color-grey'>// Decrypted: Hello, World!</span></p>
                            
                                    `
                        }}></div>
                    </div>
                </div>
            </section>
        </div>
    )
}
