import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/mysql/truncate-tables";

export default function MysqlTruncateTable() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Mysql - Truncate Table | Aspirant's Home");
        const urls = {
            'previous': '/mysql/clone-tables',
            'next': '/mysql/repair-tables'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>Mysql - Truncate Table</h3>
            <div className='mt-4 mb-5'>
                <p>
                    In MySQL, the <strong>`TRUNCATE TABLE`</strong> statement is used to quickly delete all rows from a table, effectively resetting the table to an empty state. Unlike the <strong>`DELETE`</strong> statement, which removes rows one by one and can be rolled back, <strong>`TRUNCATE TABLE`</strong> is much faster and does not support transactions. Here's the basic syntax:
                </p>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-blue">TRUNCATE TABLE</span> table_name;</p>
                        `
                    }}></div>
                </div>
                <p>
                    For example, to truncate a table named <strong>`my_table`</strong>:
                </p>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-blue">TRUNCATE TABLE</span> my_table;</p>
                        `
                    }}></div>
                </div>
                <p>
                    When you truncate a table, the table structure, indexes, and columns remain intact, but all the rows are deleted. It's important to note that <strong>`TRUNCATE TABLE`</strong> is a DDL (Data Definition Language) statement and cannot be rolled back. Once you truncate a table, the data is gone permanently.
                </p>
            </div>
            <div className='mt-5 mb-5'>
                <h5>Truncate Vs Delete</h5>
                <div className='mt-2 mb-2'>
                    <p>
                        In MySQL, TRUNCATE TABLE and DELETE are both used to remove data from a table, but they work differently and have different implications:
                    </p>
                    <table className='table'>
                        <thead className='text-center'>
                            <th>#</th>
                            <th>Truncate</th>
                            <th>Delete</th>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Functionality</td>
                                <td>
                                    The TRUNCATE command is used to remove all of the rows from a table, regardless of whether or not any conditions are met.
                                </td>
                                <td>
                                    The DELETE command removes one or more rows from a table based on the conditions specified in a WHERE Clause.
                                </td>
                            </tr>
                            <tr>
                                <td>Speed</td>
                                <td>
                                    Faster than DELETE, especially for large tables, because it does not generate individual DELETE statements for each row. Instead, it deallocates the data pages.
                                </td>
                                <td>
                                    Slower than TRUNCATE TABLE, especially for large tables, because it logs individual row deletions and executes triggers (if any) for each row.
                                </td>
                            </tr>
                            <tr>
                                <td>Transactions</td>
                                <td>
                                    Not logged in the transaction log, so you cannot roll back a TRUNCATE TABLE operation. It's a DDL (Data Definition Language) statement.
                                </td>
                                <td>
                                    Logged in the transaction log, so you can roll back a DELETE operation within a transaction.
                                </td>
                            </tr>
                            <tr>
                                <td>Auto Increment</td>
                                <td>
                                    Resets auto-increment values to 1.
                                </td>
                                <td>
                                    Does not reset auto-increment values.
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </section>
    )
}