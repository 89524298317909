import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../../Context";
import { Link } from 'react-router-dom';
export const pageUrl = () => "/mysql/string-functions/length";

export default function LENGTH() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Mysql - String Functions - LENGTH() | Aspirant's Home");
        path.setPreviousNext({});
    }, [])

    return (
        <>
            <section class='clearfix mb-3'>
                <span className='float-start'>
                    <Link to='/mysql/string-functions' onClick={() => path.setPathName('/mysql/string-functions')} className='btn btn-primary'> <i class="fa-solid fa-less-than"></i>&nbsp; Back to String Functions </Link>
                </span>
            </section>
            <section className='mt-5 mb-5'>
                <h3>Mysql - LENGTH() Function</h3>
                <div className='mt-4 mb-5'>
                    <p>
                        The <strong>`LENGTH()`</strong> function in MySQL is used to return the length of a string in bytes. This is particularly useful for determining the size of a string or validating the length of input data.
                    </p>
                    <p>
                        Here is a simple example using the <strong>`LENGTH()`</strong> function:
                    </p>
                    <div className='codePalateBox mt-2 mb-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                                <p><span class="color-blue">SELECT</span> LENGTH(<span class="color-green">'Hello, World!'</span>) <span class="color-blue">AS</span> length;</p>
                            `
                        }}></div>
                    </div>
                    <p>
                        This query will return <strong>`13`</strong>.
                    </p>
                </div>
                <div className='mt-5 mb-5'>
                    <h5>LENGTH() with MySQL Table</h5>
                    <div className='mt-3 mb-2'>
                        <p>
                            Suppose we have a <strong>`users`</strong> table with <strong>`username`</strong> and <strong>`email`</strong>.
                        </p>
                        <table className='table' style={{border: '1px dashed #ccc'}}> 
                            <thead>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>id</th>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>username</th>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>email</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>1</td>
                                    <td style={{border: '1px dashed #ccc'}}>jdoe</td>
                                    <td style={{border: '1px dashed #ccc'}}>john.doe@example.com</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>2</td>
                                    <td style={{border: '1px dashed #ccc'}}>jsmith</td>
                                    <td style={{border: '1px dashed #ccc'}}>jane.smith@domain.com</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>3</td>
                                    <td style={{border: '1px dashed #ccc'}}>emilyj</td>
                                    <td style={{border: '1px dashed #ccc'}}>emily.j@workplace.org</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>4</td>
                                    <td style={{border: '1px dashed #ccc'}}>mbrown</td>
                                    <td style={{border: '1px dashed #ccc'}}>mike.b@another.net</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>5</td>
                                    <td style={{border: '1px dashed #ccc'}}>jwilliams</td>
                                    <td style={{border: '1px dashed #ccc'}}>jessica.w@example.org</td>
                                </tr>
                            </tbody>
                        </table>
                        <p>
                            If you want to calculate the length of each user's email address.
                        </p>
                        <div className='codePalateBox mt-2 mb-2'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-blue">SELECT</span> id, username, email, LENGTH(email) <span class="color-blue">AS</span> email_length <span class="color-blue">FROM</span> users;</p>
                                `
                            }}></div>
                        </div>
                        <p>
                            This query will return:
                        </p>
                        <table className='table' style={{border: '1px dashed #ccc'}}> 
                            <thead>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>id</th>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>username</th>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>email</th>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>email_length</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>1</td>
                                    <td style={{border: '1px dashed #ccc'}}>jdoe</td>
                                    <td style={{border: '1px dashed #ccc'}}>john.doe@example.com</td>
                                    <td style={{border: '1px dashed #ccc'}}>20</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>2</td>
                                    <td style={{border: '1px dashed #ccc'}}>jsmith</td>
                                    <td style={{border: '1px dashed #ccc'}}>jane.smith@domain.com</td>
                                    <td style={{border: '1px dashed #ccc'}}>21</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>3</td>
                                    <td style={{border: '1px dashed #ccc'}}>emilyj</td>
                                    <td style={{border: '1px dashed #ccc'}}>emily.j@workplace.org</td>
                                    <td style={{border: '1px dashed #ccc'}}>20</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>4</td>
                                    <td style={{border: '1px dashed #ccc'}}>mbrown</td>
                                    <td style={{border: '1px dashed #ccc'}}>mike.b@another.net</td>
                                    <td style={{border: '1px dashed #ccc'}}>19</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>5</td>
                                    <td style={{border: '1px dashed #ccc'}}>jwilliams</td>
                                    <td style={{border: '1px dashed #ccc'}}>jessica.w@example.org</td>
                                    <td style={{border: '1px dashed #ccc'}}>21</td>
                                </tr>
                            </tbody>
                        </table>
                        <p>
                            The <strong>`LENGTH()`</strong> function returns the number of characters in the <strong>`email`</strong> column for each user.
                        </p>
                    </div>
                </div>
            </section>
        </>
    )
}