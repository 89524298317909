import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../../Context";
import { Link } from 'react-router-dom';
export const pageUrl = () => "/mysql/date-and-time-functions/adddate";

export default function AddDate() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Mysql - Date and Time functions - ADDDATE() | Aspirant's Home");
        path.setPreviousNext({});
    }, [])

    return (
        <>
            <section class='clearfix mb-3'>
                <span className='float-start'>
                    <Link to='/mysql/date-and-time-functions' onClick={() => path.setPathName('/mysql/date-and-time-functions')} className='btn btn-primary'> <i class="fa-solid fa-less-than"></i>&nbsp; Back to Date and Time functions </Link>
                </span>
            </section>
            <section className='mt-5 mb-5'>
                <h3>Mysql - ADDDATE() Functions</h3>
                <div className='mt-4 mb-5'>
                    <p>
                        The <strong>`ADDDATE()`</strong> function in MySQL is used to add a specified time interval to a date. It takes two arguments: the date you want to add the interval to, and the interval itself. The interval can be specified in terms of years, months, days, hours, minutes, and seconds. 
                    </p>
                    <p>
                        Here's the basic syntax:
                    </p>
                    <div className='codePalateBox mt-2 mb-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                                <p>ADDDATE(<span class="color-pink">date</span>, <span class="color-pink">INTERVAL</span> expr unit);</p>
                            `
                        }}></div>
                    </div>
                    <p>
                        Where, <strong>`date`</strong> is the date you want to add the interval to. <strong>`expr`</strong> is the value of the interval. <strong>`unit`</strong> is the unit of the interval, such as <strong>`YEAR`</strong>, <strong>`MONTH`</strong>, <strong>`DAY`</strong>, <strong>`HOUR`</strong>, <strong>`MINUTE`</strong>, or <strong>`SECOND`</strong>.
                    </p>
                    <p>
                        For example, to add 3 days to the date '2022-05-25', you would use:
                    </p>
                    <div className='codePalateBox mt-2 mb-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                                <p><span class="color-blue">SELECT</span> ADDDATE(<span class="color-green">'2022-05-25'</span>, <span class="color-pink">INTERVAL 3</span> <span class="color-blue">DAY</span>);</p>
                            `
                        }}></div>
                    </div>
                    <p>
                        This would return '2022-05-28', indicating that 3 days have been added to '2022-05-25'.
                    </p>
                </div>
                <div className='mt-5 mb-5'>
                    <h5>ADDDATE() with MySQL Table</h5>
                    <div className='mt-3 mb-2'>
                        <p>
                            To illustrate the <strong>`ADDDATE()`</strong> function with a MySQL table, let's assume we have a table called <strong>`sales`</strong> with columns <strong>`id`</strong>, <strong>`sale_name`</strong> and <strong>`sale_date`</strong>. We want to add 30 days to the <strong>`sale_date`</strong> for a specific id. 
                        </p>
                        <p>
                            Here's an example using a sample table:
                        </p>
                        <table className='table' style={{border: '1px dashed #ccc'}}> 
                            <thead>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>id</th>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>sale_name</th>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>sale_date</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>1</td>
                                    <td style={{border: '1px dashed #ccc'}}>Sale 1</td>
                                    <td style={{border: '1px dashed #ccc'}}>2022-05-01</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>2</td>
                                    <td style={{border: '1px dashed #ccc'}}>Sale 2</td>
                                    <td style={{border: '1px dashed #ccc'}}>2022-05-15</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>3</td>
                                    <td style={{border: '1px dashed #ccc'}}>Sale 3</td>
                                    <td style={{border: '1px dashed #ccc'}}>2022-06-10</td>
                                </tr>
                            </tbody>
                        </table>
                        <p>
                            To add 30 days to the <strong>`sale_date`</strong> for <strong>`id = 1`</strong>, you would use:
                        </p>
                        <div className='codePalateBox mt-2 mb-2'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-blue">UPDATE</span> sales</p>
                                    <p><span class="color-blue">SET</span> sale_date = ADDDATE(sale_date, <span class="color-pink">30</span>)</p>
                                    <p><span class="color-blue">WHERE</span> id = <span class="color-pink">1</span>;</p>
                                `
                            }}></div>
                        </div>
                        <p>
                            After running this query, the <strong>`sale_date`</strong> for <strong>`id = 1`</strong> would be updated to '2022-05-31'. The table would then look like this:
                        </p>
                        <table className='table' style={{border: '1px dashed #ccc'}}> 
                            <thead>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>id</th>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>sale_name</th>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>sale_date</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>1</td>
                                    <td style={{border: '1px dashed #ccc'}}>Sale 1</td>
                                    <td style={{border: '1px dashed #ccc'}}>2022-05-31</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>2</td>
                                    <td style={{border: '1px dashed #ccc'}}>Sale 2</td>
                                    <td style={{border: '1px dashed #ccc'}}>2022-05-15</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>3</td>
                                    <td style={{border: '1px dashed #ccc'}}>Sale 3</td>
                                    <td style={{border: '1px dashed #ccc'}}>2022-06-10</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </section>
        </>
    )
}