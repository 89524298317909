import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';
export const pageUrl = () => "/mongo-db/aggregation";

export default function MongoAggregation() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Aggregation Pipeline in MongoDB");
        title.setKeyWords("aggregation in mongodb, indexing and aggregation in mongodb, explain aggregation in mongodb, group aggregation in mongodb, $project aggregation in mongodb, how to update in aggregation in mongodb, how to do aggregation in mongodb, aggregate mongodb addfields, skip and limit in mongodb aggregation, and operator in mongodb aggregation, group and count in mongodb aggregation");
        title.setPageDescription("Aggregation in MongoDB is a powerful framework for data analysis and transformation, enabling complex queries to be performed on collections. The aggregation framework processes data records and returns computed results. It is similar to the SQL `GROUP BY` clause but is more flexible and powerful.");
        const urls = {
            'previous': '/mongo-db/indexing',
            'next': '/mongo-db/mongoose/introduction'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>MongoDB - Aggregation</h3>
            <div className='mt-4 mb-5'>
                <p>
                    Aggregation in MongoDB is a powerful framework for data analysis and transformation, enabling complex queries to be performed on collections. The aggregation framework processes data records and returns computed results. It is similar to the SQL <strong>`GROUP BY`</strong> clause but is more flexible and powerful.
                </p>
                <p>
                    The most common way to perform aggregation in MongoDB is using the aggregation pipeline, which is a series of stages through which data passes. Each stage transforms the data in some way.
                </p>
                <p>
                    Here are some key stages used in aggregation pipelines:
                </p>
                <ul style={{listStyle:'decimal'}}>
                    <li className='mb-4'>
                        <strong><Link className='noUnderline' to="/mongo-db/aggregation/match" onClick={() => path.setPathName('/mongo-db/aggregation/match')}>$match</Link></strong> is used to filter documents in a collection. It acts similarly to the find method by filtering documents based on specified criteria. However, <strong>`$match`</strong> is used within the context of an aggregation pipeline to filter documents at any stage of the pipeline.
                        <div className='codePalateBox mt-2 mb-2'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p>{ <span class="color-pink">$match</span>: { <span class="color-pink">status</span>: <span class="color-green">"A"</span> } }</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li className='mb-4'>
                        <strong><Link className='noUnderline' to="/mongo-db/aggregation/group" onClick={() => path.setPathName('/mongo-db/aggregation/group')}>$group</Link></strong> is used to group documents by a specified key and perform operations on the grouped data, such as calculating sums, averages, counts, and more. It is akin to the GROUP BY clause in SQL.
                        <div className='codePalateBox mt-2 mb-2'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p>{</p>
                                    <p style="margin-left:30px">    <span class="color-pink">$group</span>: {</p>
                                    <p style="margin-left:60px">    <span class="color-pink">_id</span>: <span class="color-green">"$cust_id"</span>,</p>
                                    <p style="margin-left:60px">    <span class="color-pink">total</span>: { <span class="color-pink">$sum</span>: <span class="color-green">"$amount"</span> }</p>
                                    <p style="margin-left:30px">    }</p>
                                    <p>}</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li className='mb-4'>
                        <strong><Link className='noUnderline' to="/mongo-db/aggregation/project" onClick={() => path.setPathName('/mongo-db/aggregation/project')}>$project</Link></strong> is used to shape the documents in a pipeline by including, excluding, or adding new fields. This stage is akin to the SELECT clause in SQL, allowing you to specify exactly which fields you want in your output documents and how they should be transformed.
                        <div className='codePalateBox mt-2 mb-2'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p>{</p>
                                    <p style="margin-left:30px">    <span class="color-pink">$project</span>: {</p>
                                    <p style="margin-left:60px">    <span class="color-pink">item</span>: <span class="color-pink">1</span>,</p>
                                    <p style="margin-left:60px">    <span class="color-pink">totalAmount</span>: { <span class="color-pink">$multiply</span>: [<span class="color-green">"$price"</span>, <span class="color-green">"$quantity"</span>] }</p>
                                    <p style="margin-left:30px">    }</p>
                                    <p>}</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li className='mb-4'>
                        <strong><Link className='noUnderline' to="/mongo-db/aggregation/sort" onClick={() => path.setPathName('/mongo-db/aggregation/sort')}>$sort</Link></strong> is used to sort the documents in the aggregation pipeline. It allows you to order the documents based on the values of specified fields. The sorting can be done in ascending or descending order.
                        <div className='codePalateBox mt-2 mb-2'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p>{ <span class="color-pink">$sort</span>: { <span class="color-pink">totalAmount</span>: <span class="color-pink">-1</span> } }</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li className='mb-4'>
                        <strong><Link className='noUnderline' to="/mongo-db/aggregation/limit" onClick={() => path.setPathName('/mongo-db/aggregation/limit')}>$limit</Link></strong> is used to restrict the number of documents that pass through the pipeline. This stage is similar to the LIMIT clause in SQL and is useful when you want to limit the result set to a specified number of documents.
                        <div className='codePalateBox mt-2 mb-2'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p>{ <span class="color-pink">$limit</span>: <span class="color-pink">5</span> }</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li className='mb-4'>
                        <strong><Link className='noUnderline' to="/mongo-db/aggregation/lookup" onClick={() => path.setPathName('/mongo-db/aggregation/lookup')}>$lookup</Link></strong> is used to perform a left outer join to another collection in the same database. It allows you to combine documents from different collections based on a specified field, similar to SQL joins. This stage is particularly useful for aggregating and merging related data from multiple collections.
                        <div className='codePalateBox mt-2 mb-2'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p>{</p>
                                    <p style="margin-left:30px">    <span class="color-pink">$lookup</span>: {</p>
                                    <p style="margin-left:60px">    <span class="color-pink">from</span>: <span class="color-green">"orders"</span>,</p>
                                    <p style="margin-left:60px">    <span class="color-pink">localField</span>: <span class="color-green">"cust_id"</span>,</p>
                                    <p style="margin-left:60px">    <span class="color-pink">foreignField</span>: <span class="color-green">"cust_id"</span>,</p>
                                    <p style="margin-left:60px">    <span class="color-pink">as</span>: <span class="color-green">"orders"</span></p>
                                    <p style="margin-left:30px">    }</p>
                                    <p>}</p>
                                `
                            }}></div>
                        </div>
                    </li>
                </ul>
                <h5 className='mt-5 mb-4'>Example</h5>
                <p>
                    Here's an example of an aggregation pipeline that calculates the total sales for each product and sorts them in descending order:
                </p>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>db.sales.<span class="color-red">aggregate</span>([</p>
                            <p style="margin-left:30px">    {</p>
                            <p style="margin-left:60px">        <span class="color-pink">$group</span>: {</p>
                            <p style="margin-left:90px">            <span class="color-pink">_id</span>: <span class="color-green">"$product"</span>,</p>
                            <p style="margin-left:90px">            <span class="color-pink">totalSales</span>: { <span class="color-pink">$sum</span>: <span class="color-green">"$amount"</span> }</p>
                            <p style="margin-left:60px">        }</p>
                            <p style="margin-left:30px">    },</p>
                            <p style="margin-left:30px">    {</p>
                            <p style="margin-left:60px">        <span class="color-pink">$sort</span>: { <span class="color-pink">totalSales</span>: <span class="color-pink">-1</span> }</p>
                            <p style="margin-left:30px">    },</p>
                            <p style="margin-left:30px">    {</p>
                            <p style="margin-left:60px">        <span class="color-pink">$project</span>: {</p>
                            <p style="margin-left:90px">            <span class="color-pink">product</span>: <span class="color-green">"$_id"</span>,</p>
                            <p style="margin-left:90px">            <span class="color-pink">totalSales</span>: <span class="color-pink">1</span>,</p>
                            <p style="margin-left:90px">            <span class="color-pink">_id</span>: <span class="color-pink">0</span></p>
                            <p style="margin-left:60px">        }</p>
                            <p style="margin-left:30px">    }</p>
                            <p>]);</p>
                        `
                    }}></div>
                </div>
            </div>
        </section>
    )
}