import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/mysql/foreign-key";

export default function MysqlForeignKey() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Mysql - Foreign Key | Aspirant's Home");
        const urls = {
            'previous': '/mysql/primary-key',
            'next': '/mysql/composite-key'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>Mysql - Foreign Key</h3>
            <div className='mt-4 mb-5'>
                <p>
                    A foreign key in MySQL is a column or a group of columns in a table that points to the primary key or a unique key of another table. It establishes a relationship between two tables, known as a parent-child relationship, where the child table (or referencing table) contains the foreign key that references the parent table (or referenced table).
                </p>
            </div>
            <div className='mt-5 mb-5'>
                <h5>Creating MySQL Foreign Key</h5>
                <div className='mt-2 mb-2'>
                    <p>
                        We can create a Foreign Key on a MySQL table using the <strong>`CONSTRAINT... FOREIGN KEY... REFERENCES`</strong> keyword in the <strong>`CREATE TABLE`</strong> statement.
                    </p>
                    <p>
                        Following is the syntax to add Foreign Key constraints on a column of a table −
                    </p>
                    <div className='codePalateBox mt-2 mb-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                                <p><span class="color-blue">CREATE TABLE</span> table2(</p>
                                <p style="margin-left:30px">    column1 datatype,</p>
                                <p style="margin-left:30px">    column2 datatype,</p>
                                <p style="margin-left:30px">    ...</p>
                                <p style="margin-left:30px">    <span class="color-blue">CONSTRAINT</span> constraint_name </p>
                                <p style="margin-left:30px">    <span class="color-blue">FOREIGN KEY</span> (column2) </p>
                                <p style="margin-left:30px">    <span class="color-blue">REFERENCES</span> table1(column1)</p>
                                <p>);</p>
                            `
                        }}></div>
                    </div>
                </div>
            </div>
            <div className='mt-5 mb-5'>
                <h5>Creating Foreign Key on Existing Column</h5>
                <div className='mt-2 mb-2'>
                    <p>
                        We can also create a Foreign Key constraint on a column of an existing table using the <strong>`ALTER TABLE... ADD CONSTRAINT`</strong> statement.
                    </p>
                    <p>
                        Following is the syntax to add foreign key constraint on an existing table −
                    </p>
                    <div className='codePalateBox mt-2 mb-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                                <p><span class="color-blue">ALTER TABLE</span> table_name2 </p>
                                <p><span class="color-blue">ADD CONSTRAINT</span> constraint_name </p>
                                <p><span class="color-blue">FOREIGN KEY</span>(column_name2) </p>
                                <p><span class="color-blue">REFERENCES</span> table_name1(column_name1);</p>
                            `
                        }}></div>
                    </div>
                </div>
            </div>
            <div className='mt-5 mb-5'>
                <h5>Dropping MySQL Foreign Key</h5>
                <div className='mt-2 mb-2'>
                    <p>
                        We can also drop the foreign key, created on a MySQL table, whenever it is no longer needed in that table. We can do this using the <strong>`ALTER TABLE... DROP CONSTRAINT`</strong> statement in MySQL.
                    </p>
                    <p>
                        Following is the syntax to drop the foreign key from a table −
                    </p>
                    <div className='codePalateBox mt-2 mb-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                                <p><span class="color-blue">ALTER TABLE</span> table_name <span class="color-blue">DROP CONSTRAINT</span> constraint_name;</p>
                            `
                        }}></div>
                    </div>
                </div>
            </div>
        </section>
    )
}