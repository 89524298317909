import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/node-js/mysql-create-database";

export default function MysqlCreateDatabase() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Node Js - Mysql - Create Database | Aspirant's Home");
        const urls = {
            'previous': '/node-js/mysql',
            'next': '/node-js/mysql-create-table'
        }
        path.setPreviousNext(urls);
    }, [])
    
    return (
        <section className='mt-5 mb-5'>
            <h3 className='mb-4'>Node js Mysql Create Database</h3>
            <p>To create a database in MySQL using Node.js, you can execute a <strong>`CREATE DATABASE`</strong> query using the <strong>`mysql`</strong> package. Here's an example:</p>
            <p>Create a database named "mydb":</p>
            <div className='codePalateBox mt-2 mb-2'>
                <div className='codePalate' dangerouslySetInnerHTML={{ __html: "<p><span class='color-blue'>var</span> mysql = <span class='color-yellow'>require</span>(<span class='color-green'>'mysql'</span>);</p><p><span class='color-blue'>var</span> con = mysql.<span class='color-red'>createConnection</span>({</p><p style='margin-left:30px'>  <span class='color-pink'>host</span>: <span class='color-green'>'localhost'</span>,</p><p style='margin-left:30px'>  <span class='color-pink'>user</span>: <span class='color-green'>'yourusername'</span>,</p><p style='margin-left:30px'>  <span class='color-pink'>password</span>: <span class='color-green'>'yourpassword'</span></p><p>});</p><p>con.<span class='color-red'>connect</span>(function(err) {</p><p style='margin-left:30px'>  <span class='color-blue'>if</span> (err) throw err;</p><p style='margin-left:30px'>  <span class='color-pink'>console</span>.<span class='color-red'>log</span>(<span class='color-green'>'Connected!'</span>);</p><p style='margin-left:30px'>  con.<span class='color-red'>query</span>(<span class='color-green'>'CREATE DATABASE mydb'</span>, function (err, result) {</p><p style='margin-left:60px'>    <span class='color-blue'>if</span> (err) throw err;</p><p style='margin-left:60px'>    <span class='color-pink'>console</span>.<span class='color-red'>log</span>(<span class='color-green'>'Database created'</span>);</p><p style='margin-left:30px'> });</p>});" }}></div>
            </div>
        </section>
    )
}
