import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../../Context";
import { Link } from 'react-router-dom';
export const pageUrl = () => "/mysql/date-and-time-functions/datediff";

export default function DateDiff() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Mysql - Date and Time functions - DATEDIFF() | Aspirant's Home");
        path.setPreviousNext({});
    }, [])

    return (
        <>
            <section class='clearfix mb-3'>
                <span className='float-start'>
                    <Link to='/mysql/date-and-time-functions' onClick={() => path.setPathName('/mysql/date-and-time-functions')} className='btn btn-primary'> <i class="fa-solid fa-less-than"></i>&nbsp; Back to Date and Time functions </Link>
                </span>
            </section>
            <section className='mt-5 mb-5'>
                <h3>Mysql - DATEDIFF() Functions</h3>
                <div className='mt-4 mb-5'>
                    <p>
                        The <strong>`DATEDIFF()`</strong> function in MySQL is used to calculate the difference between two dates. It takes two date values as arguments and returns the difference in days. The syntax is as follows:
                    </p>
                    <div className='codePalateBox mt-2 mb-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                                <p>DATEDIFF(end_date, start_date)</p>
                            `
                        }}></div>
                    </div>
                    <p>
                        Here, <strong>`end_date`</strong> and <strong>`start_date`</strong> are the two date values between which you want to calculate the difference. The result is an integer representing the number of days between the two dates.
                    </p>
                    <p>
                        Here's a simple example:
                    </p>
                    <div className='codePalateBox mt-2 mb-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                                <p><span class="color-blue">SELECT</span> DATEDIFF(<span class="color-green">'2022-05-16'</span>, <span class="color-green">'2022-05-10'</span>);</p>
                            `
                        }}></div>
                    </div>
                    <p>
                        This will return <strong>`6`</strong>, indicating that there are 6 days between the dates <strong>`'2022-05-16'`</strong> and <strong>`'2022-05-10'`</strong>.
                    </p>
                </div>
                <div className='mt-5 mb-5'>
                    <h5>DATEDIFF() with MySQL Table</h5>
                    <div className='mt-4 mb-2'>
                        <p>
                            To use the <strong>`DATEDIFF()`</strong> function in MySQL with a table, let's consider a table <strong>`tasks`</strong> with columns <strong>`id`</strong>, <strong>`task_name`</strong>, <strong>`start_date`</strong>, and <strong>`end_date`</strong>, where <strong>`start_date`</strong> and <strong>`end_date`</strong> are dates representing the start and end dates of each task. We want to calculate the difference in days between <strong>`end_date`</strong> and <strong>`start_date`</strong> for each task. Here's an example:
                        </p>
                        <p>
                            Consider the <strong>`tasks`</strong> table:
                        </p>
                        <table className='table' style={{border: '1px dashed #ccc'}}> 
                            <thead>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>id</th>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>task_name</th>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>start_date</th>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>end_date</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>1</td>
                                    <td style={{border: '1px dashed #ccc'}}>Task A</td>
                                    <td style={{border: '1px dashed #ccc'}}>2022-05-10</td>
                                    <td style={{border: '1px dashed #ccc'}}>2022-05-15</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>2</td>
                                    <td style={{border: '1px dashed #ccc'}}>Task B</td>
                                    <td style={{border: '1px dashed #ccc'}}>2022-05-12</td>
                                    <td style={{border: '1px dashed #ccc'}}>2022-05-18</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>3</td>
                                    <td style={{border: '1px dashed #ccc'}}>Task C</td>
                                    <td style={{border: '1px dashed #ccc'}}>2022-05-15</td>
                                    <td style={{border: '1px dashed #ccc'}}>2022-05-20</td>
                                </tr>
                            </tbody>
                        </table>
                        <p>
                            To calculate the difference in days between <strong>`end_date`</strong> and <strong>`start_date`</strong> for each task, you would use:
                        </p>
                        <div className='codePalateBox mt-2 mb-2'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-blue">SELECT</span> id, task_name, start_date, end_date, DATEDIFF(end_date, start_date) <span class="color-blue">AS</span> days_taken <span class="color-blue">FROM</span> tasks;</p>
                                `
                            }}></div>
                        </div>
                        <p>
                            This query will return:
                        </p>
                        <table className='table' style={{border: '1px dashed #ccc'}}> 
                            <thead>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>id</th>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>task_name</th>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>start_date</th>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>end_date</th>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>days_taken</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>1</td>
                                    <td style={{border: '1px dashed #ccc'}}>Task A</td>
                                    <td style={{border: '1px dashed #ccc'}}>2022-05-10</td>
                                    <td style={{border: '1px dashed #ccc'}}>2022-05-15</td>
                                    <td style={{border: '1px dashed #ccc'}}>5</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>2</td>
                                    <td style={{border: '1px dashed #ccc'}}>Task B</td>
                                    <td style={{border: '1px dashed #ccc'}}>2022-05-12</td>
                                    <td style={{border: '1px dashed #ccc'}}>2022-05-18</td>
                                    <td style={{border: '1px dashed #ccc'}}>6</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>3</td>
                                    <td style={{border: '1px dashed #ccc'}}>Task C</td>
                                    <td style={{border: '1px dashed #ccc'}}>2022-05-15</td>
                                    <td style={{border: '1px dashed #ccc'}}>2022-05-20</td>
                                    <td style={{border: '1px dashed #ccc'}}>5</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </section>
        </>
    )
}