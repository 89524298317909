import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/css/background";

export default function CSSBackgroundClip() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Background in CSS | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/css/background-clip',
            'next': '/css/border'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>Background - Shorthand property</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <strong>`background`</strong> shorthand property in CSS allows you to set multiple background-related properties in a single declaration. This can help you write more concise and maintainable code by combining several background properties into one line. It has a basic syntax -
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>selector {</p>
                            <p class="ml-30">    <span class="color-green">background</span>: [background-color] [background-image] [background-repeat] [background-attachment] [background-position] / [background-size] [background-origin] [background-clip];</p>
                            <p>}</p>
                        `
                    }}></div>
                </div>
                <p>
                    Ok, let's understand this by a simple example. If you want to set a background of an element using below property - 
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>div {</p>
                            <p class="ml-30">    <span class="color-green">background-color</span>: <span class="color-pink">#ccc</span>;</p>
                            <p class="ml-30">    <span class="color-green">background-image</span>: <span class="color-yellow">url</span>(<span class="color-green">"img_random.png"</span>);</p>
                            <p class="ml-30">    <span class="color-green">background-repeat</span>: no-repeat;</p>
                            <p class="ml-30">    <span class="color-green">background-position</span>: right top;</p>
                            <p>}</p>
                        `
                    }}></div>
                </div>
                <p>
                    You can simply use <strong>`background`</strong> shorthand property instead of using above multiple line of properties. You can see in the below example.
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>div {</p>
                        <p class="ml-30">    <span class="color-green">background</span>: <span class="color-pink">#ffffff</span> <span class="color-yellow">url</span>(<span class="color-green">"img_random.png"</span>) no-repeat right top;</p>
                        <p>}</p>
                        `
                    }}></div>
                </div>

                <p>
                    You can write all the background properties together, only you have to maintain the order given below. It does not matter if one of the property values is missing, as long as the other ones are in this order.
                </p>

                <ul style={{ 'listStyle': 'disc' }}>
                    <li>background-color</li>
                    <li>background-image</li>
                    <li>background-repeat</li>
                    <li>background-attachment</li>
                    <li>background-position / background-size <br /><span class="color-red">/* Note that this property must be separated from background-position by a slash (/). */</span></li> 
                    <li>background-origin</li>
                    <li>background-clip</li>
                </ul>

                <h5 className="mt-5 mb-3">All CSS Background Properties</h5>
                <table className='table table-striped'>
                    <thead>
                        <th>Name</th>
                        <th>Description</th>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <Link to="/css/background-color" className='noUnderline'>background-color</Link>
                            </td>
                            <td>
                                Sets the background color of the element.
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <Link to="/css/background-image" className='noUnderline'>background-image</Link>
                            </td>
                            <td>
                                Sets the background image of the element.
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <Link to="/css/background-repeat" className='noUnderline'>background-repeat</Link>
                            </td>
                            <td>
                                Defines how the background image is repeated.
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <Link to="/css/background-attachment" className='noUnderline'>background-attachment</Link>
                            </td>
                            <td>
                                Sets whether the background image is fixed or scrolls with the rest of the page.
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <Link to="/css/background-position" className='noUnderline'>background-position</Link>
                            </td>
                            <td>
                                Sets the starting position of the background image.
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <Link to="/css/background-size" className='noUnderline'>background-size</Link>
                            </td>
                            <td>
                                Specifies the size of the background image.
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <Link to="/css/background-origin" className='noUnderline'>background-origin</Link>
                            </td>
                            <td>
                                Specifies the background positioning area.
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <Link to="/css/background-clip" className='noUnderline'>background-clip</Link>
                            </td>
                            <td>
                                Specifies the painting area of the background.
                            </td>
                        </tr>
                    </tbody>
                </table>

            </div>
        </section>
    )
}