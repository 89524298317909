import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/css/background-repeat";

export default function CSSBackgroundRepeat() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Background Repeat in CSS | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/css/background-image',
            'next': '/css/background-attachment'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>Background Repeat</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <strong>`background-repeat`</strong> property in CSS controls how a background image is repeated (or not repeated) within an element. This property is essential for defining the appearance of background images when they do not cover the entire element. It has a basic syntax -
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>selector {</p>
                            <p class="ml-30">    <span class="color-green">background-repeat</span>: value;</p>
                            <p>}</p>
                        `
                    }}></div>
                </div>

                <h5 className="mt-5 mb-3">Background Image With No Properties</h5>
                <div className='outputPalateBox mb-4 mt-3' style={{'border':'none'}}>
                    <div className='outputPalate' dangerouslySetInnerHTML={{
                        __html: `
                            <style>
                                .colorNameDiv{
                                    background-image : url('../../assets/small-2-background.png');
                                    height : 200px;
                                    align-content: center;
                                    text-align: center;
                                    border : 1px solid red;
                                    color : #000;
                                    background-repeat : no-repeat;
                                }
                            </style>
                            <div class="colorNameDiv fontStyle">Original Image</div>
                        `
                    }}></div>
                </div>

                <h5 className="mt-5 mb-3">Commonly Used Values and Their Uses</h5>
                <p>
                    There are many <strong>`background-repeat`</strong> property values. They are -
                </p>
                <ul style={{ 'listStyle': 'decimal' }}>
                    <li>
                        <strong>`repeat`</strong>
                        <p>The background image is repeated both horizontally and vertically. This is the default value.</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p>div {</p>
                                    <p class="ml-30">   <span class="color-green">background-image</span>: <span class="color-yellow">url</span>(<span class="color-green">'pattern.png'</span>);</p>
                                    <p class="ml-30">    <span class="color-green">background-repeat</span>: repeat;</p>
                                    <p>}</p>
                                `
                            }}></div>
                        </div>
                        <div className='outputPalateBox mb-4 mt-3' style={{'border':'none'}}>
                            <div className='outputPalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <style>
                                        .colorNameDiv1{
                                            background-image : url('../../assets/small-2-background.png');
                                            height : 200px;
                                            align-content: center;
                                            text-align: center;
                                            background-repeat : repeat;
                                            border : 1px solid red;
                                            color : #000;
                                        }
                                    </style>
                                    <div class="colorNameDiv1 fontStyle">Background Repeat : repeat;</div>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <strong>`repeat-x`</strong>
                        <p>The background image is repeated only horizontally.</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p>div {</p>
                                    <p class="ml-30">   <span class="color-green">background-image</span>: <span class="color-yellow">url</span>(<span class="color-green">'pattern.png'</span>);</p>
                                    <p class="ml-30">    <span class="color-green">background-repeat</span>: repeat-x;</p>
                                    <p>}</p>
                                `
                            }}></div>
                        </div>
                        <div className='outputPalateBox mb-4 mt-3' style={{'border':'none'}}>
                            <div className='outputPalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <style>
                                        .colorNameDiv2{
                                            background-image : url('../../assets/small-2-background.png');
                                            height : 200px;
                                            align-content: center;
                                            text-align: center;
                                            background-repeat : repeat-x;
                                            border : 1px solid red;
                                            color : #000;
                                        }
                                    </style>
                                    <div class="colorNameDiv2 fontStyle">Background Repeat : repeat-x;</div>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <strong>`repeat-y`</strong>
                        <p>The background image is repeated only vertically.</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p>div {</p>
                                    <p class="ml-30">   <span class="color-green">background-image</span>: <span class="color-yellow">url</span>(<span class="color-green">'pattern.png'</span>);</p>
                                    <p class="ml-30">    <span class="color-green">background-repeat</span>: repeat-y;</p>
                                    <p>}</p>
                                `
                            }}></div>
                        </div>
                        <div className='outputPalateBox mb-4 mt-3' style={{'border':'none'}}>
                            <div className='outputPalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <style>
                                        .colorNameDiv3{
                                            background-image : url('../../assets/small-2-background.png');
                                            height : 200px;
                                            align-content: center;
                                            text-align: center;
                                            background-repeat : repeat-y;
                                            border : 1px solid red;
                                            color : #000;
                                        }
                                    </style>
                                    <div class="colorNameDiv3 fontStyle">Background Repeat : repeat-y;</div>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <strong>`no-repeat`</strong>
                        <p>The background image is not repeated.</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p>div {</p>
                                    <p class="ml-30">   <span class="color-green">background-image</span>: <span class="color-yellow">url</span>(<span class="color-green">'pattern.png'</span>);</p>
                                    <p class="ml-30">    <span class="color-green">background-repeat</span>: no-repeat;</p>
                                    <p>}</p>
                                `
                            }}></div>
                        </div>
                        <div className='outputPalateBox mb-4 mt-3' style={{'border':'none'}}>
                            <div className='outputPalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <style>
                                        .colorNameDiv4{
                                            background-image : url('../../assets/small-2-background.png');
                                            height : 200px;
                                            align-content: center;
                                            text-align: center;
                                            background-repeat : no-repeat;
                                            border : 1px solid red;
                                            color : #000;
                                        }
                                    </style>
                                    <div class="colorNameDiv4 fontStyle">Background Repeat : no-repeat;</div>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <strong>`space`</strong>
                        <p>The background image is repeated as much as possible without clipping. The remaining space is distributed evenly between the images.</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p>div {</p>
                                    <p class="ml-30">   <span class="color-green">background-image</span>: <span class="color-yellow">url</span>(<span class="color-green">'pattern.png'</span>);</p>
                                    <p class="ml-30">    <span class="color-green">background-repeat</span>: space;</p>
                                    <p>}</p>
                                `
                            }}></div>
                        </div>
                        <div className='outputPalateBox mb-4 mt-3' style={{'border':'none'}}>
                            <div className='outputPalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <style>
                                        .colorNameDiv5{
                                            background-image : url('../../assets/small-2-background.png');
                                            height : 200px;
                                            align-content: center;
                                            text-align: center;
                                            background-repeat : space;
                                            border : 1px solid red;
                                            color : #000;
                                        }
                                    </style>
                                    <div class="colorNameDiv5 fontStyle">Background Repeat : space;</div>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <strong>`round`</strong>
                        <p>The background image is repeated as much as possible without clipping, but if it does not fit perfectly, it is scaled to fit the remaining space.</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p>div {</p>
                                    <p class="ml-30">   <span class="color-green">background-image</span>: <span class="color-yellow">url</span>(<span class="color-green">'pattern.png'</span>);</p>
                                    <p class="ml-30">    <span class="color-green">background-repeat</span>: round;</p>
                                    <p>}</p>
                                `
                            }}></div>
                        </div>
                        <div className='outputPalateBox mb-4 mt-3' style={{'border':'none'}}>
                            <div className='outputPalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <style>
                                        .colorNameDiv6{
                                            background-image : url('../../assets/small-2-background.png');
                                            height : 200px;
                                            align-content: center;
                                            text-align: center;
                                            background-repeat : round;
                                            border : 1px solid red;
                                            color : #000;
                                        }
                                    </style>
                                    <div class="colorNameDiv6 fontStyle">Background Repeat : round;</div>
                                `
                            }}></div>
                        </div>
                    </li>
                </ul>
            </div>
        </section>
    )
}