import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/mysql/show-tables";

export default function MysqlShowTable() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Mysql - Show Table | Aspirant's Home");
        const urls = {
            'previous': '/mysql/create-tables',
            'next': '/mysql/alter-tables'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>Mysql - Show Table</h3>
            <div className='mt-4 mb-5'>
                <p>
                    To show a list of tables in a MySQL database, you can use the <strong>`SHOW TABLES`</strong> statement. This statement returns a list of tables in the current database or in a specific database if one is selected. Here's the basic syntax:
                </p>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-blue">SHOW</span> TABLES;</p>
                        `
                    }}></div>
                </div>
                <p>
                    If you want to show tables from a specific database, you can first select that database using the <strong>`USE`</strong> statement, and then use <strong>`SHOW TABLES;`</strong>. For example, to show tables from a database named <strong>`mydatabase`</strong>:
                </p>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-blue">USE</span> mydatabase;</p>
                            <p><span class="color-blue">SHOW</span> TABLES;</p>
                        `
                    }}></div>
                </div>
            </div>
            <div className='mt-4 mb-5'>
                <h5>FULL Modifier</h5>
                <p>
                    In MySQL, we can include the optional <strong>`FULL modifier`</strong> with the <strong>`SHOW TABLES`</strong> command to show a second output column. This column provides extra details about the tables in a database, including their types. For instance, a table might be identified as a <strong>`BASE TABLE`</strong>, a view as <strong>`VIEW`</strong>, or an <strong>`INFORMATION_SCHEMA`</strong> table as <strong>`SYSTEM VIEW`</strong>.
                </p>
                <p>
                    Here is an syntax:
                </p>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-blue">SHOW FULL</span> TABLES;</p>
                        `
                    }}></div>
                </div>
            </div>
        </section>
    )
}