import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../../Context";
import { Link } from 'react-router-dom';
export const pageUrl = () => "/mysql/numeric-functions/pow";

export default function POW() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Mysql - Numeric Functions - POW(x, y) | Aspirant's Home");
        path.setPreviousNext({});
    }, [])

    return (
        <>
            <section class='clearfix mb-3'>
                <span className='float-start'>
                    <Link to='/mysql/numeric-functions' onClick={() => path.setPathName('/mysql/numeric-functions')} className='btn btn-primary'> <i class="fa-solid fa-less-than"></i>&nbsp; Back to Numeric Functions </Link>
                </span>
            </section>
            <section className='mt-5 mb-5'>
                <h3>Mysql - POW(x, y) Function</h3>
                <div className='mt-4 mb-5'>
                    <p>
                        The <strong>`POW()`</strong> function in MySQL returns the value of one number raised to the power of another. It is used to perform exponentiation.
                    </p>
                    <p>
                        Here is a basic example using the POW function in a SELECT query:
                    </p>
                    <div className='codePalateBox mt-2 mb-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                                <p><span class="color-blue">SELECT</span> POW(<span class="color-pink">2</span>, <span class="color-pink">3</span>);</p>
                            `
                        }}></div>
                    </div>
                    <p>
                        This will return <strong>`8`</strong>, because 2 raised to the power of 3 is 8.
                    </p>
                </div>
                <div className='mt-5 mb-5'>
                    <h5>POW(x, y) with MySQL Table</h5>
                    <div className='mt-3 mb-2'>
                        <p>
                            Imagine you have a table <strong>`investments`</strong> that stores information about investments, where you need to calculate the future value of an investment given its principal amount, annual interest rate, and the number of years the money is invested.
                        </p>
                        <table className='table' style={{border: '1px dashed #ccc'}}> 
                            <thead>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>id</th>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>principal</th>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>annual_interest_rate</th>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>years</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>1</td>
                                    <td style={{border: '1px dashed #ccc'}}>1000</td>
                                    <td style={{border: '1px dashed #ccc'}}>0.05</td>
                                    <td style={{border: '1px dashed #ccc'}}>3</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>2</td>
                                    <td style={{border: '1px dashed #ccc'}}>1500</td>
                                    <td style={{border: '1px dashed #ccc'}}>0.04</td>
                                    <td style={{border: '1px dashed #ccc'}}>5</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>3</td>
                                    <td style={{border: '1px dashed #ccc'}}>2000</td>
                                    <td style={{border: '1px dashed #ccc'}}>0.03</td>
                                    <td style={{border: '1px dashed #ccc'}}>10</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>4</td>
                                    <td style={{border: '1px dashed #ccc'}}>2500</td>
                                    <td style={{border: '1px dashed #ccc'}}>0.06</td>
                                    <td style={{border: '1px dashed #ccc'}}>2</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>5</td>
                                    <td style={{border: '1px dashed #ccc'}}>3000</td>
                                    <td style={{border: '1px dashed #ccc'}}>0.07</td>
                                    <td style={{border: '1px dashed #ccc'}}>1</td>
                                </tr>
                            </tbody>
                        </table>
                        <p>
                            To calculate the future value of an investment, you can use the formula:
                        </p>
                        <p>
                            Using the <strong>`POW(x, y)`</strong> function, this can be written as:
                        </p>
                        <p className='fw-bold'>
                            Future Value = Principal X POW ( 1 + Annual Interest Rate , Years )
                        </p>
                        <div className='codePalateBox mt-2 mb-2'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-blue">SELECT</span> id, principal, annual_interest_rate, years, principal * POW(<span class="color-pink">1</span> + annual_interest_rate, years) <span class="color-blue">AS</span> future_value <span class="color-blue">FROM</span> investments;</p>
                                `
                            }}></div>
                        </div>
                        <p>
                            This query will return:
                        </p>
                        <table className='table' style={{border: '1px dashed #ccc'}}> 
                            <thead>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>id</th>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>principal</th>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>annual_interest_rate</th>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>years</th>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>future_value</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>1</td>
                                    <td style={{border: '1px dashed #ccc'}}>1000</td>
                                    <td style={{border: '1px dashed #ccc'}}>0.05</td>
                                    <td style={{border: '1px dashed #ccc'}}>3</td>
                                    <td style={{border: '1px dashed #ccc'}}>1157.625</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>2</td>
                                    <td style={{border: '1px dashed #ccc'}}>1500</td>
                                    <td style={{border: '1px dashed #ccc'}}>0.04</td>
                                    <td style={{border: '1px dashed #ccc'}}>5</td>
                                    <td style={{border: '1px dashed #ccc'}}>1824.768</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>3</td>
                                    <td style={{border: '1px dashed #ccc'}}>2000</td>
                                    <td style={{border: '1px dashed #ccc'}}>0.03</td>
                                    <td style={{border: '1px dashed #ccc'}}>10</td>
                                    <td style={{border: '1px dashed #ccc'}}>2685.03</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>4</td>
                                    <td style={{border: '1px dashed #ccc'}}>2500</td>
                                    <td style={{border: '1px dashed #ccc'}}>0.06</td>
                                    <td style={{border: '1px dashed #ccc'}}>2</td>
                                    <td style={{border: '1px dashed #ccc'}}>2809</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>5</td>
                                    <td style={{border: '1px dashed #ccc'}}>3000</td>
                                    <td style={{border: '1px dashed #ccc'}}>0.07</td>
                                    <td style={{border: '1px dashed #ccc'}}>1</td>
                                    <td style={{border: '1px dashed #ccc'}}>3210</td>
                                </tr>
                            </tbody>
                        </table>
                        <p>
                            In this query, the <strong>`POW`</strong> function helps in computing the future value of the investments based on the given interest rate and number of years.
                        </p>
                    </div>
                </div>
            </section>
        </>
    )
}