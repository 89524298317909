import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/mongo-db/drop-collection";

export default function MongoDropCollection() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Drop Collection in MongoDB | Step-by-Step");
        title.setKeyWords("drop collection in mongodb, how to drop collection in mongodb, create and drop collection in mongodb, mongodb drop all documents in collection, drop all collection in mongodb, drop a collection in mongodb, mongodb drop collection example, how to drop a collection in mongodb, mongodb drop collection if exists, db drop collection mongodb, drop collection in mongodb command, mongodb drop collection, mongodb drop all collections, remove collection data mongodb");
        title.setPageDescription("Dropping a collection in MongoDB can be done using the `drop()` method. This method removes the specified collection from the database.");
        const urls = {
            'previous': '/mongo-db/create-collection',
            'next': '/mongo-db/data-types'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>MongoDB - Drop Collection</h3>
            <div className='mt-4 mb-5'>
                <p>
                    Dropping a collection in MongoDB can be done using the <strong>`drop()`</strong> method. This method removes the specified collection from the database.
                </p>
                <p>
                    Here is the syntax -
                </p>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>db.collectionName.<span class="color-red">drop</span>()</p>
                        `
                    }}></div>
                </div>
                <p>
                    To check the available collections in your database use <strong>`show collections`</strong> command:
                </p>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>&gt; show collections</p>
                            <p>mycollection</p>
                            <p>mysecondcollection</p>
                        `
                    }}></div>
                </div>
                <p>
                    Now if you want to drop the collection named <strong>`mycollection`</strong>, you can simply write 
                </p>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>>db.mycollection.<span class="color-red">drop</span>()</p>
                            <p><span class="color-blue">true</span></p>
                        `
                    }}></div>
                </div>
                <p>
                    To check the available collections after dropping an collection in your database use <strong>`show collections`</strong> command:
                </p>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>&gt; show collections</p>
                            <p>mysecondcollection</p>
                        `
                    }}></div>
                </div>
            </div>
        </section>
    )
}