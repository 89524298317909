import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../../Context";
import { Link } from 'react-router-dom';
export const pageUrl = () => "/mysql/date-and-time-functions/date-format";

export default function DateFormat() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Mysql - Date and Time functions - DATE_FORMAT() | Aspirant's Home");
        path.setPreviousNext({});
    }, [])

    return (
        <>
            <section class='clearfix mb-3'>
                <span className='float-start'>
                    <Link to='/mysql/date-and-time-functions' onClick={() => path.setPathName('/mysql/date-and-time-functions')} className='btn btn-primary'> <i class="fa-solid fa-less-than"></i>&nbsp; Back to Date and Time functions </Link>
                </span>
            </section>
            <section className='mt-5 mb-5'>
                <h3>Mysql - DATE_FORMAT() Functions</h3>
                <div className='mt-4 mb-5'>
                    <p>
                        The <strong>`DATE_FORMAT()`</strong> function in MySQL is used to format a date or datetime value according to a specified format. It takes two arguments: the date or datetime value you want to format, and the format string specifying how you want the date or datetime value to be formatted.
                    </p>
                    <p>
                        Here's the basic syntax of the <strong>`DATE_FORMAT()`</strong> function:
                    </p>
                    <div className='codePalateBox mt-2 mb-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                                <p>DATE_FORMAT(date_value, format_string)</p>
                            `
                        }}></div>
                    </div>
                    <p>
                        Where, <strong>`date_value`</strong> is the date or datetime value you want to format. <strong>`format_string`</strong> is a string that specifies the format of the output. This string can contain various format specifiers that represent different parts of the date or datetime value (e.g., <strong>`%Y`</strong> for the year, <strong>`%m`</strong> for the month, <strong>`%d`</strong> for the day, etc.).
                    </p>
                    <p>
                        For example, if you have a datetime value <strong>`'2022-05-16 15:30:00'`</strong> and you want to format it as <strong>`'May 16, 2022'`</strong>, you can use <strong>`DATE_FORMAT()`</strong> like this:
                    </p>
                    <div className='codePalateBox mt-2 mb-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                                <p><span class="color-blue">SELECT</span> DATE_FORMAT(<span class="color-green">'2022-05-16 15:30:00'</span>, <span class="color-green">'%M %e, %Y'</span>);</p>
                            `
                        }}></div>
                    </div>
                    <p>
                        This will return <strong>`'May 16, 2022'`</strong>.
                    </p>
                </div>
                <div className='mt-5 mb-5'>
                    <h5>DATE_FORMAT() with MySQL Table</h5>
                    <div className='mt-4 mb-2'>
                        <p>
                            To use the <strong>`DATE_FORMAT()`</strong> function in MySQL with a table, let's consider a table <strong>`events`</strong> with columns <strong>`id`</strong>, <strong>`event_name`</strong>, and <strong>`start_time`</strong> where <strong>`start_time`</strong> represents the start time of each event as a datetime value. We want to format the <strong>`start_time`</strong> column into a more readable date format.
                        </p>
                        <p>
                            Consider the <strong>`events`</strong> table:
                        </p>
                        <table className='table' style={{border: '1px dashed #ccc'}}> 
                            <thead>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>id</th>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>event_name</th>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>start_time</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>1</td>
                                    <td style={{border: '1px dashed #ccc'}}>Event A</td>
                                    <td style={{border: '1px dashed #ccc'}}>2022-05-16 09:00:00</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>2</td>
                                    <td style={{border: '1px dashed #ccc'}}>Event B</td>
                                    <td style={{border: '1px dashed #ccc'}}>2022-05-17 10:30:00</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>3</td>
                                    <td style={{border: '1px dashed #ccc'}}>Event C</td>
                                    <td style={{border: '1px dashed #ccc'}}>2022-05-18 12:00:00</td>
                                </tr>
                            </tbody>
                        </table>
                        <p>
                            To format the <strong>`start_time`</strong> column into a more readable format like <strong>`'Month Day, Year'`</strong>, you would use:
                        </p>
                        <div className='codePalateBox mt-2 mb-2'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-blue">SELECT</span> id, event_name, DATE_FORMAT(start_time, '%M %e, %Y') <span class="color-blue">AS</span> formatted_start_time <span class="color-blue">FROM</span> events;</p>
                                `
                            }}></div>
                        </div>
                        <p>
                            This query will return:
                        </p>
                        <table className='table' style={{border: '1px dashed #ccc'}}> 
                            <thead>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>id</th>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>event_name</th>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>formatted_start_time</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>1</td>
                                    <td style={{border: '1px dashed #ccc'}}>Event A</td>
                                    <td style={{border: '1px dashed #ccc'}}>May 18, 2022</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>2</td>
                                    <td style={{border: '1px dashed #ccc'}}>Event B</td>
                                    <td style={{border: '1px dashed #ccc'}}>May 18, 2022</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>3</td>
                                    <td style={{border: '1px dashed #ccc'}}>Event C</td>
                                    <td style={{border: '1px dashed #ccc'}}>May 18, 2022</td>
                                </tr>
                            </tbody>
                        </table>
                        <p>
                            In this example, the <strong>`DATE_FORMAT()`</strong> function is used to format the <strong>`start_time`</strong> column into a more readable format. The formatted date is returned as <strong>`formatted_start_time`</strong> in the result set.
                        </p>
                    </div>
                </div>
            </section>
        </>
    )
}