import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/mysql/stored-procedure";

export default function MysqlStoredProcedure() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Mysql - Stored Procedure | Aspirant's Home");
        const urls = {
            'previous': '/mysql/cursor',
            'next': '/mysql/stored-functions'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>Mysql - Stored Procedure</h3>
            <div className='mt-4 mb-5'>
                <p>
                    A stored procedure in MySQL is a set of SQL statements that can be stored in the database and executed as a program. Stored procedures allow you to encapsulate and reuse logic on the server side, improving efficiency, security, and performance by reducing the need for repeated SQL code.
                </p>
            </div>
            <div className='mt-5 mb-5'>
                <h5>Key Features of Stored Procedures</h5>
                <div className='mt-3 mb-5'>
                    <ul style={{ listStyle: 'decimal' }}>
                        <li>
                            <strong>Encapsulation:</strong> You can encapsulate business logic in the database.
                        </li>
                        <li>
                            <strong>Reusability:</strong> Once created, stored procedures can be reused across multiple applications.
                        </li>
                        <li>
                            <strong>Performance:</strong> Stored procedures are compiled and cached in the database, leading to faster execution.
                        </li>
                        <li>
                            <strong>Security:</strong> By using stored procedures, you can restrict direct access to data and control the operations that users can perform.
                        </li>
                        <li>
                            <strong>Maintainability:</strong> Changes in logic can be made in one place without affecting multiple applications.
                        </li>
                    </ul>
                </div>
            </div>
            <div className='mt-5 mb-5'>
                <h5>Syntax for Creating a Stored Procedure</h5>
                <div className='mt-3 mb-5'>
                    <p>
                        Here is the basic syntax for creating a stored procedure in MySQL:
                    </p>
                    <div className='codePalateBox mt-2 mb-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                                <p>DELIMITER //</p>
                                <br />
                                <p><span class="color-blue">CREATE PROCEDURE</span> procedure_name ([<span class="color-blue">IN</span>|<span class="color-blue">OUT</span>|<span class="color-blue">INOUT</span>] parameter_name datatype, ...)</p>
                                <p><span class="color-blue">BEGIN</span></p>
                                <p style="margin-left:30px">   <span class="color-grey"> -- SQL statements</span></p>
                                <p><span class="color-blue">END</span> //</p>
                                <br />
                                <p>DELIMITER ;</p>
                            `
                        }}></div>
                    </div>
                    <h5 className='mt-4'>Explanation of Parameters</h5>
                    <ul>
                        <li>
                            <strong>IN:</strong> The parameter is passed into the procedure.
                        </li>
                        <li>
                            <strong>OUT:</strong> The procedure passes the parameter back out.
                        </li>
                        <li>
                            <strong>INOUT:</strong> The parameter is passed in and can be modified inside the procedure, with the modified value passed back out.
                        </li>
                    </ul>
                </div>
            </div>
            <div className='mt-5 mb-5'>
                <h5>Example of Creating and Using a Stored Procedure</h5>
                <div className='mt-3 mb-5'>
                    <p>
                        Suppose we have an table <strong>`employees`</strong> with the below data and structure.
                    </p>
                    <table className='table' style={{border: '1px dashed #ccc'}}> 
                        <thead>
                            <tr style={{border: '1px dashed #ccc'}}>
                                <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>id</th>
                                <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>name</th>
                                <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>department_id</th>
                                <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>salary</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr style={{border: '1px dashed #ccc'}}>
                                <td style={{border: '1px dashed #ccc'}}>1</td>
                                <td style={{border: '1px dashed #ccc'}}>Jane Smith</td>
                                <td style={{border: '1px dashed #ccc'}}>1</td>
                                <td style={{border: '1px dashed #ccc'}}>500000</td>
                            </tr>
                            <tr style={{border: '1px dashed #ccc'}}>
                                <td style={{border: '1px dashed #ccc'}}>2</td>
                                <td style={{border: '1px dashed #ccc'}}>John Doe</td>
                                <td style={{border: '1px dashed #ccc'}}>2</td>
                                <td style={{border: '1px dashed #ccc'}}>350000</td>
                            </tr>
                            <tr style={{border: '1px dashed #ccc'}}>
                                <td style={{border: '1px dashed #ccc'}}>3</td>
                                <td style={{border: '1px dashed #ccc'}}>Johny Smith</td>
                                <td style={{border: '1px dashed #ccc'}}>1</td>
                                <td style={{border: '1px dashed #ccc'}}>50000</td>
                            </tr>
                            <tr style={{border: '1px dashed #ccc'}}>
                                <td style={{border: '1px dashed #ccc'}}>4</td>
                                <td style={{border: '1px dashed #ccc'}}>Jeffer Joe</td>
                                <td style={{border: '1px dashed #ccc'}}>3</td>
                                <td style={{border: '1px dashed #ccc'}}>380000</td>
                            </tr>
                        </tbody>
                    </table>
                    <p>
                        Let's create a stored procedure that retrieves employee details from a table called <strong>`employees`</strong> based on the department ID.
                    </p>
                    <div className='codePalateBox mt-2 mb-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                                <p>DELIMITER //</p>
                                <br />
                                <p><span class="color-blue">CREATE PROCEDURE</span> GetEmployeesByDepartment(<span class="color-blue">IN</span> dept_id <span class="color-pink">INT</span>)</p>
                                <p><span class="color-blue">BEGIN</span></p>
                                <p style="margin-left:30px">    <span class="color-blue">SELECT</span> id, name, salary</p>
                                <p style="margin-left:30px">    <span class="color-blue">FROM</span> employees</p>
                                <p style="margin-left:30px">    <span class="color-blue">WHERE</span> department_id = dept_id;</p>
                                <p><span class="color-blue">END</span> //</p>
                                <br />
                                <p>DELIMITER ;</p>
                            `
                        }}></div>
                    </div>
                    <p>
                        You can call the stored procedure using the <strong>`CALL`</strong> statement:
                    </p>
                    <div className='codePalateBox mt-2 mb-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                                <p><span class="color-blue">CALL</span> GetEmployeesByDepartment(<span class="color-pink">1</span>);</p>
                            `
                        }}></div>
                    </div>
                    <p>
                        This will retrieve all employees in department 1.
                    </p>
                </div>
            </div>
            <div className='mt-5 mb-5'>
                <h5>Example with IN and OUT Parameters</h5>
                <div className='mt-3 mb-5'>
                    <p>
                        Let's create a stored procedure to calculate and return the total salary for a given department.
                    </p>
                    <div className='codePalateBox mt-2 mb-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                                <p>DELIMITER //</p>
                                <br />
                                <p><span class="color-blue">CREATE PROCEDURE</span> GetTotalSalaryByDepartment(<span class="color-blue">IN</span> dept_id <span class="color-pink">INT</span>, <span class="color-blue">OUT</span> total_salary <span class="color-pink">DECIMAL</span>(<span class="color-pink">10</span>, <span class="color-pink">2</span>))</p>
                                <p><span class="color-blue">BEGIN</span></p>
                                <p style="margin-left:30px">    <span class="color-blue">SELECT</span> <span class="color-yellow">SUM</span>(salary) <span class="color-blue">INTO</span> total_salary <span class="color-blue">FROM</span> employees <span class="color-blue">WHERE</span> department_id = dept_id;</p>
                                <p><span class="color-blue">END</span> //</p>
                                <br />
                                <p>DELIMITER ;</p>
                            `
                        }}></div>
                    </div>
                    <p>
                        You can call the stored procedure and retrieve the output parameter:
                    </p>
                    <div className='codePalateBox mt-2 mb-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                                <p><span class="color-blue">CALL</span> GetTotalSalaryByDepartment(<span class="color-pink">1</span>, <span class="color-pink">@total_salary</span>);</p>
                                <p><span class="color-blue">SELECT</span> <span class="color-pink">@total_salary</span>;</p>
                            `
                        }}></div>
                    </div>
                    <p>
                        This will calculate the total salary for department 1 and store it in the <strong>`@total_salary`</strong> variable.
                    </p>
                </div>
            </div>
        </section>
    )
}