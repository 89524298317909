import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/react-js/tailwind-css";

export default function TailwindCSS() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("React Styling - Tailwind CSS | Aspirant's Home");
        const urls = {
            'previous': '/react-js/styled-component',
            'next':'/react-js/redux'
        }
        path.setPreviousNext(urls);
    },[])

    return (
        <section className='mt-5 mb-5'>
            <h3>Tailwind CSS</h3>
            <div className='mt-4 mb-5'>
                <p>Tailwind CSS is a utility-first CSS framework that provides low-level utility classes to quickly build custom designs. It promotes a functional and responsive design approach, allowing you to create complex layouts and designs by composing classes rather than writing custom CSS. </p>
                <p>Here is the steps to install Tailwind CSS to your project.</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>
                        <h5>Install Tailwind CSS</h5>
                        Install <strong>`tailwindcss`</strong> via npm, and then run the init command to generate your <strong>`tailwind.config.js`</strong> file.
                        <div className='codePalateBox mt-2 mb-2'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p>npm install -D tailwindcss</p>
                                    <p>npx tailwindcss init</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li className='mt-4'>
                        <h5>Configure your template paths</h5>
                        Add the paths to all of your template files in your <strong>`tailwind.config.js`</strong> file.
                        <div className='codePalateBox mt-2 mb-2'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-grey">/** @type {import('tailwindcss').Config} */</span></p>
                                    <p><span class="color-blue">export default</span> {</p>
                                    <p style="margin-left:30px">    <span class="color-pink">content</span>: [</p>
                                    <p style="margin-left:60px">        <span class="color-green">"./src/**/*.{js,jsx,ts,tsx}"</span>,</p>
                                    <p style="margin-left:30px">    ],</p>
                                    <p style="margin-left:30px">    <span class="color-pink">theme</span>: {</p>
                                    <p style="margin-left:60px">        <span class="color-pink">extend</span>: <span class="color-green">{}</span>,</p>
                                    <p style="margin-left:30px">    },</p>
                                    <p style="margin-left:30px">    <span class="color-pink">plugins</span>: <span class="color-green">[]</span>,</p>
                                    <p>};</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li className='mt-4'>
                        <h5>Add the Tailwind directives to your CSS</h5>
                        Add the <strong>`@tailwind`</strong> directives for each of Tailwind’s layers to your <strong>`./src/index.css`</strong> file.
                        <div className='codePalateBox mt-2 mb-2'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p>@tailwind base;</p>
                                    <p>@tailwind components;</p>
                                    <p>@tailwind utilities;</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li className='mt-4'>
                        <h5>Start your build process</h5>
                        Run your build process with <strong>`npm run start`</strong>.
                        <div className='codePalateBox mt-2 mb-2'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p>npm run start</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li className='mt-4'>
                        <h5>Start using Tailwind in your project</h5>
                        Start using Tailwind’s utility classes to style your content.
                        <div className='codePalateBox mt-2 mb-2'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-blue">export default</span> function <span class="color-red">App</span>() {</p>
                                    <p style="margin-left:30px">    <span class="color-blue">return</span> (</p>
                                    <p style="margin-left:60px">        &lt;h1 <span class="color-pink">className</span>=<span class="color-green">"text-3xl font-bold underline"</span>&gt;</p>
                                    <p style="margin-left:90px">            Hello world!</p>
                                    <p style="margin-left:60px">        &lt;/h1&gt;</p>
                                    <p style="margin-left:30px">    )</p>
                                    <p>}</p>
                                `
                            }}></div>
                        </div>
                    </li>
                </ul>
            </div>
            <div className='mt-5 mb-5'>
                <h5>Adding & Using Tailwind CSS In A React Project</h5>
                <div className='mt-4 mb-5'>
                    Tailwind CSS gives you a pre-defined styles, you can do any customization you want to do. Lets see one example of customization of styles. Ok let's customise font style for your project. In <strong>`tailwind.config.js`</strong> file we have to create an utility class that allows us to use custom font. Look at the below example of how we do that.
                    <div className='codePalateBox mt-2 mb-5'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                            <p><span class="color-grey">/** @type {import('tailwindcss').Config} */</span></p>
                            <p><span class="color-blue">export default</span> {</p>
                            <p style="margin-left:30px">    <span class="color-pink">content</span>: [</p>
                            <p style="margin-left:60px">        <span class="color-green">"./src/**/*.{js,jsx,ts,tsx}"</span>,</p>
                            <p style="margin-left:30px">    ],</p>
                            <p style="margin-left:30px">    <span class="color-pink">theme</span>: {</p>
                            <p style="margin-left:60px">        <span class="color-pink">extend</span>: <span class="color-green">{</span>
                            <p style="margin-left:90px">  <span class="color-pink">fontFamily</span>: <span class="color-green">{</p>
                            <p style="margin-left:120px"><span class="color-pink">title</span>: <span class="color-green">['"Pacifico"','cursive']</p>
                            <p style="margin-left:90px"><span class="color-green">}</span></p>
                            <p style="margin-left:60px"><span class="color-green">}</span>,</p>
                            <p style="margin-left:30px">    },</p>
                            <p style="margin-left:30px">    <span class="color-pink">plugins</span>: <span class="color-green">[]</span>,</p>
                            <p>};</p>
                            `
                        }}></div>
                    </div>
                    <p>Here in the above code,</p>
                    <p><strong>`content`</strong>: This specifies the files that Tailwind CSS should process to generate the final CSS. In this case, it's set to process all JavaScript and TypeScript files (<strong>`*.&#123;js,jsx,ts,tsx&#125;`</strong>) in the src directory and its subdirectories (<strong>`"./src/**/*.&#123;js,jsx,ts,tsx&#125;"`</strong>).</p>
                    <p><strong>`theme`</strong>: This is where you can customize the default theme provided by Tailwind CSS. In this example, the <strong>`extend`</strong> property is used to add or override theme values. Specifically, it extends the <strong>`fontFamily`</strong> theme configuration by adding a new font family called <strong>`title`</strong> with the value <strong>`["Pacifico", "cursive"]`</strong>.</p>
                    <p><strong>`plugins`</strong>: This array allows you to include any Tailwind CSS plugins you want to use. Plugins can provide additional functionality or extend Tailwind CSS in various ways. In this example, the array is empty, indicating that no plugins are being used.</p>
                </div>
            </div>
            <div className='mt-5 mb-5'>
                <h5>Media Queries & Pseudo Selectors</h5>  
                <div className='mt-4 mb-4'>
                    <p>Every utility class in Tailwind can be applied conditionally at different breakpoints, which makes it a piece of cake to build complex responsive interfaces without ever leaving your HTML.</p>
                    <p>There are five breakpoints by default according to device resolutions. To add a utility but only have it take effect at a certain breakpoint, all you need to do is prefix the utility with the breakpoint name, followed by the <strong>`:`</strong> character.</p>

                    <p>Breakpoint prefixes are 
                        <br />
                        <strong>`sm`</strong> - minimum width <strong>640px</strong>
                        <br />
                        <strong>`md`</strong> - minimum width <strong>768px</strong>
                        <br />
                        <strong>`lg`</strong> - minimum width <strong>1024px</strong>
                        <br />
                        <strong>`xl`</strong> - minimum width <strong>1280px</strong>
                        <br />
                        <strong>`2xl`</strong> - minimum width <strong>1536px</strong>
                    </p>
                    <p>Look at the below example, image width is different in different screen resolution.</p>
                    <div className='codePalateBox mt-2 mb-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                            <p><span class="color-grey">&lt;!-- Width of image is 16 by default, 32 on medium screen device and 48 on large screen device. --&gt;</span></p>
                            <p>&lt;<span class="color-pink">img</span> class=<span class="color-green">"w-16 md:w-32 lg:w-48"</span> src="....." /&gt;</p>
                            `
                        }}></div>
                    </div>
                    <p>Also for pseudo selector you have to use <strong>`hover`</strong> and <strong>`focus`</strong> prefix followed by the <strong>`:`</strong> character.</p>
                </div>
            </div>
            <div className='mt-5 mb-5'>
                <h5>Tailwind : Pros & Cons</h5> 
                <div className='mt-4 mb-4'>
                    <p>There are some advantage of Tailwind CSS and disadvantage also.</p>
                    <p><strong>Advantage :</strong></p>
                    <ul style={{ listStyle: 'disc' }}>
                        <li>Tailwind CSS allows you to quickly build modern and responsive user interfaces by applying pre-defined utility classes directly in your HTML or JSX.</li>
                        <li>By using a predefined set of utility classes, Tailwind CSS helps maintain a consistent design across your application.</li>
                        <li>Tailwind CSS provides a wide range of utility classes that can be composed to create complex layouts and designs without writing custom CSS.</li>
                    </ul>
                    <p><strong>Disadvantage :</strong></p>
                    <ul style={{ listStyle: 'disc' }}>
                        <li>Relatively long className values</li>
                        <li>Any style changes require editing JSX</li>
                        <li>Lots of copy pasting for conditional and dynamic styling in Tailwind.</li>
                    </ul>
                </div>
            </div>
        </section>
    )
}