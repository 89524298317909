import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/html/forms";

export default function HTMLForms() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("HTML - Forms | Aspirant's Home");
        const urls = {
            'previous': '/html/create-bookmark',
            'next': '/html/form-attributes'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>HTML - Forms</h3>
            <div className='mt-4 mb-5'>
                <p>
                    HTML forms are a crucial element of web development, providing a way for users to interact with web applications by submitting data. Here's a detailed explanation:
                </p>

                <h5 className='mt-5 mb-3'>How A Form Looks Like</h5>
                <div className='outputPalateBox mb-4'>
                    <div className='outputPalate' dangerouslySetInnerHTML={{
                        __html: `
                            <!DOCTYPE html>
                            <html>
                                <head>
                                    <title>Simple Form Example</title>
                                    <style>
                                        body {
                                            font-family: Arial, sans-serif;
                                            background-color: #f9f9f9;
                                            display: flex;
                                            justify-content: center;
                                            align-items: center;
                                            height: 100vh;
                                        }
                                        .form-container {
                                            background: #fff;
                                            padding: 20px;
                                            border-radius: 5px;
                                            box-shadow: 0 2px 4px rgba(0,0,0,0.1);
                                        }
                                        .form-group {
                                            margin-bottom: 15px;
                                        }
                                        label {
                                            display: block;
                                            margin-bottom: 5px;
                                            color: #333;
                                        }
                                        input[type="text"],
                                        input[type="email"] {
                                            width: 100%;
                                            padding: 10px;
                                            border: 1px solid #ccc;
                                            border-radius: 5px;
                                        }
                                        input[type="submit"] {
                                            background: #007BFF;
                                            color: #fff;
                                            border: none;
                                            padding: 10px 15px;
                                            border-radius: 5px;
                                            cursor: pointer;
                                            width: 100%;
                                        }
                                        input[type="submit"]:hover {
                                            background: #0056b3;
                                        }
                                    </style>
                                </head>
                                <body>
                                    <div class="form-container">
                                        <h2>Contact Form</h2>
                                        <form action="/submit" method="post">
                                            <div class="form-group">
                                                <label for="name">Name:</label>
                                                <input type="text" id="name" name="name" required>
                                            </div>
                                            <div class="form-group">
                                                <label for="email">Email:</label>
                                                <input type="email" id="email" name="email" required>
                                            </div>
                                            <div class="form-group">
                                                <input type="submit" value="Submit">
                                            </div>
                                        </form>
                                    </div>
                                </body>
                            </html>`
                    }}></div>
                </div>

                <h5 className='mt-5 mb-3'>Basic Structure of an HTML Form</h5>
                <h5 className='mt-5 mb-3'>The &lt;form&gt; Element</h5>
                <p>
                    An HTML form is created using the <strong>`&lt;form&gt;`</strong> element, which acts as a container for various input elements like text fields, checkboxes, radio buttons, submit buttons, etc. The basic structure looks like this:
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;form <span class="color-pink">action</span>=<span class="color-green">"/submit-form"</span> <span class="color-pink">method</span>=<span class="color-green">"POST"</span>&gt;</p>
                        <p class="ml-30">    <span class="color-grey">&lt;!-- Form fields go here --&gt;</span></p>
                        <p>&lt;/form&gt;</p>
                        `
                    }}></div>
                </div>

                <h5 className='mt-5 mb-3'>Common Form Elements</h5>
                <ul>
                    <li>
                        <p>
                            <strong>Text Input Element :</strong><code className='ml-60'>&lt;input type="text"&gt;</code>
                            <span className='ml-30'>Displays a single-line text input field</span>
                        </p>
                    </li>
                    <li>
                        <p>
                            <strong>Radio Button :</strong><code className='ml-60'>&lt;input type="radio"&gt;</code> <span className='ml-30'>	Displays a radio button (for selecting one option from many option)</span>
                        </p>
                    </li>
                    <li>
                        <p>
                            <strong>Checkbox :</strong> <code className='ml-60'>&lt;input type="checkbox"&gt;</code> <span className='ml-30'>	Displays a checkbox (for selecting zero or more of many option)</span>
                        </p>
                    </li>
                    <li>
                        <p>
                            <strong>Select Dropdown :</strong><span className='ml-30'>Displays a dropdown</span><br />
                            <code>
                                &lt;select id="country" name="country"&gt;<br />
                                <qt className='ml-30'>&lt;option value="usa"&gt;USA&lt;/option&gt;</qt><br />
                                <qt className='ml-30'>&lt;option value="canada"&gt;Canada&lt;/option&gt;</qt><br />
                                &lt;/select&gt;
                            </code>
                        </p>
                    </li>
                    <li>
                        <p>
                            <strong>Textarea :</strong> <code className='ml-60'>&lt;textarea id="message" name="message"&gt;&lt;/textarea&gt;</code> <span className='ml-30'>	Create a multi-line text input field</span>
                        </p>
                    </li>
                </ul>

                <h5 className='mt-5 mb-3'>The &lt;label&gt; Element</h5>
                <p>
                    The &lt;label&gt; element in HTML is used to define a label for an &lt;input&gt; element. It improves the usability and accessibility of web forms by making it easier for users, including those using screen readers, to understand the purpose of each input field. When a user clicks on a &lt;label&gt;, the corresponding &lt;input&gt; element is automatically focused, which enhances the user experience.
                </p>
                <h6>Basic Structure</h6>
                <p>
                    Here is the basic syntax for using a <strong>`&lt;label&gt;`</strong> element:
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>&lt;label <span class="color-pink">for</span>=<span class="color-green">"inputId"</span>&gt;Label Text&lt;/label&gt;</p>
                            <p>&lt;input <span class="color-pink">type</span>=<span class="color-green">"text"</span> <span class="color-pink">id</span>=<span class="color-green">"inputId"</span> <span class="color-pink">name</span>=<span class="color-green">"inputName"</span>&gt;</p>
                        `
                    }}></div>
                </div>
                <h6>The `for` Attribute</h6>
                <p>
                    The <strong>`for`</strong> attribute in the <strong>`&lt;label&gt;`</strong> tag should match the <strong>`id`</strong> attribute of the associated <strong>`&lt;input&gt;`</strong> element. This creates a connection between the label and the input field. When the label is clicked, the input field receives focus.
                </p>
            </div>
        </section>
    )
}