import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/mongo-db/connecting-database";

export default function MongoDropDatabase() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Connecting to MongoDB Database - A Complete Steps");
        title.setKeyWords("connecting to mongodb database, connecting to a database using mongodb, error connecting to database mongodb, how connect to mongodb, connect to mongodb collection, connecting to a mongodb database, mongodb connect to database, mongodb get database names, mongodb connection example, how to connect to a mongodb, connect to mongodb database command line, connecting to mongodb, mongodb connect to db, how to connect to mongodb, connect to mongodb from terminal, how to connect to mongodb from terminal");
        title.setPageDescription("Connecting to MongoDB involves a few steps, whether you are using a local instance or a cloud-based instance like MongoDB Atlas. Below are the general steps for connecting to MongoDB using different methods and programming languages.");
        const urls = {
            'previous': '/mongo-db/drop-database',
            'next': '/mongo-db/create-collection'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>MongoDB - Connecting to Database</h3>
            <div className='mt-4 mb-5'>
                <p>
                    Connecting to MongoDB involves a few steps, whether you are using a local instance or a cloud-based instance like MongoDB Atlas. Below are the general steps for connecting to MongoDB using different methods and programming languages.
                </p>
                <h5>MongoDB Shell</h5>
                <p>
                    To connect to a local MongoDB instance using the MongoDB shell:
                </p>
                <ul>
                    <li>
                        <strong>Start the MongoDB Server:</strong>
                        <div className='codePalateBox mt-2 mb-2'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p>mongod --dbpath /path/to/your/db</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <strong>Connect using MongoDB Shell:</strong>
                        <div className='codePalateBox mt-2 mb-2'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p>mongo</p>
                                `
                            }}></div>
                        </div>
                    </li>
                </ul>
                <p>
                    For a remote MongoDB instance (e.g., MongoDB Atlas):
                </p>
                <ul>
                    <li>
                        <strong>Get the Connection String from MongoDB Atlas.</strong>
                    </li>
                    <li>
                        <strong>Connect using MongoDB Shell:</strong>
                        <div className='codePalateBox mt-2 mb-2'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p>mongo <span class="color-green">"mongodb+srv://<username>:<password>@cluster0.mongodb.net/<dbname>?retryWrites=true&w=majority"</span></p>
                                `
                            }}></div>
                        </div>
                    </li>
                </ul>
            </div>
            <div className='mt-5 mb-5'>
                <h5>Programming Language Drivers:</h5>
                <div className='mt-3 mb-5'>
                    <ul style={{listStyle:'decimal'}}>
                        <li>
                            <strong>Connecting with Python</strong>
                            <p className='mt-4'>To install PyMongo</p>
                            <div className='codePalateBox mt-2 mb-2'>
                                <div className='codePalate' dangerouslySetInnerHTML={{
                                    __html: `
                                        <p>pip install pymongo</p>
                                    `
                                }}></div>
                            </div>
                            <p>Python Script to Connect to MongoDB:</p>
                            <div className='codePalateBox mt-2 mb-2'>
                                <div className='codePalate' dangerouslySetInnerHTML={{
                                    __html: `
                                        <p><span class="color-blue">from</span> pymongo <span class="color-blue">import</span> MongoClient</p>
                                        <br />
                                        <p><span class="color-grey"># Replace the URI string with your MongoDB deployment's connection string.</span></p>
                                        <p>client = MongoClient(<span class="color-green">"mongodb+srv://<username>:<password>@cluster0.mongodb.net/<dbname>?retryWrites=true&w=majority"</span>)</p>
                                        <br />
                                        <p><span class="color-grey"># Access a database</span></p>
                                        <p>db = client[<span class="color-green">'mydatabase'</span>]</p>
                                        <br />
                                        <p><span class="color-grey"># Access a collection</span></p>
                                        <p>collection = db[<span class="color-green">'mycollection'</span>]</p>
                                        <br />
                                        <p><span class="color-grey"># Example query</span></p>
                                        <p>document = collection.find_one()</p>
                                        <p><span class="color-yellow">print</span>(document)</p>
                                    `
                                }}></div>
                            </div>
                        </li>
                        <li className='mt-4'>
                            <strong className='mb-4'>Connecting with Node.js (using Mongoose)</strong>
                            <p className='mt-4'>Install Mongoose:</p>
                            <div className='codePalateBox mt-2 mb-2'>
                                <div className='codePalate' dangerouslySetInnerHTML={{
                                    __html: `
                                        <p>npm install mongoose</p>
                                    `
                                }}></div>
                            </div>
                            <p>Node.js Script to Connect to MongoDB:</p>
                            <div className='codePalateBox mt-2 mb-2'>
                                <div className='codePalate' dangerouslySetInnerHTML={{
                                    __html: `
                                        <p><span class="color-blue">const</span> mongoose = <span class="color-yellow">require</span>(<span class="color-green">'mongoose'</span>);</p>
                                        <br />
                                        <p><span class="color-blue">const</span> uri = <span class="color-green">"mongodb+srv://<username>:<password>@cluster0.mongodb.net/<dbname>?retryWrites=true&w=majority"</span>;</p>
                                        <br />
                                        <p>mongoose.<span class="color-red">connect</span>(uri, { <span class="color-pink">useNewUrlParser</span>: <span class="color-blue">true</span>, <span class="color-pink">useUnifiedTopology</span>: <span class="color-blue">true</span> })</p>
                                        <p>.<span class="color-red">then</span>(() => {</p>
                                        <p style="margin-left:30px">    <span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">'MongoDB connected…'</span>);</p>
                                        <p>})</p>
                                        <p>.<span class="color-red">catch</span>(err => <span class="color-pink">console</span>.<span class="color-red">log</span>(err));</p>
                                        <br />
                                        <p><span class="color-grey">// Define a schema and a model</span></p>
                                        <p><span class="color-blue">const</span> <span class="color-red">Schema</span> = mongoose.Schema;</p>
                                        <p><span class="color-blue">const</span> MySchema = <span class="color-blue">new</span> <span class="color-red">Schema</span>({</p>
                                        <p style="margin-left:30px">    <span class="color-pink">name</span>: <span class="color-red">String</span>,</p>
                                        <p style="margin-left:30px">    <span class="color-pink">age</span>: <span class="color-red">Number</span>,</p>
                                        <p>});</p>
                                        <br />
                                        <p><span class="color-blue">const</span> MyModel = mongoose.<span class="color-red">model</span>(<span class="color-green">'MyModel'</span>, MySchema);</p>
                                        <br />
                                        <p><span class="color-grey">// Example query</span></p>
                                        <p>MyModel.<span class="color-red">findOne</span>({}, (err, doc) => {</p>
                                        <p style="margin-left:30px">    <span class="color-blue">if</span> (err) {</p>
                                        <p style="margin-left:60px">        <span class="color-pink">console</span>.<span class="color-red">log</span>(err);</p>
                                        <p style="margin-left:30px">    } <span class="color-blue">else</span> {</p>
                                        <p style="margin-left:60px">        <span class="color-pink">console</span>.<span class="color-red">log</span>(doc);</p>
                                        <p style="margin-left:30px">    }</p>
                                        <p>});</p>
                                    `
                                }}></div>
                            </div>
                        </li>
                        <li className='mt-4'>
                            <strong className='mb-4'>Connecting with Java (using MongoDB Java Driver)</strong>
                            <ul className='mt-4'>
                                <li>
                                    <strong>Add MongoDB Java Driver to your Project:</strong>
                                    <p>
                                        If using Maven, add the following dependency to your <strong>`pom.xml`</strong>:
                                    </p>
                                    <div className='codePalateBox mt-2 mb-2'>
                                        <div className='codePalate' dangerouslySetInnerHTML={{
                                            __html: `
                                                <p>&lt;dependency&gt;</p>
                                                <p style="margin-left:30px">    &lt;groupId&gt;org.mongodb&lt;/groupId&gt;</p>
                                                <p style="margin-left:30px">    &lt;artifactId&gt;mongodb-driver-sync&lt;/artifactId&gt;</p>
                                                <p style="margin-left:30px">    &lt;version&gt;4.3.4&lt;/version&gt;</p>
                                                <p>&lt;/dependency&gt;</p>
                                            `
                                        }}></div>
                                    </div>
                                </li>
                                <li>
                                    <strong>Java Code to Connect to MongoDB:</strong>
                                    <div className='codePalateBox mt-2 mb-2'>
                                        <div className='codePalate' dangerouslySetInnerHTML={{
                                            __html: `
                                                <p><span class="color-blue">import</span> com.mongodb.client.MongoClients;</p>
                                                <p><span class="color-blue">import</span> com.mongodb.client.MongoClient;</p>
                                                <p><span class="color-blue">import</span> com.mongodb.client.MongoDatabase;</p>
                                                <p><span class="color-blue">import</span> com.mongodb.client.MongoCollection;</p>
                                                <p><span class="color-blue">import</span> org.bson.Document;</p>
                                                <br />
                                                <p><span class="color-blue">public class</span> <span class="color-red">MongoDBConnection</span> {</p>
                                                <p style="margin-left:30px">    <span class="color-blue">public static void</span> main(String[] args) {</p>
                                                <p style="margin-left:60px">        <span class="color-blue">String uri</span> = <span class="color-green">"mongodb+srv://<username>:<password>@cluster0.mongodb.net/<dbname>?retryWrites=true&w=majority"</span>;</p>
                                                <p style="margin-left:60px">        <span class="color-pink">MongoClient mongoClient</span> = MongoClients.create(uri);</p>
                                                <p style="margin-left:60px">        <span class="color-pink">MongoDatabase database</span> = mongoClient.getDatabase(<span class="color-green">"mydatabase"</span>);</p>
                                                <br />
                                                <p style="margin-left:60px">        MongoCollection<Document> collection = database.getCollection(<span class="color-green">"mycollection"</span>);</p>
                                                <p style="margin-left:60px">        <span class="color-pink">Document document</span> = collection.find().first();</p>
                                                <p style="margin-left:60px">        System.out.println(document.toJson());</p>
                                                <p style="margin-left:30px">    }</p>
                                                <p>}</p>
                                            
                                            `
                                        }}></div>
                                    </div>
                                </li>
                            </ul>
                        </li>
                        <li className='mt-4'>
                            <strong className='mb-4'>Connecting with PHP (using MongoDB PHP Library)</strong>
                            <ul className='mt-4'>
                                <li>
                                    <strong>Install MongoDB PHP Library:</strong>
                                    <div className='codePalateBox mt-2 mb-2'>
                                        <div className='codePalate' dangerouslySetInnerHTML={{
                                            __html: `
                                                <p>sudo pecl install mongodb</p>
                                            `
                                        }}></div>
                                    </div>
                                </li>
                                <li>
                                    <strong>Add MongoDB Extension to PHP:</strong>
                                    <div className='codePalateBox mt-2 mb-2'>
                                        <div className='codePalate' dangerouslySetInnerHTML={{
                                            __html: `
                                                <p>echo <span class="color-green">"extension=mongodb.so"</span> >> /etc/php.ini</p>
                                            `
                                        }}></div>
                                    </div>
                                </li>
                                <li>
                                    <strong>PHP Code to Connect to MongoDB:</strong>
                                    <div className='codePalateBox mt-2 mb-2'>
                                        <div className='codePalate' dangerouslySetInnerHTML={{
                                            __html: `
                                                <p>&lt;?php</p>
                                                <p><span class="color-blue">require</span> <span class="color-green">'vendor/autoload.php'</span>; <span class="color-grey">// include Composer's autoloader</span></p>
                                                <br />
                                                <p><span class="color-pink">$client</span> = <span class="color-blue">new</span> MongoDB\<span class="color-red">Client</span>(<span class="color-green">"mongodb+srv://<username>:<password>@cluster0.mongodb.net/<dbname>?retryWrites=true&w=majority"</span>);</p>
                                                <br />
                                                <p><span class="color-pink">$database</span> = <span class="color-pink">$client</span>->mydatabase;</p>
                                                <p><span class="color-pink">$collection</span> = <span class="color-pink">$database</span>->mycollection;</p>
                                                <br />
                                                <p><span class="color-pink">$document</span> = <span class="color-pink">$collection</span>-><span class="color-red">findOne</span>();</p>
                                                <p><span class="color-red">var_dump</span>(<span class="color-pink">$document</span>);</p>
                                                <p>?&gt;</p>
                                            `
                                        }}></div>
                                    </div>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
        </section>
    )
}