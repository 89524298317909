import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';
export const pageUrl = () => "/mongo-db/update-document";

export default function MongoUpdateDocument() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Update Document in MongoDB | A Complete Guide");
        title.setKeyWords("update document in mongodb, update a document in mongodb, mongodb update document in aggregate, update all documents in mongodb, update all document in mongodb, mongodb update all fields in document, update mongodb document example, update document in collection mongodb, mongodb update document field, update document inside document in mongodb, update document field mongodb, update document from mongodb");
        title.setPageDescription("Updating documents in MongoDB can be done using various methods that allow for different types of updates. The primary methods for updating documents are `updateOne`, `updateMany`, and `findOneAndUpdate`");
        const urls = {
            'previous': '/mongo-db/query-document',
            'next': '/mongo-db/delete-document'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>MongoDB - Update Document</h3>
            <div className='mt-4 mb-5'>
                <p>
                    Updating documents in MongoDB can be done using various methods that allow for different types of updates. The primary methods for updating documents are <strong>`updateOne`</strong>, <strong>`updateMany`</strong>, and <strong>`findOneAndUpdate`</strong>.
                </p>

                <h5 className='mt-5 mb-4'>Update Operators</h5>
                <p>MongoDB provides several update operators to modify documents. Here are <Link to="/mongo-db/update-operators">some</Link> most commonly used update operators.</p>

                <h5 className='mt-5 mb-4'>The `updateOne()` Method</h5>
                <p>
                    The <strong>`updateOne`</strong> method is used in MongoDB to update a single document that matches a specified filter. This method is commonly used when you need to update the first document that matches certain criteria.
                </p>
                <p>
                    Here are the basic syntax of the <strong>`updateOne`</strong> method:
                </p>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>db.collection.<span class="color-red">updateOne</span>(</p>
                            <p style="margin-left:30px">    &lt;filter&gt;,</p>
                            <p style="margin-left:30px">    &lt;update&gt;,</p>
                            <p style="margin-left:30px">    {</p>
                            <p style="margin-left:60px">         <span class="color-pink">upsert</span>: &lt;boolean&gt;,</p>
                            <p style="margin-left:60px">         <span class="color-pink">writeConcern</span>: &lt;<span class="color-pink">document</span>&gt;,</p>
                            <p style="margin-left:60px">         <span class="color-pink">collation</span>: &lt;<span class="color-pink">document</span>&gt;,</p>
                            <p style="margin-left:60px">         <span class="color-pink">arrayFilters</span>: [ &lt;filterdocument1&gt;, ... ]</p>
                            <p style="margin-left:30px">    }</p>
                            <p>)</p>
                        `
                    }}></div>
                </div>
                <p>
                    Where,
                </p>
                <ul style={{ listStyle: 'decimal' }}>
                    <li><strong>`filter`</strong>: A document that specifies the criteria used to select the document to update.</li>
                    <li><strong>`update`</strong>: A document that specifies the fields to update. This document can use update operators like $set, $inc, etc.</li>
                    <li><strong>`upsert (optional)`</strong>: If true, creates a new document if no document matches the filter. Default is false.</li>
                    <li><strong>`writeConcern (optional)`</strong>: A document that specifies the level of acknowledgment requested from MongoDB for write operations.</li>
                    <li><strong>`collation (optional)`</strong>: Allows users to specify language-specific rules for string comparison, such as rules for lettercase and accent marks.</li>
                    <li><strong>`arrayFilters (optional)`</strong>: An array of filter documents that determines which array elements to modify for an update operation on an array field.</li>
                </ul>
                <p>
                    For example, to update a single document's field:
                </p>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>db.collection.<span class="color-red">updateOne</span>(</p>
                            <p style="margin-left:30px">    { <span class="color-pink">username</span>: <span class="color-green">"johndoe"</span> },</p>
                            <p style="margin-left:30px">    { <span class="color-pink">$set</span>: { <span class="color-pink">age</span>: <span class="color-pink">30</span> } }</p>
                            <p>)</p>
                        `
                    }}></div>
                </div>
                <p>
                    This example updates the <strong>`age`</strong> field to <strong>`30`</strong> for the first document where <strong>`username`</strong> is <strong>`"johndoe"`</strong>.
                </p>
                <h5 className='mt-5 mb-4'>The `updateMany()` Method</h5>
                <p>
                    The <strong>`updateMany`</strong> method in MongoDB is used to update multiple documents that match a specified filter. Unlike <strong>`updateOne`</strong>, which updates only the first matching document, <strong>`updateMany`</strong> applies the update operation to all documents that meet the filter criteria.
                </p>
                <p>
                    Here are the basic syntax of the <strong>`updateMany`</strong> method:
                </p>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>db.collection.<span class="color-red">updateMany</span>(</p>
                            <p style="margin-left:30px">    &lt;filter&gt;,</p>
                            <p style="margin-left:30px">    &lt;update&gt;,</p>
                            <p style="margin-left:30px">    {</p>
                            <p style="margin-left:60px">         <span class="color-pink">upsert</span>: &lt;boolean&gt;,</p>
                            <p style="margin-left:60px">         <span class="color-pink">writeConcern</span>: &lt;<span class="color-pink">document</span>&gt;,</p>
                            <p style="margin-left:60px">         <span class="color-pink">collation</span>: &lt;<span class="color-pink">document</span>&gt;,</p>
                            <p style="margin-left:60px">         <span class="color-pink">arrayFilters</span>: [ &lt;filterdocument1&gt;, ... ]</p>
                            <p style="margin-left:30px">    }</p>
                            <p>)</p>
                        `
                    }}></div>
                </div>
                <p>
                    Where,
                </p>
                <ul style={{ listStyle: 'decimal' }}>
                    <li><strong>`filter`</strong>: A document that specifies the criteria used to select the document to update.</li>
                    <li><strong>`update`</strong>: A document that specifies the fields to update. This document can use update operators like $set, $inc, etc.</li>
                    <li><strong>`upsert (optional)`</strong>: If true, creates a new document if no document matches the filter. Default is false.</li>
                    <li><strong>`writeConcern (optional)`</strong>: A document that specifies the level of acknowledgment requested from MongoDB for write operations.</li>
                    <li><strong>`collation (optional)`</strong>: Allows users to specify language-specific rules for string comparison, such as rules for lettercase and accent marks.</li>
                    <li><strong>`arrayFilters (optional)`</strong>: An array of filter documents that determines which array elements to modify for an update operation on an array field.</li>
                </ul>
                <p>
                    For example, to update multiple documents that match the filter criteria.
                </p>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>db.collection.<span class="color-red">updateMany</span>(</p>
                            <p style="margin-left:30px">    { <span class="color-pink">isActive</span>: <span class="color-blue">true</span> }, <span class="color-grey"> // Filter criteria</span></p>
                            <p style="margin-left:30px">    { <span class="color-pink">$set</span>: { <span class="color-pink">isActive</span>: <span class="color-blue">false</span> } }  <span class="color-grey">// Update operation</span></p>
                            <p>)</p>
                        `
                    }}></div>
                </div>
                <h5 className='mt-5 mb-4'>The `findOneAndUpdate()` Method</h5>
                <p>
                    The <strong>`findOneAndUpdate`</strong> method in MongoDB is used to find a single document that matches a specified filter, apply an update to that document, and return the document. This method is particularly useful when you need to update a document and simultaneously retrieve either the original or the updated document.
                </p>
                <p>
                    Here are the basic syntax of the <strong>`findOneAndUpdate`</strong> method:
                </p>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>db.collection.<span class="color-red">findOneAndUpdate</span>(</p>
                            <p style="margin-left:30px">    &lt;filter&gt;,</p>
                            <p style="margin-left:30px">    &lt;update&gt;,</p>
                            <p style="margin-left:30px">    {</p>
                                <p style="margin-left:60px">    <span class="color-pink">projection</span>: &lt;<span class="color-pink">document</span>&gt;,</p>
                                <p style="margin-left:60px">    <span class="color-pink">sort</span>: &lt;<span class="color-pink">document</span>&gt;,</p>
                                <p style="margin-left:60px">    <span class="color-pink">upsert</span>: &lt;boolean&gt;,</p>
                                <p style="margin-left:60px">    <span class="color-pink">returnDocument</span>: &lt;string&gt;,</p>
                                <p style="margin-left:60px">    <span class="color-pink">maxTimeMS</span>: &lt;number&gt;,</p>
                                <p style="margin-left:60px">    <span class="color-pink">collation</span>: &lt;<span class="color-pink">document</span>&gt;,</p>
                                <p style="margin-left:60px">    <span class="color-pink">arrayFilters</span>: [ &lt;filterdocument1&gt;, ... ],</p>
                                <p style="margin-left:60px">    <span class="color-pink">hint</span>: &lt;<span class="color-pink">document</span>&gt;</p>
                            <p style="margin-left:30px">    }</p>
                            <p>)</p>
                        `
                    }}></div>
                </div>
                <p>
                    Where,
                </p>
                <ul style={{ listStyle: 'decimal' }}>
                    <li><strong>`filter`</strong>: A document that specifies the criteria used to select the document to update.</li>
                    <li><strong>`update`</strong>: A document that specifies the fields to update. This document can use update operators like <strong>`$set`</strong>, <strong>`$inc`</strong>, etc.</li>
                    <li><strong>`projection (optional)`</strong>: A document that specifies the fields to return in the document.</li>
                    <li><strong>`sort (optional)`</strong>: A document that specifies the order in which to return matching documents.</li>
                    <li><strong>`upsert (optional)`</strong>: If <strong>`true`</strong>, creates a new document if no document matches the filter. Default is <strong>`false`</strong>.</li>
                    <li><strong>`returnDocument (optional)`</strong>: Specifies whether to return the document before or after the update is applied. Can be set to <strong>`before`</strong> or <strong>`after`</strong>. The default is <strong>`before`</strong>.</li>
                    <li><strong>`maxTimeMS (optional)`</strong>: The maximum amount of time to allow the query to run.</li>
                    <li><strong>`collation (optional)`</strong>: Allows users to specify language-specific rules for string comparison, such as rules for lettercase and accent marks.</li>
                    <li><strong>`arrayFilters (optional)`</strong>: An array of filter documents that determines which array elements to modify for an update operation on an array field.</li>
                    <li><strong>`hint (optional)`</strong>: A document that specifies the index to use.</li>
                </ul>
                <p>
                    For example, updating with upsert, which will create the document if it doesn't exist, and returning the updated document:
                </p>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>db.collection.<span class="color-red">findOneAndUpdate</span>(</p>
                            <p style="margin-left:30px">    { <span class="color-pink">username</span>: <span class="color-green">"janedoe"</span> },</p>
                            <p style="margin-left:30px">    { <span class="color-pink">$set</span>: { <span class="color-pink">age</span>: <span class="color-pink">25</span>, <span class="color-pink">location</span>: <span class="color-green">"New York"</span> } },</p>
                            <p style="margin-left:30px">    { <span class="color-pink">upsert</span>: <span class="color-blue">true</span>, <span class="color-pink">returnDocument</span>: <span class="color-green">"after"</span> }</p>
                            <p>)</p>
                        `
                    }}></div>
                </div>
                <p>If no document with <strong>`username: "janedoe"`</strong> exists, a new document will be created, and the updated document will be returned.</p>
            </div>
        </section>
    )
}