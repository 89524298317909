import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';
export const pageUrl = () => "/mysql/numeric-functions";

export default function MysqlNumericFunctions() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Mysql - Numeric Functions | Aspirant's Home");
        const urls = {
            'previous': '/mysql/arithmetic-operators',
            'next': '/mysql/string-functions'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>Mysql - Numeric Functions</h3>
            <div className='mt-4 mb-5'>
                <p>
                    Numeric functions are used to perform mathematical calculations on numeric values. These functions allow you to manipulate numeric data in various ways, such as rounding, absolute values, square roots, and trigonometric calculations. Here are some common numeric functions in MySQL:
                </p>
                <table className='table' style={{border: '1px dashed #ccc'}}> 
                    <thead>
                        <tr style={{border: '1px dashed #ccc'}}>
                            <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff', width: '20%'}}>Function Name</th>
                            <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>Description & Syntax</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr style={{ border: '1px dashed #ccc' }}>
                            <td style={{ border: '1px dashed #ccc', textAlign: 'center' }}>
                                <Link to="/mysql/numeric-functions/abs">ABS(x)</Link>
                            </td>
                            <td style={{ border: '1px dashed #ccc' }}>
                                <p>
                                    Returns the absolute (non-negative) value of <strong>`x`</strong>.
                                </p>
                            </td>
                        </tr>
                        <tr style={{ border: '1px dashed #ccc' }}>
                            <td style={{ border: '1px dashed #ccc', textAlign: 'center' }}>
                                <Link to="/mysql/numeric-functions/ceil">CEIL(x)</Link>
                            </td>
                            <td style={{ border: '1px dashed #ccc' }}>
                                <p>
                                    Returns the smallest integer value greater than or equal to <strong>`x`</strong>.
                                </p>
                            </td>
                        </tr>
                        <tr style={{ border: '1px dashed #ccc' }}>
                            <td style={{ border: '1px dashed #ccc', textAlign: 'center' }}>
                                <Link to="/mysql/numeric-functions/floor">FLOOR(x)</Link>
                            </td>
                            <td style={{ border: '1px dashed #ccc' }}>
                                <p>
                                    Returns the largest integer value less than or equal to <strong>`x`</strong>.
                                </p>
                            </td>
                        </tr>
                        <tr style={{ border: '1px dashed #ccc' }}>
                            <td style={{ border: '1px dashed #ccc', textAlign: 'center' }}>
                                <Link to="/mysql/numeric-functions/round">ROUND(x, d)</Link>
                            </td>
                            <td style={{ border: '1px dashed #ccc' }}>
                                <p>
                                    Rounds <strong>`x`</strong> to <strong>`d`</strong> decimal places. If <strong>`d`</strong> is omitted, <strong>`x`</strong> is rounded to 0 decimal places.
                                </p>
                            </td>
                        </tr>
                        <tr style={{ border: '1px dashed #ccc' }}>
                            <td style={{ border: '1px dashed #ccc', textAlign: 'center' }}>
                                <Link to="/mysql/numeric-functions/truncate">TRUNCATE(x, d)</Link>
                            </td>
                            <td style={{ border: '1px dashed #ccc' }}>
                                <p>
                                    Truncates <strong>`x`</strong> to <strong>`d`</strong> decimal places. Unlike <strong>`ROUND()`</strong>, <strong>`TRUNCATE()`</strong> does not round the value.
                                </p>
                            </td>
                        </tr>
                        <tr style={{ border: '1px dashed #ccc' }}>
                            <td style={{ border: '1px dashed #ccc', textAlign: 'center' }}>
                                <Link to="/mysql/numeric-functions/mod">MOD(x, y)</Link>
                            </td>
                            <td style={{ border: '1px dashed #ccc' }}>
                                <p>
                                    Returns the remainder of <strong>`x`</strong> divided by <strong>`y`</strong>.
                                </p>
                            </td>
                        </tr>
                        <tr style={{ border: '1px dashed #ccc' }}>
                            <td style={{ border: '1px dashed #ccc', textAlign: 'center' }}>
                                <Link to="/mysql/numeric-functions/pow">POW(x, y)</Link>
                            </td>
                            <td style={{ border: '1px dashed #ccc' }}>
                                <p>
                                    Raises <strong>`x`</strong> to the power of <strong>`y`</strong>.
                                </p>
                            </td>
                        </tr>
                        <tr style={{ border: '1px dashed #ccc' }}>
                            <td style={{ border: '1px dashed #ccc', textAlign: 'center' }}>
                                <Link to="/mysql/numeric-functions/sqrt">SQRT(x)</Link>
                            </td>
                            <td style={{ border: '1px dashed #ccc' }}>
                                <p>
                                    Returns the square root of <strong>`x`</strong>.
                                </p>
                            </td>
                        </tr>
                        <tr style={{ border: '1px dashed #ccc' }}>
                            <td style={{ border: '1px dashed #ccc', textAlign: 'center' }}>
                                <Link to="/mysql/numeric-functions/sin-cos-tan">SIN(x), COS(x), and TAN(x)</Link>
                            </td>
                            <td style={{ border: '1px dashed #ccc' }}>
                                <p>
                                    Trigonometric functions like <strong>`SIN(x)`</strong>, <strong>`COS(x)`</strong>, and <strong>`TAN(x)`</strong> for sine, cosine, and tangent of <strong>`x`</strong> (in radians).
                                </p>
                            </td>
                        </tr>
                        <tr style={{ border: '1px dashed #ccc' }}>
                            <td style={{ border: '1px dashed #ccc', textAlign: 'center' }}>
                                <Link to="/mysql/numeric-functions/rand">RAND()</Link>
                            </td>
                            <td style={{ border: '1px dashed #ccc' }}>
                                <p>
                                    Returns a random floating-point value between 0 and 1.
                                </p>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </section>
    )
}