import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/react-js/forms";

export default function ReactForms() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("React Forms | Aspirant's Home");
        const urls = {
            'previous': '/react-js/hooks/custom',
            'next':'/react-js/handling-form-submission'
        }
        path.setPreviousNext(urls);
    },[])

    return (
        <section className='mt-5 mb-5'>
            <h3>React Forms</h3>
            <div className='mt-4 mb-5'>
                <p>
                    HTML form elements work a bit differently from other DOM elements in React, because form elements naturally keep some internal state. The form has the default HTML form behavior of browsing to a new page when the user submits the form. If you want this behavior in React, it just works. But in most cases, it’s convenient to have a JavaScript function that handles the submission of the form and has access to the data that the user entered into the form. The standard way to achieve this is with a technique called <strong>`controlled components`</strong>.
                </p>
            </div>
            <div className='mt-5 mb-4'>
                <h5>What is Controlled Component ?</h5>
                <div className='mt-5 mb-4'>
                    <p>
                        <strong>`Controlled components`</strong> are a React pattern for managing form elements whose value is controlled by React state.
                    </p>
                    <p>
                        In a <strong>`controlled component`</strong>, the value of the form element is stored in React state and updated through a change handler function. This allows React to be the "single source of truth" for the form element's value, enabling more control over the form's behavior and ensuring that the UI reflects the current state of the form.
                    </p>
                    <p>
                        Here's an example of a controlled component using an input element:
                    </p>
                    <div className='codePalateBox mt-2 mb-5'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                                <p><span class="color-blue">import</span> <span class="color-red">React</span>, { <span class="color-red">useState</span> } <span class="color-blue">from</span> <span class="color-green">'react'</span>;</p>
                                <br />
                                <p><span class="color-blue">function</span> <span class="color-red">ControlledComponentExample</span>() {</p>
                                <p style="margin-left:30px">    <span class="color-blue">const</span> [value, setValue] = <span class="color-red">useState</span>('');</p>
                                <br />    
                                <p style="margin-left:30px">    <span class="color-blue">const</span> <span class="color-red">handleChange</span> = (event) => {</p>
                                <p style="margin-left:60px">        <span class="color-red">setValue</span>(event.target.value);</p>
                                <p style="margin-left:30px">    };</p>
                                <br />    
                                <p style="margin-left:30px">    <span class="color-blue">return</span> (</p>
                                <p style="margin-left:60px">        &lt;div&gt;</p>
                                <p style="margin-left:90px">            &lt;label&gt;</p>
                                <p style="margin-left:120px">                Name:</p>
                                <p style="margin-left:120px">                &lt;input type="text" value={value} onChange={handleChange} /&gt;</p>
                                <p style="margin-left:90px">            &lt;/label&gt;</p>
                                <p style="margin-left:90px">            &lt;p&gt;You typed: {value}&lt;/p&gt;</p>
                                <p style="margin-left:60px">        &lt;/div&gt;</p>
                                <p style="margin-left:30px">    );</p>
                                <p>}</p>
                                <br />
                                <p><span class="color-blue">export default</span> <span class="color-red">ControlledComponentExample</span>;</p>
                            `
                        }}></div>
                    </div>
                    <p>
                        In this example, the <strong>`value`</strong> state variable controls the <strong>`value`</strong> of the input element. The <strong>`handleChange`</strong> function updates the value state whenever the user types in the input field. The <strong>`value`</strong> attribute of the input element is set to {<strong>`value`</strong>}, making it a controlled component.
                    </p>
                </div>
            </div>
        </section>
    )
}