import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/react-js/handling-fetched-data";

export default function HandlingData() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("React Http Request - Transforming Fetched Data | Aspirant's Home");
        const urls = {
            'previous': '/react-js/handling-http-errors',
            'next':'/react-js/styling'
        }
        path.setPreviousNext(urls);
    },[])

    return (
        <section className='mt-5 mb-5'>
            <h3>React Http Request - Transforming Fetched Data</h3>
            <div className='mt-4 mb-4'>
                <p>
                    Transforming data from an HTTP request in React typically involves parsing the response into a usable format, such as JSON, and then manipulating the data as needed before rendering it in your components. Here's a basic example using the fetch API to fetch data from an API and transform it:
                </p>
                <div className='codePalateBox mt-2 mb-5'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-blue">import</span> <span class="color-red">React</span>, { <span class="color-red">useState</span>, <span class="color-red">useEffect</span> } <span class="color-blue">from</span> <span class="color-green">'react'</span>;</p>
                            <br />
                            <p><span class="color-blue">function</span> <span class="color-red">App</span>() {</p>
                            <p style="margin-left:30px">    <span class="color-blue">const</span> [data, setData] = <span class="color-red">useState</span>([]);</p>
                            <br />    
                            <p style="margin-left:30px">    <span class="color-red">useEffect</span>(() => {</p>
                            <p style="margin-left:60px">        <span class="color-red">fetch</span>(<span class="color-green">'https://jsonplaceholder.typicode.com/posts'</span>)</p>
                            <p style="margin-left:60px">        .<span class="color-red">then</span>(response => response.<span class="color-red">json</span>())</p>
                            <p style="margin-left:60px">        .<span class="color-red">then</span>(jsonData => {</p>
                            <p style="margin-left:90px">            <span class="color-grey">// Transforming the data</span></p>
                            <p style="margin-left:90px">            <span class="color-blue">const</span> transformedData = jsonData.<span class="color-red">map</span>(item => ({</p>
                            <p style="margin-left:120px">                 <span class="color-pink">id</span>: item.id,</p>
                            <p style="margin-left:120px">                 <span class="color-pink">title</span>: item.title.<span class="color-red">toUpperCase</span>(),</p>
                            <p style="margin-left:120px">                 <span class="color-pink">body</span>: item.body.<span class="color-red">slice</span>(<span class="color-pink">0</span>, <span class="color-pink">50</span>) + <span class="color-green">'...'</span></p>
                            <p style="margin-left:90px">            }));</p>
                            <p style="margin-left:90px">            <span class="color-red">setData</span>(transformedData);</p>
                            <p style="margin-left:60px">        })</p>
                            <p style="margin-left:60px">        .<span class="color-red">catch</span>(error => <span class="color-pink">console</span>.<span class="color-red">error</span>(<span class="color-green">'Error:'</span>, error));</p>
                            <p style="margin-left:30px">    }, []);</p>
                            <br />    
                            <p style="margin-left:30px">    <span class="color-blue">return</span> (</p>
                            <p style="margin-left:60px">        &lt;div&gt;</p>
                            <p style="margin-left:90px">        &lt;h1&gt;Transformed Data:&lt;/h1&gt;</p>
                            <p style="margin-left:90px">        &lt;ul&gt;</p>
                            <p style="margin-left:120px">            {data.<span class="color-red">map</span>(item => (</p>
                            <p style="margin-left:150px">            &lt;li <span class="color-pink">key</span>={item.id}&gt;</p>
                            <p style="margin-left:180px">                &lt;h2&gt;{item.title}&lt;/h2&gt;</p>
                            <p style="margin-left:180px">                &lt;p&gt;{item.body}&lt;/p&gt;</p>
                            <p style="margin-left:150px">            &lt;/li&gt;</p>
                            <p style="margin-left:90px">        &lt;/ul&gt;</p>
                            <p style="margin-left:60px">        &lt;/div&gt;</p>
                            <p style="margin-left:30px">    );</p>
                            <p>}</p>
                            <br />
                            <p><span class="color-blue">export default</span> <span class="color-red">App</span>;</p>
                        `
                    }}></div>
                </div>
                <p>
                    Using <strong>`map()`</strong> method we iterates over each item in the array and returns a new array with the results of applying a callback function to each item.
                </p>
                <p>Transformed data will be looks like below samples.</p>
                <div className='codePalateBox mt-2 mb-5'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>[</p>
                            <p style="margin-left:30px">    { <span class="color-pink">id</span>: 1, <span class="color-pink">title</span>: <span class="color-green">'FIRST POST'</span>, <span class="color-pink">body</span>: <span class="color-green">'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed...'</span> },</p>
                            <p style="margin-left:30px">    { <span class="color-pink">id</span>: 2, <span class="color-pink">title</span>: <span class="color-green">'SECOND POST'</span>, <span class="color-pink">body</span>: <span class="color-green">'Pellentesque habitant morbi tristique senectus et netus...'</span> },</p>
                            <p style="margin-left:30px">    { <span class="color-pink">id</span>: 3, <span class="color-pink">title</span>: <span class="color-green">'THIRD POST'</span>, <span class="color-pink">body</span>: <span class="color-green">'Integer eget dolor ac justo condimentum iaculis. Duis...'</span> }</p>
                            <p>]</p>
                        `
                    }}></div>
                </div>

            </div>
        </section>
    )
}