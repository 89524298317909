import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/css/background-size";

export default function CSSBackgroundSize() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Background Size in CSS | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/css/background-origin',
            'next': '/css/background-clip'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>Background Size</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <strong>`background-size`</strong> property in CSS specifies the size of the background images. This property allows you to control how the background image is scaled or sized within the element's background area.
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>selector {</p>
                            <p class="ml-30">    <span class="color-green">background-size</span>: value;</p>
                            <p>}</p>
                        `
                    }}></div>
                </div>
                
                <h5 className="mt-5 mb-3">Commonly Used Values and Their Uses</h5>
                <p>
                    There are many <strong>`background-size`</strong> property values. They are -
                </p>
                <ul style={{ 'listStyle': 'decimal' }}>
                    <li>
                        <strong>`auto`</strong>
                        <p>The background image is displayed at its original size. This is the default value.</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p>div {</p>
                                    <p class="ml-30">   <span class="color-green">background-image</span>: <span class="color-yellow">url</span>(<span class="color-green">'pattern.png'</span>);</p>
                                    <p class="ml-30">    <span class="color-green">background-size</span>: auto;</p>
                                    <p>}</p>
                                `
                            }}></div>
                        </div>
                        <div className='outputPalateBox mb-4 mt-3'>
                            <div className='outputPalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <style>
                                        #example1 {
                                            padding: 25px;
                                            background: url('../../assets/one-beautiful.jpg');
                                            background-repeat: no-repeat;
                                            background-size: auto;
                                            height: 300px;
                                            color: #FFF;
                                        }
                                    </style>
                                    <div id="example1">
                                        <h2>Background Size : auto</h2>
                                    </div>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <strong>`length` (e.g., `px`, `em`)</strong>
                        <p>It specifies the width and height of the background image using length units. If only one value is specified, the second value is set to <strong>`auto`</strong></p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p>div {</p>
                                    <p class="ml-30">   <span class="color-green">background-image</span>: <span class="color-yellow">url</span>(<span class="color-green">'pattern.png'</span>);</p>
                                    <p class="ml-30">    <span class="color-green">background-size</span>: 200px 100px;</p>
                                    <p>}</p>
                                `
                            }}></div>
                        </div>
                        <div className='outputPalateBox mb-4 mt-3'>
                            <div className='outputPalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <style>
                                        #example2 {
                                            padding: 25px;
                                            background: url('../../assets/one-beautiful.jpg');
                                            background-repeat: no-repeat;
                                            background-size: 200px 100px;
                                            height: 300px;
                                            color: #FFF;
                                        }
                                    </style>
                                    <div id="example2">
                                        <h2>Background Size : length</h2>
                                    </div>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <strong>`percentage`</strong>
                        <p>It specifies the width and height of the background image as a percentage of the background area.If only one value is specified, the second value is set to <strong>`auto`</strong>.</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p>div {</p>
                                    <p class="ml-30">   <span class="color-green">background-image</span>: <span class="color-yellow">url</span>(<span class="color-green">'pattern.png'</span>);</p>
                                    <p class="ml-30">    <span class="color-green">background-size</span>: 50% 25%;</p>
                                    <p>}</p>
                                `
                            }}></div>
                        </div>
                        <div className='outputPalateBox mb-4 mt-3'>
                            <div className='outputPalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <style>
                                        #example3 {
                                            padding: 25px;
                                            background: url('../../assets/one-beautiful.jpg');
                                            background-repeat: no-repeat;
                                            background-size: 50% 25%;
                                            height: 300px;
                                            color: #FFF;
                                        }
                                    </style>
                                    <div id="example3">
                                        <h2>Background Size : percentage</h2>
                                    </div>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <strong>`cover`</strong>
                        <p>It scales the background image to be as large as possible so that the background area is completely covered by the background image. Some parts of the image may be clipped to fill the element's content box.</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p>div {</p>
                                    <p class="ml-30">   <span class="color-green">background-image</span>: <span class="color-yellow">url</span>(<span class="color-green">'pattern.png'</span>);</p>
                                    <p class="ml-30">    <span class="color-green">background-size</span>: cover;</p>
                                    <p>}</p>
                                `
                            }}></div>
                        </div>
                        <div className='outputPalateBox mb-4 mt-3'>
                            <div className='outputPalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <style>
                                        #example4 {
                                            padding: 25px;
                                            background: url('../../assets/one-beautiful.jpg');
                                            background-repeat: no-repeat;
                                            background-size: cover;
                                            height: 300px;
                                            color: #FFF;
                                        }
                                    </style>
                                    <div id="example4">
                                        <h2>Background Size : cover</h2>
                                    </div>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <strong>`contain`</strong>
                        <p>This property value scales the background image to be as large as possible while ensuring both its width and height fit within the background area. The entire image will be visible, but there may be some empty space around it.</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p>div {</p>
                                    <p class="ml-30">   <span class="color-green">background-image</span>: <span class="color-yellow">url</span>(<span class="color-green">'pattern.png'</span>);</p>
                                    <p class="ml-30">    <span class="color-green">background-size</span>: contain;</p>
                                    <p>}</p>
                                `
                            }}></div>
                        </div>
                        <div className='outputPalateBox mb-4 mt-3'>
                            <div className='outputPalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <style>
                                        #example5 {
                                            padding: 25px;
                                            background: url('../../assets/one-beautiful.jpg');
                                            background-repeat: no-repeat;
                                            background-size: contain;
                                            height: 300px;
                                            color: #FFF;
                                        }
                                    </style>
                                    <div id="example5">
                                        <h2>Background Size : contain</h2>
                                    </div>
                                `
                            }}></div>
                        </div>
                    </li>
                </ul>
            </div>
        </section>
    )
}