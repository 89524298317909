import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../../Context";
import { Link } from 'react-router-dom';
export const pageUrl = () => "/mysql/date-and-time-functions/day";

export default function Day() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Mysql - Date and Time functions - DAY() | Aspirant's Home");
        path.setPreviousNext({});
    }, [])

    return (
        <>
            <section class='clearfix mb-3'>
                <span className='float-start'>
                    <Link to='/mysql/date-and-time-functions' onClick={() => path.setPathName('/mysql/date-and-time-functions')} className='btn btn-primary'> <i class="fa-solid fa-less-than"></i>&nbsp; Back to Date and Time functions </Link>
                </span>
            </section>
            <section className='mt-5 mb-5'>
                <h3>Mysql - DAY() Functions</h3>
                <div className='mt-4 mb-5'>
                    <p>
                        The <strong>`DAY()`</strong> function in MySQL is used to extract the day part from a date. It takes a date or datetime value as an argument and returns the day component as an integer, ranging from 1 to 31.
                    </p>
                    <p>
                        Here's a basic example of how to use the <strong>`DAY()`</strong> function:
                    </p>
                    <div className='codePalateBox mt-2 mb-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                                <p><span class="color-blue">SELECT DAY</span>(<span class="color-green">'2022-05-16'</span>);</p>
                            `
                        }}></div>
                    </div>
                    <p>
                        This will return <strong>`16`</strong>, which is the day component of the date <strong>`'2022-05-16'`</strong>.
                    </p>
                    <p>
                        You can also use the <strong>`DAY()`</strong> function with a column name in a table to extract the day part from dates stored in a table. For example, if you have a table <strong>`orders`</strong> with a <strong>`order_date`</strong> column containing dates, you can use <strong>`DAY()`</strong> to extract the day part:
                    </p>
                    <div className='codePalateBox mt-2 mb-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                                <p><span class="color-blue">SELECT DAY</span>(order_date) <span class="color-blue">FROM</span> orders;</p>
                            `
                        }}></div>
                    </div>
                    <p>
                        This will return the day part of each <strong>`order_date`</strong> in the <strong>`orders`</strong> table.
                    </p>
                </div>
            </section>
        </>
    )
}