import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/react-js/router";

export default function ReactRouters() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("React Routing | Aspirant's Home");
        const urls = {
            'previous': '/react-js/validating-form-user-input',
            'next':'/react-js/defining-router'
        }
        path.setPreviousNext(urls);
    },[])

    return (
        <section className='mt-5 mb-5'>
            <h3>React Js - Routers</h3>
            <div className='mt-4 mb-4'>
                <p>Routing in a React app refers to the process of navigating between different views or pages in a single-page application (SPA). In a traditional multi-page application, each page is a separate HTML file, and navigating between pages involves loading a new HTML file from the server.</p>

                <p>In contrast, SPAs load a single HTML file that serves as the entry point for the application. When a user navigates to different sections of the app, the content is dynamically updated without the need to reload the entire page. This is achieved using client-side routing, where the application changes the UI based on the current URL without requesting a new page from the server.</p>

                <p>Basically, When we building complex user interfaces, we typically build <strong>Single Page Applications</strong> (SPAs). That means only one initial HTML request & response. Page (URL) changes are then handled by client-side React code. Visible content is changed without reloading the entire page.</p>
            </div>
            <div className='mt-4 mb-4'>
                <h5>Project Setup & Installing React Router</h5>
                <div className='mt-4 mb-4'>
                    At first, we have to install `react-router-dom` by using npm in your project folder.
                    <div className='codePalateBox mt-2 mb-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `<p>npm install react-router-dom</p>
                            `
                        }}></div>
                    </div>   
                </div>
            </div>
            
            <div className='mt-5 mb-4'>
                <h5>Navigating between Pages with Links</h5>
                <div className='mt-4 mb-4'>

                </div>
            </div>
            <div className='mt-5 mb-4'>
                <h5>Layouts & Nested Routes</h5>
                <div className='mt-4 mb-4'>
                    <p>Suppose we have common sidebar navigation which we have to call in all Different Component, Then instead of calling the Nagigation Component in each of the Component, we can make an layout in the routes defination. </p>
                </div>
            </div>
        </section>
    )
}
