import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/css/outline/offset";

export default function OutlineOffset() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Outline Offset in CSS | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/css/outline/shorthand',
            'next': '/css/text/color'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>Outline Offset</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <strong>`outline-offset`</strong> property in CSS is used to specify the distance between an element's outline and its border edge. Essentially, it controls how far the outline is drawn from the border or edge of the element. It has a basic syntax -
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>element {</p>
                            <p class="ml-30">    <span class="color-green">outline-offset</span>: value;</p>
                            <p>}</p>
                        `
                    }}></div>
                </div>
                <p>
                    Where, <strong>value</strong> is specifies the distance by which the outline is offset from the border edge. It can be defined in any valid CSS length unit, such as <strong>`px`</strong> (pixels), <strong>`em`</strong>, <strong>`rem`</strong>, <strong>`%`</strong>, etc.
                </p>

                <h5 className="mt-5 mb-3">Examples of Outline Offset</h5>
                <p>
                    Here is an basic syntax of Outline offset.
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;style&gt;</p>
                        <p class="ml-30">    .box {</p>
                        <p class="ml-60">        <span class="color-green">border</span>: <span class="color-pink">2px</span> solid black;</p>
                        <p class="ml-60">        <span class="color-green">outline</span>: <span class="color-pink">4px</span> solid red;</p>
                        <p class="ml-60">        <span class="color-green">outline-offset</span>: <span class="color-pink">20px</span>;</p>
                        <p class="ml-30">    }</p>
                        <p>&lt;/style&gt;</p>
                        <p>&lt;div <span class="color-pink">class</span>=<span class="color-green">"box"</span>&gt;Outline Offset 20px&lt;/div&gt;</p>
                        `
                    }}></div>
                </div>
                <p>
                    Output will be the following -
                </p>
                <div className='outputPalateBox mb-4 mt-3' style={{'border':'0'}}>
                    <div className='outputPalate' dangerouslySetInnerHTML={{
                        __html: `
                            <style>
                                .box {
                                    border: 2px solid black;
                                    outline: 4px solid red;
                                    outline-offset: 20px;
                                }
                            </style>
                            <div class="box">Outline Offset 20px</div>
                        `
                    }}></div>
                </div>
            </div>
        </section>
    )
}