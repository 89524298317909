import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/node-js/mysql-drop-table";

export default function MysqlDropTable() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Node Js - Mysql - Drop Table | Aspirant's Home");
        const urls = {
            'previous': '/node-js/mysql-delete-query',
            'next': '/node-js/mysql-update-query'
        }
        path.setPreviousNext(urls);
    }, [])
    
    return (
        <section className='mt-5 mb-5'>
            <h3 className='mb-4'>Node js Mysql Drop Table</h3>
            <p>To delete an existing table In Mysql, we use "DROP TABLE" statement</p>
            <p>Let's check how we use this with node js <strong>`mysql`</strong> package.</p>
            <div className='codePalateBox mt-2 mb-2'>
                <div className='codePalate' dangerouslySetInnerHTML={{
                    __html: `<p><span class='color-blue'>var</span> mysql = <span class='color-yellow'>require</span>(<span class='color-green'>'mysql'</span>);</p>
                    <br />
                    <p><span class='color-blue'>var</span> con = mysql.<span class='color-red'>createConnection</span>({</p>
                        <p style='margin-left:30px'><span class='color-pink'>host</span>: <span class='color-green'>"localhost"</span>,</p>
                        <p style='margin-left:30px'><span class='color-pink'>user</span>: <span class='color-green'>"yourusername"</span>,</p>
                        <p style='margin-left:30px'><span class='color-pink'>password</span>: <span class='color-green'>"yourpassword"</span>,</p>
                        <p style='margin-left:30px'><span class='color-pink'>database</span>: <span class='color-green'>"mydb"</span></p>
                    <p>});</p>
                    <br />
                    <p>con.<span class='color-red'>connect</span>(function(err) {</p>
                        <p style='margin-left:30px'><span class='color-blue'>if</span> (err) throw err;</p>
                        <p style='margin-left:30px'>con.<span class='color-red'>query</span>(<span class='color-green'>"DROP TABLE users"</span>, function (err, result) {</p>
                        <p style='margin-left:60px'><span class='color-blue'>if</span> (err) throw err;</p>
                        <p style='margin-left:60px'><span class='color-pink'>console</span>.<span class='color-red'>log</span>("Table deleted");</p>
                        <p style='margin-left:30px'>});</p>
                    <p>});</p>
                    `
                }}></div>
            </div>
            <p>Which will give you this result:</p>
            <div className='codePalateBox mt-2 mb-2'>
                <div className='codePalate' dangerouslySetInnerHTML={{
                    __html: `Table deleted`
                }}></div>
            </div>
        </section>
    )
}
