import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../../Context";
import { Link } from 'react-router-dom';
export const pageUrl = () => "/mysql/date-and-time-functions/timediff";

export default function StrToDate() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Mysql - Date and Time functions - TIMEDIFF() | Aspirant's Home");
        path.setPreviousNext({});
    }, [])

    return (
        <>
            <section class='clearfix mb-3'>
                <span className='float-start'>
                    <Link to='/mysql/date-and-time-functions' onClick={() => path.setPathName('/mysql/date-and-time-functions')} className='btn btn-primary'> <i class="fa-solid fa-less-than"></i>&nbsp; Back to Date and Time functions </Link>
                </span>
            </section>
            <section className='mt-5 mb-5'>
                <h3>Mysql - TIMEDIFF() Functions</h3>
                <div className='mt-4 mt-5'>
                    <p>
                        The <strong>`TIMEDIFF()`</strong> function in MySQL is used to calculate the difference between two time values. It takes two arguments, the end time and the start time, and returns the difference as a time value. The syntax is as follows:
                    </p>
                    <div className='codePalateBox mt-2 mb-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                                <p>TIMEDIFF(end_time, start_time)</p>
                            `
                        }}></div>
                    </div>
                    <p>
                        The <strong>`end_time`</strong> and <strong>`start_time`</strong> parameters can be in any of the following formats:
                    </p>
                    <ul>
                        <li><strong>`'HH:MM:SS'`</strong>: Time in hours, minutes, and seconds.</li>
                        <li><strong>`'HH:MM'`</strong>: Time in hours and minutes.</li>
                        <li><strong>`'HHMMSS'`</strong>: Time in hours, minutes, and seconds without separators.</li>
                        <li><strong>`'HHMM'`</strong>: Time in hours and minutes without separators.</li>
                    </ul>
                    <p>
                        The <strong>`TIMEDIFF()`</strong> function calculates the difference as <strong>`end_time`</strong> - <strong>`start_time`</strong>. The result is returned in the same format as the input time values.
                    </p>
                    <p>
                        Here's an example to illustrate how <strong>`TIMEDIFF()`</strong> works:
                    </p>
                    <div className='codePalateBox mt-2 mb-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                                <p><span class="color-blue">SELECT</span> TIMEDIFF(<span class="color-green">'10:00:00'</span>, <span class="color-green">'08:30:00'</span>);</p>
                            `
                        }}></div>
                    </div>
                    <p>
                        This will return <strong>`'01:30:00'`</strong>, indicating that the difference between 10:00:00 and 08:30:00 is 1 hour and 30 minutes.
                    </p>
                </div>
                <div className='mt-5 mb-5'>
                    <h5>TIMEDIFF() with MySQL Table</h5>
                    <div className='mt-4 mb-2'>
                        <p>
                            To demonstrate the <strong>`TIMEDIFF()`</strong> function in MySQL with a table, let's consider a table <strong>`shifts`</strong> with columns <strong>`id`</strong>, <strong>`start_time`</strong>, and <strong>`end_time`</strong>, representing the start and end times of each shift. We want to calculate the duration of each shift using the <strong>`TIMEDIFF()`</strong> function. Here's an example:
                        </p>
                        <p>
                            Consider the <strong>`shifts`</strong> table:
                        </p>
                        <table className='table' style={{border: '1px dashed #ccc'}}> 
                            <thead>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>id</th>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>start_time</th>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>end_time</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>1</td>
                                    <td style={{border: '1px dashed #ccc'}}>08:00:00</td>
                                    <td style={{border: '1px dashed #ccc'}}>16:30:00</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>2</td>
                                    <td style={{border: '1px dashed #ccc'}}>09:30:00</td>
                                    <td style={{border: '1px dashed #ccc'}}>18:00:00</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>3</td>
                                    <td style={{border: '1px dashed #ccc'}}>07:45:00</td>
                                    <td style={{border: '1px dashed #ccc'}}>15:15:00</td>
                                </tr>
                            </tbody>
                        </table>
                        <p>
                            To calculate the duration of each shift, you would use:
                        </p>
                        <div className='codePalateBox mt-2 mb-2'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-blue">SELECT</span> id, start_time, end_time, TIMEDIFF(end_time, start_time) <span class="color-blue">AS</span> duration <span class="color-blue">FROM</span> shifts;</p>
                                `
                            }}></div>
                        </div>
                        <p>
                            This query will return:
                        </p>
                        <table className='table' style={{border: '1px dashed #ccc'}}> 
                            <thead>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>id</th>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>start_time</th>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>end_time</th>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>duration</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>1</td>
                                    <td style={{border: '1px dashed #ccc'}}>08:00:00</td>
                                    <td style={{border: '1px dashed #ccc'}}>16:30:00</td>
                                    <td style={{border: '1px dashed #ccc'}}>08:30:00</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>2</td>
                                    <td style={{border: '1px dashed #ccc'}}>09:30:00</td>
                                    <td style={{border: '1px dashed #ccc'}}>18:00:00</td>
                                    <td style={{border: '1px dashed #ccc'}}>08:30:00</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>3</td>
                                    <td style={{border: '1px dashed #ccc'}}>07:45:00</td>
                                    <td style={{border: '1px dashed #ccc'}}>15:15:00</td>
                                    <td style={{border: '1px dashed #ccc'}}>07:30:00</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </section>
        </>
    )
}