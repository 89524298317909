import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/css/text/color";

export default function TextColor() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Text Color in CSS | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/css/outline/offset',
            'next': '/css/text/alignment'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>Text Color</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <strong>`color`</strong> property in CSS is used to set the color of text in an element. It controls the foreground color, which means it affects the color of the text content inside an HTML element. It has a basic syntax -
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>element {</p>
                            <p class="ml-30">    <span class="color-green">color</span>: value;</p>
                            <p>}</p>
                        `
                    }}></div>
                </div>
                <p>
                    Where, <strong>value</strong> can be a named color (like <strong>`red`</strong>), a HEX value (like <strong>`#ff0000`</strong>), an RGB or RGBA value (like <strong>`rgb(255, 0, 0)`</strong> or <strong>`rgba(255, 0, 0, 0.8)`</strong>), an HSL or HSLA value (like <strong>`hsl(0, 100%, 50%)`</strong> or <strong>`hsla(0, 100%, 50%, 0.8)`</strong>), or a global value like <strong>`inherit`</strong>.
                </p>

                <h5 className="mt-5 mb-3">How A Text Color Looks Like</h5>

                <div className='outputPalateBox mb-4 mt-3'>
                    <div className='outputPalate' dangerouslySetInnerHTML={{
                        __html: `
                        <style>
                            h2{
                                color: green;
                                text-align: center;
                            }
                            .myTextColor{
                                color: blue;
                                text-align: center;
                            }
                        </style>
                        <h2>Heading</h2>
                        <p class="myTextColor">Some text under paragraph to show how a color in text looks like. This text is blue in color. You can see the heading is in green color. We can use different color in different section using selectors.</p>
                        `
                    }}></div>
                </div>

                <h5 className="mt-5 mb-3">Examples : How We Use Color Property</h5>
                <p>
                    Lets discuss the above example - 
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;style&gt;</p>
                        <p class="ml-30">    h2{</p>
                        <p class="ml-60">        <span class="color-green">color</span>: green;</p>
                        <p class="ml-60">        <span class="color-green">text-align</span>: center;</p>
                        <p class="ml-30">    }</p>
                        <p class="ml-30">    .myTextColor{</p>
                        <p class="ml-60">        <span class="color-green">color</span>: blue;</p>
                        <p class="ml-60">        <span class="color-green">text-align</span>: center;</p>
                        <p class="ml-30">    }</p>
                        <p>&lt;/style&gt;</p>
                        <p>&lt;h2&gt;Heading&lt;/h2&gt;</p>
                        <p>&lt;p <span class="color-pink">class</span>=<span class="color-green">"myTextColor"</span>&gt;</p>
                        <p class="ml-30">Some text under paragraph to show how a color in text looks like. This text is blue in color. You can see the heading is in green color. We can use different color in different section using selectors.</p>
                        <p>&lt;/p&gt;</p>
                        `
                    }}></div>
                </div>
                <p>
                    Here, The heading <strong>"Heading"</strong> will appear in <strong>`<span style={{color:'green'}}>green</span>`</strong> and will be centered on the page and The paragraph text <strong>"Some text under paragraph..."</strong> will appear in <strong>`<span style={{color:'blue'}}>blue</span>`</strong> and will also be centered.
                </p>
            </div>
        </section>
    )
}