import React, { useEffect, useState, useRef } from 'react'
import { Outlet, Link } from "react-router-dom";
import TopHeader from '../TopHeader';
import Footer from '../Footer'
import { MenuContext, TitleContext } from "../../Context";
import { useContext } from "react";

export default function MachineLearningLayout() {

    const addspaceElement = useRef();
    const menuElement = useRef([]);
    const menu = useContext(MenuContext);
    const [pathname, setPathName] = useState();

    useEffect(() => {
        setPathName(window.location.pathname)
        if (menu.isMenu == true) menu.setMenuOpen(false)
        addspaceElement.current.scrollIntoView(true)
        const element = document.getElementsByClassName("activeMenu")[0];
        element && element.scrollIntoView(true);
    }, [pathname])

    return (
        <>
            <TopHeader />
            <div className='container-fluid'>
                <div className='row'>
                    <div ref={menuElement} className={menu.isMenu == true ? 'col-md-3 col-lg-2 menuColumnMobile menuColumn p-0' : 'col-md-3 col-lg-2 menuColumn p-0'} style={{ 'overflowY': 'scroll', height: 'calc(100vh - 110px)' }}>
                        <div className='headerMenu'>
                            Node Js Tutorial
                        </div>
                        <nav>
                            <ul>
                                <li className={pathname == '/node-js/introduction' ? 'activeMenu' : ''}>
                                    <Link to="/node-js/introduction" onClick={() => setPathName('/node-js/introduction')}>Introduction</Link>
                                </li>
                            </ul>
                        </nav>
                    </div>
                    <div className='col-12 col-md-9 col-lg-10 rightPart' style={{ 'overflowY': 'scroll', height: 'calc(100vh - 110px)' }}>
                        <div className='row'>
                            <div className='col-12 col-md-12 col-lg-10 col-lg-9 mb-5'>
                                <div className='headerAdSpace' ref={addspaceElement}>
                                    <ins className="adsbygoogle"
                                        style={{ 'display': 'block' }}
                                        data-ad-client="ca-pub-4667727216643481"
                                        data-ad-slot="8913905287"
                                        data-ad-format="auto"
                                        data-full-width-responsive="true"></ins>
                                    <script>
                                        (adsbygoogle = window.adsbygoogle || []).push({ });
                                    </script>
                                </div>
                                <div className='container'>
                                    <Outlet />
                                </div>
                                <div className='footerAdSpace'>
                                    <ins className="adsbygoogle"
                                        style={{ 'display': 'block' }}
                                        data-ad-client="ca-pub-4667727216643481"
                                        data-ad-slot="6714530623"
                                        data-ad-format="auto"
                                        data-full-width-responsive="true"></ins>
                                    <script>
                                        (adsbygoogle = window.adsbygoogle || []).push({ });
                                    </script>
                                </div>
                            </div>
                            <div className='col-lg-3 hiddenInSmall'>
                                <ins className="adsbygoogle"
                                    style={{ 'display': 'block' }}
                                    data-ad-client="ca-pub-4667727216643481"
                                    data-ad-slot="1035415263"
                                    data-ad-format="auto"
                                    data-full-width-responsive="true"></ins>
                                <script>
                                    (adsbygoogle = window.adsbygoogle || []).push({ });
                                </script>
                            </div>
                            <div className='col-12'>
                                <div className='container-fluid footerPart'>
                                    <Footer />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
