import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';
export const pageUrl = () => "/mongo-db/mongoose/introduction";

export default function MongooseIntroduction() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("An Introduction of Mongoose | A Complete Guide");
        title.setKeyWords("mongoose introduction, mongoose examples, key feature of mongoose");
        title.setPageDescription("Mongoose is an Object Data Modeling (ODM) library for MongoDB and Node.js. It provides a straightforward, schema-based solution to model your application data, making it easier to work with MongoDB in a Node.js environment. Mongoose handles the complexities of MongoDB's data interactions and allows developers to define schemas and models for their data, enforce data validation, and utilize various built-in methods for querying and manipulating data.");
        const urls = {
            'previous': '/mongo-db/aggregation',
            'next': '/mongo-db/mongoose/get-started'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>Mongoose - Introduction</h3>
            <div className='mt-4 mb-5'>
                <p>
                    Mongoose is an Object Data Modeling (ODM) library for MongoDB and Node.js. It provides a straightforward, schema-based solution to model your application data, making it easier to work with MongoDB in a Node.js environment. Mongoose handles the complexities of MongoDB's data interactions and allows developers to define schemas and models for their data, enforce data validation, and utilize various built-in methods for querying and manipulating data.
                </p>
                <h5 className='mt-5'>Key Features of Mongoose</h5>
                <ul className='mt-4' style={{listStyle:'decimal'}}>
                    <li className='mb-4'>
                        <strong>Schemas</strong>: Mongoose schemas define the structure of your documents and can enforce data validation rules. A schema maps directly to a MongoDB collection and defines the shape of the documents within that collection.
                        <h6 className='mt-4'>Example:</h6>
                        <div className='codePalateBox mt-2 mb-2'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-blue">const</span> mongoose = <span class="color-yellow">require</span>(<span class="color-green">'mongoose'</span>);</p>
                                    <p><span class="color-blue">const</span> <span class="color-red">Schema</span> = mongoose.Schema;</p>
                                    <br />
                                    <p><span class="color-blue">const</span> userSchema = <span class="color-blue">new</span> <span class="color-red">Schema</span>({</p>
                                    <p style="margin-left:30px">    <span class="color-pink">name</span>: { <span class="color-pink">type</span>: <span class="color-red">String</span>, <span class="color-pink">required</span>: <span class="color-blue">true</span> },</p>
                                    <p style="margin-left:30px">    <span class="color-pink">email</span>: { <span class="color-pink">type</span>: <span class="color-red">String</span>, <span class="color-pink">required</span>: <span class="color-blue">true</span>, <span class="color-pink">unique</span>: <span class="color-blue">true</span> },</p>
                                    <p style="margin-left:30px">    <span class="color-pink">age</span>: { <span class="color-pink">type</span>: <span class="color-red">Number</span>, <span class="color-pink">min</span>: <span class="color-pink">0</span> }</p>
                                    <p>});</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li className='mb-4'>
                        <strong>Models</strong>: Models are constructors compiled from Mongoose schemas. Instances of models represent documents that can be saved and retrieved from your MongoDB database.
                        <h6 className='mt-4'>Example:</h6>
                        <div className='codePalateBox mt-2 mb-2'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-blue">const</span> <span class="color-red">User</span> = mongoose.<span class="color-red">model</span>(<span class="color-green">'User'</span>, userSchema);</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li className='mb-4'>
                        <strong>Queries</strong>: Mongoose provides a rich API for querying your MongoDB collections, allowing for complex filtering, sorting, and aggregation operations.
                        <h6 className='mt-4'>Example:</h6>
                        <div className='codePalateBox mt-2 mb-2'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-red">User</span>.<span class="color-red">find</span>({ <span class="color-pink">age</span>: { <span class="color-pink">$gt</span>: <span class="color-pink">18</span> } }, <span class="color-green">'name email'</span>, (err, users) => {</p>
                                    <p style="margin-left:30px">    <span class="color-blue">if</span> (err) <span class="color-pink">console</span>.<span class="color-red">log</span>(err);</p>
                                    <p style="margin-left:30px">    <span class="color-blue">else</span> <span class="color-pink">console</span>.<span class="color-red">log</span>(users);</p>
                                    <p>});</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li className='mb-4'>
                        <strong><Link className='noUnderline' to="/mongo-db/mongoose/validation" onClick={() => path.setPathName('/mongo-db/mongoose/validation')}>Validation</Link></strong>: Mongoose schemas can enforce validation rules to ensure that the data stored in the database meets specific criteria.
                        <h6 className='mt-4'>Example:</h6>
                        <div className='codePalateBox mt-2 mb-2'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p>userSchema.<span class="color-red">path</span>(<span class="color-green">'email'</span>).<span class="color-red">validate</span>((email) => {</p>
                                    <p style="margin-left:30px">    <span class="color-blue">return</span> <span class="color-green">/\S+@\S+\.\S+/</span>.<span class="color-red">test</span>(email);</p>
                                    <p>}, <span class="color-green">'Invalid email format.'</span>);</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li className='mb-4'>
                        <strong><Link className='noUnderline' to="/mongo-db/mongoose/middleware" onClick={() => path.setPathName('/mongo-db/mongoose/middleware')}>Middleware</Link></strong>: Mongoose supports middleware, also known as pre and post hooks, which allow you to perform actions before or after certain operations (e.g., saving a document).
                        <h6 className='mt-4'>Example:</h6>
                        <div className='codePalateBox mt-2 mb-2'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p>userSchema.<span class="color-red">pre</span>(<span class="color-green">'save'</span>, function(next) {</p>
                                    <p style="margin-left:30px">    <span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">'A user is about to be saved.'</span>);</p>
                                    <p style="margin-left:30px">    <span class="color-red">next</span>();</p>
                                    <p>});</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li className='mb-4'>
                        <strong><Link className='noUnderline' to="/mongo-db/mongoose/population" onClick={() => path.setPathName('/mongo-db/mongoose/population')}>Population</Link></strong>: Mongoose allows you to reference documents in other collections and populate these references with actual data.
                        <h6 className='mt-4'>Example:</h6>
                        <div className='codePalateBox mt-2 mb-2'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-blue">const</span> postSchema = <span class="color-blue">new</span> <span class="color-red">Schema</span>({</p>
                                    <p style="margin-left:30px">    <span class="color-pink">title</span>: <span class="color-red">String</span>,</p>
                                    <p style="margin-left:30px">    <span class="color-pink">author</span>: { <span class="color-pink">type</span>: <span class="color-red">Schema</span>.Types.ObjectId, <span class="color-pink">ref</span>: <span class="color-green">'User'</span> }</p>
                                    <p>});</p>
                                    <br />
                                    <p><span class="color-red">Post</span>.<span class="color-red">find</span>().<span class="color-red">populate</span>(<span class="color-green">'author'</span>).<span class="color-red">exec</span>((err, posts) => {</p>
                                    <p style="margin-left:30px">    <span class="color-blue">if</span> (err) <span class="color-pink">console</span>.<span class="color-red">log</span>(err);</p>
                                    <p style="margin-left:30px">    <span class="color-blue">else</span> <span class="color-pink">console</span>.<span class="color-red">log</span>(posts);</p>
                                    <p>});</p>
                                `
                            }}></div>
                        </div>
                    </li>
                </ul>
            </div>
        </section>
    )
}