import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../../Context";
import { Link } from 'react-router-dom';
export const pageUrl = () => "/mysql/arithmetic-operators/integer-division";

export default function IntegerDivision() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Mysql - Arithmetic Operators - Integer Division (DIV) | Aspirant's Home");
        path.setPreviousNext({});
    }, [])

    return (
        <>
            <section class='clearfix mb-3'>
                <span className='float-start'>
                    <Link to='/mysql/arithmetic-operators' onClick={() => path.setPathName('/mysql/arithmetic-operators')} className='btn btn-primary'> <i class="fa-solid fa-less-than"></i>&nbsp; Back to Arithmetic Operators </Link>
                </span>
            </section>
            <section className='mt-5 mb-5'>
                <h3>Mysql - Integer Division (DIV) Operator</h3>
                <div className='mt-4 mb-5'>
                    <p>
                        In MySQL, the <strong>`DIV`</strong> operator is used for integer division. Unlike the <strong>`/`</strong> operator, which performs standard division and returns a decimal or floating-point result, <strong>`DIV`</strong> returns the integer quotient of the division, discarding any remainder.
                    </p>
                    <p>
                        Here is a basic example using the <strong>`DIV`</strong> operator in a <strong>`SELECT`</strong> query:
                    </p>
                    <div className='codePalateBox mt-2 mb-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                                <p><span class="color-blue">SELECT</span> <span class="color-pink">10</span> DIV <span class="color-pink">3</span>;</p>
                            `
                        }}></div>
                    </div>
                    <p>
                        This query will return the result of the integer division of 10 by 3, which is 3 (since 10 divided by 3 equals 3 with a remainder of 1, and <strong>`DIV`</strong> discards the remainder).
                    </p>
                </div>
                <div className='mt-5 mb-5'>
                    <h5>Integer Division (DIV) with MySQL Table</h5>
                    <div className='mt-3 mb-2'>
                        <p>
                            Consider a table <strong>`orders`</strong> with columns <strong>`order_id`</strong>, <strong>`total_amount`</strong>, and <strong>`number_of_items`</strong>.
                        </p>
                        <table className='table' style={{border: '1px dashed #ccc'}}> 
                            <thead>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>order_id</th>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>total_amount</th>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>number_of_items</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>1</td>
                                    <td style={{border: '1px dashed #ccc'}}>1000</td>
                                    <td style={{border: '1px dashed #ccc'}}>3</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>2</td>
                                    <td style={{border: '1px dashed #ccc'}}>1500</td>
                                    <td style={{border: '1px dashed #ccc'}}>4</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>3</td>
                                    <td style={{border: '1px dashed #ccc'}}>750</td>
                                    <td style={{border: '1px dashed #ccc'}}>2</td>
                                </tr>
                            </tbody>
                        </table>
                        <p>
                            If you want to calculate the integer division of <strong>`total_amount`</strong> by <strong>`number_of_items`</strong> (which gives you the quotient without the remainder), you can use the DIV operator as follows:
                        </p>
                        <div className='codePalateBox mt-2 mb-2'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-blue">SELECT</span> order_id, total_amount, number_of_items, (total_amount DIV number_of_items) <span class="color-blue">AS</span> average_amount_per_item
                                    <span class="color-blue">FROM</span> orders;</p>
                                `
                            }}></div>
                        </div>
                        <p>
                            This query will return:
                        </p>
                        <table className='table' style={{border: '1px dashed #ccc'}}> 
                            <thead>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>order_id</th>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>total_amount</th>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>number_of_items</th>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>average_amount_per_item</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>1</td>
                                    <td style={{border: '1px dashed #ccc'}}>1000</td>
                                    <td style={{border: '1px dashed #ccc'}}>3</td>
                                    <td style={{border: '1px dashed #ccc'}}>333</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>2</td>
                                    <td style={{border: '1px dashed #ccc'}}>1500</td>
                                    <td style={{border: '1px dashed #ccc'}}>4</td>
                                    <td style={{border: '1px dashed #ccc'}}>375</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>3</td>
                                    <td style={{border: '1px dashed #ccc'}}>750</td>
                                    <td style={{border: '1px dashed #ccc'}}>2</td>
                                    <td style={{border: '1px dashed #ccc'}}>375</td>
                                </tr>
                            </tbody>
                        </table>
                        <p>
                            In this example, the expression <strong>`(total_amount DIV number_of_items)`</strong> uses the integer division operator (DIV) to divide the <strong>`total_amount`</strong> by the <strong>`number_of_items`</strong> for each row, calculating the average amount per item as an integer. The result is displayed in a new column named <strong>`average_amount_per_item`</strong>.
                        </p>
                    </div>
                </div>
            </section>
        </>
    )
}