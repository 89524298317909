import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/react-js/redux/dispatching-an-action";

export default function ReduxDispatchAction() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("React - Redux - Dispatching An Action | Aspirant's Home");
        const urls = {
            'previous': '/react-js/redux/create-a-store',
            'next': '/react-js/redux-vs-context-api'
        }
        path.setPreviousNext(urls);
    },[])

    return (
        <section className='mt-5 mb-5'>
            <h3>Dispatching An Action In Redux</h3>
            <div className='mt-4 mb-5'>
                <p>
                    <strong>`Actions`</strong> are the sole source of information for the store in Redux, as per the official documentation. They carry a payload of information from your application to the store. Actions are plain JavaScript objects that must have a type attribute to indicate the <strong>`type`</strong> of action performed. They inform us about what has happened in the application.
                </p>
            </div>
            <div className='mt-5 mb-5'>
                <h5>Action Types & Creators</h5>
                <div className='mt-2 mb-2'>
                    <p>
                        In Redux, action types and action creators are two concepts that help manage actions in a more organized way.
                    </p>
                    <p>
                        <strong>Action Types:</strong> Action types are string constants that represent the type of action being performed in your application. They are typically defined as variables to avoid typos and make it easier to refactor action types. For example:
                        <div className='codePalateBox mt-2 mb-2'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-grey">// Define action types as constants</span></p>
                                    <p><span class="color-blue">const</span> <span class="color-pink">INCREMENT</span> = <span class="color-green">'INCREMENT'</span>;</p>
                                    <p><span class="color-blue">const</span> <span class="color-pink">DECREMENT</span> = <span class="color-green">'DECREMENT'</span>;</p>
                                `
                            }}></div>
                        </div>
                    </p>
                    <p>
                        <strong>Action Creators:</strong> Action creators are functions that create and return action objects. They encapsulate the logic of creating actions and make it easier to create actions with consistent shapes. For example:
                        <div className='codePalateBox mt-2 mb-2'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-grey">// Action creators</span></p>
                                    <p><span class="color-blue">const</span> <span class="color-red">increment</span> = () => ({</p>
                                    <p style="margin-left:30px">    <span class="color-pink">type</span>: INCREMENT,</p>
                                    <p>});</p>
                                    <br />
                                    <p><span class="color-blue">const</span> <span class="color-red">decrement</span> = () => ({</p>
                                    <p style="margin-left:30px">    <span class="color-pink">type</span>: DECREMENT,</p>
                                    <p>});</p>
                                `
                            }}></div>
                        </div>
                    </p>
                </div>
            </div>
            <div className='mt-5 mb-4'>
                <h5>Dispatch Actions in Components</h5>
                <div className='mt-2 mb-2'>
                    <p>
                        To dispatch actions in React components, you typically use the <strong>`useDispatch`</strong> hook provided by React Redux. Here's an example of how you might dispatch actions in a component:
                        <div className='codePalateBox mt-2 mb-2'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-blue">import</span> <span class="color-red">React</span> <span class="color-blue">from</span> <span class="color-green">'react'</span>;</p>
                                    <p><span class="color-blue">import</span> { <span class="color-red">useDispatch</span>, <span class="color-red">useSelector</span> } <span class="color-blue">from</span> <span class="color-green">'react-redux'</span>;</p>
                                    <p><span class="color-blue">import</span> { increment, decrement } <span class="color-blue">from</span> <span class="color-green">'../actions/counterActions'</span>;</p>
                                    <br />
                                    <p><span class="color-blue">function</span> <span class="color-red">Counter</span> = () => {</p>
                                    <p style="margin-left:30px">    <span class="color-blue">const</span> dispatch = <span class="color-red">useDispatch</span>();</p>
                                    <p style="margin-left:30px">    <span class="color-blue">const</span> count = <span class="color-red">useSelector</span>((state) => state.counter);</p>
                                    <br />
                                    <p style="margin-left:30px">    <span class="color-blue">return</span> (</p>
                                    <p style="margin-left:60px">        &lt;div&gt;</p>
                                    <p style="margin-left:90px">            &lt;h1&gt;Counter: {count}&lt;/h1&gt;</p>
                                    <p style="margin-left:90px">            &lt;button <span class="color-pink">onClick</span>={() => dispatch(increment())}&gt;Increment&lt;/button&gt;</p>
                                    <p style="margin-left:90px">            &lt;button <span class="color-pink">onClick</span>={() => dispatch(decrement())}&gt;Decrement&lt;/button&gt;</p>
                                    <p style="margin-left:60px">        &lt;/div&gt;</p>
                                    <p style="margin-left:30px">    );</p>
                                    <p>};</p>
                                    <br />
                                    <p><span class="color-blue">export default</span> <span class="color-red">Counter</span>;</p>
                                
                                `
                            }}></div>
                        </div>
                    </p>
                </div>
            </div>
        </section>
    )
}