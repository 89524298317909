import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/css/selectors";

export default function CSSSelectors() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("What is Selector in CSS ? | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/css/syntax',
            'next': '/css/how-to-use'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <div className='mt-4 mb-5'>
                <h3>What is a Selector in CSS?</h3>
                <p className='mt-4 mb-4'>
                    A CSS selector is a pattern which is used to select the elements you want to style. Selectors are basically target HTML elements and apply CSS properties to them.
                </p>
                <h5 className='mt-4'>Use of Selector</h5>
                <p>
                    Selectors are essential in CSS because they define in which HTML elements a set of CSS rules will apply to. They can be used to style elements based on their tag name, class, ID, attributes, and even their state or position in the document structure.
                </p>
                <h5 className='mt-4 mb-4'>Types of Selector</h5>
                <p>
                    Mainly there are 5 types of selectors - 
                </p>
                <ul className='mt-4' style={{ listStyle: 'disc' }}>
                    <li>
                        <strong>Name / Id / Class Selector</strong> <br />
                        <span className='ml-30'>- This selector selects elements by its name, id or class.</span>
                    </li>
                    <li>
                        <strong><Link to="/css/combinators" className='noUnderline'>Combinator Selector</Link></strong> <br />
                        <span className='ml-30'>- This selector select elements based on a relationship between them.</span>
                    </li>
                    <li>
                        <strong><Link to="/css/pseudo-class" className='noUnderline'>Pseudo-class Selector</Link></strong> <br />
                        <span className='ml-30'>- This selector select elements based on a certain state like hover, active, focus.</span>
                    </li>
                    <li>
                        <strong><Link to="/css/pseudo-element" className='noUnderline'>Pseudo-element Selector</Link></strong> <br />
                        <span className='ml-30'>- This selector select and style a part of an element like first-letter, first-line etc.</span>
                    </li>
                    <li>
                        <strong><Link to="/css//attr/selectors" className='noUnderline'>Attribute Selector</Link></strong> <br />
                        <span className='ml-30'>- This selector select elements based on an attribute or attribute value.</span>
                    </li>
                </ul>

                <h5 className='mt-4 mb-4'>CSS `element` Selector</h5>
                <p>
                    The CSS element selector (also known as a type selector) is used to select HTML elements based on their tag name. This selector applies styles to all instances of a specified element in a webpage.
                </p>
                <h6>Syntax</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>element {</p>
                            <p class="ml-30"> property: value;</p>
                            <p>}</p>
                        `
                    }}></div>
                </div>
                <p>
                    Where,
                </p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><strong>element</strong> is the HTML tag name you want to style.</li>
                    <li><strong>property</strong> is the CSS property you want to apply.</li>
                    <li>and <strong>value</strong> is the value of the CSS property.</li>
                </ul>
                <p>
                    Element selectors are often used for general styling of common HTML elements, such as paragraphs, headings, lists, and divs. They are straightforward and provide a broad way to apply styles to multiple elements at once.
                </p>
                <h6>Example</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>p {</p>
                            <p class="ml-30">    <span class="color-green">font-size</span>: <span class="color-pink">16px</span>;</p>
                            <p class="ml-30">    <span class="color-green">line-height</span>: <span class="color-pink">1.5</span>;</p>
                            <p class="ml-30">    <span class="color-green">color</span>: <span class="color-pink">#333</span>;</p>
                            <p>}</p>
                        `
                    }}></div>
                </div>
                <p>
                    Above rule applies the specified styles to all <strong>`&lt;p&gt;`</strong> elements, setting the font size, line height, and text color.
                </p>

                <h5 className='mt-4 mb-4'>CSS `id` Selector</h5>
                <p>
                    The CSS ID selector is used to style a single, unique element in the HTML document. The ID selector is identified by a hash (<strong>`#`</strong>) followed by the ID value assigned to an HTML element. Since IDs must be unique within a document, the ID selector is very specific and powerful.
                </p>
                <h6>Syntax</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>#id {</p>
                            <p class="ml-30"> property: value;</p>
                            <p>}</p>
                        `
                    }}></div>
                </div>
                <p>
                    Where,
                </p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><strong>#id</strong> is the ID value prefixed by a hash symbol (<strong>`#`</strong>).</li>
                    <li><strong>property</strong> is the CSS property you want to apply.</li>
                    <li>and <strong>value</strong> is the value of the CSS property.</li>
                </ul>
                <p>
                    The ID selector is commonly used to style elements that need to be <strong><i>uniquely</i></strong> identified, such as specific sections, headers, or elements requiring <strong><i>unique</i></strong> behavior or appearance.
                </p>
                <h6>Example</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>#about {</p>
                            <p class="ml-30">    <span class="color-green">padding</span>: <span class="color-pink">50px</span>;</p>
                            <p class="ml-30">    <span class="color-green">background-color</span>: <span class="color-pink">#f9f9f9</span>;</p>
                            <p class="ml-30">    <span class="color-green">border</span>: <span class="color-pink">1px</span> solid <span class="color-pink">#ddd</span>;</p>
                            <p>}</p>
                        `
                    }}></div>
                </div>
                <p>
                    This rule applies the specified styles to the element with the ID <strong>`about`</strong>.
                </p>

                <h5 className='mt-4 mb-4'>CSS `class` Selector</h5>
                <p>
                    The CSS class selector is used to apply styles to multiple elements that share the same class attribute. Unlike IDs, classes are not unique and can be reused on multiple elements, making class selectors highly versatile and commonly used in web development.
                </p>
                <h6>Syntax</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>.class {</p>
                            <p class="ml-30"> property: value;</p>
                            <p>}</p>
                        `
                    }}></div>
                </div>
                <p>
                    Where,
                </p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><strong>.class</strong> is the class name prefixed by a dot symbol (<strong>`.`</strong>).</li>
                    <li><strong>property</strong> is the CSS property you want to apply.</li>
                    <li>and <strong>value</strong> is the value of the CSS property.</li>
                </ul>
                <p>
                    Class selectors are ideal for styling multiple elements in a consistent manner. They are commonly used to apply shared styles, such as for buttons, form controls, layout components, and more.
                </p>
                <h6>Example</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>.highlight {</p>
                            <p class="ml-30">    <span class="color-green">background-color</span>: yellow;</p>
                            <p class="ml-30">    <span class="color-green">font-weight</span>: bold</p>
                            <p>}</p>
                        `
                    }}></div>
                </div>
                <p>
                    This rule applies the specified styles to all elements with the class <strong>`highlight`</strong>.
                </p>
            </div>
        </section>
    )
}