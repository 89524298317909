import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../../Context";
import { Link } from 'react-router-dom';
export const pageUrl = () => "/node-js/built-in-modules/url";

export default function UrlModule() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("NodeJs - Url Module | Aspirant's Home");
        path.setPreviousNext({});
    }, [])
    
    return (
        <div className='contentPage'>
            <section class='clearfix mb-3'>
                <span className='float-start'>
                    <Link to='/node-js/built-in-modules' onClick={() => path.setPathName('/node-js/built-in-modules')} className='btn btn-primary'> <i class="fa-solid fa-less-than"></i>&nbsp; Back to Built-In Modules </Link>
                </span>
            </section>
            <section className='mt-5 mb-5'>
                <h3>Node Js Url Module</h3>
                <div className='mt-4'>
                    <p>The url module provides utilities for URL resolution and parsing. It can be used to manipulate URLs and extract various components such as the protocol, hostname, path, query parameters, etc.</p>
                    <p>The syntax for including the url module in your application: </p>
                    <div className='codePalateBox mt-2 mb-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `<p><span class='color-blue'>var</span> url = <span class='color-yellow'>require</span>(<span class='color-green'>'url'</span>);</p>
                                    `
                        }}></div>
                    </div>
                </div>
                <div className='mt-4'>
                    <h5>Examples</h5>
                    <p>Here's a basic example of how you might use the url module:</p>
                    <div className='codePalateBox mt-2 mb-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `<p><span class='color-blue'>const</span> url = <span class='color-yellow'>require</span>(<span class='color-green'>'url'</span>);</p>
                            <br />
                            <p><span class='color-blue'>const</span> urlString = <span class='color-green'>'https://www.example.com/path?query=value'</span>;</p>
                            <p><span class='color-blue'>const</span> parsedUrl = url.<span class='color-red'>parse</span>(urlString);</p>
                            <br />
                            <p><span class='color-pink'>console</span>.<span class='color-red'>log</span>(parsedUrl);</p>`
                        }}></div>
                    </div>
                    <p>Which will give you this result:</p>
                    <div className='codePalateBox mt-2 mb-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `<p>{</p>
                                <p style="margin-left:30px"><span class='color-pink'>protocol</span>: <span class='color-green'>'https:'</span>,</p>
                                <p style="margin-left:30px"><span class='color-pink'>slashes</span>: <span class='color-green'>true</span>,</p>
                                <p style="margin-left:30px"><span class='color-pink'>auth</span>: <span class='color-green'>null</span>,</p>
                                <p style="margin-left:30px"><span class='color-pink'>host</span>: <span class='color-green'>'www.example.com'</span>,</p>
                                <p style="margin-left:30px"><span class='color-pink'>port</span>: <span class='color-green'>null</span>,</p>
                                <p style="margin-left:30px"><span class='color-pink'>hostname</span>: <span class='color-green'>'www.example.com'</span>,</p>
                                <p style="margin-left:30px"><span class='color-pink'>hash</span>: <span class='color-green'>null</span>,</p>
                                <p style="margin-left:30px"><span class='color-pink'>search</span>: <span class='color-green'>'?query=value'</span>,</p>
                                <p style="margin-left:30px"><span class='color-pink'>query</span>: <span class='color-green'>'query=value'</span>,</p>
                                <p style="margin-left:30px"><span class='color-pink'>pathname</span>: <span class='color-green'>'/path'</span>,</p>
                                <p style="margin-left:30px"><span class='color-pink'>path</span>: <span class='color-green'>'/path?query=value'</span>,</p>
                                <p style="margin-left:30px"><span class='color-pink'>href</span>: <span class='color-green'>'https://www.example.com/path?query=value'</span></p>
                              <p>}</p>
                              `
                        }}></div>
                    </div>
                </div>
                <div className='mt-4'>
                    <p>Here are the others methods of url module - </p>
                    <div className='mt-4'>
                        <p><strong>`url.format()`</strong> -	The url.format() method is used to serialize a URL object into a URL string. This method takes a URL object as an argument and returns the corresponding URL string.</p>
                        <p>Here's a basic example of how you might use the url.format() module:</p>
                        <div className='codePalateBox mt-2 mb-2'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `<p><span class='color-blue'>const</span> url = <span class='color-yellow'>require</span>(<span class='color-green'>'url'</span>);</p>
                                    <br />  
                            <p><span class='color-blue'>const</span> urlObject = {</p>
                            <p style="margin-left:30px">  <span class='color-pink'>protocol</span>: <span class='color-green'>'https:'</span>,</p>
                            <p style="margin-left:30px">  <span class='color-pink'>hostname</span>: <span class='color-green'>'www.example.com'</span>,</p>
                            <p style="margin-left:30px">  <span class='color-pink'>port</span>: <span class='color-green'>8000</span>,</p>
                            <p style="margin-left:30px">  <span class='color-pink'>pathname</span>: <span class='color-green'>'/path/to/resource'</span>,</p>
                            <p style="margin-left:30px">  <span class='color-pink'>search</span>: <span class='color-green'>'query=value'</span></p>
                            <p>};</p>
                            <br />
                            <p><span class='color-blue'>const</span> urlString = url.<span class='color-red'>format</span>(urlObject);</p>
                            <p><span class='color-pink'>console</span>.<span class='color-red'>log</span>(urlString);</p>
                            `
                            }}></div>
                        </div>
                        <p>Which will give you this result:</p>
                        <div className='codePalateBox mt-2 mb-2'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `<p class='color-green'>'https://www.example.com:8000/path/to/resource?query=value'</p>`
                            }}></div>
                        </div>
                    </div>
                    <div className='mt-5'>
                        <p><strong>`url.resolve()`</strong> - The <strong>`url.resolve()`</strong> method is used to resolve a target URL relative to a base URL. It takes two arguments: the base URL and the target URL, and returns the resolved URL string.</p>
                        <p>Here's a basic example of how you might use the <strong>`url.resolve()`</strong> module:</p>
                        <div className='codePalateBox mt-2 mb-2'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `<p><span class='color-blue'>const</span> url = <span class='color-yellow'>require</span>(<span class='color-green'>'url'</span>);</p>
                                <br />
                                <p><span class='color-blue'>const</span> baseUrl = <span class='color-green'>'https://www.example.com/base/'</span>;</p>
                                <p><span class='color-blue'>const</span> relativeUrl = <span class='color-green'>'/path/to/resource'</span>;</p>
                                <br />
                                <p><span class='color-blue'>const</span> resolvedUrl = url.<span class='color-red'>resolve</span>(baseUrl, relativeUrl);</p>
                                <p><span class='color-pink'>console</span>.<span class='color-red'>log</span>(resolvedUrl);</p>                             
                            `
                            }}></div>
                        </div>
                        <p>Which will give you this result:</p>
                        <div className='codePalateBox mt-2 mb-2'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `<p class='color-green'>'https://www.example.com/path/to/resource'</p>`
                            }}></div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}
