import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/css/background-origin";

export default function CSSBackgroundOrigin() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Background Origin in CSS | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/css/background-position',
            'next': '/css/background-size'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>Background Origin</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <strong>`background-origin`</strong> property in CSS specifies the positioning area of the background image. This property defines the area within which the background image is placed and repeated.
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>selector {</p>
                            <p class="ml-30">    <span class="color-green">background-origin</span>: value;</p>
                            <p>}</p>
                        `
                    }}></div>
                </div>
                <h5 className="mt-5 mb-3">How Its Works</h5>
                <img className="img-fluid" src='../assets/css/background-origin.png' />

                <h5 className="mt-5 mb-3">Commonly Used Values and Their Uses</h5>
                <p>
                    There are many <strong>`background-origin`</strong> property values. They are -
                </p>
                <ul style={{ 'listStyle': 'decimal' }}>
                    <li>
                        <strong>`border-box`</strong>
                        <p>The background image starts from the upper left corner of the border box (the area including the border). This is the default value.</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p>div {</p>
                                    <p class="ml-30">   <span class="color-green">background-image</span>: <span class="color-yellow">url</span>(<span class="color-green">'pattern.png'</span>);</p>
                                    <p class="ml-30">    <span class="color-green">background-origin</span>: border-box;</p>
                                    <p>}</p>
                                `
                            }}></div>
                        </div>
                        <div className='outputPalateBox mb-4 mt-3'>
                            <div className='outputPalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <style>
                                        #example2 {
                                            border: 10px dashed black;
                                            padding: 25px;
                                            background: url('../../assets/small-box.png');
                                            background-repeat: no-repeat;
                                            background-origin: border-box;
                                            height: 300px;
                                        }
                                    </style>
                                    <div id="example2">
                                        <h2>Border Box</h2>
                                        <p>The background image starts from the upper left corner of the border.</p>
                                    </div>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <strong>`padding-box`</strong>
                        <p>The background image starts from the upper left corner of the padding box (the area inside the padding, excluding the border).</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p>div {</p>
                                    <p class="ml-30">   <span class="color-green">background-image</span>: <span class="color-yellow">url</span>(<span class="color-green">'pattern.png'</span>);</p>
                                    <p class="ml-30">    <span class="color-green">background-origin</span>: padding-box;</p>
                                    <p>}</p>
                                `
                            }}></div>
                        </div>
                        <div className='outputPalateBox mb-4 mt-3'>
                            <div className='outputPalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <style>
                                        #example1 {
                                            border: 10px dashed black;
                                            padding: 25px;
                                            background: url('../../assets/small-box.png');
                                            background-repeat: no-repeat;
                                            background-origin: padding-box;
                                            height: 300px;
                                        }
                                    </style>
                                    <div id="example1">
                                        <h2>Padding Box</h2>
                                        <p>The background image starts from the upper left corner of the padding edge.</p>
                                    </div>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <strong>`content-box`</strong>
                        <p>The background image starts from the upper left corner of the content box (the area inside the padding, excluding the padding and border).</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p>div {</p>
                                    <p class="ml-30">   <span class="color-green">background-image</span>: <span class="color-yellow">url</span>(<span class="color-green">'pattern.png'</span>);</p>
                                    <p class="ml-30">    <span class="color-green">background-origin</span>: content-box;</p>
                                    <p>}</p>
                                `
                            }}></div>
                        </div>
                        <div className='outputPalateBox mb-4 mt-3'>
                            <div className='outputPalate' dangerouslySetInnerHTML={{
                                __html: `
                                <style>
                                    #example3 {
                                        border: 10px dashed black;
                                        padding: 25px;
                                        background: url('../../assets/small-box.png');
                                        background-repeat: no-repeat;
                                        background-origin: content-box;
                                        height: 300px;
                                    }
                                </style>
                                <div id="example3">
                                    <h2>Content Box</h2>
                                    <p>The background image starts from the upper left corner of the content.</p>
                                </div>
                                `
                            }}></div>
                        </div>
                    </li>
                </ul>
            </div>
        </section>
    )
}