import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';
export const pageUrl = () => "/mongo-db/aggregation/limit";

export default function MongoAggregationLimit() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("$limit Aggregation Pipelines in MongoDB");
        title.setKeyWords("$limit aggregation pipeline in mongodb, aggregation pipeline limit in mongodb, mongodb aggregation pipeline examples, aggregation in mongodb examples, mongodb aggregation pipeline stages examples, mongodb aggregation pipeline skip limit, mongodb aggregation pipeline performance, mongodb aggregation limit, mongodb aggregation pipeline limit, aggregation pipeline mongodb examples, aggregation pipeline mongodb, aggregation limit mongodb, $limit mongodb, aggregate project limit");
        title.setPageDescription("The `$limit` stage in MongoDB's aggregation framework is used to restrict the number of documents that pass through the pipeline. This stage is similar to the `LIMIT` clause in SQL and is useful when you want to limit the result set to a specified number of documents.");
        path.setPreviousNext({});
    }, [])

    return (
        <>
            <section class='clearfix mb-3'>
                <span className='float-start'>
                    <Link to='/mongo-db/aggregation' onClick={() => path.setPathName('/mongo-db/aggregation')} className='btn btn-primary'> <i class="fa-solid fa-less-than"></i>&nbsp; Back to Aggregation </Link>
                </span>
            </section>
            <section className='mt-5 mb-5'>
                <h3>MongoDB - $limit Pipelines</h3>
                <div className='mt-4 mb-5'>
                    <p>
                        The <strong>`$limit`</strong> stage in MongoDB's aggregation framework is used to restrict the number of documents that pass through the pipeline. This stage is similar to the <strong>`LIMIT`</strong> clause in SQL and is useful when you want to limit the result set to a specified number of documents.
                    </p>
                    <h5>Basic Syntax</h5>
                    <p>
                        The <strong>`$limit`</strong> stage takes a single integer argument that specifies the maximum number of documents to return.
                    </p>
                    <div className='codePalateBox mt-2 mb-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                                <p>{</p>
                                <p style="margin-left:30px">    <span class="color-pink">$limit</span>: &lt;number&gt;</p>
                                <p>}</p>
                            `
                        }}></div>
                    </div>
                    <p>
                        Where, <strong>`&lt;number&gt;`</strong> is the maximum number of documents to pass through the pipeline.
                    </p>
                    <h5 className='mt-4 mb-3'>Example Scenario</h5>
                    <p>
                        Consider a <strong>`students`</strong> collection where each document represents a student with fields for <strong>`name`</strong>, <strong>`age`</strong>, and <strong>`grade`</strong>.
                    </p>
                    <div className='codePalateBox mt-2 mb-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                                <p>[</p>
                                <p style="margin-left:30px">    { <span class="color-pink">"_id"</span>: <span class="color-pink">1</span>, <span class="color-pink">"name"</span>: <span class="color-green">"Alice"</span>, <span class="color-pink">"age"</span>: <span class="color-pink">21</span>, <span class="color-pink">"grade"</span>: <span class="color-pink">85</span> },</p>
                                <p style="margin-left:30px">    { <span class="color-pink">"_id"</span>: <span class="color-pink">2</span>, <span class="color-pink">"name"</span>: <span class="color-green">"Bob"</span>, <span class="color-pink">"age"</span>: <span class="color-pink">19</span>, <span class="color-pink">"grade"</span>: <span class="color-pink">90</span> },</p>
                                <p style="margin-left:30px">    { <span class="color-pink">"_id"</span>: <span class="color-pink">3</span>, <span class="color-pink">"name"</span>: <span class="color-green">"Charlie"</span>, <span class="color-pink">"age"</span>: <span class="color-pink">23</span>, <span class="color-pink">"grade"</span>: <span class="color-pink">80</span> },</p>
                                <p style="margin-left:30px">    { <span class="color-pink">"_id"</span>: <span class="color-pink">4</span>, <span class="color-pink">"name"</span>: <span class="color-green">"David"</span>, <span class="color-pink">"age"</span>: <span class="color-pink">22</span>, <span class="color-pink">"grade"</span>: <span class="color-pink">88</span> },</p>
                                <p style="margin-left:30px">    { <span class="color-pink">"_id"</span>: <span class="color-pink">5</span>, <span class="color-pink">"name"</span>: <span class="color-green">"Eve"</span>, <span class="color-pink">"age"</span>: <span class="color-pink">20</span>, <span class="color-pink">"grade"</span>: <span class="color-pink">95</span> }</p>
                                <p>]</p>
                            `
                        }}></div>
                    </div>
                    <h5 className='mt-4 mb-3'>Limiting the Number of Documents</h5>
                    <p>
                        To limit the result set to the first 3 students:
                    </p>
                    <div className='codePalateBox mt-2 mb-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                                <p>db.students.<span class="color-red">aggregate</span>([</p>
                                <p style="margin-left:30px">    {</p>
                                <p style="margin-left:60px">    <span class="color-pink">$limit</span>: <span class="color-pink">3</span></p>
                                <p style="margin-left:30px">    }</p>
                                <p>])</p>
                            `
                        }}></div>
                    </div>
                    <h6 className='mt-4 mb-4'>Result of the above</h6>
                    <div className='codePalateBox mt-2 mb-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                                <p>[</p>
                                <p style="margin-left:30px">    { <span class="color-pink">"_id"</span>: <span class="color-pink">1</span>, <span class="color-pink">"name"</span>: <span class="color-green">"Alice"</span>, <span class="color-pink">"age"</span>: <span class="color-pink">21</span>, <span class="color-pink">"grade"</span>: <span class="color-pink">85</span> },</p>
                                <p style="margin-left:30px">    { <span class="color-pink">"_id"</span>: <span class="color-pink">2</span>, <span class="color-pink">"name"</span>: <span class="color-green">"Bob"</span>, <span class="color-pink">"age"</span>: <span class="color-pink">19</span>, <span class="color-pink">"grade"</span>: <span class="color-pink">90</span> },</p>
                                <p style="margin-left:30px">    { <span class="color-pink">"_id"</span>: <span class="color-pink">3</span>, <span class="color-pink">"name"</span>: <span class="color-green">"Charlie"</span>, <span class="color-pink">"age"</span>: <span class="color-pink">23</span>, <span class="color-pink">"grade"</span>: <span class="color-pink">80</span> }</p>
                                <p>]</p>
                            `
                        }}></div>
                    </div>
                    <h5 className='mt-4 mb-3'>Combining `$limit` with Other Stages</h5>
                    <p>
                        You can combine the <strong>`$limit`</strong> stage with other stages like <strong>`$sort`</strong> and <strong>`$match`</strong> to refine the result set further. For example, to get the top 2 students sorted by their grades in descending order:
                    </p>
                    <div className='codePalateBox mt-2 mb-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                                <p>db.students.<span class="color-red">aggregate</span>([</p>
                                <p style="margin-left:30px">    {</p>
                                <p style="margin-left:60px">        <span class="color-pink">$sort</span>: { <span class="color-pink">grade</span>: -<span class="color-pink">1</span> }</p>
                                <p style="margin-left:30px">    },</p>
                                <p style="margin-left:30px">    {</p>
                                <p style="margin-left:60px">        <span class="color-pink">$limit</span>: <span class="color-pink">2</span></p>
                                <p style="margin-left:30px">    }</p>
                                <p>])</p>
                            `
                        }}></div>
                    </div>
                    <h6 className='mt-4 mb-4'>Result of the above</h6>
                    <div className='codePalateBox mt-2 mb-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                                <p>[</p>
                                <p style="margin-left:30px">    { <span class="color-pink">"_id"</span>: <span class="color-pink">5</span>, <span class="color-pink">"name"</span>: <span class="color-green">"Eve"</span>, <span class="color-pink">"age"</span>: <span class="color-pink">20</span>, <span class="color-pink">"grade"</span>: <span class="color-pink">95</span> }</p>
                                <p style="margin-left:30px">    { <span class="color-pink">"_id"</span>: <span class="color-pink">2</span>, <span class="color-pink">"name"</span>: <span class="color-green">"Bob"</span>, <span class="color-pink">"age"</span>: <span class="color-pink">19</span>, <span class="color-pink">"grade"</span>: <span class="color-pink">90</span> },</p>
                                <p>]</p>
                            `
                        }}></div>
                    </div>
                    <div className='mt-5 mb-4 featureClass'>
                        <h5 className='mb-4'>Important Note</h5>
                        <ul style={{listStyle:'disc'}}>
                            <li><strong>Limiting Results</strong>: The <strong>`$limit`</strong> stage is used to restrict the number of documents that continue through the pipeline, making it useful for pagination or retrieving a fixed number of results.</li>
                            <li><strong>Order of Operations</strong>: When combined with stages like <strong>`$sort`</strong> or <strong>`$match`</strong>, the order of stages matters. For example, you should sort the documents before applying <strong>`$limit`</strong> to get the top results based on the sort order.</li>
                            <li><strong>Efficiency</strong>: Using <strong>`$limit`</strong> can improve performance by reducing the number of documents processed in subsequent stages.</li>
                        </ul>
                    </div>
                </div>
            </section>
        </>
    )
}