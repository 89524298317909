import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";

export const pageUrl = () => "/html/introduction";

export default function HTMLIntro() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("HTML - Introduction | Aspirant's Home");
        const urls = {
            'next': '/html/editors'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>HTML - Introduction</h3>
            <div className='mt-4 mb-5'>
                <p>
                    HTML, or HyperText Markup Language, is the standard markup language used to create and design the structure of web pages. It consists of a series of elements that define different parts of a webpage, such as headings, paragraphs, links, images, and other content.
                </p>
                <h5 className='mt-3 mb-4'>What is HTML?</h5>
                <ul style={{listStyle:'decimal'}}>
                    <li className='mb-4'>
                        <strong>HTML stands for HyperText Markup Language</strong>:
                        HTML is an acronym for HyperText Markup Language. "HyperText" refers to the way HTML allows text to be linked to other documents or resources, and "Markup Language" refers to the way HTML uses tags to describe the structure and presentation of the content.
                    </li>
                    <li className='mb-4'>
                        <strong>HTML is the standard markup language used to create web pages</strong>:
                        HTML is the universally accepted language for creating and designing web pages. It is the foundation upon which all websites are built. Every web page you see on the internet is written using HTML in some form.
                    </li>
                    <li className='mb-4'>
                        <strong>HTML defines the structure of a web page</strong>:
                        HTML provides the basic structure of a webpage, which includes elements like headings, paragraphs, lists, links, images, and other types of content. It organizes this content in a hierarchical manner, allowing for a logical flow and easy navigation.
                    </li>
                    <li className='mb-4'>
                        <strong>HTML is made up of a series of elements</strong>:
                        HTML documents are composed of various elements. Each element is represented by tags and can have attributes that provide additional information about the element. For example, a paragraph is an element that uses the &lt;p&gt; tag.
                    </li>
                    <li className='mb-4'>
                        <strong>HTML elements instruct the browser on how to display content</strong>:
                        HTML elements tell the web browser how to display the content enclosed within them. For example, the &lt;h1&gt; tag indicates that the enclosed text is a top-level heading, while the &lt;p&gt; tag indicates a paragraph. Browsers use these instructions to render the content appropriately.
                    </li>
                    <li className='mb-4'>
                        <strong>HTML elements label parts of the content, indicating "this is a heading," "this is a paragraph," "this is a link," and so on</strong>:
                        HTML elements act as labels that define the role of different parts of the content. For instance:
                        &lt;h1&gt; to &lt;h6&gt; tags label headings.
                        &lt;p&gt; tags label paragraphs.
                        &lt;a&gt; tags label hyperlinks.
                        &lt;img&gt; tags label images.
                        This labeling helps both browsers and search engines understand and display the content correctly.
                    </li>
                </ul>
                <h5 className='mt-3 mb-4'>Basic Structure of an HTML Document</h5>
                <p>An HTML document has a standard structure with the following essential elements:</p>
                <div className='codePalateBox mt-2 mb-5'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>&lt;!DOCTYPE <span class="color-blue">html</span>&gt;</p>
                            <p>&lt;html lang="en"&gt;</p>
                            <p style="margin-left:30px">    &lt;head&gt;</p>
                            <p style="margin-left:60px">        &lt;meta <span class="color-pink">charset</span>=<span class="color-green">"UTF-8"</span>&gt;</p>
                            <p style="margin-left:60px">        &lt;meta <span class="color-pink">name</span>=<span class="color-green">"viewport"</span> <span class="color-pink">content</span>=<span class="color-green">"width=device-width, initial-scale=1.0"</span>&gt;</p>
                            <p style="margin-left:60px">        &lt;title&gt;Document Title&lt;/title&gt;</p>
                            <p style="margin-left:30px">    &lt;/head&gt;</p>
                            <p style="margin-left:30px">    &lt;body&gt;</p>
                            <p style="margin-left:60px">        &lt;h1&gt;Hello, World!&lt;/h1&gt;</p>
                            <p style="margin-left:60px">        &lt;p&gt;This is a paragraph.&lt;/p&gt;</p>
                            <p style="margin-left:30px">    &lt;/body&gt;</p>
                            <p>&lt;/html&gt;</p>
                        `
                    }}></div>
                </div>
                <p>Where,</p>
                <ul style={{listStyle:'disc'}}>
                    <li>
                        <strong>`&lt;!DOCTYPE html&gt;`</strong>: Declares the document type and version of HTML being used.
                    </li>
                    <li>
                        <strong>`&lt;html&gt;`</strong>: The root element that contains all other elements.
                    </li>
                    <li>
                        <strong>`&lt;head&gt;`</strong>: Contains meta-information about the document, such as its title and character set.
                    </li>
                    <li>
                        <strong>`&lt;title&gt;`</strong>: Sets the title of the document, which appears in the browser tab.
                    </li>    
                    <li>
                        <strong>`&lt;body&gt;`</strong>: Contains the content of the webpage, such as text, images, and links.
                    </li>
                </ul>
            </div>
        </section>
    )
}