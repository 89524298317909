import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/mysql/distinct-clause";

export default function MysqlDistinctClause() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Mysql - Distinct Clause | Aspirant's Home");
        const urls = {
            'previous': '/mysql/limit-clause',
            'next': '/mysql/order-by-clause'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>Mysql - Distinct Clause</h3>
            <div className='mt-4 mb-5'>
                <p>
                    In MySQL, the <strong>`DISTINCT`</strong> keyword is used in a <strong>`SELECT`</strong> statement to eliminate duplicate rows from the result set. It returns only unique values for the specified columns. Here's the basic syntax:
                </p>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-blue">SELECT DISTINCT</span> column1, column2, ...</p>
                            <p><span class="color-blue">FROM</span> table_name</p>
                            <p><span class="color-blue">WHERE</span> condition;</p>
                        `
                    }}></div>
                </div>
                <p>
                    Where, <strong>`column1, column2, ...`</strong> are the columns you want to retrieve unique values for. If you specify multiple columns, the combination of values in those columns must be unique. <strong>`table_name`</strong> is the name of the table from which you want to retrieve data. <strong>`condition`</strong> is an optional condition to filter the rows.
                </p>
                <p>
                    Suppose, we have a <strong>`employees`</strong> table like below -
                </p>
                <table className='table' style={{border: '1px dashed #ccc'}}> 
                    <thead>
                        <tr style={{border: '1px dashed #ccc'}}>
                            <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>id</th>
                            <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>name</th>
                            <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>department</th>
                            <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>salary</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr style={{border: '1px dashed #ccc'}}>
                            <td style={{border: '1px dashed #ccc'}}>1</td>
                            <td style={{border: '1px dashed #ccc'}}>Jane Smith</td>
                            <td style={{border: '1px dashed #ccc'}}>IT</td>
                            <td style={{border: '1px dashed #ccc'}}>500000</td>
                        </tr>
                        <tr style={{border: '1px dashed #ccc'}}>
                            <td style={{border: '1px dashed #ccc'}}>2</td>
                            <td style={{border: '1px dashed #ccc'}}>John Doe</td>
                            <td style={{border: '1px dashed #ccc'}}>IT</td>
                            <td style={{border: '1px dashed #ccc'}}>350000</td>
                        </tr>
                        <tr style={{border: '1px dashed #ccc'}}>
                            <td style={{border: '1px dashed #ccc'}}>3</td>
                            <td style={{border: '1px dashed #ccc'}}>Johny Smith</td>
                            <td style={{border: '1px dashed #ccc'}}>Accountant</td>
                            <td style={{border: '1px dashed #ccc'}}>50000</td>
                        </tr>
                        <tr style={{border: '1px dashed #ccc'}}>
                            <td style={{border: '1px dashed #ccc'}}>4</td>
                            <td style={{border: '1px dashed #ccc'}}>Jeffer Joe</td>
                            <td style={{border: '1px dashed #ccc'}}>HR Manager</td>
                            <td style={{border: '1px dashed #ccc'}}>150000</td>
                        </tr>
                    </tbody>
                </table>
                <p>
                    For example, suppose you have a table named <strong>`employees`</strong> with a column named <strong>`department`</strong> that contains duplicate values. To retrieve a list of unique <strong>`departments`</strong>, you would use the following query:
                </p>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-blue">SELECT DISTINCT</span> department <span class="color-blue">FROM</span> employees;</p>
                        `
                    }}></div>
                </div>
                <p>
                    The Output of the query will be the following -
                </p>

                <table className='table' style={{border: '1px dashed #ccc'}}> 
                    <thead>
                        <tr style={{border: '1px dashed #ccc'}}>
                            <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>department</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr style={{border: '1px dashed #ccc'}}>
                            <td style={{border: '1px dashed #ccc'}}>IT</td>
                        </tr>
                        <tr style={{border: '1px dashed #ccc'}}>
                            <td style={{border: '1px dashed #ccc'}}>Accountant</td>
                        </tr>
                        <tr style={{border: '1px dashed #ccc'}}>
                            <td style={{border: '1px dashed #ccc'}}>HR Manager</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </section>
    )
}