import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/css/outline/width";

export default function OutlineWidth() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Outline Width in CSS | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/css/outline',
            'next': '/css/outline/color'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>Outline Width</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <strong>`outline-width`</strong> property in CSS is used to specify the thickness of the outline surrounding an element. Unlike borders, outlines do not affect the element's layout because they are drawn outside the element's border edge. The <strong>`outline-width`</strong> property can be set using keywords or length units.
                </p>
                <p>
                    The <strong>`outline-width`</strong> property specifies the width of the outline, and can have one of the following values:
                </p>
                <ul style={{listStyle:'disc'}}>
                    <li>thin (typically 1px)</li>
                    <li>medium (typically 3px)</li>
                    <li>thick (typically 5px)</li>
                    <li>A specific size (in px, pt, cm, em, etc)</li>
                </ul>
                <p>
                    The following example shows some outlines with different widths:
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;style&gt;</p>
                        <p class="ml-30">    .box {</p>
                        <p class="ml-60">        <span class="color-green">background-color</span>: lightgrey;</p>
                        <p class="ml-60">        <span class="color-green">margin-bottom</span>: <span class="color-pink">20px</span>;</p>
                        <p class="ml-60">        <span class="color-green">padding-left</span>: <span class="color-pink">20px</span>;</p>
                        <p class="ml-60">        <span class="color-green">outline-color</span>: red;</p>
                        <p class="ml-30">    }</p>
                        <br />
                        <p class="ml-30">    .box1 {</p>
                        <p class="ml-60">        <span class="color-green">outline-style</span>: solid;</p>
                        <p class="ml-60">        <span class="color-green">outline-width</span>: thin; <span class="color-grey">/* Thin outline */</span></p>
                        <p class="ml-30">    }</p>
                        <br />
                        <p class="ml-30">    .box2 {</p>
                        <p class="ml-60">        <span class="color-green">outline-style</span>: solid;</p>
                        <p class="ml-60">        <span class="color-green">outline-width</span>: <span class="color-pink">4px</span>; <span class="color-grey">/* 4px thick outline */</span></p>
                        <p class="ml-30">    }</p>
                        <br />
                        <p class="ml-30">    .box3 {</p>
                        <p class="ml-60">        <span class="color-green">outline-style</span>: solid;</p>
                        <p class="ml-60">        <span class="color-green">outline-width</span>: thick; <span class="color-grey">/* Thick outline */</span></p>
                        <p class="ml-30">    }</p>
                        <p>&lt;/style&gt;</p>
                        <p>&lt;p <span class="color-pink">class</span>=<span class="color-green">"box box1"</span>&gt;Thin Outline&lt;/p&gt;</p>
                        <p>&lt;p <span class="color-pink">class</span>=<span class="color-green">"box box2"</span>&gt;4px Outline&lt;/p&gt;</p>
                        <p>&lt;p <span class="color-pink">class</span>=<span class="color-green">"box box3"</span>&gt;Thick Outline&lt;/p&gt;</p>
                        `
                    }}></div>
                </div>
                <p>
                    Output will be the following -
                </p>
                <div className='outputPalateBox mb-4 mt-3' style={{'border':'0'}}>
                    <div className='outputPalate' dangerouslySetInnerHTML={{
                        __html: `
                            <style>
                                .box {
                                    background-color: lightgrey;
                                    margin-bottom: 20px;
                                    padding-left: 20px;
                                    outline-color: red;
                                }
                        
                                .box1 {
                                    outline-style: solid;
                                    outline-width: thin; /* Thin outline */
                                }
                        
                                .box2 {
                                    outline-style: solid;
                                    outline-width: 4px; /* 4px thick outline */
                                }
                        
                                .box3 {
                                    outline-style: solid;
                                    outline-width: thick; /* Thick outline */
                                }
                            </style>
                            <p class="box box1">Thin Outline</p>
                            <p class="box box2">4px Outline</p>
                            <p class="box box3">Thick Outline</p>
                        `
                    }}></div>
                </div>
            </div>
        </section>
    )
}