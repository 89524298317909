import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/react-js/redux";

export default function ReduxIntro() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("React - Introduction to Redux | Aspirant's Home");
        const urls = {
            'previous': '/react-js/tailwind-css',
            'next': '/react-js/redux/data-flow'
        }
        path.setPreviousNext(urls);
    },[])

    return (
        <section className='mt-5 mb-5'>
            <h3>Introduction to Redux</h3>
            <div className='mt-4 mb-4'>
                <p>
                    Redux is a predictable state container for JavaScript apps. It provides a centralized store that holds the entire state of your application, making it easier to manage and access state across your app. Thats means we maintain a app-wide state.
                </p>
            </div>
            <div className='mt-5 mb-4'>
                <h5>What is Cross-Component & App-Wide State ?</h5>
                <div className='mt-2 mb-2'>
                    <p>
                        Cross-component state and app-wide state are concepts related to how state is managed and shared in React applications.
                    </p>
                    <table className='table'>
                        <thead>
                            <tr>
                                <th>Local State</th>
                                <th>Cross-component State</th>
                                <th>App-Wide State</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    State belongs to single component
                                </td>
                                <td>
                                    State affecting multiple component
                                </td>
                                <td>
                                    State affecting the entire app
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    E.g., listening to user input on an input field or toggling a "show more details" field.
                                </td>
                                <td>
                                    E.g., open / closed state of a modal overlay.
                                </td>
                                <td>
                                    E.g., user authetication status or chosen theme.
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Should be managed inside the component via <strong>`useState()`</strong> / <strong>`useReducer()`</strong>
                                </td>
                                <td>
                                    Requires "prop drilling"
                                </td>
                                <td>
                                    Requires "prop drilling"
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div className='mt-5 mb-4'>
                <h5>Key Features of Redux</h5>
                <div className='mt-2 mb-2'>
                    <ul style={{listStyle:'disc'}}>
                        <li>
                            <strong>Single Source of Truth:</strong> The state of your whole application is stored in a single JavaScript object, known as the "store." This makes it easier to manage and access the state across your entire application.
                        </li>
                        <li>
                            <strong>Scalability:</strong> Redux is scalable and can be used in small applications as well as large, complex applications. It provides patterns and best practices that help manage state in a consistent way.
                        </li>
                        <li>
                            <strong>Centralized State Management:</strong> Redux provides a centralized store to manage the state of your application, making it easier to debug, test, and maintain your code.
                        </li>
                        <li>
                            <strong>State is Read-Only:</strong> The only way to change the state is to dispatch an action. Actions are plain JavaScript objects that describe what happened in your application.
                        </li>
                    </ul>
                </div>
            </div>
            <div className='mt-5 mb-4'>
                <h5>Installation</h5>
                <div className='mt-2 mb-2'>
                    <p>
                        To install redux, you can follow the below steps −
                    </p>
                    <p>
                        Run the following command in your command prompt to install Redux.
                    </p>
                    <div className='codePalateBox mt-2 mb-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                                <p>npm install redux</p>
                            `
                        }}></div>
                    </div>
                    <p>
                        To use Redux with react application, you need to install an additional dependency as follows −
                    </p>
                    <div className='codePalateBox mt-2 mb-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                                <p>npm install react-redux</p>
                            `
                        }}></div>
                    </div>
                </div>
            </div>
        </section>
    )
}