import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/html/page-title";

export default function HTMLPageTitle() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("HTML - Page Title | Aspirant's Home");
        const urls = {
            'previous': '/html/favicon',
            'next': '/html/iframes'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>HTML - Page Title</h3>
            <div className='mt-4 mb-5'>
                <p className='mb-4'>
                    The page title in HTML is the text that appears in the browser tab and is used as the title of a web page. It is defined within the <strong>`&lt;title&gt;`</strong> element, which is placed inside the <strong>`&lt;head&gt;`</strong> section of an HTML document. The page title is crucial for both usability and search engine optimization (SEO), as it gives users and search engines an idea of the content of the page.
                </p>
                <p>Below is the example of page title.</p>
                <div className='mt-5 myImage'>
                    <img className='img-fluid' src='../assets/html/html-page-title.png' />
                </div>
                <h5 className='mt-5 mb-3'>Importance of Page Title</h5>
                <ul style={{ listStyle: 'decimal' }}>
                    <li><strong>User Experience</strong>: The page title helps users understand what the page is about and provides context when multiple tabs are open in a browser.</li>
                    <li><strong>Search Engine Optimization (SEO)</strong>: Search engines use the page title to understand the content of the page and to display it in search results. A well-crafted title can improve the page's visibility and ranking in search engines.</li>
                    <li><strong>Bookmarks and Sharing</strong>: When users bookmark a page or share it on social media, the page title is often used as the default text.</li>
                </ul>
                <h5 className='mt-5 mb-3'>Syntax</h5>
                <p>The <strong>`&lt;title&gt;`</strong> element should be included within the <strong>`&lt;head&gt;`</strong> section of an HTML document.</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-grey">&lt;!DOCTYPE</span> <span class="color-blue">html</span><span class="color-grey">&gt;</span></p>
                        <p>&lt;html <span class="color-pink">lang</span>=<span class="color-green">"en"</span>&gt;</p>
                        <p class="ml-30">    &lt;head&gt;</p>
                        <p class="ml-60">        &lt;meta <span class="color-pink">charset</span>=<span class="color-green">"UTF-8"</span>&gt;</p>
                        <p class="ml-60">        &lt;meta <span class="color-pink">name</span>=<span class="color-green">"viewport"</span> <span class="color-pink">content</span>=<span class="color-green">"width=device-width, initial-scale=1.0"</span>&gt;</p>
                        <p class="ml-60">        &lt;title&gt;Page Title Here&lt;/title&gt;</p>
                        <p class="ml-30">    &lt;/head&gt;</p>
                        <p class="ml-30">    &lt;body&gt;</p>
                        <p class="ml-60">        &lt;h1&gt;Welcome to My Website&lt;/h1&gt;</p>
                        <p class="ml-60">        &lt;p&gt;This is an example of a web page with a title.&lt;/p&gt;</p>
                        <p class="ml-30">    &lt;/body&gt;</p>
                        <p>&lt;/html&gt;</p>
                        `
                    }}></div>
                </div>
            </div>
        </section>
    )
}