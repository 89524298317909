import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/node-js/mysql-create-table";

export default function MysqlCreateTable() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Node Js - Mysql - Create Table | Aspirant's Home");
        const urls = {
            'previous': '/node-js/mysql-create-database',
            'next': '/node-js/mysql-insert-query'
        }
        path.setPreviousNext(urls);
    }, [])
    
    return (
        <section className='mt-5 mb-5'>
            <h3 className='mb-4'>Node js Mysql Create Table</h3>
            <p>MySQL implements the CREATE TABLE statement as per the SQL standards. This statement defines the number and type of attributes of the table.</p>

            <p>The syntax of MySQL's CREATE TABLE query is as follows −</p>
            <div className='codePalateBox mt-2 mb-2'>
                <div className='codePalate'>
                    <p><span className='color-yellow'>CREATE TABLE [IF NOT EXISTS]</span> <span className='color-yellow'>table_name</span>(</p>
                    <p className='color-grey'>column1 datatype constraints,</p>
                    <p className='color-grey'>column1 datatype constraints,</p>
                    <p>) <span className='color-yellow'>ENGINE</span>=storage_engine;</p>
                </div>
            </div>
            <p>Create a Node.js script to connect to your MySQL server and create a table:</p>
            <p>Let's create a table named "users":</p>
            <div className='codePalateBox mt-2 mb-2'>
                <div className='codePalate' dangerouslySetInnerHTML={{__html:`
                    <p><span class='color-blue'>var</span> mysql = <span class='color-yellow'>require</span>(<span class='color-green'>'mysql'</span>);</p>
                    <p><span class='color-blue'>var</span> con = mysql.<span class='color-red'>createConnection</span>({</p>
                    <p style='margin-left:30px'> <span class='color-pink'>host</span>: <span class='color-green'>'localhost'</span>,</p>
                    <p style='margin-left:30px'>  <span class='color-pink'>user</span>: <span class='color-green'>"yourusername"</span>,</p>
                    <p style='margin-left:30px'>  <span class='color-pink'>password</span>: <span class='color-green'>"yourpassword"</span>,</p>
                    <p style='margin-left:30px'><span class='color-pink'>database</span>: <span class='color-green'>"mydb"</span></p>
                    <p>});</p>

                    <p>con.<span class='color-red'>connect</span>(function(err) {</p>
                    <p style='margin-left:30px'><span class='color-blue'>if</span> (err) throw err;</p>
                    <p style='margin-left:30px'><span class='color-pink'>console</span>.<span class='color-red'>log</span>(<span class='color-green'>"Connected!"</span>);</p>
                    <p style='margin-left:30px'><span class='color-blue'>var</span> sql = "CREATE TABLE users (name VARCHAR(255), email VARCHAR(255))";</p>
                    <p style='margin-left:30px'>con.<span class='color-red'>query</span>(sql, function (err, result) {</p>
                        <p style='margin-left:60px'><span class='color-blue'>if</span> (err) throw err;</p>
                        <p style='margin-left:60px'><span class='color-pink'>console</span>.<span class='color-red'>log</span>(<span class='color-green'>"Table created"</span>);</p>
                    <p style='margin-left:30px'>});</p>
                    <p>});</p>`}}>

                </div>
            </div>
        </section>
    )
}
