import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';
export const pageUrl = () => "/mongo-db/aggregation/group";

export default function MongoAggregationGroup() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("$group Aggregation Pipelines in MongoDB");
        title.setKeyWords("$group aggregation pipeline in mongodb, mongodb aggregation pipeline group, mongodb aggregation pipeline multiple groups, aggregation pipeline mongodb examples, aggregation pipeline mongodb, mongodb aggregation pipeline project, mongodb aggregation pipeline query");
        title.setPageDescription("The `$group` stage in MongoDB's aggregation framework is used to group documents by a specified key and perform operations on the grouped data, such as calculating sums, averages, counts, and more. It is similar to the `GROUP BY` clause in SQL.");
        path.setPreviousNext({});
    }, [])

    return (
        <>
            <section class='clearfix mb-3'>
                <span className='float-start'>
                    <Link to='/mongo-db/aggregation' onClick={() => path.setPathName('/mongo-db/aggregation')} className='btn btn-primary'> <i class="fa-solid fa-less-than"></i>&nbsp; Back to Aggregation </Link>
                </span>
            </section>
            <section className='mt-5 mb-5'>
                <h3>MongoDB - $group Pipelines</h3>
                <div className='mt-4 mb-5'>
                    <p>
                        The <strong>`$group`</strong> stage in MongoDB's aggregation framework is used to group documents by a specified key and perform operations on the grouped data, such as calculating sums, averages, counts, and more. It is akin to the <strong>`GROUP BY`</strong> clause in SQL.
                    </p>
                    <h5>Basic Syntax</h5>
                    <p>
                        The <strong>`$group`</strong> stage requires an <strong>`_id`</strong> field to specify the key by which to group the documents. Additionally, you can define accumulator expressions to perform calculations on the grouped data.
                    </p>
                    <div className='codePalateBox mt-2 mb-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                                <p>{</p>
                                <p style="margin-left:30px">    <span class="color-pink">$group</span>: {</p>
                                <p style="margin-left:60px">        <span class="color-pink">_id</span>: &lt;grouping criteria&gt;,</p>
                                <p style="margin-left:60px">        &lt;field1&gt;: { &lt;accumulator1&gt;: &lt;expression1&gt; },</p>
                                <p style="margin-left:60px">        &lt;field2&gt;: { &lt;accumulator2&gt;: &lt;expression2&gt; },</p>
                                <p style="margin-left:60px">        ...</p>
                                <p style="margin-left:30px">    }</p>
                                <p>}</p>
                            `
                        }}></div>
                    </div>
                    <p>
                        Where,
                    </p>
                    <ul style={{ listStyle: 'disc' }}>
                        <li><strong>`_id`</strong>: The field by which to group documents. It can be a field from the input documents, an expression, or a computed value.</li>
                        <li><strong>`&lt;field&gt;`</strong>: The name of the output field.</li>
                        <li><strong>`&lt;accumulator&gt;`</strong>: The accumulator operator (e.g., <strong>`$sum`</strong>, <strong>`$avg`</strong>, <strong>`$max`</strong>, <strong>`$min`</strong>, <strong>`$push`</strong>, <strong>`$addToSet`</strong>, etc.).</li>
                        <li><strong>`&lt;expression&gt;`</strong>: The expression on which the accumulator operates.</li>
                    </ul>
                    <h5 className='mt-4 mb-3'>Example Scenario</h5>
                    <p>
                        Consider a <strong>`sales`</strong> collection where each document represents a sale, including the <strong>`item`</strong>, <strong>`quantity`</strong>, and <strong>`total`</strong> fields.
                    </p>
                    <div className='codePalateBox mt-2 mb-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                                <p>[</p>
                                <p style="margin-left:30px">    { <span class="color-pink">"_id"</span>: <span class="color-pink">1</span>, <span class="color-pink">"item"</span>: <span class="color-green">"apple"</span>, <span class="color-pink">"quantity"</span>: <span class="color-pink">5</span>, <span class="color-pink">"total"</span>: <span class="color-pink">10</span> },</p>
                                <p style="margin-left:30px">    { <span class="color-pink">"_id"</span>: <span class="color-pink">2</span>, <span class="color-pink">"item"</span>: <span class="color-green">"banana"</span>, <span class="color-pink">"quantity"</span>: <span class="color-pink">3</span>, <span class="color-pink">"total"</span>: <span class="color-pink">5</span> },</p>
                                <p style="margin-left:30px">    { <span class="color-pink">"_id"</span>: <span class="color-pink">3</span>, <span class="color-pink">"item"</span>: <span class="color-green">"apple"</span>, <span class="color-pink">"quantity"</span>: <span class="color-pink">8</span>, <span class="color-pink">"total"</span>: <span class="color-pink">16</span> },</p>
                                <p style="margin-left:30px">    { <span class="color-pink">"_id"</span>: <span class="color-pink">4</span>, <span class="color-pink">"item"</span>: <span class="color-green">"banana"</span>, <span class="color-pink">"quantity"</span>: <span class="color-pink">1</span>, <span class="color-pink">"total"</span>: <span class="color-pink">2</span> },</p>
                                <p style="margin-left:30px">    { <span class="color-pink">"_id"</span>: <span class="color-pink">5</span>, <span class="color-pink">"item"</span>: <span class="color-green">"orange"</span>, <span class="color-pink">"quantity"</span>: <span class="color-pink">6</span>, <span class="color-pink">"total"</span>: <span class="color-pink">12</span> }</p>
                                <p>]</p>
                            `
                        }}></div>
                    </div>
                    <h5 className='mt-5 mb-3'>Grouping by Item and Summing Quantities</h5>
                    <p>
                        To group the sales by item and calculate the total quantity sold for each item:
                    </p>
                    <div className='codePalateBox mt-2 mb-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                                <p>db.sales.<span class="color-red">aggregate</span>([</p>
                                <p style="margin-left:30px">    {</p>
                                <p style="margin-left:60px">        <span class="color-pink">$group</span>: {</p>
                                <p style="margin-left:90px">            <span class="color-pink">_id</span>: <span class="color-green">"$item"</span>,</p>
                                <p style="margin-left:90px">            <span class="color-pink">totalQuantity</span>: { <span class="color-pink">$sum</span>: <span class="color-green">"$quantity"</span> }</p>
                                <p style="margin-left:60px">        }</p>
                                <p style="margin-left:30px">    }</p>
                                <p>])</p>
                            `
                        }}></div>
                    </div>
                    <h6 className='mt-4 mb-4'>Result of the above</h6>
                    <div className='codePalateBox mt-2 mb-4'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                                <p>[</p>
                                <p style="margin-left:30px">    { <span class="color-pink">"_id"</span>: <span class="color-green">"apple"</span>, <span class="color-pink">"totalQuantity"</span>: <span class="color-pink">13</span> },</p>
                                <p style="margin-left:30px">    { <span class="color-pink">"_id"</span>: <span class="color-green">"banana"</span>, <span class="color-pink">"totalQuantity"</span>: <span class="color-pink">4</span> },</p>
                                <p style="margin-left:30px">    { <span class="color-pink">"_id"</span>: <span class="color-green">"orange"</span>, <span class="color-pink">"totalQuantity"</span>: <span class="color-pink">6</span> }</p>
                                <p>]</p>
                            `
                        }}></div>
                    </div>
                    <h5 className='mt-5 mb-3'>Grouping by Item and Calculating Multiple Aggregations</h5>
                    <p>
                        To group the sales by item, calculate the total quantity sold, and the total revenue for each item:
                    </p>
                    <div className='codePalateBox mt-2 mb-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                                <p>db.sales.<span class="color-red">aggregate</span>([</p>
                                <p style="margin-left:30px">    {</p>
                                <p style="margin-left:60px">        <span class="color-pink">$group</span>: {</p>
                                <p style="margin-left:90px">            <span class="color-pink">_id</span>: <span class="color-green">"$item"</span>,</p>
                                <p style="margin-left:90px">            <span class="color-pink">totalQuantity</span>: { <span class="color-pink">$sum</span>: <span class="color-green">"$quantity"</span> }</p>
                                <p style="margin-left:90px">            <span class="color-pink">totalRevenue</span>: { <span class="color-pink">$sum</span>: <span class="color-green">"$total"</span> }</p>
                                <p style="margin-left:60px">        }</p>
                                <p style="margin-left:30px">    }</p>
                                <p>])</p>
                            `
                        }}></div>
                    </div>
                    <h6 className='mt-4 mb-4'>Result of the above</h6>
                    <div className='codePalateBox mt-2 mb-4'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                                <p>[</p>
                                <p style="margin-left:30px">    { <span class="color-pink">"_id"</span>: <span class="color-green">"apple"</span>, <span class="color-pink">"totalQuantity"</span>: <span class="color-pink">13</span>, <span class="color-pink">"totalRevenue"</span>: <span class="color-pink">26</span> },</p>
                                <p style="margin-left:30px">    { <span class="color-pink">"_id"</span>: <span class="color-green">"banana"</span>, <span class="color-pink">"totalQuantity"</span>: <span class="color-pink">4</span>, <span class="color-pink">"totalRevenue"</span>: <span class="color-pink">7</span> },</p>
                                <p style="margin-left:30px">    { <span class="color-pink">"_id"</span>: <span class="color-green">"orange"</span>, <span class="color-pink">"totalQuantity"</span>: <span class="color-pink">6</span>, <span class="color-pink">"totalRevenue"</span>: <span class="color-pink">12</span> }</p>
                                <p>]</p>
                            `
                        }}></div>
                    </div>
                    <h5 className='mt-5 mb-3'>Using Different Accumulators</h5>
                    <p>
                        To group sales by item, calculate the average quantity sold per sale, and collect all unique quantities in an array:
                    </p>
                    <div className='codePalateBox mt-2 mb-4'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                                <p>db.sales.<span class="color-red">aggregate</span>([</p>
                                <p style="margin-left:30px">    {</p>
                                <p style="margin-left:60px">        <span class="color-pink">$group</span>: {</p>
                                <p style="margin-left:90px">            <span class="color-pink">_id</span>: <span class="color-green">"$item"</span>,</p>
                                <p style="margin-left:90px">            <span class="color-pink">avgQuantity</span>: { <span class="color-pink">$avg</span>: <span class="color-green">"$quantity"</span> },</p>
                                <p style="margin-left:90px">            <span class="color-pink">uniqueQuantities</span>: { <span class="color-pink">$addToSet</span>: <span class="color-green">"$quantity"</span> }</p>
                                <p style="margin-left:60px">        }</p>
                                <p style="margin-left:30px">    }</p>
                                <p>])</p>
                            `
                        }}></div>
                    </div>
                    <h6 className='mt-4 mb-4'>Result of the above</h6>
                    <div className='codePalateBox mt-2 mb-4'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                                <p>[</p>
                                <p style="margin-left:30px">    { <span class="color-pink">"_id"</span>: <span class="color-green">"apple"</span>, <span class="color-pink">"avgQuantity"</span>: <span class="color-pink">6.5</span>, <span class="color-pink">"uniqueQuantities"</span>: [ <span class="color-pink">5</span>, <span class="color-pink">8</span> ] },</p>
                                <p style="margin-left:30px">    { <span class="color-pink">"_id"</span>: <span class="color-green">"banana"</span>, <span class="color-pink">"avgQuantity"</span>: <span class="color-pink">2</span>, <span class="color-pink">"uniqueQuantities"</span>: [ <span class="color-pink">3</span>, <span class="color-pink">1</span> ] },</p>
                                <p style="margin-left:30px">    { <span class="color-pink">"_id"</span>: <span class="color-green">"orange"</span>, <span class="color-pink">"avgQuantity"</span>: <span class="color-pink">6</span>, <span class="color-pink">"uniqueQuantities"</span>: [ <span class="color-pink">6</span> ] }</p>
                                <p>]</p>
                            `
                        }}></div>
                    </div>
                    <div className='mt-5 mb-4 featureClass'>
                        <h5 className='mb-4'>Explanation of Accumulators</h5>
                        <ul style={{ listStyle: 'disc' }}>
                            <li><strong>`$sum`</strong>: Calculates the sum of numeric values.</li>
                            <li><strong>`$avg`</strong>: Calculates the average of numeric values.</li>
                            <li><strong>`$min`</strong>: Finds the minimum value.</li>
                            <li><strong>`$max`</strong>: Finds the maximum value.</li>
                            <li><strong>`$push`</strong>: Adds values to an array.</li>
                            <li><strong>`$addToSet`</strong>: Adds unique values to an array (no duplicates).</li>
                            <li><strong>`$first`</strong>: Returns the first value in a group.</li>
                            <li><strong>`$last`</strong>: Returns the last value in a group.</li>
                        </ul>
                    </div>
                </div>
            </section>
        </>
    )
}