import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/html/entities";

export default function HTMLEntities() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("HTML - Entities | Aspirant's Home");
        const urls = {
            'previous': '/html/ids',
            'next': '/html/symbols'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>HTML - Entities</h3>
            <div className='mt-4 mb-5'>
                <p>
                    HTML entities are used to represent special characters in HTML that either cannot be easily typed on a keyboard or might be interpreted as HTML code. They are helpful in ensuring that special characters are displayed correctly in a web page without causing parsing errors. Entity names or entity numbers can be used to display reserved HTML characters.
                </p>
                <h5 className='mt-5 mb-3'>Syntax</h5>
                <p>
                    An HTML entity begins with an ampersand (<strong>`&`</strong>) and ends with a semicolon (<strong>`;`</strong>). Between these symbols is a code or name that represents the character.
                </p>
                <h5 className='mt-5 mb-3'>Named and Numeric Entities</h5>
                <p>
                    HTML entities can be named or numeric. Named entities are easier to remember, while numeric entities (both decimal and hexadecimal) provide support for characters without a named entity.
                </p>
                <h6>Example: Named vs. Numeric Entities</h6>
                <ul style={{ listStyle: 'disc' }}>
                    <li>Named entity: <strong>`{`&euro;`}`</strong> for the Euro sign (<strong>`€`</strong>).</li>
                    <li>Numeric entity (decimal): <strong>`{`&#8364;`}`</strong> for the Euro sign (<strong>`€`</strong>).</li>
                    <li>Numeric entity (hexadecimal): <strong>`{`&#x20AC;`}`</strong> for the Euro sign (<strong>`€`</strong>).</li>
                </ul>
                <h5 className='mt-5 mb-3'>Some Useful HTML Character Entities</h5>
                <table class="table">
                    <tbody>
                        <tr>
                            <th>Result</th>
                            <th>Description</th>
                            <th>Name</th>
                            <th>Number</th>
                        </tr>
                        <tr>
                            <td></td>
                            <td>non-breaking space</td>
                            <td>&amp;nbsp;</td>
                            <td>&amp;#160;</td>    
                        </tr>
                        <tr>
                            <td>&lt;</td>
                            <td>less than</td>
                            <td>&amp;lt;</td>
                            <td>&amp;#60;</td>
                        </tr>
                        <tr>
                            <td>&gt;</td>
                            <td>greater than</td>
                            <td>&amp;gt;</td>
                            <td>&amp;#62;</td>
                        </tr>
                        <tr>
                            <td>&amp;</td>
                            <td>ampersand</td>
                            <td>&amp;amp;</td>
                            <td>&amp;#38;</td>
                        </tr>
                        <tr>
                            <td>"</td>
                            <td>double quotation mark </td>
                            <td>&amp;quot;</td>
                            <td>&amp;#34;</td>
                        </tr>
                        <tr>
                            <td>'</td>
                            <td>single quotation mark</td>
                            <td>&amp;apos;</td>
                            <td>&amp;#39;</td>
                        </tr>
                        <tr>
                            <td>¢</td>
                            <td>cent</td>
                            <td>&amp;cent;</td>
                            <td>&amp;#162;</td>
                        </tr>
                        <tr>
                            <td>£</td>
                            <td>pound</td>
                            <td>&amp;pound;</td>
                            <td>&amp;#163;</td>
                        </tr>
                        <tr>
                            <td>¥</td>
                            <td>yen</td>
                            <td>&amp;yen;</td>
                            <td>&amp;#165;</td>
                        </tr>
                        <tr>
                            <td>€</td>
                            <td>euro</td>
                            <td>&amp;euro;</td>
                            <td>&amp;#8364;</td>
                        </tr>
                        <tr>
                            <td>©</td>
                            <td>copyright</td>
                            <td>&amp;copy;</td>
                            <td>&amp;#169;</td>
                        </tr>
                        <tr>
                            <td>®</td>
                            <td>trademark</td>
                            <td>&amp;reg;</td>
                            <td>&amp;#174;</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </section>
    )
}