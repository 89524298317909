import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/html/basic-tags";

export default function HTMLBasicTags() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("HTML - Basic Tags | Aspirant's Home");
        const urls = {
            'previous': '/html/editors',
            'next': '/html/elements'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>HTML - Basic Tags</h3>
            <div className='mt-4 mb-5'>
                <p>
                    HTML (HyperText Markup Language) uses various tags to define the structure and content of web pages. Each tag describes a different type of content or function within the HTML document. Here are some of the most common and essential HTML tags:
                </p>
                <h5 className='mt-4 mb-3'>What is HTML document ?</h5>
                <p>
                    An HTML (HyperText Markup Language) document is a file that contains the markup language used to structure and present content on the web. It is the foundation of web pages and is interpreted by web browsers to display text, images, and other elements.
                </p>
                <p>
                    All HTML documents must start with a document type declaration: <strong>&lt;!DOCTYPE html&gt;</strong>. The HTML document itself begins with <strong>&lt;html&gt;</strong> and ends with <strong>&lt;/html&gt;</strong>. The visible part of the HTML document is between <strong>&lt;body&gt;</strong> and <strong>&lt;/body&gt;</strong>.
                </p>
                <div className='codePalateBox mt-2 mb-5'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>&lt;!DOCTYPE <span class="color-blue">html</span>&gt;</p>
                            <p><span class="color-blue">&lt;html&gt;</span></p>
                            <p>    <span class="color-blue">&lt;body&gt;</span></p>
                            <br />    
                            <p>        <span class="color-blue">&lt;h1&gt;</span>My First Heading<span class="color-blue">&lt;/h1&gt;</span></p>
                            <br />        
                            <p>        <span class="color-blue">&lt;p&gt;</span>My first paragraph.<span class="color-blue">&lt;/p&gt;</span></p>
                            <br />    
                            <p>    <span class="color-blue">&lt;/body&gt;</span></p>
                            <p><span class="color-blue">&lt;/html&gt;</span></p>
                        `
                    }}></div>
                </div>
                <h5 className='mt-4 mb-3'>The &lt;!DOCTYPE&gt; Declaration</h5>
                <p>
                    The <strong>&lt;!DOCTYPE&gt;</strong> declaration is an important part of an HTML document. It informs the web browser about the version of HTML being used and ensures that the browser renders the page correctly. 
                </p>
                <p>
                    The <strong>&lt;!DOCTYPE&gt;</strong> declaration specifies the Document Type Definition, which is a set of rules for the HTML document's structure. This helps the browser understand how to interpret the HTML.
                </p>
                <h5 className='mt-5 mb-3'>Syntax of the &lt;!DOCTYPE&gt; Declaration</h5>
                <p>
                    The syntax for the <strong>&lt;!DOCTYPE&gt;</strong> declaration varies depending on the version of HTML:
                </p>
                <h6 className='mt-4'>HTML5</h6>
                <p>
                    HTML5 has a simple and straightforward <strong>&lt;!DOCTYPE&gt;</strong> declaration:
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-grey">&lt;!DOCTYPE</span> <span class="color-blue">html</span><span class="color-grey">&gt;</span></p>
                        `
                    }}></div>
                </div>
                <h6>HTML 4.01</h6>
                <p>
                    HTML 4.01 has three types of <strong>`&lt;!DOCTYPE&gt;`</strong> declarations: strict, transitional, and frameset.
                </p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>
                        <strong>Strict</strong>: No deprecated elements or attributes allowed.
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-grey">&lt;!DOCTYPE</span> <span class="color-blue">HTML PUBLIC</span> <span class="color-green">"-//W3C//DTD HTML 4.01//EN" "http://www.w3.org/TR/html4/strict.dtd"</span><span class="color-grey">&gt;</span></p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <strong>Transitional</strong>: Allows deprecated elements and attributes.
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-grey">&lt;!DOCTYPE</span> <span class="color-blue"> HTML PUBLIC</span> <span class="color-green"> "-//W3C//DTD HTML 4.01 Transitional//EN" "http://www.w3.org/TR/html4/loose.dtd"</span><span class="color-grey">&gt;</span></p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <strong>Frameset</strong>: Used for documents containing frames.
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p><span class="color-grey">&lt;!DOCTYPE</span> <span class="color-blue"> HTML PUBLIC</span> <span class="color-green"> "-//W3C//DTD HTML 4.01 Frameset//EN" "http://www.w3.org/TR/html4/frameset.dtd"</span><span class="color-grey">&gt;</span>
                                `
                            }}></div>
                        </div>
                    </li>
                </ul>
                <h5 className='mt-5 mb-3'>The &lt;html&gt; Tag</h5>
                <ul style={{listStyle:'disc'}}>
                    <li>
                        The <strong>`&lt;html&gt;`</strong> tag is a fundamental part of an HTML document. It serves as the root element that encapsulates all the content and elements within the HTML page. 
                    </li>
                    <li>
                        The <strong>`&lt;html&gt;`</strong> tag defines the beginning and end of an HTML document. All other elements, such as the <strong>`&lt;head&gt;`</strong> and <strong>`&lt;body&gt;`</strong> tags, are nested inside the <strong>`&lt;html&gt;`</strong> tag.
                    </li>
                    <li>
                        The <strong>`&lt;html&gt;`</strong> tag often includes the lang attribute, which specifies the language of the document’s content. This is useful for accessibility and for search engines to better understand the content.
                    </li>
                </ul>
                <h6>Syntax of the &lt;html&gt; Tag</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>&lt;html&gt;</p>
                            <p class="ml-30 color-grey">    &lt;!-- All other elements go here --&gt;</p>
                            <p>&lt;/html&gt;</p>
                        `
                    }}></div>
                </div>
                
                <h5 className='mt-5 mb-3'>The &lt;head&gt; Tag</h5>
                <p>
                    The <strong>`&lt;head&gt;`</strong> tag in an HTML document is a container for metadata (data about data) and links to external resources that are related to the document. The content inside the <strong>`&lt;head&gt;`</strong> tag is not displayed on the webpage itself, but it plays a crucial role in defining the document's properties and behavior.
                </p>
                <h6>Syntax of the &lt;head&gt; Tag</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>&lt;head&gt;</p>
                            <p class="ml-30">    &lt;title&gt;Page Title&lt;/title&gt;</p>
                            <p>&lt;/head&gt;</p>
                        `
                    }}></div>
                </div>
                <h5 className='mt-5 mb-3'>The &lt;body&gt; Tag</h5>
                <p>
                    The <strong>`&lt;body&gt;`</strong> tag contains the content of the HTML document, such as text, images, links, and other media.
                </p>
                <h6>Syntax of the &lt;body&gt; Tag</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>&lt;body&gt;</p>
                            <p class="ml-30 color-grey">    &lt;!-- Page content goes here --&gt;</p>
                            <p>&lt;/body&gt;</p>
                        `
                    }}></div>
                </div>
            </div>
        </section>
    )
}