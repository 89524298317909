import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/mongo-db/mongoose/get-started";

export default function MongooseGetStarted() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Getting Started with MongoDB | A Complete Guide");
        title.setKeyWords("how to install mongoose, how to use mongoose, how to connect mongoDB using mongoose");
        title.setPageDescription("Getting started with Mongoose involves a few key steps: setting up your environment, defining schemas and models, and performing CRUD (Create, Read, Update, Delete) operations. Here's a step-by-step guide to help you get started:");
        const urls = {
            'previous': '/mongo-db/mongoose/introduction'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>Mongoose - Getting Started</h3>
            <div className='mt-4 mb-5'>
                <p>
                    Getting started with Mongoose involves a few key steps: setting up your environment, defining schemas and models, and performing CRUD (Create, Read, Update, Delete) operations. Here's a step-by-step guide to help you get started:
                </p>
                <h5 className='mt-5'>Step 1: Install Mongoose</h5>
                <p>First, you need to install Mongoose. You can do this using npm (Node Package Manager):</p>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>npm install mongoose</p>
                        `
                    }}></div>
                </div>
                <h5 className='mt-5'>Step 2: Connect to MongoDB</h5>
                <p>
                    Next, you need to connect to your MongoDB database. Mongoose provides a straightforward way to connect using the <strong>`connect`</strong> method -
                </p>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-blue">const</span> mongoose = <span class="color-yellow">require</span>(<span class="color-green">'mongoose'</span>);</p>
                            <p>mongoose.<span class="color-red">connect</span>(<span class="color-green">'mongodb://localhost/mydatabase'</span>, { <span class="color-pink">useNewUrlParser</span>: <span class="color-blue">true</span>, <span class="color-pink">useUnifiedTopology</span>: <span class="color-blue">true</span> });</p>
                        `
                    }}></div>
                </div>
                <h5 className='mt-5'>Step 3: Define a Schema</h5>
                <p>
                    A schema defines the structure of the documents within a collection. You create a schema by using the <strong>`mongoose.Schema`</strong> class.
                </p>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-blue">const</span> userSchema = <span class="color-blue">new</span> mongoose.<span class="color-red">Schema</span>({</p>
                            <p style="margin-left:30px">    <span class="color-pink">name</span>: <span class="color-red">String</span>,</p>
                            <p style="margin-left:30px">    <span class="color-pink">email</span>: <span class="color-red">String</span>,</p>
                            <p style="margin-left:30px">    <span class="color-pink">age</span>: <span class="color-red">Number</span></p>
                            <p>});</p>
                        `
                    }}></div>
                </div>
                <h5 className='mt-5'>Step 4: Create a Model</h5>
                <p>
                    A model is a class that allows you to interact with the documents in a collection. You create a model by using the <strong>`mongoose.model`</strong> method.
                </p>
                <div className='codePalateBox mt-2 mb-5'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-blue">const</span> User = mongoose.<span class="color-red">model</span>(<span class="color-green">'User'</span>, userSchema);</p>
                        `
                    }}></div>
                </div>
                <p>
                    These are the 4 basic steps to get started and you'll be able to start working with Mongoose. Now you can easily create an document and start quering.
                </p>
                <h5 className='mt-5 mb-4'>Perform CRUD Operations</h5>
                <h6>Create</h6>
                <p>To create a new document, you can use the <strong>`save`</strong> method or the <strong>`create`</strong> method.</p>
                <div className='codePalateBox mt-2 mb-3'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-blue">const</span> newUser = <span class="color-blue">new</span> <span class="color-red">User</span>({</p>
                            <p style="margin-left:30px">    <span class="color-pink">name</span>: <span class="color-green">'Alice'</span>,</p>
                            <p style="margin-left:30px">    <span class="color-pink">email</span>: <span class="color-green">'alice@example.com'</span>,</p>
                            <p style="margin-left:30px">    <span class="color-pink">age</span>: <span class="color-pink">25</span>,</p>
                            <p>});</p>
                            <br />
                            <p>newUser.<span class="color-red">save</span>()</p>
                            <p>    .<span class="color-red">then</span>(user => <span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">'User saved:'</span>, user))</p>
                            <p>    .<span class="color-red">catch</span>(err => <span class="color-pink">console</span>.<span class="color-red">error</span>(<span class="color-green">'Error saving user:'</span>, err));</p>
                        `
                    }}></div>
                </div>
                <p>Or using <strong>`create`</strong>:</p>
                <div className='codePalateBox mt-2 mb-3'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>User.<span class="color-red">create</span>({</p>
                            <p style="margin-left:30px">    <span class="color-pink">name</span>: <span class="color-green">'Bob'</span>,</p>
                            <p style="margin-left:30px">    <span class="color-pink">email</span>: <span class="color-green">'bob@example.com'</span>,</p>
                            <p style="margin-left:30px">    <span class="color-pink">age</span>: <span class="color-pink">30</span>,</p>
                            <p>})</p>
                            <p>.<span class="color-red">then</span>(user => <span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">'User created:'</span>, user))</p>
                            <p>.<span class="color-red">catch</span>(err => <span class="color-pink">console</span>.<span class="color-red">error</span>(<span class="color-green">'Error creating user:'</span>, err));</p>
                        `
                    }}></div>
                </div>
                <h6>Read</h6>
                <p>
                    To retrieve documents, you can use the <strong>`find`</strong>, <strong>`findOne`</strong>, or <strong>`findById`</strong> methods.
                </p>
                <div className='codePalateBox mt-2 mb-5'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>User.<span class="color-red">find</span>({}, (err, users) => {</p>
                            <p style="margin-left:30px">    <span class="color-blue">if</span> (err) <span class="color-blue">return</span> <span class="color-pink">console</span>.<span class="color-red">error</span>(err);</p>
                            <p style="margin-left:30px">    <span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">'All users:'</span>, users);</p>
                            <p>});</p>
                            <br />
                            <p>User.<span class="color-red">findOne</span>({ <span class="color-pink">email</span>: <span class="color-green">'alice@example.com'</span> }, (err, user) => {</p>
                            <p style="margin-left:30px">    <span class="color-blue">if</span> (err) <span class="color-blue">return</span> <span class="color-pink">console</span>.<span class="color-red">error</span>(err);</p>
                            <p style="margin-left:30px">    <span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">'Found user:'</span>, user);</p>
                            <p>});</p>
                            <br />
                            <p>User.<span class="color-red">findById</span>(<span class="color-green">'60d5f92ee99b9b6e2f2d5a7b'</span>, (err, user) => {</p>
                            <p style="margin-left:30px">    <span class="color-blue">if</span> (err) <span class="color-blue">return</span> <span class="color-pink">console</span>.<span class="color-red">error</span>(err);</p>
                            <p style="margin-left:30px">    <span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">'Found user by ID:'</span>, user);</p>
                            <p>});</p>
                        `
                    }}></div>
                </div>
                <h6>Update</h6>
                <p>
                    To update documents, you can use the <strong>`updateOne`</strong>, <strong>`updateMany`</strong>, <strong>`findOneAndUpdate`</strong>, or <strong>`findByIdAndUpdate`</strong> methods.
                </p>
                <div className='codePalateBox mt-2 mb-5'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>User.<span class="color-red">updateOne</span>({ <span class="color-pink">email</span>: <span class="color-green">'alice@example.com'</span> }, { <span class="color-pink">age</span>: <span class="color-pink">26</span> }, (err, result) => {</p>
                            <p style="margin-left:30px">    <span class="color-blue">if</span> (err) <span class="color-blue">return</span> <span class="color-pink">console</span>.<span class="color-red">error</span>(err);</p>
                            <p style="margin-left:30px">    <span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">'Update result:'</span>, result);</p>
                            <p>});</p>
                            <br />
                            <p>User.<span class="color-red">findOneAndUpdate</span>({ <span class="color-pink">email</span>: <span class="color-green">'bob@example.com'</span> }, { <span class="color-pink">age</span>: <span class="color-pink">31</span> }, { <span class="color-pink">new</span>: <span class="color-blue">true</span> }, (err, user) => {</p>
                            <p style="margin-left:30px">    <span class="color-blue">if</span> (err) <span class="color-blue">return</span> <span class="color-pink">console</span>.<span class="color-red">error</span>(err);</p>
                            <p style="margin-left:30px">    <span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">'Updated user:'</span>, user);</p>
                            <p>});</p>
                        `
                    }}></div>
                </div>
                <h6>Delete</h6>
                <p>
                    To delete documents, you can use the <strong>`deleteOne`</strong>, <strong>`deleteMany`</strong>, <strong>`findOneAndDelete`</strong>, or <strong>`findByIdAndDelete`</strong> methods.
                </p>
                <div className='codePalateBox mt-2 mb-5'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>User.<span class="color-red">deleteOne</span>({ <span class="color-pink">email</span>: <span class="color-green">'alice@example.com'</span> }, (err) => {</p>
                            <p style="margin-left:30px">    <span class="color-blue">if</span> (err) <span class="color-blue">return</span> <span class="color-pink">console</span>.<span class="color-red">error</span>(err);</p>
                            <p style="margin-left:30px">    <span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">'User deleted'</span>);</p>
                            <p>});</p>
                            <br />
                            <p>User.<span class="color-red">findOneAndDelete</span>({ <span class="color-pink">email</span>: <span class="color-green">'bob@example.com'</span> }, (err, user) => {</p>
                            <p style="margin-left:30px">    <span class="color-blue">if</span> (err) <span class="color-blue">return</span> <span class="color-pink">console</span>.<span class="color-red">error</span>(err);</p>
                            <p style="margin-left:30px">    <span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">'Deleted user:'</span>, user);</p>
                            <p>});</p>
                        `
                    }}></div>
                </div>
            </div>
        </section>
    )
}