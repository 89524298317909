import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';
export const pageUrl = () => "/mongo-db/query-operators";

export default function MongoQueryOperators() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Query Operators | A complete list of Query Operators in MongoDB ");
        title.setKeyWords("query operators in mongodb, evaluation query operators in mongodb, element query operators in mongodb, logical query operators in mongodb, mongodb or query example, mongodb query operators examples, mongodb query criteria example, query operators, query operator mongodb array, query and projection operators in mongodb, query operators mongodb, mongodb query and operator example, mongodb query with in operator, comparison query operator in mongodb, mongodb query operators, mongodb eq operator, mongodb query options, mongodb query nin");
        title.setPageDescription("MongoDB provides a variety of query operators to help you perform complex queries on your data. These operators allow you to specify criteria for selecting documents from a collection. Below are some of the most commonly used query operators:");
        path.setPreviousNext({});
    }, [])

    return (
        <>
            <section class='clearfix mb-3'>
                <span className='float-start'>
                    <Link to='/mongo-db/query-document' onClick={() => path.setPathName('/mongo-db/query-document')} className='btn btn-primary'> <i class="fa-solid fa-less-than"></i>&nbsp; Back to Query Document </Link>
                </span>
            </section>
            <section className='mt-5 mb-5'>
                <h3>MongoDB - Query Operators</h3>
                <p>
                    MongoDB provides a variety of query operators to help you perform complex queries on your data. These operators allow you to specify criteria for selecting documents from a collection. Below are some of the most commonly used query operators:
                </p>
                <h5 className='mt-5 mb-4'>Comparison Operators</h5>
                <ul style={{listStyle:'disc'}}>
                    <li>
                        <strong>`$eq`</strong>: Matches values that are equal to a specified value.
                        <div className='codePalateBox mt-2 mb-2'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p>db.collection.<span class="color-red">find</span>({ <span class="color-pink">age</span>: { <span class="color-pink">$eq</span>: <span class="color-pink">25</span> } })</p>
                                `
                            }}></div>
                        </div>
                        <p>This finds documents where the <strong>`age`</strong> field is 25.</p>
                    </li>
                    <li>
                        <strong>`$ne`</strong>: Matches all values that are not equal to a specified value.
                        <div className='codePalateBox mt-2 mb-2'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p>db.collection.<span class="color-red">find</span>({ <span class="color-pink">age</span>: { <span class="color-pink">$ne</span>: <span class="color-pink">25</span> } })</p>
                                `
                            }}></div>
                        </div>
                        <p>This finds documents where the <strong>`age`</strong> field is not 25.</p>
                    </li>
                    <li>
                        <strong>`$gt`</strong>: Matches values that are greater than a specified value.
                        <div className='codePalateBox mt-2 mb-2'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p>db.collection.<span class="color-red">find</span>({ <span class="color-pink">age</span>: { <span class="color-pink">$gt</span>: <span class="color-pink">25</span> } })</p>
                                `
                            }}></div>
                        </div>
                        <p>This finds documents where the <strong>`age`</strong> field is greater than 25.</p>
                    </li>
                    <li>
                        <strong>`$gte`</strong>: Matches values that are greater than or equal to a specified value.
                        <div className='codePalateBox mt-2 mb-2'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p>db.collection.<span class="color-red">find</span>({ <span class="color-pink">age</span>: { <span class="color-pink">$gte</span>: <span class="color-pink">25</span> } })</p>
                                `
                            }}></div>
                        </div>
                        <p>This finds documents where the <strong>`age`</strong> field is greater than or equal to 25.</p>
                    </li>
                    <li>
                        <strong>`$lt`</strong>: Matches values that are less than a specified value.
                        <div className='codePalateBox mt-2 mb-2'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p>db.collection.<span class="color-red">find</span>({ <span class="color-pink">age</span>: { <span class="color-pink">$lt</span>: <span class="color-pink">25</span> } })</p>
                                `
                            }}></div>
                        </div>
                        <p>This finds documents where the <strong>`age`</strong> field is less than 25.</p>
                    </li>
                    <li>
                        <strong>`$lte`</strong>: Matches values that are less than or equal to a specified value.
                        <div className='codePalateBox mt-2 mb-2'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p>db.collection.<span class="color-red">find</span>({ <span class="color-pink">age</span>: { <span class="color-pink">$lte</span>: <span class="color-pink">25</span> } })</p>
                                `
                            }}></div>
                        </div>
                        <p>This finds documents where the <strong>`age`</strong> field is less than or equal to 25.</p>
                    </li>
                    <li>
                        <strong>`$in`</strong>: Matches any of the values specified in an array.
                        <div className='codePalateBox mt-2 mb-2'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p>db.collection.<span class="color-red">find</span>({ <span class="color-pink">age</span>: { <span class="color-pink">$in</span>: [<span class="color-pink">20</span>, <span class="color-pink">25</span>, <span class="color-pink">30</span>] } })</p>
                                `
                            }}></div>
                        </div>
                        <p>This finds documents where the <strong>`age`</strong> field is either 20, 25, or 30.</p>
                    </li>
                    <li>
                        <strong>`$nin`</strong>: Matches none of the values specified in an array.
                        <div className='codePalateBox mt-2 mb-2'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p>db.collection.<span class="color-red">find</span>({ <span class="color-pink">age</span>: { <span class="color-pink">$nin</span>: [<span class="color-pink">20</span>, <span class="color-pink">25</span>, <span class="color-pink">30</span>] } })</p>
                                `
                            }}></div>
                        </div>
                        <p>This finds documents where the <strong>`age`</strong> field is neither 20, 25, nor 30.</p>
                    </li>
                </ul>
                <h5 className='mt-5 mb-4'>Logical Operators</h5>
                <ul style={{listStyle:'disc'}}>
                    <li>
                        <strong>`$and`</strong>: Joins query clauses with a logical AND returns all documents that match the conditions of both clauses.
                        <div className='codePalateBox mt-2 mb-2'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p>db.collection.<span class="color-red">find</span>({ <span class="color-pink">$and</span>: [{ <span class="color-pink">age</span>: { <span class="color-pink">$gt</span>: <span class="color-pink">20</span> } }, { <span class="color-pink">age</span>: { <span class="color-pink">$lt</span>: <span class="color-pink">30</span> } }] })</p>
                                `
                            }}></div>
                        </div>
                        <p>This finds documents where the <strong>`age`</strong> field is greater than 20 and less than 30.</p>
                    </li>
                    <li>
                        <strong>`$or`</strong>: Joins query clauses with a logical OR returns all documents that match the conditions of either clause.
                        <div className='codePalateBox mt-2 mb-2'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p>db.collection.<span class="color-red">find</span>({ <span class="color-pink">$or</span>: [{ <span class="color-pink">age</span>: { <span class="color-pink">$lt</span>: <span class="color-pink">20</span> } }, { <span class="color-pink">age</span>: { <span class="color-pink">$gt</span>: <span class="color-pink">30</span> } }] })</p>
                                `
                            }}></div>
                        </div>
                        <p>This finds documents where the <strong>`age`</strong> field is either less than 20 or greater than 30.</p>
                    </li>
                    <li>
                        <strong>`$not`</strong>: Inverts the effect of a query expression and returns documents that do not match the query expression.
                        <div className='codePalateBox mt-2 mb-2'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p>db.collection.<span class="color-red">find</span>({ <span class="color-pink">age</span>: { <span class="color-pink">$not</span>: { <span class="color-pink">$gt</span>: <span class="color-pink">25</span> } } })</p>
                                `
                            }}></div>
                        </div>
                        <p>This finds documents where the <strong>`age`</strong> field is not greater than 25.</p>
                    </li>
                    <li>
                        <strong>`$nor`</strong>: Joins query clauses with a logical NOR returns all documents that fail to match both clauses.
                        <div className='codePalateBox mt-2 mb-2'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p>db.collection.<span class="color-red">find</span>({ <span class="color-pink">$nor</span>: [{ <span class="color-pink">age</span>: { <span class="color-pink">$gt</span>: <span class="color-pink">20</span> } }, { <span class="color-pink">age</span>: { <span class="color-pink">$lt</span>: <span class="color-pink">30</span> } }] })</p>
                                `
                            }}></div>
                        </div>
                        <p>This finds documents where the <strong>`age`</strong> field is neither greater than 20 nor less than 30.</p>
                    </li>
                </ul>
                <h5 className='mt-5 mb-4'>Element Operators</h5>
                <ul style={{ listStyle: 'disc' }}>
                    <li>
                        <strong>`$exists`</strong>: Matches documents that have the specified field.
                        <div className='codePalateBox mt-2 mb-2'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p>db.collection.<span class="color-red">find</span>({ <span class="color-pink">phone</span>: { <span class="color-pink">$exists</span>: <span class="color-blue">true</span> } })</p>
                                `
                            }}></div>
                        </div>
                        <p>This finds documents that have the <strong>`phone`</strong> field.</p>
                    </li>
                    <li>
                        <strong>`$type`</strong>: Selects documents if a field is of the specified type.
                        <div className='codePalateBox mt-2 mb-2'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p>db.collection.<span class="color-red">find</span>({ <span class="color-pink">age</span>: { <span class="color-pink">$type</span>: <span class="color-green">"int"</span> } })</p>
                                `
                            }}></div>
                        </div>
                        <p>This finds documents where the <strong>`age`</strong> field is of type integer.</p>
                    </li>
                </ul>
                <h5 className='mt-5 mb-4'>Array Operators</h5>
                <ul style={{ listStyle: 'disc' }}>
                    <li>
                        <strong>`$all`</strong>: Matches arrays that contain all elements specified in the query.
                        <div className='codePalateBox mt-2 mb-2'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p>db.collection.<span class="color-red">find</span>({ <span class="color-pink">tags</span>: { <span class="color-pink">$all</span>: [<span class="color-green">"tech"</span>, <span class="color-pink">"mongodb"</span>] } })</p>
                                `
                            }}></div>
                        </div>
                        <p>This finds documents where the <strong>`tags`</strong> array contains both "tech" and "mongodb".</p>
                    </li>
                    <li>
                        <strong>`$elemMatch`</strong>: Matches documents that contain an array field with at least one element that matches all the specified query criteria.
                        <div className='codePalateBox mt-2 mb-2'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p>db.collection.<span class="color-red">find</span>({ <span class="color-pink">results</span>: { <span class="color-pink">$elemMatch</span>: { <span class="color-pink">score</span>: { <span class="color-pink">$gt</span>: <span class="color-pink">80</span> }, <span class="color-pink">subject</span>: <span class="color-green">"math"</span> } } })</p>
                                `
                            }}></div>
                        </div>
                        <p>This finds documents where the <strong>`results`</strong> array contains at least one element with <strong>`score`</strong> greater than 80 and <strong>`subject`</strong> equal to "math".</p>
                    </li>
                    <li>
                        <strong>`$size`</strong>: Matches any array with the specified number of elements.
                        <div className='codePalateBox mt-2 mb-2'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p>db.collection.<span class="color-red">find</span>({ <span class="color-pink">tags</span>: { <span class="color-pink">$size</span>: <span class="color-pink">3</span> } })</p>
                                `
                            }}></div>
                        </div>
                        <p>This finds documents where the <strong>`tags`</strong> array has exactly 3 elements.</p>
                    </li>
                </ul>
                <h5 className='mt-5 mb-4'>Evaluation Operators</h5>
                <ul style={{ listStyle: 'disc' }}>
                    <li>
                        <strong>`$regex`</strong>: Selects documents where values match a specified regular expression.
                        <div className='codePalateBox mt-2 mb-2'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p>db.collection.<span class="color-red">find</span>({ <span class="color-pink">name</span>: { <span class="color-pink">$regex</span>: <span class="color-green">/^J/</span>, <span class="color-pink">$options</span>: <span class="color-green">'i'</span> } })</p>
                                `
                            }}></div>
                        </div>
                        <p>This finds documents where the <strong>`name`</strong> field starts with the letter "J", case insensitive.</p>
                    </li>
                    <li>
                        <strong>`$expr`</strong>: Allows the use of aggregation expressions within the query language.
                        <div className='codePalateBox mt-2 mb-2'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p>db.collection.<span class="color-red">find</span>({ <span class="color-pink">$expr</span>: { <span class="color-pink">$gt</span>: [<span class="color-green">"$spend"</span>, <span class="color-green">"$budget"</span>] } })</p>
                                `
                            }}></div>
                        </div>
                        <p>This finds documents where the <strong>`spend`</strong> field is greater than the budget field.</p>
                    </li>
                    <li>
                        <strong>`$mod`</strong>: Performs a modulo operation on the value of a field and selects documents with a specified result.
                        <div className='codePalateBox mt-2 mb-2'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p>db.collection.<span class="color-red">find</span>({ <span class="color-pink">age</span>: { <span class="color-pink">$mod</span>: [<span class="color-pink">4</span>, <span class="color-pink">0</span>] } })</p>
                                `
                            }}></div>
                        </div>
                        <p>This finds documents where the <strong>`age`</strong> field is divisible by 4.</p>
                    </li>
                </ul>
            </section>
        </>
    )
}