import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/mysql/drop-tables";

export default function MysqlDropTable() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Mysql - Drop Table | Aspirant's Home");
        const urls = {
            'previous': '/mysql/repair-tables',
            'next': '/mysql/insert-query'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>Mysql - Drop Table</h3>
            <div className='mt-4 mb-5'>
                <p>
                    In MySQL, the <strong>`DROP TABLE`</strong> statement is used to delete a table and all its data and indexes from the database. Here's the basic syntax:
                </p>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-blue">DROP TABLE</span> table_name;</p>
                        `
                    }}></div>
                </div>
                <p>
                    For example, to delete a table named <strong>`my_table`</strong>
                </p>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-blue">DROP TABLE</span> my_table;</p>
                        `
                    }}></div>
                </div>
                <p>
                    When you drop a table, it cannot be recovered, so it's important to use this statement carefully. Make sure to back up any data you want to keep before dropping a table.
                </p>
            </div>
            <div className='mt-5 mb-5'>
                <h5>The IF EXISTS clause</h5>
                <div className='mt-2 mb-2'>
                    <p>
                        If you try to drop a table that doesn’t exist error will be generated as shown below −
                    </p>
                    <div className='codePalateBox mt-2 mb-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                                <p><span class="color-blue">DROP TABLE</span> my_table;</p>
                                <p>ERROR 1051 (42S02): Unknown table 'mydatabase.my_table'</p>
                            `
                        }}></div>
                    </div>
                    <p>
                        If you use the <strong>`IF EXISTS`</strong> clause along with the DROP TABLE statement as shown below, the specified table will be dropped and if a table with the given name, doesn’t exist the query will be ignored.
                    </p>
                    <div className='codePalateBox mt-2 mb-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                                <p><span class="color-blue">DROP TABLE</span> IF EXISTS my_table;</p>
                            `
                        }}></div>
                    </div>
                </div>
            </div>
        </section>
    )
}