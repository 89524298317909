import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/mysql/rename-views";

export default function MysqlRenameViews() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Mysql - Rename Views | Aspirant's Home");
        const urls = {
            'previous': '/mysql/drop-views',
            'next': '/mysql/create-index'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>Mysql - Rename Views</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The MySQL <strong>`RENAME TABLE`</strong> statement in MySQL is generally used to rename the name of a table. But this statement can also be used to rename views because views are typically virtual tables created by a query.
                </p>
                <p>
                    Following is the basic syntax of the <strong>`RENAME TABLE`</strong> query to rename a view in MySQL −
                </p>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-blue">RENAME TABLE</span> youngster <span class="color-blue">TO</span> young_group;</p>
                        `
                    }}></div>
                </div>
                <p>
                    It's important to note that this statement only changes the name of the table; it does not change the structure or contents of the table. Any indexes, triggers, or other database objects associated with the table will remain unchanged.
                </p>
                <p>
                    After renaming the view, you can query it like a regular table:
                </p>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-blue">SELECT</span> * <span class="color-blue">FROM</span> young_group;</p>
                        `
                    }}></div>
                </div>
            </div>
        </section>
    )
}