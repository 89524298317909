import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../../Context";
export const pageUrl = () => "/react-js/hooks/useref";

export default function ReactHookUseRef() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("React Hooks - useRef() Hooks | Aspirant's Home");
        const urls = {
            'previous': '/react-js/hooks/usecontext',
            'next':'/react-js/hooks/usereducer'
        }
        path.setPreviousNext(urls);
    },[])

    return (
        <section className='mt-5 mb-5'>
            <h3>React useRef Hook</h3>
            <div className='mt-4 mb-4'>
                <p>The <strong>`useRef`</strong> hook in React allows you to create a mutable object that persists for the entire lifetime of the component. It's commonly used to access DOM elements or to store mutable values that don't trigger re-renders when they change.</p>
            </div>
            <div className='mt-4 mb-4'>
                <h5>Creating a Ref</h5>
                <div className='mt-2 mb-2'>
                    <p>You create a ref by calling <strong>`useRef()`</strong> and storing the result in a variable.</p>
                    <div className='codePalateBox mt-2 mb-5'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                                <p><span class="color-blue">const</span> inputRef = <span class="color-red">useRef</span>(null);</p>
                            `
                        }}></div>
                    </div>
                </div>
            </div>
            <div className='mt-4 mb-4'>
                <h5>Accessing DOM Elements</h5>
                <div className='mt-2 mb-2'>
                    <p>You can use a ref to access DOM elements directly. For example, you can focus an input element using <strong>`ref.current.focus()`</strong>.</p>
                    <div className='codePalateBox mt-2 mb-5'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                                <p><span class="color-blue">import</span> <span class="color-red">React</span>, { <span class="color-red">useRef</span> } <span class="color-blue">from</span> <span class="color-green">'react'</span>;</p>
                                <br />
                                <p><span class="color-blue">function</span> <span class="color-red">MyComponent</span> = () => {</p>
                                <p style="margin-left:30px">    <span class="color-blue">const</span> inputRef = <span class="color-red">useRef</span>(null);</p>
                                <br />    
                                <p style="margin-left:30px">    <span class="color-blue">const</span> handleClick = () => {</p>
                                <p style="margin-left:60px">        <span class="color-grey">// Focus on the input field when the button is clicked</span></p>
                                <p style="margin-left:60px">        inputRef.current.<span class="color-red">focus</span>();</p>
                                <p style="margin-left:30px">    };</p>
                                <br />    
                                <p style="margin-left:30px">    <span class="color-blue">return</span> (</p>
                                <p style="margin-left:60px">        &lt;div&gt;</p>
                                <p style="margin-left:90px">             &lt;input type="text" ref={inputRef} /&gt;</p>
                                <p style="margin-left:90px">             &lt;button <span class="color-pink">onClick</span>=<span class="color-green">{handleClick}</span>&gt;Focus Input&lt;/button&gt;</p>
                                <p style="margin-left:60px">        &lt;/div&gt;</p>
                                <p style="margin-left:30px">    );</p>
                                <p>};</p>
                                <br />
                                <p><span class="color-blue">export default</span> <span class="color-red">MyComponent</span>;</p>
                            
                            `
                        }}></div>
                    </div>
                </div>
            </div>
            <div className='mt-4 mb-4'>
                <h5>Storing Mutable Values</h5>
                <div className='mt-2 mb-2'>
                    <p>
                        You can also use a ref to store mutable values that won't trigger a re-render when they change. This is useful for storing values that are needed for computation but don't affect the UI.
                    </p>
                    <div className='ml-4 mt-2 mb-2 featureClass'>
                        <h5>What is Mutable Object ?</h5>
                        <p>
                            A mutable object is an object whose state or value can be changed after it is created. In programming, objects are considered mutable if they can be modified by changing their properties or elements. This means that you can update the object's state without creating a new object.
                        </p>
                        <p>
                            In JavaScript, objects, arrays, and functions are mutable by default, which means you can change their properties or elements directly. For example, you can add a new key-value pair to an object, push a new element into an array, or modify the properties of an object.
                        </p>
                    </div>
                    <div className='codePalateBox mt-5 mb-5'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                                <p><span class="color-blue">import</span> <span class="color-red">React</span>, { <span class="color-red">useRef</span>, <span class="color-red">useState</span> } <span class="color-blue">from</span> <span class="color-green">'react'</span>;</p>
                                <br />
                                <p><span class="color-blue">function</span> <span class="color-red">MutableValueExample</span>() {</p>
                                <p style="margin-left:30px">    <span class="color-blue">const</span> [count, setCount] = <span class="color-red">useState</span>(0);</p>
                                <p style="margin-left:30px">    <span class="color-blue">const</span> previousCountRef = <span class="color-red">useRef</span>(null);</p>
                                <br />    
                                <p style="margin-left:30px">    <span class="color-grey">// Update the previousCountRef value whenever count changes</span></p>
                                <p style="margin-left:30px">    previousCountRef.current = count;</p>
                                <br />    
                                <p style="margin-left:30px">    <span class="color-blue">const</span> <span class="color-red">handleIncrement</span> = () => {</p>
                                <p style="margin-left:60px">        <span class="color-red">setCount</span>(count + 1);</p>
                                <p style="margin-left:30px">    };</p>
                                <br />    
                                <p style="margin-left:30px">    <span class="color-blue">return</span> (</p>
                                <p style="margin-left:60px">        &lt;div&gt;</p>
                                <p style="margin-left:90px">            &lt;p&gt;Current Count: {count}&lt;/p&gt;</p>
                                <p style="margin-left:90px">            &lt;p&gt;Previous Count: {previousCountRef.current}&lt;/p&gt;</p>
                                <p style="margin-left:90px">            &lt;button <span class="color-pink">onClick</span>=<span class="color-green">{handleIncrement}</span>&gt;Increment Count&lt;/button&gt;</p>
                                <p style="margin-left:60px">        &lt;/div&gt;</p>
                                <p style="margin-left:30px">    );</p>
                                <p>}</p>
                                <br />
                                <p><span class="color-blue">export default</span> <span class="color-red">MutableValueExample</span>;</p>
                            
                            `
                        }}></div>
                    </div>
                    <p>
                        In this example, <strong>`previousCountRef`</strong> is a ref that is used to store the previous value of <strong>`count`</strong>. When the <strong>`count`</strong> state changes, the <strong>`previousCountRef`</strong> value is updated without causing a re-render of the component. This allows you to keep track of the previous value of <strong>`count`</strong> for comparison purposes.
                    </p>
                </div>
            </div>
            <div className='mt-4 mb-4'>
                <h5>Preserving Values Between Renders</h5>
                <div className='mt-2 mb-2'>
                    <p>
                        The value of a ref persists between renders. This means that you can update the ref's value without triggering a re-render of the component.
                    </p>
                </div>
            </div>
        </section>
    )
}
