import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/react-js/defining-router";

export default function DefineRouters() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("React - Defining Routers | Aspirant's Home");
        const urls = {
            'previous': '/react-js/router',
            'next':'/react-js/navigating-between-pages-with-links'
        }
        path.setPreviousNext(urls);
    },[])

    return (
        <section className='mt-5 mb-5'>
            <h3>Defining Routes</h3>
            <div className='mt-4 mb-4'>
                <p>We have to import <strong>createBrowserRouter</strong> from `react-router-dom`</p>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-blue">import</span> { <span class="color-red">createBrowserRouter</span>, <span class="color-red">RouterProvider</span> } <span class="color-blue">from</span> <span class="color-green">'react-router-dom'</span>;</p>
                            <br />
                            <p><span class="color-blue">import</span> <span class="color-red">HomePage</span> <span class="color-blue">from</span> <span class="color-green">'./components/Home'</span>;</p>
                            <p><span class="color-blue">import</span> <span class="color-red">AboutPage</span> <span class="color-blue">from</span> <span class="color-green">'./components/About'</span>;</p>
                            <br />
                            <p><span class="color-blue">const</span> router = <span class="color-red">createBrowserRouter</span>([</p>
                            <p style="margin-left:30px">    { <span class="color-pink">path</span> : <span class="color-green">'/'</span>, <span class="color-pink">element</span> : &lt;<span class="color-red">HomePage</span> /&gt; },</p>
                            <p style="margin-left:30px">    { <span class="color-pink">path</span> : <span class="color-green">'/about'</span>, <span class="color-pink">element</span> : &lt;<span class="color-red">AboutPage</span> /&gt; }</p>
                            <p>])</p>
                            <br />
                            <p><span class="color-blue">function</span> <span class="color-red">App</span>() </p>
                            <p>{</p>
                            <p style="margin-left:30px">    <span class="color-blue">return</span> &lt;<span class="color-red">RouterProvider</span> router=<span class="color-green">{router}</span> /></p>
                            <p>}</p>
                            <br />
                            <p><span class="color-blue">export default</span> <span class="color-red">App</span>;</p>
                        `
                    }}></div>
                </div> 
                <p>
                    Using <strong>`createBrowserRouter`</strong>, we have build the routes in the form of array of objects. In the object the <strong>`path`</strong> key specifies the URL path that should match for the route to be rendered. The <strong>`element`</strong> key specifies the component that should be rendered when the route matches. For example, when the path is <strong>`/`</strong>, the <strong>`HomePage`</strong> component will be rendered.
                </p>
                <p>
                    In <strong>`RouterProvider`</strong>, we pass the created routes object in the <strong>`router`</strong> props.
                </p>
            </div>
            <div className='mt-5 mb-4'>
                <h5>An Alternative Way of Defining Routes</h5>
                <div className='mt-4 mb-4'>
                    <p>You can also do the routing using <strong>BrowserRouter</strong> from `react-router-dom`.</p>
                    <div className='codePalateBox mt-2 mb-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                                <p><span class="color-blue">import</span> <span class="color-red">React</span> <span class="color-blue">from</span> <span class="color-green">'react'</span>;</p>
                                <p><span class="color-blue">import</span> { <span class="color-red">BrowserRouter</span> <span class="color-blue">as</span> <span class="color-red">Router</span>, <span class="color-red">Route</span> } <span class="color-blue">from</span> <span class="color-green">'react-router-dom'</span>;</p>
                                <p><span class="color-blue">import</span> <span class="color-red">HomePage</span> <span class="color-blue">from</span> <span class="color-green">'./components/Home'</span>;</p>
                                <p><span class="color-blue">import</span> <span class="color-red">AboutPage</span> <span class="color-blue">from</span> <span class="color-green">'./components/About'</span>;</p>
                                <br />
                                <p><span class="color-blue">function</span> <span class="color-red">App</span>() {</p>
                                <p style="margin-left:30px;">    <span class="color-blue">return</span> (</p>
                                <p style="margin-left:60px;">        &lt;<span class="color-red">Router</span>&gt;</p>
                                <p style="margin-left:90px;">        &lt;<span class="color-red">Route</span> <span class="color-pink">path</span>=<span class="color-green">"/"</span> <span class="color-pink">exact element</span>=<span class="color-green">{<HomePage />}</span> /&gt;</p>
                                <p style="margin-left:90px;">        &lt;<span class="color-red">Route</span> <span class="color-pink">path</span>=<span class="color-green">"/about"</span> <span class="color-pink">element</span>=<span class="color-green">{<AboutPage />}</span> /&gt;</p>
                                <p style="margin-left:60px;">        &lt;/<span class="color-red">Router</span>&gt;</p>
                                <p style="margin-left:30px;">    );</p>
                                <p>}</p>
                                <br />
                                <p><span class="color-blue">export default</span> <span class="color-red">App</span>;</p>
                            `
                        }}></div>
                    </div> 
                    <p>
                        The <strong>`&lt;Router&gt;`</strong> component is the root component that wraps the entire application and provides the routing functionality.
                    </p>
                    <p>
                        Inside the <strong>`&lt;Router&gt;`</strong> component, there are two <strong>`&lt;Route&gt;`</strong> components. Each <strong>`&lt;Route&gt;`</strong> component defines a route in the application.
                        The <strong>`path`</strong> prop specifies the URL path that should match for the route to be rendered.
                        The <strong>`element`</strong> prop specifies the component that should be rendered when the route matches. For example, when the path is <strong>`/`</strong>, the <strong>`HomePage`</strong> component will be rendered.
                    </p>
                    <p><strong>Route Definitions:</strong></p>
                    <p>
                        <strong>`/`</strong> (Home Route): The exact prop is used to ensure that the HomePage component is only rendered when the path is exactly <strong>`/`</strong>. Without the exact prop, the HomePage component would also be rendered for paths like <strong>`/about`</strong>.
                    </p>
                    <p>
                        <strong>`/about`</strong> (About Route): When the path is <strong>`/about`</strong>, the <strong>`AboutPage`</strong> component will be rendered.
                    </p>
                </div>
            </div>
        </section>
    )
}