import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/html/formatting";

export default function HTMLFormatting() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("HTML - Formatting | Aspirant's Home");
        const urls = {
            'previous': '/html/blocks',
            'next': '/html/quotations'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>HTML - Formatting</h3>
            <div className='mt-4 mb-5'>
                <p>
                    HTML formatting refers to the use of HTML tags to apply stylistic and structural features to text within a webpage. These tags help improve the readability and presentation of the content by emphasizing certain parts of the text or organizing it in a specific way. Here are some common HTML formatting elements and their purposes:
                </p>
                <h5 className='mt-5 mb-3'>Common HTML Formatting Elements</h5>
                <ul style={{ listStyle: 'decimal' }}>
                    <li>
                        <strong>Bold Text</strong>
                        <ul className='mt-2' style={{ listStyle: 'disc' }}>
                            <li><strong>`&lt;b&gt;`</strong>: Bold text without implying any extra importance.</li>
                            <li><strong>`&lt;strong&gt;`</strong>: Bold text with semantic importance, indicating that the text is of strong importance.</li>
                        </ul>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p>&lt;p&gt;This is a &lt;b&gt;bold&lt;/b&gt; word.</p>
                                    <p>&lt;p&gt;This is a &lt;strong&gt;strongly emphasized&lt;/strong&gt; word.&lt;/p&gt;</p>
                                `
                            }}></div>
                        </div>
                        <p>
                            Output will be following -
                        </p>
                        <div className='outputPalateBox  mb-4'>
                            <div className='outputPalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p>This is a <b>bold</b> word.</p>
                                    <p>This is a <strong>strongly emphasized</strong> word.</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <strong>Italic Text</strong>
                        <ul className='mt-2' style={{ listStyle: 'disc' }}>
                            <li><strong>`&lt;i&gt;`</strong>: Italic text without implying any extra emphasis.</li>
                            <li><strong>`&lt;em&gt;`</strong>: Italic text with semantic emphasis, indicating that the text is emphasized.</li>
                        </ul>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p>&lt;p&gt;This is an &lt;i&gt;italic&lt;/i&gt; word.&lt;/p&gt;</p>
                                    <p>&lt;p&gt;This is an &lt;em&gt;emphasized&lt;/em&gt; word.&lt;/p&gt;</p>
                                `
                            }}></div>
                        </div>
                        <p>
                            Output will be following -
                        </p>
                        <div className='outputPalateBox  mb-4'>
                            <div className='outputPalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p>This is an <i>italic</i> word.</p>
                                    <p>This is an <em>emphasized</em> word.</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <strong>Underline Text</strong>
                        <ul className='mt-2' style={{ listStyle: 'disc' }}>
                            <li><strong>`&lt;u&gt;`</strong>: Underlined text</li>
                        </ul>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p>&lt;p&gt;This is an &lt;u&gt;underlined&lt;/u&gt; word.&lt;/p&gt;</p>
                                `
                            }}></div>
                        </div>
                        <p>
                            Output will be following -
                        </p>
                        <div className='outputPalateBox  mb-4'>
                            <div className='outputPalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p>This is an <u>underlined</u> word.</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <strong>Strikethrough Text</strong>
                        <ul className='mt-2' style={{ listStyle: 'disc' }}>
                            <li><strong>`&lt;s&gt;`</strong>: Strikethrough text (use <strong>`&lt;del&gt;`</strong> for deleted text for better semantics)</li>
                        </ul>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p>&lt;p&gt;This is a &lt;s&gt;strikethrough&lt;/s&gt; word.&lt;/p&gt;</p>
                                `
                            }}></div>
                        </div>
                        <p>
                            Output will be following -
                        </p>
                        <div className='outputPalateBox  mb-4'>
                            <div className='outputPalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p>This is a <s>strikethrough</s> word.</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <strong>Small Text</strong>
                        <ul className='mt-2' style={{ listStyle: 'disc' }}>
                            <li><strong>`&lt;small&gt;`</strong>: Smaller text, often used for fine print or disclaimers.</li>
                        </ul>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p>&lt;p&gt;This is a &lt;small&gt;small&lt;/small&gt; text.&lt;/p&gt;</p>
                                `
                            }}></div>
                        </div>
                        <p>
                            Output will be following -
                        </p>
                        <div className='outputPalateBox  mb-4'>
                            <div className='outputPalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p>This is a <small>small</small> text.</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <strong>Superscript and Subscript</strong>
                        <ul className='mt-2' style={{ listStyle: 'disc' }}>
                            <li><strong>`&lt;sup&gt;`</strong>: Superscript text, typically used for footnotes or mathematical exponents.</li>
                            <li><strong>`&lt;sub&gt;`</strong>: Subscript text, typically used for chemical formulas or mathematical indices.</li>
                        </ul>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p>&lt;p&gt;This is a&lt;sup&gt;superscript&lt;/sup&gt; text.&lt;/p&gt;</p>
                                    <p>&lt;p&gt;This is a&lt;sub&gt;subscript&lt;/sub&gt; text.&lt;/p&gt;</p>
                                `
                            }}></div>
                        </div>
                        <p>
                            Output will be following -
                        </p>
                        <div className='outputPalateBox  mb-4'>
                            <div className='outputPalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p>This is a<sup>superscript</sup> text.</p>
                                    <p>This is a<sub>subscript</sub> text.</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <strong>Marked Text</strong>
                        <ul className='mt-2' style={{ listStyle: 'disc' }}>
                            <li><strong>`&lt;mark&gt;`</strong>: Highlighted text.</li>
                        </ul>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p>&lt;p&gt;This is a &lt;mark&gt;marked&lt;/mark&gt; text.&lt;/p&gt;</p>
                                `
                            }}></div>
                        </div>
                        <p>
                            Output will be following -
                        </p>
                        <div className='outputPalateBox  mb-4'>
                            <div className='outputPalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p>This is a <mark>marked</mark> text.</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <strong>Deleted and Inserted Text</strong>
                        <ul className='mt-2' style={{ listStyle: 'disc' }}>
                            <li><strong>`&lt;del&gt;`</strong>: Deleted text.</li>
                            <li><strong>`&lt;ins&gt;`</strong>: Inserted text</li>
                        </ul>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p>&lt;p&gt;This is &lt;del&gt;deleted&lt;/del&gt; text.&lt;/p&gt;</p>
                                    <p>&lt;p&gt;This is &lt;ins&gt;inserted&lt;/ins&gt; text.&lt;/p&gt;</p>
                                `
                            }}></div>
                        </div>
                        <p>
                            Output will be following -
                        </p>
                        <div className='outputPalateBox  mb-4'>
                            <div className='outputPalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p>This is <del>deleted</del> text.</p>
                                    <p>This is <ins>inserted</ins> text.</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <strong>Code and Preformatted Text</strong>
                        <ul className='mt-2' style={{ listStyle: 'disc' }}>
                            <li><strong>`&lt;code&gt;`</strong>: Inline code snippets.</li>
                            <li><strong>`&lt;pre&gt;`</strong>: Preformatted text, preserving whitespace and line breaks.</li>
                        </ul>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p>&lt;p&gt;This is an example of &lt;code&gt;inline code&lt;/code&gt;.&lt;/p&gt;</p>
                                    <p>&lt;pre&gt;</p>
                                    <p>This is a block of preformatted text.</p>
                                    <p>It preserves whitespace and line breaks.</p>
                                    <p>&lt;/pre&gt;</p>
                                `
                            }}></div>
                        </div>
                        <p>
                            Output will be following -
                        </p>
                        <div className='outputPalateBox  mb-4'>
                            <div className='outputPalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p>This is an example of <code>inline code</code>.</p>
                                    <pre>
                                    This is a block of preformatted text.
                                    It preserves whitespace and line breaks.
                                    </pre>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <strong>Quotation and Citation</strong>
                        <ul className='mt-2' style={{ listStyle: 'disc' }}>
                            <li><strong>`&lt;blockquote&gt;`</strong>: Block-level quote.</li>
                            <li><strong>`&lt;q&gt;`</strong>: Inline quote.</li>
                            <li><strong>`&lt;cite&gt;`</strong>: Citation, often used for titles of works.</li>
                        </ul>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p>&lt;blockquote&gt;</p>
                                    <p class="ml-30">    This is a blockquote.</p>
                                    <p>&lt;/blockquote&gt;</p>
                                    <p>&lt;p&gt;This is an &lt;q&gt;inline quote&lt;/q&gt;.&lt;/p&gt;</p>
                                    <p>&lt;p&gt;This is a &lt;cite&gt;citation&lt;/cite&gt;.&lt;/p&gt;</p>
                                `
                            }}></div>
                        </div>
                        <p>
                            Output will be following -
                        </p>
                        <div className='outputPalateBox  mb-4'>
                            <div className='outputPalate' dangerouslySetInnerHTML={{
                                __html: `
                                <blockquote>
                                    This is a blockquote.
                                </blockquote>
                                <p>This is an <q>inline quote</q>.</p>
                                <p>This is a <cite>citation</cite>.</p>
                                `
                            }}></div>
                        </div>
                    </li>
                </ul>
            </div>
        </section>
    )
}