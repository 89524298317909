import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/html/table-styling";

export default function HTMLTableStyling() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("HTML - Table Styling | Aspirant's Home");
        const urls = {
            'previous': '/html/tables',
            'next': '/html/table-colgroup'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>HTML - Table Styling</h3>
            <div className='mt-4 mb-5'>
                <p>
                    Styling HTML tables can enhance their appearance and improve readability. You can use CSS (Cascading Style Sheets) to style tables. Here are some common techniques and examples for styling tables in HTML:
                </p>

                <h5 className='mt-5 mb-3'>Horizontal Zebra Stripes</h5>
                <p>
                    You can add a background color on every other table row to get a nice zebra stripes effect.
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;style&gt;</p>
                        <p class="ml-30">    tr:<span class="color-pink">nth-child</span>(even) {</p>
                        <p class="ml-60">        <span class="color-green">background-color</span>: <span class="color-pink">#D6EEEE;</span></p>
                        <p class="ml-30">    }</p>
                        <p>&lt;/style&gt;</p>
                        <p>&lt;table className="table table-striped borderTable" style="width:100%; text-align:center"&gt;</p>
                        <p class="ml-30">    &lt;tr&gt;</p>
                        <p class="ml-60">        &lt;th&gt;Header 1&lt;/th&gt;</p>
                        <p class="ml-60">        &lt;th&gt;Header 2&lt;/th&gt;</p>
                        <p class="ml-60">        &lt;th&gt;Header 3&lt;/th&gt;</p>
                        <p class="ml-30">    &lt;/tr&gt;</p>
                        <p class="ml-30">    &lt;tr&gt;</p>
                        <p class="ml-60">        &lt;td&gt;Row 1, Cell 1&lt;/td&gt;</p>
                        <p class="ml-60">        &lt;td&gt;Row 1, Cell 2&lt;/td&gt;</p>
                        <p class="ml-60">        &lt;td&gt;Row 1, Cell 3&lt;/td&gt;</p>
                        <p class="ml-30">    &lt;/tr&gt;</p>
                        <p class="ml-30">    &lt;tr&gt;</p>
                        <p class="ml-60">        &lt;td&gt;Row 2, Cell 1&lt;/td&gt;</p>
                        <p class="ml-60">        &lt;td&gt;Row 2, Cell 2&lt;/td&gt;</p>
                        <p class="ml-60">        &lt;td&gt;Row 2, Cell 3&lt;/td&gt;</p>
                        <p class="ml-30">    &lt;/tr&gt;</p>
                        <p class="ml-30">    &lt;tr&gt;</p>
                        <p class="ml-60">        &lt;td&gt;Row 3, Cell 1&lt;/td&gt;</p>
                        <p class="ml-60">        &lt;td&gt;Row 3, Cell 2&lt;/td&gt;</p>
                        <p class="ml-60">        &lt;td&gt;Row 3, Cell 3&lt;/td&gt;</p>
                        <p class="ml-30">    &lt;/tr&gt;</p>
                        <p class="ml-30">    &lt;tr&gt;</p>
                        <p class="ml-60">        &lt;td&gt;Row 4, Cell 1&lt;/td&gt;</p>
                        <p class="ml-60">        &lt;td&gt;Row 4, Cell 2&lt;/td&gt;</p>
                        <p class="ml-60">        &lt;td&gt;Row 4, Cell 3&lt;/td&gt;</p>
                        <p class="ml-30">    &lt;/tr&gt;</p>
                        <p class="ml-30">    &lt;tr&gt;</p>
                        <p class="ml-60">        &lt;td&gt;Row 5, Cell 1&lt;/td&gt;</p>
                        <p class="ml-60">        &lt;td&gt;Row 5, Cell 2&lt;/td&gt;</p>
                        <p class="ml-60">        &lt;td&gt;Row 5, Cell 3&lt;/td&gt;</p>
                        <p class="ml-30">    &lt;/tr&gt;</p>
                        <p>&lt;/table&gt;</p>
                        `
                    }}></div>
                </div>
                <p>
                    Output of the following -
                </p>
                <div className='outputPalateBox mb-4' style={{'border':'none'}}>
                    <div className='outputPalate' dangerouslySetInnerHTML={{
                        __html: `
                            <style>
                                .borderTable tr:nth-child(even) {
                                    background-color: #e1f1ff !important;
                                }
                            </style>
                            <table class="table borderTable" style="width:100%; text-align:center">
                                <tr style="border: 1px solid #ccc">
                                    <th style="border: 1px solid #ccc">Header 1</th>
                                    <th style="border: 1px solid #ccc">Header 2</th>
                                    <th style="border: 1px solid #ccc">Header 3</th>
                                </tr>
                                <tr style="border: 1px solid #ccc">
                                    <td style="border: 1px solid #ccc">Row 1, Cell 1</td>
                                    <td style="border: 1px solid #ccc">Row 1, Cell 2</td>
                                    <td style="border: 1px solid #ccc">Row 1, Cell 3</td>
                                </tr>
                                <tr style="border: 1px solid #ccc">
                                    <td style="border: 1px solid #ccc">Row 2, Cell 1</td>
                                    <td style="border: 1px solid #ccc">Row 2, Cell 2</td>
                                    <td style="border: 1px solid #ccc">Row 2, Cell 3</td>
                                </tr>
                                <tr style="border: 1px solid #ccc">
                                    <td style="border: 1px solid #ccc">Row 3, Cell 1</td>
                                    <td style="border: 1px solid #ccc">Row 3, Cell 2</td>
                                    <td style="border: 1px solid #ccc">Row 3, Cell 3</td>
                                </tr>
                                <tr style="border: 1px solid #ccc">
                                    <td style="border: 1px solid #ccc">Row 4, Cell 1</td>
                                    <td style="border: 1px solid #ccc">Row 4, Cell 2</td>
                                    <td style="border: 1px solid #ccc">Row 4, Cell 3</td>
                                </tr>
                                <tr style="border: 1px solid #ccc">
                                    <td style="border: 1px solid #ccc">Row 5, Cell 1</td>
                                    <td style="border: 1px solid #ccc">Row 5, Cell 2</td>
                                    <td style="border: 1px solid #ccc">Row 5, Cell 3</td>
                                </tr>
                            </table>
                        `
                    }}></div>
                </div>
                <h5 className='mt-5 mb-3'>Vertical Zebra Stripes</h5>
                <p>
                    You can add a background color on every other table column to get a nice zebra stripes effect.
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;style&gt;</p>
                        <p class="ml-30">    td:<span class="color-pink">nth-child</span>(odd), th:<span class="color-pink">nth-child</span>(odd){</p>
                        <p class="ml-60">        <span class="color-green">background-color</span>: <span class="color-pink">#D6EEEE;</span></p>
                        <p class="ml-30">    }</p>
                        <p>&lt;/style&gt;</p>
                        <p>&lt;table className="table table-striped borderTable" style="width:100%; text-align:center"&gt;</p>
                        <p class="ml-30">    &lt;tr&gt;</p>
                        <p class="ml-60">        &lt;th&gt;Header 1&lt;/th&gt;</p>
                        <p class="ml-60">        &lt;th&gt;Header 2&lt;/th&gt;</p>
                        <p class="ml-60">        &lt;th&gt;Header 3&lt;/th&gt;</p>
                        <p class="ml-30">    &lt;/tr&gt;</p>
                        <p class="ml-30">    &lt;tr&gt;</p>
                        <p class="ml-60">        &lt;td&gt;Row 1, Cell 1&lt;/td&gt;</p>
                        <p class="ml-60">        &lt;td&gt;Row 1, Cell 2&lt;/td&gt;</p>
                        <p class="ml-60">        &lt;td&gt;Row 1, Cell 3&lt;/td&gt;</p>
                        <p class="ml-30">    &lt;/tr&gt;</p>
                        <p class="ml-30">    &lt;tr&gt;</p>
                        <p class="ml-60">        &lt;td&gt;Row 2, Cell 1&lt;/td&gt;</p>
                        <p class="ml-60">        &lt;td&gt;Row 2, Cell 2&lt;/td&gt;</p>
                        <p class="ml-60">        &lt;td&gt;Row 2, Cell 3&lt;/td&gt;</p>
                        <p class="ml-30">    &lt;/tr&gt;</p>
                        <p class="ml-30">    &lt;tr&gt;</p>
                        <p class="ml-60">        &lt;td&gt;Row 3, Cell 1&lt;/td&gt;</p>
                        <p class="ml-60">        &lt;td&gt;Row 3, Cell 2&lt;/td&gt;</p>
                        <p class="ml-60">        &lt;td&gt;Row 3, Cell 3&lt;/td&gt;</p>
                        <p class="ml-30">    &lt;/tr&gt;</p>
                        <p class="ml-30">    &lt;tr&gt;</p>
                        <p class="ml-60">        &lt;td&gt;Row 4, Cell 1&lt;/td&gt;</p>
                        <p class="ml-60">        &lt;td&gt;Row 4, Cell 2&lt;/td&gt;</p>
                        <p class="ml-60">        &lt;td&gt;Row 4, Cell 3&lt;/td&gt;</p>
                        <p class="ml-30">    &lt;/tr&gt;</p>
                        <p class="ml-30">    &lt;tr&gt;</p>
                        <p class="ml-60">        &lt;td&gt;Row 5, Cell 1&lt;/td&gt;</p>
                        <p class="ml-60">        &lt;td&gt;Row 5, Cell 2&lt;/td&gt;</p>
                        <p class="ml-60">        &lt;td&gt;Row 5, Cell 3&lt;/td&gt;</p>
                        <p class="ml-30">    &lt;/tr&gt;</p>
                        <p>&lt;/table&gt;</p>
                        `
                    }}></div>
                </div>
                <p>
                    Output of the following -
                </p>
                <div className='outputPalateBox mb-4' style={{'border':'none'}}>
                    <div className='outputPalate' dangerouslySetInnerHTML={{
                        __html: `
                            <style>
                                .borderTable1 td:nth-child(odd), .borderTable1 th:nth-child(odd) {
                                    background-color: #e1f1ff !important;
                                }
                            </style>
                            <table class="table borderTable1" style="width:100%; text-align:center">
                                <tr style="border: 1px solid #ccc">
                                    <th style="border: 1px solid #ccc">Header 1</th>
                                    <th style="border: 1px solid #ccc">Header 2</th>
                                    <th style="border: 1px solid #ccc">Header 3</th>
                                </tr>
                                <tr style="border: 1px solid #ccc">
                                    <td style="border: 1px solid #ccc">Row 1, Cell 1</td>
                                    <td style="border: 1px solid #ccc">Row 1, Cell 2</td>
                                    <td style="border: 1px solid #ccc">Row 1, Cell 3</td>
                                </tr>
                                <tr style="border: 1px solid #ccc">
                                    <td style="border: 1px solid #ccc">Row 2, Cell 1</td>
                                    <td style="border: 1px solid #ccc">Row 2, Cell 2</td>
                                    <td style="border: 1px solid #ccc">Row 2, Cell 3</td>
                                </tr>
                                <tr style="border: 1px solid #ccc">
                                    <td style="border: 1px solid #ccc">Row 3, Cell 1</td>
                                    <td style="border: 1px solid #ccc">Row 3, Cell 2</td>
                                    <td style="border: 1px solid #ccc">Row 3, Cell 3</td>
                                </tr>
                                <tr style="border: 1px solid #ccc">
                                    <td style="border: 1px solid #ccc">Row 4, Cell 1</td>
                                    <td style="border: 1px solid #ccc">Row 4, Cell 2</td>
                                    <td style="border: 1px solid #ccc">Row 4, Cell 3</td>
                                </tr>
                                <tr style="border: 1px solid #ccc">
                                    <td style="border: 1px solid #ccc">Row 5, Cell 1</td>
                                    <td style="border: 1px solid #ccc">Row 5, Cell 2</td>
                                    <td style="border: 1px solid #ccc">Row 5, Cell 3</td>
                                </tr>
                            </table>
                        `
                    }}></div>
                </div>
                <h5 className='mt-5 mb-3'>Hoverable Table</h5>
                <p>
                    Use the <code>:hover</code> selector on <strong>`tr`</strong> to highlight table rows on mouse over:
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;style&gt;</p>
                        <p class="ml-30">    tr:<span class="color-pink">hover</span>{</p>
                        <p class="ml-60">        <span class="color-green">background-color</span>: <span class="color-pink">#D6EEEE;</span></p>
                        <p class="ml-30">    }</p>
                        <p>&lt;/style&gt;</p>
                        <p>&lt;table className="table table-striped borderTable" style="width:100%; text-align:center"&gt;</p>
                        <p class="ml-30">    &lt;tr&gt;</p>
                        <p class="ml-60">        &lt;th&gt;Header 1&lt;/th&gt;</p>
                        <p class="ml-60">        &lt;th&gt;Header 2&lt;/th&gt;</p>
                        <p class="ml-60">        &lt;th&gt;Header 3&lt;/th&gt;</p>
                        <p class="ml-30">    &lt;/tr&gt;</p>
                        <p class="ml-30">    &lt;tr&gt;</p>
                        <p class="ml-60">        &lt;td&gt;Row 1, Cell 1&lt;/td&gt;</p>
                        <p class="ml-60">        &lt;td&gt;Row 1, Cell 2&lt;/td&gt;</p>
                        <p class="ml-60">        &lt;td&gt;Row 1, Cell 3&lt;/td&gt;</p>
                        <p class="ml-30">    &lt;/tr&gt;</p>
                        <p class="ml-30">    &lt;tr&gt;</p>
                        <p class="ml-60">        &lt;td&gt;Row 2, Cell 1&lt;/td&gt;</p>
                        <p class="ml-60">        &lt;td&gt;Row 2, Cell 2&lt;/td&gt;</p>
                        <p class="ml-60">        &lt;td&gt;Row 2, Cell 3&lt;/td&gt;</p>
                        <p class="ml-30">    &lt;/tr&gt;</p>
                        <p class="ml-30">    &lt;tr&gt;</p>
                        <p class="ml-60">        &lt;td&gt;Row 3, Cell 1&lt;/td&gt;</p>
                        <p class="ml-60">        &lt;td&gt;Row 3, Cell 2&lt;/td&gt;</p>
                        <p class="ml-60">        &lt;td&gt;Row 3, Cell 3&lt;/td&gt;</p>
                        <p class="ml-30">    &lt;/tr&gt;</p>
                        <p class="ml-30">    &lt;tr&gt;</p>
                        <p class="ml-60">        &lt;td&gt;Row 4, Cell 1&lt;/td&gt;</p>
                        <p class="ml-60">        &lt;td&gt;Row 4, Cell 2&lt;/td&gt;</p>
                        <p class="ml-60">        &lt;td&gt;Row 4, Cell 3&lt;/td&gt;</p>
                        <p class="ml-30">    &lt;/tr&gt;</p>
                        <p class="ml-30">    &lt;tr&gt;</p>
                        <p class="ml-60">        &lt;td&gt;Row 5, Cell 1&lt;/td&gt;</p>
                        <p class="ml-60">        &lt;td&gt;Row 5, Cell 2&lt;/td&gt;</p>
                        <p class="ml-60">        &lt;td&gt;Row 5, Cell 3&lt;/td&gt;</p>
                        <p class="ml-30">    &lt;/tr&gt;</p>
                        <p>&lt;/table&gt;</p>
                        `
                    }}></div>
                </div>
                <p>
                    Output of the following -
                </p>
                <div className='outputPalateBox mb-4' style={{'border':'none'}}>
                    <div className='outputPalate' dangerouslySetInnerHTML={{
                        __html: `
                            <style>
                                .borderTable2 tr:hover {
                                    background-color: #e1f1ff !important;
                                }
                            </style>
                            <table class="table borderTable2" style="width:100%; text-align:center">
                                <tr style="border: 1px solid #ccc">
                                    <th style="border: 1px solid #ccc">Header 1</th>
                                    <th style="border: 1px solid #ccc">Header 2</th>
                                    <th style="border: 1px solid #ccc">Header 3</th>
                                </tr>
                                <tr style="border: 1px solid #ccc">
                                    <td style="border: 1px solid #ccc">Row 1, Cell 1</td>
                                    <td style="border: 1px solid #ccc">Row 1, Cell 2</td>
                                    <td style="border: 1px solid #ccc">Row 1, Cell 3</td>
                                </tr>
                                <tr style="border: 1px solid #ccc">
                                    <td style="border: 1px solid #ccc">Row 2, Cell 1</td>
                                    <td style="border: 1px solid #ccc">Row 2, Cell 2</td>
                                    <td style="border: 1px solid #ccc">Row 2, Cell 3</td>
                                </tr>
                                <tr style="border: 1px solid #ccc">
                                    <td style="border: 1px solid #ccc">Row 3, Cell 1</td>
                                    <td style="border: 1px solid #ccc">Row 3, Cell 2</td>
                                    <td style="border: 1px solid #ccc">Row 3, Cell 3</td>
                                </tr>
                                <tr style="border: 1px solid #ccc">
                                    <td style="border: 1px solid #ccc">Row 4, Cell 1</td>
                                    <td style="border: 1px solid #ccc">Row 4, Cell 2</td>
                                    <td style="border: 1px solid #ccc">Row 4, Cell 3</td>
                                </tr>
                                <tr style="border: 1px solid #ccc">
                                    <td style="border: 1px solid #ccc">Row 5, Cell 1</td>
                                    <td style="border: 1px solid #ccc">Row 5, Cell 2</td>
                                    <td style="border: 1px solid #ccc">Row 5, Cell 3</td>
                                </tr>
                            </table>
                        `
                    }}></div>
                </div>
            </div>
        </section>
    )
}