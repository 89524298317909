import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/react-js/styled-component";

export default function StyledComponent() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("React Styling - Using Styled Component | Aspirant's Home");
        const urls = {
            'previous': '/react-js/css-modules',
            'next':'/react-js/tailwind-css'
        }
        path.setPreviousNext(urls);
    },[])

    return (
        <section className='mt-5 mb-5'>
            <h3>React Js - Styling Using Styled Component</h3>
            <div className='mt-4 mb-4'>
                <p>Styled-components is a popular CSS-in-JS library for React that allows you to write CSS code directly inside your JavaScript files using a technique called tagged template literals. With styled-components, you can create styled React components with dynamic styles, props-based styling, and theming support.</p>
                <p>Here's a basic example of how you can use styled-components in a React component:</p>
                <ul className='mt-4 mb-5' style={{ 'listStyle': 'disc' }}>
                    <li>Install styled-components
                        <div className='codePalateBox mt-2 mb-2'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `<p>npm install styled-components</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        Import styled-components and create a styled component:
                        <div className='codePalateBox mt-2 mb-2'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p><span class="color-blue">import</span> <span class="color-red">React</span> <span class="color-blue">from</span> <span class="color-green">'react'</span>;</p>
                                <p><span class="color-blue">import</span> styled <span class="color-blue">from</span> <span class="color-green">'styled-components'</span>;</p>
                                <br />
                                <p><span class="color-blue">const</span> <span class="color-red">Button</span> = styled.button<span class="color-green">&#96;</span></p>
                                <span class="color-green">
                                <p style="margin-left:30px">color: white;</p>
                                <p style="margin-left:30px">background-color: blue;</p>
                                <p style="margin-left:30px">border: none;</p>
                                <p style="margin-left:30px">padding: 10px 20px;</p>
                                <p style="margin-left:30px">border-radius: 5px;</p>
                                <p style="margin-left:30px">cursor: pointer;</p>
                                <br />
                                <p style="margin-left:30px">&:hover {</p>
                                    <p style="margin-left:60px">background-color: darkblue;</p>
                                <p style="margin-left:30px">}</p>
                                </span>
                                <p><span class="color-green">&#96;</span>;</p>
                                <br />
                                <p><span class="color-blue">function</span> <span class="color-red">MyComponent</span>() {</p>
                                <p style="margin-left:30px"><span class="color-blue">return</span> &lt;Button&gt;Click me&lt;/Button&gt;;</p>
                                <p>}</p>
                                <br />
                                <p><span class="color-blue">export default</span> <span class="color-red">MyComponent</span>;</p>

                                `
                            }}></div>
                        </div>
                    </li>
                </ul>
                <p>Styled-components essentially creates a custom component with its own unique styling. You can use these styled components anywhere in your application, and it's common practice to keep them in separate files.</p>
            </div>
            <div className='mt-4 mb-4'>
                <h5>Dynamic & Conditional Styling</h5>
                <div className='mt-4 mb-4'>
                    <p>To create flexible components using styled-components, you can utilize props to conditionally apply styles. This allows you to create reusable components that can adapt their styles based on the props they receive. Here's an example:</p>
                    <div className='codePalateBox mt-2 mb-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `<p><span class="color-blue">import</span> <span class="color-red">React</span> <span class="color-blue">from</span> <span class="color-green">'react'</span>;</p>
                            <p><span class="color-blue">import</span> styled <span class="color-blue">from</span> <span class="color-green">'styled-components'</span>;</p>
                            <br />
                            <p><span class="color-grey">// Create a styled button component</span></p>
                            <p><span class="color-blue">const</span> <span class="color-red">Button</span> = styled.button<span class="color-green">&#96;</span></p>
                            <div class="color-green">
                            <p style="margin-left:30px">  padding: &#36;&#123;&#40;props&#41; => &#40;props.large ? '12px 24px' : '8px 16px'&#41;&#125;;</p>
                            <p style="margin-left:30px">  font-size: &#36;&#123;&#40;props&#41; => &#40;props.large ? '1.5em' : '1em'&#41;&#125;;</p>
                            <p style="margin-left:30px">  background-color: &#36;&#123;&#40;props&#41; => &#40;props.primary ? 'blue' : 'gray'&#41;&#125;;</p>
                            <p style="margin-left:30px">  color: white;</p>
                            <p style="margin-left:30px">  border: none;</p>
                            <p style="margin-left:30px">  border-radius: 5px;</p>
                            <p style="margin-left:30px">  cursor: pointer;</p>
                            </div>
                            <p>  <span class="color-green">&#96;</span>;</p>
                            <br />
                            <p><span class="color-grey">// Example component using the Button component</span></p>
                            <p><span class="color-blue">function</span> <span class="color-red">MyComponent</span>() {</p>
                            <p style="margin-left:30px">  <span class="color-blue">return</span> (</p>
                            <p style="margin-left:60px">    &lt;div&gt;</p>
                            <p style="margin-left:90px">      &lt;Button&gt;Normal Button&lt;/Button&gt;</p>
                            <p style="margin-left:90px">      &lt;Button <span class="color-pink">large</span>&gt;Large Button&lt;/Button&gt;</p>
                            <p style="margin-left:90px">      &lt;Button <span class="color-pink">primary</span>&gt;Primary Button&lt;/Button&gt;</p>
                            <p style="margin-left:90px">      &lt;Button <span class="color-pink">large primary</span>&gt;Large Primary Button&lt;/Button&gt;</p>
                            <p style="margin-left:60px">    &lt;/div&gt;</p>
                            <p style="margin-left:30px">  );</p>
                            <p>}</p>
                            <br />
                            <p><span class="color-blue">export default</span> <span class="color-red">MyComponent</span>;</p>
                            `
                        }}></div>
                    </div>
                    <p>In the above example, we use styled-components to create a flexible Button component. The Button component is styled using CSS-in-JS syntax, where we use template literals to define styles. We use props to conditionally apply styles, making the Button component flexible and customizable.</p>
                    <ul style={{ 'listStyle': 'disc' }}>
                        <li>
                            The Button component adjusts its padding and font size based on the large prop. If large is true, it sets larger padding and font size; otherwise, it uses smaller values.
                        </li>
                        <li>
                            The Button component also changes its background color based on the primary prop. If primary is true, it sets the background color to blue; otherwise, it uses gray.
                        </li>
                    </ul>
                    <p>This approach allows us to create buttons with different styles by simply passing different props to the Button component. This flexibility makes styled-components a powerful tool for creating reusable and customizable components in React.</p>
                </div>
            </div>
            <div className='mt-4 mb-4'>
                <h5>Pseudo Selectors, Nested Rules & Media Queries</h5>
                <div className='mt-4 mb-4'>
                    <p>In styled-components, you can use pseudo selectors, nested rules, and media queries just like you would in regular CSS, but with the added benefit of using JavaScript syntax. Here's how you can use these features:</p>
                    <ul style={{listStyle:'disc'}}>
                        <li className='mb-4'>
                            <strong>Pseudo Selectors:</strong> Use the & character to reference the current component and apply pseudo selectors like <strong>`:hover`</strong>, <strong>`:focus`</strong>, etc.
                            <div className='codePalateBox mt-2 mb-2'>
                                <div className='codePalate' dangerouslySetInnerHTML={{
                                    __html: `
                                    <p><span class="color-blue">const</span> <span class="color-red">Button</span> = styled.button<span class="color-green">&#96;</span></p>
                                    <div class="color-green">
                                    <p style="margin-left:30px">    background-color: blue;</p>
                                    <br />
                                    <p style="margin-left:30px">    &:hover {</p>
                                    <p style="margin-left:60px">        background-color: darkblue;</p>
                                    <p style="margin-left:30px">    }</p>
                                    <br />
                                    <p style="margin-left:30px">    &:focus {</p>
                                    <p style="margin-left:60px">        outline: none;</p>
                                    <p style="margin-left:60px">        box-shadow: 0 0 3px 3px rgba(0, 0, 255, 0.5);</p>
                                    <p style="margin-left:30px">    }&#96;</p>
                                    </div>
                                    ;
                                    `
                                }}></div>
                            </div>
                        </li>
                        <li className='mb-4'>
                            <strong>Nested Rules:</strong> You can nest CSS rules inside styled-components as you would in a regular CSS preprocessor like Sass or Less.
                            <div className='codePalateBox mt-2 mb-2'>
                                <div className='codePalate' dangerouslySetInnerHTML={{
                                    __html: `
                                    <p><span class="color-blue">const</span> <span class="color-red">Container</span> = styled.div&#96;</p>
                                    <div class="color-green">
                                    <p style="margin-left:30px">    padding: 20px;</p>
                                    <br />
                                    <p style="margin-left:30px">    & h1 {</p>
                                    <p style="margin-left:60px">        font-size: 24px;</p>
                                    <p style="margin-left:60px">        color: blue;</p>
                                    <p style="margin-left:30px">    }</p>
                                    <br />
                                    <p style="margin-left:30px">    & p {</p>
                                    <p style="margin-left:60px">        color: gray;</p>
                                    <p style="margin-left:30px">    }&#96;</p>
                                    </div>
                                        ;
                                    `
                                }}></div>
                            </div>
                        </li>
                        <li className='mb-4'>
                            <strong>Media Queries:</strong> Use the css helper function from styled-components to define media queries.
                            <div className='codePalateBox mt-2 mb-4'>
                                <div className='codePalate' dangerouslySetInnerHTML={{
                                    __html: `
                                        <p><span class="color-blue">import</span> styled, { css } <span class="color-blue">from</span> <span class="color-green">'styled-components'</span>;</p>
                                        <br />
                                        <p><span class="color-blue">const</span> <span class="color-red">Container</span> = styled.div&#96;</p>
                                        <div class="color-green">
                                        <p style="margin-left:30px">width: 100%;</p>
                                        <br />
                                        <p style="margin-left:30px">&#36;&#123;props =></p>
                                        <p style="margin-left:60px">    props.fullWidth &&</p>
                                        <p style="margin-left:60px">    css&#96;</p>
                                        </div>
                                        <p style="margin-left:90px">    <span class="color-blue">@media</span> (<span class="color-green">max-width</span>: <span class="color-pink">768px</span><span class="color-green">) &#123;</span></p>
                                        <p style="margin-left:120px">        <span class="color-green">width:</span> <span class="color-pink">100vw</span><span class="color-green">;</span></p>
                                        <p style="margin-left:90px">    <span class="color-green">}</span></p>
                                        <p class="color-green" style="margin-left:60px">    &#96;}</p>
                                        <p style="margin-left:30px"><span class="color-green">&#96;</span>;</p>
                                    `
                                }}></div>
                            </div>
                            <p>In this example, the Container component will have a width of <strong>100vw</strong> when the fullWidth prop is true and the viewport width is less than or equal to <strong>768px</strong>.</p>
                        </li>
                    </ul>
                    <p>Basically, Styled-components allow you to write CSS in a more dynamic and flexible way, using JavaScript syntax to create components with styles that can change based on props, state, or other factors.</p>
                </div>
            </div>
        </section>
    )
}