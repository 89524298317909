import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/mongo-db/limit-records";

export default function MongoLimitRecords() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("How to Limit Records in MongoDB Document?");
        title.setKeyWords("limiting records in mongodb, mongodb limit results query, mongodb limit results, mongodb aggregate limit results, mongodb limit number of results, aggregation limit mongodb, mongodb limit find results, mongodb limit find, mongodb limit query results, query limit mongodb");
        title.setPageDescription("In MongoDB, you can limit the number of documents returned by a query using the `limit()` method. This is particularly useful when you want to control the amount of data returned, such as for pagination or simply to avoid overwhelming the client with too much data.");
        const urls = {
            'previous': '/mongo-db/projection',
            'next': '/mongo-db/sorting-records'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>MongoDB - Limiting Records</h3>
            <div className='mt-4 mb-5'>
                <p>
                    In MongoDB, you can limit the number of documents returned by a query using the <strong>`limit()`</strong> method. This is particularly useful when you want to control the amount of data returned, such as for pagination or simply to avoid overwhelming the client with too much data.
                </p>
                <h5 className='mt-5 mb-4'>Using `limit()`</h5>
                <p>
                    The <strong>`limit()`</strong> method is appended to the query and takes an integer argument specifying the maximum number of documents to return.
                </p>
                <p> Here is an syntax - </p>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>db.collection.<span class="color-red">find</span>().<span class="color-red">limit</span>(5)</p>
                        `
                    }}></div>
                </div>
                <p>
                    This command returns the first 5 documents from the <strong>`collection`</strong>.
                </p>
                
                <h5 className='mt-5 mb-4'>Pagination</h5>
                <p>
                    To implement pagination, you typically use <strong>`limit()`</strong> in conjunction with <strong>`skip()`</strong>, which allows you to skip a specified number of documents.
                </p>
                <p>
                    Assume you want to implement pagination with a page size of 10 documents.
                </p>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-blue">const</span> pageSize = <span class="color-pink">10</span>;</p>
                            <p><span class="color-blue">const</span> page = <span class="color-pink">2</span>;  <span class="color-grey">// Second page</span></p>
                            <br />
                            <p>db.collection.<span class="color-red">find</span>()</p>
                            <p>.<span class="color-red">skip</span>((page - <span class="color-pink">1</span>) * pageSize)</p>
                            <p>.<span class="color-red">limit</span>(pageSize)</p>
                        `
                    }}></div>
                </div>
                <p>This command skips the first 10 documents and returns the next 10 documents, effectively providing the second page of results.</p>
            </div>
        </section>
    )
}