import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/react-js/defining-dynamic-routes";

export default function DynamicRoutes() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("React - Defining Dynamic Routes | Aspirant's Home");
        const urls = {
            'previous': '/react-js/navigate-to-page-dynamically',
            'next':'/react-js/handling-http-request'
        }
        path.setPreviousNext(urls);
    },[])

    return (
        <section className='mt-5 mb-5'>
            <h3>Defining Dynamic Routes</h3>
            <div className='mt-4 mb-4'>
                <p>
                    Dynamic routes in React allow you to create routes that match patterns in the URL and extract parameters from the URL to use in your components. This is useful for creating routes that can handle variable data, such as user IDs or product names
                </p>
                <p>OK, let understand with a example -</p>
                <p>Suppose, we have an product listed site, where multiple products are listed. We have an <strong>ProductsPage</strong> component below. </p>

                <div className='codePalateBox mt-2 mb-5'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-blue">function</span> <span class="color-red">ProductsPage</span>() {</p>
                            <p style="margin-left:30px">    <span class="color-blue">return</span> (</p>
                            <p style="margin-left:60px">        &lt;&gt;</p>
                            <p style="margin-left:90px">            &lt;h1&gt;Product Page&lt;/h1&gt;</p>
                            <p style="margin-left:90px">            &lt;ul&gt;</p>
                            <p style="margin-left:120px">                &lt;li&gt;Product 1&lt;/li&gt;</p>
                            <p style="margin-left:120px">                &lt;li&gt;Product 2&lt;/li&gt;</p>
                            <p style="margin-left:120px">                &lt;li&gt;Product 3&lt;/li&gt;</p>
                            <p style="margin-left:90px">            &lt;/ul&gt;</p>
                            <p style="margin-left:60px">        &lt;/&gt;</p>
                            <p style="margin-left:30px">    )</p>
                            <p>}</p>
                            <br />
                            <p><span class="color-blue">export default</span> <span class="color-red">ProductsPage</span>;</p>
                        `
                    }}></div>
                </div> 

                <p>And a <strong>ProductDetailPage</strong> component for showing the details of each product.</p>

                <div className='codePalateBox mt-2 mb-5'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-blue">function</span> <span class="color-red">ProductDetailPage</span>() {</p>
                            <p style="margin-left:30px">    <span class="color-blue">return</span> (</p>
                            <p style="margin-left:60px">        &lt;&gt;</p>
                            <p style="margin-left:90px">            &lt;h1&gt;Product Details Page&lt;/h1&gt;</p>
                            <p style="margin-left:60px">        &lt;/&gt;</p>
                            <p style="margin-left:30px">    )</p>
                            <p>}</p>
                            <br />
                            <p><span class="color-blue">export default</span> <span class="color-red">ProductDetailPage</span>;</p>
                        `
                    }}></div>
                </div> 

                <p>Now, we need to define the routes for the product details page. Since multiple products are listed here, we must specify routes for each product.</p>

                <div className='codePalateBox mt-2 mb-5'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-blue">const</span> router = <span class="color-red">createBrowserRouter</span>([</p>
                            <p style="margin-left:30px">    {</p>
                            <p style="margin-left:60px">        <span class="color-pink">path</span>: <span class="color-green">'/'</span>,</p>
                            <p style="margin-left:60px">        <span class="color-pink">element</span>: &lt;<span class="color-green">RootLayout</span> /&gt;,</p>
                            <p style="margin-left:60px">        <span class="color-pink">errorElement</span>: &lt;<span class="color-green">ErrorPage</span> /&gt;,</p>
                            <p style="margin-left:60px">        <span class="color-pink">children</span>: <span class="color-green">[</span></p>
                            <div class="color-green">
                            <p style="margin-left:90px">            { path: '/', element: &lt;HomePage /&gt; },</p>
                            <p style="margin-left:90px">            { path: '/products', element: &lt;ProductPage /&gt;}</p>
                            <p style="margin-left:90px">            { path: '/products/product-1', element: &lt;ProductDetailPage /&gt;}</p>
                            <p style="margin-left:90px">            { path: '/products/product-2', element: &lt;ProductDetailPage /&gt;}</p>
                            <p style="margin-left:90px">            { path: '/products/product-3', element: &lt;ProductDetailPage /&gt;}</p>
                            </div>
                            <p style="margin-left:60px">        <span class="color-green">]</span></p>
                            <p style="margin-left:30px">    }</p>
                            <p>])</p>
                        `
                    }}></div>
                </div> 

                <p>
                    As we have multiple products, sometimes we don't even know how many products there are. So, how do we handle routes for an unlimited number of products? That's where the concept of params comes in. By using params, we can create dynamic routes for every product without writing the routes multiple times.
                </p>

                <p>Look at the below examples, how we are using <strong>`params`</strong> in routes.</p>

                <div className='codePalateBox mt-2 mb-5'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-blue">const</span> router = <span class="color-red">createBrowserRouter</span>([</p>
                            <p style="margin-left:30px">    {</p>
                            <p style="margin-left:60px">        <span class="color-pink">path</span>: <span class="color-green">'/'</span>,</p>
                            <p style="margin-left:60px">        <span class="color-pink">element</span>: &lt;<span class="color-green">RootLayout</span> /&gt;,</p>
                            <p style="margin-left:60px">        <span class="color-pink">errorElement</span>: &lt;<span class="color-green">ErrorPage</span> /&gt;,</p>
                            <p style="margin-left:60px">        <span class="color-pink">children</span>: <span class="color-green">[</span></p>
                            <div class="color-green">
                            <p style="margin-left:90px">            { path: '/', element: &lt;HomePage /&gt; },</p>
                            <p style="margin-left:90px">            { path: '/products', element: &lt;ProductPage /&gt;}</p>
                            <p style="margin-left:90px">            { path: '/products/:productId', element: &lt;ProductDetailPage /&gt;}</p>
                            </div>
                            <p style="margin-left:60px">        <span class="color-green">]</span></p>
                            <p style="margin-left:30px">    }</p>
                            <p>])</p>
                        `
                    }}></div>
                </div> 

                <p>
                    This defines a route for the path <strong>`/products/:productId`</strong>. The <strong>`:productId`</strong> part indicates a dynamic parameter in the URL, which can be accessed as <strong>`params`</strong> parameter in the <strong>`ProductDetailPage`</strong> component.
                </p>

                <p>
                    <strong>`useParams()`</strong> is a hook provided by React Router that allows you to access the parameters (or URL segments) of the current route in a functional component. It returns an object containing key-value pairs of the parameters defined in the route. Look at the below examples to see how we get the dynamic parameters value in <strong>`ProductDetailPage`</strong> component for each product.
                </p>

                <div className='codePalateBox mt-2 mb-5'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p> <span class="color-blue">import</span> { <span class="color-red">useParams</span> } <span class="color-blue">from</span> <span class="color-green">'react-router-dom'</span>;</p>
                            <br />
                            <p><span class="color-blue">function</span> <span class="color-red">ProductDetailPage</span>() {</p>
                            <p style="margin-left:30px"> <span class="color-blue">const</span> params = <span class="color-red">useParams</span>();</p>
                            <p style="margin-left:30px">    <span class="color-blue">return</span> (</p>
                            <p style="margin-left:60px">        &lt;&gt;</p>
                            <p style="margin-left:90px">            &lt;h1&gt;Product Details Page&lt;/h1&gt;</p>
                            <p style="margin-left:90px">            &lt;p&gt;{params.productId}&lt;/p&gt;</p>
                            <p style="margin-left:60px">        &lt;/&gt;</p>
                            <p style="margin-left:30px">    )</p>
                            <p>}</p>
                            <br />
                            <p><span class="color-blue">export default</span> <span class="color-red">ProductDetailPage</span>;</p>
                        `
                    }}></div>
                </div> 
            </div>
            <div className='mt-5 mb-5'>
                <h5>Adding Links for Dynamic Routes</h5>
                <div className='mt-4 mb-4'>
                    <p>
                        To add links for dynamic routes in React using React Router, you can use the Link component and include the dynamic parameters in the to prop.
                    </p>

                    <div className='codePalateBox mt-2 mb-5'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                                <p><span class="color-blue">import</span> { <span class="color-red">Link</span> } <span class="color-blue">from</span> <span class="color-green">'react-router-dom'</span>;</p>
                                <p><span class="color-blue">function</span> <span class="color-red">ProductsPage</span>() {</p>
                                <p style="margin-left:30px">    <span class="color-blue">return</span> (</p>
                                <p style="margin-left:60px">        &lt;&gt;</p>
                                <p style="margin-left:90px">            &lt;h1&gt;Product Page&lt;/h1&gt;</p>
                                <p style="margin-left:90px">            &lt;ul&gt;</p>
                                <p style="margin-left:120px">                &lt;li&gt;&lt;Link to="/products/product-1"&gt;Product 1&lt;/Link&gt;&lt;/li&gt;</p>
                                <p style="margin-left:120px">                &lt;li&gt;&lt;Link to="/products/product-2"&gt;Product 2&lt;/Link&gt;&lt;/li&gt;</p>
                                <p style="margin-left:120px">                &lt;li&gt;&lt;Link to="/products/product-3"&gt;Product 3&lt;/Link&gt;&lt;/li&gt;</p>
                                <p style="margin-left:90px">            &lt;/ul&gt;</p>
                                <p style="margin-left:60px">        &lt;/&gt;</p>
                                <p style="margin-left:30px">    )</p>
                                <p>}</p>
                                <br />
                                <p><span class="color-blue">export default</span> <span class="color-red">ProductsPage</span>;</p>
                            `
                        }}></div>
                    </div>
                    
                    <p>
                        In the above example, all the products are listed statically. In a real application, the products come from the backend as an array of objects. Let's see how we can dynamically generate links from the products array. Currently, we are using a static variable named <strong>`PRODUCTS`</strong>, which is an array of objects.
                    </p>

                    <div className='codePalateBox mt-2 mb-5'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `  
                                    <p><span class="color-blue">import</span> { <span class="color-red">Link</span> } <span class="color-blue">from</span> <span class="color-green">'react-router-dom'</span>;</p>
                                    <br />
                                    <p><span class="color-blue">const</span> PRODUCTS = [</p>
                                    <p style="margin-left:30px">    { <span class="color-pink">id</span>: <span class="color-green">'p1'</span>, <span class="color-pink">title</span>: <span class="color-green">'product 1'</span> },</p>
                                    <p style="margin-left:30px">    { <span class="color-pink">id</span>: <span class="color-green">'p2'</span>, <span class="color-pink">title</span>: <span class="color-green">'product 2'</span> },</p>
                                    <p style="margin-left:30px">    { <span class="color-pink">id</span>: <span class="color-green">'p3'</span>, <span class="color-pink">title</span>: <span class="color-green">'product 3'</span> },</p>
                                    <p>]</p>
                                    <br />
                                    <p><span class="color-blue">function</span> <span class="color-red">ProductsPage</span>() {</p>
                                    <p style="margin-left:30px">    <span class="color-blue">return</span> (</p>
                                    <p style="margin-left:60px">        &lt;&gt;</p>
                                    <p style="margin-left:90px">            &lt;h1&gt;Product Page&lt;/h1&gt;</p>
                                    <p style="margin-left:90px">            &lt;ul&gt;</p>
                                    <p style="margin-left:120px">                {PRODUCTS.<span class="color-red">map</span>((prod) => (</p>
                                    <p style="margin-left:150px">                    &lt;li <span class="color-pink">key</span>=<span class="color-green">{prod.id}</span>&gt;</p>
                                    <p style="margin-left:180px">                        &lt;<span class="color-red">Link</span> <span class="color-pink">to</span>=<span class="color-green">&#123;&#96;/products/&#36;&#123;prod.id&#125;&#96;&#125;</span>&gt;&#123;prod.title&#125;&lt;/<span class="color-red">Link</span>&gt;</p>
                                    <p style="margin-left:150px">                    &lt;/li&gt;</p>
                                    <p style="margin-left:120px">                ))}</p>
                                    <p style="margin-left:90px">            &lt;/ul&gt;</p>
                                    <p style="margin-left:60px">        &lt;/&gt;</p>
                                    <p style="margin-left:30px">    )</p>
                                    <p>}</p>
                                    <br />
                                    <p><span class="color-blue">export default</span> <span class="color-red">ProductsPage</span>;</p>
                            `
                        }}></div>
                    </div> 

                </div>
            </div>

        </section>
    )
}