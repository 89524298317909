import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/css/font/family";

export default function FontFamily() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("What is Font Family in CSS? | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/css/text/shadow',
            'next': '/css/font/web/safe'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>What is Font Family in CSS?</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <strong>`font-family`</strong> property in CSS is used to specify the typeface that should be applied to the text content of an HTML element. It allows you to define the visual appearance of text by choosing different fonts. This property plays a crucial role in typography and helps create a consistent and aesthetically pleasing look for web pages.
                </p>
                <p>
                    It has a basic syntax -
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>element {</p>
                            <p class="ml-30">    <span class="color-green">font-family</span>: <span class="color-green">"Font Name"</span>, fallback-font, generic-family;</p>
                            <p>}</p>
                        `
                    }}></div>
                </div>
                <p>
                    Where,
                </p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><strong>`"Font Name"`</strong> is the specific font you want to use. If the font name has spaces, it should be enclosed in quotes.</li>
                    <li><strong>`fallback-font`</strong> is a secondary font that will be used if the first choice is not available.</li>
                    <li><strong>`generic-family`</strong> is a generic font category like serif, sans-serif, monospace, cursive, or fantasy, which acts as a last fallback option.</li>
                </ul>

                <h5 className="mt-5 mb-3">Examples of Using Font Family</h5>
                <p>
                    Here are some example of how font family looks like -
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;style&gt;</p>
                        <p class="ml-30">    .p1 {</p>
                        <p class="ml-60">        <span class="color-green">font-family</span>: <span class="color-green">"Times New Roman"</span>, Times, serif;</p>
                        <p class="ml-30">    }</p>
                        <br />    
                        <p class="ml-30">    .p2 {</p>
                        <p class="ml-60">        <span class="color-green">font-family</span>: Arial, Helvetica, sans-serif;</p>
                        <p class="ml-30">    }</p>
                        <br />    
                        <p class="ml-30">    .p3 {</p>
                        <p class="ml-60">        <span class="color-green">font-family</span>: <span class="color-green">"Lucida Console"</span>, "Courier New", monospace;</p>
                        <p class="ml-30">    }</p>
                        <p>&lt;/style&gt;</p>
                        <br />
                        <p>&lt;p <span class="color-pink">class</span>=<span class="color-green">"p1"</span>&gt;This is a paragraph, shown in the Times New Roman font.&lt;/p&gt;</p>
                        <p>&lt;p <span class="color-pink">class</span>=<span class="color-green">"p2"</span>&gt;This is a paragraph, shown in the Arial font.&lt;/p&gt;</p>
                        <p>&lt;p <span class="color-pink">class</span>=<span class="color-green">"p3"</span>&gt;This is a paragraph, shown in the Lucida Console font.&lt;/p&gt;</p>
                        `
                    }}></div>
                </div>
                <p>
                    Output will be the following -
                </p>
                <div className='outputPalateBox mb-4 mt-3'>
                    <div className='outputPalate' dangerouslySetInnerHTML={{
                        __html: `
                            <style>
                                .p1 {
                                    font-family: "Times New Roman", Times, serif;
                                }
                                
                                .p2 {
                                    font-family: Arial, Helvetica, sans-serif;
                                }
                                
                                .p3 {
                                    font-family: "Lucida Console", "Courier New", monospace;
                                }
                            </style>
                            
                            <p class="p1">This is a paragraph, shown in the Times New Roman font.</p>
                            <p class="p2">This is a paragraph, shown in the Arial font.</p>
                            <p class="p3">This is a paragraph, shown in the Lucida Console font.</p>
                        `
                    }}></div>
                </div>

                <h5 className="mt-5 mb-3">Some Best Practices of Using Font Family</h5>
                <p>
                    Here are some best practices we need to follow while using Font Family.
                </p>
                <ul style={{listStyle:'disc'}}>
                    <li>
                        <strong>Font Stacks</strong>: Always provide fallback fonts to ensure the text is displayed correctly even if the preferred font isn't available. A typical font stack includes the preferred font, a common fallback, and a generic family like the below example -
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p>body {</p>
                                    <p class="ml-30">    <span class="color-green">font-family</span>: <span class="color-green">"Roboto"</span>, <span class="color-green">"Arial"</span>, sans-serif;</p>
                                    <p>}</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <strong>Web-Safe Fonts</strong>: Use fonts that are commonly installed on most devices to ensure consistent display across different systems.
                    </li>
                    <li>
                        <strong>Custom Fonts</strong>: You can use custom fonts via the @font-face rule or by linking to web fonts like Google Fonts, but still include fallback options.
                    </li>
                    <li>
                        <strong>Readability</strong>: Choose fonts that are easy to read and match the tone and purpose of your content. For example, use serif fonts for formal documents and sans-serif fonts for modern, clean designs.
                    </li>
                </ul>

                <h5 className="mt-5 mb-3">Examples of Custom Fonts</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-blue">@font-face</span> {</p>
                            <p class="ml-30">    <span class="color-green">font-family</span>: <span class="color-green">'MyCustomFont'</span>;</p>
                            <p class="ml-30">    <span class="color-green">src</span>: <span class="color-yellow">url</span>(<span class="color-green">'mycustomfont.woff2'</span>) <span class="color-yellow">format</span>(<span class="color-green">'woff2'</span>),</p>
                            <p class="ml-60">        <span class="color-yellow">url</span>(<span class="color-green">'mycustomfont.woff'</span>) <span class="color-yellow">format</span>(<span class="color-green">'woff'</span>);</p>
                            <p>}</p>
                            <br />
                            <p>body {</p>
                            <p class="ml-30">    <span class="color-green">font-family</span>: <span class="color-green">'MyCustomFont'</span>, Arial, sans-serif;</p>
                            <p>}</p>
                        `
                    }}></div>
                </div>
                <p>In this above example:</p>
                <ul style={{listStyle:'disc'}}>
                    <li>A custom font called "MyCustomFont" is used.</li>
                    <li>If the custom font is not available, it falls back to Arial or any sans-serif font.</li>
                </ul>
            </div>
        </section>
    )
}