import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';
export const pageUrl = () => "/mongo-db/mongoose/population";

export default function MongoosePopulation() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("What is Population in Mongoose ?");
        title.setKeyWords("population in mongoose schema, mongoose population");
        title.setPageDescription("In MongoDB, population refers to the process of automatically replacing specified paths in a document with documents from other collections. This is a common practice in MongoDB applications to manage references between documents stored in different collections, essentially implementing a form of JOIN operation commonly used in relational databases.");
        path.setPreviousNext({});
    }, [])

    return (
        <>
            <section class='clearfix mb-3'>
                <span className='float-start'>
                    <Link to='/mongo-db/mongoose/introduction' onClick={() => path.setPathName('/mongo-db/mongoose/introduction')} className='btn btn-primary'> <i class="fa-solid fa-less-than"></i>&nbsp; Back to Mongoose </Link>
                </span>
            </section>
            <section className='mt-5 mb-5'>
                <h3>Mongoose - Population</h3>
                <div className='mt-4 mb-5'>
                    <p>
                        In MongoDB, population refers to the process of automatically replacing specified paths in a document with documents from other collections. This is a common practice in MongoDB applications to manage references between documents stored in different collections, essentially implementing a form of JOIN operation commonly used in relational databases.
                    </p>
                    <h5 className='mt-4'>How Population Works</h5>
                    <p>
                        When you have a document that references another document, you typically store the reference as an ObjectId. To get the detailed information from the referenced document, you need to "populate" this field with the actual document.
                    </p>
                    <h5 className='mt-4'>Example Scenario</h5>
                    <p>
                        Consider a simple scenario with two collections: <strong>`Users`</strong> and <strong>`Posts`</strong>. Each post references the user who created it by storing the user's ObjectId.
                    </p>
                    <h6>User Schema</h6>
                    <div className='codePalateBox mt-2 mb-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                                <p><span class="color-blue">const</span> userSchema = <span class="color-blue">new</span> mongoose.<span class="color-red">Schema</span>({</p>
                                <p style="margin-left:30px">    <span class="color-pink">name</span>: <span class="color-red">String</span>,</p>
                                <p style="margin-left:30px">    <span class="color-pink">email</span>: <span class="color-red">String</span>,</p>
                                <p>});</p>
                                <p><span class="color-blue">const</span> <span class="color-red">User</span> = mongoose.<span class="color-red">model</span>(<span class="color-green">'User'</span>, userSchema);</p>
                            `
                        }}></div>
                    </div>
                    <h6>Post Schema</h6>
                    <div className='codePalateBox mt-2 mb-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                                <p><span class="color-blue">const</span> postSchema = <span class="color-blue">new</span> mongoose.<span class="color-red">Schema</span>({</p>
                                <p style="margin-left:30px">    <span class="color-pink">title</span>: <span class="color-red">String</span>,</p>
                                <p style="margin-left:30px">    <span class="color-pink">content</span>: <span class="color-red">String</span>,</p>
                                <p style="margin-left:30px">    <span class="color-pink">author</span>: { <span class="color-pink">type</span>: mongoose.Schema.Types.ObjectId, <span class="color-pink">ref</span>: <span class="color-green">'User'</span> },</p>
                                <p>});</p>
                                <p><span class="color-blue">const</span> Post = mongoose.<span class="color-red">model</span>(<span class="color-green">'Post'</span>, postSchema);</p>
                            `
                        }}></div>
                    </div>
                    <h5 className='mt-4'>Creating Documents</h5>
                    <p>
                        First, create and save a user:
                    </p>
                    <div className='codePalateBox mt-2 mb-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                                <p><span class="color-blue">const</span> newUser = <span class="color-blue">new</span> <span class="color-red">User</span>({ <span class="color-pink">name</span>: <span class="color-green">'Alice'</span>, <span class="color-pink">email</span>: <span class="color-green">'alice@example.com'</span> });</p>
                                <p>newUser.<span class="color-red">save</span>()</p>
                                <p>.<span class="color-red">then</span>(user => {</p>
                                <p style="margin-left:30px">    <span class="color-blue">const</span> newPost = <span class="color-blue">new</span> <span class="color-red">Post</span>({ <span class="color-pink">title</span>: <span class="color-green">'My Post'</span>, <span class="color-pink">content</span>: <span class="color-green">'This is my first post'</span>, <span class="color-pink">author</span>: user._id });</p>
                                <p style="margin-left:30px">    <span class="color-blue">return</span> newPost.<span class="color-red">save</span>();</p>
                                <p>})</p>
                                <p>.<span class="color-red">then</span>(post => <span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">'Post saved:'</span>, post))</p>
                                <p>.<span class="color-red">catch</span>(error => <span class="color-pink">console</span>.<span class="color-red">error</span>(<span class="color-green">'Error:'</span>, error));</p>
                            `
                        }}></div>
                    </div>
                    <h5 className='mt-4'>Populating References</h5>
                    <p>
                        When you retrieve a post, you can populate the <strong>`author`</strong> field to get the full user document instead of just the ObjectId:
                    </p>
                    <div className='codePalateBox mt-2 mb-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                            <p><span class="color-red">Post</span>.<span class="color-red">find</span>()</p>
                            <p>.<span class="color-red">populate</span>(<span class="color-green">'author'</span>)</p>
                            <p>.<span class="color-red">exec</span>((err, posts) => {</p>
                            <p style="margin-left:30px">  <span class="color-blue">if</span> (err) <span class="color-blue">return</span> <span class="color-pink">console</span>.<span class="color-red">error</span>(err);</p>
                            <p style="margin-left:30px">  <span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">'Posts with populated author:'</span>, posts);</p>
                            <p>});</p>
                            `
                        }}></div>
                    </div>
                    <h5 className='mt-4'>Populating Multiple Paths</h5>
                    <p>
                        You can also populate multiple paths if needed:
                    </p>
                    <div className='codePalateBox mt-2 mb-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                            <p><span class="color-red">Post</span>.<span class="color-red">find</span>()</p>
                            <p>.<span class="color-red">populate</span>(<span class="color-green">'author'</span>)</p>
                            <p>.<span class="color-red">populate</span>(<span class="color-green">'anotherField'</span>)</p>
                            <p>.<span class="color-red">exec</span>((err, posts) => {</p>
                            <p style="margin-left:30px">  <span class="color-blue">if</span> (err) <span class="color-blue">return</span> <span class="color-pink">console</span>.<span class="color-red">error</span>(err);</p>
                            <p style="margin-left:30px">  <span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">'Posts with populated fields:'</span>, posts);</p>
                            <p>});</p>
                            `
                        }}></div>
                    </div>
                    <h5 className='mt-4'>Select Specific Fields</h5>
                    <p>
                        You can specify which fields to return in the populated documents:
                    </p>
                    <div className='codePalateBox mt-2 mb-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                            <p><span class="color-red">Post</span>.<span class="color-red">find</span>()</p>
                            <p>.<span class="color-red">populate</span>(<span class="color-green">'author'</span>, <span class="color-green">'name email'</span>) <span class="color-grey">// Only include name and email fields from the author document</span></p>
                            <p>.<span class="color-red">exec</span>((err, posts) => {</p>
                            <p style="margin-left:30px">  <span class="color-blue">if</span> (err) <span class="color-blue">return</span> <span class="color-pink">console</span>.<span class="color-red">error</span>(err);</p>
                            <p style="margin-left:30px">  <span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">'Posts with populated populated author (selected fields):'</span>, posts);</p>
                            <p>});</p>
                            `
                        }}></div>
                    </div>

                    <div className='mt-4 featureClass'>
                        <p>
                            Population in MongoDB allows you to efficiently manage and query relationships between documents across different collections, making it easier to work with complex data structures in a NoSQL environment.
                        </p>
                    </div>
                </div>
            </section>
        </>
    )
}