import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';
export const pageUrl = () => "/mysql/aggregate-functions";

export default function MysqlAggregateFunctions() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Mysql - Aggregate Functions | Aspirant's Home");
        const urls = {
            'previous': '/mysql/string-functions',
            'next': '/mysql/transaction'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>Mysql - Aggregate Functions</h3>
            <div className='mt-4 mb-5'>
                <p>
                    Aggregate functions in MySQL are used to perform calculations on multiple rows of a single column of a table and return a single value. These functions are commonly used in SQL statements to group and summarize data. Here are some of the most commonly used aggregate functions in MySQL:
                </p>
                <table className='table' style={{border: '1px dashed #ccc'}}> 
                    <thead>
                        <tr style={{border: '1px dashed #ccc'}}>
                            <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff', width: '20%'}}>Function Name</th>
                            <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>Description & Syntax</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr style={{ border: '1px dashed #ccc' }}>
                            <td style={{ border: '1px dashed #ccc', textAlign: 'center' }}>
                                <Link to="/mysql/aggregate-functions/count">COUNT()</Link>
                            </td>
                            <td style={{ border: '1px dashed #ccc' }}>
                                <p>
                                    Returns the number of rows that match a specified condition.
                                </p>
                            </td>
                        </tr>
                        <tr style={{ border: '1px dashed #ccc' }}>
                            <td style={{ border: '1px dashed #ccc', textAlign: 'center' }}>
                                <Link to="/mysql/aggregate-functions/sum">SUM()</Link>
                            </td>
                            <td style={{ border: '1px dashed #ccc' }}>
                                <p>
                                    Returns the total sum of a numeric column.
                                </p>
                            </td>
                        </tr>
                        <tr style={{ border: '1px dashed #ccc' }}>
                            <td style={{ border: '1px dashed #ccc', textAlign: 'center' }}>
                                <Link to="/mysql/aggregate-functions/avg">AVG()</Link>
                            </td>
                            <td style={{ border: '1px dashed #ccc' }}>
                                <p>
                                    Returns the average value of a numeric column.
                                </p>
                            </td>
                        </tr>
                        <tr style={{ border: '1px dashed #ccc' }}>
                            <td style={{ border: '1px dashed #ccc', textAlign: 'center' }}>
                                <Link to="/mysql/aggregate-functions/min">MIN()</Link>
                            </td>
                            <td style={{ border: '1px dashed #ccc' }}>
                                <p>
                                    Returns the minimum value in a column.
                                </p>
                            </td>
                        </tr>
                        <tr style={{ border: '1px dashed #ccc' }}>
                            <td style={{ border: '1px dashed #ccc', textAlign: 'center' }}>
                                <Link to="/mysql/aggregate-functions/max">MAX()</Link>
                            </td>
                            <td style={{ border: '1px dashed #ccc' }}>
                                <p>
                                    Returns the maximum value in a column.
                                </p>
                            </td>
                        </tr>
                        <tr style={{ border: '1px dashed #ccc' }}>
                            <td style={{ border: '1px dashed #ccc', textAlign: 'center' }}>
                                <Link to="/mysql/aggregate-functions/group-concat">GROUP_CONCAT()</Link>
                            </td>
                            <td style={{ border: '1px dashed #ccc' }}>
                                <p>
                                    Concatenates values from multiple rows into a single string.
                                </p>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </section>
    )
}