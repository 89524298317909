import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";

export const pageUrl = () => "/css/syntax";

export default function CSSSyntax() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("An Syntax of CSS | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/css/introduction',
            'next': '/css/selectors'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>CSS - Syntax</h3>
            <div className='mt-4 mb-5'>
                <p>
                    CSS (Cascading Style Sheets) syntax is used to define the styles for HTML elements. It has two main parts - one is <strong>`Selector`</strong> and another is <strong>`Declaration Block`</strong>.
                </p>
                <h6>Basic Syntax</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>selector {</p>
                            <p class="ml-30">    property: value;</p>
                            <p>}</p>
                        `
                    }}></div>
                </div>
                <p>
                    Where,
                    <ul style={{ 'listStyle': 'disc' }}>
                        <li>
                            <strong>Selector</strong> basically targets the HTML element(s) to which the style will be applied. <br /> For example, <strong>`h1`</strong>, <strong>`.class-name`</strong>, <strong>`#id-name`</strong>
                        </li>
                        <li>
                            <strong>Declaration Block</strong> contains one or more declarations enclosed in curly braces <strong>`&#123;&#125;`</strong>. <br />For example, <strong>`&#123; color&#58; blue; font-size&#58; 16px; &#125;`</strong>
                        </li>
                    </ul>
                </p>
                <p>
                    In <strong>`Declaration Block`</strong>, You can write multiple set of declarations, each declaration has two parts one is <strong>`property`</strong> and other is <strong>`value`</strong>. 
                    It's basic syntax is <strong>`property: value;`</strong>.
                </p>
                <p>For example, <strong>`color&#58; blue;`</strong> is a declaration, where <strong>`color`</strong> is <strong>`property`</strong> and <strong>`blue`</strong> is <strong>`value`</strong>.</p>

                <h5 className='mt-5 mb-4'>Simple Example of Syntax</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>h1 {</p>
                            <p class="ml-30">    <span class="color-green">color</span>: blue;</p>
                            <p class="ml-30">    <span class="color-green">font-size</span>: <span class="color-pink">24px;</span></p>
                            <p class="ml-30">    <span class="color-green">text-align</span>: center;</p>
                            <p>}</p>
                        `
                    }}></div>
                </div>
                <h5 className='mt-5 mb-4'>Example Explained</h5>
                <ul style={{ listStyle: 'disc' }}>
                    <li>Here, <strong>`h1`</strong> is the <strong>Selector</strong></li>
                    <li> And <strong>`&#123; color: blue; font-size: 24px; text-align: center; &#125;`</strong>  is <strong>Declaration Block</strong>. Below are the declaration that define in the above declaration -
                        <ul style={{ listStyle: 'disc' }}>
                            <li><strong>`color: blue;`</strong></li>
                            <li><strong>`font-size: 24px;`</strong></li>
                            <li><strong>`text-align: center;`</strong></li>
                        </ul>
                    </li>
                </ul>
                <p>
                    This CSS rule define a style to  <strong>`h1`</strong> elements with blue text, a font size of 24 pixels, and center alignment.
                </p>
            </div>
        </section>
    )
}