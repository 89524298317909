import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Outlet, Link } from "react-router-dom";
export const pageUrl = () => "/mysql/drop-users";

export default function MysqlDropUser() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Mysql - Drop User | Aspirant's Home");
        const urls = {
            'previous': '/mysql/create-users',
            'next':'/mysql/show-users'
        }
        path.setPreviousNext(urls);
    },[])

    return (
        <section className='mt-5 mb-5'>
            <h3>Mysql - Drop User</h3>
            <div className='mt-4 mb-4'>
                <p>
                    To drop or delete a user in MySQL, you can use the <strong>`DROP USER`</strong> statement followed by the <strong>`username`</strong> and <strong>`host`</strong>. Here's the basic syntax:
                </p>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-blue">DROP USER</span> <span class="color-green">'username'</span>@<span class="color-green">'host'</span>;</p>
                        `
                    }}></div>
                </div>
                <p>
                    Where <strong>`username`</strong> is The username you want to drop and <strong>`host`</strong> is The host from which the user is allowed to connect.
                </p>
                <p>
                    For example, to drop a user named <strong>`olduser`</strong> that can connect from any host, you would use the following SQL statement:
                </p>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-blue">DROP USER</span> <span class="color-green">'olduser'</span>@<span class="color-green">'%'</span>;</p>
                        `
                    }}></div>
                </div>
                <p>
                    After dropping the user, you should also <Link to="/mysql/revoke-privileges">revoke any privileges</Link> that were granted to the user.
                </p>
            </div>
        </section>
    )
}