import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/react-js/css-modules";

export default function CSSModules() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("React Styling - Using CSS Modules | Aspirant's Home");
        const urls = {
            'previous': '/react-js/inline-styles',
            'next':'/react-js/styled-component'
        }
        path.setPreviousNext(urls);
    },[])

    return (
        <section className='mt-5 mb-5'>
            <h3>React Js - Styling Using CSS Modules</h3>
            <div className='mb-4 mt-4'>
                <p>To avoid CSS class name clashes, we can use <strong>CSS Modules</strong> to style the React app. <strong>CSS Modules</strong> dynamically change the CSS class name to a unique one in the DOM.</p>
                <p>
                    Suppose, we have a common css file named <strong>`style.css`.</strong>
                    <div className='codePalateBox mt-2 mb-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `<p>.<span class="color-red">error</span> {</p>
                                <p style="margin-left:30px">color: red;</p>
                            <p>}</p>
                            `
                        }}></div>
                    </div>
                </p>
                <p>Let's make an css file for component specific named <strong>`Button.module.css`.</strong>
                    <div className='codePalateBox mt-2 mb-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `<p><span class="color-red">error</span> {</p>
                                <p style="margin-left:30px">background-color: red;</p>
                            <p>}</p>
                            `
                        }}></div>
                    </div>
                </p>
                <p>Ok lets see in the below component
                    <div className='codePalateBox mt-2 mb-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                            <p><span class="color-red">import</span> React, { Component } <span class="color-blue">from</span> <span class="color-green">'react'</span>;</p>
                            <p><span class="color-red">import</span> styles <span class="color-blue">from</span> <span class="color-green">'./Button.module.css'</span>; <span class="color-grey">// Import css modules stylesheet as styles</span></p>
                            <p><span class="color-red">import</span> <span class="color-green">'./style.css'</span>; <span class="color-grey">// Import regular stylesheet</span></p>
                            <br />
                            <p><span class="color-blue">function</span> Button(){</p>
                                <p style="margin-left:30px"><span class="color-blue">return</span> &lt;button <span class="color-red">className</span>={styles.error}&gt;Error Button&lt;/button&gt;;</p>
                            <p>}</p>
                            `
                        }}></div>
                    </div>
                </p>
                <p>You can see the results below
                    <div className='codePalateBox mt-2 mb-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `<p class="color-grey">&lt;!-- This button has red background but not red text --&gt;</p>
                        <p>&lt;button <span class="color-pink">class</span>=<span class="color-green">"Button_error_ax7yz"</span>&gt;Error Button&lt;/button&gt;</p>`
                        }}></div>
                    </div>
                </p>
                <p>Therefore, CSS modules provide scoped styles, which means styles are only applied to the component they are imported in, avoiding global style conflicts.</p>
            </div>
        </section>
    )
}