import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/html/favicon";

export default function HTMLFavIcon() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("HTML - Favicon | Aspirant's Home");
        const urls = {
            'previous': '/html/images',
            'next': '/html/page-title'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>HTML - Favicon</h3>
            <div className='mt-4 mb-5'>
                <p>
                    A favicon (short for "favorite icon") is a small icon associated with a particular website or web page. This icon is displayed in the browser's address bar, tabs, bookmarks, and other interface elements that show the site identity. Using a favicon helps with branding and makes your site easily recognizable to users.
                </p>
                <h5 className='mt-5 mb-3'>Adding a Favicon to Your Website</h5>
                <p>
                    To add a favicon to your website, you need to link to the icon file within the <strong>`&lt;head&gt;`</strong> section of your HTML document using the <strong>`&lt;link&gt;`</strong> element. The favicon file is usually named <strong>`favicon.ico`</strong>, but it can be any image file format such as PNG, GIF, or SVG.
                </p>
                <div className='mt-3 myImage'>
                    <img className='img-fluid' src='../assets/html/html-favicon.png' />
                </div>
                <h6 className='mt-5 mb-3'>Example of Adding a Favicon</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-grey">&lt;!DOCTYPE</span> <span class="color-blue">html</span><span class="color-grey">&gt;</span></p>
                        <p>&lt;html <span class="color-pink">lang</span>=<span class="color-green">"en"</span>&gt;</p>
                        <p class="ml-30">    &lt;head&gt;</p>
                        <p class="ml-60">        &lt;meta <span class="color-pink">charset</span>=<span class="color-green">"UTF-8"</span>&gt;</p>
                        <p class="ml-60">        &lt;meta <span class="color-pink">name</span>=<span class="color-green">"viewport"</span> <span class="color-pink">content</span>=<span class="color-green">"width=device-width, initial-scale=1.0"</span>&gt;</p>
                        <p class="ml-60">        &lt;title&gt;Example Page with Favicon&lt;/title&gt;</p>
                        <p class="ml-60 color-grey">        &lt;!-- Favicon link --&gt;</p>
                        <p class="ml-60">        &lt;link <span class="color-pink">rel</span>=<span class="color-green">"icon"</span> <span class="color-pink">href</span>=<span class="color-green">"favicon.ico"</span> <span class="color-pink">type</span>=<span class="color-green">"image/x-icon"</span>&gt;</p>
                        <p class="ml-60 color-grey">        &lt;!-- Alternate formats for favicon --&gt;</p>
                        <p class="ml-60">        &lt;link <span class="color-pink">rel</span>=<span class="color-green">"icon"</span> <span class="color-pink">href</span>=<span class="color-green">"favicon.png"</span> <span class="color-pink">type</span>=<span class="color-green">"image/png"</span>&gt;</p>
                        <p class="ml-30">    &lt;/head&gt;</p>
                        <p class="ml-30">    &lt;body&gt;</p>
                        <p class="ml-60">        &lt;h1&gt;Hello, World!&lt;/h1&gt;</p>
                        <p class="ml-30">    &lt;/body&gt;</p>
                        <p>&lt;/html&gt;</p>
                        `
                    }}></div>
                </div>
                <h5 className='mt-5 mb-3'>Favicon File Format Support</h5>
                <p>The following table shows the file format support for a favicon image:</p>
                <table class="table">
                    <tbody>
                        <tr>
                            <th>Browser</th>
                            <th>ICO</th>
                            <th>PNG</th>
                            <th>GIF</th>
                            <th>JPEG</th>
                            <th>SVG</th>
                        </tr>
                        <tr>
                            <td>Edge</td>
                            <td>Yes</td>
                            <td>Yes</td>
                            <td>Yes</td>
                            <td>Yes</td>
                            <td>Yes</td>
                        </tr>
                        <tr>
                            <td>Chrome</td>
                            <td>Yes</td>
                            <td>Yes</td>
                            <td>Yes</td>
                            <td>Yes</td>
                            <td>Yes</td>
                        </tr>
                        <tr>
                            <td>Firefox</td>
                            <td>Yes</td>
                            <td>Yes</td>
                            <td>Yes</td>
                            <td>Yes</td>
                            <td>Yes</td>
                        </tr>
                        <tr>
                            <td>Opera</td>
                            <td>Yes</td>
                            <td>Yes</td>
                            <td>Yes</td>
                            <td>Yes</td>
                            <td>Yes</td>
                        </tr>
                        <tr>
                            <td>Safari</td>
                            <td>Yes</td>
                            <td>Yes</td>
                            <td>Yes</td>
                            <td>Yes</td>
                            <td>Yes</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </section>
    )
}