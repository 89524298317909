import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../../Context";
import { Link } from 'react-router-dom';
export const pageUrl = () => "/mysql/numeric-functions/sin-cos-tan";

export default function SinCosTan() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Mysql - Numeric Functions - SIN(x), COS(x), and TAN(x) | Aspirant's Home");
        path.setPreviousNext({});
    }, [])

    return (
        <>
            <section class='clearfix mb-3'>
                <span className='float-start'>
                    <Link to='/mysql/numeric-functions' onClick={() => path.setPathName('/mysql/numeric-functions')} className='btn btn-primary'> <i class="fa-solid fa-less-than"></i>&nbsp; Back to Numeric Functions </Link>
                </span>
            </section>
            <section className='mt-5 mb-5'>
                <h3>Mysql - SIN(x), COS(x), and TAN(x) Function</h3>
                <div className='mt-4 mb-5'>
                    <p>
                        The trigonometric functions <strong>`SIN()`</strong>, <strong>`COS()`</strong>, and <strong>`TAN()`</strong> in MySQL can be useful in various scenarios, such as calculating angles, distances, or positions in geographic information systems (GIS) or in mathematical calculations.
                    </p>
                    <p>
                        Here is a basic syntax of trigonometric function in a SELECT query:
                    </p>
                    <div className='codePalateBox mt-2 mb-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                                <p><span class="color-blue">SELECT</span> SIN(x); <span class="color-grey">// Returns the sine of x, where x is in radians.</span></p>
                                <p><span class="color-blue">SELECT</span> COS(x); <span class="color-grey">// Returns the cosine of x, where x is in radians.</span></p>
                                <p><span class="color-blue">SELECT</span> TAN(x); <span class="color-grey">// Returns the tangent of x, where x is in radians.</span></p>
                            `
                        }}></div>
                    </div>
                </div>
                <div className='mt-5 mb-5'>
                    <h5>SIN(x), COS(x), and TAN(x) with MySQL Table</h5>
                    <div className='mt-3 mb-2'>
                        <p>
                            Suppose you have a table <strong>`vectors`</strong> that stores information about the angles (in radians) of various vectors, and you want to calculate the sine, cosine, and tangent of these angles.
                        </p>
                        <table className='table' style={{border: '1px dashed #ccc'}}> 
                            <thead>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>id</th>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>vector_name</th>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>angle_rad</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>1</td>
                                    <td style={{border: '1px dashed #ccc'}}>Vector A</td>
                                    <td style={{border: '1px dashed #ccc'}}>1.047</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>2</td>
                                    <td style={{border: '1px dashed #ccc'}}>Vector B</td>
                                    <td style={{border: '1px dashed #ccc'}}>2.094</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>3</td>
                                    <td style={{border: '1px dashed #ccc'}}>Vector C</td>
                                    <td style={{border: '1px dashed #ccc'}}>0.785</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>4</td>
                                    <td style={{border: '1px dashed #ccc'}}>Vector D</td>
                                    <td style={{border: '1px dashed #ccc'}}>3.142</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>5</td>
                                    <td style={{border: '1px dashed #ccc'}}>Vector E</td>
                                    <td style={{border: '1px dashed #ccc'}}>0.524</td>
                                </tr>
                            </tbody>
                        </table>
                        <p>
                            You can use the <strong>`SIN()`</strong>, <strong>`COS()`</strong>, and <strong>`TAN()`</strong> functions to calculate the sine, cosine, and tangent of the angles, respectively.
                        </p>
                        <div className='codePalateBox mt-2 mb-2'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-blue">SELECT</span> id, vector_name, angle_rad, SIN(angle_rad) <span class="color-blue">AS</span> sine, COS(angle_rad) <span class="color-blue">AS</span> cosine, TAN(angle_rad) <span class="color-blue">AS</span> tangent <span class="color-blue">FROM</span> vectors;</p>
                                `
                            }}></div>
                        </div>
                        <p>
                            This query will return:
                        </p>
                        <table className='table' style={{border: '1px dashed #ccc'}}> 
                            <thead>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>id</th>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>vector_name</th>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>angle_rad</th>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>sine</th>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>cosine</th>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>tangent</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>1</td>
                                    <td style={{border: '1px dashed #ccc'}}>Vector A</td>
                                    <td style={{border: '1px dashed #ccc'}}>1.047</td>
                                    <td style={{border: '1px dashed #ccc'}}>0.8660254037844386</td>
                                    <td style={{border: '1px dashed #ccc'}}>0.5000000000000001</td>
                                    <td style={{border: '1px dashed #ccc'}}>1.7320508075688776</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>2</td>
                                    <td style={{border: '1px dashed #ccc'}}>Vector B</td>
                                    <td style={{border: '1px dashed #ccc'}}>2.094</td>
                                    <td style={{border: '1px dashed #ccc'}}>0.8660254037844386</td>
                                    <td style={{border: '1px dashed #ccc'}}>-0.5000000000000001</td>
                                    <td style={{border: '1px dashed #ccc'}}>-1.732050807568877</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>3</td>
                                    <td style={{border: '1px dashed #ccc'}}>Vector C</td>
                                    <td style={{border: '1px dashed #ccc'}}>0.785</td>
                                    <td style={{border: '1px dashed #ccc'}}>0.7071067811865476</td>
                                    <td style={{border: '1px dashed #ccc'}}>0.7071067811865476</td>
                                    <td style={{border: '1px dashed #ccc'}}>0.9999999999999999</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>4</td>
                                    <td style={{border: '1px dashed #ccc'}}>Vector D</td>
                                    <td style={{border: '1px dashed #ccc'}}>3.142</td>
                                    <td style={{border: '1px dashed #ccc'}}>-2.4492935982947064e-16</td>
                                    <td style={{border: '1px dashed #ccc'}}>-1.0</td>
                                    <td style={{border: '1px dashed #ccc'}}>2.4492935982947064e-16</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>5</td>
                                    <td style={{border: '1px dashed #ccc'}}>Vector E</td>
                                    <td style={{border: '1px dashed #ccc'}}>0.524</td>
                                    <td style={{border: '1px dashed #ccc'}}>0.49999999999999994</td>
                                    <td style={{border: '1px dashed #ccc'}}>0.8660254037844387</td>
                                    <td style={{border: '1px dashed #ccc'}}>0.5773502691896257</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </section>
        </>
    )
}