import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import PreviousNext from '../PreviousNext';
export const pageUrl = () => "/react-js/features";

export default function ReactFeatures() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Features of React Js | Aspirant's Home");
        const urls = {
            'previous': '/react-js/introduction',
            'next':'/react-js/start-new-application'
        }
        path.setPreviousNext(urls);
    },[])

    return (
        <section className='mt-5 mb-5'>
            <h3>Features of React Js</h3>
            <div className='mt-5 featureClass'>
                <p>Here are some key features and concepts of React Js:</p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>React simplifies the creation of dynamic applications by reducing the amount of code required and offering more functionality compared to traditional JavaScript, which can quickly become complex.</li>
                    <li>One of React's key features is its use of Virtual DOM, which improves performance by updating only the components that have changed, rather than re-rendering the entire DOM.</li>
                    <li>Components are the building blocks of React applications, and they can be reused throughout the application, reducing development time significantly.</li>
                    <li>React follows a unidirectional data flow, making it easier to debug errors and understand the flow of data in an application.</li>
                    <li>Despite its powerful features, React has a small learning curve, as it combines basic HTML and JavaScript concepts with additional features.</li>
                    <li>React is not limited to web development; it can also be used to create mobile applications using React Native, a framework derived from React.</li>
                    <li>Facebook provides dedicated tools, such as a Chrome extension for debugging React applications, making the development process faster and easier.</li>
                </ul>
            </div>
        </section>
    )
}
