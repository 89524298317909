import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/html/blocks";

export default function HTMLBlocks() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("HTML - Blocks & Inline | Aspirant's Home");
        const urls = {
            'previous': '/html/styles',
            'next': '/html/formatting'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>HTML - Blocks & Inline</h3>
            <div className='mt-4 mb-5'>
                <p>
                    HTML elements can be categorized into two main types: block-level elements and inline elements. Understanding the difference between these types is essential for controlling the layout and structure of a webpage.
                </p>
                <h5 className='mt-5 mb-3'>Block-Level Elements</h5>
                <p>
                    Block-level elements occupy the full width of their parent container and always start on a new line. They typically contain other block-level elements and inline elements. Block-level elements are used to create the basic structure and layout of a webpage.
                </p>
                <h6>Common Block-Level Elements</h6>
                <p> <strong>`&lt;div&gt;`</strong>, <strong>`&lt;p&gt;`</strong>, <strong>`&lt;h1&gt;`</strong> to <strong>`&lt;h6&gt;`</strong>, <strong>`&lt;ul&gt;`</strong>, <strong>`&lt;ol&gt;`</strong>, <strong>`&lt;li&gt;`</strong>,  <strong>`&lt;header&gt;`</strong>,  <strong>`&lt;footer&gt;`</strong>, <strong>`&lt;section&gt;`</strong>, <strong>`&lt;article&gt;`</strong>, <strong>`&lt;nav&gt;`</strong>, <strong>`&lt;aside&gt;`</strong>,  <strong>`&lt;main&gt;`</strong>,  <strong>`&lt;blockquote&gt;`</strong>, <strong>`&lt;figure&gt;`</strong>, <strong>`&lt;figcaption&gt;`</strong>
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-grey">&lt;!DOCTYPE</span> <span class="color-blue">html</span><span class="color-grey">&gt;</span></p>
                        <p>&lt;html <span class="color-pink">lang</span>=<span class="color-green">"en"</span>&gt;</p>
                        <p class="ml-30">    &lt;head&gt;</p>
                        <p class="ml-60">        &lt;meta <span class="color-pink">charset</span>=<span class="color-green">"UTF-8"</span>&gt;</p>
                        <p class="ml-60">        &lt;meta <span class="color-pink">name</span>=<span class="color-green">"viewport"</span> <span class="color-pink">content</span>=<span class="color-green">"width=device-width, initial-scale=1.0"</span>&gt;</p>
                        <p class="ml-60">        &lt;title&gt;Block-Level Elements&lt;/title&gt;</p>
                        <p class="ml-60">        &lt;style&gt;</p>
                        <p class="ml-90">            .block-element {</p>
                        <p class="ml-120">                <span class="color-green">border</span>: <span class="color-pink">1px</span> solid <span class="color-pink">#000</span>;</p>
                        <p class="ml-120">                <span class="color-green">padding</span>: <span class="color-pink">10px</span>;</p>
                        <p class="ml-120">               <span class="color-green">margin</span>: <span class="color-pink">10px 0</span>;</p>
                        <p class="ml-90">            }</p>
                        <p class="ml-60">        &lt;/style&gt;</p>
                        <p class="ml-30">    &lt;/head&gt;</p>
                        <p class="ml-30">    &lt;body&gt;</p>
                        <p class="ml-60">        &lt;div <span class="color-pink">class</span>=<span class="color-green">"block-element"</span>&gt;This is a div element, which is block-level.&lt;/div&gt;</p>
                        <p class="ml-60">        &lt;p <span class="color-pink">class</span>=<span class="color-green">"block-element"</span>&gt;This is a paragraph element, which is block-level.&lt;/p&gt;</p>
                        <p class="ml-60">        &lt;h1 <span class="color-pink">class</span>=<span class="color-green">"block-element"</span>&gt;This is an h1 element, which is block-level.&lt;/h1&gt;</p>
                        <p class="ml-60">        &lt;section <span class="color-pink">class</span>=<span class="color-green">"block-element"</span>&gt;</p>
                        <p class="ml-90">            This is a section element, which is block-level.</p>
                        <p class="ml-90">            &lt;article <span class="color-pink">class</span>=<span class="color-green">"block-element"</span>&gt;</p>
                        <p class="ml-120">                This is an article element inside a section.</p>
                        <p class="ml-90">            &lt;/article&gt;</p>
                        <p class="ml-60">        &lt;/section&gt;</p>
                        <p class="ml-30">    &lt;/body&gt;</p>
                        <p>&lt;/html&gt;</p>
                        `
                    }}></div>
                </div>
                <p>
                    Output will be the following -
                </p>
                <div className='outputPalateBox mb-4'>
                    <div className='outputPalate' dangerouslySetInnerHTML={{
                        __html: `
                        <!DOCTYPE html>
                        <html lang="en">
                            <head>
                                <meta charset="UTF-8">
                                <meta name="viewport" content="width=device-width, initial-scale=1.0">
                                <title>Block-Level Elements</title>
                                <style>
                                    .block-element {
                                        border: 1px solid red;
                                        padding: 10px;
                                        margin: 10px 0;
                                        color: #000;s
                                    }
                                </style>
                            </head>
                            <body>
                                <div class="block-element">This is a div element, which is block-level.</div>
                                <p class="block-element">This is a paragraph element, which is block-level.</p>
                                <h1 class="block-element">This is an h1 element, which is block-level.</h1>
                                <section class="block-element">
                                    This is a section element, which is block-level.
                                    <article class="block-element">
                                        This is an article element inside a section.
                                    </article>
                                </section>
                            </body>
                        </html>
                        `
                    }}></div>
                </div>
                <h5 className='mt-5 mb-3'>Inline Elements</h5>
                <p>
                    Inline elements do not start on a new line and only take up as much width as necessary. They are typically used to style parts of the text within block-level elements. Inline elements cannot contain block-level elements.
                </p>
                <h6>Common Inline Elements</h6>
                <p>
                    <strong>`&lt;span&gt;`</strong>,<strong>`&lt;a&gt;`</strong>, <strong>`&lt;strong&gt;`</strong>, <strong>`&lt;em&gt;`</strong>, <strong>`&lt;img&gt;`</strong>, <strong>`&lt;br&gt;`</strong>, <strong>`&lt;code&gt;`</strong>, <strong>`&lt;small&gt;`</strong>, <strong>`&lt;sup&gt;`</strong>, <strong>`&lt;sub&gt;`</strong>, <strong>`&lt;button&gt;`</strong>, <strong>`&lt;input&gt;`</strong>, <strong>`&lt;label&gt;`</strong>
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-grey">&lt;!DOCTYPE</span> <span class="color-blue">html</span><span class="color-grey">&gt;</span></p>
                        <p>&lt;html <span class="color-pink">lang</span>=<span class="color-green">"en"</span>&gt;</p>
                        <p class="ml-30">    &lt;head&gt;</p>
                        <p class="ml-60">        &lt;meta <span class="color-pink">charset</span>=<span class="color-green">"UTF-8"</span>&gt;</p>
                        <p class="ml-60">        &lt;meta <span class="color-pink">name</span>=<span class="color-green">"viewport"</span> <span class="color-pink">content</span>=<span class="color-green">"width=device-width, initial-scale=1.0"</span>&gt;</p>
                        <p class="ml-60">        &lt;title&gt;Inline Elements&lt;/title&gt;</p>
                        <p class="ml-60">        &lt;style&gt;</p>
                        <p class="ml-90">            .<span class="color-pink">inline-element</span> {</p>
                        <p class="ml-120">                <span class="color-green">border</span>: <span class="color-pink">1px</span> dashed <span class="color-pink">#000;</span></p>
                        <p class="ml-90">            }</p>
                        <p class="ml-60">        &lt;/style&gt;</p>
                        <p class="ml-30">    &lt;/head&gt;</p>
                        <p class="ml-30">    &lt;body&gt;</p>
                        <p class="ml-60">        &lt;p&gt;This is a paragraph containing &lt;span <span class="color-pink">class</span>=<span class="color-green">"inline-element"</span>&gt;a span element&lt;/span&gt;, which is inline.&lt;/p&gt;</p>
                        <p class="ml-60">        &lt;p&gt;This is a paragraph containing an &lt;a <span class="color-pink">href</span>=<span class="color-green">"#"</span> <span class="color-pink">class</span>=<span class="color-green">"inline-element"</span>&gt;anchor element&lt;/a&gt;, which is inline.&lt;/p&gt;</p>
                        <p class="ml-60">        &lt;p&gt;This is a paragraph containing &lt;strong <span class="color-pink">class</span>=<span class="color-green">"inline-element"</span>&gt;strong text&lt;/strong&gt;, which is inline.&lt;/p&gt;</p>
                        <p class="ml-30">    &lt;/body&gt;</p>
                        <p>&lt;/html&gt;</p>
                        `
                    }}></div>
                </div>
                <p>
                    Output will be the following -
                </p>
                <div className='outputPalateBox  mb-4'>
                    <div className='outputPalate' dangerouslySetInnerHTML={{
                        __html: `
                        <!DOCTYPE html>
                        <html lang="en">
                            <head>
                                <meta charset="UTF-8">
                                <meta name="viewport" content="width=device-width, initial-scale=1.0">
                                <title>Inline Elements</title>
                                <style>
                                    .inline-element {
                                        border: 1px dashed red;
                                        padding:5px;
                                    }
                                </style>
                            </head>
                            <body>
                                <p class="mb-3">This is a paragraph containing <span class="inline-element">a span element</span>, which is inline.</p>
                                <p class="mb-3">This is a paragraph containing an <a href="#" class="inline-element">anchor element</a>, which is inline.</p>
                                <p class="mb-3">This is a paragraph containing <strong class="inline-element">strong text</strong>, which is inline.</p>
                            </body>
                        </html>
                        `
                    }}></div>
                </div>
                <h5 className='mt-5 mb-3'>Block-Level Elements vs Inline Elements</h5>
                <table className='table'>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Block-Level Elements</th>
                            <th>Inline Elements</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                Display Behavior
                            </td>
                            <td>
                                Start on a new line and take up the full width available.
                            </td>
                            <td>
                                Do not start on a new line and only take up as much width as necessary.
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Containment
                            </td>
                            <td>
                                Can contain other block-level elements and inline elements.
                            </td>
                            <td>
                                Cannot contain block-level elements, but can contain other inline elements.
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Use Cases
                            </td>
                            <td>
                                Used to create the main structure and layout of a webpage (e.g., headers, sections, articles).
                            </td>
                            <td>
                                Used to style and format parts of the text within block-level elements (e.g., links, emphasized text).
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </section>
    )
}