import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/node-js/mysql-join-table";

export default function MysqlJoinTable() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Node Js - Mysql - Join Table | Aspirant's Home");
        const urls = {
            'previous': '/node-js/mysql-limit-clause',
            'next': '/node-js/mongodb'
        }
        path.setPreviousNext(urls);
    }, [])
    
    return (
        <section className='mt-5 mb-5'>
            <h3 className='mb-4'>Node Js Mysql Join Table</h3>
            <p>In Mysql, We can join two or more table to fetch associated data among them using JOIN keywords. There are mainly 3 types of JOIN we are using. They are INNER JOIN, LEFT JOIN and RIGHT JOIN. </p>
            <p>Lets learn with an example. We have 2 tables named "employee" and "department".</p>
            <h6>Employee table</h6>
            <div className='codePalateBox mt-2 mb-2'>
                <div className='codePalate' dangerouslySetInnerHTML={{
                    __html: `<p>[</p>
                        <p style='margin-left:10px'>{ id: 1, name: 'John', email: 'john@gmail.com', department: },</p>
                        <p style='margin-left:10px'>{ id: 2, name: 'Peter', email: 'peter@gmail.com', department: },</p>
                        <p style='margin-left:10px'>{ id: 3, name: 'Amy', email: 'amy@gmail.com', department: 3 },</p>
                        <p style='margin-left:10px'>{ id: 4, name: 'Hannah', email: 'hannah@gmail.com', department: 4 },</p>
                        <p style='margin-left:10px'>{ id: 5, name: 'Michael', email: 'michael@gmail.com', department: 5 }</p>
                        ]`
                }}></div>
            </div>
            <h6>Department table</h6>
            <div className='codePalateBox mt-2 mb-2'>
                <div className='codePalate' dangerouslySetInnerHTML={{
                    __html: `<p>[</p>
                        <p style='margin-left:10px'>{ id: 1, name: 'Quality Assurance' },</p>
                        <p style='margin-left:10px'>{ id: 2, name: 'Manager' },</p>
                        <p style='margin-left:10px'>{ id: 3, name: 'Team Lead' },</p>
                        <p style='margin-left:10px'>{ id: 4, name: 'Web Developer' },</p>
                        <p style='margin-left:10px'>{ id: 5, name: 'Web Designer' }</p>
                        ]`
                }}></div>
            </div>
            <p>Let's fetch employee name, employee email along with department name joining above two table.</p>
            <div className='codePalateBox mt-2 mb-2'>
                <div className='codePalate' dangerouslySetInnerHTML={{
                    __html: `<p><span class='color-blue'>var</span> mysql = <span class='color-yellow'>require</span>(<span class='color-green'>'mysql'</span>);</p>
                    <br />
                    <p><span class='color-blue'>var</span> con = mysql.<span class='color-red'>createConnection</span>({</p>
                        <p style='margin-left:30px'><span class='color-pink'>host</span>: <span class='color-green'>"localhost"</span>,</p>
                        <p style='margin-left:30px'><span class='color-pink'>user</span>: <span class='color-green'>"yourusername"</span>,</p>
                        <p style='margin-left:30px'><span class='color-pink'>password</span>: <span class='color-green'>"yourpassword"</span>,</p>
                        <p style='margin-left:30px'><span class='color-pink'>database</span>: <span class='color-green'>"mydb"</span></p>
                    <p>});</p>
                    <br />
                    <p>con.<span class='color-red'>connect</span>(function(err) {</p>
                        <p style='margin-left:30px'><span class='color-blue'>if</span> (err) throw err;</p>
                        <p style='margin-left:30px'><span class='color-blue'>var</span> sql = "SELECT employee.name AS employee_name,employee.email AS employee_email, department.name AS department_name FROM employee JOIN department ON employee.department = department.id";</p>
                        <p style='margin-left:30px'>con.<span class='color-red'>query</span>(sql, function (err, result, fields) {</p>
                        <p style='margin-left:60px'><span class='color-blue'>if</span> (err) throw err;</p>
                        <p style='margin-left:60px'><span class='color-pink'>console</span>.<span class='color-red'>log</span>(result);</p>
                        <p style='margin-left:30px'>});</p>
                    <p>});</p>
                    `
                }}></div>
            </div>
            <p>Which will give you this result:</p>
            <div className='codePalateBox mt-2 mb-5'>
                <div className='codePalate' dangerouslySetInnerHTML={{
                    __html: `<p>[</p>
                        <p style='margin-left:10px'>{ employee_name : 'Amy', employee_email : 'amy@gmail.com', department_name : 'Team Lead'},</p>
                        <p style='margin-left:10px'>{ employee_name : 'Hannah', employee_email : 'hannah@gmail.com', department_name : 'Web Developer'},</p>
                        <p style='margin-left:10px'>{ employee_name : 'Michael', employee_email: 'michael@gmail.com', department_name : 'Web Designer'},</p>
                        ]`
                }}></div>
            </div>
            <h5 className='mb-4'>LEFT JOIN</h5>
            <p>In MySQL, a LEFT JOIN is a type of join that retrieves records from the left table (table1) and the matched records from the right table (table2). It also includes all the records from the left table, even if there are no matches in the right table.</p>
            <p>Ok lets see what will be the result if we use left join</p>
            <div className='codePalateBox mt-2 mb-2'>
                <div className='codePalate' dangerouslySetInnerHTML={{
                    __html: `<span class='color-green'>SELECT employee.name AS employee_name,employee.email AS employee_email, department.name AS department_name FROM employee LEFT JOIN department ON employee.department = department.id</span>
                    `}}></div>
            </div>
            <p>Which will give you this result:</p>
            <div className='codePalateBox mt-2 mb-5'>
                <div className='codePalate' dangerouslySetInnerHTML={{
                    __html: `<p>[</p>
                        <p style='margin-left:10px'>{ employee_name : 'John', employee_email : 'john@gmail.com', department_name : null },</p>
                        <p style='margin-left:10px'>{ employee_name : 'Peter', employee_email : 'peter@gmail.com', department_name : null },</p>
                        <p style='margin-left:10px'>{ employee_name : 'Amy', employee_email : 'amy@gmail.com', department_name : 'Team Lead' },</p>
                        <p style='margin-left:10px'>{ employee_name : 'Hannah', employee_email : 'hannah@gmail.com', department_name : 'Web Developer' },</p>
                        <p style='margin-left:10px'>{ employee_name : 'Michael', employee_email: 'michael@gmail.com', department_name : 'Web Designer' },</p>
                        ]`
                }}></div>
            </div>
            <h5 className='mb-4'>RIGHT JOIN</h5>
            <p>In MySQL, a RIGHT JOIN is a type of join that retrieves all records from the right table (table2), and the matched records from the left table (table1). It includes all the records from the right table, even if there are no matches in the left table.</p>
            <p>Ok lets see what will be the result if we use right join</p>
            <div className='codePalateBox mt-2 mb-2'>
                <div className='codePalate' dangerouslySetInnerHTML={{
                    __html: `<span class='color-green'>SELECT employee.name AS employee_name,employee.email AS employee_email, department.name AS department_name FROM employee RIGHT JOIN department ON employee.department = department.id</span>
                    `}}></div>
            </div>
            <p>Which will give you this result:</p>
            <div className='codePalateBox mt-2 mb-2'>
                <div className='codePalate' dangerouslySetInnerHTML={{
                    __html: `<p>[</p>
                        <p style='margin-left:10px'>{ employee_name : null, employee_email : null, department_name : 'Quality Assurance' },</p>
                        <p style='margin-left:10px'>{ employee_name : null, employee_email : null, department_name : 'Manager' },</p>
                        <p style='margin-left:10px'>{ employee_name : 'Amy', employee_email : 'amy@gmail.com', department_name : 'Team Lead' },</p>
                        <p style='margin-left:10px'>{ employee_name : 'Hannah', employee_email : 'hannah@gmail.com', department_name : 'Web Developer' },</p>
                        <p style='margin-left:10px'>{ employee_name : 'Michael', employee_email: 'michael@gmail.com', department_name : 'Web Designer' },</p>
                        ]`
                }}></div>
            </div>
        </section>
    )
}
