import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/mysql/clone-tables";

export default function MysqlCloneTable() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Mysql - Clone Table | Aspirant's Home");
        const urls = {
            'previous': '/mysql/rename-tables',
            'next': '/mysql/truncate-tables'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>Mysql - Clone Table</h3>
            <div className='mt-4 mb-5'>
                <p>
                    In MySQL, the cloning operation enables the creation of an exact copy of an existing table, including its definition. This cloned table is entirely independent from the original, so any modifications made to the clone do not affect the original table, and vice versa.
                </p>
            </div>
            <div className='mt-5 mb-5'>
                <h5>Clone Table - Structure and Data</h5>
                <p>
                    To clone or duplicate a table in MySQL, you can use the <strong>`CREATE TABLE`</strong> statement along with a <strong>`SELECT`</strong> query that retrieves data from the original table. Here's a basic example:
                </p>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-blue">CREATE TABLE</span> new_table_name <span class="color-blue">AS SELECT</span> * <span class="color-blue">FROM</span> original_table_name;</p>
                        `
                    }}></div>
                </div>
                <p>
                    This statement creates a new table called <strong>`new_table_name`</strong> with the same structure and data as <strong>`original_table_name`</strong>.
                </p>
            </div>
            <div className='mt-5 mb-5'>
                <h5>Clone Table - only Structure</h5>
                <p>
                    If you want to copy only the structure (without copying any data), you can use a <strong>`SELECT`</strong> query that returns no rows:
                </p>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-blue">CREATE TABLE</span> new_table_name <span class="color-blue">AS SELECT</span> * <span class="color-blue">FROM</span> original_table_name <span class="color-blue">WHERE</span> <span class="color-pink">1</span>=<span class="color-pink">0</span>;</p>
                        `
                    }}></div>
                </div>
                <p>
                    Or, you can use <strong>`LIKE`</strong> keyword with <strong>`CREATE TABLE`</strong> statement to copy only the structure. Here is the syntax -
                </p>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-blue">CREATE TABLE</span> new_table_name <span class="color-blue">LIKE</span>  original_table_name;</p>
                        `
                    }}></div>
                </div>
            </div>
        </section>
    )
}