import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../../Context";
import { Link } from 'react-router-dom';
export const pageUrl = () => "/mysql/date-and-time-functions/date-sub";

export default function DateSub() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Mysql - Date and Time functions - DATE_SUB() | Aspirant's Home");
        path.setPreviousNext({});
    }, [])

    return (
        <>
            <section class='clearfix mb-3'>
                <span className='float-start'>
                    <Link to='/mysql/date-and-time-functions' onClick={() => path.setPathName('/mysql/date-and-time-functions')} className='btn btn-primary'> <i class="fa-solid fa-less-than"></i>&nbsp; Back to Date and Time functions </Link>
                </span>
            </section>
            <section className='mt-5 mb-5'>
                <h3>Mysql - DATE_SUB() Functions</h3>
                <div className='mt-4 mb-5'>
                    <p>
                        The <strong>`DATE_SUB()`</strong> function in MySQL is used to subtract a specified time interval from a date. It takes three arguments: the date or datetime value you want to subtract the interval from, the interval value, and the interval unit.
                    </p>
                    <p>
                        Here's the basic syntax of the <strong>`DATE_SUB()`</strong> function:
                    </p>
                    <div className='codePalateBox mt-2 mb-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                                <p>DATE_SUB(date_value, <span class="color-pink">INTERVAL</span> value unit)</p>
                            `
                        }}></div>
                    </div>
                    <p>
                        Where, <strong>`date_value`</strong> is the date or datetime value you want to subtract the interval from. <strong>`value`</strong> is the number of units you want to subtract. <strong>`unit`</strong> is the unit of time (e.g., DAY, WEEK, MONTH, YEAR) you want to subtract.
                    </p>
                    <p>
                        For example, if you have a date <strong>`'2022-05-23'`</strong> and you want to subtract 7 days from it, you can use <strong>`DATE_SUB()`</strong> like this:
                    </p>
                    <div className='codePalateBox mt-2 mb-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                                <p><span class="color-blue">SELECT</span> DATE_SUB(<span class="color-green">'2022-05-23'</span>, <span class="color-pink">INTERVAL 7</span> DAY);</p>
                            `
                        }}></div>
                    </div>
                    <p>
                        This will return <strong>`'2022-05-16'`</strong>, which is 7 days before <strong>`'2022-05-23'`</strong>.
                    </p>
                </div>
                <div className='mt-5 mb-5'>
                    <h5>DATE_SUB() with MySQL Table</h5>
                    <div className='mt-4 mb-2'>
                        <p>
                            To use the <strong>`DATE_SUB()`</strong> function in MySQL with a table, let's consider a table <strong>`events`</strong> with columns <strong>`id`</strong>, <strong>`event_name`</strong>, and <strong>`start_date`</strong> where <strong>`start_date`</strong> represents the start date of each event as a date value. We want to subtract a specified number of days from the <strong>`start_date`</strong> column.
                        </p>
                        <p>
                            Consider the <strong>`events`</strong> table:
                        </p>
                        <table className='table' style={{border: '1px dashed #ccc'}}> 
                            <thead>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>id</th>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>event_name</th>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>start_date</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>1</td>
                                    <td style={{border: '1px dashed #ccc'}}>Event A</td>
                                    <td style={{border: '1px dashed #ccc'}}>2022-05-23</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>2</td>
                                    <td style={{border: '1px dashed #ccc'}}>Event B</td>
                                    <td style={{border: '1px dashed #ccc'}}>2022-05-24</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>3</td>
                                    <td style={{border: '1px dashed #ccc'}}>Event C</td>
                                    <td style={{border: '1px dashed #ccc'}}>2022-05-25</td>
                                </tr>
                            </tbody>
                        </table>
                        <p>
                            To subtract 7 days from the <strong>`start_date`</strong> column for each event, you would use:
                        </p>
                        <div className='codePalateBox mt-2 mb-2'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-blue">SELECT</span> id, event_name, start_date, DATE_SUB(start_date, <span class="color-pink">INTERVAL 7</span> DAY) <span class="color-blue">AS</span> new_start_date <span class="color-blue">FROM</span> events;</p>
                                `
                            }}></div>
                        </div>
                        <p>
                            This query will return:
                        </p>
                        <table className='table' style={{border: '1px dashed #ccc'}}> 
                            <thead>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>id</th>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>event_name</th>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>start_date</th>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>new_start_date</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>1</td>
                                    <td style={{border: '1px dashed #ccc'}}>Event A</td>
                                    <td style={{border: '1px dashed #ccc'}}>2022-05-23</td>
                                    <td style={{border: '1px dashed #ccc'}}>2022-05-16</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>2</td>
                                    <td style={{border: '1px dashed #ccc'}}>Event B</td>
                                    <td style={{border: '1px dashed #ccc'}}>2022-05-24</td>
                                    <td style={{border: '1px dashed #ccc'}}>2022-05-17</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>3</td>
                                    <td style={{border: '1px dashed #ccc'}}>Event C</td>
                                    <td style={{border: '1px dashed #ccc'}}>2022-05-25</td>
                                    <td style={{border: '1px dashed #ccc'}}>2022-05-18</td>
                                </tr>
                            </tbody>
                        </table>
                        <p>
                            In this example, the <strong>`DATE_SUB()`</strong> function is used to subtract 7 days from the <strong>`start_date`</strong> column for each event. The result is displayed in the <strong>`new_start_date`</strong> column.
                        </p>
                    </div>
                </div>
            </section>
        </>
    )
}