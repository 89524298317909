import React, { useEffect, useState, useRef } from 'react'
import { Outlet, Link } from "react-router-dom";
import TopHeader from '../TopHeader';
import Footer from '../Footer'
import { MenuContext, TitleContext, PathContext } from "../../Context";
import { useContext } from "react";
import PreviousNext from '../PreviousNext';

export default function CSSLayout() {

    const addspaceElement = useRef();
    const menuElement = useRef([]);
    const menu = useContext(MenuContext);
    const pathName = useContext(PathContext);
    const pathname = pathName.pathName;
    const [showSubMenu, setShowSubMenu] = useState(document.getElementsByClassName("activeSubMenu")[0] ? false : true)
    const title = useContext(TitleContext);

    const setPathName = (path) => {
        if(path) pathName.setPathName(path);
    }

    const OpenShowSubMenu = (e, path) => {
        //console.log(path, e.target);
        setPathName(path)
        e.currentTarget.nextSibling.style.display = e.currentTarget.nextSibling.style.display == 'block' ? 'none' : 'block';
    }

    useEffect(() => {
        console.log(pathname)
        setPathName(window.location.pathname)
        if (menu.isMenu == true) menu.setMenuOpen(false)
        addspaceElement.current.scrollIntoView(true)
        const element = document.getElementsByClassName("activeMenu")[0];
        element && element.scrollIntoView({ block: "center" });
        const subelement = document.getElementsByClassName("activeSubMenu")[0];
        subelement && subelement.scrollIntoView({ block: "center" });
        console.log(subelement)
    }, [pathname,showSubMenu])

    return (
        <>
            <TopHeader />
            <div className='container-fluid'>
                <div className='row'>
                    <div ref={menuElement} className={menu.isMenu == true ? 'col-md-3 col-lg-2 menuColumnMobile menuColumn p-0' : 'col-md-3 col-lg-2 menuColumn p-0'} style={{ 'overflowY': 'scroll', height: 'calc(100vh - 110px)' }}>
                        <div className='headerMenu'>
                            CSS Tutorial
                        </div>
                        <nav>
                            <ul>
                                <li className={pathname == '/css/introduction' ? 'activeMenu' : ''}>
                                    <Link to="/css/introduction" onClick={() => { setPathName('/css/introduction'); } }>Introduction</Link>
                                </li>
                                <li className={pathname == '/css/syntax' ? 'activeMenu' : ''}>
                                    <Link to="/css/syntax" onClick={() => { setPathName('/css/syntax'); } }>Syntax</Link>
                                </li>
                                <li className={pathname == '/css/selectors' ? 'activeMenu' : ''}>
                                    <Link to="/css/selectors" onClick={() => { setPathName('/css/selectors'); } }>Selectors</Link>
                                </li>
                                <li className={pathname == '/css/how-to-use' ? 'activeMenu' : ''}>
                                    <Link to="/css/how-to-use" onClick={() => { setPathName('/css/how-to-use'); } }>How To Use</Link>
                                </li>
                                <li className={pathname == '/css/comments' ? 'activeMenu' : ''}>
                                    <Link to="/css/comments" onClick={() => { setPathName('/css/comments'); } }>Comments</Link>
                                </li>
                                <li>
                                    <Link to="/css/colors" onClick={(e) => OpenShowSubMenu(e, '/css/colors')}>Colors
                                        <span className='dropdown float-end'>
                                            <span className="btn btn-secondary dropdown-toggle dropdown-link-menu"></span>
                                        </span>
                                    </Link>
                                    <ul style={{'display':(pathname == '/css/colors' || pathname == '/css/colors/rgb' || pathname == '/css/colors/hex' || pathname == '/css/colors/hsl') ? 'block' :'none'}}>
                                        <li className={pathname == '/css/colors' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/css/colors" onClick={() => setPathName('/css/colors')}>
                                                <span className='ml-30'>Colors</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/css/colors/rgb' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/css/colors/rgb" onClick={() => setPathName('/css/colors/rgb')}>
                                                <span className='ml-30'>RGB</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/css/colors/hex' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/css/colors/hex" onClick={() => setPathName('/css/colors/hex')}>
                                                <span className='ml-30'>HEX</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/css/colors/hsl' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/css/colors/hsl" onClick={() => setPathName('/css/colors/hsl')}>
                                                <span className='ml-30'>HSL</span>
                                            </Link>
                                        </li>
                                    </ul>
                                </li>     
                                <li>
                                    <Link to="/css/background-color" onClick={(e) => OpenShowSubMenu(e, '/css/background-color')}>Backgrounds
                                        <span className='dropdown float-end'>
                                            <span className="btn btn-secondary dropdown-toggle dropdown-link-menu"></span>
                                        </span>
                                    </Link>
                                    <ul style={{ 'display': (pathname == '/css/background-color' || pathname == '/css/background-image' || pathname == '/css/background-repeat' || pathname == '/css/background-attachment' || pathname == '/css/background-position' || pathname == '/css/background-origin' || pathname == '/css/background-size' || pathname == '/css/background-clip' || pathname == '/css/background') ? 'block' : 'none' }}>
                                        <li className={pathname == '/css/background-color' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/css/background-color" onClick={() => setPathName('/css/background-color')}>
                                                <span className='ml-30'>Background Color</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/css/background-image' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/css/background-image" onClick={() => setPathName('/css/background-image')}>
                                                <span className='ml-30'>Background Image</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/css/background-repeat' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/css/background-repeat" onClick={() => setPathName('/css/background-repeat')}>
                                                <span className='ml-30'>Background Repeat</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/css/background-attachment' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/css/background-attachment" onClick={() => setPathName('/css/background-attachment')}>
                                                <span className='ml-30'>Background Attachment</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/css/background-position' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/css/background-position" onClick={() => setPathName('/css/background-position')}>
                                                <span className='ml-30'>Background Position</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/css/background-origin' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/css/background-origin" onClick={() => setPathName('/css/background-origin')}>
                                                <span className='ml-30'>Background Origin</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/css/background-size' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/css/background-size" onClick={() => setPathName('/css/background-size')}>
                                                <span className='ml-30'>Background Size</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/css/background-clip' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/css/background-clip" onClick={() => setPathName('/css/background-clip')}>
                                                <span className='ml-30'>Background Clip</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/css/background' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/css/background" onClick={() => setPathName('/css/background')}>
                                                <span className='ml-30'>Background Shorthand</span>
                                            </Link>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <Link to="/css/border" onClick={(e) => OpenShowSubMenu(e,'/css/border')}>Borders
                                        <span className='dropdown float-end'>
                                            <span className="btn btn-secondary dropdown-toggle dropdown-link-menu"></span>
                                        </span>
                                    </Link>
                                    <ul style={{ 'display': (pathname == '/css/border' || pathname == '/css/border-width' || pathname == '/css/border-color' || pathname == '/css/border-sides' || pathname == '/css/border-shorthand' || pathname == '/css/border-rounded') ? 'block' : 'none' }}>
                                        <li className={pathname == '/css/border' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/css/border" onClick={() => setPathName('/css/border')}>
                                                <span className='ml-30'>Border</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/css/border-width' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/css/border-width" onClick={() => setPathName('/css/border-width')}>
                                                <span className='ml-30'>Border Width</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/css/border-color' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/css/border-color" onClick={() => setPathName('/css/border-color')}>
                                                <span className='ml-30'>Border Color</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/css/border-sides' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/css/border-sides" onClick={() => setPathName('/css/border-sides')}>
                                                <span className='ml-30'>Border Sides</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/css/border-shorthand' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/css/border-shorthand" onClick={() => setPathName('/css/border-shorthand')}>
                                                <span className='ml-30'>Border Shorthand</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/css/border-rounded' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/css/border-rounded" onClick={() => setPathName('/css/border-rounded')}>
                                                <span className='ml-30'>Rounded Borders</span>
                                            </Link>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <Link to="/css/margins" onClick={(e) => OpenShowSubMenu(e, '/css/margins')}>Margins
                                        <span className='dropdown float-end'>
                                            <span className="btn btn-secondary dropdown-toggle dropdown-link-menu"></span>
                                        </span>
                                    </Link>
                                    <ul style={{ 'display': (pathname == '/css/margins' || pathname == '/css/margin/collapse') ? 'block' : 'none' }}>
                                        <li className={pathname == '/css/margins' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/css/margins" onClick={() => setPathName('/css/margins')}>
                                                <span className='ml-30'>Margins</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/css/margin/collapse' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/css/margin/collapse" onClick={() => setPathName('/css/margin/collapse')}>
                                                <span className='ml-30'>Margin Collapse</span>
                                            </Link>
                                        </li>
                                    </ul>
                                </li>
                                <li className={pathname == '/css/padding' ? 'activeMenu' : ''}>
                                    <Link to="/css/padding" onClick={() => { setPathName('/css/padding'); } }>Padding</Link>
                                </li>
                                <li className={pathname == '/css/height/width' ? 'activeMenu' : ''}>
                                    <Link to="/css/height/width" onClick={() => { setPathName('/css/height/width'); } }>Height/Width</Link>
                                </li>
                                <li className={pathname == '/css/box/model' ? 'activeMenu' : ''}>
                                    <Link to="/css/box/model" onClick={() => { setPathName('/css/box/model'); } }>Box Model</Link>
                                </li>
                                <li>
                                    <Link to="/css/outline" onClick={(e) => OpenShowSubMenu(e, '/css/outline')}>Outline
                                        <span className='dropdown float-end'>
                                            <span className="btn btn-secondary dropdown-toggle dropdown-link-menu"></span>
                                        </span>
                                    </Link>
                                    <ul style={{ 'display': (pathname == '/css/outline' || pathname == '/css/outline/width' || pathname == '/css/outline/color' || pathname == '/css/outline/shorthand' || pathname == '/css/outline/offset') ? 'block' : 'none' }}>
                                        <li className={pathname == '/css/outline' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/css/outline" onClick={() => setPathName('/css/outline')}>
                                                <span className='ml-30'>Outline</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/css/outline/width' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/css/outline/width" onClick={() => setPathName('/css/outline/width')}>
                                                <span className='ml-30'>Outline Width</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/css/outline/color' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/css/outline/color" onClick={() => setPathName('/css/outline/color')}>
                                                <span className='ml-30'>Outline Color</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/css/outline/shorthand' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/css/outline/shorthand" onClick={() => setPathName('/css/outline/shorthand')}>
                                                <span className='ml-30'>Outline Shorthand</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/css/outline/offset' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/css/outline/offset" onClick={() => setPathName('/css/outline/offset')}>
                                                <span className='ml-30'>Outline Offset</span>
                                            </Link>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <Link to="/css/text/color" onClick={(e) => OpenShowSubMenu(e, '/css/text/color')}>Text
                                        <span className='dropdown float-end'>
                                            <span className="btn btn-secondary dropdown-toggle dropdown-link-menu"></span>
                                        </span>
                                    </Link>
                                    <ul style={{ 'display': (pathname == '/css/text/color' || pathname == '/css/text/alignment' || pathname == '/css/text/decoration' || pathname == '/css/text/transformation' || pathname == '/css/text/spacing' || pathname == '/css/text/shadow') ? 'block' : 'none' }}>
                                        <li className={pathname == '/css/text/color' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/css/text/color" onClick={() => setPathName('/css/text/color')}>
                                                <span className='ml-30'>Text Color</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/css/text/alignment' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/css/text/alignment" onClick={() => setPathName('/css/text/alignment')}>
                                                <span className='ml-30'>Text Alignment</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/css/text/decoration' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/css/text/decoration" onClick={() => setPathName('/css/text/decoration')}>
                                                <span className='ml-30'>Text Decoration</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/css/text/transformation' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/css/text/transformation" onClick={() => setPathName('/css/text/transformation')}>
                                                <span className='ml-30'>Text Transformation</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/css/text/spacing' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/css/text/spacing" onClick={() => setPathName('/css/text/spacing')}>
                                                <span className='ml-30'>Text Spacing</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/css/text/shadow' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/css/text/shadow" onClick={() => setPathName('/css/text/shadow')}>
                                                <span className='ml-30'>Text Shadow</span>
                                            </Link>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <Link to="/css/font/family" onClick={(e) => OpenShowSubMenu(e, '/css/font/family')}>Fonts
                                        <span className='dropdown float-end'>
                                            <span className="btn btn-secondary dropdown-toggle dropdown-link-menu"></span>
                                        </span>
                                    </Link>
                                    <ul style={{ 'display': (pathname == '/css/font/family' || pathname == '/css/font/web/safe' || pathname == '/css/font/fallbacks' || pathname == '/css/font/style' || pathname == '/css/font/size' || pathname == '/css/font/google' || pathname == '/css/font/pairings' || pathname == '/css/font/shorthand') ? 'block' : 'none' }}>
                                        <li className={pathname == '/css/font/family' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/css/font/family" onClick={() => setPathName('/css/font/family')}>
                                                <span className='ml-30'>Font Family</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/css/font/web/safe' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/css/font/web/safe" onClick={() => setPathName('/css/font/web/safe')}>
                                                <span className='ml-30'>Font Web Safe</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/css/font/fallbacks' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/css/font/fallbacks" onClick={() => setPathName('/css/font/fallbacks')}>
                                                <span className='ml-30'>Font Fallbacks</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/css/font/style' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/css/font/style" onClick={() => setPathName('/css/font/style')}>
                                                <span className='ml-30'>Font Style</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/css/font/size' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/css/font/size" onClick={() => setPathName('/css/font/size')}>
                                                <span className='ml-30'>Font Size</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/css/font/google' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/css/font/google" onClick={() => setPathName('/css/font/google')}>
                                                <span className='ml-30'>Font Google</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/css/font/pairings' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/css/font/pairings" onClick={() => setPathName('/css/font/pairings')}>
                                                <span className='ml-30'>Font Pairings</span>
                                            </Link>
                                        </li>
                                            <li className={pathname == '/css/font/shorthand' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/css/font/shorthand" onClick={() => setPathName('/css/font/shorthand')}>
                                                <span className='ml-30'>Font Shorthand</span>
                                            </Link>
                                        </li>
                                    </ul>
                                </li>
                                <li className={pathname == '/css/icons' ? 'activeMenu' : ''}>
                                    <Link to="/css/icons" onClick={() => { setPathName('/css/icons'); } }>Icons</Link>
                                </li>
                                <li className={pathname == '/css/links' ? 'activeMenu' : ''}>
                                    <Link to="/css/links" onClick={() => { setPathName('/css/links'); } }>Links</Link>
                                </li>    
                                <li className={pathname == '/css/lists' ? 'activeMenu' : ''}>
                                    <Link to="/css/lists" onClick={() => { setPathName('/css/lists'); } }>Lists</Link>
                                </li>
                                <li>
                                    <Link to="/css/table/borders" onClick={(e) => OpenShowSubMenu(e, '/css/table/borders')}>Tables
                                        <span className='dropdown float-end'>
                                            <span className="btn btn-secondary dropdown-toggle dropdown-link-menu"></span>
                                        </span>
                                    </Link>
                                    <ul style={{ 'display': (pathname == '/css/table/borders' || pathname == '/css/table/size' || pathname == '/css/table/alignment' || pathname == '/css/table/style' || pathname == '/css/table/responsive') ? 'block' : 'none' }}>
                                        <li className={pathname == '/css/table/borders' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/css/table/borders" onClick={() => setPathName('/css/table/borders')}>
                                                <span className='ml-30'>Table Borders</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/css/table/size' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/css/table/size" onClick={() => setPathName('/css/table/size')}>
                                                <span className='ml-30'>Table Size</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/css/table/alignment' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/css/table/alignment" onClick={() => setPathName('/css/table/alignment')}>
                                                <span className='ml-30'>Table Alignment</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/css/table/style' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/css/table/style" onClick={() => setPathName('/css/table/style')}>
                                                <span className='ml-30'>Table Style</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/css/table/responsive' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/css/table/responsive" onClick={() => setPathName('/css/table/responsive')}>
                                                <span className='ml-30'>Table Responsive</span>
                                            </Link>
                                        </li>
                                    </ul>
                                </li>
                                <li className={pathname == '/css/display' ? 'activeMenu' : ''}>
                                    <Link to="/css/display" onClick={() => { setPathName('/css/display'); } }>Display</Link>
                                </li>
                                <li className={pathname == '/css/maxwidth' ? 'activeMenu' : ''}>
                                    <Link to="/css/maxwidth" onClick={() => { setPathName('/css/maxwidth'); } }>Max-width</Link>
                                </li>
                                <li className={pathname == '/css/position' ? 'activeMenu' : ''}>
                                    <Link to="/css/position" onClick={() => { setPathName('/css/position'); } }>Position</Link>
                                </li>
                                <li className={pathname == '/css/zindex' ? 'activeMenu' : ''}>
                                    <Link to="/css/zindex" onClick={() => { setPathName('/css/zindex'); } }>Z-index</Link>
                                </li>
                                <li className={pathname == '/css/overflow' ? 'activeMenu' : ''}>
                                    <Link to="/css/overflow" onClick={() => { setPathName('/css/overflow'); } }>Overflow</Link>
                                </li>
                                <li>
                                    <Link to="/css/float" onClick={(e) => OpenShowSubMenu(e, '/css/float')}>Float
                                        <span className='dropdown float-end'>
                                            <span className="btn btn-secondary dropdown-toggle dropdown-link-menu"></span>
                                        </span>
                                    </Link>
                                    <ul style={{ 'display': (pathname == '/css/float' || pathname == '/css/clear') ? 'block' : 'none' }}>
                                        <li className={pathname == '/css/float' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/css/float" onClick={() => setPathName('/css/float')}>
                                                <span className='ml-30'>Float</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/css/clear' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/css/clear" onClick={() => setPathName('/css/clear')}>
                                                <span className='ml-30'>Clear</span>
                                            </Link>
                                        </li>
                                    </ul>
                                </li>
                                <li className={pathname == '/css/inline-block' ? 'activeMenu' : ''}>
                                    <Link to="/css/inline-block" onClick={() => { setPathName('/css/inline-block'); } }>Inline-block</Link>
                                </li>
                                <li className={pathname == '/css/align' ? 'activeMenu' : ''}>
                                    <Link to="/css/align" onClick={() => { setPathName('/css/align'); } }>Align</Link>
                                </li>
                                <li className={pathname == '/css/combinators' ? 'activeMenu' : ''}>
                                    <Link to="/css/combinators" onClick={() => { setPathName('/css/combinators'); } }>Combinators</Link>
                                </li>
                                <li className={pathname == '/css/pseudo-class' ? 'activeMenu' : ''}>
                                    <Link to="/css/pseudo-class" onClick={() => { setPathName('/css/pseudo-class'); } }>Pseudo-class</Link>
                                </li>
                                <li className={pathname == '/css/pseudo-element' ? 'activeMenu' : ''}>
                                    <Link to="/css/pseudo-element" onClick={() => { setPathName('/css/pseudo-element'); } }>Pseudo-element</Link>
                                </li>
                                <li className={pathname == '/css/opacity' ? 'activeMenu' : ''}>
                                    <Link to="/css/opacity" onClick={() => { setPathName('/css/opacity'); } }>Opacity</Link>
                                </li>
                                <li>
                                    <Link to="/css/navbar" onClick={(e) => OpenShowSubMenu(e, '/css/navbar')}>Navigation Bar
                                        <span className='dropdown float-end'>
                                            <span className="btn btn-secondary dropdown-toggle dropdown-link-menu"></span>
                                        </span>
                                    </Link>
                                    <ul style={{ 'display': (pathname == '/css/navbar' || pathname == '/css/vertical/navbar' || pathname == '/css/horizontal/navbar') ? 'block' : 'none' }}>
                                        <li className={pathname == '/css/navbar' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/css/navbar" onClick={() => setPathName('/css/navbar')}>
                                                <span className='ml-30'>Navbar</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/css/vertical/navbar' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/css/vertical/navbar" onClick={() => setPathName('/css/vertical/navbar')}>
                                                <span className='ml-30'>Vertical Navbar</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/css/horizontal/navbar' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/css/horizontal/navbar" onClick={() => setPathName('/css/horizontal/navbar')}>
                                                <span className='ml-30'>Horizontal Navbar</span>
                                            </Link>
                                        </li>
                                    </ul>
                                </li>
                                <li className={pathname == '/css/dropdowns' ? 'activeMenu' : ''}>
                                    <Link to="/css/dropdowns" onClick={() => { setPathName('/css/dropdowns'); } }>Dropdowns</Link>
                                </li>
                                <li className={pathname == '/css/image/gallery' ? 'activeMenu' : ''}>
                                    <Link to="/css/image/gallery" onClick={() => { setPathName('/css/image/gallery'); } }>Image Gallery</Link>
                                </li>
                                <li className={pathname == '/css/image/sprites' ? 'activeMenu' : ''}>
                                    <Link to="/css/image/sprites" onClick={() => { setPathName('/css/image/sprites'); } }>Image Sprites</Link>
                                </li>
                                <li className={pathname == '/css/attr/selectors' ? 'activeMenu' : ''}>
                                    <Link to="/css/attr/selectors" onClick={() => { setPathName('/css/attr/selectors'); } }>Attr Selectors</Link>
                                </li>
                                <li className={pathname == '/css/forms' ? 'activeMenu' : ''}>
                                    <Link to="/css/forms" onClick={() => { setPathName('/css/forms'); } }>Forms</Link>
                                </li>
                                <li className={pathname == '/css/counters' ? 'activeMenu' : ''}>
                                    <Link to="/css/counters" onClick={() => { setPathName('/css/counters'); } }>Counters</Link>
                                </li>
                                <li className={pathname == '/css/website/layout' ? 'activeMenu' : ''}>
                                    <Link to="/css/website/layout" onClick={() => { setPathName('/css/website/layout'); } }>Website Layout</Link>
                                </li>
                                <li className={pathname == '/css/units' ? 'activeMenu' : ''}>
                                    <Link to="/css/units" onClick={() => { setPathName('/css/units'); } }>Units</Link>
                                </li>
                                <li className={pathname == '/css/specificity' ? 'activeMenu' : ''}>
                                    <Link to="/css/specificity" onClick={() => { setPathName('/css/specificity'); } }>Specificity</Link>
                                </li>
                                <li className={pathname == '/css/important' ? 'activeMenu' : ''}>
                                    <Link to="/css/important" onClick={() => { setPathName('/css/important'); } }>!important</Link>
                                </li>
                                <li className={pathname == '/css/math/functions' ? 'activeMenu' : ''}>
                                    <Link to="/css/math/functions" onClick={() => { setPathName('/css/math/functions'); } }>Math Functions</Link>
                                </li>
                            </ul>
                        </nav>
                        <div className='headerMenu'>
                            CSS Advanced
                        </div>
                        <nav>
                            <ul>
                                <li className={pathname == '/css/rounded/corners' ? 'activeMenu' : ''}>
                                    <Link to="/css/rounded/corners" onClick={() => { setPathName('/css/rounded/corners'); } }>Rounded Corners</Link>
                                </li>
                                <li className={pathname == '/css/border/images' ? 'activeMenu' : ''}>
                                    <Link to="/css/border/images" onClick={() => { setPathName('/css/border/images'); } }>Border Images</Link>
                                </li>
                                <li className={pathname == '/css/multiple/backgrounds' ? 'activeMenu' : ''}>
                                    <Link to="/css/multiple/backgrounds" onClick={() => { setPathName('/css/multiple/backgrounds'); } }>Backgrounds</Link>
                                </li>
                                <li>
                                    <Link to="/css/linear/gradients" onClick={(e) => OpenShowSubMenu(e, '/css/linear/gradients')}>Gradients
                                        <span className='dropdown float-end'>
                                            <span className="btn btn-secondary dropdown-toggle dropdown-link-menu"></span>
                                        </span>
                                    </Link>
                                    <ul style={{ 'display': (pathname == '/css/linear/gradients' || pathname == '/css/radikal/gradients' || pathname == '/css/conic/gradients') ? 'block' : 'none' }}>
                                        <li className={pathname == '/css/linear/gradients' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/css/linear/gradients" onClick={() => setPathName('/css/linear/gradients')}>
                                                <span className='ml-30'>Linear Gradients</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/css/radikal/gradients' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/css/radikal/gradients" onClick={() => setPathName('/css/radikal/gradients')}>
                                                <span className='ml-30'>Radikal Gradients</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/css/conic/gradients' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/css/conic/gradients" onClick={() => setPathName('/css/conic/gradients')}>
                                                <span className='ml-30'>Conic Gradients</span>
                                            </Link>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <Link to="/css/shadows" onClick={(e) => OpenShowSubMenu(e, '/css/shadows')}>Shadows
                                        <span className='dropdown float-end'>
                                            <span className="btn btn-secondary dropdown-toggle dropdown-link-menu"></span>
                                        </span>
                                    </Link>
                                    <ul style={{ 'display': (pathname == '/css/shadows' || pathname == '/css/box/shadow') ? 'block' : 'none' }}>
                                        <li className={pathname == '/css/shadows' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/css/shadows" onClick={() => setPathName('/css/shadows')}>
                                                <span className='ml-30'>Shadow Effects</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/css/box/shadow' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/css/box/shadow" onClick={() => setPathName('/css/box/shadow')}>
                                                <span className='ml-30'>Box Shadow</span>
                                            </Link>
                                        </li>
                                    </ul>
                                </li>
                                <li className={pathname == '/css/text/effects' ? 'activeMenu' : ''}>
                                    <Link to="/css/text/effects" onClick={() => { setPathName('/css/text/effects'); } }>Text Effects</Link>
                                </li>
                                <li className={pathname == '/css/web/fonts' ? 'activeMenu' : ''}>
                                    <Link to="/css/web/fonts" onClick={() => { setPathName('/css/web/fonts'); } }>Web Fonts</Link>
                                </li>
                                <li className={pathname == '/css/2d/transforms' ? 'activeMenu' : ''}>
                                    <Link to="/css/2d/transforms" onClick={() => { setPathName('/css/2d/transforms'); } }>2D Transforms</Link>
                                </li>
                                <li className={pathname == '/css/3d/transforms' ? 'activeMenu' : ''}>
                                    <Link to="/css/3d/transforms" onClick={() => { setPathName('/css/3d/transforms'); } }>3D Transforms</Link>
                                </li>
                                <li className={pathname == '/css/transitions' ? 'activeMenu' : ''}>
                                    <Link to="/css/transitions" onClick={() => { setPathName('/css/transitions'); } }>Transitions</Link>
                                </li>
                                <li className={pathname == '/css/animations' ? 'activeMenu' : ''}>
                                    <Link to="/css/animations" onClick={() => { setPathName('/css/animations'); } }>Animations</Link>
                                </li>
                                <li className={pathname == '/css/tooltips' ? 'activeMenu' : ''}>
                                    <Link to="/css/tooltips" onClick={() => { setPathName('/css/tooltips'); } }>Tooltips</Link>
                                </li>
                                <li className={pathname == '/css/style/images' ? 'activeMenu' : ''}>
                                    <Link to="/css/style/images" onClick={() => { setPathName('/css/style/images'); } }>Style Images</Link>
                                </li>
                                <li className={pathname == '/css/image/reflection' ? 'activeMenu' : ''}>
                                    <Link to="/css/image/reflection" onClick={() => { setPathName('/css/image/reflection'); } }>Image Reflection</Link>
                                </li>
                                <li className={pathname == '/css/masking' ? 'activeMenu' : ''}>
                                    <Link to="/css/masking" onClick={() => { setPathName('/css/masking'); } }>Masking</Link>
                                </li>
                                <li className={pathname == '/css/buttons' ? 'activeMenu' : ''}>
                                    <Link to="/css/buttons" onClick={() => { setPathName('/css/buttons'); } }>Buttons</Link>
                                </li>
                                <li className={pathname == '/css/pagination' ? 'activeMenu' : ''}>
                                    <Link to="/css/pagination" onClick={() => { setPathName('/css/pagination'); } }>Pagination</Link>
                                </li>
                                <li className={pathname == '/css/multiple/columns' ? 'activeMenu' : ''}>
                                    <Link to="/css/multiple/columns" onClick={() => { setPathName('/css/multiple/columns'); } }>Multiple Columns</Link>
                                </li>
                                <li className={pathname == '/css/user/interface' ? 'activeMenu' : ''}>
                                    <Link to="/css/user/interface" onClick={() => { setPathName('/css/user/interface'); } }>User Interface</Link>
                                </li>
                                <li>
                                    <Link to="/css/variables" onClick={(e) => OpenShowSubMenu(e, '/css/variables')}>Variables
                                        <span className='dropdown float-end'>
                                            <span className="btn btn-secondary dropdown-toggle dropdown-link-menu"></span>
                                        </span>
                                    </Link>
                                    <ul style={{ 'display': (pathname == '/css/variables' || pathname == '/css/overriding/variables' || pathname == '/css/media-query/variables') ? 'block' : 'none' }}>
                                        <li className={pathname == '/css/variables' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/css/variables" onClick={() => setPathName('/css/variables')}>
                                                <span className='ml-30'>The var() Function</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/css/overriding/variables' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/css/overriding/variables" onClick={() => setPathName('/css/overriding/variables')}>
                                                <span className='ml-30'>Overriding Variables</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/css/media-query/variables' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/css/media-query/variables" onClick={() => setPathName('/css/media-query/variables')}>
                                                <span className='ml-30'>Variables in Media Queries</span>
                                            </Link>
                                        </li>
                                    </ul>
                                </li>
                                <li className={pathname == '/css/box/sizing' ? 'activeMenu' : ''}>
                                    <Link to="/css/box/sizing" onClick={() => { setPathName('/css/box/sizing'); } }>Box Sizing</Link>
                                </li>
                                <li className={pathname == '/css/media/queries' ? 'activeMenu' : ''}>
                                    <Link to="/css/media/queries" onClick={() => { setPathName('/css/media/queries'); } }>Media Queries</Link>
                                </li>
                                <li>
                                    <Link to="/css/flexbox" onClick={(e) => OpenShowSubMenu(e, '/css/flexbox')}>Flexbox
                                        <span className='dropdown float-end'>
                                            <span className="btn btn-secondary dropdown-toggle dropdown-link-menu"></span>
                                        </span>
                                    </Link>
                                    <ul style={{ 'display': (pathname == '/css/flexbox' || pathname == '/css/flex/container' || pathname == '/css/flex/items') ? 'block' : 'none' }}>
                                        <li className={pathname == '/css/flexbox' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/css/flexbox" onClick={() => setPathName('/css/flexbox')}>
                                                <span className='ml-30'>Flexbox</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/css/flex/container' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/css/flex/container" onClick={() => setPathName('/css/flex/container')}>
                                                <span className='ml-30'>Flex Container</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/css/flex/items' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/css/flex/items" onClick={() => setPathName('/css/flex/items')}>
                                                <span className='ml-30'>Flex Items</span>
                                            </Link>
                                        </li>
                                        <li className={pathname == '/css/media-query/variables' ? 'activeSubMenu' : 'background-white'}>
                                            <Link to="/css/media-query/variables" onClick={() => setPathName('/css/media-query/variables')}>
                                                <span className='ml-30'>Variables in Media Queries</span>
                                            </Link>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </nav>
                        <div className='headerMenu'>
                            CSS Properties
                        </div>
                        <nav>
                            <ul>
                                <li className={pathname == '/css/accent-color' ? 'activeMenu' : ''}>
                                    <Link to="/css/accent-color" onClick={() => { setPathName('/css/accent-color'); } }>accent-color</Link>
                                </li>
                                <li className={pathname == '/css/align-content' ? 'activeMenu' : ''}>
                                    <Link to="/css/align-content" onClick={() => { setPathName('/css/align-content'); } }>align-content</Link>
                                </li>
                                <li className={pathname == '/css/align-items' ? 'activeMenu' : ''}>
                                    <Link to="/css/align-items" onClick={() => { setPathName('/css/align-items'); } }>align-items</Link>
                                </li>
                                <li className={pathname == '/css/align-self' ? 'activeMenu' : ''}>
                                    <Link to="/css/align-self" onClick={() => { setPathName('/css/align-self'); } }>align-self</Link>
                                </li>
                                <li className={pathname == '/css/all' ? 'activeMenu' : ''}>
                                    <Link to="/css/all" onClick={() => { setPathName('/css/all'); } }>all</Link>
                                </li>
                                <li className={pathname == '/css/animation' ? 'activeMenu' : ''}>
                                    <Link to="/css/animation" onClick={() => { setPathName('/css/animation'); } }>animation</Link>
                                </li>
                                <li className={pathname == '/css/animation-delay' ? 'activeMenu' : ''}>
                                    <Link to="/css/animation-delay" onClick={() => { setPathName('/css/animation-delay'); } }>animation-delay</Link>
                                </li>
                                <li className={pathname == '/css/animation-direction' ? 'activeMenu' : ''}>
                                    <Link to="/css/animation-direction" onClick={() => { setPathName('/css/animation-direction'); } }>animation-direction</Link>
                                </li>
                                <li className={pathname == '/css/animation-duration' ? 'activeMenu' : ''}>
                                    <Link to="/css/animation-duration" onClick={() => { setPathName('/css/animation-duration'); } }>animation-duration</Link>
                                </li>
                                <li className={pathname == '/css/animation-fill-mode' ? 'activeMenu' : ''}>
                                    <Link to="/css/animation-fill-mode" onClick={() => { setPathName('/css/animation-fill-mode'); } }>animation-fill-mode</Link>
                                </li>
                                <li className={pathname == '/css/animation-iteration-count' ? 'activeMenu' : ''}>
                                    <Link to="/css/animation-iteration-count" onClick={() => { setPathName('/css/animation-iteration-count'); } }>animation-iteration-count</Link>
                                </li>
                                <li className={pathname == '/css/animation-name' ? 'activeMenu' : ''}>
                                    <Link to="/css/animation-name" onClick={() => { setPathName('/css/animation-name'); } }>animation-name</Link>
                                </li>
                                <li className={pathname == '/css/animation-play-state' ? 'activeMenu' : ''}>
                                    <Link to="/css/animation-play-state" onClick={() => { setPathName('/css/animation-play-state'); } }>animation-play-state</Link>
                                </li>
                                <li className={pathname == '/css/animation-timing-function' ? 'activeMenu' : ''}>
                                    <Link to="/css/animation-timing-function" onClick={() => { setPathName('/css/animation-timing-function'); } }>animation-timing-function</Link>
                                </li>
                                <li className={pathname == '/css/aspect-ratio' ? 'activeMenu' : ''}>
                                    <Link to="/css/aspect-ratio" onClick={() => { setPathName('/css/aspect-ratio'); } }>aspect-ratio</Link>
                                </li>
                                <li className={pathname == '/css/backdrop-filter' ? 'activeMenu' : ''}>
                                    <Link to="/css/backdrop-filter" onClick={() => { setPathName('/css/backdrop-filter'); } }>backdrop-filter</Link>
                                </li>
                                <li className={pathname == '/css/backface-visibility' ? 'activeMenu' : ''}>
                                    <Link to="/css/backface-visibility" onClick={() => { setPathName('/css/backface-visibility'); } }>backface-visibility</Link>
                                </li>
                                <li className={pathname == '/css/background' ? 'activeMenu' : ''}>
                                    <Link to="/css/background" onClick={() => { setPathName('/css/background'); } }>background</Link>
                                </li>
                                <li className={pathname == '/css/background-attachment' ? 'activeMenu' : ''}>
                                    <Link to="/css/background-attachment" onClick={() => { setPathName('/css/background-attachment'); } }>background-attachment</Link>
                                </li>
                                <li className={pathname == '/css/background-blend-mode' ? 'activeMenu' : ''}>
                                    <Link to="/css/background-blend-mode" onClick={() => { setPathName('/css/background-blend-mode'); } }>background-blend-mode</Link>
                                </li>
                                <li className={pathname == '/css/background-clip' ? 'activeMenu' : ''}>
                                    <Link to="/css/background-clip" onClick={() => { setPathName('/css/background-clip'); } }>background-clip</Link>
                                </li>
                                <li className={pathname == '/css/background-color' ? 'activeMenu' : ''}>
                                    <Link to="/css/background-color" onClick={() => { setPathName('/css/background-color'); } }>background-color</Link>
                                </li>
                                <li className={pathname == '/css/background-image' ? 'activeMenu' : ''}>
                                    <Link to="/css/background-image" onClick={() => { setPathName('/css/background-image'); } }>background-image</Link>
                                </li>
                                <li className={pathname == '/css/background-origin' ? 'activeMenu' : ''}>
                                    <Link to="/css/background-origin" onClick={() => { setPathName('/css/background-origin'); } }>background-origin</Link>
                                </li>
                                <li className={pathname == '/css/background-position' ? 'activeMenu' : ''}>
                                    <Link to="/css/background-position" onClick={() => { setPathName('/css/background-position'); } }>background-position</Link>
                                </li>
                                <li className={pathname == '/css/background-position-x' ? 'activeMenu' : ''}>
                                    <Link to="/css/background-position-x" onClick={() => { setPathName('/css/background-position-x'); } }>background-position-x</Link>
                                </li>
                                <li className={pathname == '/css/background-position-y' ? 'activeMenu' : ''}>
                                    <Link to="/css/background-position-y" onClick={() => { setPathName('/css/background-position-y'); } }>background-position-y</Link>
                                </li>
                                <li className={pathname == '/css/background-repeat' ? 'activeMenu' : ''}>
                                    <Link to="/css/background-repeat" onClick={() => { setPathName('/css/background-repeat'); } }>background-repeat</Link>
                                </li>
                                <li className={pathname == '/css/background-size' ? 'activeMenu' : ''}>
                                    <Link to="/css/background-size" onClick={() => { setPathName('/css/background-size'); } }>background-size</Link>
                                </li>
                                <li className={pathname == '/css/block-size' ? 'activeMenu' : ''}>
                                    <Link to="/css/block-size" onClick={() => { setPathName('/css/block-size'); } }>block-size</Link>
                                </li>
                                <li className={pathname == '/css/border' ? 'activeMenu' : ''}>
                                    <Link to="/css/border" onClick={() => { setPathName('/css/border'); } }>border</Link>
                                </li>
                                <li className={pathname == '/css/border-block' ? 'activeMenu' : ''}>
                                    <Link to="/css/border-block" onClick={() => { setPathName('/css/border-block'); } }>border-block</Link>
                                </li>
                                <li className={pathname == '/css/border-block-color' ? 'activeMenu' : ''}>
                                    <Link to="/css/border-block-color" onClick={() => { setPathName('/css/border-block-color'); } }>border-block-color</Link>
                                </li>
                                <li className={pathname == '/css/border-block-end' ? 'activeMenu' : ''}>
                                    <Link to="/css/border-block-end" onClick={() => { setPathName('/css/border-block-end'); } }>border-block-end</Link>
                                </li>
                                <li className={pathname == '/css/border-block-end-color' ? 'activeMenu' : ''}>
                                    <Link to="/css/border-block-end-color" onClick={() => { setPathName('/css/border-block-end-color'); } }>border-block-end-color</Link>
                                </li>
                                <li className={pathname == '/css/border-block-end-style' ? 'activeMenu' : ''}>
                                    <Link to="/css/border-block-end-style" onClick={() => { setPathName('/css/border-block-end-style'); } }>border-block-end-style</Link>
                                </li>
                                <li className={pathname == '/css/border-block-end-width' ? 'activeMenu' : ''}>
                                    <Link to="/css/border-block-end-width" onClick={() => { setPathName('/css/border-block-end-width'); } }>border-block-end-width</Link>
                                </li>
                                <li className={pathname == '/css/border-block-start' ? 'activeMenu' : ''}>
                                    <Link to="/css/border-block-start" onClick={() => { setPathName('/css/border-block-start'); } }>border-block-start</Link>
                                </li>
                                <li className={pathname == '/css/border-block-start-color' ? 'activeMenu' : ''}>
                                    <Link to="/css/border-block-start-color" onClick={() => { setPathName('/css/border-block-start-color'); } }>border-block-start-color</Link>
                                </li>
                                <li className={pathname == '/css/border-block-start-style' ? 'activeMenu' : ''}>
                                    <Link to="/css/border-block-start-style" onClick={() => { setPathName('/css/border-block-start-style'); } }>border-block-start-style</Link>
                                </li>
                                <li className={pathname == '/css/border-block-start-width' ? 'activeMenu' : ''}>
                                    <Link to="/css/border-block-start-width" onClick={() => { setPathName('/css/border-block-start-width'); } }>border-block-start-width</Link>
                                </li>
                                <li className={pathname == '/css/border-block-style' ? 'activeMenu' : ''}>
                                    <Link to="/css/border-block-style" onClick={() => { setPathName('/css/border-block-style'); } }>border-block-style</Link>
                                </li>
                                <li className={pathname == '/css/border-block-width' ? 'activeMenu' : ''}>
                                    <Link to="/css/border-block-width" onClick={() => { setPathName('/css/border-block-width'); } }>border-block-width</Link>
                                </li>
                                <li className={pathname == '/css/border-bottom' ? 'activeMenu' : ''}>
                                    <Link to="/css/border-bottom" onClick={() => { setPathName('/css/border-bottom'); } }>border-bottom</Link>
                                </li>
                                <li className={pathname == '/css/border-bottom-color' ? 'activeMenu' : ''}>
                                    <Link to="/css/border-bottom-color" onClick={() => { setPathName('/css/border-bottom-color'); } }>border-bottom-color</Link>
                                </li>
                                <li className={pathname == '/css/border-bottom-left-radius' ? 'activeMenu' : ''}>
                                    <Link to="/css/border-bottom-left-radius" onClick={() => { setPathName('/css/border-bottom-left-radius'); } }>border-bottom-left-radius</Link>
                                </li>
                                <li className={pathname == '/css/border-bottom-right-radius' ? 'activeMenu' : ''}>
                                    <Link to="/css/border-bottom-right-radius" onClick={() => { setPathName('/css/border-bottom-right-radius'); } }>border-bottom-right-radius</Link>
                                </li>
                                <li className={pathname == '/css/border-bottom-style' ? 'activeMenu' : ''}>
                                    <Link to="/css/border-bottom-style" onClick={() => { setPathName('/css/border-bottom-style'); } }>border-bottom-style</Link>
                                </li>
                                <li className={pathname == '/css/border-bottom-width' ? 'activeMenu' : ''}>
                                    <Link to="/css/border-bottom-width" onClick={() => { setPathName('/css/border-bottom-width'); } }>border-bottom-width</Link>
                                </li>
                                <li className={pathname == '/css/border-collapse' ? 'activeMenu' : ''}>
                                    <Link to="/css/border-collapse" onClick={() => { setPathName('/css/border-collapse'); } }>border-collapse</Link>
                                </li>
                                <li className={pathname == '/css/border-color' ? 'activeMenu' : ''}>
                                    <Link to="/css/border-color" onClick={() => { setPathName('/css/border-color'); } }>border-color</Link>
                                </li>
                                <li className={pathname == '/css/border-end-end-radius' ? 'activeMenu' : ''}>
                                    <Link to="/css/border-end-end-radius" onClick={() => { setPathName('/css/border-end-end-radius'); } }>border-end-end-radius</Link>
                                </li>
                                <li className={pathname == '/css/border-end-start-radius' ? 'activeMenu' : ''}>
                                    <Link to="/css/border-end-start-radius" onClick={() => { setPathName('/css/border-end-start-radius'); } }>border-end-start-radius</Link>
                                </li>
                                <li className={pathname == '/css/border-image' ? 'activeMenu' : ''}>
                                    <Link to="/css/border-image" onClick={() => { setPathName('/css/border-image'); } }>border-image</Link>
                                </li>
                                <li className={pathname == '/css/border-image-outset' ? 'activeMenu' : ''}>
                                    <Link to="/css/border-image-outset" onClick={() => { setPathName('/css/border-image-outset'); } }>border-image-outset</Link>
                                </li>
                                <li className={pathname == '/css/border-image-repeat' ? 'activeMenu' : ''}>
                                    <Link to="/css/border-image-repeat" onClick={() => { setPathName('/css/border-image-repeat'); } }>border-image-repeat</Link>
                                </li>
                                <li className={pathname == '/css/border-image-slice' ? 'activeMenu' : ''}>
                                    <Link to="/css/border-image-slice" onClick={() => { setPathName('/css/border-image-slice'); } }>border-image-slice</Link>
                                </li>
                                <li className={pathname == '/css/border-image-source' ? 'activeMenu' : ''}>
                                    <Link to="/css/border-image-source" onClick={() => { setPathName('/css/border-image-source'); } }>border-image-source</Link>
                                </li>
                                <li className={pathname == '/css/border-image-width' ? 'activeMenu' : ''}>
                                    <Link to="/css/border-image-width" onClick={() => { setPathName('/css/border-image-width'); } }>border-image-width</Link>
                                </li>
                                <li className={pathname == '/css/border-inline' ? 'activeMenu' : ''}>
                                    <Link to="/css/border-inline" onClick={() => { setPathName('/css/border-inline'); } }>border-inline</Link>
                                </li>
                                <li className={pathname == '/css/border-inline-color' ? 'activeMenu' : ''}>
                                    <Link to="/css/border-inline-color" onClick={() => { setPathName('/css/border-inline-color'); } }>border-inline-color</Link>
                                </li>
                                <li className={pathname == '/css/border-inline-end' ? 'activeMenu' : ''}>
                                    <Link to="/css/border-inline-end" onClick={() => { setPathName('/css/border-inline-end'); } }>border-inline-end</Link>
                                </li>
                                <li className={pathname == '/css/border-inline-end-color' ? 'activeMenu' : ''}>
                                    <Link to="/css/border-inline-end-color" onClick={() => { setPathName('/css/border-inline-end-color'); } }>border-inline-end-color</Link>
                                </li>
                                <li className={pathname == '/css/border-inline-end-style' ? 'activeMenu' : ''}>
                                    <Link to="/css/border-inline-end-style" onClick={() => { setPathName('/css/border-inline-end-style'); } }>border-inline-end-style</Link>
                                </li>
                                <li className={pathname == '/css/border-inline-end-width' ? 'activeMenu' : ''}>
                                    <Link to="/css/border-inline-end-width" onClick={() => { setPathName('/css/border-inline-end-width'); } }>border-inline-end-width</Link>
                                </li>
                                <li className={pathname == '/css/border-inline-start' ? 'activeMenu' : ''}>
                                    <Link to="/css/border-inline-start" onClick={() => { setPathName('/css/border-inline-start'); } }>border</Link>
                                </li>
                                <li className={pathname == '/css/border-inline-start-color' ? 'activeMenu' : ''}>
                                    <Link to="/css/border-inline-start-color" onClick={() => { setPathName('/css/border-inline-start-color'); } }>border-inline-start-color</Link>
                                </li>
                                <li className={pathname == '/css/border-inline-start-style' ? 'activeMenu' : ''}>
                                    <Link to="/css/border-inline-start-style" onClick={() => { setPathName('/css/border-inline-start-style'); } }>border-inline-start-style</Link>
                                </li>
                                <li className={pathname == '/css/border-inline-start-width' ? 'activeMenu' : ''}>
                                    <Link to="/css/border-inline-start-width" onClick={() => { setPathName('/css/border-inline-start-width'); } }>border-inline-start-width</Link>
                                </li>
                                <li className={pathname == '/css/border-inline-style' ? 'activeMenu' : ''}>
                                    <Link to="/css/border-inline-style" onClick={() => { setPathName('/css/border-inline-style'); } }>border-inline-style</Link>
                                </li>
                                <li className={pathname == '/css/border-inline-width' ? 'activeMenu' : ''}>
                                    <Link to="/css/border-inline-width" onClick={() => { setPathName('/css/border-inline-width'); } }>border-inline-width</Link>
                                </li>
                                <li className={pathname == '/css/border-left' ? 'activeMenu' : ''}>
                                    <Link to="/css/border-left" onClick={() => { setPathName('/css/border-left'); } }>border-left</Link>
                                </li>
                                <li className={pathname == '/css/border-left-color' ? 'activeMenu' : ''}>
                                    <Link to="/css/border-left-color" onClick={() => { setPathName('/css/border-left-color'); } }>border-left-color</Link>
                                </li>
                                <li className={pathname == '/css/border-left-style' ? 'activeMenu' : ''}>
                                    <Link to="/css/border-left-style" onClick={() => { setPathName('/css/border-left-style'); } }>border-left-style</Link>
                                </li>
                                <li className={pathname == '/css/border-left-width' ? 'activeMenu' : ''}>
                                    <Link to="/css/border-left-width" onClick={() => { setPathName('/css/border-left-width'); } }>border-left-width</Link>
                                </li>
                                <li className={pathname == '/css/border-radius' ? 'activeMenu' : ''}>
                                    <Link to="/css/border-radius" onClick={() => { setPathName('/css/border-radius'); } }>border-radius</Link>
                                </li>
                                <li className={pathname == '/css/border-right' ? 'activeMenu' : ''}>
                                    <Link to="/css/border-right" onClick={() => { setPathName('/css/border-right'); } }>border-right</Link>
                                </li>
                                <li className={pathname == '/css/border-right-color' ? 'activeMenu' : ''}>
                                    <Link to="/css/border-right-color" onClick={() => { setPathName('/css/border-right-color'); } }>border-right-color</Link>
                                </li>
                                <li className={pathname == '/css/border-right-style' ? 'activeMenu' : ''}>
                                    <Link to="/css/border-right-style" onClick={() => { setPathName('/css/border-right-style'); } }>border-right-style</Link>
                                </li>
                                <li className={pathname == '/css/border-right-width' ? 'activeMenu' : ''}>
                                    <Link to="/css/border-right-width" onClick={() => { setPathName('/css/border-right-width'); } }>border-right-width</Link>
                                </li>
                                <li className={pathname == '/css/border-spacing' ? 'activeMenu' : ''}>
                                    <Link to="/css/border-spacing" onClick={() => { setPathName('/css/border-spacing'); } }>border-spacing</Link>
                                </li>
                                <li className={pathname == '/css/border-start-end-radius' ? 'activeMenu' : ''}>
                                    <Link to="/css/border-start-end-radius" onClick={() => { setPathName('/css/border-start-end-radius'); } }>border-start-end-radius</Link>
                                </li>
                                <li className={pathname == '/css/border-start-start-radius' ? 'activeMenu' : ''}>
                                    <Link to="/css/border-start-start-radius" onClick={() => { setPathName('/css/border-start-start-radius'); } }>border-start-start-radius</Link>
                                </li>
                                <li className={pathname == '/css/border-style' ? 'activeMenu' : ''}>
                                    <Link to="/css/border-style" onClick={() => { setPathName('/css/border-style'); } }>border-style</Link>
                                </li>
                                <li className={pathname == '/css/border-top' ? 'activeMenu' : ''}>
                                    <Link to="/css/border-top" onClick={() => { setPathName('/css/border-top'); } }>border-top</Link>
                                </li>
                                <li className={pathname == '/css/border-top-color' ? 'activeMenu' : ''}>
                                    <Link to="/css/border-top-color" onClick={() => { setPathName('/css/border-top-color'); } }>border-top-color</Link>
                                </li>
                                <li className={pathname == '/css/border-top-left-radius' ? 'activeMenu' : ''}>
                                    <Link to="/css/border-top-left-radius" onClick={() => { setPathName('/css/border-top-left-radius'); } }>border-top-left-radius</Link>
                                </li>
                                <li className={pathname == '/css/border-top-right-radius' ? 'activeMenu' : ''}>
                                    <Link to="/css/border-top-right-radius" onClick={() => { setPathName('/css/border-top-right-radius'); } }>border-top-right-radius</Link>
                                </li>
                                <li className={pathname == '/css/border-top-style' ? 'activeMenu' : ''}>
                                    <Link to="/css/border-top-style" onClick={() => { setPathName('/css/border-top-style'); } }>border-top-style</Link>
                                </li>
                                <li className={pathname == '/css/border-top-width' ? 'activeMenu' : ''}>
                                    <Link to="/css/border-top-width" onClick={() => { setPathName('/css/border-top-width'); } }>border-top-width</Link>
                                </li>
                                <li className={pathname == '/css/border-width' ? 'activeMenu' : ''}>
                                    <Link to="/css/border-width" onClick={() => { setPathName('/css/border-width'); } }>border-width</Link>
                                </li>
                                <li className={pathname == '/css/bottom' ? 'activeMenu' : ''}>
                                    <Link to="/css/bottom" onClick={() => { setPathName('/css/bottom'); } }>bottom</Link>
                                </li>
                                <li className={pathname == '/css/box-decoration-break' ? 'activeMenu' : ''}>
                                    <Link to="/css/box-decoration-break" onClick={() => { setPathName('/css/box-decoration-break'); } }>box-decoration-break</Link>
                                </li>
                                <li className={pathname == '/css/box-reflect' ? 'activeMenu' : ''}>
                                    <Link to="/css/box-reflect" onClick={() => { setPathName('/css/box-reflect'); } }>box-reflect</Link>
                                </li>
                                <li className={pathname == '/css/box-shadow' ? 'activeMenu' : ''}>
                                    <Link to="/css/box-shadow" onClick={() => { setPathName('/css/box-shadow'); } }>box-shadow</Link>
                                </li>
                                <li className={pathname == '/css/box-sizing' ? 'activeMenu' : ''}>
                                    <Link to="/css/box-sizing" onClick={() => { setPathName('/css/box-sizing'); } }>box-sizing</Link>
                                </li>
                                <li className={pathname == '/css/break-after' ? 'activeMenu' : ''}>
                                    <Link to="/css/break-after" onClick={() => { setPathName('/css/break-after'); } }>break-after</Link>
                                </li>
                                <li className={pathname == '/css/break-before' ? 'activeMenu' : ''}>
                                    <Link to="/css/break-before" onClick={() => { setPathName('/css/break-before'); } }>break-before</Link>
                                </li>
                                <li className={pathname == '/css/break-inside' ? 'activeMenu' : ''}>
                                    <Link to="/css/break-inside" onClick={() => { setPathName('/css/break-inside'); } }>break-inside</Link>
                                </li>
                                <li className={pathname == '/css/caption-side' ? 'activeMenu' : ''}>
                                    <Link to="/css/caption-side" onClick={() => { setPathName('/css/caption-side'); } }>caption-side</Link>
                                </li>
                                <li className={pathname == '/css/caret-color' ? 'activeMenu' : ''}>
                                    <Link to="/css/caret-color" onClick={() => { setPathName('/css/caret-color'); } }>caret-color</Link>
                                </li>
                                <li className={pathname == '/css/@charset' ? 'activeMenu' : ''}>
                                    <Link to="/css/@charset" onClick={() => { setPathName('/css/@charset'); } }>@charset</Link>
                                </li>
                                <li className={pathname == '/css/clear' ? 'activeMenu' : ''}>
                                    <Link to="/css/clear" onClick={() => { setPathName('/css/clear'); } }>clear</Link>
                                </li>
                                <li className={pathname == '/css/clip' ? 'activeMenu' : ''}>
                                    <Link to="/css/clip" onClick={() => { setPathName('/css/clip'); } }>clip</Link>
                                </li>
                                <li className={pathname == '/css/clip-path' ? 'activeMenu' : ''}>
                                    <Link to="/css/clip-path" onClick={() => { setPathName('/css/clip-path'); } }>clip-path</Link>
                                </li>
                                <li className={pathname == '/css/color' ? 'activeMenu' : ''}>
                                    <Link to="/css/color" onClick={() => { setPathName('/css/color'); } }>color</Link>
                                </li>
                                <li className={pathname == '/css/color-scheme' ? 'activeMenu' : ''}>
                                    <Link to="/css/color-scheme" onClick={() => { setPathName('/css/color-scheme'); } }>color-scheme</Link>
                                </li>
                                <li className={pathname == '/css/column-count' ? 'activeMenu' : ''}>
                                    <Link to="/css/column-count" onClick={() => { setPathName('/css/column-count'); } }>column-count</Link>
                                </li>
                                <li className={pathname == '/css/column-fill' ? 'activeMenu' : ''}>
                                    <Link to="/css/column-fill" onClick={() => { setPathName('/css/column-fill'); } }>column-fill</Link>
                                </li>
                                <li className={pathname == '/css/column-gap' ? 'activeMenu' : ''}>
                                    <Link to="/css/column-gap" onClick={() => { setPathName('/css/column-gap'); } }>column-gap</Link>
                                </li>
                                <li className={pathname == '/css/column-rule' ? 'activeMenu' : ''}>
                                    <Link to="/css/column-rule" onClick={() => { setPathName('/css/column-rule'); } }>column-rule</Link>
                                </li>
                                <li className={pathname == '/css/column-rule-color' ? 'activeMenu' : ''}>
                                    <Link to="/css/column-rule-color" onClick={() => { setPathName('/css/column-rule-color'); } }>column-rule-color</Link>
                                </li>
                                <li className={pathname == '/css/column-rule-style' ? 'activeMenu' : ''}>
                                    <Link to="/css/column-rule-style" onClick={() => { setPathName('/css/column-rule-style'); } }>column-rule-style</Link>
                                </li>
                                <li className={pathname == '/css/column-rule-width' ? 'activeMenu' : ''}>
                                    <Link to="/css/column-rule-width" onClick={() => { setPathName('/css/column-rule-width'); } }>column-rule-width</Link>
                                </li>
                                <li className={pathname == '/css/column-span' ? 'activeMenu' : ''}>
                                    <Link to="/css/column-span" onClick={() => { setPathName('/css/column-span'); } }>column-span</Link>
                                </li>
                                <li className={pathname == '/css/column-width' ? 'activeMenu' : ''}>
                                    <Link to="/css/column-width" onClick={() => { setPathName('/css/column-width'); } }>column-width</Link>
                                </li>
                                <li className={pathname == '/css/columns' ? 'activeMenu' : ''}>
                                    <Link to="/css/columns" onClick={() => { setPathName('/css/columns'); } }>columns</Link>
                                </li>
                                <li className={pathname == '/css/content' ? 'activeMenu' : ''}>
                                    <Link to="/css/content" onClick={() => { setPathName('/css/content'); } }>content</Link>
                                </li>
                                <li className={pathname == '/css/counter-increment' ? 'activeMenu' : ''}>
                                    <Link to="/css/counter-increment" onClick={() => { setPathName('/css/counter-increment'); } }>counter-increment</Link>
                                </li>
                                <li className={pathname == '/css/counter-reset' ? 'activeMenu' : ''}>
                                    <Link to="/css/counter-reset" onClick={() => { setPathName('/css/counter-reset'); } }>counter-reset</Link>
                                </li>
                                <li className={pathname == '/css/counter-set' ? 'activeMenu' : ''}>
                                    <Link to="/css/counter-set" onClick={() => { setPathName('/css/counter-set'); } }>counter-set</Link>
                                </li>
                                <li className={pathname == '/css/cursor' ? 'activeMenu' : ''}>
                                    <Link to="/css/cursor" onClick={() => { setPathName('/css/cursor'); } }>cursor</Link>
                                </li>
                                <li className={pathname == '/css/direction' ? 'activeMenu' : ''}>
                                    <Link to="/css/direction" onClick={() => { setPathName('/css/direction'); } }>direction</Link>
                                </li>
                                <li className={pathname == '/css/display' ? 'activeMenu' : ''}>
                                    <Link to="/css/display" onClick={() => { setPathName('/css/display'); } }>display</Link>
                                </li>
                                <li className={pathname == '/css/empty-cells' ? 'activeMenu' : ''}>
                                    <Link to="/css/empty-cells" onClick={() => { setPathName('/css/empty-cells'); } }>empty-cells</Link>
                                </li>
                                <li className={pathname == '/css/filter' ? 'activeMenu' : ''}>
                                    <Link to="/css/filter" onClick={() => { setPathName('/css/filter'); } }>filter</Link>
                                </li>
                                <li className={pathname == '/css/flex' ? 'activeMenu' : ''}>
                                    <Link to="/css/flex" onClick={() => { setPathName('/css/flex'); } }>flex</Link>
                                </li>
                                <li className={pathname == '/css/flex-basis' ? 'activeMenu' : ''}>
                                    <Link to="/css/flex-basis" onClick={() => { setPathName('/css/flex-basis'); } }>flex-basis</Link>
                                </li>
                                <li className={pathname == '/css/flex-direction' ? 'activeMenu' : ''}>
                                    <Link to="/css/flex-direction" onClick={() => { setPathName('/css/flex-direction'); } }>flex-direction</Link>
                                </li>
                                <li className={pathname == '/css/flex-flow' ? 'activeMenu' : ''}>
                                    <Link to="/css/flex-flow" onClick={() => { setPathName('/css/flex-flow'); } }>flex-flow</Link>
                                </li>
                                <li className={pathname == '/css/flex-grow' ? 'activeMenu' : ''}>
                                    <Link to="/css/flex-grow" onClick={() => { setPathName('/css/flex-grow'); } }>flex-grow</Link>
                                </li>
                                <li className={pathname == '/css/flex-shrink' ? 'activeMenu' : ''}>
                                    <Link to="/css/flex-shrink" onClick={() => { setPathName('/css/flex-shrink'); } }>flex-shrink</Link>
                                </li>
                                <li className={pathname == '/css/flex-wrap' ? 'activeMenu' : ''}>
                                    <Link to="/css/flex-wrap" onClick={() => { setPathName('/css/flex-wrap'); } }>flex-wrap</Link>
                                </li>
                                <li className={pathname == '/css/float' ? 'activeMenu' : ''}>
                                    <Link to="/css/float" onClick={() => { setPathName('/css/float'); } }>float</Link>
                                </li>
                                <li className={pathname == '/css/font' ? 'activeMenu' : ''}>
                                    <Link to="/css/font" onClick={() => { setPathName('/css/font'); } }>font</Link>
                                </li>
                                <li className={pathname == '/css/@font-face' ? 'activeMenu' : ''}>
                                    <Link to="/css/@font-face" onClick={() => { setPathName('/css/@font-face'); } }>@font-face</Link>
                                </li>
                                <li className={pathname == '/css/font-family' ? 'activeMenu' : ''}>
                                    <Link to="/css/font-family" onClick={() => { setPathName('/css/font-family'); } }>font-family</Link>
                                </li>
                                <li className={pathname == '/css/font-feature-settings' ? 'activeMenu' : ''}>
                                    <Link to="/css/font-feature-settings" onClick={() => { setPathName('/css/font-feature-settings'); } }>font-feature-settings</Link>
                                </li>
                                <li className={pathname == '/css/font-kerning' ? 'activeMenu' : ''}>
                                    <Link to="/css/font-kerning" onClick={() => { setPathName('/css/font-kerning'); } }>font-kerning</Link>
                                </li>
                                <li className={pathname == '/css/font-size' ? 'activeMenu' : ''}>
                                    <Link to="/css/font-size" onClick={() => { setPathName('/css/font-size'); } }>font-size</Link>
                                </li>
                                <li className={pathname == '/css/font-size-adjust' ? 'activeMenu' : ''}>
                                    <Link to="/css/font-size-adjust" onClick={() => { setPathName('/css/font-size-adjust'); } }>font-size-adjust</Link>
                                </li>
                                <li className={pathname == '/css/font-stretch' ? 'activeMenu' : ''}>
                                    <Link to="/css/font-stretch" onClick={() => { setPathName('/css/font-stretch'); } }>font-stretch</Link>
                                </li>
                                <li className={pathname == '/css/font-style' ? 'activeMenu' : ''}>
                                    <Link to="/css/font-style" onClick={() => { setPathName('/css/font-style'); } }>font-style</Link>
                                </li>
                                <li className={pathname == '/css/font-variant' ? 'activeMenu' : ''}>
                                    <Link to="/css/font-variant" onClick={() => { setPathName('/css/font-variant'); } }>font-variant</Link>
                                </li>
                                <li className={pathname == '/css/font-variant-caps' ? 'activeMenu' : ''}>
                                    <Link to="/css/font-variant-caps" onClick={() => { setPathName('/css/font-variant-caps'); } }>font-variant-caps</Link>
                                </li>
                                <li className={pathname == '/css/font-weight' ? 'activeMenu' : ''}>
                                    <Link to="/css/font-weight" onClick={() => { setPathName('/css/font-weight'); } }>font-weight</Link>
                                </li>
                                <li className={pathname == '/css/gap' ? 'activeMenu' : ''}>
                                    <Link to="/css/gap" onClick={() => { setPathName('/css/gap'); } }>gap</Link>
                                </li>
                                <li className={pathname == '/css/grid' ? 'activeMenu' : ''}>
                                    <Link to="/css/grid" onClick={() => { setPathName('/css/grid'); } }>grid</Link>
                                </li>
                                <li className={pathname == '/css/grid-area' ? 'activeMenu' : ''}>
                                    <Link to="/css/grid-area" onClick={() => { setPathName('/css/grid-area'); } }>grid-area</Link>
                                </li>
                                <li className={pathname == '/css/grid-auto-columns' ? 'activeMenu' : ''}>
                                    <Link to="/css/grid-auto-columns" onClick={() => { setPathName('/css/grid-auto-columns'); } }>grid-auto-columns</Link>
                                </li>
                                <li className={pathname == '/css/grid-auto-flow' ? 'activeMenu' : ''}>
                                    <Link to="/css/grid-auto-flow" onClick={() => { setPathName('/css/grid-auto-flow'); } }>grid-auto-flow</Link>
                                </li>
                                <li className={pathname == '/css/grid-auto-rows' ? 'activeMenu' : ''}>
                                    <Link to="/css/grid-auto-rows" onClick={() => { setPathName('/css/grid-auto-rows'); } }>grid-auto-rows</Link>
                                </li>
                                <li className={pathname == '/css/grid-column' ? 'activeMenu' : ''}>
                                    <Link to="/css/grid-column" onClick={() => { setPathName('/css/grid-column'); } }>grid-column</Link>
                                </li>
                                <li className={pathname == '/css/grid-column-end' ? 'activeMenu' : ''}>
                                    <Link to="/css/grid-column-end" onClick={() => { setPathName('/css/grid-column-end'); } }>grid-column-end</Link>
                                </li>
                                <li className={pathname == '/css/grid-column-gap' ? 'activeMenu' : ''}>
                                    <Link to="/css/grid-column-gap" onClick={() => { setPathName('/css/grid-column-gap'); } }>grid-column-gap</Link>
                                </li>
                                <li className={pathname == '/css/grid-column-start' ? 'activeMenu' : ''}>
                                    <Link to="/css/grid-column-start" onClick={() => { setPathName('/css/grid-column-start'); } }>grid-column-start</Link>
                                </li>
                                <li className={pathname == '/css/grid-gap' ? 'activeMenu' : ''}>
                                    <Link to="/css/grid-gap" onClick={() => { setPathName('/css/grid-gap'); } }>grid-gap</Link>
                                </li>
                                <li className={pathname == '/css/grid-row' ? 'activeMenu' : ''}>
                                    <Link to="/css/grid-row" onClick={() => { setPathName('/css/grid-row'); } }>grid-row</Link>
                                </li>
                                <li className={pathname == '/css/grid-row-end' ? 'activeMenu' : ''}>
                                    <Link to="/css/grid-row-end" onClick={() => { setPathName('/css/grid-row-end'); } }>grid-row-end</Link>
                                </li>
                                <li className={pathname == '/css/grid-row-gap' ? 'activeMenu' : ''}>
                                    <Link to="/css/grid-row-gap" onClick={() => { setPathName('/css/grid-row-gap'); } }>grid-row-gap</Link>
                                </li>
                                <li className={pathname == '/css/grid-row-start' ? 'activeMenu' : ''}>
                                    <Link to="/css/grid-row-start" onClick={() => { setPathName('/css/grid-row-start'); } }>grid-row-start</Link>
                                </li>
                                <li className={pathname == '/css/grid-template' ? 'activeMenu' : ''}>
                                    <Link to="/css/grid-template" onClick={() => { setPathName('/css/grid-template'); } }>grid-template</Link>
                                </li>
                                <li className={pathname == '/css/grid-template-areas' ? 'activeMenu' : ''}>
                                    <Link to="/css/grid-template-areas" onClick={() => { setPathName('/css/grid-template-areas'); } }>border</Link>
                                </li>
                                <li className={pathname == '/css/grid-template-columns' ? 'activeMenu' : ''}>
                                    <Link to="/css/grid-template-columns" onClick={() => { setPathName('/css/grid-template-columns'); } }>grid-template-columns</Link>
                                </li>
                                <li className={pathname == '/css/grid-template-rows' ? 'activeMenu' : ''}>
                                    <Link to="/css/grid-template-rows" onClick={() => { setPathName('/css/grid-template-rows'); } }>grid-template-rows</Link>
                                </li>
                                <li className={pathname == '/css/hanging-punctuation' ? 'activeMenu' : ''}>
                                    <Link to="/css/hanging-punctuation" onClick={() => { setPathName('/css/hanging-punctuation'); } }>hanging-punctuation</Link>
                                </li>
                                <li className={pathname == '/css/height' ? 'activeMenu' : ''}>
                                    <Link to="/css/height" onClick={() => { setPathName('/css/height'); } }>height</Link>
                                </li>
                                <li className={pathname == '/css/hyphens' ? 'activeMenu' : ''}>
                                    <Link to="/css/hyphens" onClick={() => { setPathName('/css/hyphens'); } }>hyphens</Link>
                                </li>
                                <li className={pathname == '/css/hyphenate-character' ? 'activeMenu' : ''}>
                                    <Link to="/css/hyphenate-character" onClick={() => { setPathName('/css/hyphenate-character'); } }>hyphenate-character</Link>
                                </li>
                                <li className={pathname == '/css/image-rendering' ? 'activeMenu' : ''}>
                                    <Link to="/css/image-rendering" onClick={() => { setPathName('/css/image-rendering'); } }>image-rendering</Link>
                                </li>
                                <li className={pathname == '/css/@import' ? 'activeMenu' : ''}>
                                    <Link to="/css/@import" onClick={() => { setPathName('/css/@import'); } }>@import</Link>
                                </li>
                                <li className={pathname == '/css/inline-size' ? 'activeMenu' : ''}>
                                    <Link to="/css/inline-size" onClick={() => { setPathName('/css/inline-size'); } }>inline-size</Link>
                                </li>
                                <li className={pathname == '/css/inset' ? 'activeMenu' : ''}>
                                    <Link to="/css/inset" onClick={() => { setPathName('/css/inset'); } }>inset</Link>
                                </li>
                                <li className={pathname == '/css/inset-block' ? 'activeMenu' : ''}>
                                    <Link to="/css/inset-block" onClick={() => { setPathName('/css/inset-block'); } }>inset-block</Link>
                                </li>
                                <li className={pathname == '/css/inset-block-end' ? 'activeMenu' : ''}>
                                    <Link to="/css/inset-block-end" onClick={() => { setPathName('/css/inset-block-end'); } }>inset-block-end</Link>
                                </li>
                                <li className={pathname == '/css/inset-block-start' ? 'activeMenu' : ''}>
                                    <Link to="/css/inset-block-start" onClick={() => { setPathName('/css/inset-block-start'); } }>inset-block-start</Link>
                                </li>
                                <li className={pathname == '/css/inset-inline' ? 'activeMenu' : ''}>
                                    <Link to="/css/inset-inline" onClick={() => { setPathName('/css/inset-inline'); } }>inset-inline</Link>
                                </li>
                                <li className={pathname == '/css/inset-inline-end' ? 'activeMenu' : ''}>
                                    <Link to="/css/inset-inline-end" onClick={() => { setPathName('/css/inset-inline-end'); } }>inset-inline-end</Link>
                                </li>
                                <li className={pathname == '/css/inset-inline-start' ? 'activeMenu' : ''}>
                                    <Link to="/css/inset-inline-start" onClick={() => { setPathName('/css/inset-inline-start'); } }>inset-inline-start</Link>
                                </li>
                                <li className={pathname == '/css/isolation' ? 'activeMenu' : ''}>
                                    <Link to="/css/isolation" onClick={() => { setPathName('/css/isolation'); } }>isolation</Link>
                                </li>
                                <li className={pathname == '/css/justify-content' ? 'activeMenu' : ''}>
                                    <Link to="/css/justify-content" onClick={() => { setPathName('/css/justify-content'); } }>justify-content</Link>
                                </li>
                                <li className={pathname == '/css/justify-items' ? 'activeMenu' : ''}>
                                    <Link to="/css/justify-items" onClick={() => { setPathName('/css/justify-items'); } }>justify-items</Link>
                                </li>
                                <li className={pathname == '/css/justify-self' ? 'activeMenu' : ''}>
                                    <Link to="/css/justify-self" onClick={() => { setPathName('/css/justify-self'); } }>justify-self</Link>
                                </li>
                                <li className={pathname == '/css/@keyframes' ? 'activeMenu' : ''}>
                                    <Link to="/css/@keyframes" onClick={() => { setPathName('/css/@keyframes'); } }>@keyframes</Link>
                                </li>
                                <li className={pathname == '/css/left' ? 'activeMenu' : ''}>
                                    <Link to="/css/left" onClick={() => { setPathName('/css/left'); } }>left</Link>
                                </li>
                                <li className={pathname == '/css/letter-spacing' ? 'activeMenu' : ''}>
                                    <Link to="/css/letter-spacing" onClick={() => { setPathName('/css/letter-spacing'); } }>letter-spacing</Link>
                                </li>
                                <li className={pathname == '/css/line-height' ? 'activeMenu' : ''}>
                                    <Link to="/css/line-height" onClick={() => { setPathName('/css/line-height'); } }>line-height</Link>
                                </li>
                                <li className={pathname == '/css/list-style' ? 'activeMenu' : ''}>
                                    <Link to="/css/list-style" onClick={() => { setPathName('/css/list-style'); } }>list-style</Link>
                                </li>
                                <li className={pathname == '/css/list-style-image' ? 'activeMenu' : ''}>
                                    <Link to="/css/list-style-image" onClick={() => { setPathName('/css/list-style-image'); } }>list-style-image</Link>
                                </li>
                                <li className={pathname == '/css/list-style-position' ? 'activeMenu' : ''}>
                                    <Link to="/css/list-style-position" onClick={() => { setPathName('/css/list-style-position'); } }>list-style-position</Link>
                                </li>
                                <li className={pathname == '/css/list-style-type' ? 'activeMenu' : ''}>
                                    <Link to="/css/list-style-type" onClick={() => { setPathName('/css/list-style-type'); } }>list-style-type</Link>
                                </li>
                                <li className={pathname == '/css/margin' ? 'activeMenu' : ''}>
                                    <Link to="/css/margin" onClick={() => { setPathName('/css/margin'); } }>margin</Link>
                                </li>
                                <li className={pathname == '/css/margin-block' ? 'activeMenu' : ''}>
                                    <Link to="/css/margin-block" onClick={() => { setPathName('/css/margin-block'); } }>margin-block</Link>
                                </li>
                                <li className={pathname == '/css/margin-block-end' ? 'activeMenu' : ''}>
                                    <Link to="/css/margin-block-end" onClick={() => { setPathName('/css/margin-block-end'); } }>margin-block-end</Link>
                                </li>
                                <li className={pathname == '/css/margin-block-start' ? 'activeMenu' : ''}>
                                    <Link to="/css/margin-block-start" onClick={() => { setPathName('/css/margin-block-start'); } }>margin-block-start</Link>
                                </li>
                                <li className={pathname == '/css/margin-bottom' ? 'activeMenu' : ''}>
                                    <Link to="/css/margin-bottom" onClick={() => { setPathName('/css/margin-bottom'); } }>margin-bottom</Link>
                                </li>
                                <li className={pathname == '/css/margin-inline' ? 'activeMenu' : ''}>
                                    <Link to="/css/margin-inline" onClick={() => { setPathName('/css/margin-inline'); } }>margin-inline</Link>
                                </li>
                                <li className={pathname == '/css/margin-inline-end' ? 'activeMenu' : ''}>
                                    <Link to="/css/margin-inline-end" onClick={() => { setPathName('/css/margin-inline-end'); } }>margin-inline-end</Link>
                                </li>
                                <li className={pathname == '/css/margin-inline-start' ? 'activeMenu' : ''}>
                                    <Link to="/css/margin-inline-start" onClick={() => { setPathName('/css/margin-inline-start'); } }>margin-inline-start</Link>
                                </li>
                                <li className={pathname == '/css/margin-left' ? 'activeMenu' : ''}>
                                    <Link to="/css/margin-left" onClick={() => { setPathName('/css/margin-left'); } }>margin-left</Link>
                                </li>
                                <li className={pathname == '/css/margin-right' ? 'activeMenu' : ''}>
                                    <Link to="/css/margin-right" onClick={() => { setPathName('/css/margin-right'); } }>margin-right</Link>
                                </li>
                                <li className={pathname == '/css/margin-top' ? 'activeMenu' : ''}>
                                    <Link to="/css/margin-top" onClick={() => { setPathName('/css/margin-top'); } }>margin-top</Link>
                                </li>
                                <li className={pathname == '/css/mask-image' ? 'activeMenu' : ''}>
                                    <Link to="/css/mask-image" onClick={() => { setPathName('/css/mask-image'); } }>mask-image</Link>
                                </li>
                                <li className={pathname == '/css/mask-mode' ? 'activeMenu' : ''}>
                                    <Link to="/css/mask-mode" onClick={() => { setPathName('/css/mask-mode'); } }>mask-mode</Link>
                                </li>
                                <li className={pathname == '/css/mask-origin' ? 'activeMenu' : ''}>
                                    <Link to="/css/mask-origin" onClick={() => { setPathName('/css/mask-origin'); } }>mask-origin</Link>
                                </li>
                                <li className={pathname == '/css/mask-position' ? 'activeMenu' : ''}>
                                    <Link to="/css/mask-position" onClick={() => { setPathName('/css/mask-position'); } }>mask-position</Link>
                                </li>
                                <li className={pathname == '/css/mask-repeat' ? 'activeMenu' : ''}>
                                    <Link to="/css/mask-repeat" onClick={() => { setPathName('/css/mask-repeat'); } }>mask-repeat</Link>
                                </li>
                                <li className={pathname == '/css/mask-size' ? 'activeMenu' : ''}>
                                    <Link to="/css/mask-size" onClick={() => { setPathName('/css/mask-size'); } }>mask-size</Link>
                                </li>
                                <li className={pathname == '/css/max-block-size' ? 'activeMenu' : ''}>
                                    <Link to="/css/max-block-size" onClick={() => { setPathName('/css/max-block-size'); } }>max-block-size</Link>
                                </li>
                                <li className={pathname == '/css/max-height' ? 'activeMenu' : ''}>
                                    <Link to="/css/max-height" onClick={() => { setPathName('/css/max-height'); } }>max-height</Link>
                                </li>
                                <li className={pathname == '/css/max-inline-size' ? 'activeMenu' : ''}>
                                    <Link to="/css/max-inline-size" onClick={() => { setPathName('/css/max-inline-size'); } }>max-inline-size</Link>
                                </li>
                                <li className={pathname == '/css/max-width' ? 'activeMenu' : ''}>
                                    <Link to="/css/max-width" onClick={() => { setPathName('/css/max-width'); } }>max-width</Link>
                                </li>
                                <li className={pathname == '/css/@media' ? 'activeMenu' : ''}>
                                    <Link to="/css/@media" onClick={() => { setPathName('/css/@media'); } }>@media</Link>
                                </li>
                                <li className={pathname == '/css/min-block-size' ? 'activeMenu' : ''}>
                                    <Link to="/css/min-block-size" onClick={() => { setPathName('/css/min-block-size'); } }>min-block-size</Link>
                                </li>
                                <li className={pathname == '/css/min-inline-size' ? 'activeMenu' : ''}>
                                    <Link to="/css/min-inline-size" onClick={() => { setPathName('/css/min-inline-size'); } }>min-inline-size</Link>
                                </li>
                                <li className={pathname == '/css/min-height' ? 'activeMenu' : ''}>
                                    <Link to="/css/min-height" onClick={() => { setPathName('/css/min-height'); } }>min-height</Link>
                                </li>
                                <li className={pathname == '/css/min-width' ? 'activeMenu' : ''}>
                                    <Link to="/css/min-width" onClick={() => { setPathName('/css/min-width'); } }>min-width</Link>
                                </li>
                                <li className={pathname == '/css/mix-blend-mode' ? 'activeMenu' : ''}>
                                    <Link to="/css/mix-blend-mode" onClick={() => { setPathName('/css/mix-blend-mode'); } }>mix-blend-mode</Link>
                                </li>
                                <li className={pathname == '/css/object-fit' ? 'activeMenu' : ''}>
                                    <Link to="/css/object-fit" onClick={() => { setPathName('/css/object-fit'); } }>object-fit</Link>
                                </li>
                                <li className={pathname == '/css/object-position' ? 'activeMenu' : ''}>
                                    <Link to="/css/object-position" onClick={() => { setPathName('/css/object-position'); } }>object-position</Link>
                                </li>
                                <li className={pathname == '/css/offset' ? 'activeMenu' : ''}>
                                    <Link to="/css/offset" onClick={() => { setPathName('/css/offset'); } }>offset</Link>
                                </li>
                                <li className={pathname == '/css/offset-anchor' ? 'activeMenu' : ''}>
                                    <Link to="/css/offset-anchor" onClick={() => { setPathName('/css/offset-anchor'); } }>offset-anchor</Link>
                                </li>
                                <li className={pathname == '/css/offset-distance' ? 'activeMenu' : ''}>
                                    <Link to="/css/offset-distance" onClick={() => { setPathName('/css/offset-distance'); } }>offset-distance</Link>
                                </li>
                                <li className={pathname == '/css/offset-path' ? 'activeMenu' : ''}>
                                    <Link to="/css/offset-path" onClick={() => { setPathName('/css/offset-path'); } }>offset-path</Link>
                                </li>
                                <li className={pathname == '/css/offset-rotate' ? 'activeMenu' : ''}>
                                    <Link to="/css/offset-rotate" onClick={() => { setPathName('/css/offset-rotate'); } }>offset-rotate</Link>
                                </li>
                                <li className={pathname == '/css/opacity' ? 'activeMenu' : ''}>
                                    <Link to="/css/opacity" onClick={() => { setPathName('/css/opacity'); } }>opacity</Link>
                                </li>
                                <li className={pathname == '/css/order' ? 'activeMenu' : ''}>
                                    <Link to="/css/order" onClick={() => { setPathName('/css/order'); } }>order</Link>
                                </li>
                                <li className={pathname == '/css/orphans' ? 'activeMenu' : ''}>
                                    <Link to="/css/orphans" onClick={() => { setPathName('/css/orphans'); } }>orphans</Link>
                                </li>
                                <li className={pathname == '/css/outline' ? 'activeMenu' : ''}>
                                    <Link to="/css/outline" onClick={() => { setPathName('/css/outline'); } }>outline</Link>
                                </li>
                                <li className={pathname == '/css/outline-color' ? 'activeMenu' : ''}>
                                    <Link to="/css/outline-color" onClick={() => { setPathName('/css/outline-color'); } }>outline-color</Link>
                                </li>
                                <li className={pathname == '/css/outline-offset' ? 'activeMenu' : ''}>
                                    <Link to="/css/outline-offset" onClick={() => { setPathName('/css/outline-offset'); } }>outline-offset</Link>
                                </li>
                                <li className={pathname == '/css/outline-style' ? 'activeMenu' : ''}>
                                    <Link to="/css/outline-style" onClick={() => { setPathName('/css/outline-style'); } }>outline-style</Link>
                                </li>
                                <li className={pathname == '/css/outline-width' ? 'activeMenu' : ''}>
                                    <Link to="/css/outline-width" onClick={() => { setPathName('/css/outline-width'); } }>outline-width</Link>
                                </li>
                                <li className={pathname == '/css/overflow' ? 'activeMenu' : ''}>
                                    <Link to="/css/overflow" onClick={() => { setPathName('/css/overflow'); } }>overflow</Link>
                                </li>
                                <li className={pathname == '/css/overflow-anchor' ? 'activeMenu' : ''}>
                                    <Link to="/css/overflow-anchor" onClick={() => { setPathName('/css/overflow-anchor'); } }>overflow-anchor</Link>
                                </li>
                                <li className={pathname == '/css/overflow-wrap' ? 'activeMenu' : ''}>
                                    <Link to="/css/overflow-wrap" onClick={() => { setPathName('/css/overflow-wrap'); } }>overflow-wrap</Link>
                                </li>
                                <li className={pathname == '/css/overflow-x' ? 'activeMenu' : ''}>
                                    <Link to="/css/overflow-x" onClick={() => { setPathName('/css/overflow-x'); } }>overflow-x</Link>
                                </li>
                                <li className={pathname == '/css/overflow-y' ? 'activeMenu' : ''}>
                                    <Link to="/css/overflow-y" onClick={() => { setPathName('/css/overflow-y'); } }>overflow-y</Link>
                                </li>
                                <li className={pathname == '/css/overscroll-behavior' ? 'activeMenu' : ''}>
                                    <Link to="/css/overscroll-behavior" onClick={() => { setPathName('/css/overscroll-behavior'); } }>overscroll-behavior</Link>
                                </li>
                                <li className={pathname == '/css/overscroll-behavior-block' ? 'activeMenu' : ''}>
                                    <Link to="/css/overscroll-behavior-block" onClick={() => { setPathName('/css/overscroll-behavior-block'); } }>overscroll-behavior-block</Link>
                                </li>
                                <li className={pathname == '/css/overscroll-behavior-inline' ? 'activeMenu' : ''}>
                                    <Link to="/css/overscroll-behavior-inline" onClick={() => { setPathName('/css/overscroll-behavior-inline'); } }>overscroll-behavior-inline</Link>
                                </li>
                                <li className={pathname == '/css/overscroll-behavior-x' ? 'activeMenu' : ''}>
                                    <Link to="/css/overscroll-behavior-x" onClick={() => { setPathName('/css/overscroll-behavior-x'); } }>overscroll-behavior-x</Link>
                                </li>
                                <li className={pathname == '/css/overscroll-behavior-y' ? 'activeMenu' : ''}>
                                    <Link to="/css/overscroll-behavior-y" onClick={() => { setPathName('/css/overscroll-behavior-y'); } }>overscroll-behavior-y</Link>
                                </li>
                                <li className={pathname == '/css/padding' ? 'activeMenu' : ''}>
                                    <Link to="/css/padding" onClick={() => { setPathName('/css/padding'); } }>padding</Link>
                                </li>
                                <li className={pathname == '/css/padding-block' ? 'activeMenu' : ''}>
                                    <Link to="/css/padding-block" onClick={() => { setPathName('/css/padding-block'); } }>padding-block</Link>
                                </li>
                                <li className={pathname == '/css/padding-block-end' ? 'activeMenu' : ''}>
                                    <Link to="/css/padding-block-end" onClick={() => { setPathName('/css/padding-block-end'); } }>padding-block-end</Link>
                                </li>
                                <li className={pathname == '/css/padding-block-start' ? 'activeMenu' : ''}>
                                    <Link to="/css/padding-block-start" onClick={() => { setPathName('/css/padding-block-start'); } }>padding-block-start</Link>
                                </li>
                                <li className={pathname == '/css/padding-bottom' ? 'activeMenu' : ''}>
                                    <Link to="/css/padding-bottom" onClick={() => { setPathName('/css/padding-bottom'); } }>padding-bottom</Link>
                                </li>
                                <li className={pathname == '/css/padding-inline' ? 'activeMenu' : ''}>
                                    <Link to="/css/padding-inline" onClick={() => { setPathName('/css/padding-inline'); } }>padding-inline</Link>
                                </li>
                                <li className={pathname == '/css/padding-inline-end' ? 'activeMenu' : ''}>
                                    <Link to="/css/padding-inline-end" onClick={() => { setPathName('/css/padding-inline-end'); } }>padding-inline-end</Link>
                                </li>
                                <li className={pathname == '/css/padding-inline-start' ? 'activeMenu' : ''}>
                                    <Link to="/css/padding-inline-start" onClick={() => { setPathName('/css/padding-inline-start'); } }>padding-inline-start</Link>
                                </li>
                                <li className={pathname == '/css/padding-left' ? 'activeMenu' : ''}>
                                    <Link to="/css/padding-left" onClick={() => { setPathName('/css/padding-left'); } }>padding-left</Link>
                                </li>
                                <li className={pathname == '/css/padding-right' ? 'activeMenu' : ''}>
                                    <Link to="/css/padding-right" onClick={() => { setPathName('/css/padding-right'); } }>padding-right</Link>
                                </li>
                                <li className={pathname == '/css/padding-top' ? 'activeMenu' : ''}>
                                    <Link to="/css/padding-top" onClick={() => { setPathName('/css/padding-top'); } }>padding-top</Link>
                                </li>
                                <li className={pathname == '/css/page-break-after' ? 'activeMenu' : ''}>
                                    <Link to="/css/page-break-after" onClick={() => { setPathName('/css/page-break-after'); } }>page-break-after</Link>
                                </li>
                                <li className={pathname == '/css/page-break-before' ? 'activeMenu' : ''}>
                                    <Link to="/css/page-break-before" onClick={() => { setPathName('/css/page-break-before'); } }>page-break-before</Link>
                                </li>
                                <li className={pathname == '/css/page-break-inside' ? 'activeMenu' : ''}>
                                    <Link to="/css/page-break-inside" onClick={() => { setPathName('/css/page-break-inside'); } }>page-break-inside</Link>
                                </li>
                                <li className={pathname == '/css/paint-order' ? 'activeMenu' : ''}>
                                    <Link to="/css/paint-order" onClick={() => { setPathName('/css/paint-order'); } }>paint-order</Link>
                                </li>
                                <li className={pathname == '/css/perspective' ? 'activeMenu' : ''}>
                                    <Link to="/css/perspective" onClick={() => { setPathName('/css/perspective'); } }>perspective</Link>
                                </li>
                                <li className={pathname == '/css/perspective-origin' ? 'activeMenu' : ''}>
                                    <Link to="/css/perspective-origin" onClick={() => { setPathName('/css/perspective-origin'); } }>perspective-origin</Link>
                                </li>
                                <li className={pathname == '/css/place-content' ? 'activeMenu' : ''}>
                                    <Link to="/css/place-content" onClick={() => { setPathName('/css/place-content'); } }>place-content</Link>
                                </li>
                                <li className={pathname == '/css/place-items' ? 'activeMenu' : ''}>
                                    <Link to="/css/place-items" onClick={() => { setPathName('/css/place-items'); } }>place-items</Link>
                                </li>
                                <li className={pathname == '/css/place-self' ? 'activeMenu' : ''}>
                                    <Link to="/css/place-self" onClick={() => { setPathName('/css/place-self'); } }>place-self</Link>
                                </li>
                                <li className={pathname == '/css/pointer-events' ? 'activeMenu' : ''}>
                                    <Link to="/css/pointer-events" onClick={() => { setPathName('/css/pointer-events'); } }>pointer-events</Link>
                                </li>
                                <li className={pathname == '/css/position' ? 'activeMenu' : ''}>
                                    <Link to="/css/position" onClick={() => { setPathName('/css/position'); } }>position</Link>
                                </li>
                                <li className={pathname == '/css/quotes' ? 'activeMenu' : ''}>
                                    <Link to="/css/quotes" onClick={() => { setPathName('/css/quotes'); } }>quotes</Link>
                                </li>
                                <li className={pathname == '/css/resize' ? 'activeMenu' : ''}>
                                    <Link to="/css/resize" onClick={() => { setPathName('/css/resize'); } }>resize</Link>
                                </li>
                                <li className={pathname == '/css/right' ? 'activeMenu' : ''}>
                                    <Link to="/css/right" onClick={() => { setPathName('/css/right'); } }>right</Link>
                                </li>
                                <li className={pathname == '/css/rotate' ? 'activeMenu' : ''}>
                                    <Link to="/css/rotate" onClick={() => { setPathName('/css/rotate'); } }>rotate</Link>
                                </li>
                                <li className={pathname == '/css/row-gap' ? 'activeMenu' : ''}>
                                    <Link to="/css/row-gap" onClick={() => { setPathName('/css/row-gap'); } }>row-gap</Link>
                                </li>
                                <li className={pathname == '/css/scroll-behavior' ? 'activeMenu' : ''}>
                                    <Link to="/css/scroll-behavior" onClick={() => { setPathName('/css/scroll-behavior'); } }>scroll-behavior</Link>
                                </li>
                                <li className={pathname == '/css/scroll-margin' ? 'activeMenu' : ''}>
                                    <Link to="/css/scroll-margin" onClick={() => { setPathName('/css/scroll-margin'); } }>scroll-margin</Link>
                                </li>
                                <li className={pathname == '/css/scroll-margin-block' ? 'activeMenu' : ''}>
                                    <Link to="/css/scroll-margin-block" onClick={() => { setPathName('/css/scroll-margin-block'); } }>scroll-margin-block</Link>
                                </li>
                                <li className={pathname == '/css/scroll-margin-block-end' ? 'activeMenu' : ''}>
                                    <Link to="/css/scroll-margin-block-end" onClick={() => { setPathName('/css/scroll-margin-block-end'); } }>scroll-margin-block-end</Link>
                                </li>
                                <li className={pathname == '/css/scroll-margin-block-start' ? 'activeMenu' : ''}>
                                    <Link to="/css/scroll-margin-block-start" onClick={() => { setPathName('/css/scroll-margin-block-start'); } }>scroll-margin-block-start</Link>
                                </li>
                                <li className={pathname == '/css/scroll-margin-bottom' ? 'activeMenu' : ''}>
                                    <Link to="/css/scroll-margin-bottom" onClick={() => { setPathName('/css/scroll-margin-bottom'); } }>scroll-margin-bottom</Link>
                                </li>
                                <li className={pathname == '/css/scroll-margin-inline' ? 'activeMenu' : ''}>
                                    <Link to="/css/scroll-margin-inline" onClick={() => { setPathName('/css/scroll-margin-inline'); } }>scroll-margin-inline</Link>
                                </li>
                                <li className={pathname == '/css/scroll-margin-inline-end' ? 'activeMenu' : ''}>
                                    <Link to="/css/scroll-margin-inline-end" onClick={() => { setPathName('/css/scroll-margin-inline-end'); } }>scroll-margin-inline-end</Link>
                                </li>
                                <li className={pathname == '/css/scroll-margin-inline-start' ? 'activeMenu' : ''}>
                                    <Link to="/css/scroll-margin-inline-start" onClick={() => { setPathName('/css/scroll-margin-inline-start'); } }>scroll-margin-inline-start</Link>
                                </li>
                                <li className={pathname == '/css/scroll-margin-left' ? 'activeMenu' : ''}>
                                    <Link to="/css/scroll-margin-left" onClick={() => { setPathName('/css/scroll-margin-left'); } }>scroll-margin-left</Link>
                                </li>
                                <li className={pathname == '/css/scroll-margin-right' ? 'activeMenu' : ''}>
                                    <Link to="/css/scroll-margin-right" onClick={() => { setPathName('/css/scroll-margin-right'); } }>scroll-margin-right</Link>
                                </li>
                                <li className={pathname == '/css/scroll-margin-top' ? 'activeMenu' : ''}>
                                    <Link to="/css/scroll-margin-top" onClick={() => { setPathName('/css/scroll-margin-top'); } }>scroll-margin-top</Link>
                                </li>
                                <li className={pathname == '/css/scroll-padding' ? 'activeMenu' : ''}>
                                    <Link to="/css/scroll-padding" onClick={() => { setPathName('/css/scroll-padding'); } }>scroll-padding</Link>
                                </li>
                                <li className={pathname == '/css/scroll-padding-block' ? 'activeMenu' : ''}>
                                    <Link to="/css/scroll-padding-block" onClick={() => { setPathName('/css/scroll-padding-block'); } }>scroll-padding-block</Link>
                                </li>
                                <li className={pathname == '/css/scroll-padding-block-end' ? 'activeMenu' : ''}>
                                    <Link to="/css/scroll-padding-block-end" onClick={() => { setPathName('/css/scroll-padding-block-end'); } }>scroll-padding-block-end</Link>
                                </li>
                                <li className={pathname == '/css/scroll-padding-block-start' ? 'activeMenu' : ''}>
                                    <Link to="/css/scroll-padding-block-start" onClick={() => { setPathName('/css/scroll-padding-block-start'); } }>scroll-padding-block-start</Link>
                                </li>
                                <li className={pathname == '/css/scroll-padding-bottom' ? 'activeMenu' : ''}>
                                    <Link to="/css/scroll-padding-bottom" onClick={() => { setPathName('/css/scroll-padding-bottom'); } }>scroll-padding-bottom</Link>
                                </li>
                                <li className={pathname == '/css/scroll-padding-inline' ? 'activeMenu' : ''}>
                                    <Link to="/css/scroll-padding-inline" onClick={() => { setPathName('/css/scroll-padding-inline'); } }>scroll-padding-inline</Link>
                                </li>
                                <li className={pathname == '/css/scroll-padding-inline-end' ? 'activeMenu' : ''}>
                                    <Link to="/css/scroll-padding-inline-end" onClick={() => { setPathName('/css/scroll-padding-inline-end'); } }>scroll-padding-inline-end</Link>
                                </li>
                                <li className={pathname == '/css/scroll-padding-inline-start' ? 'activeMenu' : ''}>
                                    <Link to="/css/scroll-padding-inline-start" onClick={() => { setPathName('/css/scroll-padding-inline-start'); } }>scroll-padding-inline-start</Link>
                                </li>
                                <li className={pathname == '/css/scroll-padding-left' ? 'activeMenu' : ''}>
                                    <Link to="/css/scroll-padding-left" onClick={() => { setPathName('/css/scroll-padding-left'); } }>scroll-padding-left</Link>
                                </li>
                                <li className={pathname == '/css/scroll-padding-right' ? 'activeMenu' : ''}>
                                    <Link to="/css/scroll-padding-right" onClick={() => { setPathName('/css/scroll-padding-right'); } }>scroll-padding-right</Link>
                                </li>
                                <li className={pathname == '/css/scroll-padding-top' ? 'activeMenu' : ''}>
                                    <Link to="/css/scroll-padding-top" onClick={() => { setPathName('/css/scroll-padding-top'); } }>scroll-padding-top</Link>
                                </li>
                                <li className={pathname == '/css/scroll-snap-align' ? 'activeMenu' : ''}>
                                    <Link to="/css/scroll-snap-align" onClick={() => { setPathName('/css/scroll-snap-align'); } }>scroll-snap-align</Link>
                                </li>
                                <li className={pathname == '/css/scroll-snap-stop' ? 'activeMenu' : ''}>
                                    <Link to="/css/scroll-snap-stop" onClick={() => { setPathName('/css/scroll-snap-stop'); } }>scroll-snap-stop</Link>
                                </li>
                                <li className={pathname == '/css/scroll-snap-type' ? 'activeMenu' : ''}>
                                    <Link to="/css/scroll-snap-type" onClick={() => { setPathName('/css/scroll-snap-type'); } }>scroll-snap-type</Link>
                                </li>
                                <li className={pathname == '/css/scrollbar-color' ? 'activeMenu' : ''}>
                                    <Link to="/css/scrollbar-color" onClick={() => { setPathName('/css/scrollbar-color'); } }>scrollbar-color</Link>
                                </li>
                                <li className={pathname == '/css/tab-size' ? 'activeMenu' : ''}>
                                    <Link to="/css/tab-size" onClick={() => { setPathName('/css/tab-size'); } }>tab-size</Link>
                                </li>
                                <li className={pathname == '/css/table-layout' ? 'activeMenu' : ''}>
                                    <Link to="/css/table-layout" onClick={() => { setPathName('/css/table-layout'); } }>table-layout</Link>
                                </li>
                                <li className={pathname == '/css/text-align' ? 'activeMenu' : ''}>
                                    <Link to="/css/text-align" onClick={() => { setPathName('/css/text-align'); } }>text-align</Link>
                                </li>
                                <li className={pathname == '/css/text-align-last' ? 'activeMenu' : ''}>
                                    <Link to="/css/text-align-last" onClick={() => { setPathName('/css/text-align-last'); } }>text-align-last</Link>
                                </li>
                                <li className={pathname == '/css/text-decoration' ? 'activeMenu' : ''}>
                                    <Link to="/css/text-decoration" onClick={() => { setPathName('/css/text-decoration'); } }>text-decoration</Link>
                                </li>
                                <li className={pathname == '/css/text-decoration-color' ? 'activeMenu' : ''}>
                                    <Link to="/css/text-decoration-color" onClick={() => { setPathName('/css/text-decoration-color'); } }>text-decoration-color</Link>
                                </li>
                                <li className={pathname == '/css/text-decoration-line' ? 'activeMenu' : ''}>
                                    <Link to="/css/text-decoration-line" onClick={() => { setPathName('/css/text-decoration-line'); } }>text-decoration-line</Link>
                                </li>
                                <li className={pathname == '/css/text-decoration-style' ? 'activeMenu' : ''}>
                                    <Link to="/css/text-decoration-style" onClick={() => { setPathName('/css/text-decoration-style'); } }>text-decoration-style</Link>
                                </li>
                                <li className={pathname == '/css/text-decoration-thickness' ? 'activeMenu' : ''}>
                                    <Link to="/css/text-decoration-thickness" onClick={() => { setPathName('/css/text-decoration-thickness'); } }>text-decoration-thickness</Link>
                                </li>
                                <li className={pathname == '/css/text-emphasis' ? 'activeMenu' : ''}>
                                    <Link to="/css/text-emphasis" onClick={() => { setPathName('/css/text-emphasis'); } }>text-emphasis</Link>
                                </li>
                                <li className={pathname == '/css/text-emphasis-color' ? 'activeMenu' : ''}>
                                    <Link to="/css/text-emphasis-color" onClick={() => { setPathName('/css/text-emphasis-color'); } }>text-emphasis-color</Link>
                                </li>
                                <li className={pathname == '/css/text-emphasis-position' ? 'activeMenu' : ''}>
                                    <Link to="/css/text-emphasis-position" onClick={() => { setPathName('/css/text-emphasis-position'); } }>text-emphasis-position</Link>
                                </li>
                                <li className={pathname == '/css/text-emphasis-style' ? 'activeMenu' : ''}>
                                    <Link to="/css/text-emphasis-style" onClick={() => { setPathName('/css/text-emphasis-style'); } }>text-emphasis-style</Link>
                                </li>
                                <li className={pathname == '/css/text-indent' ? 'activeMenu' : ''}>
                                    <Link to="/css/text-indent" onClick={() => { setPathName('/css/text-indent'); } }>text-indent</Link>
                                </li>
                                <li className={pathname == '/css/text-justify' ? 'activeMenu' : ''}>
                                    <Link to="/css/text-justify" onClick={() => { setPathName('/css/text-justify'); } }>text-justify</Link>
                                </li>
                                <li className={pathname == '/css/text-orientation' ? 'activeMenu' : ''}>
                                    <Link to="/css/text-orientation" onClick={() => { setPathName('/css/text-orientation'); } }>text-orientation</Link>
                                </li>
                                <li className={pathname == '/css/text-overflow' ? 'activeMenu' : ''}>
                                    <Link to="/css/text-overflow" onClick={() => { setPathName('/css/text-overflow'); } }>text-overflow</Link>
                                </li>
                                <li className={pathname == '/css/text-shadow' ? 'activeMenu' : ''}>
                                    <Link to="/css/text-shadow" onClick={() => { setPathName('/css/text-shadow'); } }>text-shadow</Link>
                                </li>
                                <li className={pathname == '/css/text-transform' ? 'activeMenu' : ''}>
                                    <Link to="/css/text-transform" onClick={() => { setPathName('/css/text-transform'); } }>text-transform</Link>
                                </li>
                                <li className={pathname == '/css/text-underline-offset' ? 'activeMenu' : ''}>
                                    <Link to="/css/text-underline-offset" onClick={() => { setPathName('/css/text-underline-offset'); } }>text-underline-offset</Link>
                                </li>
                                <li className={pathname == '/css/text-underline-position' ? 'activeMenu' : ''}>
                                    <Link to="/css/text-underline-position" onClick={() => { setPathName('/css/text-underline-position'); } }>text-underline-position</Link>
                                </li>
                                <li className={pathname == '/css/top' ? 'activeMenu' : ''}>
                                    <Link to="/css/top" onClick={() => { setPathName('/css/top'); } }>top</Link>
                                </li>
                                <li className={pathname == '/css/transform' ? 'activeMenu' : ''}>
                                    <Link to="/css/transform" onClick={() => { setPathName('/css/transform'); } }>transform</Link>
                                </li>
                                <li className={pathname == '/css/transform-origin' ? 'activeMenu' : ''}>
                                    <Link to="/css/transform-origin" onClick={() => { setPathName('/css/transform-origin'); } }>transform-origin</Link>
                                </li>
                                <li className={pathname == '/css/transform-style' ? 'activeMenu' : ''}>
                                    <Link to="/css/transform-style" onClick={() => { setPathName('/css/transform-style'); } }>transform-style</Link>
                                </li>
                                <li className={pathname == '/css/transition' ? 'activeMenu' : ''}>
                                    <Link to="/css/transition" onClick={() => { setPathName('/css/transition'); } }>transition</Link>
                                </li>
                                <li className={pathname == '/css/transition-delay' ? 'activeMenu' : ''}>
                                    <Link to="/css/transition-delay" onClick={() => { setPathName('/css/transition-delay'); } }>transition-delay</Link>
                                </li>
                                <li className={pathname == '/css/transition-duration' ? 'activeMenu' : ''}>
                                    <Link to="/css/transition-duration" onClick={() => { setPathName('/css/transition-duration'); } }>transition-duration</Link>
                                </li>
                                <li className={pathname == '/css/transition-property' ? 'activeMenu' : ''}>
                                    <Link to="/css/transition-property" onClick={() => { setPathName('/css/transition-property'); } }>transition-property</Link>
                                </li>
                                <li className={pathname == '/css/transition-timing-function' ? 'activeMenu' : ''}>
                                    <Link to="/css/transition-timing-function" onClick={() => { setPathName('/css/transition-timing-function'); } }>transition-timing-function</Link>
                                </li>
                                <li className={pathname == '/css/translate' ? 'activeMenu' : ''}>
                                    <Link to="/css/translate" onClick={() => { setPathName('/css/translate'); } }>translate</Link>
                                </li>
                                <li className={pathname == '/css/unicode-bidi' ? 'activeMenu' : ''}>
                                    <Link to="/css/unicode-bidi" onClick={() => { setPathName('/css/unicode-bidi'); } }>unicode-bidi</Link>
                                </li>
                                <li className={pathname == '/css/user-select' ? 'activeMenu' : ''}>
                                    <Link to="/css/user-select" onClick={() => { setPathName('/css/user-select'); } }>user-select</Link>
                                </li>
                                <li className={pathname == '/css/vertical-align' ? 'activeMenu' : ''}>
                                    <Link to="/css/vertical-align" onClick={() => { setPathName('/css/vertical-align'); } }>vertical-align</Link>
                                </li>
                                <li className={pathname == '/css/visibility' ? 'activeMenu' : ''}>
                                    <Link to="/css/visibility" onClick={() => { setPathName('/css/visibility'); } }>visibility</Link>
                                </li>
                                <li className={pathname == '/css/white-space' ? 'activeMenu' : ''}>
                                    <Link to="/css/white-space" onClick={() => { setPathName('/css/white-space'); } }>white-space</Link>
                                </li>
                                <li className={pathname == '/css/widows' ? 'activeMenu' : ''}>
                                    <Link to="/css/widows" onClick={() => { setPathName('/css/widows'); } }>widows</Link>
                                </li>
                                <li className={pathname == '/css/width' ? 'activeMenu' : ''}>
                                    <Link to="/css/width" onClick={() => { setPathName('/css/width'); } }>width</Link>
                                </li>
                                <li className={pathname == '/css/word-break' ? 'activeMenu' : ''}>
                                    <Link to="/css/word-break" onClick={() => { setPathName('/css/word-break'); } }>word-break</Link>
                                </li>
                                <li className={pathname == '/css/word-spacing' ? 'activeMenu' : ''}>
                                    <Link to="/css/word-spacing" onClick={() => { setPathName('/css/word-spacing'); } }>word-spacing</Link>
                                </li>
                                <li className={pathname == '/css/word-wrap' ? 'activeMenu' : ''}>
                                    <Link to="/css/word-wrap" onClick={() => { setPathName('/css/word-wrap'); } }>word-wrap</Link>
                                </li>
                                <li className={pathname == '/css/writing-mode' ? 'activeMenu' : ''}>
                                    <Link to="/css/writing-mode" onClick={() => { setPathName('/css/writing-mode'); } }>writing-mode</Link>
                                </li>
                                <li className={pathname == '/css/z-index' ? 'activeMenu' : ''}>
                                    <Link to="/css/z-index" onClick={() => { setPathName('/css/z-index'); } }>z-index</Link>
                                </li>
                            </ul>
                        </nav>
                    </div>
                    <div className='col-12 col-md-9 col-lg-10 rightPart' style={{ 'overflowY': 'scroll', height: 'calc(100vh - 110px)' }}>
                        <div className='row'>
                            <div className='col-12 col-md-12 col-lg-10 col-lg-9 mb-5'>
                                <div className='headerAdSpace' ref={addspaceElement}>
                                    <ins className="adsbygoogle"
                                        style={{ 'display': 'block' }}
                                        data-ad-client="ca-pub-2061924575986153"
                                        data-ad-slot="8656415608"
                                        data-ad-format="auto"
                                        data-full-width-responsive="true"></ins>
                                    <script>
                                        (adsbygoogle = window.adsbygoogle || []).push({ });
                                    </script>
                                </div>
                                <div className='container'>
                                    <div className='contentPage'>
                                        {
                                            pathName.urls ? <PreviousNext urls={ pathName.urls } /> : <></>
                                        }
                                            <Outlet />
                                        {
                                            pathName.urls ? <PreviousNext urls={ pathName.urls } /> : <></>
                                        }
                                    </div>
                                </div>
                                <div className='footerAdSpace'>
                                    <ins className="adsbygoogle"
                                        style={{ 'display': 'block' }}
                                        data-ad-client="ca-pub-2061924575986153"
                                        data-ad-slot="7856138126"
                                        data-ad-format="auto"
                                        data-full-width-responsive="true"></ins>
                                    <script>
                                        (adsbygoogle = window.adsbygoogle || []).push({ });
                                    </script>
                                </div>
                            </div>
                            <div className='col-lg-3 hiddenInSmall'>
                                <ins className="adsbygoogle"
                                    style={{ 'display': 'block' }}
                                    data-ad-client="ca-pub-2061924575986153"
                                    data-ad-slot="6669380669"
                                    data-ad-format="auto"
                                    data-full-width-responsive="true"></ins>
                                <script>
                                    (adsbygoogle = window.adsbygoogle || []).push({ });
                                </script>
                            </div>
                            <div className='col-12'>
                                <div className='container-fluid footerPart'>
                                    <Footer />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}