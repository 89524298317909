import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Outlet, Link } from "react-router-dom";
export const pageUrl = () => "/mysql/replace-query";

export default function MysqlReplaceQuery() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Mysql - Replace Query | Aspirant's Home");
        const urls = {
            'previous': '/mysql/delete-query',
            'next': '/mysql/create-views'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>Mysql - Replace Query</h3>
            <div className='mt-4 mb-5'>
                <p>
                    In MySQL, the <strong>`REPLACE`</strong> statement is used to insert or update a record into a table. If a record with the same primary key (or unique key) already exists, it will be replaced with the new values, effectively performing an update. If the record does not exist, a new record will be inserted. 
                </p>
                <p>
                    The syntax for the <strong>`REPLACE`</strong> statement is similar to that of the <strong>`INSERT`</strong> statement:
                </p>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-blue">REPLACE INTO</span> table_name (column1, column2, ...) <span class="color-blue">VALUES</span> (value1, value2, ...);</p>
                        `
                    }}></div>
                </div>
                <p>
                    Where, <strong>`table_name`</strong> is the name of the table you want to insert into or replace a row in. <strong>`column1, column2, ...`</strong> are the columns you want to insert data into. <strong>`value1, value2, ...`</strong> are the values you want to insert into the columns.
                </p>
                <p>
                    Suppose, we have a <strong>`users`</strong> table like below -
                </p>
                <table className='table' style={{border: '1px dashed #ccc'}}> 
                    <thead>
                        <tr style={{border: '1px dashed #ccc'}}>
                            <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>id</th>
                            <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>name</th>
                            <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>age</th>
                            <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>Address</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr style={{border: '1px dashed #ccc'}}>
                            <td style={{border: '1px dashed #ccc'}}>1</td>
                            <td style={{border: '1px dashed #ccc'}}>Jane Smith</td>
                            <td style={{border: '1px dashed #ccc'}}>25</td>
                            <td style={{border: '1px dashed #ccc'}}>Apt. 415 9295 Anh Harbor, South Junior, FL 51309-5804</td>
                        </tr>
                        <tr style={{border: '1px dashed #ccc'}}>
                            <td style={{border: '1px dashed #ccc'}}>2</td>
                            <td style={{border: '1px dashed #ccc'}}>John Doe</td>
                            <td style={{border: '1px dashed #ccc'}}>35</td>
                            <td style={{border: '1px dashed #ccc'}}>Suite 977 83408 Murray Lakes, Lakinchester, WV 60583</td>
                        </tr>
                        <tr style={{border: '1px dashed #ccc'}}>
                            <td style={{border: '1px dashed #ccc'}}>3</td>
                            <td style={{border: '1px dashed #ccc'}}>Johny Smith</td>
                            <td style={{border: '1px dashed #ccc'}}>39</td>
                            <td style={{border: '1px dashed #ccc'}}>3797 Benton Locks, Weissnatside, MS 54762-5660</td>
                        </tr>
                        <tr style={{border: '1px dashed #ccc'}}>
                            <td style={{border: '1px dashed #ccc'}}>4</td>
                            <td style={{border: '1px dashed #ccc'}}>Jeffer Joe</td>
                            <td style={{border: '1px dashed #ccc'}}>28</td>
                            <td style={{border: '1px dashed #ccc'}}>Suite 715 6587 Champlin Freeway, Port Angelhaven, PA 42789</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className='mt-5 mb-5'>
                <h5>REPLACE an existing record</h5>
                <div className='mt-2 mb-2'>
                    <p>
                        If we use <strong>`INSERT`</strong> statement like below in our <strong>`users`</strong> table -
                    </p>
                    <div className='codePalateBox mt-2 mb-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                                <p><span class="color-blue">INSERT INTO</span> users (id, name, age, address) <span class="color-blue">VALUES</span> (<span class="color-pink">1</span>, <span class="color-green">'Xavier Doe'</span>, <span class="color-pink">30</span>, <span class="color-green">'79646 Shayna Wells, Markitatown, PA 15610-6996'</span>);</p>
                            `
                        }}></div>
                    </div>
                    <p>
                        Then, we got an error for duplicate entry of <Link to="/mysql/primary-key" >primary key</Link> -
                    </p>
                    <div className='codePalateBox mt-2 mb-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                                ERROR 1062 (23000): Duplicate entry '1' for key 'users.PRIMARY'
                            `
                        }}></div>
                    </div>


                    <p>
                        Now, If we use <strong>`REPLACE`</strong> statement like below in our <strong>`users`</strong> table -
                    </p>
                    <div className='codePalateBox mt-2 mb-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                                <p><span class="color-blue">REPLACE INTO</span> users (id, name, age, address) <span class="color-blue">VALUES</span> (<span class="color-pink">1</span>, <span class="color-green">'Xavier Doe'</span>, <span class="color-pink">30</span>, <span class="color-green">'79646 Shayna Wells, Markitatown, PA 15610-6996'</span>);</p>
                            `
                        }}></div>
                    </div>
                    <p>
                        Then the data of <strong>`users`</strong> table gets replaced by new data as primary key 1 is exists in <strong>`users`</strong> table. Now <strong>`users`</strong> table is looks like below -
                    </p>
                    <table className='table' style={{border: '1px dashed #ccc'}}> 
                        <thead>
                            <tr style={{border: '1px dashed #ccc'}}>
                                <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>id</th>
                                <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>name</th>
                                <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>age</th>
                                <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>Address</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr style={{border: '1px dashed #ccc'}}>
                                <td style={{border: '1px dashed #ccc'}}>1</td>
                                <td style={{border: '1px dashed #ccc'}}>Xavier Doe</td>
                                <td style={{border: '1px dashed #ccc'}}>30</td>
                                <td style={{border: '1px dashed #ccc'}}>79646 Shayna Wells, Markitatown, PA 15610-6996</td>
                            </tr>
                            <tr style={{border: '1px dashed #ccc'}}>
                                <td style={{border: '1px dashed #ccc'}}>2</td>
                                <td style={{border: '1px dashed #ccc'}}>John Doe</td>
                                <td style={{border: '1px dashed #ccc'}}>35</td>
                                <td style={{border: '1px dashed #ccc'}}>Suite 977 83408 Murray Lakes, Lakinchester, WV 60583</td>
                            </tr>
                            <tr style={{border: '1px dashed #ccc'}}>
                                <td style={{border: '1px dashed #ccc'}}>3</td>
                                <td style={{border: '1px dashed #ccc'}}>Johny Smith</td>
                                <td style={{border: '1px dashed #ccc'}}>39</td>
                                <td style={{border: '1px dashed #ccc'}}>3797 Benton Locks, Weissnatside, MS 54762-5660</td>
                            </tr>
                            <tr style={{border: '1px dashed #ccc'}}>
                                <td style={{border: '1px dashed #ccc'}}>4</td>
                                <td style={{border: '1px dashed #ccc'}}>Jeffer Joe</td>
                                <td style={{border: '1px dashed #ccc'}}>28</td>
                                <td style={{border: '1px dashed #ccc'}}>Suite 715 6587 Champlin Freeway, Port Angelhaven, PA 42789</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </section>
    )
}