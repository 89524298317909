import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../../Context";
import { Link } from 'react-router-dom';
export const pageUrl = () => "/mysql/aggregate-functions/group-concat";

export default function GROUP_CONCAT() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Mysql - Aggregate Functions - GROUP_CONCAT() | Aspirant's Home");
        path.setPreviousNext({});
    }, [])

    return (
        <>
            <section class='clearfix mb-3'>
                <span className='float-start'>
                    <Link to='/mysql/aggregate-functions' onClick={() => path.setPathName('/mysql/aggregate-functions')} className='btn btn-primary'> <i class="fa-solid fa-less-than"></i>&nbsp; Back to Aggregate Functions </Link>
                </span>
            </section>
            <section className='mt-5 mb-5'>
                <h3>Mysql - GROUP_CONCAT() Function</h3>
                <div className='mt-4 mb-5'>
                    <p>
                        The <strong>`GROUP_CONCAT()`</strong> function in MySQL is an aggregate function that concatenates values from multiple rows into a single string. This function is particularly useful when you need to aggregate data from several rows into one row, often with a specified separator.
                    </p>
                    <h5>Syntax</h5>
                    <div className='codePalateBox mt-2 mb-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                                <p>GROUP_CONCAT(expression [<span class="color-blue">ORDER BY</span> {unsigned_integer | col_name | formula} <span class="color-blue">ASC</span> | <span class="color-blue">DESC</span>] [SEPARATOR str_val])</p>
                            `
                        }}></div>
                    </div>
                    <p>
                        Where, <strong>`expression`</strong> is the column or expression whose values you want to concatenate. <strong>`ORDER BY`</strong> is an optional clause to sort the concatenated values. <strong>`SEPARATOR`</strong> is an optional string to separate the concatenated values (default is a comma).
                    </p>
                    <p>
                        Let's consider a table <strong>`employees`</strong> with the following structure and data:
                    </p>
                    <table className='table' style={{border: '1px dashed #ccc'}}> 
                        <thead>
                            <tr style={{border: '1px dashed #ccc'}}>
                                <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>id</th>
                                <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>name</th>
                                <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>department</th>
                                <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>salary</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr style={{border: '1px dashed #ccc'}}>
                                <td style={{border: '1px dashed #ccc'}}>1</td>
                                <td style={{border: '1px dashed #ccc'}}>Jane Smith</td>
                                <td style={{border: '1px dashed #ccc'}}>IT</td>
                                <td style={{border: '1px dashed #ccc'}}>500000</td>
                            </tr>
                            <tr style={{border: '1px dashed #ccc'}}>
                                <td style={{border: '1px dashed #ccc'}}>2</td>
                                <td style={{border: '1px dashed #ccc'}}>John Doe</td>
                                <td style={{border: '1px dashed #ccc'}}>IT</td>
                                <td style={{border: '1px dashed #ccc'}}>350000</td>
                            </tr>
                            <tr style={{border: '1px dashed #ccc'}}>
                                <td style={{border: '1px dashed #ccc'}}>3</td>
                                <td style={{border: '1px dashed #ccc'}}>Johny Smith</td>
                                <td style={{border: '1px dashed #ccc'}}>Accounting</td>
                                <td style={{border: '1px dashed #ccc'}}>50000</td>
                            </tr>
                            <tr style={{border: '1px dashed #ccc'}}>
                                <td style={{border: '1px dashed #ccc'}}>4</td>
                                <td style={{border: '1px dashed #ccc'}}>Jeffer Joe</td>
                                <td style={{border: '1px dashed #ccc'}}>HR</td>
                                <td style={{border: '1px dashed #ccc'}}>380000</td>
                            </tr>
                            <tr style={{border: '1px dashed #ccc'}}>
                                <td style={{border: '1px dashed #ccc'}}>5</td>
                                <td style={{border: '1px dashed #ccc'}}>Sikhar Smith</td>
                                <td style={{border: '1px dashed #ccc'}}>Accounting</td>
                                <td style={{border: '1px dashed #ccc'}}>70000</td>
                            </tr>
                            <tr style={{border: '1px dashed #ccc'}}>
                                <td style={{border: '1px dashed #ccc'}}>6</td>
                                <td style={{border: '1px dashed #ccc'}}>Xavier Doe</td>
                                <td style={{border: '1px dashed #ccc'}}>IT</td>
                                <td style={{border: '1px dashed #ccc'}}>750000</td>
                            </tr>
                        </tbody>
                    </table>
                    <h5>Examples</h5>
                    <ul style={{listStyle: 'decimal'}}>
                        <li>
                            <strong>Using GROUP_CONCAT() to Concatenate Names by Department</strong>
                            <p>
                                To concatenate the names of employees for each department:
                            </p>
                            <div className='codePalateBox mt-2 mb-2'>
                                <div className='codePalate' dangerouslySetInnerHTML={{
                                    __html: `
                                        <p><span class="color-blue">SELECT</span> department, GROUP_CONCAT(name <span class="color-blue">ORDER BY</span> name <span class="color-blue">ASC</span> SEPARATOR <span class="color-green">', '</span>) <span class="color-blue">AS</span> employee_names</p>
                                        <p><span class="color-blue">FROM</span> employees</p>
                                        <p><span class="color-blue">GROUP BY</span> department;</p>
                                    `
                                }}></div>
                            </div>
                            <p>
                                This query will return:
                            </p>
                            <table className='table' style={{border: '1px dashed #ccc'}}> 
                                <thead>
                                    <tr style={{border: '1px dashed #ccc'}}>
                                        <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>department</th>
                                        <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>employee_names</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr style={{border: '1px dashed #ccc'}}>
                                        <td style={{border: '1px dashed #ccc'}}>IT</td>
                                        <td style={{border: '1px dashed #ccc'}}>Jane Smith, John Doe, Xavier Doe</td>
                                    </tr>
                                    <tr style={{border: '1px dashed #ccc'}}>
                                        <td style={{border: '1px dashed #ccc'}}>Accounting</td>
                                        <td style={{border: '1px dashed #ccc'}}>Johny Smith, Sikhar Smith</td>
                                    </tr>
                                    <tr style={{border: '1px dashed #ccc'}}>
                                        <td style={{border: '1px dashed #ccc'}}>HR</td>
                                        <td style={{border: '1px dashed #ccc'}}>Jeffer Joe</td>
                                    </tr>
                                </tbody>
                            </table>
                        </li>
                        <li>
                            <strong>Using GROUP_CONCAT() with Other Columns</strong>
                            <p>
                                To concatenate names along with their salaries for each department:
                            </p>
                            <div className='codePalateBox mt-2 mb-2'>
                                <div className='codePalate' dangerouslySetInnerHTML={{
                                    __html: `
                                        <p><span class="color-blue">SELECT</span> department, GROUP_CONCAT(CONCAT(name, ' (', salary, ')') <span class="color-blue">ORDER BY</span> salary <span class="color-blue">DESC</span> SEPARATOR <span class="color-green">', '</span>) <span class="color-blue">AS</span> employee_details
                                        <p><span class="color-blue">FROM</span> employees</p>
                                        <p><span class="color-blue">GROUP BY</span> department;</p>
                                    `
                                }}></div>
                            </div>
                            <p>
                                This query will return:
                            </p>
                            <table className='table' style={{border: '1px dashed #ccc'}}> 
                                <thead>
                                    <tr style={{border: '1px dashed #ccc'}}>
                                        <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>department</th>
                                        <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>employee_details</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr style={{border: '1px dashed #ccc'}}>
                                        <td style={{border: '1px dashed #ccc'}}>IT</td>
                                        <td style={{border: '1px dashed #ccc'}}>Xavier Doe (750000), Jane Smith (500000), John Doe (350000)</td>
                                    </tr>
                                    <tr style={{border: '1px dashed #ccc'}}>
                                        <td style={{border: '1px dashed #ccc'}}>Accounting</td>
                                        <td style={{border: '1px dashed #ccc'}}>Sikhar Smith (70000), Johny Smith (50000)</td>
                                    </tr>
                                    <tr style={{border: '1px dashed #ccc'}}>
                                        <td style={{border: '1px dashed #ccc'}}>HR</td>
                                        <td style={{border: '1px dashed #ccc'}}>Jeffer Joe (380000)</td>
                                    </tr>
                                </tbody>
                            </table>
                            <p>
                                Where, The <strong>`CONCAT(name, ' (', salary, ')')`</strong> function creates a string combining the name and salary of each employee. The <strong>`GROUP_CONCAT(... ORDER BY salary DESC SEPARATOR ', ')`</strong> function concatenates these strings for each department, sorted by salary in descending order, with a comma and space as the separator.
                            </p>
                        </li>
                    </ul>
                </div>
            </section>
        </>
    )
}