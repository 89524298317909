import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/mysql/create-database";

export default function MysqlCreateDatabase() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Mysql - Create Database | Aspirant's Home");
        const urls = {
            'previous': '/mysql/features',
            'next':'/mysql/drop-database'
        }
        path.setPreviousNext(urls);
    },[])

    return (
        <section className='mt-5 mb-5'>
            <h3>Mysql - Create Database</h3>
            <div className='mt-4 mb-5'>
                <p>
                    To create a database in MySQL, you can use the CREATE DATABASE statement followed by the name you want to give to your database. Here's the basic syntax:
                </p>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-blue">CREATE</span> DATABASE database_name;</p>
                        `
                    }}></div>
                </div>
                <p>
                    For example, to create a database named <strong>`mydatabase`</strong>, you would use the following SQL statement:
                </p>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-blue">CREATE</span> DATABASE mydatabase;</p>
                        `
                    }}></div>
                </div>
                <p>
                    If the database is created successfully, MySQL will return a message indicating that the operation was successful. You can then use this database to create tables and store data.
                </p>
            </div>
            <div className='mt-5 mb-5'>
                <h5>IF NOT EXISTS clause</h5>
                <div className='mb-2 mt-2'>
                    <p>
                        If you try to create a database with an existing name, an error will be generated like below
                    </p>
                    <div className='codePalateBox mt-2 mb-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                                <p>ERROR 1007 (HY000): Can't create database 'mydatabase';</p>
                                `
                        }}></div>
                    </div>
                    <p>
                        If you use the <strong>`IF NOT EXISTS`</strong> clause along with the <strong>`CREATE`</strong> statement as shown below a new database will be created and if a database with the given name, already exists the query will be ignored.
                    </p>
                    <div className='codePalateBox mt-2 mb-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                            <p><span class="color-blue">CREATE</span> DATABASE IF NOT EXISTS mydatabase</p>
                            `
                        }}></div>
                    </div>
                </div>
            </div>
        </section>
    )
}