import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/css/box/model";

export default function BoxModel() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Box Model in CSS | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/css/height/width',
            'next': '/css/outline'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>Box Model</h3>
            <div className='mt-4 mb-5'>
                <p>
                    Box Model is a fundamental concept that describes the rectangular boxes generated for elements in a document tree and the layout rules used to determine the size and position of these boxes. Understanding the box model is essential for effectively designing and laying out web pages.
                </p>
                <p>
                    The structure of the boxmodel is explained below.
                </p>
                <div class="mt-4 boxmodel">
                    <div class="margin">
                        <div class="border">
                            <div class="padding">
                                <div class="content"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <h5 className="mt-5 mb-3">Components of the Box Model</h5>

                <p>The box model consists of the following components:</p>
                <ul style={{ listStyle: 'decimal' }}>
                    <li><strong>Content</strong>: The innermost part of the box where the text and images appear. The width and height properties of an element set the dimensions of the content area.</li>

                    <li><strong>Padding</strong>: The space between the content and the border. Padding is added inside the element and increases the size of the element without affecting the outer dimensions.</li>

                    <li><strong>Border</strong>: A line that surrounds the padding (if any) and the content.</li>

                    <li><strong>Margin</strong>: The outermost part of the box. Margins create space between the element and its surrounding elements. Margins are transparent and do not have a background color.</li>
                </ul>

                <h5 className="mt-5 mb-3">Calculation Of Height & Width</h5>
                <p>
                    Let's take an example to understand the box-model.
                </p>
                
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>div {</p>
                            <p class="ml-30">    <span class="color-green">width</span>: <span class="color-pink">400px</span>;</p>
                            <p class="ml-30">    <span class="color-green">height</span>: <span class="color-pink">100px</span>;</p>
                            <p class="ml-30">    <span class="color-green">padding</span>: <span class="color-pink">20px</span>;</p>
                            <p class="ml-30">    <span class="color-green">border</span>: <span class="color-pink">10px</span> solid gray;</p>
                            <p class="ml-30">    <span class="color-green">margin</span>: <span class="color-pink">0</span>;</p>
                            <p>}</p>
                        `
                    }}></div>
                </div>
                <div className='outputPalateBox mb-4 mt-3' style={{'border':'0'}}>
                    <div className='outputPalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>The total width of an element should be calculated like this:</p>
                            <p>Total element <strong>width</strong> = <strong>width</strong> + <strong>left padding</strong> + <strong>right padding</strong> + <strong>left border</strong> + <strong>right border</strong></p>
                            <p> Total element width = 400px + 20px + 20px + 10px + 10px = 460px</p>
                            <p>The total height of an element should be calculated like this:</p>
                            <p>Total element <strong>height</strong> = <strong>height</strong> + <strong>top padding</strong> + <strong>bottom padding</strong> + <strong>top border</strong> + <strong>bottom border</strong></p>
                            <p> Total element height = 100px + 20px + 20px + 10px + 10px = 160px</p>
                        `
                    }}></div>
                </div>
            </div>
        </section>
    )
}