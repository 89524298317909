import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../../Context";
import { Link } from 'react-router-dom';
export const pageUrl = () => "/mysql/arithmetic-operators/addition";

export default function Addition() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Mysql - Arithmetic Operators - Addition(+) | Aspirant's Home");
        path.setPreviousNext({});
    }, [])

    return (
        <>
            <section class='clearfix mb-3'>
                <span className='float-start'>
                    <Link to='/mysql/arithmetic-operators' onClick={() => path.setPathName('/mysql/arithmetic-operators')} className='btn btn-primary'> <i class="fa-solid fa-less-than"></i>&nbsp; Back to Arithmetic Operators </Link>
                </span>
            </section>
            <section className='mt-5 mb-5'>
                <h3>Mysql - Addition(+) Operator</h3>
                <div className='mt-4 mb-5'>
                    <p>
                        In MySQL, the addition operator (<strong>`+`</strong>) is used to add two or more numeric values together. It can be used in various contexts, such as in SELECT queries, UPDATE statements, and when defining columns in CREATE TABLE statements.
                    </p>
                    <p>
                        Here's a basic example of using the addition operator in a SELECT query:
                    </p>
                    <div className='codePalateBox mt-2 mb-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                                <p><span class="color-blue">SELECT</span> <span class="color-pink">10</span> + <span class="color-pink">5</span>;</p>
                            `
                        }}></div>
                    </div>
                    <p>
                        This query will return the result of adding 10 and 5, which is 15.
                    </p>
                </div>
                <div className='mt-5 mb-5'>
                    <h5>Addition(+) with MySQL Table</h5>
                    <div className='mt-3 mb-2'>
                        <p>
                            Consider a table <strong>`expenses`</strong> with columns <strong>`item`</strong>, <strong>`cost`</strong>, and <strong>`tax`</strong>. 
                        </p>
                        <table className='table' style={{border: '1px dashed #ccc'}}> 
                            <thead>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>id</th>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>item</th>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>cost</th>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>tax</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>1</td>
                                    <td style={{border: '1px dashed #ccc'}}>Stationery</td>
                                    <td style={{border: '1px dashed #ccc'}}>100</td>
                                    <td style={{border: '1px dashed #ccc'}}>10</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>2</td>
                                    <td style={{border: '1px dashed #ccc'}}>Electronics</td>
                                    <td style={{border: '1px dashed #ccc'}}>500</td>
                                    <td style={{border: '1px dashed #ccc'}}>50</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>3</td>
                                    <td style={{border: '1px dashed #ccc'}}>Furniture</td>
                                    <td style={{border: '1px dashed #ccc'}}>1000</td>
                                    <td style={{border: '1px dashed #ccc'}}>100</td>
                                </tr>
                            </tbody>
                        </table>
                        <p>
                            If you want to calculate the total cost for each item including tax, you can use the addition operator as follows:
                        </p>
                        <div className='codePalateBox mt-2 mb-2'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-blue">SELECT</span> id, item, cost, tax, (cost + tax) <span class="color-blue">AS</span> total_cost <span class="color-blue">FROM</span> expenses;</p>
                                `
                            }}></div>
                        </div>
                        <p>
                            This query will return:
                        </p>
                        <table className='table' style={{border: '1px dashed #ccc'}}> 
                            <thead>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>id</th>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>item</th>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>cost</th>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>tax</th>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>total_cost</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>1</td>
                                    <td style={{border: '1px dashed #ccc'}}>Stationery</td>
                                    <td style={{border: '1px dashed #ccc'}}>100</td>
                                    <td style={{border: '1px dashed #ccc'}}>10</td>
                                    <td style={{border: '1px dashed #ccc'}}>110</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>2</td>
                                    <td style={{border: '1px dashed #ccc'}}>Electronics</td>
                                    <td style={{border: '1px dashed #ccc'}}>500</td>
                                    <td style={{border: '1px dashed #ccc'}}>50</td>
                                    <td style={{border: '1px dashed #ccc'}}>550</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>3</td>
                                    <td style={{border: '1px dashed #ccc'}}>Furniture</td>
                                    <td style={{border: '1px dashed #ccc'}}>1000</td>
                                    <td style={{border: '1px dashed #ccc'}}>100</td>
                                    <td style={{border: '1px dashed #ccc'}}>1100</td>
                                </tr>
                            </tbody>
                        </table>
                        <p>
                            In this example, the expression <strong>`(cost + tax)`</strong> uses the addition operator <strong>`(+)`</strong> to add the <strong>`cost`</strong> and <strong>`tax`</strong> columns together, calculating the total cost for each item including tax. The result is displayed in a new column named <strong>`total_cost`</strong>.
                        </p>
                    </div>
                </div>
            </section>
        </>
    )
}