import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/css/border-width";

export default function BorderWidth() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Border Width in CSS | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/css/border',
            'next': '/css/border-color'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>Border Width</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <strong>`border-width`</strong> property in CSS specifies the width of an element's border. You can set the width for all four sides of an element or individually for each side. The value can be specified in length units (such as <strong>`pixels`</strong>, <strong>`ems`</strong>, <strong>`rems`</strong>) or using predefined keyword values (<strong>`thin`</strong>, <strong>`medium`</strong>, <strong>`thick`</strong>).
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>selector {</p>
                            <p class="ml-30">    <span class="color-green">border-width</span>: value;</p>
                            <p>}</p>
                        `
                    }}></div>
                </div>
                <h5 className="mt-5 mb-3">How a Border Width Can Change a Look</h5>
                <p>
                    Here is some examples of <strong>`border-width`</strong> property.
                </p>
                <div className='outputPalateBox mb-4 mt-3' style={{'border':'none'}}>
                    <div className='outputPalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p style="border-style:solid;border-width: 5px; padding: 10px 20px;">I am a solid border, with <strong>5px</strong> border width.</p>
                            <p style="border-style:dotted;border-color:lightblue;border-width: 10px; padding: 10px 20px;">I am a dotted border, with <strong>10px</strong> border width.</p>

                            <p style="border-style:dashed;border-color:green;border-width: thin; padding: 10px 20px;">I am a dashed border, with <strong>thin</strong> border width.</p>

                            <p style="border-style:groove;border-color:red;border-width: medium; padding: 10px 20px;">I am a groove border, with <strong>medium</strong> border width.</p>

                            <p style="border-style:double;border-color:blue;border-width: thick; padding: 10px 20px;">I am a double border, with <strong>thick</strong> border width.</p>
                        `
                    }}></div>
                </div>
                <h5 className="mt-5 mb-3">How to Use Border Width</h5>
                <p>
                    Here is some examples of how to use <strong>`border-width`</strong> property in CSS.
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>&lt;style&gt;</p>
                            <p class="ml-30">    .pClassOne{</p>
                            <p class="ml-60">        <span class="color-green">border-style</span>:solid;</p>
                            <p class="ml-60">        <span class="color-green">border-width</span>: <span class="color-pink">10px;</span></p>
                            <p class="ml-60">        <span class="color-green">border-color</span>: navy;</p>
                            <p class="ml-60">        <span class="color-green">padding</span>: <span class="color-pink">10px 20px</span>;</p>
                            <p class="ml-30">    }</p>
                            <p class="ml-30">    .pClassTwo{</p>
                            <p class="ml-60">        <span class="color-green">border-style</span>:dashed;</p>
                            <p class="ml-60">        <span class="color-green">border-width</span>: <span class="color-pink">5px;</span></p>
                            <p class="ml-60">        <span class="color-green">border-color</span>: red;</p>
                            <p class="ml-60">        <span class="color-green">padding</span>: <span class="color-pink">10px 20px</span>;</p>
                            <p class="ml-30">    }</p>
                            <p class="ml-30">    .pClassThree{</p>
                            <p class="ml-60">        <span class="color-green">border-style</span>:dotted;</p>
                            <p class="ml-60">        <span class="color-green">border-width</span>: <span class="color-pink">5px;</span></p>
                            <p class="ml-60">        <span class="color-green">border-color</span>: green;</p>
                            <p class="ml-60">        <span class="color-green">padding</span>: <span class="color-pink">10px 20px</span>;</p>
                            <p class="ml-30">    }</p>
                            <p>&lt;/style&gt;</p>
                            <p>&lt;p <span class="color-pink">class</span>=<span class="color-green">"pClassOne"</span>&gt;</p>
                            <p class="ml-30">    I am a solid border, with &lt;strong&gt;10px&lt;/strong&gt; border width.</p>
                            <p>&lt;/p&gt;</p>
                            <p>&lt;p <span class="color-pink">class</span>=<span class="color-green">"pClassTwo"</span>&gt;</p>
                            <p class="ml-30">    I am a dashed border, with &lt;strong&gt;5px&lt;/strong&gt; border width.</p>
                            <p>&lt;/p&gt;</p>
                            <p>&lt;p <span class="color-pink">class</span>=<span class="color-green">"pClassThree"</span>&gt;</p>
                            <p class="ml-30">    I am a dotted border, with &lt;strong&gt;5px&lt;/strong&gt; border width.</p>
                            <p>&lt;/p&gt;</p>
                        `
                    }}></div>
                </div>
                <p>
                    Output will be the following -
                </p>
                <div className='outputPalateBox mb-4 mt-3' style={{'border':'none'}}>
                    <div className='outputPalate' dangerouslySetInnerHTML={{
                        __html: `
                        <style>
                            .pClassOne{
                                border-style:solid;
                                border-width: 10px;
                                border-color: navy;
                                padding: 10px 20px;
                            }
                            .pClassTwo{
                                border-style:dashed;
                                border-width: 5px;
                                border-color: red;
                                padding: 10px 20px;
                            }
                            .pClassThree{
                                border-style:dotted;
                                border-width: 5px;
                                border-color: green;
                                padding: 10px 20px;
                            }
                        </style>
                        <p class="pClassOne">
                            I am a solid border, with <strong>10px</strong> border width.
                        </p>
                        <p class="pClassTwo">
                            I am a dashed border, with <strong>5px</strong> border width.
                        </p>
                        <p class="pClassThree">
                            I am a dotted border, with <strong>5px</strong> border width.
                        </p>
                        `
                    }}></div>
                </div>
                <h5 className="mt-5 mb-3">Individual Border Width</h5>
                <p>
                    You can also specify individual widths for each side using:
                </p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><strong>`border-top-width`</strong></li>
                    <li><strong>`border-right-width`</strong></li>
                    <li><strong>`border-bottom-width`</strong></li>
                    <li><strong>`border-left-width`</strong></li>
                </ul>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>&lt;style&gt;</p>
                            <p class="ml-30">    .pIndivisual{</p>
                            <p class="ml-60">        <span class="color-green">border-top-width</span>: <span class="color-pink">5px</span>;</p>
                            <p class="ml-60">        <span class="color-green">border-right-width</span>: <span class="color-pink">10px;</span></p>
                            <p class="ml-60">        <span class="color-green">border-bottom-width</span>: <span class="color-pink">15px;</span></p>
                            <p class="ml-60">        <span class="color-green">border-left-width</span>: <span class="color-pink">20px;</span></p>
                            <p class="ml-60">        <span class="color-green">border-color</span>: lightgreen;</p>
                            <p class="ml-60">        <span class="color-green">border-style</span>: solid;</p>
                            <p class="ml-60">        <span class="color-green">padding</span>: <span class="color-pink">10px 20px</span>;</p>
                            <p class="ml-30">    }</p>
                            <p>&lt;/style&gt;</p>
                            <p>&lt;p <span class="color-pink">class</span>=<span class="color-green">"pIndivisual"</span>&gt;</p>
                            <p class="ml-30">    I am a &lt;strong&gt;solid&lt;/strong&gt; border. I have &lt;strong&gt;5px&lt;/strong&gt; top width, &lt;strong&gt;10px&lt;/strong&gt; right width, &lt;strong&gt;15px&lt;/strong&gt; bottom width and &lt;strong&gt;20px&lt;/strong&gt; left width.</p>
                            <p>&lt;/p&gt;</p>
                        `
                    }}></div>
                </div>
                <p>
                    Output will be the following -
                </p>
                <div className='outputPalateBox mb-4 mt-3' style={{'border':'none'}}>
                    <div className='outputPalate' dangerouslySetInnerHTML={{
                        __html: `
                            <style>
                                .pIndivisual{
                                    border-top-width: 5px;
                                    border-right-width: 10px;
                                    border-bottom-width: 15px;
                                    border-left-width: 20px;
                                    border-color: lightgreen;
                                    border-style: solid;
                                    padding: 10px 20px;
                                }
                            </style>
                            <p class="pIndivisual">
                                I am a <strong>solid</strong> border. I have <strong>5px</strong> top width, <strong>10px</strong> right width, <strong>15px</strong> bottom width and <strong>20px</strong> left width.
                            </p>
                        `
                    }}></div>
                </div>

                <p>
                    You can even set multiple side width in a single <strong>`border-width`</strong> property. It has to follow the order. Order will be <strong>`top`</strong>, <strong>`right`</strong>, <strong>`bottom`</strong>, <strong>`left`</strong>. Syntax will be the following - 
                </p>

                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>&lt;style&gt;</p>
                            <p class="ml-30">    .pIndivisual{</p>
                            <p class="ml-60">        <span class="color-green">border-width</span>: <span class="color-pink">5px 10px 15px 20px</span>;</p>
                            <p class="ml-60">        <span class="color-green">border-color</span>: lightgreen;</p>
                            <p class="ml-60">        <span class="color-green">border-style</span>: solid;</p>
                            <p class="ml-60">        <span class="color-green">padding</span>: <span class="color-pink">10px 20px</span>;</p>
                            <p class="ml-30">    }</p>
                            <p>&lt;/style&gt;</p>
                            <p>&lt;p <span class="color-pink">class</span>=<span class="color-green">"pIndivisual"</span>&gt;</p>
                            <p class="ml-30">    I am a &lt;strong&gt;solid&lt;/strong&gt; border. I have &lt;strong&gt;5px&lt;/strong&gt; top width, &lt;strong&gt;10px&lt;/strong&gt; right width, &lt;strong&gt;15px&lt;/strong&gt; bottom width and &lt;strong&gt;20px&lt;/strong&gt; left width.</p>
                            <p>&lt;/p&gt;</p>
                        `
                    }}></div>
                </div>
                <p>
                    Output will be the same as above -
                </p>
                <div className='outputPalateBox mb-4 mt-3' style={{'border':'none'}}>
                    <div className='outputPalate' dangerouslySetInnerHTML={{
                        __html: `
                            <style>
                                .pIndivisual{
                                    border-width: 5px 10px 15px 20px;
                                    border-color: lightgreen;
                                    border-style: solid;
                                    padding: 10px 20px;
                                }
                            </style>
                            <p class="pIndivisual">
                                I am a <strong>solid</strong> border. I have <strong>5px</strong> top width, <strong>10px</strong> right width, <strong>15px</strong> bottom width and <strong>20px</strong> left width.
                            </p>
                        `
                    }}></div>
                </div>
            </div>
        </section>
    )
}