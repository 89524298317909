import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/mysql/update-query";

export default function MysqlUpdateQuery() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Mysql - Update Query | Aspirant's Home");
        const urls = {
            'previous': '/mysql/select-query',
            'next': '/mysql/delete-query'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>Mysql - Update Query</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <strong>`UPDATE`</strong> operation on any table updates one or more records, which are already available. You can update the values of existing records in MySQL using the <strong>`UPDATE`</strong> statement. To update specific rows, you need to use the WHERE clause along with it.
                </p>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-blue">UPDATE</span> table_name</p>
                            <p><span class="color-blue">SET</span> column1 = value1, column2 = value2, ...</p>
                            <p><span class="color-blue">WHERE condition</span>;</p>
                        `
                    }}></div>
                </div>
                <p>
                    <strong>`table_name`</strong> is the name of the table you want to update. <strong>`column1, column2, ...`</strong> are the columns you want to update. <strong>`value1, value2, ...`</strong> are the new values you want to set for the columns. <strong>`condition`</strong> are an optional condition to specify which rows to update. If omitted, all rows in the table will be updated.
                </p>
                <p>
                    Suppose, we have a <strong>`users`</strong> table like below -
                </p>
                <table className='table' style={{border: '1px dashed #ccc'}}> 
                    <thead>
                        <tr style={{border: '1px dashed #ccc'}}>
                            <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>id</th>
                            <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>name</th>
                            <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>age</th>
                            <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>Address</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr style={{border: '1px dashed #ccc'}}>
                            <td style={{border: '1px dashed #ccc'}}>1</td>
                            <td style={{border: '1px dashed #ccc'}}>Jane Smith</td>
                            <td style={{border: '1px dashed #ccc'}}>25</td>
                            <td style={{border: '1px dashed #ccc'}}>Apt. 415 9295 Anh Harbor, South Junior, FL 51309-5804</td>
                        </tr>
                        <tr style={{border: '1px dashed #ccc'}}>
                            <td style={{border: '1px dashed #ccc'}}>2</td>
                            <td style={{border: '1px dashed #ccc'}}>John Doe</td>
                            <td style={{border: '1px dashed #ccc'}}>35</td>
                            <td style={{border: '1px dashed #ccc'}}>Suite 977 83408 Murray Lakes, Lakinchester, WV 60583</td>
                        </tr>
                        <tr style={{border: '1px dashed #ccc'}}>
                            <td style={{border: '1px dashed #ccc'}}>3</td>
                            <td style={{border: '1px dashed #ccc'}}>Johny Smith</td>
                            <td style={{border: '1px dashed #ccc'}}>39</td>
                            <td style={{border: '1px dashed #ccc'}}>3797 Benton Locks, Weissnatside, MS 54762-5660</td>
                        </tr>
                        <tr style={{border: '1px dashed #ccc'}}>
                            <td style={{border: '1px dashed #ccc'}}>4</td>
                            <td style={{border: '1px dashed #ccc'}}>Jeffer Joe</td>
                            <td style={{border: '1px dashed #ccc'}}>28</td>
                            <td style={{border: '1px dashed #ccc'}}>Suite 715 6587 Champlin Freeway, Port Angelhaven, PA 42789</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className='mt-5 mb-5'>
                <h5>Update Columns With Conditions</h5>
                <div className='mt-2 mb-2'>
                    <p>
                        You can update multiple columns using comma separator in <strong>`UPDATE`</strong> statement. Here is the example given below -
                    </p>
                    <p>
                        Suppose, I want to update age and address of an user whoose name is "John Doe". Then the required query will be the following -
                    </p>
                    <div className='codePalateBox mt-2 mb-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                                <p><span class="color-blue">UPDATE</span> users <span class="color-blue">SET</span> age = <span class="color-pink">40</span>, address = <span class="color-green">"641 Eloise Fall, South Bradlyhaven, MT 53320"</span> <span class="color-blue">WHERE</span> name = <span class="color-green">'John Doe'</span>;</p>
                            `
                        }}></div>
                    </div>
                    <p>
                        The Output of the query will be the following -
                    </p>
                    <table className='table' style={{border: '1px dashed #ccc'}}> 
                        <thead>
                            <tr style={{border: '1px dashed #ccc'}}>
                                <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>id</th>
                                <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>name</th>
                                <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>age</th>
                                <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>Address</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr style={{border: '1px dashed #ccc'}}>
                                <td style={{border: '1px dashed #ccc',borderBottom: '2px dashed #E2950C'}}>1</td>
                                <td style={{border: '1px dashed #ccc',borderBottom: '2px dashed #E2950C'}}>Jane Smith</td>
                                <td style={{border: '1px dashed #ccc',borderBottom: '2px dashed #E2950C'}}>25</td>
                                <td style={{border: '1px dashed #ccc',borderBottom: '2px dashed #E2950C'}}>Apt. 415 9295 Anh Harbor, South Junior, FL 51309-5804</td>
                            </tr>
                            <tr style={{border: '1px dashed #ccc'}}>
                                <td style={{border: '2px dashed #E2950C'}}>2</td>
                                <td style={{border: '2px dashed #E2950C'}}>John Doe</td>
                                <td style={{border: '2px dashed #E2950C'}}>40</td>
                                <td style={{border: '2px dashed #E2950C'}}>641 Eloise Fall, South Bradlyhaven, MT 53320</td>
                            </tr>
                            <tr style={{border: '1px dashed #ccc'}}>
                                <td style={{border: '1px dashed #ccc'}}>3</td>
                                <td style={{border: '1px dashed #ccc'}}>Johny Smith</td>
                                <td style={{border: '1px dashed #ccc'}}>39</td>
                                <td style={{border: '1px dashed #ccc'}}>3797 Benton Locks, Weissnatside, MS 54762-5660</td>
                            </tr>
                            <tr style={{border: '1px dashed #ccc'}}>
                                <td style={{border: '1px dashed #ccc'}}>4</td>
                                <td style={{border: '1px dashed #ccc'}}>Jeffer Joe</td>
                                <td style={{border: '1px dashed #ccc'}}>28</td>
                                <td style={{border: '1px dashed #ccc'}}>Suite 715 6587 Champlin Freeway, Port Angelhaven, PA 42789</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </section>
    )
}