import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/mongo-db/insert-document";

export default function MongoInsertDocument() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Insert Document in MongoDB | A Complete Guide");
        title.setKeyWords("insert document in mongodb, insert document in mongodb example, how to insert document in mongodb collection, insert document mongodb array, insert a document in mongodb, mongodb insert document example");
        title.setPageDescription("To insert a document into a MongoDB collection, you can use the `insertOne` or `insertMany` methods. Below are examples of how to use these methods:");

        const urls = {
            'previous': '/mongo-db/data-types',
            'next': '/mongo-db/query-document'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>MongoDB - Insert Document</h3>
            <div className='mt-4 mb-5'>
                <p>
                    To insert a document into a MongoDB collection, you can use the <strong>`insertOne`</strong> or <strong>`insertMany`</strong> methods. Below are examples of how to use these methods:
                </p>
                <h5 className='mt-5 mb-4'>Using `insertMany` Method</h5>
                <p>
                    The <strong>`insertMany`</strong> method is used to insert multiple documents into a collection. Here is an example -
                </p>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>db.myCollection.insertMany([</p>
                            <p>    {</p>
                            <p style="margin-left:30px">        name: <span class="color-green">"John Doe"</span>,</p>
                            <p style="margin-left:30px">        age: <span class="color-pink">29</span>,</p>
                            <p style="margin-left:30px">        height: <span class="color-pink">5.9</span>,</p>
                            <p style="margin-left:30px">        isActive: <span class="color-blue">true</span>,</p>
                            <p style="margin-left:30px">        birthdate: ISODate(<span class="color-green">"1993-05-01T00:00:00Z"</span>),</p>
                            <p style="margin-left:30px">        hobbies: [<span class="color-green">"reading"</span>, <span class="color-green">"traveling"</span>, <span class="color-green">"swimming"</span>],</p>
                            <p style="margin-left:30px">        address: {</p>
                            <p style="margin-left:60px">            street: <span class="color-green">"123 Main St"</span>,</p>
                            <p style="margin-left:60px">            city: <span class="color-green">"Anytown"</span>,</p>
                            <p style="margin-left:60px">            state: <span class="color-green">"CA"</span></p>
                            <p style="margin-left:30px">        }</p>
                            <p>    },</p>
                            <p>    {</p>
                            <p style="margin-left:30px">        name: <span class="color-green">"Jane Smith"</span>,</p>
                            <p style="margin-left:30px">        age: <span class="color-pink">34</span>,</p>
                            <p style="margin-left:30px">        height: <span class="color-pink">5.5</span>,</p>
                            <p style="margin-left:30px">        isActive: <span class="color-blue">false</span>,</p>
                            <p style="margin-left:30px">        birthdate: ISODate(<span class="color-green">"1988-11-15T00:00:00Z"</span>),</p>
                            <p style="margin-left:30px">        hobbies: [<span class="color-green">"cooking"</span>, <span class="color-green">"cycling"</span>],</p>
                            <p style="margin-left:30px">        address: {</p>
                            <p style="margin-left:60px">            street: <span class="color-green">"456 Elm St"</span>,</p>
                            <p style="margin-left:60px">            city: <span class="color-green">"Othertown"</span>,</p>
                            <p style="margin-left:60px">            state: <span class="color-green">"NY"</span></p>
                            <p style="margin-left:30px">        }</p>
                            <p>    }</p>
                            <p>])</p>
                        `
                    }}></div>
                </div>
                <p>
                    Where, <strong>`db`</strong> refers to the current database in the MongoDB shell. <strong>`myCollection`</strong> is the name of the collection where the document will be inserted. If the collection does not exist, MongoDB will create it. <strong>`insertMany`</strong> is the method used to insert a multiple documents into the collection.
                </p>
                <h5 className='mt-5 mb-4'>Using `insertOne` Method</h5>
                <p>
                    The <strong>`insertOne`</strong> method is used to insert single document into a collection. Here is an example -
                </p>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>db.myCollection.insertOne({</p>
                            <p style="margin-left:30px">        name: <span class="color-green">"John Doe"</span>,</p>
                            <p style="margin-left:30px">        age: <span class="color-pink">29</span>,</p>
                            <p style="margin-left:30px">        height: <span class="color-pink">5.9</span>,</p>
                            <p style="margin-left:30px">        isActive: <span class="color-blue">true</span>,</p>
                            <p style="margin-left:30px">        birthdate: ISODate(<span class="color-green">"1993-05-01T00:00:00Z"</span>),</p>
                            <p style="margin-left:30px">        hobbies: [<span class="color-green">"reading"</span>, <span class="color-green">"traveling"</span>, <span class="color-green">"swimming"</span>],</p>
                            <p style="margin-left:30px">        address: {</p>
                            <p style="margin-left:60px">            street: <span class="color-green">"123 Main St"</span>,</p>
                            <p style="margin-left:60px">            city: <span class="color-green">"Anytown"</span>,</p>
                            <p style="margin-left:60px">            state: <span class="color-green">"CA"</span></p>
                            <p style="margin-left:30px">        }</p>
                            <p>    })</p>
                        `
                    }}></div>
                </div>
                <p>
                    Where, <strong>`db`</strong> refers to the current database in the MongoDB shell. <strong>`myCollection`</strong> is the name of the collection where the document will be inserted. If the collection does not exist, MongoDB will create it. <strong>`insertOne`</strong> is the method used to insert a single document into the collection.
                </p>
            </div>
        </section>
    )
}