import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/html/inputs";

export default function HTMLInputs() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("HTML - Inputs | Aspirant's Home");
        const urls = {
            'previous': '/html/form-elements',
            'next': '/html/input-attributes'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>HTML - Inputs</h3>
            <div className='mt-4 mb-5'>
                <p>
                    HTML provides a variety of <strong>`&lt;input&gt;`</strong> types to handle different kinds of user input. Here’s a comprehensive list of the different input types, along with a brief explanation of each:
                </p>
                <h5 className='mt-5 mb-4'>Text Input</h5>
                <p>
                    The HTML <strong>`&lt;input type="text"&gt;`</strong> element is used to create a single-line text input field, where users can enter text data. This element is one of the most common and versatile form elements, providing a way for users to input data that can then be sent to a server or processed on the client side.
                </p>
                <h6 className='mb-3'>Basic Syntax</h6>
                <p>
                    Here is a basic syntax of how to write text input element.
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>&lt;input <span class="color-pink">type</span>=<span class="color-green">"text"</span> <span class="color-pink">name</span>=<span class="color-green">"username"</span>&gt;</p>
                        `
                    }}></div>
                </div>
                <h5 className='mt-5 mb-4'>Password Input</h5>
                <p>
                    The HTML <strong>`&lt;input type="password"&gt;`</strong> element is used to create a single-line text input field where users can enter a password. The characters entered in this field are obscured (typically replaced with dots or asterisks) to protect the user's privacy.
                </p>
                <h6 className='mb-3'>Basic Syntax</h6>
                <p>
                    Here is a basic syntax of how to write password input element.
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>&lt;input <span class="color-pink">type</span>=<span class="color-green">"password"</span> <span class="color-pink">name</span>=<span class="color-green">"password"</span>&gt;</p>
                        `
                    }}></div>
                </div>
                <h5 className='mt-5 mb-4'>Email Input</h5>
                <p>
                    The HTML <strong>`&lt;input type="email"&gt;`</strong> element is used to create a field for entering an email address. This input type ensures that the entered data is in the correct email format (e.g., <strong>`user@example.com`</strong>). It provides built-in validation to check the format of the input, helping to ensure that users enter valid email addresses.
                </p>
                <h6 className='mb-3'>Basic Syntax</h6>
                <p>
                    Here is a basic syntax of how to write email input element.
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>&lt;input <span class="color-pink">type</span>=<span class="color-green">"email"</span> <span class="color-pink">name</span>=<span class="color-green">"email"</span>&gt;</p>
                        `
                    }}></div>
                </div>
                <h5 className='mt-5 mb-4'>Input Type Submit</h5>
                <p>
                    The HTML <strong>`&lt;input type="submit"&gt;`</strong> element is used to create a button that submits a form. When clicked, it sends the form data to the server specified in the form's action attribute using the method specified in the method attribute (typically "GET" or "POST").
                </p>
                <h6 className='mb-3'>Basic Example</h6>
                <p>
                    Here is a basic example of how to write how a submit button works.
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>&lt;form <span class="color-pink">action</span>=<span class="color-green">"/page_to_submit_data.php"</span> <span class="color-pink">method</span>=<span class="color-green">"post"</span>&gt;</p>
                            <p class="ml-30">    &lt;label <span class="color-pink">for</span>=<span class="color-green">"name"</span>&gt;Name:&lt;/label&gt;</p>
                            <p class="ml-30">    &lt;input <span class="color-pink">type</span>=<span class="color-green">"text"</span> <span class="color-pink">id</span>=<span class="color-green">"name"</span> <span class="color-pink">name</span>=<span class="color-green">"name"</span> <span class="color-pink">value</span>=<span class="color-green">"John"</span>&gt;</p>
                            <p class="ml-30">    &lt;input <span class="color-pink">type</span>=<span class="color-green">"submit"</span> <span class="color-pink">value</span>=<span class="color-green">"Submit"</span>&gt;</p>
                            <p>&lt;/form&gt;</p>
                        `
                    }}></div>
                </div>
                <p>
                    Form will be looks like the following -
                </p>
                <div className='outputPalateBox mb-4' style={{border:'none'}}>
                    <div className='outputPalate' dangerouslySetInnerHTML={{
                        __html: `
                            <form method="post">
                                <label for="name">Name:</label>
                                <input type="text" id="name" name="name" value="John">
                                <input type="submit" value="Submit">
                            </form>
                        `
                    }}></div>
                </div>
                <h5 className='mt-5 mb-4'>Input Type Button</h5>
                <p>
                    The HTML <strong>`&lt;input type="button"&gt;`</strong> element is used to create a clickable button that can be programmed to perform a specific task when clicked. Unlike the <strong>`&lt;input type="submit"&gt;`</strong> element, which submits a form, the <strong>`&lt;input type="button"&gt;`</strong> does not have any default behavior and relies on JavaScript to define its functionality.
                </p>
                <div className='mt-2 mb-3'>
                    <input type='button' value="Click Me"/>
                </div>
                <h6 className='mb-3'>Basic Syntax</h6>
                <p>
                    Here is a basic syntax of how to write input type button element.
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>&lt;input <span class="color-pink">type</span>=<span class="color-green">"button"</span> <span class="color-pink">value</span>=<span class="color-green">"Click Me"</span>&gt;</p>
                        `
                    }}></div>
                </div>
                <h5 className='mt-5 mb-4'>Checkbox</h5>
                <p>
                    The HTML <strong>`&lt;input type="checkbox"&gt;`</strong> element is used to create a checkbox, a GUI widget that allows the user to make a binary choice (i.e., a choice between one of two possible mutually exclusive options). A checkbox can be either checked or unchecked. Checkboxes are often used in forms to allow users to select multiple options from a list.
                </p>
                <h6 className='mb-3'>Basic Syntax</h6>
                <p>
                    Here is a basic syntax of how to write input type checkbox element.
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>&lt;input <span class="color-pink">type</span>=<span class="color-green">"checkbox"</span> <span class="color-pink">name</span>=<span class="color-green">"option1"</span>&gt;</p>
                        `
                    }}></div>
                </div>
                <h6 className='mb-3'>Basic Example</h6>
                <p>
                    Here is a basic example of how to use input type checkbox element.
                </p>
                <div className='outputPalateBox mb-4' style={{border:'none'}}>
                    <div className='outputPalate' dangerouslySetInnerHTML={{
                        __html: `
                            <form>
                                <input type="checkbox" id="javascript" name="javascript" value="Bike">
                                <label for="javascript"> I know Javascript</label><br>
                                <input type="checkbox" id="html" name="html" value="Car">
                                <label for="html"> I know HTML</label><br>
                                <input type="checkbox" id="css" name="css" value="Boat">
                                <label for="css"> I know CSS</label>
                            </form>
                        `
                    }}></div>
                </div>
                <h5 className='mt-5 mb-4'>Radio Button</h5>
                <p>
                    The HTML <strong>`&lt;input type="radio"&gt;`</strong> element is used to create radio buttons. Radio buttons allow the user to select one option from a set of mutually exclusive choices. Only one radio button in a group can be selected at any given time.
                </p>
                <h6 className='mb-3'>Basic Syntax</h6>
                <p>
                    Here is a basic syntax of how to write input type radio button element.
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>&lt;input <span class="color-pink">type</span>=<span class="color-green">"radio"</span> <span class="color-pink">name</span>=<span class="color-green">"group1"</span> <span class="color-pink">value</span>=<span class="color-green">"Option1"</span>&gt;</p>
                        `
                    }}></div>
                </div>
                <h6 className='mb-3'>Basic Example</h6>
                <p>
                    Here is a basic example of how to use input type radio button element.
                </p>
                <div className='outputPalateBox mb-4' style={{border:'none'}}>
                    <div className='outputPalate' dangerouslySetInnerHTML={{
                        __html: `
                        <form method="post">
                            <h6>Choose Your Gender</h6>
                            <label>
                                <input type="radio" name="gender" value="male" checked> Male
                            </label><br>
                            
                            <label>
                                <input type="radio" name="gender" value="female"> Female
                            </label><br>
                            
                            <label>
                                <input type="radio" name="gender" value="other"> Other
                            </label><br><br>
                            
                            <input type="submit" value="Submit">
                        </form>
                        `
                    }}></div>
                </div>
                <h5 className='mt-5 mb-4'>Input Type Color</h5>
                <p>
                    The HTML <strong>`&lt;input type="color"&gt;`</strong> element is used to create a color picker that allows users to select a color. When clicked, it opens a color picker interface, typically provided by the browser, allowing the user to choose a color. The selected color is returned as a hexadecimal color code.
                </p>
                <h6 className='mb-3'>Basic Syntax</h6>
                <p>
                    Here is a basic syntax of how to write input type color element.
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>&lt;input <span class="color-pink">type</span>=<span class="color-green">"color"</span> <span class="color-pink">name</span>=<span class="color-green">"favcolor"</span>&gt;</p>
                        `
                    }}></div>
                </div>
                <h6 className='mb-3'>Basic Example</h6>
                <p>
                    Here is a basic example of how to use input type color element.
                </p>
                <div className='outputPalateBox mb-4' style={{border:'none'}}>
                    <div className='outputPalate' dangerouslySetInnerHTML={{
                        __html: `
                            <label for="favcolor">Choose your favorite color:</label>
                            <input type="color" id="favcolor" name="favcolor" value="#ff0000">
                        `
                    }}></div>
                </div>
                <h5 className='mt-5 mb-4'>Input Type Date</h5>
                <p>
                    The HTML <strong>`&lt;input type="date"&gt;`</strong> element is used to create a date picker input that allows users to select a date. When clicked, it opens a calendar interface, typically provided by the browser, allowing the user to choose a date. The selected date is returned in the DD/MM/YYYY format.
                </p>
                <h6 className='mb-3'>Basic Syntax</h6>
                <p>
                    Here is a basic syntax of how to write input type date element.
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>&lt;input <span class="color-pink">type</span>=<span class="color-green">"date"</span> <span class="color-pink">name</span>=<span class="color-green">"birthdate"</span>&gt;</p>
                        `
                    }}></div>
                </div>
                <h6 className='mb-3'>Basic Example</h6>
                <p>
                    Here is a basic example of how to use input type date element.
                </p>
                <div className='outputPalateBox mb-4' style={{border:'none'}}>
                    <div className='outputPalate' dangerouslySetInnerHTML={{
                        __html: `
                            <label for="birthdate">Select your birthdate:</label>
                            <input type="date" id="birthdate" name="birthdate" required>
                        `
                    }}></div>
                </div>
                <h5 className='mt-5 mb-4'>Input Type Image</h5>
                <p>
                    The HTML <strong>`&lt;input type="image"&gt;`</strong> element is used to create an image button within a form. When clicked, it submits the form just like a regular submit button, but it uses an image instead of text. This type of input is useful when you want to use a custom image for form submission, such as for a graphical button.
                </p>
                <h6 className='mb-3'>Basic Syntax</h6>
                <p>
                    Here is a basic syntax of how to write input type image element.
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>&lt;input <span class="color-pink">type</span>=<span class="color-green">"image"</span> <span class="color-pink">src</span>=<span class="color-green">"image-url"</span> <span class="color-pink">alt</span>=<span class="color-green">"Description"</span>&gt;</p>
                        `
                    }}></div>
                </div>
                <h6 className='mb-3'>Basic Example</h6>
                <p>
                    Here is a basic example of how to use input type image element.
                </p>
                <div className='outputPalateBox mb-4' style={{border:'none'}}>
                    <div className='outputPalate' dangerouslySetInnerHTML={{
                        __html: `
                        <label>Username:</label>
                        <input type="text" name="username" placeholder="Enter your username"><br><br>
                        <label>Password:</label>
                        <input type="password" name="password" placeholder="Enter your password"><br><br>
                        <input type="image" src="../assets/submit-button.png" style="width:70px" alt="Submit Form">
                        `
                    }}></div>
                </div>
                <h5 className='mt-5 mb-4'>Input Type File</h5>
                <p>
                    The HTML <strong>`&lt;input type="file"&gt;`</strong> element is used to create a file-select control, which allows users to browse their local filesystem and select a file to upload. This element is commonly used in forms where file uploads are required, such as profile picture uploads, document submissions, or any other scenario where a file needs to be transferred from the user's computer to the server.
                </p>
                <h6 className='mb-3'>Basic Syntax</h6>
                <p>
                    Here is a basic syntax of how to write input type file element.
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>&lt;input <span class="color-pink">type</span>=<span class="color-green">"file"</span> <span class="color-pink">name</span>=<span class="color-green">"fileUpload"</span>&gt;</p>
                        `
                    }}></div>
                </div>
                <h6 className='mb-3'>Basic Example</h6>
                <p>
                    Here is a basic example of how to use input type file element.
                </p>
                <div className='outputPalateBox mb-4' style={{border:'none'}}>
                    <div className='outputPalate' dangerouslySetInnerHTML={{
                        __html: `
                            <label for="fileUpload">Choose a file:</label>
                            <input type="file" id="fileUpload" name="fileUpload" required>
                        `
                    }}></div>
                </div>
                <h5 className='mt-5 mb-4'>Input Type Hidden</h5>
                <p>
                    The HTML <strong>`&lt;input type="hidden"&gt;`</strong> element is used to create an input field that is not displayed to the user. This type of input is useful for storing data that needs to be sent with the form submission but does not need to be seen or interacted with by the user, such as a token, user ID, or other backend-related data.
                </p>
                <h6 className='mb-3'>Basic Syntax</h6>
                <p>
                    Here is a basic syntax of how to write input type file element.
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>&lt;input <span class="color-pink">type</span>=<span class="color-green">"hidden"</span> <span class="color-pink">name</span>=<span class="color-green">"hiddenField"</span> <span class="color-pink">value</span>=<span class="color-green">"hiddenValue"</span>&gt;</p>
                        `
                    }}></div>
                </div>
                <h5 className='mt-5 mb-4'>Input Type Month</h5>
                <p>
                    The HTML <strong>`&lt;input type="month"&gt;`</strong> element is used to create a month and year input field, allowing users to select a specific month and year from a drop-down menu. This is particularly useful for forms where you need users to provide a date in the "month-year" format, such as for credit card expiration dates, subscription periods, or project timelines.
                </p>
                <h6 className='mb-3'>Basic Syntax</h6>
                <p>
                    Here is a basic syntax of how to write input type month element.
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>&lt;input <span class="color-pink">type</span>=<span class="color-green">"month"</span> <span class="color-pink">name</span>=<span class="color-green">"monthInput"</span>&gt;</p>
                        `
                    }}></div>
                </div>
                <h6 className='mb-3'>Basic Example</h6>
                <p>
                    Here is a basic example of how to use input type month element.
                </p>
                <div className='outputPalateBox mb-4' style={{border:'none'}}>
                    <div className='outputPalate' dangerouslySetInnerHTML={{
                        __html: `
                        <label for="monthInput">Select a month:</label>
                        <input type="month" id="monthInput" name="monthInput" required>
                        `
                    }}></div>
                </div>
                <h5 className='mt-5 mb-4'>Input Type Number</h5>
                <p>
                    The HTML <strong>`&lt;input type="number"&gt;`</strong> element is used to create a numeric input field, allowing users to enter and edit a number. This type of input is useful for forms that require numeric values such as age, quantity, or any other numerical input.
                </p>
                <h6 className='mb-3'>Basic Syntax</h6>
                <p>
                    Here is a basic syntax of how to write input type number element.
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>&lt;input <span class="color-pink">type</span>=<span class="color-green">"number"</span> <span class="color-pink">name</span>=<span class="color-green">"quantity"</span>  <span class="color-pink">min</span>=<span class="color-green">"1"</span> <span class="color-pink">max</span>=<span class="color-green">"10"</span> <span class="color-pink">step</span>=<span class="color-green">"1"</span> &gt;</p>
                        `
                    }}></div>
                </div>
                <h6 className='mb-3'>Attributes</h6>
                <ul style={{ listStyle: 'disc' }}>
                    <li>
                        <strong>`min`</strong> : Specifies the minimum allowable value for the input field.
                    </li>
                    <li>
                        <strong>`max`</strong> : Specifies the maximum allowable value for the input field.
                    </li>
                    <li>
                        <strong>`step`</strong> : Specifies the increment by which the value can be adjusted. The default is <strong>`1`</strong>.
                    </li>
                </ul>
                <h6 className='mb-3'>Basic Example</h6>
                <p>
                    Here is a basic example of how to use input type number element.
                </p>
                <div className='outputPalateBox mb-4' style={{border:'none'}}>
                    <div className='outputPalate' dangerouslySetInnerHTML={{
                        __html: `
                        <label for="quantity">Quantity:</label>
                        <input type="number" id="quantity" name="quantity" min="1" max="10" step="1" value="1" required>
                        `
                    }}></div>
                </div>
                <h5 className='mt-5 mb-4'>Input Type Range</h5>
                <p>
                    The HTML <strong>`&lt;input type="range"&gt;`</strong> element creates a slider control for selecting a value from a defined range. This type of input is useful for scenarios where you want users to select a value visually from a continuous range, such as setting volume, brightness, or any other numerical value within a specified range.
                </p>
                <h6 className='mb-3'>Basic Syntax</h6>
                <p>
                    Here is a basic syntax of how to write input type range element.
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>&lt;input <span class="color-pink">type</span>=<span class="color-green">"range"</span> <span class="color-pink">name</span>=<span class="color-green">"slider"</span>&gt;</p>
                        `
                    }}></div>
                </div>
                <h6 className='mb-3'>Attributes</h6>
                <ul style={{ listStyle: 'disc' }}>
                    <li>
                        <strong>`min`</strong> : Specifies the minimum allowable value for the input field.
                    </li>
                    <li>
                        <strong>`max`</strong> : Specifies the maximum allowable value for the input field.
                    </li>
                    <li>
                        <strong>`step`</strong> : Specifies the increment by which the value can be adjusted. The default is <strong>`1`</strong>.
                    </li>
                </ul>
                <h6 className='mb-3'>Basic Example</h6>
                <p>
                    Here is a basic example of how to use input type range element.
                </p>
                <div className='outputPalateBox mb-4' style={{border:'none'}}>
                    <div className='outputPalate' dangerouslySetInnerHTML={{
                        __html: `
                        <label for="slider">Select a value:</label>
                        <input type="range" id="slider" name="slider" min="0" max="100" step="1" value="50" required>
                        `
                    }}></div>
                </div>
            </div>
        </section>
    )
}