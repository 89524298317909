import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../../Context";
import { Link } from 'react-router-dom';
export const pageUrl = () => "/mysql/date-and-time-functions/curdate";

export default function CurDate() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Mysql - Date and Time functions - CURDATE() | Aspirant's Home");
        path.setPreviousNext({});
    }, [])

    return (
        <>
            <section class='clearfix mb-3'>
                <span className='float-start'>
                    <Link to='/mysql/date-and-time-functions' onClick={() => path.setPathName('/mysql/date-and-time-functions')} className='btn btn-primary'> <i class="fa-solid fa-less-than"></i>&nbsp; Back to Date and Time functions </Link>
                </span>
            </section>
            <section className='mt-5 mb-5'>
                <h3>Mysql - CURDATE() Functions</h3>
                <div className='mt-4 mb-5'>
                    <p>
                        The <strong>`CURDATE()`</strong> function in MySQL is used to get the current date. It returns the current date in <strong>`'YYYY-MM-DD'`</strong> format. Here's how you can use it:
                    </p>
                    <div className='codePalateBox mt-2 mb-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                                <p><span class="color-blue">SELECT</span> CURDATE();</p>
                            `
                        }}></div>
                    </div>
                    <p>
                        This will return the current date, for example, <strong>`'2024-05-14'`</strong>.
                    </p>
                    <p>
                        You can also use <strong>`CURDATE()`</strong> in INSERT or UPDATE statements to insert or update the current date in a table:
                    </p>
                    <div className='codePalateBox mt-2 mb-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                                <p><span class="color-blue">INSERT INTO</span> table_name (date_column) <span class="color-blue">VALUES</span> (CURDATE());</p>
                            `
                        }}></div>
                    </div>
                    <p>
                        This will insert the current date into the <strong>`date_column`</strong> of <strong>`table_name`</strong>.
                    </p>
                    <p>
                        Similarly, you can use <strong>`CURDATE()`</strong> in an UPDATE statement to update the <strong>`date_column`</strong> to the <strong>`current date`</strong>:
                    </p>
                    <div className='codePalateBox mt-2 mb-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                                <p><span class="color-blue">UPDATE</span> table_name <span class="color-blue">SET</span> date_column = CURDATE() <span class="color-blue">WHERE</span> id = <span class="color-pink">1</span>;</p>
                            `
                        }}></div>
                    </div>
                    <p>
                        This will update the <strong>`date_column`</strong> to the current date for the row with <strong>`id`</strong> 1 in <strong>`table_name`</strong>.
                    </p>
                </div>
            </section>
        </>
    )
}