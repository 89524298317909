import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/mysql/stored-functions";

export default function MysqlStoredFunctions() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Mysql - Stored Functions | Aspirant's Home");
        const urls = {
            'previous': '/mysql/stored-procedure',
            'next': '/mysql/case-function'
        }
        path.setPreviousNext(urls);

    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>Mysql - Stored Functions</h3>
            <div className='mt-4 mb-5'>
                <p>
                    A stored function in MySQL is a reusable SQL code that you can use to perform calculations or operations and return a single value. Stored functions are created with the <strong>`CREATE FUNCTION`</strong> statement and can be invoked within SQL queries, just like built-in functions.
                </p>
                <div className='mt-5 mb-5'>
                    <h5>Key Characteristics of Stored Functions</h5>
                    <div className='mt-3 mb-5'>
                        <ul style={{listStyle:'decimal'}}>
                            <li>
                                <strong>Single Return Value:</strong> A stored function returns a single value.
                            </li>
                            <li>
                                <strong>Parameters:</strong> You can pass parameters to the function to customize its operation.
                            </li>
                            <li>
                                <strong>Reusable:</strong> Once defined, a function can be reused in multiple queries.
                            </li>
                            <li>
                                <strong>Encapsulation:</strong> Encapsulates complex calculations or operations in a single unit.
                            </li>
                        </ul>
                    </div>
                </div>
                <div className='mt-5 mb-5'>
                    <h5>Syntax</h5>
                    <div className='mt-3 mb-5'>
                        <p>Here is the syntax - </p>
                        <div className='codePalateBox mt-2 mb-2'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-blue">CREATE FUNCTION</span> function_name ([parameters])</p>
                                    <p><span class="color-blue">RETURNS</span> return_data_type</p>
                                    <p><span class="color-blue">BEGIN</span></p>
                                    <p style="margin-left:30px">    <span class="color-grey">-- function body</span></p>
                                    <p style="margin-left:30px">    <span class="color-blue">RETURN</span> return_value;</p>
                                    <p><span class="color-blue">END</span>;</p>
                                `
                            }}></div>
                        </div>
                        <p className='mt-4'>Where,</p>
                        <ul>
                            <li><strong>function_name:</strong> Name of the function.</li>
                            <li><strong>parameters:</strong> List of input parameters and their data types.</li> 
                            <li><strong>return_data_type:</strong> Data type of the value returned by the function.</li>
                            <li><strong>BEGIN ... END:</strong> Block containing the function logic.</li>
                            <li><strong>RETURN:</strong> Statement to return the function's result.</li>
                        </ul>
                    </div>
                </div>
                <div className='mt-5 mb-5'>
                    <h5>Example</h5>
                    <div className='mt-3 mb-5'>
                        <p>
                            Suppose, we have an table <strong>`employees`</strong> with the below data and structure.
                        </p>
                        <table className='table' style={{border: '1px dashed #ccc'}}> 
                            <thead>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>id</th>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>first_name</th>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>last_name</th>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>monthly_salary</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>1</td>
                                    <td style={{border: '1px dashed #ccc'}}>John</td>
                                    <td style={{border: '1px dashed #ccc'}}>Doe</td>
                                    <td style={{border: '1px dashed #ccc'}}>4000.00</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>2</td>
                                    <td style={{border: '1px dashed #ccc'}}>Jane</td>
                                    <td style={{border: '1px dashed #ccc'}}>Smith</td>
                                    <td style={{border: '1px dashed #ccc'}}>4500.00</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>3</td>
                                    <td style={{border: '1px dashed #ccc'}}>Alice</td>
                                    <td style={{border: '1px dashed #ccc'}}>Johnson</td>
                                    <td style={{border: '1px dashed #ccc'}}>5000.00</td>
                                </tr>
                            </tbody>
                        </table>
                        <p>
                            Let's create a stored function that calculates the annual salary of an employee based on their monthly salary.
                        </p>
                        <div className='codePalateBox mt-2 mb-2'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p>DELIMITER //</p>
                                    <br />
                                    <p><span class="color-blue">CREATE FUNCTION</span> calculate_annual_salary(monthly_salary <span class="color-pink">DECIMAL</span>(<span class="color-pink">10</span>, <span class="color-pink">2</span>))</p>
                                    <p><span class="color-blue">RETURNS</span> <span class="color-pink">DECIMAL</span>(<span class="color-pink">10</span>, <span class="color-pink">2</span>)</p>
                                    <p><span class="color-blue">BEGIN</span></p>
                                    <p style="margin-left:30px">    <span class="color-blue">DECLARE</span> annual_salary <span class="color-pink">DECIMAL</span>(<span class="color-pink">10</span>, <span class="color-pink">2</span>);</p>
                                    <p style="margin-left:30px">    <span class="color-blue">SET</span> annual_salary = monthly_salary * <span class="color-pink">12</span>;</p>
                                    <p style="margin-left:30px">    <span class="color-blue">RETURN</span> annual_salary;</p>
                                    <p><span class="color-blue">END</span> //</p>
                                    <br />
                                    <p>DELIMITER ;</p>
                                `
                            }}></div>
                        </div>
                        <p>
                            You can now use the <strong>`calculate_annual_salary`</strong> function in a query.
                        </p>
                        <div className='codePalateBox mt-2 mb-2'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-blue">SELECT</span> id, first_name, last_name, monthly_salary, calculate_annual_salary(monthly_salary) <span class="color-blue">AS</span> annual_salary <span class="color-blue">FROM</span> employees;</p>
                                `
                            }}></div>
                        </div>
                        <p>
                            Output of the following query -
                        </p>
                        <table className='table' style={{border: '1px dashed #ccc'}}> 
                            <thead>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>id</th>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>first_name</th>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>last_name</th>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>monthly_salary</th>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>annual_salary</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>1</td>
                                    <td style={{border: '1px dashed #ccc'}}>John</td>
                                    <td style={{border: '1px dashed #ccc'}}>Doe</td>
                                    <td style={{border: '1px dashed #ccc'}}>4000.00</td>
                                    <td style={{border: '1px dashed #ccc'}}>48000.00</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>2</td>
                                    <td style={{border: '1px dashed #ccc'}}>Jane</td>
                                    <td style={{border: '1px dashed #ccc'}}>Smith</td>
                                    <td style={{border: '1px dashed #ccc'}}>4500.00</td>
                                    <td style={{border: '1px dashed #ccc'}}>54000.00</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>3</td>
                                    <td style={{border: '1px dashed #ccc'}}>Alice</td>
                                    <td style={{border: '1px dashed #ccc'}}>Johnson</td>
                                    <td style={{border: '1px dashed #ccc'}}>5000.00</td>
                                    <td style={{border: '1px dashed #ccc'}}>60000.00</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </section>
    )
}