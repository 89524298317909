import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/node-js/mongodb-drop-document";

export default function MongoDbDropDocument() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Node Js - MongoDB - Drop Document | Aspirant's Home");
        const urls = {
            'previous': '/node-js/mongodb-delete-document',
            'next': '/node-js/mongodb-update-document'
        }
        path.setPreviousNext(urls);
    }, [])
    
    return (
        <section className='mt-5 mb-5'>
            <h3>Node Js MongoDB Drop Document</h3>
            <div className='mt-4'>
                <p>In MongoDB, if you want to delete the collection you have to use the `drop()` method. It is same like drop a table in Mysql.</p>
                <p>The <strong>drop()</strong> method takes a callback function containing two parameters: an error object (if an error occurred) and a result parameter. The result parameter returns true if the collection was dropped successfully; otherwise, it returns false.</p>
                <p>Lets see how we use the drop method in node js</p>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html:
                            `<p><span class='color-blue'>var</span> MongoClient = <span class='color-yellow'>require</span>(<span class='color-green'>'mongodb'</span>).MongoClient;</p>
                            <p><span class='color-blue'>var</span> url = <span class='color-green'>"mongodb://localhost:27017/"</span>;</p>
                            <br />
                            <p>MongoClient.<span class='color-red'>connect</span>(<span class='color-blue'>url</span>, function(err, db) {</p>
                            <p style="margin-left:30px">  <span class='color-blue'>if</span> (err) <span class='color-blue'>throw</span> err;</p>
                            <p style="margin-left:30px">  <span class='color-blue'>var</span> dbo = db.<span class='color-red'>db</span>(<span class='color-green'>"mymongodbdatabase"</span>);</p>
                            <p style="margin-left:30px">  dbo.<span class='color-red'>collection</span>(<span class='color-green'>"employees"</span>).<span class='color-red'>drop</span>(function(err, res) {</p>
                            <p style="margin-left:60px">    <span class='color-blue'>if</span> (err) <span class='color-blue'>throw</span> err;</p>
                            <p style="margin-left:60px">    <span class='color-blue'>if</span> (res) <span class='color-pink'>console</span>.<span class='color-red'>log</span>(<span class='color-green'>"Collection deleted"</span>);</p>
                            <p style="margin-left:60px">    db.<span class='color-red'>close</span>();</p>
                            <p style="margin-left:30px">  });</p>
                            <p>});</p>`
                    }}>
                    </div>
                </div>
                <p>Which will give you this result:</p>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html:
                            `<p> Collection deleted</p>`
                    }}>
                    </div>
                </div>
            </div>
        </section>
    )
}
