import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/html/classes";

export default function HTMLClasses() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("HTML - Classes | Aspirant's Home");
        const urls = {
            'previous': '/html/meta-tags',
            'next': '/html/ids'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>HTML - Classes</h3>
            <div className='mt-4 mb-5'>
                <p>
                    HTML classes are used to apply styles to groups of elements and to manipulate those elements using JavaScript. They are defined using the <strong>`class`</strong> attribute in HTML and can be applied to any HTML element. Multiple elements can share the same class, making it a powerful way to apply consistent styling and behavior.
                </p>
                <h5 className='mt-5 mb-3'>Syntax</h5>
                <p>
                    To assign a class to an HTML element, use the <strong>`class`</strong> attribute followed by the class name:
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>&lt;div <span class="color-pink">class</span>=<span class="color-green">"myClass"</span>&gt;This is a div element with a class.&lt;/div&gt;</p>
                            <p>&lt;p <span class="color-pink">class</span>=<span class="color-green">"myClass"</span>&gt;This is a paragraph element with the same class.&lt;/p&gt;</p>
                        `
                    }}></div>
                </div>
                <h5 className='mt-5 mb-3'>Use of Classes in CSS</h5>
                <p>
                    Classes are commonly used in CSS to apply styles to elements. In CSS, class selectors are prefixed with a dot (.).
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-grey">&lt;!DOCTYPE</span> <span class="color-blue">html</span><span class="color-grey">&gt;</span></p>
                        <p>&lt;html <span class="color-pink">lang</span>=<span class="color-green">"en"</span>&gt;</p>
                        <p class="ml-30">    &lt;head&gt;</p>
                        <p class="ml-60">        &lt;meta <span class="color-pink">charset</span>=<span class="color-green">"UTF-8"</span>&gt;</p>
                        <p class="ml-60">        &lt;title&gt;HTML Classes Example&lt;/title&gt;</p>
                        <p class="ml-60">        &lt;style&gt;</p>
                        <p class="ml-90">            <span class="color-pink">.myClass</span> {</p>
                        <p class="ml-120">                <span class="color-green">color</span>: blue;</p>
                        <p class="ml-120">                <span class="color-green">font-size</span>: <span class="color-pink">20px;</span></p>
                        <p class="ml-90">            }</p>
                        <p class="ml-60">        &lt;/style&gt;</p>
                        <p class="ml-30">    &lt;/head&gt;</p>
                        <p class="ml-30">    &lt;body&gt;</p>
                        <p class="ml-60">        &lt;div <span class="color-pink">class</span>=<span class="color-green">"myClass"</span>&gt;This is a styled div element.&lt;/div&gt;</p>
                        <p class="ml-60">        &lt;p <span class="color-pink">class</span>=<span class="color-green">"myClass"</span>&gt;This is a styled paragraph element.&lt;/p&gt;</p>
                        <p class="ml-30">    &lt;/body&gt;</p>
                        <p>&lt;/html&gt;</p>
                        
                        `
                    }}></div>
                </div>
                <p>
                    Output of the following -
                </p>
                <div className='outputPalateBox mb-4'>
                    <div className='outputPalate' dangerouslySetInnerHTML={{
                        __html: `
                        <!DOCTYPE html>
                        <html lang="en">
                            <head>
                                <meta charset="UTF-8">
                                <title>HTML Classes Example</title>
                                <style>
                                    .myClass {
                                        color: blue;
                                        font-size: 20px;
                                    }
                                </style>
                            </head>
                            <body>
                                <div class="myClass">This is a styled div element.</div>
                                <p class="myClass">This is a styled paragraph element.</p>
                            </body>
                        </html>
                        `
                    }}></div>
                </div>
                <p>
                    You can see, using the same class we style multiple element of an web page.
                </p>
                <h5 className='mt-5 mb-3'>Use of Classes in JavaScript</h5>
                <p>
                    Classes can also be used in JavaScript to select and manipulate elements. The <strong>`classList`</strong> property provides methods to add, remove, and toggle classes.
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-grey">&lt;!DOCTYPE</span> <span class="color-blue">html</span><span class="color-grey">&gt;</span></p>
                        <p>&lt;html <span class="color-pink">lang</span>=<span class="color-green">"en"</span>&gt;</p>
                        <p class="ml-30">    &lt;head&gt;</p>
                        <p class="ml-60">        &lt;meta <span class="color-pink">charset</span>=<span class="color-green">"UTF-8"</span>&gt;</p>
                        <p class="ml-60">        &lt;title&gt;Manipulating Classes Example&lt;/title&gt;</p>
                        <p class="ml-60">        &lt;style&gt;</p>
                        <p class="ml-90">            <span class="color-pink">.highlight</span> {</p>
                        <p class="ml-120">                <span class="color-green">background-color</span>: yellow;</p>
                        <p class="ml-90">            }</p>
                        <p class="ml-60">        &lt;/style&gt;</p>
                        <p class="ml-60">        &lt;script&gt;</p>
                        <p class="ml-90">            <span class="color-blue">function</span> <span class="color-red">toggleHighlight</span>() {</p>
                        <p class="ml-120">                <span class="color-blue">let</span> elements = <span class="color-pink">document</span>.<span class="color-red">getElementsByClassName</span>(<span class="color-green">'myClass'</span>);</p>
                        <p class="ml-120">                <span class="color-blue">for</span> (<span class="color-blue">let</span> element <span class="color-blue">of</span> elements) {</p>
                        <p class="ml-150">                    element.classList.<span class="color-red">toggle</span>(<span class="color-green">'highlight'</span>);</p>
                        <p class="ml-120">                }</p>
                        <p class="ml-90">            }</p>
                        <p class="ml-60">        &lt;/script&gt;</p>
                        <p class="ml-30">    &lt;/head&gt;</p>
                        <p class="ml-30">    &lt;body&gt;</p>
                        <p class="ml-60">        &lt;div <span class="color-pink">class</span>=<span class="color-green">"myClass"</span>&gt;This is a div element.&lt;/div&gt;</p>
                        <p class="ml-60">        &lt;p <span class="color-pink">class</span>=<span class="color-green">"myClass"</span>&gt;This is a paragraph element.&lt;/p&gt;</p>
                        <p class="ml-60">        &lt;button <span class="color-pink">onclick</span>=<span class="color-green">"toggleHighlight()"</span>&gt;Toggle Highlight&lt;/button&gt;</p>
                        <p class="ml-30">    &lt;/body&gt;</p>
                        <p>&lt;/html&gt;</p>
                        `
                    }}></div>
                </div>
                <h5 className='mt-5 mb-3'>Multiple Classes</h5>
                <div className='mt-3'>
                    <p>HTML elements can be assigned to multiple classes.</p>
                    <p>To define multiple classes, separate the class names with a space, e.g., <strong>`&lt;div class="city main"&gt;`</strong>. The element will be styled according to all the specified classes.</p>
                    <p>In the example below, the first <strong>`&lt;h2&gt;`</strong> element belongs to both the <strong>`city`</strong> class and the <strong>`main`</strong> class, and it will receive the CSS styles from both classes:</p>
                </div>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>&lt;h2 <span class="color-pink">class</span>=<span class="color-green">"city main"</span>&gt;London&lt;/h2&gt;</p>
                            <p>&lt;h2 <span class="color-pink">class</span>=<span class="color-green">"city"</span>&gt;Paris&lt;/h2&gt;</p>
                            <p>&lt;h2 <span class="color-pink">class</span>=<span class="color-green">"city"</span>&gt;Tokyo&lt;/h2&gt;</p>
                        `
                    }}></div>
                </div>
            </div>
        </section>
    )
}