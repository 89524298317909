import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../../Context";
import { Link } from 'react-router-dom';
export const pageUrl = () => "/node-js/built-in-modules/path";

export default function PathModule() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("NodeJs - Path Module | Aspirant's Home");
        path.setPreviousNext({});
    }, [])

    return (
        <div className='contentPage'>
            <section class='clearfix mb-3'>
                <span className='float-start'>
                    <Link to='/node-js/built-in-modules' onClick={() => path.setPathName('/node-js/built-in-modules')} className='btn btn-primary'> <i class="fa-solid fa-less-than"></i>&nbsp; Back to Built-In Modules </Link>
                </span>
            </section>
            <section className='mt-5 mb-5'>
                <h3>Node Js Path Module</h3>
                <div className='mt-4'>
                    <p>The path module provides utilities for working with file and directory paths. It helps in constructing and manipulating file paths in a way that is consistent across different operating systems.</p>
                    <p>The syntax for including the path module in your application: </p>
                    <div className='codePalateBox mt-2 mb-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `<p><span class='color-blue'>var</span> path = <span class='color-yellow'>require</span>(<span class='color-green'>'path'</span>);</p>
                                    `
                        }}></div>
                    </div>
                </div>
                <div className='mt-4'>
                    <h5>Examples</h5>
                    <p>Here's a basic example of how you might use the path module:</p>
                    <div className='codePalateBox mt-2 mb-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `<p><span class='color-blue'>const</span> path = <span class='color-yellow'>require</span>(<span class='color-green'>'path'</span>);</p>
                            <br />
                            <p><span class='color-blue'>const</span> fullPath = path.<span class='color-red'>join</span>(<span class='color-green'>'/path'</span>, <span class='color-green'>'to'</span>, <span class='color-green'>'file.txt'</span>);</p>
                            <p><span class='color-pink'>console</span>.<span class='color-red'>log</span>(fullPath);</p>
                            `
                        }}></div>
                    </div>
                    <p>Which will give you this result:</p>
                    <div className='codePalateBox mt-2 mb-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `<p class="color-green">'/path/to/file.txt'</p>`
                        }}></div>
                    </div>
                </div>
                <div className='mt-4'>
                    <p>Here are the some methods of path module:</p>
                    <p><strong>`basename()`</strong> -	The basename method in the Node.js path module is used to get the last portion of a path. It returns the last portion of a path.</p>
                    <p><strong>`dirname()`</strong> -	The dirname method in the Node.js path module is used to get the directory name of a path. It returns the directory portion of a path.</p>
                    <p><strong>`extname()`</strong> -	The extname() method in the Node.js path module is used to get the extension of a file path. It returns the extension of the last portion of the path, including the . (dot) character.</p>
                    <p><strong>`format()`</strong> -	The path.format() method is used to convert an object representing a file path into a file path string. This method is the opposite of path.parse(), which converts a file path string into an object.</p>
                    <p><strong>`isAbsolute()`</strong> -	The isAbsolute() method in the Node.js path module is used to determine if a given path is an absolute path. An absolute path is a path that starts from the root of the file system.</p>
                    <p><strong>`parse()`</strong> -	The parse() method in the Node.js path module is used to parse a file path string into an object containing its components. </p>
                    <p><strong>`resolve()`</strong> -	The resolve() method in the Node.js path module is used to resolve a sequence of paths or path segments into an absolute path.</p>
                </div>
            </section>
        </div>
    )
}
