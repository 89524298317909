import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/mysql/show-database";

export default function MysqlShowDatabase() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Mysql - Show Database | Aspirant's Home");
        const urls = {
            'previous': '/mysql/select-database',
            'next':'/mysql/create-users'
        }
        path.setPreviousNext(urls);
    },[])

    return (
        <section className='mt-5 mb-5'>
            <h3>Mysql - Show Database</h3>
            <div className='mt-4 mb-4'>
                <p>
                    To view the complete list of databases stored on a MySQL server, you can employ the <strong>`SHOW DATABASES`</strong> statement. This command presents the results in a tabular format with a single column, listing the databases alphabetically for easy reference. Here's the basic syntax:
                </p>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-blue">SHOW</span> DATABASES;</p>
                        `
                    }}></div>
                </div>
                <p>
                    This statement will return a result set with a list of database names. Each row in the result set represents a database.
                </p>
            </div>
        </section>
    )
}