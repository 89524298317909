import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/node-js/npm-module";

export default function NPMModule() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Node Js - NPM Module | Aspirant's Home");
        const urls = {
            'previous': '/node-js/url-module',
            'next': '/node-js/events'
        }
        path.setPreviousNext(urls);
    }, [])
    
    return (
        <section className='mt-5 mb-5'>
            <h3 className='mb-4'>Node js NPM Module</h3>
            <p>In Node.js, npm (Node Package Manager) is a package manager that comes bundled with Node.js. It is used to install, manage, and share reusable JavaScript code packages/modules. npm allows developers to easily add functionality to their Node.js applications by installing packages from the npm registry, a public repository of Node.js packages.</p>
            <p>Here's a basic explanation of npm and how it is used in Node.js, along with an example:</p>
            <ul style={{ 'listStyle': 'decimal' }}>
                <li>
                <strong>Installing Packages:</strong> npm allows you to install packages from the npm registry, a public repository of Node.js packages. You can install packages locally in your project or globally on your system.
                </li>

                <li><strong>Managing Dependencies:</strong> npm helps manage dependencies for your Node.js projects. It allows you to specify dependencies in a package.json file and automatically installs the required packages when you run npm install.</li>

                <li><strong>Version Management:</strong> npm allows you to specify version ranges for dependencies in your package.json file. This helps ensure that your project uses compatible versions of packages.</li>

                <li><strong>Publishing Packages:</strong> If you've developed a reusable module or application, you can publish it to the npm registry for others to use. npm provides commands to help you publish packages and manage access to them.</li>

                <li><strong>Scripts:</strong> npm allows you to define scripts in your package.json file that can be run using the npm run command. This is useful for defining build scripts, test scripts, and other project-specific commands.</li>

                <li><strong>Security:</strong> npm provides security features to help protect your projects from vulnerabilities. It can alert you to known security issues in your dependencies and suggest updates to resolve them.</li>                    
            </ul>
            <p>Overall, npm is a powerful tool that simplifies the process of managing packages and dependencies for Node.js projects. It is an essential part of the Node.js ecosystem and is widely used by developers around the world.</p>
        </section>
    )
}
