import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/css/font/fallbacks";

export default function FontFallback() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Font Fallback in CSS | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/css/font/web/safe',
            'next': '/css/font/style'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>Font Fallback in CSS</h3>
            <div className='mt-4 mb-5'>
                <p>
                    <strong>Font fallbacks</strong> refer to the practice of specifying multiple font options in a CSS <strong>`font-family`</strong> declaration. This ensures that if the preferred font is not available on a user's device, a fallback font will be used, providing a consistent appearance for your text across different platforms.
                </p>
                <h5 className="mt-5 mb-3">How Font Fallbacks Work</h5>
                <p>
                    When you specify multiple fonts in the <strong>`font-family`</strong> property, the browser tries to use the first font in the list. If that font is not available, it moves on to the next one, and so on. If none of the specified fonts are available, the browser will ultimately use a generic font family (like <strong>`serif`</strong>, <strong>`sans-serif`</strong>, or <strong>`monospace`</strong>).
                </p>
                <h5 className="mt-5 mb-3">Syntax of Font Fallbacks</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>element {</p>
                            <p class="ml-30">    <span class="color-green">font-family</span>: <span class="color-green">"Primary Font"</span>, <span class="color-green">"Fallback Font"</span>, generic-family;</p>
                            <p>}</p>
                        `
                    }}></div>
                </div>
                <h5 className="mt-5 mb-3">Basic Example of Font Fallbacks</h5>
                <p>
                    Here is an basic example how we use font fallbacks in <strong>`font-family`</strong> declaration.
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>body {</p>
                            <p class="ml-30">    <span class="color-green">font-family</span>: <span class="color-green">"Roboto"</span>, <span class="color-green">"Helvetica Neue"</span>, Arial, sans-serif;</p>
                            <p>}</p>
                        `
                    }}></div>
                </div>
                <p>
                    In this above example -
                </p>
                <ul style={{ listStyle: 'decimal' }}>
                    <li><strong>`Roboto`</strong> is the preferred font.</li>
                    <li>If <strong>`Roboto`</strong> is not available, the browser will try <strong>`Helvetica Neue`</strong>.</li>
                    <li>If neither <strong>`Roboto`</strong> nor <strong>`Helvetica Neue`</strong> are available, the browser will use <strong>`Arial`</strong>.</li>
                    <li>If none of the specified fonts are available, it will default to any available <strong>sans-serif</strong> font.</li>
                </ul>
            </div>
        </section>
    )
}