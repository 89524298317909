import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/node-js/mongodb-delete-document";

export default function MongoDbDeleteDocument() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Node Js - MongoDB - Delete Document | Aspirant's Home");
        const urls = {
            'previous': '/node-js/mongodb-sort-document',
            'next': '/node-js/mongodb-drop-document'
        }
        path.setPreviousNext(urls);
    }, [])
    
    return (
        <section className='mt-5 mb-5'>
            <h3>Node Js MongoDB Delete Document</h3>
            <div className='mt-4'>
                <p>To delete a document from a collection in MongoDB, we use <strong>`deleteOne()`</strong> or <strong>`deleteMany()`</strong> methods. It is same as delete record from table in Mysql. Ok lets see the example how we delete the document in MongoDB.</p>
            </div>
            <div className='mt-4'>
                <h5>Using `deleteMany()`</h5>
                <p>We can delete more than one document using `deleteMany()`, if you want to delete all document then you have pass { } like this way. </p>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html:
                            ` db.collection.<span class='color-blue'>deleteMany</span>( { } )`
                    }}>
                    </div>
                </div>
                <p>If you want to delete the document which have to match such conditions, then we have to pass the conditions in the first parameter like an object. Suppose, we have a collection of employee and we have to delete the record or document of employee whoose name start with `S`, then the syntax will be the following.</p>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html:
                            ` <p><span class='color-blue'>var</span> MongoClient = <span class='color-yellow'>require</span>(<span class='color-green'>'mongodb'</span>).MongoClient;</p>
                            <p><span class='color-blue'>var</span> url = <span class='color-green'>"mongodb://localhost:27017/"</span>;</p>
                            <br />
                            <p>MongoClient.<span class='color-red'>connect</span>(url, function(<span class='color-blue'>err</span>, db) {</p>
                            <p style="margin-left:30px">  <span class='color-blue'>if</span> (err) <span class='color-blue'>throw</span> err;</p>
                            <p style="margin-left:30px">  <span class='color-blue'>var</span> dbo = db.<span class='color-red'>db</span>(<span class='color-green'>"mymongodbdatabase"</span>);</p>
                            <p style="margin-left:30px">  <span class='color-blue'>var</span> myquery = { <span class='color-pink'>email</span>: /^S/ };</p>
                            <p style="margin-left:30px">  dbo.<span class='color-red'>collection</span>(<span class='color-green'>"employees"</span>).<span class='color-red'>deleteMany</span>(myquery, function(<span class='color-blue'>err</span>, obj) {</p>
                            <p style="margin-left:60px">    <span class='color-blue'>if</span> (err) <span class='color-blue'>throw</span> err;</p>
                            <p style="margin-left:60px">    <span class='color-pink'>console</span>.<span class='color-red'>log</span>(obj.result.n + <span class='color-green'>" document(s) deleted"</span>);</p>
                            <p style="margin-left:60px">    db.<span class='color-red'>close</span>();</p>
                            <p style="margin-left:30px">  });</p>
                            <p>});</p>`
                    }}>
                    </div>
                </div>
                <p>Which will give you this result:</p>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html:
                            `<p> 2 document(s) deleted</p>`
                    }}>
                    </div>
                </div>
            </div>
            <div className='mt-4'>
                <h5>Using `deleteOne()`</h5>
                <p>We can delete only one document using `deleteOne()`, if you want to delete the document without any filter then you have pass { } like this way. </p>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html:
                            ` db.collection.<span class='color-blue'>deleteOne</span>( { } )`
                    }}>
                    </div>
                </div>
                <p>If you want to delete the document which have to match such conditions, then we have to pass the conditions in the first parameter like an object like above example. Difference is that it deletes only the first document that matches.</p>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html:
                            ` <p><span class='color-blue'>var</span> MongoClient = <span class='color-yellow'>require</span>(<span class='color-green'>'mongodb'</span>).MongoClient;</p>
                            <p><span class='color-blue'>var</span> url = <span class='color-green'>"mongodb://localhost:27017/"</span>;</p>
                            <br />
                            <p>MongoClient.<span class='color-red'>connect</span>(url, function(<span class='color-blue'>err</span>, db) {</p>
                            <p style="margin-left:30px">  <span class='color-blue'>if</span> (err) <span class='color-blue'>throw</span> err;</p>
                            <p style="margin-left:30px">  <span class='color-blue'>var</span> dbo = db.<span class='color-red'>db</span>(<span class='color-green'>"mymongodbdatabase"</span>);</p>
                            <p style="margin-left:30px">  <span class='color-blue'>var</span> myquery = { <span class='color-pink'>email</span>: /^S/ };</p>
                            <p style="margin-left:30px">  dbo.<span class='color-red'>collection</span>(<span class='color-green'>"employees"</span>).<span class='color-red'>deleteOne</span>(myquery, function(<span class='color-blue'>err</span>, obj) {</p>
                            <p style="margin-left:60px">    <span class='color-blue'>if</span> (err) <span class='color-blue'>throw</span> err;</p>
                            <p style="margin-left:60px">    <span class='color-pink'>console</span>.<span class='color-red'>log</span>(obj.result.n + <span class='color-green'>" document(s) deleted"</span>);</p>
                            <p style="margin-left:60px">    db.<span class='color-red'>close</span>();</p>
                            <p style="margin-left:30px">  });</p>
                            <p>});</p>`
                    }}>
                    </div>
                </div>
                <p>Which will give you this result:</p>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html:
                            `<p> 1 document deleted</p>`
                    }}>
                    </div>
                </div>
            </div>
        </section>
    )
}
