import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../../Context";
import { Link } from 'react-router-dom';
export const pageUrl = () => "/mysql/date-and-time-functions/date";

export default function Date() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Mysql - Date and Time functions - DATE() | Aspirant's Home");
        path.setPreviousNext({});
    }, [])

    return (
        <>
            <section class='clearfix mb-3'>
                <span className='float-start'>
                    <Link to='/mysql/date-and-time-functions' onClick={() => path.setPathName('/mysql/date-and-time-functions')} className='btn btn-primary'> <i class="fa-solid fa-less-than"></i>&nbsp; Back to Date and Time functions </Link>
                </span>
            </section>
            <section className='mt-5 mb-5'>
                <h3>Mysql - DATE() Functions</h3>
                <div className='mt-4 mb-5'>
                    <p>
                        The <strong>`DATE()`</strong> function in MySQL is used to extract the date part from a datetime or timestamp expression. It returns the date without the time part.
                    </p>
                    <p>
                        Here's the basic syntax of the <strong>`DATE()`</strong> function:
                    </p>
                    <div className='codePalateBox mt-2 mb-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                                <p>DATE(datetime_expression)</p>
                            `
                        }}></div>
                    </div>
                    <p>
                        Where <strong>`datetime_expression`</strong> is a datetime or timestamp value or column.
                    </p>
                    <p>
                        For example, if you have a datetime value <strong>`'2022-05-23 10:30:00'`</strong> and you want to extract only the date part (i.e., <strong>`'2022-05-23'`</strong>), you can use the <strong>`DATE()`</strong> function like this:
                    </p>
                    <div className='codePalateBox mt-2 mb-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                                <p><span class="color-blue">SELECT</span> DATE(<span class="color-green">'2022-05-23 10:30:00'</span>);</p>
                            `
                        }}></div>
                    </div>
                    <p>
                        This will return <strong>`'2022-05-23'`</strong>.
                    </p>
                </div>
                <div className='mt-5 mb-5'>
                    <h5>DATE() with MySQL Table</h5>
                    <div className='mt-4 mb-2'>
                        <p>
                            To use the <strong>`DATE()`</strong> function in MySQL with a table, let's consider a table <strong>`events`</strong> with columns <strong>`id`</strong>, <strong>`event_name`</strong>, and <strong>`start_datetime`</strong> where <strong>`start_datetime`</strong> represents the start date and time of each event as a datetime value. We want to extract only the date part from the <strong>`start_datetime`</strong> column.
                        </p>
                        <p>
                            Consider the <strong>`events`</strong> table:
                        </p>
                        <table className='table' style={{border: '1px dashed #ccc'}}> 
                            <thead>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>id</th>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>event_name</th>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>start_datetime</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>1</td>
                                    <td style={{border: '1px dashed #ccc'}}>Event A</td>
                                    <td style={{border: '1px dashed #ccc'}}>2022-05-23 10:30:00</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>2</td>
                                    <td style={{border: '1px dashed #ccc'}}>Event B</td>
                                    <td style={{border: '1px dashed #ccc'}}>2022-05-24 11:45:00</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>3</td>
                                    <td style={{border: '1px dashed #ccc'}}>Event C</td>
                                    <td style={{border: '1px dashed #ccc'}}>2022-05-25 09:00:00</td>
                                </tr>
                            </tbody>
                        </table>
                        <p>
                            To extract only the date part from the <strong>`start_datetime`</strong> column for each event, you would use:
                        </p>
                        <div className='codePalateBox mt-2 mb-2'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-blue">SELECT</span> id, event_name, start_datetime, DATE(start_datetime) <span class="color-blue">AS</span> start_date <span class="color-blue">FROM</span> events;</p>
                                `
                            }}></div>
                        </div>
                        <p>
                            This query will return:
                        </p>
                        <table className='table' style={{border: '1px dashed #ccc'}}> 
                            <thead>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>id</th>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>event_name</th>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>start_datetime</th>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>start_date</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>1</td>
                                    <td style={{border: '1px dashed #ccc'}}>Event A</td>
                                    <td style={{border: '1px dashed #ccc'}}>2022-05-23 10:30:00</td>
                                    <td style={{border: '1px dashed #ccc'}}>2022-05-23</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>2</td>
                                    <td style={{border: '1px dashed #ccc'}}>Event B</td>
                                    <td style={{border: '1px dashed #ccc'}}>2022-05-24 11:45:00</td>
                                    <td style={{border: '1px dashed #ccc'}}>2022-05-24</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>3</td>
                                    <td style={{border: '1px dashed #ccc'}}>Event C</td>
                                    <td style={{border: '1px dashed #ccc'}}>2022-05-25 09:00:00</td>
                                    <td style={{border: '1px dashed #ccc'}}>2022-05-25</td>
                                </tr>
                            </tbody>
                        </table>
                        <p>
                            In this example, the <strong>`DATE()`</strong> function is used to extract only the date part from the <strong>`start_datetime`</strong> column for each event. The result is displayed in the <strong>`start_date`</strong> column.
                        </p>
                    </div>
                </div>
            </section>
        </>
    )
}