import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/node-js/modules";

export default function NodeModules() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Node Js - Modules | Aspirant's Home");
        const urls = {
            'previous': '/node-js/get-started',
            'next': '/node-js/http-module'
        }
        path.setPreviousNext(urls);
    }, [])
    
    return (
        <section className='mt-5 mb-5'>
            <h3>Node Modules</h3>
            <p className='mt-4'>In Node.js, modules are reusable blocks of code that encapsulate related functionality and can be used in other parts of your application. Modules help in organizing and structuring your codebase, making it easier to manage and maintain.</p>
            <p>Node.js uses the CommonJS module system, which allows you to define modules using the <strong>`module.exports`</strong> object and import them using the <strong>`require`</strong> function. Here's a basic example to illustrate how modules work in Node.js:</p>
            <ul style={{ 'listStyle': 'decimal' }}>
                <li><strong>Creating a Module:</strong>
                    <div className='codePalateBox mt-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{ __html: "<p class='color-grey'>// math.js</p><p><span class='color-blue'>const</span> <span class='color-red'>add</span> = (a, b) => a + b;</p><p><span class='color-blue'>const</span> <span class='color-red'>subtract</span> = (a, b) => a - b;</p><p><span class='color-pink'>module</span>.exports = { add, subtract };</p>" }}>
                        </div>
                    </div>
                </li>
                <li><strong>Using a Module:</strong>
                    <div className='codePalateBox mt-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{ __html: "<p class='color-grey'>// app.js</p><p><span class='color-blue'>const</span> { add, subtract } = <span class='color-yellow'>require</span>(<span class='color-green'>'./math'</span>);</p><p><span class='color-pink'>console</span>.<span class='color-red'>log</span>(<span class='color-red'>add</span>(<span class='color-pink'>5</span>, <span class='color-pink'>3</span>)); <span class='color-grey'>// Output: 8</span></p><p><span class='color-pink'>console</span>.<span class='color-red'>log</span>(<span class='color-red'>subtract</span>(<span class='color-pink'>5</span>, <span class='color-pink'>3</span>)); <span class='color-grey'>// Output: 2</span></p>" }}>
                        </div>
                    </div>
                </li>
            </ul>
            <p>In this example, <strong>`math.js`</strong> is a module that exports two functions (<strong>`add`</strong> and <strong>`subtract`</strong>) using <strong>`module.exports`</strong>. In <strong>`app.js`</strong>, we import these functions using <strong>`require('./math')`</strong> and use them in our application.</p>
            <p>Node.js also provides core modules (e.g., <strong>`http`</strong>, <strong>`fs`</strong>, <strong>`path`</strong>) that are built-in and can be used without installing any additional packages. </p>

            <h3 className='mb-4 mt-5'>Built-in Modules</h3>
            <p>Node.js has a set of built-in modules which you can use without any further installation.</p>
            <p>We have discussed <a href="/node-js/built-in-modules">Built-in Modules</a> Reference for a complete list of modules.</p>
        </section>
    )
}
