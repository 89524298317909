import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/react-js/validating-form-user-input";

export default function ReactFormInputvalidations() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("React Forms - Validating User Input | Aspirant's Home");
        const urls = {
            'previous': '/react-js/managing-form-user-input',
            'next':'/react-js/router'
        }
        path.setPreviousNext(urls);
    },[])

    return (
        <section className='mt-5 mb-5'>
            <h3>React Forms - Validating User Input</h3>
            <div className='mt-4 mb-4'>
                <p>
                    Validating user input in React forms helps ensure that the data entered by the user meets certain criteria or constraints. This can include checking for required fields, valid email addresses, or password strength. By validating user input, you can provide feedback to users when they enter incorrect or incomplete information, helping them to correct errors and improve the overall user experience.
                </p>
                <p>
                    Here's a simple example of validating a single input field for a username:
                </p>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-blue">import</span> <span class="color-red">React</span>, { <span class="color-red">useState</span> } <span class="color-blue">from</span> <span class="color-green">'react'</span>;</p>
                            <br />
                            <p><span class="color-blue">function</span> <span class="color-red">SimpleForm</span>() {</p>
                            <p style="margin-left:30px">    <span class="color-blue">const</span> [username, setUsername] = <span class="color-red">useState</span>('');</p>
                            <p style="margin-left:30px">    <span class="color-blue">const</span> [error, setError] = <span class="color-red">useState</span>('');</p>
                            <br />    
                            <p style="margin-left:30px">    <span class="color-blue">const</span> <span class="color-red">handleChange</span> = (event) => {</p>
                            <p style="margin-left:60px">         <span class="color-red">setUsername</span>(event.target.value);</p>
                            <p style="margin-left:30px">    };</p>
                            <br />    
                            <p style="margin-left:30px">    <span class="color-blue">const</span> <span class="color-red">handleSubmit</span> = (event) => {</p>
                            <p style="margin-left:60px">        event.<span class="color-red">preventDefault</span>();</p>
                            <p style="margin-left:60px">        <span class="color-blue">if</span> (username.trim() === '') {</p>
                            <p style="margin-left:90px">            <span class="color-red">setError</span>(<span class="color-green">'Username is required'</span>);</p>
                            <p style="margin-left:60px">        } <span class="color-blue">else</span> {</p>
                            <p style="margin-left:90px">            <span class="color-red">setError</span>(<span class="color-green">''</span>);</p>
                            <p style="margin-left:90px">            <span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">'Username submitted:'</span>, username);</p>
                            <p style="margin-left:60px">        }</p>
                            <p style="margin-left:30px">    };</p>
                            <br />    
                            <p style="margin-left:30px">    <span class="color-blue">return</span> (</p>
                            <p style="margin-left:60px">        &lt;div&gt;</p>
                            <p style="margin-left:90px">            &lt;h1&gt;Simple Form Example&lt;/h1&gt;</p>
                            <p style="margin-left:90px">            &lt;form <span class="color-pink">onSubmit</span>=<span class="color-green">{handleSubmit}</span>&gt;</p>
                            <p style="margin-left:120px">                &lt;label&gt;</p>
                            <p style="margin-left:150px">                     Username:</p>
                            <p style="margin-left:150px">                     &lt;input
                            <span class="color-pink">type</span>=<span class="color-green">"text"</span>
                            <span class="color-pink">value</span>=<span class="color-green">{username}</span>
                            <span class="color-pink">onChange</span>=<span class="color-green">{handleChange}</span>
                                                    /&gt;</p>
                            <p style="margin-left:120px">                &lt;/label&gt;</p>
                            <p style="margin-left:120px">                {error && &lt;div style={{ color: 'red' }}&gt;{error}&lt;/div&gt;}</p>
                            <p style="margin-left:120px">                &lt;button <span class="color-pink">type</span>=<span class="color-green">"submit"</span>&gt;Submit&lt;/button&gt;</p>
                            <p style="margin-left:90px">             &lt;/form&gt;</p>
                            <p style="margin-left:60px">         &lt;/div&gt;</p>
                            <p style="margin-left:30px">     );</p>
                            <p>}</p>
                            <br />
                            <p><span class="color-blue">export default</span> <span class="color-red">SimpleForm</span>;</p>
                        
                        `
                    }}></div>
                </div>
                <p>
                    The <strong>`handleSubmit`</strong> function checks if the username is empty (<strong>`trim()`</strong> is used to remove any whitespace) and sets an error message if it is. Otherwise, it clears any existing error message and logs the username to the console.
                </p>
                <p>
                    In the above example, we validate the form <strong>`onSubmit`</strong> function, that means when a form gets submitted we validate the input field as per our requirement. You can also validate the form with <strong>`onBlur`</strong> event handler when an input field loses focus (i.e., when the user clicks outside the field)
                </p>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-blue">const</span> [username, setUsername] = <span class="color-red">useState</span>('');</p>
                            <p><span class="color-blue">const</span> [usernameError, setUsernameError] = <span class="color-red">useState</span>('');</p>
                            <br />
                            <p><span class="color-blue">const</span> <span class="color-red">handleUsernameChange</span> = (event) => {</p>
                            <p style="margin-left:30px">    <span class="color-red">setUsername</span>(event.target.value);</p>
                            <p>};</p>
                            <br />
                            <p><span class="color-blue">const</span> <span class="color-red">handleUsernameBlur</span> = () => {</p>
                            <p style="margin-left:30px">   <span class="color-blue"> if</span> (username.trim() === '') {</p>
                            <p style="margin-left:60px">        <span class="color-red">setUsernameError</span>(<span class="color-green">'Username is required'</span>);</p>
                            <p style="margin-left:30px">    } <span class="color-blue">else</span> {</p>
                            <p style="margin-left:60px">        <span class="color-red">setUsernameError</span>('');</p>
                            <p style="margin-left:30px">    }</p>
                            <p>};</p>
                        `
                    }}></div>
                </div>
                <p>
                    In the above examples, you can see how we validate input field value in <strong>`onBlur`</strong> event handler. You need to add <strong>`onBlur`</strong> property on your input fields.
                </p>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>&lt;input
                            <span class="color-pink">type</span>=<span class="color-green">"text"</span>
                            <span class="color-pink">value</span>=<span class="color-green">{username}</span>
                            <span class="color-pink">onChange</span>=<span class="color-green">{handleUsernameChange}</span>
                            <span class="color-pink">onBlur</span>=<span class="color-green">{handleUsernameBlur}</span>
                            /&gt;</p>
                            <p>{usernameError && &lt;div style={{ color: 'red' }}&gt;{usernameError}&lt;/div&gt;}</p>
                        `
                    }}></div>
                </div>
            </div>
        </section>
    )
}