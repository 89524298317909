import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/mysql/drop-index";

export default function MysqlDropIndex() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Mysql - Drop Index | Aspirant's Home");
        const urls = {
            'previous': '/mysql/create-index',
            'next': '/mysql/show-indexes'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>Mysql - Drop Index</h3>
            <div className='mt-4 mb-5'>
                <p>
                    To drop an index on existing table, we use the following SQL statements −
                </p>
                <ul>
                    <li>Using DROP INDEX Statement</li>
                    <li>Using ALTER TABLE Statement</li>
                </ul>
            </div>
            <div className='mt-5 mb-5'>
                <h5>Using DROP INDEX Statement</h5>
                <div className='mt-2 mb-2'>
                    <p>
                        To drop index on an existing table in MySQL, you can use the <strong>`DROP INDEX`</strong> statement. Here's the basic syntax:
                    </p>
                    <div className='codePalateBox mt-2 mb-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                                <p><span class="color-blue">DROP INDEX</span> index_name <span class="color-blue">ON</span> table_name;</p>
                            `
                        }}></div>
                    </div>
                    <p>
                        Replace <strong>`index_name`</strong> with the index name you want to drop and <strong>`table_name`</strong> with the name of your existing table.
                    </p>
                    <p>
                        For example, let's say you have an existing table called <strong>`users`</strong> and you want to drop an index:
                    </p>
                    <div className='codePalateBox mt-2 mb-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                                <p><span class="color-blue">DROP INDEX</span> idx_email <span class="color-blue">ON</span> users;</p>
                            `
                        }}></div>
                    </div>
                </div>
            </div>
            <div className='mt-5 mb-5'>
                <h5>Using ALTER TABLE Statement</h5>
                <div className='mt-2 mb-2'>
                    <p>
                        To drop an index using the <strong>`ALTER TABLE`</strong> statement in MySQL, you can use the <strong>`DROP INDEX`</strong> clause. Here's the basic syntax:
                    </p>
                    <div className='codePalateBox mt-2 mb-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                                <p><span class="color-blue">ALTER TABLE</span> table_name <span class="color-blue">DROP INDEX</span> index_name;</p>
                            `
                        }}></div>
                    </div>
                    <p>
                        Replace <strong>`table_name`</strong> with the name of your existing table and  <strong>`index_name`</strong> with the name you want to drop.
                    </p>
                    <p>
                        For example, to drop an index on the email column of the <strong>`users`</strong> table:
                    </p>
                    <div className='codePalateBox mt-2 mb-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                                <p><span class="color-blue">ALTER TABLE</span> users <span class="color-blue">DROP INDEX</span> idx_email;</p>
                            `
                        }}></div>
                    </div>
                </div>
            </div>
        </section>
    )
}