import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/node-js/events";

export default function EventModule() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Node Js - Events | Aspirant's Home");
        const urls = {
            'previous': '/node-js/npm-module',
            'next': '/node-js/upload-files'
        }
        path.setPreviousNext(urls);
    }, [])
    
    return (
        <section className='mt-5 mb-5'>
            <h3 className='mb-4'>Node js Events</h3>
            <p>In Node.js, events are a fundamental part of the core architecture, allowing objects to signal that something has happened. This event-driven architecture is central to Node.js's asynchronous, non-blocking nature, enabling efficient handling of I/O operations and concurrency.</p>
            <p>The core module <strong>`events`</strong> in Node.js provides an <strong>`EventEmitter`</strong> class that serves as the foundation for working with events. Here's how it works:</p>
            <div className='mt-5 mb-3'>
                <h5 className='mb-4'>EventEmitter Object</h5>
                <p>
                    The <strong>`EventEmitter`</strong> class is used to bind events and event listeners. It allows objects to emit events and for other parts of the code to listen for those events.
                </p>
                <div className='codePalateBox mt-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{ __html: "<p><span class='color-blue'>const</span> <span class='color-red'>EventEmitter</span> = <span class='color-yellow'>require</span>(<span class='color-green'>'events'</span>);</p><p class='color-grey'>// Create an instance of EventEmitter</p><p><span class='color-blue'>const</span> myEmitter = <span class='color-blue'>new</span> <span class='color-red'>EventEmitter();</span></p><p class='color-grey'>// Bind a listener to the 'hello' event</p><p>myEmitter.<span class='color-red'>on</span>(<span class='color-green'>'hello'</span>, (name) => {</p><p style='margin-left:30px'>  <span class='color-pink'>console</span>.<span class='color-red'>log</span>(<span class='color-green'>`Hello, ${name}!`</span>);</p><p>});</p><p class='color-grey'>// Emit the 'hello' event</p><p>myEmitter.<span class='color-red'>emit</span>(<span class='color-green'>'hello'</span>, <span class='color-green'>'Alice'</span>);</p>" }}>
                    </div>
                </div>
            </div>
            <div className='mt-5 mb-3'>
            <h5 className='mb-4'>EventEmitter Methods:</h5>
            <ul>
                <li><strong>`on(eventName, listener)`:</strong> Binds a listener function to the specified event.</li>
                <li><strong>`emit(eventName, [...args])`:</strong> Emits an event with the specified name, triggering any listeners bound to that event.</li>
                <li><strong>`once(eventName, listener)`:</strong> Binds a listener function to the specified event, but only triggers it once.</li>
                <li><strong>`removeListener(eventName, listener)`:</strong> Removes a listener for the specified event.</li>
                <li><strong>`removeAllListeners([eventName])`:</strong> Removes all listeners for the specified event, or all listeners if no event name is provided.</li>
                </ul>
            </div>

        </section>
    )
}
