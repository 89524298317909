import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/react-js/showing-error-page";


export default function ErrorPage() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("React - Showing Error Page | Aspirant's Home");
        const urls = {
            'previous': '/react-js/layouts-nested-routes',
            'next':'/react-js/navigation-link'
        }
        path.setPreviousNext(urls);
    },[])

    return (
        <section className='mt-5 mb-5'>
            <h3>Showing Error Page</h3>
            <div className='mt-4 mb-4'>
                <p>To show an error page in a React application, you can create a component that represents the error page and use React Router to route to this component when an error occurs. There are two ways to define the <strong>Error Component</strong> in routes.</p>

                <p>If you have <strong>Nested Routes</strong>, you can set <strong>`errorElement`</strong> prop in the root routes to set the <strong>Error Component</strong>.  Look at the below example -</p>
                <div className='codePalateBox mt-2 mb-5'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-blue">const</span> router = <span class="color-red">createBrowserRouter</span>([</p>
                            <p style="margin-left:30px">    {</p>
                            <p style="margin-left:60px">        <span class="color-pink">path</span>: <span class="color-green">'/'</span>,</p>
                            <p style="margin-left:60px">        <span class="color-pink">element</span>: &lt;<span class="color-green">RootLayout</span> /&gt;,</p>
                            <p style="margin-left:60px">        <span class="color-pink">errorElement</span>: &lt;<span class="color-green">ErrorPage</span> /&gt;,</p>
                            <p style="margin-left:60px">        <span class="color-pink">children</span>: <span class="color-green">[</span></p>
                            <div class="color-green">
                            <p style="margin-left:90px">            { path: '/', element: &lt;HomePage /&gt; },</p>
                            <p style="margin-left:90px">            { path: '/about', element: &lt;AboutPage /&gt;}</p>
                            </div>
                            <p style="margin-left:60px">        <span class="color-green">]</span></p>
                            <p style="margin-left:30px">    }</p>
                            <p>])</p>
                        `
                    }}></div>
                </div> 

                <p>Or, If you are using <strong>BrowserRouter</strong> from `react-router-dom`, look at the below code for set the <strong>Error Component</strong>.</p>

                <div className='codePalateBox mt-2 mb-5'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-blue">import</span> <span class="color-red">React</span> <span class="color-blue">from</span> <span class="color-green">'react'</span>;</p>
                        <p><span class="color-blue">import</span> { <span class="color-red">BrowserRouter</span> <span class="color-blue">as</span> <span class="color-red">Router</span>, <span class="color-red">Routes</span>, <span class="color-red">Route</span> } <span class="color-blue">from</span> <span class="color-green">'react-router-dom'</span>;</p>
                        <p><span class="color-blue">import</span> <span class="color-red">HomePage</span> <span class="color-blue">from</span> <span class="color-green">'./components/Home'</span>;</p>
                        <p><span class="color-blue">import</span> <span class="color-red">AboutPage</span> <span class="color-blue">from</span> <span class="color-green">'./components/About'</span>;</p>
                        <p><span class="color-blue">import</span> <span class="color-red">RootLayout</span> <span class="color-blue">from</span> <span class="color-green">'./components/RootLayout'</span>;</p>
                        <p><span class="color-blue">import</span> <span class="color-red">ErrorPage</span> <span class="color-blue">from</span> <span class="color-green">'./components/ErrorPage'</span>;</p>
                        <br />
                        <p><span class="color-blue">function</span> <span class="color-red">App</span>() {</p>
                        <p style="margin-left:30px;">    <span class="color-blue">return</span> (</p>
                        <p style="margin-left:60px;"> &lt;<span class="color-red">Router</span>&gt;</p>
                        <p style="margin-left:90px">    &lt;<span class="color-red">Routes</span>&gt;</p>
                        <p style="margin-left:120px">        &lt;<span class="color-red">Route</span> <span class="color-pink">path</span>=<span class="color-green">"/"</span> <span class="color-pink">element</span>={&lt;<span class="color-red">RootLayout</span> /&gt;} <span class="color-pink">errorElement</span>={&lt;<span class="color-red">ErrorPage</span> /&gt;}&gt;</p>
                        <p style="margin-left:150px">            &lt;<span class="color-red">Route</span> <span class="color-pink">path</span>=<span class="color-green">"/"</span> <span class="color-pink">exact element</span>={&lt;<span class="color-red">HomePage</span> /&gt;} /&gt;</p>
                        <p style="margin-left:150px">            &lt;<span class="color-red">Route</span> <span class="color-pink">path</span>=<span class="color-green">"/about"</span> <span class="color-pink">element</span>={&lt;<span class="color-red">AboutPage</span> /&gt;} /&gt;</p>
                        <p style="margin-left:120px">        &lt;/<span class="color-red">Route</span>&gt;</p>
                        <p style="margin-left:90px">    &lt;/<span class="color-red">Routes</span>&gt;</p>
                        <p style="margin-left:60px;">&lt;/<span class="color-red">Router</span>&gt;</p>
                        <p style="margin-left:30px;">    );</p>
                        <p>}</p>
                        <br />
                        <p><span class="color-blue">export default</span> <span class="color-red">App</span>;</p>
                        `
                    }}></div>
                </div> 
            </div>
            <div className='mt-4 mb-4'>
                <p>Below is sample of <strong>ErrorPage Component</strong></p>
                <div className='codePalateBox mt-2 mb-5'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p class="color-grey">// components/ErrorPage.js</p>
                            <p><span class="color-blue">import</span> <span class="color-red">React</span> <span class="color-blue">from</span> <span class="color-green">'react'</span>;</p>
                            <br />
                            <p><span class="color-blue">function</span> <span class="color-red">ErrorPage</span> = () => {</p>
                            <p style="margin-left:30px">    <span class="color-blue">return</span> (</p>
                            <p style="margin-left:60px">        &lt;div&gt;</p>
                            <p style="margin-left:90px">        &lt;h2&gt;Error 404: Page not found&lt;/h2&gt;</p>
                            <p style="margin-left:90px">        &lt;p&gt;Sorry, the page you are looking for does not exist.&lt;/p&gt;</p>
                            <p style="margin-left:60px">        &lt;/div&gt;</p>
                            <p style="margin-left:30px">    );</p>
                            <p>};</p>
                            <br />
                            <p><span class="color-blue">export default</span> <span class="color-red">ErrorPage</span>;</p>
                        `
                    }}></div>
                </div> 
            </div>

            <div className='mt-4 mb-4'>
                <p>If you don't have <strong>Nested Routes</strong>, then you can simply setup the <strong>Error Component</strong> like below </p>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>&lt;<span class="color-red">Route</span> <span class="color-pink">path</span>=<span class="color-green">'*'</span> <span class="color-pink">element</span>=<span class="color-green">{&lt;ErrorPage /&gt;}</span> /&gt;</p>
                        `
                    }}></div>
                </div> 
                <p>In the above code, <strong>`path='*'`</strong> is a wildcard path that matches any URL that hasn't been matched by previous routes. This is typically used as a catch-all route to handle 404 errors, indicating that the requested page was not found.</p>
            </div>
        </section>
    )
}