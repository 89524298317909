import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/css/background-clip";

export default function CSSBackgroundClip() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Background Clip in CSS | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/css/background-size',
            'next': '/css/background'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>Background Clip</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <strong>`background-clip`</strong> property in CSS specifies the painting area of the background. In other words, it defines how far the background image or color extends within an element.
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>selector {</p>
                            <p class="ml-30">    <span class="color-green">background-clip</span>: value;</p>
                            <p>}</p>
                        `
                    }}></div>
                </div>
                <h5 className="mt-5 mb-3">Commonly Used Values and Their Uses</h5>
                <p>
                    There are many <strong>`background-clip`</strong> property values. They are -
                </p>
                <ul style={{ 'listStyle': 'decimal' }}>
                    <li>
                        <strong>`border-box`</strong>
                        <p>The background extends to the outer edge of the border (background painted under the border).This is the default value.</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p>div {</p>
                                    <p class="ml-30">   <span class="color-green">background-image</span>: <span class="color-yellow">url</span>(<span class="color-green">'pattern.png'</span>);</p>
                                    <p class="ml-30">    <span class="color-green">background-clip</span>: border-box;</p>
                                    <p>}</p>
                                `
                            }}></div>
                        </div>
                        <div className='outputPalateBox mb-4 mt-3'>
                            <div className='outputPalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <style>
                                        #example2 {
                                            border: 10px dashed black;
                                            padding: 25px;
                                            background: url('../../assets/small-box.png');
                                            background-repeat: no-repeat;
                                            background-clip: border-box;
                                            height: 300px;
                                        }
                                    </style>
                                    <div id="example2">
                                        <h2>Border Box</h2>
                                    </div>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <strong>`padding-box`</strong>
                        <p>The background extends to the outer edge of the padding, but does not go under the border.</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p>div {</p>
                                    <p class="ml-30">   <span class="color-green">background-image</span>: <span class="color-yellow">url</span>(<span class="color-green">'pattern.png'</span>);</p>
                                    <p class="ml-30">    <span class="color-green">background-clip</span>: padding-box;</p>
                                    <p>}</p>
                                `
                            }}></div>
                        </div>
                        <div className='outputPalateBox mb-4 mt-3'>
                            <div className='outputPalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <style>
                                        #example1 {
                                            border: 10px dashed black;
                                            padding: 50px;
                                            background: url('../../assets/small-box.png');
                                            background-repeat: no-repeat;
                                            background-clip: padding-box;
                                            height: 300px;
                                        }
                                    </style>
                                    <div id="example1">
                                        <h2>Padding Box</h2>
                                    </div>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <strong>`content-box`</strong>
                        <p>The background extends to the edge of the content box (background painted only within the content area).</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p>div {</p>
                                    <p class="ml-30">   <span class="color-green">background-image</span>: <span class="color-yellow">url</span>(<span class="color-green">'pattern.png'</span>);</p>
                                    <p class="ml-30">    <span class="color-green">background-clip</span>: content-box;</p>
                                    <p>}</p>
                                `
                            }}></div>
                        </div>
                        <div className='outputPalateBox mb-4 mt-3'>
                            <div className='outputPalate' dangerouslySetInnerHTML={{
                                __html: `
                                <style>
                                    #example3 {
                                        border: 10px dashed black;
                                        padding: 25px;
                                        background: url('../../assets/small-box.png');
                                        background-repeat: no-repeat;
                                        background-clip: content-box;
                                        height: 300px;
                                    }
                                </style>
                                <div id="example3">
                                    <h2>Content Box</h2>
                                </div>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <strong>`text`</strong>
                        <p>The background is clipped to the foreground text. This value is only supported in webkit-based browsers (e.g., Chrome, Safari).</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p>div {</p>
                                    <p class="ml-30">   <span class="color-green">background-image</span>: <span class="color-yellow">url</span>(<span class="color-green">'pattern.png'</span>);</p>
                                    <p class="ml-30">    <span class="color-green">background-clip</span>: text;</p>
                                    <p class="ml-30">    <span class="color-green">-webkit-background-clip</span>: text; <span class="color-grey">/* for compatibility */</span></p>
                                    <p class="ml-30">    <span class="color-green">color</span>: transparent;</p>
                                    <p>}</p>
                                `
                            }}></div>
                        </div>
                        <div className='outputPalateBox mb-4 mt-3'>
                            <div className='outputPalate' dangerouslySetInnerHTML={{
                                __html: `
                                <style>
                                    #example4 {
                                        border: 10px dashed black;
                                        padding: 25px;
                                        background: url('../../assets/one-beautiful.jpg');
                                        background-repeat: no-repeat;
                                        background-clip: text;
                                        -webkit-background-clip: text; /* for compatibility */
                                        color: transparent;
                                        height: 300px;
                                    }
                                </style>
                                <div id="example4">
                                    <h2>Text With Background Image</h2>
                                </div>
                                `
                            }}></div>
                        </div>
                    </li>
                </ul>
            </div>
        </section>
    )
}