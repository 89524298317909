import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/mysql/introduction";

export default function MysqlIntro() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Mysql - Introduction | Aspirant's Home");
        const urls = {
            'next':'/mysql/features'
        }
        path.setPreviousNext(urls);
    },[])

    return (
        <section className='mt-5 mb-5'>
            <h3>Mysql - Introduction</h3>
            <div className='mt-4 mb-5'>
                <p>
                    <strong>`MySQL`</strong> is an open-source <strong>relational database management system</strong> (RDBMS) that is widely used for managing and storing data. It is one of the most popular databases in the world, known for its reliability, performance, and ease of use. <strong>`MySQL`</strong> is commonly used in web applications to store and retrieve data.
                </p>
            </div>
            <div className='mt-5 mb-4'>
                <h5>What is a database ?</h5>
                <div className='mt-4 mb-4'>
                    <p>
                        A database is a structured collection of data that is organized and stored in a way that allows for easy access, retrieval, and management. It is designed to efficiently store and retrieve large amounts of data while ensuring data integrity, security, and scalability.
                    </p>
                    <p>
                        In a database, data is typically organized into tables, which consist of rows and columns. Each row in a table represents a record, and each column represents a specific piece of information or attribute about the record.
                    </p>
                    <p>
                        Databases are commonly used in applications and systems to store and manage various types of data, such as customer information, product details, financial records, and more. They are essential for modern applications that require efficient data storage, retrieval, and manipulation.
                    </p>
                </div>
            </div>
            <div className='mt-5 mb-4'>
                <h5>SQL vs MYSQL</h5>
                <div className='mt-4 mb-4'>
                    <p>
                        SQL (Structured Query Language) and MySQL are related but distinct concepts
                    </p>
                    <table className='table'>
                        <thead>
                            <tr>
                                <th>SQL</th>
                                <th>MySQL</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    SQL is a language used for managing and manipulating relational databases. It is a standard language for interacting with databases and is used to perform tasks such as querying data, updating data, and defining the structure of a database. SQL is not a database itself but rather a language used to interact with databases.
                                </td>
                                <td>
                                    MySQL is a specific implementation of a relational database management system (RDBMS) that uses SQL as its query language. MySQL is one of several popular RDBMSs available today, and it is known for its speed, reliability, and ease of use. It is often used in web applications to store and manage data.
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <p>
                        SQL is a language used for working with databases, while MySQL is a specific database management system that uses SQL as its query language. 
                    </p>
                </div>
            </div>
        </section>
    )
}