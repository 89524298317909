import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/css/background-color";

export default function CSSBackgroundColor() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Background Color in CSS | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/css/colors/hsl',
            'next': '/css/background-image'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>CSS - Backgrounds</h3>
            <div className='mt-4 mb-5'>
                <p>
                    CSS backgrounds are used to set the background of an element on a webpage. This includes setting colors, images, gradients, and various background properties to control the appearance and behavior of backgrounds.
                </p>
                <p>
                    We have many background properties to set the background of an element -
                </p>
                <ul style={{ 'listStyle': 'disc' }}>
                    <li>background-color</li>
                    <li><Link to="/css/background-image" className='noUnderline'>background-image</Link></li>
                    <li><Link to="/css/background-repeat" className='noUnderline'>background-repeat</Link></li>
                    <li><Link to="/css/background-attachment" className='noUnderline'>background-attachment</Link></li>
                    <li><Link to="/css/background-position" className='noUnderline'>background-position</Link></li>
                    <li><Link to="/css/background-origin" className='noUnderline'>background-origin</Link></li>
                    <li><Link to="/css/background-size" className='noUnderline'>background-size</Link></li>
                    <li><Link to="/css/background-clip" className='noUnderline'>background-clip</Link></li>
                    <li><Link to="/css/background" className='noUnderline'>background (shorrthand)</Link></li>
                </ul>
                <p>
                    We will discuss all the background properties here.
                </p>

                <h5 className="mt-5 mb-3">CSS background-color</h5>
                <p>
                    The <strong>`background-color`</strong> property in CSS is used to set the background color of an element. This property can accept various color values, including named colors, hex values, RGB, RGBA, HSL, and HSLA. It has a basic syntax -
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>selector {</p>
                            <p class="ml-30">    <span class="color-green">background-color</span>: color;</p>
                            <p>}</p>
                        `
                    }}></div>
                </div>
                <h5 className="mt-5 mb-3">How Background Color Looks Like</h5>
                <div className='outputPalateBox mb-4'>
                    <div className='outputPalate' dangerouslySetInnerHTML={{
                        __html: `
                            <style>
                                p.colorName {
                                    background-color : yellow;
                                }
                                .colorNameDiv{
                                    background-color : lightblue;
                                    height : 100px;
                                    align-content: center;
                                    text-align: center;
                                }
                            </style>
                            <h6>P Tag</h6>
                            <p class="colorName">This is a example text</p>
                            <h6>Div Tag</h6>
                            <div class="colorNameDiv">This is a example text in div</p>
                        `
                    }}></div>
                </div>

                <h6 className='mt-5 mb-3'>Examples</h6>
                <ul style={{ listStyle: 'decimal' }}>
                    <li>
                        <strong>Named Colors</strong>
                        <p>Predefined color names, such as <strong>`red`</strong>, <strong>`blue`</strong>, <strong>`green`</strong>, etc.</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p>p {</p>
                                    <p class="ml-30">    <span class="color-green">background-color</span>: red;</p>
                                    <p>}</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <strong>Hexadecimal Colors</strong>
                        <p>A six-digit code preceded by <strong>`#`</strong>, representing red, green, and blue components.</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p>div {</p>
                                    <p class="ml-30">    <span class="color-green">background-color</span>: <span class="color-pink">#ff5733</span>;</p>
                                    <p>}</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <strong>RGB Colors</strong>
                        <p>Defines colors using the Red-Green-Blue model.</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p>h1 {</p>
                                    <p class="ml-30">    <span class="color-green">background-color</span>: <span class="color-yellow">rgb</span>(<span class="color-pink">255</span>, <span class="color-pink">0</span>, <span class="color-pink">0</span>);</p>
                                    <p>}</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <strong>RGBA Colors</strong>
                        <p>Similar to RGB, but includes an alpha value for opacity.</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p>section {</p>
                                    <p class="ml-30">    <span class="color-green">background-color</span>: <span class="color-yellow">rgb</span>(<span class="color-pink">0</span>, <span class="color-pink">0</span>, <span class="color-pink">255</span>, <span class="color-pink">0.5</span>);</p>
                                    <p>}</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <strong>HSL Colors</strong>
                        <p>Defines colors using the Hue-Saturation-Lightness model.</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p>article {</p>
                                    <p class="ml-30">    <span class="color-green">background-color</span>: <span class="color-yellow">hsl</span>(<span class="color-pink">120</span>, <span class="color-pink">100%</span>, <span class="color-pink">50%</span>);</p>
                                    <p>}</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <strong>HSLA Colors</strong>
                        <p>Similar to HSL, but includes an alpha value for opacity.</p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p>article {</p>
                                    <p class="ml-30">    <span class="color-green">background-color</span>: <span class="color-yellow">hsla</span>(<span class="color-pink">240</span>, <span class="color-pink">100%</span>, <span class="color-pink">50%</span>, <span class="color-pink">0.3</span>);</p>
                                    <p>}</p>
                                `
                            }}></div>
                        </div>
                    </li>
                </ul>
            </div>
        </section>
    )
}