const requireModule = require.context("./",true,/\.js$/); //console.log(requireModule.keys())
const api = {};
const routes = [];
requireModule.keys().forEach((fileName) => {
    if (fileName === "./Index.js") return;
    const moduleName = fileName.replace(/(\.\/|\.js)/g, "");
    const module = requireModule(fileName);
    if (module.default) {
        api[moduleName] = module.default;
    } else {
        api[moduleName] = module;
    }
    if (module.pageUrl) {
        routes.push({ path: module.pageUrl(), component: moduleName });
    }
});

api['Routes'] = routes;
export default api;
