import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/mysql/show-privileges";

export default function MysqlShowPriviledge() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Mysql - Show Privileges To User | Aspirant's Home");
        const urls = {
            'previous': '/mysql/grant-privileges',
            'next': '/mysql/revoke-privileges'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>Mysql - Show Privileges To User</h3>
            <div className='mt-4 mb-5'>
                <p>
                    In MySQL, you can use the <strong>`SHOW GRANTS`</strong> statement to display the privileges granted to a specific user or all users. This statement shows the privileges that have been explicitly granted to the user, as well as any privileges that have been granted through roles or the <strong>`GRANT OPTION`</strong>.
                </p>
                <p>
                    Here's the basic syntax:
                </p>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-blue">SHOW</span> GRANTS <span class="color-blue">FOR</span> <span class="color-green">'username'</span>@<span class="color-green">'host'</span>;</p>
                        `
                    }}></div>
                </div>
                <p>
                    Where, The username and host is the user account for which you want to show privileges.
                </p>
                <p>
                    For example, to show the privileges granted to a user named <strong>`myuser`</strong> connecting from <strong>`localhost`</strong>, you would use the following SQL statement:
                </p>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-blue">SHOW</span> GRANTS <span class="color-blue">FOR</span> <span class="color-green">'myuser'</span>@<span class="color-green">'localhost'</span>;</p>
                        `
                    }}></div>
                </div>
                <p>
                    If you want to show grants for the current user, you can use the <strong>`CURRENT_USER`</strong> keyword:
                </p>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-blue">SHOW</span> GRANTS <span class="color-blue">FOR</span> <span class="color-yellow">CURRENT_USER</span>;</p>
                        `
                    }}></div>
                </div>
                <p>
                    This statement will return a result set showing the privileges granted to the specified user account.
                </p>
            </div>
        </section>
    )
}