import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../../Context";
import { Link } from 'react-router-dom';
export const pageUrl = () => "/mysql/string-functions/repeat";

export default function REPEAT() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Mysql - String Functions - REPEAT() | Aspirant's Home");
        path.setPreviousNext({});
    }, [])

    return (
        <>
            <section class='clearfix mb-3'>
                <span className='float-start'>
                    <Link to='/mysql/string-functions' onClick={() => path.setPathName('/mysql/string-functions')} className='btn btn-primary'> <i class="fa-solid fa-less-than"></i>&nbsp; Back to String Functions </Link>
                </span>
            </section>
            <section className='mt-5 mb-5'>
                <h3>Mysql - REPEAT() Function</h3>
                <div className='mt-4 mb-5'>
                    <p>
                        The <strong>`REPEAT()`</strong> function in MySQL is used to repeat a string a specified number of times. This function is useful when you need to create a string that consists of repeated instances of a given substring.
                    </p>
                    <h5>Syntax</h5>
                    <div className='codePalateBox mt-2 mb-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                                <p>REPEAT(string, count)</p>
                            `
                        }}></div>
                    </div>
                    <p>
                        Where, <strong>`string`</strong> is the string to be repeated and <strong>`count`</strong> is the number of times to repeat the string.
                    </p>
                    <h5>Examples</h5>
                    <div className='codePalateBox mt-2 mb-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                                <p><span class="color-blue">SELECT</span> REPEAT(<span class="color-green">'abc'</span>, <span class="color-pink">3</span>) <span class="color-blue">AS</span> repeated_string;</p>
                            `
                        }}></div>
                    </div>
                    <p>
                        In this example, the string 'abc' is repeated 3 times, resulting in 'abcabcabc'.
                    </p>
                </div>
                <div className='mt-5 mb-5'>
                    <h5>REPEAT() with MySQL Table</h5>
                    <div className='mt-3 mb-2'>
                        <p>
                            Lets consider a table <strong>`customers`</strong> with <strong>`customer_name`</strong> and <strong>`points`</strong>.
                        </p>
                        <table className='table' style={{border: '1px dashed #ccc'}}> 
                            <thead>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>id</th>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>customer_name</th>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>points</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>1</td>
                                    <td style={{border: '1px dashed #ccc'}}>Alice Johnson</td>
                                    <td style={{border: '1px dashed #ccc'}}>150</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>2</td>
                                    <td style={{border: '1px dashed #ccc'}}>Bob Smith</td>
                                    <td style={{border: '1px dashed #ccc'}}>75</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>3</td>
                                    <td style={{border: '1px dashed #ccc'}}>Charlie Brown</td>
                                    <td style={{border: '1px dashed #ccc'}}>200</td>
                                </tr>
                            </tbody>
                        </table>
                        <p>
                            If we want to create a visual representation of loyalty points using stars (<strong>`*`</strong>) where each star represents 25 points.
                        </p>
                        <div className='codePalateBox mt-2 mb-2'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-blue">SELECT</span> customer_id, customer_name, points, REPEAT(<span class="color-green">'*'</span>, points / <span class="color-pink">25</span>) <span class="color-blue">AS</span> loyalty_stars <span class="color-blue">FROM</span> customers;</p>
                                `
                            }}></div>
                        </div>
                        <p>
                            This query will return:
                        </p>
                        <table className='table' style={{border: '1px dashed #ccc'}}> 
                            <thead>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>id</th>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>customer_name</th>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>points</th>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>loyalty_stars</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>1</td>
                                    <td style={{border: '1px dashed #ccc'}}>Alice Johnson</td>
                                    <td style={{border: '1px dashed #ccc'}}>150</td>
                                    <td style={{border: '1px dashed #ccc'}}>******</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>2</td>
                                    <td style={{border: '1px dashed #ccc'}}>Bob Smith</td>
                                    <td style={{border: '1px dashed #ccc'}}>75</td>
                                    <td style={{border: '1px dashed #ccc'}}>***</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>3</td>
                                    <td style={{border: '1px dashed #ccc'}}>Charlie Brown</td>
                                    <td style={{border: '1px dashed #ccc'}}>200</td>
                                    <td style={{border: '1px dashed #ccc'}}>********</td>
                                </tr>
                            </tbody>
                        </table>
                        <p>
                            This example demonstrates how the <strong>`REPEAT()`</strong> function can be used to visually represent customer loyalty points, providing an intuitive and easy-to-understand display of customer rewards.
                        </p>
                    </div>
                </div>
            </section>
        </>
    )
}