import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/html/lists";

export default function HTMLLists() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("HTML - Lists | Aspirant's Home");
        const urls = {
            'previous': '/html/table-colspan-rowspan',
            'next': '/html/unordered-lists'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>HTML - Lists</h3>
            <div className='mt-4 mb-5'>
                <p>
                    HTML lists are used to group a set of related items in a specific order. There are three main types of lists in HTML:
                </p>
                <ul style={{ listStyle: 'decimal' }}>
                    <li>Unordered List (<strong>`&lt;ul&gt;`</strong>)</li>
                    <li>Ordered List (<strong>`&lt;ol&gt;`</strong>)</li>
                    <li>Description List (<strong>`&lt;dl&gt;`</strong>)</li>
                </ul>
                <h6 className='mt-5 mb-3'>Example:</h6>
                <div className='row' style={{border:'1px solid #000',padding:'20px'}}>
                    <div className='col-4'>
                        <h5>Unordered List</h5>
                        <ul>
                            <li>Item 1</li>
                            <li>Item 2</li>
                            <li>Item 3</li>
                        </ul>
                    </div>
                    <div className='col-4'>
                        <h5>Ordered List</h5>
                        <ol>
                            <li>Item 1</li>
                            <li>Item 2</li>
                            <li>Item 3</li>
                        </ol>
                    </div>
                    <div className='col-4'>
                        <h5>Description List</h5>
                        <dl>
                            <dt>Term 1</dt>
                            <dd>Description 1</dd>
                            <dt>Term 2</dt>
                            <dd>Description 2</dd>
                        </dl>
                    </div>
                </div>
                <h5 className='mt-5 mb-3'>Unordered List (`&lt;ul&gt;`)</h5>
                <p>
                    An unordered list is used to group a set of related items in no particular order. Items in an unordered list are typically displayed with bullet points.
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;ul&gt;</p>
                        <p class="ml-30">    &lt;li&gt;Item 1&lt;/li&gt;</p>
                        <p class="ml-30">    &lt;li&gt;Item 2&lt;/li&gt;</p>
                        <p class="ml-30">    &lt;li&gt;Item 3&lt;/li&gt;</p>
                        <p>&lt;/ul&gt;</p>
                        `
                    }}></div>
                </div>
                <h5 className='mt-5 mb-3'>Ordered List (`&lt;ol&gt;`)</h5>
                <p>
                    An ordered list is used to group a set of related items in a specific order. Items in an ordered list are typically displayed with numbers or letters.
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;ol&gt;</p>
                        <p class="ml-30">    &lt;li&gt;Item 1&lt;/li&gt;</p>
                        <p class="ml-30">    &lt;li&gt;Item 2&lt;/li&gt;</p>
                        <p class="ml-30">    &lt;li&gt;Item 3&lt;/li&gt;</p>
                        <p>&lt;/ol&gt;</p>
                        `
                    }}></div>
                </div>
                <h5 className='mt-5 mb-3'>Description List (`&lt;dl&gt;`)</h5>
                <p>
                    A description list is used to group a set of related items in a list of terms and their descriptions. This is useful for defining terms or concepts.
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;dl&gt;</p>
                        <p class="ml-30">    &lt;dt&gt;Term 1&lt;/dt&gt;</p>
                        <p class="ml-30">    &lt;dd&gt;Description 1&lt;/dd&gt;</p>
                        <p class="ml-30">    &lt;dt&gt;Term 2&lt;/dt&gt;</p>
                        <p class="ml-30">    &lt;dd&gt;Description 2&lt;/dd&gt;</p>
                        <p>&lt;/dl&gt;</p>
                        `
                    }}></div>
                </div>
            </div>
        </section>
    )
}