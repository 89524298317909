import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../../Context";
export const pageUrl = () => "/react-js/hooks/useeffect";

export default function ReactHookUseEffect() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("React Hooks - useEffect() Hooks | Aspirant's Home");
        const urls = {
            'previous': '/react-js/hooks/usestate',
            'next':'/react-js/hooks/usecontext'
        }
        path.setPreviousNext(urls);
    },[])


    return (
        <section className='mt-5 mb-5'>
            <h3>React useEffect Hook</h3>
            <div className='mt-4 mb-4'>
                <p>
                    The <strong>`useEffect`</strong> hook in React allows you to perform side effects in function components. Side effects can be any code that needs to interact with the outside world, such as fetching data, subscribing to events, or manually changing the DOM.
                </p>
                <p>
                    The useEffect hook enables you to perform fetching data, directly updating the DOM, and using timers. It accepts two arguments, with the second argument being optional like <strong>useEffect(&lt;function&gt;, &lt;dependency&gt;)</strong>.
                </p>
                <p>
                    The first argument is a function that contains the code for the side effect. This function will be called after the component has rendered. The second argument is an optional array of dependencies. If provided, the effect will only be re-run if one of the dependencies has changed since the last render. If the dependencies array is empty, the effect will only run once after the initial render.
                </p>
            </div>
            <div className='mt-5 mb-5'>
                <h5>Import <strong>`useEffect`</strong></h5>
                <p className='mt-4'>To use the <strong>`useEffect`</strong> Hook, we first need to import it into our component.</p>
                <div className='codePalateBox mt-2 mb-5'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-blue">import</span> { <span class="color-red">useEffect</span> } <span class="color-blue">from</span> <span class="color-green">'react'</span>;</p>
                        `
                    }}></div>
                </div>
            </div>
            <div className='mt-5 mb-5'>
                <p>Here is an example of illustrate the usage of <strong>`useEffect`</strong></p>
                <div className='codePalateBox mt-2 mb-5'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-blue">import</span> <span class="color-red">React</span>, { <span class="color-red">useState</span>, <span class="color-red">useEffect</span> } <span class="color-blue">from</span> <span class="color-green">'react'</span>;</p>
                            <br />
                            <p style="margin-left:30px"><span class="color-blue">function</span> <span class="color-red">ExampleComponent</span>() {</p>
                            <p style="margin-left:60px">    <span class="color-blue">const</span> [count, setCount] = <span class="color-red">useState</span>(0);</p>
                            <br />    
                            <p style="margin-left:60px">    <span class="color-red">useEffect</span>(() => {</p>
                            <p style="margin-left:90px">        <span class="color-blue">document</span>.title = &#96;You clicked &#36;{count} times&#96;;</p>
                            <p style="margin-left:60px">    }, [count]);</p>
                            <br />    
                            <p style="margin-left:60px">    <span class="color-blue">return</span> (</p>
                            <p style="margin-left:90px">        &lt;div&gt;</p>
                            <p style="margin-left:120px">             &lt;p&gt;You clicked {count} times&lt;/p&gt;</p>
                            <p style="margin-left:120px">             &lt;button <span class="color-red">onClick</span>=<span class="color-green">{() => setCount(count + 1)}</span>&gt;Click me&lt;/button&gt;</p>
                            <p style="margin-left:90px">        &lt;/div&gt;</p>
                            <p style="margin-left:60px">    );</p>
                            <p>}</p>
                            <br />
                            <p><span class="color-blue">export default</span> <span class="color-red">ExampleComponent</span>;</p>
                        
                        `
                    }}></div>
                </div>
                <p>
                    In this example, the <strong>`useEffect`</strong> hook is used to update the document title whenever the <strong>`count`</strong> state changes. The effect runs after every render because <strong>`count`</strong> is included in the dependencies array. If you don't need to pass any dependencies, you can pass an empty array, which means the effect only runs once when the page loads for the first time.
                </p>
            </div>
        </section>
    )
}
