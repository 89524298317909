import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/html/paragraphs";

export default function HTMLParagraphs() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("HTML - Paragraphs | Aspirant's Home");
        const urls = {
            'previous': '/html/headings',
            'next': '/html/styles'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>HTML - Paragraphs</h3>
            <div className='mt-4 mb-5'>
                <p>
                    HTML paragraphs are used to define blocks of text that are separated from other blocks. They are represented by the <strong>`&lt;p&gt;`</strong> tag. Paragraphs are fundamental elements in HTML documents and are used to structure and organize text content.
                </p>
                <h5 className='mt-5 mb-3'>Basic Syntax</h5>
                <p>
                    A paragraph is defined using the <strong>`&lt;p&gt;`</strong> tag. The text content is placed between the opening <strong>`&lt;p&gt;`</strong> tag and the closing <strong>`&lt;/p&gt;`</strong> tag.
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>&lt;p&gt;This is a paragraph of text.&lt;/p&gt;</p>
                        `
                    }}></div>
                </div>
                <h5 className='mt-5 mb-3'>Special Characters in Paragraphs</h5>
                <p>
                    When writing paragraphs, you might need to include special characters like ampersands (<strong>`&`</strong>), less-than (<strong>`&lt;`</strong>), and greater-than (<strong>`&gt;`</strong>) symbols. These characters have special meanings in HTML and need to be encoded to display correctly.
                </p>
                <pre>{`Use &lt; and &gt; to display less-than and greater-than symbols.`}</pre>
                <pre>{`Use &amp; to display an ampersand symbol.`}</pre>
                <h5 className='mt-5 mb-3'>Line Breaks</h5>
                <p>
                    If you need to force a line break within a paragraph, you can use the <strong>`&lt;br&gt;`</strong> tag. The <strong>`&lt;br&gt;`</strong> tag is an empty element and does not have a closing tag.
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>&lt;p&gt;This is the first line.&lt;br&gt;This is the second line.&lt;/p&gt;</p>
                        `
                    }}></div>
                </div>
                <p>Output will be - </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>This is the first line.<br>This is the second line.</p>
                        `
                    }}></div>
                </div>
            </div>
        </section>
    )
}