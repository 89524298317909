import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../../Context";
import { Link } from 'react-router-dom';
export const pageUrl = () => "/mysql/arithmetic-operators/division";

export default function Division() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Mysql - Arithmetic Operators - Division(/) | Aspirant's Home");
        path.setPreviousNext({});
    }, [])

    return (
        <>
            <section class='clearfix mb-3'>
                <span className='float-start'>
                    <Link to='/mysql/arithmetic-operators' onClick={() => path.setPathName('/mysql/arithmetic-operators')} className='btn btn-primary'> <i class="fa-solid fa-less-than"></i>&nbsp; Back to Arithmetic Operators </Link>
                </span>
            </section>
            <section className='mt-5 mb-5'>
                <h3>Mysql - Division(/) Operator</h3>
                <div className='mt-4 mb-5'>
                    <p>
                        In MySQL, the division operator (/) is used to divide one numeric value by another. It can be used in various contexts, such as in SELECT queries, UPDATE statements, and when defining columns in CREATE TABLE statements.
                    </p>
                    <p>
                        Here's a basic example of using the division operator in a SELECT query:
                    </p>
                    <div className='codePalateBox mt-2 mb-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                                <p><span class="color-blue">SELECT</span> <span class="color-pink">10</span> / <span class="color-pink">2</span>;</p>
                            `
                        }}></div>
                    </div>
                    <p>
                        This query will return the result of dividing 10 by 2, which is 5.
                    </p>
                </div>
                <div className='mt-5 mb-5'>
                    <h5>Division(/) with MySQL Table</h5>
                    <div className='mt-3 mb-2'>
                        <p>
                            Consider a table <strong>`sales`</strong> with columns <strong>`product_name`</strong>, <strong>`total_revenue`</strong>, and <strong>`quantity_sold`</strong>.
                        </p>
                        <table className='table' style={{border: '1px dashed #ccc'}}> 
                            <thead>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>id</th>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>product_name</th>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>total_revenue</th>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>quantity_sold</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>1</td>
                                    <td style={{border: '1px dashed #ccc'}}>Laptop</td>
                                    <td style={{border: '1px dashed #ccc'}}>50000.00</td>
                                    <td style={{border: '1px dashed #ccc'}}>50</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>2</td>
                                    <td style={{border: '1px dashed #ccc'}}>Phone</td>
                                    <td style={{border: '1px dashed #ccc'}}>35000.00</td>
                                    <td style={{border: '1px dashed #ccc'}}>100</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>3</td>
                                    <td style={{border: '1px dashed #ccc'}}>Tablet</td>
                                    <td style={{border: '1px dashed #ccc'}}>22500.00</td>
                                    <td style={{border: '1px dashed #ccc'}}>75</td>
                                </tr>
                            </tbody>
                        </table>
                        <p>
                            If you want to calculate the average revenue per unit sold (total_revenue / quantity_sold), you can use the division operator as follows:
                        </p>
                        <div className='codePalateBox mt-2 mb-2'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-blue">SELECT</span> id, product_name, total_revenue, quantity_sold, (total_revenue / quantity_sold) <span class="color-blue">AS</span> average_revenue_per_unit <span class="color-blue">FROM</span> sales;</p>
                                `
                            }}></div>
                        </div>
                        <p>
                            This query will return:
                        </p>
                        <table className='table' style={{border: '1px dashed #ccc'}}> 
                            <thead>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>id</th>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>product_name</th>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>total_revenue</th>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>quantity_sold</th>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>average_revenue_per_unit</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>1</td>
                                    <td style={{border: '1px dashed #ccc'}}>Laptop</td>
                                    <td style={{border: '1px dashed #ccc'}}>50000.00</td>
                                    <td style={{border: '1px dashed #ccc'}}>50</td>
                                    <td style={{border: '1px dashed #ccc'}}>1000.00</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>2</td>
                                    <td style={{border: '1px dashed #ccc'}}>Phone</td>
                                    <td style={{border: '1px dashed #ccc'}}>35000.00</td>
                                    <td style={{border: '1px dashed #ccc'}}>100</td>
                                    <td style={{border: '1px dashed #ccc'}}>350.00</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>3</td>
                                    <td style={{border: '1px dashed #ccc'}}>Tablet</td>
                                    <td style={{border: '1px dashed #ccc'}}>22500.00</td>
                                    <td style={{border: '1px dashed #ccc'}}>75</td>
                                    <td style={{border: '1px dashed #ccc'}}>300.00</td>
                                </tr>
                            </tbody>
                        </table>
                        <p>
                            In this example, the expression <strong>`(total_revenue / quantity_sold)`</strong> uses the division operator (/) to divide the <strong>`total_revenue`</strong> by the <strong>`quantity_sold`</strong> for each row, calculating the average revenue per unit sold. The result is displayed in a new column named <strong>`average_revenue_per_unit`</strong>.
                        </p>
                    </div>
                </div>
            </section>
        </>
    )
}