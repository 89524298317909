import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/css/padding";

export default function Padding() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Padding in CSS | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/css/margin/collapse',
            'next': '/css/height/width'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>What is Padding ?</h3>
            <div className='mt-4 mb-5'>
                <p>
                    Padding is the space between the content of an element and its border. It is used to create space inside an element, pushing the content away from the edges. Padding is defined using CSS properties, and it can be set for all four sides of an element (<strong>`top`</strong>, <strong>`right`</strong>, <strong>`bottom`</strong>, <strong>`left`</strong>).
                </p>
                <div className='outputPalateBox mb-4 mt-3' style={{'border':'none'}}>
                    <div className='outputPalate' dangerouslySetInnerHTML={{
                        __html: `
                            <style>
                                .fullBox{
                                    background: #CCC;
                                    border: 1px solid black;
                                }
                                .textArea{
                                    padding: 40px;
                                    text-align: center;
                                }
                            </style>
                            <div class="fullBox">
                                <div class="textArea">This is text part of this box</div>
                            </div>
                        `
                    }}></div>
                </div>

                <h5 className="mt-5 mb-3">Padding Properties</h5>
                <p>
                    Here are the properties of padding.
                </p>
                <ul style={{ listStyle: 'decimal' }}>
                    <li><strong>`padding-top`</strong>: Sets the padding space on the top side of an element.</li>
                    <li><strong>`padding-right`</strong>: Sets the padding space on the right side of an element.</li>
                    <li><strong>`padding-bottom`</strong>: Sets the padding space on the bottom side of an element.</li>
                    <li><strong>`padding-left`</strong>: Sets the padding space on the left side of an element.</li>
                    <li><strong>`padding`</strong>: A shorthand property for setting all four padding values at once.</li>
                </ul>

                <h5 className="mt-5 mb-3">Some Examples of Padding Properties</h5>

                <p>
                    To set a uniform padding on all four sides of an element.
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>&lt;style&gt;</p>
                            <p class="ml-30">    .box1 {</p>
                            <p class="ml-60">        <span class="color-green">background-color</span>: <span class="color-pink">#3498db</span>;</p>
                            <p class="ml-60">        <span class="color-green">padding</span>: <span class="color-pink">70px</span>; <span class="color-grey">/* 70px padding on all sides */</span></p>
                            <p class="ml-60">        <span class="color-green">text-align</span>: center;</p>
                            <p class="ml-30">    }</p>
                            <p>&lt;/style&gt;</p>
                            <p>&lt;div <span class="color-pink">class</span>=<span class="color-green">"box1"</span>&gt;This has a uniform 70px padding.&lt;/div&gt;</p>
                        `
                    }}></div>
                </div>
                <p>
                    Output will be the following -
                </p>
                <div className='outputPalateBox mb-4 mt-3' style={{'padding':'0'}}>
                    <div className='outputPalate' dangerouslySetInnerHTML={{
                        __html: `
                        <style>
                            .box1 {
                                background-color: #3498db;
                                padding: 70px;
                                text-align: center;
                            }
                        </style>
                        <div class="box1">This has a uniform 70px padding.</div>
                        `
                    }}></div>
                </div>

                <p>
                    You can set different padding to each indivisual side using these indivisual side properties like <strong>`padding-top`</strong>, <strong>`padding-right`</strong>, <strong>`padding-bottom`</strong> and <strong>`padding-left`</strong>.
                </p>

                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;style&gt;</p>
                        <p class="ml-30">    .example {</p>
                        <p class="ml-60">        <span class="color-green">padding-top</span>: <span class="color-pink">30px</span>;</p>
                        <p class="ml-60">        <span class="color-green">padding-right</span>: <span class="color-pink">85px</span>;</p>
                        <p class="ml-60">        <span class="color-green">padding-bottom</span>: <span class="color-pink">30px</span>;</p>
                        <p class="ml-60">        <span class="color-green">padding-left</span>: <span class="color-pink">85px</span>;</p>
                        <p class="ml-30">    }</p>
                        <p>&lt;/style&gt;</p>
                        <p>&lt;div <span class="color-pink">class</span>=<span class="color-green">"example"</span>&gt;This is a example of indivisual side padding properties which explain different padding to each side. This area is 30px top padding, 85px right padding, 30px bottom padding and 85px left padding.&lt;/div&gt;</p>
                        `
                    }}></div>
                </div>
                <p>
                    Output will be the following -
                </p>
                <div className='outputPalateBox mb-4 mt-3' style={{'padding':'0'}}>
                    <div className='outputPalate' dangerouslySetInnerHTML={{
                        __html: `
                            <style>
                                .example {
                                    padding-top: 30px;
                                    padding-right: 85px;
                                    padding-bottom: 30px;
                                    padding-left: 85px;
                                }
                            </style>
                            <div class="example">This is a example of indivisual side padding properties which explain different padding to each side. This area is 30px top padding, 85px right padding, 30px bottom padding and 85px left padding.</div>
                        `
                    }}></div>
                </div>

                <h5 className="mt-5 mb-3">Shorthand Property of Padding</h5>
                <p>
                    Using shorthand property you can set padding to indivisual sides. Below are the some examples of shorthand property value.
                    The shorthand property can take one, two, three, or four values:
                </p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>
                        <strong>One Value</strong>: Applies the same padding to all four sides.
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p>.example {</p>
                                    <p class="ml-30">    <span class="color-green">padding</span>: <span class="color-pink">10px</span>; <span class="color-grey">/* 10px for all sides */</span></p>
                                    <p>}</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <strong>Two Values</strong>: First value for top and bottom, second value for right and left.
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p>.example {</p>
                                    <p class="ml-30">    <span class="color-green">padding</span>: <span class="color-pink">10px 15px</span>; <span class="color-grey">/* 10px top and bottom, 15px right and left */</span></p>
                                    <p>}</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <strong>Three Values</strong>: First value for top, second value for right and left, third value for bottom.
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p>.example {</p>
                                    <p class="ml-30">    <span class="color-green">padding</span>: <span class="color-pink">10px 15px 20px</span>; <span class="color-grey">/* 10px top, 15px right and left, 20px bottom */</span></p>
                                    <p>}</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <strong>Four Values</strong>: First value for top and bottom, second value for right and left.
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p>.example {</p>
                                    <p class="ml-30">    <span class="color-green">padding</span>: <span class="color-pink">10px 15px 20px 25px</span>; <span class="color-grey">/* 10px top, 15px right, 20px bottom, 25px left */</span></p>
                                    <p>}</p>
                                `
                            }}></div>
                        </div>
                    </li>
                </ul>
            </div>
        </section>
    )
}