import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/node-js/url-module";

export default function URLModule() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Node Js - URL Module | Aspirant's Home");
        const urls = {
            'previous': '/node-js/file-system',
            'next': '/node-js/npm-module'
        }
        path.setPreviousNext(urls);
    }, [])
    
    return (
        <section className='mt-5 mb-5'>
            <h3 className='mb-4'>Node js URL Module</h3>
            <p>The Node.js <strong>`url`</strong> module provides utilities for URL resolution and parsing. It allows you to work with URLs in a convenient way, parsing them into their various components (such as protocol, hostname, path, query parameters, etc.) or formatting them from their components.</p>

            <p>Here's a basic overview of the <strong>`url`</strong> module and how you can use it with examples:</p>
            <div className='mt-5 mb-3'>
                <p className='mb-4'><strong>Parsing a URL:</strong></p>
                <p>You can use the <strong>`url.parse()`</strong> method to parse a URL string into its components:</p>
                <div className='codePalateBox mt-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{ __html: "<p><span class='color-blue'>const</span> url = <span class='color-yellow'>require</span>(<span class='color-green'>'url'</span>);</p><p><span class='color-blue'>const</span> urlString = <span class='color-green'>'https://www.example.com/path/to/resource?query=123'</span>;</p><p><span class='color-blue'>const</span> parsedUrl = url.<span class='color-red'>parse</span>(urlString, <span class='color-blue'>true</span>);</p><p><span class='color-pink'>console</span>.<span class='color-red'>log</span>(parsedUrl.protocol); <span class='color-grey'>// Output: 'https:'</span></p><p><span class='color-pink'>console</span>.<span class='color-red'>log</span>(parsedUrl.hostname); <span class='color-grey'>// Output: 'www.example.com'</span></p><p><span class='color-pink'>console</span>.<span class='color-red'>log</span>(parsedUrl.pathname); <span class='color-grey'>// Output: '/path/to/resource'</span></p><p><span class='color-pink'>console</span>.<span class='color-red'>log</span>(parsedUrl.query); <span class='color-grey'>   // Output: { query: '123' }</span></p>" }}>
                    </div>
                </div>
            </div>
            <div className='mt-5 mb-3'>
                <p className='mb-4'><strong>Formatting a URL:</strong></p>
                <p>You can use the <strong>`url.format()`</strong> method to parse a URL string into its components:</p>
                <div className='codePalateBox mt-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{ __html: "<p><span class='color-blue'>const</span> url = <span class='color-yellow'>require</span>(<span class='color-green'>'url'</span>);</p><p><span class='color-blue'>const</span> parsedUrl = {</p><p style='margin-left:30px'> <span class='color-pink'>protocol</span>: <span class='color-green'>'https:'</span>,</p><p style='margin-left:30px'> <span class='color-pink'> hostname</span>: <span class='color-green'>'www.example.com'</span>,</p><p style='margin-left:30px'>  <span class='color-pink'>pathname</span>: <span class='color-green'>'/path/to/resource'</span>,</p><p style='margin-left:30px'> <span class='color-pink'>query</span>: { <span class='color-pink'>query</span>: <span class='color-green'>'123'</span> }</p><p>};</p><p><span class='color-blue'>const</span> formattedUrl = url.<span class='color-red'>format</span>(parsedUrl);</p><p><span class='color-pink'>console</span>.<span class='color-red'>log</span>(formattedUrl); <span class='color-grey'>// Output: 'https://www.example.com/path/to/resource?query=123'</span></p>" }}>
                    </div>
                </div>
            </div>
            <div className='mt-5 mb-3'>
                <p className='mb-4'><strong>Resolving a URL:</strong></p>
                <p>You can use the <strong>`url.resolve()`</strong> method to resolve a relative URL against a base URL</p>
                <div className='codePalateBox mt-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{ __html: "<p><span class='color-blue'>const</span> url = <span class='color-yellow'>require</span>(<span class='color-green'>'url'</span>);</p><p><span class='color-blue'>const</span> baseUrl = <span class='color-green'>'https://www.example.com'</span>;</p><p><span class='color-blue'>const</span> relativeUrl = <span class='color-green'>'/path/to/resource'</span>;</p><p><span class='color-blue'>const</span> resolvedUrl = url.<span class='color-red'>resolve</span>(baseUrl, relativeUrl);</p><p><span class='color-pink'>console</span>.<span class='color-red'>log</span>(resolvedUrl); <span class='color-grey'>// Output: 'https://www.example.com/path/to/resource'</span></p>" }}>
                    </div>
                </div>
            </div>
            <p className='mt-5'>These examples demonstrate how you can use the <strong>`url`</strong> module in Node.js to work with URLs, parse them into their components, format them from components, and resolve relative URLs against a base URL.</p>
        </section>
    )
}
