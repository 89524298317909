import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/html/table-colgroup";

export default function HTMLTableColgroup() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("HTML - Table Colgroup | Aspirant's Home");
        const urls = {
            'previous': '/html/table-styling',
            'next': '/html/table-colspan-rowspan'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>HTML - Table Colgroup</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <strong>`&lt;colgroup&gt;`</strong> tag in HTML is used to group one or more columns in a table for styling purposes. This tag allows you to apply styles or attributes to entire columns rather than individual cells. Within the <strong>`&lt;colgroup&gt;`</strong> tag, you can use the <strong>`&lt;col&gt;`</strong> tag to specify the properties of each column.
                </p>
                <h5 className='mt-5 mb-3'>Syntax and Structure</h5>
                <p>
                    Here's the basic structure of using <strong>`&lt;colgroup&gt;`</strong> and <strong>`&lt;col&gt;`</strong> tags:
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;table class="table"&gt;</p>
                        <p class="ml-30">    &lt;colgroup&gt;</p>
                        <p class="ml-60">        &lt;col <span class="color-pink">span</span>=<span class="color-green">"1"</span> <span class="color-pink">style</span>="<span class="color-green">background-color: #f2f2f2;</span>"&gt;</p>
                        <p class="ml-60">        &lt;col <span class="color-pink">span</span>=<span class="color-green">"2"</span> <span class="color-pink">style</span>="<span class="color-green">background-color: #ccc;</span>"&gt;</p>
                        <p class="ml-30">    &lt;/colgroup&gt;</p>
                        <p class="ml-30">    &lt;tr&gt;</p>
                        <p class="ml-60">        &lt;th&gt;Header 1&lt;/th&gt;</p>
                        <p class="ml-60">        &lt;th&gt;Header 2&lt;/th&gt;</p>
                        <p class="ml-60">        &lt;th&gt;Header 3&lt;/th&gt;</p>
                        <p class="ml-30">    &lt;/tr&gt;</p>
                        <p class="ml-30">    &lt;tr&gt;</p>
                        <p class="ml-60">        &lt;td&gt;Data 1&lt;/td&gt;</p>
                        <p class="ml-60">        &lt;td&gt;Data 2&lt;/td&gt;</p>
                        <p class="ml-60">        &lt;td&gt;Data 3&lt;/td&gt;</p>
                        <p class="ml-30">    &lt;/tr&gt;</p>
                        <p>&lt;/table&gt;</p>
                        `
                    }}></div>
                </div>
                <p>
                    Where, <strong>`&lt;colgroup&gt;`</strong>: This tag defines a group of columns in the table and <strong>`&lt;col&gt;`</strong>: This tag specifies column properties within the <strong>`&lt;colgroup&gt;`</strong> tag. It can include attributes like <strong>`span`</strong> and <strong>`style`</strong>.
                </p>
                <p>
                    And <strong>`span`</strong>: Defines the number of columns the <strong>`&lt;col&gt;`</strong> element should span. The default value is 1. <strong>`style`</strong>: Inline CSS can be used to style the column.
                </p>
                <p>
                    Output of the following -
                </p>
                <div className='outputPalateBox mb-4' style={{'border':'none'}}>
                    <div className='outputPalate' dangerouslySetInnerHTML={{
                        __html: `
                        <table class="table">
                            <colgroup>
                                <col span="1" style="background-color: #f2f2f2;">
                                <col span="2" style="background-color: #ccc;">
                            </colgroup>
                            <tr>
                                <th>Header 1</th>
                                <th>Header 2</th>
                                <th>Header 3</th>
                            </tr>
                            <tr>
                                <td>Data 1</td>
                                <td>Data 2</td>
                                <td>Data 3</td>
                            </tr>
                        </table>
                        `
                    }}></div>
                </div>
                <h5 className='mt-5 mb-3'>Applying Styles to Specific Columns</h5>
                <p>
                    Here's an example of how to use the <strong>`&lt;colgroup&gt;`</strong> and <strong>`&lt;col&gt;`</strong> tags to style specific columns in a table:
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;table class="table"&gt;</p>
                        <p class="ml-30">    &lt;colgroup&gt;</p>
                        <p class="ml-60">        &lt;col <span class="color-pink">span</span>=<span class="color-green">"1"</span> <span class="color-pink">style</span>="<span class="color-green">background-color: #f2f2f2;</span>"&gt;</p>
                        <p class="ml-60">        &lt;col <span class="color-pink">span</span>=<span class="color-green">"1"</span> <span class="color-pink">style</span>="<span class="color-green">background-color: #ccc;</span>"&gt;</p>
                        <p class="ml-60">        &lt;col <span class="color-pink">span</span>=<span class="color-green">"1"</span> <span class="color-pink">style</span>="<span class="color-green">background-color: #a6a4a4;</span>"&gt;</p>
                        <p class="ml-30">    &lt;/colgroup&gt;</p>
                        <p class="ml-30">    &lt;tr&gt;</p>
                        <p class="ml-60">        &lt;th&gt;Header 1&lt;/th&gt;</p>
                        <p class="ml-60">        &lt;th&gt;Header 2&lt;/th&gt;</p>
                        <p class="ml-60">        &lt;th&gt;Header 3&lt;/th&gt;</p>
                        <p class="ml-30">    &lt;/tr&gt;</p>
                        <p class="ml-30">    &lt;tr&gt;</p>
                        <p class="ml-60">        &lt;td&gt;Data 1&lt;/td&gt;</p>
                        <p class="ml-60">        &lt;td&gt;Data 2&lt;/td&gt;</p>
                        <p class="ml-60">        &lt;td&gt;Data 3&lt;/td&gt;</p>
                        <p class="ml-30">    &lt;/tr&gt;</p>
                        <p class="ml-30">    &lt;tr&gt;</p>
                        <p class="ml-60">        &lt;td&gt;Data 4&lt;/td&gt;</p>
                        <p class="ml-60">        &lt;td&gt;Data 5&lt;/td&gt;</p>
                        <p class="ml-60">        &lt;td&gt;Data 6&lt;/td&gt;</p>
                        <p class="ml-30">    &lt;/tr&gt;</p>
                        <p>&lt;/table&gt;</p>
                        `
                    }}></div>
                </div>
                <p>
                    Output of the following -
                </p>
                <div className='outputPalateBox mb-4' style={{'border':'none'}}>
                    <div className='outputPalate' dangerouslySetInnerHTML={{
                        __html: `
                        <table class="table">
                            <colgroup>
                                <col span="1" style="background-color: #f2f2f2;">
                                <col span="1" style="background-color: #ccc;">
                                <col span="1" style="background-color: #a6a4a4">
                            </colgroup>
                            <tr>
                                <th>Header 1</th>
                                <th>Header 2</th>
                                <th>Header 3</th>
                            </tr>
                            <tr>
                                <td>Data 1</td>
                                <td>Data 2</td>
                                <td>Data 3</td>
                            </tr>
                            <tr>
                                <td>Data 4</td>
                                <td>Data 5</td>
                                <td>Data 6</td>
                            </tr>
                        </table>
                        `
                    }}></div>
                </div>
                <p className='mt-4 featureClass'>
                    Using <strong>`&lt;colgroup&gt;`</strong> and <strong>`&lt;col&gt;`</strong> tags allows for more efficient and centralized styling of table columns, making it easier to maintain and update the styles.
                </p>
            </div>
        </section>
    )
}