import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../../Context";
import { Link } from 'react-router-dom';
export const pageUrl = () => "/mysql/numeric-functions/ceil";

export default function CEIL() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Mysql - Numeric Functions - CEIL(x) | Aspirant's Home");
        path.setPreviousNext({});
    }, [])

    return (
        <>
            <section class='clearfix mb-3'>
                <span className='float-start'>
                    <Link to='/mysql/numeric-functions' onClick={() => path.setPathName('/mysql/numeric-functions')} className='btn btn-primary'> <i class="fa-solid fa-less-than"></i>&nbsp; Back to Numeric Functions </Link>
                </span>
            </section>
            <section className='mt-5 mb-5'>
                <h3>Mysql - CEIL(x) Function</h3>
                <div className='mt-4 mb-5'>
                    <p>
                        The <strong>`CEIL()`</strong> function in MySQL is used to return the smallest integer value that is greater than or equal to a specified number. It rounds up the number to the nearest integer that is greater than or equal to the input.
                    </p>
                    <p>
                        Here is a simple example using the <strong>`CEIL()`</strong> function:
                    </p>
                    <div className='codePalateBox mt-2 mb-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                                <p><span class="color-blue">SELECT</span> CEIL(<span class="color-pink">4.3</span>);</p>
                            `
                        }}></div>
                    </div>
                    <p>
                        This query will return <strong>`5`</strong>, as the nearest integer greater than or equal to <strong>`4.3`</strong> is <strong>`5`</strong>.
                    </p>
                </div>
                <div className='mt-5 mb-5'>
                    <h5>CEIL(x) with MySQL Table</h5>
                    <div className='mt-3 mb-2'>
                        <p>
                            Consider a table <strong>`prices`</strong> with columns <strong>`product_id`</strong>, <strong>`product_name`</strong> and <strong>`price`</strong>. 
                        </p>
                        <table className='table' style={{border: '1px dashed #ccc'}}> 
                            <thead>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>product_id</th>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>product_name</th>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>price</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>1</td>
                                    <td style={{border: '1px dashed #ccc'}}>Product A</td>
                                    <td style={{border: '1px dashed #ccc'}}>10.25</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>2</td>
                                    <td style={{border: '1px dashed #ccc'}}>Product B</td>
                                    <td style={{border: '1px dashed #ccc'}}>5.75</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>3</td>
                                    <td style={{border: '1px dashed #ccc'}}>Product C</td>
                                    <td style={{border: '1px dashed #ccc'}}>8.50</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>4</td>
                                    <td style={{border: '1px dashed #ccc'}}>Product D</td>
                                    <td style={{border: '1px dashed #ccc'}}>12.00</td>
                                </tr>
                            </tbody>
                        </table>
                        <p>
                            If you want to round up the <strong>`price`</strong> of each product to the nearest integer, you can use the CEIL function as follows:
                        </p>
                        <div className='codePalateBox mt-2 mb-2'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-blue">SELECT</span> product_id, product_name, price, CEIL(price) <span class="color-blue">AS</span> rounded_price <span class="color-blue">FROM</span> prices;</p>
                                `
                            }}></div>
                        </div>
                        <p>
                            This query will return:
                        </p>
                        <table className='table' style={{border: '1px dashed #ccc'}}> 
                            <thead>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>product_id</th>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>product_name</th>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>price</th>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>rounded_price</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>1</td>
                                    <td style={{border: '1px dashed #ccc'}}>Product A</td>
                                    <td style={{border: '1px dashed #ccc'}}>10.25</td>
                                    <td style={{border: '1px dashed #ccc'}}>11</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>2</td>
                                    <td style={{border: '1px dashed #ccc'}}>Product B</td>
                                    <td style={{border: '1px dashed #ccc'}}>5.75</td>
                                    <td style={{border: '1px dashed #ccc'}}>6</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>3</td>
                                    <td style={{border: '1px dashed #ccc'}}>Product C</td>
                                    <td style={{border: '1px dashed #ccc'}}>8.50</td>
                                    <td style={{border: '1px dashed #ccc'}}>9</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>4</td>
                                    <td style={{border: '1px dashed #ccc'}}>Product D</td>
                                    <td style={{border: '1px dashed #ccc'}}>12.00</td>
                                    <td style={{border: '1px dashed #ccc'}}>12</td>
                                </tr>
                            </tbody>
                        </table>
                        <p>
                            In this example, the CEIL function is used to round up the <strong>`price`</strong> column to the nearest integer. The <strong>`CEIL(price)`</strong> expression returns the rounded-up value for each row.
                        </p>
                    </div>
                </div>
            </section>
        </>
    )
}