import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';
export const pageUrl = () => "/html/basic-tags/head";

export default function HTMLBasicHEADTags() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("HTML - <head> Basic Tags | Aspirant's Home");
        path.setPreviousNext({});
    }, [])

    return (
        <>
            <section class='clearfix mb-3'>
                <span className='float-start'>
                    <Link to='/html/basic-tags' onClick={() => path.setPathName('/html/basic-tags')} className='btn btn-primary'> <i class="fa-solid fa-less-than"></i>&nbsp; Back to Basic Tags </Link>
                </span>
            </section>
            <section className='mt-5 mb-5'>
                <h3>HTML - &lt;head&gt; Basic tags</h3>
                <div className='mt-4 mb-5'>
                    <p>
                        The <strong>`&lt;head&gt;`</strong> tag in an HTML document is a container for metadata (data about data) and links to external resources that are related to the document. The content inside the <strong>`&lt;head&gt;`</strong> tag is not displayed on the webpage itself, but it plays a crucial role in defining the document's properties and behavior.
                    </p>
                    <h5 className='mt-5 mb-3'>Purpose of the `&lt;head&gt;` Tag</h5>
                    <ul style={{ listStyle: 'decimal' }}>
                        <li>
                            <strong>Metadata</strong>: The <strong>`&lt;head&gt;`</strong> tag contains metadata about the HTML document, such as its character set, author, and description.
                        </li>
                        <li>
                            <strong>Links to External Resources</strong>: It can link to external stylesheets, scripts, and other resources that are needed for the webpage.
                        </li>
                        <li>
                            <strong>SEO and Accessibility</strong>: The metadata within the <strong>`&lt;head&gt;`</strong> tag helps search engines understand the content of the page, improving SEO. It also provides information that improves accessibility for users.
                        </li>
                    </ul>
                    <h5 className='mt-5 mb-3'>Common Elements Inside the `&lt;head&gt;` Tag</h5>
                    <ul style={{ listStyle: 'decimal' }}>
                        <li>
                            <strong>&lt;title&gt;</strong>: Sets the title of the webpage, which appears in the browser's title bar or tab.
                            <div className='codePalateBox mt-2 mb-4'>
                                <div className='codePalate' dangerouslySetInnerHTML={{
                                    __html: `
                                        <p>&lt;title&gt;Page Title&lt;/title&gt;</p>
                                    `
                                }}></div>
                            </div>
                        </li>
                        <li>
                            <strong>&lt;meta&gt;</strong>: Provides metadata about the HTML document. Common attributes include:
                            <ul style={{ listStyle: 'disc' }}>
                                <li><strong>`charset`</strong>: Specifies the character encoding for the document.</li>
                                <li><strong>`name and content`</strong>: Provide meta-information such as author, description, and keywords.</li>
                            </ul>
                            <div className='codePalateBox mt-2 mb-4'>
                                <div className='codePalate' dangerouslySetInnerHTML={{
                                    __html: `
                                        <p>&lt;meta <span class="color-pink">charset</span>=<span class="color-green">"UTF-8"</span>&gt;</p>
                                        <p>&lt;meta <span class="color-pink">name</span>=<span class="color-green">"description"</span> <span class="color-pink">content</span>=<span class="color-green">"A brief description of the webpage"</span>&gt;</p>
                                        <p>&lt;meta <span class="color-pink">name</span>=<span class="color-green">"author"</span> <span class="color-pink">content</span>=<span class="color-green">"Author Name"</span>&gt;</p>
                                        <p>&lt;meta <span class="color-pink">name</span>=<span class="color-green">"viewport"</span> <span class="color-pink">content</span>=<span class="color-green">"width=device-width, initial-scale=1.0"</span>&gt;</p>
                                    `
                                }}></div>
                            </div>
                        </li>
                        <li>
                            <strong>&lt;link&gt;</strong>: Links to external resources like stylesheets and icons.
                            <div className='codePalateBox mt-2 mb-4'>
                                <div className='codePalate' dangerouslySetInnerHTML={{
                                    __html: `
                                        <p>&lt;link <span class="color-pink">rel</span>=<span class="color-green">"stylesheet"</span> <span class="color-pink">href</span>=<span class="color-green">"styles.css"</span>&gt;</p>
                                        <p>&lt;link <span class="color-pink">rel</span>=<span class="color-green">"icon"</span> <span class="color-pink">href</span>=<span class="color-green">"favicon.ico"</span> <span class="color-pink">type</span>=<span class="color-green">"image/x-icon"</span>&gt;</p>
                                    `
                                }}></div>
                            </div>
                        </li>
                        <li>
                            <strong>&lt;style&gt;</strong>: Contains internal CSS styles for the document.
                            <div className='codePalateBox mt-2 mb-4'>
                                <div className='codePalate' dangerouslySetInnerHTML={{
                                    __html: `
                                        <p>&lt;style&gt;</p>
                                        <p class="ml-30">    body {</p>
                                        <p class="ml-60">        <span class="color-green">font-family</span>: Arial, sans-serif;</p>
                                        <p class="ml-30">    }</p>
                                        <p>&lt;/style&gt;</p>
                                    `
                                }}></div>
                            </div>
                        </li>
                        <li>
                            <strong>&lt;script&gt;</strong>: Links to external JavaScript files or contains inline JavaScript code.
                            <div className='codePalateBox mt-2 mb-4'>
                                <div className='codePalate' dangerouslySetInnerHTML={{
                                    __html: `
                                        <p>&lt;script <span class="color-pink">src</span>=<span class="color-green">"script.js"</span>&gt;&lt;/script&gt;</p>
                                        <p>&lt;script&gt;</p>
                                        <p class="ml-30">    <span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">'Inline JavaScript'</span>);</p>
                                        <p>&lt;/script&gt;</p>
                                    `
                                }}></div>
                            </div>
                        </li>
                    </ul>
                </div>
            </section>
        </>
    )
}