import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/react-js/managing-form-user-input";

export default function ReactFormUserInputs() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("React Forms - Managing User Input | Aspirant's Home");
        const urls = {
            'previous': '/react-js/handling-form-submission',
            'next':'/react-js/validating-form-user-input'
        }
        path.setPreviousNext(urls);
    },[])

    return (
        <section className='mt-5 mb-5'>
            <h3>React Forms - Managing User Input</h3>
            <div className='mt-5 mb-4'>
                <ul style={{listStyle:'disc'}}>
                    <li className='mt-5 mb-5'>
                        <h5>Getting User Input via useState</h5>
                        <div className='mt-4 mb-4'>
                            <p>
                                Handling user input in React forms involves using controlled components to manage the form state and update it in response to user actions. Controlled components are form elements whose value is controlled by React state.
                            </p>
                            <div className='codePalateBox mt-2 mb-2'>
                                <div className='codePalate' dangerouslySetInnerHTML={{
                                    __html: `
                                        <p>&lt;form&gt;</p>
                                        <p style="margin-left:30px">    &lt;div <span class="color-pink">className</span>=<span class="color-green">"mb-3"</span>&gt;</p>
                                        <p style="margin-left:60px">        &lt;label <span class="color-pink">htmlFor</span>=<span class="color-green">"username"</span> <span class="color-pink">className</span>=<span class="color-green">"form-label"</span>&gt;Username&lt;/label&gt;</p>
                                        <p style="margin-left:60px">        &lt;input
                                        <span class="color-pink">type</span>=<span class="color-green">"text"</span>
                                        <span class="color-pink">className</span>=<span class="color-green">"form-control"</span>
                                        <span class="color-pink">id</span>=<span class="color-green">"username"</span>
                                        <span class="color-pink">name</span>=<span class="color-green">"username"</span>
                                                    required
                                                /&gt;</p>
                                        <p style="margin-left:30px">    &lt;/div&gt;</p>
                                        <p style="margin-left:30px">    &lt;div <span class="color-pink">className</span>=<span class="color-green">"mb-3"</span>&gt;</p>
                                        <p style="margin-left:60px">        &lt;label <span class="color-pink">htmlFor</span>=<span class="color-green">"password"</span> <span class="color-pink">className</span>=<span class="color-green">"form-label"</span>&gt;Password&lt;/label&gt;</p>
                                        <p style="margin-left:60px">        &lt;input
                                        <span class="color-pink">type</span>=<span class="color-green">"password"</span>
                                        <span class="color-pink">className</span>=<span class="color-green">"form-control"</span>
                                        <span class="color-pink">id</span>=<span class="color-green">"password"</span>
                                        <span class="color-pink">name</span>=<span class="color-green">"password"</span>
                                                    required
                                                /&gt;</p>
                                        <p style="margin-left:30px">    &lt;/div&gt;</p>
                                        <p style="margin-left:30px">    &lt;button <span class="color-pink">type</span>=<span class="color-green">"submit"</span> <span class="color-pink">className</span>=<span class="color-green">"btn btn-primary"</span>&gt;Login&lt;/button&gt;</p>
                                        <p>&lt;/form&gt;</p>
                                    `
                                }}></div>
                            </div>
                            <p>
                                Now, We use the <strong>`useState`</strong> hook to create a state variable to store the form data (username and password). We can do the state define in two ways. Look at the below examples - 
                            </p>
                        </div>
                        <div className='mt-2 mb-4'>
                            <h5>Process 1 :</h5>
                            <div className='codePalateBox mt-2 mb-2'>
                                <div className='codePalate' dangerouslySetInnerHTML={{
                                    __html: `
                                        <p><span class="color-blue">const</span> [username, setUserName] = <span class="color-red">useState</span>();</p>
                                        <p><span class="color-blue">const</span> [password, setPassword] = <span class="color-red">useState</span>();</p>
                                        <br />
                                        <p><span class="color-blue">const</span> <span class="color-red">handleUserNameChange</span> = (event) => {</p>
                                        <p style="margin-left:30px">    <span class="color-red">setUserName</span>(event.target.value);</p>
                                        <p>}</p>
                                        <br />
                                        <p><span class="color-blue">const</span> <span class="color-red">handlePasswordChange</span> = (event) => {</p>
                                        <p style="margin-left:30px">     <span class="color-red">setPassword</span>(event.target.value);</p>
                                        <p>}</p>
                                    `
                                }}></div>
                            </div>
                            <p>
                                You can take separate <strong>`state`</strong> variable and separate <strong>`handleChange`</strong> event for all input fields. Lets look at the below example how we add the <strong>`state`</strong> variable and <strong>`handleChange`</strong> event in our form.
                            </p>
                            <div className='codePalateBox mt-2 mb-2'>
                                <div className='codePalate' dangerouslySetInnerHTML={{
                                    __html: `
                                        <p>&lt;form&gt;</p>
                                        <p style="margin-left:30px">    &lt;div <span class="color-pink">className</span>=<span class="color-green">"mb-3"</span>&gt;</p>
                                        <p style="margin-left:60px">        &lt;label <span class="color-pink">htmlFor</span>=<span class="color-green">"username"</span> <span class="color-pink">className</span>=<span class="color-green">"form-label"</span>&gt;Username&lt;/label&gt;</p>
                                        <p style="margin-left:60px">        &lt;input
                                        <span class="color-pink">type</span>=<span class="color-green">"text"</span>
                                        <span class="color-pink">className</span>=<span class="color-green">"form-control"</span>
                                        <span class="color-pink">id</span>=<span class="color-green">"username"</span>
                                        <span class="color-pink">name</span>=<span class="color-green">"username"</span>
                                        <span class="color-pink">value</span>=<span class="color-green">{username}</span>
                                        <span class="color-pink">onChange</span>=<span class="color-green">{handleUserNameChange}</span>
                                                    required
                                                /&gt;</p>
                                        <p style="margin-left:30px">    &lt;/div&gt;</p>
                                        <p style="margin-left:30px">    &lt;div <span class="color-pink">className</span>=<span class="color-green">"mb-3"</span>&gt;</p>
                                        <p style="margin-left:60px">        &lt;label <span class="color-pink">htmlFor</span>=<span class="color-green">"password"</span> <span class="color-pink">className</span>=<span class="color-green">"form-label"</span>&gt;Password&lt;/label&gt;</p>
                                        <p style="margin-left:60px">        &lt;input
                                        <span class="color-pink">type</span>=<span class="color-green">"password"</span>
                                        <span class="color-pink">className</span>=<span class="color-green">"form-control"</span>
                                        <span class="color-pink">id</span>=<span class="color-green">"password"</span>
                                        <span class="color-pink">name</span>=<span class="color-green">"password"</span>
                                        <span class="color-pink">value</span>=<span class="color-green">{password}</span>
                                        <span class="color-pink">onChange</span>=<span class="color-green">{handlePasswordChange}</span>
                                                    required
                                                /&gt;</p>
                                        <p style="margin-left:30px">    &lt;/div&gt;</p>
                                        <p style="margin-left:30px">    &lt;button <span class="color-pink">type</span>=<span class="color-green">"submit"</span> <span class="color-pink">className</span>=<span class="color-green">"btn btn-primary"</span>&gt;Login&lt;/button&gt;</p>
                                        <p>&lt;/form&gt;</p>
                                    `
                                }}></div>
                            </div>
                        </div>
                        <div className='mt-5 mb-4'>
                            <h5>Process 2 :</h5>
                            <div className='codePalateBox mt-2 mb-2'>
                                <div className='codePalate' dangerouslySetInnerHTML={{
                                    __html: `
                                        <p><span class="color-blue">const</span> [formData, setFormData] = <span class="color-red">useState</span>({</p>
                                        <p style="margin-left:30px">    <span class="color-pink">username</span>: <span class="color-green">''</span>,</p>
                                        <p style="margin-left:30px">    <span class="color-pink">password</span>: <span class="color-green">''</span></p>
                                        <p>});</p>
                                        <br />
                                        <p><span class="color-blue">const</span> <span class="color-red">handleChange</span> = (event) => {</p>
                                        <p style="margin-left:30px">    <span class="color-blue">const</span> { name, value } = event.target;</p>
                                        <p style="margin-left:30px">    <span class="color-red">setFormData</span>({ ...formData, [name]: value });</p>
                                        <p>};</p>
                                    `   
                                }}></div>
                            </div>
                            <p>
                                Here, we create a common state variable for all input fields, <strong>`username`</strong> and <strong>`password`</strong>. We set the state value as an object. In the input fields of the form element, we pass the object value to the <strong>`value`</strong> property of the input fields and use the common <strong>`handleChange`</strong> function in the <strong>`onChange`</strong> property. Look the below example -
                            </p>
                            <div className='codePalateBox mt-2 mb-2'>
                                <div className='codePalate' dangerouslySetInnerHTML={{
                                    __html: `
                                        <p>&lt;form&gt;</p>
                                        <p style="margin-left:30px">    &lt;div <span class="color-pink">className</span>=<span class="color-green">"mb-3"</span>&gt;</p>
                                        <p style="margin-left:60px">        &lt;label <span class="color-pink">htmlFor</span>=<span class="color-green">"username"</span> <span class="color-pink">className</span>=<span class="color-green">"form-label"</span>&gt;Username&lt;/label&gt;</p>
                                        <p style="margin-left:60px">        &lt;input
                                        <span class="color-pink">type</span>=<span class="color-green">"text"</span>
                                        <span class="color-pink">className</span>=<span class="color-green">"form-control"</span>
                                        <span class="color-pink">id</span>=<span class="color-green">"username"</span>
                                        <span class="color-pink">name</span>=<span class="color-green">"username"</span>
                                        <span class="color-pink">value</span>=<span class="color-green">{formData.username}</span>
                                        <span class="color-pink">onChange</span>=<span class="color-green">{handleChange}</span>
                                                    required
                                                /&gt;</p>
                                        <p style="margin-left:30px">    &lt;/div&gt;</p>
                                        <p style="margin-left:30px">    &lt;div <span class="color-pink">className</span>=<span class="color-green">"mb-3"</span>&gt;</p>
                                        <p style="margin-left:60px">        &lt;label <span class="color-pink">htmlFor</span>=<span class="color-green">"password"</span> <span class="color-pink">className</span>=<span class="color-green">"form-label"</span>&gt;Password&lt;/label&gt;</p>
                                        <p style="margin-left:60px">        &lt;input
                                        <span class="color-pink">type</span>=<span class="color-green">"password"</span>
                                        <span class="color-pink">className</span>=<span class="color-green">"form-control"</span>
                                        <span class="color-pink">id</span>=<span class="color-green">"password"</span>
                                        <span class="color-pink">name</span>=<span class="color-green">"password"</span>
                                        <span class="color-pink">value</span>=<span class="color-green">{formData.password}</span>
                                        <span class="color-pink">onChange</span>=<span class="color-green">{handleChange}</span>
                                                    required
                                                /&gt;</p>
                                        <p style="margin-left:30px">    &lt;/div&gt;</p>
                                        <p style="margin-left:30px">    &lt;button <span class="color-pink">type</span>=<span class="color-green">"submit"</span> <span class="color-pink">className</span>=<span class="color-green">"btn btn-primary"</span>&gt;Login&lt;/button&gt;</p>
                                        <p>&lt;/form&gt;</p>
                                    `
                                }}></div>
                            </div>
                        </div>
                    </li>
                    <div className='mt-5 mb-5 addBetweenSpace'>
                        <ins class="adsbygoogle"
                            style={{ "display":"block"}}
                            data-ad-client="ca-pub-4667727216643481"
                            data-ad-slot="8819006670"
                            data-ad-format="auto"
                            data-full-width-responsive="true"></ins>
                        <script>
                            (adsbygoogle = window.adsbygoogle || []).push({});
                        </script>
                    </div>
                    <li className='mt-5 mb-5'>
                        <h5>Getting User Input via Refs</h5>
                        <div className='mt-2 mb-4'>
                            <p>
                                You can use refs to get user input from form elements directly. Refs provide a way to access DOM nodes or React elements created in the render method. Here's how you can use refs to get user input from an input field:
                            </p>
                            <div className='codePalateBox mt-2 mb-2'>
                                <div className='codePalate' dangerouslySetInnerHTML={{
                                    __html: `
                                        <p><span class="color-blue">import</span> <span class="color-red">React</span>, { <span class="color-red">useRef</span> } <span class="color-blue">from</span> <span class="color-green">'react'</span>;</p>
                                        <br />
                                        <p><span class="color-blue">function</span> <span class="color-red">FormExample</span>() {</p>
                                        <p style="margin-left:30px">    <span class="color-blue">const</span> inputRef = <span class="color-red">useRef</span>(null);</p>
                                        <br />    
                                        <p style="margin-left:30px">    <span class="color-blue">const</span> <span class="color-red">handleSubmit</span> = (event) => {</p>
                                        <p style="margin-left:60px">        event.<span class="color-red">preventDefault</span>();</p>
                                        <p style="margin-left:60px">        <span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">'Input value:'</span>, inputRef.current.value);</p>
                                        <p style="margin-left:60px">        inputRef.current.value = ''; <span class="color-grey">// Clear input field after logging value</span></p>
                                        <p style="margin-left:30px">    };</p>
                                        <br />    
                                        <p style="margin-left:30px">    <span class="color-blue">return</span> (</p>
                                        <p style="margin-left:60px">        &lt;div&gt;</p>
                                        <p style="margin-left:90px">            &lt;h1&gt;React Form Example&lt;/h1&gt;</p>
                                        <p style="margin-left:90px">            &lt;form <span class="color-pink">onSubmit</span>=<span class="color-green">{handleSubmit}</span>&gt;</p>
                                        <p style="margin-left:120px">                &lt;label&gt;</p>
                                        <p style="margin-left:150px">                Enter a value:</p>
                                        <p style="margin-left:150px">                &lt;input
                                        <span class="color-pink">type</span>=<span class="color-green">"text"</span>
                                        <span class="color-pink">ref</span>=<span class="color-green">{inputRef}</span>
                                                            /&gt;</p>
                                        <p style="margin-left:120px">                &lt;/label&gt;</p>
                                        <p style="margin-left:120px">                &lt;button <span class="color-pink">type</span>=<span class="color-green">"submit"</span>&gt;Submit&lt;/button&gt;</p>
                                        <p style="margin-left:90px">            &lt;/form&gt;</p>
                                        <p style="margin-left:60px">        &lt;/div&gt;</p>
                                        <p style="margin-left:30px">    );</p>
                                        <p>}</p>
                                        <br />
                                        <p><span class="color-blue">export default</span> <span class="color-red">FormExample</span>;</p>
                                    
                                    `
                                }}></div>
                            </div>
                            <p>
                                In this example:
                            </p>
                            <ul style={{listStyle:'disc'}}>
                                <li>We create a ref using the <strong>`useRef`</strong> hook and initialize it with <strong>`null`</strong>.</li>
                                <li>We attach the ref to the input field using the <strong>`ref`</strong> attribute.</li>
                                <li>When the form is submitted, we access the input field value using <strong>`inputRef.current.value`</strong> and log it to the console.</li>
                                <li>We can also use <strong>`inputRef.current.value`</strong> to modify the input field value, such as clearing it after submission.</li>
                            </ul>
                            <p>
                                Using refs in this way allows you to directly access and manipulate form elements without needing to update state or use event handlers for each input field.
                            </p>
                        </div>
                    </li>
                    <div className='mt-5 mb-5 addBetweenSpace'>
                        <ins class="adsbygoogle"
                            style={{ "display":"block"}}
                            data-ad-client="ca-pub-4667727216643481"
                            data-ad-slot="8819006670"
                            data-ad-format="auto"
                            data-full-width-responsive="true"></ins>
                        <script>
                            (adsbygoogle = window.adsbygoogle || []).push({});
                        </script>
                    </div>
                    <li className='mt-5 mb-5'>
                        <h5>Getting User Input via FormData</h5>
                        <div className='mt-2 mb-4'>
                            <p>
                                You can use the FormData API to gather form data from user input fields. This method simplifies the process of collecting data from various form elements without explicitly managing state for each input. To receive user input for each form element, you must assign a unique "name" property to each input field.
                            </p>
                            <p>
                                Here's how you can use FormData to get user input in a form:
                            </p>
                            <div className='codePalateBox mt-2 mb-2'>
                                <div className='codePalate' dangerouslySetInnerHTML={{
                                    __html: `   
                                        <p><span class="color-blue">function</span> <span class="color-red">handleSubmit</span>(event)</p>
                                        <p>{</p>
                                        <p style="margin-left:30px">    event.<span class="color-red">preventDefault</span>();</p>
                                        <p style="margin-left:30px">    <span class="color-blue">const</span> fd = <span class="color-blue">new</span> <span class="color-red">FormData</span>(event.target);</p>
                                        <p style="margin-left:30px">    <span class="color-blue">const</span> data = Object.<span class="color-red">formEntries</span>(fd.<span class="color-red">entries</span>());</p>
                                        <p style="margin-left:30px"> <span class="color-pink">console</span>.<span class="color-red">log</span>(data);</p>
                                        <p>}</p>
                                    `
                                }}></div>
                            </div>
                            <p>
                                <strong>`const fd = new FormData(event.target);`</strong>: This line creates a new FormData object <strong>`fd`</strong> from the form element that triggered the event. <strong>`event.target`</strong> refers to the form element itself. The FormData object collects the form data from the form's input fields.
                            </p>
                            <p>
                                <strong>`const data = Object.fromEntries(fd.entries());`</strong>: This line converts the FormData object <strong>`fd`</strong> into a plain JavaScript object <strong>`data`</strong>. The <strong>`entries()`</strong> method of the FormData object returns an iterator of key/value pairs for each form field. <strong>`Object.fromEntries()`</strong> then converts these key/value pairs into a new object.
                            </p>
                            <p>
                                You will get the below output
                            </p>
                            <div className='codePalateBox mt-2 mb-2'>
                                <div className='codePalate' dangerouslySetInnerHTML={{
                                    __html: `
                                        <p>{</p>
                                        <p style="margin-left:30px">    username : "your typed username",</p>
                                        <p style="margin-left:30px">    password : "your typed password"</p>
                                        <p>}</p>
                                    `
                                }}></div>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </section>
    )
}