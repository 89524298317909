import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/mysql/select-query";

export default function MysqlSelectQuery() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Mysql - Select Query | Aspirant's Home");
        const urls = {
            'previous': '/mysql/insert-query',
            'next': '/mysql/update-query'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>Mysql - Select Query</h3>
            <div className='mt-4 mb-5'>
                <p>
                    In MySQL, the <strong>`SELECT`</strong> statement is used to retrieve data from one or more tables in a database. Here's the basic syntax for a simple <strong>`SELECT`</strong> query:
                </p>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-blue">SELECT</span> column1, column2, ...</p>
                            <p><span class="color-blue">FROM</span> table_name</p>
                            <p><span class="color-blue">WHERE</span> condition;</p>
                        `
                    }}></div>
                </div>
                <p>
                    Where, <strong>`column1, column2, ...`</strong> are the columns you want to retrieve. Use * to select all columns. <strong>`table_name`</strong> is the name of the table from which you want to retrieve data. <strong>`condition`</strong> is an optional condition to filter the rows to be retrieved.
                </p>
                <p>
                    Suppose, we have a <strong>`users`</strong> table like below -
                </p>
                <table className='table' style={{border: '1px dashed #ccc'}}> 
                    <thead>
                        <tr style={{border: '1px dashed #ccc'}}>
                            <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>id</th>
                            <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>name</th>
                            <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>age</th>
                            <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>Address</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr style={{border: '1px dashed #ccc'}}>
                            <td style={{border: '1px dashed #ccc'}}>1</td>
                            <td style={{border: '1px dashed #ccc'}}>Jane Smith</td>
                            <td style={{border: '1px dashed #ccc'}}>25</td>
                            <td style={{border: '1px dashed #ccc'}}>Apt. 415 9295 Anh Harbor, South Junior, FL 51309-5804</td>
                        </tr>
                        <tr style={{border: '1px dashed #ccc'}}>
                            <td style={{border: '1px dashed #ccc'}}>2</td>
                            <td style={{border: '1px dashed #ccc'}}>John Doe</td>
                            <td style={{border: '1px dashed #ccc'}}>35</td>
                            <td style={{border: '1px dashed #ccc'}}>Suite 977 83408 Murray Lakes, Lakinchester, WV 60583</td>
                        </tr>
                        <tr style={{border: '1px dashed #ccc'}}>
                            <td style={{border: '1px dashed #ccc'}}>3</td>
                            <td style={{border: '1px dashed #ccc'}}>Johny Smith</td>
                            <td style={{border: '1px dashed #ccc'}}>39</td>
                            <td style={{border: '1px dashed #ccc'}}>3797 Benton Locks, Weissnatside, MS 54762-5660</td>
                        </tr>
                        <tr style={{border: '1px dashed #ccc'}}>
                            <td style={{border: '1px dashed #ccc'}}>4</td>
                            <td style={{border: '1px dashed #ccc'}}>Jeffer Joe</td>
                            <td style={{border: '1px dashed #ccc'}}>28</td>
                            <td style={{border: '1px dashed #ccc'}}>Suite 715 6587 Champlin Freeway, Port Angelhaven, PA 42789</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className='mt-5 mb-5'>
                <h5>Select All Columns</h5>
                <div className='mt-2 mb-2'>
                    <p>
                        To select all fields from table <strong>`users`</strong>
                    </p>
                    <div className='codePalateBox mt-2 mb-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                                <p><span class="color-blue">SELECT</span> * <span class="color-blue">FROM</span> users;</p>
                            `
                        }}></div>
                    </div>
                    <p>
                        The Output of the query will be the following -
                    </p>
                    
                    <table className='table' style={{border: '1px dashed #ccc'}}> 
                        <thead>
                            <tr style={{border: '1px dashed #ccc'}}>
                                <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>id</th>
                                <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>name</th>
                                <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>age</th>
                                <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>Address</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr style={{border: '1px dashed #ccc'}}>
                                <td style={{border: '1px dashed #ccc'}}>1</td>
                                <td style={{border: '1px dashed #ccc'}}>Jane Smith</td>
                                <td style={{border: '1px dashed #ccc'}}>25</td>
                                <td style={{border: '1px dashed #ccc'}}>Apt. 415 9295 Anh Harbor, South Junior, FL 51309-5804</td>
                            </tr>
                            <tr style={{border: '1px dashed #ccc'}}>
                                <td style={{border: '1px dashed #ccc'}}>2</td>
                                <td style={{border: '1px dashed #ccc'}}>John Doe</td>
                                <td style={{border: '1px dashed #ccc'}}>35</td>
                                <td style={{border: '1px dashed #ccc'}}>Suite 977 83408 Murray Lakes, Lakinchester, WV 60583</td>
                            </tr>
                            <tr style={{border: '1px dashed #ccc'}}>
                                <td style={{border: '1px dashed #ccc'}}>3</td>
                                <td style={{border: '1px dashed #ccc'}}>Johny Smith</td>
                                <td style={{border: '1px dashed #ccc'}}>39</td>
                                <td style={{border: '1px dashed #ccc'}}>3797 Benton Locks, Weissnatside, MS 54762-5660</td>
                            </tr>
                            <tr style={{border: '1px dashed #ccc'}}>
                                <td style={{border: '1px dashed #ccc'}}>4</td>
                                <td style={{border: '1px dashed #ccc'}}>Jeffer Joe</td>
                                <td style={{border: '1px dashed #ccc'}}>28</td>
                                <td style={{border: '1px dashed #ccc'}}>Suite 715 6587 Champlin Freeway, Port Angelhaven, PA 42789</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div className='mt-5 mb-5'>
                <h5>Select Specific Columns</h5>
                <div className='mt-2 mb-2'>
                    <p>
                        By specifying the names of the required columns you can retrieve the desired column values of records of a table.
                    </p>
                    <div className='codePalateBox mt-2 mb-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                                <p><span class="color-blue">SELECT</span> id, name, address <span class="color-blue">FROM</span> users;</p>
                            `
                        }}></div>
                    </div>
                    <p>
                        The Output of the query will be the following -
                    </p>
                    
                    <table className='table' style={{border: '1px dashed #ccc'}}> 
                        <thead>
                            <tr style={{border: '1px dashed #ccc'}}>
                                <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>id</th>
                                <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>name</th>
                                <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>Address</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr style={{border: '1px dashed #ccc'}}>
                                <td style={{border: '1px dashed #ccc'}}>1</td>
                                <td style={{border: '1px dashed #ccc'}}>Jane Smith</td>
                                <td style={{border: '1px dashed #ccc'}}>Apt. 415 9295 Anh Harbor, South Junior, FL 51309-5804</td>
                            </tr>
                            <tr style={{border: '1px dashed #ccc'}}>
                                <td style={{border: '1px dashed #ccc'}}>2</td>
                                <td style={{border: '1px dashed #ccc'}}>John Doe</td>
                                <td style={{border: '1px dashed #ccc'}}>Suite 977 83408 Murray Lakes, Lakinchester, WV 60583</td>
                            </tr>
                            <tr style={{border: '1px dashed #ccc'}}>
                                <td style={{border: '1px dashed #ccc'}}>3</td>
                                <td style={{border: '1px dashed #ccc'}}>Johny Smith</td>
                                <td style={{border: '1px dashed #ccc'}}>3797 Benton Locks, Weissnatside, MS 54762-5660</td>
                            </tr>
                            <tr style={{border: '1px dashed #ccc'}}>
                                <td style={{border: '1px dashed #ccc'}}>4</td>
                                <td style={{border: '1px dashed #ccc'}}>Jeffer Joe</td>
                                <td style={{border: '1px dashed #ccc'}}>Suite 715 6587 Champlin Freeway, Port Angelhaven, PA 42789</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div className='mt-5 mb-5'>
                <h5>Select Columns With Conditions</h5>
                <div className='mt-2 mb-2'>
                    <p>
                        You can use the <strong>`WHERE`</strong> clause to filter the rows. For example, to select users with an age greater than <strong>`30`</strong>:
                    </p>
                    <div className='codePalateBox mt-2 mb-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                                <p><span class="color-blue">SELECT</span> * <span class="color-blue">FROM</span> users WHERE age > <span class="color-pink">30</span>;</p>
                            `
                        }}></div>
                    </div>
                    <p>
                        The Output of the query will be the following -
                    </p>
                    
                    <table className='table' style={{border: '1px dashed #ccc'}}> 
                        <thead>
                            <tr style={{border: '1px dashed #ccc'}}>
                                <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>id</th>
                                <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>name</th>
                                <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>age</th>
                                <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>Address</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr style={{border: '1px dashed #ccc'}}>
                                <td style={{border: '1px dashed #ccc'}}>2</td>
                                <td style={{border: '1px dashed #ccc'}}>John Doe</td>
                                <td style={{border: '1px dashed #ccc'}}>35</td>
                                <td style={{border: '1px dashed #ccc'}}>Suite 977 83408 Murray Lakes, Lakinchester, WV 60583</td>
                            </tr>
                            <tr style={{border: '1px dashed #ccc'}}>
                                <td style={{border: '1px dashed #ccc'}}>3</td>
                                <td style={{border: '1px dashed #ccc'}}>Johny Smith</td>
                                <td style={{border: '1px dashed #ccc'}}>39</td>
                                <td style={{border: '1px dashed #ccc'}}>3797 Benton Locks, Weissnatside, MS 54762-5660</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </section>
    )
}