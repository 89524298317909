import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../../Context";
import { Link } from 'react-router-dom';
export const pageUrl = () => "/mysql/string-functions/left";

export default function LEFT() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Mysql - String Functions - LEFT() | Aspirant's Home");
        path.setPreviousNext({});
    }, [])

    return (
        <>
            <section class='clearfix mb-3'>
                <span className='float-start'>
                    <Link to='/mysql/string-functions' onClick={() => path.setPathName('/mysql/string-functions')} className='btn btn-primary'> <i class="fa-solid fa-less-than"></i>&nbsp; Back to String Functions </Link>
                </span>
            </section>
            <section className='mt-5 mb-5'>
                <h3>Mysql - LEFT() Function</h3>
                <div className='mt-4 mb-5'>
                    <p>
                        The <strong>`LEFT()`</strong> function in MySQL is used to extract a specified number of characters from the left side of a string. It returns the substring from the start of the given string up to the specified number of characters.
                    </p>
                    <h5>Syntax</h5>
                    <div className='codePalateBox mt-2 mb-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                                <p>LEFT(string, length)</p>
                            `
                        }}></div>
                    </div>
                    <p>
                        Where, <strong>`string`</strong> is the string from which to extract the substring and <strong>`length`</strong> is the number of characters to extract from the left side of the string.
                    </p>
                    <h5>Examples</h5>
                    <div className='codePalateBox mt-2 mb-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                                <p><span class="color-blue">SELECT</span> LEFT(<span class="color-green">'Hello, World!'</span>, <span class="color-pink">5</span>) <span class="color-blue">AS</span> left_string;</p>
                            `
                        }}></div>
                    </div>
                    <p>
                        In this example, the first 5 characters of 'Hello, World!' are extracted, output will be 'Hello'.
                    </p>
                </div>
                <div className='mt-5 mb-5'>
                    <h5>LEFT() with MySQL Table</h5>
                    <div className='mt-3 mb-2'>
                        <p>
                            Consider a table employees with the following structure and data:
                        </p>
                        <table className='table' style={{border: '1px dashed #ccc'}}> 
                            <thead>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>id</th>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>name</th>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>email</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>1</td>
                                    <td style={{border: '1px dashed #ccc'}}>Alice Johnson</td>
                                    <td style={{border: '1px dashed #ccc'}}>alice@example.com</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>2</td>
                                    <td style={{border: '1px dashed #ccc'}}>Bob Smith</td>
                                    <td style={{border: '1px dashed #ccc'}}>bob.smith@sample.com</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>3</td>
                                    <td style={{border: '1px dashed #ccc'}}>Charlie Brown</td>
                                    <td style={{border: '1px dashed #ccc'}}>charlie.brown@company.org</td>
                                </tr>
                            </tbody>
                        </table>
                        <p>
                            If you want to extract 3 letter from begining of employee name then we have to use <strong>`LEFT()`</strong> function.
                        </p>
                        <div className='codePalateBox mt-2 mb-2'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-blue">SELECT</span> id, name, LEFT(name, 3) <span class="color-blue">AS</span> name_prefix <span class="color-blue">FROM</span> employees;</p>
                                `
                            }}></div>
                        </div>
                        <p>
                            This query will return:
                        </p>
                        <table className='table' style={{border: '1px dashed #ccc'}}> 
                            <thead>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>id</th>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>name</th>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>name_prefix</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>1</td>
                                    <td style={{border: '1px dashed #ccc'}}>Alice Johnson</td>
                                    <td style={{border: '1px dashed #ccc'}}>Ali</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>2</td>
                                    <td style={{border: '1px dashed #ccc'}}>Bob Smith</td>
                                    <td style={{border: '1px dashed #ccc'}}>Bob</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>3</td>
                                    <td style={{border: '1px dashed #ccc'}}>Charlie Brown</td>
                                    <td style={{border: '1px dashed #ccc'}}>Cha</td>
                                </tr>
                            </tbody>
                        </table>
                        <p>
                            In this example, the first 3 characters of each name are extracted and returned as <strong>`name_prefix`</strong>.
                        </p>
                    </div>
                </div>
            </section>
        </>
    )
}