import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../../Context";
import { Link } from 'react-router-dom';
export const pageUrl = () => "/node-js/built-in-modules/buffer";

export default function BufferModule() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("NodeJs - Buffer Module | Aspirant's Home");
        path.setPreviousNext({});
    }, [])

    return (
        <div className='contentPage'>
            <section class='clearfix mb-3'>
                <span className='float-start'>
                    <Link to='/node-js/built-in-modules' onClick={() => path.setPathName('/node-js/built-in-modules')} className='btn btn-primary'> <i class="fa-solid fa-less-than"></i>&nbsp; Back to Built-In Modules </Link>
                </span>
            </section>
            <section className='mt-5 mb-5'>
                <h3>Node Js Buffer Module</h3>
                <div className='mt-4'>
                    <p>In Node.js, the Buffer module provides a way to handle binary data. It is a global module that can be used to create buffers, which are fixed-size chunks of memory that can store binary data. Buffers are useful for working with binary data, such as reading from files, working with network streams, or encoding/decoding data.</p>
                </div>
                <div className='mt-4'>
                    <h4>Examples</h4>
                    <p>Here's a basic example of how you might use the buffer module:</p>
                    <div className='mt-4'>
                        <h5>Creating Buffers:</h5>
                        <p>You can create a buffer using the Buffer.from() method, passing in a string, array, or another buffer:</p>
                        <div className='codePalateBox mt-2 mb-2'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `<p><span class='color-blue'>const</span> buf = Buffer.<span class='color-red'>from</span>(<span class='color-green'>'hello'</span>, <span class='color-green'>'utf8'</span>);</p>`
                            }}></div>
                        </div>
                    </div>
                    <div className='mt-4'>
                        <h5>Reading and Writing Buffers:</h5>
                        <p>You can read and write to buffers using array-like syntax:</p>
                        <div className='codePalateBox mt-2 mb-2'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `<p><span class='color-blue'>const</span> buf = Buffer.<span class='color-red'>alloc</span>(<span class='color-pink'>5</span>); <span class='color-grey'>// Create a buffer of size 5</span></p>
                                <p>buf[<span class='color-pink'>0</span>] = <span class='color-pink'>0x48</span>; <span class='color-grey'>// Write a byte (ASCII code for 'H')</span></p>
                                <p><span class='color-pink'>console</span>.<span class='color-red'>log</span>(buf.<span class='color-red'>toString</span>(<span class='color-green'>'utf8'</span>)); <span class='color-grey'>// Output: 'H'</span></p>
                                `
                            }}></div>
                        </div>
                    </div>
                    <div className='mt-4'>
                        <h5>Concatenating Buffers:</h5>
                        <p>You can concatenate multiple buffers using the <strong>`Buffer.concat()`</strong> method:</p>
                        <div className='codePalateBox mt-2 mb-2'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `<p><span class='color-blue'>const</span> buf1 = Buffer.<span class='color-red'>from</span>(<span class='color-green'>'hello'</span>, <span class='color-green'>'utf8'</span>);</p>
                                <p><span class='color-blue'>const</span> buf2 = Buffer.<span class='color-red'>from</span>(<span class='color-green'>'world'</span>, <span class='color-green'>'utf8'</span>);</p>
                                <p><span class='color-blue'>const</span> buf = Buffer.<span class='color-red'>concat</span>([buf1, buf2]);</p>
                                <br />
                                <p><span class='color-pink'>console</span>.<span class='color-red'>log</span>(buf.<span class='color-red'>toString(<span class='color-green'>'utf8'</span>)); <span class='color-grey'>// Output: 'helloworld'</span></p>                                
                                `
                            }}></div>
                        </div>
                    </div>
                    <div className='mt-4'>
                        <h5>Converting Buffers to JSON:</h5>
                        <p>You can convert a buffer to a JSON object using the <strong>`buf.toJSON()`</strong> method:</p>
                        <div className='codePalateBox mt-2 mb-2'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `<p><span class='color-blue'>const</span> buf = Buffer.<span class='color-red'>from</span>(<span class='color-green'>'hello'</span>, <span class='color-green'>'utf8'</span>);</p>
                                <p><span class='color-blue'>const</span> json = buf.<span class='color-red'>toJSON</span>();</p>`
                            }}></div>
                        </div>
                    </div>
                    <div className='mt-4'>
                        <h5>Encoding and Decoding Buffers:</h5>
                        <p>You can convert a buffer to a string using the <strong>`buf.toString()`</strong> method and specify the encoding:</p>
                        <div className='codePalateBox mt-2 mb-2'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `<p><span class='color-blue'>const</span> originalString = <span class='color-green'>'hello, world!'</span>;</p>
                                <br />
                                <p><span class='color-grey'>// Encoding a string to a buffer</span></p>
                                <p><span class='color-blue'>const</span> buffer = Buffer.<span class='color-red'>from</span>(originalString, <span class='color-green'>'utf8'</span>);</p>
                                <br />
                                <p><span class='color-grey'>// Decoding the buffer back to a string</span></p>
                                <p><span class='color-blue'>const</span> decodedString = buffer.<span class='color-red'>toString</span>(<span class='color-green'>'utf8'</span>);</p>
                                <br />
                                <p><span class='color-pink'>console</span>.<span class='color-red'>log</span>(<span class='color-green'>'Original String:'</span>, originalString); <span class='color-grey'>// Original String: hello, world!</span></p>
                                <p><span class='color-pink'>console</span>.<span class='color-red'>log</span>(<span class='color-green'>'Encoded Buffer:'</span>, buffer); <span class='color-grey'>// Encoded Buffer: &ltBuffer 68 65 6c 6c 6f 2c 20 77 6f 72 6c 64 21&gt</span></p>
                                <p><span class='color-pink'>console</span>.<span class='color-red'>log</span>(<span class='color-green'>'Decoded String:'</span>, decodedString); <span class='color-grey'>// Decoded String: hello, world!</span></p>                            
                                `
                            }}></div>
                        </div>
                    </div>
                    <div className='mt-4'>
                        <h5>Checking Buffer Size:</h5>
                        <p>You can check the size of a buffer using the <strong>`buf.length`</strong> property:</p>
                        <div className='codePalateBox mt-2 mb-2'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `<p><span class='color-blue'>const</span> buf = Buffer.<span class='color-red'>from</span>(<span class='color-green'>'hello'</span>, <span class='color-green'>'utf8'</span>);</p>
                                <p><span class='color-pink'>console</span>.<span class='color-red'>log</span>(buf.length); <span class='color-grey'>// Output: 5</span></p>
                                `
                            }}></div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}
