import React, { useEffect, useState, useRef } from 'react'
import { Outlet, Link } from "react-router-dom";
import TopHeader from '../TopHeader';
import Footer from '../Footer'
import { MenuContext, TitleContext, PathContext } from "../../Context";
import { useContext } from "react";
import PreviousNext from '../PreviousNext';

function NodeJsLayout() {
    const addspaceElement = useRef();
    const menuElement = useRef([]);
    const menu = useContext(MenuContext);
    const pathName = useContext(PathContext);
    const pathname = pathName.pathName;
    const title = useContext(TitleContext);

    const setPathName = (path) => {
        if(path)
        pathName.setPathName(path);
    }

    useEffect(() => {
        setPathName(window.location.pathname)
        if (menu.isMenu == true) menu.setMenuOpen(false)
        addspaceElement.current.scrollIntoView(true)
        const element = document.getElementsByClassName("activeMenu")[0];
        element && element.scrollIntoView({ block: "center" });
        title.setKeyWords("Node.js, Node.js tutorial, Node.js guide, Node.js documentation, Node.js installation, Node.js download, Node.js features, Node.js benefits, Node.js performance, Node.js use cases, Node.js applications, Node.js advantages, Node.js modules, Node.js npm, Node.js packages, Node.js HTTP module, Node.js file system, Node.js events,  Node.js streams, Node.js buffers, Node.js middleware, Node.js asynchronous programming, Node.js callbacks, Node.js promises, Node.js async/await, Node.js error handling, Node.js and Express, Node.js and MongoDB, Node.js and MySQL, Node.js and PostgreSQL, Node.js and REST APIs, Node.js and GraphQL, Node.js and WebSockets, Node.js and authentication, Node.js and JWT, Node.js and React, Node.js and Angular, Node.js and Vue");
        title.setPageDescription("Get started with Node.js through our comprehensive tutorial. Follow step-by-step instructions, code examples, and best practices to build robust server-side applications with Node.js.");

    }, [pathname])

    return (
        <>
            <TopHeader />
            <div className='container-fluid'>
                <div className='row'>

                    <div ref={menuElement} className={menu.isMenu == true ? 'col-md-3 col-lg-2 menuColumnMobile menuColumn p-0' : 'col-md-3 col-lg-2 menuColumn p-0'} style={{ 'overflowY': 'scroll', height: 'calc(100vh - 110px)' }}>
                        <div className='headerMenu'>
                            Node Js Tutorial
                        </div>
                        <nav>
                            <ul>
                                <li className={pathname == '/node-js/introduction' ? 'activeMenu' : ''}>
                                    <Link to="/node-js/introduction" onClick={() => setPathName('/node-js/introduction')}>Introduction</Link>
                                </li>
                                <li className={pathname == '/node-js/environment-setup' ? 'activeMenu' : ''}>
                                    <Link to="/node-js/environment-setup" onClick={() => setPathName('/node-js/environment-setup')}>Environment Setup</Link>
                                </li>
                                <li className={pathname == '/node-js/get-started' ? 'activeMenu' : ''}>
                                    <Link to="/node-js/get-started" onClick={() => setPathName('/node-js/get-startedp')}>Start A New Application</Link>
                                </li>
                                <li className={pathname == '/node-js/modules' ? 'activeMenu' : ''}>
                                    <Link to="/node-js/modules" onClick={() => setPathName('/node-js/modules')}>Modules</Link>
                                </li>
                                <li className={pathname == '/node-js/http-module' ? 'activeMenu' : ''}>
                                    <Link to="/node-js/http-module" onClick={() => setPathName('/node-js/http-module')}>HTTP Module</Link>
                                </li>
                                <li className={pathname == '/node-js/file-system' ? 'activeMenu' : ''}>
                                    <Link to="/node-js/file-system" onClick={() => setPathName('/node-js/file-system')}>File System</Link>
                                </li>
                                <li className={pathname == '/node-js/url-module' ? 'activeMenu' : ''}>
                                    <Link to="/node-js/url-module" onClick={() => setPathName('/node-js/url-module')}>URL Module</Link>
                                </li>
                                <li className={pathname == '/node-js/npm-module' ? 'activeMenu' : ''}>
                                    <Link to="/node-js/npm-module" onClick={() => setPathName('/node-js/npm-module')}>NPM Module</Link>
                                </li>
                                <li className={pathname == '/node-js/events' ? 'activeMenu' : ''}>
                                    <Link to="/node-js/events" onClick={() => setPathName('/node-js/events')}>Events</Link>
                                </li>
                                <li className={pathname == '/node-js/upload-files' ? 'activeMenu' : ''}>
                                    <Link to="/node-js/upload-files" onClick={() => setPathName('/node-js/upload-files')}>Upload Files</Link>
                                </li>
                                <li className={pathname == '/node-js/sending-email' ? 'activeMenu' : ''}>
                                    <Link to="/node-js/sending-email" onClick={() => setPathName('/node-js/sending-email')}>Sending Email</Link>
                                </li>
                            </ul>
                        </nav>
                        <div className='headerMenu'>
                            Node Js & Mysql
                        </div>
                        <nav>
                            <ul>
                                <li className={pathname == '/node-js/mysql' ? 'activeMenu' : ''}>
                                    <Link to="/node-js/mysql" onClick={() => setPathName('/node-js/mysql')}>Get Started</Link>
                                </li>
                                <li className={pathname == '/node-js/mysql-create-database' ? 'activeMenu' : ''}>
                                    <Link to="/node-js/mysql-create-database" onClick={() => setPathName('/node-js/mysql-create-database')}>Create Database</Link>
                                </li>
                                <li className={pathname == '/node-js/mysql-create-table' ? 'activeMenu' : ''}>
                                    <Link to="/node-js/mysql-create-table" onClick={() => setPathName('/node-js/mysql-create-table')}>Create Table</Link>
                                </li>
                                <li className={pathname == '/node-js/mysql-insert-query' ? 'activeMenu' : ''}>
                                    <Link to="/node-js/mysql-insert-query" onClick={() => setPathName('/node-js/mysql-insert-query')}>Insert Query</Link>
                                </li>
                                <li className={pathname == '/node-js/mysql-select-query' ? 'activeMenu' : ''}>
                                    <Link to="/node-js/mysql-select-query" onClick={() => setPathName('/node-js/mysql-select-query')}>Select Query</Link>
                                </li>
                                <li className={pathname == '/node-js/mysql-where-clause' ? 'activeMenu' : ''}>
                                    <Link to="/node-js/mysql-where-clause" onClick={() => setPathName('/node-js/mysql-where-clause')}>Where Clause</Link>
                                </li>
                                <li className={pathname == '/node-js/mysql-order-by' ? 'activeMenu' : ''}>
                                    <Link to="/node-js/mysql-order-by" onClick={() => setPathName('/node-js/mysql-order-by')}>Order By Clause</Link>
                                </li>
                                <li className={pathname == '/node-js/mysql-delete-query' ? 'activeMenu' : ''}>
                                    <Link to="/node-js/mysql-delete-query" onClick={() => setPathName('/node-js/mysql-delete-query')}>Delete Query</Link>
                                </li>
                                <li className={pathname == '/node-js/mysql-drop-table' ? 'activeMenu' : ''}>
                                    <Link to="/node-js/mysql-drop-table" onClick={() => setPathName('/node-js/mysql-drop-table')}>Drop Table</Link>
                                </li>
                                <li className={pathname == '/node-js/mysql-update-query' ? 'activeMenu' : ''}>
                                    <Link to="/node-js/mysql-update-query" onClick={() => setPathName('/node-js/mysql-update-query')}>Update Query</Link>
                                </li>
                                <li className={pathname == '/node-js/mysql-limit-clause' ? 'activeMenu' : ''}>
                                    <Link to="/node-js/mysql-limit-clause" onClick={() => setPathName('/node-js/mysql-limit-clause')}>Limit Clause</Link>
                                </li>
                                <li className={pathname == '/node-js/mysql-join-table' ? 'activeMenu' : ''}>
                                    <Link to="/node-js/mysql-join-table" onClick={() => setPathName('/node-js/mysql-join-table')}>Join Table</Link>
                                </li>
                            </ul>
                        </nav>
                        <div className='headerMenu'>
                            Node Js & MongoDB
                        </div>
                        <nav>
                            <ul>
                                <li className={pathname == '/node-js/mongodb' ? 'activeMenu' : ''}>
                                    <Link to="/node-js/mongodb" onClick={() => setPathName('/node-js/mongodb')}>Get Started</Link>
                                </li>
                                <li className={pathname == '/node-js/mongodb-create-database' ? 'activeMenu' : ''}>
                                    <Link to="/node-js/mongodb-create-database" onClick={() => setPathName('/node-js/mongodb-create-database')}>Create Database</Link>
                                </li>
                                <li className={pathname == '/node-js/mongodb-create-collection' ? 'activeMenu' : ''}>
                                    <Link to="/node-js/mongodb-create-collection" onClick={() => setPathName('/node-js/mongodb-create-collection')}>Create Collection</Link>
                                </li>
                                <li className={pathname == '/node-js/mongodb-insert-query' ? 'activeMenu' : ''}>
                                    <Link to="/node-js/mongodb-insert-query" onClick={() => setPathName('/node-js/mongodb-insert-query')}>Insert Query</Link>
                                </li>
                                <li className={pathname == '/node-js/mongodb-find-query' ? 'activeMenu' : ''}>
                                    <Link to="/node-js/mongodb-find-query" onClick={() => setPathName('/node-js/mongodb-find-query')}>Find Query</Link>
                                </li>
                                <li className={pathname == '/node-js/mongodb-query' ? 'activeMenu' : ''}>
                                    <Link to="/node-js/mongodb-query" onClick={() => setPathName('/node-js/mongodb-query')}>Query Document</Link>
                                </li>
                                <li className={pathname == '/node-js/mongodb-sort-document' ? 'activeMenu' : ''}>
                                    <Link to="/node-js/mongodb-sort-document" onClick={() => setPathName('/node-js/mongodb-sort-document')}>Sort Document</Link>
                                </li>
                                <li className={pathname == '/node-js/mongodb-delete-document' ? 'activeMenu' : ''}>
                                    <Link to="/node-js/mongodb-delete-document" onClick={() => setPathName('/node-js/mongodb-delete-document')}>Delete Document</Link>
                                </li>
                                <li className={pathname == '/node-js/mongodb-drop-document' ? 'activeMenu' : ''}>
                                    <Link to="/node-js/mongodb-drop-document" onClick={() => setPathName('/node-js/mongodb-drop-document')}>Drop Document</Link>
                                </li>
                                <li className={pathname == '/node-js/mongodb-update-document' ? 'activeMenu' : ''}>
                                    <Link to="/node-js/mongodb-update-document" onClick={() => setPathName('/node-js/mongodb-update-document')}>Update Document</Link>
                                </li>
                                <li className={pathname == '/node-js/mongodb-limit-document' ? 'activeMenu' : ''}>
                                    <Link to="/node-js/mongodb-limit-document" onClick={() => setPathName('/node-js/mongodb-limit-document')}>Limit Document</Link>
                                </li>
                                <li className={pathname == '/node-js/mongodb-join-document' ? 'activeMenu' : ''}>
                                    <Link to="/node-js/mongodb-join-document" onClick={() => setPathName('/node-js/mongodb-join-document')}>Join Document</Link>
                                </li>
                            </ul>
                        </nav>
                        <div className='headerMenu'>
                            Node Js References
                        </div>
                        <nav>
                            <ul>
                                <li className={pathname == '/node-js/built-in-modules' ? 'activeMenu' : ''}>
                                    <Link to="/node-js/built-in-modules" onClick={() => setPathName('/node-js/built-in-modules')}>Built-in Modules</Link>
                                </li>
                            </ul>
                        </nav>
                    </div>
                    <div className='col-12 col-md-9 col-lg-10 rightPart' style={{ 'overflowY': 'scroll', height: 'calc(100vh - 110px)' }}>
                        <div className='row'>
                            <div className='col-12 col-md-12 col-lg-10 col-lg-9 mb-5'>
                                <div className='headerAdSpace' ref={addspaceElement}>
                                    <ins class="adsbygoogle"
                                        style={{ 'display': 'block' }}
                                        data-ad-client="ca-pub-2061924575986153"
                                        data-ad-slot="8656415608"
                                        data-ad-format="auto"
                                        data-full-width-responsive="true"></ins>
                                    <script>
                                        (adsbygoogle = window.adsbygoogle || []).push({});
                                    </script>
                                </div>
                                <div className='container'>
                                    <div className='contentPage'>
                                        {
                                            pathName.urls ? <PreviousNext urls={ pathName.urls } /> : <></>
                                        }
                                            <Outlet />
                                        {
                                            pathName.urls ? <PreviousNext urls={ pathName.urls } /> : <></>
                                        }
                                    </div>
                                </div>
                                <div className='footerAdSpace'>
                                    <ins class="adsbygoogle"
                                        style={{ 'display': 'block' }}
                                        data-ad-client="ca-pub-2061924575986153"
                                        data-ad-slot="7856138126"
                                        data-ad-format="auto"
                                        data-full-width-responsive="true"></ins>
                                    <script>
                                        (adsbygoogle = window.adsbygoogle || []).push({});
                                    </script>
                                </div>
                            </div>
                            <div className='col-lg-3 hiddenInSmall'>
                                <ins class="adsbygoogle"
                                    style={{ 'display': 'block' }}
                                    data-ad-client="ca-pub-2061924575986153"
                                    data-ad-slot="6669380669"
                                    data-ad-format="auto"
                                    data-full-width-responsive="true"></ins>
                                <script>
                                    (adsbygoogle = window.adsbygoogle || []).push({});
                                </script>
                            </div>
                            <div className='col-12'>
                                <div className='container-fluid footerPart'>
                                    <Footer />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default NodeJsLayout