import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/node-js/mongodb-query";

export default function MongoDbQueryDocument() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Node Js - MongoDB - Query Document | Aspirant's Home");
        const urls = {
            'previous': '/node-js/mongodb-find-query',
            'next': '/node-js/mongodb-sort-document'
        }
        path.setPreviousNext(urls);
    }, [])
    
    return (
        <section className='mt-5 mb-5'>
            <h3>Node Js MongoDB Query Document</h3>
            <div className='mt-4'>
                <p>A query document is a JSON-like object that contains field-value pairs. The keys in the query document are the names of the fields in the documents in the collection, and the values are the criteria that the documents must match.</p>
                <p>Lets look an example,</p>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html:
                            `{employee_name: "Benjamin"}`
                    }}>
                    </div>
                </div>
                <p>Suppose, you have to find an email by his/her employee name, then you have to write the following code.</p>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html:
                            `<p><span class='color-blue'>var</span> MongoClient = <span class='color-yellow'>require</span>(<span class='color-green'>'mongodb'</span>).MongoClient;</p>
                            <p><span class='color-blue'>var</span> url = <span class='color-green'>"mongodb://localhost:27017/"</span>;</p>
                            <br />
                            <p>MongoClient.<span class='color-red'>connect</span>(<span class='color-blue'>url</span>, function(err, db) {</p>
                            <p style='margin-left:30px'>  <span class='color-blue'>if</span> (err) throw err;</p>
                            <p style='margin-left:30px'>  <span class='color-blue'>var</span> dbo = db.<span class='color-red'>db</span>(<span class='color-green'>"mymongodbdatabase"</span>);</p>
                            <p style='margin-left:30px'>  dbo.<span class='color-red'>collection</span>(<span class='color-green'>"employees"</span>).findOne({employee_name: "Benjamin"}).toArray(<span class='color-blue'>function</span>(err, result) {</p>
                            <p style='margin-left:60px'>    <span class='color-blue'>if</span> (err) <span class='color-blue'>throw</span> err;</p>
                            <p style='margin-left:60px'>    <span class='color-pink'>console</span>.<span class='color-red'>log</span>(result);</p>
                            <p style='margin-left:60px'>    db.<span class='color-red'>close</span>();</p>
                            <p style='margin-left:30px'>  });</p>
                            <p>});</p>`
                    }}>
                    </div>
                </div>
                <p>Which will give you this result:</p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html:
                            `<p>[</p>
                            <p style='margin-left:30px'> { _id: 58fdbf5c0ef8a50b4cdd9a80 , employee_name: "Benjamin", email: "benjamin@gmail.com" }</p>
                            <p>]</p>`
                    }}>
                    </div>
                </div>
                <p>We have discussed in details about every query element in our <a href='/mongo-db'>MongoDB Tutorial</a> page.</p>
            </div>
        </section>
    )
}
