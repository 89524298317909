import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../../Context";
import { Link } from 'react-router-dom';
export const pageUrl = () => "/mysql/date-and-time-functions/curtime";

export default function CurTime() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Mysql - Date and Time functions - CURTIME() | Aspirant's Home");
        path.setPreviousNext({});
    }, [])

    return (
        <>
            <section class='clearfix mb-3'>
                <span className='float-start'>
                    <Link to='/mysql/date-and-time-functions' onClick={() => path.setPathName('/mysql/date-and-time-functions')} className='btn btn-primary'> <i class="fa-solid fa-less-than"></i>&nbsp; Back to Date and Time functions </Link>
                </span>
            </section>
            <section className='mt-5 mb-5'>
                <h3>Mysql - CURTIME() Functions</h3>
                <div className='mt-4 mb-5'>
                    <p>
                        The <strong>`CURTIME()`</strong> function in MySQL is used to get the current time. It returns the current time in 'HH:MM:SS' format (hours, minutes, seconds). Here's how you can use it:
                    </p>
                    <div className='codePalateBox mt-2 mb-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                                <p><span class="color-blue">SELECT</span> CURTIME();</p>
                            `
                        }}></div>
                    </div>
                    <p>
                        This will return the current time, for example, <strong>`'15:30:00'`</strong> (3:30 PM).
                    </p>
                    <p>
                        You can also use <strong>`CURTIME()`</strong> in INSERT or UPDATE statements to insert or update the current time in a table:
                    </p>
                    <div className='codePalateBox mt-2 mb-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                                <p><span class="color-blue">INSERT INTO</span> table_name (time_column) <span class="color-blue">VALUES</span> (CURTIME());</p>
                            `
                        }}></div>
                    </div>
                    <p>
                        This will insert the current time into the <strong>`time_column`</strong> of <strong>`table_name`</strong>.
                    </p>
                    <p>
                        Similarly, you can use <strong>`CURTIME()`</strong> in an UPDATE statement to update the <strong>`time_column`</strong> to the current time:
                    </p>
                    <div className='codePalateBox mt-2 mb-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                                <p><span class="color-blue">UPDATE</span> table_name <span class="color-blue">SET</span> time_column = CURTIME() <span class="color-blue">WHERE</span> id = <span class="color-pink">1</span>;</p>
                            `
                        }}></div>
                    </div>
                    <p>
                        This will update the <strong>`time_column`</strong> to the current time for the row with <strong>`id`</strong> 1 in <strong>`table_name`</strong>.
                    </p>
                </div>
            </section>
        </>
    )
}