import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../../Context";
import { Link } from 'react-router-dom';
export const pageUrl = () => "/node-js/built-in-modules/assert";

export default function AssertModule() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("NodeJs - Assert Module | Aspirant's Home");
        path.setPreviousNext({});
    }, [])

    return (
        <div className='contentPage'>
            <section class='clearfix mb-3'>
                <span className='float-start'>
                    <Link to='/node-js/built-in-modules' onClick={() => path.setPathName('/node-js/built-in-modules')} className='btn btn-primary'> <i class="fa-solid fa-less-than"></i>&nbsp; Back to Built-In Modules </Link>
                </span>
            </section>
            <section className='mt-5 mb-5'>
                <h3>Node Js Assert Module</h3>
                <div className='mt-4'>
                    <p>The <strong>`assert`</strong> module in Node.js provides a collection of assertion functions used to verify invariants. If the condition evaluates to false or 0, an assertion error is thrown, which terminates the program. If the condition evaluates to true, no output is returned.</p>
                    <h5>Syntax</h5>
                    <p>The syntax for including the assert module in your application: </p>
                    <div className='codePalateBox mt-2 mb-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `<p><span class='color-blue'>var</span> assert = <span class='color-yellow'>require</span>(<span class='color-green'>'assert'</span>);</p>
                                    `
                        }}></div>
                    </div>
                </div>
                <div className='mt-4'>
                    <p>Here are some commonly used functions from the assert module:</p>
                    <ul style={{ 'listStyle': 'decimal' }}>
                        <li className='mb-3'><strong>`assert(value, message)`</strong>: Throws an <strong>`AssertionError`</strong> if the <strong>`value`</strong> is false (e.g., <strong>`false`</strong>, <strong>`0`</strong>, <strong>`null`</strong>, <strong>`undefined`</strong>, etc.).
                            <div className='codePalateBox mt-2 mb-2'>
                                <div className='codePalate' dangerouslySetInnerHTML={{
                                    __html: `<p><span class='color-blue'>const</span> assert = <span class='color-yellow'>require</span>(<span class='color-green'>'assert'</span>);</p>
                                    <p><span class='color-red'>assert</span>(<span class='color-blue'>true</span>); <span class='color-grey'>// OK</span></p>
                                    <p><span class='color-red'>assert</span>(1 === 1); <span class='color-grey'>// OK</span></p>
                                    <p><span class='color-red'>assert</span>(<span class='color-blue'>false</span>, <span class='color-green'>'This will throw an AssertionError'</span>); <span class='color-grey'>// Throws AssertionError with message</span></p>
                                    `
                                }}></div>
                            </div>
                        </li>
                        <li className='mb-3'><strong>`assert.strictEqual(actual, expected, message)`</strong>: Throws an <strong>`AssertionError`</strong> if <strong>`actual`</strong> is not strictly equal to <strong>`expected`</strong>.
                            <div className='codePalateBox mt-2 mb-2'>
                                <div className='codePalate' dangerouslySetInnerHTML={{
                                    __html: ` <p>assert.<span class='color-red'>strictEqual</span>(<span class='color-pink'>1</span>, <span class='color-pink'>1</span>); <span class='color-grey'>// OK</span></p>
                                    <p>assert.<span class='color-red'>strictEqual</span>(<span class='color-green'>'hello'</span>, <span class='color-green'>'hello'</span>); <span class='color-grey'>// OK</span></p>
                                    <p>assert.<span class='color-red'>strictEqual</span>(<span class='color-pink'>1</span>, <span class='color-greeen'>'1'</span>, <span class='color-green'>'This will throw an AssertionError'</span>); <span class='color-grey'>// Throws AssertionError with message</span> </p>`
                                }}></div>
                            </div>
                        </li>
                        <li className='mb-3'><strong>`assert.deepEqual(actual, expected, message)`</strong>: Throws an <strong>`AssertionError`</strong> if <strong>`actual`</strong> is not deeply equal to <strong>`expected`</strong>.
                            <div className='codePalateBox mt-2 mb-2'>
                                <div className='codePalate' dangerouslySetInnerHTML={{
                                    __html: `<p>assert.<span class='color-red'>deepEqual</span>({ <span class='color-pink'>a</span> : <span class='color-pink'>1</span> }, { <span class='color-pink'>a</span> : <span class='color-pink'>1</span> }); <span class='color-grey'>// OK</span></p>
                                    <p>assert.<span class='color-red'>deepEqual</span>([<span class='color-pink'>1</span>, <span class='color-pink'>2</span>, <span class='color-pink'>3</span>], [<span class='color-pink'>1</span>, <span class='color-pink'>2</span>, <span class='color-pink'>3</span>]); <span class='color-grey'>// OK</span></p>
                                    <p>assert.<span class='color-red'>deepEqual</span>({ <span class='color-pink'>a</span> : <span class='color-pink'>1</span> }, { <span class='color-pink'>b</span> : <span class='color-pink'>1</span> }, <span class='color-green'>'This will throw an AssertionError'</span>); <span class='color-grey'>// Throws AssertionError with message</span></p>
                                    `
                                }}></div>
                            </div>
                        </li>
                        <li className='mb-3'><strong>`assert.throws(block, error, message)`</strong>: Throws an <strong>`AssertionError`</strong> if <strong>`block`</strong> does not throw an error or if the error thrown is not an instance of <strong>`error`</strong>.
                            <div className='codePalateBox mt-2 mb-2'>
                                <div className='codePalate' dangerouslySetInnerHTML={{
                                    __html: `<p>assert.<span class='color-red'>throws</span>(() => { <span class='color-blue'>throw</span> <span class='color-blue'>new</span> <span class='color-red'>Error</span>(<span class='color-green'>'test error'</span>); }, <span class='color-red'>Error</span>); <span class='color-grey'>// OK</span></p>
                                    <p>assert.<span class='color-red'>throws</span>(() => { <span class='color-blue'>throw</span> <span class='color-blue'>new</span> <span class='color-red'>TypeError</span>(<span class='color-green'>'test error'</span>); }, <span class='color-red'>Error</span>, <span class='color-green'>'This will throw an AssertionError'</span>); <span class='color-grey'>// Throws AssertionError with message</span></p>                                    
                                    `
                                }}></div>
                            </div>
                        </li>
                        <li className='mb-3'><strong>`assert.equal(value1, value2, message)`</strong>: Throws an <strong>`AssertionError`</strong> if both value are not <strong>`equal`</strong>.
                            <div className='codePalateBox mt-2 mb-2'>
                                <div className='codePalate' dangerouslySetInnerHTML={{
                                    __html: `<p>assert.<span class='color-red'>equal</span>(<span class='color-pink'>70</span>, <span class='color-pink'>70</span>); <span class='color-grey'>//OK</span></p>
                                    <p>assert.<span class='color-red'>equal</span>(<span class='color-pink'>50</span>, <span class='color-green'>"50"</span>); <span class='color-grey'>//OK</span></p>
                                    <p>assert.<span class='color-red'>equal</span>(<span class='color-pink'>90</span>, <span class='color-pink'>70</span>);  <span class='color-grey'>//This will throw an AssertionError AssertionError: 90 == 70</span> </p>                                 
                                    `
                                }}></div>
                            </div>
                        </li>
                        <li className='mb-3'><strong>`assert.notEqual(value1, value2, message)`</strong>: Throws an <strong>`AssertionError`</strong> if both value are <strong>`equal`</strong>.
                            <div className='codePalateBox mt-2 mb-2'>
                                <div className='codePalate' dangerouslySetInnerHTML={{
                                    __html: `<p>assert.<span class='color-red'>notEqual</span>(<span class='color-pink'>70</span>, <span class='color-pink'>90</span>); <span class='color-grey'>//OK</span></p>
                                    <p>assert.<span class='color-red'>notEqual</span>(<span class='color-pink'>70</span>, <span class='color-pink'>70</span>);  <span class='color-grey'>//This will throw an AssertionError AssertionError: 70 == 70</span> </p>                                 
                                    `
                                }}></div>
                            </div>
                        </li>
                    </ul>
                </div>
            </section>
        </div>
    )
}
