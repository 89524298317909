import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "./Context";
import Footer from "./pages/Footer";
import TopHeader from "./pages/TopHeader";

export default function TermService() {
    const title = useContext(TitleContext);
    useEffect(() => {
        title.setPageTitle("Terms of Use | Aspirant's Home");
    }, [])


    return (
        <>
            <TopHeader />
            <div className='container-fluid'>
                <div className='row mt-5 mb-5'>
                    <div className="col-2">
                        <ins className="adsbygoogle"
                            style={{ 'display': 'block' }}
                            data-ad-client="ca-pub-4667727216643481"
                            data-ad-slot="1035415263"
                            data-ad-format="auto"
                            data-full-width-responsive="true"></ins>
                        <script>
                            (adsbygoogle = window.adsbygoogle || []).push({ });
                        </script>   
                    </div>
                    <div className="col-8">
                        <h3 className="text-center mb-5">Terms of Use</h3>
                        <p>
                            Welcome to Aspirant’s Home. By accessing or using our website, services, and educational content, you agree to comply with and be bound by the following Terms of Use. Please read them carefully. If you do not agree with any part of these terms, you should not use our services.
                        </p>
                        <ul style={{ listStyle: 'decimal' }}>
                            <li>
                                <strong>Acceptance of Terms</strong>
                                <p>
                                    By using Aspirant’s Home, you agree to abide by these Terms of Use and our Privacy Policy. These terms may be updated from time to time without prior notice. It is your responsibility to review these terms periodically for any changes.
                                </p>
                            </li>
                            <li>
                                <strong>Description of Services</strong>
                                <p>
                                    Aspirant’s Home provides educational content across various fields. We aim to enhance knowledge and facilitate learning through accessible and high-quality resources. Our content includes articles, tutorials, videos, interactive modules, and other educational materials.
                                </p>
                            </li>
                            <li>
                                <strong>User Responsibilities</strong>
                                <p>
                                    Users agree to use Aspirant’s Home for lawful purposes only. You must not:
                                </p>
                                <ul style={{ listStyle: 'disc' }} className='mb-3'>
                                    <li>Violate any applicable local, state, national, or international law.</li>
                                    <li>Infringe on the rights of others, including intellectual property rights.</li>
                                    <li>Distribute viruses, malware, or other harmful software.</li>
                                    <li>Engage in any activity that interferes with or disrupts the services or servers of Aspirant’s Home.</li>
                                </ul>
                            </li>
                            <li>
                                <strong>Intellectual Property</strong>
                                <p>
                                    All content provided on Aspirant’s Home is protected by intellectual property laws. You may not reproduce, distribute, or create derivative works from our content without express written permission. All trademarks, logos, and service marks displayed on the site are the property of Aspirant’s Home or their respective owners.
                                </p>
                            </li>
                            <li>
                                <strong>User-Generated Content</strong>
                                <p>
                                    If you submit any content to Aspirant’s Home, you grant us a non-exclusive, royalty-free, perpetual, and worldwide license to use, distribute, reproduce, modify, and display such content. You are solely responsible for the content you submit and must ensure it does not violate any third-party rights or applicable laws.
                                </p>
                            </li>
                            <li>
                                <strong>Privacy Policy</strong>
                                <p>
                                    Your use of Aspirant’s Home is also governed by our Privacy Policy, which details how we collect, use, and protect your personal information. By using our services, you consent to the practices described in the Privacy Policy.
                                </p>
                            </li>
                            <li>
                                <strong>Disclaimers</strong>
                                <p>
                                    Aspirant’s Home provides educational content on an “as is” and “as available” basis. We make no warranties, expressed or implied, regarding the accuracy, completeness, reliability, or suitability of the content. We do not guarantee that our services will be uninterrupted or error-free.
                                </p>
                            </li>
                            <li>
                                <strong>Limitation of Liability</strong>
                                <p>
                                    To the fullest extent permitted by law, Aspirant’s Home shall not be liable for any indirect, incidental, special, consequential, or punitive damages arising out of or in connection with your use of our services. This includes, but is not limited to, any loss of profits, data, or other intangible losses.
                                </p>
                            </li>
                            <li>
                                <strong>Indemnification</strong>
                                <p>
                                    You agree to indemnify, defend, and hold harmless Aspirant’s Home, its affiliates, and their respective officers, directors, employees, and agents from and against any claims, liabilities, damages, losses, and expenses arising from your use of our services or violation of these Terms of Use.
                                </p>
                            </li>
                            <li>
                                <strong>Contact Information</strong>
                                <p>If you have any questions about these Terms of Use, please contact us at support@aspirantshome.com.</p>
                            </li>
                        </ul>
                    </div>
                    <div className="col-2">
                        <ins className="adsbygoogle"
                            style={{ 'display': 'block' }}
                            data-ad-client="ca-pub-4667727216643481"
                            data-ad-slot="1035415263"
                            data-ad-format="auto"
                            data-full-width-responsive="true"></ins>
                        <script>
                            (adsbygoogle = window.adsbygoogle || []).push({ });
                        </script>   
                    </div>
                </div>
                <div className="row">
                    <div className='footerAdSpace'>
                        <ins className="adsbygoogle"
                            style={{ 'display': 'block' }}
                            data-ad-client="ca-pub-4667727216643481"
                            data-ad-slot="6714530623"
                            data-ad-format="auto"
                            data-full-width-responsive="true"></ins>
                        <script>
                            (adsbygoogle = window.adsbygoogle || []).push({ });
                        </script>
                    </div>
                    <div className='col-12'>
                        <div className='container-fluid footerPart'>
                            <Footer />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}