import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';
export const pageUrl = () => "/mongo-db/mongoose/middleware";

export default function MongooseMiddleware() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("What is Middleware in Mongoose ?");
        title.setKeyWords("middleware in mongoose schema, mongoose schema middleware, mongodb middleware, mongoose document middleware, mongoose middleware, middleware mongoose, query middleware mongoose");
        title.setPageDescription("Middleware in Mongoose refers to functions that are executed during certain stages of a document's lifecycle, such as before or after validation, save, and remove operations. Middleware is a powerful feature that allows you to add custom logic at various points in the document's lifecycle, enabling tasks like validation, logging, or modifying documents before saving them to the database.");
        path.setPreviousNext({});
    }, [])

    return (
        <>
            <section class='clearfix mb-3'>
                <span className='float-start'>
                    <Link to='/mongo-db/mongoose/introduction' onClick={() => path.setPathName('/mongo-db/mongoose/introduction')} className='btn btn-primary'> <i class="fa-solid fa-less-than"></i>&nbsp; Back to Mongoose </Link>
                </span>
            </section>
            <section className='mt-5 mb-5'>
                <h3>Mongoose - Middleware</h3>
                <div className='mt-4 mb-5'>
                    <p>
                        Middleware in Mongoose refers to functions that are executed during certain stages of a document's lifecycle, such as before or after validation, save, and remove operations. Middleware is a powerful feature that allows you to add custom logic at various points in the document's lifecycle, enabling tasks like validation, logging, or modifying documents before saving them to the database.
                    </p>
                    <h5 className='mt-4'>Types of Middleware</h5>
                    <p>
                        Mongoose supports two types of middleware:
                    </p>
                    <ul style={{ listStyle: 'decimal' }}>
                        <li>
                            <strong>Pre Middleware</strong>: Functions that run before a particular operation.
                        </li>
                        <li>
                            <strong>Post Middleware</strong>: Functions that run after a particular operation.
                        </li>
                    </ul>
                    <h5 className='mt-4'>Common Middleware Hooks</h5>
                    <p>
                        Here are some common hooks where middleware can be applied:
                    </p>
                    <ul style={{ listStyle: 'decimal' }}>
                        <li><strong>`validate`</strong>: Runs before or after document validation.</li>
                        <li><strong>`save`</strong>: Runs before or after saving a document.</li>
                        <li><strong>`remove`</strong>: Runs before or after removing a document.</li>
                        <li><strong>`updateOne`</strong> and <strong>`deleteOne`</strong>: Runs before or after updating or deleting documents.</li>
                    </ul>
                    <h5 className='mt-4'>Pre Middleware Example</h5>
                    <p>
                        Pre middleware runs before a specified operation. For instance, you might want to hash a user's password before saving the document.
                    </p>
                    <div className='codePalateBox mt-2 mb-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                                <p><span class="color-blue">const</span> userSchema = <span class="color-blue">new</span> mongoose.<span class="color-red">Schema</span>({</p>
                                <p style="margin-left:30px">    <span class="color-pink">name</span>: <span class="color-red">String</span>,</p>
                                <p style="margin-left:30px">    <span class="color-pink">email</span>: <span class="color-red">String</span>,</p>
                                <p style="margin-left:30px">    <span class="color-pink">password</span>: <span class="color-red">String</span>,</p>
                                <p>});</p>
                                <br />
                                <p><span class="color-grey">// Pre-save middleware to hash password</span></p>
                                <p>userSchema.<span class="color-red">pre</span>(<span class="color-green">'save'</span>, <span class="color-blue">function</span>(next) {</p>
                                <p style="margin-left:30px">    <span class="color-blue">const</span> user = <span class="color-pink">this</span>;</p>
                                <br />    
                                <p style="margin-left:30px">    <span class="color-grey">// Only hash the password if it has been modified (or is new)</span></p>
                                <p style="margin-left:30px">    <span class="color-blue">if</span> (!user.<span class="color-red">isModified</span>(<span class="color-green">'password'</span>)) <span class="color-blue">return</span> <span class="color-red">next</span>();</p>
                                <br />
                                <p style="margin-left:30px">    <span class="color-grey">// Hash the password using a suitable hashing function, e.g., bcrypt</span></p>
                                <p style="margin-left:30px">    bcrypt.<span class="color-red">hash</span>(user.password, <span class="color-pink">10</span>, (err, hash) => {</p>
                                <p style="margin-left:60px">    <span class="color-blue">if</span> (err) <span class="color-blue">return</span> <span class="color-red">next</span>(err);</p>
                                <p style="margin-left:60px">    user.password = hash;</p>
                                <p style="margin-left:60px">    <span class="color-red">next</span>();</p>
                                <p style="margin-left:30px">    });</p>
                                <p>});</p>
                                <br />
                                <p><span class="color-blue">const</span> User = mongoose.<span class="color-red">model</span>(<span class="color-green">'User'</span>, userSchema);</p>
                            `
                        }}></div>
                    </div>
                    <h5 className='mt-4'>Post Middleware Example</h5>
                    <p>
                        Post middleware runs after a specified operation. For example, you might want to log an action after a document is saved.
                    </p>
                    <div className='codePalateBox mt-2 mb-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                                <p><span class="color-grey">// Post-save middleware to log action</span></p>
                                <p>userSchema.<span class="color-red">post</span>(<span class="color-green">'save'</span>, function(doc) {</p>
                                <p style="margin-left:30px"><span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">&#96;User &#36;{doc.name} has been saved&#96;</span>);</p>
                                <p>});</p>
                                <br />
                                <p><span class="color-blue">const</span> User = mongoose.<span class="color-red">model</span>(<span class="color-green">'User'</span>, userSchema);</p>
                                `
                        }}></div>
                    </div>
                    <h5 className='mt-4'>Middleware for Query Operations</h5>
                    <p>
                        You can also define middleware for query operations such as <strong>`find`</strong>, <strong>`findOne`</strong>, <strong>`updateOne`</strong>, and <strong>`deleteOne`</strong>.
                    </p>
                    <h5 className='mt-4'>Error Handling in Middleware</h5>
                    <p>
                        Middleware functions can also handle errors. By passing an error to the <strong>`next`</strong> function, you can interrupt the operation and handle errors appropriately.
                    </p>
                    <div className='codePalateBox mt-2 mb-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                                <p>userSchema.<span class="color-red">pre</span>(<span class="color-green">'save'</span>, function(next) {</p>
                                <p style="margin-left:30px">    <span class="color-blue">if</span> (!<span class="color-pink">this</span>.email) {</p>
                                <p style="margin-left:60px">        <span class="color-blue">const</span> err = <span class="color-blue">new</span> <span class="color-red">Error</span>(<span class="color-green">'Email is required'</span>);</p>
                                <p style="margin-left:60px">        <span class="color-blue">return</span> <span class="color-red">next</span>(err);</p>
                                <p style="margin-left:30px">    }</p>
                                <p style="margin-left:30px">    <span class="color-red">next</span>();</p>
                                <p>});</p>
                            `
                        }}></div>
                    </div>
                    <div className='mt-4 featureClass'>
                        <h5 className='mb-3'>Important Note</h5>
                        <ul style={{ listStyle: 'disc' }}>
                            <li><strong>Pre Middleware</strong>: Runs before a specified operation (e.g.,  <strong>`pre('save')`</strong>).</li>
                            <li><strong>Post Middleware</strong>: Runs after a specified operation (e.g.,  <strong>`post('save')`</strong>).</li>
                            <li><strong>Common Hooks</strong>: Include  <strong>`validate`</strong>,  <strong>`save`</strong>,  <strong>`remove`</strong>,  <strong>`updateOne`</strong>,  <strong>`deleteOne`</strong>, etc.</li>
                            <li><strong>Usage</strong>: Define middleware functions to add custom logic such as validation, logging, or data modification.</li>
                            <li><strong>Error Handling</strong>: Middleware functions can handle and propagate errors using the  <strong>`next`</strong> function.</li>
                        </ul>
                    </div>
                </div>
            </section>
        </>
    )
}