import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/mysql/features";

export default function MysqlFeatures() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Mysql - Features | Aspirant's Home");
        const urls = {
            'previous': '/mysql/introduction',
            'next':'/mysql/create-database'
        }
        path.setPreviousNext(urls);
    },[])

    return (
        <section className='mt-5 mb-5'>
            <h3>Mysql - Features</h3>
            <div className='mt-4 mb-5'>
                <p>
                    MySQL is a very popular open-source relational database management system (RDBMS).
                </p>
                <h5>What is a Relational Database?</h5>
                <div className='mt-2 mb-2'>
                    <p>
                        A relational database is a type of database that organizes data into tables, where each table consists of rows and columns. The columns represent different attributes or properties of the data, while the rows represent individual records or entries. Relational databases are based on the relational model. The relational model defines relationships between tables using keys, which are unique identifiers for each record. These keys can be used to establish relationships between different tables, allowing data to be linked and queried in a structured manner.
                    </p>
                </div>
            </div>
            <div className='mt-5 mb-4'>
                <p>
                    Here are some key features of MySQL:
                </p>
                <div  className='featureClass'>
                    <ul style={{'listStyle':'decimal'}}>
                        <li>
                            <strong>Relational Database:</strong> MySQL is a relational database, which means it organizes data into tables, where each table consists of rows and columns.
                        </li>
                        <li>
                            <strong>SQL (Structured Query Language):</strong> MySQL uses SQL for querying and managing data. SQL is a powerful language that allows you to perform various operations such as selecting, inserting, updating, and deleting data.
                        </li>
                        <li>
                            <strong>Multi-user and Multi-threaded:</strong> MySQL is designed to support multiple users concurrently, allowing multiple users to access the database at the same time. It is also multi-threaded, which means it can handle multiple operations simultaneously.
                        </li>
                        <li>
                            <strong>Scalability:</strong> MySQL is highly scalable, meaning it can easily handle large amounts of data and high traffic loads. It can be used in small applications as well as large-scale enterprise applications.
                        </li>
                        <li>
                            <strong>Security:</strong> MySQL provides various security features to protect your data, including user authentication, access control, and encryption.
                        </li>
                        <li>
                            <strong>Compatibility:</strong> MySQL is compatible with various operating systems, programming languages, and development tools, making it easy to integrate into your existing environment.
                        </li>
                    </ul>
                </div>
            </div>
        </section>
    )
}