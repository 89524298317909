import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/mysql/alter-tables";

export default function MysqlAlterTable() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Mysql - Alter Table | Aspirant's Home");
        const urls = {
            'previous': '/mysql/show-tables',
            'next': '/mysql/rename-tables'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>Mysql - Alter Table</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The MySQL <strong>`ALTER`</strong> command is employed to alter the structure of an existing table. It facilitates a variety of modifications, including adding, deleting, or modifying columns within the table.
                </p>
                <p>
                    Moreover, the <strong>`ALTER`</strong> command is utilized to add and drop various constraints linked to an existing table.
                </p>
                <p>
                    Following is the syntax of <strong>`ALTER`</strong> command in MySQL −
                </p>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-blue">ALTER TABLE</span> table_name [alter_option ...];</p>
                        `
                    }}></div>
                </div>
            </div>
            <div className='mt-5 mb-5'>
                <h5>Add a new column</h5>
                <p>
                    To add a new column in our table, syntax will be the following. 
                </p>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-blue">ALTER TABLE</span> table_name <span class="color-blue">ADD</span> column_name datatype;</p>
                        `
                    }}></div>
                </div>
                <p>
                    <strong>`ALTER TABLE table_name`</strong> specifies the name of the table you want to alter. <strong>`ADD column_name datatype`</strong> specifies the action to add a new column to the table. <strong>`column_name`</strong> is the name of the new column you want to add, and <strong>`datatype`</strong> specifies the data type for the new column.
                </p>
                <p>
                    For example, if you have a table called <strong>`employees`</strong> and you want to add a new column called <strong>`phone_number`</strong> with the data type <strong>`VARCHAR(15)`</strong>, you would use the following SQL statement:
                </p>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-blue">ALTER TABLE</span> employees <span class="color-blue">ADD</span> phone_number <span class="color-pink">VARCHAR</span>(<span class="color-pink">15</span>);</p>
                        `
                    }}></div>
                </div>
                <p>
                    After executing this statement, the <strong>`employees`</strong> table will have a new column called <strong>`phone_number`</strong> with a data type of <strong>`VARCHAR(15)`</strong>.
                </p>
            </div>
            <div className='mt-5 mb-5'>
                <h5>Dropping a Column</h5>
                <p>
                    To drop a column in an existing table, we use the ALTER TABLE command with <strong>`DROP`</strong> clause. Here is the syntax - 
                </p>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-blue">ALTER TABLE</span> table_name <span class="color-blue">DROP</span> COLUMN column_name;</p>
                        `
                    }}></div>
                </div>
                <p>
                    <strong>`ALTER TABLE table_name`</strong> specifies the name of the table you want to alter. <strong>`DROP COLUMN column_name`</strong> specifies the action to drop (remove) a column from the table. <strong>`column_name`</strong> is the name of the column you want to remove.
                </p>
                <p>
                    For example, if you have a table called <strong>`employees`</strong> and you want to remove the <strong>`phone_number`</strong> column, you would use the following SQL statement:
                </p>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-blue">ALTER TABLE</span> employees <span class="color-blue">DROP</span> COLUMN phone_number;</p>
                        `
                    }}></div>
                </div>
                <p>
                    After executing this statement, the <strong>`employees`</strong> table will no longer have the <strong>`phone_number`</strong> column.
                </p>
            </div>
            <div className='mt-5 mb-5'>
                <h5>Modify a column</h5>
                <p>
                    The <strong>`ALTER TABLE`</strong> statement in MySQL is used to modify an existing table's structure. You can modify table column's datatype using <strong>`MODIFY`</strong> keyword with <strong>`ALTER TABLE`</strong> the below syntax - 
                </p>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-blue">ALTER TABLE</span> table_name <span class="color-blue">MODIFY</span> COLUMN column_name new_datatype;</p>
                        `
                    }}></div>
                </div>
                <p>
                    <strong>`ALTER TABLE table_name`</strong> specifies the name of the table you want to alter. <strong>`MODIFY COLUMN column_name new_datatype`</strong> specifies the action to modify a column's data type. <strong>`column_name`</strong> is the name of the column you want to modify, and <strong>`new_datatype`</strong> is the new data type you want to assign to that column.
                </p>
                <p>
                    For example, if you have a table called <strong>`employees`</strong> and you want to change the data type of the <strong>`phone_number`</strong> column from <strong>`VARCHAR(15)`</strong> to <strong>`VARCHAR(20)`</strong>, you would use the following SQL statement:
                </p>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-blue">ALTER TABLE</span> employees <span class="color-blue">MODIFY</span> COLUMN phone_number <span class="color-pink">VARCHAR</span>(<span class="color-pink">20</span>);</p>
                        `
                    }}></div>
                </div>
                <p>
                    After executing this statement, the <strong>`phone_number`</strong> column in the <strong>`employees`</strong> table will have a data type of <strong>`VARCHAR(20)`</strong>.
                </p>
            </div>
            <div className='mt-5 mb-5'>
                <h5>Rename a column</h5>
                <p>
                    You can modify table column's datatype using <strong>`MODIFY`</strong> keyword with <strong>`ALTER TABLE`</strong> the below syntax - 
                </p>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-blue">ALTER TABLE</span> table_name <span class="color-blue">CHANGE</span> COLUMN old_column_name new_column_name datatype;</p>
                        `
                    }}></div>
                </div>
                <p>
                    <strong>`ALTER TABLE table_name`</strong> specifies the name of the table you want to alter. <strong>`CHANGE COLUMN old_column_name new_column_name datatype`</strong> specifies the action to change a column's name and data type. <strong>`old_column_name`</strong> is the current name of the column you want to change, <strong>`new_column_name`</strong> is the new name you want to assign to the column, and <strong>`datatype`</strong> is the new data type you want to assign to the column.
                </p>
                <p>
                    For example, if you have a table called <strong>`employees`</strong> and you want to rename the <strong>`phone_number`</strong> column to <strong>`contact_number`</strong> and change its data type from <strong>`VARCHAR(15)`</strong> to <strong>`VARCHAR(20)`</strong>, you would use the following SQL statement:
                </p>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-blue">ALTER TABLE</span> employees <span class="color-blue">CHANGE</span> COLUMN phone_number contact_number <span class="color-pink">VARCHAR</span>(<span class="color-pink">20</span>);</p>
                        `
                    }}></div>
                </div>
                <p>
                    After executing this statement, the <strong>`phone_number`</strong> column in the <strong>`employees`</strong> table will be renamed to <strong>`contact_number`</strong> and have a data type of <strong>`VARCHAR(20)`</strong>.
                </p>
            </div>
        </section>
    )
}