import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/mysql/full-join";

export default function MysqlFullJoin() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Mysql - Full Join | Aspirant's Home");
        const urls = {
            'previous': '/mysql/cross-join',
            'next': '/mysql/self-join'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>Mysql - Full Join</h3>
            <div className='mt-4 mb-5'>
                <p>
                    In MySQL, there is no explicit <strong>`FULL JOIN`</strong> syntax like in some other database systems. However, you can achieve a similar result using a combination of <strong>`LEFT JOIN`</strong>, <strong>`RIGHT JOIN`</strong>, and <strong>`UNION`</strong>. Here's an example:
                </p>
                <p>
                    Suppose we have two tables, <strong>`employees`</strong> and <strong>`departments`</strong>, with the following data:
                </p>
                <div className='row'>
                    <div className='col-6'>
                        <h6>Table `employees`</h6>
                        <table className='table' style={{border: '1px dashed #ccc'}}> 
                            <thead>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>id</th>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>name</th>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>department_id</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>1</td>
                                    <td style={{border: '1px dashed #ccc'}}>Alice</td>
                                    <td style={{border: '1px dashed #ccc'}}>1</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>2</td>
                                    <td style={{border: '1px dashed #ccc'}}>Bob</td>
                                    <td style={{border: '1px dashed #ccc'}}>2</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>3</td>
                                    <td style={{border: '1px dashed #ccc'}}>Charlie</td>
                                    <td style={{border: '1px dashed #ccc'}}><span className='color-blue'>NULL</span></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className='col-6'>
                        <h6>Table `departments`</h6>
                        <table className='table' style={{border: '1px dashed #ccc'}}> 
                            <thead>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>id</th>
                                    <th style={{ border: '1px dashed #ccc', backgroundColor: '#e1f1ff' }}>department_name</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>1</td>
                                    <td style={{ border: '1px dashed #ccc' }}>Sales</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>2</td>
                                    <td style={{ border: '1px dashed #ccc' }}>Marketing</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>3</td>
                                    <td style={{ border: '1px dashed #ccc' }}>Finance</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <p>
                    To perform a <strong>`FULL JOIN`</strong> to include all rows from both tables regardless of whether there is a match, you can use the following query:
                </p>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-blue">SELECT</span> e.id, e.name <span class="color-blue">AS</span> employee_name, d.name <span class="color-blue">AS</span> department_name</p>
                            <p><span class="color-blue">FROM</span> employees e</p>
                            <p><span class="color-blue">LEFT JOIN</span> departments d <span class="color-blue">ON</span> e.department_id = d.id</p>
                            <p><span class="color-blue">UNION</span></p>
                            <p><span class="color-blue">SELECT</span> e.id, e.name <span class="color-blue">AS</span> employee_name, d.name <span class="color-blue">AS</span> department_name</p>
                            <p><span class="color-blue">FROM</span> employees e</p>
                            <p><span class="color-blue">RIGHT JOIN</span> departments d <span class="color-blue">ON</span> e.department_id = d.id</p>
                        `
                    }}></div>
                </div>
                <p>
                    This query first performs a <strong>`LEFT JOIN`</strong> to include all employees regardless of whether they have a department. Then, it performs a <strong>`RIGHT JOIN`</strong> to include all departments regardless of whether they have employees.
                </p>
                <p>
                    The Output of the query will be the following -
                </p>
                <table className='table' style={{border: '1px dashed #ccc'}}> 
                    <thead>
                        <tr style={{border: '1px dashed #ccc'}}>
                            <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>id</th>
                            <th style={{ border: '1px dashed #ccc', backgroundColor: '#e1f1ff' }}>employee_name</th>
                            <th style={{ border: '1px dashed #ccc', backgroundColor: '#e1f1ff' }}>department_name</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr style={{border: '1px dashed #ccc'}}>
                            <td style={{border: '1px dashed #ccc'}}>1</td>
                            <td style={{ border: '1px dashed #ccc' }}>Alice</td>
                            <td style={{ border: '1px dashed #ccc' }}>Sales</td>
                        </tr>
                        <tr style={{border: '1px dashed #ccc'}}>
                            <td style={{border: '1px dashed #ccc'}}>2</td>
                            <td style={{ border: '1px dashed #ccc' }}>Bob</td>
                            <td style={{ border: '1px dashed #ccc' }}>Marketing</td>
                        </tr>
                        <tr style={{border: '1px dashed #ccc'}}>
                            <td style={{border: '1px dashed #ccc'}}>3</td>
                            <td style={{ border: '1px dashed #ccc' }}>Charlie</td>
                            <td style={{ border: '1px dashed #ccc' }}><span className='color-blue'>NULL</span></td>
                        </tr>
                        <tr style={{border: '1px dashed #ccc'}}>
                            <td style={{border: '1px dashed #ccc'}}><span className='color-blue'>NULL</span></td>
                            <td style={{ border: '1px dashed #ccc' }}><span className='color-blue'>NULL</span></td>
                            <td style={{ border: '1px dashed #ccc' }}>Finance</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </section>
    )
}