import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/css/how-to-use";

export default function CSSSelectors() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("How to use CSS ? | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/css/selectors',
            'next': '/css/comments'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <div className='mt-4 mb-5'>
                <h3>How to use CSS?</h3>
                <p>
                    We can apply CSS in HTML Web page mainly three primary ways: <strong>`inline styles`</strong>, <strong>`internal styles`</strong>, and <strong>`external styles`</strong>. Each method has different use cases, advantages, and disadvantages. Let's see how it is different from another way.
                </p>
                <h5 className='mt-4 mb-4'>Inline Styles</h5>
                <p>
                    Inline styles are applied directly within an HTML element using the style attribute. It has a basic syntax -
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>&lt;element <span class="color-pink">style</span>=<span class="color-green">"property: value;"</span>&gt;</p>
                        `
                    }}></div>
                </div>
                <p>
                    Ok, For clear understand this, let's have a look at below example -
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>&lt;h1 <span class="color-pink">style</span>=<span class="color-green">"color: blue; text-align: center;"</span>&gt;Hello, World!&lt;/h1&gt;</p>
                        `
                    }}></div>
                </div>
                <p>
                    Output of the following -
                </p>
                <div className='outputPalateBox mb-4'>
                    <div className='outputPalate' dangerouslySetInnerHTML={{
                        __html: `
                            <h1 style="color: blue; text-align: center;">Hello, World!</h1>
                            `
                    }}></div>
                </div>
                <p className='featureClass mb-4'>
                    <strong>Pros & Cons</strong><br />
                    It is very easy to use for small, specific styles. No need to write additional <strong>`&lt;style&gt;`</strong> block. But for larger projects it is very difficult to maintain. It can not be re-use across multiple elements. It is very hard to override with other CSS rules.
                </p>

                <h5 className='mt-5 mb-4'>Internal Styles</h5>
                <p>
                    Internal styles are defined within the <strong>`&lt;style&gt;`</strong> tag inside the <strong>`&lt;head&gt;`</strong> section of an HTML document. It has a basic syntax -
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>&lt;style&gt;</p>
                            <p class="ml-30">    CSS rules</p>
                            <p>&lt;/style&gt;</p>
                        `
                    }}></div>
                </div>
                <p>
                    Ok, let's have a look at below example for better understanding.
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>&lt;style&gt;</p>
                            <p class="ml-30">    h1 {</p>
                            <p class="ml-60">        <span class="color-green">color</span>: blue;</p>
                            <p class="ml-60">        <span class="color-green">text-align</span>: center;</p>
                            <p class="ml-30">    }</p>
                            <p class="ml-30">    p {</p>
                            <p class="ml-60">        <span class="color-green">font-size</span>: <span class="color-pink">16px</span>;</p>
                            <p class="ml-60">        <span class="color-green">line-height</span>: <span class="color-pink">1.5</span>;</p>
                            <p class="ml-60">        <span class="color-green">color</span>: red;</p>
                            <p class="ml-30">    }</p>
                            <p>&lt;/style&gt;</p>
                            <p>&lt;h1&gt;Hello, World!&lt;/h1&gt;</p>
                            <p>&lt;p&gt;This is a paragraph styled with internal CSS.&lt;/p&gt;</p>
                        `
                    }}></div>
                </div>
                <p>
                    Output of the following -
                </p>
                <div className='outputPalateBox mb-4'>
                    <div className='outputPalate' dangerouslySetInnerHTML={{
                        __html: `
                            <style>
                                h1.internalClass {
                                    color: blue;
                                    text-align: center;
                                }
                                p.internalPtag {
                                    font-size: 16px;
                                    line-height: 1.5;
                                    color: red;
                                }
                            </style>
                            <h1 class="internalClass">Hello, World!</h1>
                            <p class="internalPtag">This is a paragraph styled with internal CSS.</p>
                        `
                    }}></div>
                </div>
                <p className='featureClass mb-4'>
                    <strong>Pros & Cons</strong><br />
                    It is useful for single page web page. It's styles are centralized, making them easier to manage compared to inline styles. But Styles are not reusable across multiple HTML files.
                </p>

                <h5 className='mt-5 mb-4'>External Styles</h5>
                <p>
                    External styles are defined in a separate CSS file, which is linked to the HTML document using the <strong>`&lt;link&gt;`</strong> tag.
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-grey">&lt;!DOCTYPE</span> <span class="color-blue">html</span><span class="color-grey">&gt;</span></p>
                            <p>&lt;html <span class="color-pink">lang</span>=<span class="color-green">"en"</span>&gt;</p>
                            <p class="ml-30">    &lt;head&gt;</p>
                            <p class="ml-60">        &lt;meta <span class="color-pink">charset</span>=<span class="color-green">"UTF-8"</span>&gt;</p>
                            <p class="ml-60">        &lt;meta <span class="color-pink">name</span>=<span class="color-green">"viewport"</span> <span class="color-pink">content</span>=<span class="color-green">"width=device-width, initial-scale=1.0"</span>&gt;</p>
                            <p class="ml-60">        &lt;title&gt;External CSS Example&lt;/title&gt;</p>
                            <p class="ml-60">        &lt;link <span class="color-pink">rel</span>=<span class="color-green">"stylesheet"</span> <span class="color-pink">href</span>=<span class="color-green">"styles.css"</span>&gt;</p>
                            <p class="ml-30">    &lt;/head&gt;</p>
                            <p class="ml-30">    &lt;body&gt;</p>
                            <p class="ml-60">        &lt;h1&gt;Hello, World!&lt;/h1&gt;</p>
                            <p class="ml-60">        &lt;p&gt;This is a paragraph styled with external CSS.&lt;/p&gt;</p>
                            <p class="ml-30">    &lt;/body&gt;</p>
                            <p>&lt;/html&gt;</p>
                        `
                    }}></div>
                </div>
                <h6>CSS (<strong>`styles.css`</strong>):</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>body {</p>
                            <p class="ml-30">    <span class="color-green">font-family</span>: Arial, sans-serif;</p>
                            <p>}</p>
                            <p>h1 {</p>
                            <p class="ml-30">    <span class="color-green">color</span>: blue;</p>
                            <p class="ml-30">    <span class="color-green">text-align</span>: center;</p>
                            <p>}</p>
                            <p>p {</p>
                            <p class="ml-30">    <span class="color-green">font-size</span>: <span class="color-pink">16px</span>;</p>
                            <p class="ml-30">    <span class="color-green">line-height</span>: <span class="color-pink">1.5</span>;</p>
                            <p>}</p>
                        `
                    }}></div>
                </div>
                <p className='featureClass mb-4'>
                    <strong>Pros & Cons</strong><br />
                    This types of style is reusable across multiple HTML files. It keeps HTML files clean and separates content from presentation. It is easier to maintain and update styles in large projects. But in this style, it requires additional HTTP requests to load the CSS file (though this can be mitigated with caching).
                </p>
            </div>
        </section>
    )
}