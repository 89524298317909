import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/mysql/cursor";

export default function MysqlCursor() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Mysql - Cursor | Aspirant's Home");
        const urls = {
            'previous': '/mysql/sub-query',
            'next': '/mysql/stored-procedure'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>Mysql - Cursor</h3>
            <div className='mt-4 mb-5'>
                <p>
                    A cursor in MySQL is a database object used to retrieve, traverse, and manipulate data row-by-row. Cursors are useful when you need to perform operations on individual rows returned by a query, such as complex calculations, business logic, or updates based on row-by-row processing.
                </p>
                <div className='mt-5 mb-5'>
                    <h5>Types of Cursors in MySQL</h5>
                    <div className='mt-3 mb-5'>
                        <p>
                            MySQL supports two types of cursors:    
                        </p>
                        <ul style={{listStyle:'decimal'}}>
                            <li>
                                <strong>Read-only Cursors:</strong> These cursors allow you to read data from the result set but do not support updating the data.
                            </li>
                            <li>
                                <strong>Non-scrollable Cursors:</strong> These cursors can only move forward in the result set, meaning you can only fetch rows sequentially from the first to the last.
                            </li>
                        </ul>
                    </div>   
                </div>
                <div className='mt-5 mb-5'>
                    <h5>Basic Cursor Operations</h5>
                    <div className='mt-3 mb-5'>
                        <ul style={{listStyle:'decimal'}}>
                            <li>
                                <strong>DECLARE Cursor</strong>
                                <p>The <strong>`DECLARE`</strong> statement is used to define a cursor, specifying the query whose result set the cursor will iterate over.</p>
                                <div className='codePalateBox mt-2 mb-2'>
                                    <div className='codePalate' dangerouslySetInnerHTML={{
                                        __html: `
                                            <p><span class="color-blue">DECLARE</span> cursor_name <span class="color-blue">CURSOR FOR</span> select_statement;</p>
                                        `
                                    }}></div>
                                </div>
                                <p>
                                    Where, <strong>`cursor_name`</strong> is the name you assign to the cursor. <strong>`select_statement`</strong> is the query whose result set the cursor will iterate over.
                                </p>
                            </li>
                            <li>
                                <strong>OPEN Cursor</strong>
                                <p>
                                    To open a cursor in MySQL, you use the <strong>`OPEN`</strong> statement after declaring the cursor. Opening the cursor initializes it and prepares it for fetching rows.
                                </p>
                                <div className='codePalateBox mt-2 mb-2'>
                                    <div className='codePalate' dangerouslySetInnerHTML={{
                                        __html: `
                                            <p><span class="color-blue">OPEN</span> cursor_name;</p>
                                        `
                                    }}></div>
                                </div>
                                <p>
                                    Where, <strong>`cursor_name`</strong> is the name of the cursor you have declared.
                                </p>
                            </li>
                            <li>
                                <strong>FETCH Cursor</strong>
                                <p>
                                    To fetch data from a cursor in MySQL, you use the <strong>`FETCH`</strong> statement. Fetching retrieves the next row from the result set of the cursor into specified variables.
                                </p>
                                <div className='codePalateBox mt-2 mb-2'>
                                    <div className='codePalate' dangerouslySetInnerHTML={{
                                        __html: `
                                            <p><span class="color-blue">FETCH</span> cursor_name <span class="color-blue">INTO</span> variable1, variable2, ...;</p>
                                        `
                                    }}></div>
                                </div>
                                <p>
                                    Where, <strong>`cursor_name`</strong> is the name of the cursor you have declared and opened. <strong>`variable1, variable2, ...`</strong> are the variables to store the fetched column values.
                                </p>
                            </li>
                            <li>
                                <strong>CLOSE Cursor</strong>
                                <p>
                                    To close a cursor in MySQL, you use the <strong>`CLOSE`</strong> statement. This statement releases the resources associated with an open cursor. It is important to close a cursor when it is no longer needed to free up resources and avoid potential memory leaks.
                                </p>
                                <div className='codePalateBox mt-2 mb-2'>
                                    <div className='codePalate' dangerouslySetInnerHTML={{
                                        __html: `
                                            <p><span class="color-blue">CLOSE</span> cursor_name;</p>
                                        `
                                    }}></div>
                                </div>
                                <p>
                                    Where, <strong>`cursor_name`</strong> is the name of the cursor you want to close.
                                </p>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className='mt-5 mb-5'>
                    <h5>Basic Examples</h5>
                    <div className='mt-3 mb-5'>
                        <p>Suppose, we have an <strong>`employee`</strong> table with <strong>`name`</strong> and <strong>`department_id`</strong>.</p>
                        <table className='table' style={{border: '1px dashed #ccc'}}> 
                            <thead>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>id</th>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>name</th>
                                    <th style={{ border: '1px dashed #ccc', backgroundColor: '#e1f1ff' }}>department_id</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>1</td>
                                    <td style={{border: '1px dashed #ccc'}}>Jane Smith</td>
                                    <td style={{ border: '1px dashed #ccc' }}>1</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>2</td>
                                    <td style={{border: '1px dashed #ccc'}}>John Doe</td>
                                    <td style={{ border: '1px dashed #ccc' }}>2</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>3</td>
                                    <td style={{border: '1px dashed #ccc'}}>Alice Johnson</td>
                                    <td style={{ border: '1px dashed #ccc' }}>3</td>
                                </tr>
                            </tbody>
                        </table>
                        <p>
                            Ok, lets see how we manage <strong>`CURSOR`</strong> in Stored Procedure.
                        </p>
                        <div className='codePalateBox mt-2 mb-2'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p>DELIMITER //</p>
                                    <br />
                                    <p><span class="color-blue">CREATE PROCEDURE</span> process_employees()</p>
                                    <p style="margin-left:30px">    <span class="color-blue">BEGIN</span></p>
                                    <p style="margin-left:60px">        <span class="color-blue">DECLARE</span> done <span class="color-pink">INT</span> <span class="color-blue">DEFAULT</span> <span class="color-pink">0</span>;</p>
                                    <p style="margin-left:60px">        <span class="color-blue">DECLARE</span> emp_id <span class="color-pink">INT</span>;</p>
                                    <p style="margin-left:60px">        <span class="color-blue">DECLARE</span> emp_name <span class="color-pink">VARCHAR</span>(<span class="color-pink">100</span>);</p>
                                    <p style="margin-left:60px">        <span class="color-blue">DECLARE</span> emp_dept <span class="color-pink">INT</span>;</p>
                                    <p style="margin-left:60px">        <span class="color-blue">DECLARE</span> cur <span class="color-blue">CURSOR FOR SELECT</span> id, name, department_id <span class="color-blue">FROM</span> employees;</p>
                                    <p style="margin-left:60px">        <span class="color-blue">DECLARE</span> CONTINUE HANDLER <span class="color-blue">FOR NOT</span> FOUND <span class="color-blue">SET</span> done = <span class="color-pink">1</span>;</p>
                                    <br />    
                                    <p style="margin-left:60px">        <span class="color-blue">OPEN</span> cur;</p>
                                    <br />        
                                    <p style="margin-left:60px">        read_loop: LOOP</p>
                                    <p style="margin-left:90px">            <span class="color-blue">FETCH</span> cur <span class="color-blue">INTO</span> emp_id, emp_name, emp_dept;</p>
                                    <p style="margin-left:90px">            IF done <span class="color-blue">THEN</span></p>
                                    <p style="margin-left:120px">                LEAVE read_loop;</p>
                                    <p style="margin-left:90px">            <span class="color-blue">END</span> IF;</p>
                                    <br />            
                                    <p style="margin-left:90px">            <span class="color-grey">-- Process each row: here we're just inserting a log</span></p>
                                    <p style="margin-left:90px">            <span class="color-blue">INSERT INTO</span> employee_log (employee_id, log_message, log_time) </p>
                                    <p style="margin-left:90px">            <span class="color-blue">VALUES</span> (emp_id, CONCAT(<span class="color-green">'Processed employee: '</span>, emp_name), NOW());</p>
                                    <p style="margin-left:60px">        <span class="color-blue">END</span> LOOP;</p>
                                    <br />    
                                    <p style="margin-left:60px">        <span class="color-blue">CLOSE</span> cur;</p>
                                    <p style="margin-left:30px">    <span class="color-blue">END</span> //</p>
                                    <br />
                                    <p>DELIMITER ;</p>
                                `
                            }}></div>
                        </div>
                        <p>
                            In this example
                        </p>
                        <ul style={{listStyle:'disc'}}>
                            <li>
                                <strong>DECLARE CURSOR:</strong> The cursor <strong>`cur`</strong> is declared to select <strong>`id`</strong>, <strong>`name`</strong>, and <strong>`department_id`</strong> from the <strong>`employees`</strong> table.
                            </li>
                            <li>
                                <strong>OPEN CURSOR:</strong> The cursor is opened to begin fetching rows.
                            </li>
                            <li>
                                <strong>FETCH CURSOR:</strong> Inside the loop, the <strong>`FETCH`</strong> statement retrieves each row and stores the values in the variables <strong>`emp_id`</strong>, <strong>`emp_name`</strong>, and <strong>`emp_dept`</strong>.
                            </li>
                            <li>
                                <strong>LOOP and PROCESS:</strong> The loop processes each row until there are no more rows to fetch. In this example, each row's details are inserted into the <strong>`employee_log`</strong> table.
                            </li>
                            <li>
                                <strong>CLOSE CURSOR:</strong> The cursor is closed after processing is complete.
                            </li>
                        </ul>
                    </div>
                </div>
                <div className='mt-5 mb-5'>
                    <h5>Key Points to Remember</h5>
                    <div className='mt-3 mb-5'>
                        <ul style={{listStyle:'disc'}}>
                            <li>Cursors should be closed after their use to release resources.</li>
                            <li>Cursors are typically used when you need to process rows individually and cannot achieve the desired results with set-based operations.</li>
                            <li>Overuse of cursors can lead to performance issues. They should be used judiciously, and set-based operations should be preferred when possible.</li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    )
}