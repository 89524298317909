import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "./Context";
import Footer from "./pages/Footer";
import TopHeader from "./pages/TopHeader";

export default function AboutUs() {
    const title = useContext(TitleContext);
    useEffect(() => {
        title.setPageTitle("About Us | Aspirant's Home");
    }, [])


    return (
        <>
            <TopHeader />
            <div className='container-fluid'>
                <div className='row mt-5 mb-5'>
                    <div className="col-2">
                        <ins className="adsbygoogle"
                            style={{ 'display': 'block' }}
                            data-ad-client="ca-pub-4667727216643481"
                            data-ad-slot="1035415263"
                            data-ad-format="auto"
                            data-full-width-responsive="true"></ins>
                        <script>
                            (adsbygoogle = window.adsbygoogle || []).push({ });
                        </script>   
                    </div>
                    <div className="col-8">
                        <h3 className="text-center mb-5">About Us</h3>
                        <p>
                            Aspirant's Home is an educational platform and a community focused on sharing knowledge and encouraging lifelong learning. Our goal is to provide educational content to people everywhere, driven by our belief in the power of education. We offer a variety of high-quality resources to help individuals learn more, reach their goals, and contribute to a well-informed world.
                        </p>
                        <h5 className='mt-4 mb-3'>Diverse Educational Fields</h5>
                        <p>
                            Aspirant's Home offers educational content across many fields. From sciences and humanities to technology and arts, our platform covers a wide range of interests and academic goals. Whether you're a student looking for extra study material, a professional wanting to learn new skills, or a lifelong learner curious about new subjects, Aspirant's Home has something for you.
                        </p>
                        <h5 className='mt-4 mb-3'>Our Mission: Knowledge for All</h5>
                        <p>
                            Aspirant's Home has a clear mission: to provide educational content to people everywhere. We believe education is a basic human right and a powerful tool for personal and societal growth. By making knowledge available to everyone, regardless of their location, economic status, or background, we aim to close educational gaps and promote fairness.
                        </p>
                        <p>
                            Our commitment to this mission drives us to keep expanding and updating our content library to stay relevant and inclusive. We work with educators, experts, and industry professionals to create high-quality materials for different learners. Whether through detailed tutorials, interactive modules, or engaging videos, we aim to present information that is both informative and enjoyable.
                        </p>
                        <h5 className='mt-4 mb-3'>Empowering Through Knowledge</h5>
                        <p>
                            By providing educational content to people worldwide, Aspirant's Home aims to help individuals improve their knowledge and skills. Education is a lifelong journey, and we strive to be a dependable companion on that path. Our resources support formal education, professional development, and personal growth, serving learners of all ages and stages of life.
                        </p>
                        <p>
                            We understand that the world is always changing, and so are people's educational needs. We are dedicated to staying current with trends and new topics to provide the most up-to-date information. Whether you're preparing for exams, looking to advance your career, or pursuing a passion for learning, Aspirant's Home is here to support your educational journey.
                        </p>
                    </div>
                    <div className="col-2">
                        <ins className="adsbygoogle"
                            style={{ 'display': 'block' }}
                            data-ad-client="ca-pub-4667727216643481"
                            data-ad-slot="1035415263"
                            data-ad-format="auto"
                            data-full-width-responsive="true"></ins>
                        <script>
                            (adsbygoogle = window.adsbygoogle || []).push({ });
                        </script>   
                    </div>
                </div>
                <div className="row">
                    <div className='footerAdSpace'>
                        <ins className="adsbygoogle"
                            style={{ 'display': 'block' }}
                            data-ad-client="ca-pub-4667727216643481"
                            data-ad-slot="6714530623"
                            data-ad-format="auto"
                            data-full-width-responsive="true"></ins>
                        <script>
                            (adsbygoogle = window.adsbygoogle || []).push({ });
                        </script>
                    </div>
                    <div className='col-12'>
                        <div className='container-fluid footerPart'>
                            <Footer />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}