import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../../Context";
import { Link } from 'react-router-dom';
export const pageUrl = () => "/mysql/numeric-functions/rand";

export default function RAND() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Mysql - Numeric Functions - RAND() | Aspirant's Home");
        path.setPreviousNext({});
    }, [])

    return (
        <>
            <section class='clearfix mb-3'>
                <span className='float-start'>
                    <Link to='/mysql/numeric-functions' onClick={() => path.setPathName('/mysql/numeric-functions')} className='btn btn-primary'> <i class="fa-solid fa-less-than"></i>&nbsp; Back to Numeric Functions </Link>
                </span>
            </section>
            <section className='mt-5 mb-5'>
                <h3>Mysql - RAND() Function</h3>
                <div className='mt-4 mb-5'>
                    <p>
                        The <strong>`RAND()`</strong> function in MySQL is used to generate a random floating-point value between 0 (inclusive) and 1 (exclusive). This function is commonly used when you need to retrieve random rows from a table or when you need a random number for calculations.
                    </p>
                    <h5>Syntax</h5>
                    <div className='codePalateBox mt-2 mb-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                                RAND([seed])
                            `
                        }}></div>
                    </div>
                    <p>
                        Where, <strong>`seed`</strong> (optional): If provided, <strong>`RAND()`</strong> produces a repeatable sequence of random numbers. If not provided, the function returns a different random number each time it is called.
                    </p>
                    <h5>Example Usage</h5>
                    <p>
                        <strong>Generating a Random Number:</strong>
                    </p>
                    <div className='codePalateBox mt-2 mb-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                                <p><span class="color-blue">SELECT</span> RAND() <span class="color-blue">AS</span> random_number;</p>
                            `
                        }}></div>
                    </div>
                    <p>
                        This will return a random floating-point number between 0 and 1.
                    </p>
                    <p>
                        <strong>Using a Seed:</strong>
                    </p>
                    <div className='codePalateBox mt-2 mb-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                                <p><span class="color-blue">SELECT</span> RAND(100) <span class="color-blue">AS</span> random_number;</p>
                            `
                        }}></div>
                    </div>
                    <p>
                        This will always return the same random number each time the query is executed with the same seed.
                    </p>
                </div>
                <div className='mt-5 mb-5'>
                    <h5>RAND() with MySQL Table</h5>
                    <div className='mt-3 mb-2'>
                        <p>
                            Suppose you run an e-commerce platform and want to assign a random discount between 5% and 15% to a set of products.
                        </p>
                        <table className='table' style={{border: '1px dashed #ccc'}}> 
                            <thead>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>id</th>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>product_name</th>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>price</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>1</td>
                                    <td style={{border: '1px dashed #ccc'}}>Laptop</td>
                                    <td style={{border: '1px dashed #ccc'}}>1000</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>2</td>
                                    <td style={{border: '1px dashed #ccc'}}>Smartphone</td>
                                    <td style={{border: '1px dashed #ccc'}}>500</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>3</td>
                                    <td style={{border: '1px dashed #ccc'}}>Tablet</td>
                                    <td style={{border: '1px dashed #ccc'}}>300</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>4</td>
                                    <td style={{border: '1px dashed #ccc'}}>Headphones</td>
                                    <td style={{border: '1px dashed #ccc'}}>50</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>5</td>
                                    <td style={{border: '1px dashed #ccc'}}>Smartwatch</td>
                                    <td style={{border: '1px dashed #ccc'}}>200</td>
                                </tr>
                            </tbody>
                        </table>
                        <p>
                            You want to assign a random discount percentage between 5% and 15% to each product.
                        </p>
                        <div className='codePalateBox mt-2 mb-2'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-blue">SELECT</span> id, product_name, price, ROUND(RAND() * (<span class="color-pink">15</span> - <span class="color-pink">5</span>) + <span class="color-pink">5</span>, <span class="color-pink">2</span>) <span class="color-blue">AS</span> discount_percentage  <span class="color-blue">FROM</span> products;</p>
                                `
                            }}></div>
                        </div>
                        <p>
                            This query will return:
                        </p>
                        <table className='table' style={{border: '1px dashed #ccc'}}> 
                            <thead>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>id</th>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>product_name</th>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>price</th>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>discount_percentage</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>1</td>
                                    <td style={{border: '1px dashed #ccc'}}>Laptop</td>
                                    <td style={{border: '1px dashed #ccc'}}>1000</td>
                                    <td style={{border: '1px dashed #ccc'}}>12.34</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>2</td>
                                    <td style={{border: '1px dashed #ccc'}}>Smartphone</td>
                                    <td style={{border: '1px dashed #ccc'}}>500</td>
                                    <td style={{border: '1px dashed #ccc'}}>8.76</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>3</td>
                                    <td style={{border: '1px dashed #ccc'}}>Tablet</td>
                                    <td style={{border: '1px dashed #ccc'}}>300</td>
                                    <td style={{border: '1px dashed #ccc'}}>14.55</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>4</td>
                                    <td style={{border: '1px dashed #ccc'}}>Headphones</td>
                                    <td style={{border: '1px dashed #ccc'}}>50</td>
                                    <td style={{border: '1px dashed #ccc'}}>5.67</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>5</td>
                                    <td style={{border: '1px dashed #ccc'}}>Smartwatch</td>
                                    <td style={{border: '1px dashed #ccc'}}>200</td>
                                    <td style={{border: '1px dashed #ccc'}}>10.23</td>
                                </tr>
                            </tbody>
                        </table>
                        <p>
                            In this example, For each product, the <strong>`RAND()`</strong> function generates a random value between 0 and 1. This value is scaled to a range between 5 and 15 by using the formula <strong>`RAND() * (15 - 5) + 5`</strong>. The <strong>`ROUND`</strong> function is used to round the discount percentage to two decimal places. Each product gets a different random discount percentage within the specified range.
                        </p>
                    </div>
                </div>
            </section>
        </>
    )
}