import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/node-js/mysql-limit-clause";

export default function MysqlLimitClause() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Node Js - Mysql - Limit Clause | Aspirant's Home");
        const urls = {
            'previous': '/node-js/mysql-update-query',
            'next': '/node-js/mysql-join-table'
        }
        path.setPreviousNext(urls);
    }, [])
    
    return (
        <section className='mt-5 mb-5'>
            <h3 className='mb-4'>Node Js Mysql Limit Clause</h3>
            <p>In Mysql, we can limit our fetch records using LIMIT clause.</p>
            <p>To perform a MySQL select query with limit clause in Node.js, you can use the <strong>`mysql`</strong> module along with the <strong>`mysql`</strong> package. First, make sure to install the <strong>`mysql`</strong> package using npm:</p>
            <div className='codePalateBox mt-2 mb-2'>
                <div className='codePalate' dangerouslySetInnerHTML={{
                    __html: `npm install mysql`
                }}></div>
            </div>
            <p>Then, you can create a connection to your MySQL database and execute a select query. Select name and email from the "users" table with the limit, and display the return object:</p>
            <div className='codePalateBox mt-2 mb-2'>
                <div className='codePalate' dangerouslySetInnerHTML={{
                    __html: `<p><span class='color-blue'>var</span> mysql = <span class='color-yellow'>require</span>(<span class='color-green'>'mysql'</span>);</p>
                    <br />
                    <p><span class='color-blue'>var</span> con = mysql.<span class='color-red'>createConnection</span>({</p>
                        <p style='margin-left:30px'><span class='color-pink'>host</span>: <span class='color-green'>"localhost"</span>,</p>
                        <p style='margin-left:30px'><span class='color-pink'>user</span>: <span class='color-green'>"yourusername"</span>,</p>
                        <p style='margin-left:30px'><span class='color-pink'>password</span>: <span class='color-green'>"yourpassword"</span>,</p>
                        <p style='margin-left:30px'><span class='color-pink'>database</span>: <span class='color-green'>"mydb"</span></p>
                    <p>});</p>
                    <br />
                    <p>con.<span class='color-red'>connect</span>(function(err) {</p>
                        <p style='margin-left:30px'><span class='color-blue'>if</span> (err) throw err;</p>
                        <p style='margin-left:30px'>con.<span class='color-red'>query</span>(<span class='color-green'>"SELECT name, email FROM users LIMIT 5"</span>, function (err, result, fields) {</p>
                        <p style='margin-left:60px'><span class='color-blue'>if</span> (err) throw err;</p>
                        <p style='margin-left:60px'><span class='color-pink'>console</span>.<span class='color-red'>log</span>(result);</p>
                        <p style='margin-left:30px'>});</p>
                    <p>});</p>
                    `
                }}></div>
            </div>
            <p>Which will give you this result:</p>
            <div className='codePalateBox mt-2 mb-2'>
                <div className='codePalate' dangerouslySetInnerHTML={{
                    __html: `<p>[</p>
                        <p style='margin-left:10px'>{ name: 'John', email: 'john@gmail.com'},</p>
                        <p style='margin-left:10px'>{ name: 'Peter', email: 'peter@gmail.com'},</p>
                        <p style='margin-left:10px'>{ name: 'Amy', email: 'amy@gmail.com'},</p>
                        <p style='margin-left:10px'>{ name: 'Hannah', email: 'hannah@gmail.com'},</p>
                        <p style='margin-left:10px'>{ name: 'Michael', email: 'michael@gmail.com'}</p>
                        ]`
                }}></div>
            </div>
        </section>
    )
}
