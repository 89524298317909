import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';
export const pageUrl = () => "/mongo-db/aggregation/match";

export default function MongoAggregationMatch() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("$match Aggregation Pipelines in MongoDB");
        title.setKeyWords("$match aggregation pipeline in mongodb, mongodb aggregation pipeline match, mongodb aggregation pipeline multiple match, mongodb pipeline match in array, mongodb match pipeline, mongodb aggregation pipeline index, aggregation pipeline mongodb examples, aggregation pipeline mongodb, mongodb $match aggregation, mongodb aggregate lookup pipeline match");
        title.setPageDescription("The `$match` stage in MongoDB's aggregation framework is used to filter documents in a collection. It acts similarly to the `find` method by filtering documents based on specified criteria. However, `$match` is used within the context of an aggregation pipeline to filter documents at any stage of the pipeline.");
        path.setPreviousNext({});
    }, [])

    return (
        <>
            <section class='clearfix mb-3'>
                <span className='float-start'>
                    <Link to='/mongo-db/aggregation' onClick={() => path.setPathName('/mongo-db/aggregation')} className='btn btn-primary'> <i class="fa-solid fa-less-than"></i>&nbsp; Back to Aggregation </Link>
                </span>
            </section>
            <section className='mt-5 mb-5'>
                <h3>MongoDB - $match Pipelines</h3>
                <div className='mt-4 mb-5'>
                    <p>
                        The <strong>`$match`</strong> stage in MongoDB's aggregation framework is used to filter documents in a collection. It acts similarly to the <strong>`find`</strong> method by filtering documents based on specified criteria. However, <strong>`$match`</strong> is used within the context of an aggregation pipeline to filter documents at any stage of the pipeline.
                    </p>
                    <p>
                        It allows you to use all the query operators available in MongoDB, such as <strong>`$eq`</strong>, <strong>`$gt`</strong>, <strong>`$lt`</strong>, <strong>`$in`</strong>, and more.
                    </p>
                    <h5 className='mt-4 mb-3'>Example Scenario</h5>
                    <p>
                        Consider a <strong>`students`</strong> collection where you want to filter students based on their age and enrolled courses.
                    </p>
                    <div className='codePalateBox mt-2 mb-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                                <p>[</p>
                                <p style="margin-left:30px">    { <span class="color-pink">"_id"</span>: <span class="color-pink">1</span>, <span class="color-pink">"name"</span>: <span class="color-green">"Alice"</span>, <span class="color-pink">"age"</span>: <span class="color-pink">21</span>, <span class="color-pink">"courses"</span>: [<span class="color-green">"Math"</span>, <span class="color-green">"Science"</span>] },</p>
                                <p style="margin-left:30px">    { <span class="color-pink">"_id"</span>: <span class="color-pink">2</span>, <span class="color-pink">"name"</span>: <span class="color-green">"Bob"</span>, <span class="color-pink">"age"</span>: <span class="color-pink">19</span>, <span class="color-pink">"courses"</span>: [<span class="color-green">"History"</span>] },</p>
                                <p style="margin-left:30px">    { <span class="color-pink">"_id"</span>: <span class="color-pink">3</span>, <span class="color-pink">"name"</span>: <span class="color-green">"Charlie"</span>, <span class="color-pink">"age"</span>: <span class="color-pink">23</span>, <span class="color-pink">"courses"</span>: [<span class="color-green">"Math"</span>, <span class="color-green">"Science"</span>, <span class="color-green">"History"</span>] }</p>
                                <p>]</p>
                            `
                        }}></div>
                    </div>
                    <h5 className='mt-5 mb-3'>Filtering by Age</h5>
                    <p>
                        To filter students who are older than 20 years:
                    </p>

                    <div className='codePalateBox mt-2 mb-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                                <p>db.students.<span class="color-red">aggregate</span>([</p>
                                <p style="margin-left:30px">    { <span class="color-pink">$match</span>: { <span class="color-pink">age</span>: { <span class="color-pink">$gt</span>: <span class="color-pink">20</span> } } }</p>
                                <p>])</p>
                            `
                        }}></div>
                    </div>
                    <h6 className='mt-4 mb-4'>Result of the above</h6>
                    <div className='codePalateBox mt-2 mb-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                                <p>[</p>
                                <p style="margin-left:30px">    { <span class="color-pink">"_id"</span>: <span class="color-pink">1</span>, <span class="color-pink">"name"</span>: <span class="color-green">"Alice"</span>, <span class="color-pink">"age"</span>: <span class="color-pink">21</span>, <span class="color-pink">"courses"</span>: [<span class="color-green">"Math"</span>, <span class="color-green">"Science"</span>] },</p>
                                <p style="margin-left:30px">    { <span class="color-pink">"_id"</span>: <span class="color-pink">3</span>, <span class="color-pink">"name"</span>: <span class="color-green">"Charlie"</span>, <span class="color-pink">"age"</span>: <span class="color-pink">23</span>, <span class="color-pink">"courses"</span>: [<span class="color-green">"Math"</span>, <span class="color-green">"Science"</span>, <span class="color-green">"History"</span>] }</p>
                                <p>]</p>
                            `
                        }}></div>
                    </div>
                    <h5 className='mt-5 mb-3'>Filtering by Courses</h5>
                    <p>
                        To filter students who are enrolled in the "Math" course:
                    </p>
                    <div className='codePalateBox mt-2 mb-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                                <p>db.students.<span class="color-red">aggregate</span>([</p>
                                <p>    { <span class="color-pink">$match</span>: { <span class="color-pink">courses</span>: <span class="color-green">"Math"</span> } }</p>
                                <p>])</p>
                            `
                        }}></div>
                    </div>
                    <h6 className='mt-4 mb-4'>Result of the above</h6>
                    <div className='codePalateBox mt-2 mb-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                                <p>[</p>
                                <p style="margin-left:30px">    { <span class="color-pink">"_id"</span>: <span class="color-pink">1</span>, <span class="color-pink">"name"</span>: <span class="color-green">"Alice"</span>, <span class="color-pink">"age"</span>: <span class="color-pink">21</span>, <span class="color-pink">"courses"</span>: [<span class="color-green">"Math"</span>, <span class="color-green">"Science"</span>] },</p>
                                <p style="margin-left:30px">    { <span class="color-pink">"_id"</span>: <span class="color-pink">3</span>, <span class="color-pink">"name"</span>: <span class="color-green">"Charlie"</span>, <span class="color-pink">"age"</span>: <span class="color-pink">23</span>, <span class="color-pink">"courses"</span>: [<span class="color-green">"Math"</span>, <span class="color-green">"Science"</span>, <span class="color-green">"History"</span>] }</p>
                                <p>]</p>
                            `
                        }}></div>
                    </div>
                    <h5 className='mt-5 mb-3'>Combining Multiple Conditions</h5>
                    <p>
                        To filter students who are older than 20 years and enrolled in the "Science" course:
                    </p>
                    <div className='codePalateBox mt-2 mb-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                                <p>db.students.<span class="color-red">aggregate</span>([</p>
                                <p style="margin-left:30px">    { <span class="color-pink">$match</span>: { <span class="color-pink">age</span>: { <span class="color-pink">$gt</span>: <span class="color-pink">20</span> }, <span class="color-pink">courses</span>: <span class="color-green">"Science"</span> } }</p>
                                <p>])</p>
                            `
                        }}></div>
                    </div>
                    <h6 className='mt-4 mb-4'>Result of the above</h6>
                    <div className='codePalateBox mt-2 mb-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                                <p>[</p>
                                <p style="margin-left:30px">    { <span class="color-pink">"_id"</span>: <span class="color-pink">1</span>, <span class="color-pink">"name"</span>: <span class="color-green">"Alice"</span>, <span class="color-pink">"age"</span>: <span class="color-pink">21</span>, <span class="color-pink">"courses"</span>: [<span class="color-green">"Math"</span>, <span class="color-green">"Science"</span>] },</p>
                                <p style="margin-left:30px">    { <span class="color-pink">"_id"</span>: <span class="color-pink">3</span>, <span class="color-pink">"name"</span>: <span class="color-green">"Charlie"</span>, <span class="color-pink">"age"</span>: <span class="color-pink">23</span>, <span class="color-pink">"courses"</span>: [<span class="color-green">"Math"</span>, <span class="color-green">"Science"</span>, <span class="color-green">"History"</span>] }</p>
                                <p>]</p>
                            `
                        }}></div>
                    </div>
                    <div className='mt-5 mb-4 featureClass'>
                        <h5 className='mb-4'>Important Note</h5>
                        <ul style={{listStyle:'disc'}}>
                            <li><strong>Basic Filtering</strong>: <strong>`$match`</strong> can perform simple filtering operations using operators like <strong>`$gt`</strong>, <strong>`$lt`</strong>, <strong>`$eq`</strong>, etc.</li>
                            <li><strong>Array Matching</strong>: When matching array fields, specifying a value (e.g., courses: "Math") checks if the array contains that value.</li>
                            <li><strong>Combining Conditions</strong>: Multiple conditions can be combined to create complex queries, similar to using logical operators like <strong>`$and`</strong>, <strong>`$or`</strong>, <strong>`$not`</strong>, etc.</li>
                        </ul>
                    </div>
                </div>
            </section>
        </>
    )
}