import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../../Context";
import { Link } from 'react-router-dom';
export const pageUrl = () => "/mysql/string-functions/instr";

export default function INSTR() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Mysql - String Functions - INSTR() | Aspirant's Home");
        path.setPreviousNext({});
    }, [])

    return (
        <>
            <section class='clearfix mb-3'>
                <span className='float-start'>
                    <Link to='/mysql/string-functions' onClick={() => path.setPathName('/mysql/string-functions')} className='btn btn-primary'> <i class="fa-solid fa-less-than"></i>&nbsp; Back to String Functions </Link>
                </span>
            </section>
            <section className='mt-5 mb-5'>
                <h3>Mysql - INSTR() Function</h3>
                <div className='mt-3 mb-5'>
                    <p>
                        The <strong>`INSTR()`</strong> function in MySQL is used to find the position of the first occurrence of a substring within a string. It returns the position (1-based index) of the first occurrence of the specified substring. If the substring is not found, it returns 0.
                    </p>
                    <h5>Syntax</h5>
                    <div className='codePalateBox mt-2 mb-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                                <p>INSTR(string, substring)</p>
                            `
                        }}></div>
                    </div>
                    <p>
                        Where, <strong>`string`</strong> is the string to search within and <strong>`substring`</strong> is the substring to search for.
                    </p>
                    <h5>Examples</h5>
                    <div className='codePalateBox mt-2 mb-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                                <p><span class="color-blue">SELECT</span> INSTR(<span class="color-green">'Hello, World!'</span>, <span class="color-green">'World'</span>) <span class="color-blue">AS</span> position;</p>
                            `
                        }}></div>
                    </div>
                    <p>
                        In this example, 'World' starts at the 8th position in 'Hello, World!'.
                    </p>
                </div>
                <div className='mt-5 mb-5'>
                    <h5>INSTR() with MySQL Table</h5>
                    <div className='mt-3 mb-2'>
                        <p>
                            Consider a table <strong>`employees`</strong> with the following structure and data:
                        </p>
                        <table className='table' style={{border: '1px dashed #ccc'}}> 
                            <thead>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>id</th>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>name</th>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>email</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>1</td>
                                    <td style={{border: '1px dashed #ccc'}}>Alice Johnson</td>
                                    <td style={{border: '1px dashed #ccc'}}>alice@example.com</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>2</td>
                                    <td style={{border: '1px dashed #ccc'}}>Bob Smith</td>
                                    <td style={{border: '1px dashed #ccc'}}>bob.smith@sample.com</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>3</td>
                                    <td style={{border: '1px dashed #ccc'}}>Charlie Brown</td>
                                    <td style={{border: '1px dashed #ccc'}}>charlie.brown@company.org</td>
                                </tr>
                            </tbody>
                        </table>
                        <h5>Examples</h5>
                        <ul style={{listStyle: 'decimal'}}>
                            <li>
                                <strong>Find Position of Substring in Email:</strong>
                                <div className='codePalateBox mt-2 mb-2'>
                                    <div className='codePalate' dangerouslySetInnerHTML={{
                                        __html: `
                                            <p><span class="color-blue">SELECT</span> id, name, INSTR(email, <span class="color-green">'@'</span>) <span class="color-blue">AS</span> at_position <span class="color-blue">FROM</span> employees;</p>
                                        `
                                    }}></div>
                                </div>
                                <p>
                                    This query will return:
                                </p>
                                <table className='table' style={{border: '1px dashed #ccc'}}> 
                                    <thead>
                                        <tr style={{border: '1px dashed #ccc'}}>
                                            <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>id</th>
                                            <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>name</th>
                                            <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>at_position</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr style={{border: '1px dashed #ccc'}}>
                                            <td style={{border: '1px dashed #ccc'}}>1</td>
                                            <td style={{border: '1px dashed #ccc'}}>Alice Johnson</td>
                                            <td style={{border: '1px dashed #ccc'}}>6</td>
                                        </tr>
                                        <tr style={{border: '1px dashed #ccc'}}>
                                            <td style={{border: '1px dashed #ccc'}}>2</td>
                                            <td style={{border: '1px dashed #ccc'}}>Bob Smith</td>
                                            <td style={{border: '1px dashed #ccc'}}>10</td>
                                        </tr>
                                        <tr style={{border: '1px dashed #ccc'}}>
                                            <td style={{border: '1px dashed #ccc'}}>3</td>
                                            <td style={{border: '1px dashed #ccc'}}>Charlie Brown</td>
                                            <td style={{border: '1px dashed #ccc'}}>14</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </li>
                            <li>
                                <strong>Select Rows Based on Substring Position:</strong>
                                <div className='codePalateBox mt-2 mb-2'>
                                    <div className='codePalate' dangerouslySetInnerHTML={{
                                        __html: `
                                            <p><span class="color-blue">SELECT</span> id, name, email <span class="color-blue">FROM</span> employees <span class="color-blue">WHERE</span> INSTR(email, <span class="color-green">'example'</span>) > <span class="color-pink">0</span>;</p>
                                        `
                                    }}></div>
                                </div>
                                <p>
                                    This query will return:
                                </p>
                                <table className='table' style={{border: '1px dashed #ccc'}}> 
                                    <thead>
                                        <tr style={{border: '1px dashed #ccc'}}>
                                            <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>id</th>
                                            <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>name</th>
                                            <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>at_position</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr style={{border: '1px dashed #ccc'}}>
                                            <td style={{border: '1px dashed #ccc'}}>1</td>
                                            <td style={{border: '1px dashed #ccc'}}>Alice Johnson</td>
                                            <td style={{border: '1px dashed #ccc'}}>alice@example.com</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <p>
                                    This selects only the rows where 'example' is found in the email address.
                                </p>
                            </li>
                        </ul>
                    </div>
                </div>
            </section>
        </>
    )
}