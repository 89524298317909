import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/html/meta-tags";

export default function HTMLMetaTags() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("HTML - Meta Tags | Aspirant's Home");
        const urls = {
            'previous': '/html/javascripts',
            'next': '/html/classes'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>HTML - Meta Tags</h3>
            <div className='mt-4 mb-5'>
                <p>
                    HTML meta tags are snippets of text placed within the <strong>`&lt;head&gt;`</strong> section of an HTML document that provide metadata about the web page. Metadata is data about data, and in this context, it includes information like the page description, keywords, author, and other details that help search engines and browsers understand the content and purpose of the page. Meta tags do not display content on the page but play a crucial role in SEO (Search Engine Optimization) and user experience.
                </p>
                <p>
                    Here are some key points about HTML meta tags:
                </p>
                <ul style={{ listStyle: 'decimal' }}>
                    <li>
                        <strong>Meta Description Tag:</strong>
                        <ul style={{ listStyle: 'disc' }}>
                            <li>Provides a brief summary of the page content.</li>
                            <li>Often displayed in search engine results under the page title.</li>
                        </ul>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p>&lt;meta <span class="color-pink">name</span>=<span class="color-green">"description"</span> <span class="color-pink">content</span>=<span class="color-green">"Learn HTML with comprehensive tutorials and guides."</span>&gt;</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <strong>Meta Keywords Tag:</strong>
                        <ul style={{ listStyle: 'disc' }}>
                            <li>Lists keywords relevant to the page content.</li>
                            <li>Used to be important for SEO, but now less influential.</li>
                        </ul>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p>&lt;meta <span class="color-pink">name</span>=<span class="color-green">"keywords"</span> <span class="color-pink">content</span>=<span class="color-green">"HTML, web development, tutorials, guides"</span>&gt;</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <strong>Meta Charset Tag:</strong>
                        <ul style={{ listStyle: 'disc' }}>
                            <li>Specifies the character encoding for the HTML document.</li>
                            <li>Ensures the correct display of text.</li>
                        </ul>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p>&lt;meta <span class="color-pink">charset</span>=<span class="color-green">"UTF-8"<s/pan>&gt;</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <strong>Meta Author Tag:</strong>
                        <ul style={{ listStyle: 'disc' }}>
                            <li>Indicates the author of the web page.</li>
                        </ul>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p>&lt;meta <span class="color-pink">name</span>=<span class="color-green">"author"</span> content="John Doe"&gt;</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <strong>Meta Viewport Tag:</strong>
                        <ul style={{ listStyle: 'disc' }}>
                            <li>Controls the layout on mobile browsers.</li>
                            <li>Important for responsive web design.</li>
                        </ul>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p>&lt;meta <span class="color-pink">name</span>=<span class="color-green">"viewport"</span> <span class="color-pink">content</span>=<span class="color-green">"width=device-width, initial-scale=1.0"</span>&gt;</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <strong>Meta Robots Tag:</strong>
                        <ul style={{ listStyle: 'disc' }}>
                            <li>Instructs search engine crawlers how to index the page.</li>
                        </ul>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p>&lt;meta <span class="color-pink">name</span>=<span class="color-green">"robots"</span> <span class="color-pink">content</span>=<span class="color-green">"index, follow"</span>&gt;</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <strong>Meta Refresh Tag:</strong>
                        <ul style={{ listStyle: 'disc' }}>
                            <li>Specifies an automatic refresh interval for the page.</li>
                            <li>Can redirect after a specified number of seconds.</li>
                        </ul>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p>&lt;meta <span class="color-pink">http-equiv</span>=<span class="color-green">"refresh"</span> <span class="color-pink">content</span>=<span class="color-green">"30"</span>&gt;</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <strong>Meta HTTP-Equiv Tag:</strong>
                        <ul style={{ listStyle: 'disc' }}>
                            <li>Provides HTTP headers to the web page.</li>
                        </ul>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p>&lt;meta <span class="color-pink">http-equiv</span>=<span class="color-green">"X-UA-Compatible"</span> <span class="color-pink">content</span>=<span class="color-green">"IE=edge"</span>&gt;</p>
                                `
                            }}></div>
                        </div>
                    </li>
                </ul>
                <p className='mt-5 featureClass'>
                    In summary, HTML meta tags provide essential information about the web page to browsers, search engines, and other web services, playing a critical role in SEO, accessibility, and overall web development.
                </p>
            </div>
        </section>
    )
}