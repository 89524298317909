import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/mysql/between-operator";

export default function MysqlBetweenOperator() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Mysql - BETWEEN Operator | Aspirant's Home");
        const urls = {
            'previous': '/mysql/not-operator',
            'next': '/mysql/union-operator'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>Mysql - BETWEEN Operator</h3>
            <div className='mt-4 mb-5'>
                <p>
                    In MySQL, the <strong>`BETWEEN`</strong> operator is used to select values within a range. It is inclusive, meaning it includes both the start and end values specified in the range. Here's the basic syntax:
                </p>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-blue">SELECT</span> column1, column2, ...</p>
                            <p><span class="color-blue">FROM</span> table_name</p>
                            <p><span class="color-blue">WHERE</span> column_name <span class="color-blue">BETWEEN</span> value1 <span class="color-blue">AND</span> value2;</p>
                        `
                    }}></div>
                </div>
                <p>
                    Where, <strong>`column1, column2, ...`</strong> are the columns you want to retrieve. <strong>`table_name`</strong> is the name of the table you're querying. <strong>`column_name`</strong> is the column you want to check for values within a range and
                    <strong>`value1, value2`</strong> are the start and end values of the range.
                </p>
                <p>
                    Suppose, we have a <strong>`employees`</strong> table like below -
                </p>
                <table className='table' style={{border: '1px dashed #ccc'}}> 
                    <thead>
                        <tr style={{border: '1px dashed #ccc'}}>
                            <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>id</th>
                            <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>name</th>
                            <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>department</th>
                            <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>salary</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr style={{border: '1px dashed #ccc'}}>
                            <td style={{border: '1px dashed #ccc'}}>1</td>
                            <td style={{border: '1px dashed #ccc'}}>Jane Smith</td>
                            <td style={{border: '1px dashed #ccc'}}>IT</td>
                            <td style={{border: '1px dashed #ccc'}}>500000</td>
                        </tr>
                        <tr style={{border: '1px dashed #ccc'}}>
                            <td style={{border: '1px dashed #ccc'}}>2</td>
                            <td style={{border: '1px dashed #ccc'}}>John Doe</td>
                            <td style={{border: '1px dashed #ccc'}}>IT</td>
                            <td style={{border: '1px dashed #ccc'}}>350000</td>
                        </tr>
                        <tr style={{border: '1px dashed #ccc'}}>
                            <td style={{border: '1px dashed #ccc'}}>3</td>
                            <td style={{border: '1px dashed #ccc'}}>Johny Smith</td>
                            <td style={{border: '1px dashed #ccc'}}>Accountant</td>
                            <td style={{border: '1px dashed #ccc'}}>50000</td>
                        </tr>
                        <tr style={{border: '1px dashed #ccc'}}>
                            <td style={{border: '1px dashed #ccc'}}>4</td>
                            <td style={{border: '1px dashed #ccc'}}>Jeffer Joe</td>
                            <td style={{border: '1px dashed #ccc'}}>HR Manager</td>
                            <td style={{border: '1px dashed #ccc'}}>380000</td>
                        </tr>
                        <tr style={{border: '1px dashed #ccc'}}>
                            <td style={{border: '1px dashed #ccc'}}>5</td>
                            <td style={{border: '1px dashed #ccc'}}>Sikhar Smith</td>
                            <td style={{border: '1px dashed #ccc'}}>Accountant</td>
                            <td style={{border: '1px dashed #ccc'}}>70000</td>
                        </tr>
                        <tr style={{border: '1px dashed #ccc'}}>
                            <td style={{border: '1px dashed #ccc'}}>6</td>
                            <td style={{border: '1px dashed #ccc'}}>Xavier Doe</td>
                            <td style={{border: '1px dashed #ccc'}}>IT</td>
                            <td style={{border: '1px dashed #ccc'}}>750000</td>
                        </tr>
                    </tbody>
                </table>
                <p>
                    Suppose you want to find all employees whose salary is between 50,000 and 100,000. You would use the <strong>`BETWEEN`</strong> operator as follows:
                </p>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-blue">SELECT</span> * <span class="color-blue">FROM</span> employees <span class="color-blue">WHERE</span> salary <span class="color-blue">BETWEEN</span> <span class="color-pink">50000</span> <span class="color-blue">AND</span> <span class="color-pink">100000</span>;
                        `
                    }}></div>
                </div>
                <p>
                    This query will return all rows where the salary falls within the range of 50,000 to 100,000, inclusive.
                </p>
                <p>
                    The Output of the query will be the following -
                </p>
                <table className='table' style={{border: '1px dashed #ccc'}}> 
                    <thead>
                        <tr style={{border: '1px dashed #ccc'}}>
                            <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>id</th>
                            <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>name</th>
                            <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>department</th>
                            <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>salary</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr style={{border: '1px dashed #ccc'}}>
                            <td style={{border: '1px dashed #ccc'}}>3</td>
                            <td style={{border: '1px dashed #ccc'}}>Johny Smith</td>
                            <td style={{border: '1px dashed #ccc'}}>Accountant</td>
                            <td style={{border: '1px dashed #ccc'}}>50000</td>
                        </tr>
                        <tr style={{border: '1px dashed #ccc'}}>
                            <td style={{border: '1px dashed #ccc'}}>5</td>
                            <td style={{border: '1px dashed #ccc'}}>Sikhar Smith</td>
                            <td style={{border: '1px dashed #ccc'}}>Accountant</td>
                            <td style={{border: '1px dashed #ccc'}}>70000</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </section>
    )
}