import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/mongo-db/drop-database";

export default function MongoDropDatabase() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Drop Database in MongoDB - Step-by-Step Complete Guide");
        title.setKeyWords("drop database mongodb, drop database mongodb command line, mongodb drop local database, drop a database mongodb, drop all collections in database mongodb, mongodb drop database example, create and drop database in mongodb, mongodb drop all database, mongodb drop all collections in database, mongodb drop all database command line, mongodb drop all database, drop database in mongodb, mongodb drop database command line, mongodb drop multiple databases");
        title.setPageDescription("Dropping a database in MongoDB is a straightforward process. You use the `dropDatabase()` method to delete the database currently in use. Here's a step-by-step guide to dropping a database in MongoDB:");

        const urls = {
            'previous': '/mongo-db/create-database',
            'next': '/mongo-db/connecting-database'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>MongoDB - Drop Database</h3>
            <div className='mt-4 mb-5'>
                <p>
                    Dropping a database in MongoDB is a straightforward process. You use the <strong>`dropDatabase()`</strong> method to delete the database currently in use. Here's a step-by-step guide to dropping a database in MongoDB:
                </p>
                <p>
                    Ensure your MongoDB server is running. Open a new terminal and use the <strong>`use`</strong> command to switch to the database you want to drop. For example, if you want to drop a database named <strong>`mynewdatabase`</strong>, type:
                </p>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>use mynewdatabase</p>
                        `
                    }}></div>
                </div>
                <p>
                    Use the <strong>`dropDatabase()`</strong> method to drop the database:
                </p>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>db.<span class="color-red">dropDatabase</span>()</p>
                        `
                    }}></div>
                </div>
                <p>
                    To verify that the database has been dropped, you can list all databases:
                </p>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>&gt; show dbs</p>
                            <p><span style="margin-right:30px">admin</span>          0.000GB</p>
                            <p><span style="margin-right:30px">config</span>         0.000GB</p>
                            <p><span style="margin-right:40px">local</span>          0.000GB</p>
                        `
                    }}></div>
                </div>
            </div>
        </section>
    )
}