import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/css/background-image";

export default function CSSBackgroundImage() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Background Image in CSS | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/css/background-color',
            'next': '/css/background-repeat'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>Background Image</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <strong>`background-image`</strong> property in CSS is used to set an image as the background of an element. This helps you add a nice visual look to your web pages. It has a basic syntax -
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>selector {</p>
                            <p class="ml-30">    <span class="color-green">background-image</span>: <span class="color-yellow">url</span>(<span class="color-green">'image_url'</span>);</p>
                            <p>}</p>
                        `
                    }}></div>
                </div>

                <h5 className="mt-5 mb-3">Example</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>&lt;style&gt;</p>
                            <p class="ml-30">.colorNameDiv{</p>
                            <p class="ml-60">    <span class="color-green">background-image</span> : <span class="color-yellow">url</span>(<span class="color-green">'../../assets/background_image.webp'</span>);</p>
                            <p class="ml-60">    <span class="color-green">height</span> : <span class="color-pink">300px</span>;</p>
                            <p class="ml-60">    <span class="color-green">align-content</span>: center;</p>
                            <p class="ml-60">    <span class="color-green">text-align</span>: center;</p>
                            <p class="ml-30">}</p>
                            <p>&lt;/style&gt;</p>
                            <p>&lt;h6&gt;Div Tag&lt;/h6&gt;</p>
                            <p>&lt;div <span class="color-pink">class</span>=<span class="color-green">"colorNameDiv fontStyle"</span>&gt;This is a example text in div with background image.&lt;/div&gt;</p>
                        `
                    }}></div>
                </div>

                <h5 className="mt-5 mb-3">How Background Image Looks Like</h5>
                <p>
                    This is how a background image is look like -
                </p>
                <div className='outputPalateBox mb-4 mt-3' style={{'border':'none'}}>
                    <div className='outputPalate' dangerouslySetInnerHTML={{
                        __html: `
                            <style>
                                .colorNameDiv{
                                    background-image : url('../../assets/background_image.webp');
                                    height : 300px;
                                    align-content: center;
                                    text-align: center;
                                    background-repeat : no-repeat;
                                    width : 500px;
                                }
                            </style>
                            <h6>Div Tag</h6>
                            <div class="colorNameDiv fontStyle">This is a example text in div with background image.</div>
                        `
                    }}></div>
                </div>
            </div>
        </section>
    )
}