import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/html/quotations";

export default function HTMLQuotations() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("HTML - Quotations & Citation | Aspirant's Home");
        const urls = {
            'previous': '/html/formatting',
            'next': '/html/comments'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>HTML - Quotations & Citation</h3>
            <div className='mt-4 mb-5'>
                <p>
                    Quotations and citations in HTML are used to mark text that is being quoted from another source or to cite the source of a piece of information. Here are the key elements used for quotations and citations in HTML:
                </p>
                <ul style={{ listStyle: 'decimal' }}>
                    <li>
                        <strong>`&lt;blockquote&gt;`</strong>
                        <p className='mt-4'>
                            The <strong>`&lt;blockquote&gt;`</strong> tag is used for long quotations that require block-level presentation. It is typically used to quote large sections of text from another source. Browsers usually display blockquote elements with indentation.
                        </p>
                        <ul style={{ listStyle: 'disc' }}>
                            <li>
                                <strong>Attributes:</strong>
                                <ul className='mt-2' style={{ listStyle: 'disc' }}>
                                    <li>
                                        <strong>`&lt;cite&gt;`</strong>: A URL that points to the source of the quote.
                                    </li>
                                </ul>
                                <div className='codePalateBox mt-2 mb-4'>
                                    <div className='codePalate' dangerouslySetInnerHTML={{
                                        __html: `
                                            <p>&lt;blockquote <span class="color-pink">cite</span>=<span class="color-green">"https://www.example.com"</span>&gt;</p>
                                            <p class="ml-30">    This is a longer block quote from another source.</p>
                                            <p>&lt;/blockquote&gt;</p>
                                        `
                                    }}></div>
                                </div>
                                <p>
                                    Output will be following -
                                </p>
                                <div className='outputPalateBox  mb-4'>
                                    <div className='outputPalate' dangerouslySetInnerHTML={{
                                        __html: `
                                            <blockquote cite="https://www.example.com">
                                                This is a longer block quote from another source.
                                            </blockquote>
                                        `
                                    }}></div>
                                </div>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <strong>`&lt;q&gt;`</strong>
                        <p className='mt-4'>
                            The <strong>`&lt;q&gt;`</strong> tag is used for short, inline quotations. Browsers typically render text inside <strong>`&lt;q&gt;`</strong> tags with quotation marks.
                        </p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p>&lt;p&gt;John said, &lt;q&gt;This is a quote.&lt;/q&gt;&lt;/p&gt;</p>
                                `
                            }}></div>
                        </div>
                        <p>
                            Output will be following -
                        </p>
                        <div className='outputPalateBox  mb-4'>
                            <div className='outputPalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p>John said, <q>This is a quote.</q></p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <strong>`&lt;abbr&gt;`</strong>
                        <p className='mt-4'>
                            The <strong>`&lt;abbr&gt;`</strong> tag is used to represent an abbreviation or acronym. The title attribute is often used with <strong>`&lt;abbr&gt;`</strong> to provide the full form of the abbreviation, which is displayed as a tooltip when the mouse hovers over the abbreviation.
                        </p>
                        <ul style={{ listStyle: 'disc' }}>
                            <li>
                                <strong>Attributes:</strong>
                                <ul className='mt-2' style={{ listStyle: 'disc' }}>
                                    <li>
                                        <strong>`&lt;cite&gt;`</strong>: A URL that points to the source of the quote.
                                    </li>
                                </ul>
                                <div className='codePalateBox mt-2 mb-4'>
                                    <div className='codePalate' dangerouslySetInnerHTML={{
                                        __html: `
                                            <p>&lt;p&gt;The &lt;abbr <span class="color-pink">title</span>=<span class="color-green">"World Health Organization"</span>&gt;WHO&lt;/abbr&gt; was founded in 1948.&lt;/p&gt;</p>
                                        `
                                    }}></div>
                                </div>
                                <p>
                                    Output will be following -
                                </p>
                                <div className='outputPalateBox  mb-4'>
                                    <div className='outputPalate' dangerouslySetInnerHTML={{
                                        __html: `
                                            <p>The <abbr title="World Health Organization">WHO</abbr> was founded in 1948.</p>
                                        `
                                    }}></div>
                                </div>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <strong>`&lt;address&gt;`</strong>
                        <p className='mt-4'>
                            The <strong>`&lt;address&gt;`</strong> tag is used to provide contact information for the author or owner of a document or an article. Browsers typically display text inside <strong>`&lt;address&gt;`</strong> elements in italics.
                        </p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p>&lt;address&gt;</p>
                                    <p class="ml-30">    Written by John Doe.&lt;br&gt;</p>
                                    <p class="ml-30">    Visit us at:&lt;br&gt;</p>
                                    <p class="ml-30">    Example.com&lt;br&gt;</p>
                                    <p class="ml-30">    Box 564, Disneyland&lt;br&gt;</p>
                                    <p class="ml-30">    USA</p>
                                    <p>&lt;/address&gt;</p>
                                `
                            }}></div>
                        </div>
                        <p>
                            Output will be following -
                        </p>
                        <div className='outputPalateBox  mb-4'>
                            <div className='outputPalate' dangerouslySetInnerHTML={{
                                __html: `
                                <address><i>
                                    Written by John Doe.<br>
                                    Visit us at:<br>
                                    Example.com<br>
                                    Box 564, Disneyland<br>
                                    USA</i>
                                </address>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <strong>`&lt;cite&gt;`</strong>
                        <p className='mt-4'>
                            The <strong>`&lt;cite&gt;`</strong> tag is used to reference the title of a work, such as a book, article, song, etc. Browsers usually render text inside <strong>`&lt;cite&gt;`</strong> tags in italics.
                        </p>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p>&lt;p&gt;The book &lt;cite&gt;To Kill a Mockingbird&lt;/cite&gt; is a classic.&lt;/p&gt;</p>
                                `
                            }}></div>
                        </div>
                        <p>
                            Output will be following -
                        </p>
                        <div className='outputPalateBox  mb-4'>
                            <div className='outputPalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p>The book <cite>To Kill a Mockingbird</cite> is a classic.</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <strong>`&lt;bdo&gt;`</strong>
                        <p className='mt-4'>
                            The <strong>`&lt;bdo&gt;`</strong> (Bi-Directional Override) tag is used to override the current text direction. It is useful for displaying text that needs to be rendered in a different direction than the surrounding content, such as in languages that are written right-to-left.
                        </p>
                        <ul style={{ listStyle: 'disc' }}>
                            <li>
                                <strong>Attributes:</strong>
                                <ul className='mt-2' style={{ listStyle: 'disc' }}>
                                    <li>
                                        <strong>`&lt;dir&gt;`</strong>: Specifies the text direction. Values can be <strong>`&lt;ltr&gt;`</strong> (left-to-right) or <strong>`&lt;rtl&gt;`</strong> (right-to-left).

                                    </li>
                                </ul>
                                <div className='codePalateBox mt-2 mb-4'>
                                    <div className='codePalate' dangerouslySetInnerHTML={{
                                        __html: `
                                            <p>&lt;p&gt;Normal text: Hello, world!&lt;/p&gt;</p>
                                            <p>&lt;p&gt;Reversed text: &lt;bdo <span class="color-pink">dir</span>=<span class="color-green">"rtl"</span>&gt;Hello, world!&lt;/bdo&gt;&lt;/p&gt;</p>
                                        `
                                    }}></div>
                                </div>
                                <p>
                                    Output will be following -
                                </p>
                                <div className='outputPalateBox  mb-4'>
                                    <div className='outputPalate' dangerouslySetInnerHTML={{
                                        __html: `
                                            <p>Normal text: Hello, world!</p>
                                            <p>Reversed text: <bdo dir="rtl">Hello, world!</bdo></p>
                                        `
                                    }}></div>
                                </div>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
        </section>
    )
}