import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../../Context";
import { Link } from 'react-router-dom';
export const pageUrl = () => "/mysql/aggregate-functions/count";

export default function COUNT() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Mysql - Aggregate Functions - COUNT() | Aspirant's Home");
        path.setPreviousNext({});
    }, [])

    return (
        <>
            <section class='clearfix mb-3'>
                <span className='float-start'>
                    <Link to='/mysql/aggregate-functions' onClick={() => path.setPathName('/mysql/aggregate-functions')} className='btn btn-primary'> <i class="fa-solid fa-less-than"></i>&nbsp; Back to Aggregate Functions </Link>
                </span>
            </section>
            <section className='mt-5 mb-5'>
                <h3>Mysql - COUNT() Function</h3>
                <div className='mt-4 mb-5'>
                    <p>
                        The <strong>`COUNT()`</strong> function in MySQL is used to count the number of rows that match a specified condition. It can be applied to either a specific column or to all columns (<strong>`*`</strong>).
                    </p>
                    <h5>Syntax</h5>
                    <ul style={{listStyle:'decimal'}}>
                        <li>
                            <strong>COUNT(column_name):</strong>&nbsp;
                            Counts the number of non-NULL values in a specified column.
                        </li>
                        <li>
                            <strong>COUNT(*):</strong>&nbsp;
                            Counts the total number of rows in a table, including rows with NULL values.
                        </li>
                    </ul>
                    <p>
                        Let's consider a table <strong>`employees`</strong> with the following structure and data:
                    </p>
                    <table className='table' style={{border: '1px dashed #ccc'}}> 
                        <thead>
                            <tr style={{border: '1px dashed #ccc'}}>
                                <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>id</th>
                                <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>name</th>
                                <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>department</th>
                                <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>salary</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr style={{border: '1px dashed #ccc'}}>
                                <td style={{border: '1px dashed #ccc'}}>1</td>
                                <td style={{border: '1px dashed #ccc'}}>Jane Smith</td>
                                <td style={{border: '1px dashed #ccc'}}>IT</td>
                                <td style={{border: '1px dashed #ccc'}}>500000</td>
                            </tr>
                            <tr style={{border: '1px dashed #ccc'}}>
                                <td style={{border: '1px dashed #ccc'}}>2</td>
                                <td style={{border: '1px dashed #ccc'}}>John Doe</td>
                                <td style={{border: '1px dashed #ccc'}}>IT</td>
                                <td style={{border: '1px dashed #ccc'}}>350000</td>
                            </tr>
                            <tr style={{border: '1px dashed #ccc'}}>
                                <td style={{border: '1px dashed #ccc'}}>3</td>
                                <td style={{border: '1px dashed #ccc'}}>Johny Smith</td>
                                <td style={{border: '1px dashed #ccc'}}>Accounting</td>
                                <td style={{border: '1px dashed #ccc'}}>50000</td>
                            </tr>
                            <tr style={{border: '1px dashed #ccc'}}>
                                <td style={{border: '1px dashed #ccc'}}>4</td>
                                <td style={{border: '1px dashed #ccc'}}>Jeffer Joe</td>
                                <td style={{border: '1px dashed #ccc'}}>HR</td>
                                <td style={{border: '1px dashed #ccc'}}>380000</td>
                            </tr>
                            <tr style={{border: '1px dashed #ccc'}}>
                                <td style={{border: '1px dashed #ccc'}}>5</td>
                                <td style={{border: '1px dashed #ccc'}}>Sikhar Smith</td>
                                <td style={{border: '1px dashed #ccc'}}>Accounting</td>
                                <td style={{border: '1px dashed #ccc'}}><span className='color-blue'>NULL</span></td>
                            </tr>
                            <tr style={{border: '1px dashed #ccc'}}>
                                <td style={{border: '1px dashed #ccc'}}>6</td>
                                <td style={{border: '1px dashed #ccc'}}>Xavier Doe</td>
                                <td style={{border: '1px dashed #ccc'}}>IT</td>
                                <td style={{border: '1px dashed #ccc'}}>750000</td>
                            </tr>
                        </tbody>
                    </table>
                    <h5>Examples</h5>
                    <ul style={{listStyle: 'decimal'}}>
                        <li>
                            <strong>Counting All Rows:</strong>
                            <p>
                                To count the total number of rows in the <strong>`employees`</strong> table:
                            </p>
                            <div className='codePalateBox mt-2 mb-2'>
                                <div className='codePalate' dangerouslySetInnerHTML={{
                                    __html: `
                                        <p><span class="color-blue">SELECT</span> COUNT(*) <span class="color-blue">AS</span> total_employees <span class="color-blue">FROM</span> employees;</p>
                                    `
                                }}></div>
                            </div>
                            <p>
                                This query will return:
                            </p>
                            <table className='table' style={{border: '1px dashed #ccc'}}> 
                                <thead>
                                    <tr style={{border: '1px dashed #ccc'}}>
                                        <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>total_employees</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr style={{border: '1px dashed #ccc'}}>
                                        <td style={{border: '1px dashed #ccc'}}>6</td>
                                    </tr>
                                </tbody>
                            </table>
                        </li>
                        <li>
                            <strong>Counting Non-NULL Values in a Column:</strong>
                            <p>
                                To count the number of non-NULL values in the <strong>`salary`</strong> column:
                            </p>
                            <div className='codePalateBox mt-2 mb-2'>
                                <div className='codePalate' dangerouslySetInnerHTML={{
                                    __html: `
                                        <p><span class="color-blue">SELECT</span> COUNT(salary) <span class="color-blue">AS</span> total_salaries <span class="color-blue">FROM</span> employees;</p>
                                    `
                                }}></div>
                            </div>
                            <p>
                                This query will return:
                            </p>
                            <table className='table' style={{border: '1px dashed #ccc'}}> 
                                <thead>
                                    <tr style={{border: '1px dashed #ccc'}}>
                                        <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>total_salaries</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr style={{border: '1px dashed #ccc'}}>
                                        <td style={{border: '1px dashed #ccc'}}>5</td>
                                    </tr>
                                </tbody>
                            </table>
                        </li>
                        <li>
                            <strong>Using COUNT() with WHERE Clause:</strong>
                            <p>
                                To count the number of employees in the 'IT' department:
                            </p>
                            <div className='codePalateBox mt-2 mb-2'>
                                <div className='codePalate' dangerouslySetInnerHTML={{
                                    __html: `
                                        <p><span class="color-blue">SELECT</span> COUNT(*) <span class="color-blue">AS</span> total_employees <span class="color-blue">FROM</span> employees <span class="color-blue">WHERE</span> department = <span class="color-green">'IT'</span>;</p>
                                    `
                                }}></div>
                            </div>
                            <p>
                                This query will return:
                            </p>
                            <table className='table' style={{border: '1px dashed #ccc'}}> 
                                <thead>
                                    <tr style={{border: '1px dashed #ccc'}}>
                                        <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>total_employees</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr style={{border: '1px dashed #ccc'}}>
                                        <td style={{border: '1px dashed #ccc'}}>3</td>
                                    </tr>
                                </tbody>
                            </table>
                        </li>
                        <li>
                            <strong>Using COUNT() with GROUP BY:</strong>
                            <p>
                                To count the number of employees in each department:
                            </p>
                            <div className='codePalateBox mt-2 mb-2'>
                                <div className='codePalate' dangerouslySetInnerHTML={{
                                    __html: `
                                        <p><span class="color-blue">SELECT</span> department, COUNT(*) <span class="color-blue">AS</span> total_employees</p>
                                        <p><span class="color-blue">FROM</span> employees</p>
                                        <p><span class="color-blue">GROUP BY</span> department;</p>
                                    `
                                }}></div>
                            </div>
                            <p>
                                This query will return:
                            </p>
                            <table className='table' style={{border: '1px dashed #ccc'}}> 
                                <thead>
                                    <tr style={{border: '1px dashed #ccc'}}>
                                        <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>department</th>
                                        <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>total_employees</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr style={{border: '1px dashed #ccc'}}>
                                        <td style={{border: '1px dashed #ccc'}}>IT</td>
                                        <td style={{border: '1px dashed #ccc'}}>3</td>
                                    </tr>
                                    <tr style={{border: '1px dashed #ccc'}}>
                                        <td style={{border: '1px dashed #ccc'}}>Accounting</td>
                                        <td style={{border: '1px dashed #ccc'}}>2</td>
                                    </tr>
                                    <tr style={{border: '1px dashed #ccc'}}>
                                        <td style={{border: '1px dashed #ccc'}}>HR</td>
                                        <td style={{border: '1px dashed #ccc'}}>1</td>
                                    </tr>
                                </tbody>
                            </table>
                        </li>
                    </ul>
                </div>
            </section>
        </>
    )
}