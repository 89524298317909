import React, {useEffect, useContext, useState} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/css/colors/hex";

export default function CSSColorHex() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    const [redColor, setRedColor] = useState(Number(155).toString(16).toUpperCase());
    const [greenColor, setGreenColor] = useState(Number(40).toString(16).toUpperCase());
    const [blueColor, setBlueColor] = useState(Number(210).toString(16).toUpperCase());


    useEffect(() => {
        title.setPageTitle("Hex Colors in CSS | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/css/colors/rgb',
            'next': '/css/colors/hsl'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>Hex Colors in CSS</h3>
            <div className='mt-4 mb-5'>
                <p>
                    Hexadecimal color values are a way of representing colors in CSS using a six-digit code preceded by a hash (<strong>`#`</strong>). Each pair of digits represents the amount of the red, green, and blue value of the color, respectively. Hex color codes are widely used in web design because they are compact and easy to understand once you are familiar with the format.
                </p>
                <h5 className='mt-4 mb-4'>Syntax</h5>
                <p>
                    A hex color code is written as <strong>`#RRGGBB`</strong>, where:
                </p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><strong>`RR`</strong> represents the red value.</li>
                    <li><strong>`GG`</strong> represents the green value.</li>
                    <li><strong>`BB`</strong> represents the blue value.</li>
                </ul>
                <p>
                    Each pair of digits can range from <strong>`00`</strong> to <strong>`FF`</strong> in hexadecimal notation (<strong>`00`</strong> to <strong>`255`</strong> in decimal).
                </p>
                <div className='codePalateBox mt-2 mb-5'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-green">color</span>: #RRGGBB;</p>
                        `
                    }}></div>
                </div>
                <h5 className='mt-5 mb-5'>Common Hex Color Code</h5>
                <div className='mt-4 mb-5 row text-center colorNames fontStyle'>
                    <div className='col-4' style={{backgroundColor:'#000000',color:'#FFF'}}>#000000</div>
                    <div className='col-4' style={{backgroundColor:'#FFFFFF',color:'#000'}}>#FFFFFF</div>
                    <div className='col-4' style={{backgroundColor:'#FF0000',color:'#FFF'}}>#FF0000</div>
                    <div className='col-4' style={{backgroundColor:'#00FF00',color:'#FFF'}}>#00FF00</div>
                    <div className='col-4' style={{backgroundColor:'#0000FF',color:'#FFF'}}>#0000FF</div>
                    <div className='col-4' style={{backgroundColor:'#FFFF00',color:'#000'}}>#FFFF00</div>
                    <div className='col-4' style={{backgroundColor:'#00FFFF',color:'#FFF'}}>#00FFFF</div>
                    <div className='col-4' style={{backgroundColor:'#FF00FF',color:'#FFF'}}>#FF00FF</div>
                    <div className='col-4' style={{backgroundColor:'#ADD8E6',color:'#FFF'}}>#ADD8E6</div>
                </div>

                <h5 className='mt-5 mb-4'>Experiment with Hex Value</h5>
                <p>
                    You can experiment hex value with the below sliders - 
                </p>
                <div className='row'>
                    <div className='col-3'></div>
                    <div className='col-6 fontStyle' style={{height:'100px','alignContent':'center','textAlign':'center', backgroundColor: '#'+redColor + greenColor + blueColor, color: '#FFF' }}>
                        {'#'+redColor + greenColor + blueColor}
                    </div>
                    <div className='col-3'></div>
                </div>
                <div className='row mt-5 mb-5'>
                    <div className='col-4 text-center'>
                        <label for="points">Red:</label>
                        <br />
                        <input type="range" id="points1" value={parseInt(redColor, 16)} name="points" min="0" max="255" onChange={(e) => setRedColor(Number(e.target.value).toString(16).toUpperCase())} />
                        <br />
                        <span>{redColor}</span>
                    </div>
                    <div className='col-4 text-center'>
                        <label for="points">Green:</label>
                        <br />
                        <input type="range" id="points2" value={parseInt(greenColor, 16)} name="points" min="0" max="255" onChange={(e)=>setGreenColor(Number(e.target.value).toString(16).toUpperCase())} />
                        <br />
                        <span>{greenColor}</span>
                    </div>
                    <div className='col-4 text-center'>
                        <label for="points">Blue:</label>
                        <br />
                        <input type="range" id="points3" value={parseInt(blueColor, 16)} name="points" min="0" max="255" onChange={(e)=>setBlueColor(Number(e.target.value).toString(16).toUpperCase())} />
                        <br />
                        <span>{blueColor}</span>
                    </div>
                </div>

                <h5 className='mt-5 mb-4'>3 Digit Hex Value</h5>
                <p>
                    CSS also supports shorthand 3-digit hex color codes. This shorthand format is useful for colors where the red, green, and blue components are the same for both digits.
                </p>
                <p>
                    Each pair of digits can range from <strong>`0`</strong> to <strong>`F`</strong> in hexadecimal notation.
                </p>
                <div className='codePalateBox mt-2 mb-5'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-green">color</span>: #RGB;</p>
                        `
                    }}></div>
                </div>
                <p>
                    The 3-digit hex code can only be used when both the values (RR, GG, and BB) are the same for each component. So, if we have #FF0000, it can be written like this: #F00.
                </p>
                <div className='row'>
                    <div className='col-5 fontStyle' style={{height:'100px','alignContent':'center','textAlign':'center', backgroundColor: '#FF0000', color: '#FFF' }}>
                        {'#FF0000'}
                    </div>
                    <div className='col-2'></div>
                    <div className='col-5 fontStyle' style={{height:'100px','alignContent':'center','textAlign':'center', backgroundColor: '#F00', color: '#FFF' }}>
                        {'#F00'}
                    </div>
                </div>
            </div>
        </section>
    )
}