import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/html/text-links";

export default function HTMLTextLinks() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("HTML - Text Links | Aspirant's Home");
        const urls = {
            'previous': '/html/definition-lists',
            'next': '/html/customize-links'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>HTML - Text Links</h3>
            <div className='mt-4 mb-5'>
                <p>
                    HTML text links, also known as hyperlinks, are used to link one page to another. These links are created using the <strong>`&lt;a&gt;`</strong> (anchor) tag. Hyperlinks can link to other web pages, files, locations within the same page, email addresses, and more.
                </p>
                <h5 className='mt-5 mb-3'>Basic Structure</h5>
                <p>
                    The basic structure of an HTML link includes the <strong>`&lt;a&gt;`</strong> tag with the <strong>`href`</strong> attribute, which specifies the URL of the page the link goes to.
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>&lt;a <span class="color-pink">href</span>=<span class="color-green">"URL"</span>&gt;Link Text&lt;/a&gt;</p>
                        `
                    }}></div>
                </div>
                <h5 className='mt-5 mb-3'>Example</h5>
                <p>
                    Here's a simple example of a text link:
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-grey">&lt;!DOCTYPE</span> <span class="color-blue">html</span><span class="color-grey">&gt;</span></p>
                            <p>&lt;html&gt;</p>
                            <p class="ml-30">    &lt;head&gt;</p>
                            <p class="ml-60">        &lt;title&gt;HTML Text Links Example&lt;/title&gt;</p>
                            <p class="ml-30">    &lt;/head&gt;</p>
                            <p class="ml-30">    &lt;body&gt;</p>
                            <p class="ml-60">        &lt;a <span class="color-pink">href</span>=<span class="color-green">"https://www.example.com"</span>&gt;Visit Example.com&lt;/a&gt;</p>
                            <p class="ml-30">    &lt;/body&gt;</p>
                            <p>&lt;/html&gt;</p>
                        `
                    }}></div>
                </div>
                <h5 className='mt-5 mb-3'>Common Attributes of the `&lt;a&gt;` Tag</h5>
                <h6 className='mt-4'>`href`</h6>
                <p>
                    The <strong>`href`</strong> attribute specifies the URL of the page the link goes to. If the <strong>`href`</strong> attribute is not present, the <strong>`&lt;a&gt;`</strong> tag will not be a hyperlink.
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>&lt;a <span class="color-pink">href</span>=<span class="color-green">"https://www.example.com"</span>&gt;Visit Example.com&lt;/a&gt;</p>
                        `
                    }}></div>
                </div>
                <h6 className='mt-4'>`target`</h6>
                <p>
                    The <strong>`target`</strong> attribute specifies where to open the linked document. Common values include:
                </p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><strong>`_self`</strong>: Opens the link in the same tab or window (default behavior).</li>
                    <li><strong>`_blank`</strong>: Opens the link in a new tab or window.</li>
                    <li><strong>`_parent`</strong>: Opens the link in the parent frame.</li>
                    <li><strong>`_top`</strong>: Opens the link in the full body of the window.</li>
                </ul>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>&lt;a <span class="color-pink">href</span>=<span class="color-green">"https://www.example.com"</span> <span class="color-pink">target</span>=<span class="color-green">"_blank"</span>&gt;Visit Example.com&lt;/a&gt;</p>
                        `
                    }}></div>
                </div>
                <h6 className='mt-4'>`title`</h6>
                <p>
                    The <strong>`title`</strong> attribute provides additional information about the link. When the user hovers over the link, the title appears as a tooltip.
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>&lt;a <span class="color-pink">href</span>=<span class="color-green">"https://www.example.com"</span> <span class="color-pink">title</span>=<span class="color-green">"Example Website"</span>&gt;Visit Example.com&lt;/a&gt;</p>
                        `
                    }}></div>
                </div>
                <h6 className='mt-4'>`rel`</h6>
                <p>
                    The <strong>`rel`</strong> attribute specifies the relationship between the current document and the linked document. It is commonly used in SEO and to improve security.
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>&lt;a <span class="color-pink">href</span>=<span class="color-green">"https://www.example.com"</span> <span class="color-pink">rel</span>=<span class="color-green">"noopener noreferrer"</span>&gt;Visit Example.com&lt;/a&gt;</p>
                        `
                    }}></div>
                </div>
                <h5 className='mt-5 mb-3'>Linking to an Email Address</h5>
                <p>
                    You can create a link that opens the user's email client to send an email using the <strong>`mailto:`</strong> scheme.
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>&lt;a <span class="color-pink">href</span>=<span class="color-green">"mailto:someone@example.com"</span>&gt;Email Us&lt;/a&gt;</p>
                        `
                    }}></div>
                </div>
                <h5 className='mt-5 mb-3'>Linking to a Phone Number</h5>
                <p>
                    You can create a link that allows users to call a phone number using the <strong>`tel:`</strong> scheme.
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>&lt;a <span class="color-pink">href</span>=<span class="color-green">"tel:+1234567890"</span>&gt;Call Us&lt;/a&gt;</p>
                        `
                    }}></div>
                </div>
                <h5 className='mt-5 mb-3'>Linking to a Image</h5>
                <p>
                    You can also display a preview of the image by combining an image link with an image tag:
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>&lt;a <span class="color-pink">href</span>=<span class="color-green">"https://www.example.com/image.jpg"</span>&gt;</p>
                            <p class="ml-30">    &lt;img <span class="color-pink">src</span>=<span class="color-green">"https://www.example.com/image.jpg"</span> <span class="color-pink">alt</span>=<span class="color-green">"Example Image"</span> <span class="color-pink">width</span>=<span class="color-green">"100"</span> <span class="color-pink">height</span>=<span class="color-green">"100"</span>&gt;</p>
                            <p>&lt;/a&gt;</p>
                        `
                    }}></div>
                </div>
                <p className='mt-5 featureClass'>
                    By understanding and using hyperlinks properly, you can create effective and accessible navigation in your HTML documents.
                </p>
            </div>
        </section>
    )
}