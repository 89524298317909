import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/mongo-db/sorting-records";

export default function MongoSortingRecords() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("How to Sort Records in MongoDB Document?");
        title.setKeyWords("sorting records in mongodb, sort in mongodb example, mongodb sort in ascending order, mongodb sort results, how to sort records in mongodb, count records in mongodb collection, mongodb sort collection descending, mongodb sort example, mongodb sort in find, mongodb sort find results, how to sort mongodb query results, sort latest record in mongodb, mongodb sort limit, mongodb sort pagination, query sort mongodb, sort data with mongodb, query sort mongodb");
        title.setPageDescription("Sorting records in MongoDB can be done using the `sort` method. This method allows you to specify the order in which the documents are returned based on one or more fields. The `sort` method can be used in conjunction with the `find` method to retrieve documents in a specified order.");
        const urls = {
            'previous': '/mongo-db/limit-records',
            'next': '/mongo-db/indexing'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>MongoDB - Sorting Records</h3>
            <div className='mt-4 mb-5'>
                <p>
                    Sorting records in MongoDB can be done using the <strong>`sort`</strong> method. This method allows you to specify the order in which the documents are returned based on one or more fields. The <strong>`sort`</strong> method can be used in conjunction with the <strong>`find`</strong> method to retrieve documents in a specified order.
                </p>
                <p>
                    Here is the basic syntax -
                </p>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>db.collection.<span class="color-red">find</span>(&lt;query&gt;).<span class="color-red">sort</span>(&lt;sort&gt;)</p>
                        `
                    }}></div>
                </div>
                <p>
                    Where, <strong>`query`</strong> is a document that specifies the criteria used to select documents. <strong>`sort`</strong> is a document that specifies the fields to sort by and the order in which to sort them.
                </p>
                <h5 className='mt-5 mb-4'>Sort Order</h5>
                <ul style={{listStyle:'disc'}}>
                    <li><strong>`1`</strong> for ascending order.</li>
                    <li><strong>`-1`</strong> for descending order.</li>
                </ul>
                <p>
                    For example, Sorting documents by a single field in ascending order:
                </p>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>db.users.<span class="color-red">find</span>().<span class="color-red">sort</span>({ <span class="color-pink">age</span>: <span class="color-pink">1</span> })</p>
                        `
                    }}></div>
                </div>
                <p>This example sorts the documents in the <strong>`users`</strong> collection by the <strong>`age`</strong> field in ascending order.</p>
                <p>
                    Sorting documents by a single field in descending order:
                </p>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>db.users.<span class="color-red">find</span>().<span class="color-red">sort</span>({ <span class="color-pink">age</span>: <span class="color-pink">-1</span> })</p>
                        `
                    }}></div>
                </div>
                <p>This example sorts the documents in the <strong>`users`</strong> collection by the <strong>`age`</strong> field in descending order.</p>
                <p>Sorting documents by multiple fields:</p>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>db.users.<span class="color-red">find</span>().<span class="color-red">sort</span>({ <span class="color-pink">age</span>: <span class="color-pink">1</span>, <span class="color-pink">username</span>: <span class="color-pink">-1</span> })</p>
                        `
                    }}></div>
                </div>
                <p>
                    This example sorts the documents in the <strong>`users`</strong> collection first by the <strong>`age`</strong> field in ascending order, and then by the <strong>`username`</strong> field in descending order for documents with the same <strong>`age`</strong>.
                </p>
                <h5 className='mt-5 mb-4'>Example with Aggregation Pipeline</h5>
                <p>
                    You can also sort documents using the aggregation framework with the <strong>`$sort`</strong> stage.
                </p>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>db.users.<span class="color-red">aggregate</span>([</p>
                            <p style="margin-left:30px">    { <span class="color-pink">$match</span>: { <span class="color-pink">status</span>: <span class="color-pink">"active"</span> } },</p>
                            <p style="margin-left:30px">    { <span class="color-pink">$sort</span>: { <span class="color-pink">age</span>: <span class="color-pink">1</span> } }</p>
                            <p>])</p>
                        `
                    }}></div>
                </div>
                <p>
                    This example first matches documents where <strong>`status`</strong> is <strong>`"active"`</strong> and then sorts the resulting documents by the <strong>`age`</strong> field in ascending order.
                </p>
            </div>
        </section>
    )
}