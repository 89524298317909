import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/node-js/http-module";

export default function HTTPModules() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Node Js - HTTP Module | Aspirant's Home");
        const urls = {
            'previous': '/node-js/modules',
            'next': '/node-js/file-system'
        }
        path.setPreviousNext(urls);
    }, [])
    
    return (
        <section className='mt-5 mb-5'>
            <h3>HTTP Module</h3>
            <p className='mt-4'>The Node.js <strong>`http`</strong> module is a core module that provides functionality for creating HTTP servers and clients. With the <strong>`http`</strong> module, you can build web servers, handle incoming HTTP requests, and make HTTP requests to other servers.</p>
            <p>Here's an overview of some key components and features of the <strong>`http`</strong> module:</p>
            <ul style={{ 'listStyle': 'decimal' }}>
                <li className='mb-3'><strong>Creating an HTTP Server:</strong> You can create an HTTP server using the <strong>`http.createServer()`</strong> method, which takes a callback function that will be called whenever the server receives an HTTP request.
                    <div className='codePalateBox mt-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{ __html: "<p><span class='color-blue'>const</span> http = <span class='color-yellow'>require</span>(<span class='color-green'>'http'</span>);</p><p><span class='color-blue'>const</span> server = http.<span class='color-red'>createServer</span>((req, res) => {</p><p style='margin-left:30px'>res.<span class='color-red'>writeHead</span>(<span class='color-pink'>200</span>, { <span class='color-green'>'Content-Type': 'text/plain'</span> });</p><p style='margin-left:30px'>res.<span class='color-red'>end</span>(<span class='color-green'>'Hello, World!\n'</span>);</p><p>});</p><p>server.<span class='color-red'>listen</span>(<span class='color-pink'>3000</span>, <span class='color-green'>'127.0.0.1'</span>, ( ) => {</p><p style='margin-left:30px'> <span class='color-pink'>console</span>.<span class='color-red'>log</span>(<span class='color-green'>'Server running at http://127.0.0.1:3000/'</span>);</p><p>});</p>" }}>
                        </div>
                    </div>
                </li>
                <li className='mb-3'><strong>Handling HTTP Requests:</strong> Inside the callback function passed to <strong>`createServer()`</strong>, you can handle incoming HTTP requests and send back responses. The <strong>`req`</strong> object represents the incoming request, and the <strong>`res`</strong> object is used to send the response back to the client.</li>

                <li className='mb-3'><strong>HTTP Methods:</strong> The http module supports various <strong>`HTTP`</strong> methods such as <strong>`GET`</strong>, <strong>`POST`</strong>, <strong>`PUT`</strong>, <strong>`DELETE`</strong>, etc. You can use the <strong>``req.method</strong> property to determine the <strong>`HTTP`</strong> method used in the request.</li>

                <li className='mb-3'><strong>HTTP Headers:</strong> You can set <strong>`HTTP`</strong> headers in the response using the <strong>`res.writeHead()`</strong> method. This method takes the status code and an object containing the response headers as arguments.</li>

                <li className='mb-3'><strong>Listening for Requests:</strong> Once you have created the <strong>`HTTP`</strong> server, you can start listening for incoming requests using the <strong>`server.listen()`</strong> method. This method takes the port number and an optional hostname as arguments.</li>

                <li className='mb-3'><strong>Making HTTP Requests:</strong> The http module also provides functionality for making <strong>`HTTP`</strong> requests to other servers. You can use the <strong>`http.request()`</strong> method to create a client request object and send it to a server.</li>
            </ul>
            <p className='mt-4'>Overall, the http module in Node.js provides a powerful and flexible API for building <strong>`HTTP`</strong> servers and clients, making it easy to create web applications and interact with external <strong>`HTTP`</strong> APIs.</p>
        </section>
    )
}
