import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/react-js/css-files";


export default function CSSFiles() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("React Styling - Using Vanilla CSS | Aspirant's Home");
        const urls = {
            'previous': '/react-js/styling',
            'next':'/react-js/inline-styles'
        }
        path.setPreviousNext(urls);
    },[])

    return (
        <section className='mt-5 mb-5'>
            <h3>React Js - Styling Using Vanilla CSS</h3>
            <div className='mt-4 mb-5'>
                <ul style={{'listStyle':'disc'}}>
                    <li>
                        Create a separate CSS file for your component, e.g., <strong>MyComponent.css</strong>.
                        <div className='codePalateBox mt-2 mb-2'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p><span class="color-pink">.my-component</span> {</p>
                                <p style="margin-left:30px">    <span class="color-green">color</span>: blue;</p>
                                <p style="margin-left:30px">    <span class="color-green">font-size</span>: <span class="color-pink">16px</span>;</p>
                                <p>}</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        Import the CSS file in your component file, e.g., <strong>import './MyComponent.css'</strong>;.
                        <div className='codePalateBox mt-2 mb-2'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p><span class="color-blue">import</span> <span class="color-red">React</span> <span class="color-blue">from</span> <span class="color-green">'react'</span>;</p>
                                <p><span class="color-blue">import</span> <span class="color-green">'./MyComponent.css'</span>;</p>
                                <br />
                                <p><span class="color-blue">function</span> <span class="color-red">MyComponent</span>() {</p>
                                <p style="margin-left:30px">  <span class="color-blue">return</span> &lt;div <span class="color-pink">className</span>=<span class="color-green">"my-component"</span>>Hello, world!&lt;/div&gt;;</p>
                                <p>}</p>
                                <br />
                                <p><span class="color-blue">export default</span> <span class="color-red">MyComponent</span>;</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        Apply styles to your component using class names.
                        <div className='codePalateBox mt-2 mb-2'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                <p><span class="color-blue">import</span> <span class="color-red">React</span> <span class="color-blue">from</span> <span class="color-green">'react'</span>;</p>
                                <p><span class="color-blue">import</span> MyComponent <span class="color-blue">from</span> <span class="color-green">'./MyComponent'</span>;</p>
                                <br />
                                <p><span class="color-blue">function</span> <span class="color-red">App</span>() {</p>
                                <p style="margin-left:30px">    <span class="color-blue">return</span> (</p>
                                <p style="margin-left:60px">        &lt;div&gt;</p>
                                <p style="margin-left:90px">        &lt;h1&gt;Welcome to My App&lt;/h1&gt;</p>
                                <p style="margin-left:90px">        &lt;MyComponent /&gt;</p>
                                <p style="margin-left:60px">        &lt;/div&gt;</p>
                                <p style="margin-left:30px">    );</p>
                                <p>}</p>
                                <br />
                                <p><span class="color-blue">export default</span> <span class="color-red">App</span>;</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>This approach is good for separating styles from components and maintaining a clear separation of concerns.</li>
                </ul>
            </div>
            <div className='mb-4 mt-5'>
                <h5>Advantage & Disadvantage</h5>
                <div className='mt-4 mb-4'>
                    <p>
                        <strong>Advantage :</strong> The main advantage of using vanilla CSS is that, since the CSS is written in a separate file, another developer can write code simultaneously.
                    </p>
                    <p>
                        <strong>Disadvantage :</strong> In Vanilla CSS, the CSS code is not scoped to components. This means that CSS rules may clash across components (for example, the same CSS class name used in different components for different purposes).
                    </p>
                </div>
            </div>
        </section>
    )
}