import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/css/margin/collapse";

export default function MarginCollapse() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Margin Collapse in CSS | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/css/margins',
            'next': '/css/padding'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>Margin Collapse</h3>
            <div className='mt-4 mb-5'>
                <p>
                    Margin collapse is a behavior in CSS where vertical margins between adjacent block-level elements are combined into a single margin, rather than adding up. This usually happens with top and bottom margins of elements. The resulting margin is equal to the largest of the combined margins.
                </p>

                <h5 className="mt-5 mb-3">When Margins Collapse Occur ?</h5>
                <ul style={{ listStyle: 'decimal' }}>
                    <li>
                        <strong>Adjacent Sibling Elements:</strong>
                        The bottom margin of one element and the top margin of the next element collapse.
                    </li>
                    <li>
                        <strong>Parent and First/Last Child:</strong>
                        The top margin of the first child and the parent's top margin (or the bottom margin of the last child and the parent's bottom margin) collapse.
                    </li>
                    <li>
                        <strong>Empty Elements:</strong>
                        If an element has no content, padding, or border, its top and bottom margins collapse.
                    </li>
                </ul>
                <h5 className="mt-5 mb-3">Example 1 : Adjacent Sibling Elements</h5>
                <p>
                    When two sibling elements have vertical margins that meet, only the larger margin is applied. Like first element has a bottom margin and second element has a top margin, then in between these two margin larger margin will be applied here. Ok let's take an example -
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;style&gt;</p>
                        <p class="ml-30">    .box1 {</p>
                        <p class="ml-60">        <span class="color-green">height</span>: <span class="color-pink">100px</span>;</p>
                        <p class="ml-60">        <span class="color-green">background-color</span>: <span class="color-pink">#e67e22</span>;</p>
                        <p class="ml-60">        <span class="color-green">margin-bottom</span>: <span class="color-pink">30px</span>;</p>
                        <p class="ml-30">    }</p>
                        <p class="ml-30">    .box2 {</p>
                        <p class="ml-60">        <span class="color-green">height</span>: <span class="color-pink">100px</span>;</p>
                        <p class="ml-60">        <span class="color-green">background-color</span>: <span class="color-pink">#f1c40f</span>;</p>
                        <p class="ml-60">        <span class="color-green">margin-top</span>: <span class="color-pink">20px</span>;</p>
                        <p class="ml-30">    }</p>
                        <p>&lt;/style&gt;</p>
                        <p>&lt;div <span class="color-pink">class</span>=<span class="color-green">"box1"</span>&gt;&lt;/div&gt;</p>
                        <p>&lt;div <span class="color-pink">class</span>=<span class="color-green">"box2"</span>&gt;&lt;/div&gt;</p>
                        `
                    }}></div>
                </div>
                <p>
                    Output will be the following -
                </p>
                <div className='outputPalateBox mb-4 mt-3' style={{'padding':'0'}}>
                    <div className='outputPalate' dangerouslySetInnerHTML={{
                        __html: `
                            <style>
                                .box1 {
                                    height: 100px;
                                    background-color: #e67e22;
                                    margin-bottom: 30px;
                                }
                        
                                .box2 {
                                    height: 100px;
                                    background-color: #f1c40f;
                                    margin-top: 20px;
                                }
                            </style>
                            <div class="box1"></div>
                            <div class="box2"></div>
                        `
                    }}></div>
                </div>
                <p>
                    In this example, the margin between <strong>`box1`</strong> and <strong>`box2`</strong> will be 30px, not 50px, because the margins collapse and only the larger margin is applied.
                </p>

                <h5 className="mt-5 mb-3">Example 2: Parent and First/Last Child</h5>
                <p>
                    When a parent element has a margin and its first or last child also has a margin, these margins can collapse.
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;style&gt;</p>
                        <p class="ml-30">    .parent {</p>
                        <p class="ml-60">        <span class="color-green">background-color</span>: <span class="color-pink">#3498db</span>;</p>
                        <p class="ml-60">        <span class="color-green">margin-top</span>: <span class="color-pink">50px</span>;</p>
                        <p class="ml-60">        <span class="color-green">padding</span>: <span class="color-pink">0px</span>;</p>
                        <p class="ml-60">        <span class="color-green">height</span>: <span class="color-pink">150px</span>;</p>
                        <p class="ml-30">    }</p>
                        <br />
                        <p class="ml-30">    .child {</p>
                        <p class="ml-60">        <span class="color-green">height</span>: <span class="color-pink">100px;</span></p>
                        <p class="ml-60">        <span class="color-green">background-color</span>: <span class="color-pink">#2ecc71</span>;</p>
                        <p class="ml-60">        <span class="color-green">margin-top</span>: <span class="color-pink">30px</span>;</p>
                        <p class="ml-30">    }</p>
                        <p>&lt;/style&gt;</p>
                        <p>&lt;div <span class="color-pink">class</span>=<span class="color-green">"parent"</span>&gt;</p>
                        <p class="ml-30">    &lt;div <span class="color-pink">class</span>=<span class="color-green">"child"</span>&gt;&lt;/div&gt;</p>
                        <p>&lt;/div&gt;</p>
                        `
                    }}></div>
                </div>
                <p>
                    Output will be the following -
                </p>
                <div className='outputPalateBox mb-4 mt-3' style={{'padding':'0'}}>
                    <div className='outputPalate' dangerouslySetInnerHTML={{
                        __html: `
                        <style>
                            .parent {
                                background-color: #3498db;
                                margin-top: 50px;
                                padding: 0px;
                                height: 150px;
                            }
                    
                            .child {
                                height: 100px;
                                background-color: #2ecc71;
                                margin-top: 30px;
                            }
                        </style>
                        <div class="parent">
                            <div class="child"></div>
                        </div>
                        `
                    }}></div>
                </div>
                <p>
                    In this example, the top margin of the <strong>`parent`</strong> (50px) and the top margin of the <strong>`child`</strong> (30px) collapse into a single 50px margin because there is no padding or border between them.
                </p>

                <h5 className="mt-5 mb-3">Example 3: Empty Elements</h5>
                <p>
                    If an element has no content, padding, or border, its top and bottom margins collapse.
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;style&gt;</p>
                        <p class="ml-30">    .empty {</p>
                        <p class="ml-60">        <span class="color-green">height</span>: 0;</p>
                        <p class="ml-60">        <span class="color-green">margin-top</span>: <span class="color-pink">40px</span>;</p>
                        <p class="ml-60">        <span class="color-green">margin-bottom</span>: <span class="color-pink">40px</span>;</p>
                        <p class="ml-60">        <span class="color-green">background-color</span>: <span class="color-pink">#3498db</span>;</p>
                        <p class="ml-30">    }</p>
                        <br />
                        <p class="ml-30">    .box {</p>
                        <p class="ml-60">        <span class="color-green">height</span>: <span class="color-pink">100px</span>;</p>
                        <p class="ml-60">        <span class="color-green">background-color</span>: <span class="color-pink">#e74c3c</span>;</p>
                        <p class="ml-30">    }</p>
                        <p>&lt;/style&lt;</p>
                        <p>&lt;div <span class="color-pink">class</span>=<span class="color-green">"empty"</span>&lt;&lt;/div&gt;</p>
                        <p>&lt;div <span class="color-pink">class</span>=<span class="color-green">"box"</span>&gt;Box Element&lt;/div&gt;</p>
                        `
                    }}></div>
                </div>
                <p>
                    Output will be the following -
                </p>
                <div className='outputPalateBox mb-4 mt-3' style={{'padding':'0'}}>
                    <div className='outputPalate' dangerouslySetInnerHTML={{
                        __html: `
                        <style>
                            .empty {
                                height: 0;
                                margin-top: 40px;
                                margin-bottom: 40px;
                                background-color: #3498db;
                            }
                    
                            .box {
                                height: 100px;
                                background-color: #e74c3c;
                            }
                        </style>
                        <div class="empty"></div>
                        <div class="box">Box Element</div>
                        `
                    }}></div>
                </div>
            </div>
        </section>
    )
}