import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/react-js/handling-http-request";

export default function HttpRequest() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("React Http Request | Aspirant's Home");
        const urls = {
            'previous': '/react-js/defining-dynamic-routes',
            'next':'/react-js/handling-states'
        }
        path.setPreviousNext(urls);
    },[])

    return (
        <section className='mt-5 mb-5'>
            <h3>React Http Request</h3>
            <div className='mt-4 mb-4'>
                <p>
                    The <strong>Hypertext Transfer Protocol (HTTP)</strong> is designed to enable communications between clients and servers. HTTP works as a request-response protocol between a client and server. To get data( i.e., products data for product listing) dynamic we have to http request to server's REST API. <strong>REST API</strong> is a web server that exposes certain "endpoints" (URLs) to which you can send requests & data ( to receieve data back).
                </p>
                <p>
                    With React, you build a <strong>client-side</strong> web application that runs in a browser. To communicate with a backend (REST API) you can make HTTP requests, such as GET, POST, PUT, or DELETE requests using the <strong>`fetch`</strong> API or libraries like <strong>`Axios`</strong>.
                </p>
            </div>
            <div className='mt-4 mb-4'>
                <p>
                    Here's a basic example using the <strong>`fetch`</strong> API to make a HTTP request:
                </p>
                <h5>GET Request</h5>
                <div className='codePalateBox mt-2 mb-5'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p style="margin-left:30px">    <span class="color-red">fetch</span>(<span class="color-green">'https://api.example.com/data'</span>)  .<span class="color-red">then</span>(response => response.<span class="color-red">json</span>())
                               .<span class="color-red">then</span>(data => <span class="color-red">setData</span>(data))</p>
                            <p style="margin-left:30px">    .<span class="color-red">catch</span>(error => <span class="color-pink">console</span>.<span class="color-red">error</span>(<span class="color-green">'Error:'</span>, error));</p>
                        `
                    }}></div>
                </div>
                <h5>POST Request</h5>
                <div className='codePalateBox mt-2 mb-5'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-blue">const</span> handleSubmit = <span class="color-blue">async</span> (event) => {</p>
                            <p style="margin-left:30px">event.<span class="color-red">preventDefault</span>();</p>
                            <p style="margin-left:30px"><span class="color-blue">const</span> formData = <span class="color-blue">new</span> <span class="color-red">FormData</span>(event.target);</p>
                            <p style="margin-left:30px">    <span class="color-blue">const</span> data = {</p>
                            <p style="margin-left:60px">        <span class="color-pink">name</span>: formData.<span class="color-red">get</span>(<span class="color-green">'name'</span>),</p>
                            <p style="margin-left:60px">        <span class="color-pink">email</span>: formData.<span class="color-red">get</span>(<span class="color-green">'email'</span>),</p>
                            <p style="margin-left:30px">    };</p>
                            <br />
                            <p style="margin-left:30px"><span class="color-blue">const</span> response = <span class="color-blue">await</span> <span class="color-red">fetch</span>(<span class="color-green">'https://api.example.com/postEndpoint'</span>, {</p>
                            <p style="margin-left:60px">    <span class="color-pink">method</span>: <span class="color-green">'POST'</span>,</p>
                            <p style="margin-left:60px">    <span class="color-pink">headers: {</p>
                            <p style="margin-left:90px">        <span class="color-green">'Content-Type'</span>: <span class="color-green">'application/json'</span>,</p>
                            <p style="margin-left:60px">    },</p>
                            <p style="margin-left:60px">    <span class="color-pink">body</span>: <span class="color-red">JSON</span>.<span class="color-red">stringify</span>(data),</p>
                            <p style="margin-left:30px">});</p>
                            <p style="margin-left:30px"><span class="color-blue">const</span> responseData = <span class="color-blue">await</span> response.<span class="color-red">json</span>();</p>
                            <p style="margin-left:30px">    <span class="color-red">setResponseMessage</span>(responseData.message);</p>
                            <p>};</p>
                        `
                    }}></div>
                </div>
                <ul style={{listStyle:'disc'}}>
                    <li>The <strong>`fetch`</strong> API is a built-in JavaScript function for making HTTP requests.</li>
                    <li>It is typically used to fetch data from a server by providing the URL of the server endpoint.</li>
                    <li><strong>`fetch`</strong> returns a Promise that resolves to the <strong>`Response`</strong> object representing the response to the request.</li>
                </ul>
            </div>
        </section>
    )
}