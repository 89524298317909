import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/css/colors";

export default function CSSColors() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Colors in CSS | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/css/comments',
            'next': '/css/colors/rgb'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>Colors in CSS</h3>
            <div className='mt-4 mb-5'>
                <p>
                    Colors in CSS can be specified in various formats, each providing a different way to define colors. The most common methods are:
                </p>
                <ul style={{ 'listStyle': 'decimal' }}>
                    <li><strong>Color Names</strong></li>
                    <li><strong><Link to="/css/colors/hex" className='noUnderline'>Hexadecimal Colors</Link></strong></li>
                    <li><strong><Link to="/css/colors/rgb" className='noUnderline'>RGB and RGBA Colors</Link></strong></li>
                    <li><strong><Link to="/css/colors/hsl" className='noUnderline'>HSL and HSLA Colors</Link></strong></li>
                </ul>

                <h5 className="mt-5 mb-3">CSS Color Names</h5>
                <p>
                    CSS provides a wide array of predefined color names that you can use directly in your stylesheets. These color names correspond to specific RGB values and cover a broad spectrum of colors. Using color names is convenient for quick styling without needing to remember specific hex or RGB values.
                </p>
                <h5 className="mt-5 mb-3">Example of How to use Color Names</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>&lt;style&gt;</p>
                            <p class="ml-30">    p {</p>
                            <p class="ml-60">        <span class="color-green">background-color</span> : yellow;</p>
                            <p class="ml-60">        <span class="color-green">color</span> : red;</p>
                            <p class="ml-30">    }</p>
                            <p>&lt;/style&gt;</p>
                            <p>&lt;p&gt;This is a example text&lt;/p&gt;</p>
                        `
                    }}></div>
                </div>
                <p>
                    Output of the following -
                </p>
                <div className='outputPalateBox mb-4'>
                    <div className='outputPalate' dangerouslySetInnerHTML={{
                        __html: `
                            <style>
                                p.colorName {
                                    background-color : yellow;
                                    color : red;
                                }
                            </style>
                            <p class="colorName">This is a example text</p>
                        `
                    }}></div>
                </div>

                <h6 className='mt-5 mb-3'>List of Standard CSS Color Names</h6>
                <p>
                    CSS includes 140 standard color names defined in the CSS Color Module Level 3 specification. Here are some of the most commonly used:
                </p>
                <div className='mt-4 mb-3 row text-center colorNames'>
                    <div className='col-2' style={{backgroundColor:'black',color:'#FFF'}}>black</div>
                    <div className='col-2' style={{ backgroundColor: 'white', color: '#000' }}>white</div>
                    <div className='col-2' style={{backgroundColor:'red',color:'#FFF'}}>red</div>
                    <div className='col-2' style={{backgroundColor:'green',color:'#FFF'}}>green</div>
                    <div className='col-2' style={{backgroundColor:'blue',color:'#FFF'}}>blue</div>
                    <div className='col-2' style={{backgroundColor:'yellow',color:'#000'}}>yellow</div>
                    <div className='col-2' style={{backgroundColor:'cyan',color:'#FFF'}}>cyan</div>
                    <div className='col-2' style={{backgroundColor:'magenta',color:'#FFF'}}>magenta</div>
                    <div className='col-2' style={{backgroundColor:'gray',color:'#000'}}>gray</div>
                    <div className='col-2' style={{backgroundColor:'silver',color:'#000'}}>silver</div>
                    <div className='col-2' style={{backgroundColor:'darkgray',color:'#FFF'}}>darkgray</div>
                    <div className='col-2' style={{backgroundColor:'lightgray',color:'#FFF'}}>lightgray</div>
                    <div className='col-2' style={{backgroundColor:'dimgray',color:'#FFF'}}>dimgray</div>
                    <div className='col-2' style={{backgroundColor:'gainsboro',color:'#FFF'}}>gainsboro</div>
                    <div className='col-2' style={{backgroundColor:'aliceblue',color:'#000'}}>aliceblue</div>
                    <div className='col-2' style={{backgroundColor:'antiquewhite',color:'#FFF'}}>antiquewhite</div>
                    <div className='col-2' style={{backgroundColor:'aqua',color:'#FFF'}}>aqua</div>
                    <div className='col-2' style={{backgroundColor:'aquamarine',color:'#FFF'}}>aquamarine</div>
                    <div className='col-2' style={{backgroundColor:'azure',color:'#000'}}>azure</div>
                    <div className='col-2' style={{backgroundColor:'beige',color:'#000'}}>beige</div>
                    <div className='col-2' style={{backgroundColor:'bisque',color:'#FFF'}}>bisque</div>
                    <div className='col-2' style={{backgroundColor:'blanchedalmond',color:'#FFF'}}>blanchedalmond</div>
                    <div className='col-2' style={{backgroundColor:'blueviolet',color:'#FFF'}}>blueviolet</div>
                    <div className='col-2' style={{backgroundColor:'brown',color:'#FFF'}}>brown</div>
                    <div className='col-2' style={{backgroundColor:'burlywood',color:'#FFF'}}>burlywood</div>
                    <div className='col-2' style={{backgroundColor:'cadetblue',color:'#FFF'}}>cadetblue</div>
                    <div className='col-2' style={{backgroundColor:'chartreuse',color:'#FFF'}}>chartreuse</div>
                    <div className='col-2' style={{backgroundColor:'chocolate',color:'#FFF'}}>chocolate</div>
                    <div className='col-2' style={{backgroundColor:'cornflowerblue',color:'#FFF'}}>cornflowerblue</div>
                    <div className='col-2' style={{backgroundColor:'cornsilk',color:'#FFF'}}>cornsilk</div>
                    <div className='col-2' style={{backgroundColor:'crimson',color:'#FFF'}}>crimson</div>
                    <div className='col-2' style={{backgroundColor:'darkblue',color:'#FFF'}}>darkblue</div>
                    <div className='col-2' style={{backgroundColor:'darkcyan',color:'#FFF'}}>darkcyan</div>
                    <div className='col-2' style={{backgroundColor:'darkgoldenrod',color:'#FFF'}}>darkgoldenrod</div>
                    <div className='col-2' style={{backgroundColor:'darkgreen',color:'#FFF'}}>darkgreen</div>
                    <div className='col-2' style={{backgroundColor:'darkkhaki',color:'#FFF'}}>darkkhaki</div>
                    <div className='col-2' style={{backgroundColor:'darkmagenta',color:'#FFF'}}>darkmagenta</div>
                    <div className='col-2' style={{backgroundColor:'darkolivegreen',color:'#FFF'}}>darkolivegreen</div>
                    <div className='col-2' style={{backgroundColor:'darkorange',color:'#FFF'}}>darkorange</div>
                    <div className='col-2' style={{backgroundColor:'darkorchid',color:'#FFF'}}>darkorchid</div>
                    <div className='col-2' style={{backgroundColor:'darkred',color:'#FFF'}}>darkred</div>
                    <div className='col-2' style={{backgroundColor:'darksalmon',color:'#FFF'}}>darksalmon</div>
                    <div className='col-2' style={{backgroundColor:'darkseagreen',color:'#FFF'}}>darkseagreen</div>
                    <div className='col-2' style={{backgroundColor:'darkslateblue',color:'#FFF'}}>darkslateblue</div>
                    <div className='col-2' style={{backgroundColor:'darkslategray',color:'#FFF'}}>darkslategray</div>
                    <div className='col-2' style={{backgroundColor:'darkturquoise',color:'#FFF'}}>darkturquoise</div>
                    <div className='col-2' style={{backgroundColor:'darkviolet',color:'#FFF'}}>darkviolet</div>
                    <div className='col-2' style={{backgroundColor:'deeppink',color:'#FFF'}}>deeppink</div>
                    <div className='col-2' style={{backgroundColor:'deepskyblue',color:'#FFF'}}>deepskyblue</div>
                    <div className='col-2' style={{backgroundColor:'dodgerblue',color:'#FFF'}}>dodgerblue</div>
                    <div className='col-2' style={{backgroundColor:'firebrick',color:'#FFF'}}>firebrick</div>
                    <div className='col-2' style={{backgroundColor:'floralwhite',color:'#000'}}>floralwhite</div>
                    <div className='col-2' style={{backgroundColor:'forestgreen',color:'#FFF'}}>forestgreen</div>
                    <div className='col-2' style={{backgroundColor:'fuchsia',color:'#FFF'}}>fuchsia</div>
                    <div className='col-2' style={{backgroundColor:'gainsboro',color:'#FFF'}}>gainsboro</div>
                    <div className='col-2' style={{backgroundColor:'ghostwhite',color:'#000'}}>ghostwhite</div>
                    <div className='col-2' style={{backgroundColor:'gold',color:'#FFF'}}>gold</div>
                    <div className='col-2' style={{backgroundColor:'goldenrod',color:'#FFF'}}>goldenrod</div>
                    <div className='col-2' style={{backgroundColor:'greenyellow',color:'#FFF'}}>greenyellow</div>
                    <div className='col-2' style={{backgroundColor:'honeydew',color:'#000'}}>honeydew</div>
                    <div className='col-2' style={{backgroundColor:'hotpink',color:'#FFF'}}>hotpink</div>
                    <div className='col-2' style={{backgroundColor:'indianred',color:'#FFF'}}>indianred</div>
                    <div className='col-2' style={{backgroundColor:'indigo',color:'#FFF'}}>indigo</div>
                    <div className='col-2' style={{backgroundColor:'ivory',color:'#000'}}>ivory</div>
                    <div className='col-2' style={{backgroundColor:'khaki',color:'#FFF'}}>khaki</div>
                    <div className='col-2' style={{backgroundColor:'lavender',color:'#FFF'}}>lavender</div>
                    <div className='col-2' style={{backgroundColor:'lavenderblush',color:'#000'}}>lavenderblush</div>
                    <div className='col-2' style={{backgroundColor:'lawngreen',color:'#FFF'}}>lawngreen</div>
                    <div className='col-2' style={{backgroundColor:'lemonchiffon',color:'#000'}}>lemonchiffon</div>
                    <div className='col-2' style={{backgroundColor:'lightblue',color:'#FFF'}}>lightblue</div>
                    <div className='col-2' style={{backgroundColor:'lightcoral',color:'#000'}}>lightcoral</div>
                    <div className='col-2' style={{backgroundColor:'lightcyan',color:'#000'}}>lightcyan</div>
                    <div className='col-2' style={{backgroundColor:'lightgoldenrodyellow',color:'#000'}}>lightgoldenrodyellow</div>
                    <div className='col-2' style={{backgroundColor:'lightgreen',color:'#FFF'}}>lightgreen</div>
                    <div className='col-2' style={{backgroundColor:'lightpink',color:'#FFF'}}>lightpink</div>
                    <div className='col-2' style={{backgroundColor:'lightsalmon',color:'#FFF'}}>lightsalmon</div>
                    <div className='col-2' style={{backgroundColor:'lightseagreen',color:'#FFF'}}>lightseagreen</div>
                    <div className='col-2' style={{backgroundColor:'lightskyblue',color:'#FFF'}}>lightskyblue</div>
                    <div className='col-2' style={{backgroundColor:'lightslategray',color:'#FFF'}}>lightslategray</div>
                    <div className='col-2' style={{backgroundColor:'lightsteelblue',color:'#FFF'}}>lightsteelblue</div>
                    <div className='col-2' style={{backgroundColor:'lightyellow',color:'#000'}}>lightyellow</div>
                    <div className='col-2' style={{backgroundColor:'lime',color:'#FFF'}}>lime</div>
                    <div className='col-2' style={{backgroundColor:'limegreen',color:'#FFF'}}>limegreen</div>
                    <div className='col-2' style={{backgroundColor:'linen',color:'#000'}}>linen</div>
                    <div className='col-2' style={{backgroundColor:'maroon',color:'#FFF'}}>maroon</div>
                    <div className='col-2' style={{backgroundColor:'mediumaquamarine',color:'#FFF'}}>mediumaquamarine</div>
                    <div className='col-2' style={{backgroundColor:'mediumblue',color:'#FFF'}}>mediumblue</div>
                    <div className='col-2' style={{backgroundColor:'mediumorchid',color:'#FFF'}}>mediumorchid</div>
                    <div className='col-2' style={{backgroundColor:'mediumpurple',color:'#FFF'}}>mediumpurple</div>
                    <div className='col-2' style={{backgroundColor:'mediumseagreen',color:'#FFF'}}>mediumseagreen</div>
                    <div className='col-2' style={{backgroundColor:'mediumslateblue',color:'#FFF'}}>mediumslateblue</div>
                    <div className='col-2' style={{backgroundColor:'mediumspringgreen',color:'#FFF'}}>mediumspringgreen</div>
                    <div className='col-2' style={{backgroundColor:'mediumturquoise',color:'#FFF'}}>mediumturquoise</div>
                    <div className='col-2' style={{backgroundColor:'mediumvioletred',color:'#FFF'}}>mediumvioletred</div>
                    <div className='col-2' style={{backgroundColor:'midnightblue',color:'#FFF'}}>midnightblue</div>
                    <div className='col-2' style={{backgroundColor:'mintcream',color:'#000'}}>mintcream</div>
                    <div className='col-2' style={{backgroundColor:'mistyrose',color:'#FFF'}}>mistyrose</div>
                    <div className='col-2' style={{backgroundColor:'moccasin',color:'#FFF'}}>moccasin</div>
                    <div className='col-2' style={{backgroundColor:'navajowhite',color:'#000'}}>navajowhite</div>
                    <div className='col-2' style={{backgroundColor:'oldlace',color:'#000'}}>oldlace</div>
                    <div className='col-2' style={{backgroundColor:'olive',color:'#FFF'}}>olive</div>
                    <div className='col-2' style={{backgroundColor:'olivedrab',color:'#FFF'}}>olivedrab</div>
                    <div className='col-2' style={{backgroundColor:'orange',color:'#FFF'}}>orange</div>
                    <div className='col-2' style={{backgroundColor:'orangered',color:'#FFF'}}>orangered</div>
                    <div className='col-2' style={{backgroundColor:'orchid',color:'#FFF'}}>orchid</div>
                    <div className='col-2' style={{backgroundColor:'palegoldenrod',color:'#FFF'}}>palegoldenrod</div>
                    <div className='col-2' style={{backgroundColor:'palegreen',color:'#FFF'}}>palegreen</div>
                    <div className='col-2' style={{backgroundColor:'paleturquoise',color:'#FFF'}}>paleturquoise</div>
                    <div className='col-2' style={{backgroundColor:'palevioletred',color:'#FFF'}}>palevioletred</div>
                    <div className='col-2' style={{backgroundColor:'papayawhip',color:'#000'}}>papayawhip</div>
                    <div className='col-2' style={{backgroundColor:'peachpuff',color:'#FFF'}}>peachpuff</div>
                    <div className='col-2' style={{backgroundColor:'peru',color:'#FFF'}}>peru</div>
                    <div className='col-2' style={{backgroundColor:'pink',color:'#FFF'}}>pink</div>
                    <div className='col-2' style={{backgroundColor:'plum',color:'#FFF'}}>plum</div>
                    <div className='col-2' style={{backgroundColor:'powderblue',color:'#FFF'}}>powderblue</div>
                    <div className='col-2' style={{backgroundColor:'purple',color:'#FFF'}}>purple</div>
                    <div className='col-2' style={{backgroundColor:'rebeccapurple',color:'#FFF'}}>rebeccapurple</div>
                    <div className='col-2' style={{backgroundColor:'rosybrown',color:'#FFF'}}>rosybrown</div>
                    <div className='col-2' style={{backgroundColor:'royalblue',color:'#FFF'}}>royalblue</div>
                    <div className='col-2' style={{backgroundColor:'saddlebrown',color:'#FFF'}}>saddlebrown</div>
                    <div className='col-2' style={{backgroundColor:'salmon',color:'#FFF'}}>salmon</div>
                    <div className='col-2' style={{backgroundColor:'sandybrown',color:'#FFF'}}>sandybrown</div>
                    <div className='col-2' style={{backgroundColor:'seagreen',color:'#FFF'}}>seagreen</div>
                    <div className='col-2' style={{backgroundColor:'seashell',color:'#000'}}>seashell</div>
                    <div className='col-2' style={{backgroundColor:'sienna',color:'#FFF'}}>sienna</div>
                    <div className='col-2' style={{backgroundColor:'skyblue',color:'#FFF'}}>skyblue</div>
                    <div className='col-2' style={{backgroundColor:'slateblue',color:'#FFF'}}>slateblue</div>
                    <div className='col-2' style={{backgroundColor:'slategray',color:'#FFF'}}>slategray</div>
                    <div className='col-2' style={{backgroundColor:'snow',color:'#000'}}>snow</div>
                    <div className='col-2' style={{backgroundColor:'springgreen',color:'#FFF'}}>springgreen</div>
                    <div className='col-2' style={{backgroundColor:'steelblue',color:'#FFF'}}>steelblue</div>
                    <div className='col-2' style={{backgroundColor:'tan',color:'#FFF'}}>tan</div>
                    <div className='col-2' style={{backgroundColor:'teal',color:'#FFF'}}>teal</div>
                    <div className='col-2' style={{backgroundColor:'thistle',color:'#FFF'}}>thistle</div>
                    <div className='col-2' style={{backgroundColor:'tomato',color:'#FFF'}}>tomato</div>
                    <div className='col-2' style={{backgroundColor:'turquoise',color:'#FFF'}}>turquoise</div>
                    <div className='col-2' style={{backgroundColor:'violet',color:'#FFF'}}>violet</div>
                    <div className='col-2' style={{backgroundColor:'wheat',color:'#FFF'}}>wheat</div>
                    <div className='col-2' style={{backgroundColor:'whitesmoke',color:'#000'}}>whitesmoke</div>
                    <div className='col-2' style={{backgroundColor:'yellowgreen',color:'#FFF'}}>yellowgreen</div>
                </div>
            </div>
        </section>
    )
}