import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/html/customize-links";

export default function HTMLCustomizeLinks() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("HTML - Customize Links | Aspirant's Home");
        const urls = {
            'previous': '/html/text-links',
            'next': '/html/create-bookmark'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>HTML - Customize Links</h3>
            <div className='mt-4 mb-5'>
                <p>
                    Customizing links in HTML involves using CSS to change their appearance and behavior. You can customize links to change their color, add underlines, change the appearance on hover, and more.
                </p>
                <h5 className='mt-5 mb-3'>Basic Example</h5>
                <p>
                    Here’s how you can style a link:
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-grey">&lt;!DOCTYPE</span> <span class="color-blue">html</span><span class="color-grey">&gt;</span></p>
                        <p>&lt;html&gt;</p>
                        <p class="ml-30">    &lt;head&gt;</p>
                        <p class="ml-60">        &lt;style&gt;</p>
                        <p class="ml-90">            a {</p>
                        <p class="ml-120">                <span class="color-green">color</span>: blue; <span class="color-grey">/* Sets the link color */</span></p>
                        <p class="ml-120">                <span class="color-green">text-decoration</span>: none; <span class="color-grey">/* Removes the underline */</span></p>
                        <p class="ml-90">            }</p>
                        <p class="ml-90">            a:<span class="color-pink">hover</span> {</p>
                        <p class="ml-120">                <span class="color-green">color</span>: red; <span class="color-grey">/* Changes color on hover */</span></p>
                        <p class="ml-120">                <span class="color-green">text-decoration</span>: underline; <span class="color-grey">/* Adds underline on hover */</span></p>
                        <p class="ml-90">            }</p>
                        <p class="ml-60">        &lt;/style&gt;</p>
                        <p class="ml-60">        &lt;title&gt;Customizing Links Example&lt;/title&gt;</p>
                        <p class="ml-30">    &lt;/head&gt;</p>
                        <p class="ml-30">    &lt;body&gt;</p>
                        <p class="ml-60">        &lt;a <span class="color-pink">href</span>=<span class="color-green">"https://www.example.com"</span>&gt;Visit Example.com&lt;/a&gt;</p>
                        <p class="ml-30">    &lt;/body&gt;</p>
                        <p>&lt;/html&gt;</p>
                        `
                    }}></div>
                </div>
                <p>
                    Output of the following -
                </p>
                <div className='outputPalateBox mb-4'>
                    <div className='outputPalate' dangerouslySetInnerHTML={{
                        __html: `
                        <!DOCTYPE html>
                        <html>
                            <head>
                                <style>
                                    a {
                                        color: blue; /* Sets the link color */
                                        text-decoration: none; /* Removes the underline */
                                    }
                                    a.myClass:hover {
                                        color: red; /* Changes color on hover */
                                        text-decoration: underline; /* Adds underline on hover */
                                    }
                                </style>
                                <title>Customizing Links Example</title>
                            </head>
                            <body>
                                <a class="myClass" href="https://www.example.com">Visit Example.com</a>
                            </body>
                        </html>
                        `
                    }}></div>
                </div>
                <h5 className='mt-5 mb-3'>Styling Different Link States</h5>
                <p>
                    Links have several states that you can style:
                </p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><strong>`Normal`</strong>: The default state of the link.</li>
                    <li><strong>`Visited`</strong>: The state of the link after it has been clicked.</li>
                    <li><strong>`Hover`</strong>: The state when the mouse pointer is over the link.</li>
                    <li><strong>`Active`</strong>: The state when the link is being clicked.</li>
                </ul>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-grey">&lt;!DOCTYPE</span> <span class="color-blue">html</span><span class="color-grey">&gt;</span></p>
                        <p>&lt;html&gt;</p>
                        <p class="ml-30">    &lt;head&gt;</p>
                        <p class="ml-60">        &lt;style&gt;</p>
                        <p class="ml-90">            a:<span class="color-pink">link</span> {</p>
                        <p class="ml-120">                <span class="color-green">color</span>: blue; <span class="color-grey">/* Normal state */</span></p>
                        <p class="ml-90">            }</p>
                        <p class="ml-90">            a:<span class="color-pink">visited</span> {</p>
                        <p class="ml-120">                <span class="color-green">color</span>: purple; <span class="color-grey">/* Visited state */</span></p>
                        <p class="ml-90">            }</p>
                        <p class="ml-90">            a:<span class="color-pink">hover</span> {</p>
                        <p class="ml-120">                <span class="color-green">color</span>: red; <span class="color-green">/* Hover state */</span></p>
                        <p class="ml-120">                <span class="color-green">text-decoration</span>: underline; <span class="color-grey">/* Adds underline on hover */</span></p>
                        <p class="ml-90">            }</p>
                        <p class="ml-90">            a:<span class="color-pink">active</span> {</p>
                        <p class="ml-120">                <span class="color-green">color</span>: orange; <span class="color-grey">/* Active state */</span></p>
                        <p class="ml-90">            }</p>
                        <p class="ml-60">        &lt;/style&gt;</p>
                        <p class="ml-60">        &lt;title&gt;Styling Link States Example&lt;/title&gt;</p>
                        <p class="ml-30">    &lt;/head&gt;</p>
                        <p class="ml-30">    &lt;body&gt;</p>
                        <p class="ml-60">        &lt;a <span class="color-pink">href</span>=<span class="color-green">"https://www.example.com"</span>&gt;Visit Example.com&lt;/a&gt;</p>
                        <p class="ml-30">    &lt;/body&gt;</p>
                        <p>&lt;/html&gt;</p>
                        `
                    }}></div>
                </div>
                <p>
                    Output of the following -
                </p>
                <div className='outputPalateBox mb-4'>
                    <div className='outputPalate' dangerouslySetInnerHTML={{
                        __html: `
                        <!DOCTYPE html>
                        <html>
                            <head>
                                <style>
                                    a.myClass2:link {
                                        color: blue; /* Normal state */
                                    }
                                    a.myClass2:visited {
                                        color: purple; /* Visited state */
                                    }
                                    a.myClass2:hover {
                                        color: red; /* Hover state */
                                        text-decoration: underline; /* Adds underline on hover */
                                    }
                                    a.myClass2:active {
                                        color: orange; /* Active state */
                                    }
                                </style>
                                <title>Styling Link States Example</title>
                            </head>
                            <body>
                                <a class="myClass2" href="https://www.example.com">Visit Example.com</a>
                            </body>
                        </html>
                        `
                    }}></div>
                </div>
                <h5 className='mt-5 mb-3'>Adding Background and Padding</h5>
                <p>
                    You can add background colors, padding, and borders to make links look like buttons.
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-grey">&lt;!DOCTYPE</span> <span class="color-blue">html</span><span class="color-grey">&gt;</span></p>
                        <p>&lt;html&gt;</p>
                        <p class="ml-30">    &lt;head&gt;</p>
                        <p class="ml-60">        &lt;style&gt;</p>
                        <p class="ml-90">            a.<span class="color-pink">button</span> {</p>
                        <p class="ml-120">                <span class="color-green">background-color</span>: <span class="color-pink">#4CAF50</span>; <span class="color-grey">/* Green background */</span></p>
                        <p class="ml-120">                <span class="color-green">color</span>: white; <span class="color-grey">/* White text */</span></p>
                        <p class="ml-120">                <span class="color-green">padding</span>: <span class="color-pink">10px 20px</span>; <span class="color-grey">/* Some padding */</span></p>
                        <p class="ml-120">                <span class="color-green">text-align</span>: center; <span class="color-grey">/* Centered text */</span></p>
                        <p class="ml-120">                <span class="color-green">text-decoration</span>: none; <span class="color-grey">/* No underline */</span></p>
                        <p class="ml-120">                <span class="color-green">display</span>: inline-block; <span class="color-grey">/* Make the link behave like a button */</span></p>
                        <p class="ml-120">                <span class="color-green">border-radius</span>: <span class="color-pink">4px</span>; <span class="color-grey">/* Rounded corners */</span></p>
                        <p class="ml-90">            }</p>
                        <p class="ml-90">            a.<span class="color-pink">button</span>:hover {</p>
                        <p class="ml-120">                <span class="color-green">background-color</span>: <span class="color-pink">#45a049</span>; <span class="color-grey">/* Darker green on hover */</span></p>
                        <p class="ml-90">            }</p>
                        <p class="ml-60">        &lt;/style&gt;</p>
                        <p class="ml-60">        &lt;title&gt;Button Link Example&lt;/title&gt;</p>
                        <p class="ml-30">    &lt;/head&gt;</p>
                        <p class="ml-30">    &lt;body&gt;</p>
                        <p class="ml-60">        &lt;a <span class="color-pink">href</span>=<span class="color-green">"https://www.example.com"</span> <span class="color-pink">class</span>=<span class="color-green">"button"</span>&gt;Visit Example.com&lt;/a&gt;</p>
                        <p class="ml-30">    &lt;/body&gt;</p>
                        <p>&lt;/html&gt;</p>
                        `
                    }}></div>
                </div>
                <p>
                    Output of the following -
                </p>
                <div className='outputPalateBox mb-4' style={{border:'none'}}>
                    <div className='outputPalate' dangerouslySetInnerHTML={{
                        __html: `
                        <!DOCTYPE html>
                        <html>
                            <head>
                                <style>
                                    a.button {
                                    background-color: #4CAF50; /* Green background */
                                    color: white; /* White text */
                                    padding: 10px 20px; /* Some padding */
                                    text-align: center; /* Centered text */
                                    text-decoration: none; /* No underline */
                                    display: inline-block; /* Make the link behave like a button */
                                    border-radius: 4px; /* Rounded corners */
                                    }
                                    a.button:hover {
                                    background-color: #45a049; /* Darker green on hover */
                                    }
                                </style>
                                <title>Button Link Example</title>
                            </head>
                            <body>
                                <a href="https://www.example.com" class="button">Visit Example.com</a>
                            </body>
                        </html>
                        `
                    }}></div>
                </div>
                <h5 className='mt-5 mb-3'>Using Pseudo-elements</h5>
                <p>
                    You can use CSS pseudo-elements like <strong>`::before`</strong> and <strong>`::after`</strong> to add additional content to your links.
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-grey">&lt;!DOCTYPE</span> <span class="color-blue">html</span><span class="color-grey">&gt;</span></p>
                        <p>&lt;html&gt;</p>
                        <p class="ml-30">    &lt;head&gt;</p>
                        <p class="ml-60">        &lt;style&gt;</p>
                        <p class="ml-90">            a::before {</p>
                        <p class="ml-120">                <span class="color-green">content</span>: <span class="color-green">"→ "</span>; <span class="color-grey">/* Adds an arrow before the link text */</span></p>
                        <p class="ml-90">            }</p>
                        <p class="ml-90">            a::after {</p>
                        <p class="ml-120">                <span class="color-green">content</span>: <span class="color-green">" (external)"</span>; <span class="color-grey">/* Adds text after the link text */</span></p>
                        <p class="ml-120">                <span class="color-green">font-size</span>: smaller; <span class="color-grey">/* Makes the additional text smaller */</span></p>
                        <p class="ml-90">            }</p>
                        <p class="ml-60">        &lt;/style&gt;</p>
                        <p class="ml-60">        &lt;title&gt;Pseudo-element Link Example&lt;/title&gt;</p>
                        <p class="ml-30">    &lt;/head&gt;</p>
                        <p class="ml-30">    &lt;body&gt;</p>
                        <p class="ml-60">        &lt;a <span class="color-pink"></span>>=<span class="color-green">"https://www.example.com"</span>&gt;Visit Example.com&lt;/a&gt;</p>
                        <p class="ml-30">    &lt;/body&gt;</p>
                        <p>&lt;/html&gt;</p>
                        `
                    }}></div>
                </div>
                <p>
                    Output of the following -
                </p>
                <div className='outputPalateBox mb-4' style={{border:'none'}}>
                    <div className='outputPalate' dangerouslySetInnerHTML={{
                        __html: `
                        <!DOCTYPE html>
                        <html>
                            <head>
                                <style>
                                    a.pseudoClass::before {
                                        content: "→ "; /* Adds an arrow before the link text */
                                    }
                                    a.pseudoClass::after {
                                        content: " (external)"; /* Adds text after the link text */
                                        font-size: smaller; /* Makes the additional text smaller */
                                    }
                                </style>
                                <title>Pseudo-element Link Example</title>
                            </head>
                            <body>
                                <a class="pseudoClass" href="https://www.example.com">Visit Example.com</a>
                            </body>
                        </html>
                        `
                    }}></div>
                </div>
                <h5 className='mt-5 mb-3'>Customizing Image Links</h5>
                <p>
                    You can also customize image links with CSS.
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-grey">&lt;!DOCTYPE</span> <span class="color-blue">html</span><span class="color-grey">&gt;</span></p>
                        <p>&lt;html&gt;</p>
                        <p class="ml-30">   &lt;head&gt;</p>
                        <p class="ml-60">        &lt;style&gt;</p>
                        <p class="ml-90">            a img {</p>
                        <p class="ml-120">                <span class="color-green">border</span>: <span class="color-pink">2px</span> solid <span class="color-pink">#ccc</span>; <span class="color-grey">/* Adds a border to the image */</span></p>
                        <p class="ml-120">                <span class="color-green">border-radius</span>: <span class="color-pink">8px</span>; <span class="color-grey">/* Rounds the corners */</span></p>
                        <p class="ml-120">                <span class="color-green">transition</span>: transform <span class="color-pink">0.3s</span>; <span class="color-grey">/* Adds a transition effect */</span></p>
                        <p class="ml-90">            }</p>
                        <p class="ml-90">            a img<span class="color-pink">:hover</span> {</p>
                        <p class="ml-120">                <span class="color-green">transform</span>: <span class="color-yellow">scale</span>(<span class="color-pink">1.1</span>); <span class="color-grey">/* Scales the image up on hover */</span></p>
                        <p class="ml-120">                <span class="color-green">border-color</span>: <span class="color-pink">#333</span>; <span class="color-grey">/* Changes the border color on hover */</span></p>
                        <p class="ml-90">            }</p>
                        <p class="ml-60">        &lt;/style&gt;</p>
                        <p class="ml-60">        &lt;title&gt;Styled Image Link Example&lt;/title&gt;</p>
                        <p class="ml-30">    &lt;/head&gt;</p>
                        <p class="ml-30">    &lt;body&gt;</p>
                        <p class="ml-60">        &lt;a <span class="color-pink">href</span>=<span class="color-green">"/html/images"</span>&gt;</p>
                        <p class="ml-90">            &lt;img <span class="color-pink">src</span>=<span class="color-green">"../assets/wmpaintff078.webp"</span> <span class="color-pink">alt</span>=<span class="color-green">"Example Logo"</span> <span class="color-pink">width</span>=<span class="color-green">"100"</span> <span class="color-pink">height</span>=<span class="color-green">"100"</span>&gt;</p>
                        <p class="ml-60">        &lt;/a&gt;</p>
                        <p class="ml-30">    &lt;/body&gt;</p>
                        <p>&lt;/html&gt;</p>
                        `
                    }}></div>
                </div>
                <p>
                    Output of the following -
                </p>
                <div className='outputPalateBox mb-4' style={{border:'none'}}>
                    <div className='outputPalate' dangerouslySetInnerHTML={{
                        __html: `
                        <!DOCTYPE html>
                        <html>
                            <head>
                                <style>
                                    a.example img {
                                        border: 2px solid #ccc; /* Adds a border to the image */
                                        border-radius: 8px; /* Rounds the corners */
                                        transition: transform 0.3s; /* Adds a transition effect */
                                    }
                                    a img:hover {
                                        transform: scale(1.1); /* Scales the image up on hover */
                                        border-color: #333; /* Changes the border color on hover */
                                    }
                                </style>
                                <title>Styled Image Link Example</title>
                            </head>
                            <body>
                                <a class="example" href="/html/images">
                                    <img src="../assets/wmpaintff078.webp" alt="Example Logo" width="100" height="100">
                                </a>
                            </body>
                        </html>
                        `
                    }}></div>
                </div>

                <p className='mt-4 featureClass'>
                    By using these techniques, you can create visually appealing and interactive links in your HTML documents.
                </p>
            </div>
        </section>
    )
}