import React, { useEffect, useState, useRef } from 'react'
import { Outlet, Link } from "react-router-dom";
import TopHeader from '../TopHeader';
import Footer from '../Footer'
import { MenuContext, TitleContext } from "../../Context";
import { useContext } from "react";

export default function PythonLayout() {

    const addspaceElement = useRef();
    const menuElement = useRef([]);
    const menu = useContext(MenuContext);
    const [pathname, setPathName] = useState();

    useEffect(() => {
        setPathName(window.location.pathname)
        if (menu.isMenu == true) menu.setMenuOpen(false)
        addspaceElement.current.scrollIntoView(true)
        const element = document.getElementsByClassName("activeMenu")[0];
        element && element.scrollIntoView(true);
    }, [pathname])

    return (
        <>
            <TopHeader />
            <div className='container-fluid'>
                <div className='row'>
                    <div ref={menuElement} className={menu.isMenu == true ? 'col-md-3 col-lg-2 menuColumnMobile menuColumn p-0' : 'col-md-3 col-lg-2 menuColumn p-0'} style={{ 'overflowY': 'scroll', height: 'calc(100vh - 110px)' }}>
                        <div className='headerMenu'>
                            Python Tutorial
                        </div>
                        <nav>
                            <ul>
                                <li className={pathname == '/python/introduction' ? 'activeMenu' : ''}>
                                    <Link to="/python/introduction" onClick={() => setPathName('/python/introduction')}>Introduction</Link>
                                </li>
                                <li className={pathname == '/python/how-it-works' ? 'activeMenu' : ''}>
                                    <Link to="/python/how-it-works" onClick={() => setPathName('/python/how-it-works')}>How Python is Works</Link>
                                </li>
                                <li className={pathname == '/python/features-of-python' ? 'activeMenu' : ''}>
                                    <Link to="/python/features-of-python" onClick={() => setPathName('/python/features-of-python')}>Features of Python</Link>
                                </li>
                                <li className={pathname == '/python/environment-setup' ? 'activeMenu' : ''}>
                                    <Link to="/python/environment-setup" onClick={() => setPathName('/python/environment-setup')}>Environment Setup</Link>
                                </li>
                                <li className={pathname == '/python/datatypes' ? 'activeMenu' : ''}>
                                    <Link to="/python/datatypes" onClick={() => setPathName('/python/datatypes')}>Python Datatypes</Link>
                                </li>
                                <li className={pathname == '/python/operators-expressions' ? 'activeMenu' : ''}>
                                    <Link to="/python/operators-expressions" onClick={() => setPathName('/python/operators-expressions')}>Operators & Expressios</Link>
                                </li>
                                <li className={pathname == '/python/conditional-statements' ? 'activeMenu' : ''}>
                                    <Link to="/python/conditional-statements" onClick={() => setPathName('/python/conditional-statements')}>Conditional Statements</Link>
                                </li>
                                <li className={pathname == '/python/loops-control-flow' ? 'activeMenu' : ''}>
                                    <Link to="/python/loops-control-flow" onClick={() => setPathName('/python/loops-control-flow')}>Loops - Control Flow</Link>
                                </li>
                                <li className={pathname == '/python/string-methods' ? 'activeMenu' : ''}>
                                    <Link to="/python/string-methods" onClick={() => setPathName('/python/string-methods')}>String and its Methods</Link>
                                </li>
                                <li className={pathname == '/python/formatting' ? 'activeMenu' : ''}>
                                    <Link to="/python/formatting" onClick={() => setPathName('/python/formatting')}>Formatting</Link>
                                </li>
                                <li className={pathname == '/python/regular-expression' ? 'activeMenu' : ''}>
                                    <Link to="/python/regular-expression" onClick={() => setPathName('/python/regular-expression')}>Regular Expression</Link>
                                </li>
                                <li className={pathname == '/python/list' ? 'activeMenu' : ''}>
                                    <Link to="/python/list" onClick={() => setPathName('/python/list')}>Python List</Link>
                                </li>
                                <li className={pathname == '/python/tuple' ? 'activeMenu' : ''}>
                                    <Link to="/python/tuple" onClick={() => setPathName('/python/tuple')}>Python Tuple</Link>
                                </li>
                                <li className={pathname == '/python/sets' ? 'activeMenu' : ''}>
                                    <Link to="/python/sets" onClick={() => setPathName('/python/sets')}>Python Sets</Link>
                                </li>
                                <li className={pathname == '/python/dictionary' ? 'activeMenu' : ''}>
                                    <Link to="/python/dictionary" onClick={() => setPathName('/python/dictionary')}>Dictionary</Link>
                                </li>
                                <li className={pathname == '/python/functions' ? 'activeMenu' : ''}>
                                    <Link to="/python/functions" onClick={() => setPathName('/python/functions')}>Functions</Link>
                                </li>
                                <li className={pathname == '/python/exception-handling' ? 'activeMenu' : ''}>
                                    <Link to="/python/exception-handling" onClick={() => setPathName('/python/exception-handling')}>Exception Handling</Link>
                                </li>
                                <li className={pathname == '/python/file-handling' ? 'activeMenu' : ''}>
                                    <Link to="/python/file-handling" onClick={() => setPathName('/python/file-handling')}>File Handling</Link>
                                </li>
                                <li className={pathname == '/python/oops-concept' ? 'activeMenu' : ''}>
                                    <Link to="/python/oops-concept" onClick={() => setPathName('/python/oops-concept')}>Python OOPs Concept</Link>
                                </li>
                                <li className={pathname == '/python/multi-threading' ? 'activeMenu' : ''}>
                                    <Link to="/python/multi-threading" onClick={() => setPathName('/python/multi-threading')}>MultiThreading</Link>
                                </li>
                                <li className={pathname == '/python/date-and-time' ? 'activeMenu' : ''}>
                                    <Link to="/python/date-and-time" onClick={() => setPathName('/python/date-and-time')}>Date and Time</Link>
                                </li>
                                <li className={pathname == '/python/database-connection' ? 'activeMenu' : ''}>
                                    <Link to="/python/database-connection" onClick={() => setPathName('/python/database-connection')}>Database Connectivity</Link>
                                </li>
                                <li className={pathname == '/python/data-structure-modules' ? 'activeMenu' : ''}>
                                    <Link to="/python/data-structure-modules" onClick={() => setPathName('/python/data-structure-modules')}>Data Structure Modules</Link>
                                </li>
                                <li className={pathname == '/python/math-modules' ? 'activeMenu' : ''}>
                                    <Link to="/python/math-modules" onClick={() => setPathName('/python/math-modules')}>Math Modules</Link>
                                </li>
                                <li className={pathname == '/python/os-modules' ? 'activeMenu' : ''}>
                                    <Link to="/python/os-modules" onClick={() => setPathName('/python/os-modules')}>OS Modules</Link>
                                </li>
                            </ul>
                        </nav>
                    </div>
                    <div className='col-12 col-md-9 col-lg-10 rightPart' style={{ 'overflowY': 'scroll', height: 'calc(100vh - 110px)' }}>
                        <div className='row'>
                            <div className='col-12 col-md-12 col-lg-10 col-lg-9 mb-5'>
                                <div className='headerAdSpace' ref={addspaceElement}>
                                    <ins className="adsbygoogle"
                                        style={{ 'display': 'block' }}
                                        data-ad-client="ca-pub-4667727216643481"
                                        data-ad-slot="8913905287"
                                        data-ad-format="auto"
                                        data-full-width-responsive="true"></ins>
                                    <script>
                                        (adsbygoogle = window.adsbygoogle || []).push({ });
                                    </script>
                                </div>
                                <div className='container'>
                                    <Outlet />
                                </div>
                                <div className='footerAdSpace'>
                                    <ins className="adsbygoogle"
                                        style={{ 'display': 'block' }}
                                        data-ad-client="ca-pub-4667727216643481"
                                        data-ad-slot="6714530623"
                                        data-ad-format="auto"
                                        data-full-width-responsive="true"></ins>
                                    <script>
                                        (adsbygoogle = window.adsbygoogle || []).push({ });
                                    </script>
                                </div>
                            </div>
                            <div className='col-lg-3 hiddenInSmall'>
                                <ins className="adsbygoogle"
                                    style={{ 'display': 'block' }}
                                    data-ad-client="ca-pub-4667727216643481"
                                    data-ad-slot="1035415263"
                                    data-ad-format="auto"
                                    data-full-width-responsive="true"></ins>
                                <script>
                                    (adsbygoogle = window.adsbygoogle || []).push({ });
                                </script>
                            </div>
                            <div className='col-12'>
                                <div className='container-fluid footerPart'>
                                    <Footer />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
