import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/mysql/show-users";

export default function MysqlShowUser() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Mysql - Show User | Aspirant's Home");
        const urls = {
            'previous': '/mysql/drop-users',
            'next': '/mysql/change-password'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>Mysql - Show User</h3>
            <div className='mt-4 mb-5'>
                <p>
                    To show a list of users and the details of these user accounts is stored in the <strong>`user`</strong> table within the database in MySQL, we can use the <strong>`SELECT`</strong> statement to list out the contents of this <strong>`mysql.user`</strong> table, which contains information about the users who have access to the MySQL server. Here's an example:
                </p>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-blue">SELECT * FROM</span> mysql.user;</p>
                        `
                    }}></div>
                </div>
                <p>
                    To see the structure of this <strong>`user`</strong> table, use the following query with the <strong>`DESC`</strong> command −
                </p>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-blue">DESC</span> mysql.user;</p>
                        `
                    }}></div>
                </div>
            </div>
            <div className='mt-5 mb-4'>
                <h5>Show Current User</h5>
                <div className='mt-2 mb-2'>
                    <p>
                        To see the current user, we can see with the help of <strong>`user()`</strong> or <strong>`current_user()`</strong> functions. Following is the syntax to show the current user −
                    </p>
                    <div className='codePalateBox mt-2 mb-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                                <p><span class="color-blue">SELECT</span> user();</p>
                            `
                        }}></div>
                    </div>
                    <p> or </p>
                    <div className='codePalateBox mt-2 mb-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                                <p><span class="color-blue">SELECT</span> current_user();</p>
                            `
                        }}></div>
                    </div>
                </div>
            </div>
        </section>
    )
}