import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/react-js/jsx";

export default function ReactJSX() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("React JSX | Aspirant's Home");
        const urls = {
            'previous': '/react-js/render-html',
            'next':'/react-js/components'
        }
        path.setPreviousNext(urls);
    },[])

    return (
        <section className='mt-5 mb-5'>
            <h3>React Js - JSX</h3>
            <div className='mb-4 mt-4'>
                <p>JSX (JavaScript XML) is an extension to the JavaScript language syntax used with React. It resembles XML/HTML and allows you to write HTML-like code within your JavaScript files. JSX is not a separate template language; it's a syntactic sugar that makes writing React components more concise and readable.</p>
                <p>Here's a basic example of JSX:</p>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `<p><span class="color-blue">const</span> element = &lt;h1&gt;Hello, world!&lt;/h1&gt;;</p>`
                    }}></div>
                </div>
            </div>
            <div className='mb-4 mt-5'>
                <p>We can do the same using <strong>`React.createElement()`</strong> also. </p>
                <p>Here is the syntax of <strong>`React.createElement()`</strong></p>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `<p><span class="color-blue">const</span> element = <span class="color-yellow">createElement</span>(type, props, ...children)</p>`
                    }}></div>
                </div>
                <p>Here is the example of <strong>`React.createElement()`</strong></p>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `<p><span class="color-red">import</span> { <span class="color-blue">createElement</span> } from <span class="color-green">'react'</span>;</p>
                        <br />
                        <p><span class="color-blue">function</span> Greeting({ <span class="color-blue">name</span> }) {</p>
                        <p style="margin-left:30px">  <span class="color-red">return</span> <span class="color-yellow">createElement</span>(</p>
                        <p style="margin-left:60px">    <span class="color-green">'h1'</span>,</p>
                        <p style="margin-left:60px">    { <span class="color-blue">className</span>: <span class="color-green">'greeting'</span> },</p>
                        <p style="margin-left:60px">    <span class="color-green">'Hello, world!'</span></p>
                        <p style="margin-left:30px">  );</p>
                        <p>}</p>`
                    }}></div>
                </div>
            </div>
            <div className='mb-4 mt-5'>
                <h5>JSX of Multiple Lines</h5>
                <p>If you want to render multiple lines of HTML using JSX, you have to wrap up all lines under a html element, look at the below example. </p>
                <div className='codePalateBox mt-2 mb-5'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `<p><span class="color-blue">const</span> element = (</p>
                            <p style="margin-left:30px">&lt;div&gt;</p>
                            <p style="margin-left:60px">  &lt;h1&gt;Hello, world!&lt;/h1&gt;</p>
                            <p style="margin-left:60px">  &lt;p&gt;Welcome to the world of JSX&lt;/p&gt;</p>
                            <p style="margin-left:30px">&lt;/div&gt;</p>
                            );
                            `
                    }}></div>
                </div>
                <p>Alternatively, you can use a "fragment" to wrap multiple lines. This will prevent unnecessarily adding extra nodes to the DOM.</p>
                <p>A fragment looks like an empty HTML tag: &lt;&gt;&lt;/&gt;.</p>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `<p><span class="color-blue">const</span> element = (</p>
                            <p style="margin-left:30px">&lt;&gt</p>
                            <p style="margin-left:60px">  &lt;h1&gt;Hello, world!&lt;/h1&gt;</p>
                            <p style="margin-left:60px">  &lt;p&gt;Welcome to the world of JSX&lt;/p&gt;</p>
                            <p style="margin-left:30px">&lt;/&gt;</p>
                            );
                            `
                    }}></div>
                </div>
            </div>
        </section>
    )
}
