import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/html/table-colspan-rowspan";

export default function HTMLTableColRowSpan() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("HTML - Colspan & Rowspan | Aspirant's Home");
        const urls = {
            'previous': '/html/table-colgroup',
            'next': '/html/lists'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>HTML - Colspan & Rowspan</h3>
            <div className='mt-4 mb-5'>
                <p>
                    The <strong>`colspan`</strong> and <strong>`rowspan`</strong> attributes in HTML are used to merge cells across columns and rows in a table, respectively. These attributes are applied to the <strong>`&lt;td&gt;`</strong> (table data) and <strong>`&lt;th&gt;`</strong> (table header) elements.
                </p>
                <h5 className='mt-5 mb-3'>`colspan` Attribute</h5>
                <p>
                    The <strong>`colspan`</strong> attribute is used to make a cell span across multiple columns. This can be useful for creating table headers that cover multiple columns or for merging cells horizontally.
                </p>
                <h6 className='mt-2 mb-2'>Syntax</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>&lt;td <span class="color-pink">colspan</span>=<span class="color-green">"number"</span>&gt;Cell Content&lt;/td&gt;</p>
                        `
                    }}></div>
                </div>
                <p>
                    Where, <strong>`number`</strong> is The number of columns that the cell should span across.
                </p>
                <p>
                    Here’s an example demonstrating the use of the <strong>`colspan`</strong> attribute:
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-grey">&lt;!DOCTYPE</span> <span class="color-blue">html</span><span class="color-grey">&gt;</span></p>
                        <p>&lt;html&gt;</p>
                        <p class="ml-30">    &lt;head&gt;</p>
                        <p class="ml-60">        &lt;style&gt;</p>
                        <p class="ml-90">            table {</p>
                        <p class="ml-120">                <span class="color-green">width</span>: <span class="color-pink">100%</span>;</p>
                        <p class="ml-120">                <span class="color-green">border-collapse</span>: collapse;</p>
                        <p class="ml-90">            }</p>
                        <p class="ml-90">            th, td {</p>
                        <p class="ml-120">                <span class="color-green">border</span>: 1px solid black;</p>
                        <p class="ml-120">                <span class="color-green">padding</span>: 8px;</p>
                        <p class="ml-120">                <span class="color-green">text-align</span>: left;</p>
                        <p class="ml-90">            }</p>
                        <p class="ml-60">        &lt;/style&gt;</p>
                        <p class="ml-30">    &lt;/head&gt;</p>
                        <p class="ml-30">    &lt;body&gt;</p>
                        <p class="ml-60">        &lt;table&gt;</p>
                        <p class="ml-90">            &lt;tr&gt;</p>
                        <p class="ml-120">                &lt;th <span class="color-pink">colspan</span>=<span class="color-green">"2"</span>&gt;Header 1/th&gt;</p>
                        <p class="ml-120">                &lt;th&gt;Header 2&lt;/th&gt;</p>
                        <p class="ml-90">            &lt;/tr&gt;</p>
                        <p class="ml-90">            &lt;tr&gt;</p>
                        <p class="ml-120">                &lt;td&gt;Data 1&lt;/td&gt;</p>
                        <p class="ml-120">                &lt;td&gt;Data 2&lt;/td&gt;</p>
                        <p class="ml-120">                &lt;td&gt;Data 3&lt;/td&gt;</p>
                        <p class="ml-90">            &lt;/tr&gt;</p>
                        <p class="ml-90">            &lt;tr&gt;</p>
                        <p class="ml-120">                &lt;td&gt;Data 4&lt;/td&gt;</p>
                        <p class="ml-120">                &lt;td&gt;Data 5&lt;/td&gt;</p>
                        <p class="ml-120">                &lt;td&gt;Data 6&lt;/td&gt;</p>
                        <p class="ml-90">            &lt;/tr&gt;</p>
                        <p class="ml-60">        &lt;/table&gt;</p>
                        <p class="ml-30">    &lt;/body&gt;</p>
                        <p>&lt;/html&gt;</p>
                        
                        `
                    }}></div>
                </div>
                <p>
                    Output of the following -
                </p>
                <div className='outputPalateBox mb-4' style={{'border':'none'}}>
                    <div className='outputPalate' dangerouslySetInnerHTML={{
                        __html: `
                        <!DOCTYPE html>
                        <html>
                            <head>
                                <style>
                                    table {
                                        width: 100%;
                                        border-collapse: collapse;
                                    }
                                    th, td {
                                        border: 1px solid black;
                                        padding: 8px;
                                        text-align: left;
                                    }
                                </style>
                            </head>
                            <body>
                                <table>
                                    <tr>
                                        <th colspan="2">Header 1</th>
                                        <th>Header 2</th>
                                    </tr>
                                    <tr>
                                        <td>Data 1</td>
                                        <td>Data 2</td>
                                        <td>Data 3</td>
                                    </tr>
                                    <tr>
                                        <td>Data 4</td>
                                        <td>Data 5</td>
                                        <td>Data 6</td>
                                    </tr>
                                </table>
                            </body>
                        </html>
                        
                        `
                    }}></div>
                </div>
                <h5 className='mt-5 mb-3'>`rowspan` Attribute</h5>
                <p>
                    The <strong>`rowspan`</strong> attribute is used to make a cell span across multiple rows. This can be useful for creating table headers that cover multiple rows or for merging cells vertically.
                </p>
                <h6 className='mt-2 mb-2'>Syntax</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>&lt;td <span class="color-pink">rowspan</span>=<span class="color-green">"number"</span>&gt;Cell Content&lt;/td&gt;</p>
                        `
                    }}></div>
                </div>
                <p>
                    Where, <strong>`number`</strong> is The number of rows that the cell should span across.
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-grey">&lt;!DOCTYPE</span> <span class="color-blue">html</span><span class="color-grey">&gt;</span></p>
                        <p>&lt;html&gt;</p>
                        <p class="ml-30">    &lt;head&gt;</p>
                        <p class="ml-60">        &lt;style&gt;</p>
                        <p class="ml-90">            table {</p>
                        <p class="ml-120">                <span class="color-green">width</span>: 100%;</p>
                        <p class="ml-120">                <span class="color-green">border-collapse</span>: collapse;</p>
                        <p class="ml-90">            }</p>
                        <p class="ml-90">            th, td {</p>
                        <p class="ml-120">                <span class="color-green">border</span>: 1px solid black;</p>
                        <p class="ml-120">                <span class="color-green">padding</span>: 8px;</p>
                        <p class="ml-120">                <span class="color-green">text-align</span>: left;</p>
                        <p class="ml-90">            }</p>
                        <p class="ml-60">        &lt;/style&gt;</p>
                        <p class="ml-30">    &lt;/head&gt;</p>
                        <p class="ml-30">    &lt;body&gt;</p>
                        <p class="ml-60">        &lt;table&gt;</p>
                        <p class="ml-90">            &lt;tr&gt;</p>
                        <p class="ml-120">                &lt;th&gt;Header 1&lt;/th&gt;</p>
                        <p class="ml-120">                &lt;th&gt;Header 2&lt;/th&gt;</p>
                        <p class="ml-90">            &lt;/tr&gt;</p>
                        <p class="ml-90">            &lt;tr&gt;</p>
                        <p class="ml-120">                &lt;td <span class="color-pink">rowspan</span>=<span class="color-green">"2"</span>&gt;Data 1&lt;/td&gt;</p>
                        <p class="ml-120">                &lt;td&gt;Data 2&lt;/td&gt;</p>
                        <p class="ml-90">            &lt;/tr&gt;</p>
                        <p class="ml-90">            &lt;tr&gt;</p>
                        <p class="ml-120">                &lt;td&gt;Data 3&lt;/td&gt;</p>
                        <p class="ml-90">            &lt;/tr&gt;</p>
                        <p class="ml-90">            &lt;tr&gt;</p>
                        <p class="ml-120">                &lt;td&gt;Data 4&lt;/td&gt;</p>
                        <p class="ml-120">                &lt;td&gt;Data 5&lt;/td&gt;</p>
                        <p class="ml-90">            &lt;/tr&gt;</p>
                        <p class="ml-60">        &lt;/table&gt;</p>
                        <p class="ml-30">    &lt;/body&gt;</p>
                        <p>&lt;/html&gt;</p>
                        `
                    }}></div>
                </div>
                <p>
                    Output of the following -
                </p>
                <div className='outputPalateBox mb-4' style={{'border':'none'}}>
                    <div className='outputPalate' dangerouslySetInnerHTML={{
                        __html: `
                            <!DOCTYPE html>
                            <html>
                                <head>
                                    <style>
                                        table {
                                            width: 100%;
                                            border-collapse: collapse;
                                        }
                                        th, td {
                                            border: 1px solid black;
                                            padding: 8px;
                                            text-align: left;
                                        }
                                    </style>
                                </head>
                                <body>
                                    <table>
                                        <tr>
                                            <th>Header 1</th>
                                            <th>Header 2</th>
                                        </tr>
                                        <tr>
                                            <td rowspan="2">Data 1</td>
                                            <td>Data 2</td>
                                        </tr>
                                        <tr>
                                            <td>Data 3</td>
                                        </tr>
                                        <tr>
                                            <td>Data 4</td>
                                            <td>Data 5</td>
                                        </tr>
                                    </table>
                                </body>
                            </html>
                        
                        `
                    }}></div>
                </div>
                <p className='mt-4 featureClass'>
                    Using <strong>`colspan`</strong> and <strong>`rowspan`</strong> allows you to create more complex and flexible table layouts, improving the organization and presentation of tabular data.
                </p>
            </div>
        </section>
    )
}