import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/react-js/navigation-link";

export default function NavigationLink() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("React - Navigation Link (NavLink) | Aspirant's Home");
        const urls = {
            'previous': '/react-js/showing-error-page',
            'next':'/react-js/navigate-to-page-dynamically'
        }
        path.setPreviousNext(urls);
    },[])

    return (
        <section className='mt-5 mb-5'>
            <h3>Navigation Link (NavLink)</h3>
            <div className='mt-4 mb-4'>
                <p>In React Router, <strong>`NavLink`</strong> is a component that is used to render navigation links in your application. It is similar to the <strong>`Link`</strong> component, but with added styling capabilities for active links. <strong>`NavLink`</strong> applies an <strong>`activeClassName`</strong> or <strong>`activeStyle`</strong> when the link's URL matches the current URL, allowing you to style active links differently.</p>
                <p>Here's an example of how you can use NavLink in a React application:</p>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-blue">import</span> { <span class="color-red">NavLink</span> } <span class="color-blue">from</span> <span class="color-green">'react-router-dom'</span>;</p>
                            <br />
                            <p><span class="color-blue">function</span> <span class="color-red">RootLayout</span>() {</p>
                            <p style="margin-left:30px">    <span class="color-blue">return</span> (</p>
                            <p style="margin-left:60px"> &lt;nav&gt;</p>
                            <p style="margin-left:90px">     &lt;ul&gt;</p>
                            <p style="margin-left:120px">         &lt;li&gt;</p>
                            <p style="margin-left:150px">            &lt;<span class="color-red">NavLink</span> <span class="color-pink">to</span>=<span class="color-green">"/"</span> <span class="color-pink">exact activeClassName</span>=<span class="color-green">"active"</span>&gt;Home&lt;/<span class="color-red">NavLink</span>&gt;</p>
                            <p style="margin-left:120px">         &lt;/li&gt;</p>
                            <p style="margin-left:120px">         &lt;li&gt;</p>
                            <p style="margin-left:150px">            &lt;<span class="color-red">NavLink</span> <span class="color-pink">to</span>=<span class="color-green">"/about"</span> <span class="color-pink">activeClassName</span>=<span class="color-green">"active"</span>&gt;About&lt;/<span class="color-red">NavLink</span>&gt;</p>
                            <p style="margin-left:120px">         &lt;/li&gt;</p>
                            <p style="margin-left:90px">      &lt;/ul&gt;</p>
                            <p style="margin-left:60px">    &lt;/nav&gt;</p>
                            <p style="margin-left:30px">    );</p>
                            <p>}</p>
                            <br />
                            <p><span class="color-blue">export default</span> <span class="color-red">RootLayout</span>;</p>
                        `
                    }}></div>
                </div> 
                <p>
                    In this example, the <strong>`NavLink`</strong> components are used to render navigation links for the <strong>`Home`</strong> and <strong>`About`</strong> pages. The <strong>`to`</strong> prop specifies the destination URL for each link. The <strong>`exact`</strong> prop is used to ensure that the link is only considered active when the URL exactly matches the to <strong>`prop`</strong>. The <strong>`activeClassName`</strong> prop specifies the CSS class to apply to the link when it is <strong>`active`</strong>, allowing you to style active links differently.
                </p>
            </div>
        </section>
    )
}