import React, { useEffect, useState, useRef } from 'react'
import { Outlet, Link } from "react-router-dom";
import TopHeader from '../TopHeader';
import Footer from '../Footer'
import { MenuContext, TitleContext, PathContext } from "../../Context";
import { useContext } from "react";
import PreviousNext from '../PreviousNext';

export default function ReactJSLayout() {

    const addspaceElement = useRef();
    const menuElement = useRef([]);
    const menu = useContext(MenuContext);
    const pathName = useContext(PathContext);
    const pathname = pathName.pathName;
    const title = useContext(TitleContext);

    const setPathName = (path) => {
        if(path)
        pathName.setPathName(path);
    }

    useEffect(() => {
        setPathName(window.location.pathname)
        if (menu.isMenu == true) menu.setMenuOpen(false)
        addspaceElement.current.scrollIntoView(true)
        const element = document.getElementsByClassName("activeMenu")[0];
        element && element.scrollIntoView({ block: "center" });
        title.setKeyWords("MySQL, MySQL database, MySQL tutorial, MySQL guide, MySQL documentation, MySQL installation, MySQL download, MySQL features, MySQL benefits, MySQL performance, MySQL use cases, MySQL applications, MySQL advantages, MySQL queries, MySQL SQL, MySQL commands, MySQL syntax, MySQL joins, MySQL indexes, MySQL stored procedures, MySQL functions, MySQL triggers, MySQL views, MySQL backup, MySQL restore, MySQL transactions, MySQL normalization");
        title.setPageDescription("Master MySQL with our comprehensive tutorial. Step-by-step instructions, examples, and best practices for installing, configuring, and using MySQL for web development and data management.");

    }, [pathname])


    return (
        <>
            <TopHeader />
            <div className='container-fluid'>
                <div className='row'>
                    <div ref={menuElement} className={menu.isMenu == true ? 'col-md-3 col-lg-2 menuColumnMobile menuColumn p-0' : 'col-md-3 col-lg-2 menuColumn p-0'} style={{ 'overflowY': 'scroll', height: 'calc(100vh - 110px)' }}>
                        <div className='headerMenu'>
                            Mysql Tutorial
                        </div>
                        <nav>
                            <ul>
                                <li className={pathname == '/mysql/introduction' ? 'activeMenu' : ''}>
                                    <Link to="/mysql/introduction" onClick={() => setPathName('/mysql/introduction')}>Introduction</Link>
                                </li>
                                <li className={pathname == '/mysql/features' ? 'activeMenu' : ''}>
                                    <Link to="/mysql/features" onClick={() => setPathName('/mysql/features')}>Features</Link>
                                </li>
                            </ul>
                        </nav>
                        <div className='headerMenu'>
                            Database
                        </div>
                        <nav>
                            <ul>
                                <li className={pathname == '/mysql/create-database' ? 'activeMenu' : ''}>
                                    <Link to="/mysql/create-database" onClick={() => setPathName('/mysql/create-database')}>Create Database</Link>
                                </li>
                                <li className={pathname == '/mysql/drop-database' ? 'activeMenu' : ''}>
                                    <Link to="/mysql/drop-database" onClick={() => setPathName('/mysql/drop-database')}>Drop Database</Link>
                                </li>
                                <li className={pathname == '/mysql/select-database' ? 'activeMenu' : ''}>
                                    <Link to="/mysql/select-database" onClick={() => setPathName('/mysql/select-database')}>Select Database</Link>
                                </li>
                                <li className={pathname == '/mysql/show-database' ? 'activeMenu' : ''}>
                                    <Link to="/mysql/show-database" onClick={() => setPathName('/mysql/show-database')}>Show Database</Link>
                                </li>
                            </ul>
                        </nav>
                        <div className='headerMenu'>
                            Users
                        </div>
                        <nav>
                            <ul>
                                <li className={pathname == '/mysql/create-users' ? 'activeMenu' : ''}>
                                    <Link to="/mysql/create-users" onClick={() => setPathName('/mysql/create-users')}>Create Users</Link>
                                </li>
                                <li className={pathname == '/mysql/drop-users' ? 'activeMenu' : ''}>
                                    <Link to="/mysql/drop-users" onClick={() => setPathName('/mysql/drop-users')}>Drop Users</Link>
                                </li>
                                <li className={pathname == '/mysql/show-users' ? 'activeMenu' : ''}>
                                    <Link to="/mysql/show-users" onClick={() => setPathName('/mysql/show-users')}>Show Users</Link>
                                </li>
                                <li className={pathname == '/mysql/change-password' ? 'activeMenu' : ''}>
                                    <Link to="/mysql/change-password" onClick={() => setPathName('/mysql/change-password')}>Change Password</Link>
                                </li>
                                <li className={pathname == '/mysql/grant-privileges' ? 'activeMenu' : ''}>
                                    <Link to="/mysql/grant-privileges" onClick={() => setPathName('/mysql/grant-privileges')}>Grant Privileges</Link>
                                </li>
                                <li className={pathname == '/mysql/show-privileges' ? 'activeMenu' : ''}>
                                    <Link to="/mysql/show-privileges" onClick={() => setPathName('/mysql/show-privileges')}>Show Privileges</Link>
                                </li>
                                <li className={pathname == '/mysql/revoke-privileges' ? 'activeMenu' : ''}>
                                    <Link to="/mysql/revoke-privileges" onClick={() => setPathName('/mysql/revoke-privileges')}>Revoke Privileges</Link>
                                </li>
                            </ul>
                        </nav>
                        <div className='headerMenu'>
                            Tables
                        </div>
                        <nav>
                            <ul>
                                <li className={pathname == '/mysql/create-tables' ? 'activeMenu' : ''}>
                                    <Link to="/mysql/create-tables" onClick={() => setPathName('/mysql/create-tables')}>Create Tables</Link>
                                </li>
                                <li className={pathname == '/mysql/show-tables' ? 'activeMenu' : ''}>
                                    <Link to="/mysql/show-tables" onClick={() => setPathName('/mysql/show-tables')}>Show Tables</Link>
                                </li>
                                <li className={pathname == '/mysql/alter-tables' ? 'activeMenu' : ''}>
                                    <Link to="/mysql/alter-tables" onClick={() => setPathName('/mysql/alter-tables')}>Alter Tables</Link>
                                </li>
                                <li className={pathname == '/mysql/rename-tables' ? 'activeMenu' : ''}>
                                    <Link to="/mysql/rename-tables" onClick={() => setPathName('/mysql/rename-tables')}>Rename Tables</Link>
                                </li>
                                <li className={pathname == '/mysql/clone-tables' ? 'activeMenu' : ''}>
                                    <Link to="/mysql/clone-tables" onClick={() => setPathName('/mysql/clone-tables')}>Clone Tables</Link>
                                </li>
                                <li className={pathname == '/mysql/truncate-tables' ? 'activeMenu' : ''}>
                                    <Link to="/mysql/truncate-tables" onClick={() => setPathName('/mysql/truncate-tables')}>Truncate Tables</Link>
                                </li>
                                <li className={pathname == '/mysql/repair-tables' ? 'activeMenu' : ''}>
                                    <Link to="/mysql/repair-tables" onClick={() => setPathName('/mysql/repair-tables')}>Repair Tables</Link>
                                </li>
                                <li className={pathname == '/mysql/drop-tables' ? 'activeMenu' : ''}>
                                    <Link to="/mysql/drop-tables" onClick={() => setPathName('/mysql/drop-tables')}>Drop Tables</Link>
                                </li>
                            </ul>
                        </nav>
                        <div className='headerMenu'>
                            Queries
                        </div>
                        <nav>
                            <ul>
                                <li className={pathname == '/mysql/insert-query' ? 'activeMenu' : ''}>
                                    <Link to="/mysql/insert-query" onClick={() => setPathName('/mysql/insert-query')}>Insert Query</Link>
                                </li>
                                <li className={pathname == '/mysql/select-query' ? 'activeMenu' : ''}>
                                    <Link to="/mysql/select-query" onClick={() => setPathName('/mysql/select-query')}>Select Query</Link>
                                </li>
                                <li className={pathname == '/mysql/update-query' ? 'activeMenu' : ''}>
                                    <Link to="/mysql/update-query" onClick={() => setPathName('/mysql/update-query')}>Update Query</Link>
                                </li>
                                <li className={pathname == '/mysql/delete-query' ? 'activeMenu' : ''}>
                                    <Link to="/mysql/delete-query" onClick={() => setPathName('/mysql/delete-query')}>Delete Query</Link>
                                </li>
                                <li className={pathname == '/mysql/replace-query' ? 'activeMenu' : ''}>
                                    <Link to="/mysql/replace-query" onClick={() => setPathName('/mysql/replace-query')}>Replace Query</Link>
                                </li>
                            </ul>
                        </nav>
                        <div className='headerMenu'>
                            Views
                        </div>
                        <nav>
                            <ul>
                                <li className={pathname == '/mysql/create-views' ? 'activeMenu' : ''}>
                                    <Link to="/mysql/create-views" onClick={() => setPathName('/mysql/create-views')}>Create Views</Link>
                                </li>
                                <li className={pathname == '/mysql/update-views' ? 'activeMenu' : ''}>
                                    <Link to="/mysql/update-views" onClick={() => setPathName('/mysql/update-views')}>Update Views</Link>
                                </li>
                                <li className={pathname == '/mysql/drop-views' ? 'activeMenu' : ''}>
                                    <Link to="/mysql/drop-views" onClick={() => setPathName('/mysql/drop-views')}>Drop Views</Link>
                                </li>
                                <li className={pathname == '/mysql/rename-views' ? 'activeMenu' : ''}>
                                    <Link to="/mysql/rename-views" onClick={() => setPathName('/mysql/rename-views')}>Rename Views</Link>
                                </li>
                            </ul>
                        </nav>
                        <div className='headerMenu'>
                            Indexes
                        </div>
                        <nav>
                            <ul>
                                <li className={pathname == '/mysql/create-index' ? 'activeMenu' : ''}>
                                    <Link to="/mysql/create-index" onClick={() => setPathName('/mysql/create-index')}>Create Index</Link>
                                </li>
                                <li className={pathname == '/mysql/drop-index' ? 'activeMenu' : ''}>
                                    <Link to="/mysql/drop-index" onClick={() => setPathName('/mysql/drop-index')}>Drop Index</Link>
                                </li>
                                <li className={pathname == '/mysql/show-indexes' ? 'activeMenu' : ''}>
                                    <Link to="/mysql/show-indexes" onClick={() => setPathName('/mysql/show-indexes')}>Show Indexes</Link>
                                </li>
                                <li className={pathname == '/mysql/unique-index' ? 'activeMenu' : ''}>
                                    <Link to="/mysql/unique-index" onClick={() => setPathName('/mysql/unique-index')}>Unique Index</Link>
                                </li>
                            </ul>
                        </nav>
                        <div className='headerMenu'>
                            Operator & Clause
                        </div>
                        <nav>
                            <ul>
                                <li className={pathname == '/mysql/where-clause' ? 'activeMenu' : ''}>
                                    <Link to="/mysql/where-clause" onClick={() => setPathName('/mysql/where-clause')}>Where Clause</Link>
                                </li>
                                <li className={pathname == '/mysql/limit-clause' ? 'activeMenu' : ''}>
                                    <Link to="/mysql/limit-clause" onClick={() => setPathName('/mysql/limit-clause')}>Limit Clause</Link>
                                </li>
                                <li className={pathname == '/mysql/distinct-clause' ? 'activeMenu' : ''}>
                                    <Link to="/mysql/distinct-clause" onClick={() => setPathName('/mysql/distinct-clause')}>Distinct Clause</Link>
                                </li>
                                <li className={pathname == '/mysql/order-by-clause' ? 'activeMenu' : ''}>
                                    <Link to="/mysql/order-by-clause" onClick={() => setPathName('/mysql/order-by-clause')}>Order By Clause</Link>
                                </li>
                                <li className={pathname == '/mysql/group-by-clause' ? 'activeMenu' : ''}>
                                    <Link to="/mysql/group-by-clause" onClick={() => setPathName('/mysql/group-by-clause')}>Group By Clause</Link>
                                </li>
                                <li className={pathname == '/mysql/having-clause' ? 'activeMenu' : ''}>
                                    <Link to="/mysql/having-clause" onClick={() => setPathName('/mysql/having-clause')}>Having Clause</Link>
                                </li>
                                <li className={pathname == '/mysql/and-operator' ? 'activeMenu' : ''}>
                                    <Link to="/mysql/and-operator" onClick={() => setPathName('/mysql/and-operator')}>AND Operator</Link>
                                </li>
                                <li className={pathname == '/mysql/or-operator' ? 'activeMenu' : ''}>
                                    <Link to="/mysql/or-operator" onClick={() => setPathName('/mysql/or-operator')}>OR Operator</Link>
                                </li>
                                <li className={pathname == '/mysql/like-operator' ? 'activeMenu' : ''}>
                                    <Link to="/mysql/like-operator" onClick={() => setPathName('/mysql/like-operator')}>Like Operator</Link>
                                </li>
                                <li className={pathname == '/mysql/in-operator' ? 'activeMenu' : ''}>
                                    <Link to="/mysql/in-operator" onClick={() => setPathName('/mysql/in-operator')}>In Operator</Link>
                                </li>
                                <li className={pathname == '/mysql/any-operator' ? 'activeMenu' : ''}>
                                    <Link to="/mysql/any-operator" onClick={() => setPathName('/mysql/any-operator')}>Any Operator</Link>
                                </li>
                                <li className={pathname == '/mysql/not-operator' ? 'activeMenu' : ''}>
                                    <Link to="/mysql/not-operator" onClick={() => setPathName('/mysql/not-operator')}>NOT Operator</Link>
                                </li>
                                <li className={pathname == '/mysql/between-operator' ? 'activeMenu' : ''}>
                                    <Link to="/mysql/between-operator" onClick={() => setPathName('/mysql/between-operator')}>BETWEEN Operator</Link>
                                </li>
                                <li className={pathname == '/mysql/union-operator' ? 'activeMenu' : ''}>
                                    <Link to="/mysql/union-operator" onClick={() => setPathName('/mysql/union-operator')}>UNION Operator</Link>
                                </li>
                            </ul>
                        </nav>
                        <div className='headerMenu'>
                            Joins
                        </div>
                        <nav>
                            <ul>
                                <li className={pathname == '/mysql/inner-join' ? 'activeMenu' : ''}>
                                    <Link to="/mysql/inner-join" onClick={() => setPathName('/mysql/inner-join')}>Inner Join</Link>
                                </li>
                                <li className={pathname == '/mysql/left-join' ? 'activeMenu' : ''}>
                                    <Link to="/mysql/left-join" onClick={() => setPathName('/mysql/left-join')}>Left Join</Link>
                                </li>
                                <li className={pathname == '/mysql/right-join' ? 'activeMenu' : ''}>
                                    <Link to="/mysql/right-join" onClick={() => setPathName('/mysql/right-join')}>Right Join</Link>
                                </li>
                                <li className={pathname == '/mysql/cross-join' ? 'activeMenu' : ''}>
                                    <Link to="/mysql/cross-join" onClick={() => setPathName('/mysql/cross-join')}>Cross Join</Link>
                                </li>
                                <li className={pathname == '/mysql/full-join' ? 'activeMenu' : ''}>
                                    <Link to="/mysql/full-join" onClick={() => setPathName('/mysql/full-join')}>Full Join</Link>
                                </li>
                                <li className={pathname == '/mysql/self-join' ? 'activeMenu' : ''}>
                                    <Link to="/mysql/self-join" onClick={() => setPathName('/mysql/self-join')}>Self Join</Link>
                                </li>
                            </ul>
                        </nav>
                        <div className='headerMenu'>
                            Keys
                        </div>
                        <nav>
                            <ul>
                                <li className={pathname == '/mysql/unique-key' ? 'activeMenu' : ''}>
                                    <Link to="/mysql/unique-key" onClick={() => setPathName('/mysql/unique-key')}>Unique Key</Link>
                                </li>
                                <li className={pathname == '/mysql/primary-key' ? 'activeMenu' : ''}>
                                    <Link to="/mysql/primary-key" onClick={() => setPathName('/mysql/primary-key')}>Primary Key</Link>
                                </li>
                                <li className={pathname == '/mysql/foreign-key' ? 'activeMenu' : ''}>
                                    <Link to="/mysql/foreign-key" onClick={() => setPathName('/mysql/foreign-key')}>Foreign Key</Link>
                                </li>
                                <li className={pathname == '/mysql/composite-key' ? 'activeMenu' : ''}>
                                    <Link to="/mysql/composite-key" onClick={() => setPathName('/mysql/unique-key')}>Composite Key</Link>
                                </li>
                            </ul>
                        </nav>
                        <div className='headerMenu'>
                            Triggers
                        </div>
                        <nav>
                            <ul>
                                <li className={pathname == '/mysql/create-trigger' ? 'activeMenu' : ''}>
                                    <Link to="/mysql/create-trigger" onClick={() => setPathName('/mysql/create-trigger')}>Create Trigger</Link>
                                </li>
                                <li className={pathname == '/mysql/drop-trigger' ? 'activeMenu' : ''}>
                                    <Link to="/mysql/drop-trigger" onClick={() => setPathName('/mysql/drop-trigger')}>Drop Trigger</Link>
                                </li>
                            </ul>
                        </nav>
                        <div className='headerMenu'>
                            Functions & Operators
                        </div>
                        <nav>
                            <ul>
                                <li className={pathname == '/mysql/date-and-time-functions' ? 'activeMenu' : ''}>
                                    <Link to="/mysql/date-and-time-functions" onClick={() => setPathName('/mysql/date-and-time-functions')}>Date and Time functions</Link>
                                </li>
                                <li className={pathname == '/mysql/arithmetic-operators' ? 'activeMenu' : ''}>
                                    <Link to="/mysql/arithmetic-operators" onClick={() => setPathName('/mysql/arithmetic-operators')}>Arithmetic Operators</Link>
                                </li>
                                <li className={pathname == '/mysql/numeric-functions' ? 'activeMenu' : ''}>
                                    <Link to="/mysql/numeric-functions" onClick={() => setPathName('/mysql/numeric-functions')}>Numeric Functions</Link>
                                </li>
                                <li className={pathname == '/mysql/string-functions' ? 'activeMenu' : ''}>
                                    <Link to="/mysql/string-functions" onClick={() => setPathName('/mysql/string-functions')}>String Functions</Link>
                                </li>
                                <li className={pathname == '/mysql/aggregate-functions' ? 'activeMenu' : ''}>
                                    <Link to="/mysql/aggregate-functions" onClick={() => setPathName('/mysql/aggregate-functions')}>Aggregate Functions</Link>
                                </li>
                            </ul>
                        </nav>
                        <div className='headerMenu'>
                            Miscellaneous
                        </div>
                        <nav>
                            <ul>
                                <li className={pathname == '/mysql/transaction' ? 'activeMenu' : ''}>
                                    <Link to="/mysql/transaction" onClick={() => setPathName('/mysql/transaction')}>Transaction</Link>
                                </li>
                                <li className={pathname == '/mysql/sequences' ? 'activeMenu' : ''}>
                                    <Link to="/mysql/sequences" onClick={() => setPathName('/mysql/sequences')}>Sequences</Link>
                                </li>
                                <li className={pathname == '/mysql/sub-query' ? 'activeMenu' : ''}>
                                    <Link to="/mysql/sub-query" onClick={() => setPathName('/mysql/sub-query')}>SubQuery</Link>
                                </li>
                                <li className={pathname == '/mysql/cursor' ? 'activeMenu' : ''}>
                                    <Link to="/mysql/cursor" onClick={() => setPathName('/mysql/cursor')}>Cursor</Link>
                                </li>
                                <li className={pathname == '/mysql/stored-procedure' ? 'activeMenu' : ''}>
                                    <Link to="/mysql/stored-procedure" onClick={() => setPathName('/mysql/stored-procedure')}>Stored Procedure</Link>
                                </li>
                                <li className={pathname == '/mysql/stored-functions' ? 'activeMenu' : ''}>
                                    <Link to="/mysql/stored-functions" onClick={() => setPathName('/mysql/stored-functions')}>Stored Functions</Link>
                                </li>
                                <li className={pathname == '/mysql/case-function' ? 'activeMenu' : ''}>
                                    <Link to="/mysql/case-function" onClick={() => setPathName('/mysql/case-function')}>CASE Function</Link>
                                </li>
                            </ul>
                        </nav>
                    </div>
                    <div className='col-12 col-md-9 col-lg-10 rightPart' style={{ 'overflowY': 'scroll', height: 'calc(100vh - 110px)' }}>
                        <div className='row'>
                            <div className='col-12 col-md-12 col-lg-10 col-lg-9 mb-5'>
                                <div className='headerAdSpace' ref={addspaceElement}>
                                    <ins class="adsbygoogle"
                                        style={{ 'display': 'block' }}
                                        data-ad-client="ca-pub-2061924575986153"
                                        data-ad-slot="8656415608"
                                        data-ad-format="auto"
                                        data-full-width-responsive="true"></ins>
                                    <script>
                                        (adsbygoogle = window.adsbygoogle || []).push({ });
                                    </script>
                                </div>
                                <div className='container'>
                                    <div className='contentPage'>
                                        {
                                            pathName.urls ? <PreviousNext urls={ pathName.urls } /> : <></>
                                        }
                                        
                                            <Outlet />
                                        {
                                            pathName.urls ? <PreviousNext urls={ pathName.urls } /> : <></>
                                        }
                                    </div>
                                </div>
                                <div className='footerAdSpace'>
                                    <ins class="adsbygoogle"
                                        style={{ 'display': 'block' }}
                                        data-ad-client="ca-pub-2061924575986153"
                                        data-ad-slot="7856138126"
                                        data-ad-format="auto"
                                        data-full-width-responsive="true"></ins>
                                    <script>
                                        (adsbygoogle = window.adsbygoogle || []).push({ });
                                    </script>
                                </div>
                            </div>
                            <div className='col-lg-3 hiddenInSmall'>
                                <ins class="adsbygoogle"
                                    style={{ 'display': 'block' }}
                                    data-ad-client="ca-pub-2061924575986153"
                                    data-ad-slot="6669380669"
                                    data-ad-format="auto"
                                    data-full-width-responsive="true"></ins>
                                <script>
                                    (adsbygoogle = window.adsbygoogle || []).push({ });
                                </script>
                            </div>
                            <div className='col-12'>
                                <div className='container-fluid footerPart'>
                                    <Footer />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}