import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/react-js/handling-form-submission";

export default function ReactFormHandling() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("React Forms - Handling Form Submission | Aspirant's Home");
        const urls = {
            'previous': '/react-js/forms',
            'next':'/react-js/managing-form-user-input'
        }
        path.setPreviousNext(urls);
    },[])

    return (
        <section className='mt-5 mb-5'>
            <h3>React Forms - Handling Form Submission</h3>
            <div className='mt-4 mb-4'>
                <p>
                    Handling form submission in React involves using the <strong>`onSubmit`</strong> event handler on the form element. When the form is submitted, this event handler is called, allowing you to handle the form data, perform validation, and interact with the server if needed.
                </p> 
                <p>
                    Also, You can handle form submission using other events, such as <strong>`onClick`</strong> or <strong>`onChange`</strong>, and allows you to control the behavior of your form based on the user's input.
                </p>
                <p>
                    Here's a basic example of handling form submission in React:
                </p>
                <div className='codePalateBox mt-2 mb-5'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-blue">import</span> <span class="color-red">React</span>, { <span class="color-red">useState</span> } <span class="color-blue">from</span> <span class="color-green">'react'</span>;</p>
                            <br />
                            <p><span class="color-blue">function</span> <span class="color-red">FormExample</span>() {</p>
                            <p style="margin-left:30px">    <span class="color-blue">const</span> [formData, setFormData] = <span class="color-red">useState</span>({</p>
                            <p style="margin-left:60px">        <span class="color-pink">username</span>: '',</p>
                            <p style="margin-left:60px">        <span class="color-pink">email</span>: ''</p>
                            <p style="margin-left:30px">    });</p>
                            <br />    
                            <p style="margin-left:30px">    <span class="color-blue">const</span> <span class="color-red">handleChange</span> = (event) => {</p>
                            <p style="margin-left:60px">        <span class="color-blue">const</span> { name, value } = event.target;</p>
                            <p style="margin-left:60px">        <span class="color-red">setFormData</span>({ ...formData, [name]: value });</p>
                            <p style="margin-left:30px">    };</p>
                            <br />    
                            <p style="margin-left:30px">    <span class="color-blue">const</span> <span class="color-red">handleSubmit</span> = (event) => {</p>
                            <p style="margin-left:60px">        event.<span class="color-red">preventDefault</span>();</p>
                            <p style="margin-left:60px">        <span class="color-grey">// Handle form submission logic here</span></p>
                            <p style="margin-left:60px">        <span class="color-pink">console</span>.<span class="color-red">log</span>(<span class="color-green">'Form submitted:'</span>, formData);</p>
                            <p style="margin-left:30px">    };</p>
                            <br />
                            <p style="margin-left:30px">    <span class="color-blue">return</span> (</p>
                            <p style="margin-left:60px">        &lt;div&gt;</p>
                            <p style="margin-left:90px">            &lt;h1&gt;React Form Example&lt;/h1&gt;</p>
                            <p style="margin-left:90px">            &lt;form <span class="color-pink">onSubmit</span>=<span class="color-green">{handleSubmit}</span>&gt;</p>
                            <p style="margin-left:120px">                &lt;label&gt;</p>
                            <p style="margin-left:150px">                    Username:</p>
                            <p style="margin-left:150px">                    &lt;input <span class="color-pink">type</span>=<span class="color-green">"text"</span> <span class="color-pink">name</span>=<span class="color-green">"username"</span> <span class="color-pink">value</span>=<span class="color-green">{formData.username}</span> <span class="color-pink">onChange</span>=<span class="color-green">{handleChange}</span> /&gt;</p>
                            <p style="margin-left:120px">                &lt;/label&gt;</p>
                            <p style="margin-left:120px">                &lt;label&gt;</p>
                            <p style="margin-left:150px">                    Email:</p>
                            <p style="margin-left:150px">                    &lt;input <span class="color-pink">type</span>=<span class="color-green">"email"</span> <span class="color-pink">name</span>=<span class="color-green">"email"</span> <span class="color-pink">value</span>=<span class="color-green">{formData.email}</span> <span class="color-pink">onChange</span>=<span class="color-green">{handleChange}</span> /&gt;</p>
                            <p style="margin-left:120px">                &lt;/label&gt;</p>
                            <p style="margin-left:120px">                &lt;button <span class="color-pink">type</span>=<span class="color-green">"submit"</span>&gt;Submit&lt;/button&gt;</p>
                            <p style="margin-left:90px">            &lt;/form&gt;</p>
                            <p style="margin-left:60px">        &lt;/div&gt;</p>
                            <p style="margin-left:30px">    );</p>
                            <p>}</p>
                            <br />
                            <p><span class="color-blue">export default</span> <span class="color-red">FormExample</span>;</p>
                        
                        `
                    }}></div>
                </div>
                <p>
                    In this example:
                </p>
                <ul>
                    <li>
                        The <strong>`handleSubmit`</strong> function is called when the form is submitted. It prevents the default form submission behavior using <strong>`event.preventDefault()`</strong> to prevent the page from reloading.
                    </li>
                    <li>
                        The form data is stored in the <strong>`formData`</strong> state using the <strong>`useState`</strong> hook. The <strong>`handleChange`</strong> function updates the <strong>`formData`</strong> state whenever the user types in the input fields.
                    </li>
                </ul>
            </div>
        </section>
    )   
}