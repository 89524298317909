import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';
export const pageUrl = () => "/mongo-db/aggregation/sort";

export default function MongoAggregationSort() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("$sort Aggregation Pipelines in MongoDB");
        title.setKeyWords("$sort aggregation pipeline in mongodb, mongodb aggregation pipeline sort, mongodb pipeline sort, aggregation pipeline mongodb examples, aggregation pipeline mongodb, mongodb sort aggregation, mongodb aggregation pipeline query");
        title.setPageDescription("The `$sort` stage in MongoDB's aggregation framework is used to sort the documents in the aggregation pipeline. It allows you to order the documents based on the values of specified fields. The sorting can be done in ascending or descending order.");
        path.setPreviousNext({});
    }, [])

    return (
        <>
            <section class='clearfix mb-3'>
                <span className='float-start'>
                    <Link to='/mongo-db/aggregation' onClick={() => path.setPathName('/mongo-db/aggregation')} className='btn btn-primary'> <i class="fa-solid fa-less-than"></i>&nbsp; Back to Aggregation </Link>
                </span>
            </section>
            <section className='mt-5 mb-5'>
                <h3>MongoDB - $sort Pipelines</h3>
                <div className='mt-4 mb-5'>
                    <p>
                        The <strong>`$sort`</strong> stage in MongoDB's aggregation framework is used to sort the documents in the aggregation pipeline. It allows you to order the documents based on the values of specified fields. The sorting can be done in ascending or descending order.
                    </p>
                    <h5>Basic Syntax</h5>
                    <p>
                        The <strong>`$sort`</strong> stage uses a document to specify the fields to sort by and the sort order for each field.
                    </p>
                    <div className='codePalateBox mt-2 mb-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                                <p>{</p>
                                <p style="margin-left:30px">    <span class="color-pink">$sort</span>: {</p>
                                <p style="margin-left:60px">    &lt;field1&gt;: &lt;sortOrder1&gt;,</p>
                                <p style="margin-left:60px">    &lt;field2&gt;: &lt;sortOrder2&gt;,</p>
                                <p style="margin-left:60px">    ...</p>
                                <p style="margin-left:30px">    }</p>
                                <p>}</p>
                            `
                        }}></div>
                    </div>
                    <p>
                        Where,
                    </p>
                    <ul style={{ listStyle: 'disc' }}>
                        <li><strong>`&lt;field&gt;`</strong>: The field by which to sort the documents.</li>
                        <li><strong>`&lt;sortOrder&gt;`</strong>: The order to sort the documents:
                            <ul style={{ listStyle: 'disc' }}>
                                <li><strong>`1`</strong> for ascending order.</li>
                                <li><strong>`-1`</strong> for descending order.</li>
                            </ul>
                        </li>
                    </ul>
                    <h5 className='mt-4 mb-3'>Example Scenario</h5>
                    <p>
                        Consider a <strong>`students`</strong> collection where each document represents a student with fields for <strong>`name`</strong>, <strong>`age`</strong>, and <strong>`grade`</strong>.
                    </p>
                    <div className='codePalateBox mt-2 mb-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                                <p>[</p>
                                <p style="margin-left:30px">    { <span class="color-pink">"_id"</span>: <span class="color-pink">1</span>, <span class="color-pink">"name"</span>: <span class="color-green">"Alice"</span>, <span class="color-pink">"age"</span>: <span class="color-pink">21</span>, <span class="color-pink">"grade"</span>: <span class="color-pink">85</span> },</p>
                                <p style="margin-left:30px">    { <span class="color-pink">"_id"</span>: <span class="color-pink">2</span>, <span class="color-pink">"name"</span>: <span class="color-green">"Bob"</span>, <span class="color-pink">"age"</span>: <span class="color-pink">19</span>, <span class="color-pink">"grade"</span>: <span class="color-pink">90</span> },</p>
                                <p style="margin-left:30px">    { <span class="color-pink">"_id"</span>: <span class="color-pink">3</span>, <span class="color-pink">"name"</span>: <span class="color-green">"Charlie"</span>, <span class="color-pink">"age"</span>: <span class="color-pink">23</span>, <span class="color-pink">"grade"</span>: <span class="color-pink">80</span> },</p>
                                <p>]</p>
                            `
                        }}></div>
                    </div>
                    <h5 className='mt-5 mb-3'>Sorting by a Single Field</h5>
                    <p>
                        To sort the students by their age in ascending order:
                    </p>
                    <div className='codePalateBox mt-2 mb-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                                <p>db.students.<span class="color-red">aggregate</span>([</p>
                                <p style="margin-left:30px">    {</p>
                                <p style="margin-left:60px">    <span class="color-pink">$sort</span>: { <span class="color-pink">age</span>: <span class="color-pink">1</span> }</p>
                                <p style="margin-left:30px">    }</p>
                                <p>])</p>
                            `
                        }}></div>
                    </div>
                    <h6 className='mt-4 mb-4'>Result of the above</h6>
                    <div className='codePalateBox mt-2 mb-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                                <p>[</p>
                                <p style="margin-left:30px">    { <span class="color-pink">"_id"</span>: <span class="color-pink">2</span>, <span class="color-pink">"name"</span>: <span class="color-green">"Bob"</span>, <span class="color-pink">"age"</span>: <span class="color-pink">19</span>, <span class="color-pink">"grade"</span>: <span class="color-pink">90</span> },</p>
                                <p style="margin-left:30px">    { <span class="color-pink">"_id"</span>: <span class="color-pink">1</span>, <span class="color-pink">"name"</span>: <span class="color-green">"Alice"</span>, <span class="color-pink">"age"</span>: <span class="color-pink">21</span>, <span class="color-pink">"grade"</span>: <span class="color-pink">85</span> },</p>
                                <p style="margin-left:30px">    { <span class="color-pink">"_id"</span>: <span class="color-pink">3</span>, <span class="color-pink">"name"</span>: <span class="color-green">"Charlie"</span>, <span class="color-pink">"age"</span>: <span class="color-pink">23</span>, <span class="color-pink">"grade"</span>: <span class="color-pink">80</span> },</p>
                                <p>]</p>
                            `
                        }}></div>
                    </div>
                    <h5 className='mt-5 mb-3'>Sorting by Multiple Fields</h5>
                    <p>
                        To sort the students first by grade in descending order and then by age in ascending order:
                    </p>
                    <div className='codePalateBox mt-2 mb-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                                <p>db.students.<span class="color-red">aggregate</span>([</p>
                                <p style="margin-left:30px">    {</p>
                                <p style="margin-left:60px">    <span class="color-pink">$sort</span>: { <span class="color-pink">grade</span>: <span class="color-pink">-1</span>, <span class="color-pink">age</span>: <span class="color-pink">1</span> }</p>
                                <p style="margin-left:30px">    }</p>
                                <p>])</p>
                            `
                        }}></div>
                    </div>
                    <h6 className='mt-4 mb-4'>Result of the above</h6>
                    <div className='codePalateBox mt-2 mb-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                                <p>[</p>
                                <p style="margin-left:30px">    { <span class="color-pink">"_id"</span>: <span class="color-pink">2</span>, <span class="color-pink">"name"</span>: <span class="color-green">"Bob"</span>, <span class="color-pink">"age"</span>: <span class="color-pink">19</span>, <span class="color-pink">"grade"</span>: <span class="color-pink">90</span> },</p>
                                <p style="margin-left:30px">    { <span class="color-pink">"_id"</span>: <span class="color-pink">1</span>, <span class="color-pink">"name"</span>: <span class="color-green">"Alice"</span>, <span class="color-pink">"age"</span>: <span class="color-pink">21</span>, <span class="color-pink">"grade"</span>: <span class="color-pink">85</span> },</p>
                                <p style="margin-left:30px">    { <span class="color-pink">"_id"</span>: <span class="color-pink">3</span>, <span class="color-pink">"name"</span>: <span class="color-green">"Charlie"</span>, <span class="color-pink">"age"</span>: <span class="color-pink">23</span>, <span class="color-pink">"grade"</span>: <span class="color-pink">80</span> },</p>
                                <p>]</p>
                            `
                        }}></div>
                    </div>
                    <h5 className='mt-5 mb-3'>Sorting by Computed Fields</h5>
                    <p>
                        If you have computed fields in your pipeline and you want to sort by those, you can include the <strong>`$sort`</strong> stage after computing the fields.
                    </p>
                    <div className='codePalateBox mt-2 mb-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                                <p>db.students.<span class="color-red">aggregate</span>([</p>
                                <p style="margin-left:30px">    {</p>
                                <p style="margin-left:60px">    <span class="color-pink">$addFields</span>: { <span class="color-pink">totalScore</span>: { <span class="color-pink">$sum</span>: [<span class="color-green">"$grade"</span>, <span class="color-pink">10</span>] } }</p>
                                <p style="margin-left:30px">    },</p>
                                <p style="margin-left:30px">    {</p>
                                <p style="margin-left:60px">    <span class="color-pink">$sort</span>: { <span class="color-pink">totalScore</span>: <span class="color-pink">-1</span> }</p>
                                <p style="margin-left:30px">    }</p>
                                <p>])</p>
                            `
                        }}></div>
                    </div>
                    <h6 className='mt-4 mb-4'>Result of the above</h6>
                    <div className='codePalateBox mt-2 mb-4'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                                <p>[</p>
                                <p style="margin-left:30px">    { <span class="color-pink">"_id"</span>: <span class="color-pink">2</span>, <span class="color-pink">"name"</span>: <span class="color-green">"Bob"</span>, <span class="color-pink">"age"</span>: <span class="color-pink">19</span>, <span class="color-pink">"grade"</span>: <span class="color-pink">90</span> },</p>
                                <p style="margin-left:30px">    { <span class="color-pink">"_id"</span>: <span class="color-pink">1</span>, <span class="color-pink">"name"</span>: <span class="color-green">"Alice"</span>, <span class="color-pink">"age"</span>: <span class="color-pink">21</span>, <span class="color-pink">"grade"</span>: <span class="color-pink">85</span> },</p>
                                <p style="margin-left:30px">    { <span class="color-pink">"_id"</span>: <span class="color-pink">3</span>, <span class="color-pink">"name"</span>: <span class="color-green">"Charlie"</span>, <span class="color-pink">"age"</span>: <span class="color-pink">23</span>, <span class="color-pink">"grade"</span>: <span class="color-pink">80</span> },</p>
                                <p>]</p>
                            `
                        }}></div>
                    </div>
                    <p>
                        The <strong>`$sort`</strong> stage in MongoDB's aggregation framework is a powerful tool for ordering documents in the pipeline. It allows sorting by one or multiple fields, in ascending or descending order, and can also be used to sort by computed fields. By understanding and leveraging the <strong>`$sort`</strong> stage, you can efficiently organize your query results to meet your specific application requirements.
                    </p>
                    <div className='mt-5 mb-4 featureClass'>
                        <h5 className='mb-4'>Important Note</h5>
                        <ul style={{ listStyle: 'disc' }}>
                            <li><strong>Single Field Sorting</strong>: Specify the field and the sort order (1 for ascending, -1 for descending).</li>
                            <li><strong>Multiple Fields Sorting</strong>: Specify multiple fields with their respective sort orders. The documents are sorted by the first field, and then by the second field if there are ties, and so on.</li>
                            <li><strong>Computed Fields Sorting</strong>: If sorting by computed fields, include the computation stage (e.g., <strong>`$addFields`</strong>) before the <strong>`$sort`</strong> stage.</li>
                        </ul>
                    </div>
                </div>
            </section>
        </>
    )
}