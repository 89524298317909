import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/html/elements";

export default function HTMLElements() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("HTML - Elements | Aspirant's Home");
        const urls = {
            'previous': '/html/basic-tags',
            'next': '/html/attributes'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>HTML - Elements</h3>
            <div className='mt-4 mb-5'>
                <p>
                    HTML elements are the building blocks of HTML documents. They define the structure and content of a webpage, allowing browsers to render text, images, links, forms, and other types of content. Each element is defined by a pair of tags: an opening tag and a closing tag, with content in between.
                </p>
                <h5 className='mt-5 mb-3'>Basic Structure of HTML Elements</h5>
                <p>
                    An HTML element typically consists of:
                </p>
                <ul style={{listStyle:'decimal'}}>
                    <li>
                        <strong>Opening Tag</strong>: This indicates the start of an element. It is enclosed in angle brackets and may contain attributes.
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p>&lt;tagname <span class="color-pink">attribute</span>=<span class="color-green">"value"</span>&gt;</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <strong>Content</strong>: This is the information or data that the element holds, such as text or other HTML elements.
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p>Content goes here</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <strong>Closing Tag</strong>: This indicates the end of an element. It is also enclosed in angle brackets but includes a forward slash before the tag name.
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p>&lt;/tagname&gt;</p>
                                `
                            }}></div>
                        </div>
                    </li>
                </ul>
                <h5 className='mt-5 mb-3'>Example of an HTML Element</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>&lt;p&gt;This is a paragraph.&lt;/p&gt;</p>
                        `
                    }}></div>
                </div>
                <p>
                    Where,
                </p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><strong>`&lt;p&gt;`</strong> is the opening tag.</li>
                    <li><strong>`This is a paragraph.`</strong> is the content.</li>
                    <li><strong>`&lt;/p&gt;`</strong> is the closing tag.</li>
                </ul>
                <h5 className='mt-5 mb-3'>Types of HTML Elements</h5>
                <ul style={{ listStyle: 'decimal' }}>
                    <li>
                        <strong>Block-Level Elements</strong>: These elements typically start on a new line and take up the full width available. Examples include:
                        <ul style={{ listStyle: 'disc' }}>
                            <li><strong>Headings</strong>: <strong>`&lt;h1&gt;`</strong> to <strong>`&lt;h6&gt;`</strong></li>
                            <li><strong>Paragraph</strong>: <strong>`&lt;p&gt;`</strong></li>
                            <li><strong>Div</strong>: <strong>`&lt;div&gt;`</strong></li>
                            <li><strong>Section</strong>: <strong>`&lt;section&gt;`</strong></li>
                            <li><strong>Article</strong>: <strong>`&lt;article&gt;`</strong></li>
                            <li><strong>Nav</strong>: <strong>`&lt;nav&gt;`</strong></li>
                            <li><strong>Footer</strong>: <strong>`&lt;footer&gt;`</strong></li>
                        </ul>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p>&lt;h1&gt;This is a heading&lt;/h1&gt;</p>
                                    <p>&lt;p&gt;This is a paragraph.&lt;/p&gt;</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <strong>Inline Elements</strong>: These elements do not start on a new line and only take up as much width as necessary. Examples include:
                        <ul style={{ listStyle: 'disc' }}>
                            <li><strong>Span</strong>: <strong>`&lt;span&gt;`</strong></li>
                            <li><strong>Anchor</strong>: <strong>`&lt;a&gt;`</strong></li>
                            <li><strong>Image</strong>: <strong>`&lt;img&gt;`</strong></li>
                            <li><strong>Strong</strong>: <strong>`&lt;strong&gt;`</strong></li>
                            <li><strong>Em</strong>: <strong>`&lt;em&gt;`</strong></li>
                        </ul>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p>&lt;p&gt;This is a &lt;strong&gt;bold&lt;/strong&gt; word in a paragraph.&lt;/p&gt;</p>
                                `
                            }}></div>
                        </div>
                    </li>
                    <li>
                        <strong>Empty Elements</strong>: These elements do not have content and are self-closing. Examples include:
                        <ul style={{ listStyle: 'disc' }}>
                            <li><strong>Image</strong>: <strong>`&lt;img src="image.jpg" alt="Description"&gt;`</strong></li>
                            <li><strong>Break</strong>: <strong>`&lt;br&gt;`</strong></li>
                            <li><strong>Meta</strong>: <strong>`&lt;meta charset="UTF-8"&gt;`</strong></li>
                            <li><strong>Link</strong>: <strong>`&lt;link rel="stylesheet" href="styles.css"&gt;`</strong></li>
                            <li><strong>Input</strong>: <strong>`&lt;input type="text" name="username"&gt;`</strong></li>
                        </ul>
                        <div className='codePalateBox mt-2 mb-4'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p>&lt;img <span class="color-pink">src</span>=<span class="color-green">"image.jpg"</span> <span class="color-pink">alt</span>=<span class="color-green">"Description"</span>&gt;</p>
                                `
                            }}></div>
                        </div>
                    </li>
                </ul>
                <h5 className='mt-5 mb-3'>Nesting HTML Elements</h5>
                <p>
                    HTML elements can be nested inside other elements to create a hierarchical structure. Proper nesting is important for ensuring that the document is well-formed and displays correctly.
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>&lt;div&gt;</p>
                            <p class="ml-30">    &lt;h1&gt;This is a heading&lt;/h1&gt;</p>
                            <p class="ml-30">    &lt;p&gt;This is a paragraph with an &lt;a <span class="color-pink">href</span>=<span class="color-green">"https://example.com"</span>>inline link&lt;/a&gt;.&lt;/p&gt;</p>
                            <p>&lt;/div&gt;</p>
                        `
                    }}></div>
                </div>
            </div>
        </section>
    )
}