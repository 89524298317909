import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/node-js/built-in-modules";

export default function BuiltInModules() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Node Js - Built-in Modules | Aspirant's Home");
        const urls = {
            'previous': '/node-js/mongodb-join-document'
        }
        path.setPreviousNext(urls);
    }, [])
    
    return (
        <section className='mt-5 mb-5'>
            <h3>Node Js Built In Modules</h3>
            <div className='mt-4'>
                <p>Node.js has a set of built-in modules which you can use without any further installation. Below are the some built-in modules of Node.</p>
                <ul style={{ 'listStyle': 'disc' }}>
                    <li className='mt-4'>
                        <a className='linkPage' href="/node-js/built-in-modules/assert"><strong>Assert Module</strong></a>
                        <p>Provides a set of assertion functions that can be used for writing tests or checking assumptions within your code. The assert module in Node.js provides a means of testing expressions. If the expression evaluates to 0 or false, it triggers an assertion failure, which terminates the program.</p>
                        <p>Originally designed for internal use within Node.js, this module has since become a widely used tool for writing tests and verifying assumptions in JavaScript applications.</p>
                        <p>The syntax for including the assert module in your application: </p>
                        <div className='codePalateBox mt-2 mb-2'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `<p><span class='color-blue'>var</span> assert = <span class='color-yellow'>require</span>(<span class='color-green'>'assert'</span>);</p>
                                `
                            }}></div>
                        </div>
                        <p>We have discussed in details about these module's function in <a href='/node-js/built-in-modules/assert'>this</a> page.</p>
                    </li>
                    <li className='mt-4'>
                        <a className='linkPage' href="/node-js/built-in-modules/buffer"><strong>Buffer Module</strong></a>
                        <p>The Buffer module in Node.js provides a way to handle binary data. It is a global module, so you do not need to use <strong>require('buffer')</strong> to access it. The Buffer class is used to create buffer instances, which are fixed-size chunks of memory.</p>
                        <p>We have discussed in details about these module's function in <a href='/node-js/built-in-modules/buffer'>this</a> page.</p>
                    </li>
                    <li className='mt-4'>
                        <a className='linkPage' href="/node-js/built-in-modules/os"><strong>Os Module</strong></a>
                        <p>The os module in Node.js provides a way to access information about the operating system on which the Node.js process is running. It allows you to retrieve information such as the operating system's hostname, architecture, and platform, as well as information about the system's memory, CPU, and network interfaces.</p>
                        <p>The syntax for including the os module in your application: </p>
                        <div className='codePalateBox mt-2 mb-2'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `<p><span class='color-blue'>var</span> os = <span class='color-yellow'>require</span>(<span class='color-green'>'os'</span>);</p>
                                `
                            }}></div>
                        </div>
                        <p>We have discussed in details about these module's function in <a href='/node-js/built-in-modules/os'>this</a> page.</p>
                    </li>
                    <li className='mt-4'>
                        <a className='linkPage' href="/node-js/built-in-modules/cluster"><strong>Cluster Module</strong></a>
                        <p>The cluster module is used to create child processes (workers) that share server ports to handle incoming requests in a more efficient manner. This module allows you to take advantage of multi-core systems by spawning a process for each core, thus distributing the load evenly.</p>
                        <p>The syntax for including the cluster module in your application: </p>
                        <div className='codePalateBox mt-2 mb-2'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `<p><span class='color-blue'>var</span> cluster = <span class='color-yellow'>require</span>(<span class='color-green'>'cluster'</span>);</p>
                                `
                            }}></div>
                        </div>
                        <p>We have discussed in details about these module's function in <a href='/node-js/built-in-modules/cluster'>this</a> page.</p>
                    </li>
                    <li className='mt-4'>
                        <a className='linkPage' href="/node-js/built-in-modules/crypto"><strong>Crypto Module</strong></a>
                        <p>The crypto module provides cryptographic functionality including encryption, decryption, hashing, and more. It offers a way to perform secure communication, generate secure random numbers, and create cryptographic signatures.</p>
                        <p>The syntax for including the crypto module in your application: </p>
                        <div className='codePalateBox mt-2 mb-2'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `<p><span class='color-blue'>var</span> crypto = <span class='color-yellow'>require</span>(<span class='color-green'>'crypto'</span>);</p>
                                `
                            }}></div>
                        </div>
                        <p>We have discussed in details about these module's function in <a href='/node-js/built-in-modules/crypto'>this</a> page.</p>
                    </li>
                    <li className='mt-4'>
                        <a className='linkPage' href="/node-js/built-in-modules/events"><strong>Events Module</strong></a>
                        <p>The events module provides a way to handle and emit events. It is a core module that allows objects to emit and listen for events. This module is the foundation of many other Node.js modules and is used extensively in building event-driven applications.</p>
                        <p>The syntax for including the events module in your application: </p>
                        <div className='codePalateBox mt-2 mb-2'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `<p><span class='color-blue'>var</span> events = <span class='color-yellow'>require</span>(<span class='color-green'>'events'</span>);</p>
                                `
                            }}></div>
                        </div>
                        <p>We have discussed in details about these module's function in <a href='/node-js/built-in-modules/events'>this</a> page.</p>
                    </li>
                    <li className='mt-4'>
                        <a className='linkPage' href="/node-js/built-in-modules/fs"><strong>Fs Module</strong></a>
                        <p>The fs (file system) module provides an API for interacting with the file system. It allows you to perform various operations such as reading from and writing to files, creating and deleting files and directories, and more.</p>
                        <p>The syntax for including the fs module in your application: </p>
                        <div className='codePalateBox mt-2 mb-2'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `<p><span class='color-blue'>var</span> fs = <span class='color-yellow'>require</span>(<span class='color-green'>'fs'</span>);</p>
                                `
                            }}></div>
                        </div>
                        <p>We have discussed in details about these module's function in <a href='/node-js/built-in-modules/fs'>this</a> page.</p>
                    </li>
                    <li className='mt-4'>
                        <a className='linkPage' href="/node-js/built-in-modules/http"><strong>Http & Https Module</strong></a>
                        <p>The http and https modules are used to create HTTP and HTTPS servers, respectively, and make HTTP and HTTPS requests. Both modules provide classes such as http.Server and http.ClientRequest (or https.Server and https.ClientRequest for HTTPS) to handle server and client operations.</p>
                        <p>The syntax for including the http and https module in your application: </p>
                        <div className='codePalateBox mt-2 mb-2'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `<p><span class='color-blue'>var</span> http = <span class='color-yellow'>require</span>(<span class='color-green'>'http'</span>);</p>
                                <p><span class='color-blue'>var</span> https = <span class='color-yellow'>require</span>(<span class='color-green'>'https'</span>);</p>
                                `
                            }}></div>
                        </div>
                        <p>We have discussed in details about these module's function in <a href='/node-js/built-in-modules/http'>this</a> page.</p>
                    </li>
                    <li className='mt-4'>
                        <a className='linkPage' href="/node-js/built-in-modules/path"><strong>Path Module</strong></a>
                        <p>The path module provides utilities for working with file and directory paths. It helps in constructing and manipulating file paths in a way that is consistent across different operating systems.</p>
                        <p>The syntax for including the path module in your application: </p>
                        <div className='codePalateBox mt-2 mb-2'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `<p><span class='color-blue'>var</span> path = <span class='color-yellow'>require</span>(<span class='color-green'>'path'</span>);</p>
                                `
                            }}></div>
                        </div>
                        <p>We have discussed in details about these module's function in <a href='/node-js/built-in-modules/path'>this</a> page.</p>
                    </li>
                    <li className='mt-4'>
                        <a className='linkPage' href="/node-js/built-in-modules/querystring"><strong>Query String Module</strong></a>
                        <p>The querystring module provides utilities for parsing and formatting URL query strings. It can be used to work with query parameters in URLs.</p>
                        <p>The syntax for including the querystring module in your application: </p>
                        <div className='codePalateBox mt-2 mb-2'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `<p><span class='color-blue'>var</span> querystring = <span class='color-yellow'>require</span>(<span class='color-green'>'querystring'</span>);</p>
                                `
                            }}></div>
                        </div>
                        <p>We have discussed in details about these module's function in <a href='/node-js/built-in-modules/querystring'>this</a> page.</p>
                    </li>
                    <li className='mt-4'>
                        <a className='linkPage' href="/node-js/built-in-modules/url"><strong>Url Module</strong></a>
                        <p>The url module provides utilities for URL resolution and parsing. It can be used to manipulate URLs and extract various components such as the protocol, hostname, path, query parameters, etc.</p>
                        <p>The syntax for including the url module in your application: </p>
                        <div className='codePalateBox mt-2 mb-2'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `<p><span class='color-blue'>var</span> url = <span class='color-yellow'>require</span>(<span class='color-green'>'url'</span>);</p>
                                `
                            }}></div>
                        </div>
                        <p>We have discussed in details about these module's function in <a href='/node-js/built-in-modules/url'>this</a> page.</p>
                    </li>
                    <li className='mt-4'>
                        <a className='linkPage' href="/node-js/built-in-modules/util"><strong>Util Module</strong></a>
                        <p>The util module provides a set of utility functions that are helpful for developers. These functions cover a wide range of use cases, including debugging, error handling, object inspection, and more.</p>
                        <p>The syntax for including the util module in your application: </p>
                        <div className='codePalateBox mt-2 mb-2'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `<p><span class='color-blue'>var</span> util = <span class='color-yellow'>require</span>(<span class='color-green'>'util'</span>);</p>
                                `
                            }}></div>
                        </div>
                        <p>We have discussed in details about these module's function in <a href='/node-js/built-in-modules/util'>this</a> page.</p>
                    </li>
                    <li className='mt-4'>
                        <a className='linkPage' href="/node-js/built-in-modules/zlib"><strong>Zlib Module</strong></a>
                        <p>The zlib module provides compression and decompression functionalities through the use of zlib. It supports gzip, deflate, and raw deflate compression formats. The module can be used to compress and decompress buffers, streams, or files.</p>
                        <p>The syntax for including the zlib module in your application: </p>
                        <div className='codePalateBox mt-2 mb-2'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `<p><span class='color-blue'>var</span> zlib = <span class='color-yellow'>require</span>(<span class='color-green'>'zlib'</span>);</p>
                                `
                            }}></div>
                        </div>
                        <p>We have discussed in details about these module's function in <a href='/node-js/built-in-modules/zlib'>this</a> page.</p>
                    </li>
                </ul>
            </div>
        </section>
    )
}
