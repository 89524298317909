import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/mysql/drop-trigger";

export default function MysqlDropTrigger() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Mysql - Drop Trigger | Aspirant's Home");
        const urls = {
            'previous': '/mysql/create-trigger',
            'next': '/mysql/date-and-time-functions'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>Mysql - Drop Trigger</h3>
            <div className='mt-4 mb-5'>
                <p>
                    To drop a trigger in MySQL, you can use the <strong>`DROP TRIGGER`</strong> statement followed by the name of the trigger you want to drop. Here's the basic syntax:
                </p>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-blue">DROP TRIGGER</span> [IF <span class="color-blue">EXISTS</span>] trigger_name;</p>
                        `
                    }}></div>
                </div>
                <p>
                    Where, <strong>`IF EXISTS`</strong> is optional and allows you to avoid an error if the trigger does not exist. <strong>`trigger_name`</strong> is the name of the trigger you want to drop.
                </p>
                <p>
                    For example, to drop a trigger named <strong>`my_trigger`</strong>:
                </p>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-blue">DROP TRIGGER</span> [IF <span class="color-blue">EXISTS</span>] my_trigger;</p>
                        `
                    }}></div>
                </div>
                <p>
                    This statement will remove the <strong>`my_trigger`</strong> trigger from the database.
                </p>
            </div>
        </section>
    )
}