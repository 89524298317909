import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/css/font/web/safe";

export default function WebSafeFonts() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("What is Web Safe Fonts in CSS? | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/css/font/family',
            'next': '/css/font/fallbacks'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>Web Safe Fonts</h3>
            <div className='mt-4 mb-5'>
                <p>
                    <strong>Web safe fonts</strong> are fonts that are commonly installed across most operating systems and devices, ensuring consistent display of text on web pages. When you use web safe fonts in your CSS, you can be more confident that the text will appear as intended for the vast majority of users, regardless of the platform they are using.
                </p>
                <h5 className="mt-5 mb-3">Some Key Features of Web Safe Fonts</h5>
                <ul style={{ listStyle: 'disc' }}>
                    <li>
                        <strong>Cross-Platform Availability</strong>: Web safe fonts are pre-installed on Windows, macOS, Linux, iOS, Android, and other operating systems, making them universally accessible.
                    </li>
                    <li>
                        <strong>Consistent Display</strong>: Since these fonts are commonly available, the text will look the same (or very similar) across different browsers and devices, providing a consistent user experience.
                    </li>
                    <li>
                        <strong>Fallback Options</strong>: If a web safe font is not available, CSS allows you to specify fallback fonts to ensure text is still displayed appropriately.
                    </li>
                </ul>
                <h5 className="mt-5 mb-3">Common Web Safe Fonts</h5>
                <ul style={{ listStyle: 'decimal' }}>
                    <li>
                        <strong>Serif Fonts:</strong>
                        <ul style={{ listStyle: 'disc' }}>
                            <li>Times New Roman</li>
                            <li>Georgia</li>
                            <li>Garamond</li>
                        </ul>
                    </li>
                    <li>
                        <strong>Sans-Serif Fonts:</strong>
                        <ul style={{ listStyle: 'disc' }}>
                            <li>Arial</li>
                            <li>Helvetica</li>
                            <li>Verdana</li>
                            <li>Tahoma</li>
                        </ul>
                    </li>
                    <li>
                        <strong>Monospace Fonts:</strong>
                        <ul style={{ listStyle: 'disc' }}>
                            <li>Courier New</li>
                            <li>Lucida Console</li>
                            <li>Monaco</li>
                        </ul>
                    </li>
                    <li>
                        <strong>Cursive Fonts:</strong>
                        <ul style={{ listStyle: 'disc' }}>
                            <li>Comic Sans MS</li>
                            <li>Brush Script MT</li>
                        </ul>
                    </li>
                    <li>
                        <strong>Fantasy Fonts:</strong>
                        <ul style={{ listStyle: 'disc' }}>
                            <li>Impact</li>
                            <li>Papyrus</li>
                        </ul>
                    </li>
                </ul>

                <h5 className="mt-5 mb-3">Examples of Web Safe Fonts</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>body {</p>
                            <p class="ml-30">    <span class="color-green">font-family</span>: Arial, Helvetica, sans-serif;</p>
                            <p>}</p>
                            <br />
                            <p>h1 {</p>
                            <p class="ml-30">    <span class="color-green">font-family</span>: <span class="color-green">"Times New Roman"</span>, Times, serif;</p>
                            <p>}</p>
                            <br />
                            <p>code {</p>
                            <p class="ml-30">    <span class="color-green">font-family</span>: <span class="color-green">"Courier New"</span>, Courier, monospace;</p>
                            <p>}</p>
                        `
                    }}></div>
                </div>

                <h5 className="mt-5 mb-3">Benifits of Web Safe Fonts</h5>
                <ul style={{ listStyle: 'disc' }}>
                    <li><strong>Reliability</strong>: Ensures that your website’s typography is displayed correctly across various devices and browsers.</li>
                    <li><strong>Performance</strong>: Web safe fonts load faster since they are already installed on the user's device, unlike custom fonts which may require additional loading time.</li>
                    <li><strong>Fallback Strategy</strong>: Even if the primary font isn't available, you can specify alternative fonts to maintain a consistent appearance.</li>
                </ul>
            </div>
        </section>
    )
}