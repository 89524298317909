import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/html/create-bookmark";

export default function HTMLCreateBookmark() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("HTML - Create Bookmark | Aspirant's Home");
        const urls = {
            'previous': '/html/customize-links',
            'next': '/html/forms'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>HTML - Create Bookmark</h3>
            <div className='mt-4 mb-5'>
                <p>
                    Creating a bookmark in HTML allows users to jump to specific sections of a web page. This is achieved using anchor links with the id attribute and the <strong>`&lt;a&gt;`</strong> tag.
                </p>
                <h5 className='mt-5 mb-3'>Basic Structure</h5>
                <ul style={{ listStyle: 'decimal' }}>
                    <li><strong>Define a Bookmark</strong>: Use the <strong>`id`</strong> attribute to create a bookmark within the page.</li>
                    <li><strong>Create a Link to the Bookmark</strong>: Use the <strong>`&lt;a&gt;`</strong> tag with the <strong>`href`</strong> attribute pointing to the <strong>`id`</strong> of the bookmark.</li>
                </ul>
                <h5 className='mt-5 mb-3'>Example</h5>
                <p>
                    Here’s an example of how to create a bookmark in HTML:
                </p>
                <h6>Step 1: Define a Bookmark</h6>
                <p>
                    First, add an <strong>`id`</strong> attribute to the element where you want to create the bookmark.
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>&lt;h2 <span class="color-pink">id</span>=<span class="color-green">"section1"</span>&gt;Section 1&lt;/h2&gt;</p>
                            <p>&lt;p&gt;This is Section 1.&lt;/p&gt;</p>
                        `
                    }}></div>
                </div>
                <h6>Step 2: Create a Link to the Bookmark</h6>
                <p>
                    Next, create a link that points to the bookmark using the <strong>`href`</strong> attribute with a hash (<strong>`#`</strong>) followed by the <strong>`id`</strong> value.
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>&lt;a <span class="color-pink">href</span>=<span class="color-green">"#section1"</span>&gt;Go to Section 1&lt;/a&gt;</p>
                        `
                    }}></div>
                </div>

                <h5 className='mt-5 mb-3'>Complete Example</h5>
                <p>
                    Combining the two steps, here’s a complete example:
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-grey">&lt;!DOCTYPE</span> <span class="color-blue">html</span><span class="color-grey">&gt;</span></p>
                        <p>&lt;html&gt;</p>
                        <p class="ml-30">    &lt;head&gt;</p>
                        <p class="ml-60">        &lt;title&gt;HTML Bookmark Example&lt;/title&gt;</p>
                        <p class="ml-30">    &lt;/head&gt;</p>
                        <p class="ml-30">    &lt;body&gt;</p>
                        <p class="ml-60">        <span class="color-grey">&lt;!-- Link to the bookmark --&gt;</span></p>
                        <p class="ml-60">        &lt;a <span class="color-pink">href</span>=<span class="color-green">"#section1"</span>&gt;Go to Section 1&lt;/a&gt;</p>
                        <p class="ml-60">        &lt;a <span class="color-pink">href</span>=<span class="color-green">"#section2"</span>&gt;Go to Section 2&lt;/a&gt;</p>
                        <br />        
                        <p class="ml-60">        <span class="color-grey">&lt;!-- Content --&gt;</span></p>
                        <p class="ml-60">        &lt;h2 <span class="color-pink">id</span>=<span class="color-green">"section1"</span>&gt;Section 1&lt;/h2&gt;</p>
                        <p class="ml-60">        &lt;p&gt;This is Section 1.&lt;/p&gt;</p>
                        <br />        
                        <p class="ml-60">        &lt;h2 <span class="color-pink">id</span>=<span class="color-green">"section2"</span>&gt;Section 2&lt;/h2&gt;</p>
                        <p class="ml-60">        &lt;p&gt;This is Section 2.&lt;/p&gt;</p>
                        <p class="ml-30">    &lt;/body&gt;</p>
                        <p>&lt;/html&gt;</p>
                        `
                    }}></div>
                </div>
                <p>
                    Output of the following -
                </p>
                <div className='outputPalateBox mb-4'>
                    <div className='outputPalate' dangerouslySetInnerHTML={{
                        __html: `
                        <!DOCTYPE html>
                        <html>
                            <head>
                                <title>HTML Bookmark Example</title>
                            </head>
                            <body>
                                <!-- Link to the bookmark -->
                                <a href="#section1">Go to Section 1</a>
                                <a href="#section2">Go to Section 2</a>
                                
                                <!-- Content -->
                                <h2 id="section1">Section 1</h2>
                                <p>This is Section 1.</p>
                                
                                <h2 id="section2">Section 2</h2>
                                <p>This is Section 2.</p>
                            </body>
                        </html>
                        `
                    }}></div>
                </div>
            </div>
        </section>
    )
}