import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/react-js/render-html";

export default function RenderHTML() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("React Js - Render HTML | Aspirant's Home");
        const urls = {
            'previous': '/react-js/folder-architecture',
            'next':'/react-js/jsx'
        }
        path.setPreviousNext(urls);
    },[])

    return (
        <section className='mt-5 mb-5'>
            <h3>React Js - Render HTML</h3>
            <div className='mt-4'>
                <p>There are many ways to render you can render your html to your react project.</p>
                <p>React renders HTML to the web page by using a function called createRoot() and its method render().</p>
                <div className='mt-3'>
                    <img className='img-fluid' src='../assets/react/render-html-1.png' />
                </div>
            </div>
            <div className='mt-4 mb-5'>
                <ul style={{ listStyle: 'disc' }}>
                    <li><strong>`createRoot()`</strong>: This function is to define the HTML element where a React component should be displayed.</li>
                    <li><strong>`render()`</strong>: This function is to define what a React component should be displayed.</li>
                    <li><strong>`&lt;App /&gt;`</strong>: This is the App component, that is being displayed.</li>
                </ul>
            </div>
            <div className='mt-4 mb-5'>
                <p>You can also directly render your component like the below way.</p>
                <div className='mt-3'>
                    <img className='img-fluid' src='../assets/react/render-html-2.png' />
                </div>
                <div className='mt-4'>
                    <p>The <strong>`&lt;App / &gt;`</strong> component is rendered to a DOM element with the id root. The <strong>`ReactDOM.render()`</strong> method takes two arguments: the React element or component to render, and the DOM element to render it into.</p>
                </div>
            </div>
        </section>
    )
}
