import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';
export const pageUrl = () => "/html/basic-tags/html";

export default function HTMLBasicHTMLTags() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("HTML - <html> Basic Tags | Aspirant's Home");
        path.setPreviousNext({});
    }, [])

    return (
        <>
            <section class='clearfix mb-3'>
                <span className='float-start'>
                    <Link to='/html/basic-tags' onClick={() => path.setPathName('/html/basic-tags')} className='btn btn-primary'> <i class="fa-solid fa-less-than"></i>&nbsp; Back to Basic Tags </Link>
                </span>
            </section>
            <section className='mt-5 mb-5'>
                <h3>HTML - &lt;html&gt; Basic tags</h3>
                <div className='mt-4 mb-5'>
                    <p>
                        The &lt;html&gt; tag is a fundamental part of an HTML document. It serves as the root element that encapsulates all the content and elements within the HTML page. Here’s an in-depth explanation of the &lt;html&gt; tag:
                    </p>
                    <h5 className='mt-5 mb-3'>Purpose of the `&lt;html&gt;` Tag</h5>
                    <ul style={{listStyle:'decimal'}}>
                        <li>
                            <strong>Root Element:</strong> The <strong>`&lt;html&gt;`</strong> tag is a fundamental part of an HTML document. It serves as the root element that encapsulates all the content and elements within the HTML page. 
                        </li>
                        <li>
                            <strong>Document Type Identification:</strong> The <strong>`&lt;html&gt;`</strong> tag defines the beginning and end of an HTML document. All other elements, such as the <strong>`&lt;head&gt;`</strong> and <strong>`&lt;body&gt;`</strong> tags, are nested inside the <strong>`&lt;html&gt;`</strong> tag.
                        </li>
                        <li>
                            <strong>Language Attribute:</strong> The <strong>`&lt;html&gt;`</strong> tag often includes the lang attribute, which specifies the language of the document’s content. This is useful for accessibility and for search engines to better understand the content.
                        </li>
                    </ul>
                    <h5 className='mt-5 mb-3'>Syntax of the &lt;html&gt; Tag</h5>
                    <p>
                        The <strong>`&lt;html&gt;`</strong> tag is placed at the very beginning of the HTML document, right after the <strong>&lt;!DOCTYPE&gt;</strong> declaration, and it is closed at the very end of the document.
                    </p>
                    <div className='codePalateBox mt-2 mb-4'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                                <p>&lt;html&gt;</p>
                                <p class="ml-30 color-grey">    &lt;!-- All other elements go here --&gt;</p>
                                <p>&lt;/html&gt;</p>
                            `
                        }}></div>
                    </div>
                    <h5 className='mt-5 mb-3'>Attributes of the &lt;html&gt; Tag</h5>
                    <ul style={{ listStyle: 'decimal' }}>
                        <li>
                            <strong>lang</strong>: Specifies the language of the document. It helps search engines and screen readers understand the primary language of the content.
                            <div className='codePalateBox mt-2 mb-4'>
                                <div className='codePalate' dangerouslySetInnerHTML={{
                                    __html: `
                                        <p>&lt;html <span class="color-pink">lang</span>=<span class="color-green">"en"</span>&gt;</p>
                                    `
                                }}></div>
                            </div>
                        </li>
                        <li>
                            <strong>xml</strong>: Used when the document is served as XML. It specifies the language of the document.
                            <div className='codePalateBox mt-2 mb-4'>
                                <div className='codePalate' dangerouslySetInnerHTML={{
                                    __html: `
                                        <p>&lt;html <span class="color-pink">lang</span>=<span class="color-green">"en"</span> <span class="color-pink">xml:lang</span>=<span class="color-green">"en"</span>&gt;</p>
                                    `
                                }}></div>
                            </div>
                        </li>
                    </ul>
                </div>
            </section>
        </>
    )
}