import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/node-js/mongodb-join-document";

export default function MongoDbJoinDocument() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Node Js - MongoDB - Join Document | Aspirant's Home");
        const urls = {
            'previous': '/node-js/mongodb-limit-document',
            'next': '/node-js/built-in-modules'
        }
        path.setPreviousNext(urls);
    }, [])
    
    return (
        <section className='mt-5 mb-5'>
            <h3>Node Js MongoDB Join Document</h3>
            <div className='mt-4'>
                <p>In MongoDB, there is no native JOIN operation like in traditional relational databases. MongoDB is a NoSQL database, and it follows a different data modeling approach. However, you can achieve similar results using aggregation pipelines, which allow you to combine data from multiple collections.</p>
                <p>Now lets see how we join two document, let's take two collection one is "departments" and another is "employees".</p>
                <h5>Employees</h5>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html:
                            `<p>[</p>
                            <p style='margin-left:30px'> { _id: 1 , employee_name: "Sudhir", email: "sudhir@gmail.com", address: "8341 Murphy Forges Suite 858", "department_id":"110" },</p>
                            <p>]</p>`
                    }}>
                    </div>
                </div>
                <h5>Departments</h5>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html:
                            `<p>[</p>
                            <p style='margin-left:30px'> { _id: 110 , name: "Accountant" },</p>
                            <p style='margin-left:30px'> { _id: 111 , name: "HR" },</p>
                            <p style='margin-left:30px'> { _id: 112 , name: "Maintainance" },</p>
                            <p>]</p>`
                    }}>
                    </div>
                </div>
                <p>Now, join the matching "deparments" document(s) to the "employees" collection:</p>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html:
                            `<p><span class='color-blue'>var</span> MongoClient = <span class='color-yellow'>require</span>(<span class='color-green'>'mongodb'</span>).MongoClient;</p>
                            <p><span class='color-blue'>var</span> url = <span class='color-green'>"mongodb://127.0.0.1:27017/"</span>;</p>
                            <br />
                            <p>MongoClient.<span class='color-red'>connect</span>(url, function(<span class='color-blue'>err</span>, db) {</p>
                            <p style="margin-left:30px">  <span class='color-blue'>if</span> (err) <span class='color-blue'>throw</span> err;</p>
                            <p style="margin-left:30px">  <span class='color-blue'>var</span> dbo = db.<span class='color-red'>db</span>(<span class='color-green'>"mymongodbdatabase"</span>);</p>
                            <p style="margin-left:30px">  dbo.<span class='color-red'>collection</span>(<span class='color-green'>'employees'</span>).aggregate([</p>
                            <p style="margin-left:60px">    { <span class='color-pink'>$lookup</span>:</p>
                            <p style="margin-left:90px">       {</p>
                            <p style="margin-left:120px">         <span class='color-pink'>from</span>: <span class='color-green'>'deparments'</span>,</p>
                            <p style="margin-left:120px">         <span class='color-pink'>localField</span>: <span class='color-green'>'department_id'</span>,</p>
                            <p style="margin-left:120px">         <span class='color-pink'>foreignField</span>: <span class='color-green'>'_id'</span>,</p>
                            <p style="margin-left:120px">         <span class='color-pink'>as</span>: <span class='color-green'>'deparmentDetails'</span></p>
                            <p style="margin-left:90px">       }</p>
                            <p style="margin-left:60px">     }</p>
                            <p style="margin-left:30px">    ]).<span class='color-red'>toArray</span>(function(err, res) {</p>
                            <p style="margin-left:60px">    <span class='color-blue'>if</span> (err) <span class='color-blue'>throw</span> err;</p>
                            <p style="margin-left:60px">    <span class='color-pink'>console</span>.<span class='color-red'>log</span>(JSON.stringify(res));</p>
                            <p style="margin-left:60px">    db.<span class='color-red'>close</span>();</p>
                            <p style="margin-left:30px">  });</p>
                            <p>});</p>
                            `
                    }}>
                    </div>
                </div>
                <p>Which will give you this result:</p>
                <div className='codePalateBox mt-2 mb-2'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html:
                            `<p>[</p>
                            <p style='margin-left:30px'> { </p>
                            <p style='margin-left:60px'>_id: 1 , employee_name: "Sudhir", email: "sudhir@gmail.com", address: "8341 Murphy Forges Suite 858", "department_id":"110", deparmentDetails: [</p>
                            <p style='margin-left:90px'>    { _id: 110 , name: "Accountant" }</p> 
                            <p style='margin-left:60px'>]</p>
                            <p style='margin-left:30px'>  }</p>
                            <p>]</p>`
                    }}>
                    </div>
                </div>
            </div>
        </section>
    )
}
