import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../../Context";
export const pageUrl = () => "/react-js/hooks/usecallback";

export default function ReactHookUseCallback() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("React Hooks - useCallback() Hooks | Aspirant's Home");
        const urls = {
            'previous': '/react-js/hooks/usereducer',
            'next':'/react-js/hooks/usememo'
        }
        path.setPreviousNext(urls);
    },[])

    return (
        <section className='mt-5 mb-5'>
            <h3>React useCallback Hook</h3>
            <div className='mt-4 mb-4'>
                <p>
                    The <strong>`useCallback`</strong> hook in React is used to memoize functions. It returns a memoized version of the callback function that only changes if one of the dependencies has changed. This can be useful for optimizing performance in situations where a function is passed as a prop to child components and you want to prevent unnecessary re-renders.
                </p>
                <p>Here's a brief overview of how <strong>`useCallback`</strong> works:</p>
                <ul style={{listStyle:'disc'}}>
                    <li>
                        <strong>Creating a Memoized Function:</strong> You use the <strong>`useCallback`</strong> hook to create a memoized version of a function. The hook takes two arguments: the callback function and an array of dependencies.
                    </li>
                    <li>
                        <strong>Dependencies:</strong> The dependencies array is used to specify the values that the callback function depends on. If any of these values change, the callback function will be re-created. If the array is empty, the callback function will only be created once.
                    </li>
                    <li>
                        <strong>Usage:</strong> You can then use the memoized function in your components, passing it as a prop to child components or using it in event handlers.
                    </li>
                </ul>
                <p>Here, is the simple examples -</p>
                <div className='codePalateBox mt-2 mb-5'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `

                            <p><span class="color-grey">// MyComponent.js</span></p>
                            <p><span class="color-blue">import</span> { <span class="color-red">useState</span>, <span class="color-red">useCallback</span> } <span class="color-blue">from</span> <span class="color-green">"react"</span>;</p>
                            <p><span class="color-blue">import</span> <span class="color-red">TaskComponent</span> <span class="color-blue">from</span> <span class="color-green">"./TaskComponent"</span>;</p>
                            <br />
                            <p><span class="color-blue">function</span> <span class="color-red">MyComponent</span> = () => {</p>
                            <p style="margin-left:30px">    <span class="color-blue">const</span> [count, setCount] = <span class="color-red">useState</span>(0);</p>
                            <p style="margin-left:30px">    <span class="color-blue">const</span> [tasks, setTasks] = <span class="color-red">useState</span>([]);</p>
                            <br />    
                            <p style="margin-left:30px">    <span class="color-blue">const</span> <span class="color-red">increment</span> = () => {</p>
                            <p style="margin-left:60px">        <span class="color-red">setCount</span>((c) => c + 1);</p>
                            <p style="margin-left:30px">    };</p>
                            <p style="margin-left:30px">    <span class="color-blue">const</span> addTask = <span class="color-red">useCallback</span>(() => {</p>
                            <p style="margin-left:60px">        <span class="color-red">setTasks</span>((t) => [...t, "New Task"]);</p>
                            <p style="margin-left:30px">    }, [<span class="color-blue">tasks</span>]);</p>
                            <br />    
                            <p style="margin-left:30px">    <span class="color-blue">return</span> (</p>
                            <p style="margin-left:60px">        &lt;&gt;</p>
                            <p style="margin-left:90px">        &lt;<span class="color-red">TaskComponent</span> <span class="color-pink">tasks</span>=<span class="color-green">{tasks}</span> <span class="color-pink">addTask</span>=<span class="color-green">{addTask}</span> /&gt;</p>
                            <p style="margin-left:90px">        &lt;div&gt;</p>
                            <p style="margin-left:120px">            Count: {count}</p>
                            <p style="margin-left:120px">            &lt;button <span class="color-pink">onClick</span>=<span class="color-green">{increment}</span>&gt;+&lt;/button&gt;</p>
                            <p style="margin-left:90px">        &lt;/div&gt;</p>
                            <p style="margin-left:60px">        &lt;/&gt;</p>
                            <p style="margin-left:30px">    );</p>
                            <p>};</p>
                            <p><span class="color-blue">export default</span> <span class="color-red">MyComponent</span>;</p>
                            <br />
                            <p><span class="color-grey">// TaskComponent.js</span></p>
                            <p><span class="color-blue">function</span> <span class="color-red">TaskComponent</span> = ({ tasks, addTask }) => {</p>
                            <p style="margin-left:30px">    <span class="color-blue">return</span> (</p>
                            <p style="margin-left:60px">      &lt;&gt;</p>
                            <p style="margin-left:90px">        &lt;h2&gt;My Tasks&lt;/h2&gt;</p>
                            <p style="margin-left:90px">        {tasks.<span class="color-red">map</span>((task, index) => {</p>
                            <p style="margin-left:120px">          <span class="color-blue">return</span> &lt;p key={index}&gt;{task}&lt;/p&gt;;</p>
                            <p style="margin-left:90px">        })}</p>
                            <p style="margin-left:90px">        &lt;button <span class="color-pink">onClick</span>=<span class="color-green">{addTask}</span>&gt;Add Task&lt;/button&gt;</p>
                            <p style="margin-left:60px">      &lt;/&gt;</p>
                            <p style="margin-left:30px">    );</p>
                            <p>};</p>
                            <p><span class="color-blue">export default</span> <span class="color-red">TaskComponent</span>;</p>
                        `
                    }}></div>
                </div>
                <p>Here <strong>`TaskComponent`</strong> will only re-render when the <strong>`tasks`</strong> prop changes.</p>
            </div>
        </section>
    )
}
