import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";

export const pageUrl = () => "/html/unordered-lists";

export default function HTMLUnorderedLists() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("HTML - Unordered Lists | Aspirant's Home");
        const urls = {
            'previous': '/html/lists',
            'next': '/html/ordered-lists'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>HTML - Unordered Lists</h3>
            <div className='mt-4 mb-5'>
                <p>
                    An unordered list (<strong>`&lt;ul&gt;`</strong>) in HTML is used to group a set of items in no particular order. Items in an unordered list are typically displayed with bullet points. The <strong>`&lt;ul&gt;`</strong> tag is used to define the unordered list, and each item within the list is defined using the <strong>`&lt;li&gt;`</strong> (list item) tag.
                </p>
                <h5 className='mt-5 mb-3'>Basic Structure</h5>
                <p>
                    Here's the basic structure of an unordered list:
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;ul&gt;</p>
                        <p class="ml-30">    &lt;li&gt;Item 1&lt;/li&gt;</p>
                        <p class="ml-30">    &lt;li&gt;Item 2&lt;/li&gt;</p>
                        <p class="ml-30">    &lt;li&gt;Item 3&lt;/li&gt;</p>
                        <p>&lt;/ul&gt;</p>
                        `
                    }}></div>
                </div>
                <h5 className='mt-5 mb-3'>Example</h5>
                <p>
                    Here's a simple example of an unordered list:
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;!DOCTYPE html&gt;</p>
                        <p>&lt;html&gt;</p>
                        <p class="ml-30">    &lt;head&gt;</p>
                        <p class="ml-60">        &lt;title&gt;Unordered List Example&lt;/title&gt;</p>
                        <p class="ml-30">    &lt;/head&gt;</p>
                        <p class="ml-30">    &lt;body&gt;</p>
                        <p class="ml-60">        &lt;ul&gt;</p>
                        <p class="ml-90">            &lt;li&gt;Apple&lt;/li&gt;</p>
                        <p class="ml-90">            &lt;li&gt;Banana&lt;/li&gt;</p>
                        <p class="ml-90">            &lt;li&gt;Cherry&lt;/li&gt;</p>
                        <p class="ml-60">        &lt;/ul&gt;</p>
                        <p class="ml-30">    &lt;/body&gt;</p>
                        <p>&lt;/html&gt;</p>
                        `
                    }}></div>
                </div>
                <p>
                    Output of the following -
                </p>
                <div className='outputPalateBox mb-4'>
                    <div className='outputPalate' dangerouslySetInnerHTML={{
                        __html: `
                        <!DOCTYPE html>
                        <html>
                            <head>
                                <title>Unordered List Example</title>
                            </head>
                            <body>
                                <ul>
                                    <li>Apple</li>
                                    <li>Banana</li>
                                    <li>Cherry</li>
                                </ul>
                            </body>
                        </html>
                        `
                    }}></div>
                </div>
                <h5 className='mt-5 mb-3'>Styling Unordered Lists</h5>
                <p>
                    You can style unordered lists using CSS to change the bullet style, padding, margins, and more.
                </p>
                <h6>Changing Bullet Style</h6>
                <p>
                    You can change the bullet style using the <strong>`list-style-type`</strong> property. Common values include <strong>`disc`</strong>, <strong>`circle`</strong>, <strong>`square`</strong>, and <strong>`none`</strong>.
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-grey">&lt;!DOCTYPE</span> <span class="color-blue">html</span><span class="color-grey">&gt;</span></p>
                        <p>&lt;html&gt;</p>
                        <p class="ml-30">    &lt;head&gt;</p>
                        <p class="ml-60">        &lt;style&gt;</p>
                        <p class="ml-90">            ul.<span class="color-pink">disc</span> {</p>
                        <p class="ml-120">                <span class="color-green">list-style-type</span>: disc;
                        <p class="ml-90">            }</p>
                        <p class="ml-90">            ul.<span class="color-pink">circle</span> {</p>
                        <p class="ml-120">                <span class="color-green">list-style-type</span>: circle;</p>
                        <p class="ml-90">            }</p>
                        <p class="ml-90">            ul.<span class="color-pink">square</span> {</p>
                        <p class="ml-120">                <span class="color-green">list-style-type</span>: square;</p>
                        <p class="ml-90">            }</p>
                        <p class="ml-90">            ul.<span class="color-pink">none</span> {</p>
                        <p class="ml-120">                <span class="color-green">list-style-type</span>: none;</p>
                        <p class="ml-90">            }</p>
                        <p class="ml-60">        &lt;/style&gt;</p>
                        <p class="ml-30">    &lt;/head&gt;</p>
                        <p class="ml-30">    &lt;body&gt;</p>
                        <p class="ml-60">        &lt;ul <span class="color-pink">class</span>=<span class="color-green">"disc"</span>&gt;</p>
                        <p class="ml-90">            &lt;li&gt;Disc Bullet&lt;/li&gt;</p>
                        <p class="ml-90">            &lt;li&gt;Disc Bullet&lt;/li&gt;</p>
                        <p class="ml-60">        &lt;/ul&gt;</p>
                        <p class="ml-60">        &lt;ul <span class="color-pink">class</span>=<span class="color-green">"circle"</span>&gt;</p>
                        <p class="ml-90">            &lt;li&gt;Circle Bullet&lt;/li&gt;</p>
                        <p class="ml-90">            &lt;li&gt;Circle Bullet&lt;/li&gt;</p>
                        <p class="ml-60">        &lt;/ul&gt;</p>
                        <p class="ml-60">        &lt;ul <span class="color-pink">class</span>=<span class="color-green">"square"</span>&gt;</p>
                        <p class="ml-90">            &lt;li&gt;Square Bullet&lt;/li&gt;</p>
                        <p class="ml-90">            &lt;li&gt;Square Bullet&lt;/li&gt;</p>
                        <p class="ml-60">        &lt;/ul&gt;</p>
                        <p class="ml-60">        &lt;ul <span class="color-pink">class</span>=<span class="color-green">"none"</span>&gt;</p>
                        <p class="ml-90">            &lt;li&gt;No Bullet&lt;/li&gt;</p>
                        <p class="ml-90">            &lt;li&gt;No Bullet&lt;/li&gt;</p>
                        <p class="ml-60">        &lt;/ul&gt;</p>
                        <p class="ml-30">    &lt;/body&gt;</p>
                        <p>&lt;/html&gt;</p>
                        `
                    }}></div>
                </div>
                <p>
                    Output of the following -
                </p>
                <div className='outputPalateBox mb-4'>
                    <div className='outputPalate' dangerouslySetInnerHTML={{
                        __html: `
                        <!DOCTYPE html>
                        <html>
                            <head>
                                <style>
                                    ul.disc {
                                        list-style-type: disc;
                                    }
                                    ul.circle {
                                        list-style-type: circle;
                                    }
                                    ul.square {
                                        list-style-type: square;
                                    }
                                    ul.none {
                                        list-style-type: none;
                                    }
                                </style>
                            </head>
                            <body>
                                <ul class="disc">
                                    <li>Disc Bullet</li>
                                    <li>Disc Bullet</li>
                                </ul>
                                <ul class="circle">
                                    <li>Circle Bullet</li>
                                    <li>Circle Bullet</li>
                                </ul>
                                <ul class="square">
                                    <li>Square Bullet</li>
                                    <li>Square Bullet</li>
                                </ul>
                                <ul class="none">
                                    <li>No Bullet</li>
                                    <li>No Bullet</li>
                                </ul>
                            </body>
                        </html>
                        `
                    }}></div>
                </div>
                <h5 className='mt-5 mb-3'>Customizing List Item Appearance</h5>
                <p>
                    You can customize the appearance of list items using various CSS properties, such as <strong>`padding`</strong>, <strong>`margin`</strong>, <strong>`background`</strong>, <strong>`color`</strong>, etc.
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-grey">&lt;!DOCTYPE</span> <span class="color-blue">html</span><span class="color-grey">&gt;</span></p>
                        <p>&lt;html&gt;</p>
                        <p class="ml-30">    &lt;head&gt;</p>
                        <p class="ml-60">    &lt;style&gt;</p>
                        <p class="ml-90">        ul.<span class="color-pink">custom-list</span> {</p>
                        <p class="ml-120">            <span class="color-green">list-style-type</span>: none;</p>
                        <p class="ml-120">            <span class="color-green">padding</span>: 0;</p>
                        <p class="ml-90">        }</p>
                        <p class="ml-90">        ul.<span class="color-pink">custom-list</span> li {</p>
                        <p class="ml-120">            <span class="color-green">background</span>: #e0e0e0;</p>
                        <p class="ml-120">            <span class="color-green">margin</span>: 5px 0;</p>
                        <p class="ml-120">            <span class="color-green">padding</span>: 10px;</p>
                        <p class="ml-120">            <span class="color-green">border-radius</span>: 5px;</p>
                        <p class="ml-120">        }</p>
                        <p class="ml-60">    &lt;/style&gt;</p>
                        <p class="ml-30">    &lt;/head&gt;</p>
                        <p class="ml-30">    &lt;body&gt;</p>
                        <p class="ml-60">        &lt;ul <span class="color-pink">class</span>=<span class="color-green">"custom-list"</span>&gt;</p>
                        <p class="ml-90">            &lt;li&gt;Item 1&lt;/li&gt;</p>
                        <p class="ml-90">            &lt;li&gt;Item 2&lt;/li&gt;</p>
                        <p class="ml-90">            &lt;li&gt;Item 3&lt;/li&gt;</p>
                        <p class="ml-60">        &lt;/ul&gt;</p>
                        <p class="ml-30">    &lt;/body&gt;</p>
                        <p>&lt;/html&gt;</p>
                        `
                    }}></div>
                </div>
                <p>
                    Output of the following -
                </p>
                <div className='outputPalateBox mb-4'>
                    <div className='outputPalate' dangerouslySetInnerHTML={{
                        __html: `
                        <!DOCTYPE html>
                        <html>
                            <head>
                            <style>
                                ul.custom-list {
                                    list-style-type: none;
                                    padding: 0;
                                }
                                ul.custom-list li {
                                    background: #e0e0e0;
                                    margin: 5px 0;
                                    padding: 10px;
                                    border-radius: 5px;
                                }
                            </style>
                            </head>
                            <body>
                                <ul class="custom-list">
                                    <li>Item 1</li>
                                    <li>Item 2</li>
                                    <li>Item 3</li>
                                </ul>
                            </body>
                        </html>
                        `
                    }}></div>
                </div>
                <h5 className='mt-5 mb-3'>Nesting Unordered Lists</h5>
                <p>
                    You can nest unordered lists inside other lists to create sub-lists.
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p><span class="color-grey">&lt;!DOCTYPE</span> <span class="color-blue">html</span><span class="color-grey">&gt;</span></p>
                        <p>&lt;html&gt;</p>
                        <p class="ml-30">    &lt;head&gt;</p>
                        <p class="ml-60">        &lt;title&gt;Nesting Lists Example&lt;/title&gt;</p>
                        <p class="ml-30">    &lt;/head&gt;</p>
                        <p class="ml-30">    &lt;body&gt;</p>
                        <p class="ml-60">        &lt;ul&gt;</p>
                        <p class="ml-90">            &lt;li&gt;Fruit</p>
                        <p class="ml-120">                &lt;ul&gt;</p>
                        <p class="ml-150">                    &lt;li&gt;Apple&lt;/li&gt;</p>
                        <p class="ml-150">                    &lt;li&gt;Banana&lt;/li&gt;</p>
                        <p class="ml-150">                    &lt;li&gt;Cherry&lt;/li&gt;</p>
                        <p class="ml-120">                &lt;/ul&gt;</p>
                        <p class="ml-90">            &lt;/li&gt;</p>
                        <p class="ml-90">            &lt;li&gt;Vegetables</p>
                        <p class="ml-120">                &lt;ul&gt;</p>
                        <p class="ml-150">                    &lt;li&gt;Carrot&lt;/li&gt;</p>
                        <p class="ml-150">                    &lt;li&gt;Broccoli&lt;/li&gt;</p>
                        <p class="ml-150">                    &lt;li&gt;Spinach&lt;/li&gt;</p>
                        <p class="ml-120">                &lt;/ul&gt;</p>
                        <p class="ml-90">            &lt;/li&gt;</p>
                        <p class="ml-60">        &lt;/ul&gt;</p>
                        <p class="ml-30">    &lt;/body&gt;</p>
                        <p>&lt;/html&gt;</p>
                        `
                    }}></div>
                </div>
                <p>
                    Output of the following -
                </p>
                <div className='outputPalateBox mb-4'>
                    <div className='outputPalate' dangerouslySetInnerHTML={{
                        __html: `
                        <!DOCTYPE html>
                        <html>
                            <head>
                                <title>Nesting Lists Example</title>
                            </head>
                            <body>
                                <ul>
                                    <li>Fruit
                                        <ul>
                                            <li>Apple</li>
                                            <li>Banana</li>
                                            <li>Cherry</li>
                                        </ul>
                                    </li>
                                    <li>Vegetables
                                        <ul>
                                            <li>Carrot</li>
                                            <li>Broccoli</li>
                                            <li>Spinach</li>
                                        </ul>
                                    </li>
                                </ul>
                            </body>
                        </html>
                        `
                    }}></div>
                </div>

                <p className='mt-4 featureClass'>
                    By understanding and using unordered lists properly, you can create well-structured and visually appealing lists in your HTML documents.
                </p>
            </div>
        </section>
    )
}



