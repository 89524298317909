import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/html/symbols";

export default function HTMLSymbols() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("HTML - Symbols | Aspirant's Home");
        const urls = {
            'previous': '/html/entities',
            'next': '/html/emojis'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>HTML - Symbols</h3>
            <div className='mt-4 mb-5'>
                <p>
                    HTML symbols are special characters that are not found on a standard keyboard but can be displayed in a web page using HTML entities. These symbols include mathematical signs, currency signs, arrows, and many other symbols used in various contexts. HTML entities are used to represent these symbols, ensuring they are displayed correctly in the browser.
                </p>
                <h5 className='mt-5 mb-3'>Example</h5>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate'>
                        <p>&lt;p&gt;I will display <code>{`&euro;`}</code>&lt;/p&gt;</p>
                        <p>&lt;p&gt;I will display <code>{`&#8364;`}</code>&lt;/p&gt;</p>
                        <p>&lt;p&gt;I will display <code>{`&#x20AC;`}</code>&lt;/p&gt;</p>
                    </div>    
                </div>
                <p>
                    Output of the following -
                </p>
                <div className='outputPalateBox mb-4'>
                    <div className='outputPalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>I will display &euro;</p>
                            <p>I will display &#8364;</p>
                            <p>I will display &#x20AC;</p>
                        `
                    }}></div>
                </div>
                <h5 className='mt-5 mb-3'>Usefull Mathematical Symbols</h5>
                <table class="table">
                    <tbody>
                        <tr>
                            <th>Result</th>
                            <th>Description</th>
                            <th>Name</th>
                            <th>Number</th>
                        </tr>
                        <tr>
                            <td style={{'fontSize':'27px'}}>±</td>
                            <td>Plus-minus sign</td>
                            <td>{`&plusmn;`}</td>
                            <td>{`&#177;`}</td>    
                        </tr>
                        <tr>
                            <td style={{'fontSize':'27px'}}>×</td>
                            <td>Multiplication sign</td>
                            <td>{`&times;`}</td>
                            <td>{`&#215;`}</td>
                        </tr>
                        <tr>
                            <td style={{'fontSize':'27px'}}>÷</td>
                            <td>Division sign</td>
                            <td>{`&divide;`}</td>
                            <td>{`&#247;`}</td>
                        </tr>
                        <tr>
                            <td style={{'fontSize':'27px'}}>≤</td>
                            <td>Less-than or equal to</td>
                            <td>{`&le;`}</td>
                            <td>{`&#8804;`}</td>
                        </tr>
                        <tr>
                            <td style={{'fontSize':'27px'}}>≥</td>
                            <td>Greater-than or equal to</td>
                            <td>{`&ge;`}</td>
                            <td>{`&#8805;`}</td>
                        </tr>
                        <tr>
                            <td style={{'fontSize':'27px'}}>∀</td>
                            <td>For all</td>
                            <td>{`&forall;`}</td>
                            <td>{`&#8704;`}</td>
                        </tr>
                        <tr>
                            <td style={{'fontSize':'27px'}}>∂</td>
                            <td>Partial differential</td>
                            <td>{`&part;`}</td>
                            <td>{`&#8706;`}</td>
                        </tr>
                        <tr>
                            <td style={{'fontSize':'27px'}}>∃</td>
                            <td>There exists</td>
                            <td>{`&exist;`}</td>
                            <td>{`&#8707;`}</td>
                        </tr>
                        <tr>
                            <td style={{'fontSize':'27px'}}>∅</td>
                            <td>Empty sets</td>
                            <td>{`&empty;`}</td>
                            <td>{`&#8709;`}</td>
                        </tr>
                        <tr>
                            <td style={{'fontSize':'27px'}}>∇</td>
                            <td>Nabla</td>
                            <td>{`&nabla;`}</td>
                            <td>{`&#8711;`}</td>
                        </tr>
                        <tr>
                            <td style={{'fontSize':'27px'}}>∈</td>
                            <td>Element of</td>
                            <td>{`&isin;`}</td>
                            <td>{`&#8712;`}</td>
                        </tr>
                        <tr>
                            <td style={{'fontSize':'27px'}}>∉</td>
                            <td>Not an element of</td>
                            <td>{`&notin;`}</td>
                            <td>{`&#8713;`}</td>
                        </tr>
                        <tr>
                            <td style={{'fontSize':'27px'}}>∋</td>
                            <td>Contains as member</td>
                            <td>{`&ni;`}</td>
                            <td>{`&#8715;`}</td>
                        </tr>
                        <tr>
                            <td style={{'fontSize':'27px'}}>∏</td>
                            <td>N-ary product</td>
                            <td>{`&prod;`}</td>
                            <td>{`&#8719;`}</td>
                        </tr>
                        <tr>
                            <td style={{'fontSize':'27px'}}>∑</td>
                            <td>N-ary summation</td>
                            <td>{`&sum;`}</td>
                            <td>{`&#8721;`}</td>
                        </tr>
                    </tbody>
                </table>

                <h5 className='mt-5 mb-3'>Currency Symbols</h5>
                <table class="table">
                    <tbody>
                        <tr>
                            <th>Result</th>
                            <th>Description</th>
                            <th>Name</th>
                            <th>Number</th>
                        </tr>
                        <tr>
                            <td style={{'fontSize':'27px'}}>$</td>
                            <td>Dollar sign</td> 
                            <td>{`$`}</td>
                            <td></td>      
                        </tr>
                        <tr>
                            <td style={{'fontSize':'27px'}}>€</td>
                            <td>Euro sign</td>
                            <td>{`&euro;`}</td>
                            <td>{`&#8364;`}</td> 
                        </tr>
                        <tr>
                            <td style={{'fontSize':'27px'}}>£</td>
                            <td>Pound sign</td>
                            <td>{`&pound;`}</td>
                            <td>{`&#163;`}</td>    
                        </tr>
                        <tr>
                            <td style={{'fontSize':'27px'}}>¥</td>
                            <td>Yen sign</td>
                            <td>{`&yen;`}</td>
                            <td>{`&#165;`}</td>    
                        </tr>
                        <tr>
                            <td style={{'fontSize':'27px'}}>¢</td>
                            <td>Cent sign</td>
                            <td>{`&cent;`}</td>
                            <td>{`&#162;`}</td>    
                        </tr>
                    </tbody>
                </table>
                <h5 className='mt-5 mb-3'>Arrow Symbols</h5>
                <table class="table">
                    <tbody>
                        <tr>
                            <th>Result</th>
                            <th>Description</th>
                            <th>Name</th>
                            <th>Number</th>
                        </tr>
                        <tr>
                            <td style={{'fontSize':'27px'}}>←</td>
                            <td>Left arrow</td> 
                            <td>{`&larr;`}</td>
                            <td>{`&#8592;`}</td>      
                        </tr>
                        <tr>
                            <td style={{'fontSize':'27px'}}>→</td>
                            <td>Right arrow</td>
                            <td>{`&rarr;`}</td>
                            <td>{`&#8594;`}</td> 
                        </tr>
                        <tr>
                            <td style={{'fontSize':'27px'}}>↑</td>
                            <td>Up arrow</td>
                            <td>{`&uarr;`}</td>
                            <td>{`&#8593;`}</td>    
                        </tr>
                        <tr>
                            <td style={{'fontSize':'27px'}}>↓</td>
                            <td>Down arrow</td>
                            <td>{`&darr;`}</td>
                            <td>{`&#8595;`}</td>    
                        </tr>
                        <tr>
                            <td style={{'fontSize':'27px'}}>⇐</td>
                            <td>Double left arrow</td>
                            <td>{`&lArr;`}</td>
                            <td>{`&#8656;`}</td>    
                        </tr>
                        <tr>
                            <td style={{'fontSize':'27px'}}>⇒</td>
                            <td>Double right arrow</td>
                            <td>{`&rArr;`}</td>
                            <td>{`&#8658;`}</td>    
                        </tr>
                    </tbody>
                </table>
                <h5 className='mt-5 mb-3'>Miscellaneous Symbols</h5>
                <table class="table">
                    <tbody>
                        <tr>
                            <th>Result</th>
                            <th>Description</th>
                            <th>Name</th>
                            <th>Number</th>
                        </tr>
                        <tr>
                            <td style={{'fontSize':'27px'}}>©</td>
                            <td>Copyright sign</td> 
                            <td>{`&copy;`}</td>
                            <td>{`&#169;`}</td>      
                        </tr>
                        <tr>
                            <td style={{'fontSize':'27px'}}>®</td>
                            <td>Registered trademark sign</td> 
                            <td>{`&reg;`}</td>
                            <td>{`&#174;`}</td>      
                        </tr>
                        <tr>
                            <td style={{'fontSize':'27px'}}>°</td>
                            <td>Degree sign</td> 
                            <td>{`&deg;`}</td>
                            <td>{`&#176;`}</td>      
                        </tr>
                        <tr>
                            <td style={{'fontSize':'27px'}}>•</td>
                            <td>Bullet point</td> 
                            <td>{`&bull;`}</td>
                            <td>{`&#8226;`}</td>      
                        </tr>
                        <tr>
                            <td style={{'fontSize':'27px'}}>§</td>
                            <td>Section sign</td> 
                            <td>{`&sect;`}</td>
                            <td>{`&#167;`}</td>      
                        </tr>
                        <tr>
                            <td style={{'fontSize':'27px'}}>♠</td>
                            <td>SPADE</td> 
                            <td>{`&spades;`}</td>
                            <td>{`&#9824;`}</td>      
                        </tr>
                        <tr>
                            <td style={{'fontSize':'27px'}}>♣</td>
                            <td>CLUB</td> 
                            <td>{`&clubs;`}</td>
                            <td>{`&#9827;`}</td>      
                        </tr>
                        <tr>
                            <td style={{'fontSize':'27px'}}>♥</td>
                            <td>HEART</td> 
                            <td>{`&hearts;`}</td>
                            <td>{`&#9829;`}</td>      
                        </tr>
                        <tr>
                            <td style={{'fontSize':'27px'}}>♦</td>
                            <td>DIAMOND</td> 
                            <td>{`&diams;`}</td>
                            <td>{`&#9830;`}</td>      
                        </tr>
                    </tbody>
                </table>
            </div>
        </section>
    )
}