import React, { useEffect, useState, useRef } from 'react'
import { Outlet, Link } from "react-router-dom";
import TopHeader from '../TopHeader';
import Footer from '../Footer'
import { MenuContext, TitleContext, PathContext } from "../../Context";
import { useContext } from "react";
import PreviousNext from '../PreviousNext';

export default function MongoDBLayout() {

    const addspaceElement = useRef();
    const menuElement = useRef([]);
    const menu = useContext(MenuContext);
    const pathName = useContext(PathContext);
    const pathname = pathName.pathName;
    const [showSubMenu, setShowSubMenu] = useState(document.getElementsByClassName("activeSubMenu")[0] ? false : true)
    const title = useContext(TitleContext);

    const setPathName = (path) => {
        if(path)
        pathName.setPathName(path);
    }

    useEffect(() => {
        setPathName(window.location.pathname)
        if (menu.isMenu == true) menu.setMenuOpen(false)
        addspaceElement.current.scrollIntoView(true)
        const element = document.getElementsByClassName("activeMenu")[0];
        element && element.scrollIntoView({ block: "center" });
        const subelement = document.getElementsByClassName("activeSubMenu")[0];
        subelement && subelement.scrollIntoView({ block: "center" });
        // title.setKeyWords("MongoDB, NoSQL database, MongoDB tutorial, MongoDB guide, MongoDB documentation, MongoDB installation, MongoDB download, MongoDB features, MongoDB vs SQL, MongoDB advantages, MongoDB benefits, MongoDB performance, MongoDB use cases, MongoDB applications, MongoDB schema, MongoDB collections, MongoDB documents, MongoDB indexes, MongoDB queries, MongoDB aggregation, MongoDB replication, MongoDB sharding, MongoDB transactions, MongoDB security, MongoDB backup, MongoDB restore, MongoDB deployment, MongoDB administration");
        // title.setPageDescription("Get started with MongoDB through our comprehensive tutorial. Follow step-by-step instructions, code examples, and best practices to build and manage MongoDB databases effectively.");
    }, [pathname])

    return (
        <>
            <TopHeader />
            <div className='container-fluid'>
                <div className='row'>
                    <div ref={menuElement} className={menu.isMenu == true ? 'col-md-3 col-lg-2 menuColumnMobile menuColumn p-0' : 'col-md-3 col-lg-2 menuColumn p-0'} style={{ 'overflowY': 'scroll', height: 'calc(100vh - 110px)' }}>
                        <div className='headerMenu'>
                            MongoDB Tutorial
                        </div>
                        <nav>
                            <ul>
                                <li className={pathname == '/mongo-db/introduction' ? 'activeMenu' : ''}>
                                    <Link to="/mongo-db/introduction" onClick={() => setPathName('/mongo-db/introduction')}>Introduction</Link>
                                </li>
                                <li className={pathname == '/mongo-db/get-started' ? 'activeMenu' : ''}>
                                    <Link to="/mongo-db/get-started" onClick={() => setPathName('/mongo-db/get-started')}>Get Started</Link>
                                </li>
                                <li className={pathname == '/mongo-db/create-database' ? 'activeMenu' : ''}>
                                    <Link to="/mongo-db/create-database" onClick={() => setPathName('/mongo-db/create-database')}>Create Database</Link>
                                </li>
                                <li className={pathname == '/mongo-db/drop-database' ? 'activeMenu' : ''}>
                                    <Link to="/mongo-db/drop-database" onClick={() => setPathName('/mongo-db/drop-database')}>Drop Database</Link>
                                </li>
                                <li className={pathname == '/mongo-db/connecting-database' ? 'activeMenu' : ''}>
                                    <Link to="/mongo-db/connecting-database" onClick={() => setPathName('/mongo-db/connecting-database')}>Connecting Database</Link>
                                </li>
                            </ul>
                        </nav>
                        <div className='headerMenu'>
                            Collections & Datatypes
                        </div>
                        <nav>
                            <ul>
                                <li className={pathname == '/mongo-db/create-collection' ? 'activeMenu' : ''}>
                                    <Link to="/mongo-db/create-collection" onClick={() => setPathName('/mongo-db/create-collection')}>Create Collection</Link>
                                </li>
                                <li className={pathname == '/mongo-db/drop-collection' ? 'activeMenu' : ''}>
                                    <Link to="/mongo-db/drop-collection" onClick={() => setPathName('/mongo-db/drop-collection')}>Drop Collection</Link>
                                </li>
                                <li className={pathname == '/mongo-db/data-types' ? 'activeMenu' : ''}>
                                    <Link to="/mongo-db/data-types" onClick={() => setPathName('/mongo-db/data-types')}>Data Types</Link>
                                </li>
                            </ul>
                        </nav>
                        <div className='headerMenu'>
                            Documents
                        </div>
                        <nav>
                            <ul>
                                <li className={pathname == '/mongo-db/insert-document' ? 'activeMenu' : ''}>
                                    <Link to="/mongo-db/insert-document" onClick={() => setPathName('/mongo-db/insert-document')}>Insert Document</Link>
                                </li>
                                <li className={pathname == '/mongo-db/query-document' ? 'activeMenu' : ''}>
                                    <Link to="/mongo-db/query-document" onClick={() => setPathName('/mongo-db/query-document')}>Query Document</Link>
                                </li>
                                <li className={pathname == '/mongo-db/update-document' ? 'activeMenu' : ''}>
                                    <Link to="/mongo-db/update-document" onClick={() => setPathName('/mongo-db/update-document')}>Update Document</Link>
                                </li>
                                <li className={pathname == '/mongo-db/delete-document' ? 'activeMenu' : ''}>
                                    <Link to="/mongo-db/delete-document" onClick={() => setPathName('/mongo-db/delete-document')}>Delete Document</Link>
                                </li>
                            </ul>
                        </nav>
                        <div className='headerMenu'>
                            Relations
                        </div>
                        <nav>
                            <ul>
                                <li className={pathname == '/mongo-db/document-relations' ? 'activeMenu' : ''}>
                                    <Link to="/mongo-db/document-relations" onClick={() => setPathName('/mongo-db/document-relations')}>Understanding</Link>
                                </li>
                                <li className={pathname == '/mongo-db/one-to-one-relations' ? 'activeMenu' : ''}>
                                    <Link to="/mongo-db/one-to-one-relations" onClick={() => setPathName('/mongo-db/one-to-one-relations')}>One to One</Link>
                                </li>
                                <li className={pathname == '/mongo-db/one-to-many-relations' ? 'activeMenu' : ''}>
                                    <Link to="/mongo-db/one-to-many-relations" onClick={() => setPathName('/mongo-db/one-to-many-relations')}>One to Many</Link>
                                </li>
                                <li className={pathname == '/mongo-db/many-to-many-relations' ? 'activeMenu' : ''}>
                                    <Link to="/mongo-db/many-to-many-relations" onClick={() => setPathName('/mongo-db/many-to-many-relations')}>Many to Many</Link>
                                </li>
                            </ul>
                        </nav>
                        <div className='headerMenu'>
                            Others
                        </div>
                        <nav>
                            <ul>
                                <li className={pathname == '/mongo-db/projection' ? 'activeMenu' : ''}>
                                    <Link to="/mongo-db/projection" onClick={() => setPathName('/mongo-db/projection')}>Projection</Link>
                                </li>
                                <li className={pathname == '/mongo-db/limit-records' ? 'activeMenu' : ''}>
                                    <Link to="/mongo-db/limit-records" onClick={() => setPathName('/mongo-db/limit-records')}>Limiting Records</Link>
                                </li>
                                <li className={pathname == '/mongo-db/sorting-records' ? 'activeMenu' : ''}>
                                    <Link to="/mongo-db/sorting-records" onClick={() => setPathName('/mongo-db/sorting-records')}>Sorting Records</Link>
                                </li>
                                <li className={pathname == '/mongo-db/indexing' ? 'activeMenu' : ''}>
                                    <Link to="/mongo-db/indexing" onClick={() => setPathName('/mongo-db/indexing')}>Indexing</Link>
                                </li>
                                <li>
                                    <Link to="/mongo-db/aggregation" onClick={() => setPathName('/mongo-db/aggregation')}>Aggregation</Link>
                                    <span className='dropdown float-end'>
                                        <span className="btn btn-secondary dropdown-toggle dropdown-link-menu" onClick={(e)=>setShowSubMenu(!showSubMenu)}></span>
                                    </span>
                                </li>
                                {
                                    showSubMenu == true ? 
                                        <>
                                            <li className={pathname == '/mongo-db/aggregation' ? 'activeSubMenu' : 'background-white'}>
                                                <Link to="/mongo-db/aggregation" onClick={() => setPathName('/mongo-db/aggregation')}>
                                                    <span className='ml-30'>Aggregation</span>
                                                </Link>
                                            </li>
                                            <li className={pathname == '/mongo-db/aggregation/match' ? 'activeSubMenu' : 'background-white'}>
                                                <Link to="/mongo-db/aggregation/match" onClick={() => setPathName('/mongo-db/aggregation/match')}>
                                                    <span className='ml-30'>$match</span>
                                                </Link>
                                            </li>
                                            <li className={pathname == '/mongo-db/aggregation/group' ? 'activeSubMenu' : 'background-white'}>
                                                <Link to="/mongo-db/aggregation/group" onClick={() => setPathName('/mongo-db/aggregation/group')}>
                                                    <span className='ml-30'>$group</span>
                                                </Link>
                                            </li>
                                            <li className={pathname == '/mongo-db/aggregation/project' ? 'activeSubMenu' : 'background-white'}>
                                                <Link to="/mongo-db/aggregation/project" onClick={() => setPathName('/mongo-db/aggregation/project')}>
                                                    <span className='ml-30'>$project</span>
                                                </Link>
                                            </li>
                                            <li className={pathname == '/mongo-db/aggregation/sort' ? 'activeSubMenu' : 'background-white'}>
                                                <Link to="/mongo-db/aggregation/sort" onClick={() => setPathName('/mongo-db/aggregation/sort')}>
                                                    <span className='ml-30'>$sort</span>
                                                </Link>
                                            </li>
                                            <li className={pathname == '/mongo-db/aggregation/limit' ? 'activeSubMenu' : 'background-white'}>
                                                <Link to="/mongo-db/aggregation/limit" onClick={() => setPathName('/mongo-db/aggregation/limit')}>
                                                    <span className='ml-30'>$limit</span>
                                                </Link>
                                            </li>
                                            <li className={pathname == '/mongo-db/aggregation/lookup' ? 'activeSubMenu' : 'background-white'}>
                                                <Link to="/mongo-db/aggregation/lookup" onClick={() => setPathName('/mongo-db/aggregation/lookup')}>
                                                    <span className='ml-30'>$lookup</span>
                                                </Link>
                                            </li>
                                        </>
                                        : <></>
                                }
                            </ul>
                        </nav>
                        <div className='headerMenu'>
                            Mongoose
                        </div>
                        <nav>
                            <ul>
                                <li className={pathname == '/mongo-db/mongoose/introduction' ? 'activeMenu' : ''}>
                                    <Link to="/mongo-db/mongoose/introduction" onClick={() => setPathName('/mongo-db/mongoose/introduction')}>Introduction</Link>
                                </li>
                                <li className={pathname == '/mongo-db/mongoose/get-started' ? 'activeMenu' : ''}>
                                    <Link to="/mongo-db/mongoose/get-started" onClick={() => setPathName('/mongo-db/mongoose/get-started')}>Getting Started</Link>
                                </li>
                            </ul>
                        </nav>
                    </div>
                    <div className='col-12 col-md-9 col-lg-10 rightPart' style={{ 'overflowY': 'scroll', height: 'calc(100vh - 110px)' }}>
                        <div className='row'>
                            <div className='col-12 col-md-12 col-lg-10 col-lg-9 mb-5'>
                                <div className='headerAdSpace' ref={addspaceElement}>
                                    <ins class="adsbygoogle"
                                        style={{ 'display': 'block' }}
                                        data-ad-client="ca-pub-2061924575986153"
                                        data-ad-slot="8656415608"
                                        data-ad-format="auto"
                                        data-full-width-responsive="true"></ins>
                                    <script>
                                        (adsbygoogle = window.adsbygoogle || []).push({ });
                                    </script>
                                </div>
                                <div className='container'>
                                    <div className='contentPage'>
                                        {
                                            pathName.urls ? <PreviousNext urls={ pathName.urls } /> : <></>
                                        }
                                        
                                            <Outlet />
                                        {
                                            pathName.urls ? <PreviousNext urls={ pathName.urls } /> : <></>
                                        }
                                    </div>
                                </div>
                                <div className='footerAdSpace'>
                                    <ins class="adsbygoogle"
                                        style={{ 'display': 'block' }}
                                        data-ad-client="ca-pub-2061924575986153"
                                        data-ad-slot="7856138126"
                                        data-ad-format="auto"
                                        data-full-width-responsive="true"></ins>
                                    <script>
                                        (adsbygoogle = window.adsbygoogle || []).push({ });
                                    </script>
                                </div>
                            </div>
                            <div className='col-lg-3 hiddenInSmall'>
                                <ins class="adsbygoogle"
                                    style={{ 'display': 'block' }}
                                    data-ad-client="ca-pub-2061924575986153"
                                    data-ad-slot="6669380669"
                                    data-ad-format="auto"
                                    data-full-width-responsive="true"></ins>
                                <script>
                                    (adsbygoogle = window.adsbygoogle || []).push({ });
                                </script>
                            </div>
                            <div className='col-12'>
                                <div className='container-fluid footerPart'>
                                    <Footer />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
