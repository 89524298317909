import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/css/border-sides";

export default function BorderSides() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Border Sides in CSS | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/css/border-color',
            'next': '/css/border-shorthand'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>Border Sides</h3>
            <div className='mt-4 mb-5'>
                <p>
                    In CSS, you can style the borders of an element individually for each side. This allows you to apply different styles, widths, and colors to the <strong>`top`</strong>, <strong>`right`</strong>, <strong>`bottom`</strong>, and <strong>`left`</strong> borders of an element.
                </p>
                <p>
                    Each side of the border can be controlled with its own set of properties. You can set different styles to each sides. You can set different border width and color to each side. 
                </p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><strong>`border-top`</strong> styles the top border.</li>
                    <li><strong>`border-right`</strong> styles the right border.</li>
                    <li><strong>`border-bottom`</strong> styles the bottom border.</li>
                    <li><strong>`border-left`</strong> styles the left border.</li>
                </ul>
                <p>
                    You can even break the all the styles into sub-properties for more detailed control.
                </p>
                <ul style={{ listStyle: 'disc' }}>
                    <li><strong>`border-*-width`</strong> sets the width of the border on the specified side.</li>
                    <li><strong>`border-*-style`</strong> sets the style of the border on the specified side.</li>
                    <li><strong>`border-*-color`</strong> sets the color of the border on the specified side.</li>
                </ul>
                <p>
                    For example,
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>&lt;style&gt;</p>
                            <p class="ml-30">    .box {</p>
                            <p class="ml-60">        <span class="color-green">height</span>: <span class="color-pink">100px</span>;</span></p>
                            <p class="ml-60">        <span class="color-green">padding</span>: <span class="color-pink">10px</span>;</span></p>
                            <p class="ml-60">        <span class="color-green">margin-bottom</span>: <span class="color-pink">20px</span>;</span></p>
                            <p class="ml-30">    }</p>
                            <p class="ml-30">    .example1 {</p>
                            <p class="ml-60">        <span class="color-green">border-top-width</span>: <span class="color-pink">2px</span>;</p>
                            <p class="ml-60">        <span class="color-green">border-top-style</span>: solid;</p>
                            <p class="ml-60">        <span class="color-green">border-top-color</span>: red;</p>
                            <br />
                            <p class="ml-60">        <span class="color-green">border-right-width</span>: <span class="color-pink">4px</span>;</p>
                            <p class="ml-60">        <span class="color-green">border-right-style</span>: dashed;</p>
                            <p class="ml-60">        <span class="color-green">border-right-color</span>: green;</p>
                            <br />
                            <p class="ml-60">        <span class="color-green">border-bottom-width</span>: <span class="color-pink">6px</span>;</p>
                            <p class="ml-60">        <span class="color-green">border-bottom-style</span>: dotted;</p>
                            <p class="ml-60">        <span class="color-green">border-bottom-color</span>: blue;</p>
                            <br />
                            <p class="ml-60">        <span class="color-green">border-left-width</span>: <span class="color-pink">8px</span>;</p>
                            <p class="ml-60">        <span class="color-green">border-left-style</span>: double;</p>
                            <p class="ml-60">        <span class="color-green">border-left-color</span>: purple;</p>
                            <p class="ml-30">    }</p>
                            <p>&lt;/style&gt;</p>
                            <p>&lt;div <span class="color-pink">class</span>=<span class="color-green">"box example1"</span>&gt;Individual Border Properties&lt;/div&gt;</p>
                        `
                    }}></div>
                </div>
                <p>
                    Output will be the following -
                </p>
                <div className='outputPalateBox mb-4 mt-3' style={{'border':'none'}}>
                    <div className='outputPalate' dangerouslySetInnerHTML={{
                        __html: `
                        <style>
                            .box {
                                height: 100px;
                                padding: 10px;
                                margin-bottom: 20px;
                            }
                            .example1 {
                                border-top-width: 2px;
                                border-top-style: solid;
                                border-top-color: red;
                    
                                border-right-width: 4px;
                                border-right-style: dashed;
                                border-right-color: green;
                    
                                border-bottom-width: 6px;
                                border-bottom-style: dotted;
                                border-bottom-color: blue;
                    
                                border-left-width: 8px;
                                border-left-style: double;
                                border-left-color: purple;
                            }
                        </style>
                        <div class="box example1">Individual Border Properties</div>
                        `
                    }}></div>
                </div>

                <h5 className="mt-5 mb-3">Shorthand Border Side Properties</h5>
                <p>
                    You can also use shorthand properties to set multiple border properties at once.
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                        <p>&lt;style&gt;</p>
                        <p class="ml-30">    .box {</p>
                        <p class="ml-60">        <span class="color-green">height</span>: <span class="color-pink">100px</span>;</p>
                        <p class="ml-60">        <span class="color-green">padding</span>: <span class="color-pink">10px</span>;</p>
                        <p class="ml-60">        <span class="color-green">margin-bottom</span>: <span class="color-pink">20px</span>;</p>
                        <p class="ml-30">    }</p>
                        <p class="ml-30">    .example2 {</p>
                        <p class="ml-60">        <span class="color-green">border-top</span>: <span class="color-pink">2px</span> solid red;</p>
                        <p class="ml-60">        <span class="color-green">border-right</span>: <span class="color-pink">4px</span> dashed green;</p>
                        <p class="ml-60">        <span class="color-green">border-bottom</span>: <span class="color-pink">6px</span> dotted blue;</p>
                        <p class="ml-60">        <span class="color-green">border-left</span>: <span class="color-pink">8px</span> double purple;</p>
                        <p class="ml-30">    }</p>
                        <p>&lt;/style&gt;</p>
                        <p>&lt;div <span class="color-pink">class</span>=<span class="color-green">"box example2"</span>&gt;Shorthand Border Properties&lt;/div&gt;</p>
                        `
                    }}></div>
                </div>
                <p>
                    Output will be the same as above -
                </p>
                <div className='outputPalateBox mb-4 mt-3' style={{'border':'none'}}>
                    <div className='outputPalate' dangerouslySetInnerHTML={{
                        __html: `
                        <style>
                            .box {
                                height: 100px;
                                padding: 10px;
                                margin-bottom: 20px;
                            }
                            .example2 {
                                border-top: 2px solid red;
                                border-right: 4px dashed green;
                                border-bottom: 6px dotted blue;
                                border-left: 8px double purple;
                            }
                        </style>
                        <div class="box example2">Shorthand Border Properties</div>
                        `
                    }}></div>
                </div>

                <h5 className="mt-5 mb-3">Usage of Border Side Properties</h5>
                <p>
                    Using border side properties lets you customize the borders on each side of an element, helping you create attractive and well-organized designs.
                </p>
                <ul style={{ listStyle: 'disc' }}>
                    <li>
                        <strong>Styling Only One Side</strong> You can add a border to just one side of an element. This is useful for creating underline effects, sidebars, or dividers.
                    </li>
                    <li>
                        <strong>Different Styles for Each Side</strong> You can mix and match border styles to create unique effects.
                    </li>
                </ul>
            </div>
        </section>
    )
}