import React, { useEffect, useState, useRef } from 'react'
import { Outlet, Link } from "react-router-dom";
import TopHeader from '../TopHeader';
import Footer from '../Footer'
import { MenuContext, TitleContext, PathContext } from "../../Context";
import { useContext } from "react";
import PreviousNext from '../PreviousNext';

export default function HTMLLayout() {

    const addspaceElement = useRef();
    const menuElement = useRef([]);
    const menu = useContext(MenuContext);
    const pathName = useContext(PathContext);
    const pathname = pathName.pathName;
    const [showSubMenu, setShowSubMenu] = useState(document.getElementsByClassName("activeSubMenu")[0] ? false : true)
    const title = useContext(TitleContext);

    const setPathName = (path) => {
        if(path)
            pathName.setPathName(path);
    }

    useEffect(() => {
        setPathName(window.location.pathname)
        if (menu.isMenu == true) menu.setMenuOpen(false)
        addspaceElement.current.scrollIntoView(true)
        const element = document.getElementsByClassName("activeMenu")[0];
        element && element.scrollIntoView({ block: "center" });
        const subelement = document.getElementsByClassName("activeSubMenu")[0];
        subelement && subelement.scrollIntoView({ block: "center" });
        console.log(subelement)
        if (window.location.pathname != '/html/basic-tags') {
            if(subelement == undefined) setShowSubMenu(false)
        }
    }, [pathname,showSubMenu])

    return (
        <>
            <TopHeader />
            <div className='container-fluid'>
                <div className='row'>
                    <div ref={menuElement} className={menu.isMenu == true ? 'col-md-3 col-lg-2 menuColumnMobile menuColumn p-0' : 'col-md-3 col-lg-2 menuColumn p-0'} style={{ 'overflowY': 'scroll', height: 'calc(100vh - 110px)' }}>
                        <div className='headerMenu'>
                            HTML Tutorial
                        </div>
                        <nav>
                            <ul>
                                <li className={pathname == '/html/introduction' ? 'activeMenu' : ''}>
                                    <Link to="/html/introduction" onClick={() => { setPathName('/html/introduction'); } }>Introduction</Link>
                                </li>
                                <li className={pathname == '/html/editors' ? 'activeMenu' : ''}>
                                    <Link to="/html/editors" onClick={() => setPathName('/html/editors')}>Editors</Link>
                                </li>
                                <li onClick={() => { setPathName('/html/basic-tags'); setShowSubMenu(!showSubMenu) }}>
                                    <Link to="/html/basic-tags">Basic Tags
                                    <span className='dropdown float-end'>
                                        <span className="btn btn-secondary dropdown-toggle dropdown-link-menu"></span>
                                    </span>
                                    </Link>
                                </li>
                                {
                                    showSubMenu == true ? 
                                        <>
                                            <li className={pathname == '/html/basic-tags' ? 'activeSubMenu' : 'background-white'}>
                                                <Link to="/html/basic-tags" onClick={() => setPathName('/html/basic-tags')}>
                                                    <span className='ml-30'>Basic Tags</span>
                                                </Link>
                                            </li>
                                            <li className={pathname == '/html/basic-tags/html' ? 'activeSubMenu' : 'background-white'}>
                                                <Link to="/html/basic-tags/html" onClick={() => setPathName('/html/basic-tags/html')}>
                                                    <span className='ml-30'>&lt;html&gt;</span>
                                                </Link>
                                            </li>
                                            <li className={pathname == '/html/basic-tags/head' ? 'activeSubMenu' : 'background-white'}>
                                                <Link to="/html/basic-tags/head" onClick={() => setPathName('/html/basic-tags/head')}>
                                                    <span className='ml-30'>&lt;head&gt;</span>
                                                </Link>
                                            </li>
                                            <li className={pathname == '/html/basic-tags/body' ? 'activeSubMenu' : 'background-white'}>
                                                <Link to="/html/basic-tags/body" onClick={() => setPathName('/html/basic-tags/body')}>
                                                    <span className='ml-30'>&lt;body&gt;</span>
                                                </Link>
                                            </li>
                                        </>
                                        : <></>
                                }
                                
                                <li className={pathname == '/html/elements' ? 'activeMenu' : ''}>
                                    <Link to="/html/elements" onClick={() => setPathName('/html/elemnets')}>Elements</Link>
                                </li>
                                <li className={pathname == '/html/attributes' ? 'activeMenu' : ''}>
                                    <Link to="/html/attributes" onClick={() => setPathName('/html/attributes')}>Attributes</Link>
                                </li>
                                <li className={pathname == '/html/headings' ? 'activeMenu' : ''}>
                                    <Link to="/html/headings" onClick={() => setPathName('/html/headings')}>Headings</Link>
                                </li>
                                <li className={pathname == '/html/paragraphs' ? 'activeMenu' : ''}>
                                    <Link to="/html/paragraphs" onClick={() => setPathName('/html/editors')}>Paragraphs</Link>
                                </li>
                                <li className={pathname == '/html/styles' ? 'activeMenu' : ''}>
                                    <Link to="/html/styles" onClick={() => setPathName('/html/styles')}>Styles</Link>
                                </li>
                                {/* <li className={pathname == '/html/fonts' ? 'activeMenu' : ''}>
                                    <Link to="/html/fonts" onClick={() => setPathName('/html/fonts')}>Fonts</Link>
                                </li> */}
                                <li className={pathname == '/html/blocks' ? 'activeMenu' : ''}>
                                    <Link to="/html/blocks" onClick={() => setPathName('/html/blocks')}>Blocks & Inline</Link>
                                </li>
                                <li className={pathname == '/html/formatting' ? 'activeMenu' : ''}>
                                    <Link to="/html/formatting" onClick={() => setPathName('/html/formatting')}>Formatting</Link>
                                </li>
                                <li className={pathname == '/html/quotations' ? 'activeMenu' : ''}>
                                    <Link to="/html/quotations" onClick={() => setPathName('/html/quotations')}>Quotations</Link>
                                </li>
                                <li className={pathname == '/html/comments' ? 'activeMenu' : ''}>
                                    <Link to="/html/comments" onClick={() => setPathName('/html/comments')}>Comments</Link>
                                </li>
                                {/* <li className={pathname == '/html/colors' ? 'activeMenu' : ''}>
                                    <Link to="/html/colors" onClick={() => setPathName('/html/colors')}>Colors</Link>
                                </li> */}
                                <li className={pathname == '/html/images' ? 'activeMenu' : ''}>
                                    <Link to="/html/images" onClick={() => setPathName('/html/images')}>Images</Link>
                                </li>
                                <li className={pathname == '/html/favicon' ? 'activeMenu' : ''}>
                                    <Link to="/html/favicon" onClick={() => setPathName('/html/favicon')}>Favicon</Link>
                                </li>
                                <li className={pathname == '/html/page-title' ? 'activeMenu' : ''}>
                                    <Link to="/html/page-title" onClick={() => setPathName('/html/page-title')}>Page Title</Link>
                                </li>
                                <li className={pathname == '/html/iframes' ? 'activeMenu' : ''}>
                                    <Link to="/html/iframes" onClick={() => setPathName('/html/iframes')}>Iframes</Link>
                                </li>
                                <li className={pathname == '/html/javascripts' ? 'activeMenu' : ''}>
                                    <Link to="/html/javascripts" onClick={() => setPathName('/html/javascripts')}>Javascripts</Link>
                                </li>
                                <li className={pathname == '/html/meta-tags' ? 'activeMenu' : ''}>
                                    <Link to="/html/meta-tags" onClick={() => setPathName('/html/meta-tags')}>Meta Tags</Link>
                                </li>
                                <li className={pathname == '/html/classes' ? 'activeMenu' : ''}>
                                    <Link to="/html/classes" onClick={() => setPathName('/html/classes')}>Classes</Link>
                                </li>
                                <li className={pathname == '/html/ids' ? 'activeMenu' : ''}>
                                    <Link to="/html/ids" onClick={() => setPathName('/html/ids')}>IDs</Link>
                                </li>
                                <li className={pathname == '/html/entities' ? 'activeMenu' : ''}>
                                    <Link to="/html/entities" onClick={() => setPathName('/html/entities')}>Entities</Link>
                                </li>
                                <li className={pathname == '/html/symbols' ? 'activeMenu' : ''}>
                                    <Link to="/html/symbols" onClick={() => setPathName('/html/symbols')}>Symbols</Link>
                                </li>
                                <li className={pathname == '/html/emojis' ? 'activeMenu' : ''}>
                                    <Link to="/html/emojis" onClick={() => setPathName('/html/emojis')}>Emojis</Link>
                                </li>
                                <li className={pathname == '/html/layouts' ? 'activeMenu' : ''}>
                                    <Link to="/html/layouts" onClick={() => setPathName('/html/layouts')}>Layout</Link>
                                </li>
                                <li className={pathname == '/html/semantics' ? 'activeMenu' : ''}>
                                    <Link to="/html/semantics" onClick={() => setPathName('/html/semantics')}>Semantics Element</Link>
                                </li>
                            </ul>
                        </nav>
                        <div className='headerMenu'>
                            HTML Tables
                        </div>
                        <nav>
                            <ul>
                                <li className={pathname == '/html/tables' ? 'activeMenu' : ''}>
                                    <Link to="/html/tables" onClick={() => setPathName('/html/tables')}>Tables</Link>
                                </li>
                                <li className={pathname == '/html/table-styling' ? 'activeMenu' : ''}>
                                    <Link to="/html/table-styling" onClick={() => setPathName('/html/table-styling')}>Table Styling</Link>
                                </li>
                                <li className={pathname == '/html/table-colgroup' ? 'activeMenu' : ''}>
                                    <Link to="/html/table-colgroup" onClick={() => setPathName('/html/table-colgroup')}>Table Colgroup</Link>
                                </li>
                                <li className={pathname == '/html/table-colspan-rowspan' ? 'activeMenu' : ''}>
                                    <Link to="/html/table-colspan-rowspan" onClick={() => setPathName('/html/table-colspan-rowspan')}>Colspan & Rowspan</Link>
                                </li>
                            </ul>
                        </nav>
                        <div className='headerMenu'>
                            HTML Lists
                        </div>
                        <nav>
                            <ul>
                                <li className={pathname == '/html/lists' ? 'activeMenu' : ''}>
                                    <Link to="/html/lists" onClick={() => setPathName('/html/lists')}>Lists</Link>
                                </li>
                                <li className={pathname == '/html/unordered-lists' ? 'activeMenu' : ''}>
                                    <Link to="/html/unordered-lists" onClick={() => setPathName('/html/unordered-lists')}>Unordered Lists</Link>
                                </li>
                                <li className={pathname == '/html/ordered-lists' ? 'activeMenu' : ''}>
                                    <Link to="/html/ordered-lists" onClick={() => setPathName('/html/ordered-lists')}>Ordered Lists</Link>
                                </li>
                                <li className={pathname == '/html/definition-lists' ? 'activeMenu' : ''}>
                                    <Link to="/html/definition-lists" onClick={() => setPathName('/html/definition-lists')}>Definition Lists</Link>
                                </li>
                            </ul>
                        </nav>
                        <div className='headerMenu'>
                            HTML Links
                        </div>
                        <nav>
                            <ul>
                                <li className={pathname == '/html/text-links' ? 'activeMenu' : ''}>
                                    <Link to="/html/text-links" onClick={() => setPathName('/html/text-links')}>Text Links</Link>
                                </li>
                                <li className={pathname == '/html/customize-links' ? 'activeMenu' : ''}>
                                    <Link to="/html/customize-links" onClick={() => setPathName('/html/customize-links')}>Customize Links</Link>
                                </li>
                                <li className={pathname == '/html/create-bookmark' ? 'activeMenu' : ''}>
                                    <Link to="/html/create-bookmark" onClick={() => setPathName('/html/create-bookmark')}>Create Bookmark</Link>
                                </li>
                            </ul>
                        </nav>
                        <div className='headerMenu'>
                            HTML Forms
                        </div>
                        <nav>
                            <ul>
                                <li className={pathname == '/html/forms' ? 'activeMenu' : ''}>
                                    <Link to="/html/forms" onClick={() => setPathName('/html/forms')}>Forms</Link>
                                </li>
                                <li className={pathname == '/html/form-attributes' ? 'activeMenu' : ''}>
                                    <Link to="/html/form-attributes" onClick={() => setPathName('/html/form-attributes')}>Form Attributes</Link>
                                </li>
                                <li className={pathname == '/html/form-elements' ? 'activeMenu' : ''}>
                                    <Link to="/html/form-elements" onClick={() => setPathName('/html/form-elements')}>Form Elements</Link>
                                </li>
                                <li className={pathname == '/html/inputs' ? 'activeMenu' : ''}>
                                    <Link to="/html/inputs" onClick={() => setPathName('/html/inputs')}>Inputs</Link>
                                </li>
                                <li className={pathname == '/html/input-attributes' ? 'activeMenu' : ''}>
                                    <Link to="/html/input-attributes" onClick={() => setPathName('/html/input-attributes')}>Input Attributes</Link>
                                </li>
                            </ul>
                        </nav>
                        <div className='headerMenu'>
                            HTML Media
                        </div>
                        <nav>
                            <ul>
                                <li className={pathname == '/html/video-element' ? 'activeMenu' : ''}>
                                    <Link to="/html/video-element" onClick={() => setPathName('/html/video-element')}>Video Element</Link>
                                </li>
                                <li className={pathname == '/html/audio-element' ? 'activeMenu' : ''}>
                                    <Link to="/html/audio-element" onClick={() => setPathName('/html/audio-element')}>Audio Element</Link>
                                </li>
                                <li className={pathname == '/html/embed-multimedia' ? 'activeMenu' : ''}>
                                    <Link to="/html/embed-multimedia" onClick={() => setPathName('/html/embed-multimedia')}>Embed Multimedia</Link>
                                </li>
                            </ul>
                        </nav>
                        {/* <div className='headerMenu'>
                            HTML Layouts
                        </div>
                        <nav>
                            <ul>
                                <li className={pathname == '/html/layouts' ? 'activeMenu' : ''}>
                                    <Link to="/html/layouts" onClick={() => setPathName('/html/layouts')}>Layouts</Link>
                                </li>
                                <li className={pathname == '/html/layout-elements' ? 'activeMenu' : ''}>
                                    <Link to="/html/layout-elements" onClick={() => setPathName('/html/layout-elements')}>Layout Elements</Link>
                                </li>
                                <li className={pathname == '/html/layout-using-css' ? 'activeMenu' : ''}>
                                    <Link to="/html/layout-using-css" onClick={() => setPathName('/html/layout-using-css')}>Layout using CSS</Link>
                                </li>
                                <li className={pathname == '/html/responsiveness' ? 'activeMenu' : ''}>
                                    <Link to="/html/responsiveness" onClick={() => setPathName('/html/responsiveness')}>Responsiveness</Link>
                                </li>
                                <li className={pathname == '/html/symbols' ? 'activeMenu' : ''}>
                                    <Link to="/html/symbols" onClick={() => setPathName('/html/symbols')}>Symbols</Link>
                                </li>
                                <li className={pathname == '/html/emojis' ? 'activeMenu' : ''}>
                                    <Link to="/html/emojis" onClick={() => setPathName('/html/emojis')}>Emojis</Link>
                                </li>
                            </ul>
                        </nav> */}
                        <div className='headerMenu'>
                            HTML Graphics
                        </div>
                        <nav>
                            <ul>
                                <li className={pathname == '/html/svg' ? 'activeMenu' : ''}>
                                    <Link to="/html/svg" onClick={() => setPathName('/html/svg')}>SVG</Link>
                                </li>
                                <li className={pathname == '/html/canvas' ? 'activeMenu' : ''}>
                                    <Link to="/html/canvas" onClick={() => setPathName('/html/canvas')}>Canvas</Link>
                                </li>
                            </ul>
                        </nav>
                        <div className='headerMenu'>
                            HTML APIs
                        </div>
                        <nav>
                            <ul>
                                <li className={pathname == '/html/geolocation' ? 'activeMenu' : ''}>
                                    <Link to="/html/geolocation" onClick={() => setPathName('/html/geolocation')}>Geolocation</Link>
                                </li>
                                <li className={pathname == '/html/drag-drop' ? 'activeMenu' : ''}>
                                    <Link to="/html/drag-drop" onClick={() => setPathName('/html/drag-drop')}>Drag/Drop</Link>
                                </li>
                                <li className={pathname == '/html/web-storage' ? 'activeMenu' : ''}>
                                    <Link to="/html/web-storage" onClick={() => setPathName('/html/web-storage')}>Web Storage</Link>
                                </li>
                                <li className={pathname == '/html/web-workers' ? 'activeMenu' : ''}>
                                    <Link to="/html/web-workers" onClick={() => setPathName('/html/web-workers')}>Web Workers</Link>
                                </li>
                            </ul>
                        </nav>
                        <div className='headerMenu'>
                            HTML References
                        </div>
                        <nav>
                            <ul>
                                <li className={pathname == '/html/events' ? 'activeMenu' : ''}>
                                    <Link to="/html/events" onClick={() => setPathName('/html/events')}>Events</Link>
                                </li>
                                <li className={pathname == '/html/tag-list' ? 'activeMenu' : ''}>
                                    <Link to="/html/tag-list" onClick={() => setPathName('/html/tag-list')}>Tags List</Link>
                                </li>
                            </ul>
                        </nav>
                    </div>
                    <div className='col-12 col-md-9 col-lg-10 rightPart' style={{ 'overflowY': 'scroll', height: 'calc(100vh - 110px)' }}>
                        <div className='row'>
                            <div className='col-12 col-md-12 col-lg-10 col-lg-9 mb-5'>
                                <div className='headerAdSpace' ref={addspaceElement}>
                                    <ins className="adsbygoogle"
                                        style={{ 'display': 'block' }}
                                        data-ad-client="ca-pub-2061924575986153"
                                        data-ad-slot="8656415608"
                                        data-ad-format="auto"
                                        data-full-width-responsive="true"></ins>
                                    <script>
                                        (adsbygoogle = window.adsbygoogle || []).push({ });
                                    </script>
                                </div>
                                <div className='container'>
                                    <div className='contentPage'>
                                        {
                                            pathName.urls ? <PreviousNext urls={ pathName.urls } /> : <></>
                                        }
                                            <Outlet />
                                        {
                                            pathName.urls ? <PreviousNext urls={ pathName.urls } /> : <></>
                                        }
                                    </div>
                                </div>
                                <div className='footerAdSpace'>
                                    <ins className="adsbygoogle"
                                        style={{ 'display': 'block' }}
                                        data-ad-client="ca-pub-2061924575986153"
                                        data-ad-slot="7856138126"
                                        data-ad-format="auto"
                                        data-full-width-responsive="true"></ins>
                                    <script>
                                        (adsbygoogle = window.adsbygoogle || []).push({ });
                                    </script>
                                </div>
                            </div>
                            <div className='col-lg-3 hiddenInSmall'>
                                <ins className="adsbygoogle"
                                    style={{ 'display': 'block' }}
                                    data-ad-client="ca-pub-2061924575986153"
                                    data-ad-slot="6669380669"
                                    data-ad-format="auto"
                                    data-full-width-responsive="true"></ins>
                                <script>
                                    (adsbygoogle = window.adsbygoogle || []).push({ });
                                </script>
                            </div>
                            <div className='col-12'>
                                <div className='container-fluid footerPart'>
                                    <Footer />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}