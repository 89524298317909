import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
export const pageUrl = () => "/mongo-db/introduction";

export default function MongoIntro() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("An Introduction to MongoDB - Step By Step Complete Guide");
        title.setKeyWords("mongodb introduction, mongodb tutorial, mongo db, mongoDB, mongodb introduction tutorial, mongodb introduction course, mongodb introduction to nosql databases, introduction and installation of mongodb, introduction about mongodb, mongodb intro, mongodb brief introduction, mongodb tutorial beginners, mongodb basics tutorial, mongodb best tutorial, mongodb description, mongodb tutorial example, mongodb easy tutorial, mongodb tutorial for beginners, mongodb tutorial for beginners step by step, introduction to mongodb key features, intro to mongodb, introduction mongodb, mongodb tutorial aspirantshome, mongodb mongoose tutorial, mongodb nosql tutorial");
        title.setPageDescription("MongoDB is a popular NoSQL database known for its high performance, high availability, and easy scalability. Unlike traditional relational databases (RDBMS) that store data in tables, MongoDB stores data in flexible, JSON-like documents. This allows for a more dynamic and efficient way of handling data, especially for modern applications that require flexibility and scalability.");
        const urls = {
            'next': '/mongo-db/get-started'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>MongoDB - Introduction</h3>
            <div className='mt-4 mb-5'>
                <p>
                    MongoDB is a popular NoSQL database known for its high performance, high availability, and easy scalability. Unlike traditional relational databases (RDBMS) that store data in tables, MongoDB stores data in flexible, JSON-like documents. This allows for a more dynamic and efficient way of handling data, especially for modern applications that require flexibility and scalability.
                </p>
            </div>
            <div className='mt-5 mb-5'>
                <h5>Key Features of MongoDB</h5>
                <div className='mt-3 mb-5'>
                    <ul style={{listStyle:'decimal'}}>
                        <li>
                            <strong>Document-Oriented Storage:</strong>
                            <ul style={{listStyle:'disc'}}>
                                <li>MongoDB stores data in documents (BSON format - Binary JSON).</li>
                                <li>Each document can have a different number of fields, sizes, and content, which offers great flexibility.</li>
                            </ul>
                        </li>
                        <li>
                            <strong>Schema Flexibility:</strong>
                            <ul style={{ listStyle: 'disc' }}>
                                <li>Unlike relational databases, MongoDB does not require a fixed schema, allowing for the storage of documents with varying structures.</li>
                                <li>Fields can vary from document to document, and new fields can be added without affecting existing documents.</li>
                            </ul>
                        </li>
                        <li>
                            <strong>Scalability</strong>
                            <ul style={{ listStyle: 'disc' }}>
                                <li>MongoDB supports horizontal scaling through a process called sharding, which distributes data across multiple servers.</li>
                                <li>It is designed to handle large amounts of data and high-throughput operations efficiently.</li>
                            </ul>
                        </li>
                        <li>
                            <strong>High Performance:</strong>
                            <ul style={{ listStyle: 'disc' }}>
                                <li>MongoDB offers high read and write throughput for big data applications.</li>
                                <li>It supports rich queries, indexing, and aggregation operations, which enhance performance.</li>
                            </ul>
                        </li>
                        <li>
                            <strong>High Availability:</strong>
                            <ul style={{ listStyle: 'disc' }}>
                                <li>MongoDB ensures high availability with replica sets, which maintain multiple copies of data across different servers.</li>
                                <li>In the event of a failure, replica sets provide automatic failover, ensuring continuity.</li>
                            </ul>
                        </li>
                        <li>
                            <strong>Rich Query Language:</strong>
                            <ul style={{ listStyle: 'disc' }}>
                                <li>MongoDB supports a powerful and flexible query language that includes support for field, range queries, and regular expressions.</li>
                                <li>It also offers aggregations, text search, and geospatial queries.</li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
        </section>
    )
}