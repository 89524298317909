import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/css/border-rounded";

export default function RoundedBorders() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Rounded Borders in CSS | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/css/border-shorthand',
            'next': '/css/margins'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>Rounded Borders</h3>
            <div className='mt-4 mb-5'>
                <p>
                    Rounded borders in CSS can be created using the <strong>`border-radius`</strong> property. This property allows you to add rounded corners to an element, giving it a softer and more modern appearance. It has a simple syntax -
                </p>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>selector {</p>
                            <p class="ml-30">    <span class="color-green">border-radius</span>: value;</p>
                            <p>}</p>
                        `
                    }}></div>
                </div>
                <p>
                    Where, <strong>`value`</strong> specifies the radius of the element's corners. It can be a length (e.g., <strong>`px`</strong>, <strong>`em`</strong>, <strong>`%`</strong>) or multiple values to control individual corners.
                </p>

                <h5 className="mt-5 mb-3">How a Rounded Corner Look Like</h5>
                <p>
                    Below are some examples of how rounded corner's are look like - 
                </p>
                <h6>Example 1</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>&lt;style&gt;</p>
                            <p class="ml-30">    .roundedStyle1{</p>
                            <p class="ml-60">        <span class="color-green">padding</span>: <span class="color-pink">10px 20px</span>;</p>
                            <p class="ml-60">        <span class="color-green">border</span> : <span class="color-pink">2px</span> solid green;</p>
                            <p class="ml-60">        <span class="color-green">border-radius</span> : <span class="color-pink">10px</span>;</p>
                            <p class="ml-30">    }</p>
                            <p>&lt;/style&gt;</p>
                            <p>&lt;p <span class="color-pink">class</span>=<span class="color-green">"roundedStyle1"</span>&gt; This is rounded corner style 1&lt;/p&gt;</p>
                        `
                    }}></div>
                </div>
                <p>
                    Output will be the following -
                </p>
                <div className='outputPalateBox mb-4 mt-3' style={{'border':'none'}}>
                    <div className='outputPalate' dangerouslySetInnerHTML={{
                        __html: `
                            <style>
                                .roundedStyle1{
                                    padding: 10px 20px;
                                    border : 2px solid green;
                                    border-radius : 10px;
                                }
                            </style>
                            <p class="roundedStyle1"> This is rounded corner style 1</p>
                        `
                    }}></div>
                </div>
                <h6>Example 2</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>&lt;style&gt;</p>
                            <p class="ml-30">    .roundedStyle2{</span>
                            <p class="ml-60">        <span class="color-green">padding</span>: <span class="color-pink">10px 20px</span>;</p>
                            <p class="ml-60">        <span class="color-green">border</span> : <span class="color-pink">2px</span> solid green;</p>
                            <p class="ml-60">        <span class="color-green">border-radius</span> : <span class="color-pink">20px</span>;</p>
                            <p class="ml-30">    }</p>
                            <p>&lt;/style&gt;</p>
                            <p>&lt;p <span class="color-pink">class</span>=<span class="color-green">"roundedStyle2"</span>&gt; This is rounded corner style 2&lt;/p&gt;</p>
                        `
                    }}></div>
                </div>
                <p>
                    Output will be the following -
                </p>
                <div className='outputPalateBox mb-4 mt-3' style={{'border':'none'}}>
                    <div className='outputPalate' dangerouslySetInnerHTML={{
                        __html: `
                            <style>
                                .roundedStyle2{
                                    padding: 10px 20px;
                                    border : 2px solid green;
                                    border-radius : 20px;
                                }
                            </style>
                            <p class="roundedStyle2"> This is rounded corner style 2</p>
                        `
                    }}></div>
                </div>
                <h6>Example 3</h6>
                <div className='codePalateBox mt-2 mb-4'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p>&lt;style&gt;</p>
                            <p class="ml-30">    .roundedStyle3{</p>
                            <p class="ml-60">        <span class="color-green">padding</span>: <span class="color-pink">10px 20px</span>;</p>
                            <p class="ml-60">        <span class="color-green">border</span> : <span class="color-pink">2px</span> solid green;</p>
                            <p class="ml-60">        <span class="color-green">border-radius</span> : <span class="color-pink">30px</span>;</p>
                            <p class="ml-30">    }</p>
                            <p>&lt;/style&gt;</p>
                            <p>&lt;p <span class="color-pink">class</span>=<span class="color-green">"roundedStyle3"</span>&gt; This is rounded corner style 3&lt;/p&gt;</p>
                        `
                    }}></div>
                </div>
                <p>
                    Output will be the following -
                </p>
                <div className='outputPalateBox mb-4 mt-3' style={{'border':'none'}}>
                    <div className='outputPalate' dangerouslySetInnerHTML={{
                        __html: `
                            <style>
                                .roundedStyle3{
                                    padding: 10px 20px;
                                    border : 2px solid green;
                                    border-radius : 30px;
                                }
                            </style>
                            <p class="roundedStyle3"> This is rounded corner style 3</p>
                        `
                    }}></div>
                </div>
            </div>
        </section>
    )
}