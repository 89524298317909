import React, {useEffect, useContext} from 'react'
import { TitleContext, PathContext } from "../../../Context";
import { Link } from 'react-router-dom';
export const pageUrl = () => "/mysql/arithmetic-operators/unary-minus";

export default function UnaryMinus() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    useEffect(() => {
        title.setPageTitle("Mysql - Arithmetic Operators - Unary Minus (-) | Aspirant's Home");
        path.setPreviousNext({});
    }, [])

    return (
        <>
            <section class='clearfix mb-3'>
                <span className='float-start'>
                    <Link to='/mysql/arithmetic-operators' onClick={() => path.setPathName('/mysql/arithmetic-operators')} className='btn btn-primary'> <i class="fa-solid fa-less-than"></i>&nbsp; Back to Arithmetic Operators </Link>
                </span>
            </section>
            <section className='mt-5 mb-5'>
                <h3>Mysql - Unary Minus (-) Operator</h3>
                <div className='mt-4 mb-5'>
                    <p>
                        The unary minus operator in MySQL is used to change the sign of a numeric value. It turns positive values into negative ones and vice versa.
                    </p>
                    <p>
                        Here is a simple example using the unary minus operator:
                    </p>
                    <div className='codePalateBox mt-2 mb-2'>
                        <div className='codePalate' dangerouslySetInnerHTML={{
                            __html: `
                                <p><span class="color-blue">SELECT</span> <span class="color-pink">-5</span>;</p>
                            `
                        }}></div>
                    </div>
                    <p>
                        This query will return -5.
                    </p>
                </div>
                <div className='mt-5 mb-5'>
                    <h5>Unary Minus (-) with MySQL Table</h5>
                    <div className='mt-3 mb-2'>
                        <p>
                            Consider a table <strong>`transactions`</strong> with columns <strong>`transaction_id`</strong>, <strong>`amount`</strong>, and <strong>`transaction_type`</strong>. 
                        </p>
                        <table className='table' style={{border: '1px dashed #ccc'}}> 
                            <thead>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>transaction_id</th>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>amount</th>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>transaction_type</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>1</td>
                                    <td style={{border: '1px dashed #ccc'}}>500.00</td>
                                    <td style={{border: '1px dashed #ccc'}}>'credit'</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>2</td>
                                    <td style={{border: '1px dashed #ccc'}}>200.00</td>
                                    <td style={{border: '1px dashed #ccc'}}>'debit'</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>3</td>
                                    <td style={{border: '1px dashed #ccc'}}>300.00</td>
                                    <td style={{border: '1px dashed #ccc'}}>'credit'</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>4</td>
                                    <td style={{border: '1px dashed #ccc'}}>150.00</td>
                                    <td style={{border: '1px dashed #ccc'}}>'debit'</td>
                                </tr>
                            </tbody>
                        </table>
                        <p>
                            If you want to negate the <strong>`amount`</strong> for transactions of type 'debit', you can use the unary minus operator as follows:
                        </p>
                        <div className='codePalateBox mt-2 mb-2'>
                            <div className='codePalate' dangerouslySetInnerHTML={{
                                __html: `
                                    <p><span class="color-blue">SELECT</span> transaction_id, amount, transaction_type, </p>
                                    <p><span class="color-blue">CASE WHEN</span> transaction_type = 'debit' <span class="color-blue">THEN</span> -amount <span class="color-blue">ELSE</span> amount <span class="color-blue">END AS</span> adjusted_amount</p>
                                    <p><span class="color-blue">FROM</span> transactions;</p>
                                `
                            }}></div>
                        </div>
                        <p>
                            This query will return:
                        </p>
                        <table className='table' style={{border: '1px dashed #ccc'}}> 
                            <thead>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>transaction_id</th>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>amount</th>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>transaction_type</th>
                                    <th style={{border: '1px dashed #ccc', backgroundColor:'#e1f1ff'}}>adjusted_amount</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>1</td>
                                    <td style={{border: '1px dashed #ccc'}}>500.00</td>
                                    <td style={{border: '1px dashed #ccc'}}>'credit'</td>
                                    <td style={{border: '1px dashed #ccc'}}>500.00</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>2</td>
                                    <td style={{border: '1px dashed #ccc'}}>200.00</td>
                                    <td style={{border: '1px dashed #ccc'}}>'debit'</td>
                                    <td style={{border: '1px dashed #ccc'}}> -200.00</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>3</td>
                                    <td style={{border: '1px dashed #ccc'}}>300.00</td>
                                    <td style={{border: '1px dashed #ccc'}}>'credit'</td>
                                    <td style={{border: '1px dashed #ccc'}}>300.00</td>
                                </tr>
                                <tr style={{border: '1px dashed #ccc'}}>
                                    <td style={{border: '1px dashed #ccc'}}>4</td>
                                    <td style={{border: '1px dashed #ccc'}}>150.00</td>
                                    <td style={{border: '1px dashed #ccc'}}>'debit'</td>
                                    <td style={{border: '1px dashed #ccc'}}> -150.00</td>
                                </tr>
                            </tbody>
                        </table>
                        <p>
                            In this example, the <strong>`CASE`</strong> statement is used to check the <strong>`transaction_type`</strong>. If the <strong>`transaction_type`</strong> is 'debit', the unary minus operator is applied to the <strong>`amount`</strong> to negate it. Otherwise, the <strong>`amount`</strong> remains unchanged.
                        </p>
                    </div>
                </div>
            </section>
        </>
    )
}