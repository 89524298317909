import React, {useEffect, useContext, useState} from 'react'
import { TitleContext, PathContext } from "../../Context";
import { Link } from 'react-router-dom';

export const pageUrl = () => "/css/colors/hsl";

export default function CSSColorHex() {

    const title = useContext(TitleContext);
    const path = useContext(PathContext);
    const [redColor, setRedColor] = useState(0);
    const [greenColor, setGreenColor] = useState(100);
    const [blueColor, setBlueColor] = useState(50);


    useEffect(() => {
        title.setPageTitle("HSL Colors in CSS | A Complete Guide");
        title.setKeyWords("");
        title.setPageDescription("");
        const urls = {
            'previous': '/css/colors/hex',
            'next': '/css/background-color'
        }
        path.setPreviousNext(urls);
    }, [])

    return (
        <section className='mt-5 mb-5'>
            <h3>HSL Colors in CSS</h3>
            <div className='mt-4 mb-5'>
                <p>
                    HSL stands for <strong><i>Hue</i></strong>, <strong><i>Saturation</i></strong>, and <strong><i>Lightness</i></strong>. It is a color model that describes colors in terms of their hue, the degree of saturation (intensity), and the degree of lightness (brightness). The HSL color model is often seen as easier to understand than RGB because it matches how people naturally see colors.
                </p>
                <h5 className='mt-4 mb-4'>Syntax</h5>
                <p>
                    The <strong>`hsl()`</strong> function in CSS is used to define colors using the HSL model. It takes three parameters:
                </p>
                <ul style={{ 'listStyle': 'disc' }}>
                    <li><strong>`hue`</strong> represent a degree on the color wheel (from 0 to 360). Where, 0 (or 360) is red, 120 is green, and 240 is blue.</li>
                    <li><strong>`saturation`</strong> represent a percentage (0% to 100%), the intensity of the color. Where, 0% is a shade of gray, and 100% is the full color.</li>
                    <li><strong>`lightness`</strong> represent a percentage (0% to 100%), the brightness of the color. Where, 0% is black, and 100% is white.</li>
                </ul>
                <div className='codePalateBox mt-2 mb-5'>
                    <div className='codePalate' dangerouslySetInnerHTML={{
                        __html: `
                            <p><span class="color-green">color</span>: <span class="color-yellow">hsl</span>(hue, saturation, lightness);</p>
                        `
                    }}></div>
                </div>
                <h5 className='mt-5 mb-5'>Common HSL Color Code</h5>
                <div className='mt-4 mb-5 row text-center colorNames transparentBackground'>
                    <div className='col-4' style={{ backgroundColor: 'hsl(0, 100%, 50%)', color: '#FFF' }}>hsl(0, 100%, 50%)</div>
                    <div className='col-4' style={{ backgroundColor: 'hsl(120, 100%, 50%)', color: '#FFF' }}>hsl(120, 100%, 50%)</div>
                    <div className='col-4' style={{ backgroundColor: 'hsl(240, 100%, 50%)', color: '#FFF' }}>hsl(240, 100%, 50%)</div>
                    <div className='col-4' style={{ backgroundColor: 'hsl(60, 100%, 50%)', color: '#000' }}>hsl(60, 100%, 50%)</div>
                    <div className='col-4' style={{ backgroundColor: 'hsl(0, 0%, 0%)', color: '#FFF' }}>hsl(0, 0%, 0%)</div>
                    <div className='col-4' style={{ backgroundColor: 'hsl(0, 0%, 25%)', color: '#FFF' }}>hsl(0, 0%, 25%)</div>
                    <div className='col-4' style={{ backgroundColor: 'hsl(0, 0%, 100%)', color: '#000' }}>hsl(0, 0%, 100%)</div>
                    <div className='col-4' style={{ backgroundColor: 'hsl(195, 53%, 79%)', color: '#FFF' }}>hsl(195, 53%, 79%)</div>
                    <div className='col-4' style={{ backgroundColor: 'hsl(0, 0%, 50%)', color: '#FFF' }}>hsl(0, 0%, 50%)</div>
                </div>

                <h5 className='mt-5 mb-4'>Experiment with HSL Value</h5>
                <p>
                    You can experiment hsl value with the below sliders - 
                </p>
                <div className='row'>
                    <div className='col-3'></div>
                    <div className='col-6 fontStyle' style={{height:'100px','alignContent':'center','textAlign':'center', backgroundColor: 'hsl('+redColor +', '+ greenColor+'%, ' + blueColor+'%)', color: '#FFF' }}>
                        {'hsl('+redColor +', '+ greenColor+'%, ' + blueColor+'%)'}
                    </div>
                    <div className='col-3'></div>
                </div>
                <div className='row mt-5 mb-5'>
                    <div className='col-4 text-center'>
                        <label for="points">HUE:</label>
                        <br />
                        <input type="range" id="points1" value={redColor} name="points" min="0" max="360" onChange={(e) => setRedColor(e.target.value)} />
                        <br />
                        <span>{redColor}</span>
                    </div>
                    <div className='col-4 text-center'>
                        <label for="points">SATURATION:</label>
                        <br />
                        <input type="range" id="points2" value={greenColor} name="points" min="0" max="100" onChange={(e)=>setGreenColor(e.target.value)} />
                        <br />
                        <span>{greenColor}%</span>
                    </div>
                    <div className='col-4 text-center'>
                        <label for="points">LIGHTNESS:</label>
                        <br />
                        <input type="range" id="points3" value={blueColor} name="points" min="0" max="100" onChange={(e)=>setBlueColor(e.target.value)} />
                        <br />
                        <span>{blueColor}%</span>
                    </div>
                </div>

                <h5 className='mt-5 mb-4'>What is HUE ?</h5>
                <p>
                    In the HSL color model, Hue represents the type of color and is the degree on the color wheel from 0 to 360. Each degree corresponds to a specific color on the spectrum of visible light.
                </p>
                <p>
                    Imagine a color wheel where each degree corresponds to a different color. By rotating around the wheel, you can change the hue, thereby selecting different colors.
                </p>
                <h6>Examples</h6>
                <div className='mt-4 mb-5 row text-center colorNames transparentBackground'>
                    <div className='col-4' style={{ backgroundColor: 'hsl(0, 100%, 50%)', color: '#FFF' }}>hsl(0, 100%, 50%)</div>
                    <div className='col-4' style={{ backgroundColor: 'hsl(40, 100%, 50%)', color: '#FFF' }}>hsl(40, 100%, 50%)</div>
                    <div className='col-4' style={{ backgroundColor: 'hsl(80, 100%, 50%)', color: '#000' }}>hsl(80, 100%, 50%)</div>
                    <div className='col-4' style={{ backgroundColor: 'hsl(120, 100%, 50%)', color: '#000' }}>hsl(120, 100%, 50%)</div>
                    <div className='col-4' style={{ backgroundColor: 'hsl(160, 100%, 50%)', color: '#FFF' }}>hsl(160, 100%, 50%)</div>
                    <div className='col-4' style={{ backgroundColor: 'hsl(200, 100%, 50%)', color: '#FFF' }}>hsl(200, 100%, 50%)</div>
                    <div className='col-4' style={{ backgroundColor: 'hsl(240, 100%, 50%)', color: '#FFF' }}>hsl(240, 100%, 50%)</div>
                    <div className='col-4' style={{ backgroundColor: 'hsl(280, 100%, 50%)', color: '#FFF' }}>hsl(280, 100%, 50%)</div>
                    <div className='col-4' style={{ backgroundColor: 'hsl(320, 100%, 50%)', color: '#FFF' }}>hsl(320, 100%, 50%)</div>
                </div>
                <p>
                    In the above examples, you can check <strong>`hue`</strong> value are different for different color.
                </p>

                <h5 className='mt-5 mb-4'>What is SATURATION ?</h5>
                <p>
                    In the HSL (Hue, Saturation, Lightness) color model, saturation means how strong or pure a color is. It shows how bright or dull the color looks.
                </p>
                <p>
                    <strong>`Saturation`</strong> is expressed as a percentage.
                </p>
                <p>
                    <strong>High saturation</strong> (closer to <strong>`100%`</strong>) means the color is more intense and vibrant.
                </p>
                <p>
                    And, <strong>Low saturation</strong> (closer to <strong>`0%`</strong>) means the color is more muted and closer to gray.
                </p>
                <p>
                    Imagine a slider where one end is pure and the other is gray. Moving the slider adjusts the saturation level, changing the color's intensity.
                </p>
                <h6>Examples</h6>
                <div className='mt-4 mb-5 row text-center colorNames transparentBackground'>
                    <div className='col-4' style={{ backgroundColor: 'hsl(0, 100%, 50%)', color: '#FFF' }}>hsl(0, 100%, 50%)</div>
                    <div className='col-4' style={{ backgroundColor: 'hsl(0, 50%, 50%)', color: '#FFF' }}>hsl(0, 50%, 50%)</div>
                    <div className='col-4' style={{ backgroundColor: 'hsl(0, 0%, 50%)', color: '#000' }}>hsl(0, 0%, 50%)</div>
                </div>

                <h5 className='mt-5 mb-4'>What is LIGHTNESS ?</h5>
                <p>
                    <strong>`Lightness`</strong> in the HSL (Hue, Saturation, Lightness) color model refers to how light or dark a color appears. It describes the brightness of the color.
                </p>
                <p>
                    <strong>`Lightness`</strong> is expressed as a percentage.
                </p>
                <p>
                    <strong>High lightness</strong> (closer to <strong>`100%`</strong>) makes the color appear lighter or closer to white. And <strong>Low lightness</strong> (closer to <strong>`0%`</strong>) makes the color appear darker or closer to black.
                </p>
                <p>
                    Imagine a slider where one end is white, and the other end is black. Moving the slider adjusts the lightness level, changing the color's brightness.
                </p>
                <h6>Examples</h6>
                <div className='mt-4 mb-5 row text-center colorNames transparentBackground'>
                    <div className='col-6' style={{ backgroundColor: 'hsl(0, 100%, 100%)', color: '#000' }}>hsl(0, 100%, 100%)</div>
                    <div className='col-6' style={{ backgroundColor: 'hsl(0, 100%, 80%)', color: '#FFF' }}>hsl(0, 100%, 80%)</div>
                    <div className='col-6' style={{ backgroundColor: 'hsl(0, 100%, 60%)', color: '#FFF' }}>hsl(0, 100%, 60%)</div>
                    <div className='col-6' style={{ backgroundColor: 'hsl(0, 100%, 40%)', color: '#FFF' }}>hsl(0, 100%, 40%)</div>
                    <div className='col-6' style={{ backgroundColor: 'hsl(0, 100%, 20%)', color: '#FFF' }}>hsl(0, 100%, 20%)</div>
                    <div className='col-6' style={{ backgroundColor: 'hsl(0, 100%, 0%)', color: '#FFF' }}>hsl(0, 100%, 0%)</div>
                </div>

                <h5 className='mt-5 mb-3'>What is HSLA Value ?</h5>
                <p>
                    HSLA stands for <strong>Hue, Saturation, Lightness, and Alpha</strong>. It is an extension of the HSL color model that adds an alpha channel, which represents the opacity of the color. The HSLA color model allows you to define colors in a way that is often more intuitive than the RGB model and includes transparency.
                </p>
                <p>
                    Where, <strong>Alpha</strong> represents the opacity of the color and is defined as a number between <strong>`0`</strong> and <strong>`1`</strong>.
                </p>
                <p>
                    <strong>`0`</strong> is fully transparent, <strong>`1`</strong> is fully opaque.
                </p>
                <h6>Examples</h6>
                <div className='mt-4 mb-5 row text-center colorNames transparentBackground'>
                    <div className='col-6' style={{ backgroundColor: 'hsla(0, 100%, 50%, 0)', color: '#000' }}>hsla(0, 100%, 50%, 0)</div>
                    <div className='col-6' style={{ backgroundColor: 'hsla(0, 100%, 50%, 0.2)', color: '#FFF' }}>hsla(0, 100%, 50%, 0.2)</div>
                    <div className='col-6' style={{ backgroundColor: 'hsla(0, 100%, 50%, 0.4)', color: '#FFF' }}>hsla(0, 100%, 50%, 0.4)</div>
                    <div className='col-6' style={{ backgroundColor: 'hsla(0, 100%, 50%, 0.6)', color: '#FFF' }}>hsla(0, 100%, 50%, 0.6)</div>
                    <div className='col-6' style={{ backgroundColor: 'hsla(0, 100%, 50%, 0.8)', color: '#FFF' }}>hsla(0, 100%, 50%, 0.8)</div>
                    <div className='col-6' style={{ backgroundColor: 'hsla(0, 100%, 50%, 1)', color: '#FFF' }}>hsla(0, 100%, 50%, 1)</div>
                </div>
            </div>
        </section>
    )
}